/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/ReceiptReissueActionType';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.RECEIPT_DROPDOWNS:
      return { ...state, receiptDropdown: action.receiptDropdown };      
    case actionTypes.SYSTEM_LIST_RECEIPT:
      return { rowReceiptSyslist: action.rowReceiptSyslist };
    case actionTypes.FUND_CODE_SEARCH_CRITERIA:
      return { searchResults: action.searchResults };
    case actionTypes.FUND_CODE_SEARCH_ERROR:
      return { searchError: action.searchError };
    case actionTypes.FUND_CODE_ADD:
      return { addFundCodeData: action.addFundCodeData };
    case actionTypes.SAVE_REISSUE_CHECK_REPLACEMENT:
      return { saveReplacement: action.saveReplacement };
    case actionTypes.SEARCH_FUND_CODE:
      return { payloadFundCode: action.payloadFundCode };
    case actionTypes.RESETDATA:
      return action.resetData;
    default: return state;
  }
};
export default reducer;
