/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import numeral from 'numeral';
import Radio from '@material-ui/core/Radio';

export default function ExemptionDetails (props) {
  const userInquiryPrivileges = !global.globalIsReadOnly();
  return (
    <form autoComplete="off">
      <div className="row">
        <div className="col-3">
          <div className="form-wrapper vertical-wrapper">
            <div
              className="mui-custom-form"
              style={{ lineHeight: '100%', marginBottom: '22px' }}
            >
              <label className="MuiFormLabel-root small-label">
                Exempt From Penalty<span>*</span>
              </label>
              <div className="sub-radio min-md">
                <Radio
                  value="Y"
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : props.values.balanceAmount === '0.00'
                  }
                  id="exemptFromPenaltyIdY"
                  checked={props.values.exemptFromPenalty === 'Y'}
                  onChange={props.handleChange('exemptFromPenalty')}
                />
                <label className="text-black" for="exemptFromPenaltyIdY">
                  Yes
                </label>
                <Radio
                  value="N"
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : props.values.balanceAmount === '0.00'
                  }
                  id="exemptFromPenaltyIdN"
                  checked={props.values.exemptFromPenalty === 'N'}
                  onChange={props.handleChange('exemptFromPenalty')}
                  className="ml-4"
                />
                <label className="text-black" for="exemptFromPenaltyIdN">
                  No
                </label>
              </div>
            </div>
            <div
              className="mui-custom-form"
              style={{ lineHeight: '100%', marginBottom: '22px' }}
            >
              <label className="MuiFormLabel-root small-label">
                Exempt from Interest<span>*</span>
              </label>
              <div className="sub-radio sub-radio min-md">
                <Radio
                  value="Y"
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : props.values.balanceAmount === '0.00'
                  }
                  id="exemptFromInterestIdY"
                  checked={props.values.exemptFromInterest === 'Y'}
                  onChange={props.handleChange('exemptFromInterest')}
                />
                <label className="text-black" for="exemptFromInterestIdY">
                  Yes
                </label>
                <Radio
                  value="N"
                  id="exemptFromInterestIdN"
                  disabled={!userInquiryPrivileges}
                  checked={props.values.exemptFromInterest === 'N'}
                  onChange={props.handleChange('exemptFromInterest')}
                  className="ml-4"
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : props.values.balanceAmount === '0.00'
                  }
                />
                <label className="text-black" for="exemptFromInterestIdN">
                  No
                </label>
              </div>
            </div>
            <div
              className="mui-custom-form"
              style={{ lineHeight: '100%', marginBottom: '22px' }}
            >
              <label className="MuiFormLabel-root small-label">
                Exempt From Collection Agency<span>*</span>
              </label>
              <div className="sub-radio min-md">
                <Radio
                  value="Y"
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : props.values.balanceAmount === '0.00'
                  }
                  id="AdanceexemptFromCollectionAgencyIdYes"
                  checked={props.values.exemptFromCollectionAgency === 'Y'}
                  onChange={props.handleChange('exemptFromCollectionAgency')}
                />
                <label
                  className="text-black"
                  for="AdanceexemptFromCollectionAgencyIdYes"
                >
                  Yes
                </label>
                <Radio
                  value="N"
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : props.values.balanceAmount === '0.00'
                  }
                  id="AdanceexemptFromCollectionAgencyIdNo"
                  checked={props.values.exemptFromCollectionAgency === 'N'}
                  onChange={props.handleChange('exemptFromCollectionAgency')}
                  className="ml-4"
                />
                <label
                  className="text-black"
                  for="AdanceexemptFromCollectionAgencyIdNo"
                >
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="form-wrapper vertical-wrapper">
            <div className="mui-custom-form min-md">
              <TextField
                id="standard-calculated-penalty"
                label="Calculated Penalty"
                disabled
                placeholder=""
                inputProps={{ maxlength: 13 }}
                InputLabelProps={{
                  shrink: true
                }}
                value={numeral(props.values.calculatedPenalty).format(
                  '$0,0.00'
                )}
              ></TextField>
            </div>
            <div className="mui-custom-form min-md">
              <TextField
                id="standard-calculated-interest"
                label="Calculated Interest"
                disabled
                placeholder=""
                inputProps={{ maxlength: 13 }}
                InputLabelProps={{
                  shrink: true
                }}
                value={numeral(props.values.calculateInterest).format(
                  '$0,0.00'
                )}
              ></TextField>
            </div>
            <div className="mui-custom-form min-md">
              <TextField
                id="standard-fee-assessed"
                label="Fees Assessed"
                disabled
                placeholder=""
                inputProps={{ maxlength: 13 }}
                InputLabelProps={{
                  shrink: true
                }}
                value={numeral(props.values.feesAssesed).format('$0,0.00')}
              ></TextField>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
