import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "react-bootstrap";
import * as ErrorConst from "../../../../SharedModules/Messages/ErrorMsgConstants";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import dateFnsFormat from "date-fns/format";

export default function SearchForm(props) {
  const formatDate = (dt) => {
    if (!dt) {
      return "";
    }
    dt = new Date(dt);
    if (dt.toString() == "Invalid Date") {
      return dt;
    } else {
      return dateFnsFormat(dt, "MM/dd/yyyy");
    }
  };

  const handleBeginDateChange = (date) => {
    props.handleDCDtChange("beginDate", formatDate(date));
  };
  const handleEndDateChange = (date) => {
    props.handleDCDtChange("endDate", formatDate(date));
  };

  const [readMore, setReadMore] = useState(false);
  const extraContent = (
    <div>
      <p className="extra-content">
        <br />
        User ID / Job-ID or User Last name
        <div>OR</div>
        Table Name and Field Name
        <div>OR</div>
        Key Type and Key Value
        <div>
          <br />{" "}
        </div>
        You may enter more than minimuum required criteria, and Date and Time
        values may be added to provide a more detailed search
      </p>
    </div>
  );
  const linkName = readMore ? "View Less  " : "View More ";

  return (
    <div className="pos-relative">
      <div className="tabs-container mt-2">
        <div className="tab-holder container-space pt-2">
          <div className="mt-2">
            Note : Due to the Volume of audit history available, this search
            require a minimum of the following criteria.
            <Button
              variant="outlined"
              color="primary"
              id="showMore"
              data-testid = "showMore"
              className="btn view-more-btn btn-underln p-0"
              onClick={() => {
                setReadMore(!readMore);
              }}
            >
              <span data-test="linkname">{linkName}</span>
            </Button>
            {readMore && extraContent}
          </div>
          <div className="tab-holder">
            <div className="tab-header">
              <h6 className="tab-heading float-left pb-0">User / Job</h6>
              <div className="clearfix"></div>
            </div>
            <div className="tab-body-bordered">
              <div className="form-wrapper">
                <div className="mui-custom-form input-md" data-test="userID">
                  <TextField
                    id="userID"
                    label="User ID / Job ID"
                    value={
                      props.values.userID !== null ? props.values.userID : ""
                    }
                    onChange={props.handleChanges("userID")}
                    inputProps={{ maxLength: 30 }}
                    placeholder=""
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className="mui-custom-form input-md" data-test="userName">
                  <TextField
                    id="user-last-name"
                    label="User Last Name"
                    value={
                      props.values.fullName !== null
                        ? props.values.fullName
                        : ""
                    }
                    onChange={props.handleChanges("lastName")}
                    inputProps={{ maxLength: 35 }}
                    placeholder=""
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="tab-holder mt-2">
            <div className="tab-header">
              <h6 className="tab-heading float-left pb-0">Date / Time </h6>
              <div className="clearfix"></div>
            </div>
            <div className="tab-body-bordered">
              <div className="form-wrapper">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div
                    className="mui-custom-form input-md with-select"
                    data-test="BeginDate"
                  >
                    <KeyboardDatePicker
                      id="begin_date"
                      data-testid="BeginDate"
                      label="Begin Date"
                      format="MM/dd/yyyy"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="MM/DD/YYYY"
                      value={props.values.beginDate}
                      onChange={handleBeginDateChange}
                      helperText={
                        props.errors.beginDateInvalidErr
                          ? ErrorConst.GLOBAL_DATE_INVALID
                          : null
                      }
                      error={
                        props.errors.beginDateInvalidErr
                          ? ErrorConst.GLOBAL_DATE_INVALID
                          : null
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div
                    className="mui-custom-form input-md with-select"
                    data-test="EndDate"
                  >
                    <KeyboardDatePicker
                      id="end_date"
                      data-testid="EndDate"
                      label="End Date"
                      format="MM/dd/yyyy"
                      InputLabelProps={{ shrink: true }}
                      placeholder="MM/DD/YYYY"
                      value={props.values.endDate}
                      onChange={handleEndDateChange}
                      helperText={
                        props.errors.endDateInvalidErr
                          ? ErrorConst.GLOBAL_DATE_INVALID
                          : props.errors.beginDtGtEndDtErr
                          ? ErrorConst.EVENT_GT_DT_ERROR
                          : null
                      }
                      error={
                        props.errors.endDateInvalidErr
                          ? ErrorConst.GLOBAL_DATE_INVALID
                          : props.errors.beginDtGtEndDtErr
                          ? ErrorConst.EVENT_GT_DT_ERROR
                          : null
                      }
                      KeyboardButtonProps={{ "aria-label": "change date" }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div
                    className="mui-custom-form input-md with-select"
                    data-test="beginTimess"
                  >
                    <TextField
                      id="beginTimess"
                      label="Begin Time"
                      format="HH:MM:SS"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        props.values.beginTime !== null
                          ? props.values.beginTime
                          : ""
                      }
                      onChange={props.handleChanges("beginTime")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />

                    <label
                      for="descriptionContainsId"
                      className="date-format"
                      htmlFor="descriptionContainsId"
                    >
                      HH:MM:SS PM
                    </label>
                  </div>
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div
                    className="mui-custom-form input-md with-select"
                    data-test="EndTimes"
                  >
                    <TextField
                      id="endTimes"
                      label="End Time"
                      format="HH:MM:SS"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      //placeholder="mm/dd/yyyy"
                      value={
                        props.values.endTime !== null
                          ? props.values.endTime
                          : ""
                      }
                      onChange={props.handleChanges("beginTime")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />

                    <label
                      for="descriptionContainsId"
                      className="date-format"
                      htmlFor="descriptionContainsId"
                    >
                      HH:MM:SS PM
                    </label>
                  </div>
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
          <div className="tab-holder mt-2">
            <div className="tab-header">
              <h6 className="tab-heading float-left pb-0">Data / Key</h6>
              <div className="clearfix"></div>
            </div>
            <div className="tab-body-bordered">
              <div className="form-wrapper">
                <div
                  className="mui-custom-form input-md"
                  data-test="TableNames"
                >
                  <TextField
                    id="table-name"
                    label="Table Name"
                    value={
                      props.values.tableName !== null
                        ? props.values.tableName
                        : ""
                    }
                    onChange={props.handleChanges("tableName")}
                    inputProps={{ maxLength: 30 }}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="mui-custom-form input-md">
                  <TextField
                    id="field-name"
                    label="Field Name"
                    value={
                      props.values.fieldName !== null ? props.values.fieldName : ""
                    }
                    onChange={props.handleChanges("fieldName")}
                    inputProps={{ maxLength: 30 }}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="mui-custom-form input-md" data-test="KeyType">
                  <TextField
                    id="KeyType"
                    label="Key Type"
                    value={
                      props.values.keyType !== null
                        ? props.values.keyType
                        : ""
                    }
                    onChange={props.handleChanges("keyType")}
                    inputProps={{ maxLength: 30 }}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="mui-custom-form input-md" data-test="KeyValue">
                  <TextField
                    id="KeyValue"
                    label="Key Value"
                    value={props.values.keyValue !== null ? props.values.keyValue : ""}
                    onChange={props.handleChanges("keyValue")}
                    inputProps={{ maxLength: 30 }}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="page-footer mt-4">
            <div className="float-right th-btnGroup">
              <Button
                data-test="SearchBtn"
                title="Search"
                variant="outlined"
                color="primary"
                className="btn btn-ic btn-search"
                onClick={props.searchCheck}
              >
                {" "}
                Search{" "}
              </Button>
              <Button
                data-test="resetTable"
                title="Reset"
                variant="outlined"
                color="primary"
                className="btn btn-ic btn-reset"
                onClick={props.resetTable}
              >
                {" "}
                Reset{" "}
              </Button>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
