/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';

import { withRouter } from 'react-router';

import TableComponent from '../../../../SharedModules/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { systemParameterRowClickAction } from '../../Store/Actions/TextManagement/TextManagementActions';
import { DEFAULT_LOB_VALUE } from '../../../../SharedModules/AppConstants';

const headCellsMultiRecord = [
  {
    id: 'eombFromCode',
    disablePadding: false,
    label: 'From Code',
    isVarchar: true
  },
  {
    id: 'eombThruCode',
    disablePadding: false,
    label: 'Thru Code',
    isVarchar: true
  },
  { id: 'eombDesc', disablePadding: false, label: 'Text', isText: true }
];
const headCellsSingleRecord = [
  { id: 'eombFromCode', disablePadding: false, label: 'From Code' },
  { id: 'eombThruCode', disablePadding: false, label: 'Thru Code' },
  { id: 'eombDesc', disablePadding: false, label: 'Text', isText: true }
];

function SPResult (props) {
  const [redirect, setRedirect] = React.useState(false);
  const dispatch = useDispatch();
  const onRowClick = values => dispatch(systemParameterRowClickAction(values));
  const payloadViewData = useSelector(
    state => state.appConfigState.systemParameterState.rowSearchSysParam
  );
  const payloadRecordData = payloadViewData || (props.tableData.length === 1 ? props.tableData : null);
  const payloadData = Array.isArray(payloadRecordData) ? payloadRecordData[0] : payloadRecordData;

  if (redirect === 1) {
    if (payloadData !== undefined && payloadData !== null) {
      props.history.push({
        pathname: '/TextUpdate',
        state: {
          row: payloadData,
          TextType: 'EOMB',
          EombType: 'surgical-procedure'
        }
      });
    }
  }

  const formatSearchCriteria = (row) => {
    return ({
      eombProcedureTypeCode: 'I',
      lobCode: [DEFAULT_LOB_VALUE],
      eombFromCode: row.eombFromCode,
      eombthruCode: row.eombTromCode,
      eombText: row.eombDesc,
      eombSequenceNumber: row.eombSequenceNumber
    });
  };
  const editRow = row => event => {
    const searchCriteria = {
      eombProcedureTypeCode: 'I',
      lobCode: [DEFAULT_LOB_VALUE],
      eombFromCode: row.eombFromCode,
      eombthruCode: row.eombTromCode,
      eombText: row.eombDesc,
      eombSequenceNumber: row.eombSequenceNumber
    };
    onRowClick(searchCriteria);
    let valuetoredirect = 0;
    valuetoredirect = valuetoredirect + 1;
    setRedirect(valuetoredirect);
  };

  const headCells = props.tableData.length === 1 ? headCellsSingleRecord : headCellsMultiRecord;

  return (
    <TableComponent
      isSearch={true}
      headCells={headCells}
      pathTo='/TextUpdate?data='
      formatSearchCriteria={formatSearchCriteria}
      tableData={props.tableData ? props.tableData : []}
      onTableRowClick={editRow}
      defaultSortColumn={headCells[0].id}
    />
  );
}
export default withRouter(SPResult);
