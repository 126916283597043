/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './TextManagementActionTypes';
import * as serviceEndPoint from '../../../services/service';
import { commonAPIHandler } from '../../../../../SharedModules/ServerErrorHandler';
import axios from 'axios';

export const searchSystemParameterAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse === undefined) {
          dispatch(setSystemParameter([]));
        } else {
          dispatch(setSystemParameter(sendingResponse));
        }
      })
      .catch(error => {
        
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSystemParameter(sendingResponse));
      });
  };
};

export const searchSystemParameterActionRAEOB = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_RAEOB_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(setSystemParameter(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(setSystemParameter([]));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSystemParameter(sendingResponse));
      });
  };
};

export const searchSystemParameterActionPN = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_PN_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(setSystemParameter(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(setSystemParameter([]));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSystemParameter(sendingResponse));
      });
  };
};

export const searchSystemParameterActionSP = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_SP_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse === undefined) {
          dispatch(setSystemParameter([]));
        } else {
          dispatch(setSystemParameter(sendingResponse));
        }
      })
      .catch(error => {
        
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSystemParameter(sendingResponse));
      });
  };
};

export const searchSystemParameterActionRevenue = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_REVENUE_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse === undefined) {
          dispatch(setSystemParameter([]));
        } else {
          dispatch(setSystemParameter(sendingResponse));
        }
      })
      .catch(error => {
        
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSystemParameter(sendingResponse));
      });
  };
};

export const systemParameterRowClickAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse === undefined) {
          dispatch(setSystemParameter([]));
        } else {
          dispatch(setSystemParameter(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSystemParameter(sendingResponse));
      });
  };
};

export const providerNoticeRowClickAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_PN_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(setSystemParameterPNRowData(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(setSystemParameterPNRowData([]));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSystemParameterPNRowData(sendingResponse));
      });
  };
};

export const RAEOBRowClickAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_RAEOB_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse === undefined) {
          dispatch(setSystemParameter([]));
        } else {
          dispatch(setSystemParameter(sendingResponse));
        }
      })
      .catch(error => {
        
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSystemParameter(sendingResponse));
      });
  };
};

export const searchSystemParameterActionRC = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_RC_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(setSystemParameterRC(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(setSystemParameterRC([]));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSystemParameterRC(sendingResponse));
      });
  };
};

export const searchSystemParameterActionARC = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_ARC_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(setSystemParameterARC(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(setSystemParameterARC([]));
        }

      })
      .catch(error => {
        
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSystemParameterARC(sendingResponse));
      });
  };
};

export const searchSystemParameterActionNCPDP = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_NCPDP_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(setSystemParameterNCPDP(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(setSystemParameterNCPDP([]));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSystemParameterNCPDP(sendingResponse));
        
      });
  };
};

export const NCPDPRowClickAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_NCPDP_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(setSystemParameterNCPDPRow(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(setSystemParameterNCPDPRow([]));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSystemParameterNCPDPRow(sendingResponse));
        
      });
  };
};

export const ARRowClickAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_ARC_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(setSystemParameterARRow(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(setSystemParameterARRow([]));
        }
      })
      .catch(error => {
        
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSystemParameterARRow(sendingResponse));
      });
  };
};

export const RCRowClickAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_RC_ENDPOINT}`, value)
      .then(response => {
        let data = [];
        data = response.data.searchResults;

        dispatch(setSystemParameterRCRow(data));
        if (response.data.searchResults === undefined) {
          dispatch(setSystemParameterRCRow({}));
        }
      })
      .catch(error => {
        
        dispatch(setSystemParameterRCRow({ systemError: true }));
      });
  };
};

export const resetSearchTextManagement = () => {
  return {
    type: actionTypes.RESETDATA,
    resetData: []
  };
};

export const setSystemParameter = (eombData) => {
  return {
    type: actionTypes.SEARCH_TEXT_MANAGEMENT,
    eombData: eombData
  };
};

export const setSystemParameterRAEOB = (RAEOBData) => {
  return {
    type: actionTypes.SEARCH_TEXT_RAEOB,
    RAEOBData: RAEOBData
  };
};

export const setSystemParameterPN = (PNData) => {
  return {
    type: actionTypes.SEARCH_TEXT_PN,
    PNData: PNData
  };
};

export const setSystemParameterSP = (SPData) => {
  return {
    type: actionTypes.SEARCH_TEXT_SP,
    SPData: SPData
  };
};

export const setSystemParameterRevenue = (RevenueData) => {
  return {
    type: actionTypes.SEARCH_TEXT_REVENUE,
    RevenueData: RevenueData
  };
};

export const setSystemParameterPNRowData = (PNRowData) => {
  return {
    type: actionTypes.SEARCH_TEXT_PNROW,
    PNRowData: PNRowData
  };
};

export const setSystemParameterRAEOBRowData = (RAEOBRowData) => {
  return {
    type: actionTypes.SEARCH_TEXT_RAEOBROW,
    RAEOBRowData: RAEOBRowData
  };
};

export const setSystemParameterRC = (RCData) => {
  return {
    type: actionTypes.SEARCH_TEXT_RC,
    RCData: RCData
  };
};

export const setSystemParameterARC = (ARCData) => {
  return {
    type: actionTypes.SEARCH_TEXT_ARC,
    ARCData: ARCData
  };
};

export const setSystemParameterNCPDP = (NCPDPData) => {
  return {
    type: actionTypes.SEARCH_TEXT_NCPDP,
    NCPDPData: NCPDPData
  };
};

export const setSystemParameterNCPDPRow = (NCPDPRowData) => {
  return {
    type: actionTypes.SEARCH_TEXT_NCPDPROW,
    NCPDPRowData: NCPDPRowData
  };
};

export const setSystemParameterARRow = (ARRowData) => {
  return {
    type: actionTypes.SEARCH_TEXT_ARROW,
    ARRowData: ARRowData
  };
};

export const ResetTextSearchReducer = () => {
  return {
    type: actionTypes.RESET_TEXT_SEARCH
  };
};
export const setSystemParameterRCRow = (RCRowData) => {
  return {
    type: actionTypes.SEARCH_TEXT_RCROW,
    RCRowData: RCRowData
  };
};

export const ProviderSpecialtyDataSearchAction = (ProviderCode) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.PROVIDER_SPECIALTY_ENDPOINT}${ProviderCode}`)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(ProviderSpecialtyData(sendingResponse.providerSpecialityList));
        if (sendingResponse === undefined) {
          dispatch(ProviderSpecialtyData([]));
        }
      })
      .catch(error => {
        
        dispatch(ProviderSpecialtyData({ systemError: true }));
      });
  };
};

export const ProviderSpecialtyDataAction = (ProviderCode) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.PROVIDER_SPECIALTY_ENDPOINT}${ProviderCode.code}`)
      .then(response => {
        dispatch(ProviderSpecialtyData(response.data.providerSpecialityList));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(ProviderSpecialtyData(sendingResponse));
      });
  };
};
export const ProviderSpecialtyData = (ProviderSpecialtyData) => {
  return {
    type: actionTypes.PROVIDER_SPECIALTY_DATA,
    ProviderSpecialtyData: ProviderSpecialtyData
  };
};

