/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import TableComponent from "../../../../../../../SharedModules/Table/Table";

export default function lienHolderPayoutTable(props) {
  const headCells = [
    {
      id: "fcn/tcn",
      numeric: false,
      disablePadding: true,
      label: "FCN/TCN",
      enableHyperLink: false,
      isDate: false,
      width: 120,
      fontSize: 12
    },
    {
      id: "reasonCode",
      numeric: false,
      disablePadding: false,
      label: "Reason Code",
      enableHyperLink: true,
      isDate: false,
      width: 120,
      fontSize: 12
    },
    {
      id: "paidDate",
      numeric: false,
      disablePadding: false,
      label: "Paid Date",
      enableHyperLink: false,
      isDate: true,
      width: 120,
      fontSize: 12
    },
    {
      id: "paymentAmount",
      numeric: false,
      disablePadding: false,
      label: "Payment Amount",
      enableHyperLink: false,
      isDate: true,
      width: 120,
      fontSize: 12
    }
  ];

  return (
    <div>
      <TableComponent
        print={props.print}
        headCells={headCells}
        tableData={[]}
      />
    </div>
  );
}
