import * as actionTypes from './actionTypes';

const axiosPayLoad = {
  appdropdowns:[],
  networkIdDropdown:[],
  mapIDDropdown:[],
  nonvvdropdowns:[],
  benefitDropDowns:[],
  vvdropdowns:[],
  dispositionDropDown:[],
  voidRscdDropdown:[],
};
const reducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.GET_APP_DROPDOWNS:
      return { ...state, appdropdowns: action.dropdowns};
    case actionTypes.GET_VALIDVALUE_DROPDOWNS:
        return { ...state, vvdropdowns: action.dropdownsVV};  
    case actionTypes.GET_VOID_RSCD:
        return { ...state, voidRscdDropdown: action.dropdowns};  
default: return action.type&&action.dynamicType?{...state,[action.type]:action.dynamicType}:state;
  }
};

export default reducer;
