/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import axios from "axios";
import * as actionTypes from "./DataUploadActionTypes";
import * as serviceEndPoint from "../../../../SharedModules/services/service";
import { commonAPIHandler } from "../../../../SharedModules/ServerErrorHandler/index";

export const getDropdownDataAction = value => {
  return dispatch => {
    return axios
      .post(`${serviceEndPoint.DROPDOWNS}`, value)
      .then(response => {
        dispatch(setDropdownData(response.data));
      })
      .catch(error => {
        dispatch(setDropdownData({ systemFailure: true }));
      });
  };
};

export const setDropdownData = dropdownData => {
  return {
    type: actionTypes.DROPDOWNDATA,
    dropdownData: dropdownData
  };
};

export const searchDataUpload = value => {
  return dispatch => {
    dispatch(resetSearchRecords())
    return axios
      .post(`${serviceEndPoint.SEARCH_FINANCE_UPLOAD}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse === null) {
          dispatch(setSearchRecords([]));
        } else {
          dispatch(setSearchRecords(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSearchRecords(sendingResponse));
      });
  };
};

export const setSearchRecords = (data,value) => {
  return {
    type: actionTypes.SEARCH_ACTION,
    searchData: data,
    searhCriteria:value
  };
};

export const resetSearchRecords = () => {
  return {
    type: actionTypes.RESET_SEARCH_ACTION
  };
};


export const getActivityTypeDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.VALID_VALUE_DROPDOWNS}`, value)
      .then(response => {
        dispatch(setActivityType(response.data));
      })
      .catch(error => {
        dispatch(setActivityType(error));
      });
  };
};

export const setActivityType = (bankingDropdown) => {
  return {
    type: actionTypes.ACTIVITY_DROPDOWN_ACTION,
    activityDropDown: bankingDropdown
  };
};

export const getUniqueDetailsFileUpload = (value,check) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.SEARCH_UNQ_FINANCE_UPLOAD}`+ value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
          dispatch(setFileUploadData(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setFileUploadData(sendingResponse));
      });
  };
};

export const setFileUploadData = (data) => {
  return {
    type: actionTypes.UNIQUE_DETAILS,
    uniqueData: data
  };
};


export const getUniqueUserIds = () => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.UPLOADED_USER_DROPDOWN}`)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
          dispatch(setUserIds(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setUserIds(sendingResponse));
      });
  };
};

export const setUserIds = (data) => {
  return {
    type: actionTypes.UNIQUE_DETAILS_USER_ID,
    uniqueDataUsers: data
  };
};


export const financeUploadAPI = (value, file) => {
  const formInfo = new FormData();
  if (file) {
      formInfo.append('file', file);
  }
  formInfo.append('ifaceVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.UPLOAD_FINANCE_TXN}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(saveUpload(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(saveUpload(sendingResponse));
      });
  };
};

export const saveUpload = (data) => {
  return {
    type: actionTypes.UPLOAD_TXN,
    saveResponse: data
  };
};