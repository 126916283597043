/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as serviceEndPoint from '../../../../SharedModules/services/service';
import axios from 'axios';
import * as actionTypes from './WriteOffActionType';
import { commonAPIHandler } from '../../../../SharedModules/ServerErrorHandler/index';

export const getDropdownDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.DROPDOWNS}`, value)
      .then(response => {
        dispatch(setDropdownData(response.data));
      })
      .catch(error => {
        dispatch(setDropdownData(error));
      });
  };
};

export const setDropdownData = (dropdownData) => {
  return {
    type: actionTypes.DROPDOWNDATA,
    dropdownData: dropdownData
  };
};

export const saveWriteOff = (value, file) => {
  const formInfo = new FormData();
  if (file && file.length > 0) {
    file.map((values) => {
      formInfo.append('file', values);
    })
  }
  formInfo.append('accountsReceivableVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.SAVE_WRITE_OFF}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setWriteOffSave([]));
        } else {
          dispatch(setWriteOffSave(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setWriteOffSave(sendingResponse));
      });
  };
};

export const setWriteOffSave = (saveWriteOffData) => {
  return {
    type: actionTypes.WRITE_OFF_SAVE,
    saveWriteOffData: saveWriteOffData
  };
};

export const saveWriteOffRestablishment = (value, file) => {
  const formInfo = new FormData();
  if (file && file.length > 0) {
    file.map((values) => {
      formInfo.append('file', values);
    })
  }
  formInfo.append('accountsReceivableVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.SAVE_WRITE_OFF_RESTABLISHMENT}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setWriteOffRestablishmentSave([]));
        } else {
          dispatch(setWriteOffRestablishmentSave(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setWriteOffRestablishmentSave(sendingResponse));
      });
  };
};

export const setWriteOffRestablishmentSave = (saveWriteOffRestablishmentData) => {
  return {
    type: actionTypes.WRITE_OFF_RESTABLISHMENT_SAVE,
    saveWriteOffRestablishmentData: saveWriteOffRestablishmentData
  };
};

export const getPayeeIdType = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.PAYEE_TYPE_ID_DROPDOWN_ENDPOINT}?payeeTypeCode=${value}`)
      .then(response => {
        let sendingResponse = commonAPIHandler(response)
        if (response) {

        }
        dispatch(setPayeeIdTypeDropdown(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setPayeeIdTypeDropdown(sendingResponse));
      });
  };
};

export const setPayeeIdTypeDropdown = (data) => {
  return {
    type: actionTypes.PAYEE_ID_TYPE_DROPDOWN,
    payeeIdTypeDropdown: data
  };
};

export const agingDaysList = () => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.AR_AGING_DAYS}`)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (response) {

        }
        dispatch(setAgingDays(sendingResponse));
      })
      .catch(error => {
        dispatch(setAgingDays([]));
      });
  };
};

export const setAgingDays = (agingDaysListData) => {
  return {
    type: actionTypes.AR_AGING_DAYS,
    agingDaysListData: agingDaysListData
  };
};

export const validatePayeeID = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.ARTRANSFER_VALIDATE}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setValidatePayeeID([]));
        } else {
          dispatch(setValidatePayeeID(sendingResponse));
        }
      })
      .catch( error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setValidatePayeeID(sendingResponse));
      });
  };
};
export const setValidatePayeeID = (data) => {
  return {
    type: actionTypes.PAYEE_ID_VALIDATE,
    validatePayeeIDvalues: data
  };
};
