/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './BankAccountSearchActionTypes';
import * as serviceEndPoint from '../../../../SharedModules/services/service';
import { commonAPIHandler } from '../../../../SharedModules/ServerErrorHandler/index';
import axios from 'axios';

const headers = {
  'Access-Control-Allow-Origin': '*'
};


export const getAuditLogDetailsAction = (tableName, keyValue, auditData) => {
  let body = {
    "tableName": tableName,
    "keyValue": keyValue
  }
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}

export const setAuditLogData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE,
    payload: data,
    keyName
  };
};

export const getAuditLogLockBoxDetailsAction = (body, auditData) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_MULTIPLE_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogLockBoxData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}

export const setAuditLogLockBoxData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE_LOCK_BOX,
    payload: data,
    keyName
  };
};
export const getAuditLogAddressDetailsAction = (body, auditData) => {

  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_MULTIPLE_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogAddressData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}

export const setAuditLogAddressData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE_ADDRESS,
    payload: data,
    keyName
  };
};
export const getAuditLogContactDetailsAction = (body, auditData) => {
  
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_MULTIPLE_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogContactData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}

export const setAuditLogContactData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE_CONTACT,
    payload: data,
    keyName
  };
};



export const getDropdownDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.DROPDOWNS}`, value)
      .then(response => {
        dispatch(setDropdownData(response.data));
      })
      .catch(error => {
        dispatch(setDropdownData({ systemFailure: true }));
      });
  };
};

export const setDropdownData = (dropdownData) => {
  return {
    type: actionTypes.BANKACCOUNT_LOCKBOX_GETREFERENCEDATA,
    dropdownData: dropdownData
  };
};

export const getDropdownTypeDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.VALID_VALUE_DROPDOWNS}`, value)
      .then(response => {
        dispatch(setTypeDropdownData(response.data));
      })
      .catch(error => {
        dispatch(setTypeDropdownData(error));
      });
  };
};

export const setTypeDropdownData = (typeDropdownData) => {
  return {
    type: actionTypes.BANKACCOUNT_TYPE_GETREFERENCEDATA,
    typeDropdownData: typeDropdownData
  };
};

export const searchLockBoxDetailsAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.BANKACCOUNT_LOCKBOX_SEARCH_ENDPOINT}`, value, { headers: headers })
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(setLockBoxDetails(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setLockBoxDetails(sendingResponse));
      });
  };
};

export const setLockBoxDetails = (lockBoxData) => {
  return {
    type: actionTypes.SEARCH_BANKACCOUNT_AND_LOCKBOX,
    lockBoxData: lockBoxData
  };
};

export const addBankAccountAndLockBox = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.ADD_BANK_ACCOUNT_AND_LOCKBOX}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setAddBankAccountAndLockBox(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setAddBankAccountAndLockBox(sendingResponse));
      });
  };
};

export const setAddBankAccountAndLockBox = (data) => {
  return {
    type: actionTypes.ADD_BANK_ACCOUNT_AND_LOCKBOX,
    addBankAccountAndLockBox: data
  };
};

export const updateBankAccountAndLockBox = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.UPDATE_BANK_ACCOUNT_AND_LOCKBOX}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setUpdateBankAccountAndLockBox(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setUpdateBankAccountAndLockBox(sendingResponse));
      });
  };
};

export const setUpdateBankAccountAndLockBox = (updateBankAccountAndLockBox) => {
  return {

    type: actionTypes.UPDATE_BANK_ACCOUNT_AND_LOCKBOX,
    updateBankAccountAndLockBox: updateBankAccountAndLockBox
  };
};

export const resetBankAccountAndLockBox = () => {
  return {
    type: actionTypes.RESETDATA,
    resetData: []
  };
};
