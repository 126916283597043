/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import numeral from 'numeral';
import * as DateUtils from '../../../../../../../SharedModules/DateUtilities/DateUtilities';
import Radio from '@material-ui/core/Radio';
import * as commonMethods from '../../../../../../../SharedModules/CommonUtilities/commonUtilities';
import { getLongDescription } from '../../../../../../../SharedModules/CommonUtilities/commonUtilities';
import NativeDropDown from '../../../../../../../SharedModules/Dropdowns/NativeDropDown';
import * as AppConstants from "../../../../../../../SharedModules/AppConstants";

export default function LienLevyDetails (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const disableIfF16007 = commonMethods.checkValueExists(props.dropDownCheckList, props.values.reasonCode);
  return (
    <div>
      <form autoComplete="off">
        <div className="form-wrapper">
          <div className="mui-custom-form  input-md override-m-1">
            <TextField
              id="standard-fcn"
              fullWidth
              label="FCN"
              disabled
              placeholder=""
              inputProps={{ maxlength: 14 }}
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.fcn}
            ></TextField>
          </div>
          <div className="mui-custom-form input-md override-m-1">
            <TextField
              required
              fullWidth
              id="LienLevyAddDetailsOriginalA/R"
              label="Original A/R Amount"
              placeholder=""
              inputProps={{ maxlength: 12 }}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
              onInput={e => {
                e.target.value = e.target.value.replace(
                  /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                  ''
                );
              }}
              value={props.values.originalARAmount}
              onChange={props.handleChangeForDecimalValues('originalARAmount')}
              helperText={props.errors[0] ? props.errorText[0] : null}
              error={props.errors[0] ? props.errorText[0] : null}
              disabled={disableIfF16007}
            ></TextField>
          </div>
          <div className="mui-custom-form input-md override-m-1">
            <TextField
              id="standard-adjusted-aramount"
              fullWidth
              label="Adjusted A/R Amount"
              placeholder=""
              inputProps={{ maxlength: 11 }}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' }}
              value={numeral(props.values.adjustedARAmount).format('0,0.00')}
            ></TextField>
          </div>
          <div className="mui-custom-form  input-md override-m-1">
            <TextField
              id="standard-balance-amount"
              fullWidth
              label="Balance Amount"
              placeholder=""
              inputProps={{ maxlength: 11 }}
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' }}
              InputLabelProps={{
                shrink: true
              }}
              value={numeral(props.values.balanceAmount).format('0,0.00')}
            ></TextField>
          </div>
          <div
            className="mui-custom-form override-m-1 override-width-10"
            style={{ lineHeight: '100%' }}
          >
            <label className="MuiFormLabel-root small-label">Approval</label>
            <div className="sub-radio no-margin">
              <Radio
                value="Y"
                id="LienLevyAddDetailapprovalIdYes"
                checked={props.values.approval === 'Y'}
                onChange={props.handleChange('approval')}
              />
              <label
                className="text-black"
                htmlFor="LienLevyAddDetailapprovalIdYes"
              >
                Yes
              </label>
              <Radio
                value="N"
                id="LienLevyAddDetailapprovalIdNo"
                checked={props.values.approval === 'N'}
                onChange={props.handleChange('approval')}
                className="ml-2"
              />
              <label className="text-black" htmlFor="LienLevyAddDetailapprovalIdNo">
                No
              </label>
            </div>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form input-md override-m-1">
            <TextField
              id="standard-related-ft-tcn"
              fullWidth
              label="Related FT TCN"
              disabled
              placeholder=""
              inputProps={{ maxlength: 17 }}
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.relatedFTTCN}
              InputProps={{
                readOnly: true, className: 'Mui-disabled'
              }}
            ></TextField>
          </div>
          <div className="mui-custom-form  input-md override-m-1">
            <TextField
              id="standard-established-date"
              fullWidth
              label="Established Date"
              disabled
              placeholder=""
              inputProps={{ maxlength: 10 }}
              InputLabelProps={{
                shrink: true
              }}
              value={DateUtils.getDateInMMDDYYYYFormat(new Date())}
            ></TextField>
          </div>
          <div className="mui-custom-form  input-md override-m-1">
            <TextField
              id="standard-lastdate-applied"
              fullWidth
              label="Last Date Applied"
              disabled
              placeholder=""
              inputProps={{ maxlength: 10 }}
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.lastDateApplied}
            ></TextField>
          </div>
          <div className="mui-custom-form  input-md override-m-1">
            <TextField
              id="standard-arage"
              fullWidth
              label="A/R Age in Days"
              disabled
              value={props.values.arAgeInDays}
              placeholder=""
              inputProps={{ maxlength: 3 }}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>
          <div className="mui-custom-form  input-sm override-m-1 override-width-10">
            <TextField
              id="standard-arlettersent"
              fullWidth
              label="A/R Letter Sent "
              disabled
              placeholder=""
              inputProps={{ maxlength: 3 }}
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.arLettersent}
            ></TextField>
          </div>
        </div>
        <div className="form-wrapper">
          <NativeDropDown
            className='override-m-1'
            isRequired
            id="LienLevyAddDetailsLienHolder"
            label="Lien Holder Type"
            value={props.values.lienHolderType}
            onChange={props.handleChange('lienHolderType')}
            inputProps={{ maxlength: 3 }}
            errTxt={props.errors[13] ? props.errorText[13] : null}
            dropdownList={props.lienHolderTypeData
              ? props.lienHolderTypeData.map((option, index) => (
                <option key={index} value={option.code}>
                  {option.description}
                </option>
              ))
              : null}
          />
          <NativeDropDown
            className='override-m-1'

            isRequired
            id="LienLevyAddDetailsHolderIDType"
            label="Lien Holder ID Type Code"
            value={props.values.lienHolderIdType}
            disabled={props.values.lienHolderType === DEFAULT_DD_VALUE}
            onChange={props.handleChange('lienHolderIdType')}
            inputProps={{ maxlength: 3 }}
            errTxt={props.errors[14] ? props.errorText[14] : null}
            dropdownList={props.lienHolderIdTypeData
              ? props.lienHolderIdTypeData.map((option, index) => (
                <option key={index} value={option.code}>
                  {option.description}
                </option>
              ))
              : null}
          />
          <div className="mui-custom-form  input-md override-m-1">
            <TextField
              id="standard-lien-holder-id"
              fullWidth
              required
              label="Lien Holder ID"
              inputRef={props.inputRefCursor}
              placeholder=""
              inputProps={{ maxlength: 17 }}
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.lienHolderId}
              onChange={props.handleChange('lienHolderId')}
              onBlur={props.validateLienHolderSystemDetails}
              helperText={props.errors[15] ? props.errorText[15] : null}
              error={props.errors[15] ? props.errorText[15] : null}
            ></TextField>
          </div>
          <div className="mui-custom-form  input-md override-m-1">
            <TextField
              id="standard-lien-holder-name"
              fullWidth
              label="Lien Holder Name"
              disabled
              placeholder=""
              inputProps={{ maxlength: 10 }}
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.lienHolderName}
              onChange={props.handleChange('lienHolderName')}
            ></TextField>
          </div>
          <NativeDropDown
            className='override-m-1'
            id="payeeStatus"
            label="Payee Status"
            value={props.values.payeeStatus || DEFAULT_DD_VALUE}
            onChange={props.handleChange('payeeStatus')}
            title={getLongDescription(props.payeePaymentStatusFrmState, props.values.payeeStatus)}
            dropdownList={props.payeePaymentStatusFrmState
              ? props.payeePaymentStatusFrmState.map((item, index) => (
                <option key={index} value={item.code}>
                  {item.description}
                </option>
              ))
              : null}
          />
          <div className="mui-custom-form input-md override-m-1">
            <TextField
              id='ATRnumber'
              fullWidth
              label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
              placeholder=""
              value={props.values.atrNumber}
              onChange={props.handleChange('atrNumber')}
              inputProps={{ maxlength: 18 }}
              InputLabelProps={{
                shrink: true
              }}
              helperText={props.errors[16] ? props.errorText[16] : null}
              error={props.errors[16] ? props.errorText[16] : null}
            ></TextField>
          </div>
        </div>
        <div className="tab-holder">
          <div className="row mb-2">
            <div className="col-5" style={{ maxWidth: '398px' }}>
              <fieldset className="custom-fieldset">
                <legend>Account:</legend>
                <div className="form-wrapper vertical-wrapper">
                  <div className="mui-custom-form input-md min-md">
                    <TextField
                      required
                      fullWidth
                      id="standard-fiscal-year"
                      label="Fiscal Year"
                      placeholder=""
                      inputProps={{ maxlength: 4 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      type="number"
                      value={props.values.fiscalYear}
                      onChange={props.handleChange('fiscalYear')}
                      helperText={props.errors[1] ? props.errorText[1] : null}
                      error={props.errors[1] ? props.errorText[1] : null}
                      onInput={e => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 4);
                      }}
                      onKeyDown={evt => evt.key === 'e' && evt.preventDefault()}
                    ></TextField>
                  </div>
                  <NativeDropDown
                    className='min-md'
                    id="categoryOfService"
                    label="Category of Service"
                    value={props.values.categoryOfService}
                    onChange={props.handleChange('categoryOfService')}
                    inputProps={{ maxlength: 3 }}
                    title={getLongDescription(props.values.categoryOfServiceData, props.values.categoryOfService)}
                    dropdownList={props.values.categoryOfServiceData.map(
                      (option, index) => (
                        <option key={index} value={option.code}>
                          {option.description}
                        </option>
                      )
                    )}
                  />
                  <NativeDropDown
                    className='min-md'
                    isRequired
                    id="fundCode"
                    label="Fund Code"
                    value={props.values.collocationCode}
                    onChange={props.handleChange('collocationCode')}
                    inputProps={{ maxlength: 10 }}
                    errTxt={props.errors[2] ? props.errorText[2] : null}
                    title={getLongDescription(props.values.collocationCodeData, props.values.collocationCode)}
                    dropdownList={props.values.collocationCodeData.map((option, index) => (
                      <option key={index} value={option.code}>
                        {option.description}
                      </option>
                    ))}
                  />
                </div>
              </fieldset>
            </div>
            <div className="col-7" style={{ paddingLeft: '62px' }}>
              <fieldset className="custom-fieldset">
                <legend>Recoupment:</legend>
                <div className="form-wrapper">
                  <NativeDropDown
                    isRequired={props.values.underAppeal === 'N'}
                    className='min-md'
                    id="LienLevyAddDetailsRecoupmentType"
                    label="Recoupment Type Code"
                    value={props.values.recoupmentTypeCode}
                    onChange={props.handleChange('recoupmentTypeCode')}
                    inputProps={{ maxlength: 1, "data-testid": "test_recoupment" }}
                    errTxt={props.errors[3] ? props.errorText[3] : null}
                    dropdownList={props.values.recoupmentData.map((option, index) => (
                      <option key={index} value={option.code}>
                        {option.description}
                      </option>
                    ))}
                  />
                  <div className="mui-custom-form  input-md with-icon with-icon-margin min-md">
                    <TextField
                      required={props.values.recoupmentTypeCode === 'A'}
                      fullWidth
                      id="standard-installment-value"
                      label="Installment Value"
                      placeholder=""
                      inputProps={{ maxlength: 14 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        )
                      }}
                      value={props.values.installmentValue}
                      onChange={props.handleChangeForDecimalValues('installmentValue')}
                      disabled={
                        props.values.underAppeal === 'Y' ||
                        props.values.recoupmentTypeCode === 'S' ||
                        (disableIfF16007 &&
                          props.values.recoupmentTypeCode === 'A')
                      }
                      helperText={props.errors[10] ? props.errorText[10] : null}
                      error={props.errors[10] ? props.errorText[10] : null}
                      onInput={e => {
                        e.target.value = e.target.value.replace(
                          /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                          ''
                        );
                      }}
                    ></TextField>
                  </div>
                </div>
                <div className="form-wrapper">
                  <div className="mui-custom-form  input-md with-icon with-icon-margin min-md">
                    <TextField
                      id="standard-percent-value"
                      fullWidth
                      required={props.values.recoupmentTypeCode === 'B' || props.values.recoupmentTypeCode === 'D'}
                      label="Percent Value"
                      placeholder=""
                      inputProps={{ maxlength: 14 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        )
                      }}
                      onInput={e => {
                        e.target.value = e.target.value.replace(
                          /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                          ''
                        );
                      }}
                      value={props.values.percentValue}
                      onBlur={props.formatPercent('percentValue')}
                      onChange={props.handleChange('percentValue')}
                      disabled={
                        (disableIfF16007 &&
                          props.values.recoupmentTypeCode === 'B') ||
                        props.values.recoupmentTypeCode === 'S'
                      }
                      helperText={props.errors[9] ? props.errorText[9] : null}
                      error={props.errors[9] ? props.errorText[9] : null}
                    ></TextField>
                  </div>
                  <div className="mui-custom-form min-md">
                    <TextField
                      fullWidth
                      id="standard-LienLevyMaxWithholdAmount"
                      label="Max Withhold Amount"
                      placeholder=""
                      inputProps={{ maxlength: 14 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        )
                      }}
                      onInput={e => {
                        e.target.value = e.target.value.replace(
                          /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                          ''
                        );
                      }}
                      value={props.values.maxWithholdAmount}
                      onBlur={props.formatDecimalField('maxWithholdAmount')}
                      onFocus={props.removeFormatDecimalFieldWHAmount('maxWithholdAmount')}
                      onChange={props.handleChange('maxWithholdAmount')}
                      disabled={true}
                    ></TextField>
                  </div>
                </div>
                <div className="form-wrapper v-align-end">
                  {<MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="mui-custom-form with-date min-md">
                      <KeyboardDatePicker
                        maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                        id="date-picker-dialog-startdate"
                        fullWidth
                        label="Start Date"
                        format="MM/dd/yyyy"
                        placeholder="mm/dd/yyyy"
                        minDate={new Date()}
                        value={props.selectedRecoupmentDate}
                        onChange={props.handleRecoupmentDateChange}
                        InputLabelProps={{
                          shrink: true
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        disabled={props.values.underAppeal === 'Y' || props.values.recoupmentTypeCode === 'S'}
                        helperText={props.errors[11] ? props.errorText[11] : null || props.errors[15] ? props.errors[15] : null}
                        error={props.errors[11] ? props.errorText[11] : null || props.errors[15] ? props.errors[15] : null}
                        required={props.values.recoupmentTypeCode === 'B' || props.values.recoupmentTypeCode === 'A' || props.values.recoupmentTypeCode === 'D'}
                      />
                    </div>
                  </MuiPickersUtilsProvider>}
                  <NativeDropDown
                    isRequired={props.values.recoupmentTypeCode === AppConstants.RECOUPMENTTYPECODE_PERCENTAMT || props.values.recoupmentTypeCode === AppConstants.RECOUPMENTTYPECODE_DOLLARAMT}
                    className='min-md mb-auto'
                    id="standard-frequency"
                    label="Frequency"
                    value={props.values.frequency}
                    onChange={props.handleChange('frequency')}
                    inputProps={{ maxlength: 2, "data-testid": "test_frequency" }}
                    disabled={
                      props.values.underAppeal === 'Y' ||
                      props.values.recoupmentTypeCode === 'S'
                    }
                    errTxt={props.errors[12] ? props.errorText[12] : null}
                    dropdownList={props.frequencyDataDropdown.map((option, index) => (
                      <option key={index} value={option.code}>
                        {option.description}
                      </option>
                    ))}
                  />
                  <div
                    className="mui-custom-form override-width-95"
                    style={{ width: '30%', whiteSpace: 'nowrap' }}
                  >
                    {!disableIfF16007 ? <a disabled onClick={props.defaultRecoupment} className="has-link" style={{ color: 'blue', cursor: 'pointer' }}>
                      Use Payee Default Recoupment
                    </a>
                      : <div className="mui-custom-form override-width-95 override-m-2">
                        <span>Use Payee Default Recoupment</span>
                      </div>}
                  </div>
                </div>
                <div className="form-wrapper">
                  {<MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="mui-custom-form with-date min-md">
                      <KeyboardDatePicker
                        disabled={true}
                        maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                        id="date-picker-dialog-paymentdate"
                        fullWidth
                        label="Initial Payment Date"
                        format="MM/dd/yyyy"
                        placeholder="mm/dd/yyyy"
                        minDate={new Date()}
                        value={props.selectedInitialPaymentDate}
                        onChange={props.handleInitialPaymentDate}
                        InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                    </div>
                  </MuiPickersUtilsProvider>}
                  {<MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="mui-custom-form with-date min-md">
                      <KeyboardDatePicker
                        disabled={true}
                        maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                        id="date-picker-dialog-satisfieddate"
                        fullWidth
                        label="Satisfied Date"
                        format="MM/dd/yyyy"
                        placeholder="mm/dd/yyyy"
                        minDate={new Date()}
                        value={props.selectedSatisfiedDate}
                        onChange={props.handleSatisfiedDate}
                        InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                    </div>
                  </MuiPickersUtilsProvider>}
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
