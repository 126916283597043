/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import { Prompt } from "react-router-dom";
import ErrorMessages from '../../../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../../../SharedModules/Errors/SuccessMessage';

import {
  DialogContent,
  DialogActions
} from '../../../../../SharedModules/Dialog/DialogUtilities';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import BulkReverseTable from './BulkReverseTable';
import {
  getDropdownDataAction,
  saveBulkReverse
} from '../../../Store/Actions/accountReceivable/accountReceivableActions';
import dropDownValues from './dropDownCriteria.json';
import {
  getUTCTimeStamp
} from '../../../../../SharedModules/DateUtilities/DateUtilities';
import * as constants from './ReverseConstants';
import { GET_APP_DROPDOWNS } from "../../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../../SharedModules/Dropdowns/dropdowns";

function BulkReverse (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const logInUserID = useSelector(state => state.sharedState.logInUserID);

  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [reasonCodeDropdown, setReasonCodeDropdown] = React.useState([]);
  const dispatch = useDispatch();
  const toPrintRef = useRef();

  const [tableData, setTableData] = React.useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);

  const handleTableReasonCode = row => event => {
    setFocusCheck(true);
    const value = event.target.value;
    const tableDataTemp = tableData;
    tableDataTemp.map(record => {
      if (record.fcn === row.fcn) {
        record.reasonCode = value;
      }
    });
    setTableData(tableDataTemp);
  };
  const DropDownAPI = dropdownValues => {
    dispatch(getDropdownDataAction(dropDownValues));
  };

  const saveBulkReverseAction = value => {
    dispatch(saveBulkReverse(value));
  };

  const dropDownDetails = useSelector(
    state => state.accountReceivableState.dropdownData
  );

  const saveBulkResult = useSelector(
    state => state.accountReceivableState.saveBulkViewReverse
  );

  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const dropdownData = useSelector(state => state.appDropDowns.appdropdowns);
  useEffect(() => {
    if (
      saveBulkResult &&
      !saveBulkResult.systemFailure &&
      saveBulkResult.errorMessages &&
      saveBulkResult.errorMessages.length > 0
    ) {
      const errorMessages = [];
      saveBulkResult.errorMessages.map(value => {
        const message = `${saveBulkResult.originalFCN}: ${value}`;
        errorMessages.push(message);
      });
      setErrorMessages(errorMessages);
    } else if (
      saveBulkResult &&
      !saveBulkResult.systemFailure &&
      saveBulkResult.respcode === '01'
    ) {
      const successMessage = [];
      const message = constants.SAVED_SUCCESS;
      successMessage.push(message);
      setSystemSuccesMessages(successMessage);

      setFocusCheck(false);
      const tempData = JSON.parse(JSON.stringify(tableData));
      tempData.map(value => {
        value.balanceAmount = '0.00';
      });
      setTableData(tempData);
    } else if (saveBulkResult && saveBulkResult.systemFailure) {
      const errorMessages = [];
      errorMessages.push(constants.GENERIC_SYSTEM_ERROR);
      setErrorMessages(errorMessages);
    }
  }, [saveBulkResult]);

  useEffect(() => {
    DropDownAPI(dropDownValues);
    onDropdowns([Dropdowns.F_RSN_CD]);
  }, []);
  useEffect(() => {
    if (dropDownDetails && props.location.state) {
      const tableValues = JSON.parse(
        JSON.stringify(props.location.state.selectedFcnObj)
      );
      if (dropDownDetails.listObj && dropDownDetails.listObj['Financial#10']) {
        setReasonCodeDropdown(dropDownDetails.listObj['Financial#10']);
        tableValues.map(value => {
          value.transferreasonCode =
            dropDownDetails.listObj['Financial#10'][0]?.code;
        });
        setTableData(tableValues);
      }
    }
  }, [dropDownDetails]);

  const setRsnCdData = data => {
    setTableData(JSON.parse(JSON.stringify(data)));
  };

  const saveReverse = () => {
    setErrorMessages([]);
    setSystemSuccesMessages([]);
    const bulkReverseResult =
      props.location.state && props.location.state.bulkViewReverseResult
        ? props.location.state.bulkViewReverseResult
        : tableData;
    const selectedTransferResult = tableData;
    const saveReverseArray = [];
    const errorMsgArray = [];
    let count = 0;
    for (let i = 0; i <= bulkReverseResult.length - 1; i++) {
      if (
        bulkReverseResult[i].originalFCN ===
        selectedTransferResult[i].receivableFCN
      ) {
        if (
          !selectedTransferResult[i].transferreasonCode ||
          selectedTransferResult[i].transferreasonCode === DEFAULT_DD_VALUE
        ) {
          count = count + 1;
          selectedTransferResult[i].errorKey = true;
        } else {
          selectedTransferResult[i].errorKey = false;
        }
        const reverseObj = {
          receivableFCN: selectedTransferResult[i].receivableFCN,
          reversalVO: {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            reasonCode:
              selectedTransferResult[i].transferreasonCode ===
                DEFAULT_DD_VALUE
                ? bulkReverseResult[i].reversalVO.reasonCode
                : selectedTransferResult[i].transferreasonCode
          }
        };
        saveReverseArray.push(reverseObj);
      }
    }
    if (count > 0) {
      errorMsgArray.push(constants.REASON_CODE_REQ);
    }
    setTableData(selectedTransferResult);
    if (errorMsgArray.length === 0) {
      saveBulkReverseAction(saveReverseArray);
    } else {
      setErrorMessages(errorMsgArray);
    }
  };

  const valuesFromBulkMaintenance = props.history && props.history.location && props.history.location.state &&
  props.history.location.state.values;

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(() => {
      setDialogOpen(false);
      setFocusCheck(false);
      props.history.push({
        pathname: '/BulkMaintenance',
        tableRender: true,
        state: { valuesFromBulkMaintenance: valuesFromBulkMaintenance }
      });
    }, 100);
  };
  const majorCancel = () => {
    setErrorMessages([]);
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      props.history.push({
        pathname: '/BulkMaintenance',
        tableRender: true,
        state: { valuesFromBulkMaintenance: valuesFromBulkMaintenance }
      });
    }
  };
  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          // getting respective record from dropdown list which also has desc
          const reasonCode =
          dropdownData &&
          dropdownData['F1#F_RSN_CD'] &&
          dropdownData['F1#F_RSN_CD'].filter(
            value => value.code === each.reasonCode?.split("-")[0]
          );
          // updating the record with desc
          each.reasonCodeDesc =
          reasonCode && reasonCode.length > 0
            ? reasonCode[0].description
            : each.reasonCode ? each.reasonCode : '';
        }
        each.transReasCodeCheck = reasonCodeDropdown.find(value => value.code === each.transferreasonCode);
        if (!each.transReasCodeCheck || each.transReasCodeCheck.length === 0) {
          each.transferreasonCode = DEFAULT_DD_VALUE;
        }
        return each;
      });
      return tData;
    }
    return [];
  };
  return (
    <div className="pos-relative w-100 h-100">
      <Prompt
        when={allowNavigation}
        message={location =>
            `Are you sure you want to go to ${location.pathname}`
        }
      />
      <ErrorMessages errorMessages={errorMessages} />
      {systemSuccessMessages.length > 0 && errorMessages.length === 0 ? (
        <SuccessMessages successMessages={systemSuccessMessages} />
      ) : null}

      <div className="tabs-container" ref={toPrintRef}>
        <div className="tab-header">
          <h1 className="tab-heading float-left">Account Receivable Reverse</h1>
          <div className="float-right mt-2">
            <Button
              className="btn btn-success ml-1"
              onClick={() => saveReverse()}
            >
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
            {window.location.pathname === '/BulkReverse' && (
              <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
            )}
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body tableNoWrap">
          <BulkReverseTable
            handleTableReasonCode={handleTableReasonCode}
            dropDownData={reasonCodeDropdown}
            setRsnCdData={setRsnCdData}
            tableData={getTableData(tableData || [])}
          />
        </div>
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-alert-box"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
              </>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false);
              }}
              color="primary"
              className="btn btn-transparent"
            >
                  STAY ON THIS PAGE!
            </Button>
            <Button
              onClick={cancelMinorSection}
              color="primary"
              className="btn btn-success"
              autoFocus
            >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default withRouter(BulkReverse);
