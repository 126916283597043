/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const DROPDOWNDATA = 'DROPDOWNDATA';
export const SEARCH_CMS64 = 'SEARCH_CMS64';
export const CREATE_CMS64 = 'CREATE_CMS64';
export const UPDATE_CMS64 = 'UPDATE_CMS64';
export const RESET_CMS64 = 'RESET_CMS64';
