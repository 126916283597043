
import axios from 'axios';
import * as actionTypes from './actionTypes';
import * as serviceEndPoint from '../../../SharedModules/services/service';

export const resetSearchCriteria = () => ({
    type: actionTypes.RESETDATA,
    resetData: []
});

export const dispatchGlobalAuditSearch = (response, values) => ({
    type: actionTypes.GLOBAL_AUDIT_SEARCH_TYPE,
    auditSearchData: response,
    fieldValues: values
});


export const GlobalAuditSearchAction = values => dispatch => {
    return axios.post(`${serviceEndPoint.GLOBAL_AUDIT_LOG_DETAILS}`, values)
        .then(response => {
            if (response.status === 500 || response.status === 400) {
                dispatch(dispatchGlobalAuditSearch(response.data));
            }
            else if (response.status === 200 && response.data) {               
                dispatch(dispatchGlobalAuditSearch(response.data, values));
            }
            else {
                dispatch(dispatchGlobalAuditSearch([]))
            }
        })
        .catch(error => {
            dispatch(dispatchGlobalAuditSearch(error.data));
        })
}

export const dispatchAuditLog = (auditData, auditType) => ({
    type: actionTypes.AUDIT_LOG,
    response:
      auditData && auditData.responseAuditLogDetails
        ? auditData.responseAuditLogDetails
        : [],
    auditType: auditType,
  });

export const getAuditLogDataAction = (values, auditType) => (dispatch) => {
    return axios
      .post(`${serviceEndPoint.AUDIT_LOG_DETAILS}`, values)
      .then((response) => {
        dispatch(dispatchAuditLog(response.data, auditType));
      })
      .catch((error) => {
        dispatch(dispatchAuditLog(error.data, auditType));
      });
  };





