/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const BEGIN_DT_REQ = 'Begin Date is required.';
export const END_DT_REQ = 'End Date is required.';
export const FINANCE_RC_REQ = 'Finance Reason Code is required.';
export const FRC_DESC_REQ = 'Finance Reason Code Description is required.';
export const FRC_GROUP_REQ = 'Finance Reason Code Group is required.';
export const FRC_CD_TY_REQ = 'Finance Reason Code Type is required.';
export const PN_REQ = 'Priority Number is required.';
export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const NO_RECORDS_FOUND = 'No records found for the search criteria entered.';
export const INVALID_FORMAT = 'Please enter the data in correct format.';
export const INVALID_DATE = 'Please Enter the date in correct format.';
export const END_DATE_GREATER_THAN_BG_DT = 'The End Date must be greater than or equal to the Begin Date.'



