import React from 'react';
import { withRouter } from 'react-router';


function FinancialEntityUpdate () {
  return (
    <div>Financial Entity Update</div>
  );
};
export default withRouter((FinancialEntityUpdate));
