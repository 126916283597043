/* eslint-disable eol-last */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const PAYEE_TYPE = 'Payee Type is required.';
export const PAYEE_ID_TYPE_CODE = 'Payee ID Type Code is required.';
export const PAYEE_ID = 'Payee ID is required.';
export const REASON_CODE = 'Reason Code is required.';
export const BALANCE_ZERO = 'An A/R with a zero balance cannot be Transferred.';
export const SAME_ID = 'A Receivable cannot be transferred to the same Payee ID.';
export const ID_NOT_FOUND = 'Payee/Payer does not exist in the database.';
export const SYS_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const SAVE_SUCCESS = 'System successfully saved the Information.';
export const REVERSE_ERROR = 'Reversal can only be performed when the Balance Amount is EQUAL TO the Adjusted/Original Amount.';
export const TRANSACTION_NUMBER_FORMAT = 'should not contain spaces or special characters.';