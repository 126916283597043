/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import AddAddress from "./AddAddress";
import TableComponent from "../../../../SharedModules/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  verifyAddressAPI
} from "../../../../SharedModules/store/Actions/SharedAction";
import {
  generateUUID,
  getDateInMMDDYYYYFormat,
  getUTCTimeStamp
} from "../../../../SharedModules/DateUtilities/DateUtilities";
import {
  resetAddressPopup,
  validateAddressPopup
} from "../AddValidationUtility";
import * as AppConstants from "../../../../SharedModules/AppConstants";
import AuditTableComponent from "../../../../SharedModules/AuditLog/AuditTableComponent";

export default function Address (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const {
    seterrorMessages,
    showLogAddressTable,
    auditRowData,
    setAuditRowData,
    setShowLogTable,
    setActiveAddressData,
    userInquiryPrivileges,
    setFocusCheck,
    initialFocusCheck
  } = props;
  const dispatch = useDispatch();
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const validateAddress = values => {
    dispatch(verifyAddressAPI(values));
  };
  const validateAddressResult = useSelector(
    state => state.sharedState.addressValidatedData
  );
  const [addressError, setAddressError] = React.useState([]);
  const [showAdditionalDetails1, setShowAdditionalDetails1] = React.useState(
    false
  );
  const [uspsAddres, setUspsAddress] = React.useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode5: ""
  });
  const [verified, setVerified] = React.useState(false);

  useEffect(() => {
    if (validateAddressResult) {
      if (validateAddressResult.systemFailue) {
        setAddressError([AppConstants.ERR_PROCESSING_REQ]);
        setUspsAddress({
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipCode5: ""
        });
        setVerified(false);
        window.scrollTo({
          top: 100,
          behavior: "smooth"
        });
      }
    }
    if (
      validateAddressResult &&
      validateAddressResult.AddressValidateResponse &&
      !validateAddressResult.systemFailue
    ) {
      if (validateAddressResult.AddressValidateResponse.Address) {
        if (validateAddressResult.AddressValidateResponse.Address.Error) {
          setAddressError([
            validateAddressResult.AddressValidateResponse.Address.Error
              .Description
          ]);
          seterrorMessages([
            validateAddressResult.AddressValidateResponse.Address.Error
              .Description
          ]);
          setUspsAddress({
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zipCode5: ""
          });
          setVerified(false);
          window.scrollTo({
            top: 100,
            behavior: "smooth"
          });
        } else {
          setUspsAddress({
            addressLine1:
              validateAddressResult.AddressValidateResponse.Address.Address2,
            addressLine2:
              validateAddressResult.AddressValidateResponse.Address.Address1,
            city: validateAddressResult.AddressValidateResponse.Address.City,
            state: validateAddressResult.AddressValidateResponse.Address.State,
            zipCode5: validateAddressResult.AddressValidateResponse.Address.Zip5
          });
          setAddressError([]);
          seterrorMessages([]);
          setVerified(true);
          setShowAdditionalDetails1(true);
        }
      } else {
        setAddressError([AppConstants.INVALID_ADDRESS]);
        seterrorMessages([AppConstants.INVALID_ADDRESS]);

        setUspsAddress({
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipCode5: ""
        });
        setVerified(false);
        window.scrollTo({
          top: 100,
          behavior: "smooth"
        });
      }
    } else {
      setAddressError([AppConstants.ERR_PROCESSING_REQ]);

      setUspsAddress({
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode5: ""
      });
      setVerified(false);
      window.scrollTo({
        top: 100,
        behavior: "smooth"
      });
    }
  }, [validateAddressResult]);
  const {
    AddressTypeDropdown,
    SignificanceTypeDropdown,
    StatusCodeDropdown,
    CountyDropdown,
    CountryDropdown,
    StateDropdown,
    ChangeReasonDropdown,
    payeeValues,
    setAddressTableData,
    addressTableData,
    setAllowNavigation,
    callValidatePayeeDetails,
    disableFields
  } = props;

  const headCells = [
    {
      id: "statusCodeDesc",
      numeric: false,
      disablePadding: true,
      label: "Status"
    },
    {
      id: "addressTypeDesc",
      numeric: false,
      disablePadding: false,
      label: "Address Type"
    },
    {
      id: "beginDate",
      numeric: false,
      disablePadding: false,
      label: "Begin Date"
    },
    { id: "endDate", numeric: false, disablePadding: false, label: "End Date" },
    {
      id: "addressOne",
      numeric: false,
      disablePadding: false,
      label: "Street Address"
    },
    { id: "city", numeric: false, disablePadding: false, label: "City" },
    { id: "stateDesc", numeric: false, disablePadding: false, label: "State" },
    { id: "country", numeric: false, disablePadding: false, label: "Country" },
    { id: "zipOne", numeric: false, disablePadding: false, label: "Zip Code" }
  ];
  const editHeadCells = [
    {
      id: "statusCodeDesc",
      numeric: false,
      disablePadding: true,
      label: "Status"
    },
    {
      id: "addressTypeDesc",
      numeric: false,
      disablePadding: false,
      label: "Address Type"
    },
    {
      id: "beginDate",
      numeric: false,
      disablePadding: false,
      label: "Begin Date"
    },
    { id: "endDate", numeric: false, disablePadding: false, label: "End Date" },
    {
      id: "addressOne",
      numeric: false,
      disablePadding: false,
      label: "Street Address"
    },
    { id: "city", numeric: false, disablePadding: false, label: "City" },
    { id: "stateDesc", numeric: false, disablePadding: false, label: "State" },
    { id: "country", numeric: false, disablePadding: false, label: "Country" },
    { id: "zipOne", numeric: false, disablePadding: false, label: "Zip Code" }
  ];

  // Dropdown description implementation ---- starts
  const [filteredTableData, setFilteredTableData] = React.useState([]);

  const dropDownFilter = (dropDownType, value) => {
    let TypeDropdownData = [];
    switch (dropDownType) {
      case "statusCode":
        TypeDropdownData = StatusCodeDropdown;
        break;
      case "addressType":
        TypeDropdownData = AddressTypeDropdown;
        break;
      case "state":
        TypeDropdownData = StateDropdown;
        break;
      case "country":
        TypeDropdownData = CountryDropdown;
        break;
    }
    if (TypeDropdownData) {
      const filteredValue = TypeDropdownData.filter(
        (dropdownTypeValue) => dropdownTypeValue.code === value
      );
      if (filteredValue && filteredValue.length > 0) {
        return filteredValue[0].description;
      }
      return value;
    }
  };

  const addressTableFilter = filteredData => {
    // const filteredData = miscellaneousTableData;
    if (filteredData && filteredData.length > 0) {
      filteredData.map((data) => {
        data.statusCodeDesc = dropDownFilter("statusCode", data.statusCode);
        data.addressTypeDesc = dropDownFilter("addressType", data.addressType);
        data.stateDesc = dropDownFilter("state", data.state);
      });
    }
    setFilteredTableData(filteredData);
  };
  // Dropdown description implementation -------- ends

  // Set Description in fields on Initial table load
  React.useEffect(() => {
    const filteredData = addressTableData;
    addressTableFilter(filteredData);
  }, [addressTableData]);
  const [considerUSPS, setConsiderUSPS] = React.useState("N");
  const handleChangeUSPS = () => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setConsiderUSPS(event.target.value);
  };
  const AddAddressInitialValue = {
    id: generateUUID(),
    phone: "",
    email: "",
    addedAuditUserID: "",
    addedAuditTimeStamp: "",
    addressUsageTypeCode: DEFAULT_DD_VALUE,
    addressSK: null,
    beginDate: "",
    endDate: "",
    addressUsageSigCode: DEFAULT_DD_VALUE,
    changeReasonCode: DEFAULT_DD_VALUE,
    statusCode: "A",
    addressRepCrossReferences: [],
    addressUsageSequenceNumber: 0,
    addressUsageSK: null,
    commonEntitySK: null,
    addressUsageVersionNumber: "0",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    addressLine4: "",
    statusDesc: null,
    addressTypeDesc: null,
    countyDesc: null,
    latitudeNumber: "",
    longitudeNumber: "",
    city: "",
    state: DEFAULT_DD_VALUE,
    zipCode5: "",
    zipCode4: "",
    town: "",
    country: DEFAULT_DD_VALUE,
    county: DEFAULT_DD_VALUE,
    uspsAddressVerifyCode: null,
    uspsAddressLine1: null,
    uspsAddressLine2: null,
    addressVersionNumber: 0
  };
  const MailingAddressVoObj = {
    id: null,
    auditUserID: "",
    auditTimeStamp: "",
    addedAuditUserID: "",
    addedAuditTimeStamp: "",
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    contactType: null,
    addressType: "",
    contactSSN: null,
    contactDob: null,
    contactDod: null,
    contactStatusDesc: null,
    contactTypeDesc: null,
    contactTitle: null,
    significanceTypeCode: "",
    status: null,
    contactAddrBeginDate: null,
    contactAddrEndDate: null,
    gender: null,
    streetaddress: null,
    zipcode: null,
    dummyInd: null,
    addressUsageSequenceNumber: 0,
    statusCode: DEFAULT_DD_VALUE,
    changeReason: "",
    dateFormat: null,
    beginDate: "",
    endDate: "",
    addressTypeRowIndex: null,
    latitudeNumber: "",
    longitudeNumber: "",
    uspsAddressLine1: null,
    uspsAddressLine2: null,
    uspsAddressVerifyCode: null,
    addressUsageSK: null,
    prefix: null,
    lastName: null,
    firstName: null,
    middleName: null,
    suffix: null,
    phone: null,
    phoneExtn: null,
    email: null,
    addressOne: "",
    addressTwo: "",
    addressThree: null,
    addressFour: null,
    county: null,
    country: "",
    town: null,
    addressVerification: null,
    uspsVerifiedCode: null,
    latitude: null,
    longitude: null,
    city: "",
    state: "",
    zipOne: "",
    zipTwo: "",
    representativeSK: null,
    eaddressSK: null,
    phoneSK: null,
    addressSK: null,
    countryCode: null,
    countyCode: null,
    addressBeginDateStr: null,
    addressEndDateStr: null,
    mailReturnedIndicator: false,
    addressVoidIndicator: false,
    addressPendingIndicator: false,
    phoneBeginDateStr: null,
    phoneEndDateStr: null,
    phoneVoidIndicator: false,
    phoneOutOfServiceIndicator: false,
    emailAddressBeginDateStr: null,
    emailAddressEndDateStr: null,
    emailAddressVoidIndicator: false,
    emailAddressBouncedIndicator: false,
    contactTypeRowIndex: null,
    commonEntityVersionNumber: 0,
    repCommonEntitySK: null,
    renderContactSection: false,
    renderAddContactSection: false,
    renderEditContactSection: false,
    renderDeleteContactSection: false,
    contactKey: null,
    contactRowIndex: null,
    renderAdditionalContactSection: false,
    renderMailingAddressSection: false,
    commonEntityCrossRefVersionNumber: 0,
    representativeVersionNumber: 0,
    addressUsageVersionNumber: 0,
    addressVersionNumber: 0,
    returnedMailVersionNumber: 0,
    phoneUsageVersionNumber: 0,
    phoneVersionNumber: 0,
    emailUsageVersionNumber: 0,
    emailVersionNumber: 0,
    renderPlusMailingAddressSection: false,
    renderPlusAdditionalContactSection: false,
    nameVrsnNo: 0,
    cmnEntityTypVrsnNo: 0,
    commonEntitySK: null,
    disableContactType: false
  };

  const [showAddressError, setShowAddressError] = React.useState({
    showaddressUsageTypeCodeError: false,
    showBeginDateError: false,
    showEndDateError: false,
    showSignificanceTypeCodeError: false,
    showChangeReasonCodeError: false,
    showStatusCodeError: false,
    showAddressLine1Error: false,
    showAddressLine2Error: false,
    showAddressLine3Error: false,
    showAddressLine4Error: false,
    showCityError: false,
    showStateError: false,
    showZipcodeError: false,
    showZipcodeReqError: false,
    showCountyError: false,
    showCountryError: false,
    showTownError: false,
    showAddressVerificationError: false,
    showUSPSVerifiedCodeError: false,
    showLatitudeError: false,
    showLongitudeError: false,
    showPhoneError: false,
    showEmailError: false
  });

  const [showAddressErrorMsg, setShowAddressErrorMsg] = React.useState({
    showaddressUsageTypeCodeErrorMsg: "",
    showBeginDateErrorMsg: "",
    showEndDateErrorMsg: "",
    showSignificanceTypeCodeErrorMsg: "",
    showChangeReasonCodeErrorMsg: "",
    showStatusCodeErrorMsg: "",
    showAddressLine1ErrorMsg: "",
    showAddressLine2ErrorMsg: "",
    showAddressLine3ErrorMsg: "",
    showAddressLine4ErrorMsg: "",
    showCityErrorMsg: "",
    showStateErrorMsg: "",
    showZipcodeErrorMsg: "",
    showZipcodeReqErrorMsg: '',
    showCountyErrorMsg: "",
    showCountryErrorMsg: "",
    showTownErrorMsg: "",
    showAddressVerificationErrorMsg: "",
    showUSPSVerifiedCodeErrorMsg: "",
    showLatitudeErrorMsg: "",
    showLongitudeErrorMsg: "",
    showPhoneErrorMsg: "",
    showEmailErrorMsg: ""
  });
  // Reset Edit Popup
  const [retainResetValue, setRetainResetValue] = React.useState("");
  const onEditRowPopUp = value => {
    setAddAddress({ ...value });
    setSelectedBeginDate(value.beginDate);
    setSelectedEndDate(value.endDate);
    setCurrentRecord(value);
    setAddAddress({
      id: value.id,
      phone: value.phone,
      email: value.email,
      addedAuditUserID: value.addedAuditUserID,
      addedAuditTimeStamp: value.addedAuditTimeStamp,
      statusCode: value.statusCode,
      beginDate: value.beginDate,
      endDate: value.endDate,
      addressLine1: value.addressOne,
      country: value.country,
      county: value.county,
      addressUsageTypeCode: value.addressType,
      addressUsageSigCode: value.significanceTypeCode,
      zipCode5: value.zipOne,
      changeReason: value.changeReason,
      addressLine2: value.addressTwo,
      addressLine3: value.addressThree,
      addressLine4: value.addressFour,
      town: value.town,
      city: value.city,
      state: value.state,
      latitudeNumber: value.latitudeNumber,
      longitudeNumber: value.longitudeNumber,
      uspsAddressLine1: value.uspsAddressLine1,
      uspsAddressLine2: value.uspsAddressLine2,
      uspsAddressVerifyCode: value.uspsAddressVerifyCode,
      addressSK: value.addressSK,
      addressUsageSK: value.addressUsageSK,
      phone: value.phone,
      email: value.email
    });
  };
  // Address Values
  const [addAddress, setAddAddress] = React.useState(AddAddressInitialValue);
  const [selectedBeginDate, setSelectedBeginDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [currentRecord, setCurrentRecord] = React.useState({});
  // Handle Address change values
  const handleChangeAddAddress = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setAddAddress({ ...addAddress, [name]: event.target.value });
  };
  const handleBeginDateChange = beginDate => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedBeginDate(beginDate);
  };
  const handleEndDateChange = endDate => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedEndDate(endDate);
  };

  // Opens new Dialog box with empty values
  const [editRow, setEditRow] = React.useState(false);
  const handleClickOpenAddress = () => {
    setConsiderUSPS(AppConstants.CONSIDER_USPS_NO)
    setAddressError([]);
    setVerified(false);
    setUspsAddress({
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode5: ""
    });
    if (callValidatePayeeDetails(true)) {
      setOpenAddress(true);
      setEditRow(false);
      resetAddressPopup(
        setAddAddress,
        AddAddressInitialValue,
        addressTableData,
        setSelectedBeginDate,
        setSelectedEndDate,
        setShowAddressError,
        setShowAddressErrorMsg
      );
    }
  };
  // Reset popup function
  const handleAddressReset = event => {
    setConsiderUSPS(AppConstants.CONSIDER_USPS_NO)
    setFocusCheck(initialFocusCheck);
    setAllowNavigation(initialFocusCheck);
    setActiveAddressData(null);
    seterrorMessages([]);
    setShowAddressError({
      showaddressUsageTypeCodeError: false,
      showBeginDateError: false,
      showEndDateError: false,
      showSignificanceTypeCodeError: false,
      showChangeReasonCodeError: false,
      showStatusCodeError: false,
      showAddressLine1Error: false,
      showAddressLine2Error: false,
      showAddressLine3Error: false,
      showAddressLine4Error: false,
      showCityError: false,
      showStateError: false,
      showZipcodeError: false,
      showZipcodeReqError: false,
      showCountyError: false,
      showCountryError: false,
      showTownError: false,
      showAddressVerificationError: false,
      showUSPSVerifiedCodeError: false,
      showLatitudeError: false,
      showLongitudeError: false,
      showPhoneError: false,
      showEmailError: false
    });
    setAddressError([]);
    if (editRow) {
      onEditRowPopUp(retainResetValue);
    } else {
      resetAddressPopup(
        setAddAddress,
        AddAddressInitialValue,
        addressTableData,
        setSelectedBeginDate,
        setSelectedEndDate,
        setShowAddressError,
        setShowAddressErrorMsg
      );
    }
  };
  // Delete functionality starts --->
  const [addressSelectedRows, setAddressSelectedRows] = React.useState([]);
  const selectedAddressTableRow = selectedRows => {
    setAddressSelectedRows([...addressSelectedRows, ...selectedRows]);
  };
  const deleteSelectedAddressTableRow = () => {
    let tempAddressTableData = [...addressTableData];
    if (addressSelectedRows && addressSelectedRows.length > 0) {
      addressSelectedRows.map((selectedRowId) => {
        tempAddressTableData = tempAddressTableData.filter(
          tempRow => tempRow.id !== selectedRowId
        );
      });
    }
    setAddressTableData(tempAddressTableData);
  };
  // Delete functionality ends --->
  // Address Verification Method

  // Add row and Update row functionality
  const handleSave = () => {
    if (
      validateAddressPopup(
        addAddress,
        selectedBeginDate,
        selectedEndDate,
        AddAddressInitialValue,
        addressTableData,
        setShowAddressError,
        setShowAddressErrorMsg,
        retainResetValue,
        editRow,
        seterrorMessages
      )
    ) {
      const addressReq = {
        address1: addAddress.addressLine1,
        address2: addAddress.addressLine2,
        city: addAddress.city,
        state: addAddress.state,
        zip4: "",
        zip5: addAddress.zipCode5
      };
      validateAddress(addressReq);
      }
  };
  const handleSaveFinal = () => {
    // Map VO obj
    if (addressError?.length) {
      return false;
    }
    if (
      validateAddressPopup(
        addAddress,
        selectedBeginDate,
        selectedEndDate,
        AddAddressInitialValue,
        addressTableData,
        setShowAddressError,
        setShowAddressErrorMsg,
        retainResetValue,
        editRow,
        seterrorMessages
      )
    ) {
      let AdressTableData = [];
      if (verified === false && considerUSPS === "Y") {
        setAddressError([AppConstants.VERIFIED_ADDRESS]);
        seterrorMessages([AppConstants.VERIFIED_ADDRESS]);
      } else {
        setAddressError([]);
        MailingAddressVoObj.auditUserID = logInUserID; // For Add and Edit/Update API call new values
        MailingAddressVoObj.auditTimeStamp = getUTCTimeStamp();
        MailingAddressVoObj.phone = addAddress.phone;
        MailingAddressVoObj.email = addAddress.email;
        MailingAddressVoObj.addedAuditUserID =
          addAddress.addedAuditUserID !== ""
            ? addAddress.addedAuditUserID
            : logInUserID; //
        MailingAddressVoObj.addedAuditTimeStamp =
          addAddress.addedAuditTimeStamp !== ""
            ? addAddress.addedAuditTimeStamp
            : getUTCTimeStamp();
        MailingAddressVoObj.addressType =
          addAddress.addressUsageTypeCode !== DEFAULT_DD_VALUE
            ? addAddress.addressUsageTypeCode
            : null;
        MailingAddressVoObj.significanceTypeCode =
          addAddress.addressUsageSigCode !== DEFAULT_DD_VALUE
            ? addAddress.addressUsageSigCode
            : null;
        MailingAddressVoObj.statusCode =
          addAddress.statusCode !== DEFAULT_DD_VALUE
            ? addAddress.statusCode
            : null;
        MailingAddressVoObj.changeReason =
          addAddress.changeReason !== DEFAULT_DD_VALUE
            ? addAddress.changeReason
            : null;
        MailingAddressVoObj.beginDate = selectedBeginDate
          ? getDateInMMDDYYYYFormat(selectedBeginDate)
          : null;
        MailingAddressVoObj.endDate = selectedEndDate
          ? getDateInMMDDYYYYFormat(selectedEndDate)
          : null;
        MailingAddressVoObj.addressThree = addAddress.addressLine3;
        MailingAddressVoObj.addressFour = addAddress.addressLine4;
        MailingAddressVoObj.town = addAddress.town;
        MailingAddressVoObj.dateFormat = null;
        MailingAddressVoObj.country =
          addAddress.country !== DEFAULT_DD_VALUE
            ? addAddress.country
            : null;
        MailingAddressVoObj.county =
          addAddress.county !== DEFAULT_DD_VALUE
            ? addAddress.county
            : null;
        MailingAddressVoObj.latitudeNumber = addAddress.latitudeNumber;
        MailingAddressVoObj.longitudeNumber = addAddress.longitudeNumber;
        MailingAddressVoObj.uspsAddressLine1 = addAddress.uspsAddressLine1;
        MailingAddressVoObj.uspsAddressLine2 = addAddress.uspsAddressLine2;
        MailingAddressVoObj.uspsAddressVerifyCode =
          addAddress.uspsAddressVerifyCode;
        MailingAddressVoObj.id = addAddress.id;
        MailingAddressVoObj.addressSK = addAddress.addressSK;
        MailingAddressVoObj.addressUsageSK = addAddress.addressUsageSK;
        if (considerUSPS === "Y") {
          MailingAddressVoObj.addressOne = uspsAddres.addressLine1;
          MailingAddressVoObj.addressTwo = uspsAddres.addressLine2;
          MailingAddressVoObj.city = uspsAddres.city;
          MailingAddressVoObj.state = uspsAddres.state;
          MailingAddressVoObj.zipOne = uspsAddres.zipCode5;
          addAddress.addressLine1 = uspsAddres.addressLine1;
        } else {
          MailingAddressVoObj.addressOne = addAddress.addressLine1;
          MailingAddressVoObj.addressTwo = addAddress.addressLine2;
          MailingAddressVoObj.city = addAddress.city;
          MailingAddressVoObj.state =
            addAddress.state !== DEFAULT_DD_VALUE
              ? addAddress.state
              : null;
          MailingAddressVoObj.zipOne = addAddress.zipCode5;
        }
        if (editRow) {
          AdressTableData = addressTableData.map(Record => {
            if (Record.id) {
              if (Record.id === MailingAddressVoObj.id) {
                MailingAddressVoObj.addressUsageSequenceNumber =
                  Record.addressUsageSequenceNumber;
                return MailingAddressVoObj;
              } else {
                return Record;
              }
            } else {
              if (
                Record.addressUsageSK === MailingAddressVoObj.addressUsageSK
              ) {
                MailingAddressVoObj.addressUsageSequenceNumber =
                  Record.addressUsageSequenceNumber;
                return MailingAddressVoObj;
              } else {
                return Record;
              }
            }
          });
        } else {
          AdressTableData = addressTableData;
          AdressTableData.push(MailingAddressVoObj);
        }
        setAddressTableData(AdressTableData);
        addressTableFilter(AdressTableData);
        handleCloseAddress();
        setAllowNavigation(true);
      }
    } else {
    }
  };
  // Address Dialog box open and close functionality starts ---->
  const [openAddress, setOpenAddress] = React.useState(false);
  const handleCloseAddress = () => {
    setFocusCheck(initialFocusCheck);
    setAllowNavigation(initialFocusCheck);
    setActiveAddressData(null);
    seterrorMessages([]);
    setOpenAddress(false);
  };
  const handleOpenAddress = () => {
    setOpenAddress(true);
  };
  // Address Dialog box open and close functionality ends---->
  useEffect(() => {
    if (openAddress && document.getElementById("form_pop_up")) {
      document.getElementById("form_pop_up").scrollIntoView();
    }
  }, [openAddress]);
  // On edit of row click (Values setting in Dialogue box)
  const AddressTableRowClk = value => event => {
    setConsiderUSPS(AppConstants.CONSIDER_USPS_NO)
    setActiveAddressData(value);
    setShowAddressError({
      showaddressUsageTypeCodeError: false,
      showBeginDateError: false,
      showEndDateError: false,
      showSignificanceTypeCodeError: false,
      showChangeReasonCodeError: false,
      showStatusCodeError: false,
      showAddressLine1Error: false,
      showAddressLine2Error: false,
      showAddressLine3Error: false,
      showAddressLine4Error: false,
      showCityError: false,
      showStateError: false,
      showZipcodeError: false,
      showZipcodeReqError: false,
      showCountyError: false,
      showCountryError: false,
      showTownError: false,
      showAddressVerificationError: false,
      showUSPSVerifiedCodeError: false,
      showLatitudeError: false,
      showLongitudeError: false,
      showPhoneError: false,
      showEmailError: false
    });
    setAddressError([]);
    setVerified(false);
    if (callValidatePayeeDetails(true)) {
      setAddAddress({ ...value });
      setSelectedBeginDate(value.beginDate);
      setSelectedEndDate(value.endDate);
      setCurrentRecord(value);
      setAddAddress({
        id: value.id,
        addedAuditUserID: value.addedAuditUserID,
        addedAuditTimeStamp: value.addedAuditTimeStamp,
        statusCode: value.statusCode,
        beginDate: value.beginDate,
        endDate: value.endDate,
        addressLine1: value.addressOne,
        country: value.country,
        county: value.county,
        addressUsageTypeCode: value.addressType,
        addressUsageSigCode: value.significanceTypeCode,
        zipCode5: value.zipOne,
        changeReason: value.changeReason,
        addressLine2: value.addressTwo,
        addressLine3: value.addressThree,
        addressLine4: value.addressFour,
        town: value.town,
        city: value.city,
        state: value.state,
        latitudeNumber: value.latitudeNumber,
        longitudeNumber: value.longitudeNumber,
        uspsAddressLine1: value.uspsAddressLine1,
        uspsAddressLine2: value.uspsAddressLine2,
        uspsAddressVerifyCode: value.uspsAddressVerifyCode,
        addressSK: value.addressSK,
        addressUsageSK: value.addressUsageSK,
        phone: value.phone,
        email: value.email
      });
      setEditRow(true);
      setUspsAddress({
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode5: ""
      });
      setRetainResetValue(value);
      handleOpenAddress();
    }
  };

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const statusCode =
            props.dropdowns &&
            props.dropdowns['G1#G_ADR_USG_STAT_CD'] &&
            props.dropdowns['G1#G_ADR_USG_STAT_CD'].filter(
              value => value.code === each.statusCode
            );
          each.statusCodeDesc =
          statusCode && statusCode.length > 0
              ? statusCode[0].description
              : each.statusCode ? each.statusCode : '';
          const stateCode =
            props.dropdowns &&
            props.dropdowns['G1#G_US_STATE_CD'] &&
            props.dropdowns['G1#G_US_STATE_CD'].filter(
              value => value.code === each.state
            );
            each.stateDesc =
            stateCode && stateCode.length > 0
              ? stateCode[0].description
              : each.state ? each.state : '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  return (
    <div className="tab-holder">
      <div className="float-right mb-3">
        <Button
          data-testid="test_deleteAddress_Btn"
          className="btn-text-main-delete btn-transparent  ml-1"
          onClick={deleteSelectedAddressTableRow}
          disabled={disableFields}
          title={"Delete"}
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
          Delete
        </Button>
        <button
          className="btn-text-main-add btn-success  ml-1"
          data-testid="test_addAddress_Btn"
          onClick={handleClickOpenAddress}
          disabled={disableFields || !userInquiryPrivileges}
          title={"Add Address"}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>Add
        </button>
      </div>
      <div className="clearfix"></div>
      <div className="table-no-wrap table-p-5">
        <TableComponent
          print={props.print}
          isSearch={false}
          headCells={props.showEditPage ? editHeadCells : headCells}
          tableData={getTableData(filteredTableData || [])}
          onTableRowDelete={selectedAddressTableRow}
          onTableRowClick={AddressTableRowClk}
          defaultSortColumn={headCells[0].id}
          // Audit Log Changes
          onTableCellButtonClick={props.onClickAuditDialogLog}
          uniqueKey="addressSK"
          setClose={props.setClose}
          close={props.close}
        />
        {openAddress ? (
          <div className="tabs-container" id="form_pop_up">

            <div className="tab-header  mini-tab-header">
              <div className="tab-heading float-left">
                {editRow ? "Edit Address" : "Add Address"}
              </div>
              <div className="float-right mt-1">
                <Button
                  variant="outlined"
                  data-testid="test_verify_Btn"
                  color="primary"
                  className="btn btn-success ml-1"
                  onClick={handleSave}
                  disabled={disableFields || !userInquiryPrivileges}
                  title={'Verify'}
                >
                  Verify
                </Button>
                <Button
                  className="btn btn-success ml-1"
                  onClick={handleSaveFinal}
                  disabled={disableFields || !userInquiryPrivileges}
                  data-testid="test_add_Btn"
                  title={editRow ? "Update" : "Add"}
                >
                 <i className="fa fa-check" aria-hidden="true"></i>
                  {editRow ? "Update" : "Add"}
                </Button>
                <Button
                  className="bt-reset btn-transparent  ml-1"
                  data-testid="test_reset_Btn"
                  onClick={handleAddressReset}
                  disabled={disableFields || !userInquiryPrivileges}
                  title ={'Reset'}
                >
                  <i className="fa fa-undo" aria-hidden="true"></i>
                  Reset
                </Button>
                <Button
                  variant="outlined"
                  data-testid="test_cancel_btn"
                  color="primary"
                  className="btn btn-primary btn btn-outlined ml-1"
                  onClick={handleCloseAddress}
                  title={'Cancel'}
                >
                  Cancel
                </Button>
              </div>
            </div>
            <AddAddress
              disableFields={disableFields}
              editRow={editRow}
              payeeValues={payeeValues}
              AddressTypeDropdown={AddressTypeDropdown || []}
              SignificanceTypeDropdown={SignificanceTypeDropdown || []}
              StatusCodeDropdown={StatusCodeDropdown || []}
              CountyDropdown={CountyDropdown || []}
              CountryDropdown={CountryDropdown || []}
              StateDropdown={StateDropdown || []}
              ChangeReasonDropdown={ChangeReasonDropdown || []}
              showAdditionalDetails1={showAdditionalDetails1}
              setShowAdditionalDetails1={setShowAdditionalDetails1}
              addAddress={addAddress}
              selectedBeginDate={selectedBeginDate}
              selectedEndDate={selectedEndDate}
              handleChangeAddAddress={handleChangeAddAddress}
              handleBeginDateChange={handleBeginDateChange}
              handleEndDateChange={handleEndDateChange}
              showAddressErrorMsg={showAddressErrorMsg}
              showAddressError={showAddressError}
              handleChangeUSPS={handleChangeUSPS}
              considerUSPS={considerUSPS}
              uspsAddres={uspsAddres}
              userInquiryPrivileges = {userInquiryPrivileges}
            />
            {showLogAddressTable ? (
              <div id="audit-Address-table">
                <AuditTableComponent
                  print
                  auditRowData={auditRowData}
                  setAuditRowData={setAuditRowData}
                  setShowLogTable={setShowLogTable}
                />{" "}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}
