import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { mapSetActionView } from '../../../Store/Actions/MapSet/mapSetActions';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { generateUUID } from '../../../../../SharedModules/DateUtilities/DateUtilities';
// import { FixedSizeList } from 'react-window';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 150,
    maxWidth: 300
    // backgroundColor: theme.palette.background.paper,
  }
}));

function MapsetIDList(props) {
  //   const classes = useStyles();
  const dispatch = useDispatch();

  // API dispatch for Mapset Search API.
  const onSearchView = searchviewvalues => dispatch(mapSetActionView(searchviewvalues));
  // Mapset Record.
  const Record = useSelector(state => state.appConfigState.mapSetState.mapSetView);

  const [redirect, setRedirect] = React.useState(0);
  const [mapID, setMapID] = React.useState(null);
  // Method invoked on click of MapId ---> Invokes search API for MapSet record.
  const redirectToMapsetUpdate = MapId => event => {
    props.openMapsetPopUpDialog();
    setMapID(MapId);
  };

  React.useEffect(() => {
    if (props.redirectToMapset) {
      const searchCriteria = {
        mapSetID: mapID
      };
      onSearchView(searchCriteria);
    }
  }, [props.redirectToMapset]);
  // Redirect useEffect to Mapset Edit screen.
  React.useEffect(() => {
    if (props.redirectToMapset) {
      if (Record && Record[0]) {
        // Record.map(var1 => {
        //   var1.mapDefDetail.map(var2 => {
        //     var2.id = generateUUID();
        //     props.functionalAreaReference.map(fDesc => { if (var2.functionalArea === fDesc.code) { var2.functionalAreaDesc = fDesc.description; } });
        //   });
        // });
        props.history.push({
          pathname: '/MapSetEdit',
          state: { payloadViewData: Record }
        });
      }
    }
  }, [Record]);
  const [cursor, setCursor] = React.useState(0);
  const handleKeyDown = (e) => {
    // const { cursor, result } = this.state
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38 && cursor > 0) {
      if (cursor > 0) {
        setCursor(cursor - 1);
      } else {
        setCursor(0);
      }
    } else if (e.keyCode === 40 && cursor < props.MapIdList.length - 1) {
      setCursor(cursor + 1);
    }
  };
  const handleEnterKeyPress = (MapID, event) => {
    var code = event.which;
    if (code === 13) {
      props.openMapsetPopUpDialog();
      setMapID(MapID);
    }
  }

  return (
    <div style={{ overflowY: 'scroll', height: '150px' }}>
      <ul style={{ listStyleType: 'none', marginLeft: '0px', paddingLeft: '10px' }} onKeyDown={handleKeyDown}>
        {props.MapIdList.map((MapID, i) => {
          return (
            <a id={MapID} className={MapID === mapID ? 'mapdefinition-li-active' : null} style={{ textDecorationLine: 'underline', color: 'blue', cursor: 'pointer' }} onClick={redirectToMapsetUpdate(MapID)} onKeyPress={(e) => handleEnterKeyPress(MapID, e)} ><li className='mapdefinition-li-active1' tabindex="0">{MapID}</li></a>
          );
        })}
      </ul>
    </div>
  );
}
export default withRouter(MapsetIDList);
