/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/
import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import numeral from 'numeral';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import moment from "moment";
import { getLongDescription } from '../../../../../../SharedModules/CommonUtilities/commonUtilities';
import NativeDropDown from '../../../../../../SharedModules/Dropdowns/NativeDropDown';
import { useSelector } from 'react-redux';
import {
  compareTwoDatesGreaterThanOrEqual
} from "../../../../../../SharedModules/DateUtilities/DateUtilities";
import * as AppConstants from "../../../../../../SharedModules/AppConstants";
export default function GrossLevelDetails (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const [reasonCd, setReasonCd] = useState([]);
  const reasonCdData = useSelector(state => state.appConfigState.systemListState.payload);

  useEffect(() => {
    if (reasonCdData !== undefined && reasonCdData !== null) {
      const date = moment(new Date()).format("MM/DD/YYYY");
      const nonVoidedRsnCdsList = reasonCdData[0]?.systemListDetails?.filter(
        (code, index) => code.voidDate === null
		  );
		  const nonVoidedEndDtRsnCdsList = nonVoidedRsnCdsList.filter(
        (code, index) => compareTwoDatesGreaterThanOrEqual(date, code.endDate)
		  );
		  const nonVoidedRsnCds = nonVoidedEndDtRsnCdsList?.map(rsnCd => rsnCd.startingValue);
		  setReasonCd(nonVoidedRsnCds);
    }
  }, [reasonCdData]);

  return (
    <div>
      <form autoComplete="off">
        <div className="form-wrapper">
          <div className="mui-custom-form override-m-1">
            <TextField
              id="standard-GrossDetailsFCN"
              fullWidth
              label="FCN"
              disabled
              placeholder=""
              inputProps={{ maxlength: 14 }}
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.fcn}
            >
            </TextField>
          </div>
          <div className="mui-custom-form input-md override-m-1">
            <TextField
              required
              fullWidth
              id="standard-GrossDetailsOriginalA/RAmount"
              label="Original A/R Amount"
              placeholder=""
              inputProps={{ maxlength: 12, "data-testid": "original_AR__amount_testid" }}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              value={props.values.originalARAmount}
              onBlur={props.formatDecimalField('originalARAmount')}
              onFocus={props.removeFormatDecimalField('originalARAmount')}
              onChange={props.handleChange('originalARAmount')}
              helperText={props.errors[0] ? props.errorText[0] : null}
              error={props.errors[0] ? props.errorText[0] : null}
              onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
              onInput={e => {
                e.target.value = e.target.value.replace(
                  /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                  ''
                );
              }}
            >
            </TextField>
          </div>
          <div className="mui-custom-form input-md override-m-1">
            <TextField
              id="standard-GrossDetailsAdjustedA/RAmount"
              fullWidth
              label="Adjusted A/R Amount"
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' }}
              placeholder=""
              inputProps={{ maxlength: 11 }}
              InputLabelProps={{
                shrink: true
              }}
              value={numeral(props.values.adjustedARAmount).format('0,0.00')}
            >
            </TextField>
          </div>
          <div className="mui-custom-form  input-sm override-m-1" >
            <TextField
              id="standard-GrossDetailsBalanceAmount"
              fullWidth
              label="Balance Amount"
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' }}
              placeholder=""
              inputProps={{ maxlength: 11 }}
              InputLabelProps={{
                shrink: true
              }}
              value={numeral(props.values.balanceAmount).format('0,0.00')}
            >
            </TextField>
          </div>
          <div className="mui-custom-form override-m-1 override-width-10" style={{ lineHeight: '100%' }}>
            <label className='MuiFormLabel-root small-label' >Approval<span>*</span></label>
            <div className="sub-radio no-margin">
              <Radio
                value="Y"
                id="GroosLevelDetailsapprovalIdYes"
                checked={props.values.approval === 'Y'}
                onChange={props.handleChange('approval')}
              /><label className="text-black" htmlFor="GroosLevelDetailsapprovalIdYes">Yes</label>
              <Radio
                value="N"
                id="GroosLevelDetailsapprovalIdNo"
                checked={props.values.approval === 'N'}
                onChange={props.handleChange('approval')}
                className="ml-2"
              /><label className="text-black" htmlFor="GroosLevelDetailsapprovalIdNo">No</label>
            </div>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form  input-md override-m-1">
            <TextField
              id="standard-GrossDetailsRelatedFTTCN"
              fullWidth
              label="Related FT TCN"
              disabled
              placeholder=""
              inputProps={{ maxlength: 17 }}
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.relatedFTTCN}
            >
            </TextField>
          </div>
          <div className="mui-custom-form  input-md override-m-1">
            <TextField
              id="standard-GrossDetailsEstablishedDate"
              fullWidth
              label="Established Date"
              disabled
              placeholder=""
              inputProps={{ maxlength: 10 }}
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.establishedDate}
            >
            </TextField>
          </div>
          <div className="mui-custom-form  input-md override-m-1">
            <TextField
              id="standard-GrossDetailsLastDateApplied"
              fullWidth
              label="Last Date Applied"
              disabled
              placeholder=""
              inputProps={{ maxlength: 10 }}
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.lastDateApplied}
            >
            </TextField>
          </div>
          <div className="mui-custom-form  input-sm override-m-1">
            <TextField
              id="standard-GrossDetailsA/RAgeinDays"
              fullWidth
              label="A/R Age in Days"
              disabled
              value={props.values.arAgeInDays}
              placeholder=""
              inputProps={{ maxlength: 3 }}
              InputLabelProps={{
                shrink: true
              }}
            >
            </TextField>
          </div>
          <div className="mui-custom-form override-m-1 override-width-10" style={{ lineHeight: '100%' }}>
            <label className='MuiFormLabel-root small-label' >Under Appeal<span>*</span></label>
            <div className="sub-radio no-margin">
              <Radio
                value="Y"
                id="GrossLevelDetailsunderAppealId1Y"
                checked={props.values.underAppeal === 'Y'}
                onChange={props.handleChange('underAppeal')}
              /><label className="text-black" htmlFor="GrossLevelDetailsunderAppealId1Y">Yes</label>
              <Radio
                value="N"
                id="GrossLevelDetailsunderAppealId1N"
                checked={props.values.underAppeal === 'N'}
                onChange={props.handleChange('underAppeal')}
                className="ml-2"
              /><label className="text-black" htmlFor="GrossLevelDetailsunderAppealId1N">No</label>
            </div>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form  input-md override-m-1">
            <TextField
              id="standard-GrossDetailsA/RLetterSent"
              fullWidth
              label="A/R Letter Sent "
              disabled
              placeholder=""
              inputProps={{ maxlength: 3 }}
              InputLabelProps={{
                shrink: true
              }}
              value={props.values.arLettersent}
            >
            </TextField>
          </div>
          {<MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="mui-custom-form with-date  override-m-1">
              <KeyboardDatePicker
                fullWidth
                required = {!!reasonCd.includes(props.values.reasonCode)}
                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                id="GrossLevelDetailBegindate-picker-dialog"
                label="Begin Date"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                minDate={'01/01/1964'}
                value={props.selectedBeginDate}
                onChange={props.handleBeginDateChange}
                InputLabelProps={{
                  shrink: true
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                disabled={!(reasonCd.includes(props.values.reasonCode.includes("-") ? props.values.reasonCode.split("-")[0] : props.values.reasonCode))}
                helperText={props.errors[4] ? props.errorText[4] : null || props.errors[7] ? props.errorText[7] : null || props.errors[13] ? props.errorText[13] : null}
                error={props.errors[4] ? props.errorText[4] : null || props.errors[7] ? props.errorText[7] : null || props.errors[13] ? props.errorText[13] : null}
              />
            </div>
          </MuiPickersUtilsProvider>}
          {<MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="mui-custom-form with-date  override-m-1">
              <KeyboardDatePicker
                fullWidth
                required = {!!reasonCd.includes(props.values.reasonCode)}
                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                id="GrossLevelDetailEndate-picker-dialog"
                label="End Date"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                value={props.selectedEndDate}
                onChange={props.handleEndDateChange}
                InputLabelProps={{
                  shrink: true
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                disabled={!reasonCd.includes(props.values.reasonCode.includes("-") ? props.values.reasonCode.split("-")[0] : props.values.reasonCode)}
                helperText={props.errors[5] ? props.errorText[5] : null || props.errors[8] ? props.errorText[8] : null || props.errors[6] ? props.errorText[6] : null || props.errors[14] ? props.errorText[14] : null}
                error={props.errors[5] ? props.errorText[5] : null || props.errors[8] ? props.errorText[8] : null || props.errors[6] ? props.errorText[6] : null || props.errors[14] ? props.errorText[14] : null}
              />
            </div>
          </MuiPickersUtilsProvider>}
          <NativeDropDown
            className='override-m-1'
            id="payeeStatus"
            label="Payee Status"
            value={props.values.payeeStatus || DEFAULT_DD_VALUE}
            onChange={props.handleChange('payeeStatus')}
            title={getLongDescription(props.payeePaymentStatusFrmState, props.values.payeeStatus)}
            dropdownList={props.payeePaymentStatusFrmState
              ? props.payeePaymentStatusFrmState.map((item, index) => (
                <option key={index} value={item.code}>
                  {item.description}
                </option>
              ))
              : null}
          />
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form  input-md override-m-1">
            <TextField
              id='ATRnumber'
              fullWidth
              label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
              placeholder=""
              value={props.values.atrNumber}
              onChange={props.handleChange('atrNumber')}
              inputProps={{ maxlength: 18 }}
              InputLabelProps={{
                shrink: true
              }}
              helperText={props.errors[17] ? `${props.errorText[17]}` : null}
              error={props.errors[17] ? `${props.errorText[17]}` : null}
            ></TextField>
          </div>

        </div>
        <div className="tab-holder">
          <div className="row mb-2">
            <div className="col-5" style={{ maxWidth: '398px', marginLeft: '0px', paddingLeft: '0px' }}>
              <fieldset className="custom-fieldset">
                <legend>Account:</legend>
                <div className="form-wrapper vertical-wrapper">
                  <div className="mui-custom-form input-md min-md">
                    <TextField
                      required
                      fullWidth
                      id="standard-GrossDetailsFiscalYear"
                      label="Fiscal Year"
                      placeholder=""
                      inputProps={{ maxlength: 11 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      type="number"
                      value={props.values.fiscalYear}
                      onChange={props.handleChange('fiscalYear')}
                      helperText={props.errors[1] ? props.errorText[1] : null}
                      error={props.errors[1] ? props.errorText[1] : null}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4);
                      }}
                      onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                    >
                    </TextField>
                  </div>
                  <NativeDropDown
                    className='min-md'
                    id="standard-GrossDetailsCategoryofService"
                    label="Category of Service"
                    value={props.values.categoryOfService}
                    onChange={props.handleChange('categoryOfService')}
                    inputProps={{ maxlength: 3 }}
                    title={getLongDescription(props.values.categoryOfServiceData, props.values.categoryOfService)}
                    dropdownList={props.values.categoryOfServiceData.map((option, index) => (
                      <option key={index} value={option.code}>
                        {option.description}
                      </option>
                    ))}

                  />
                  <NativeDropDown
                    className='min-md'
                    isRequired
                    id="standard-GrossDetailsFundCode"
                    label="Fund Code"
                    value={props.values.collocationCode}
                    onChange={props.handleChange('collocationCode')}
                    inputProps={{ maxlength: 10, "data-testid": "fund_code_testid" }}
                    errTxt={props.errors[2] ? props.errorText[2] : null}
                    title={getLongDescription(props.values.collocationCodeData, props.values.collocationCode)}
                    dropdownList={props.values.collocationCodeData.map((option, index) => (
                      <option key={index} value={option.code}>
                        {option.description}
                      </option>
                    ))}
                  />
                </div>
              </fieldset>
            </div>
            <div className="col-7" style={{ paddingLeft: '78px', paddingRight: '0px', width: '551px' }}>

              <fieldset className="custom-fieldset">
                <legend>Recoupment:</legend>
                <div className="form-wrapper">

                </div>
                <div className="form-wrapper">
                  <NativeDropDown
                    className='min-md'

                    isRequired={props.values.underAppeal === 'N'}
                    id="standard-GrossDetailsRecoupment"
                    label="Recoupment Type Code"
                    value={props.values.recoupmentTypeCode}
                    onChange={props.handleChange('recoupmentTypeCode')}
                    inputProps={{ maxlength: 1,"data-testid":"test_recoupmentType" }}
                    errTxt={props.errors[3] ? props.errorText[3] : null}
                    disabled={props.values.underAppeal === 'Y'}
                    dropdownList={props.values.recoupmentData.map((option, index) => (
                      <option key={index} value={option.code}>
                        {option.description}
                      </option>
                    ))}

                  />
                  <div className="mui-custom-form  min-md">
                    <TextField
                      required={props.values.recoupmentTypeCode === 'A'}
                      fullWidth
                      id="standard-GrossDetailsInstallmentValue"
                      label="Installment Value"
                      placeholder=""
                      inputProps={{ maxlength: 14 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      value={props.values.installmentValue}
                      onChange={props.handleChange('installmentValue')}
                      disabled={props.values.underAppeal === 'Y' || props.values.recoupmentTypeCode === 'S'}
                      helperText={props.errors[10] ? props.errorText[10] : null}
                      error={props.errors[10] ? props.errorText[10] : null}
                      onInput={e => {
                        e.target.value = e.target.value.replace(
                          /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                          ''
                        );
                      }}
                    >
                    </TextField>
                  </div>

                </div>
                <div className="form-wrapper">
                  <div className="mui-custom-form  min-md" >
                    <TextField
                      id="standard-GrossDetailPercentValue"
                      fullWidth
                      required={props.values.recoupmentTypeCode === 'B' || props.values.recoupmentTypeCode === 'D'}
                      label="Percent Value"
                      placeholder=""
                      inputProps={{ maxlength: 14 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      value={props.values.percentValue}
                      onChange={props.handleChange('percentValue')}
                      disabled={props.values.underAppeal === 'Y' || props.values.recoupmentTypeCode === 'S'}
                      helperText={props.errors[9] ? props.errorText[9] : null}
                      error={props.errors[9] ? props.errorText[9] : null}
                      onBlur={props.formatPercent('percentValue')}
                      onInput={e => {
                        e.target.value = e.target.value.replace(
                          /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                          ''
                        );
                      }}
                    >

                    </TextField>
                  </div>
                  {<MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="mui-custom-form with-date min-md">
                      <KeyboardDatePicker
                        maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                        id="GrossLevelDetailStartDate-picker-dialog"
                        fullWidth
                        label="Start Date"
                        format="MM/dd/yyyy"
                        placeholder="mm/dd/yyyy"
                        minDate={new Date()}
                        value={props.selectedRecoupmentDate}
                        onChange={props.handleRecoupmentDateChange}
                        InputLabelProps={{
                          shrink: true
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        disabled={props.values.underAppeal === 'Y' || props.values.recoupmentTypeCode === 'S'}
                        helperText={props.errors[11] ? props.errorText[11] : null || props.errors[12] ? props.errorText[12] : null || props.errors[15] ? props.errorText[15] : null}
                        error={props.errors[11] ? props.errorText[11] : null || props.errors[12] ? props.errorText[12] : null || props.errors[15] ? props.errorText[15] : null}
                        required={props.values.recoupmentTypeCode === 'B' || props.values.recoupmentTypeCode === 'A' || props.values.recoupmentTypeCode === 'D'}
                      />
                    </div>
                  </MuiPickersUtilsProvider>}
                </div>
                <div className="form-wrapper">
                  <NativeDropDown
                    className='min-md'
                    isRequired={props.values.recoupmentTypeCode ===AppConstants.RECOUPMENTTYPECODE_PERCENTAMT || props.values.recoupmentTypeCode === AppConstants.RECOUPMENTTYPECODE_DOLLARAMT}
                    id="standard-GrossDetailsFrequency"
                    label="Frequency"
                    value={props.values.frequency}
                    onChange={props.handleChange('frequency')}
                    inputProps={{ maxlength: 2 ,"data-testid":"test_frequency"}}
                    disabled={props.values.underAppeal === 'Y' || props.values.recoupmentTypeCode === 'S'}
                    errTxt={props.errors[16] ? props.errorText[16] : null}
                    dropdownList={props.frequencyData.map((option, index) => (
                      <option key={index} value={option.code}>
                        {option.description}
                      </option>
                    ))}
                  />
                  <div className="mui-custom-form override-width-95" style={{ margin: '0px', fontSize: '0.938em', padding: '4px 0px 10px 14px' }}>
                    <a onClick={props.defaultRecoupment} className="has-link" style={{ color: 'blue', cursor: 'pointer' }}>
                      Use Payee Default Recoupment
                    </a>
                  </div>

                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </form >
    </div >
  );
}
