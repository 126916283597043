/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/
import { CLAIMS_INQUIRY_COMP } from "../../SharedModules/services/service";

const dataToUrlParam = (rowData) => {
  const objStr = JSON.stringify(rowData);
  const toBase64 = btoa(unescape(encodeURIComponent(objStr)));
  return toBase64;
};
export const redirectGrossLevelFCN = (values) => {
  const datatoURL = dataToUrlParam(values);
  return "/FinancialAccountGrossLevelUpdate?data=" + datatoURL;
};
export const redirectSystemID = (values) => {
  const datatoURL = dataToUrlParam(values);
  return "/FinancialEntityUpdate?data=" + datatoURL;
};
const header = {};

export const redirectPayeeID = (values) => {
  const datatoURL = dataToUrlParam({
    commonEntityTypeCode: null,
    entityIDTypeCode: null,
    payeePaymentStatus: null,
    entityID: values,
    payeeNameStartsWithOrContains: null,
    payeeName: null,
  });
  return "/FinancialEntitySearch?data=" + datatoURL;
};
export const redirectReceiptFCN = (values) => {
  const datatoURL = dataToUrlParam({
    fcn: values,
    fcnSearch: true,
  });
  return "/FinancialReceiptUpdate?data=" + datatoURL;
};
export const redirectPayoutFCN = (values) => {
  const datatoURL = dataToUrlParam({
    fcn: values,
    searchBy: "searchByFCN",
    fcnSearch: 1,
    payeeSearch: 0,
    payeeType: null,
    payeeID: null,
    payeeIDTypeCode: null,
  });
  return "/FinancialPayoutUpdate?data=" + datatoURL;
};

const renderCom = () => {
  return `${CLAIMS_INQUIRY_COMP}`;
};
export const navigateToClaims = (href, userName, accessToken, refreshToken) => {
  renderCom();
  const url = `${href}&user=${userName}&accessToken=${accessToken}&refreshToken=${refreshToken}&externalRedirect=yes`;
  return url;
};
