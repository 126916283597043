// eslint-disable-next-line no-unused-vars
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as FinancialEntityConstants from './FinancialEntityConstants';
import * as DateUtilities from '../../../SharedModules/DateUtilities/DateUtilities';
import moment from 'moment';
let validateErrorMsg = [];

const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE
export const validateAddressStatus = (addressTableData, systemParamAddresscheck, errorMessages, seterrorMessages) => {
  const tempErrMsgArray = validateErrorMsg;
  let _isIPvalid = true;
  if (addressTableData && addressTableData.length === 0) {
    _isIPvalid = false;
    let message = `Atleast one active ${systemParamAddresscheck === 'B' ? 'Billing' : 'Mailing'} address should be present.`;
    tempErrMsgArray.push(message);
  } else if (addressTableData && addressTableData.length > 0) {
    let count = 0;
    for (let i = 0; i <= addressTableData.length - 1; i++) {
      if (addressTableData[i].addressType === systemParamAddresscheck && new Date(moment().format('L')) <= new Date(addressTableData[i].endDate) && new Date(moment().format('L')) >= new Date(addressTableData[i].beginDate)) {
        count = count + 1;
      }
    }
    if (count === 0) {
      _isIPvalid = false;
      let message = `Atleast one active ${systemParamAddresscheck === 'B' ? 'Billing' : 'Mailing'} address should be present.`;
      tempErrMsgArray.push(message);
    }

  }
  seterrorMessages(tempErrMsgArray);

  return _isIPvalid;
}
export const validatePayeeStatusLength = (payeeStatusTable, addressTableData, systemParamAddresscheck, errorMessages, seterrorMessages) => {
  const tempErrMsgArray = validateErrorMsg;
  let _isIPvalid = true;
  if (payeeStatusTable && payeeStatusTable.length === 0) {
    _isIPvalid = false;
    let message = FinancialEntityConstants.ATLEAST_ONE_PAYEE;
    tempErrMsgArray.push(message);
  }
  seterrorMessages(tempErrMsgArray);

  return _isIPvalid;
}

// Validate Payee Tab Details
export const validatePayeeDetails = (payeeTab, payeeDob, payeeStatusTable, setShowPayeeError, setPayeeErrMsg, seterrorMessages, validatePayeeOnSave) => {
  const tempErrMsgArray = [];
  let _isIPvalid = true;
  let _showPTypeErr, _showPIDTypeErr, _showPIDErr, _showDateErr, _showPayeeNameErr;
  _showPTypeErr = _showPIDTypeErr = _showPIDErr = _showDateErr = _showPayeeNameErr = false;
  let _pTypeErrMsg, _pIDTypeErrMsg, _pIDErrMsg, _showDateErrMsg, _showPayeeNameErrMsg;
  _pTypeErrMsg = _pIDTypeErrMsg = _pIDErrMsg = _showDateErrMsg = _showPayeeNameErrMsg = '';
  // Payee DOB Validation
  if (payeeDob && payeeDob.toString() === 'Invalid Date') {
    _isIPvalid = false;
    _showDateErr = true;
    _showDateErrMsg = FinancialEntityConstants.INVALID_DATE_FORMAT;
    tempErrMsgArray.push(_showDateErrMsg);
  }
  // Payee Name Validation
  if (validatePayeeOnSave && ((!payeeTab.payeePayerName) || (payeeTab.payeePayerName && !payeeTab.payeePayerName.trim()))) {
    _isIPvalid = false;
    _showPayeeNameErr = true;
    _showPayeeNameErrMsg = FinancialEntityConstants.PAYEE_NAME_REQ;
    tempErrMsgArray.push(_showPayeeNameErrMsg);
  }
  if (payeeTab.payeePayerType === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showPTypeErr = true;
    _pTypeErrMsg = FinancialEntityConstants.PAYEETYPE_REQUIRED;
    tempErrMsgArray.push(_pTypeErrMsg);
  }
  if (payeeTab.entityIDTypeCD === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showPIDTypeErr = true;
    _pIDTypeErrMsg = FinancialEntityConstants.PAYEEIDTYPECODE_REQUIRED;
    tempErrMsgArray.push(_pIDTypeErrMsg);
  }
  if (payeeTab.entityID === '' || payeeTab.entityID === null || payeeTab.entityID === undefined || (payeeTab.entityID && !payeeTab.entityID.trim())) {
    _isIPvalid = false;
    _showPIDErr = true;
    _pIDErrMsg = FinancialEntityConstants.PAYEEID_REQUIRED;
    tempErrMsgArray.push(_pIDErrMsg);
  }
  setShowPayeeError({
    showPTypeErr: _showPTypeErr,
    showPIDTypeErr: _showPIDTypeErr,
    showPIDErr: _showPIDErr,
    showPayeeNameErr: _showPayeeNameErr,
    showDOBErr: _showDateErr
  });
  setPayeeErrMsg({
    pTypeErrMsg: _pTypeErrMsg,
    pIDTypeErrMsg: _pIDTypeErrMsg,
    pIDErrMsg: _pIDErrMsg,
    showPayeeNameErrMsg: _showPayeeNameErrMsg,
    showDOBErrMsg: _showDateErrMsg
  });
  validateErrorMsg = tempErrMsgArray;
  seterrorMessages(tempErrMsgArray);
  return _isIPvalid;
};
export const validateRecoupmentDetails = (RecoupmentTabValues, setShowRecoupmentError, setRecoupmentErrMsg, setRecoupmentErrorArray) => {
  const tempErrMsgArray = [];
  let _isIPvalid = true;
  let _showInstallmentErr, _showLogDaysErr, _showPecentValueErr, _showFrequencyErr, _showMaxWithholdAmtErr;
  _showInstallmentErr = _showLogDaysErr = _showPecentValueErr = _showFrequencyErr = _showMaxWithholdAmtErr = false;
  let _installmentErrMsg, _logDaysErrMsg, _percentValueErrMsg, _frequencyErrMsg, _showMaxWithholdAmtErrMsg;
  _installmentErrMsg = _logDaysErrMsg = _percentValueErrMsg = _frequencyErrMsg = _showMaxWithholdAmtErrMsg = '';
  let flagInvalidFormat = false;
  var regex = /^[0-9]{1,7}(\.\d{1,2})?%?$/;
  var regex_max = /^[0-9]{1,11}(\.\d{1,2})?%?$/;
  //Max Withhold Amount Validation starts
  if (RecoupmentTabValues.maxWithholdAmount && RecoupmentTabValues.maxWithholdAmount.trim() && !regex_max.test(RecoupmentTabValues.maxWithholdAmount.replace(/,/g, ''))) {
    if (!(RecoupmentTabValues.maxWithholdAmount < 0)) {
      _isIPvalid = false;
      _showMaxWithholdAmtErr = true;
      _showMaxWithholdAmtErrMsg = FinancialEntityConstants.INVALID_DATA_FORMAT;
      flagInvalidFormat = true;
      tempErrMsgArray.push(_showMaxWithholdAmtErrMsg);
    }
  }
  if (RecoupmentTabValues.maxWithholdAmount && RecoupmentTabValues.maxWithholdAmount.trim()) {
    if (parseFloat(RecoupmentTabValues.maxWithholdAmount) < 0) {
      _isIPvalid = false;
      _showMaxWithholdAmtErr = true;
      _showMaxWithholdAmtErrMsg = FinancialEntityConstants.INVALID_DATA_FORMAT;
      tempErrMsgArray.push(_showMaxWithholdAmtErrMsg);
    }
  }
  //Max Withhold Amount Validation ends
  if (RecoupmentTabValues.installmentvalue && RecoupmentTabValues.installmentvalue.trim() && !regex.test(RecoupmentTabValues.installmentvalue.replace(/,/g, ''))) {
    if (!(RecoupmentTabValues.installmentvalue < 0)) {
      _isIPvalid = false;
      _showInstallmentErr = true;
      _installmentErrMsg = FinancialEntityConstants.INVALID_DATA_FORMAT;
      flagInvalidFormat = true;
      tempErrMsgArray.push(_installmentErrMsg);
    }
  }
  if (RecoupmentTabValues.installmentvalue && RecoupmentTabValues.installmentvalue.trim()) {
    if (parseFloat(RecoupmentTabValues.installmentvalue) < 0) {
      _isIPvalid = false;
      _showInstallmentErr = true;
      _installmentErrMsg = FinancialEntityConstants.INSTALLMENTVALUE_SHOULDNOT_LESSTHAN_0;
      tempErrMsgArray.push(_installmentErrMsg);
      tempErrMsgArray.push(FinancialEntityConstants.INSTALLMENTVALUE_SHOULDNOT_BE_EQUAL_0);
    }
    if (parseFloat(RecoupmentTabValues.installmentvalue) === 0) {
      _isIPvalid = false;
      _showInstallmentErr = true;
      _installmentErrMsg = FinancialEntityConstants.INSTALLMENTVALUE_SHOULDNOT_BE_EQUAL_0;
      tempErrMsgArray.push(_installmentErrMsg);
    }
  }
  var regexPercent = /^[0-9]{1,7}(\.\d{1,2})?%?$/;
  if (RecoupmentTabValues.percentagevalue && RecoupmentTabValues.percentagevalue.trim() && !(RecoupmentTabValues.percentagevalue < 0) && !regexPercent.test(RecoupmentTabValues.percentagevalue)) {
    _isIPvalid = false;
    _showPecentValueErr = true;
    _percentValueErrMsg = FinancialEntityConstants.INVALID_DATA_FORMAT;
    if (!flagInvalidFormat) {
      tempErrMsgArray.push(_percentValueErrMsg);
    }
  }
  if (RecoupmentTabValues.percentagevalue > 100 && !_showPecentValueErr) {
    _isIPvalid = false;
    _showPecentValueErr = true;
    _percentValueErrMsg = FinancialEntityConstants.PERSENTVALUE_SHOULDNOT_GREATERTHAN_100;
    tempErrMsgArray.push(_percentValueErrMsg);
    tempErrMsgArray.push(FinancialEntityConstants.PERCENT_VAL_BETWEEN_0_100);
  }
  if (RecoupmentTabValues.percentagevalue < 0) {
    _isIPvalid = false;
    _showPecentValueErr = true;
    _percentValueErrMsg = FinancialEntityConstants.PERSENTVALUE_SHOULDNOT_LESSTHAN_0;
    tempErrMsgArray.push(_percentValueErrMsg);
    tempErrMsgArray.push(FinancialEntityConstants.PERCENT_VAL_BETWEEN_0_100);
  }
  if (RecoupmentTabValues.installmentvalue !== '' && RecoupmentTabValues.installmentvalue !== null &&
    RecoupmentTabValues.percentagevalue !== '' && RecoupmentTabValues.percentagevalue !== null) {
    _isIPvalid = false;
    if (!_showPecentValueErr) {
      _showPecentValueErr = true;
      _percentValueErrMsg = FinancialEntityConstants.CANNT_ENTER_BOTH_INSTALLMENT_AND_PERCENT;
    }
    if (!_showInstallmentErr) {
      _showInstallmentErr = true;
      _installmentErrMsg = FinancialEntityConstants.CANNT_ENTER_BOTH_INSTALLMENT_AND_PERCENT;
    }
    tempErrMsgArray.push(FinancialEntityConstants.CANNT_ENTER_BOTH_INSTALLMENT_AND_PERCENT);
  }
  if ((RecoupmentTabValues.installmentvalue !== '' && RecoupmentTabValues.installmentvalue !== null) &&
    RecoupmentTabValues.frequency === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showFrequencyErr = true;
    _frequencyErrMsg = FinancialEntityConstants.FREQUENCY_REQUIRED;
    tempErrMsgArray.push(_frequencyErrMsg);
  }
  if ((RecoupmentTabValues.percentagevalue !== '' && RecoupmentTabValues.percentagevalue !== null) &&
    RecoupmentTabValues.frequency === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showFrequencyErr = true;
    _frequencyErrMsg = FinancialEntityConstants.FREQUENCY_REQUIRED;
    tempErrMsgArray.push(_frequencyErrMsg);
  }
  if (RecoupmentTabValues.recoupmentLogDays !== '' && RecoupmentTabValues.recoupmentLogDays !== null &&
    RecoupmentTabValues.recoupmentLogDays <= 0) {
    _isIPvalid = false;
    _showLogDaysErr = true;
    _logDaysErrMsg = FinancialEntityConstants.RECOUPMENT_LOG_DAY_MUSTBE_GREATERTHAN_ZERO;
    tempErrMsgArray.push(_logDaysErrMsg);
  }
  if (RecoupmentTabValues.recoupmentLogDays && RecoupmentTabValues.recoupmentLogDays.trim() && !regex.test(RecoupmentTabValues.recoupmentLogDays)) {
    if (!(RecoupmentTabValues.recoupmentLogDays < 0)) {
      _isIPvalid = false;
      _showLogDaysErr = true;
      _logDaysErrMsg = FinancialEntityConstants.INVALID_DATA_FORMAT;
      flagInvalidFormat = true;
      tempErrMsgArray.push(_logDaysErrMsg);
    }
  }
  setShowRecoupmentError({
    showInstallmentErr: _showInstallmentErr,
    showLogDaysErr: _showLogDaysErr,
    showPecentValueErr: _showPecentValueErr,
    showFrequencyErr: _showFrequencyErr,
    showMaxWithholdAmtErr: _showMaxWithholdAmtErr
  });
  setRecoupmentErrMsg({
    installmentErrMsg: _installmentErrMsg,
    logDaysErrMsg: _logDaysErrMsg,
    percentValueErrMsg: _percentValueErrMsg,
    frequencyErrMsg: _frequencyErrMsg,
    showMaxWithholdAmtErrMsg: _showMaxWithholdAmtErrMsg
  });
  setRecoupmentErrorArray(tempErrMsgArray);
  return _isIPvalid;
};

export const validatePayeeIdentifierDetails = (IdentifierDetails, beginDate, endDate, setIdentifierErr, setIdentifierErrMsg, currentPayeeIdentifierRow, tableData,seterrorMessages) => {
  let _isIPvalid = true;
  let idNumberErr, idTypeErr, beginDateErr, endDateErr, minDateError;
  idNumberErr = idTypeErr = beginDateErr = endDateErr = minDateError =false;
  let idNumberErrMsg, idTypeErrMsg, beginDateErrMsg, endDateErrMsg;
  idNumberErrMsg = idTypeErrMsg = beginDateErrMsg = endDateErrMsg = '';
  const errorArray = [];
  if (!IdentifierDetails.idNumber || (IdentifierDetails.idNumber && !IdentifierDetails.idNumber.trim())) {
    _isIPvalid = false;
    idNumberErr = true;
    idNumberErrMsg = FinancialEntityConstants.IDNUMBER_REQ;
    errorArray.push(idNumberErrMsg);
  }
  if (IdentifierDetails.idType === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    idTypeErr = true;
    idTypeErrMsg = FinancialEntityConstants.ID_TYPE_RQ;
    errorArray.push(idTypeErrMsg);
  }
  if (!beginDate) {
    _isIPvalid = false;
    beginDateErr = true;
    beginDateErrMsg = FinancialEntityConstants.BEGIN_DATE_REQ;
    errorArray.push(beginDateErrMsg);
  } else if (beginDate && (beginDate.toString() === 'Invalid Date' || beginDate.toString() === 'Invalid date')) {
    _isIPvalid = false;
    beginDateErr = true;
    beginDateErrMsg = FinancialEntityConstants.INVALID_DATE_FORMAT;
    errorArray.push(beginDateErrMsg);
  }
  if (beginDate && (beginDate.toString() !== 'Invalid Date')) {
    if (DateUtilities.validateDateMinimumValue(beginDate)) {
      _isIPvalid = false;
      beginDateErr = true;
      minDateError = true;
      beginDateErrMsg = FinancialEntityConstants.MIN_DATE_ERRORMSG;
    }
  }
  if (!endDate) {
    _isIPvalid = false;
    endDateErr = true;
    endDateErrMsg = FinancialEntityConstants.END_DATE_REQ;
    errorArray.push(endDateErrMsg);
  } else if (endDate && (endDate.toString() === 'Invalid Date' || endDate.toString() === 'Invalid date')) {
    _isIPvalid = false;
    endDateErr = true;
    endDateErrMsg = FinancialEntityConstants.INVALID_DATE_FORMAT;
    errorArray.push(endDateErrMsg);
  }
  if (endDate && endDate.toString() !== 'Invalid Date') {
    if (DateUtilities.validateDateMinimumValue(endDate)) {
      _isIPvalid = false;
      endDateErr = true;
      minDateError = true;
      endDateErrMsg = FinancialEntityConstants.MIN_DATE_ERRORMSG;
    }
  }
  if (minDateError){
    errorArray.push(FinancialEntityConstants.MIN_DATE_ERRORMSG)
  }
  if (beginDate && endDate &&
    !beginDateErr && !endDateErr &&
    beginDate.toString() !== 'Invalid Date' &&
    endDate.toString() !== 'Invalid Date') {
    if (new Date(beginDate) > new Date(endDate)) {
      _isIPvalid = false;
      endDateErr = true;
      endDateErrMsg = FinancialEntityConstants.ENDATE_SHOULDGREATER_OR_EQUAL_BEGINDATE;
      errorArray.push(endDateErrMsg);
    }
  }
  // Overlap Check
  if (beginDate && endDate &&
    !beginDateErr && !endDateErr &&
    beginDate.toString() !== 'Invalid Date' &&
    endDate.toString() !== 'Invalid Date') {
    if (!(beginDate > endDate)) {
      let overlapFlag = false;
      if (IdentifierDetails && IdentifierDetails !== null) {
        tableData.map((value, index) => {
          if (value.id && IdentifierDetails.id) {
            if (!(value.mainRecord && IdentifierDetails.mainRecord) && (value.id !== IdentifierDetails.id)) {
              if (value.idType === IdentifierDetails.idType && ((new Date(value.beginDate) <= new Date(beginDate)) && (new Date(beginDate) <= new Date(value.endDate)))) {
                _isIPvalid = false;
                beginDateErr = true;
                beginDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
              }
              if (value.idType === IdentifierDetails.idType && ((new Date(value.beginDate) <= new Date(endDate)) && (new Date(endDate) <= new Date(value.endDate)))) {
                _isIPvalid = false;
                endDateErr = true;
                endDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
              }
              if (value.idType === IdentifierDetails.idType && ((new Date(beginDate) <= new Date(value.beginDate)) && (new Date(endDate) >= new Date(value.beginDate)))) {
                _isIPvalid = false;
                endDateErr = true;
                endDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
              }
              if (value.idType === IdentifierDetails.idType && ((new Date(beginDate) === new Date(value.endDate)) || (new Date(beginDate) === new Date(value.beginDate)))) {
                _isIPvalid = false;
                beginDateErr = true;
                beginDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
              }
              if (value.idType === IdentifierDetails.idType && ((new Date(endDate) === new Date(value.endDate)) || (new Date(endDate) === new Date(value.beginDate)))) {
                _isIPvalid = false;
                endDateErr = true;
                endDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
              }
            }
          } else if ((value.mainRecord && IdentifierDetails.id) || (value.id && IdentifierDetails.mainRecord)) {
            if (value.idType === IdentifierDetails.idType && ((new Date(value.beginDate) <= new Date(beginDate)) && (new Date(beginDate) <= new Date(value.endDate)))) {
              _isIPvalid = false;
              beginDateErr = true;
              beginDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
            }
            if (value.idType === IdentifierDetails.idType && ((new Date(value.beginDate) <= new Date(endDate)) && (new Date(endDate) <= new Date(value.endDate)))) {
              _isIPvalid = false;
              endDateErr = true;
              endDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
            }
            if (value.idType === IdentifierDetails.idType && ((new Date(beginDate) <= new Date(value.beginDate)) && (new Date(endDate) >= new Date(value.beginDate)))) {
              _isIPvalid = false;
              endDateErr = true;
              endDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
            }
            if (value.idType === IdentifierDetails.idType && ((new Date(beginDate) === new Date(value.endDate)) || (new Date(beginDate) === new Date(value.beginDate)))) {
              _isIPvalid = false;
              beginDateErr = true;
              beginDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
            }
            if (value.idType === IdentifierDetails.idType && ((new Date(endDate) === new Date(value.endDate)) || (new Date(endDate) === new Date(value.beginDate)))) {
              _isIPvalid = false;
              endDateErr = true;
              endDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
            }
          }
          else {
            if (
              value.altIdSk !==
              IdentifierDetails.altIdSk
            ) {
              if (value.idType === IdentifierDetails.idType && ((new Date(value.beginDate) <= new Date(beginDate)) && (new Date(beginDate) <= new Date(value.endDate)))) {
                _isIPvalid = false;
                beginDateErr = true;
                beginDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
              }
              if (value.idType === IdentifierDetails.idType && ((new Date(value.beginDate) <= new Date(endDate)) && (new Date(endDate) <= new Date(value.endDate)))) {
                _isIPvalid = false;
                endDateErr = true;
                endDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
              }
              if (value.idType === IdentifierDetails.idType && ((new Date(beginDate) <= new Date(value.beginDate)) && (new Date(endDate) >= new Date(value.beginDate)))) {
                _isIPvalid = false;
                endDateErr = true;
                endDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
              }
              if (value.idType === IdentifierDetails.idType && ((new Date(beginDate) === new Date(value.endDate)) || (new Date(beginDate) === new Date(value.beginDate)))) {
                _isIPvalid = false;
                beginDateErr = true;
                beginDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
              }
              if (value.idType === IdentifierDetails.idType && ((new Date(endDate) === new Date(value.endDate)) || (new Date(endDate) === new Date(value.beginDate)))) {
                _isIPvalid = false;
                endDateErr = true;
                endDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
              }
            }
          }
        });

      } else {
        tableData.map((value, index) => {
          if (value.idType === IdentifierDetails.idType && ((new Date(value.beginDate) <= new Date(beginDate)) && (new Date(beginDate) <= new Date(value.endDate)))) {
            _isIPvalid = false;
            beginDateErr = true;
            beginDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
          }
          if (value.idType === IdentifierDetails.idType && ((new Date(value.beginDate) <= new Date(endDate)) && (new Date(endDate) <= new Date(value.endDate)))) {
            _isIPvalid = false;
            endDateErr = true;
            endDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
          }
          if (value.idType === IdentifierDetails.idType && ((new Date(beginDate) <= new Date(value.beginDate)) && (new Date(endDate) >= new Date(value.beginDate)))) {
            _isIPvalid = false;
            endDateErr = true;
            endDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
          }
          if (value.idType === IdentifierDetails.idType && ((new Date(beginDate) === new Date(value.endDate)) || (new Date(beginDate) === new Date(value.beginDate)))) {
            _isIPvalid = false;
            beginDateErr = true;
            beginDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
          }
          if (value.idType === IdentifierDetails.idType && ((new Date(endDate) === new Date(value.endDate)) || (new Date(endDate) === new Date(value.beginDate)))) {
            _isIPvalid = false;
            endDateErr = true;
            endDateErrMsg = FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP;
          }
        });
      }
      if (beginDateErr || endDateErr) {
        errorArray.push(FinancialEntityConstants.PAYEE_IDENTIFIER_OVERLAP)
      }

    }
  }
  setIdentifierErr({
    idNumber: idNumberErr,
    idType: idTypeErr,
    beginDate: beginDateErr,
    endDate: endDateErrMsg
  });
  setIdentifierErrMsg({
    idNumber: idNumberErrMsg,
    idType: idTypeErrMsg,
    beginDate: beginDateErrMsg,
    endDate: endDateErrMsg
  });
  seterrorMessages(errorArray);
  return _isIPvalid;
}
export const validateAddBankDetails = (bankingDetails, selectedBeginDate, selectedEndDate, setSelectedEndDate, selectedEftDate, selectedPreNoteDate, setShowBankDetailsErr,
  setBankDetailsErrMsg,
  bankingTabTableData,
  bankingTableClkRow,seterrorMessages) => {
  const errorArray = [];
  let _isIPvalid = true;
  let _showBeginDateErr, _showEndDateErr, _showEFTStatusErr, _showEFTStatusDateErr, _showPreNoteDateErr, _showFinancInstiErr, _showBranchErr,
    _showAccTypeErr, _showRoutingNumErr, _showACCNumErr, _showAccHoldNameErr,_showAccountOverlappingErr;
  _showBeginDateErr = _showEndDateErr = _showEFTStatusErr = _showEFTStatusDateErr = _showPreNoteDateErr = _showFinancInstiErr = _showBranchErr =
    _showAccTypeErr = _showRoutingNumErr = _showACCNumErr = _showAccHoldNameErr =_showAccountOverlappingErr = false;
  let _beginDateErrMsg, _endDateErrMsg, _eftStatusErrMsg, _eftStatusDateErrMsg, _preNoteDateErrMsg, _financInstiErrMsg, _branchErrMsg,
    _accTypeErrMsg, _routingNumErrMsg, _accNumerrMsg, _accHoldNameErrMsg;
  _beginDateErrMsg = _endDateErrMsg = _eftStatusErrMsg = _eftStatusDateErrMsg = _preNoteDateErrMsg = _financInstiErrMsg = _branchErrMsg =
    _accTypeErrMsg = _routingNumErrMsg = _accNumerrMsg = _accHoldNameErrMsg = '';
  // Begin Date Validation
  if (selectedBeginDate === null || selectedBeginDate === '' || selectedBeginDate === undefined) {
    _isIPvalid = false;
    _showBeginDateErr = true;
    _beginDateErrMsg = FinancialEntityConstants.BEGINDATE_REQUIRED;
    errorArray.push(_beginDateErrMsg);
  }
  if (selectedBeginDate && selectedBeginDate.toString() === 'Invalid Date') {
    _isIPvalid = false;
    _showBeginDateErr = true;
    _beginDateErrMsg = FinancialEntityConstants.INVALID_DATE_FORMAT;
    errorArray.push(_beginDateErrMsg);
  }
  if (selectedBeginDate && selectedBeginDate.toString() !== 'Invalid Date') {
    if (DateUtilities.validateDateMinimumValue(selectedBeginDate)) {
      _isIPvalid = false;
      _showBeginDateErr = true;
      _beginDateErrMsg = FinancialEntityConstants.MIN_DATE_ERRORMSG;
      errorArray.push(_beginDateErrMsg);
    }
  }
  // End Date Validation
  if (selectedEndDate === null || selectedEndDate === '' || selectedEndDate === undefined) {
    setSelectedEndDate(new Date('12/31/9999'));
  } else if (selectedEndDate && selectedEndDate.toString() === 'Invalid Date') {
    _isIPvalid = false;
    _showEndDateErr = true;
    _endDateErrMsg = FinancialEntityConstants.INVALID_DATE_FORMAT;
    errorArray.push(_endDateErrMsg);
  }
  if (selectedEndDate && selectedEndDate.toString() !== 'Invalid Date') {
    if (DateUtilities.validateDateMinimumValue(selectedEndDate)) {
      _isIPvalid = false;
      _showEndDateErr = true;
      _endDateErrMsg = FinancialEntityConstants.MIN_DATE_ERRORMSG;
      errorArray.push(_endDateErrMsg);
    }
  }
  // Begin Date <= End Date
  if (selectedBeginDate && selectedEndDate &&
    !_showBeginDateErr && !_showEndDateErr &&
    selectedBeginDate.toString() !== 'Invalid Date' &&
    selectedEndDate.toString() !== 'Invalid Date') {
    if (DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedBeginDate)>DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEndDate)) {
      _isIPvalid = false;
      _showEndDateErr = true;
      _endDateErrMsg = FinancialEntityConstants.ENDATE_SHOULDGREATER_OR_EQUAL_BEGINDATE;
      errorArray.push(_endDateErrMsg);
    }
  }
  if (selectedBeginDate && selectedEndDate &&
    !_showBeginDateErr && !_showEndDateErr &&
    selectedBeginDate.toString() !== 'Invalid Date' &&
    selectedEndDate.toString() !== 'Invalid Date') {
    if (!(selectedBeginDate > selectedEndDate)) {
      let overlapFlag = false;
      if (bankingTableClkRow && bankingTableClkRow !== null) {
        bankingTabTableData.map((value, index) => {
          if (value.id) {
            if (value.id !== bankingTableClkRow.id) {
              if ((new Date(value.beginDate) <= new Date(selectedBeginDate)) && (new Date(selectedBeginDate) <= new Date(value.endDate))) {
                _isIPvalid = false;
                _showBeginDateErr = true;
                _beginDateErrMsg = FinancialEntityConstants.BANK_ACCOUNT_OVERLAP;
                _showAccountOverlappingErr = true;
              }
              if ((new Date(value.beginDate) <= new Date(selectedEndDate)) && (new Date(selectedEndDate) <= new Date(value.endDate))) {
                _isIPvalid = false;
                _showEndDateErr = true;
                _endDateErrMsg = FinancialEntityConstants.BANK_ACCOUNT_OVERLAP;
                _showAccountOverlappingErr = true;
              }
            }
          } else {
            if (
              value.payeePayerEffectiveSetupSequenceNumber !==
              bankingTableClkRow.payeePayerEffectiveSetupSequenceNumber
            ) {
              if ((new Date(value.beginDate) <= new Date(selectedBeginDate)) && (new Date(selectedBeginDate) <= new Date(value.endDate))) {
                _isIPvalid = false;
                _showBeginDateErr = true;
                _beginDateErrMsg = FinancialEntityConstants.BANK_ACCOUNT_OVERLAP;
                _showAccountOverlappingErr = true;
              }
              if ((new Date(value.beginDate) <= new Date(selectedEndDate)) && (new Date(selectedEndDate) <= new Date(value.endDate))) {
                _isIPvalid = false;
                _showEndDateErr = true;
                _endDateErrMsg = FinancialEntityConstants.BANK_ACCOUNT_OVERLAP;
                _showAccountOverlappingErr = true;
              }
            }
          }
        });
      } else {
        bankingTabTableData.map((value, index) => {
          if ((new Date(value.beginDate) <= new Date(selectedBeginDate)) && (new Date(selectedBeginDate) <= new Date(value.endDate))) {
            _isIPvalid = false;
            _showBeginDateErr = true;
            _beginDateErrMsg = FinancialEntityConstants.BANK_ACCOUNT_OVERLAP;
            _showAccountOverlappingErr = true;
          }
          if ((new Date(value.beginDate) <= new Date(selectedEndDate)) && (new Date(selectedEndDate) <= new Date(value.endDate))) {
            _isIPvalid = false;
            _showEndDateErr = true;
            _endDateErrMsg = FinancialEntityConstants.BANK_ACCOUNT_OVERLAP;
            _showAccountOverlappingErr = true;
          }
        });
      }

    }
  }
  if(_showAccountOverlappingErr){
    errorArray.push( FinancialEntityConstants.BANK_ACCOUNT_OVERLAP);
  }
  // EFT Status
  if (bankingDetails.eftStatus === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showEFTStatusErr = true;
    _eftStatusErrMsg = FinancialEntityConstants.EFTSTATUS_REQUIRED;
    errorArray.push(_eftStatusErrMsg);
  }
  // EFT Status Date
  if (selectedEftDate === null || selectedEftDate === '' || selectedEftDate === undefined) {
    _isIPvalid = false;
    _showEFTStatusDateErr = true;
    _eftStatusDateErrMsg = FinancialEntityConstants.EFTSTATUS_DATE_REQUIRED;
    errorArray.push(_eftStatusDateErrMsg);
  }
  if (selectedEftDate && selectedEftDate.toString() === 'Invalid Date') {
    _isIPvalid = false;
    _showEFTStatusDateErr = true;
    _eftStatusDateErrMsg = FinancialEntityConstants.INVALID_DATE_FORMAT;
    errorArray.push(_eftStatusDateErrMsg);
  }
  if (selectedEftDate && selectedEftDate.toString() !== 'Invalid Date') {
    if (DateUtilities.validateDateMinimumValue(selectedEftDate)) {
      _isIPvalid = false;
      _showEFTStatusDateErr = true;
      _eftStatusDateErrMsg = FinancialEntityConstants.MIN_DATE_ERRORMSG;
      errorArray.push(_eftStatusDateErrMsg);
    }
  }
  if (selectedEftDate && selectedEftDate.toString() !== 'Invalid Date') {
    if (DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEftDate) >
      DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEndDate) ||
      DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedBeginDate) >
      DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEftDate)) {
      _isIPvalid = false;
      _showEFTStatusDateErr = true;
      _eftStatusDateErrMsg = FinancialEntityConstants.EFT_DATE_FALL_BW_BEGIN_END_DATE;
      errorArray.push(_eftStatusDateErrMsg);
    }
  }
  // Pre Note Date
  if (selectedPreNoteDate && selectedPreNoteDate.toString() === 'Invalid Date') {
    _isIPvalid = false;
    _showPreNoteDateErr = true;
    _preNoteDateErrMsg = FinancialEntityConstants.INVALID_DATE_FORMAT;
    errorArray.push(_preNoteDateErrMsg);
  }
  if (selectedPreNoteDate && selectedPreNoteDate.toString() !== 'Invalid Date') {
    if (DateUtilities.validateDateMinimumValue(selectedPreNoteDate)) {
      _isIPvalid = false;
      _showPreNoteDateErr = true;
      _preNoteDateErrMsg = FinancialEntityConstants.MIN_DATE_ERRORMSG;
      errorArray.push(_preNoteDateErrMsg);
    }
  }
  if (selectedPreNoteDate && selectedPreNoteDate.toString() !== 'Invalid Date' && !_showPreNoteDateErr) {
    if (DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedPreNoteDate) >
    DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEndDate) ||
    DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedBeginDate) >
    DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedPreNoteDate)) {
      _isIPvalid = false;
      _showPreNoteDateErr = true;
      _preNoteDateErrMsg = FinancialEntityConstants.PRE_NOTE_DATE_FALL_BW_BEGIN_END_DATE;
      errorArray.push(_preNoteDateErrMsg);
    }
  }
  // Financial Institution
  if (bankingDetails.financialInstitution === null || bankingDetails.financialInstitution === '' || bankingDetails.financialInstitution === undefined) {
    _isIPvalid = false;
    _showFinancInstiErr = true;
    _financInstiErrMsg = FinancialEntityConstants.FINANCIAL_INSTITUTION_REQUIRED;
    errorArray.push(_financInstiErrMsg);
  }
  // Account Type
  if (bankingDetails.accountType === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showAccTypeErr = true;
    _accTypeErrMsg = FinancialEntityConstants.ACC_TYPE_REQUIRED;
    errorArray.push(_accTypeErrMsg);
  }
  // Routing Number
  if (bankingDetails.routingNumber === null || bankingDetails.routingNumber === '' || bankingDetails.routingNumber === undefined) {
    _isIPvalid = false;
    _showRoutingNumErr = true;
    _routingNumErrMsg = FinancialEntityConstants.ROUTING_NUM_REQUIRED;
    errorArray.push(_routingNumErrMsg);
  } else if (bankingDetails.routingNumber !== null || bankingDetails.routingNumber !== '' || bankingDetails.routingNumber !== undefined) {
    if (bankingDetails.routingNumber.toString().length !== 9) {
      _isIPvalid = false;
      _showRoutingNumErr = true;
      _routingNumErrMsg = FinancialEntityConstants.ROUTING_NUM_LENGTH_MUSTBE_9;
      errorArray.push(_routingNumErrMsg);
    }
  }
  // Branch
  if (bankingDetails.branch === null || bankingDetails.branch === '' || bankingDetails.branch === undefined) {
    _isIPvalid = false;
    _showBranchErr = true;
    _branchErrMsg = FinancialEntityConstants.BRANCH_REQUIRED;
    errorArray.push(_branchErrMsg);
  }
  // Account Number
  if (bankingDetails.accountNumber === null || bankingDetails.accountNumber === '' || bankingDetails.accountNumber === undefined) {
    _isIPvalid = false;
    _showACCNumErr = true;
    _accNumerrMsg = FinancialEntityConstants.ACC_NUM_REQUIRED;
    errorArray.push(_accNumerrMsg);
  }
  // Account Holder Name
  if (bankingDetails.accountHolderName === null || bankingDetails.accountHolderName === '' || bankingDetails.accountHolderName === undefined) {
    _isIPvalid = false;
    _showAccHoldNameErr = true;
    _accHoldNameErrMsg = FinancialEntityConstants.ACC_HOLD_NAME_REQUIRED;
    errorArray.push(_accHoldNameErrMsg);
  }
  // Set Error Messages
  setShowBankDetailsErr({
    showBeginDateErr: _showBeginDateErr,
    showEndDateErr: _showEndDateErr,
    showEFTStatusErr: _showEFTStatusErr,
    showEFTStatusDateErr: _showEFTStatusDateErr,
    showPreNoteDateErr: _showPreNoteDateErr,
    showFinancInstiErr: _showFinancInstiErr,
    showBranchErr: _showBranchErr,
    showAccTypeErr: _showAccTypeErr,
    showRoutingNumErr: _showRoutingNumErr,
    showACCNumErr: _showACCNumErr,
    showAccHoldNameErr: _showAccHoldNameErr
  });
  setBankDetailsErrMsg({
    beginDateErrMsg: _beginDateErrMsg,
    endDateErrMsg: _endDateErrMsg,
    eftStatusErrMsg: _eftStatusErrMsg,
    eftStatusDateErrMsg: _eftStatusDateErrMsg,
    preNoteDateErrMsg: _preNoteDateErrMsg,
    financInstiErrMsg: _financInstiErrMsg,
    branchErrMsg: _branchErrMsg,
    accTypeErrMsg: _accTypeErrMsg,
    routingNumErrMsg: _routingNumErrMsg,
    accNumerrMsg: _accNumerrMsg,
    accHoldNameErrMsg: _accHoldNameErrMsg
  });
  seterrorMessages(errorArray);
  return _isIPvalid;
};
export const resetAddBankPopUp = (setbankingDetails, setSelectedBeginDate, setSelectedEndDate, setSelectedEftDate, setSelectedPreNoteDate,
  setShowBankDetailsErr, setBankDetailsErrMsg, bankingTableClkRow) => {
  setSelectedBeginDate(null);
  setSelectedEndDate(new Date('12/31/9999'));
  setSelectedEftDate(null);
  setSelectedPreNoteDate(null);
  setbankingDetails({
    beginDate: '',
    endDate: '12/31/9999',
    eftStatus: DEFAULT_DD_VALUE,
    eftStatusDate: '',
    preNoteDate: '',
    financialInstitution: '',
    branch: '',
    accountType: DEFAULT_DD_VALUE,
    routingNumber: '',
    accountNumber: '',
    accountHolderName: ''
  });
  setShowBankDetailsErr({
    showBeginDateErr: false,
    showEndDateErr: false,
    showEFTStatusErr: false,
    showEFTStatusDateErr: false,
    showPreNoteDateErr: false,
    showFinancInstiErr: false,
    showBranchErr: false,
    showAccTypeErr: false,
    showRoutingNumErr: false,
    showACCNumErr: false,
    showAccHoldNameErr: false
  });
  setBankDetailsErrMsg({
    beginDateErrMsg: '',
    endDateErrMsg: '',
    eftStatusErrMsg: '',
    eftStatusDateErrMsg: '',
    preNoteDateErrMsg: '',
    financInstiErrMsg: '',
    branchErrMsg: '',
    accTypeErrMsg: '',
    routingNumErrMsg: '',
    accNumerrMsg: '',
    accHoldNameErrMsg: ''
  });
};
export const validateMiscellaneousPopup = (MiscellaneousDetails, SelectedMiscellaneousBeginDate, SelectedMiscellaneousEndDate, setShowMiscellaneousError, setShowMiscellaneousErrorMsg, seterrorMessages) => {
  var _showBankruptcyTypeCodeError = false;
  var _showBankruptcyBeginDateError = false;
  var _showBankruptcyEndDateError = false;
  var _showClaimAmountError = false;
  var _showPostedPetitionDebtAmountError = false;
  var _showPostedPetitionIndicatorError = false;
  var _showBankruptcyTypeCodeErrorMsg = '';
  var _showBankruptcyBeginDateErrorMsg = '';
  var _showBankruptcyEndDateErrorMsg = '';
  var _showClaimAmountErrorMsg = '';
  var _showPostedPetitionDebtAmountErrorMsg = '';
  var _showPostedPetitionIndicatorErrorMsg = '';
  var _isIPvalid = true;
  const errorArray = [];
  if (!SelectedMiscellaneousBeginDate) {
    _isIPvalid = false;
    _showBankruptcyBeginDateError = true;
    _showBankruptcyBeginDateErrorMsg = FinancialEntityConstants.BANKRUPTCY_BEGIN_DATE_REQ;
    errorArray.push(_showBankruptcyBeginDateErrorMsg);
  } else if (SelectedMiscellaneousBeginDate && SelectedMiscellaneousBeginDate.toString() === 'Invalid Date') {
    _isIPvalid = false;
    _showBankruptcyBeginDateError = true;
    _showBankruptcyBeginDateErrorMsg = FinancialEntityConstants.INVALID_DATE_FORMAT;
    errorArray.push(_showBankruptcyBeginDateErrorMsg);
  } else if (SelectedMiscellaneousBeginDate && SelectedMiscellaneousBeginDate.toString() !== 'Invalid Date') {
    if (DateUtilities.validateDateMinimumValue(SelectedMiscellaneousBeginDate)) {
      _isIPvalid = false;
      _showBankruptcyBeginDateError = true;
      _showBankruptcyBeginDateErrorMsg = FinancialEntityConstants.MIN_DATE_ERRORMSG;
      errorArray.push(_showBankruptcyBeginDateErrorMsg);
    }
  }
  if (!SelectedMiscellaneousEndDate) {
    _isIPvalid = false;
    _showBankruptcyEndDateError = true;
    _showBankruptcyEndDateErrorMsg = FinancialEntityConstants.BANKRUPTCY_END_DATE_REQ;
    errorArray.push(_showBankruptcyEndDateErrorMsg);
  } else if (SelectedMiscellaneousEndDate && SelectedMiscellaneousEndDate.toString() === 'Invalid Date') {
    _isIPvalid = false;
    _showBankruptcyEndDateError = true;
    _showBankruptcyEndDateErrorMsg = FinancialEntityConstants.INVALID_DATE_FORMAT;
    errorArray.push(_showBankruptcyEndDateErrorMsg);
  } else if (SelectedMiscellaneousEndDate && SelectedMiscellaneousEndDate.toString() !== 'Invalid Date') {
    if (SelectedMiscellaneousEndDate && DateUtilities.validateDateMinimumValue(SelectedMiscellaneousEndDate)) {
      _isIPvalid = false;
      _showBankruptcyEndDateError = true;
      _showBankruptcyEndDateErrorMsg = FinancialEntityConstants.MIN_DATE_ERRORMSG;
      errorArray.push(_showBankruptcyEndDateErrorMsg);
    }
  }
  if (SelectedMiscellaneousBeginDate && SelectedMiscellaneousBeginDate.toString() !== 'Invalid Date' &&
    SelectedMiscellaneousEndDate && SelectedMiscellaneousEndDate.toString() !== 'Invalid Date' &&
    !_showBankruptcyBeginDateError && !_showBankruptcyEndDateError) {
    if (DateUtilities.getDateInMMDDYYYYFormatWithApendZero(SelectedMiscellaneousBeginDate) > DateUtilities.getDateInMMDDYYYYFormatWithApendZero(SelectedMiscellaneousEndDate)) {
      _isIPvalid = false;
      _showBankruptcyBeginDateError = true;
      _showBankruptcyBeginDateErrorMsg = FinancialEntityConstants.BANKRUPTCY_BEGIN_DATE_LESS_THAN_END;
      errorArray.push(_showBankruptcyBeginDateErrorMsg);
    }
  }
  if (MiscellaneousDetails && MiscellaneousDetails.bankruptcyTypeCode === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showBankruptcyTypeCodeError = true;
    _showBankruptcyTypeCodeErrorMsg = FinancialEntityConstants.BANKRUPTCY_TYPE_REQUIRED;
    errorArray.push(_showBankruptcyTypeCodeErrorMsg);
  }
  const currency_reg = '^(\d{1,3}(\,\d{3})*|(\d+))(\.\d{1,2})?$';
  var regex = /^[0-9]{1,7}(\.\d{1,2})?%?$/;
  if (!MiscellaneousDetails.claimAmount && !MiscellaneousDetails.claimAmount.trim()) {
    _isIPvalid = false;
    _showClaimAmountError = true;
    _showClaimAmountErrorMsg = FinancialEntityConstants.CLAIM_AMT_REQ;
    errorArray.push(_showClaimAmountErrorMsg);
  }
  if (MiscellaneousDetails.claimAmount && MiscellaneousDetails.claimAmount.trim() && !regex.test(MiscellaneousDetails.claimAmount.toString().replace(/,/g, ''))) {
    _isIPvalid = false;
    _showClaimAmountError = true;
    _showClaimAmountErrorMsg = FinancialEntityConstants.INVALID_DATA_FORMAT;
    errorArray.push(_showClaimAmountErrorMsg);
  }
  if (!MiscellaneousDetails.postedPetitionDebtAmount && !MiscellaneousDetails.postedPetitionDebtAmount.trim()) {
    _isIPvalid = false;
    _showPostedPetitionDebtAmountError = true;
    _showPostedPetitionDebtAmountErrorMsg = FinancialEntityConstants.PETITION_DEBT_AMT_REQ;
    errorArray.push(_showPostedPetitionDebtAmountErrorMsg);
  }
  if (MiscellaneousDetails.postedPetitionDebtAmount && MiscellaneousDetails.postedPetitionDebtAmount.trim() && !regex.test(MiscellaneousDetails.postedPetitionDebtAmount.toString().replace(/,/g, ''))) {
    _isIPvalid = false;
    _showPostedPetitionDebtAmountError = true;
    _showPostedPetitionDebtAmountErrorMsg = FinancialEntityConstants.INVALID_DATA_FORMAT;
    errorArray.push(_showPostedPetitionDebtAmountErrorMsg);
  }
  seterrorMessages(errorArray);
  setShowMiscellaneousError({
    showBankruptcyTypeCodeError: _showBankruptcyTypeCodeError,
    showBankruptcyBeginDateError: _showBankruptcyBeginDateError,
    showBankruptcyEndDateError: _showBankruptcyEndDateError,
    showClaimAmountError: _showClaimAmountError,
    showPostedPetitionDebtAmountError: _showPostedPetitionDebtAmountError,
    showPostedPetitionIndicatorError: _showPostedPetitionIndicatorError
  });
  setShowMiscellaneousErrorMsg({
    showBankruptcyTypeCodeErrorMsg: _showBankruptcyTypeCodeErrorMsg,
    showBankruptcyBeginDateErrorMsg: _showBankruptcyBeginDateErrorMsg,
    showBankruptcyEndDateErrorMsg: _showBankruptcyEndDateErrorMsg,
    showClaimAmountErrorMsg: _showClaimAmountErrorMsg,
    showPostedPetitionDebtAmountErrorMsg: _showPostedPetitionDebtAmountErrorMsg,
    showPostedPetitionIndicatorErrorMsg: _showPostedPetitionIndicatorErrorMsg
  });
  return _isIPvalid;
};

export const MiscellaneousReset = (miscellaneousTableData, setMiscellaneousDetails, setSelectedMiscellaneousBeginDate, setSelectedMiscellaneousEndDate, setShowMiscellaneousError, setShowMiscellaneousErrorMsg) => {
  setSelectedMiscellaneousBeginDate(null);
  setSelectedMiscellaneousEndDate(null);
  setMiscellaneousDetails({
    id: DateUtilities.generateUUID(),
    bankruptcyTypeCode: DEFAULT_DD_VALUE,
    bankruptcyBeginDate: null,
    bankruptcyEndDate: null,
    claimAmount: '',
    postedPetitionDebtAmount: '',
    postedPetitionIndicator: false,
    beginDate: null,
    endDate: null
  });
  setShowMiscellaneousError({
    showBankruptcyTypeCodeError: false,
    showBankruptcyBeginDateError: false,
    showBankruptcyEndDateError: false,
    showClaimAmountError: false,
    showPostedPetitionDebtAmountError: false,
    showPostedPetitionIndicatorError: false
  });
  setShowMiscellaneousErrorMsg({
    showBankruptcyTypeCodeErrorMsg: '',
    showBankruptcyBeginDateErrorMsg: '',
    showBankruptcyEndDateErrorMsg: '',
    showClaimAmountErrorMsg: '',
    showPostedPetitionDebtAmountErrorMsg: '',
    showPostedPetitionIndicatorErrorMsg: ''
  });
};

export const validateAddressPopup = (addAddress, selectedBeginDate, selectedEndDate, AddAddressInitialValue, addressTableData, setShowAddressError, setShowAddressErrorMsg, retainResetValue, editRow, seterrorMessages) => {
  let _showaddressUsageTypeCodeError, _showBeginDateError, _showEndDateError, _showSignificanceTypeCodeError, _showChangeReasonCodeError,
    _showStatusCodeError, _showAddressLine1Error, _showAddressLine2Error, _showAddressLine3Error, _showAddressLine4Error, _showCityError,
    _showStateError, _showZipcodeError,_showZipcodeReqError, _showCountyError, _showCountryError, _showTownError, _showAddressVerificationError,
    _showUSPSVerifiedCodeError, _showLatitudeError, _showLongitudeError, _showEmailError, _showPhoneError;
  _showaddressUsageTypeCodeError = _showBeginDateError = _showEndDateError = _showSignificanceTypeCodeError = _showChangeReasonCodeError =
    _showStatusCodeError = _showAddressLine1Error = _showAddressLine2Error = _showAddressLine3Error = _showAddressLine4Error = _showCityError =
    _showStateError = _showZipcodeError = _showZipcodeReqError = _showCountyError = _showCountryError = _showTownError = _showAddressVerificationError =
    _showUSPSVerifiedCodeError = _showLatitudeError = _showEmailError = _showPhoneError = _showLongitudeError = false;
  let _showaddressUsageTypeCodeErrorMsg, _showBeginDateErrorMsg, _showEndDateErrorMsg, _showSignificanceTypeCodeErrorMsg, _showChangeReasonCodeErrorMsg,
    _showStatusCodeErrorMsg, _showAddressLine1ErrorMsg, _showAddressLine2ErrorMsg, _showAddressLine3ErrorMsg, _showAddressLine4ErrorMsg, _showCityErrorMsg,
    _showStateErrorMsg, _showZipcodeErrorMsg, _showZipcodeReqErrorMsg, _showCountyErrorMsg, _showCountryErrorMsg, _showTownErrorMsg, _showAddressVerificationErrorMsg,
    _showUSPSVerifiedCodeErrorMsg, _showLatitudeErrorMsg, _showLongitudeErrorMsg, _showEmailErrorMsg, _showPhoneErrorMsg;
  _showaddressUsageTypeCodeErrorMsg = _showBeginDateErrorMsg = _showEndDateErrorMsg = _showSignificanceTypeCodeErrorMsg = _showChangeReasonCodeErrorMsg =
    _showStatusCodeErrorMsg = _showAddressLine1ErrorMsg = _showAddressLine2ErrorMsg = _showAddressLine3ErrorMsg = _showAddressLine4ErrorMsg = _showCityErrorMsg =
    _showStateErrorMsg = _showZipcodeErrorMsg = _showZipcodeReqErrorMsg = _showCountyErrorMsg = _showEmailErrorMsg = _showPhoneErrorMsg = _showCountryErrorMsg = _showTownErrorMsg = _showAddressVerificationErrorMsg =
    _showUSPSVerifiedCodeErrorMsg = _showLatitudeErrorMsg = _showLongitudeErrorMsg = '';
  let _isIPvalid = true;
  const errorArray = [];
  if (!selectedBeginDate) {
    _isIPvalid = false;
    _showBeginDateError = true;
    _showBeginDateErrorMsg = FinancialEntityConstants.BEGINDATE_REQUIRED;
    errorArray.push(_showBeginDateErrorMsg);
  } else if (selectedBeginDate && selectedBeginDate.toString() === 'Invalid Date') {
    _isIPvalid = false;
    _showBeginDateError = true;
    _showBeginDateErrorMsg = FinancialEntityConstants.INVALID_DATE_FORMAT;
    errorArray.push(_showBeginDateErrorMsg);
  } else {
    if (selectedBeginDate && DateUtilities.validateDateMinimumValue(selectedBeginDate)) {
      _isIPvalid = false;
      _showBeginDateError = true;
      _showBeginDateErrorMsg = FinancialEntityConstants.MIN_DATE_ERRORMSG;
      errorArray.push(_showBeginDateErrorMsg);
    }
  }
  if (!selectedEndDate) {
    _isIPvalid = false;
    _showEndDateError = true;
    _showEndDateErrorMsg = FinancialEntityConstants.ENDNDATE_REQUIRED;
    errorArray.push(_showEndDateErrorMsg);
  } else if (selectedEndDate && selectedEndDate.toString() === 'Invalid Date') {
    _isIPvalid = false;
    _showEndDateError = true;
    _showEndDateErrorMsg = FinancialEntityConstants.INVALID_DATE_FORMAT;
    errorArray.push(_showEndDateErrorMsg);
  } else {
    if (selectedEndDate && DateUtilities.validateDateMinimumValue(selectedEndDate)) {
      _isIPvalid = false;
      _showEndDateError = true;
      _showEndDateErrorMsg = FinancialEntityConstants.MIN_DATE_ERRORMSG;
      errorArray.push(_showEndDateErrorMsg);
    }
  }
  if (selectedBeginDate && selectedBeginDate.toString() !== 'Invalid Date' &&
    selectedEndDate && selectedEndDate.toString() !== 'Invalid Date' &&
    !_showBeginDateError && !_showEndDateError) {
    if (DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedBeginDate) > DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEndDate)) {
      _isIPvalid = false;
      _showEndDateError = true;
      _showEndDateErrorMsg = FinancialEntityConstants.ENDATE_SHOULDGREATER_OR_EQUAL_BEGINDATE;
      errorArray.push(_showEndDateErrorMsg);
    }
  }
  if (addAddress && addAddress.addressUsageTypeCode === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showaddressUsageTypeCodeError = true;
    _showaddressUsageTypeCodeErrorMsg = FinancialEntityConstants.ADDRESS_TYPE_REQUIRED;
    errorArray.push(_showaddressUsageTypeCodeErrorMsg);
  }
  // Phone Error Check
  if (addAddress && addAddress.phone && addAddress.phone.trim() && isNaN(addAddress.phone)) {
    _isIPvalid = false;
    _showPhoneError = true;
    _showPhoneErrorMsg = FinancialEntityConstants.INVALID_DATA_FORMAT;
    errorArray.push(_showPhoneErrorMsg);
  } else if (addAddress && addAddress.phone && addAddress.phone.trim() && addAddress.phone.length < 10) {
    _isIPvalid = false;
    _showPhoneError = true;
    _showPhoneErrorMsg = FinancialEntityConstants.PHONE_10_DIGITS;
    errorArray.push(_showPhoneErrorMsg);
  }
  // Email Validation Check
  const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (addAddress && addAddress.email && addAddress.email.trim() && !regexEmail.test(addAddress.email.trim())) {
    _isIPvalid = false;
    _showEmailError = true;
    _showEmailErrorMsg = FinancialEntityConstants.INVALID_DATA_FORMAT;
    errorArray.push(_showEmailErrorMsg);
  }
  const regex = /^^[a-zA-Z0-9 ]*$/;
  if (addAddress && addAddress.zipCode5 && addAddress.zipCode5.trim() && !regex.test(addAddress.zipCode5)) {
    _isIPvalid = false;
    _showZipcodeError = true;
    _showZipcodeErrorMsg = FinancialEntityConstants.INVALID_ZIP_CODE;
    errorArray.push(_showZipcodeErrorMsg);
  }
  if (addAddress && !addAddress.zipCode5) {
    _isIPvalid = false;
    _showZipcodeReqError = true;
    _showZipcodeReqErrorMsg = FinancialEntityConstants.ZIP_CODE_REQ;
    errorArray.push(_showZipcodeReqErrorMsg);
  }
  if (addAddress && !addAddress.city){
    _isIPvalid = false;
    _showCityError = true;
    _showCityErrorMsg = FinancialEntityConstants.CITY_REQ;
    errorArray.push(_showCityErrorMsg);
  }
  if (addAddress && !addAddress.state){
    _isIPvalid = false;
    _showStateError = true;
    _showStateErrorMsg = FinancialEntityConstants.STATE_REQ;
    errorArray.push(_showStateErrorMsg);
  }
  if (addAddress && addAddress.addressUsageSigCode === DEFAULT_DD_VALUE) {
    _isIPvalid = false;
    _showSignificanceTypeCodeError = true;
    _showSignificanceTypeCodeErrorMsg = FinancialEntityConstants.SIGNIFICANCE_REQ;
    errorArray.push(_showSignificanceTypeCodeErrorMsg);
  }
  if ((addAddress && !addAddress.addressLine1) || (addAddress.addressLine1 && !addAddress.addressLine1.trim())) {
    _isIPvalid = false;
    _showAddressLine1Error = true;
    _showAddressLine1ErrorMsg = FinancialEntityConstants.ADDRESS_1_REQ;
    errorArray.push(_showAddressLine1ErrorMsg);
  }
  if (!editRow) {
    addressTableData.filter((tableRow) => {
      if (addAddress.addressUsageTypeCode === tableRow.addressType
        && addAddress.addressUsageSigCode === tableRow.significanceTypeCode
        && addAddress.statusCode === tableRow.statusCode
      ) {
        if (moment(selectedBeginDate).isBetween(tableRow.beginDate, tableRow.endDate)
          || moment(selectedEndDate).isBetween(tableRow.beginDate, tableRow.endDate)
          || moment(tableRow.beginDate).isBetween(selectedBeginDate, selectedEndDate)
          || moment(tableRow.endDate).isBetween(selectedBeginDate, selectedEndDate)
          || new Date(selectedBeginDate) === new Date(tableRow.beginDate)
          || new Date(selectedBeginDate) === new Date(tableRow.endDate)
          || new Date(selectedEndDate) === new Date(tableRow.beginDate)
          || new Date(selectedEndDate) === new Date(tableRow.endDate)
        ) {
          _isIPvalid = false;
          _showEndDateError = true;
          _showEndDateErrorMsg = FinancialEntityConstants.ADDRESS_OVERLAP;
          errorArray.push(_showEndDateErrorMsg);
        }
      }
    });
  } else {
    addressTableData.filter((tableRow) => {
      if (addAddress.addressUsageTypeCode === tableRow.addressType
        && addAddress.addressUsageSigCode === tableRow.significanceTypeCode
        && addAddress.statusCode === tableRow.statusCode
      ) {
        if (tableRow !== retainResetValue) {
          if (moment(selectedBeginDate).isBetween(tableRow.beginDate, tableRow.endDate)
            || moment(selectedEndDate).isBetween(tableRow.beginDate, tableRow.endDate)
            || moment(tableRow.beginDate).isBetween(selectedBeginDate, selectedEndDate)
            || moment(tableRow.endDate).isBetween(selectedBeginDate, selectedEndDate)
            || new Date(selectedBeginDate) === new Date(tableRow.beginDate)
            || new Date(selectedBeginDate) === new Date(tableRow.endDate)
            || new Date(selectedEndDate) === new Date(tableRow.beginDate)
            || new Date(selectedEndDate) === new Date(tableRow.endDate)
          ) {
            _isIPvalid = false;
            _showEndDateError = true;
            _showEndDateErrorMsg = FinancialEntityConstants.ADDRESS_OVERLAP;
            errorArray.push(_showEndDateErrorMsg);
          }
        }
      }
    });
  }
  if(errorArray && errorArray.length >0){
    seterrorMessages(errorArray);
  }
  setShowAddressErrorMsg({
    showaddressUsageTypeCodeErrorMsg: _showaddressUsageTypeCodeErrorMsg,
    showBeginDateErrorMsg: _showBeginDateErrorMsg,
    showEndDateErrorMsg: _showEndDateErrorMsg,
    showSignificanceTypeCodeErrorMsg: _showSignificanceTypeCodeErrorMsg,
    showChangeReasonCodeErrorMsg: _showChangeReasonCodeErrorMsg,
    showStatusCodeErrorMsg: _showStatusCodeErrorMsg,
    showAddressLine1ErrorMsg: _showAddressLine1ErrorMsg,
    showAddressLine2ErrorMsg: _showAddressLine2ErrorMsg,
    showAddressLine3ErrorMsg: _showAddressLine3ErrorMsg,
    showAddressLine4ErrorMsg: _showAddressLine4ErrorMsg,
    showCityErrorMsg: _showCityErrorMsg,
    showStateErrorMsg: _showStateErrorMsg,
    showZipcodeErrorMsg: _showZipcodeErrorMsg,
    showZipcodeReqErrorMsg: _showZipcodeReqErrorMsg,
    showCountyErrorMsg: _showCountyErrorMsg,
    showCountryErrorMsg: _showCountryErrorMsg,
    showTownErrorMsg: _showTownErrorMsg,
    showAddressVerificationErrorMsg: _showAddressVerificationErrorMsg,
    showUSPSVerifiedCodeErrorMsg: _showUSPSVerifiedCodeErrorMsg,
    showLatitudeErrorMsg: _showLatitudeErrorMsg,
    showLongitudeErrorMsg: _showLongitudeErrorMsg,
    showPhoneErrorMsg: _showPhoneErrorMsg,
    showEmailErrorMsg: _showEmailErrorMsg
  });
  setShowAddressError({
    showaddressUsageTypeCodeError: _showaddressUsageTypeCodeError,
    showBeginDateError: _showBeginDateError,
    showEndDateError: _showEndDateError,
    showSignificanceTypeCodeError: _showSignificanceTypeCodeError,
    showChangeReasonCodeError: _showChangeReasonCodeError,
    showStatusCodeError: _showStatusCodeError,
    showAddressLine1Error: _showAddressLine1Error,
    showAddressLine2Error: _showAddressLine2Error,
    showAddressLine3Error: _showAddressLine3Error,
    showAddressLine4Error: _showAddressLine4Error,
    showCityError: _showCityError,
    showStateError: _showStateError,
    showZipcodeError: _showZipcodeError,
    showZipcodeReqError:_showZipcodeReqError,
    showCountyError: _showCountyError,
    showCountryError: _showCountryError,
    showTownError: _showTownError,
    showAddressVerificationError: _showAddressVerificationError,
    showUSPSVerifiedCodeError: _showUSPSVerifiedCodeError,
    showLatitudeError: _showLatitudeError,
    showLongitudeError: _showLongitudeError,
    showPhoneError: _showPhoneError,
    showEmailError: _showEmailError
  });
  return _isIPvalid;
};

export const resetAddressPopup = (setAddAddress, AddAddressInitialValue, addressTableData, setSelectedBeginDate, setSelectedEndDate, setShowAddressError, setShowAddressErrorMsg) => {
  setAddAddress(AddAddressInitialValue);
  setSelectedBeginDate(null);
  setSelectedEndDate(null);
  setShowAddressError({
    showaddressUsageTypeCodeError: false,
    showBeginDateError: false,
    showEndDateError: false,
    showSignificanceTypeCodeError: false,
    showChangeReasonCodeError: false,
    showStatusCodeError: false,
    showAddressLine1Error: false,
    showAddressLine2Error: false,
    showAddressLine3Error: false,
    showAddressLine4Error: false,
    showCityError: false,
    showStateError: false,
    showZipcodeError: false,
    showZipcodeErrorMsg: false,
    showCountyError: false,
    showCountryError: false,
    showTownError: false,
    showAddressVerificationError: false,
    showUSPSVerifiedCodeError: false,
    showLatitudeError: false,
    showLongitudeError: false
  });
  setShowAddressErrorMsg({
    showaddressUsageTypeCodeErrorMsg: '',
    showBeginDateErrorMsg: '',
    showEndDateErrorMsg: '',
    showSignificanceTypeCodeErrorMsg: '',
    showChangeReasonCodeErrorMsg: '',
    showStatusCodeErrorMsg: '',
    showAddressLine1ErrorMsg: '',
    showAddressLine2ErrorMsg: '',
    showAddressLine3ErrorMsg: '',
    showAddressLine4ErrorMsg: '',
    showCityErrorMsg: '',
    showStateErrorMsg: '',
    showZipcodeErrorMsg: '',
    showZipcodeReqErrorMsg: '',
    showCountyErrorMsg: '',
    showCountryErrorMsg: '',
    showTownErrorMsg: '',
    showAddressVerificationErrorMsg: '',
    showUSPSVerifiedCodeErrorMsg: '',
    showLatitudeErrorMsg: '',
    showLongitudeErrorMsg: ''
  });
};

export const validatePayeeStatus = (payeeStatusDetails, beginDate, endDate, setPayeeStatusError, setPayeeStatusErrorMessage, payeeStatusTable, edit, retainEdit, seterrorMessages, errorMessages) => {
  let _isIPvalid = true;
  let statusErr, reasonCodeErr, beginDateErr, endDateErr;
  statusErr = reasonCodeErr = beginDateErr = endDateErr = false;
  let statusErrMsg, resonErrMsg, beginDateErrMsg, endDateErrMsg;
  statusErrMsg = resonErrMsg = beginDateErrMsg = endDateErrMsg = '';
  let tempErrMsgArray = []
  if (payeeStatusDetails.statusTypeCd === DEFAULT_DD_VALUE || payeeStatusDetails.statusTypeCd === '' || payeeStatusDetails.statusTypeCd === undefined) {
    _isIPvalid = false;
    statusErr = true;
    statusErrMsg = FinancialEntityConstants.PAYEE_STATUS_REQ;
    tempErrMsgArray.push(statusErrMsg);
  }

  if (payeeStatusDetails.ststusRsnCd === DEFAULT_DD_VALUE || payeeStatusDetails.ststusRsnCd === '' || payeeStatusDetails.ststusRsnCd === undefined) {
    _isIPvalid = false;
    reasonCodeErr = true;
    resonErrMsg = FinancialEntityConstants.PAYEE_REASON_CODE_REQ;
    tempErrMsgArray.push(resonErrMsg);
  }
  if (!beginDate) {
    _isIPvalid = false;
    beginDateErr = true;
    beginDateErrMsg = FinancialEntityConstants.BEGIN_DATE_REQ;
    tempErrMsgArray.push(beginDateErrMsg);
    
  } else if (beginDate && beginDate.toString().toUpperCase() === 'INVALID DATE') {
    _isIPvalid = false;
    beginDateErr = true;
    beginDateErrMsg = FinancialEntityConstants.INVALID_DATE_FORMAT;
    tempErrMsgArray.push(beginDateErrMsg);

  }
  if (beginDate && beginDate.toString().toUpperCase() !== 'INVALID DATE') {
    if (DateUtilities.validateDateMinimumValue(beginDate)) {
      _isIPvalid = false;
      beginDateErr = true;
      beginDateErrMsg = FinancialEntityConstants.MIN_DATE_ERRORMSG;
      tempErrMsgArray.push(beginDateErrMsg);

    }
  }
  if (!endDate) {
    _isIPvalid = false;
    endDateErr = true;
    endDateErrMsg = FinancialEntityConstants.END_DATE_REQ;
    tempErrMsgArray.push(endDateErrMsg);

  } else if (endDate && endDate.toString().toUpperCase() === 'INVALID DATE') {
    _isIPvalid = false;
    endDateErr = true;
    endDateErrMsg = FinancialEntityConstants.INVALID_DATE_FORMAT;
    tempErrMsgArray.push(endDateErrMsg);

  }
  if (endDate && endDate.toString().toUpperCase() !== 'INVALID DATE') {
    if (DateUtilities.validateDateMinimumValue(endDate)) {
      _isIPvalid = false;
      endDateErr = true;
      endDateErrMsg = FinancialEntityConstants.MIN_DATE_ERRORMSG;
      tempErrMsgArray.push(endDateErrMsg);

    }
  }
  // Begin Date <= End Date
  if (beginDate && endDate &&
    !beginDateErr && !endDateErr &&
    beginDate.toString().toUpperCase() !== 'INVALID DATE' &&
    endDate.toString().toUpperCase() !== 'INVALID DATE') {
    if (new Date(moment(beginDate).format('L')) > new Date(moment(endDate).format('L'))) {
      _isIPvalid = false;
      endDateErr = true;
      endDateErrMsg = FinancialEntityConstants.ENDATE_SHOULDGREATER_OR_EQUAL_BEGINDATE;
      tempErrMsgArray.push(endDateErrMsg);
    }
  }
  let enteredBeginDate = DateUtilities.getDateInMMDDYYYYFormat(beginDate);
  let enteredEndDate = DateUtilities.getDateInMMDDYYYYFormat(endDate);

  if (!edit) {
    payeeStatusTable && payeeStatusTable.filter((tableRow) => {
      if (moment(enteredBeginDate).isBetween(tableRow.beginDate, tableRow.endDate)
        || moment(enteredEndDate).isBetween(tableRow.beginDate, tableRow.endDate)
        || moment(tableRow.beginDate).isBetween(enteredBeginDate, enteredEndDate)
        || moment(tableRow.endDate).isBetween(enteredBeginDate, enteredEndDate)) {
        _isIPvalid = false;
        endDateErr = true;
        endDateErrMsg = FinancialEntityConstants.PAYEE_STATUS_OVERLAP;
        tempErrMsgArray.push(endDateErrMsg);

      }
    });
  } else {
    payeeStatusTable.filter((tableRow) => {
      if (tableRow !== retainEdit) {
        if (moment(enteredBeginDate).isBetween(tableRow.beginDate, tableRow.endDate)
          || moment(enteredEndDate).isBetween(tableRow.beginDate, tableRow.endDate)
          || moment(tableRow.beginDate).isBetween(enteredBeginDate, enteredEndDate)
          || moment(tableRow.endDate).isBetween(enteredBeginDate, enteredEndDate)) {
          _isIPvalid = false;
          endDateErr = true;
          endDateErrMsg = FinancialEntityConstants.PAYEE_STATUS_OVERLAP;
          tempErrMsgArray.push(endDateErrMsg);

        }
      }
    });
    seterrorMessages(tempErrMsgArray);
  }


  setPayeeStatusError({
    statusError: statusErr,
    reasonCodeError: reasonCodeErr,
    beginDateError: beginDateErr,
    endDateError: endDateErr
  });

  setPayeeStatusErrorMessage({
    statusErrorMsg: statusErrMsg,
    reasonCodeErrorMsg: resonErrMsg,
    beginDateErrorMsg: beginDateErrMsg,
    endDateErrorMsg: endDateErrMsg
  })
  return _isIPvalid;
}
