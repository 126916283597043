/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import { useDispatch, useSelector } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router";
import { checkingDecimal, getLongDescription } from "../../../../SharedModules/CommonUtilities/commonUtilities";
import { Link, Prompt } from "react-router-dom";
import ErrorMessages from "../../../../SharedModules/Errors/ErrorMessages";
import SuccessMessages from "../../../../SharedModules/Errors/SuccessMessage";
import * as ErrorConstants from "./ErrorConstants";
import * as ErrorConsts from '../ErrorCheck';
import { CLAIMS_INQUIRY_TCN_LINK } from "../../../../SharedModules/services/service";
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  DialogContent
} from '../../../../SharedModules/Dialog/DialogUtilities';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import moment from "moment";
import {
  getDropdownDataAction,
  getPayeeIdType
} from "../../Store/Actions/financialPayout/financialPayoutActions";

import {
  redirectSystemID,
  redirectGrossLevelFCN
} from "../../../../SharedModules/TabRedirections/TabRedirections";
import {
  LinkOfTcn,
  LinkOfFcn
} from "../../../../SharedModules/TabRedirections/customInputLink";
import numeral from "numeral";
import dropdownCriteria from "./PayoutAddUpdate.json";
import {
  refreshAttachmentsAction
} from "../../../../SharedModules/store/Actions/SharedAction";
import Footer from "../../../../SharedModules/Layout/footer";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  getUTCTimeStamp,
  generateUUID,
  getDateInMMDDYYYYFormatWithApendZero,
  compareTwoDatesGreaterThanOrEqual
} from "../../../../SharedModules/DateUtilities/DateUtilities";
import axios from 'axios';
import * as serviceEndPoint from '../../../../SharedModules/services/service';
import NativeDropDown from "../../../../SharedModules/Dropdowns/NativeDropDown";
import { systemListActions } from "../../../ApplicationConfiguration/Store/Actions/systemList/systemListActions";
import { CHECK_DATE, DATE_FORMAT, MAX_DATE, MAX_DATE_FORMAT, ADJUSTMENT_REASON_CODE } from "../../../../SharedModules/AppConstants";
import { checkReasonCode } from "../financialPayoutValidationUtilities";
function FPAdjust (props) {
  // Notes Tab Functionality
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const [disablePage, setDisablepage] = React.useState(false);
  const FPAdjustDetails = JSON.parse(
    JSON.stringify(props.location.state?.claimsData)
  );
  const dropdownData = props.location.state.dropdownData;
  const payeeTypeIdCodeData = props.location.state.payeeTypeIdCodeData;
  const [ARAdjustDetails, setARAdjustDetails] = React.useState(FPAdjustDetails);
  const [payoutRsnCd, setPayoutRsnCd] = React.useState([]);
  const [beginEndDateReqSts, setBeginEndDateReqSts] = React.useState(false);
  const [beginDateValue, setBeginDateValue] = React.useState(false);
  const [endDateValue, setEndDateValue] = React.useState(false);
  const [saveFlag, setSaveFlag] = React.useState(false);
  const [isFocusCheck, setFocusCheck] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [disableReasonCodeData, setDisableReasonCodeData] = React.useState([]);
  const logInUserID = useSelector((state) => state.sharedState.logInUserID);
  // Attachments Integration start
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  React.useEffect(() => {
    const attachmentVOList = [];
    if (attachmentsTableData && attachmentsTableData.length > 0) {
      attachmentsTableData.map((value) => {
        if (value.id) {
          const attachmentVO = {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            versionNo: 0,
            dbRecord: true,
            sortColumn: null,
            attachmentSK: null,
            attachmentPageID: null,
            finalPath: null,
            detachInd: false,
            historyIndicator: false,
            cascadeKey: null,
            attachmentIndicator: null,
            dateAdded: getDateInMMDDYYYYFormatWithApendZero(
              new Date(
                new Date().toLocaleString("en-US", {
                  timeZone: process.env.REACT_APP_TIMEZONE
                })
              )
            ),
            fileUrl: null,
            showDeleteForSearched: false,
            rowIndex: 0,
            addedBy: logInUserID,
            description: value ? value.description : null,
            edmsStoredDate: null,
            anEDMSPageId: null,
            fileName: attachmentsTableData && value ? value.fileName : null,
            file1: null,
            edmsWrkUnitLevel: null,
            edmsDocType: null
          };
          attachmentVOList.push(attachmentVO);
        }
      });
    }
    ARAdjustDetails.attachmentVO = null;
    ARAdjustDetails.attachmentsList = attachmentVOList;
  }, [attachmentsTableData]);
  // Notes Integration Begin
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({});

  let refreshAttachmentResult = useSelector(
    (state) => state.sharedState.newAttachmentResult
  );
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);


  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setsaveMessage([]);
    seterrorMessages([]);
    setReasonCodeError(false);
  };
  let paramsData;

  useEffect(() => {
    if (props.location.state.claimsData) {
      paramsData = props.location.state.claimsData;
      // notes
      if (paramsData.noteSetVO) {
        const noteSetVO = paramsData.noteSetVO;
        const notesTable = paramsData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID
            ? noteSetVO.addedAuditUserID
            : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
            ? noteSetVO.addedAuditTimeStamp
            : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTableData,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
    }
    refreshAttachmentResult = null;
  }, []);

  useEffect(() => {
    if (notesInput) {
      setNoteSetListInput({
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: notesInput.addedAuditUserID
          ? notesInput.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: notesInput.addedAuditTimeStamp
          ? notesInput.addedAuditTimeStamp
          : getUTCTimeStamp(),
        versionNo: notesInput.versionNo,
        dbRecord: false,
        sortColumn: null,
        noteTextValue: null,
        userIdName: null,
        notesCexAuditUserID: null,
        notesCexAuditTimeStamp: null,
        notesCexAddedAuditUserID: null,
        notesCexAddedAuditTimeStamp: null,
        noteSetSK: notesInput.noteSetSK,
        usageTypeDesc: "",
        shortNotes: null,
        checked: false,
        renderNoHistoryMsg: false,
        noteSequenceNumber: null,
        currentNote: null,
        rowValue: null,
        usageTypeList: null,
        strBeginDate: moment(new Date()).format("MM/DD/YYYY hh:mm:ss"),
        usageTypeCode: DEFAULT_DD_VALUE,
        tableName: null,
        noteText: "",
        commonEntityName: null,
        commonEntityTypeCode: null,
        commonEntityId: null,
        entityId: null,
        filterbeginDate: moment(new Date()).format("YYYY-MM-DD"),
        filterEndDate: null,
        userId: notesInput.userId ? notesInput.userId : logInUserID,
        noteCexVersionNum: notesInput.noteCexVersionNum
          ? notesInput.noteCexVersionNum
          : 0,
        saNoteSequenceNumber: notesInput.saNoteSequenceNumber
          ? notesInput.saNoteSequenceNumber
          : null,
        notesCexnoteTextValue: notesInput.notesCexnoteTextValue
          ? notesInput.notesCexnoteTextValue
          : 0,
        id: generateUUID()
      });
    }
  }, [notesInput]);

  // Notes Integration End

  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const toPrintRef = useRef();
  const DropDownAPI = (dropdownValues) =>
    dispatch(getDropdownDataAction(dropdownValues));
  let dropDownDetails = [];
  dropDownDetails = useSelector(
    (state) => state.accountReceivableState.dropdownData
  );
  const reasonCds = useSelector(state => state.appConfigState.systemListState);

  const ARletterSentAPI = () => { };
  const ARLetterSentDetails = {};
  const PayeeIDCodeAPI = (payee) => dispatch(getPayeeIdType(payee));
  let payeeIDTypeCodeDetails = [];
  payeeIDTypeCodeDetails = useSelector(
    (state) => state.accountReceivableState.payeeIdTypeDropdown
  );

  let saveARAdjustResult = [];
  saveARAdjustResult = useSelector(
    (state) => state.accountReceivableAdjustState.saveResults
  );
  const [ARletterSent, setARletterSent] = React.useState("");
  const [payeePayerTypeCodeDesc, setpayeePayerTypeCodeDesc] =
    React.useState("");
  const [payeePayerIDTypeCodeDesc, setpayeePayerIDTypeCodeDesc] =
    React.useState("");
  const [activityTypeCodeDesc, setActivityTypeCodeDesc] = React.useState("");
  const [balance, setBalance] = React.useState(
    ARAdjustDetails.financialPayoutAdjustmentVO.balanceAmount
  );
  const [adjFtTcn, setFtTcn] = React.useState(ARAdjustDetails.relatedFTTCN);
  const [penalty, setPenalty] = React.useState(
    ARAdjustDetails.financialPayoutAdjustmentVO?.penalty
  );
  const [interest, setInterest] = React.useState(
    ARAdjustDetails.financialPayoutAdjustmentVO?.interest
  );
  const [fee, setFee] = React.useState(
    ARAdjustDetails.financialPayoutAdjustmentVO?.fee
  );
  const [reasonCodeDesc, setReaconCodeDesc] = React.useState("");
  const [saveMessage, setsaveMessage] = React.useState([]);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [reasonCodeError, setReasonCodeError] = React.useState(false);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [revisedARAmountError, setRevisedARAmountError] = React.useState(false);
  
  const [revisedInterestError, setRevisedInterestError] = React.useState(false);
  
  const [revisedPenalityError, setRevisedPenalityError] = React.useState(false);
 
  const [atrError, showATRError] = React.useState(false);
  const [revisedFeeError, setRevisedFeeError] = React.useState(false);
  
  const [verificationState, setVerificationState] = React.useState({});
  const [reasonCodeArray, setReasonCodeArray] = React.useState([]);
  const [linkEnable, setLinkEnable] = React.useState(false);

  useEffect(() => {
    saveARAdjustResult = [];
    setsaveMessage([]);
    seterrorMessages([]);

    PayeeIDCodeAPI(ARAdjustDetails.payeePayerTypeCode);
    DropDownAPI(dropdownCriteria);
    ARletterSentAPI();
    dispatch(
      systemListActions({
        listNumber: "9003",
        functionalArea: "F1",
        dataElementName: "F_RSN_CD"
      }, 'PayOutRsnCd')
    );
  }, []);
  // System Payee redirection starts
  const [redirect, setRedirect] = React.useState(0);
  const searchSingleFinancialEntityRes = useSelector(
    (state) => state.financialEntityState?.singleSearchResults
  );
  React.useEffect(() => {
    if (
      redirect === 1 &&
      searchSingleFinancialEntityRes &&
      !searchSingleFinancialEntityRes.systemFailue
    ) {
      props.history.push({
        pathname: "/FinancialEntityUpdate",
        state: { searchSingleFinancialEntityRes }
      });
    }
  }, [searchSingleFinancialEntityRes]);

  // System Payee redirection ends
  useEffect(() => {
    if (
      payeeIDTypeCodeDetails &&
      payeeIDTypeCodeDetails.systemFailure === undefined &&
      payeeIDTypeCodeDetails.payeeIdTypeCode &&
      payeeIDTypeCodeDetails.payeeIdTypeCode.length > 0
    ) {
      payeeIDTypeCodeDetails.payeeIdTypeCode.map((var1) => {
        if (var1.code === ARAdjustDetails.payeePayerIDTypeCode) {
          setpayeePayerIDTypeCodeDesc(var1.description);
        }
      });
      seterrorMessages([]);
    }
  }, [payeeIDTypeCodeDetails]);

  useEffect(() => {
    if (ARLetterSentDetails && ARLetterSentDetails.systemFailure) {
      ARLetterSentDetails.map((var1) => {
        if (ARAdjustDetails.agingCode === var1.code) {
          setARletterSent(var1.description);
        }
      });
    }
  }, [ARLetterSentDetails]);
  const [ddListObj, setDDListObj] = React.useState({});
  useEffect(() => {
    if (dropDownDetails && dropDownDetails.listObj) {
      if (dropDownDetails.listObj["General#6002"]) {
        dropDownDetails.listObj["General#6002"].map((var1) => {
          if (var1.code === ARAdjustDetails.payeePayerTypeCode) {
            setpayeePayerTypeCodeDesc(var1.description);
          }
        });
      }
      if (dropDownDetails.listObj["Financial#3013"]) {
        dropDownDetails.listObj["Financial#3013"].map((var1) => {
          if (var1.code === ARAdjustDetails.activityTypeCode) {
            setActivityTypeCodeDesc(var1.description);
          }
        });
      }
      if (dropDownDetails.listObj['Financial#3002']) {
        setDisableReasonCodeData(dropDownDetails.listObj['Financial#3002']);
      }
      if (dropDownDetails.listObj["Financial#8"]) {
        setReasonCodeArray(dropDownDetails.listObj["Financial#8"]);
      }
      if (dropDownDetails.listObj["General#1012"]) {
        setUsageTypeCodeData(dropDownDetails.listObj["General#1012"]);
      }
      setDDListObj(dropDownDetails.listObj);
    }
  }, [dropDownDetails?.listObj]);
  const [beginDate, setBeginDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const handleSettlementBeginDateChange = name => date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setBeginDateValue(true);
    setARAdjustDetails({
      ...ARAdjustDetails,
      financialPayoutAdjustmentVO: {
        ...ARAdjustDetails.financialPayoutAdjustmentVO,
        [name]: date && moment(date).format(DATE_FORMAT),
      },
    });
    setBeginDate(date);
  };

  const handleSettlementEndDateChange = name => date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setEndDate(date);
    setEndDateValue(true);
    setARAdjustDetails({
      ...ARAdjustDetails,
      financialPayoutAdjustmentVO: {
        ...ARAdjustDetails.financialPayoutAdjustmentVO,
        [name]: date && moment(date).format(DATE_FORMAT),
      },
    });
  };

  const setAdjustDetails = (e, k, date) => {
    setFocusCheck(true);
    const value = e?.target ? e.target.value : e;
    if (k === ADJUSTMENT_REASON_CODE) {
      const val = payoutRsnCd?.includes(e.target.value ? e.target.value : '');
      if (val) {
        setFormErrors({
          beginDateErr: false,
          endDateErr: false,
          edtInvalid: false,
          bgdtGtEdtErr: false,
          showCheckDateErrorTemp: false,
          showCheckDateGreaterErr: false,
          showAmountErrorTemp: false,
          showAmountGreaterErrorTemp: false,
          showFiscalYearReqErrorTemp: false,
          showReasoncodeErrorTemp: false,
          showFundCodeErrorTemp: false,
          showFundCodeFisError: false,
          showCheckDtErrorTemp: false,
          showCheckNumErrorTemp: false,
          showFiscalYearFormatErrorTemp: false
        });
        seterrorMessages([]);
        setBeginEndDateReqSts(true);
        setEndDateValue(false);
        setBeginDateValue(false);
      } else if (!val) {
        seterrorMessages([]);
        setFormErrors({
          beginDateErr: false,
          endDateErr: false,
          edtInvalid: false,
          bgdtGtEdtErr: false,
          showCheckDateErrorTemp: false,
          showCheckDateGreaterErr: false,
          showAmountErrorTemp: false,
          showAmountGreaterErrorTemp: false,
          showFiscalYearReqErrorTemp: false,
          showReasoncodeErrorTemp: false,
          showFundCodeErrorTemp: false,
          showFundCodeFisError: false,
          showCheckDtErrorTemp: false,
          showCheckNumErrorTemp: false,
          showFiscalYearFormatErrorTemp: false
        });
        setBeginEndDateReqSts(false);
        setEndDateValue(true);
        setBeginDateValue(true);
      }
    }

    if (k === 'adjustmentAmount') {
      if (e.target.value && e.target.value.includes('.')) {
        if (checkingDecimal(e.target.value)) {
          setARAdjustDetails({
            ...ARAdjustDetails,
            [k]: value,
            financialPayoutAdjustmentVO: {
              ...ARAdjustDetails.financialPayoutAdjustmentVO,
              [k]: value
            }
          });
        }
      } else {
        setARAdjustDetails({
          ...ARAdjustDetails,
          [k]: value,
          financialPayoutAdjustmentVO: {
            ...ARAdjustDetails.financialPayoutAdjustmentVO,
            [k]: value
          }
        });
      }
    } else if(k === CHECK_DATE){
      let date =   e?.target ? e?.target.value : e
      setARAdjustDetails({
        ...ARAdjustDetails,
        [k]: date ? moment(date).format(DATE_FORMAT) : null,
        financialPayoutAdjustmentVO: {
          ...ARAdjustDetails?.financialPayoutAdjustmentVO,
          [k]: date ? moment(date).format(DATE_FORMAT): null
        }
      });
    } else if (k === ADJUSTMENT_REASON_CODE && checkReasonCode(e?.target?.value, disableReasonCodeData)) {
      setARAdjustDetails({
        ...ARAdjustDetails,
        [k]: value,
        financialPayoutAdjustmentVO: {
          ...ARAdjustDetails?.financialPayoutAdjustmentVO,
          [k]: value,
        }
      });
    } else if (k === ADJUSTMENT_REASON_CODE) {
      setARAdjustDetails({
        ...ARAdjustDetails,
        [k]: value,
        checkNumber: '', checkDate: null,
        financialPayoutAdjustmentVO: {
          ...ARAdjustDetails?.financialPayoutAdjustmentVO,
          [k]: value,
          checkNumber: '', checkDate: null
        }
      });
    } else {
      setARAdjustDetails({
        ...ARAdjustDetails,
        [k]: value,
        financialPayoutAdjustmentVO: {
          ...ARAdjustDetails?.financialPayoutAdjustmentVO,
          [k]: value
        }
      });
    }
  };
  const setAccountDetails = (e, k) => {
    setFocusCheck(true);
    setARAdjustDetails({
      ...ARAdjustDetails,
      financialAdjustmentAccountVO: {
        ...ARAdjustDetails.financialAdjustmentAccountVO,
        [k]: e.target.value
      }
    });
  };
  useEffect(() => {
    const saveMSG = [];
    if (
      saveARAdjustResult &&
      saveARAdjustResult.respcode &&
      saveARAdjustResult.respcode === "01"
    ) {
      setSaveFlag(true);
      saveMSG.push(ErrorConstants.SAVE_SUCCESS);
      setsaveMessage(saveMSG);
      setLinkEnable(true);
      seterrorMessages([]);
      setBalance("0.00");
      // setAdjustAmt(values.adjustedArAmt);
      setPenalty(values.revisedPenality);
      setInterest(values.revisedInterest);
      setFee(values.revisedFee);
      ARAdjustDetails.financialPayoutAdjustmentVO.balanceAmount = "0.00";
      ARAdjustDetails.financialPayoutAdjustmentVO.adjustedArAmt =
        values.adjustedArAmt;
      ARAdjustDetails.financialPayoutAdjustmentVO.penalty =
        values.revisedPenality;
      ARAdjustDetails.financialPayoutAdjustmentVO.interest =
        values.revisedInterest;
      ARAdjustDetails.financialPayoutAdjustmentVO.fee = values.revisedFee;
      setAllowNavigation(false);

      if (
        ARAdjustDetails &&
        ARAdjustDetails.adjustmentVO &&
        ARAdjustDetails.fcn
      ) {
        // dispatch(searchFCNAccountReceivableAction(ARAdjustDetails.fcn));
      }

      // Refresh Attachmnets section, pass FCN as argument
      if (
        ARAdjustDetails &&
        ARAdjustDetails.adjustmentVO &&
        ARAdjustDetails.fcn
      ) {
        dispatch(refreshAttachmentsAction(ARAdjustDetails.fcn));
      }
    } else if (
      saveARAdjustResult &&
      saveARAdjustResult.respcode &&
      saveARAdjustResult.respcode === "Fail"
    ) {
      seterrorMessages([saveARAdjustResult.respdesc]);
    } else if (
      saveARAdjustResult &&
      saveARAdjustResult.respcode &&
      saveARAdjustResult.respcode !== "01"
    ) {
      seterrorMessages([saveARAdjustResult.errorMessages]);
    } else if (saveARAdjustResult && saveARAdjustResult.SystemError) {
      seterrorMessages([ErrorConstants.SYS_ERROR]);
    }
  }, [saveARAdjustResult]);
  useEffect(() => {
    if (refreshAttachmentResult && !refreshAttachmentResult.systemFailure) {
      setAttachmentsTableData(refreshAttachmentResult);
    }
  }, [refreshAttachmentResult]);
  useEffect(() => {
    if (reasonCds && reasonCds?.PayOutRsnCd) {
      let date = moment(new Date()).format(DATE_FORMAT);
      const nonVoidedRsnCdsList = reasonCds?.PayOutRsnCd[0]?.systemListDetails?.filter(
        (code) => code.voidDate === null
      );
      const nonVoidedEndDtRsnCdsList = nonVoidedRsnCdsList.filter(
        (code) => compareTwoDatesGreaterThanOrEqual(date, code.endDate)
      );
      const nonVoidedRsnCds = nonVoidedEndDtRsnCdsList?.map(rsnCd => rsnCd.startingValue);
      setPayoutRsnCd(nonVoidedRsnCds);
    }
  }, [reasonCds]);
  const [values, setValues] = React.useState({
    revisedARAmount: ARAdjustDetails.financialPayoutAdjustmentVO.balanceAmount,
    revisedInterest: "",
    revisedPenality: "",
    revisedFee: "",
    reasonCode: DEFAULT_DD_VALUE,
    adjustedArAmt: ARAdjustDetails.financialPayoutAdjustmentVO.balanceAmount
  });
  const payoutReasonCd = payoutRsnCd?.includes(ARAdjustDetails.financialPayoutAdjustmentVO.adjusmentReasonCode);
  useEffect(() => {
    if (open === true) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [open]);

  useEffect(() => {
    if (!payoutReasonCd) {
      setARAdjustDetails({
        ...ARAdjustDetails,
        financialPayoutAdjustmentVO: {
          ...ARAdjustDetails.financialPayoutAdjustmentVO,
          costSettlementBeginDate: moment(new Date()).format(DATE_FORMAT),
          costSettlementEndDate: moment(new Date(MAX_DATE_FORMAT)).format(
            DATE_FORMAT
          ),
        },
      });
      setBeginDate(new Date());
      setEndDate(new Date(MAX_DATE_FORMAT));
    } else {
      setARAdjustDetails({
        ...ARAdjustDetails,
        financialPayoutAdjustmentVO: {
          ...ARAdjustDetails.financialPayoutAdjustmentVO,
          costSettlementBeginDate: null,
          costSettlementEndDate: null,
        },
      });
      setBeginDate(null);
      setEndDate(null);
    }
  }, [
    payoutReasonCd,
    ARAdjustDetails.financialPayoutAdjustmentVO.adjusmentReasonCode,
  ]);

  const [
    {
      beginDateErr,
      endDateErr,
      bgdtInvalid,
      edtInvalid,
      bgdtGtEdtErr,
      showCheckDateErrorTemp,
      showCheckDateGreaterErr,
      showFiscalYearErrorTemp,
      showAmountErrorTemp,
      showAmountGreaterErrorTemp,
      showFiscalYearReqErrorTemp,
      showReasoncodeErrorTemp,
      showFundCodeErrorTemp,
      showFundCodeFisError,
      showFiscalYearFormatErrorTemp,
      showCheckDtErrorTemp,
      showCheckNumErrorTemp
    },
    setFormErrors
  ] = React.useState(false);

  const AdjustAmount = () => {
    let rARamt = values.revisedARAmount;
    let rInst = values.revisedInterest;
    let rFee = values.revisedFee;
    let rPenality = values.revisedPenality;
    const amount =
      Number(values.revisedARAmount.replace(/,/g, "")) +
      Number(values.revisedInterest.replace(/,/g, "")) +
      Number(values.revisedPenality.replace(/,/g, "")) +
      Number(values.revisedFee.replace(/,/g, ""));
    if (
      values.revisedARAmount === "" ||
      Number(values.revisedARAmount.replace(/,/g, "")) === 0
    ) {
      rARamt = "0.00";
    }
    if (
      values.revisedInterest &&
      Number(values.revisedInterest.replace(/,/g, "")) === 0
    ) {
      rInst = "0.00";
    }
    if (
      values.revisedFee &&
      Number(values.revisedFee.replace(/,/g, "")) === 0
    ) {
      rFee = "0.00";
    }
    if (
      values.revisedPenality &&
      Number(values.revisedPenality.replace(/,/g, "")) === 0
    ) {
      rPenality = "0.00";
    }
    if (
      !revisedARAmountError &&
      !revisedFeeError &&
      !revisedInterestError &&
      !revisedPenalityError
    ) {
      setValues({
        ...values,
        revisedARAmount: numeral(rARamt).format("0,0.00"),
        revisedInterest: values.revisedInterest
          ? numeral(rInst).format("0,0.00")
          : "",
        revisedPenality: values.revisedPenality
          ? numeral(rPenality).format("0,0.00")
          : "",
        revisedFee: values.revisedFee ? numeral(rFee).format("0,0.00") : "",
        adjustedArAmt: String(amount)
      });
    }
  };

  const valuesFromFinAdjustPayout = props.history && props.history.location && props.history.location.state && props.history.location.state.values;

  const majorCancel = () => {
    clearSuccessErrorMsgs();
    if (isFocusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      props.history.push({
        pathname: '/FinancialPayoutUpdate',
        payoutDataRender: true,
        state: { valuesFromFinAdjustPayout: valuesFromFinAdjustPayout }
      });
    }
  };

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setDialogOpen(false);
    setFocusCheck(false);
    props.history.push({
      pathname: '/FinancialPayoutUpdate',
      payoutDataRender: true,
      state: { valuesFromFinAdjustPayout: valuesFromFinAdjustPayout }
    });
  };
  function masterSave () {
    seterrorMessages([]);

    const errorMessagesArray = [];
    const errors = {};
    setFormErrors({
      beginDateErr: false,
      endDateErr: false,
      edtInvalid: false,
      bgdtGtEdtErr: false,
      showCheckDateErrorTemp: false,
      showCheckDateGreaterErr: false,
      showFiscalYearErrorTemp: false
    });
    const dateToday = new Date();
    const monthToday = dateToday.getMonth() + 1;
    const currFiscalYear = monthToday > 6 ? dateToday.getFullYear() + 1 : dateToday.getFullYear();
    if (ARAdjustDetails.atrNumberField || ARAdjustDetails.atrNumber) {
      const regex = /[^A-Za-z0-9]/;
      if (regex.test(ARAdjustDetails.atrNumberField || ARAdjustDetails.atrNumber)) {
        showATRError(true);
        errorMessagesArray.push(process.env.REACT_APP_TRANSACTION_NUMBER_LABEL + ' ' + ErrorConstants.TRANSACTION_NUMBER_FORMAT);
        seterrorMessages(errorMessagesArray);

        return;
      } else { showATRError(false); }
    }

    //  if(matchingData){
    let pushFlag = false;
    if (payoutReasonCd) {
      if (beginDate === null && beginDate !== undefined) {
        errors.beginDateErr = true;
        pushFlag = true;
      } else if (new Date(beginDate).toString() === 'Invalid Date' && beginDate !== undefined) {
        errors.bgdtInvalid = true;
        errorMessagesArray.push(ErrorConstants.SETTLEMENT_BEGINDATE_INVALID);
      }
      if (endDate === null && endDate !== undefined) {
        errors.endDateErr = true;
        pushFlag = true;
      } else if (new Date(endDate).toString() === 'Invalid Date' && endDate !== undefined) {
        errors.edtInvalid = true;
        errorMessagesArray.push(ErrorConstants.SETTLEMENT_ENDDATE_INVALID);
      } else if ((moment(new Date(beginDate)).format(DATE_FORMAT)) > (moment(new Date(endDate)).format(DATE_FORMAT))) {
        errors.bgdtGtEdtErr = true;
        errorMessagesArray.push(ErrorConstants.BEGIN_END_DATE_COMPARE);
      }

      if (pushFlag) {
        errorMessagesArray.push(ErrorConstants.SETTLEMENT_DATES_REQUIRED);
      }
    }

    if (ARAdjustDetails.financialPayoutAdjustmentVO.adjustmentAmount === '' || ARAdjustDetails.financialPayoutAdjustmentVO.adjustmentAmount === 'undefined') {
      errors.showAmountErrorTemp = true;
      errorMessagesArray.push(ErrorConsts.AMOUNT_REQUIRED);
    }
    if (parseFloat(ARAdjustDetails.financialPayoutAdjustmentVO.adjustmentAmount) <= 0) {
      errors.showAmountGreaterErrorTemp = true;
      errorMessagesArray.push(ErrorConsts.AMOUNT_GREATER_THAN_ZERO);
    }
    if (ARAdjustDetails.financialAdjustmentAccountVO.fiscalYear === '' || ARAdjustDetails.financialAdjustmentAccountVO.fiscalYear === 'undefined') {
      errors.showFiscalYearReqErrorTemp = true;
      errorMessagesArray.push(ErrorConsts.FISCAL_YEAR_REQUIRED);
    }
    if (ARAdjustDetails.financialAdjustmentAccountVO.fiscalYear && ARAdjustDetails.financialAdjustmentAccountVO.fiscalYear.length < 4) {
      errors.showFiscalYearFormatErrorTemp = true;
      errorMessagesArray.push(ErrorConsts.DATA_FORMAT_ERROR);
    }
    if (ARAdjustDetails.financialPayoutAdjustmentVO.adjusmentReasonCode === '' || ARAdjustDetails.financialPayoutAdjustmentVO.adjusmentReasonCode === 'undefined') {
      errors.showReasoncodeErrorTemp = true;
      errorMessagesArray.push(ErrorConsts.REASON_CODE_REQUIRED);
    }
    if (ARAdjustDetails.financialAdjustmentAccountVO.fiscalYear && (ARAdjustDetails.financialAdjustmentAccountVO.fundCode === '' || ARAdjustDetails.financialAdjustmentAccountVO.fundCode === 'undefined')) {
      errors.showFundCodeErrorTemp = true;
      errorMessagesArray.push(ErrorConsts.COLLOCATION_CODE);
    } else if (ARAdjustDetails.financialAdjustmentAccountVO.fundCode === '' || ARAdjustDetails.financialAdjustmentAccountVO.fundCode === 'undefined') {
      errors.showFundCodeFisError = true;
      errorMessagesArray.push(ErrorConsts.FUND_CODE_REQUIRED);
    }
    if (!apmEnable) {
      if (ARAdjustDetails.financialPayoutAdjustmentVO?.checkDate) {
        if (!moment(ARAdjustDetails.financialPayoutAdjustmentVO?.checkDate).isValid()) {
          errors.showCheckDateErrorTemp = true;
          errorMessagesArray.push(ErrorConsts.MANUAL_DATE_INVALID);
        } else if (moment(ARAdjustDetails.financialPayoutAdjustmentVO?.checkDate).isValid() && new Date(ARAdjustDetails.financialPayoutAdjustmentVO?.checkDate).setHours(0, 0, 0, 0) > new Date(ARAdjustDetails.financialPayoutAdjustmentVO?.establishedDate).setHours(0, 0, 0, 0)) {
          errors.showCheckDateGreaterErr = true;
          errorMessagesArray.push(ErrorConsts.CHECK_DATE_GREATER_EST_DATE);
        }
      }
      if ((ARAdjustDetails.financialPayoutAdjustmentVO.checkNumber === '' || ARAdjustDetails.financialPayoutAdjustmentVO.checkNumber === 'undefined') && (ARAdjustDetails.financialPayoutAdjustmentVO.checkDate === null || ARAdjustDetails.financialPayoutAdjustmentVO.checkDate === 'undefined')) {
        errors.showCheckNumErrorTemp = true;
        errors.showCheckDtErrorTemp = true;
        errorMessagesArray.push(ErrorConsts.CHECK_DETAILS_REQUIRED);
      } else {
        if (ARAdjustDetails.financialPayoutAdjustmentVO.checkNumber === '' || ARAdjustDetails.financialPayoutAdjustmentVO.checkNumber === 'undefined') {
          errors.showCheckNumErrorTemp = true;
          errorMessagesArray.push(ErrorConsts.CHECK_DETAILS_REQUIRED);
        }
        if (ARAdjustDetails.financialPayoutAdjustmentVO.checkDate === null || ARAdjustDetails.financialPayoutAdjustmentVO.checkDate === 'undefined') {
          errors.showCheckDtErrorTemp = true;
          errorMessagesArray.push(ErrorConsts.CHECK_DETAILS_REQUIRED);
        }
      }
    }
    if (!disablePage) {
      if (ARAdjustDetails.financialAdjustmentAccountVO.fiscalYear &&
        ARAdjustDetails.financialAdjustmentAccountVO.fiscalYear.length === 4 &&
        ARAdjustDetails.financialAdjustmentAccountVO.fiscalYear < currFiscalYear) {
        errors.showFiscalYearErrorTemp = true;
        errorMessagesArray.push(ErrorConsts.WRONG_FISCAL_YEAR);
      }
    }
    if (errorMessagesArray.length) {
      seterrorMessages(errorMessagesArray);
      setFormErrors(errors);

      return false;
    } else {
      ARAdjustDetails.auditUserID = logInUserID;
      ARAdjustDetails.addedAuditUserID = ARAdjustDetails.addedAuditUserID || logInUserID;
      ARAdjustDetails.auditTimeStamp = getUTCTimeStamp();
      ARAdjustDetails.addedAuditTimeStamp = ARAdjustDetails.addedAuditTimeStamp ? ARAdjustDetails.addedAuditTimeStamp : getUTCTimeStamp();
      ARAdjustDetails.entityType = ARAdjustDetails.payeePayerTypeCode;
      ARAdjustDetails.entityIDTypeCode = ARAdjustDetails.payeePayerIDTypeCode;
      ARAdjustDetails.entityIDTypeCodeValue = ARAdjustDetails.payeePayerIDTypeCode;
      if (ARAdjustDetails.financialAdjustmentAccountVO?.categoryOfServices === DEFAULT_DD_VALUE) {
        ARAdjustDetails.financialAdjustmentAccountVO.categoryOfServices = null;
      }
      if (ARAdjustDetails?.categoryOfService === DEFAULT_DD_VALUE) {
        ARAdjustDetails.categoryOfService = null;
      }
      axios.post(`${serviceEndPoint.PAYOUT_ADJUST_SAVE_ENDPOINT}`, ARAdjustDetails)
        .then(response => {
          if (response?.data?.respcode == '01') {
            setsaveMessage([ErrorConstants.SAVE_SUCCESS]);
            setDisablepage(true);
            setFocusCheck(false);
          } else {
            seterrorMessages([ErrorConstants.SYS_ERROR]);
          }
        })
        .catch(error => {
          if (error?.response?.data?.respdesc) {
            seterrorMessages([error.response.data.respdesc]);
          } else {
            seterrorMessages([ErrorConstants.SYS_ERROR]);
          }
        });
    }
  }

  const payload = useSelector((state) => state.accountReceivableState.payload);
  let errorMessagesArray;

  useEffect(() => {
    if (payload && payload.respcode === "02" && payload.respdesc !== "") {
      errorMessagesArray = [];
      errorMessagesArray.push(payload.respdesc);
      seterrorMessages(errorMessagesArray);
    }
    if (payload && payload.systemFailure !== undefined) {
      errorMessagesArray = [];
      errorMessagesArray.push("TEST");
      seterrorMessages(errorMessagesArray);
    }

    if (
      payload != null &&
      payload !== "" &&
      payload.systemFailure === undefined
    ) {
      setFtTcn(payload.relatedFTTCN);
    }
  }, [payload]);

  const apmEnable = ddListObj["Financial#3006"]?.findIndex(e => e.code == ARAdjustDetails.financialPayoutAdjustmentVO
    .adjusmentReasonCode && e.description?.includes('APM')) === -1;

  const cosVal = ddListObj["Claims#1020"] && ddListObj["Claims#1020"].find(value => value.code === ARAdjustDetails.financialAdjustmentAccountVO
    .categoryOfServices);

  const fundVal = ddListObj["Financial#3200"] && ddListObj["Financial#3200"].find(value => value.code === ARAdjustDetails.financialAdjustmentAccountVO
    .fundCode);

    return (
    <div>
      <div>
        <Prompt
          when={allowNavigation}
          message={(location) =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />

        <div className="tabs-container" ref={toPrintRef}>
          <ErrorMessages errorMessages={errorMessages} />
          {saveMessage.length > 0 && errorMessages.length === 0 ? (
            <SuccessMessages successMessages={saveMessage} />
          ) : null}
          <div className="tab-header">
            <h1 className="tab-heading float-left">
              Financial Payout Adjustment
            </h1>
            <div className="float-right mt-2">
              <Button className="btn btn-success" onClick={masterSave} disabled={disablePage}>
                <i className="fa fa-check" aria-hidden="true"></i>
                Save
              </Button>
              <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="tab-body">
            <form autoComplete="off">
              <div className="form-wrapper">
                <NativeDropDown
                  id="ARAjuststandard-Payee/Payer"
                  label="Payee Type"
                  value={ARAdjustDetails.payeePayerTypeCode}
                  inputProps={{ maxLength: 13 }}
                  // disabled
                  InputProps={{ readOnly: true, className: "Mui-disabled" }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={dropdownData
                    ? dropdownData['G1#G_CMN_ENTY_TY_CD'] && dropdownData['G1#G_CMN_ENTY_TY_CD'].map(option => (
                      <option key={option.code} value={option.code}>
                        {option.description}
                      </option>
                    ))
                    : null}
                />
                <NativeDropDown
                  id="ARAjuststandardPayee/PayerIDType"
                  label="Payee ID Type Code"
                  value={ARAdjustDetails.payeePayerIDTypeCode}
                  inputProps={{ maxLength: 13 }}
                  // disabled
                  InputProps={{ readOnly: true, className: "Mui-disabled" }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={dropdownData ? payeeTypeIdCodeData && payeeTypeIdCodeData.map(option => (
                    <option key={option.code} value={option.code}>
                      {option.description}
                    </option>
                  ))
                    : null}
                />
                <div className="mui-custom-form input-md">
                  <TextField
                    id="ARAjuststandardPayeeID"
                    fullWidth
                    label="Payee ID"
                    value={ARAdjustDetails.entityID}
                    inputProps={{ maxLength: 15 }}
                    // disabled
                    InputProps={{ readOnly: true, className: "Mui-disabled" }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>
                <div className="mui-custom-form input-md has-link">
                  <TextField
                    id="ARAjuststandardSystemPayeeID"
                    fullWidth
                    label="System Payee ID"
                    // disabled
                    InputProps={{
                      readOnly: true,
                      className: "Mui-disabled",
                      startAdornment: (
                        <InputAdornment position="start">
                          <Link to={redirectSystemID(ARAdjustDetails)}>
                            {ARAdjustDetails.sysID}
                          </Link>
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>
                <div className="mui-custom-form input-md">
                  <TextField
                    id="ARAjuststandardPayee/PayerName"
                    fullWidth
                    label="Payee Name"
                    value={ARAdjustDetails.payeePayerName}
                    InputProps={{ readOnly: true, className: "Mui-disabled" }}
                    inputProps={{ maxLength: 60 }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>
                <NativeDropDown
                  id="standard-MemberIDType"
                  label="Member ID Type"
                  disabled={true}
                  value={ARAdjustDetails.memberIDType}
                  placeholder=""
                  inputProps={{ maxlength: 3 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={dropdownData
                    ? dropdownData['Member#B_ALT_ID_TY_CD'] && dropdownData['Member#B_ALT_ID_TY_CD'].map(option => (
                      <option key={option.code} value={option.code}>
                        {option.description}
                      </option>
                    ))
                    : null}
                />
                <div className="mui-custom-form input-md">
                  <TextField
                    id="standard-MemberID"
                    fullWidth
                    label="Member ID"
                    disabled={true}
                    placeholder=""
                    inputProps={{ maxlength: 15 }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={ARAdjustDetails.memberID || ""}
                    helperText={null}
                    error={null}
                  ></TextField>
                </div>
                <div className="mui-custom-form input-md">
                  <TextField
                    id="standard-MemberName"
                    fullWidth
                    label="Member Name"
                    disabled={true}
                    value={ARAdjustDetails.memberName || ""}
                    inputProps={{ maxlength: 60 }}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                  ></TextField>
                </div>
              </div>

              <div className="mui-custom-form override-width-95">
                <div>
                  <fieldset className="r-custom-fieldset">
                    <legend>Original Request:</legend>
                    <div className="form-wrapper">
                      <div className="mui-custom-form">
                        <TextField
                          id="standard-FCN-id-0"
                          fullWidth
                          label="FCN"
                          placeholder=""
                          value={ARAdjustDetails.fcn}
                          disabled={true}
                          InputProps={{
                            readOnly: true,
                            className: "Mui-disabled"
                          }}
                          inputProps={{ maxlength: 14 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        ></TextField>
                      </div>
                      <div
                        className="mui-custom-form "
                        style={{ lineHeight: "100%" }}
                      >
                        <TextField
                          id="standard-CheckNumber-id"
                          fullWidth
                          label="Check Number"
                          placeholder=""
                          inputProps={{ maxlength: 15, 'data-testid': 'test_original_check_number' }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          disabled={true}
                          InputProps={{
                            readOnly: true,
                            className: "Mui-disabled"
                          }}
                          value={FPAdjustDetails?.checkNumber}
                          required={false}
                          helperText={null}
                          error={null}
                        ></TextField>
                      </div>
                      {
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div
                            className="mui-custom-form with-date with-select"
                            style={{ lineHeight: "100%" }}
                          >
                            <KeyboardDatePicker
                              maxDate={Date.parse(MAX_DATE)}
                              id="date-picker-dialogCheckDate"
                              label="Check Date"
                              required={false}
                              disabled={true}
                              InputProps={{
                                readOnly: true,
                                className: "Mui-disabled"
                              }}
                              inputProps={{'data-testid': 'test_original_check_date'}}
                              format="MM/dd/yyyy"
                              placeholder="mm/dd/yyyy"
                              value={FPAdjustDetails?.checkDate}
                              InputLabelProps={{
                                shrink: true
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                              helperText={null}
                              error={null}
                            />
                          </div>
                        </MuiPickersUtilsProvider>
                      }
                      <NativeDropDown
                        id="standard-ReasonCode-Id-0"
                        label="Reason Code"
                        value={ARAdjustDetails.reasonCode}
                        placeholder=""
                        inputProps={{ maxlength: 3 }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        disabled={true}
                        dropdownList={dropdownData
                          ? dropdownData['F1#F_RSN_CD'] && dropdownData['F1#F_RSN_CD'].map(option => (
                            <option key={option.code} value={option.code}>
                              {option.description}
                            </option>
                          ))
                          : null}
                      />
                    </div>
                    <div className="form-wrapper">
                      <div
                        className="mui-custom-form "
                        style={{ lineHeight: "100%" }}
                      >
                        <TextField
                          fullWidth
                          id="amount-Id-1"
                          label="Amount"
                          placeholder=""
                          inputProps={{ maxlength: 12 }}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                              ""
                            );
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            )
                          }}
                          type="text"
                          disabled={true}
                          value={ARAdjustDetails.amount}
                          helperText={null}
                          error={null}
                        ></TextField>
                      </div>
                      <div className="mui-custom-form radio-box input-15">
                        <label className="MuiFormLabel-root small-label no-margin">
                          Approved
                        </label>
                        {!props.editScreen ? (
                          <div className="sub-radio">
                            <Radio
                              id="Approved-Id-0"
                              name="Approved"
                              disabled={true}
                              value={ARAdjustDetails.approval}
                              checked={ARAdjustDetails.approval === "Yes"}
                            />
                            <span
                              className="text-black"
                              style={{
                                fontSize: "14px",
                                fontWeight: "650",
                                color: "#274463"
                              }}
                            >
                              <label
                                style={{ fontSize: "14px" }}
                                htmlFor="Approved-Id-0"
                              >
                                Yes
                              </label>
                            </span>
                            <Radio
                              className="ml-2"
                              name="Approved"
                              id="ApprovedNO"
                              disabled={true}
                              value={ARAdjustDetails.approval}
                              checked={ARAdjustDetails.approval === "No"}
                            />
                            <span
                              className="text-black"
                              style={{
                                fontSize: "14px",
                                fontWeight: "650",
                                color: "#274463"
                              }}
                            >
                              {" "}
                              <label
                                style={{ fontSize: "14px" }}
                                htmlFor="ApprovedNO"
                              >
                                No
                              </label>
                            </span>
                          </div>
                        ) : null}
                        {props.editScreen ? (
                          <div className="sub-radio">
                            {ARAdjustDetails.approval === "Yes" ? (
                              <span
                                className="text-blue"
                                style={{
                                  fontSize: "14px",
                                  verticalAlign: "text-bottom"
                                }}
                              >
                                <label htmlFor="Approved1">Yes</label>
                              </span>
                            ) : (
                              ""
                            )}
                            {ARAdjustDetails.approval === "No" ? (
                              <span
                                className="text-blue"
                                style={{
                                  fontSize: "14px",
                                  verticalAlign: "text-bottom"
                                }}
                              >
                                <label htmlFor="ApprovedNO1">No</label>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : null}
                      </div>
                      <div
                        className="mui-custom-form "
                        style={{ lineHeight: "100%" }}
                      >
                        <TextField
                          id="standard-EstablishedDate"
                          fullWidth
                          label="Established Date"
                          disabled={true}
                          InputProps={{
                            readOnly: true,
                            className: "Mui-disabled"
                          }}
                          placeholder=""
                          inputProps={{ maxlength: 10 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={ARAdjustDetails.establishedDateStr}
                        ></TextField>
                      </div>
                      <div
                        className="mui-custom-form has-link"
                        style={{ lineHeight: "100%" }}
                      >
                        <TextField
                          id="standard-RelatedFTTCN-Id-1"
                          fullWidth
                          label="Related FT TCN"
                          disabled={true}
                          InputProps={{
                            readOnly: true,
                            className: "Mui-disabled",
                            startAdornment: (
                              <InputAdornment position="start">
                                <a
                                  href={
                                    CLAIMS_INQUIRY_TCN_LINK +
                                    ARAdjustDetails.relatedFTTCN
                                  }
                                  target="_blank" rel="noreferrer"
                                >
                                  {ARAdjustDetails.relatedFTTCN}
                                </a>
                              </InputAdornment>
                            )
                          }}
                          placeholder=""
                          inputProps={{ maxlength: 17 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        ></TextField>
                      </div>
                      {
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div
                            className="mui-custom-form with-date with-select"
                            style={{ lineHeight: "100%" }}
                          >
                            <KeyboardDatePicker
                              maxDate={Date.parse(MAX_DATE)}
                              fullWidth
                              id="date-picker-dialog-BeginDate-Id-0"
                              label="Begin Date"
                              format="MM/dd/yyyy"
                              placeholder="mm/dd/yyyy"
                              value={FPAdjustDetails?.beginDate}
                              inputProps={{'data-testid': 'test_original_begin_date'}}
                              InputLabelProps={{
                                shrink: true
                              }}
                              disabled={true}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                              helperText={null}
                              error={null}
                            />
                          </div>
                        </MuiPickersUtilsProvider>
                      }
                      {
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div
                            className="mui-custom-form with-date"
                            style={{ lineHeight: "100%" }}
                          >
                            <KeyboardDatePicker
                              maxDate={Date.parse(MAX_DATE)}
                              fullWidth
                              id="date-picker-dialog-EndDate-Id-0"
                              label="End Date"
                              format="MM/dd/yyyy"
                              placeholder="mm/dd/yyyy"
                              value={FPAdjustDetails?.endDate}
                              inputProps={{'data-testid': 'test_original_end_date'}}
                              InputLabelProps={{
                                shrink: true
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                              disabled={true}
                              helperText={null}
                              error={null}
                            />
                          </div>
                        </MuiPickersUtilsProvider>
                      }
                      <div
                        className="mui-custom-form "
                        style={{ lineHeight: "100%" }}
                      >
                        <TextField
                          id="standard-CheckNumber-RA"
                          fullWidth
                          label="RA Number"
                          placeholder=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            readOnly: true,
                            className: "Mui-disabled"
                          }}
                          value={ARAdjustDetails.raNumber}
                        ></TextField>
                      </div>
                      <div
                        className="mui-custom-form "
                        style={{ lineHeight: "100%" }}
                      >
                        <TextField
                          id="standard-CheckNumber-payment"
                          fullWidth
                          label="Payment Status"
                          placeholder=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            readOnly: true,
                            className: "Mui-disabled"
                          }}
                          value={
                            dropdownData && dropdownData['F1#C_STAT_CD'] &&
                            dropdownData['F1#C_STAT_CD']?.find(
                              (e) =>
                                e.code === ARAdjustDetails.paymentStatusCode
                            )?.description
                          }
                        ></TextField>
                      </div>
                    </div>
                    <div className="form-wrapper">
                      <div className="mui-custom-form">
                        <TextField
                          id="standard-FCN1"
                          fullWidth
                          label="Fiscal Pend ID"
                          placeholder=""
                          value={ARAdjustDetails.fiscalPendID}
                          InputProps={{
                            readOnly: true,
                            className: "Mui-disabled"
                          }}
                          inputProps={{ maxlength: 14 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        ></TextField>
                      </div>
                      <div
                        className="mui-custom-form "
                        style={{ lineHeight: "100%" }}
                      >
                        <TextField
                          id='ATRnumber'
                          fullWidth
                          label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL}
                          placeholder=""
                          value={FPAdjustDetails.atrNumber}
                          inputProps={{ maxlength: 18 }}
                          InputProps={{
                            readOnly: true,
                            className: "Mui-disabled"
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        ></TextField>
                      </div>
                    </div>
                    <div className="tab-holder">
                      <div className="row mb-2">
                        <div className="col-12">
                          <fieldset className="custom-fieldset">
                            <legend>Account:</legend>
                            <div className="form-wrapper ">
                              <div className="mui-custom-form input-md override-width-28">
                                <TextField
                                  fullWidth
                                  id="standard-FiscalYear-Id-0"
                                  type="number"
                                  label="Fiscal Year"
                                  placeholder=""
                                  inputProps={{ maxlength: 4 }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  disabled={true}
                                  value={ARAdjustDetails.fiscalYear}
                                  helperText={null}
                                  error={null}
                                ></TextField>
                              </div>
                              <NativeDropDown
                                className="override-width-28"
                                id="standard-CategoryofService-Id-1"
                                label="Category of Service"
                                value={ARAdjustDetails.categoryOfService ? ARAdjustDetails.categoryOfService : DEFAULT_DD_VALUE}
                                disabled={true}
                                placeholder=""
                                inputProps={{ maxlength: 3 }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                dropdownList={dropdownData && dropdownData['C1#C_COS_CD'] &&
                                  dropdownData['C1#C_COS_CD'].length > 0
                                  ? dropdownData['C1#C_COS_CD'].map((item) => (
                                    <option key={item.code} value={item.code}>
                                      {item.description}
                                    </option>
                                  ))
                                  : null}
                              />
                              <NativeDropDown
                                className="override-width-28"
                                id="standard-FundCode-Id-0"
                                label="Fund Code"
                                value={ARAdjustDetails.collocationCode}
                                placeholder=""
                                inputProps={{ maxlength: 10 }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                disabled={true}
                                dropdownList={dropdownData && dropdownData['R1#R_FUND_CD'] &&
                                  dropdownData['R1#R_FUND_CD'].length > 0
                                  ? dropdownData['R1#R_FUND_CD'].map((item) => (
                                    <option key={item.code} value={item.code}>
                                      {item.description}
                                    </option>
                                  ))
                                  : null}
                              />
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="mui-custom-form override-width-95">
                <div>
                  <fieldset className="r-custom-fieldset">
                    <legend>Payout Adjustment Action:</legend>
                    <div className="form-wrapper">
                      <div className="mui-custom-form">
                        <TextField
                          id="standard-FCN-Id-1"
                          fullWidth
                          label="FCN"
                          placeholder=""
                          value={
                            ARAdjustDetails.financialPayoutAdjustmentVO
                              .adjustedFcn
                          }
                          disabled={true}
                          InputProps={{
                            readOnly: true,
                            className: "Mui-disabled"
                          }}
                          inputProps={{ maxlength: 14 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        ></TextField>
                      </div>
                      <div
                        className="mui-custom-form "
                        style={{ lineHeight: "100%" }}
                      >
                        <TextField
                          id="standard-CheckNumber-id1"
                          fullWidth
                          label="Check Number"
                          placeholder=""
                          inputProps={{ maxlength: 15, 'data-testid': 'test_adjust_check_number' }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          disabled={apmEnable}
                          InputProps={{
                            readOnly: apmEnable,
                            className: apmEnable ? "Mui-disabled" : ''
                          }}
                          value={
                            ARAdjustDetails.financialPayoutAdjustmentVO
                              .checkNumber
                          }
                          required={!apmEnable}
                          helperText={showCheckNumErrorTemp && ErrorConsts.CHECK_DETAILS_REQUIRED}
                          error={showCheckNumErrorTemp && ErrorConsts.CHECK_DETAILS_REQUIRED}
                          onChange={(e) => setAdjustDetails(e, 'checkNumber')}
                        ></TextField>
                      </div>
                      {
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div
                            className="mui-custom-form with-date with-select"
                            style={{ lineHeight: "100%" }}
                          >
                            <KeyboardDatePicker
                              maxDate={Date.parse(MAX_DATE)}
                              id="date-picker-dialogCheckDate2"
                              label="Check Date"
                              required={!apmEnable}
                              disabled={apmEnable}
                              InputProps={{
                                readOnly: apmEnable,
                                className: apmEnable ? "Mui-disabled" : ''
                              }}
                              inputProps={{'data-testid': 'test_adjust_check_date'}}
                              format="MM/dd/yyyy"
                              onChange={(e) => setAdjustDetails(e, CHECK_DATE)}
                              placeholder="mm/dd/yyyy"
                              value={
                                ARAdjustDetails.financialPayoutAdjustmentVO?.checkDate
                              }
                              InputLabelProps={{
                                shrink: true
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                              helperText={(showCheckDateErrorTemp && ErrorConsts.MANUAL_DATE_INVALID) || (showCheckDateGreaterErr && ErrorConsts.CHECK_DATE_GREATER_EST_DATE) || (showCheckDtErrorTemp && ErrorConsts.CHECK_DETAILS_REQUIRED)}
                              error={(showCheckDateErrorTemp && ErrorConsts.MANUAL_DATE_INVALID) || (showCheckDateGreaterErr && ErrorConsts.CHECK_DATE_GREATER_EST_DATE) || (showCheckDtErrorTemp && ErrorConsts.CHECK_DETAILS_REQUIRED)}
                            />
                          </div>
                        </MuiPickersUtilsProvider>
                      }
                      <div
                        className="mui-custom-form "
                        style={{ lineHeight: "100%" }}
                      >
                        <TextField
                          id='ATRnumberField'
                          fullWidth
                          label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL}
                          disabled={disablePage}
                          placeholder=""
                          value={ARAdjustDetails.atrNumber}
                          onChange={(e) => setAdjustDetails(e, 'atrNumber')}
                          inputProps={{ maxlength: 18 }}
                          InputLabelProps={{
                            shrink: true
                          }}
						  data-testid="test-atr-num"
                          helperText={atrError ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ErrorConstants.TRANSACTION_NUMBER_FORMAT}` : null}
                          error={atrError ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ErrorConstants.TRANSACTION_NUMBER_FORMAT}` : null}
                        ></TextField>
                      </div>
                      <NativeDropDown
                        isRequired
                        id="standard-ReasonCode-Id-1"
                        label="Reason Code"
                        value={
                          ARAdjustDetails.financialPayoutAdjustmentVO
                            .adjusmentReasonCode
                        }
                        onChange={(e) => setAdjustDetails(e, ADJUSTMENT_REASON_CODE)}
                        disabled={disablePage}
                        placeholder=""
                        inputProps={{ maxlength: 3, "data-testid": "test_adjusment_reason_code" }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        dropdownList={ddListObj["Financial#3006"]?.map(
                          (option, index) => (
                            <option key={index} value={option.code}>
                              {option.description}
                            </option>
                          )
                        )}
                        errTxt={showReasoncodeErrorTemp && ErrorConsts.REASON_CODE_REQUIRED}
                      />
                    </div>
                    <div className="form-wrapper">
                      <div
                        className="mui-custom-form "
                        style={{ lineHeight: "100%" }}
                      >
                        <TextField
                          required
                          fullWidth
                          id="amount-Id-0"
                          label="Amount"
                          placeholder=""
                          inputProps={{ maxlength: 12 }}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                              ""
                            );
                          }}
                          onChange={(e) => setAdjustDetails(e, 'adjustmentAmount')}
                          disabled={disablePage}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            )
                          }}
                          type="text"
                          value={
                            ARAdjustDetails.financialPayoutAdjustmentVO.adjustmentAmount
                          }
                          onBlur={props.formatDecimalForAmount}
                          onFocus={props.removeFormatDecimalForAmount}
                          helperText={showAmountErrorTemp && ErrorConsts.AMOUNT_REQUIRED || showAmountGreaterErrorTemp && ErrorConsts.AMOUNT_GREATER_THAN_ZERO}
                          error={showAmountErrorTemp && ErrorConsts.AMOUNT_REQUIRED || showAmountGreaterErrorTemp && ErrorConsts.AMOUNT_GREATER_THAN_ZERO}
                        ></TextField>
                      </div>
                      <div className="mui-custom-form radio-box input-15">
                        <label className="MuiFormLabel-root small-label no-margin">
                          Approved
                        </label>
                        {!props.editScreen ? (
                          <div className="sub-radio">
                            <Radio
                              id="Approved-Id"
                              name="Approved"
                              disabled={true}
                              value={ARAdjustDetails.approval}
                              checked={ARAdjustDetails.approval === "Yes"}
                            />
                            <span
                              className="text-black"
                              style={{
                                fontSize: "14px",
                                fontWeight: "650",
                                color: "#274463"
                              }}
                            >
                              <label
                                style={{ fontSize: "14px" }}
                                htmlFor="Approved-Id"
                              >
                                Yes
                              </label>
                            </span>
                            <Radio
                              className="ml-2"
                              name="Approved"
                              id="ApprovedNO-Id-0"
                              disabled={true}
                              value={ARAdjustDetails.approval}
                              checked={ARAdjustDetails.approval === "No"}
                            />
                            <span
                              className="text-black"
                              style={{
                                fontSize: "14px",
                                fontWeight: "650",
                                color: "#274463"
                              }}
                            >
                              {" "}
                              <label
                                style={{ fontSize: "14px" }}
                                htmlFor="ApprovedNO-Id-0"
                              >
                                No
                              </label>
                            </span>
                          </div>
                        ) : null}
                        {props.editScreen ? (
                          <div className="sub-radio">
                            {ARAdjustDetails.approval === "Yes" ? (
                              <span
                                className="text-blue"
                                style={{
                                  fontSize: "14px",
                                  verticalAlign: "text-bottom"
                                }}
                              >
                                <label htmlFor="Approved">Yes</label>
                              </span>
                            ) : (
                              ""
                            )}
                            {ARAdjustDetails.approval === "No" ? (
                              <span
                                className="text-blue"
                                style={{
                                  fontSize: "14px",
                                  verticalAlign: "text-bottom"
                                }}
                              >
                                <label htmlFor="ApprovedNO">No</label>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : null}
                      </div>
                      <div
                        className="mui-custom-form "
                        style={{ lineHeight: "100%" }}
                      >
                        <TextField
                          id="standard-EstablishedDate-Id-0"
                          fullWidth
                          label="Established Date"
                          disabled={true}
                          InputProps={{
                            readOnly: true,
                            className: "Mui-disabled"
                          }}
                          placeholder=""
                          inputProps={{ maxlength: 10 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={ARAdjustDetails.financialPayoutAdjustmentVO?.establishedDate || null}
                        ></TextField>
                      </div>
                      <div
                        className="mui-custom-form has-link"
                        style={{ lineHeight: "100%" }}
                      >
                        <TextField
                          id="standard-RelatedFTTCN-Id-0"
                          fullWidth
                          label="Related FT TCN"
                          disabled={true}
                          InputProps={{
                            readOnly: true,
                            className: "Mui-disabled",
                            startAdornment: (
                              <InputAdornment position="start">
                                <a
                                  href={
                                    CLAIMS_INQUIRY_TCN_LINK +
                                    ARAdjustDetails.financialPayoutAdjustmentVO
                                      ?.relatedFtTcn
                                  }
                                  target="_blank" rel="noreferrer"
                                >
                                  {
                                    ARAdjustDetails.financialPayoutAdjustmentVO
                                      .relatedFtTcn
                                  }
                                </a>
                              </InputAdornment>
                            )
                          }}
                          placeholder=""
                          inputProps={{ maxlength: 17 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        ></TextField>
                      </div>
                      {
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div
                            className="mui-custom-form with-date with-select"
                            style={{ lineHeight: "100%" }}
                          >
                            <KeyboardDatePicker
                              maxDate={Date.parse(MAX_DATE)}
                              fullWidth
                              required={beginEndDateReqSts || (!!payoutReasonCd)}
                              id="date-picker-dialog-BeginDate-Id-1"
                              inputProps={{"data-testid": 'test_cost_settlement_begin_date'}}
                              label="Begin Date"
                              format="MM/dd/yyyy"
                              placeholder="mm/dd/yyyy"
                              value={((beginEndDateReqSts || payoutReasonCd) && !beginDateValue)
                                ? null
                                : ARAdjustDetails.financialPayoutAdjustmentVO.costSettlementBeginDate}
                              onChange={handleSettlementBeginDateChange('costSettlementBeginDate')}
                              disabled={disablePage}
                              InputLabelProps={{
                                shrink: true
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                              helperText={
                                ((beginDateErr && payoutReasonCd) ? ErrorConstants.SETTLEMENT_DATES_REQUIRED : bgdtInvalid ? ErrorConstants.SETTLEMENT_BEGINDATE_INVALID : '')
                              }
                              error={beginDateErr || bgdtInvalid}
                            />
                          </div>
                        </MuiPickersUtilsProvider>
                      }
                      {
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div
                            className="mui-custom-form with-date"
                            style={{ lineHeight: "100%" }}
                          >
                            <KeyboardDatePicker
                              maxDate={Date.parse(MAX_DATE)}
                              fullWidth
                              id="date-picker-dialog-EndDate-Id-1"
                              required={!!(beginEndDateReqSts || payoutReasonCd)}
                              inputProps={{"data-testid": "test_cost_settlement_end_date"}}
                              label="End Date"
                              format="MM/dd/yyyy"
                              placeholder="mm/dd/yyyy"
                              value={((beginEndDateReqSts || payoutReasonCd) && !endDateValue) ? null :
                                ARAdjustDetails.financialPayoutAdjustmentVO.costSettlementEndDate
                              }
                              onChange={handleSettlementEndDateChange('costSettlementEndDate')}
                              disabled={disablePage}
                              InputLabelProps={{
                                shrink: true
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                              helperText={
                                ((endDateErr && payoutReasonCd) ? ErrorConstants.SETTLEMENT_DATES_REQUIRED : edtInvalid ? ErrorConstants.SETTLEMENT_ENDDATE_INVALID : bgdtGtEdtErr && payoutReasonCd ? ErrorConstants.BEGIN_END_DATE_COMPARE : '')
                              }
                              error={endDateErr || edtInvalid || bgdtGtEdtErr}
                            />
                          </div>
                        </MuiPickersUtilsProvider>
                      }
                    </div>
                    <div className="tab-holder">
                      <div className="row mb-2">
                        <div className="col-12">
                          <fieldset className="custom-fieldset">
                            <legend>Account:</legend>
                            <div className="form-wrapper ">
                              <div className="mui-custom-form input-md override-width-28">
                                <TextField
                                  required
                                  fullWidth
                                  id="standard-FiscalYear-Id-1"
                                  type="number"
                                  label="Fiscal Year"
                                  onChange={(e) => setAccountDetails(e, "fiscalYear")}
                                  disabled={disablePage}
                                  placeholder=""
                                  inputProps={{ maxlength: 4 }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  value={
                                    ARAdjustDetails.financialAdjustmentAccountVO
                                      .fiscalYear
                                  }
                                  helperText={(showFiscalYearErrorTemp && ErrorConsts.WRONG_FISCAL_YEAR) || (showFiscalYearReqErrorTemp && ErrorConsts.FISCAL_YEAR_REQUIRED) || (showFiscalYearFormatErrorTemp && ErrorConsts.DATA_FORMAT_ERROR)}
                                  error={(showFiscalYearErrorTemp && ErrorConsts.WRONG_FISCAL_YEAR) || (showFiscalYearReqErrorTemp && ErrorConsts.FISCAL_YEAR_REQUIRED) || (showFiscalYearFormatErrorTemp && ErrorConsts.DATA_FORMAT_ERROR)}
                                ></TextField>
                              </div>
                              <NativeDropDown
                                className="override-width-28"
                                id="standard-CategoryofService-Id-0"
                                label="Category of Service"
                                value={
                                  cosVal !== undefined ? ARAdjustDetails.financialAdjustmentAccountVO
                                    .categoryOfServices : DEFAULT_DD_VALUE
                                }
                                onChange={(e) => setAccountDetails(e, "categoryOfServices")}
                                disabled={disablePage}
                                placeholder=""
                                inputProps={{ maxlength: 3 }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                dropdownList={ddListObj["Claims#1020"]?.map(
                                  (option, index) => (
                                    <option key={index} value={option.code}>
                                      {option.description}
                                    </option>
                                  )
                                )}
                              />
                              <NativeDropDown
                                isRequired
                                className="override-width-28"
                                id="standard-FundCode-Id-1"
                                label="Fund Code"
                                value={
                                  fundVal !== undefined ? ARAdjustDetails.financialAdjustmentAccountVO
                                    .fundCode : DEFAULT_DD_VALUE
                                }
                                onChange={(e) => setAccountDetails(e, "fundCode")}
                                disabled={disablePage}
                                placeholder=""
                                inputProps={{ maxlength: 10 }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                dropdownList={ddListObj[
                                  "Financial#3200"
                                ]?.map((option, index) => (
                                  <option key={index} value={option.code}>
                                    {option.description}
                                  </option>
                                ))}
                                errTxt={(showFundCodeErrorTemp && ErrorConsts.COLLOCATION_CODE) || (showFundCodeFisError && ErrorConsts.FUND_CODE_REQUIRED)}
                              />
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              {open === true ? (
                <div className="mui-custom-form override-width-95">
                  <div>
                    <fieldset className="custom-fieldset">
                      <legend>Adjustment Verification:</legend>
                      <form autoComplete="off">
                        <div className="form-wrapper">
                          <div
                            className={
                              saveFlag
                                ? "mui-custom-form input-md has-fcn-link"
                                : "mui-custom-form input-md"
                            }
                          >
                            <TextField
                              id="ARAjuststandard-FCN2"
                              fullWidth
                              label="FCN"
                              inputProps={{ maxLength: 3 }}
                              disabled
                              InputProps={{
                                inputComponent: LinkOfFcn,
                                inputProps: {
                                  value: ARAdjustDetails.fcn,
                                  redirection: redirectGrossLevelFCN,
                                  saveFlag: saveFlag
                                }
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>
                          <div className="mui-custom-form input-md">
                            <TextField
                              id="ARAjuststandard-reasoncode1"
                              fullWidth
                              label="Reason Code"
                              title={getLongDescription(
                                reasonCodeArray,
                                reasonCodeDesc
                              )}
                              disabled
                              value={reasonCodeDesc}
                              inputProps={{ maxLength: 10 }}
                              InputLabelProps={{
                                shrink: true
                              }}
                            ></TextField>
                          </div>
                          <div className="mui-custom-form input-md">
                            <TextField
                              id="ARAjuststandard-EstablishedDate"
                              fullWidth
                              label="Established Date"
                              value={moment(new Date()).format("L")}
                              inputProps={{ maxLength: 10 }}
                              disabled
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>
                          <div className="mui-custom-form input-md has-link">
                            <TextField
                              id="ARAjuststandardRelated FT"
                              fullWidth
                              label="Related FT TCN"
                              disabled
                              InputProps={{
                                inputComponent: LinkOfTcn,
                                inputProps: {
                                  value: adjFtTcn,
                                  saveFlag: saveFlag
                                }
                              }}
                              inputProps={{ maxLength: 10 }}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>
                          <div className="mui-custom-form input-Adronment">
                            <TextField
                              id="ARAjuststandard-RevisedA/R"
                              fullWidth
                              disabled
                              label="Revised A/R Amount"
                              value={verificationState.revisedARAmount}
                              onBlur={AdjustAmount}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                )
                              }}
                              inputProps={{ maxLength: 16 }}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>

                          <div className="mui-custom-form input-Adronment with-icon with-icon-margin">
                            <TextField
                              id="standard-originalamt"
                              fullWidth
                              label="Original A/R Amount"
                              value={numeral(
                                ARAdjustDetails.financialPayoutAdjustmentVO
                                  .originalAmount
                              ).format("0,0,000,000,000,000,000.00")}
                              disabled
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                )
                              }}
                              inputProps={{ maxLength: 11 }}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>
                          <div className="mui-custom-form input-Adronment with-icon with-icon-margin">
                            <TextField
                              id="standard-fttcn1"
                              fullWidth
                              label="Adjusted A/R Amount"
                              value={numeral(
                                verificationState.adjustedArAmt
                              ).format("0,0,000,000,000,000,000.00")}
                              inputProps={{ maxLength: 17 }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                )
                              }}
                              disabled
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>

                          <div className="mui-custom-form input-Adronment with-icon with-icon-margin">
                            <TextField
                              id="standard-balanceamt1"
                              fullWidth
                              label="Balance Amount"
                              value={numeral(
                                verificationState.adjustedArAmt
                              ).format("0,0,000,000,000,000,000.00")}
                              disabled
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                )
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>

                          <div className="mui-custom-form input-Adronment ">
                            <TextField
                              id="standard-interest1"
                              fullWidth
                              disabled
                              label="Interest"
                              value={numeral(
                                verificationState.revisedInterest
                              ).format("0,0,000,000,000,000,000.00")}
                              placeholder=""
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                )
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>
                          <div className="mui-custom-form input-Adronment with-icon with-icon-margin">
                            <TextField
                              id="standard-penalty1"
                              fullWidth
                              disabled
                              label="Penalty"
                              value={numeral(
                                verificationState.revisedPenality
                              ).format("0,0,000,000,000,000,000.00")}
                              placeholder=""
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                )
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>
                          <div className="mui-custom-form input-Adronment with-icon with-icon-margin">
                            <TextField
                              id="standard-lettersent1"
                              fullWidth
                              disabled
                              label="Fees Assessed"
                              value={numeral(
                                verificationState.revisedFee
                              ).format("0,0,000,000,000,000,000.00")}
                              placeholder=""
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                )
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>
                        </div>
                      </form>
                    </fieldset>
                  </div>
                </div>
              ) : null}
            </form>
          </div>
          <Footer print />
          <Dialog
            open={dialogOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="custom-alert-box"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <>
                  Unsaved changes will be lost. <br />
                  Are you sure you want to continue?
                </>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setDialogOpen(false);
                }}
                color="primary"
                className="btn btn-transparent"
              >
                STAY ON THIS PAGE!
              </Button>
              <Button
                onClick={cancelMinorSection}
                color="primary"
                className="btn btn-success"
                autoFocus
              >
                CONTINUE <i className="fa fa-arrow-right ml-1" />
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
export default withRouter(FPAdjust);
