import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import { validValueViewAction } from '../../Store/Actions/validValue/validValueActions';
import TableComponent from '../../../../SharedModules/Table/Table';

const headCellsMultiRecord = [
  { id: 'dataElementName', numeric: false, disablePadding: true, label: 'Data Element Name', enableHyperLink: true, width: '33%' },
  { id: 'businessName', numeric: false, disablePadding: false, label: 'Business Name', enableHyperLink: false, width: '33%' },
  { id: 'dataSize', numeric: false, disablePadding: false, label: 'Data Size', enableHyperLink: false, width: '33%', isDataSize: true }
];
const headCellsSingleRecord = [
  { id: 'domainName', numeric: false, disablePadding: true, label: 'Data Element Name', enableHyperLink: true, width: '33%' },
  { id: 'dataBusinessName', numeric: false, disablePadding: false, label: 'Business Name', enableHyperLink: false, width: '33%' },
  { id: 'dataSize', numeric: false, disablePadding: false, label: 'Data Size', enableHyperLink: false, width: '33%', isDataSize: true }
];

function ValidValueSearchTableComponent (props) {
  const dispatch = useDispatch();
  const [showTable, setShowTable] = React.useState(false);

  useEffect(() => {
    if (props.tableData && props.tableData.length > 0) {
      setShowTable(true);
    }
  }, [props.tableData]);
  const [redirect, setRedirect] = React.useState(0);
  const onSearchView = searchviewvalues => dispatch(validValueViewAction(searchviewvalues));
  const payloadViewData = useSelector(state => state.appConfigState.validValueState.validValueView);

  const { values } = props;
  const payloadDataTable = props.tableData;

  if (redirect === 1) {
    if (payloadDataTable.length === 1) {
      props.history.push({
        pathname: '/ValidvalueView',
        state: { payloadDataTable, values, payloadViewData }
      });
    }
  }

  const formatSearchCriteria = (rowData) => {
    const searchCriteria = {
      code: rowData.code !== '' && rowData.code !== undefined ? rowData.code : null,
      businessName: rowData.businessName !== '' ? rowData.businessName : null,
      dataElementName: rowData.dataElementName !== '' ? rowData.dataElementName : null,
      dataEleNameStartsOrContains: rowData.dataEleNameStartsOrContains === 'StartsWith' || rowData.dataEleNameStartsOrContains !== undefined ? 0 : rowData.dataEleNameStartsOrContains === 'Contains' ? 1 : null,
      busNameStartsOrContains: rowData.busNameStartsOrContains === 'StartsWith' || rowData.busNameStartsOrContains !== undefined ? 0 : rowData.busNameStartsOrContains === 'Contains' ? 1 : null,
      valueShortDescription: rowData.valueShortDescription !== '' && rowData.valueShortDescription !== undefined ? rowData.valueShortDescription : null,
      crossReferenceColumnName: rowData.crossReferenceColumnName !== '' && rowData.crossReferenceColumnName !== undefined ? rowData.crossReferenceColumnName : null,
      crossReferenceTableName: rowData.crossReferenceTableName !== '' && rowData.crossReferenceTableName !== undefined ? rowData.crossReferenceTableName : null
    };
    return searchCriteria;
  };
  const editRow = row => event => {
    const searchCriteria = formatSearchCriteria(row);
    onSearchView(searchCriteria);

    setRedirect(1);
  };

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const functionalArea =
            props.dropdowns &&
            props.dropdowns['Reference#R_FUNC_AREA_CD'] &&
            props.dropdowns['Reference#R_FUNC_AREA_CD'].filter(
              value => value.code === each.functionalArea
            );
          each.functionalAreaDesc =
          functionalArea && functionalArea.length > 0
              ? functionalArea[0].description
              : each.functionalArea ? each.functionalArea : '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };
  const headCells = props.tableData && props.tableData.length === 1 ? headCellsSingleRecord : headCellsMultiRecord;
  const tableComp = <TableComponent fixedTable print={props.print} pathTo='/ValidvalueView?data=' formatSearchCriteria={formatSearchCriteria} headCells={headCells} functionalArea={props.functionalArea}
    tableData={getTableData(props.tableData ? props.tableData : [])} onTableRowClick={editRow}
     defaultSortColumn={headCells[0].id} isSearch={true} />;

  return (
    showTable ? tableComp : null

  );
}
export default withRouter(ValidValueSearchTableComponent);
