/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './ARAdjustActionTypes';
import * as serviceEndPoint from '../../../../../SharedModules/services/service';
import axios from 'axios';
import { commonAPIHandler } from '../../../../../SharedModules/ServerErrorHandler';

const headers = {
  'Access-Control-Allow-Origin': '*'
};
export const saveARAdjust = (value, file) => {
  const formInfo = new FormData();
  if (file && file.length > 0) {
    file.map((values) => {
      formInfo.append('file', values);
    })
  }
  formInfo.append('accountsReceivableVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndPoint.AR_ADJUST_ENDPOINT}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      }).then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setSaveResults([]));
        } else {
          dispatch(setSaveResults(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setSaveResults(sendingResponse));
      });
  };
};
export const setSaveResults = (data) => {
  return {
    type: actionTypes.AR_ADJUST,
    saveResults: data
  };
};
export const setInternalError = (data) => {
  return {
    type: actionTypes.SYSTEM_ERROR,
    adjustError: data
  };
};
