/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const PAYMENT_CRITERIA_SEARCH = 'PAYMENT_CRITERIA_SEARCH';
export const PAYMENT_CRITERIA_ROW_DETAILS = 'PAYMENT_CRITERIA_ROW_DETAILS';
export const RESETDATA ='RESETDATA';
export const RESET_RESPONSE ='RESET_RESPONSE';
export const SAVE_UPDATE_DATA = 'SAVE_UPDATE_DATA';
export const FIND_PAYEE = 'FIND_PAYEE';
export const DELETE_PAYEE = 'DELETE_PAYEE';
export const AUDIT_LOG_TABLE = "AUDIT_LOG_TABLE";
export const AUDIT_LOG_TABLE_ROW = "AUDIT_LOG_TABLE_ROW";