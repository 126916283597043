import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as AppConstants from '../AppConstants';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button } from 'react-bootstrap';
import { userLogout, dispatchSessionExpiry,dispatchSessionExpand, stoptUsrInactivityTimeOut } from "../store/Actions/SharedAction";
import * as commonServiceEndPoint from '../services/service';

let origTitle = document.title, animatedTitle, timer, timeout, currentState = false, link=document.querySelector("link[rel~='icon']");   
export default function SessionExpire() {     
    const showSessionExpiryDialog = useSelector((state) => state.sharedState.showSessionExpiryDialog);
    const sessionExtendStatus = useSelector((state) => state.sharedState.sessionExtendStatus);
    const userInOutStatus = useSelector((state) => state.sharedState.userInOutStatus);
    const dispatch = useDispatch();    
    const [prompt, setPrompt] = React.useState(false);
    const animateTitle = (newTitle) => {
        animatedTitle = newTitle;
        timer && clearInterval(timer);
        timer = setInterval(startAnimation, eval(commonServiceEndPoint.TITLE_CHANGE_TIME));        
        link.href = '/public/favicon-notify.ico';
    };
    const startAnimation = () => {
        document.title = currentState ? origTitle : animatedTitle;
        currentState = !currentState;
    };
    const startUsrInactivityTimeOut = () => {  
        inactivityTimeOutID && clearTimeout(inactivityTimeOutID);
        inactivityTimeOutID = setTimeout(() => {    
          dispatch(dispatchSessionExpiry(true));
        },eval(commonServiceEndPoint.USER_INACTIVITY_TIME)) 
        
      };
    const restoreTitle = () => {
        dispatch(dispatchSessionExpand(false));  
        dispatch(dispatchSessionExpiry(false));   
        clearInterval(timer);
        clearTimeout(timeout);
        document.title = origTitle;        
        link.href = '/public/favicon.ico';
        setPrompt(false);
    };
    const timedOut = () => {
        restoreTitle();
        dispatch(userLogout(true));
    };
    const onConfirm = () => {  
        dispatch(dispatchSessionExpand(true));                   
    };
    const onCancel = () => {
        timedOut();
    };
    let inactivityTimeOutID = null;
 
    useEffect(() => {
        if (showSessionExpiryDialog) {
            setPrompt(true); 
            animateTitle(AppConstants.ANIMATE_TITLE);            
            timeout = setTimeout(() => {
                setPrompt(false);
                timedOut();           
            }, eval(commonServiceEndPoint.USER_INACTIVITY_TIME));                                 
        }
    }, [showSessionExpiryDialog]);
    useEffect(() => {
        if (sessionExtendStatus) {            
            restoreTitle();                                 
        }
    }, [sessionExtendStatus]);
    useEffect(() => {
        if (userInOutStatus == 'IN') {
            stoptUsrInactivityTimeOut();
        }else if(userInOutStatus === 'OUT'){
            startUsrInactivityTimeOut();
        }
    }, [userInOutStatus]);

    return ( 
        <Dialog 
            open={prompt}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="custom-alert-box"
        > 
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {AppConstants.SESSION_EXPIRY_MSG}
                </DialogContentText>
            </DialogContent>
            {<DialogActions>
                <Button onClick={() => {onConfirm();}} color="primary" className="btn btn-success" autoFocus>
                    Yes
                </Button>
                <Button onClick={() => { setPrompt(false); onCancel(); }} color="primary">
                    No
                </Button>
            </DialogActions>}
        </Dialog>
    );
};
