/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const BUSINESS_NAME_REQUIRED = 'Business Name is required.';
export const DATAELEMENT_NAME_REQUIRED = 'Data Element Name is required.';
export const DESCRIPTION_REQUIRED = 'Description is required.';
export const BUSINESS_NAME_MAXLENGTH_50 = 'Business Name Should MAX 50 Characters.';
export const DESCRIPTION_MAXLENGTH_320 = 'Description Max Length is 320 Characters.';
export const LISTTYPE_REQUIRED = 'List Type is required.';
export const BEGINVALUE_REQUIRED = 'Begin Value is required.';
export const ENDVALUE_REQUIRED = 'End Value is required.';
export const FUNCTIONALAREA_REQUIRED = 'Functional Area is required.';
export const LISTNUMBER_REQUIRED = 'List Number is required.';
export const F = 'List Number is required';
export const NO_RECORDS_FOUND = 'No records found for the search criteria entered.';
export const ERROR_OCCURED_DURING_TRANSACTION = 'There was an error processing the request. Please retry the transaction.';
export const DESCRIPTION_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const LIST_NUMBER_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const BUSINESS_NAME_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const BUSINESS_NAME_REQUIRED_ERROR = 'Business Name is required.';
export const LIST_TYPE_REQUIRED = 'List Type is required.';
export const BEGIN_DATE_REQUIRED = 'Begin Date is required.';
export const BEGIN_DATE_INVALID = 'Begin Date that was entered is invalid.';
export const END_DATE_REQUIRED = 'End Date is required.';
export const END_DATE_INVALID = 'End Date that was entered is invalid.';
export const BEGIN_DATE_LESS = 'The begin date must be less than or equal to the end date.';
export const DUPLICATE_SYSTEM_LIST = 'This System List and Functional Area combination already exists in the system. Duplicate System List and Functional Area combinations are not allowed in the system.';
export const UPDATED_SUCCESSFULLY = 'System Successfully saved the information.';
export const SAVED_SUCCESSFULLY = 'System successfully saved the information.';
export const SOMETHING_WENT_WRONG = 'Something Went Wrong. Please Try Again.';
export const ERR_PROCESSING_REQ = 'There was an error processing the request. Please retry the transaction.';
export const OVERLAPPING_ERROR = 'Overlapping System List detail rows for a LOB are not allowed.';
export const DETAIL_ROW_REQUIRED = 'At least one detail row is required.  Please enter at least one detail row.';
export const REVENUE_CODE_TEXT_BEGINVALUE = 'The Begin Value was not entered in the correct format. Revenue Code must be four characters, all numeric.';
export const REVENUE_CODE_TEXT_ENDVALUE = 'The End Value was not entered in the correct format. Revenue Code must be four characters, all numeric.';
export const BEGINVALUE_INVALID_TEXT = 'Begin Value must be less than or equal to End Value.';
export const DIAG_BEGINVALUE_ERROR = 'Invalid Diagnosis code for system list.';
export const DIAG_BEGINVALUE_INVALID_ERROR = 'A maximum of 5 characters It must be at least 2 characters.';
export const INVALID_SURG_PROC_CODE = 'Invalid Surgical Procedure code.';
