/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from "react";
import TableComponent from "../../../../SharedModules/Table/Table";

const headCells = [
  {
    id: "raNumber",
    disablePadding: false,
    label: "RA Number",
    width: "20%",
    previewPDF: true
  },
  {
    id: "warrantAmount",
    disablePadding: false,
    label: "Paid Amount",
    width: "20%",
    isBalance: true
  },
  {
    id: "paymentDate",
    disablePadding: false,
    label: "Payment Date",
    width: "20%",
    isDate: true
  },
  {
    id: "warrantNumber",
    disablePadding: false,
    label: "Check/EFT Number",
    width: "20%"
  },
  {
    id: "warrStatusCd",
    disablePadding: false,
    label: "Check Status",
    width: "20%"
  },
  {
    id: "pmntClrdDate",
    disablePadding: false,
    label: "Payment Cleared Date",
    width: "20%",
    isDate: true
  }
];

const getTableData = data => {
  if (data && data.length) {
    let tData = JSON.stringify(data);
    tData = JSON.parse(tData);
    tData.map((each, index) => {
      each.raNumber = each.raNumber;
      each.warrantAmount = each.warrantAmount;
      each.paymentDate = each.paymentDate;
      each.warrantNumber = each.warrantNumber;
      each.warrStatusCd =
        each.warrStatusCd && each.description
          ? each.warrStatusCd + " " + "-" + " " + each.description
          : null;
      each.pmntClrdDate = each.pmntClrdDate;
    });
    return tData;
  } else {
    return [];
  }
};

const onLinkClick = row => event => {
  window.open("fcn", "_blank");
};

function ClaimsHistory(props) {
  return (
    <TableComponent
      headCells={headCells}
      tableData={getTableData(props.tableData)}
      align={"left"}
      isSearch={true}
      onTableRowClick={props.onRowClick}
      onLinkClick={onLinkClick}
      setErrorMessages={props.setErrorMessages}
    />
  );
}

export default ClaimsHistory;
