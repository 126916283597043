/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import TableComponent from "../../../../SharedModules/Table/Table";

const headCells = [
  {
    id: "beginDate",
    numeric: false,
    disablePadding: true,
    label: "Begin Date",
    isDate: true
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: false,
    label: "End Date",
    isDate: true
  },
  {
    id: "federalPercentage",
    numeric: false,
    disablePadding: false,
    label: "Federal",
    isPercent: true
  },
  {
    id: "statePercentage",
    numeric: false,
    disablePadding: false,
    label: "State",
    isPercent: true
  },
  {
    id: "countyPercentage",
    numeric: false,
    disablePadding: false,
    label: "County",
    isPercent: true
  },
  {
    id: "otherPercentage",
    numeric: false,
    disablePadding: false,
    label: "Other",
    isPercent: true
  }
];

function BudgetFundingSourceTable(props) {
  return (
    <TableComponent
      print={props.print}
      headCells={headCells}
      tableData={props.tableData || []}
      onTableRowClick={props.onRowClick}
      onTableRowDelete={props.rowDeleteFSource}
      defaultSortColumn={"beginDate"}
      isSearch={false}
    />
  );
}
export default BudgetFundingSourceTable;
