/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import TextField from "@material-ui/core/TextField";
import Bootstrap, { Button, Form } from "react-bootstrap";
import * as ValidValueConstants from "./ValidValueConstants";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import TableComponent from "../../../../SharedModules/Table/Table";
import AuditTableComponent from "../../../../SharedModules/AuditLog/AuditTableComponent";
import {
  DialogTitle,
  DialogContent,
  DialogActions
} from "../../../../SharedModules/Dialog/DialogUtilities";
import { useSelector } from "react-redux";
import { getUTCTimeStamp } from "../../../../SharedModules/DateUtilities/DateUtilities";
import Radio from "@material-ui/core/Radio";
const headCells = [
  {
    id: "code",
    numeric: false,
    disablePadding: true,
    label: "Code",
    width: "14%",
    enableHyperLink: true,
    isVarChar: true
  },
  {
    id: "shortDescription",
    numeric: false,
    disablePadding: false,
    label: "Short Description",
    width: "15%"
  },
  {
    id: "longDescription",
    numeric: false,
    disablePadding: false,
    label: "Long Description",
    width: "30%"
  },
  {
    id: "constantText",
    numeric: false,
    disablePadding: false,
    label: "Constant Text",
    width: "25%"
  },
  {
    id: "voidDate",
    numeric: false,
    disablePadding: false,
    label: "Void Date",
    isDate: true,
    width: "15%"
  }
];

export default function AssociatedValueTableComponent(props) {
  const {
    showLogDialogTable,
    auditRowData,
    setAuditTableData,
    setShowLogDialogTable,
    setAuditLogRow,
    setFocusCheck,
    setAllowNavigation,
    initialFocusCheck,
	openEditForm,
	setOpenEditForm,
	setOpenAddForm,
  } = props;
  const logInUserID = useSelector(state => state.sharedState?.logInUserID);
  const [rowData, setRowData] = React.useState(0);
  const validvaluecons = ValidValueConstants;
  // Total Characters
  const [totalCharacters] = React.useState(100);

  let errorMessagesArray = [];
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [
    {
      showCodeError,
      showConstanttError,
      showShortDescriptionError,
      showLongDescriptionError,
      showDateOverlappingError,
    },
    setShowError
  ] = React.useState(false);
  const [
    {
      showCodeErrorText,
      showConstanttErrorText,
      showShortDescriptionErrorText,
      showLongDescriptionErrorText
    },
    setShowErrorText
  ] = React.useState("");
  const [associatedTableData1, setassociatedTableData] = React.useState(
    props.tableData
  );
  const userInquiryPrivileges = !global.globalIsReadOnly();
  const [currentRow, setCurrentRow] = React.useState({
    id: "",
    code: "",
    shortDescription: "",
    longDescription: "",
    voidDate: null,
    constantText: "",
    associatedselectedOption: "No"
  });
  React.useEffect(() => {}, [currentRow]);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = row => event => {
    setAuditLogRow(row);
    if (props.values.sourceTable === "R_VV_TB") {
      setRowData(row);
	  setOpenEditForm(true);
	  setOpenAddForm(false);
      setDataElement({
        id: row.id,
        code: row.code,
        shortDescription: row.shortDescription,
        longDescription: row.longDescription,
        voidDate: row.voidDate,
        constantText: row.constantText,
        associatedselectedOption: row.voidDate != null ? "Yes" : "No"
      });
      setCurrentRow({ ...row });
    }
    seterrorMessages([]);
    setShowErrorText({
      showCodeErrorText: "",
      showConstanttErrorText: "",
      showShortDescriptionErrorText: "",
      showLongDescriptionErrorText: ""
    });
    setShowError({
      showCodeError: false,
      showConstanttError: false,
      showShortDescriptionError: false,
      showLongDescriptionError: false,
      showDateOverlappingError: false
    });
  };

  const handleReset = row => {
    setFocusCheck(initialFocusCheck)
    setAllowNavigation(initialFocusCheck)
    errorMessagesArray = [];
    seterrorMessages([]);
    setShowErrorText({
      showCodeErrorText: "",
      showConstanttErrorText: "",
      showShortDescriptionErrorText: "",
      showLongDescriptionErrorText: ""
    });
    setShowError({
      showCodeError: false,
      showConstanttError: false,
      showShortDescriptionError: false,
      showLongDescriptionError: false,
      showDateOverlappingError: false
    });
    setDataElement({
      code: row.code ? row.code : "",
      shortDescription: row.shortDescription,
      longDescription: row.longDescription,
      voidDate: row.voidDate,
      associatedselectedOption: row.voidDate != null ? "Yes" : "No"
    });
  };
  const handleClose = () => {
    setFocusCheck(initialFocusCheck)
    setAllowNavigation(initialFocusCheck)
    setAuditLogRow(null);
    setOpenEditForm(false);
  };

  const handleOpen = row => {
    setDataElement({
      id: row.id,
      code: row.code,
      shortDescription: row.shortDescription,
      longDescription: row.longDescription,
      voidDate: row.voidDate
    });
	setOpenEditForm(true);
	setOpenAddForm(false);
  };

  const [dataElement, setDataElement] = React.useState({
    code: "",
    shortDescription: "",
    longDescription: "",
    voidDate: "",
    constantText: "",
    associatedselectedOption: "No",
    addedAuditTimeStamp: "",
    addedAuditUserID: "",
    auditKeyListFiltered: "",
    auditTimeStamp: "",
    auditUserID: "",
    dbRecord: "",
    showVoidRecord: "",
    showVoids: "",
    sortColumn: "",
    valueStatus: "",
    versionNo: "",
    voidIndicator: ""
  });

  React.useEffect(() => {
    const newRecord = props.newRecord;
  }, [props.newRecord]);

  const handleEditAssociated = () => {
    errorMessagesArray = [];
    seterrorMessages([]);
    let count = 0;
    var showCodeError;
    var showConstanttError;
    var showShortDescriptionError;
    var showLongDescriptionError;
    var showDateOverlappingError = false;
    var showCodeErrorText;
    var showConstanttErrorText;
    var showShortDescriptionErrorText;
    var showLongDescriptionErrorText = "";

    if (!dataElement.code.trim()) {
      showCodeError = true;
      errorMessagesArray.push(validvaluecons.CODE_DATASIZE_REQUIRED);
      showCodeErrorText = validvaluecons.CODE_DATASIZE_REQUIRED;
      seterrorMessages(errorMessagesArray);
      setOpenEditForm(true);
	  setOpenAddForm(false);
    }
    if (
      dataElement.code.trim() &&
      dataElement.code.trim().length > props.values.dataSize
    ) {
      showCodeError = true;
      errorMessagesArray.push(validvaluecons.CODE_DATASIZE_ERROR);
      showCodeErrorText = validvaluecons.CODE_DATASIZE_ERROR;
      seterrorMessages(errorMessagesArray);
	  setOpenEditForm(true);
	  setOpenAddForm(false);
    }
    const alphaNumericCheck = /^[a-z0-9]+$/i;
    if (dataElement.code && !alphaNumericCheck.test(dataElement.code)) {
      showCodeError = true;
      showCodeErrorText = validvaluecons.CODE_INVALID;
      errorMessagesArray.push(showCodeErrorText);
      seterrorMessages(errorMessagesArray);
	  setOpenEditForm(true);
	  setOpenAddForm(false);
    }
    const associatedTableData = props.tableData;
    if (dataElement.code.trim() && associatedTableData.length > 0) {
      associatedTableData.map((value, index) => {
        if (
          value.voidDate === null &&
          (value.constantText === dataElement.constantText ||
            value.code === dataElement.code) &&
          currentRow.tempID &&
          value.tempID !== currentRow.tempID
        ) {
          count = count + 1;
          return false;
        } else if (
          value.voidDate === null &&
          (value.constantText === dataElement.constantText ||
            value.code === dataElement.code) &&
          currentRow.id &&
          value.id !== currentRow.id
        ) {
          count = count + 1;
          return false;
        }
      });
      if (count > 0) {
        showCodeError = true;
        errorMessagesArray.push(validvaluecons.VALID_VALUE_CODE_OVERLAPPING);
        showCodeErrorText = validvaluecons.VALID_VALUE_CODE_OVERLAPPING;
        seterrorMessages(errorMessagesArray);
        setOpenEditForm(true);
	  setOpenAddForm(false);
      }
    }
    if (!dataElement.shortDescription) {
      showShortDescriptionError = true;
      errorMessagesArray.push(validvaluecons.SHORT_DESCRIPTION_REQUIRED);
      showShortDescriptionErrorText = validvaluecons.SHORT_DESCRIPTION_REQUIRED;
      seterrorMessages(errorMessagesArray);
      setOpenEditForm(true);
	  setOpenAddForm(false);
    }
    if (!dataElement.longDescription) {
      showLongDescriptionError = true;
      errorMessagesArray.push(validvaluecons.LONG_DESCRIPTION_REQUIRED);
      showLongDescriptionErrorText = validvaluecons.LONG_DESCRIPTION_REQUIRED;
      seterrorMessages(errorMessagesArray);
      setOpenEditForm(true);
	  setOpenAddForm(false);
    }
    const regex = /^^[a-zA-Z0-9 _/\-',.]*$/;
    if (!regex.test(dataElement.longDescription)) {
      showLongDescriptionError = true;
      showLongDescriptionErrorText = validvaluecons.LONG_DES_INVALID;
      seterrorMessages(errorMessagesArray);
      setOpenEditForm(true);
	  setOpenAddForm(false);
    }

    if (!regex.test(dataElement.shortDescription)) {
      showShortDescriptionError = true;
      showShortDescriptionErrorText = validvaluecons.SHORT_DES_INVALID;
      seterrorMessages(errorMessagesArray);
	  setOpenEditForm(true);
	  setOpenAddForm(false);
    } else if (errorMessagesArray.length === 0) {
      const dataElementdata = {
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: currentRow
          ? currentRow.addedAuditUserID
            ? currentRow.addedAuditUserID
            : logInUserID
          : logInUserID,
        addedAuditTimeStamp: currentRow
          ? currentRow.addedAuditTimeStamp
            ? currentRow.addedAuditTimeStamp
            : getUTCTimeStamp()
          : getUTCTimeStamp(),

        code: dataElement.code,
        shortDescription: dataElement.shortDescription,
        longDescription: dataElement.longDescription,
        voidDate: currentRow.voidDate,
        constantText: currentRow.constantText,
        auditKeyList: [],
        auditKeyListFiltered: currentRow.auditKeyListFiltered,
        dbRecord: currentRow.dbRecord,
        showVoidRecord: currentRow.showVoidRecord,
        showVoids: dataElement.associatedselectedOption === "Yes",
        sortColumn: currentRow.sortColumn,
        valueStatus: currentRow.valueStatus,
        versionNo: currentRow.versionNo,
        voidIndicator: currentRow.voidIndicator,
        validValueSk: currentRow.validValueSk,
        _edit_audit: true
      };
      setDataElement(dataElementdata);
      for (var i in associatedTableData) {
        if (
          associatedTableData[i].constantText === dataElementdata.constantText && !associatedTableData[i].voidDate
        ) {
          if (associatedTableData[i].id) {
            dataElementdata.id = associatedTableData[i].id;
          }
          associatedTableData[i] = dataElementdata;
          if (dataElementdata.showVoids) {
            associatedTableData[i].voidDate = moment(new Date()).format(
              "MM/DD/YYYY hh:mm:ss"
            );
          }
          break; // Stop this loop, we found it!
        }
      }

      props.updateTableData(associatedTableData);
	  setOpenEditForm(false);
    }
    setShowError({
      showCodeError: showCodeError,
      showConstanttError: showConstanttError,
      showShortDescriptionError: showShortDescriptionError,
      showLongDescriptionError: showLongDescriptionError,
      showDateOverlappingError: showDateOverlappingError
    });

    setShowErrorText({
      showCodeErrorText: showCodeErrorText,
      showConstanttErrorText: showConstanttErrorText,
      showShortDescriptionErrorText: showShortDescriptionErrorText,
      showLongDescriptionErrorText: showLongDescriptionErrorText
    });
  };
  const handleChangeDataElement = name => event => {
    setFocusCheck(true)
    setAllowNavigation(true)
    if (name === "code") {
      setDataElement({
        ...dataElement,
        [name]: event.target.value.toUpperCase()
      });
    } else {
      setDataElement({ ...dataElement, [name]: event.target.value });
    }
  };

  return (
    <div>
      {props.showVoid ? (
        <TableComponent
          print={props.print}
          fixedTable
          headCells={headCells}
          tableData={props.tableData ? props.tableData : []}
          onTableRowClick={handleClickOpen}
          defaultSortColumn={headCells[0].id}
          isTrueSort={true}
          isSearch={false}
          onTableRowDelete={props.rowDeleteAssociatedValidValue}
          // Audit Log Changes
          onTableCellButtonClick={props.onClickAuditDialogLog}
          uniqueKey="constantText"
          setClose={props.setClose}
          close={props.close}
        />
      ) : (
        <TableComponent
          print={props.print}
          fixedTable
          headCells={headCells}
          tableData={props.filteredData ? props.filteredData : []}
          onTableRowClick={handleClickOpen}
          defaultSortColumn={headCells[0].id}
          isSearch={false}
          isTrueSort={true}
          onTableRowDelete={props.rowDeleteAssociatedValidValue}
          // Audit Log Changes
          onTableCellButtonClick={props.onClickAuditDialogLog}
          uniqueKey="constantText"
          setClose={props.setClose}
          close={props.close}
        />
      )}
      {openEditForm ? (
        <div className="tabs-container" id="form_pop_up">
          <div className="tab-header  mini-tab-header">
            <div className="tab-heading float-left">
              Edit Associated Valid Values
            </div>
            <div className="float-right mt-1">
              <Button
                variant="outlined"
                color="primary"
                className="btn btn-success ml-1"
                onClick={() => handleEditAssociated(dataElement, rowData)}
                disabled={
                  !userInquiryPrivileges
                    ? !userInquiryPrivileges
                    : currentRow.voidDate != null
                }
              >
                 <i class="fa fa-check" aria-hidden="true"></i>
                Update
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className="bt-reset btn-transparent ml-1"
                onClick={() => handleReset(rowData)}
                disabled={
                  !userInquiryPrivileges
                    ? !userInquiryPrivileges
                    : currentRow.voidDate != null
                }
              >
                <i class="fa fa-undo" aria-hidden="true"></i>
                Reset
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className="btn btn-primary btn btn-outlined ml-1"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
          <div className="form-border my-3">
            <form autoComplete="off">
              <div className="form-wrapper">
                <div className="mui-custom-form input-md override-width-20">
                  <TextField
                    id="standard-code"
                    fullWidth
                    label="Code"
                    inputProps={{ maxLength: 15 }}
                    required
                    value={dataElement.code}
                    helperText={showCodeError ? showCodeErrorText : null}
                    error={showCodeError ? showCodeErrorText : null}
                    onChange={handleChangeDataElement("code")}
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={
                      !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : currentRow.voidDate != null
                    }
                  />
                </div>
                <div className="mui-custom-form input-md override-width-20">
                  <TextField
                    disabled={currentRow.voidDate != null}
                    id="standard-constantText"
                    fullWidth
                    label="Constant Text"
                    disabled={true}
                    value={dataElement.constantText}
                    inputProps={{ maxLength: 50 }}
                    // error={!dataElement.constantText}
                    onChange={handleChangeDataElement("constantText")}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>
                <div className="mui-custom-form input-md override-width-20">
                  <TextField
                    disabled={
                      !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : currentRow.voidDate != null
                    }
                    id="standard-shortDescription"
                    fullWidth
                    label="Short Description"
                    inputProps={{ maxLength: 10 }}
                    value={dataElement.shortDescription}
                    helperText={
                      showShortDescriptionError
                        ? showShortDescriptionErrorText
                        : null
                    }
                    error={
                      showShortDescriptionError
                        ? showShortDescriptionErrorText
                        : null
                    }
                    required
                    onChange={handleChangeDataElement("shortDescription")}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>
                {currentRow.isNewRow !== undefined &&
                currentRow.isNewRow ? null : (
                  <div className="mui-custom-form">
                    <label className="MuiFormLabel-root small-label">
                      Void
                    </label>
                    <div className="sub-radio">
                      <Radio
                        disabled={
                          !userInquiryPrivileges
                            ? !userInquiryPrivileges
                            : currentRow.voidDate != null
                        }
                        name="associatedselectedOption"
                        value="Yes"
                        id="associatedselectedOptionId"
                        checked={dataElement.associatedselectedOption === "Yes"}
                        onChange={handleChangeDataElement(
                          "associatedselectedOption"
                        )}
                      />
                      <label
                        className="text-black"
                        for="associatedselectedOptionId"
                      >
                        Yes
                      </label>
                      <Radio
                        disabled={
                          !userInquiryPrivileges
                            ? !userInquiryPrivileges
                            : currentRow.voidDate != null
                        }
                        name="associatedselectedOption"
                        value="No"
                        id="associatedselectedNoId"
                        checked={dataElement.associatedselectedOption === "No"}
                        onChange={handleChangeDataElement(
                          "associatedselectedOption"
                        )}
                        className="ml-2"
                      />
                      <label
                        className="text-black"
                        for="associatedselectedNoId"
                      >
                        No
                      </label>
                    </div>
                  </div>
                )}
              </div>
          <div className="form-wrapper">
          <div className="mui-custom-form with-textarea override-width-95">
                  <Form.Group>
                    <Form.Label className="MuiFormLabel-root small-label">
                      Long Description{" "}
                      <span class="Mui-required" id="text-label-01">
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      aria-describedby="text-label-01"
                      aria-label="form-controll-01"
                      as="textarea"
                      rows="4"
                      name="text"
                      disabled={
                        !userInquiryPrivileges
                          ? !userInquiryPrivileges
                          : currentRow.voidDate != null
                      }
                      value={dataElement.longDescription}
                      onChange={handleChangeDataElement('longDescription')}
                      InputProps={{ readOnly: !userInquiryPrivileges, className: !userInquiryPrivileges ? 'Mui-disabled' : '' }}
                      inputProps={{ maxLength: 100 }}
                      maxLength={totalCharacters}
                      style={{ width: "100%" }}
                    />
                      <p
                        class="MuiFormHelperText-root Mui-error Mui-required"
                        role="alert"
                      >
                        {
                 showLongDescriptionError
                 ? showLongDescriptionErrorText
                 : null
                }{' '}
                      </p>
                  </Form.Group>
                  <div
                    style={{
                      border: "1px solid rgb(169, 169, 169)",
                      borderRadius: ".25rem",
                      paddingLeft: "5px",
                      width: "300px",
                      alignText: "center"
                    }}
                  >
                    Characters Remaining: {100-dataElement.longDescription.length}
                  </div>
                </div>
              </div>
            </form>
            {showLogDialogTable ? (
              <div id="audit-dialog-table">
                <AuditTableComponent
                  print
                  auditRowData={auditRowData}
                  setAuditRowData={setAuditTableData}
                  setShowLogTable={setShowLogDialogTable}
                />{" "}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}
