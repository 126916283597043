/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const GENERIC_SYSTEM_ERROR =
  "There was an error processing the request. Please retry the transaction.";
export const DESCRIPTION_IS_REQUIRED = "Description is required.";
export const WORKUNIT_IS_REQUIRED = "Work Unit Level is required.";
export const DOC_TYPE_IS_REQUIRED = "Document Type is required.";
export const FILE_IS_REQUIRED = "File is required.";
export const ONLY_ONE_FILE_SELECT = "Only 1 file can be uploaded at a time.";
export const MAX_ONE_ATTACHMENT_ALLOWED = "Only one attachment is allowed.";
export const INVALID_FILE_TYPE =
  "The files you are attempting to attach are an invalid file type. Please contact Technical Support for information on valid file types.";
export const ZERO_MB_FILE =
  "The file uploaded is empty/zero bytes. The attachment will not be saved.";
export const MAX_FILE_SIZE_EXCEEDED =
  "The file(s) you are attempting to attach is/are larger than the maximum allowed size of 20 mb. Please try again by reducing the file size or by uploading the files separately.";
export const ERROR_IN_DOWNLOADING_FILE =
  "Error while downloading the file. Please contact Technical Support";
export const INVALID_FOR_TXN =
  "The files you are attempting to attach is an invalid file type.";
export const ZERO_MB_FOR_TXN = "The file uploaded is empty/zero bytes.";
export const INVALID_TYPE_TXN =
  "The files you are attempting to attach is an invalid file type.";
export const FILE_NOT_FOUND =
  "The file you are trying to access is not available.";
