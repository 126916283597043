/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const SEARCH_SYSTEM_PARAMETER = 'SEARCH_SYSTEM_PARAMETER';
export const SEARCH_SYSTEM_PARAMETER9047 = 'SEARCH_SYSTEM_PARAMETER9047';
export const ROW_SEARCH_SYSTEM_PARAMETER = 'ROW_SEARCH_SYSTEM_PARAMETER';
export const UPDATE_SYSTEM_PARAMETER = 'UPDATE_SYSTEM_PARAMETER';
export const ADD_SYSTEM_PARAMETER = 'ADD_SYSTEM_PARAMETER';
export const RESETDATA = 'RESETDATA';
export const FUNCTIONAL_AREA_DROPDOWN = 'FUNCTIONAL_AREA_DROPDOWN';
export const SYSTEM_ERROR = 'SYSTEM_ERROR';
export const AUDIT_LOG_TABLE = "AUDIT_LOG_TABLE";
