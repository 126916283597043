/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/PaymentStatusInquiryActionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.PAYMENT_STATUS_INQUIRY_SEARCH:
      return { searchPaymentData: action.searchData };
    case actionTypes.SEARCH_RESULTS_RESET:
      return action.resetData;
    case actionTypes.AUDIT_LOG_TABLE:
      return { ...state, auditData: action.payload };
    default: return state;
  }
};

export default reducer;
