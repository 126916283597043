/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableComponent from "../../../../SharedModules/Table/Table";

const headCells = [
  {
    id: "columnName",
    numeric: false,
    disablePadding: true,
    label: "Column Name",
    width: "20%"
  },
  {
    id: "columnBusinessName",
    numeric: false,
    disablePadding: false,
    label: "Column Business Name",
    width: "30%"
  },
  {
    id: "tableName",
    numeric: false,
    disablePadding: false,
    label: "Table Name",
    width: "30%"
  },
  {
    id: "tableBusinessName",
    numeric: false,
    disablePadding: false,
    label: "Table Business Name",
    width: "20%"
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
  },
  tableWrapper: {
    overflowX: "auto"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

export default function CrossReferenceTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("parameterNumber");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const tableComp = (
    <TableComponent
      print={props.print}
      fixedTable
      headCells={headCells}
      isSearch={true}
      tableData={props.tableData ? props.tableData : []}
      defaultSortColumn={"dataElementName"}
    />
  );
  return tableComp;
}
