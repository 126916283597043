/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const DESCRIPTION_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const PARAMETERNUMBER_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const NO_RECORD_FOUND = 'No records found for the search criteria entered.';
export const SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
