/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './AppConfigActionTypes';
import * as serviceEndPoint from '../../../services/service';
import axios from 'axios';
import { commonAPIHandler } from '../../../../../SharedModules/ServerErrorHandler/index';

export const getAuditLogDetailsAction = (tableName, keyValue, auditData) => {
  let body = {
    "tableName": tableName,
    "keyValue": keyValue
  }
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}

export const setAuditLogData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE,
    payload: data,
    keyName
  };
};

export const setFocus = (val) => {
  return ({
    type: actionTypes.FOCUS_CHECK,
    setFocus: val
  });
};

export const AppConfigDropdownActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.APP_CONFIG_DROPDOWN}`, value)
      .then(response => {
        dispatch(AppConfigDropdown(response.data));
      })
      .catch(error => {
        dispatch(AppConfigDropdown(error));
      });
  };
};
export const AppConfigDropdown = (appConfigDropdown) => {
  return ({
    type: actionTypes.APP_CONFIG_DROPDOWNS,
    appConfigDropdown: appConfigDropdown
  });
};

export const DataElementDropdownActions = () => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.DATA_ELEMENT_DROPDOWN}`)
      .then(response => {
        dispatch(DataElementDropdown(response.data));
      })
      .catch(error => {
        dispatch(DataElementDropdown(error));
      });
  };
};
export const DataElementDropdown = (dataElementNameDropdown) => {
  return ({
    type: actionTypes.DATA_ELEMENT_NAME_DROPDOWNS,
    dataElementNameDropdown: dataElementNameDropdown
  });
};

export const DataElementMapDropdownActions = () => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.DATA_ELEMENT_MAP_DROPDOWN}`)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(DataElementMapDropdown(sendingResponse));
      })
      .catch(error => {
        let sendingResonse = commonAPIHandler(error);
        dispatch(DataElementMapDropdown(sendingResonse));
      });
  };
};
export const DataElementMapDropdown = (dataElementMapDropdown) => {
  return ({
    type: actionTypes.DATA_ELEMENT_MAP_NAME_DROPDOWNS,
    dataElementMapDropdown: dataElementMapDropdown
  });
};

export const ModifierDropdownActions = () => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.MODIFIER_DROPDOWN}`)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(ModifierDropdown(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(ModifierDropdown(sendingResponse));
      });
  };
};
export const ModifierDropdown = (modifierDropdown) => {
  return ({
    type: actionTypes.MODIFIER_DROPDOWNS,
    modifierDropdown: modifierDropdown
  });
};
