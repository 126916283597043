/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const AR_ADJUST = 'AR_ADJUST';
export const SYSTEM_ERROR = 'SYSTEM_ERROR';