/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import moment from "moment";
export function desc(a, b, orderBy, varCharflag,isTrueSort) {
  if(isTrueSort){
    if (a[orderBy] !== undefined && b[orderBy] !== undefined) {
      if (!isNaN(a[orderBy]) && !isNaN(b[orderBy]) && (''+b[orderBy]).length<17 && (''+a[orderBy]).length<17) {
        if((b[orderBy]=="" || b[orderBy]==null)  && a[orderBy]==0 ) {
          return -1;
        }
        else if ((a[orderBy]=="" || a[orderBy]==null)  && b[orderBy]==0 ) {
          return 1;
        }
        else if((a[orderBy]=="" || a[orderBy]==null) && (b[orderBy]=="" || b[orderBy]==null) ){
          return 0;
        }
        if (Number(b[orderBy]) < Number(a[orderBy])) {
          return -1;
        }
        else if (Number(b[orderBy]) > Number(a[orderBy])) {
          return 1;
        }
  
        
        return 0;
      } 
      else if (!isNaN(Date.parse(a[orderBy])) && !isNaN(Date.parse(b[orderBy])) && moment(b[orderBy], "MM/DD/YYYY HH:mm:ss", true).isValid() && moment(a[orderBy], "MM/DD/YYYY HH:mm:ss", true).isValid()) {
        if (Date.parse(b[orderBy]) < Date.parse(a[orderBy])) {
          return -1;
        }
        if (Date.parse(b[orderBy]) > Date.parse(a[orderBy])) {
          return 1;
        }
        return 0;
      } else if (!isNaN(Date.parse(a[orderBy])) && !isNaN(Date.parse(b[orderBy])) && moment(b[orderBy], "MM/DD/YYYY", true).isValid() && moment(a[orderBy], "MM/DD/YYYY", true).isValid()) {
        if (Date.parse(b[orderBy]) < Date.parse(a[orderBy])) {
          return -1;
        }
        if (Date.parse(b[orderBy]) > Date.parse(a[orderBy])) {
          return 1;
        }
        return 0;
      } 
      /*else if (!isNaN(Date.parse(a[orderBy])) && !isNaN(Date.parse(b[orderBy]))) {
        if (Date.parse(b[orderBy]) < Date.parse(a[orderBy])) {
          return -1;
        }
        if (Date.parse(b[orderBy]) > Date.parse(a[orderBy])) {
          return 1;
        }
        return 0;
  
  }*/
      else {       
        if (a[orderBy] !== null && b[orderBy] !== null) {
          return typeof a[orderBy] == "string" && typeof b[orderBy] == "string" && a[orderBy].toLowerCase().localeCompare(b[orderBy].toLowerCase())==1?-1:typeof a[orderBy] == "string" && typeof b[orderBy] == "string" && a[orderBy].toLowerCase().localeCompare(b[orderBy].toLowerCase())==0?0:1;
        } else if (a[orderBy] === null) {
          return 1;
        } else {
          return -1;
        }
      }
    
    }
  }
 else if (varCharflag === true) {
    if (a[orderBy] !== undefined && b[orderBy] !== undefined) {
      if (a[orderBy] !== null && a[orderBy] !== '' && b[orderBy] !== null && b[orderBy] !== '') {
        if (b[orderBy].toString().toLowerCase().trim() < a[orderBy].toString().toLowerCase().trim()) {
          return -1;
        }
        if (b[orderBy].toString().toLowerCase().trim() > a[orderBy].toString().toLowerCase().trim()) {
          return 1;
        }
        if (b[orderBy].toString().toLowerCase().trim()[0] == a[orderBy].toString().toLowerCase().trim()[0]) {
          if (b[orderBy].toString().trim() < a[orderBy].toString().trim()) {
            return -1;
          }
          if (b[orderBy].toString().trim() > a[orderBy].toString().trim()) {
            return 1;
          }
        }
        return 0;
      } else if ((a[orderBy] === null || a[orderBy] === '') && (b[orderBy] !== null && b[orderBy] !== '')) {
        return 1;
      } else if (a[orderBy] === null && b[orderBy] === null) {
        return 0;
      } else {
        return -1;
      }
    }
  } else {
    if (a[orderBy] !== undefined && b[orderBy] !== undefined) {
      if (typeof a[orderBy] === 'number' && typeof b[orderBy] === 'number') {
        if (Number(b[orderBy]) < Number(a[orderBy])) {
          return -1;
        }
        if (Number(b[orderBy]) > Number(a[orderBy])) {
          return 1;
        }
        return 0;
      } else if (a[orderBy] instanceof Date && b[orderBy] instanceof Date) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      } else if (!isNaN(Date.parse(a[orderBy])) && !isNaN(Date.parse(b[orderBy])) && moment(b[orderBy], "MM/DD/YYYY HH:mm:ss", true).isValid() && moment(a[orderBy], "MM/DD/YYYY HH:mm:ss", true).isValid()) {
        if (Date.parse(b[orderBy]) < Date.parse(a[orderBy])) {
          return -1;
        }
        if (Date.parse(b[orderBy]) > Date.parse(a[orderBy])) {
          return 1;
        }
        return 0;
      } else if (!isNaN(Date.parse(a[orderBy])) && !isNaN(Date.parse(b[orderBy])) && moment(b[orderBy], "MM/DD/YYYY", true).isValid() && moment(a[orderBy], "MM/DD/YYYY", true).isValid()) {
        if (Date.parse(b[orderBy]) < Date.parse(a[orderBy])) {
          return -1;
        }
        if (Date.parse(b[orderBy]) > Date.parse(a[orderBy])) {
          return 1;
        }
        return 0;
      } else if (!isNaN(Date.parse(a[orderBy])) && !isNaN(Date.parse(b[orderBy]))) {
        if (Date.parse(b[orderBy]) < Date.parse(a[orderBy])) {
          return -1;
        }
        if (Date.parse(b[orderBy]) > Date.parse(a[orderBy])) {
          return 1;
        }
        return 0;
      } else if (typeof a[orderBy] === 'boolean' && typeof b[orderBy] === 'boolean') {
        return (a[orderBy] === b[orderBy]) ? 0 : a[orderBy] ? -1 : 1;
      } else {
        if (a[orderBy] !== null && a[orderBy] !== '' && b[orderBy] !== null && b[orderBy] !== '') {
          if ((typeof parseFloat(b[orderBy].toString().replace(',', '')) === 'number') && (typeof parseFloat(a[orderBy].toString().replace(',', '')) === 'number') && !isNaN(parseFloat(a[orderBy].toString().replace(',', ''))) && !isNaN(parseFloat(b[orderBy].toString().replace(',', '')))) {
            if (parseFloat(b[orderBy].toString().replace(',', '')) < parseFloat(a[orderBy].toString().replace(',', ''))) {
              return -1;
            }
            if (parseFloat(b[orderBy].toString().replace(',', '')) > parseFloat(a[orderBy].toString().replace(',', ''))) {
              return 1;
            }
            return 0;
          } else {
            if (b[orderBy].toString().toLowerCase().replace(/<\/?[^>]+(>|$)/g, '').substring(0, 35).trim() < a[orderBy].toString().toLowerCase().replace(/<\/?[^>]+(>|$)/g, '').substring(0, 35).trim()) {
              return -1;
            }
            if (b[orderBy].toString().toLowerCase().replace(/<\/?[^>]+(>|$)/g, '').substring(0, 35).trim() > a[orderBy].toString().toLowerCase().replace(/<\/?[^>]+(>|$)/g, '').substring(0, 35).trim()) {
              return 1;
            }
            if (b[orderBy].toString().toLowerCase().replace(/<\/?[^>]+(>|$)/g, '').substring(0, 35).trim()[0] === a[orderBy].toString().toLowerCase().replace(/<\/?[^>]+(>|$)/g, '').substring(0, 35).trim()[0]) {
              if (b[orderBy].toString().replace(/<\/?[^>]+(>|$)/g, '').substring(0, 35).trim() < a[orderBy].toString().replace(/<\/?[^>]+(>|$)/g, '').substring(0, 35).trim()) {
                return -1;
              }
              if (b[orderBy].toString().replace(/<\/?[^>]+(>|$)/g, '').substring(0, 35).trim() > a[orderBy].toString().replace(/<\/?[^>]+(>|$)/g, '').substring(0, 35).trim()) {
                return 1;
              }
            }
            return 0;
          }
        } else if ((a[orderBy] === null || a[orderBy] === '') && (b[orderBy] !== null && b[orderBy] !== '')) {
          return 1;
        } else if (a[orderBy] === null && b[orderBy] === null) {
          return 0;
        } else {
          return -1;
        }
      }
    }
  }
}

export function stableSort(array, cmp) {
  const stabilizedThis = Array.isArray(array) && array.map((el, index) => [el, index]);
  stabilizedThis && stabilizedThis.sort((firstElement, secondElement) => {
    const order = cmp(firstElement[0], secondElement[0]);
    if (order !== 0) return order;
    return firstElement[1] - secondElement[1];
  });
  return Array.isArray(stabilizedThis) && stabilizedThis.map(el => el[0]);
}

export function getSorting(order, orderBy, varCharflag,isTrueSort, sortColumns, multiColumnSortingEnabled) {
    // Added for sorting by considering all sortColumns. 
    if (multiColumnSortingEnabled) {
      const sortingFields = sortColumns.split(",").map((field) => field.trim());
      return (firstElm, secondElm) => {
        for (const field of sortingFields) {
          const result = desc(firstElm, secondElm, field);
          if (result !== 0) {
            return order === "desc" ? result : -result;
          }
        }
        // If all fields are equal, compare original indexes for stable sorting
        return firstElm.index - secondElm.index;
      };
    }
  return order === 'desc' ? (firstElm, secondElm) => desc(firstElm, secondElm, orderBy, varCharflag,isTrueSort)
   : (firstElme, secondElme) => -desc(firstElme, secondElme, orderBy, varCharflag,isTrueSort);
}
