import React from 'react';
import { NativeSelect, InputLabel, FormHelperText } from '@material-ui/core';
import { PLEASE_SELECT_ONE } from '../AppConstants';

const NativeDropDown = props => {
    const { id, testId, inputProps, value, isRequired, onChange, onBlur, name, label, dropdownList, errTxt, disabled = false, showOnlyListItem = false, className = "", autoFocus = false } = props;
    return (
        <div className={`mui-custom-form-select with-select input-md custom-input-select pos-rel ${className}`}>
            <InputLabel className={label ? "" : "mute-lbl"} htmlFor={id}>
                {label ? label : "Dummy Label"}
                {isRequired ? <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">*</span> : ''}
            </InputLabel>
            <NativeSelect
                data-testid={testId}
                id={id}
                disabled={disabled}
                inputProps={inputProps}
                value={value}
                name={name}
                autoFocus={autoFocus}
                InputLabelProps={{
                    shrink: true,
                    required: { isRequired },
                    name: 'name',
                    id: { id },
                }}
                onChange={onChange}
                onBlur={onBlur}
            >
                {!showOnlyListItem && <option selected key={process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE} value={process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE}>{PLEASE_SELECT_ONE}</option>}
                {dropdownList}
            </NativeSelect>
            <FormHelperText className="required-label">{errTxt}</FormHelperText>
        </div>
    )
}

export default NativeDropDown;