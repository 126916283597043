/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const MAP_SET_ACTION_TYPE = 'MAP_SET_ACTION_TYPE';
export const MAP_SET_VIEW_ACTION_TYPE = 'MAP_SET_VIEW_ACTION_TYPE';
export const MAP_DEFINITION_ADD_TYPE = 'MAP_DEFINITION_ADD_TYPE';
export const MAP_DEFINITION_UPDATE_TYPE = 'MAP_DEFINITION_UPDATE_TYPE';
export const SEARCH_SYSTEM_LIST = 'SEARCH_SYSTEM_LIST';
export const RESET_ADD_DATA = 'RESET_ADD_DATA';
export const RESET_PAYLOAD_DATA = 'RESET_PAYLOAD_DATA';
export const RESETDATA = 'RESETDATA';
export const AUDIT_LOG_TABLE = "AUDIT_LOG_TABLE";
export const AUDIT_LOG_TABLE_ROW = "AUDIT_LOG_TABLE_ROW";

