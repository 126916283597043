/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const FISCAL_PEND_ADD_ACTION = 'FISCAL_PEND_ADD_ACTION';
export const FISCAL_PEND_UPDATE_ACTION = 'FISCAL_PEND_UPDATE_ACTION';
export const FISCAL_PEND_ID_ACTION = 'FISCAL_PEND_ID_ACTION';
export const FISCAL_PEND_RESET = 'FISCAL_PEND_RESET';
export const GET_FISCAL_PEND_RECORD = 'GET_FISCAL_PEND_RECORD';
export const FISCAL_PEND_DELETE = 'FISCAL_PEND_DELETE';
export const FISCAL_PEND_ID_RESET = 'FISCAL_PEND_ID_RESET';