/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import ErrorMessages from "../Errors/ErrorMessages";
import InputLabel from "@material-ui/core/InputLabel";
export default function AddAttachment(props) {
  const {
    selectedFile,
    preview,
    attachmentRowEditFlag,
    addAttachmentDetails,
    handleChangeAttachment,
    showAttachmentErr,
    attachmentErrMsg,
    saveAttachment,
    resetAttachment,
    onChangeChooseFile,
    errorMessages,
    userInquiryPrivileges,
    inputRef,
    downloaFileClick,
    addedFileDownload
  } = props;
  return (
    <div className={"pos-relative"}>
      <ErrorMessages errorMessages={errorMessages} />
      <div className="form-border my-3">
        <form autoComplete="off">
          {!attachmentRowEditFlag ? (
            <div className="form-wrapper">
              <input
                id="file_upload"
                type="file"
                name="file"
                onChange={onChangeChooseFile}
                ref={inputRef}
                required
                style={{ display: "none" }}
              />
              <div
                style={{
                  border: "none",
                  paddingLeft: "0px",
                  margin: "12px 2.5%"
                }}
              >
                <InputLabel
                  htmlFor="file"
                  className="MuiInputLabel-shrink Mui-error Mui-required"
                  style={{
                    fontSize: "1.28em",
                    color: "#274463"
                  }}
                >
                  File<span style={{ color: "#a81f00" }}>&nbsp;*</span>
                </InputLabel>
                <div style={{ display: "flex" }}>
                  <label for="file_upload">
                    <a class="btn btn-primary">Choose File</a>
                  </label>
                  <span style={{ margin: "5px" }}>
                    {selectedFile && selectedFile.name
                      ? selectedFile.name
                      : "No file choosen"}
                  </span>
                </div>
                <p class="MuiFormHelperText-root MuiFormHelperText-filled danger-text">
                  {showAttachmentErr.showFileNameErr
                    ? attachmentErrMsg.fileNameErrMsg
                    : null}
                </p>
              </div>
            </div>
          ) : (
            <div className="form-wrapper">
              <div className="mui-custom-form input-md override-width-20">
                <TextField
                  id="dateadded"
                  fullWidth
                  label="Date Added"
                  value={addAttachmentDetails.addedAuditTimeStamp}
                  InputProps={{ readOnly: true, className: "Mui-disabled" }}
                  InputLabelProps={{
                    shrink: true
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form input-md override-width-20">
                <TextField
                  fullWidth
                  id="addedBy"
                  label="Added By"
                  value={addAttachmentDetails.addedBy}
                  InputProps={{ readOnly: true, className: "Mui-disabled" }}
                  InputLabelProps={{
                    shrink: true
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form has-link input-md override-width-42">
                {addAttachmentDetails.isAddedRow && preview ? (
                  <a
                    onClick={() =>
                      addedFileDownload(addAttachmentDetails.fileName, preview)
                    }
                  >
                    <TextField
                      id="fileName"
                      fullWidth
                      label="File Name"
                      value={addAttachmentDetails.fileName}
                      InputProps={{ readOnly: true, className: "Mui-disabled" }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    ></TextField>
                  </a>
                ) : (
                  <a onClick={downloaFileClick}>
                    <TextField
                      id="fileName"
                      fullWidth
                      label="File Name"
                      value={addAttachmentDetails.fileName}
                      InputProps={{ readOnly: true, className: "Mui-disabled" }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    ></TextField>
                  </a>
                )}
              </div>
            </div>
          )}

          <div className="form-wrapper">
            <div
              className="mui-custom-form with-textarea override-width-45"
              style={{ marginTop: "-5px" }}
            >
              <TextField
                id="description"
                label="Description"
                fullWidth
                required
                InputProps={{
                  readOnly: !userInquiryPrivileges
                    ? !userInquiryPrivileges
                    : !addAttachmentDetails.isAddedRow,
                  className: (!userInquiryPrivileges
                  ? !userInquiryPrivileges
                  : !addAttachmentDetails.isAddedRow)
                    ? "Mui-disabled"
                    : null
                }}
                inputProps={{ maxlength: 35 }}
                value={addAttachmentDetails.description}
                onChange={handleChangeAttachment("description")}
                InputLabelProps={{
                  shrink: true
                }}
                helperText={
                  showAttachmentErr.showDescriptionErr
                    ? attachmentErrMsg.descriptionErrMsg
                    : null
                }
                error={
                  showAttachmentErr.showDescriptionErr
                    ? attachmentErrMsg.descriptionErrMsg
                    : null
                }
              ></TextField>
            </div>
            {!attachmentRowEditFlag ? (
              <div className="form-wrapper"></div>
            ) : (
              <div className="mui-custom-form input-md ">
                <InputLabel htmlFor="attached" className="MuiInputLabel-shrink">
                  Attached
                </InputLabel>
                {addAttachmentDetails.isAddedRow ? (
                  preview ? (
                    <span style={{ paddingLeft: "13px" }}>Yes</span>
                  ) : (
                    <span style={{ paddingLeft: "13px" }}>No</span>
                  )
                ) : (
                  <div className="hide-on-print">
                    <span style={{ paddingLeft: "13px" }}>Yes</span>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="clearfix"></div>
        </form>
      </div>
    </div>
  );
}
