import React,{useEffect} from 'react';
import { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import * as ErrorConst from "../../../../SharedModules/Errors/ErrorMsgConstant";
import InputAdornment from '@material-ui/core/InputAdornment';
import dateFnsFormat from 'date-fns/format';

const beginDateHelperText = props => {
    return props.errors.beginDateErr ?
    ErrorConst.Begin_Date_Error :
    props.errors.beginDateInvalidErr ?
    ErrorConst.Invalid_Begin_Date_Error : null
}
const beginDateErrorText = props => {
    return props.errors.beginDateErr ?
    ErrorConst.Begin_Date_Error :
    props.errors.beginDateInvalidErr?
    ErrorConst.Invalid_Begin_Date_Error : null
}
const endDateHelperText = props =>{
    return props.errors.endDateErr ?
    ErrorConst.End_Date_Error:props.errors.endDateInvalidErr?ErrorConst.Invalid_End_Date_Error :
     props.errors.beginDtGtEndDtErr?ErrorConst.EVENT_GT_DT_ERROR:null
}
const endDateErrorText = props =>{
    return props.errors.endDateErr ?
    ErrorConst.End_Date_Error:props.errors.endDateInvalidErr?ErrorConst.Invalid_End_Date_Error :
     props.errors.beginDtGtEndDtErr?ErrorConst.EVENT_GT_DT_ERROR:null
}
const userLastNameHelperText = props =>{
    return props.errors.userIdNameErr ?ErrorConst.USER_ID_NAME_ERR  : null
}
const userLastNameErrorText = props =>{
    return props.errors.userIdNameErr ?ErrorConst.USER_ID_NAME_ERR  : null
}
function InquirySearchForm(props) {
    const [, setSelectedToDate] = React.useState('');
    const [selectedFromDate, setSelectedFromDate] = React.useState('');
    const handleBeginDateChange = date => {
        props.handleDCDtChange('beginDate', formatDate(date));
};
const handleEndDateChange = date => {
    props.handleDCDtChange('endDate', formatDate(date));
};
const formatDate = dt => {
    if (!dt) {
      return "";
    }
    dt = new Date(dt)
    if (dt.toString() == 'Invalid Date') {
      return dt
    } else {
      return dateFnsFormat(dt, 'MM/dd/yyyy')
    }
  }
  useEffect(() => {
    props.inquiryValues.beginDate !== '' ? setSelectedFromDate(props.inquiryValues.beginDate) : setSelectedFromDate('');
}, []);

useEffect(() => {
     props.inquiryValues.endDate !== '' ? setSelectedToDate(props.inquiryValues.endDate) : setSelectedToDate('');
}, []);
    return (
        <div>
            <form autoComplete="off">
                <div className="tab-body  mt-4">
                    <div className="form-wrapper p-0">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div className="mui-custom-form input-md with-select">
                                <KeyboardDatePicker
                                    id="begin_date_inq"
                                    label="Begin Date"
                                    format="MM/dd/yyyy"
                                    InputLabelProps={{shrink: true,required: true}}
                                    placeholder="mm/dd/yyyy"
                                    value={props.inquiryValues.beginDate?props.inquiryValues.beginDate:null}
                                    onChange={handleBeginDateChange}
                                    helperText={beginDateHelperText(props)}
                                    error={beginDateErrorText(props)}
                                    KeyboardButtonProps={{"aria-label": ErrorConst.CHANGE_DATE}}
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div className="mui-custom-form input-md with-select">
                                <KeyboardDatePicker
                                    id="end_date_inq"
                                    label="End Date"
                                    format="MM/dd/yyyy"
                                    InputLabelProps={{shrink: true,required: true}}
                                    placeholder="mm/dd/yyyy"
                                    value={props.inquiryValues.endDate?props.inquiryValues.endDate:null}
                                    onChange={handleEndDateChange}
                                    helperText={endDateHelperText(props)}
                                    error={endDateErrorText(props)}
                                    KeyboardButtonProps={{"aria-label": ErrorConst.CHANGE_DATE}}
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                            <div className="mui-custom-form input-md with-select">
                                <TextField
                                    id="begin_time_inq"
                                    label="Begin Time"
                                    format="HH:MM:SS"
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{ maxLength: 12 }}
                                    value={props.inquiryValues.beginTime}
                                    onChange={props.handleChanges('beginTime')}
                                    KeyboardButtonProps={{"aria-label": ErrorConst.CHANGE_DATE}}/>
                                <div className="mt-2 ml-2 txt-time-format">HH:MM:SS PM</div>
                            </div>
                            <div className="mui-custom-form input-md with-select">
                                <TextField
                                    id="end_time_inq"
                                    label="End Time"
                                    format="HH:MM:SS"
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{ maxLength: 12 }}
                                    value={props.inquiryValues.endTime}
                                    onChange={props.handleChanges('endTime')}
                                    KeyboardButtonProps={{"aria-label": ErrorConst.CHANGE_DATE}}/>
                                <div className="mt-2 ml-2 txt-time-format">HH:MM:SS PM</div>
                            </div>
                        <div className="mui-custom-form with-select input-md">
                            <TextField
                                id="userIDInq"
                                label="User ID"
                                value={props.inquiryValues.userID}
                                inputProps={{ maxLength: 30 }}
                                onChange={props.handleChanges('userID')}
                                placeholder=""
                                InputLabelProps={{shrink: true}}
                            />
                        </div>
                        <div className="mui-custom-form with-select input-md">
                            <TextField
                                id="userLastNameInq"
                                label="User Last Name"
                                value={props.inquiryValues.userLastName}
                                inputProps={{ maxLength:35 }}
                                onChange={props.handleChanges('userLastName')}
                                InputLabelProps={{shrink: true}}
                                placeholder=""
                                helperText={userLastNameHelperText(props)}
                                error={userLastNameErrorText(props)}
                                errorT

                            />
                        </div>
                        <div className="mui-custom-form with-select input-md">
                            <TextField
                                id="portletName"
                                label="Portlet Name"
                                value={props.inquiryValues.portletName}
                                inputProps={{ maxLength: 99 }}
                                onChange={props.handleChanges('portletName')}
                                InputLabelProps={{shrink: true}}
                            />
                        </div>
                    </div>
                    <div className="form-wrapper">
                        <div className="mui-custom-form with-select input-md">
                            <TextField
                                className=""
                                id="keyType1"
                                label="Key Type"
                                value={props.inquiryValues.keyType1}
                                inputProps={{ maxLength: 30 }}
                                onChange={props.handleChanges('keyType1')}
                                InputLabelProps={{shrink: true}}
                                InputProps={{startAdornment: <InputAdornment position="start">1</InputAdornment>,
                                    maxLength: 30}}
                            />
                        </div>
                        <div className="mui-custom-form with-select input-md">
                            <TextField
                                className=""
                                id="keyValue1"
                                label="Key"
                                value={props.inquiryValues.keyValue1}
                                inputProps={{ maxLength: 50 }}
                                onChange={props.handleChanges('keyValue1')}
                                InputLabelProps={{shrink: true}}
                            />
                        </div>
                        <div class="line-break-div"></div>
                        <div className="mui-custom-form with-select input-md">
                        <label className="visuallyhidden" for="keyType2">key Type 2</label>
                            <TextField
                                className="no-mt"
                                id="keyType2"
                                inputProps={{ maxLength: 30 }}
                                value={props.inquiryValues.keyType2}
                                onChange={props.handleChanges('keyType2')}
                                InputLabelProps={{shrink: true}}
                                InputProps={{startAdornment: <InputAdornment position="start">2</InputAdornment>,
                                    maxLength: 30}}
                            />
                        </div>
                        <div className="mui-custom-form with-select input-md">
                        <label className="visuallyhidden" for="keyValue2">key Value 2</label>
                            <TextField
                                className="no-mt"
                                id="keyValue2"
                                value={props.inquiryValues.keyValue2}
                                inputProps={{ maxLength: 50 }}
                                onChange={props.handleChanges('keyValue2')}
                                InputLabelProps={{shrink: true}}
                            />
                        </div>
                        <div class="line-break-div"></div>
                        <div className="mui-custom-form with-select input-md">
                        <label className="visuallyhidden" for="keyType3">key Type 3</label>
                            <TextField
                                className="no-mt"
                                id="keyType3"
                                inputProps={{ maxLength: 30 }}
                                value={props.inquiryValues.keyType3}
                                onChange={props.handleChanges('keyType3')}
                                InputLabelProps={{shrink: true}}
                                InputProps={{startAdornment: <InputAdornment position="start">3</InputAdornment>,
                                    maxLength:30}}
                            />
                        </div>
                        <div className="mui-custom-form with-select input-md">
                        <label className="visuallyhidden" for="keyValue3">key Value 3</label>
                            <TextField
                                className="no-mt"
                                id="keyValue3"
                                value={props.inquiryValues.keyValue3}
                                inputProps={{ maxLength: 50 }}
                                onChange={props.handleChanges('keyValue3')}
                                InputLabelProps={{shrink: true}}
                            />
                        </div>
                        <div class="line-break-div"></div>
                        <div className="mui-custom-form with-select input-md">
                        <label className="visuallyhidden" for="keyType4">key Type 4</label>
                            <TextField
                                className="no-mt"
                                id="keyType4"
                                inputProps={{ maxLength: 30 }}
                                value={props.inquiryValues.keyType4}
                                onChange={props.handleChanges('keyType4')}
                                InputLabelProps={{shrink: true}}
                                InputProps={{startAdornment: <InputAdornment position="start">4</InputAdornment>,
                                    maxLength: 30}}
                            />
                        </div>
                        <div className="mui-custom-form with-select input-md">
                        <label className="visuallyhidden" for="keyValue4">key Value 4</label>
                            <TextField
                                className="no-mt"
                                id="keyValue4"
                                value={props.inquiryValues.keyValue4}
                                inputProps={{ maxLength: 50 }}
                                onChange={props.handleChanges('keyValue4')}
                                InputLabelProps={{shrink: true}}
                            />
                        </div>
                        <div class="line-break-div"></div>
                        <div className="mui-custom-form with-select input-md">
                        <label className="visuallyhidden" for="keyType5">key Type 5</label>
                            <TextField
                                className="no-mt"
                                id="keyType5"
                                inputProps={{ maxLength: 30 }}
                                value={props.inquiryValues.keyType5}
                                onChange={props.handleChanges('keyType5')}
                                InputLabelProps={{shrink: true}}
                                InputProps={{startAdornment: <InputAdornment position="start">5</InputAdornment>,
                                    maxLength: 30}}
                            />
                        </div>
                        <div className="mui-custom-form with-select input-md">
                        <label className="visuallyhidden" for="keyValue5">key Value 5</label>
                            <TextField
                                className="no-mt"
                                id="keyValue5"
                                value={props.inquiryValues.keyValue5}
                                inputProps={{ maxLength: 50 }}
                                onChange={props.handleChanges('keyValue5')}
                                InputLabelProps={{shrink: true}}
                            />
                        </div>
                    </div>
                    </div>
                    <div className="tab-footer">
                    <div className="float-right th-btnGroup mr-2 mb-2">
                        <Button
                            title="Search"
                            variant="outlined"
                            color="primary"
                            className="btn btn-ic btn-search"
                            onClick={props.inquirySearchCheck}
                            disabled={props.privileges && !props.privileges.search ? "disabled" : ""}
                        >
                            Search{" "}
                        </Button>
                        <Button
                            title="Reset"
                            variant="outlined"
                            color="primary"
                            className="btn btn-ic btn-reset"
                            onClick={props.resetTable}
                        >
                            Reset{" "}
                        </Button>
                    </div>
                </div>
                <div className="clearfix" />
            </form>
        </div>
    );
}
export default InquirySearchForm;
