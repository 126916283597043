/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import { systemParameterRowClickAction } from '../../Store/Actions/systemParameter/systemParameterActions';
import TableComponent from '../../../../SharedModules/Table/Table';

const headCellsMultiRecord = [
  { id: 'parameterNumber', numeric: false, enableHyperLink: true, disablePadding: true, label: 'Parameter Number', width: 180 },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description', width: 350, isVarChar: true },
  { id: 'functionalAreaDesc', numeric: false, disablePadding: false, label: 'Functional Area', width: 260 }
];
const headCellsSingleRecord = [
  { id: 'parameterNumber', numeric: false, enableHyperLink: true, disablePadding: true, label: 'Parameter Number', width: 180 },
  { id: 'parameterName', numeric: false, disablePadding: false, label: 'Description', width: 350, isVarChar: true },
  { id: 'functionalArea', numeric: false, disablePadding: false, label: 'Functional Area', width: 260 }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

function SystemParameterTable (props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [redirect, setRedirect] = React.useState(0);

  const onRowClick = values => dispatch(systemParameterRowClickAction(values));
  const payloadData = useSelector(state => state.appConfigState.systemParameterState.rowSearchSysParam);
  const { values } = props;
  if (redirect === 1) {
    if (payloadData) {
      props.history.push({
        pathname: '/SystemParametersEdit',
        state: { payloadData, values }
      });
    }
  }

  useEffect(() => {
    if (payloadData != null) {

    }
  }, [payloadData]);

  const editRow = row => event => {
    onRowClick(row);

    const valuetoredirect = redirect + 1;
    setRedirect(valuetoredirect);
  };

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const functionalArea =
            props.dropdowns &&
            props.dropdowns['Reference#R_FUNC_AREA_CD'] &&
            props.dropdowns['Reference#R_FUNC_AREA_CD'].filter(
              value => value.code === each.functionalArea
            );
          each.functionalAreaDesc =
          functionalArea && functionalArea.length > 0
              ? functionalArea[0].description
              : each.functionalArea ? each.functionalArea : '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  const headCells = props.tableData && props.tableData.length === 1 ? headCellsSingleRecord : headCellsMultiRecord;
  return (
    <div className={classes.root}>
      {
        props.tableData.length !== 0
          ? <TableComponent print={props.print} pathTo='/SystemParametersEdit?data=' headCells={headCells} isSearch={true} tableData={getTableData(props.tableData ? props.tableData : [])} onTableRowClick={editRow} defaultSortColumn={headCells[0].id} />
          : null}
    </div>
  );
}
export default withRouter((SystemParameterTable));
