import React, { useState, useEffect, useRef } from 'react';
import TableComponent from '../../../SharedModules/Table/Table';
import { useDispatch, useSelector } from "react-redux";
import { searchSingleRecordFinancialEntity } from '../../FinancialEntities/Store/Actions/FinancialEntityActions';
import { useHistory } from 'react-router';

const headCellsMultiRecord = [
  {
    id: "commonEntityTypeCodeDesc",
    label: "Payee Type",
    enableHyperLink: false,
    isDate: false,
    width: "14%",
    fontSize: 12,
    disablePadding: false,
  },
  {
    id: "entityIDTypeCodeDesc",
    label: "Payee ID Type Code",
    enableHyperLink: false,
    isDate: false,
    width: "16%",
    fontSize: 12,
    disablePadding: false,
  },
  {
    id: "entityID",
    label: "Payee ID",
    isDate: false,
    fontSize: 12,
    isVarChar: true,
    width: "14%",
    disablePadding: false,
    enableHyperLink: false,   
  },
  {
    id: "sortName",
    label: "Payee Name",
    enableHyperLink: false,
    isDate: false,
    width: "14%",
    fontSize: 12,
    disablePadding: false,
  },
  {
    id: "financialDetailRef",
    label: "Receipt FCN",
    enableHyperLink: true,
    isDate: false,
    width: "14%",
    fontSize: 12,
    disablePadding: false,
  },
  {
    id: "dispositionReasonCode",
    label: "Receipt Reason Code",
    enableHyperLink: false,
    isDate: false,
    width: "16%",
    fontSize: 12,
    disablePadding: false,
  },
  {
    id: "raNumber",
    label: "RA Number",
    enableHyperLink: false,
    isDate: false,
    width: "14%",
    fontSize: 12,
    disablePadding: false,
  }
];
const headCellsSingleRecord = [
  {
    id: "commonEntityTypeCodeDesc",
    label: "Payee Type",
    enableHyperLink: false,
    isDate: false,
    width: "14%",
    fontSize: 12,
    disablePadding: false,
  },
  {
    id: "entityIDTypeCodeDesc",
    label: "Payee ID Type Code",
    enableHyperLink: false,
    isDate: false,
    width: "16%",
    fontSize: 12,
    disablePadding: false,
  },
  {
    id: "entityID",
    label: "Payee ID",
    enableHyperLink: false,
    isDate: false,
    fontSize: 12,
    isVarChar: true,
    width: "14%",
    disablePadding: false,
  },
  {
    id: "sortName",
    label: "Payee Name",
    enableHyperLink: false,
    isDate: false,
    width: "14%",
    fontSize: 12,
    disablePadding: false,
  },
  {
    id: "financialDetailRef",
    label: "Receipt FCN",
    enableHyperLink: true,
    isDate: false,
    width: "14%",
    fontSize: 12,
    disablePadding: false,
  },
  {
    id: "dispositionReasonCode",
    label: "Receipt Reason Code",
    enableHyperLink: false,
    isDate: false,
    width: "16%",
    fontSize: 12,
    disablePadding: false,
  },
  {
    id: "raNumber",
    label: "RA Number",
    enableHyperLink: false,
    isDate: false,
    width: "14%",
    fontSize: 12,
    disablePadding: false,
  }
];


function PaymentStatusInquiryTable(props) {
  const headCells = props.tableData.length === 1 ? headCellsSingleRecord : headCellsMultiRecord;

  useEffect(() => {
    props.OnReset();
  }, []);

  const getTableData = (d) => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;

          const commanEntyPayTypeCode =
            props.dropdowns &&
            props.dropdowns["G1#G_CMN_ENTY_TY_CD"] &&
            props.dropdowns["G1#G_CMN_ENTY_TY_CD"].filter(
              (value) => value.code === each.commonEntityTypeCode
            );
          each.commonEntityTypeCodeDesc =
            commanEntyPayTypeCode && commanEntyPayTypeCode.length > 0
                ? commanEntyPayTypeCode[0].description
                : each.commonEntityTypeCode
                ? each.commonEntityTypeCode
                : "";

          const payTypeCode =
            props.dropdowns &&
            props.dropdowns["G1#G_CMN_ENTY_TY_CD"] &&
            props.dropdowns["G1#G_CMN_ENTY_TY_CD"].filter(
              (value) => value.code === each.entityType
            );
          each.entTypeDesc =
            payTypeCode && payTypeCode.length > 0
              ? payTypeCode[0].description
              : each.entityType
              ? each.entityType
              : "";
      
          const reasonVal =
              props.dropdowns &&
              props.dropdowns['F1#F_RSN_CD'] &&
              props.dropdowns['F1#F_RSN_CD'].filter(
                value => value.code == each.dispositionReasonCode
              );

          each.dispositionReasonCode = reasonVal && reasonVal.length > 0
            ? reasonVal[0].description
            : each.dispositionReasonCode ? each.dispositionReasonCode : '';

          if (each.commonEntityTypeCode === "P" || each.entityType === 'P') {
            const provEntityTypeCD =
              props.dropdowns &&
              props.dropdowns["F1#P_ALT_ID_TY_CD"] &&
              props.dropdowns["F1#P_ALT_ID_TY_CD"].filter(
                (value) => value.code === (each.entityIDTypeCode || each.entityTypeCode)
              );
            each.entityIDTypeCodeDesc =
              provEntityTypeCD && provEntityTypeCD.length > 0
                ? provEntityTypeCD[0].description
                : each.entityIDTypeCode
                ? each.entityIDTypeCode : each.entityTypeCode ? each.entityTypeCode
                : "";
            
            each.entTypCdDesc =
              provEntityTypeCD && provEntityTypeCD.length > 0
                ? provEntityTypeCD[0].description
                : each.entityIDTypeCode
                ? each.entityIDTypeCode :  each.entityTypeCode ? each.entityTypeCode
                : "";
          }else if(each.commonEntityTypeCode === "O" || each.entityType === 'O'){
            const othrEntityTypeCD =
              props.dropdowns &&
              props.dropdowns["F1#G_ENTY_EXTL_ID_TY_CD"] &&
              props.dropdowns["F1#G_ENTY_EXTL_ID_TY_CD"].filter(
                (value) => value.code === (each.entityIDTypeCode || each.entityTypeCode)
              );
            each.entityIDTypeCodeDesc =
              othrEntityTypeCD && othrEntityTypeCD.length > 0
                ? othrEntityTypeCD[0].description
                : each.entityIDTypeCode
                ? each.entityIDTypeCode : each.entityTypeCode ? each.entityTypeCode
                : "";
            
            each.entTypCdDesc =
              othrEntityTypeCD && othrEntityTypeCD.length > 0
                ? othrEntityTypeCD[0].description
                : each.entityIDTypeCode
                ? each.entityIDTypeCode : each.entityTypeCode ? each.entityTypeCode
                : "";
          }else if(each.commonEntityTypeCode === "M" || each.entityType === 'M'){
            const memberEntityTypeCD =
              props.dropdowns &&
              props.dropdowns["F1#B_ALT_ID_TY_CD"] &&
              props.dropdowns["F1#B_ALT_ID_TY_CD"].filter(
                (value) => value.code === (each.entityIDTypeCode || each.entityTypeCode)
              );
            each.entityIDTypeCodeDesc =
              memberEntityTypeCD && memberEntityTypeCD.length > 0
                ? memberEntityTypeCD[0].description
                : each.entityIDTypeCode
                ? each.entityIDTypeCode : each.entityTypeCode ? each.entityTypeCode
                : "";
            
            each.entTypCdDesc =
              memberEntityTypeCD && memberEntityTypeCD.length > 0
                ? memberEntityTypeCD[0].description
                : each.entityIDTypeCode
                ? each.entityIDTypeCode : each.entityTypeCode ? each.entityTypeCode
                : "";
          }else if(each.commonEntityTypeCode === "TC" || each.entityType === 'TC'){
            const tplEntityTypeCD =
              props.dropdowns &&
              props.dropdowns["F1#DM_G_CARR_ENTY_ID_TY_CD"] &&
              props.dropdowns["F1#DM_G_CARR_ENTY_ID_TY_CD"].filter(
                (value) => value.code === (each.entityIDTypeCode || each.entityTypeCode)
              );
            each.entityIDTypeCodeDesc =
              tplEntityTypeCD && tplEntityTypeCD.length > 0
                ? tplEntityTypeCD[0].description
                : each.entityIDTypeCode
                ? each.entityIDTypeCode : each.entityTypeCode ? each.entityTypeCode
                : "";
            
            each.entTypCdDesc =
              tplEntityTypeCD && tplEntityTypeCD.length > 0
                ? tplEntityTypeCD[0].description
                : each.entityIDTypeCode
                ? each.entityIDTypeCode : each.entityTypeCode ? each.entityTypeCode
                : "";
          }
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  const editRow = (row) => (event) => {
    props.setShowDetailView(true);
    props.setDetailData(row);    
  };
  
  
  return (
    <TableComponent
    fixedTable
    headCells={headCells}
    tableData={getTableData(props.tableData)}
    onTableRowClick={editRow}
    defaultSortColumn={'payeeType'}
    order={'asc'}
    isSearch={true}
   />
  )
}

export default PaymentStatusInquiryTable