/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import axios from 'axios';
import * as actionTypes from './FiscalPendAddActionTypes';
import * as serviceEndPoint from '../../../../SharedModules/services/service';
import { commonAPIHandler } from '../../../../SharedModules/ServerErrorHandler/index';
const headers = {
  'Access-Control-Allow-Origin': '*'
};
export const FiscalPendIDGenerator = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.FISCAL_PEND_ID}`)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse) {
          dispatch(setFiscalPendID(sendingResponse.fiscalPendDetailsVO.fiscalPendID));
        } else {
          dispatch(setFiscalPendID(''));
        };
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setResultOfFiscalPendAction(sendingResponse));
      });
  };
};
export const ficalePendIDResest = () => {
  return {
    type: actionTypes.FISCAL_PEND_ID_RESET
  };
};

export const setFiscalPendID = (data) => {
  return {
    type: actionTypes.FISCAL_PEND_ID_ACTION,
    fiscalPendID: data
  };
};

export const fiscalPendAddAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FISCAL_PEND_ADD}`, value, { headers: headers })
      .then(response => {
        if (response) {
          let sendingResponse = commonAPIHandler(response);
          sendingResponse.exc = value;
          dispatch(setResultOfFiscalPendAction(sendingResponse));
        } else {
          dispatch(setResultOfFiscalPendAction([]));
        };
      })
      .catch(error => {
        let sendingResponse  = commonAPIHandler(error);
        dispatch(setResultOfFiscalPendAction(sendingResponse));
      });
  };
};
export const fiscalPendUpdateAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FISCAL_PEND_UPDATE}`, value, { headers: headers })
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse) {
          sendingResponse.exc = value;
          dispatch(setResultOfFiscalPendUpdateAction(sendingResponse));
        } else {
          dispatch(setResultOfFiscalPendUpdateAction([]));
        };
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setResultOfFiscalPendUpdateAction(sendingResponse));
      });
  };
};

export const fiscalPendAddToEdit = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.GET_FISCAL_PEND_RECORD}` + value, { headers: headers })
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setFiscalPendRecordDetails(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setFiscalPendRecordDetails(sendingResponse));
      });
  };
};

export const fiscalPendDeleteAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FISCAL_PEND_DELETE}`, value, { headers: headers })
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse) {
          sendingResponse.exc = value;
          dispatch(setResultOfFiscalPendDeleteAction(sendingResponse));
        } 
        else {
          dispatch(setResultOfFiscalPendDeleteAction([]));
        };
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setResultOfFiscalPendDeleteAction(sendingResponse));
      });
  };
};

export const setResultOfFiscalPendDeleteAction = (deleteRecord) => {
  return {
    type: actionTypes.GET_FISCAL_PEND_RECORD,
    deleteResult: deleteRecord
  };
};

export const setFiscalPendRecordDetails = (searchRecord) => {
  return {
    type: actionTypes.GET_FISCAL_PEND_RECORD,
    searchRecord: searchRecord
  };
};

export const setResultOfFiscalPendAction = (data) => {
  return {
    type: actionTypes.FISCAL_PEND_ADD_ACTION,
    resultOfFiscalPendAdd: data
  };
};
export const setResultOfFiscalPendUpdateAction = (data) => {
  return {
    type: actionTypes.FISCAL_PEND_UPDATE_ACTION,
    resultOfFiscalPendUpdate: data
  };
};
export const resetState = () => {
  return {
    type: actionTypes.FISCAL_PEND_RESET,
    resetData: []
  };
};
