/* eslint-disable import/no-duplicates */
/* eslint-disable no-unused-vars */
/**

* � [2020] Conduent, Inc. All rights reserved. Conduent�and Conduent Agile

* Star�are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import { Route } from 'react-router-dom';
import * as RouteUrls from "./RouteConstants";
import Dashboardsearch from '../../SharedModules/Dashboard/dashboard';
import ValidvalueSearch from '../../Modules/ApplicationConfiguration/Components/ValidValue/ValidvalueSearch';
import ValidvalueView from '../../Modules/ApplicationConfiguration/Components/ValidValue/ValidvalueView';
import SystemListSearch from '../../Modules/ApplicationConfiguration/Components/SystemList/Search/SystemListSearch';
import SystemListAdd from '../../Modules/ApplicationConfiguration/Components/SystemList/Add/SystemListAdd';
import SystemParameterSearch from '../../Modules/ApplicationConfiguration/Components/SystemParameter/SystemParameter';
import SystemParameterAdd from '../../Modules/ApplicationConfiguration/Components/SystemParameter/Add/SystemParameterAdd';
import SystemParameterUpdate from '../../Modules/ApplicationConfiguration/Components/SystemParameter/SystemParameterUpdate';
import SystemListAddUpdate from '../../Modules/ApplicationConfiguration/Components/SystemList/Search/SystemListAddUpdate';
import MapSetSearch from '../../Modules/ApplicationConfiguration/Components/MapSet/MapSet';
import MapSetAdd from '../../Modules/ApplicationConfiguration/Components/MapSet/MapSetAdd';
import MapSetEdit from '../../Modules/ApplicationConfiguration/Components/MapSet/MapSetAdd';
import TextManagementSearch from '../../Modules/ApplicationConfiguration/Components/TextManagement/TextManagementSearch';
import TextUpdate from '../../Modules/ApplicationConfiguration/Components/TextManagement/TextUpdate';
import TextAddUpdate from '../../Modules/ApplicationConfiguration/Components/TextManagement/TextAddUpdate';
import BankAccountAndLockbox from '../../Modules/BankAccountAndLockbox/Components/BankAccountAndLockBox';
import BankAccountAndLockBoxAdd from '../../Modules/BankAccountAndLockbox/Components/BankAccountAndLockBoxAdd';
import FinancialBudgetSearch from '../../Modules/BudgetMaintenance/Components/FinancialBudgetSearch';
import BudgetDataUpdate from '../../Modules/BudgetMaintenance/Components/Update/BudgetDataUpdate';
import BudgetMaintenanceAdd from '../../Modules/BudgetMaintenance/Components/Add/BudgetMaintenanceAdd';
import FinancialAccountInquiry from '../../Modules/AccountReceivables/Components/ARInquiryUpdate/ARSearch/FinancialAccountInquiry';
import FCNPayOutSearchResults from '../../Modules/FinancialPayOut/components/FPSearch/FCNSearchResults';
import FinancialPayOutAdd from '../../Modules/FinancialPayOut/components/FinancialPayOutAddAndUpdate/PayoutAddComponent';
import FinancialPayOutUpdate from '../../Modules/FinancialPayOut/components/FinancialPayOutAddAndUpdate/PayoutAddComponent';
import FCNSearchResults from '../../Modules/AccountReceivables/Components/ARInquiryUpdate/ARSearch/FCNSearchResults';

import MCNSearchResults from '../../Modules/AccountReceivables/Components/ARInquiryUpdate/ARSearch/MCNSearchResults';
import PayeeSearchResults from '../../Modules/AccountReceivables/Components/ARInquiryUpdate/ARSearch/PayeeSearchResults';
import BankAccountAndLockBoxEdit from '../../Modules/BankAccountAndLockbox/Components/BankAccountAndLockBoxEdit';
import FinancialEntitySearch from '../../Modules/FinancialEntities/Components/FinancialEntitySearch';
import FinancialEntityAdd from '../../Modules/FinancialEntities/Components/FinancialEntityAdd/FinancialEntityAdd';
import FinancialAccountGrossLevel from '../../Modules/AccountReceivables/Components/ARInquiryUpdate/GrossLevelAdd/add/FinancialAccountGrossLevel';
import GrossLevelAddPayeeComponent from '../../Modules/AccountReceivables/Components/ARInquiryUpdate/GrossLevelAdd/add/GrossLevelAddPayeeComponent';
import ChangePassword from '../../Modules/Security/Components/ChangePassword';
import ResetPassword from '../../Modules/Security/Components/ResetPassword';
import AddUser from '../../Modules/Security/Components/AddUser';
import UserProfile from '../../Modules/Security/Components/UserProfile';
import SearchUser from '../../Modules/Security/Components/SearchUser';
import Login from '../../Login';
import FinancialReceiptSearch from '../../Modules/FinancialReceipt/Components/FinancialReceiptSearch';
import FinancialReceiptReturn from '../..//Modules/FinancialReceipt/Components/FinancialReceiptReturn';
import FinancialReceiptAdd from '../../Modules/FinancialReceipt/Components/ADD/FinancialReceiptAdd';
import FinancialReceiptReversal from '../../Modules/FinancialReceipt/Components/FinancialReceiptReversal';
import FundCodeAdd from '../../Modules/FundCode/Components/FundCodeAdd';
import FundCodeEdit from '../../Modules/FundCode/Components/FundCodeAdd';
import WriteOff from '../../Modules/WriteOffAndReEstablishment/Components/WriteOff';
import WriteOffReEstablishment from '../../Modules/WriteOffAndReEstablishment/Components/WriteOffReEstablishment';
import FundCodeSearch from '../../Modules/FundCode/Components/FundCodeSearch';
import FinancialEntityUpdate from '../../Modules/FinancialEntities/Components/FinancialEntityUpdate';
import FCNInquiryPayOut from '../../Modules/FinancialPayOut/components/FPSearch/FinancialPayoutInquiry';

import LienLevyAdd from '../../Modules/AccountReceivables/Components/ARInquiryUpdate/GrossLevelAdd/Lien Levy/add/FinancialAccountLienLevy';
import ClaimsReceivable from '../../Modules/AccountReceivables/Components/ClaimsReceivables/ClaimsReceivable';
import PayoutApprovalSearchForm from '../../Modules/AccountPayables/Components/PayoutApproval/PayoutApprovalSearchForm';
import FiscalPendCriteriaAdd from '../../Modules/FiscalPendCriteria/Components/FiscalPendCriteriaAdd';
import LienLevyUpdate from '../../Modules/AccountReceivables/Components/ARInquiryUpdate/GrossLevelAdd/Lien Levy/update/FinancialAccountLienLevy';
import GrossLevelUpdate from '../../Modules/AccountReceivables/Components/ARInquiryUpdate/GrossLevelAdd/update/FinancialAccountGrossLevelUpdate';

import ARTransfer from '../../Modules/AccountReceivables/Components/ARInquiryUpdate/ARTransfer/ARTransfer';
import FinancialReceiptAdjustment from '../../Modules/FinancialReceipt/Components/Adjustment/FinancialReceiptAdjustment';
import FiscalPendCriteriaSearch from '../../Modules/FiscalPendCriteria/Components/FiscalPendCriteriaSearch';
import ARReverse from '../../Modules/AccountReceivables/Components/ARInquiryUpdate/ARReverse/ARReverse';
import ARAdjust from '../../Modules/AccountReceivables/Components/ARInquiryUpdate/ARAdjustment/ARAdjust';
import FPAdjust from '../../Modules/FinancialPayOut/components/FinancialPayOutAddAndUpdate/PayoutAdjust';
import AdvancePayment from '../../Modules/AccountReceivables/Components/ARInquiryUpdate/AdvancePayment/AdvancePayment';
import CorrectionSearch from '../../Modules/1099Correction/Components/Search/CorrectionSearch';
import CorrectionDetailsView from '../../Modules/1099Correction/Components/Search/CorrectionDetailsView';
import CorrectionSearchApproval from '../../Modules/1099Correction/Components/CorrectionApprovalSearch/CorrectionApprovalSearch';

import ARBulkTransfer from '../../Modules/AccountReceivables/Components/BulkMaintenance/Transfer/BulkTransfer';
import ChangePayStatus from '../../Modules/AccountReceivables/Components/BulkMaintenance/ChangePayeeStatus/ChangePayeeStatus';

import ClaimFinancialInquirySearch from '../../Modules/FinancialInquiry/Components/FISearch/ClaimFinancialInquirySearch';
import PaymentStatusInquirySearch from '../../Modules/PaymentStatusInquiry/Components/PaymentStatusInquirySearch';
import ClaimsReceivables from '../../Modules/FinancialInquiry/Components/ClaimsReceivables/ClaimsReceivables';
import EventLoggingSearch from '../../Modules/ProjectControl/EventLogging/Components/EventLogSearch';
import GlobalAuditSearch from '../../Modules/ProjectControl/GlobalAuditSearch/Components/GlobalAuditSearch';
import GlobalAuditDetails from '../../Modules/ProjectControl/GlobalAuditSearch/Components/GlobalAuditDetails';
import ReissueUpdate from '../../Modules/ReceiptReissue/Components/ReissueUpdate';
import BulkMaintenance from '../../Modules/AccountReceivables/Components/BulkMaintenance/BulkMaintenance';
import EMARSearch from '../../Modules/EMAR/Components/EMARSearch';
import EMARAdd from '../../Modules/EMAR/Components/EMARAdd';
import WaiverLineSearch from '../../Modules/WaiverLine/WaiverLineSearch';
import WaiverAdd from '../../Modules/WaiverLine/WaiverLineAdd';
import FRCAdd from '../../Modules/FRC/Components/FRCAdd';
import FRCSearch from '../../Modules/FRC/Components/FRCSearch';
import CMS64Add from '../../Modules/CMS64/Components/CMS64Add';
import CMS64Search from '../../Modules/CMS64/Components/CMS64Search';
import BulkReverse from '../../Modules/AccountReceivables/Components/BulkMaintenance/Reverse/BulkReverse';
import BulkWriteOff from '../../Modules/AccountReceivables/Components/BulkMaintenance/Write-off/BulkWriteOff';
import BulkWriteOffReest from '../../Modules/AccountReceivables/Components/BulkMaintenance/Write-offReest/BulkWriteOffReest';
import FinancialUpload from '../../Modules/MassUpload/Components/DataUpload';
import FinancialUploadView from '../../Modules/MassUpload/Components/DataUploadView';
import PaymentCriteriaAdd from '../../Modules/PaymentCriteria/Components/Add/PaymentCriteriaAdd';
import PaymentCriteriaSearch from '../../Modules/PaymentCriteria/Components/Search/PaymentCriteriaSearch';
import SITEMAP from '../../Modules/ContentManagement/SiteMap';
import FOOTERLINKS from "../../Modules/ContentManagement/FooterLinks";
import MyAccount from '../Layout/MyAccount';
import { someInLeftNav } from '../../SharedModules/CommonUtilities/commonUtilities';

export default function RoutesApp (props) {
  const tempUUid = props.uuid;
global.enableSpinner = !(localStorage.getItem("IsAddOrCancel") === "Add")  && !someInLeftNav() || (localStorage.getItem("IsAddOrCancel") === "Cancel");
localStorage.removeItem('IsAddOrCancel');  
return (
    <React.Fragment // uuid={props.uuid}
    >
      <Route exact path='/' component={Dashboardsearch} />
      <Route exact path={RouteUrls.My_ACCOUNT} component={MyAccount} />
      {/* Application Configuration start */}
      <Route path={RouteUrls.DASHBOARD} component={Dashboardsearch} />
      <Route path={RouteUrls.VALID_VALUE_SEARCH} component={ValidvalueSearch} />
      <Route path={RouteUrls.VALID_VALUE_VIEW} component={ValidvalueView} />
      <Route path={RouteUrls.SYSTEM_LIST} component={SystemListSearch} />
      <Route path={RouteUrls.SYSTEM_LIST_ADD} render={(props) => <SystemListAdd {...props} key={tempUUid} />} />
      <Route path={RouteUrls.SYSTEM_PARAMETER} component={SystemParameterSearch} />
      <Route path={RouteUrls.SYSTEM_PARAMETER_ADD} render={(props) => <SystemParameterAdd {...props} key={tempUUid} />} />
      <Route path={RouteUrls.SYSTEM_PARAMETER_EDIT} component={SystemParameterUpdate} />
      <Route path={RouteUrls.SYSTEM_LIST_ADD_UPDATE} component={SystemListAddUpdate} />
      <Route path={RouteUrls.MAPSET_SEARCH} component={MapSetSearch} />
      <Route path={RouteUrls.MAPSET_ADD} render={(props) => <MapSetAdd {...props} key={tempUUid} />} />
      <Route path={RouteUrls.MAPSET_EDIT} component={MapSetEdit} />
      <Route path={RouteUrls.TEXT_MANAGEMENT_SEARCH} component={TextManagementSearch} />
      <Route path={RouteUrls.TEXT_UPDATE} component={TextUpdate} />
      <Route path={RouteUrls.TEXT_ADD} render={(props) => <TextAddUpdate {...props} key={tempUUid} />} />
      {/* Application Configuration end */}

      {/* Bank Account & LockBox start */}
      <Route path={RouteUrls.BANK_ACCOUNT_AND_LOCKBOX} component={BankAccountAndLockbox} />
      <Route path={RouteUrls.BANK_ACCOUNT_LOCKBOX_ADD} render={(props) => (<BankAccountAndLockBoxAdd {...props} key={tempUUid} />)} />
      <Route path={RouteUrls.BANK_ACCOUNT_LOCKBOX_EDIT} component={BankAccountAndLockBoxEdit} />
      {/* Bank Account & LockBox end */}

      {/* Budget Maintenance start */}
      <Route path={RouteUrls.FINANCIAL_BUDGET_SEARCH} component={FinancialBudgetSearch} />
      <Route path={RouteUrls.BUDGET_DATA_UPDATE} component={BudgetDataUpdate} />
      <Route path={RouteUrls.BUDGET_DATA_ADD} render={(props) => <BudgetMaintenanceAdd {...props} key={tempUUid} />} />
      {/* Budget Maintenance end */}

      {/* Account Receivables start */}
      <Route path={RouteUrls.FINANCIAL_ACCOUNT_INQUIRY} component={FinancialAccountInquiry} />
      <Route path={RouteUrls.FCN_SEARCH_RESULTS} component={FCNSearchResults} />
      <Route path={RouteUrls.GROSS_LEVEL_ADD_PAYEE_COMPONENT} render={(props) => (   <GrossLevelAddPayeeComponent {...props} key={tempUUid} /> )} />
      <Route path={RouteUrls.FINANCIAL_ACCOUNT_GROSS_LEVEL} component={FinancialAccountGrossLevel} />
      <Route path={RouteUrls.PAYEE_SEARCH_RESULTS} component={PayeeSearchResults} />
      <Route path={RouteUrls.MCN_SEARCH_RESULTS} component={MCNSearchResults} />
      <Route path={RouteUrls.FINANCIAL_ACCOUNT_LIEN_LEVY} component={LienLevyAdd} />
      <Route path={RouteUrls.CLAIMS_RECEIVABLE} component={ClaimsReceivable} />
      <Route path={RouteUrls.FINANCIAL_ACCOUNT_LIEN_LEVY_UPDATE} component={LienLevyUpdate} />
      <Route path={RouteUrls.FINANCIAL_ACCOUNT_GROSS_LEVEL_UPDATE} component={GrossLevelUpdate} />
      <Route path={RouteUrls.AR_TRANSFER} component={ARTransfer} />
      <Route path={RouteUrls.AR_REVERSE} component={ARReverse} />
      <Route path={RouteUrls.AR_ADJUST} component={ARAdjust} />
      <Route path={RouteUrls.FP_ADJUST} component={FPAdjust} />
      <Route path={RouteUrls.ADVANCE_PAYMENT} component={AdvancePayment} />
      <Route path={RouteUrls.WRITE_OFF} component={WriteOff} />
      <Route path={RouteUrls.WRITE_OFF_REESTABLISHMENT} component={WriteOffReEstablishment} />
      <Route path={RouteUrls.BULK_MAINTENANCE} component={BulkMaintenance} />
      <Route path={RouteUrls.AR_BULK_TRANSFER} component={ARBulkTransfer} />
      <Route path={RouteUrls.BULK_REVERSE} component={BulkReverse} />
      <Route path={RouteUrls.BULK_WRITE_OFF} component={BulkWriteOff} />
      <Route path={RouteUrls.BULK_WRITE_OFF_REEST} component={BulkWriteOffReest} />
      <Route path={RouteUrls.CHANGE_PAY_STATUS} component={ChangePayStatus} />
      {/* Account Receivables end */}

      {/* Security start */}
      <Route path={RouteUrls.CHANGE_PASSWORD} component={ChangePassword} />
      <Route path={RouteUrls.ADD_USER} render={(props) => <AddUser {...props} key={tempUUid} />} />
      <Route path={RouteUrls.EDIT_USER} component={AddUser} />
      <Route path={RouteUrls.USER_PROFILE} component={UserProfile} />
      <Route path={RouteUrls.SEARCH_USER} component={SearchUser} />
      <Route path={RouteUrls.RESET_PASSWORD} component={ResetPassword} />

      {/* Security end */}

      {/* Financial Entity start */}
      <Route path={RouteUrls.FINANCIAL_ENTITY_SEARCH} component={FinancialEntitySearch} />
      <Route path={RouteUrls.FINANCIAL_ENTITY_ADD} render={(props) => <FinancialEntityAdd {...props} key={tempUUid} />} />
      <Route path={RouteUrls.FINANCIAL_ENTITY_UPDATE} component={FinancialEntityAdd} />

      {/* Financial Entity end */}

      {/* Financial Receipt start */}
      <Route path={RouteUrls.FINANCIAL_RECEIPT_SEARCH} component={ FinancialReceiptSearch } />
      <Route path={RouteUrls.FINANCIAL_RECEIPT_RETURN} component={ FinancialReceiptReturn } />
      <Route path={RouteUrls.FINANCIAL_RECEIPT_ADD} render={ (props) => <FinancialReceiptAdd { ...props } key={ tempUUid } /> } />
      <Route path={RouteUrls.FINANCIAL_RECEIPT_UPDATE} component={ FinancialReceiptAdd } />
      <Route path={RouteUrls.FINANCIAL_RECEIPT_ADJUSTMENT} component={FinancialReceiptAdjustment} />
      <Route path={RouteUrls.FINANCIAL_RECEIPT_REVERSAL} component={FinancialReceiptReversal} />
      <Route path={RouteUrls.REISSUE_UPDATE} component={ReissueUpdate} />
      {/* Financial Receipt end */}
      {/* Fund Code start */}
      <Route path={RouteUrls.FUND_CODE_SEARCH} component={FundCodeSearch} />
      <Route path={RouteUrls.FUND_CODE_ADD} render={(props) => <FundCodeAdd {...props} key={tempUUid} />} />
      <Route path={RouteUrls.FUND_CODE_EDIT} component={FundCodeEdit} />
      {/* Fund Code end */}
      {/* Financial Payout start */}
      <Route path={RouteUrls.FINANCIAL_PAYOUT_SEARCH} component={FCNInquiryPayOut} />
      <Route path={RouteUrls.FCN_PAYOUT_SEARCH_RESULTS} component={FCNPayOutSearchResults} />
      <Route path={RouteUrls.FINANCIAL_PAYOUT_ADD} render={(props) => <FinancialPayOutAdd {...props} key={tempUUid} />} />
      <Route path={RouteUrls.FINANCIAL_PAYOUT_UPDATE} component={FinancialPayOutUpdate} />
      {/* Financial Payout end */}

      {/* Accounts Payable start */}
      <Route path={RouteUrls.PAYOUT_APPROVAL_SEARCH} component={PayoutApprovalSearchForm} />

      {/* Fiscal Pend Criteria Start */}
      <Route path={RouteUrls.FISCAL_PEND_CRITERIA_ADD} render={(props) => <FiscalPendCriteriaAdd {...props} key={tempUUid} />} />
      <Route path={RouteUrls.FISCAL_PEND_CRITERIA_EDIT} component={FiscalPendCriteriaAdd} />
      <Route path={RouteUrls.FISCAL_PEND_CRITERIA_SEARCH} component={FiscalPendCriteriaSearch} />
      {/* Fiscal Pend Criteria Start */}

      {/* 1099 Correction Start */}
      <Route path={RouteUrls.CORRECTION_SEARCH} component={CorrectionSearch} />
      <Route path={RouteUrls.CORRECTION_DETAILS_VIEW} component={CorrectionDetailsView} />
      <Route path={RouteUrls.CORRECTION_SEARCH_APPROVAL} component={CorrectionSearchApproval} />
      {/* 1099 Correction End */}
      {/* Financial Inquiry start */}
      <Route path={RouteUrls.FINANCIAL_INQUIRY_SEARCH} component={ClaimFinancialInquirySearch} />
      <Route path={RouteUrls.CLAIM_SUMMARY} component={ClaimsReceivables} />
      {/* Financial Inquiry end */}
      {/* Project Control start */}
      <Route path={RouteUrls.EVENT_LOGGING} component={EventLoggingSearch} />
      <Route path={RouteUrls.GLOBAL_AUDIT_SEARCH} component={GlobalAuditSearch} />
      <Route path={RouteUrls.GLOBAL_AUDIT_DETAILS} component={GlobalAuditDetails} />
      {/* Project Control end */}
      {/* EMAR Start */}
      <Route path={RouteUrls.EMAR_SEARCH} component={EMARSearch} />
      <Route path={RouteUrls.EMAR_ADD} component={EMARAdd} />
      <Route path={RouteUrls.EMAR_UPDATE} component={EMARAdd} />
      {/* EMAR End*/}
      {/* Waiver Start */}
      <Route path={RouteUrls.WAIVER_SEARCH} component={WaiverLineSearch} />
      <Route path={RouteUrls.WAIVER_ADD} component={WaiverAdd} />
      <Route path={RouteUrls.WAIVER_UPDATE} component={WaiverAdd} />
      {/* Waiver End */}
      {/* FRC Start*/}
      <Route path={RouteUrls.FRC_ADD} component={FRCAdd} />
      <Route path={RouteUrls.FRC_SEARCH} component={FRCSearch} />
      <Route path={RouteUrls.FRC_UPDATE} component={FRCAdd} />
      {/* FRC End*/}
      {/* CMS Start*/}
      <Route path={RouteUrls.CMS64_SEARCH} component={CMS64Search} />
      <Route path={RouteUrls.CMS64_ADD} component={CMS64Add} />
      <Route path={RouteUrls.CMS64_UPDATE} component={CMS64Add} />
      {/* CMS End*/}
      {/* Mass Upload Start */}
      <Route path={RouteUrls.FINANCIAL_UPLOAD} component={FinancialUpload} />
      <Route path={RouteUrls.FINANCIAL_UPLOAD_VIEW} component={FinancialUploadView} />
      {/* Mass Upload End */}
      {/* Payment Inquiry Status Start */}
      <Route path={RouteUrls.PAYMENT_INQUIRY_STATUS} component={PaymentStatusInquirySearch} />
      {/* Payment Inquiry Status End */}
      {/* Payment Criteria Start */}
      <Route path={RouteUrls.PAYMENT_CRITERIA_SEARCH} component={PaymentCriteriaSearch} />
      <Route path={RouteUrls.PAYMENT_CRITERIA_ADD} component={PaymentCriteriaAdd} />
      <Route path={RouteUrls.PAYMENT_CRITERIA_EDIT} component={PaymentCriteriaAdd} />
      {/* Payment Criteria End */}
      {/* Footer Links Start */}
      <Route path={RouteUrls.SITE_MAP} component={SITEMAP} />
      <Route path={RouteUrls.FOOTER_LINKS} component={FOOTERLINKS} />
      {/* Footer Links Ends */}
      {/* Insights Start */}
      <Route path={RouteUrls.OPERATIONAL_INSIGHTS} />
      <Route path={RouteUrls.PERFORMANCE_INSIGHTS} />
      {/* Insights Ends */}
    </React.Fragment>
  );
}
