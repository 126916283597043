import React from "react";
import { withRouter } from "react-router";
import TableComponent from "../../../../SharedModules/Table/Table";
import dateFnsFormat from 'date-fns/format';
import moment from 'moment';
const headCellsInquiry = [
  {
    id: "eventLoggedDate",
    numeric: false,
    disablePadding: true,
    label: "Date",
    enableHyperLink: false,
    isDate: true,
    fontSize: 12,
    width: "100px",
  },
  {
    id: "eventTime",
    numeric: false,
    disablePadding: true,
    label: "Time",
    enableHyperLink: false,
    isConcat: true,
    fontSize: 12,
    width: "100px",
  }, 
  {
    id: "userId",
    numeric: false,
    disablePadding: true,
    label: "User ID",
    enableHyperLink: false,
    isConcat: true,
    fontSize: 12,
    width: "110px",
  },
  {
    id: "userName",
    numeric: false,
    disablePadding: true,
    label: "User Name",
    enableHyperLink: false,
    isConcat: true,
    fontSize: 12,
    width: "150px",
  },
  {
    id: "portletName",
    numeric: false,
    disablePadding: true,
    label: "Portlet Name",
    enableHyperLink: false,
    isConcat: true,
    fontSize: 12,
    width: "140px",
  },
  {
    id: "keyType",
    numeric: false,
    disablePadding: true,
    label: "Key Type",
    enableHyperLink: false,
    isConcat: true,
    fontSize: 12,
    width: "100px",
  },
  {
    id: "key",
    numeric: false,
    disablePadding: true,
    label: "Key",
    enableHyperLink: false,
    isConcat: true,
    fontSize: 12,
    width: "100px",
  }
];
const headCellsEvent = [
  {
    id: "eventLoggedDate",
    numeric: false,
    disablePadding: true,
    label: "Date",
    enableHyperLink: false,
    isDate: true,
    fontSize: 12,
    width: "110px",
    },
  {
    id: "eventTime",
    numeric: false,
    disablePadding: true,
    label: "Time",
    enableHyperLink: false,
    isConcat: true,
    fontSize: 12,
    width: "120px",
  },
  {
    id: "userId",
    numeric: false,
    disablePadding: true,
    label: "User ID",
    enableHyperLink: false,
    isConcat: true,
    fontSize: 12,
    width: "110px",
    },
  {
    id: "userName",
    numeric: false,
    disablePadding: true,
    label: "User Name",
    enableHyperLink: false,
    isConcat: true,
    fontSize: 12,
    width: "145px",
  },
  {
    id: "eventType",
    numeric: false,
    disablePadding: true,
    label: "Event Type",
    enableHyperLink: false,
    isConcat: true,
    fontSize: 12,
    width: "100px",
  },
  {
    id: "eventCode",
    numeric: false,
    disablePadding: true,
    label: "Event Code",
    enableHyperLink: false,
    isConcat: true,
    fontSize: 12,
    width: "120px",
  },
  {
    id: "shortDescription",
    numeric: false,
    disablePadding: true,
    label: "Short Description",
    enableHyperLink: false,
    isConcat: true,
    fontSize: 12,
    width: "120px",
  },
  {
    id: "technicalDescription",
    numeric: false,
    disablePadding: true,
    label: "Technical Message",
    enableHyperLink: false,
    isConcat: true,
    fontSize: 12,
    width: "120px",
  },
];
function EventSearchTableComponent(props) {
  const editRow = (row) => (event) => {
  }
  const formatDate = (dt) => {      
    if (!dt) {
        return "";
    }
    dt = new Date(dt);
    if (dt.toString() == "Invalid Date") {
        return dt;
    } else {
        return dateFnsFormat(dt, "MM/dd/yyyy");
    }
};

  const getTableData = (data) => {
    if (data && data.length) {
      let tData = JSON.stringify(data);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        each.index = index;
        each.eventLoggedDate = formatDate(each.eventLoggedDate);
        each.eventTime = moment(each.eventTime).format('hh:mm:ss A');
      });
      return tData;
    } else {
      return [];
    }
  };
  return (
    <div className="wrap-th-header">
      <TableComponent
        headCells={props.tabName && props.tabName ==="Inquiry" ? headCellsInquiry : headCellsEvent}
        onTableRowClick={editRow}
        tableData={getTableData(props.tableData)}
        defaultSortColumn="carrierID"
        isSearch = {true}
      />
    </div>
  );
}
export default withRouter(EventSearchTableComponent);
