/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const BUSINESS_NAME_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const DATA_ELEMENT_NAME_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const SOURCE_TABLE_REQUIRED = 'Source Table is required.';
export const CODE_DATASIZE_REQUIRED = 'Code is required.';
export const CODE_INVALID = 'Code is Invalid.';
export const CONSTANT_TEXT_REQUIRED = 'Constant Text is required.';
export const SHORT_DESCRIPTION_REQUIRED = 'Short Description is required.';
export const LONG_DESCRIPTION_REQUIRED = 'Long Description is required.';
export const FUNCTIONAL_AREA_FIELD_REQUIRED = 'Functional Area is required.';
export const CODE_DATASIZE_ERROR = 'Valid Value longer than defined Data Size.';
export const ASSOCIATED_VALID_VALUE_REQUIRED = 'At least one Associated Valid Value row is required if Source Table is R_VV_TB.';
export const VALID_VALUE_CODE_OVERLAPPING = 'Associated Valid Value already exists.';
export const CONSTANT_TEXT_FORMAT_ERROR = 'Valid Value Constant Text must begin with VV_ and should not contain spaces or special characters.';
export const CONSTANT_TEXT_UNIQUE_ERROR = 'Constant Text is not unique for the Valid Value domain.';
export const NO_RECORDS_FOUND = 'No records found for the search criteria entered.';
export const ERROR_OCCURED_DURING_TRANSACTION = 'There was an error processing the request. Please retry the transaction.';
export const SEARCH_DATA_INCORRECT_FORMATE = 'Please enter the data in correct format.';
export const SOURCE_TABLE_NOT_R_VV_TB = 'Source Code and Source Description fields are required when Source Table is a table other than R_VV_TB.';
export const ITEM_BEGINDATE_GREATER_CURRENTDATE = 'In order to delete this item, the begin date must be greater than or equal to the current date.';
export const TEXT_INVALID_FORMAT = 'Valid Value Constant Text must begin with VV_ and should not contain spaces or special characters.';
export const ERROR_PROCESSING_REQUEST = 'There was an error processing the request. Please retry the transaction.';
export const LONG_DES_INVALID = 'Long Description is Invalid.';
export const SHORT_DES_INVALID = 'Short Description is Invalid.'
