/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const CR_HEAD = 'Financial - Claim Receivable';
export const CR_TEXT = 'Claim Receivable';
export const RECOUPMENT = 'Recoupment';
export const ORIGINATING_CLAIMS = 'Originating Claims';
export const NETWORK_ERROR = 'Network Error';
export const TRANSACTION_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const RECOUPMENT_CODE = 'recoupmentCode';
export const START_DATE_INVALID = 'Start Date entered is invalid.';
export const DUE_DATE_INVALID = 'Due Date entered is invalid.';
export const DEFAULT_DATE_INVALID = 'Default Date entered is invalid.';
export const INSTALLMENT_INVALID = 'Installment Value entered is invalid.';
export const PERCENT_INVALID = 'Percent Value entered is invalid.';
export const DATA_INVALID = 'Please enter the data in correct format.';
export const DEFAULT_DATE_LESS = 'Default Date can not be prior to todays date.';
export const TRANSACTION_NUMBER_FORMAT = 'should not contain spaces or special characters.';

export const RECOUPMENT_CODE_REQUIRED = 'Recoupment Type Code is required.';
export const INSTALLMENT_VALUE_REQUIRED = 'Installment Value is required.';
export const START_DATE_REQUIRED = 'Start Date is required.';
export const PERCENT_VALUE_REQUIRED = 'Percent Value is required.';
export const PERCENT_NEGATIVE_ERROR = 'A negative Percentage value cannot be entered. Please re-enter.';
export const INSTALLMENT_NEGATIVE_ERROR = 'A negative Installment value is not allowed. Please re-enter.';
export const PERCENT_INSTALLMENT_BOTH_NOT_ALLOWED = 'Both Percent and Installment values are not allowed at the same time. Please re-enter.';
export const START_DATE_PERCENT_REQUIRED = 'Recoupment start date and Percentage value are required.';
export const START_DATE_LESS_CURRENT_DATE_ERROR = 'Recoupment Start Date cannot be less than {current date}. Please re-enter.';
export const START_DATE_LESS_AR_CREATED_ERROR = 'The Recoupment Start Date must be greater than or equal to the date the A/R was created. Please re-enter.';
export const START_DATE_INSTALLMENT_REQUIRED = 'Recoupment start date and value are required. Please enter required field.';
export const PERCENT_MUST_BE_WITHIN_0_100 = 'Recoupment Percentage Value must be between 0 and 100. Please re-enter.';
export const INSTALLMENT_MUST_BE_GREATER_0 = 'Installment Value must be greater than 0. Please re-enter.';
export const INSTALLMENT_PERCENT_MUST_BE_0 = 'For Recoupment Type No Recoupment, Installment and Percent must be zero.';
export const INSTALLMENT_GREATER_ORIGINAL_AMOUNT_ERROR = 'Installment Amount cannot exceed Original/Adjusted A/R Amount. Please re-enter.';
export const PERCENT_MUST_BE_BLANK = 'For Recoupment Type Installment, Percent Value must be blank.';
export const INSTALLMENT_MUST_BE_BLANK = 'For Recoupment Type Percent, Installment Value must be blank.';
export const START_DATE_MUST_BE_BLANK = 'For Recoupment Type No Recoupment, Recoupment Start Date must be blank.';
export const PAYMENT_SCHEDULE_REQUIRED = 'For recoupment type scheduled payment, a payment schedule is required.';
export const FREQUENCY_MUST_BE_BLANK = 'For Recoupment Type No Recoupment, the frequency must be blank.';
export const PERCENT_VALUE_CAN_NOT_GREATER_100 = 'Percentage value cannot be greater than 100. Please re-enter.';
export const START_DATE_SHOULD_BE_GREATER = 'The Recoupment Start Date must be greater than or equal to the date the A/R was created. Please re-enter.';
export const PERCENT_ZERO = 'Percentage Amount must be greater than 0. Please Re-Enter.';

export const A = 'A';
export const B = 'B';
export const D = 'D';
export const N = 'N';
export const S = 'S';
export const DUE_DATE_ERROR = 'Due date must be greater than or equal to today’s date.';
