/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/
import React from "react";
import TableComponent from "../../../../../SharedModules/Table/Table";

const headCells = [
  {
    id: "beginDate",
    numeric: false,
    disablePadding: true,
    label: "Begin Date",
    width: '15%',
    isDate: true
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: false,
    label: "End Date",
    width: '15%',
    isDate: true
  },
  {
    id: "startingValue",
    numeric: false,
    disablePadding: false,
    label: "Begin Value",
    isVarChar: true,
    width: '15%',
  },
  {
    id: "endingValue",
    numeric: false,
    disablePadding: false,
    label: "End Value",
    isVarChar: true,
    width: '15%',
  },
  { id: 'sortNum', numeric: false, disablePadding: false, label: 'Sort Order', width: '15%', isVarChar: true },
  {
    id: "voidDate",
    numeric: false,
    disablePadding: false,
    label: "Void Date",
    width: '15%',
    isDate: true
  },
  
];

export default function SystemListAddTable(props) {
  
  const tableComp = (
    <TableComponent
      fixedTable
      print={ props.print }
      headCells={ headCells }
      isSearch={ false }
      isTrueSort={true}
      tableData={props.tableData ? props.tableData : []}
      onTableRowClick={ props.editSystemListTable }
      defaultSortColumn={ headCells[0].id }
      onTableRowDelete={ props.rowDeleteSystemListDetails }
      onTableCellButtonClick={ props.onClickAuditDialogLog }
      uniqueKey={ props.uniqueKey }
      setClose={ props.setClose }
      close={ props.close }
    />
  );
  return tableComp;
}
