/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const DROPDOWNDATA = 'DROPDOWNDATA';
export const SEARCH_EMAR = 'SEARCH_EMAR';
export const CREATE_EMAR = 'CREATE_EMAR';
export const UPDATE_EMAR = 'UPDATE_EMAR';
export const RESET_EMAR = 'RESET_EMAR';