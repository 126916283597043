/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from "../Actions/DataUploadActionTypes";
const axiosPayload = {

  searchData: null,
  uniqueData:null

};
const reducer = (state = axiosPayload, action) => {
  switch (action.type) {
    case actionTypes.RESET_SEARCH_ACTION:
      return {...state , searchData: null,uniqueData: null}
    case actionTypes.DROPDOWNDATA:
      return { ...state, dropdownData: action.dropdownData };
    case actionTypes.SEARCH_ACTION:
      return { ...state, searchData: action.searchData , searhCriteria:action.searhCriteria};
    case actionTypes.ACTIVITY_DROPDOWN_ACTION:
      return { ...state, activityDropDown: action.activityDropDown };
    case actionTypes.UNIQUE_DETAILS:
      return { ...state, uniqueData: action.uniqueData };
    case actionTypes.UNIQUE_DETAILS_USER_ID:
      return { ...state, uniqueDataUsers: action.uniqueDataUsers };
    case actionTypes.UPLOAD_TXN:
      return { ...state, saveResponse: action.saveResponse };
    default:
      return state;
  }
};

export default reducer;
