/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import Bootstrap, { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import PayeeJson from "./PayeType.json";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import axios from "axios";
import clsx from "clsx";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import NativeDropDown from "../../../../SharedModules/Dropdowns/NativeDropDown";
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  }
}));

export default function AddBank(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE
  // Dropdown starts
  const dropDownData = useSelector(
    state => state.financialEntityState.dropdownData
  );
  const {
    editRow,
    showEditPage,
    formatDecimalForAmountClaimAmount,
    formatDecimalForAmountDebtAmount,
    removeFormatDecimalForAmountDebtAmount,
    removeFormatDecimalForAmountClaimAmount,
    disableFields,
    BankruptcyTypeDropdown,
    userInquiryPrivileges
  } = props;
  
  // Dropdown ends

  let bnkruptcyType = BankruptcyTypeDropdown.find(value => value.code === props.values.bankruptcyTypeCode);

  return (
    <div className="form-border my-3">
    <form autoComplete="off">
      <div className="form-wrapper">
          <NativeDropDown
            id="bankruptcy-type"
            isRequired
            label="Bankruptcy Type Code"
            inputProps={{ maxlength: 2 }}
            value={bnkruptcyType !== undefined ? props.values.bankruptcyTypeCode : DEFAULT_DD_VALUE}
            disabled={disableFields || !userInquiryPrivileges}
            onChange={props.handleChangeMiscellaneous("bankruptcyTypeCode")}
            InputLabelProps={{
              shrink: true
            }}
            errTxt={
              props.showMiscellaneousError.showBankruptcyTypeCodeError
                ? props.showMiscellaneousErrorMsg.showBankruptcyTypeCodeErrorMsg
                : null
            }
            dropdownList={BankruptcyTypeDropdown
              ? BankruptcyTypeDropdown.map(option => (
                <option key={option} value={option.code}>
                    {option.description}
                </option>
                ))
              : null}
          />

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="mui-custom-form  with-date">
            <KeyboardDatePicker
              maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
              id="bankruptcy-begin-date"
              label="Bankruptcy Begin Date"
              format="MM/dd/yyyy"
              required
              disabled={disableFields || !userInquiryPrivileges}
              placeholder="mm/dd/yyyy"
              value={props.SelectedMiscellaneousBeginDate}
              onChange={props.handleBeginDateChange}
              InputLabelProps={{
                shrink: true
              }}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              helperText={
                props.showMiscellaneousError.showBankruptcyBeginDateError
                  ? props.showMiscellaneousErrorMsg
                      .showBankruptcyBeginDateErrorMsg
                  : null
              }
              error={
                props.showMiscellaneousError.showBankruptcyBeginDateError
                  ? props.showMiscellaneousErrorMsg
                      .showBankruptcyBeginDateErrorMsg
                  : null
              }
            />
          </div>
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="mui-custom-form with-date">
            <KeyboardDatePicker
              maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
              id="bankruptcy-end-date"
              label="Bankruptcy End Date"
              required
              format="MM/dd/yyyy"
              placeholder="mm/dd/yyyy"
              value={props.SelectedMiscellaneousEndDate}
              onChange={props.handleEndDateChange}
              InputLabelProps={{
                shrink: true
              }}
              disabled={disableFields || !userInquiryPrivileges}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              helperText={
                props.showMiscellaneousError.showBankruptcyEndDateError
                  ? props.showMiscellaneousErrorMsg
                      .showBankruptcyEndDateErrorMsg
                  : null
              }
              error={
                props.showMiscellaneousError.showBankruptcyEndDateError
                  ? props.showMiscellaneousErrorMsg
                      .showBankruptcyEndDateErrorMsg
                  : null
              }
            />
          </div>
        </MuiPickersUtilsProvider>
        <div className="mui-custom-form">
          <TextField
            id="claim-amount"
            label="Claim Amount"
            required
            inputProps={{ maxlength: 10 }}
            disabled={(showEditPage && editRow) || disableFields || !userInquiryPrivileges}
            value={props.values.claimAmount}
            onChange={props.handleChangeMiscellaneous("claimAmount")}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
            onInput={e => {
              e.target.value = e.target.value.replace(
                /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                ""
              );
            }}
            helperText={
              props.showMiscellaneousError.showClaimAmountError
                ? props.showMiscellaneousErrorMsg.showClaimAmountErrorMsg
                : null
            }
            error={
              props.showMiscellaneousError.showClaimAmountError
                ? props.showMiscellaneousErrorMsg.showClaimAmountErrorMsg
                : null
            }
          ></TextField>
        </div>
      </div>
      <div className="form-wrapper">
        <div className="mui-custom-form input-md">
          <TextField
            id="postedPetitionDebtAmount"
            label="Posted Petition Debt Amount"
            inputProps={{ maxlength: 10 }}
            required
            disabled={(showEditPage && editRow) || disableFields || !userInquiryPrivileges}
            value={props.values.postedPetitionDebtAmount}
            onChange={props.handleChangeMiscellaneous(
              "postedPetitionDebtAmount"
            )}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
            helperText={
              props.showMiscellaneousError.showPostedPetitionDebtAmountError
                ? props.showMiscellaneousErrorMsg
                    .showPostedPetitionDebtAmountErrorMsg
                : null
            }
            error={
              props.showMiscellaneousError.showPostedPetitionDebtAmountError
                ? props.showMiscellaneousErrorMsg
                    .showPostedPetitionDebtAmountErrorMsg
                : null
            }
          ></TextField>
        </div>
        <div className="mui-custom-form sub-radio checkbox-font">
          <FormControlLabel
            value="balance"
            control={
              <Checkbox
                color="primary"
                checked={props.values.postedPetitionIndicator}
                value={props.values.postedPetitionIndicator}
                onChange={props.handleChangeMiscellaneous(
                  "postedPetitionIndicator"
                )}
                disabled={disableFields || !userInquiryPrivileges}
              />
            }
            label="Posted Petition Indicator"
            labelPlacement="top"
          />
        </div>
      </div>
    </form>
    </div>
  );
}
