/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import TextField from '@material-ui/core/TextField';
import TableComponent from '../../../../SharedModules/Table/Table';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import numeral from 'numeral';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import AuditTableComponent from '../../../../SharedModules/AuditLog/AuditTableComponent'
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';
import { CORRECTION_YES_APPROVAL_INDICATOR,CORRECTION_NO_APPROVAL_INDICATOR ,CORRECTION_PENDING_APPROVAL_INDICATOR} from '../../../../SharedModules/AppConstants';
export default function CorrectionView (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  /* IMPLEMENT TABLE COMPONENT */
  const headCells = [
    {
      id: 'updateDateStr',
      disablePadding: false,
      label: 'Correction Date',
      enableHyperLink: true,
      width: '20%'
    },
    {
      id: 'adjusted1099Amount',
      disablePadding: false,
      label: 'Adjusted 1099 Amount',
      enableHyperLink: false,
      isBalance: true,
      width: '20%'
    },
    {
      id: 'reasonCodeDesc',
      disablePadding: false,
      label: 'Reason Code',
      enableHyperLink: false,
      width: '20%'
    },
    {
      id: 'fin1099ApprovalIndicator',
      disablePadding: false,
      label: '1099 Approval Indicator',
      enableHyperLink: false,
      width: '20%'
    },
    {
      id: 'fin1099VoidIndicator',
      disablePadding: false,
      label: 'Void Indicator',
      enableHyperLink: false,
      width: '20%'
    }
  ];
  const editRow = row => event => {
    props.setCorrectionHistoryRowData(row);
    props.setIsCorrectionHistoryRowData(true);
  };
  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          let reasonDescri =
              props.reasonCodeData &&
              props.reasonCodeData.filter(
                value => ((value.code === each.reasonCode) || (value.description === each.reasonCode))
              );
          each.reasonCode = reasonDescri && reasonDescri.length
            ? reasonDescri[0].description
            : '';
          const reasonCode =
            props.dropdowns &&
            props.dropdowns['F1#F_RSN_CD'] &&
            props.dropdowns['F1#F_RSN_CD'].filter(
              value => value.description === each.reasonCode
            );
          each.reasonCodeDesc =
          reasonCode && reasonCode.length > 0
              ? reasonCode[0].description
              : '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };
  let resnCode = props.reasonCodeData.find(value => value.code === props.correctionViewDetails.reasonCode);
   return (
    <form style={{ paddingLeft: '1%' }}>
      <div className="form-wrapper">
        <div className="mui-custom-form">
          <TextField
            id="standard-originala/ramount"
            label="Original 1099 Amount"
			data-testid='test-original'
            InputProps={{
              "data-testid":"1099Amount",
              readOnly: true,
              className: 'Mui-disabled',
              startAdornment:
                <InputAdornment position="start">$</InputAdornment>
            }}
			value={numeral(
              props.correctionViewDetails.original1099Amount
            ).format('0,0.00')}
            onChange={props.handleChangeDetails('original1099Amount')}
            placeholder=""
            inputProps={{ maxlength: 13 }}
            InputLabelProps={{
              shrink: true
            }}
          ></TextField>
        </div>
        <div className="mui-custom-form">
          <TextField
            id="adjustedAmount1099"
            name="adjustedAmount1099"
            label="Adjusted 1099 Amount"
            InputProps={{
              "data-testid":"AdjustAmount",
              startAdornment:
                props.correctionViewDetails.a1099Approval !== 'Yes' ? (
                  <InputAdornment position="start">$</InputAdornment>
                ) : null
            }}
            required={props.correctionViewDetails.reasonCode === props.systemList26.toString()}
            value={
              props.correctionViewDetails.adjusted1099Amount
            }
            onChange={props.handleDecimalValues('adjusted1099Amount')}
            disabled={props.correctionViewDetails?.a1099Approval === 'Yes'}
            placeholder=""
            inputProps={{ maxlength: 14 }}
            InputLabelProps={{
              shrink: true
            }}
            onInput={e => {
              e.target.value = e.target.value.replace(
                /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                ''
              );
            }}
            helperText={
              props.showAdjustedAmountError
                ? props.showAdjustedAmountErrorText
                : null
            }
            error={
              props.showAdjustedAmountError
                ? props.showAdjustedAmountErrorText
                : null
            }
          ></TextField>
        </div>
          <NativeDropDown
            id="standard-reason-code"
            label="Reason Code"
            isRequired
            value={resnCode !== undefined ? props.correctionViewDetails.reasonCode : DEFAULT_DD_VALUE}
            onChange={props.handleChangeDetails('reasonCode')}
            placeholder=""
            inputProps={{ maxlength: 11,"data-testid":"test_ReasonCode" }}
            InputLabelProps={{
              shrink: true
            }}
            errTxt={
              props.showReasonCodeError ? props.showReasonCodeErrorText : null
            }
            disabled={props.correctionViewDetails?.a1099Approval === 'Yes'}
            dropdownList={props.reasonCodeData
              ? props.reasonCodeData.map((option, index) => (
                <option key={index} value={option.code}>
                  {option.description}
                </option>
              ))
              : null}
          />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="mui-custom-form with-date">
            <KeyboardDatePicker
              id="date-picker-dialog"
              required
              label="Update Date"
              format="MM/dd/yyyy"
              placeholder="mm/dd/yyyy"
              minDate={new Date()}
              maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
              value={props.updateDate}
              InputLabelProps={{
                shrink: true
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              helperText={
                props.showUpdateDateError ? props.showUpdateDateErrorText : null
              }
              error={
                props.showUpdateDateError ? props.showUpdateDateErrorText : null
              }
              disabled
            />
          </div>
        </MuiPickersUtilsProvider>
      </div>
      <div className="form-wrapper">
        <div className="mui-custom-form">
          <label className="MuiFormLabel-root small-label no-margin">
            1099 Approval Indicator
          </label>
          <div className="radioDisabled">
            {props.correctionViewDetails.a1099Approval === CORRECTION_YES_APPROVAL_INDICATOR ? (
              <span
              >
                Y-Yes
              </span>
            ) : null}
            {props.correctionViewDetails.a1099Approval === CORRECTION_NO_APPROVAL_INDICATOR ? (
              <span
              >
               N-No
              </span>
            ) : null}
            {(props.correctionViewDetails.a1099Approval === CORRECTION_PENDING_APPROVAL_INDICATOR) ? (
              <span
              >
                P-Pending
              </span>
            ) : null}
          </div>
        </div>
        <div className="mui-custom-form">
          <label className="MuiFormLabel-root small-label no-margin">
            Void Indicator
          </label>
          <div className="sub-radio">
            <Radio
              name="void"
              value="Yes"
              id="void-indicator-yes"
              disabled
              checked={props.correctionViewDetails.void === 'Yes'}
              required={props.correctionViewDetails.reasonCode === '1099'}
            />
            <label
              for="void-indicator-yes"
              className="text-black"
              style={{ fontSize: '14px', fontWeight: '650', color: '#274463' }}
            >
              Yes
            </label>
            <Radio className="ml-2"
              name="void"
              value="No"
              id="void-indicator-no"
              disabled
			  checked={props.correctionViewDetails.void === 'No'}
              required={props.correctionViewDetails.reasonCode === '1099'}
            />
            <label
              for="void-indicator-no"
              className="text-black"
              style={{ fontSize: '14px', fontWeight: '650', color: '#274463' }}
            >
              No
            </label>
          </div>
        </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="mui-custom-form input-date with-date">
            <KeyboardDatePicker
              id="letterSendDate"
              label="Letter Sent Date"
              format="MM/dd/yyyy"
              placeholder="mm/dd/yyyy"
              maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
              value={props.letterSendDt}
              onChange={props.handleLetterSendDateChange}
              InputProps={{"data-testid":"letterSentDate"}}
              InputLabelProps={{
                shrink: true
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              disabled
            />
          </div>
        </MuiPickersUtilsProvider>
      </div>
      {props.tableData?.length > 0 ? (
        <div className="tabs-container">
          <div className="tab-header">
            <h1 className="tab-heading float-left">Correction Detail</h1>
            <div
              className="float-right mb-2"
              style={ { marginTop: '6px' } }
            >
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="tab-panelbody">
            <div className="tab-holder mb-3 mt-2">
              <div className="mt-2">
                { (
                  <div className="tab-holder p-0">
                    <TableComponent
                      print={ props.print }
                      fixedTable
                      align={ 'left' }
                      isSearch={ true }
                      headCells={ headCells }
                      tableData={ getTableData(props.tableData ? props.tableData : [] )}
                      onTableRowClick={ editRow }
                      defaultSortColumn={ headCells[0].id}
                      order={'desc'}
                    />
                  </div>
                ) }
              </div>
            </div>
          </div>
        </div>) : null }
        {props.showLogTable ? (
                  <div id="audit-table">
                    <AuditTableComponent
                      print={props.print}
                      auditRowData={props.auditData ? props.auditData : []}
                    />{" "}
                  </div>
                ) : null}
    </form>
  );
}
