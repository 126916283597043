import React, { useEffect, useState } from 'react';
import My_Account from '../MicroFrontend/MicrofrontendContainer';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import axios from 'axios';
import { MANAGE_ACCOUNT_USER_DETAILS } from '../services/service';
import DisplayErrorMessages from '../Errors/ErrorMessages';
import { commonAPIHandler } from '../ServerErrorHandler';
import { SYSTEMERROR } from '../../Modules/FiscalPendCriteria/Components/ErrorConstants';

function MyAccount (props) {
  const [userDetailsData, setUserDetailsData] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [scriptLoad, setScriptLoad] = useState(false);

  const validateResponse = responseHandler => {
    if (responseHandler?.statusCode == '01') {
      setUserDetailsData(responseHandler?.responseObject);

      setScriptLoad(true);
      setErrorMessages([]);
    } else if (responseHandler?.statusCode == '02') {
      setErrorMessages([responseHandler?.statusDescription]);

      setScriptLoad(false);
    }
  };
  const getUserDetails = loginState => {
    setErrorMessages([]);
    axios.get(
        MANAGE_ACCOUNT_USER_DETAILS +
          loginState?.logInUser
      )
      .then(response => {
        const responseHandler = commonAPIHandler(response);
        validateResponse(responseHandler);
      })
      .catch(error => {
        const errorHandler = commonAPIHandler(error);

        if (errorHandler && errorHandler.systemFailure) {
          setErrorMessages([SYSTEMERROR]);
        }

        setScriptLoad(false);
      });
  };

  useEffect(() => {
    const loginState = JSON.parse(localStorage.getItem('loginState'));
    getUserDetails(loginState);
  }, []);

  const myAccountElement = (
    <My_Account type='my_account' userDetailsData={userDetailsData} />
  );
  let myAccountDiv;
  if (userDetailsData) {
    myAccountDiv = myAccountElement;
  } else {
    myAccountDiv = myAccountElement;
  }
  return (
    <>
      {errorMessages && errorMessages.length > 0 ? (
        <DisplayErrorMessages errorMessages={errorMessages} />
      ) : (
        <></>
      )}
      {scriptLoad ? (
        myAccountDiv
      ) : (
        <></>
      )}
    </>
  );
}

export default withRouter(MyAccount);
