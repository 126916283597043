/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/EmarActiontypes';

const initialState = {
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DROPDOWNDATA:
      return { dropdownData: action.dropdownData };
    case actionTypes.SEARCH_EMAR: 
        return {searchPayload: action.searchPayload};
    case actionTypes.CREATE_EMAR: 
        return {createPayload: action.createPayload};
    case actionTypes.UPDATE_EMAR: 
        return {updatePayload: action.updatePayload};
  case actionTypes.RESET_EMAR:
            return { ...state, [action.keyName]: null };
        default: return state;
    }
};

export default reducer;
