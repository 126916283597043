/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import TableComponent from "../../../../../../../SharedModules/Table/Table";

export default function lienHolderPayoutTable(props) {
  const headCells = [
    {
      id: "receivableFCN",
      numeric: false,
      disablePadding: true,
      label: "FCN/TCN",
      isDate: false,
      width: 120,
      fontSize: 13,
      isRedirectable: true
    },
    {
      id: "reasonCode",
      numeric: false,
      disablePadding: false,
      label: "Reason Code",
      isDate: false,
      width: 120,
      fontSize: 13,
      isTooltip: true,
      isTitle: 'reasonCodeLongDesc'
    },
    {
      id: "appliedDate",
      numeric: false,
      disablePadding: false,
      label: "Paid Date",
      enableHyperLink: false,
      isDate: true,
      width: 120,
      fontSize: 13
    },
    {
      id: "appliedAmount",
      numeric: false,
      disablePadding: false,
      label: "Payment Amount",
      enableHyperLink: false,
      isDate: false,
      width: 120,
      isBalance: true,
      fontSize: 13
    }
  ];

  const onRowClick = row => event => { };
  const onLinkClick = row => event => {
    window.open("fcn", "_blank");
  };

  return (
    <div>
      <TableComponent
        print={props.print}
        headCells={headCells}
        tableData={
          props.tableData && props.tableData.length > 0 ? props.tableData : []
        }
        isSearch={true}
        onTableRowClick={onRowClick}
        onLinkClick={onLinkClick}
      />
    </div>
  );
}
