/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './CMS64Actiontypes';
import * as serviceEndPoint from '../../../../SharedModules/services/service';
import axios from 'axios';
import { commonAPIHandler } from '../../../../SharedModules/ServerErrorHandler/index';

const headers = {
  'Access-Control-Allow-Origin': '*'
};

export const RESET_CMS64 = (keyName) => {
  return {
    type: actionTypes.RESET_CMS64,
    keyName: keyName
  }
}

export const getDropdownDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.DROPDOWNS}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setDropdownData(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setDropdownData(sendingResponse));
      });
  };
};

export const searchCMS64Action = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SEARCH_CMS64}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(setSearchData(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setSearchData(sendingResponse));
      });
  };
};

export const createCMS64Action = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.ADD_CMS64}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setAddresponse(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setAddresponse({ systemFailure: true }));
      });
  };
};


export const updateCMS64Action = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.UPDATE_CMS64}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setUpdateresponse(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setUpdateresponse({ systemFailure: true }));
      });
  };
};

export const setUpdateresponse = (updateResponse) => {
  return {
    type: actionTypes.UPDATE_CMS64,
    updatePayload: updateResponse
  }
}

export const setAddresponse = (addResponse) => {
  return {
    type: actionTypes.CREATE_CMS64,
    createPayload: addResponse
  }
}

export const setSearchData = (searchData) => {
  return {
    type: actionTypes.SEARCH_CMS64,
    searchPayload: searchData
  }
}

export const setDropdownData = (dropdownData) => {
  return {
    type: actionTypes.DROPDOWNDATA,
    dropdownData: dropdownData
  };
};