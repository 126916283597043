/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../../Actions/accountReceivable/ARAdjustActionTypes';
const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.AR_ADJUST:
      return { saveResults: action.saveResults };
    case actionTypes.SYSTEM_ERROR:
      return { adjustError: action.adjustError };
    default: return state;
  }
};

export default reducer;
