/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './systemParameterActionTypes';
import * as serviceEndPoint from '../../../services/service';
import axios from 'axios';
import * as ERRORS from '../../../Components/SystemParameter/systemParameterConstants';
import { commonAPIHandler } from '../../../../../SharedModules/ServerErrorHandler';

export const resetSearchSystemParameters = () => {
  return {
    type: actionTypes.RESETDATA,
    resetData: []
  };
};

export const searchSystemParameterAction = (value, parameterNumberStartsWith) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SYSTEM_PARAMETER_MULTI_SEARCH_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(setSystemParameter(sendingResponse));
      })
      .catch(error => {
        let sendingRes = commonAPIHandler(error);
        dispatch(setSystemParameter(sendingRes));
      });
  };
};

export const searchSystemParameterAction9047 = (value, parameterNumberStartsWith) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SYSTEM_PARAMETER_MULTI_SEARCH_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(setSystemParameter9047(sendingResponse))
      })
      .catch(error => {
        let sendingRes = commonAPIHandler(error);
        dispatch(setSystemParameter9047(sendingRes))
      });
  };
};

export const setSystemParameterError = (data) => {
  return {
    type: actionTypes.SYSTEM_ERROR,
    systemError: data
  };
};

export const systemParameterRowClickAction = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.SYSTEM_PARAMETER_SEARCH_ENDPOINT}?paramNumber=${value.parameterNumber}&functionalArea=${value.functionalArea}`)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setSystemParameterRow(sendingResponse));
      })
      .catch(error => {
        let sendingRes = commonAPIHandler(error);
        dispatch(setSystemParameterRow(sendingRes));
      });
  };
};

export const updateSystemParameter = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.UPDATE_SYSTEMPARAMETER}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setUpdateSystemParameter(sendingResponse));

      }).catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setUpdateSystemParameter(sendingResponse));
      });
  };
};

export const addSystemParameter = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.ADD_SYSTEM_PARAMETER}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setAddSystemParameter(sendingResponse));

      }).catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setAddSystemParameter(sendingResponse));

      });
  };
};

export const setUpdateSystemParameter = (value) => {
  return {
    type: actionTypes.UPDATE_SYSTEM_PARAMETER,
    updateSystemParameter: value
  };
};

export const setAddSystemParameter = (value) => {
  return {
    type: actionTypes.ADD_SYSTEM_PARAMETER,
    addSystemParameter: value
  };
};

export const setSystemParameter = (systemParameters) => {
  return {
    type: actionTypes.SEARCH_SYSTEM_PARAMETER,
    systemParameters: systemParameters
  };
};

export const setSystemParameter9047 = (systemParameters) => {
  return {
    type: actionTypes.SEARCH_SYSTEM_PARAMETER9047,
    systemParameters9047: systemParameters
  };
};

export const setSystemParameterRow = (data) => {
  return {
    type: actionTypes.ROW_SEARCH_SYSTEM_PARAMETER,
    rowsearchsysparam: data
  };
};
