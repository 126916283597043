/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { NCPDPRowClickAction } from "../../Store/Actions/TextManagement/TextManagementActions";

import TableComponent from "../../../../SharedModules/Table/Table";

const headCells = [
  {
    id: "ncpdpRejectCode",
    disablePadding: false,
    label: "NCPDP Reject Code",
    width: 180,
    enableHyperLink: true,
    numeric: false,
    isVarChar: true
  },
  {
    id: "ncpdpRejectCodeText",
    disablePadding: false,
    label: "Text",
    isText: true
  }
];

function ProviderNoticeTable (props) {
  const [redirect, setRedirect] = React.useState(false);

  const dispatch = useDispatch();
  const onRowClick = values => dispatch(NCPDPRowClickAction(values));
  const payloadData = useSelector(
    state => state.appConfigState.textManagementState.payload
  );
  if (redirect === 1) {
    if (payloadData != null) {
      if (payloadData.length === 1) {
        props.history.push({
          pathname: "/TextUpdate",
          state: { row: payloadData[0], TextType: "NCPDP Rejection Codes" }
        });
      }
    }
  }
  const formatSearchCriteria = (row) => {
    return ({
      ncpdpRejectCode: row.ncpdpRejectCode ? row.ncpdpRejectCode : null,
      ncpdpRejectCodeText: row.ncpdpRejectCodeText
        ? row.ncpdpRejectCodeText
        : null,
      ncpdpRejectCodeStartsOrCopntains: null
    });
  };

  const editRow = row => event => {
    const searchCriteria = {
      ncpdpRejectCode: row.ncpdpRejectCode ? row.ncpdpRejectCode : null,
      ncpdpRejectCodeText: row.ncpdpRejectCodeText
        ? row.ncpdpRejectCodeText
        : null,
      ncpdpRejectCodeStartsOrCopntains: null
    };
    onRowClick(searchCriteria);
    let valuetoredirect = 0;
    valuetoredirect = valuetoredirect + 1;
    setRedirect(valuetoredirect);
  };

  const tableComp = (
    <TableComponent
      print={props.print}
      isSearch={true}
      headCells={headCells}
      pathTo='/TextUpdate?data='
      formatSearchCriteria={formatSearchCriteria}
      tableData={props.tableData ? props.tableData : []}
      onTableRowClick={editRow}
      defaultSortColumn={headCells[0].id}
    />
  );
  return tableComp;
}
export default withRouter(ProviderNoticeTable);
