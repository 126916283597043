/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import ErrorMessages from '../../../SharedModules/Errors/ErrorMessages';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../../SharedModules/TabPanel/TabPanel';
import SuccessMessages from '../../../SharedModules/Errors/SuccessMessage';
import TableComponent from '../../../SharedModules/Table/Table';
import 'date-fns';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as financialConstants from './FinancialReceiptConstants';
import { financialReceiptUpdateAction, financialReceiptSearchAction } from '../Store/Actions/FinancialReceipt/financialReceiptActions';
import { redirectSystemID, redirectReceiptFCN } from '../../../SharedModules/TabRedirections/TabRedirections';
import Footer from '../../../SharedModules/Layout/footer';
import numeral from 'numeral';
import { Link, Prompt } from 'react-router-dom';
import { searchSingleRecordFinancialEntity } from '../../FinancialEntities/Store/Actions/FinancialEntityActions';
import NativeDropDown from '../../../SharedModules/Dropdowns/NativeDropDown';
import { checkingDecimal } from '../../../SharedModules/CommonUtilities/commonUtilities';

const headCells = [
  {
    id: 'lob',
    disablePadding: false,
    label: 'TCN',
    enableHyperLink: true,
    isDate: false,
    isLink: true
  },
  {
    id: 'fcn',
    disablePadding: false,
    label: 'Disposition Applied Date',
    enableHyperLink: false,
    isDate: true
  },
  {
    id: 'reasonCode',
    disablePadding: false,
    label: 'Amount',
    enableHyperLink: false,
    isDate: false
  }
];

const tabledata = [];

function FinancialReceiptReturn (props) {
  const [value, setValue] = React.useState(0);
  const [searchData, setsearchData] = React.useState(
    props.location.state.searchData || {}
  );
  const dropdownData = props.location.state.dropdowns;
  const [payerType, setpayerType] = React.useState(
    props.location.state.searchData.entityType
  );
  const [atrError, setAtrError] = useState(false);
  const entityIDTypeCode = props.location.state?.searchData?.entityType;
  const payeeTypeIDDropdown = entityIDTypeCode === 'P' ? 'P1#P_ALT_ID_TY_CD'
      : entityIDTypeCode === 'M' ? 'F1#B_ALT_ID_TY_CD'
      : entityIDTypeCode === 'O' ? 'F1#G_ENTY_EXTL_ID_TY_CD'
      : entityIDTypeCode === 'TC' ? 'F1#DM_G_CARR_ENTY_ID_TY_CD' : null;
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [successMessages, setSuccessMessages] = React.useState([]);
  const [errorDesc, setError] = React.useState('');
  const payeeName = props.location.state.payeeName;
  const dispatch = useDispatch();
  const toPrintRef = useRef();

  const [allowNavigation, setAllowNavigation] = React.useState(false);

  // System Payee redirection starts
  const searchSingleFinancialEntityRes = useSelector(
    state => state.financialEntityState.singleSearchResults
  );
  const financialReceiptSearch = useSelector(
    (state) => state.financialReceiptState.financialReceiptSearch
  );
  const [redirect, setRedirect] = React.useState(0);
  React.useEffect(() => {
    if (
      redirect === 1 &&
      searchSingleFinancialEntityRes &&
      !searchSingleFinancialEntityRes.systemFailue
    ) {
      props.history.push({
        pathname: '/FinancialEntityUpdate',
        state: { searchSingleFinancialEntityRes }
      });
    }
  }, [searchSingleFinancialEntityRes]);
  // System Payee redirection ends
  const onSearchView = searchviewvalues =>
    dispatch(financialReceiptUpdateAction(searchviewvalues));
  const payloadViewData = useSelector(
    state => state.financialReceiptState.financialReceiptUpdate
  );

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const getReceiptData = () => {
    dispatch(financialReceiptSearchAction({
      fcn: searchData.fcn,
      fcnSearch: true
  }));
  };
  useEffect(() => {
    if (financialReceiptSearch?.enterpriseSearchresultVO?.searchResults[0]) {
      setsearchData({ ...searchData, ...financialReceiptSearch?.enterpriseSearchresultVO?.searchResults[0] });
    }
  }, [financialReceiptSearch]);
  useEffect(() => {
    if (props.location?.state?.searchData?.fcn) {
      getReceiptData();
    }
  }, []);
  useEffect(() => {
    if (payloadViewData && payloadViewData.systemFailure) {
      const tempErrorArray = [];
      tempErrorArray.push(financialConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(tempErrorArray);
      setSuccessMessages([]);
      setsearchData({
        ...searchData,
        returnedCheckEftAmount: numeral(
          searchData.returnedCheckEftAmount
        ).format('0,0.00')
      });
    }
    if (payloadViewData && payloadViewData.respcode === '01') {
      setAllowNavigation(false);

      const tempErrorArray = [];
      tempErrorArray.push(financialConstants.SUCCESSFULLY_SAVED);
      setSuccessMessages(tempErrorArray);
      seterrorMessages([]);
      setsearchData({
        ...searchData,
        returnedCheckEftAmount: numeral(
          searchData.returnedCheckEftAmount
        ).format('0,0.00')
      });
      getReceiptData();
    }
    if (payloadViewData && payloadViewData.respcode === '02') {
      const tempErrorArray = [];
      tempErrorArray.push(payloadViewData.errorMessages);
      seterrorMessages(tempErrorArray);
      setsearchData({
        ...searchData,
        returnedCheckEftAmount: numeral(
          searchData.returnedCheckEftAmount
        ).format('0,0.00')
      });
    }
  }, [payloadViewData]);

  const handleChange = name => event => {
    if (name === 'returnedCheckEftAmount') {
      if (event.target.value && event.target.value.includes('.')) {
        if (checkingDecimal(event.target.value)) {
          setsearchData({
            ...searchData,
            [name]: event.target.value
          });
        }
      } else {
      setsearchData({
        ...searchData,
        [name]: event.target.value
      });
    }
  } else {
    setsearchData({
      ...searchData,
      [name]: event.target.value
    });
    setAllowNavigation(true);
  }
};
  const masterSave = () => {
    const errorArray = [];
    seterrorMessages([]);
    setAtrError(false);
    setSuccessMessages([]);
    if (searchData.voidDate) {
      seterrorMessages([financialConstants.RETURN_ONLY_ONCE_ERROR]);
      return false;
    }

    if (searchData.returnedCheckEftAmount !== '') {
      if (
        searchData.returnedCheckEftAmount
          .toString()
          .replace(/,/g, '')
          .match(/^[-]?[0-9]{0,9}\.?[0-9]{0,2}$/) === null
      ) {
        errorArray.push(financialConstants.INVALID_DATE_FORMAT);
        setError(financialConstants.INVALID_DATE_FORMAT);
      } else {
        if (
          !(
            searchData.returnedCheckEftAmount
              .toString()
              .replace(/,/g, '')
              .match(/^[-]?[0-9]{10,13}\.?[0-9]{0,2}?$/) === null
          )
        ) {
          errorArray.push(financialConstants.INVALID_DATE_FORMAT);
          setError(financialConstants.INVALID_DATE_FORMAT);
        }
      }
    } else {
      searchData.returnedCheckEftAmount = '0.00';
    }
    if (errorArray.length === 0) {
      searchData.returnedCheckEftAmount = searchData.returnedCheckEftAmount.replace(
        /,/g,
        ''
      );
      onSearchView(searchData);
      setsearchData({
        ...searchData,
        returnedCheckEftAmount: numeral(
          searchData.returnedCheckEftAmount
        ).format('0,0.00')
      });
      seterrorMessages([]);
      setError('');
    } else {
      seterrorMessages(errorArray);
    }
  };

  const onLinkClick = () => () => {
    window.open('fcn', '_blank');
  };

  const reasonCode = dropdownData['F1#F_RSN_CD'].find(value => value.code === searchData.financialReason);

  return (
    <div className="pos-relative w-100 h-100">
      <Prompt
        when={allowNavigation}
        message={location =>
          `Are you sure you want to go to ${location.pathname}`
        }
      />

      <ErrorMessages errorMessages={errorMessages} />
      {errorMessages.length === 0 ? (
        <SuccessMessages successMessages={successMessages} />
      ) : null}
      <div className="tabs-container" ref={toPrintRef}>
        <div className="tab-header">
          <div className="tab-heading float-left">Receipt Return</div>
          <div className="float-right mt-2">
            <Button className="btn btn-success ml-1" onClick={masterSave}>
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        {/* form goes here */}
        <div className="tab-body">
          <div className="form-wrapper">
              <NativeDropDown
                id="standard-select-payeeType"
                value={payerType}
                label="Payee Type"
                disabled={true}
                name="payeeType"
                inputProps={{ maxLength: 3 }}
                placeholder=""
                InputLabelProps={{
                  shrink: true
                }}
                dropdownList={dropdownData ? dropdownData['G1#G_CMN_ENTY_TY_CD'] &&
              dropdownData['G1#G_CMN_ENTY_TY_CD'].map((item, index) => (
                <option key={index} value={item.code}>
                  {item.description}
                </option>
              )) : null}
              />
              <NativeDropDown
                id="standard-select-payeeIDTypeCode"
                value={props.location.state?.searchData?.entityIDTypeCode}
                label="Payee ID Type Code"
                name="payeeIDTypeCode"
                disabled={true}
                inputProps={{ maxLength: 3 }}
                SelectProps={{
                  MenuProps: {}
                }}
                placeholder=""
                InputLabelProps={{
                  shrink: true
                }}
                dropdownList={dropdownData ? dropdownData[payeeTypeIDDropdown] &&
              dropdownData[payeeTypeIDDropdown].map((item, index) => (
                <option key={index} value={item.code}>
                  {item.description}
                </option>
              )) : null}
              />
            <div className="mui-custom-form input-md">
              <TextField
                id="standard-select-payerID"
                value={searchData.entityID}
                label="Payee ID"
                name="payeeID"
                disabled={true}
                inputProps={{ maxLength: 15 }}
                SelectProps={{
                  MenuProps: {}
                }}
                placeholder=""
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>

            <div className="mui-custom-form input-md has-link">
              <TextField
                id="systemPayeeID"
                label="System Payee ID"
                name="systemPayeeID"
                disabled={true}
                inputProps={{ maxLength: 10 }}
                SelectProps={{
                  MenuProps: {}
                }}
                placeholder=""
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Link to={redirectSystemID(searchData)}>
                        {searchData.payeePayerID}
                      </Link>
                    </InputAdornment>
                  )
                }}
              ></TextField>
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="standard-select-payeeName"
                value={payeeName}
                label="Payee Name"
                name="payeeName"
                disabled={true}
                inputProps={{ maxLength: 60 }}
                SelectProps={{
                  MenuProps: {}
                }}
                placeholder=""
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>

              <NativeDropDown
                id="standard-select-memberIDType"
                value={searchData.memberIDTypeValue ? searchData.memberIDTypeValue : ''}
                label="Member ID Type"
                disabled={true}
                name="memberIDType"
                inputProps={{ maxLength: 3 }}
                SelectProps={{
                  MenuProps: {}
                }}
                placeholder=""
                InputLabelProps={{
                  shrink: true
                }}
                dropdownList = {dropdownData
                    ? dropdownData['Member#B_ALT_ID_TY_CD'] && dropdownData['Member#B_ALT_ID_TY_CD'].map(option => (
                      <option key={option.code} value={option.code}>
                        {option.description}
                      </option>
                    ))
                    : null}
              />
            <div className="mui-custom-form input-md">
              <TextField
                id="standard-select-memberID"
                value={searchData.memberID}
                label="Member ID"
                name="memberID"
                disabled={true}
                inputProps={{ maxLength: 15 }}
                SelectProps={{
                  MenuProps: {}
                }}
                placeholder=""
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
            <div className="mui-custom-form input-md">
              <TextField
                id="standard-select-memberName"
                value={searchData.memberName}
                disabled={true}
                label="Member Name"
                name="memberName"
                inputProps={{ maxLength: 120 }}
                SelectProps={{
                  MenuProps: {}
                }}
                placeholder=""
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
          </div>
          <div className="tab-panelbody">
            <div className="tab-holder">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                >
                  <Tab label="Check Details" />
                  <Tab label="History" />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                {value === 0 && (
                  <div className="tab-holder p-0">
                    <form autoComplete="off">
                      <div className="form-wrapper">
                        <fieldset className="custom-fieldset no-padding  mb-3 mt-2 w-100">
                          <legend>Returned Check</legend>
                          <div className="form-wrapper horizontal-wrapper">
                            <div className="mui-custom-form with-select input-md has-link">
                              <TextField
                                id="return-systemPayeeID"
                                label="FCN"
                                name="systemPayeeID"
                                disabled={true}
                                inputProps={{ maxLength: 14 }}
                                SelectProps={{
                                  MenuProps: {}
                                }}
                                placeholder=""
                                InputLabelProps={{
                                  shrink: true
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Link to={redirectReceiptFCN(searchData.fcn)}>
                                        {searchData.fcn}
                                      </Link>
                                    </InputAdornment>
                                  )
                                }}
                              ></TextField>
                            </div>
                            <div className="mui-custom-form with-select input-md">
                              <TextField
                                id="return-EFTNumber"
                                value={searchData.returnedCheckEftNumber}
                                label="Returned Check/EFT Number"
                                name="systemPayeeID"
                                onChange={handleChange(
                                  'returnedCheckEftNumber'
                                )}
                                disabled={searchData.voidDate}
                                inputProps={{ maxLength: 11 }}
                                SelectProps={{
                                  MenuProps: {}
                                }}
                                placeholder=""
                                InputLabelProps={{
                                  shrink: true
                                }}
                              ></TextField>
                            </div>
                            <div className="mui-custom-form with-select input-md">
                              <TextField
                                id="return-EFTAmount"
                                value={searchData.returnedCheckEftAmount}
                                label="Returned Check/EFT Amount"
                                name="systemPayeeID"
                                disabled={searchData.voidDate}
                                inputProps={{ maxLength: 12 }}
                                onChange={handleChange(
                                  'returnedCheckEftAmount'
                                )}
                                SelectProps={{
                                  MenuProps: {}
                                }}
                                placeholder=""
                                InputLabelProps={{
                                  shrink: true
                                }}
                                onInput={e => {
                                  e.target.value = e.target.value.replace(
                                    /[a-zA-Z!@#$%^&*()_+\-=[\]{};':"\\|,<>/?~ ]/,
                                    ''
                                  );
                                }}
                                onBlur={event => {
                                  setsearchData({
                                    ...searchData,
                                    returnedCheckEftAmount: numeral(
                                      event.target.value
                                    ).format('0,0.00')
                                  });
                                }}
                                onFocus={() => {
                                  setsearchData({
                                    ...searchData,
                                    returnedCheckEftAmount: numeral(searchData.returnedCheckEftAmount
                                    ).format('0.00')
                                  });
                                }}
                                helperText={errorDesc !== '' ? errorDesc : null}
                                error={errorDesc !== '' ? errorDesc : null}
                              ></TextField>
                            </div>
                            <div className="mui-custom-form input-md">
                              <TextField
                                id="return-reasonCode"
                                value={reasonCode !== 'undefined' ? reasonCode.description : null}
                                label="Financial Reason Code"
                                name="systemPayeeID"
                                disabled={true}
                                inputProps={{ maxLength: 3 }}
                                SelectProps={{
                                  MenuProps: {}
                                }}
                                placeholder=""
                                InputLabelProps={{
                                  shrink: true
                                }}
                                title={searchData.FinacialReasoncodeLD}
                              ></TextField>
                            </div>
                            <div className="mui-custom-form with-select input-md">
                              <TextField
                                id="return-estdDate"
                                value={searchData.establishedDate}
                                label="Established Date"
                                name="systemPayeeID"
                                disabled={true}
                                inputProps={{ maxLength: 10 }}
                                SelectProps={{
                                  MenuProps: {}
                                }}
                                placeholder=""
                                InputLabelProps={{
                                  shrink: true
                                }}
                              ></TextField>
                            </div>
                            <div className="mui-custom-form with-select input-md">
                              <TextField
                                id='ATRnumber'
                                fullWidth
                                label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
                                placeholder=""
                                value={searchData?.atrNumber}
                                disabled
                                inputProps={{ maxlength: 18 }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              ></TextField>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div className="row mb-2">
                        <div className="col-8" style={{ maxWidth: '100%' }}>
                          <fieldset className="custom-fieldset no-padding">
                            <legend>Voided Claims</legend>
                            <div className="form-wrapper horizontal-wrapper">
                              <div className="mui-custom-form with-select input-md override-width-45">
                                <TextField
                                  id="return-MassAdj"
                                  fullWidth
                                  label="Number of Claims Mass Adjusted"
                                  name="systemPayeeID"
                                  disabled={true}
                                  inputProps={{ maxLength: 10 }}
                                  SelectProps={{
                                    MenuProps: {}
                                  }}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                ></TextField>
                              </div>
                              <div
                                className="mui-custom-form with-select input-md override-width-45"
                                style={{ marginLeft: '10px' }}
                              >
                                <TextField
                                  id="return-Dispositioned"
                                  fullWidth
                                  label="Number of Claims Dispositioned"
                                  name="systemPayeeID"
                                  disabled={true}
                                  inputProps={{ maxLength: 10 }}
                                  SelectProps={{
                                    MenuProps: {}
                                  }}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                ></TextField>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        <div className="col-4" style={{ maxWidth: '100%' }}>
                          <fieldset className="custom-fieldset  no-padding">
                            <legend>Previously Voided/Adjusted Claims:</legend>
                            <div className="form-wrapper horizontal-wrapper">
                              <div className="mui-custom-form with-select override-width-45">
                                <TextField
                                  id="return-void-number"
                                  fullWidth
                                  label="Number"
                                  name="systemPayeeID"
                                  disabled={true}
                                  inputProps={{ maxLength: 10 }}
                                  SelectProps={{
                                    MenuProps: {}
                                  }}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                ></TextField>
                              </div>
                              <div className="mui-custom-form with-select override-width-45">
                                <TextField
                                  id="return-void-amount"
                                  fullWidth
                                  label="Amount"
                                  name="systemPayeeID"
                                  disabled={true}
                                  inputProps={{ maxLength: 11 }}
                                  SelectProps={{
                                    MenuProps: {}
                                  }}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                ></TextField>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </form>
                  </div>
                )}
              </TabPanel>

              <TabPanel value={value} index={1}>
                {value === 1 && (
                  <>
                    <form autoComplete="off">
                      <div className="form-wrapper">
                        <div className="w-100">
                          <div className="form-wrapper">
                            <div className="mui-custom-form with-select override-width-22">
                              <TextField
                                id="EFT-Number"
                                fullWidth
                                value={searchData.checkNumber}
                                label="Returned Check/ETF Number"
                                name="systemPayeeID"
                                disabled={true}
                                inputProps={{ maxLength: 30 }}
                                SelectProps={{
                                  MenuProps: {}
                                }}
                                placeholder=""
                                InputLabelProps={{
                                  shrink: true
                                }}
                              ></TextField>
                            </div>
                            <div
                              className="mui-custom-form with-select input-md override-width-22"
                              style={{ marginLeft: '20px' }}
                            >
                              <TextField
                                id="EFT-Amount"
                                fullWidth
                                value={searchData.originalAmount}
                                label="Returned Check/EFT Amount"
                                name="systemPayeeID"
                                disabled={true}
                                inputProps={{ maxLength: 11 }}
                                SelectProps={{
                                  MenuProps: {}
                                }}
                                placeholder=""
                                InputLabelProps={{
                                  shrink: true
                                }}
                              ></TextField>
                            </div>
                          </div>
                        </div>
                        <div className="col-12" style={{ maxWidth: '100%' }}>
                          <fieldset className="custom-fieldset no-padding no-border">
                            <legend className="no-margin">Voided Claims</legend>
                            <div className="form-wrapper horizontal-wrapper">
                              <div className="table-no-wrap w-100">
                                <TableComponent
                                  headCells={headCells}
                                  tableData={tabledata}
                                  dropdownData={props.dropdownData}
                                  onTableRowClick={() => { }}
                                  defaultSortColumn={''}
                                  onLinkClick={onLinkClick}
                                  isSearch={true}
                                />
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </fieldset>
                        </div>
                      </div>
                    </form>
                  </>
                )}
              </TabPanel>

            </div>
          </div>
        </div>
        <Footer print />
      </div>
    </div>
  );
}

export default withRouter(FinancialReceiptReturn);
