/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const SEARCH_FINANCIAL_INQUIRY = 'SEARCH_FINANCIAL_INQUIRY';
export const DROPDOWNDATA = 'DROPDOWNDATA';
export const RESETDATA = 'RESETDATA';
export const GET_PAYEEIDTYPECODE_ON_PAYEETYPE = 'GET_PAYEEIDTYPECODE_ON_PAYEETYPE';
export const FINANCIAL_CLAIM_SUMMARY_DETAILS = 'FINANCIAL_CLAIM_SUMMARY_DETAILS';
