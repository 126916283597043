/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const APP_CONFIG_DROPDOWNS = 'APP_CONFIG_DROPDOWNS';
export const DATA_ELEMENT_NAME_DROPDOWNS = 'DATA_ELEMENT_NAME_DROPDOWNS';
export const DATA_ELEMENT_MAP_NAME_DROPDOWNS = 'DATA_ELEMENT_MAP_NAME_DROPDOWNS';
export const MODIFIER_DROPDOWNS = 'MODIFIER_DROPDOWNS';
export const AUDIT_LOG_TABLE = "AUDIT_LOG_TABLE";
export const FOCUS_CHECK = 'FOCUS_CHECK';
