/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import EnhancedTableHead from "../../../SharedModules/Table/EnhancedTableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../../SharedModules/Table/TablePagination";
import {
  stableSort,
  getSorting
} from "../../../SharedModules/Table/TableSort";

import Moment from "react-moment";
import Checkbox from "@material-ui/core/Checkbox";
import { generateUUID } from "../../../SharedModules/DateUtilities/DateUtilities";

const headCells = [
  {
    id: "lockBoxBeginDate",
    dataColumnName: "lockBoxBeginDate",
    numeric: false,
    disablePadding: true,
    enableHyperLink: true,
    isDate: true,
    label: "Begin Date"
  },
  {
    id: "lockBoxEndDate",
    dataColumnName: "lockBoxEndDate",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "End Date"
  },
  {
    id: "lockBoxNumber",
    dataColumnName: "lockBoxNumber",
    numeric: false,
    disablePadding: false,
    label: "LockBox Number"
  },
  {
    id: "lockBoxName",
    dataColumnName: "lockBoxName",
    numeric: false,
    disablePadding: false,
    label: "LockBox Name"
  }
];

const headCellsWithoutAuditLog = [
  {
    id: "lockBoxBeginDate",
    dataColumnName: "lockBoxBeginDate",
    numeric: false,
    disablePadding: true,
    enableHyperLink: true,
    isDate: true,
    label: "Begin Date"
  },
  {
    id: "lockBoxEndDate",
    dataColumnName: "lockBoxEndDate",
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: "End Date"
  },
  {
    id: "lockBoxNumber",
    dataColumnName: "lockBoxNumber",
    numeric: false,
    disablePadding: false,
    label: "LockBox Number"
  },
  {
    id: "lockBoxName",
    dataColumnName: "lockBoxName",
    numeric: false,
    disablePadding: false,
    label: "LockBox Name"
  }
];

export default function LockBoxTable (props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(headCells[0].id);
  const [headCellState, setHeadCellState] = React.useState(props.addPage ? headCellsWithoutAuditLog : headCells);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [selectedID, setSelectedID] = React.useState(null);

  useEffect(() => {
    setPage(0);
    setSelected([]);
  }, [props.tableData]);

  useEffect(() => {
    props.rowDeleteLockBox(selected);
  }, [selected]);

  useEffect(() => {
    setSelected([]);
    let countWithID = 0;
    props.tableData.map((values) => {
      if (values.id) {
        countWithID++;
      }
    });
    if (countWithID > 0) {
      setShowHeaderCheckBox(true);
    } else {
      setShowHeaderCheckBox(false);
    }
  }, [props.tableData]);
  const [showHeaderCheckBox, setShowHeaderCheckBox] = React.useState(false);
  useEffect(() => {
    let countWithID = 0;
    props.tableData.map((values) => {
      if (values.id) {
        countWithID++;
      }
    });
    if (countWithID > 0) {
      setShowHeaderCheckBox(true);
    } else {
      setShowHeaderCheckBox(false);
    }
  }, [props.tableData.length]);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = [];
      for (var i = 0; i < props.tableData.length; i++) {
        if (props.tableData[i].id !== undefined && !props.tableData[i].lockBoxKey) {
          newSelecteds.push(props.tableData[i].id);
        } else {
          newSelecteds.push(null);
        }
      }
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
    } else if (selectedIndex === selected.length - 1) {
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(

      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatTableCell = (row, name, index, labelId, rowIndex) => {
    const rowclick = props.handleLockBoxTableAdd
      ? props.handleLockBoxTableAdd(row, rowIndex)
      : null;
    if (name.enableHyperLink && name.isDate) {
      return (
        <TableCell
          onClick={rowclick}
          style={{ width: name.width }}
          id={labelId}
          scope="row"
          padding="default"
          className="has-link"
        >
          <a>
            <Moment
              format="MM/DD/YYYY"
              date={
                row[name.dataColumnName] !== undefined
                  ? row[name.dataColumnName]
                  : row[name.id]
              }
            />
          </a>
        </TableCell>
      );
    }
    if (name.isDate) {
      return (
        <TableCell onClick={rowclick} style={{ width: name.width }}>
          <Moment
            format="MM/DD/YYYY"
            date={
              row[name.dataColumnName] !== undefined
                ? row[name.dataColumnName]
                : row[name.id]
            }
          />
        </TableCell>
      );
    }
    if (name.isRadio) {
      if (!name.showRadio) {
        if (!row.audit_flag) {
          if (!row.id && !row.uuId) {
            return (
              <TableCell key={index || '21' + labelId || '21'} style={{ width: name.width }}>
                <label aria-hidden={true} hidden htmlFor={props.uniqueKey ? props.print ? row[props.uniqueKey] + 'onScreen' : row[props.uniqueKey] : (index || '21' + labelId || '21')}>Audit Log</label>
                <input
                  name="auditRadio"
                  type='radio'
                  className='radio-table'
                  value={false}
                  id={props.uniqueKey ? props.print ? row[props.uniqueKey] + 'onScreen' : row[props.uniqueKey] : (index || '21' + labelId || '21')}
                  checked={(!props.close) && selectedID === row[props.uniqueKey]}
                  onClick={() => {
                    if (props.uniqueKey) {
                      setSelectedID(row[props.uniqueKey]);
                      if (props.setClose) {
                        props.setClose(false);
                      }
                    }
                    if (props.onClickAuditDialogLog) {
                      props.onClickAuditDialogLog(row);
                    }
                  }}
                />
              </TableCell >
            );
          }
        }
      } else {
        if (!row.audit_flag) {
          return (
            <TableCell key={index || '21' + labelId || '21'} style={{ width: name.width }}>
              <label aria-hidden={true} hidden htmlFor={props.uniqueKey ? props.print ? row[props.uniqueKey] + 'onScreen' : (index || '21' + labelId || '21') : row[props.uniqueKey]}>Audit Log</label>
              <input
                name="auditRadio"
                type='radio'
                className='radio-table'
                value={false}
                id={props.uniqueKey ? props.print ? row[props.uniqueKey] + 'onScreen' : row[props.uniqueKey] : (index || '21' + labelId || '21')}
                checked={(!props.close) && selectedID === row[props.uniqueKey]}
                onClick={() => {
                  if (props.uniqueKey) {
                    setSelectedID(row[props.uniqueKey]);
                    if (props.setClose) {
                      props.setClose(false);
                    }
                  }
                  if (props.onClickAuditDialogLog) {
                    props.onClickAuditDialogLog(row);
                  }
                }}
              />
            </TableCell >
          );
        }
      }
    }
    if (name.isDropDown) {
      return (
        <TableCell onClick={rowclick} style={{ width: name.width }}>
          {row[name.dataColumnName] !== "Please Select One"
            ? row[name.dataColumnName]
            : ""}
        </TableCell>
      );
    } else {
      return (
        <TableCell onClick={rowclick} style={{ width: name.width }}>
          {row[name.dataColumnName]}
        </TableCell>
      );
    }
  };

  const isSelected = name => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, props.tableData.length - page * rowsPerPage);

  return (
    <div className={"pos-relative"}>

      <div className="table-wrapper">
        <Table
          className={`customDataTable with-link ${
            props.fixedTable ? "fixedTable" : ""
            }`}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={props.tableData.length}
            headCells={headCellState}
            isSearch={false}
            showHeaderCheckBox={showHeaderCheckBox}
          />
          <TableBody>
            {stableSort(props.tableData, getSorting(order, orderBy, false))
              .slice(
                ...([page * rowsPerPage, page * rowsPerPage + rowsPerPage])
              )
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      {row.lockBoxKey ? null : (
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={event =>
                            handleClick(
                              event,
                              row.lockBoxKey ? row.lockBoxKey : row.id
                            )
                          }
                        />
                      )}
                    </TableCell>
                    <TableCell
                      id={labelId}
                      hidden={true}
                      scope="row"
                      padding="default"
                    >
                      {generateUUID()}
                    </TableCell>
                    {row.id ? (
                      <TableCell
                        id={labelId}
                        hidden={true}
                        scope="row"
                        padding="default"
                      >
                        {row.id}
                      </TableCell>
                    ) : null}
                    {headCellState.map((name, colindex) =>
                      formatTableCell(row, name, colindex, labelId, index)
                    )}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={headCellState.length} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell className="table-pagination">
                Page: <span>{page + 1}</span>
              </TableCell>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={headCellState.length}
                count={props.tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  );
}
