/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const START_WITH_OR_CONTIANS = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const NO_RECORD = "No records found for the search criteria entered.";
export const VALUE = "Value is required.";
export const TYPE = "Type is required.";
export const RETRY_TRANSACTION =  "Service is under maintenance, please try again later.";
export const BEGIN_DATE_GREATER_ERROR = 'Criteria Begin Date Should be greater than or equal to the Current System Date.';
export const BEGIN_DATE_IS_REQUIRED = 'Begin Date is required.';
export const BEGIN_DATE_IS_INVALID = 'Begin Date is Invalid.';
export const END_DATE_IS_REQUIRED = 'End Date is required.';
export const END_DATE_IS_INVALID = 'End Date is Invalid.';
export const END_DATE_GREATER_ERROR = 'Criteria End Date Should be greater than or equal to the Current System Date.';
export const BEGIN_DT_END_DT_ERROR = 'The Criteria End Date cannot be less than the Criteria Begin Date.'
export const SAVESUCCESS = "System successfully saved the Information."
export const DELETESUCCESS = "System successfully deleted the Information."
export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const PAYEE_NUM_INVALID = "Payee not found (or) doesn't exist.";
export const ATLEAST_ONE_ROW = "Add atleast one criteria at detail level.";
export const PAYEE_NUM_DUPLICATE = "Payee already exist in the criteria.";
export const REASON_CODES_DUPLICATE = "Reason Code already exist in the criteria. ";
export const HAS_BEEN_PROCESSED_YES = "Payout Criteria Record Cannot Be Deleted Due to the Payout Payment Criteria Dates Already Used by the Payment Cycle.";
