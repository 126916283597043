/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useSelector, useEffect } from 'react';
import ChildTableComponent from './ChildTableComponent';
import { withRouter } from 'react-router';


function PriorStateFiscalYear (props) {
  const ClaimsHeadCells = [
    { id: 'categoryCode', disablePadding: false, label: 'Status', numeric: false, width: 80 },
    { id: 'numberOfClaims', disablePadding: false, label: 'No of Claims', numeric: false, width: 80 },
    { id: 'totalClaimAmount', disablePadding: false, label: 'Amount', numeric: true, width: 80 }
  ];

  const TransactionHeadCells = [
    { id: 'categoryCode', disablePadding: false, label: 'Financial activity', numeric: false, width: 80 },
    { id: 'numberOfClaims', disablePadding: false, label: 'No of Transactions', numeric: false, width: 80 },
    { id: 'totalClaimAmount', disablePadding: false, label: 'Amount', numeric: true, width: 80 }
  ];

  return (
    <div className="pt-2">
      <div className="row">
        <div className="col-6" style={{ marginTop: '10px' }}>
          <fieldset className="custom-fieldset ">
            <legend>{props.fiscalYear-1} YTD</legend>
            <div>
              <h4 className="sub-header">Claims</h4>
              <ChildTableComponent tableData={props.summaryDetails ? props.summaryDetails.priorFirstFiscalYTDList : []} headCells={ClaimsHeadCells} />
            </div>
            <h4 className="sub-header  mt-3">Financial Transactions</h4>
            <div>
              <ChildTableComponent tableData={props.summaryDetails ? props.summaryDetails.priorFirstFiscalYTDSubList : []} headCells={TransactionHeadCells} />
            </div>
          </fieldset>
        </div>
        <div className="col-6" style={{ marginTop: '10px' }}>
          <fieldset className="custom-fieldset ">
            <legend>{props.fiscalYear-2} YTD</legend>
            <div>
              <h4 className="sub-header">Claims</h4>
              <ChildTableComponent tableData={props.summaryDetails ? props.summaryDetails.priorSecondFiscalYTDList : []} headCells={ClaimsHeadCells} />
            </div>
            <h4 className="sub-header  mt-3">Financial Transactions</h4>
            <div>
              <ChildTableComponent tableData={props.summaryDetails ? props.summaryDetails.priorSecondFiscalYTDSubList : []} headCells={TransactionHeadCells} />
            </div>
          </fieldset>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <fieldset className="custom-fieldset ">
            <legend>{props.fiscalYear-3} YTD</legend>
            <div>
              <h4 className="sub-header">Claims</h4>
              <ChildTableComponent tableData={props.summaryDetails ? props.summaryDetails.priorThirdFiscalYTDList : []} headCells={ClaimsHeadCells} />
            </div>

            <h4 className="sub-header  mt-3">Financial Transactions</h4>
            <div>
              <ChildTableComponent tableData={props.summaryDetails ? props.summaryDetails.priorThirdFiscalYTDSubList : []} headCells={TransactionHeadCells} />
            </div>
          </fieldset>
        </div>
        <div className="col-6">
          <fieldset className="custom-fieldset ">
            <legend>{props.fiscalYear-4} YTD</legend>
            <div>
              <h4 className="sub-header">Claims</h4>
            </div>
            <ChildTableComponent tableData={props.summaryDetails ? props.summaryDetails.priorFourthFiscalYTDList : []} headCells={ClaimsHeadCells} />
            <h4 className="sub-header  mt-3">Financial Transactions</h4>
            <div>
              <ChildTableComponent tableData={props.summaryDetails ? props.summaryDetails.priorFourthFiscalYTDSubList : []} headCells={TransactionHeadCells} />
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
}
export default withRouter((PriorStateFiscalYear));
