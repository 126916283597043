/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const SEARCH_ACCOUNT_RECEIVABLE = 'SEARCH_ACCOUNT_RECEIVABLE';
export const PAYEE_SEARCH_ACCOUNT_RECEIVABLE = 'PAYEE_SEARCH_ACCOUNT_RECEIVABLE';
export const PAYEE_OUTSTANDING_SEARCH_ACCOUNT_RECEIVABLE = 'PAYEE_OUTSTANDING_SEARCH_ACCOUNT_RECEIVABLE';
export const ROW_SEARCH_ACCOUNT_RECEIVABLE = 'ROW_SEARCH_ACCOUNT_RECEIVABLE';
export const RESETDATA = 'RESETDATA';
export const PAYOUT_DETAILS='PAYOUT_DETAILS'
export const PAYEE_ID_TYPE_DROPDOWN = 'PAYEE_ID_TYPE_DROPDOWN';
export const MCN_SEARCH_ACCOUNT_RECEIVABLE = 'MCN_SEARCH_ACCOUNT_RECEIVABLE';
export const GOTONEXTPAGE = 'GOTONEXTPAGE';
export const GROSS_LEVEL_ADD = 'GROSS_LEVEL_ADD';
export const LIEN_LEVY_ADD = 'LIEN_LEVY_ADD';
export const LIEN_LEVY_UPDATE = 'LIEN_LEVY_UPDATE';
export const SEARCH_CLAIMS_RECEIVABLE = 'SEARCH_CLAIMS_RECEIVABLE';
export const RECOUPMENT_CODE_DROPDOWN = 'RECOUPMENT_CODE_DROPDOWN';
export const FREQUENCY_DROPDOWN = 'FREQUENCY_DROPDOWN';
export const CLAIMS_RECEIVABLE_SAVE = 'CLAIMS_RECEIVABLE_SAVE';
export const LIEN_LEVY_HOLDER_TYPE_ID_DROPDOWN = 'LIEN_LEVY_HOLDER_TYPE_ID_DROPDOWN';
export const AR_AGING_DAYS = 'AR_AGING_DAYS';
export const ARTRANSFER_ELIGIBLE = 'ARTRANSFER_ELIGIBLE';
export const ARREVERSE_ELIGIBLE = 'ARREVERSE_ELIGIBLE';
export const ARADJUST_ELIGIBLE = 'ARADJUST_ELIGIBLE';
export const SAVE_REVERSE = 'SAVE_REVERSE';
export const GROSSLEVEL_EDIT = 'GROSSLEVEL_EDIT';
export const ADVANCE_PAYMENT_UPDATE = 'ADVANCE_PAYMENT_UPDATE';
export const FETCH_WRITE_OFF = 'FETCH_WRITE_OFF';
export const FETCH_WRITE_OFF_RESTABLISHMENT = 'FETCH_WRITE_OFF_RESTABLISHMENT';
export const FETCH_RECOUPMENT_DETAILS = 'FETCH_RECOUPMENT_DETAILS';
export const DROPDOWNDATA = 'DROPDOWNDATA';
export const SUB_DROPDOWNDATA = 'SUB_DROPDOWNDATA';
export const REASON_CODE_MAP = 'REASON_CODE_MAP';
export const VALIDATE_LIEN_HOLDER_DETAILS = 'VALIDATE_LIEN_HOLDER_DETAILS';
export const FINANCIALENTITY_PAYEE_SEARCH_DATA = 'FINANCIALENTITY_PAYEE_SEARCH_DATA';
export const BULK_VIEW_TRANSFER = 'BULK_VIEW_TRANSFER';
export const BULK_VIEW_TRANSFER_ERROR = 'BULK_VIEW_TRANSFER_ERROR';
export const SAVE_BULK_TRANSFER = 'SAVE_BULK_TRANSFER';
export const BULK_VIEW_REVERSE = 'BULK_VIEW_REVERSE';
export const BULK_VIEW_REVERSE_ERROR = 'BULK_VIEW_REVERSE_ERROR';
export const SAVE_BULK_REVERSE = 'SAVE_BULK_REVERSE';
export const BULK_VIEW_WRITEOFF = 'BULK_VIEW_WRITEOFF';
export const BULK_VIEW_WRITEOFF_ERROR = 'BULK_VIEW_WRITEOFF_ERROR';
export const SAVE_BULK_VIEW_WRITE_OFF = 'SAVE_BULK_VIEW_WRITE_OFF';
export const BULK_VIEW_PAYEE_STATUS = 'BULK_VIEW_PAYEE_STATUS';
export const BULK_VIEW_PAYEE_STATUS_ERROR = 'BULK_VIEW_PAYEE_STATUS_ERROR';
export const SAVE_BULK_VIEW_PAYEE_STATUS = 'SAVE_BULK_VIEW_PAYEE_STATUS';
export const BULK_VIEW_WRITE_OFF_REEST = 'BULK_VIEW_WRITE_OFF_REEST';
export const BULK_VIEW_WRITE_OFF_REEST_ERROR = 'BULK_VIEW_WRITE_OFF_REEST_ERROR';
export const SAVE_BULK_WRITE_OFF_REEST = 'SAVE_BULK_WRITE_OFF_REEST';