/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const BULK_TRANFER_HEADER = 'Account Receivable Change Payee Status';
export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const SAVED_SUCCESS = 'System Successfully saved information.';
export const PAYEE_STATUS_REQUIRED = 'Payee Status is Required.';
export const TRANSACTION_NUMBER_FORMAT = 'should not contain spaces or special characters.';