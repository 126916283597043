/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/CorrectionSearchActionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SEARCH_CORRECTION_1099:
      return { search1099CorrectionData: action.searchData };
    case actionTypes.SEARCH_CORRECTION_APPROVAL:
      return { searchCorrectionApprovalData: action.searchCorrectionApprovalData };
    case actionTypes.CORRECTION_DROPDOWNS_ACTION:
      return { dropdowns: action.dropdowns };
    case actionTypes.CORRECTION_DETAIL_LIST_ACTION:
      return { correctionDeatilListData: action.correctionDeatilListData };
    case actionTypes.CORRECTION_DETAIL_VIEW_ACTION:
      return { correctionDeatilViewData: action.correctionDeatilViewData };
    case actionTypes.CORRECTION_DETAIL_UPDATE_ACTION:
      return { correctionDetailUpdateResponse: action.correctionDetailUpdateResponse };
    case actionTypes.CORRECTION_APPROVAL_VALIDATE_ACTION:
      return { correctionApprovalValidateResponse: action.correctionApprovalValidateResponse };
    case actionTypes.CORRECTION_APPROVAL_UPDATE_ACTION:
      return { correctionApprovalUpdateResponse: action.correctionApprovalUpdateResponse };
    case actionTypes.CORRECTION_1099_SEARCH_RESULTS_RESET:
      return action.resetData;
    case actionTypes.AUDIT_LOG_TABLE:
      return { ...state, auditData: action.payload };
    default: return state;
  }
};

export default reducer;
