/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Bootstrap, { Button } from 'react-bootstrap';
import { withRouter } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as ARSearchConstants from './ARSearchConstants';
import ErrorMessages from '../../../../../SharedModules/Errors/ErrorMessages';
import { ARTransferEligibility, ARReverseEligibility } from '../../../Store/Actions/accountReceivable/accountReceivableActions';

function FCNInquiry (props) {
  const dispatch = useDispatch();
  const [errorMessages, setErrorMessages] = React.useState([]);
  const checkARTransfer = (values) => { dispatch(ARTransferEligibility(values)); };
  const transferDetails = useSelector(state => state.accountReceivableState.arTransferEligibleDetails);
  const checkARREverse = (values) => { dispatch(ARReverseEligibility(values)); };
  const reverseDetails = useSelector(state => state.accountReceivableState.arReverseEligibleDetails);
  const claimsData = props.location.state.payload;
  const [values, setValues] = React.useState({
    lob: '',
    payeeType: '',
    payeeIDTypeCode: '',
    payeeID: '',
    payeeName: '',
    activityType: '',
    reasonCode: '',
    sysPayeeId: ''
  });

  useEffect(() => {
    setValues({
      lob: claimsData.lob ? claimsData.lob : '',
      payeeType: claimsData.payeePayerTypeCode ? claimsData.payeePayerTypeCode : '',
      payeeIDTypeCode: claimsData.payeePayerIDTypeCode ? claimsData.payeePayerIDTypeCode : '',
      payeeID: claimsData.payeePayerID ? claimsData.payeePayerID : '',
      payeeName: claimsData.payeePayerNameFromDB ? claimsData.payeePayerNameFromDB : '',
      activityType: claimsData.activityTypeCode ? claimsData.activityTypeCode : '',
      reasonCode: claimsData.accountReceivableReasonCode ? claimsData.accountReceivableReasonCode : '',
      sysPayeeId: claimsData.sysID ? claimsData.sysID : ''
    });
  }, [claimsData]);
  useEffect(() => {
    if (transferDetails && transferDetails.transferVO !== null && transferDetails.transferVO !== undefined) {
      claimsData.transferVO = transferDetails.transferVO;
      props.history.push({
        pathname: '/ARTransfer',
        state: { claimsData }
      });
    }
    if (transferDetails && transferDetails.SystemError === true) {
      setErrorMessages([ARSearchConstants.TRANSACTION_ERROR]);
    }
  }, [transferDetails]);
  useEffect(() => {
    if (reverseDetails && reverseDetails.reversalVO !== null && reverseDetails.reversalVO !== undefined) {
      claimsData.reversalVO = reverseDetails.reversalVO;
      props.history.push({
        pathname: '/ARReverse',
        state: { claimsData }
      });
    }
    if (reverseDetails && reverseDetails.reversalVO === null) {
      setErrorMessages([ARSearchConstants.REVERSE_ERROR]);
    }
    if (reverseDetails && reverseDetails.SystemError === true) {
      setErrorMessages([ARSearchConstants.TRANSACTION_ERROR]);
    }
  }, [reverseDetails]);
  function ARReverse () {
    claimsData.recoupmentVO.startDateStr = moment().format('L');
    claimsData.accountReceivableReasonCode = claimsData.accountReceivableReasonCode.split('-')[0];
    claimsData.payeePayerTypeCode = claimsData.payeePayerTypeCode.split('-')[0];
    claimsData.activityTypeCode = claimsData.activityTypeCode.split('-')[0];
    checkARREverse(claimsData);
  }
  function ARTransfer () {
    claimsData.recoupmentVO.startDateStr = moment().format('L');
    claimsData.accountReceivableReasonCode = claimsData.accountReceivableReasonCode.split('-')[0];
    claimsData.payeePayerTypeCode = claimsData.payeePayerTypeCode.split('-')[0];
    claimsData.activityTypeCode = claimsData.activityTypeCode.split('-')[0];
    checkARTransfer(claimsData);
  };

  return (
    <div>
      <ErrorMessages errorMessages={errorMessages}/>
      <div align="right" className="nav-menu" >
        <span><a>Adjust</a></span>
        <span><a onClick={ARReverse}>&nbsp;&nbsp;Reverse</a></span>
        <span><a onClick={ARTransfer}>&nbsp;&nbsp;Transfer</a></span>
        <span><a>&nbsp;&nbsp;Write-Off</a></span>
        <span><a>&nbsp;&nbsp;Write-Off Re-establish</a></span>
      </div>
      <div className="tabs-container">
        <div className="tab-header">
          
          <div className="tab-heading float-left">
                Gross Level Detail
          </div>
          <div className="float-right mt-2 mr-2">
            <Button color="primary" className='btn btn-primary ml-1'>
                    Print
            </Button>
            <Button color="primary" className='btn btn-primary ml-1'>
                    Help
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div>
          <form noValidate autoComplete="off">
            <div className='form-wrapper'>
              <div className="mui-custom-form input-md">
                <TextField
                  id="lob"
                  disabled={true}

                  label="LOB"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.lob}
                />
              </div>

              <div className="mui-custom-form input-md">
                <TextField
                  id="payeeType"
                  disabled={true}
                  label="Payee type"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.payeeType}
                />
              </div>

              <div className="mui-custom-form input-md">
                <TextField
                  id="payeeIDTypeCode"
                  disabled={true}
                  label="Payee ID Type Code"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.payeeIDTypeCode}
                />
              </div>

              <div className="mui-custom-form input-md">
                <TextField
                  id="payeeID"
                  disabled={true}
                  label="Payee ID"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.payeeID}
                />
              </div>

              <div className="mui-custom-form input-md">
                <TextField
                  id="payeeName"
                  disabled={true}
                  label="Payee Name"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.payeeName}
                />
              </div>

              <div className="mui-custom-form input-md">
                <TextField
                  id="activityType"
                  disabled={true}
                  label="Activity Type"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.activityType}
                />
              </div>

              <div className="mui-custom-form input-md">
                <TextField
                  id="reasonCode"
                  disabled={true}
                  label="Reason code"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.reasonCode}
                />
              </div>

              <div className="mui-custom-form input-md">
                <TextField
                  id="sysPayeeId"
                  disabled={true}
                  label="System Payee ID"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.sysPayeeId}
                />
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  );
}

export default withRouter((FCNInquiry));
