/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from "react";
import { withRouter } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

import * as AppConstants from "../../../SharedModules/AppConstants";
import "../../FiscalPendCriteria/FiscalPendCriteria.scss";
import ErrorMessages from "../../../SharedModules/Errors/ErrorMessages";
import {
  compareTwoDatesGreaterThanOrEqual,
  validateDateMinimumValue
} from "../../../SharedModules/DateUtilities/DateUtilities";
import * as ErrorConstants from "./ErrorConstants";
import { getLongDescription, removeLeadingTrailingSpaces } from '../../../SharedModules/CommonUtilities/commonUtilities';
import {
  searchFiscalPendCriteriaAction,
  resetState,
  getDropdownDataAction
} from "../Store/Actions/FiscalPendCriteriaSearchActions";
import FiscalPendSearchTable from "./FiscalPendSearchTable";
import dropdownCriteria from "./FiscalPendSearch.json";
import Footer from "../../../SharedModules/Layout/footer";
import { GET_APP_DROPDOWNS } from "../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from "../../../SharedModules/Dropdowns/NativeDropDown";

function FiscalPendCriteriaSearch (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const dispatch = useDispatch();
  const onSearch = values => {
    dispatch(searchFiscalPendCriteriaAction(values));
    setIsSearch(true);
  };
  const resetSearchState = () => dispatch(resetState());
  const getDropdownData = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));
  const errorMessagesArray = [];
  const [selectedBeginDate, setSelectedBeginDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [showTable, setShowTable] = React.useState(false);

  const [errorMessages, setErrorMessages] = React.useState([]);
  const [errorMegBeginDate, setErrorMegBeginDate] = React.useState(null);
  const [errorMegEndDate, setErrorMegEndDate] = React.useState(null);
  const [searchTable, setSearchTable] = React.useState([]);
  const dropdown = useSelector(
    state => state.fiscalPendSearchState.dropdownData
  );
  let tableData = useSelector(
    state => state.fiscalPendSearchState.searchFiscalPendData
  );
  const [fundCodeList, setFundCodeList] = React.useState([]);

  const toPrintRef = useRef();
  const [
    billingProviderTypeDropdown,
    setBillingProviderTypeDropdown
  ] = React.useState([]);
  const [claimTypeDropdown, setClaimTypeDropdown] = React.useState([]);
  const [isSearch, setIsSearch] = React.useState(false);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  // set initial values
  const [values, setValues] = React.useState({
    fundCode: DEFAULT_DD_VALUE,
    fiscalPendId: '',
    claimType: DEFAULT_DD_VALUE,
    billingProviderId: '',
    billingProviderType: DEFAULT_DD_VALUE
  });

  // fetch dropdown values
  useEffect(() => {
    setErrorMessages([]);
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj["Financial#3200"]) {
        setFundCodeList(dropdown.listObj["Financial#3200"]);
      }
      if (dropdown.listObj['Provider#1062']) {
        setBillingProviderTypeDropdown(dropdown.listObj['Provider#1062']);
      }
      if (dropdown.listObj['Claims#1038']) {
        setClaimTypeDropdown(dropdown.listObj['Claims#1038']);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    getDropdownData(dropdownCriteria);
    tableData = null;
    resetSearchState();
    onDropdowns([
      Dropdowns.FUND_CODE,
    ]);
  }, []);

  // fund code with description
  const fundCodeDescriptionMap = fundCode => {
    const filteredValue = fundCodeList.filter(
      (fncode, index) => fncode.code === fundCode
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return fundCode;
  };
  const fundCodeLongDescriptionMap = fundCode => {
    const filteredValue = fundCodeList.filter(
      (fncode, index) => fncode.code === fundCode
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].longDescription;
    }
    return fundCode;
  };

  // fetch table data
  useEffect(() => {
    if (tableData) {
      if (!tableData.systemFailure && tableData.length === 0) {
        errorMessagesArray.push(ErrorConstants.NO_RECORDS_FOUND);
        setErrorMessages(errorMessagesArray);
        setShowTable(false);
      } else if (tableData.systemFailure) {
        errorMessagesArray.push(ErrorConstants.SYSTEMERROR);
        setErrorMessages(errorMessagesArray);
        setShowTable(false);
      } else {
        if (tableData.length > 1) {
          tableData.map((data, index) => {
            data.fundCodeDesc = fundCodeDescriptionMap(data.fundCode);
            data.fundCodeLongDesc = fundCodeLongDescriptionMap(data.fundCode);
          });
          setSearchTable(tableData);
          setShowTable(true);
        }
        if (tableData.length === 1) {
          setSearchTable(tableData);
          if (!props.location.tableRender) {
            props.history.push({
              pathname: "/FiscalPendCriteriaEdit",
              state: { searchResult: tableData[0], values: { ...values, selectedBeginDate: selectedBeginDate, selectedEndDate: selectedEndDate } }
            });
          } else {
            tableData[0].voidDate = tableData[0]?.fiscalPendDetailsVO?.voidDate;
            tableData[0].financialTxnIndicator = tableData[0]?.fiscalPendDetailsVO?.financialTxnIndicator;
            tableData[0].rank = tableData[0]?.fiscalPendDetailsVO?.rankNumber;
            tableData[0].payCycBegDate = tableData[0]?.fiscalPendDetailsVO?.beginDate;
            tableData[0].payCycEndDate = tableData[0]?.fiscalPendDetailsVO?.endDate;
            tableData[0].id = tableData[0]?.fiscalPendDetailsVO?.fiscalPendID;
            tableData[0].description = tableData[0]?.fiscalPendDetailsVO?.description;
            tableData[0].fundCodeDesc = fundCodeList?.filter(val => val?.code === tableData[0]?.fiscalPendDetailsVO?.fundCode)[0]?.description;
            setSearchTable(tableData);
            setShowTable(true);
          }
        }
      }
    }
  }, [tableData]);

  const handleBeginDateChange = date => {
    setSelectedBeginDate(date);
  };

  const handleEndDateChange = date => {
    setSelectedEndDate(date);
  };

  // handle change in any input field
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  // To reset page
  const resetPage = () => {
    setSelectedBeginDate(null);
    setSelectedEndDate(null);
    setValues({
      fundCode: DEFAULT_DD_VALUE,
      fiscalPendId: '',
      claimType: DEFAULT_DD_VALUE,
      billingProviderId: '',
      billingProviderType: DEFAULT_DD_VALUE
    });
    setShowTable(false);
    setErrorMessages([]);
    setErrorMegBeginDate(null);
    setErrorMegEndDate(null);
  };

  // To display search result
  const searchFiscalPendCriteria = () => {
    setShowTable(false);
    props.location.tableRender = false;
    tableData = [];
    setShowTable(false);
    var beginDateInvalidError, endDateInvalidError;
    setErrorMessages([]);
    setErrorMegBeginDate(null);
    setErrorMegEndDate(null);
    // date validations
    if (selectedBeginDate || selectedEndDate) {
      if (selectedBeginDate) {
        if (selectedBeginDate.toString() !== "Invalid Date") {
          if (validateDateMinimumValue(selectedBeginDate)) {
            beginDateInvalidError = true;
            errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
            setErrorMegBeginDate(AppConstants.DATE_ERROR_1964);
          }
        } else {
          beginDateInvalidError = true;
          errorMessagesArray.push(ErrorConstants.BEGIN_DATE_VALID);
          setErrorMegBeginDate(ErrorConstants.BEGIN_DATE_VALID);
        }
      }
      if (selectedEndDate) {
        if (selectedEndDate.toString() !== "Invalid Date") {
          if (validateDateMinimumValue(selectedEndDate)) {
            endDateInvalidError = true;
            errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
            setErrorMegEndDate(AppConstants.DATE_ERROR_1964);
          }
        } else {
          endDateInvalidError = true;
          errorMessagesArray.push(ErrorConstants.END_DATE_VALID);
          setErrorMegEndDate(ErrorConstants.END_DATE_VALID);
        }
      }
      if (
        selectedBeginDate &&
        selectedEndDate &&
        !beginDateInvalidError &&
        !endDateInvalidError &&
        compareTwoDatesGreaterThanOrEqual(selectedEndDate, selectedBeginDate)
      ) {
        endDateInvalidError = true;
        errorMessagesArray.push(ErrorConstants.INVALID_ENDDATE);
        setErrorMegEndDate(ErrorConstants.INVALID_ENDDATE);
      }
    }
    if (errorMessagesArray.length > 0) {
      setShowTable(false);
      setErrorMessages(errorMessagesArray);
    } else {
      const searchCriteria = {
        lob: AppConstants.DEFAULT_LOB_VALUE,
        fundCode:
          values.fundCode !== DEFAULT_DD_VALUE
            ? values.fundCode
            : null,
        payCycBegDate: selectedBeginDate
          ? moment(selectedBeginDate).format("MM/DD/YYYY")
          : null,
        payCycEndDate: selectedEndDate
          ? moment(selectedEndDate).format("MM/DD/YYYY")
          : null,
        claimType: values.claimType !== DEFAULT_DD_VALUE
          ? values.claimType
          : null,
        billingProviderType: values.billingProviderType !== DEFAULT_DD_VALUE
          ? values.billingProviderType
          : null,
        billingProviderId: values.billingProviderId,
        fiscalPendId: values.fiscalPendId
      };
      // setShowTable(true);
      setErrorMessages([]);
      onSearch(removeLeadingTrailingSpaces(searchCriteria));
      if (!props.history.state) {
        props.history.state = {};
      }
      props.history.state.values = searchCriteria;
    }
  };

  const setValuesFromFiscPend = props?.history?.state?.values;

  useEffect(() => {
    if (props.location.tableRender && !isSearch) {
      if (setValuesFromFiscPend !== undefined) {
        const searchCriteria = {
          lob: AppConstants.DEFAULT_LOB_VALUE,
          fundCode:
            setValuesFromFiscPend.fundCode !== DEFAULT_DD_VALUE
            ? setValuesFromFiscPend.fundCode : null,
          payCycBegDate: setValuesFromFiscPend.selectedBeginDate
            ? moment(setValuesFromFiscPend.selectedBeginDate).format("MM/DD/YYYY")
            : setValuesFromFiscPend.payCycBegDate
            ? moment(setValuesFromFiscPend.payCycBegDate).format("MM/DD/YYYY")
            : null,
          payCycEndDate: setValuesFromFiscPend.selectedEndDate
            ? moment(setValuesFromFiscPend.selectedEndDate).format("MM/DD/YYYY")
            : setValuesFromFiscPend.payCycEndDate
            ? moment(setValuesFromFiscPend.payCycEndDate).format("MM/DD/YYYY")
            : null,
          claimType: setValuesFromFiscPend.claimType !== DEFAULT_DD_VALUE
            ? setValuesFromFiscPend.claimType
            : null,
          billingProviderType: setValuesFromFiscPend.billingProviderType !== DEFAULT_DD_VALUE
            ? setValuesFromFiscPend.billingProviderType
            : null,
          billingProviderId: setValuesFromFiscPend.billingProviderId,
          fiscalPendId: setValuesFromFiscPend.fiscalPendId
        };
        setErrorMessages([]);
        onSearch(removeLeadingTrailingSpaces(searchCriteria));
        const validValues = removeLeadingTrailingSpaces(searchCriteria);
        setValues({
          lob: AppConstants.DEFAULT_LOB_VALUE,
          fundCode: validValues.fundCode || DEFAULT_DD_VALUE,
          fiscalPendId: validValues.fiscalPendId || '',
          claimType: validValues.claimType || DEFAULT_DD_VALUE,
          billingProviderId: validValues.billingProviderId || '',
          billingProviderType: validValues.billingProviderType || DEFAULT_DD_VALUE
        });
        setSelectedBeginDate(validValues.payCycBegDate);
        setSelectedEndDate(validValues.payCycEndDate);
      }
    }
  }, [props.location.tableRender]);

  // display system error on table row click
  const showTableErrorMessages = errorMessage => {
    setErrorMessages(errorMessage);
    setShowTable(false);
  };

  const addFisPend = () => {
    props.history.push({
      pathname: '/FiscalPendCriteriaAdd'
    });
  };

  return (
    <div className="pos-relative w-100 h-100">

      <div className="tabs-container" ref={ toPrintRef }>
        <ErrorMessages
          className=""
          errorMessages={ errorMessages }
        />
        <div className="tab-header">
          <h1 className="tab-heading float-left">
            Search Fiscal Pend Criteria
          </h1>
          <div className="float-right">
            <Button
              title="Add Fiscal Pend Criteria"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-add btn-add-new"
              onClick={() => addFisPend()}
              disabled={global.globalIsReadOnly()}
            >
              Add
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form autoComplete="off">
            <div className="form-wrapper">
              <NativeDropDown
                id="standard-select-fundCode"
                  label="Fund Code"
                  inputProps={ { maxlength: 5 } }
                  value={ values.fundCode }
                  onChange={ handleChange("fundCode") }
                  InputLabelProps={ {
                    shrink: true
                  } }
                  title={ getLongDescription(fundCodeList, values.fundCode) }
                dropdownList={fundCodeList
                    ? fundCodeList.map((item, index) => (
                      <option key={index} value={item.code}>
                        { item.description }
                      </option>
                    ))
                    : null }
              />
              <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                <div className="mui-custom-form with-date md">
                  <KeyboardDatePicker
                    maxDate={ Date.parse("31 Dec 9999 00:00:00 GMT") }
                    id="date-picker-begindate"
                    fullWidth
                    label="Paid Date Begin Date"
                    InputLabelProps={ {
                      shrink: true
                    } }
                    helperText={ errorMegBeginDate }
                    error={ errorMegBeginDate }
                    placeholder="mm/dd/yyyy"
                    format="MM/dd/yyyy"
                    value={ selectedBeginDate }
                    onChange={ handleBeginDateChange }
                    KeyboardButtonProps={ {
                      "aria-label": "change date"
                    } }
                  />
                </div>

                <div className="mui-custom-form with-date md">
                  <KeyboardDatePicker
                    maxDate={ Date.parse("31 Dec 9999 00:00:00 GMT") }
                    id="date-picker-enddate"
                    fullWidth
                    label="Paid Date End Date"
                    format="MM/dd/yyyy"
                    InputLabelProps={ {
                      shrink: true
                    } }
                    helperText={ errorMegEndDate }
                    error={ errorMegEndDate }
                    placeholder="mm/dd/yyyy"
                    value={ selectedEndDate }
                    onChange={ handleEndDateChange }
                    KeyboardButtonProps={ {
                      "aria-label": "change date"
                    } }
                  />
                </div>
              </MuiPickersUtilsProvider>

              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-select-pfID"
                  fullWidth
                  label="Fiscal Pend ID"
                  inputProps={ { maxlength: 4 } }
                  value={ values.fiscalPendId }
                  onChange={ handleChange("fiscalPendId") }
                  InputLabelProps={ {
                    shrink: true
                  } }
                  onInput={ e => {
                    e.target.value = e.target.value.replace(
                      AppConstants.NUMBER_ONLY_REGEX,
                      ''
                    );
                  } }
                />
              </div>
            </div>
            <div className="form-wrapper">
              <NativeDropDown
                id="standard-select-clmtype"
                  label="Claim Type"
                  inputProps={ { maxlength: 4 } }
                  value={ values.claimType }
                  onChange={ handleChange("claimType") }
                  InputLabelProps={ {
                    shrink: true
                  } }
                dropdownList={claimTypeDropdown.map(option => (
                  <option key={option.code} value={option.code}>
                      { option.description }
                  </option>
                  )) }
              />
              <NativeDropDown
                id="standard-select-bpt"
                  label="Billing Provider Type"
                  inputProps={ { maxlength: 4 } }
                  value={ values.billingProviderType }
                  onChange={ handleChange("billingProviderType") }
                  InputLabelProps={ {
                    shrink: true
                  } }
                dropdownList={billingProviderTypeDropdown.map(option => (
                  <option key={option.code} value={option.code}>
                      { option.description }
                  </option>
                  )) }
              />
              <div className="mui-custom-form with-select input-md">
                <TextField
                  id="standard-select-bpID"
                  fullWidth
                  label="Billing Provider ID"
                  inputProps={ { maxlength: 15 } }
                  value={ values.billingProviderId }
                  onChange={ handleChange("billingProviderId") }
                  InputLabelProps={ {
                    shrink: true
                  } }
                  onInput={ e => {
                    e.target.value = e.target.value.replace(
                      /[ ]/,
                      ''
                    );
                  } }
                />
              </div>
            </div>
            <div className="float-right mr-4 mb-3">
              <Button
                variant="outlined"
                color="primary"
                className="btn btn-primary"
                onClick={ () => searchFiscalPendCriteria() }
              >
                <i className="fa fa-search" aria-hidden="true"></i> Search
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className="bt-reset btn-transparent  ml-1"
                onClick={ () => resetPage() }
              >
                <i className="fa fa-undo" aria-hidden="true"></i> Reset
              </Button>
            </div>
            <div className="clearfix"></div>
          </form>

          { showTable && searchTable.length > 0 ? (
            <div className="tab-holder pb-1 tableNoWrap">
              <FiscalPendSearchTable
                tableData={ searchTable }
                tableErrors={ showTableErrorMessages }
                dropdowns={addDropdowns}
                values ={{ ...values, selectedBeginDate: selectedBeginDate, selectedEndDate: selectedEndDate }}
              ></FiscalPendSearchTable>
            </div>
          ) : null }

          <Footer print />
        </div>
      </div>
    </div>
  );
}
export default withRouter(FiscalPendCriteriaSearch);
