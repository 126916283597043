/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from "react";
import moment from 'moment';
import FinancialPayoutSearchResultsComponent from './FinancialPayoutSearchResultsComponent';
import TextField from "@material-ui/core/TextField";
import { Button } from "react-bootstrap";
import {
  FCN_REQUIRED,
  FCN_TEXT,
  NO_RECODRS_FOUND,
  FCN_INVALID,
  GENERIC_SYSTEM_ERROR
} from "./FPSearchConstants";
import { withRouter } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import {
  searchFCNFinanceAction,
  resetFCNSearchFinancialPayout
} from "../../Store/Actions/financialPayout/financialPayoutActions";
import { removeLeadingTrailingSpaces } from "../../../../SharedModules/CommonUtilities/commonUtilities";
import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";
import { DEFAULT_LOB_VALUE } from "../../../../SharedModules/AppConstants";

function FCNInquiry (props) {
  const dispatch = useDispatch();
  const onReset = () => dispatch(resetFCNSearchFinancialPayout());
  const { value } = props;
  const [showTable, setShowTable] = React.useState(false);
  const [payeeTableData, setpayeeTableData] = React.useState([]);
  const [{ showFCNError }, setShowError] = React.useState(false);
  let errorMessagesArray = [];
  const [errorMeg, setErrorMeg] = React.useState([]);
  let payload = [];
  const [redirect, setRedirect] = React.useState(0);

  const [showNoRecords, setShowNoRecords] = React.useState(false);
  const [isSearch, setIsSearch] = React.useState(false);

  const [values, setValues] = React.useState({
    fcn: ""
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const onSearch = values => dispatch(searchFCNFinanceAction(values));
  payload = useSelector(state => state.financialPayoutState.payload);
  const isNullOrEmpty = str => {
    return !str || !str.trim();
  };
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);

  const formatSearchCriteria = (_row) => {
    return ({
      fcn: _row.fcn,
      searchBy: 'searchByFCN',
      lob: [DEFAULT_LOB_VALUE],
      fcnSearch: 1,
      payeeSearch: 0,
      payeeType: null,
      payeeID: null,
      payeeIDTypeCode: null
    });
  };

  const onRowClick = row => event => {
    props.location.tableRender = false;
    const searchCriteria = formatSearchCriteria(row);
    onSearch(removeLeadingTrailingSpaces(searchCriteria));
  };

  useEffect(() => {
    if (payload != null && payload.systemFailure) {
      errorMessagesArray = [];
      errorMessagesArray.push(GENERIC_SYSTEM_ERROR);
      props.showError(errorMessagesArray);
    } else if (payload != null && !payload.systemFailure && payload && payload.length === 0) {
      errorMessagesArray = [];
      errorMessagesArray.push(NO_RECODRS_FOUND);
      props.showError(errorMessagesArray);
    } else if (payload != null && payload !== "" && payload.respcode === "02") {
      errorMessagesArray = [];
      errorMessagesArray.push(payload.errorMessages);
      props.showError(errorMessagesArray);
    } else if (
      payload?.respcode !== "02" && payload?.enterpriseSearchresultVO?.searchResults
    ) {
      if (!props.location.tableRender) {
        const payoutUpdate = true;
        sessionStorage.setItem('isFromAdd', JSON.stringify(false));
        props.history.push({
          pathname: "/FinancialPayoutUpdate",
          state: {
            payoutUpdate,
            addSuccessData: payload.enterpriseSearchresultVO.searchResults[0],
            values: values
          }
        });
} else {
          setShowTable(true);
          const tempTableData = JSON.parse(JSON.stringify(payload.enterpriseSearchresultVO.searchResults));
          tempTableData.map((value) => {
            value.transDate = moment(new Date(value.transDate)).format('L');
          });
          setpayeeTableData(tempTableData);
        }
    }
  }, [payload]);

  useEffect(() => {
    onReset();
    onDropdowns([Dropdowns.PAYEE_TYPE, Dropdowns.F_RSN_CD, Dropdowns.MEMBER_TYPE_DD]);
  }, []);

  React.useEffect(() => {
    setShowError(false);
  }, [value]);

  const resetFCN = () => {
    setValues({ fcn: "" });
    setShowTable(false);
    errorMessagesArray = [];
    setShowError(false);
    props.showError(errorMessagesArray);
    onReset();
    setShowNoRecords(false);
    const valuetoredirect = redirect - 1;
    setRedirect(valuetoredirect);
  };

  const searchFCN = () => {
    sessionStorage.setItem('tabValuePayout', 0);
    props.location.tableRender = false;
    var showFCNError = false;
    if (isNullOrEmpty(values.fcn)) {
      setValues({ fcn: "" });
      showFCNError = true;
      setShowError({ showFCNError: showFCNError });
      errorMessagesArray = [];
      errorMessagesArray.push(FCN_REQUIRED);
      setErrorMeg(FCN_REQUIRED);
      props.showError(errorMessagesArray);
    } else if (values.fcn.indexOf(" ") >= 0) {
      showFCNError = true;
      setShowError({ showFCNError: showFCNError });
      errorMessagesArray = [];
      errorMessagesArray.push(FCN_INVALID);
      setErrorMeg(FCN_INVALID);
      props.showError(errorMessagesArray);
    } else {
      showFCNError = false;
      setShowError(false);
      errorMessagesArray = [];
      props.showError(errorMessagesArray);

      const searchCriteria = {
        fcn: values.fcn,
        searchBy: "searchByFCN",
        lob: [DEFAULT_LOB_VALUE],
        fcnSearch: 1,
        payeeSearch: 0,
        payeeType: null,
        payeeID: null,
        payeeIDTypeCode: null
      };
      onSearch(removeLeadingTrailingSpaces(searchCriteria));
      if (!props.history.state) {
        props.history.state = {};
      }
      props.history.state.values = values.fcn;
      setIsSearch(true);
      if (payload != null && payload === "") {
        errorMessagesArray = [];
        errorMessagesArray.push(NO_RECODRS_FOUND);
        props.showError(errorMessagesArray);
      }
    }
  };

  const ValuesFromFinPayout = props?.history?.state?.values;
  useEffect(() => {
    if (props.location.tableRender && !isSearch) {
      if (ValuesFromFinPayout !== undefined) {
        setShowError(false);
        errorMessagesArray = [];
        props.showError(errorMessagesArray);

        const searchCriteria = {
          fcn: ValuesFromFinPayout,
          searchBy: 'searchByFCN',
          lob: [DEFAULT_LOB_VALUE],
          fcnSearch: 1,
          payeeSearch: 0,
          payeeType: null,
          payeeID: null,
          payeeIDTypeCode: null
        };
        onSearch(removeLeadingTrailingSpaces(searchCriteria));
        setValues({ fcn: ValuesFromFinPayout });
      };
    }
  }, [props.location.tableRender]);

  return (
    <div className="pos-relative w-100 h-100">
      <div className="mui-custom-form input-md">
        <TextField
          id="fcn"
          required
          label={FCN_TEXT}
          InputLabelProps={{
            shrink: true
          }}
          value={values.fcn}
          onChange={handleChange("fcn")}
          inputProps={{ maxLength: 14 }}
          helperText={showFCNError ? FCN_REQUIRED : null}
          error={showFCNError ? FCN_REQUIRED : null}
        />
      </div>

      <div className="float-right mb-2 mr-3">
        <Button className="btn-tab-pannel" onClick={() => searchFCN("gt")}>
          <i className="fa fa-search" aria-hidden="true"></i>
          Search
        </Button>

        <Button
          variant="outlined"
          color="primary"
          className="bt-tabreset btn-transparent ml-1"
          onClick={() => resetFCN()}
        >
          <i className="fa fa-undo" aria-hidden="true"></i>
          Reset
        </Button>
      </div>

      <div className="clearfix"></div>
      <div className="tab-holder mt-3">
        {showTable ? (
            <div className="tab-holder-p-8 tableNoWrap">
              <FinancialPayoutSearchResultsComponent
                dropdowns={addDropdowns}
                tableData={payeeTableData}
                onRowClick={onRowClick}
              />
            </div>
        ) : null}
      </div>
    </div>
  );
}

export default withRouter(FCNInquiry);
