/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import { withRouter } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import {
  resetSearchTextManagement
} from '../../Store/Actions/TextManagement/TextManagementActions';
import TableComponent from '../../../../SharedModules/Table/Table';
import { RemarkTextGetRecordAction } from '../../Store/Actions/TextManagement/TextManagementAddUpdateActions';

const headCells = [
  {
    id: 'remarkCode',
    disablePadding: false,
    label: 'Remark Code',
    width: '16%',
    enableHyperLink: true,
    isVarchar: true
  },
  {
    id: 'remarkBeginDate',
    disablePadding: false,
    label: 'Begin Date',
    width: '13%',
    isDate: true
  },
  {
    id: 'remarkEndDate',
    disablePadding: false,
    label: 'End Date',
    width: '13%',
    isDate: true
  },
  {
    id: 'remarkText',
    disablePadding: false,
    label: 'Text',
    width: '58%',
    isText: true
  }
];

function ProviderNoticeTable (props) {
  const dispatch = useDispatch();

  const [useEffectValues, setUseEffectValues] = React.useState([]);
  const onReset = () => dispatch(resetSearchTextManagement());
  useEffect(() => {
    onReset();
  }, [useEffectValues]);
  const [searchCriteria, setSearchCriteria] = React.useState({});
  const [redirect, setRedirect] = React.useState(false);
  const [current, setCurrent] = React.useState(null);
  const onRowClick = values => dispatch(RemarkTextGetRecordAction(values));
  const payloadData = useSelector(
    state => state.appConfigState.TextSearchState.Text
  );
  if (redirect === 1) {
    if (payloadData != null) {
      if (payloadData[0].remarkCode === current) {
        props.history.push({
          pathname: '/TextUpdate',
          state: { row: payloadData[0], TextType: 'Remark Code' }
        });
      }
    }
  }
  const formatSearchCriteria = (row) => {
    return ({
      remarkCode: row.remarkCode ? row.remarkCode : null,
      remarkText: row.remarkText ? row.remarkText : null,
      remarkTextStartsOrContains: null
    });
  };

  const editRow = row => event => {
    const searchCriteria = {
      remarkCode: row.remarkCode ? row.remarkCode : null,
      remarkText: row.remarkText ? row.remarkText : null,
      remarkTextStartsOrContains: null
    };
    onRowClick(searchCriteria);
    setSearchCriteria(searchCriteria);
    setCurrent(searchCriteria.remarkCode);

    let valuetoredirect = 0;
    valuetoredirect = valuetoredirect + 1;
    setRedirect(valuetoredirect);
  };

  const tableComp = (
    <TableComponent
      fixedTable
      print={props.print}
      isSearch={true}
      headCells={headCells}
      pathTo='/TextUpdate?data='
      formatSearchCriteria={formatSearchCriteria}
      tableData={props.tableData ? props.tableData : []}
      onTableRowClick={editRow}
      defaultSortColumn={headCells[0].id}
    />
  );
  return tableComp;
}
export default withRouter(ProviderNoticeTable);
