/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import * as serviceEndPoint from "../../SharedModules/services/service";
import axios from "axios";
import * as service_constants from "../services/service";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    textAlign: "left"
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  "table-content": {
    width: "100%"
  },
  responsiveFont: {
    [theme.breakpoints.up("md")]: {
      fontSize: "0.7rem !important"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "0.8rem !important"
    }
  },
  row: {
  },
  circle: {
    color: "white",
    fontSize: "1rem",
    borderRadius: "50%",
    width: 50,
    height: 50,
    padding: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none"
  },
  col0: {
    width: 70
  },
  col1: {
    width: "55%"
  },
  col2: {
    width: "15%",
    minWidth: 50
  },
  col3: {
    width: "15%",
    minWidth: 60
  },
  col13: {
    marginTop: "8%",
    marginLeft: "10%"
  },
  timetext: {
    color: "#545955 !important",
    textAlign: "center"
  }
}));
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const TableRow = ({
  text = "",
  time = "00:00:00 HRS",
  onViewClick,
  color = "#ff8700",
  initials = "GK"
}) => {
  const classes = useStyles();
  return (
    <tr className={classes.row}>
      <td className={classes.col0}>
        <div className={classes.circle} style={{ backgroundColor: color }}>
          <div>{initials}</div>
        </div>
      </td>
      <td
        className={clsx(classes.col1, classes.responsiveFont, "align-middle")}
      >
        <div>{text}</div>
      </td>
      <td className={clsx(classes.col2, classes.timetext, "align-middle")}>
        <div>{time}</div>
      </td>
      <td onClick={onViewClick} className={classes.col3}>
        <button
          color="primary"
          type="button"
          className="btn btn-success btn btn-primary upgrade-btn"
        >
          View details{" "}
        </button>
      </td>
    </tr>
  );
};

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function Dashboardsearch(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const classes = useStyles();
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const getLoginUser = () => {
    const l = localStorage.getItem('loginState');
    if (l) {
      return JSON.parse(l).logInUser;
    }
    return null;
  };    
  const logInUserID = getLoginUser();
  const [mavenData, setMavenData] = useState(false);
  const [dashboardData, setDashBoardData] = useState([]);
  useEffect(() => {
    if (process.env.REACT_APP_CASE_CREATION === "true") {
      getWorkFlowContents(); //get work flow content and redirect to maven
    } else {
      getCaseCreation(); //get case creation and redirect to application
    }
  }, [process.env.REACT_APP_CASE_CREATION]);

  let [caseCreationData, setCaseCreationData] = useState([]);

  const getCaseCreation = () => {
    return axios
      .get(`${serviceEndPoint.CASE_CREATION}/${logInUserID}`)
      .then(response => {
        setCaseCreationData(
          response.data.mavenIntegrationFormat.workflowContents
        );
      })
      .catch(error => {
      });
  };

  const getWorkFlowContents = () => {
    return axios
      .get(`${serviceEndPoint.MAVEN_INTG_HOST_ENV}` + "getAllWorkflowContents")
      .then(response => {
        setDashBoardData(response.data.mavenIntegrationFormat.workflowContents);
        let _obj = response.data.mavenIntegrationFormat.workflowContents;
        let rowData = "";
        let _class = "";
        let _cnt = "";
        let _txt = "";
        let _time = "";
        let endPoint = service_constants.REACT_APP_MAVEN_REDIRECT;
        Object.keys(_obj).map((key, i) => {
          let workflowName = _obj[key].workflowName;
          var initials = Array.prototype.map
            .call(workflowName.split(" "), function(x) {
              return x
                .substring(0, 1)
                .replace(/[0-9]/g, "")
                .replace("-", "")
                .toUpperCase();
            })
            .join("");
          if (initials.length > 2) {
            var initialsTemp = initials.substring(0, 2);
            initials = initialsTemp;
          }
          if (
            _obj[key].workflowName === "Payout Approval" ||
            _obj[key].workflowName === "1099 Correction Approval"
          ) {
            _class = _obj[key].caseDescription !== null ? "show" : "hide";
            _txt =
              _obj[key].caseDescription !== null ? _obj[key].workflowName : "";
            _time =
              _obj[key].caseDescription !== null
                ? _obj[key].caseDescription[0].modificationDate
                : "";
            _cnt =
              _obj[key].caseDescription !== null
                ? _obj[key].caseDescription.length
                : "";
            rowData += "<tr class='" + classes.row + " " + _class + "'>";
            rowData +=
              "<td class='" +
              classes.col0 +
              "'><div class='" +
              classes.circle +
              "'  style='background-color:#ff8700'><div>" +
              initials +
              "</div></div></td>";
            rowData +=
              "<td class='" +
              clsx(classes.col1, classes.responsiveFont, "align-middle") +
              "'><div>" +
              _txt +
              "</div></td>";
            rowData +=
              "<td class='" +
              clsx(classes.col2, classes.timetext, "align-middle") +
              "'><div>" +
              _cnt +
              "</div></td>";
            rowData +=
              "<td class='" +
              classes.col113 +
              "'><a href='#' onclick='view_details(`" +
              `${process.env.REACT_APP_MAVEN_APP}` +
              "`," +
              _obj[key].uuid +
              ")' data-work='" +
              _obj[key].workflowName +
              "' id='" +
              _obj[key].uuid +
              "' color='primary' type='button' style='margin: 12px;' class='colViewDeatils btn btn-success btn btn-primary upgrade-btn'>View details </a></td>";
            rowData += "</tr>";
          }
        });

        document.getElementById("tableData").innerHTML = rowData;

        var script_tag = document.createElement("script");
        script_tag.type = "text/javascript";
        script_tag.text =
          'var src1 = ""; var frame = document.getElementById("iframe");var m = document.getElementById("main-col"); function view_details(abcd,id){ src1 = abcd + id; frame.setAttribute("src", src1);document.getElementById("tableDisplay").classList.add("hide");document.getElementById("sidebar-div").classList.add("hide");document.getElementById("alert-div").classList.add("hide");document.getElementById("dashboard-heading").classList.add("hide");document.getElementById("banner-section").classList.add("hide"); m.classList.add("col-lg-12"); frame.classList.remove("hide"); }';
        document.body.appendChild(script_tag);
      })
      .catch(error => {
      });
  };
  
  const renderTableBody = () => {
    return (
      caseCreationData &&
      caseCreationData.map((dashboard, index) => {
        if (
          dashboard.workflowName === "Payout Approval" ||
          dashboard.workflowName === "1099 Correction Approval"
        ) {
          let _class = "";
          let _txt = "";
          let _time = "";
          let _cnt = "";
          let linkPayout = "";
          let linkCorrection = "";
          if (dashboard.workflowName === "Payout Approval") {
            linkPayout = "/PayoutApprovalSearch";
          } else if (dashboard.workflowName === "1099 Correction Approval") {
            linkCorrection = "/CorrectionSearchApproval";
          }
          _class = dashboard.workflowName !== null ? "show" : "hide";
          _txt = dashboard.workflowName !== null ? dashboard.workflowName : "";
          _cnt = dashboard.workflowName !== null ? dashboard.count : "";

          let workflowName = dashboard.workflowName;
          var initials = Array.prototype.map
            .call(workflowName.split(" "), function(x) {
              return x
                .substring(0, 1)
                .replace(/[0-9]/g, "")
                .replace("-", "")
                .toUpperCase();
            })
            .join("");
          if (initials.length > 2) {
            var initialsTemp = initials.substring(0, 2);
            initials = initialsTemp;
          }

          return (
            <tr className={classes.row + " " + _class}>
              <td className={classes.col0}>
                <div
                  className={classes.circle}
                  style={{ backgroundColor: "#ff8700" }}
                >
                  {initials}
                </div>
              </td>
              <td
                className={clsx(
                  classes.col1,
                  classes.responsiveFont,
                  "align-middle"
                )}
              >
                <div>{_txt}</div>
              </td>
              <td
                className={clsx(classes.col2, classes.timetext, "align-middle")}
              >
                <div>{_cnt}</div>
              </td>
              {dashboard.workflowName === "Payout Approval" ? (
                <td className={classes.col13}>
                  <Link
                    to={linkPayout}
                    style={{ margin: "12px" }}
                    className="colViewDeatils btn btn-success btn btn-primary upgrade-btn"
                  >
                    View Details
                  </Link>
                </td>
              ) : (
                <td className={classes.col13}>
                  <Link
                    to={linkCorrection}
                    style={{ margin: "12px" }}
                    className="colViewDeatils btn btn-success btn btn-primary upgrade-btn"
                  >
                    View Details
                  </Link>
                </td>
              )}
            </tr>
          );
        }
      })
    );
  };

  return (
    <div className="container-fluid main-content-margin">
      <div className="row h-100">
        <div className="col-lg-12 noPadding" id="main-col">
          <div className="container-fluid h-100 d-flex flex-column justify-content-between py-3">
            <div className="row banner-section" id="banner-section">
              <div className="image-responsive">
                <img
                  src="images/state_banner.jpg"
                  alt="Welcome to CMdS-Financial"
                />
              </div>
              <div className="banner-text">
                <div className="large-text">Welcome to CMdS-Financial</div>
              </div>
            </div>

            <div className="row">
              <div className="tab-body col-12 noPadding">
                <div id="dashboard-heading" className="dashboard-heading">
                  Work Queue
                </div>
                {process.env.REACT_APP_CASE_CREATION === "true" ? (
                  <>
                    <table
                      id="tableDisplay"
                      className="table table-striped table-bordered"
                    >
                      <tbody id="tableData"></tbody>
                    </table>
                    <iframe
                      className="hide"
                      id="iframe"
                      src=""
                      width="100%"
                      height="700"
                      frameBorder="0"
                      marginHeight="0"
                      marginWidth="0"
                    />
                  </>
                ) : (
                  <>
                    {caseCreationData && caseCreationData.length > 0 ? (
                      <>
                        <table className="table table-striped table-bordered">
                          <tbody>{renderTableBody()}</tbody>
                        </table>
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>
            <div className="row" id="alert-div">
              <div className="col-md-12 tab-body noPadding alert-content">
                <h1
                  id="dashboard-heading"
                  className="dashboard-heading float-left"
                >
                  Alerts
                </h1>{" "}
                <label className="label label-danger alert-dangericon">1</label>{" "}
                <span className="alertview">View all</span>
                <div className="clearfix"></div>
                <div className="alert-contenttext">
                  <div className="row">
                    <div className="col-8 alerts">
                      New fiscal spend criteria added for Med Assist
                    </div>{" "}
                    <div className="col-4 alertsdate">
                      {" "}
                      <span className="margin-left-10"> </span>
                    </div>
                  </div>
                </div>
                <div className="alert-contenttext">
                  <div className="row">
                    <div className="col-8 alerts">
                      GL Accounting unit changed in MED-Med Assist State Fiscal
                      Budget 2021
                    </div>{" "}
                    <div className="col-4 alertsdate">
                      {" "}
                      <span className="margin-left-10"> </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Dashboardsearch);
