/**
* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile
* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United
* States and/or other countries.
*/
import React, { useState, useEffect, useRef } from "react";
import TextField from '@material-ui/core/TextField';
import * as ErrorConstants from "../../../SharedModules/Errors/ErrorMsgConstant";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ChangePasswordAction, setUserprivileges } from "../../../SharedModules/store/Actions/SharedAction";
import { useHistory } from 'react-router-dom';
import ErrorMessages from "../../../SharedModules/Errors/ErrorMessages";
import { withRouter } from 'react-router';
const ChangePassword = (props) => {
  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState([]);
  let errorMessagesArray = [];
  const sucessMessages = [];
  const dispatch = useDispatch();
  const history1 = useHistory();
  const printRef = useRef();
  const changePasswordData = useSelector(state => state.changePassword.changePassword);
  const userData = useSelector((state) => state.sharedState.userPrivileges);
  const [errorMessages, seterrorMessages] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [{ currePassReqErr, newPassReqErr, confirmNewPassReqErr, passwordMinLenErr, password3Of4Err, newConfirmSameErr }, setShowErr] = useState(false);
  const defaultFormValue = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  };
  const [formValue, setformValue] = useState(defaultFormValue);
  const handleChange = (name) => (event) => {
      setformValue({ ...formValue, [name]: event.target.value });
  };
  const onchangePassword = (searchvalues, fieldsValue) => {
    return dispatch(ChangePasswordAction(searchvalues, fieldsValue));
  };
  const resetTable = () => {
    setShowErr(false);
    seterrorMessages([]);
    setSystemSuccesMessages([]);
    setformValue(defaultFormValue);
  };
  const formValidation = () => {
    errorMessagesArray = [];
    seterrorMessages([]);
    setSystemSuccesMessages([]);
    const reqFieldArr = [];
    const reqPassword3of4 = [];
   var currePassReqErr, newPassReqErr, confirmNewPassReqErr, passwordMinLenErr, password3Of4Err, newConfirmSameErr;
    if (formValue.oldPassword === "") {
      currePassReqErr = true;
      reqFieldArr.push(ErrorConstants.PASSWORD_REQ);
    }
    if (formValue.newPassword === "") {
      newPassReqErr = true;
      reqFieldArr.push(ErrorConstants.PASSWORD_NEW_REQ);
    }
    if (formValue.oldPassword === formValue.newPassword) {
      currePassReqErr = true;
      reqFieldArr.push(ErrorConstants.OLD_NEW_PWD_MSG);
    }
    if (formValue.newPassword !== "" && formValue.newPassword.length < 10) {
      passwordMinLenErr = true;
      reqFieldArr.push(ErrorConstants.PASSWORD_LEN);
    }
    if (formValue.confirmNewPassword === "") {
      confirmNewPassReqErr = true;
      reqFieldArr.push(ErrorConstants.PASSWORD_CONFIRM_REQ);
    }
    if (formValue.newPassword !== "" && formValue.newPassword.length >= 10) {
      if (/^(?=.*[a-z])/.test(formValue.newPassword)) {
        reqPassword3of4.push('lower');
      }
      if (/^(?=.*[A-Z])/.test(formValue.newPassword)) {
        reqPassword3of4.push('upper');
      }
      if (/^(?=.*[?=.*\d])/.test(formValue.newPassword)) {
        reqPassword3of4.push('number');
      }
      if (/^(?=.*?[^\w\s])/.test(formValue.newPassword)) {
        reqPassword3of4.push('specialChar');
      }
     if (reqPassword3of4.length < 3) {
      password3Of4Err = true;
      reqFieldArr.push(ErrorConstants.PASSWORD_3_OF_4);
     }
    }

    if (formValue.newPassword !== "" && formValue.newPassword.length >= 10 && reqPassword3of4.length >= 3 && formValue.confirmNewPassword !== "" && formValue.newPassword !== formValue.confirmNewPassword) {
      newConfirmSameErr = true;
      reqFieldArr.push(ErrorConstants.PASSWORD_AND_CONFIRM_SAME);
    }
    if (reqFieldArr.length) {
      setShowErr({ currePassReqErr: currePassReqErr, newPassReqErr: newPassReqErr, confirmNewPassReqErr: confirmNewPassReqErr, passwordMinLenErr: passwordMinLenErr, password3Of4Err: password3Of4Err, newConfirmSameErr: newConfirmSameErr });
      seterrorMessages(reqFieldArr);
      return false;
    }
    return true;
  };
  const onchangePass = () => {
    setShowErr(false);
   if (formValidation()) {
    setRedirect(true);

      let searchCriteria = {};
      const username = JSON.parse(localStorage.getItem("loginState"));
      searchCriteria = {
        userID: username.logInUserId,
        oldPassword: formValue.oldPassword,
        newPassword: formValue.newPassword
      };
      onchangePassword(searchCriteria, formValue);
   }
  };
  useEffect(() => {
    if (changePasswordData != null && changePasswordData.statusCode == "01" && changePasswordData.responseObject && changePasswordData.statusDescription === 'Success') {
      var menus = { ...userData };
      menus.passwordChanged = true;
     dispatch(setUserprivileges(menus));
     if (redirect) {
      history1.push({
        pathname: '/dashboard',
        state: { passwordUpdated: true }
        });
     }
    }
    if (changePasswordData && (changePasswordData.message != null || changePasswordData.message !== "") && !changePasswordData.statusCode) {
     errorMessagesArray.push(ErrorConstants.ERROR_OCCURED_DURING_TRANSACTION);
     seterrorMessages(errorMessagesArray);
    }
    if (changePasswordData != null && changePasswordData.statusCode == "02") {
      if (changePasswordData.statusDescription == ErrorConstants.INVALID_PWD) {
        errorMessagesArray.push(ErrorConstants.INVALID_PWD_MSG);
        seterrorMessages(errorMessagesArray);
      } else if (changePasswordData.statusDescription == ErrorConstants.EMPTY_PWD_NULL) {
        errorMessagesArray.push(ErrorConstants.EMPTY_PWD_NULL_MSG);
        seterrorMessages(errorMessagesArray);
      } else if (changePasswordData.statusDescription == ErrorConstants.PREVIOUS_PWD) {
        errorMessagesArray.push(ErrorConstants.PREVIOUS_PWD);
        seterrorMessages(errorMessagesArray);
} else {
        errorMessagesArray.push(ErrorConstants.ERROR_OCCURED_DURING_TRANSACTION);
        seterrorMessages(errorMessagesArray);
      }
    } else {
 sucessMessages.push(ErrorConstants.SUCCESS_MESSAGE);
    setSystemSuccesMessages(sucessMessages);
}
   }, [changePasswordData]);

return (
        <>
<div className="pos-relative w-100 h-100" ref={printRef}>
<div className="tabs-container">
  <div className="tab-header">
  <ErrorMessages errorMessages={errorMessages} />
    <h1 className="tab-heading float-left">Change Password</h1>
    <div className="float-right mr-2">
      <Button
        variant="outlined"
        color="primary"
        className="btn btn-secondary ml-1 mt-2"
      >
        <i className="fa fa-question-circle" aria-hidden="true"></i>
        Help
      </Button>
    </div>
    <div className="clearfix"></div>
  </div>
  <div className="tab-bo  dy">
    <form autoComplete="off">
      <div className="form-wrapper">
        <div>
          <ul style={{ marginTop: "15px" }} password-left-padding>
            <li className="very-small-label">
              Password must be minium of 10 characters
            </li>
            <li className="very-small-label">
              Password must be different from last 5 passwords
            </li>
            <li className="very-small-label">
              Password must contains 3 out of 4 following types
              <ul password-left-padding>
                <li className="very-small-label">Uppercase letters</li>
                <li className="very-small-label">Lowercase letters</li>
                <li className="very-small-label">Numbers</li>
                <li className="very-small-label">
                  Non Alphanumerics (! $ # or %)
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div className="form-wrapper">
        <div className="mui-custom-form input-xl">
        <TextField
                                required
                                id="current-passwprd"
                                type="password"
                                label='Current Password'
                                className="inline-lable-ttl"
                                value={formValue.oldPassword}
                                onChange={handleChange('oldPassword')}
                                inputProps={{ maxLength: 30 }}
                                placeholder=""
                                helperText={currePassReqErr ? ErrorConstants.PASSWORD_REQ : null}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                error={currePassReqErr ? ErrorConstants.PASSWORD_REQ : null}
                            />
        </div>
      </div>
      <div className="form-wrapper">
        <div className="mui-custom-form input-xl">
        <TextField
                                    required
                                    id="new-password"
                                    type="password"
                                    label= 'New Password'
                                    value={formValue.newPassword}
                                    inputProps={{ maxLength: 30 }}
                                    onChange={handleChange('newPassword')}
                                    placeholder=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    helperText={
                                        newPassReqErr ? ErrorConstants.PASSWORD_NEW_REQ
                                        : passwordMinLenErr ? ErrorConstants.PASSWORD_LEN
                                        : password3Of4Err ? ErrorConstants.PASSWORD_3_OF_4
                                        : null}
                                    error={
                                            newPassReqErr ? ErrorConstants.PASSWORD_NEW_REQ
                                            : passwordMinLenErr ? ErrorConstants.PASSWORD_LEN
                                            : password3Of4Err ? ErrorConstants.PASSWORD_3_OF_4
                                            : null}
                                />
        </div>
      </div>
      <div className="form-wrapper">
        <div className="mui-custom-form input-xl">
        <TextField

                                      id="confirm-new-password"
                                      type="password"
                                      label='Confirm Password'
                                      value={formValue.confirmNewPassword}
                                      inputProps={{ maxLength: 30 }}
                                      onChange={handleChange('confirmNewPassword')}
                                      placeholder=""
                                      InputLabelProps={{
                                          shrink: true
                                      }}
                                      helperText={
                                          confirmNewPassReqErr ? ErrorConstants.PASSWORD_CONFIRM_REQ
                                          : newConfirmSameErr ? ErrorConstants.PASSWORD_AND_CONFIRM_SAME
                                          : null}
                                      error={
                                         confirmNewPassReqErr ? ErrorConstants.PASSWORD_CONFIRM_REQ
                                          : newConfirmSameErr ? ErrorConstants.PASSWORD_AND_CONFIRM_SAME
                                          : null}
                                  />
        </div>
      </div>
      <div
        className="float-right mr-3 mb-2"
        style={{ marginTop: "20px" }}
      >
        <Button
          variant="outlined"
          color="primary"
          className="float-right btn btn-primary  ml-1"
          onClick={resetTable}
        >
          {" "}
          <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className="float-right btn btn-primary"
          onClick={onchangePass}
        >
          {" "}
          Update{" "}
        </Button>
      </div>
      <div className="clearfix"></div>
    </form>
  </div>
</div>
</div>
<div>WIP page</div>
        </>
    );
  };

  export default withRouter(ChangePassword);
