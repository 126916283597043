import * as actionTypes from '../Actions/EventLoggingSearchActionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SEARCH_EVENT_LOGGING:
      return { eventPayload: action.searchEventData };
    case actionTypes.SEARCH_INQUIRY_LOGGING:
      return { inquiryPayload: action.searchInquiryData };
    default: return state;
  }
};

export default reducer;
