/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    onApproveSelect,
    approvedNum,
    approvalCreator,
    approved,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    showHeaderCheckBox,
    disableHeaderCheckBox
  } = props;
  const createSortHandler = property => event => {
    if (event.target.type === 'checkbox') {
      return;
    }
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {!props.isSearch && props.isSearch !== undefined ? (
          <TableCell padding="checkbox" style={{ width: '60px' }}>
            {showHeaderCheckBox ? <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              disabled={disableHeaderCheckBox}
              checked={numSelected === rowCount && numSelected !== 0}
              onChange={onSelectAllClick}
              data-testid='test_header_checkbox'
              color="primary"
              inputProps={{ readOnly: disableHeaderCheckBox, 'aria-label': 'select all desserts' }}
            /> : null}{' '}
            <a aria-hidden="true" hidden="true">
              {' '}
              check box{' '}
            </a>
          </TableCell>
        ) : null}
        {props.headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding="default"
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ width: headCell.width, fontSize: headCell.fontSize }}
          >
            <TableSortLabel
              hideSortIcon={headCell.sortDisable}
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
              data-testid='test_tablesort'
            >
              {headCell.showHeaderCheckBox ?
                <Checkbox 
                  indeterminate={approvedNum > 0 && approvedNum < rowCount}
                  disabled={props?.values?.selectedOption === 'Approved' || approved === rowCount || approvalCreator === rowCount}
                  checked={approvedNum === rowCount && approvedNum !== 0 ? true : false}
                  onChange={onApproveSelect}
                  color="primary"
                  inputProps={{ 'aria-label': 'select all desserts' }}
                  className='mr-2'
                />
                : null
              }
              {headCell.label}
              {orderBy === headCell.id ?
                <span>{order === 'desc' ? '' : ''}</span>
                : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};
