import React, { useState } from 'react';
import Footer from '../../../SharedModules/Layout/footer';

import { useSelector, useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Link, Prompt } from 'react-router-dom';
import { downloadPDFPreview } from '../../../SharedModules/store/Actions/SharedAction';
import { redirectReceiptFCN, redirectPayeeID } from '../../../SharedModules/TabRedirections/TabRedirections';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";

function PaymentStatusInquiryView(props) {
  const dispatch = useDispatch();
    const paymentMethodCode =
    props.dropdowns &&
    props.dropdowns["C5#C_WARR_MEDM_CD"] &&
    props.dropdowns["C5#C_WARR_MEDM_CD"].filter(
      (value) => value.code === props.detailsData.paymentMediumCode
    );
    props.detailsData.paymentMediumCode =
    paymentMethodCode && paymentMethodCode.length > 0
        ? paymentMethodCode[0].description
        : props.detailsData.paymentMediumCode
        ? props.detailsData.paymentMediumCode
        : "";

    const warrantStatusCode =
    props.dropdowns &&
    props.dropdowns["F1#C_WARR_STAT_CD"] &&
    props.dropdowns["F1#C_WARR_STAT_CD"].filter(
      (value) => value.code === props.detailsData.warrStatusCd
    );
    props.detailsData.warrStatusCd =
    warrantStatusCode && warrantStatusCode.length > 0
        ? warrantStatusCode[0].description
        : props.detailsData.warrStatusCd
        ? props.detailsData.warrStatusCd
        : "";

    const reasonCode =
        props.dropdowns &&
        props.dropdowns["F1#F_RSN_CD"] &&
        props.dropdowns["F1#F_RSN_CD"].filter(
          (value) => value.code === props.detailsData.dispositionReasonCode
        );

        props.detailsData.dispositionReasonCode =
        reasonCode && reasonCode.length > 0
        ? reasonCode[0].description
        : props.detailsData.dispositionReasonCode
        ? props.detailsData.dispositionReasonCode
        : "";

  return (
    <div>
      <form autoComplete="off" style={ { paddingLeft: '1%' } }>
     
        <div className="form-wrapper">
          <div className="mui-custom-form  override-width-22 override-m-08">
          <TextField
              id="standard-original1099amount"
              label="Payee Type"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              value={props.detailsData.commonEntityTypeCodeDesc}
              placeholder=""
              inputProps={ { maxlength: 14 } }
              InputLabelProps={ {
                shrink: true
              } }
            ></TextField>
          </div>
          <div className="mui-custom-form  override-width-22 override-m-08">
          <TextField
              id="standard-adjusted-amount"
              label="Payee ID Type Code"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              value={ props.detailsData.entityIDTypeCodeDesc }
              placeholder=""
              inputProps={ { maxlength: 13 } }
              InputLabelProps={ {
                shrink: true
              } }
            ></TextField>
          </div>
          <div className="mui-custom-form  override-width-22 override-m-08">
          <TextField
              id="Payee_ID"
              label="Payee ID"
              InputProps={{ readOnly: true, className: 'Mui-disabled',
                startAdornment: (
                  <InputAdornment position="start">
                    {
                      props.detailsData.entityID !== ''
                        ? <Link to={redirectPayeeID(props.detailsData.entityID)} target="_blank">
                          { props.detailsData.entityID }
                        </Link> : null
                    }
                  </InputAdornment>
                ) }}
              placeholder=""
              inputProps={ { maxlength: 11 } }
              InputLabelProps={ {
                shrink: true
              } }
            ></TextField>
          </div>
          <div className="mui-custom-form  override-width-22 override-m-08">
          <TextField
              id="Payee_Name"
              label="Payee Name"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              value={ props.detailsData.sortName }
              placeholder=""
              inputProps={ { maxlength: 11 } }
              InputLabelProps={ {
                shrink: true
              } }
            ></TextField>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form  override-width-22 override-m-08">
            <TextField
              id="Receipt_FCN"
              label="Receipt FCN"
              InputProps={ { readOnly: true, className: 'Mui-disabled',
                startAdornment: (
                  <InputAdornment position="start">
                    <Link to={redirectReceiptFCN(props.detailsData.financialDetailRef)} target="_blank">
                      {props.detailsData.financialDetailRef}
                    </Link>
                  </InputAdornment>
                )
              }}
              placeholder=""
              inputProps={ { maxlength: 17 } }
              InputLabelProps={ {
                shrink: true
              } }
            ></TextField>
          </div>
          <div className="mui-custom-form  override-width-22 override-m-08">
            <TextField
              id="Receipt_Reason_Code"
              label="Receipt Reason Code"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              value={ props.detailsData.dispositionReasonCode }
              placeholder=""
              inputProps={ { maxlength: 17 } }
              InputLabelProps={ {
                shrink: true
              } }
            ></TextField>
          </div>
          <div className="mui-custom-form  override-width-22 override-m-08">
            <TextField
              id="RA_Number"
              label="RA Number"
              InputProps={{ readOnly: true, className: 'Mui-disabled',
              startAdornment: (
                <InputAdornment position="start">
                  {
                    props.detailsData.raNumber !== '' 
                      ? <Link
                       onClick={ () => { dispatch(downloadPDFPreview(`${process.env.REACT_APP_PAPER_RA_FOLDER}`, props.detailsData.raNumber  + '.pdf')); } }>{ props.detailsData.raNumber  }</Link>
                            : null
                  }                  
                </InputAdornment>
              )
             }}
              placeholder=""
              inputProps={ { maxlength: 17 } }
              InputLabelProps={ {
                shrink: true
              } }
            ></TextField>
          </div>
          <div className="mui-custom-form  override-width-22 override-m-08">
            <TextField
              id="Payment_Method_Code"
              label="Payment Method Code"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              value={ props.detailsData.paymentMediumCode }
              placeholder=""
              inputProps={ { maxlength: 17 } }
              InputLabelProps={ {
                shrink: true
              } }
            ></TextField>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form  override-width-22 override-m-08">
            <TextField
              id="Check/EFT_Number"
              label="Check/EFT Number"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              value={ props.detailsData.warrantNumber }
              placeholder=""
              inputProps={ { maxlength: 17 } }
              InputLabelProps={ {
                shrink: true
              } }
            ></TextField>
          </div>
          <div className="mui-custom-form  override-width-22 override-m-08">
            <TextField
              id="Check/EFT_Status"
              label="Check/EFT Status"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              value={ props.detailsData.warrStatusCd }
              placeholder=""
              inputProps={ { maxlength: 17 } }
              InputLabelProps={ {
                shrink: true
              } }
            ></TextField>
          </div>
          <div className="mui-custom-form  override-width-22 override-m-08">
            <TextField
              id="Payment_Amount"
              label="Payment Amount"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              value={ props.detailsData.warrantAmount }
              placeholder=""
              inputProps={ { maxlength: 17 } }
              InputLabelProps={ {
                shrink: true
              } }
            ></TextField>
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="mui-custom-form  with-date override-width-22 override-m-08">
            <KeyboardDatePicker
              maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
              id="Payment_Date"
              label="Payment Date"
              format="MM/dd/yyyy"
              placeholder="mm/dd/yyyy"
              value={props.detailsData.paymentDate }
              disabled
              InputLabelProps={{
                shrink: true
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
        </div>
      </form>
            {/* <Footer print /> */}
    </div>
  )
}

export default PaymentStatusInquiryView