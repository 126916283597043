/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import Select from "@material-ui/core/Select";
import { Prompt } from "react-router-dom";
import TextTypeData from "./TextTypeData.json";
import PropTypes from "prop-types";
import MUIRichTextEditor from "mui-rte";
import Footer from "../../../../SharedModules/Layout/footer";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "./TextManagement.scss";
import { Editor, EditorState } from "draft-js";
import { Form } from "react-bootstrap";
import * as TextManagementErrors from "./TextManagementErrors";
import CustomizedEditor from "../../../../SharedModules/RichTextEditor/TextEditor";
import {
  DialogContent,
  DialogTitle,
  DialogActions
} from '../../../../SharedModules/Dialog/DialogUtilities';
import numeral from "numeral";
import {
  TextManagementAddProviderNoticeAction,
  ResetData,  
  TextManagementAddRAEOBAction,
  TextManagementAddRemarkTextAction,
  TextManagementAddAdjustmentReasonAction,
  TextManagementAddNCPDPAction,
  TextManagementAddEOMBAction,
  providerNoticeGetRecordAction,
  EOMBGetRecordAction,
  RaeobGetRecordAction,
  RemarkTextGetRecordAction,
  AdjustmentReasonRecordAction,
  NCPDPGetRecordAction
} from "../../Store/Actions/TextManagement/TextManagementAddUpdateActions";
import {
  ProviderSpecialtyDataAction,
  ResetTextSearchReducer
} from "../../Store/Actions/TextManagement/TextManagementActions";
import {
  AppConfigDropdownActions,
  ModifierDropdownActions,
  setFocus
} from "../../Store/Actions/AppConfigCommon/AppConfigActions";
import { connect } from "react-redux";

import moment from "moment";
import * as DateUtils from "../../../../SharedModules/DateUtilities/DateUtilities";
import { withRouter } from "react-router";
import * as AppConstants from "../../../../SharedModules/AppConstants";
import dropdownCriteria from "./TextManagementAddUpdate.json";
import Notes from "../../../../SharedModules/Notes/Notes";
import Radio from '@material-ui/core/Radio';
import NativeDropDown from "../../../../SharedModules/Dropdowns/NativeDropDown";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function dateCheck(date1, date2) {
  date1 = new Date(date1);
  date2 = new Date(date2);
  var dateone = new Date(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate(),
    0,
    0,
    0
  );
  var datetwo = new Date(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate(),
    0,
    0,
    0
  );
  if (dateone < datetwo) {
    return 0;
  } else if (dateone > datetwo) {
    return 1;
  } else {
    return 2;
  }
}

const mapStateToProps = state => ({
  functionalDropDown:
    state.appConfigState.AppConfigCommonState.appConfigDropdown,
    isFocusCheck: state.appConfigState.AppConfigCommonState.isFocusCheck,
  ModifierData: state.appConfigState.AppConfigCommonState.modifierDropdown,
  ProviderSpecialtyData:
    state.appConfigState.textManagementState.ProviderSpecialtyData,
  Result: state.appConfigState.textManagementState.payload
    ? state.appConfigState.textManagementState.payload
    : null,
  Record: state.appConfigState.TextSearchState.Text,
  current: state.sharedState.currentPath,  
  logInUserID: state.sharedState.logInUserID
});

class TextAddUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.toPrintRef = React.createRef();
    this.eombType = "";
    this.DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
    this.eomb_ref = React.createRef();
    this.raeob_ref = React.createRef();
    this.provider_notice_ref = React.createRef();
    this.remark_code_ref = React.createRef();
    this.adjustment_reason_ref = React.createRef();
    this.ncpdp_ref = React.createRef();
    this.notes_ref = React.createRef();
    this.state = {
      TextType: this.DEFAULT_DD_VALUE,
      dialogOpen: false,
      focusCheck: false,
      error_messages: [],      
      redirect: false,
      showMsg: false,
      allowNavigation: false,
      tabValue: 0,
      notesTableData: [],
      tabOne: false,
      value: 0,
      textTypeError: false,
      disableNotes: true,
      notesInput: {
        auditUserID: this.props.logInUserID,
        auditTimeStamp: DateUtils.getUTCTimeStamp(),
        addedAuditUserID: this.props.logInUserID,
        addedAuditTimeStamp: DateUtils.getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        tableName: null,
        noteSetSK: null,
        noteSourceName: null,
        notesList: [],
        globalNotesList: [],
        checkAll: null,
        addNewLinkRender: null,
        filterLinkRender: null,
        printLinkRender: null,
        completeNotesList: []
      },
      noteSetListInput: {
        auditUserID: this.props.logInUserID,
        auditTimeStamp: DateUtils.getUTCTimeStamp(),
        addedAuditUserID: this.props.logInUserID,
        addedAuditTimeStamp: DateUtils.getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        noteTextValue: null,
        userIdName: null,
        notesCexAuditUserID: null,
        notesCexAuditTimeStamp: null,
        notesCexAddedAuditUserID: null,
        notesCexAddedAuditTimeStamp: null,
        noteSetSK: null,
        usageTypeDesc: "",
        shortNotes: null,
        checked: false,
        renderNoHistoryMsg: false,
        noteSequenceNumber: null,
        currentNote: null,
        rowValue: null,
        usageTypeList: null,
        strBeginDate: moment(new Date()).format("MM/DD/YYYY hh:mm:ss"),
        usageTypeCode: this.DEFAULT_DD_VALUE,
        tableName: null,
        noteText: "",
        commonEntityName: null,
        commonEntityTypeCode: null,
        commonEntityId: null,
        entityId: null,
        filterbeginDate: moment(new Date()).format("YYYY-MM-DD"),
        filterEndDate: null,
        userId: this.props.logInUserID,
        noteCexVersionNum: 0,
        saNoteSequenceNumber: null,
        notesCexnoteTextValue: 0,
        id: DateUtils.generateUUID()
      },
      usageTypeCodeData: [],
      editNoteData: {}
    };
  
    this.props.dispatch(AppConfigDropdownActions(dropdownCriteria));    
    this.getProviderSpecialty = this.getProviderSpecialty.bind(this);
    this.validateParentForm = this.validateParentForm.bind(this);
  }
  validateParentForm() {
    if (this.state.TextType === this.DEFAULT_DD_VALUE) {
      this.setState({ disableNotes: true });
    } else {
      this.setState({ disableNotes: false });
    }
  };
  handleChangeTabs = (event, newValue) => {
    this.setState({ value: newValue });
    if (newValue === 0) {
      this.setState({ tabOne: true });
    }
  };  
  MappingTextType(textType) {
    switch (textType) {
      case "2-EOMB":
        return "EOMB";
      case "3-RA EOB":
        return "RA EOB";
      case "4-ProvNotice":
        return "Provider Notice";
      case "5-Remark":
        return "Remark Code";
      case "6-Adj Reason":
        return "Adjustment Reason";
      case "8-NCPDP":
        return "NCPDP Rejection Codes";
      default:
        return "EOMB";
    }
  }
  handleChanges = event => {
    this.props.dispatch(setFocus(true));
    this.props.dispatch(ResetData());
    this.props.dispatch(ResetTextSearchReducer());
    this.setState(
      { error_messages: [], TextType: event.target.value, textTypeError: false },
      () => { }
    );
    this.setState({
      TextType: event.target.value,
      showMsg: false,
      allowNavigation: true,
      redirect: false
    }, () => { this.validateParentForm() });
  };

  setAllowNavigation = event => {
    this.setState({ allowNavigation: true });
  };
  
  setFocusCheck = data => {
    this.setState({isFocusCheck:data});
}
  //set note set list input
  setNoteSetListInput = data => {

    const noteSetListInput = data;
        this.setState({
      noteSetListInput: noteSetListInput
    });
  };

  setErrorMessages = data => {
    this.setState({error_messages: data })
  }

  setErrorNull = event => {
    this.setState({ error_messages: [], textTypeError: false });
    this.props.dispatch(ResetData());
    this.props.dispatch(ResetTextSearchReducer());
    let textType = this.MappingTextType(this.state.TextType);
    if(this.state.TextType !== this.DEFAULT_DD_VALUE){
      switch (textType) {
      case 'EOMB':
        this.eomb_ref.current.resetFieldError();
        break;
      case 'RA EOB':
        this.raeob_ref.current.resetFieldError();
        break;
      case 'Provider Notice':
        this.provider_notice_ref.current.resetFieldError();
        this.provider_notice_ref.current.setErrObject();
        break;
      case 'Remark Code':
        this.remark_code_ref.current.resetFieldError();
        this.remark_code_ref.current.setErrObject();
        break;
      case 'Adjustment Reason':
        this.adjustment_reason_ref.current.resetFieldError();
        this.adjustment_reason_ref.current.setErrObject();
        break;
      case 'NCPDP Rejection Codes':
        this.ncpdp_ref.current.resetFieldError();
        this.ncpdp_ref.current.setErrObject();
        break;
    };
    }
    };
  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (
      nextProps.Result &&
      nextProps.Result.respcode == "01" &&
      nextProps.Record &&
      nextProps.Record !== "" &&
      nextProps.Record[0]
    ) {
      if (nextProps.Record[0].eombType) {
        let eomb = nextProps.Record[0].eombType;
        if (eomb == "H") this.eombType = "hcpcs";
        if (eomb == "I") this.eombType = "surgical-procedure";
        if (eomb == "R") this.eombType = "revenue";
      }
      let textType = "EOMB";
      switch (this.state.TextType) {
        case "2-EOMB":
          textType = "EOMB";
          break;
        case "3-RA EOB":
          textType = "RA EOB";
          break;
        case "4-ProvNotice":
          textType = "Provider Notice";
          break;
        case "5-Remark":
          textType = "Remark Code";
          break;
        case "6-Adj Reason":
          textType = "Adjustment Reason";
          break;
        case "8-NCPDP":
          textType = "NCPDP Rejection Codes";
          break;
        default:
          return "EOMB";
      }
      if (this.state.redirect) {
        this.props.history.push({
          pathname: "/TextUpdate",
          state: {
            row: nextProps.Record[0],
            TextType: textType,
            EombType: this.eombtype,
            message: TextManagementErrors.SUCCESS_MSG,
            isFromAdd:true
          }
        });
      }
    }
  }
    // add notes
  addNotes = data => {
    let notesDataArray = [];
    this.props.dispatch(setFocus(true));
    this.setAllowNavigation();
    const noteText = data;
    let tempNoteSet = this.state.notesInput.notesList;
    tempNoteSet.push(noteText);
    const notesInput = this.state.notesInput;
    notesInput.notesList = tempNoteSet;
    notesDataArray = this.state.notesTableData;
    this.setState({
      notesInput: notesInput,
      notesTableData: notesInput.notesList
    });
  };
  handleSave = event => {    
    this.setState({ error_messages: [], showMsg: true, textTypeError: false }, () => {
      let data = null;
      if (this.state.TextType !== this.DEFAULT_DD_VALUE) {
        let textType = this.MappingTextType(this.state.TextType);
        switch (textType) {
          case "EOMB":
            data = this.eomb_ref.current.searchCheck();
            if (data.length == 0) {
              data = this.eomb_ref.current.onSave();
              if (data) {
                data = { ...data, noteSetVO: this.state.notesInput };
              }
              this.props.dispatch(setFocus(false));
              this.props.dispatch(TextManagementAddEOMBAction(data));
                          } else {
              this.setState({
                error_messages: [...this.state.error_messages, ...data],
                showMsg: false
              });
            }
            break;
          case "RA EOB":
            data = this.raeob_ref.current.searchCheck();
            if (data.length == 0) {
              data = this.raeob_ref.current.onSave();
              if (data) {
                data = { ...data, noteSetVO: this.state.notesInput };
              }
              this.props.dispatch(setFocus(false));
              this.props.dispatch(TextManagementAddRAEOBAction(data));
                          } else {
              this.setState({
                error_messages: [...this.state.error_messages, ...data],
                showMsg: false
              });
            }
            break;
          case "Provider Notice":
            data = this.provider_notice_ref.current.searchCheck();
            if (data.length == 0) {
              this.provider_notice_ref.current.setErrObject();
              data = this.provider_notice_ref.current.onSave();
              if (data) {
                data = { ...data, noteSetVO: this.state.notesInput };
              }
              this.props.dispatch(setFocus(false));
              this.props.dispatch(TextManagementAddProviderNoticeAction(data));
                          } else {
              this.setState({
                error_messages: [...this.state.error_messages, ...data],
                showMsg: false
              });
            }
            break;
          case "Remark Code":
            data = this.remark_code_ref.current.searchCheck();
            if (data.length == 0) {
              this.remark_code_ref.current.setErrObject();
              data = this.remark_code_ref.current.onSave();
              if (data) {
                data = { ...data, noteSetVO: this.state.notesInput };
              }
              this.props.dispatch(setFocus(false));
              this.props.dispatch(TextManagementAddRemarkTextAction(data));
              } else {
              this.setState({
                error_messages: [...this.state.error_messages, ...data]
              });
            }
            break;
          case "Adjustment Reason":
            data = this.adjustment_reason_ref.current.searchCheck();
            if (data.length == 0) {
              this.adjustment_reason_ref.current.setErrObject();
              data = this.adjustment_reason_ref.current.onSave();
              if (data) {
                data = { ...data, noteSetVO: this.state.notesInput };
              }
              this.props.dispatch(setFocus(false));
              this.props.dispatch(
                TextManagementAddAdjustmentReasonAction(data)
              );
            } else {
              this.setState({
                error_messages: [...this.state.error_messages, ...data],
                showMsg: false
              });
            }
            break;
          case "NCPDP Rejection Codes":
            data = this.ncpdp_ref.current.searchCheck();
            if (data.length == 0) {
              this.ncpdp_ref.current.setErrObject();
              data = this.ncpdp_ref.current.onSave();
              if (data) {
                data = { ...data, noteSetVO: this.state.notesInput };
              }
              this.props.dispatch(setFocus(false));
              this.props.dispatch(TextManagementAddNCPDPAction(data));
            } else {
              this.setState({
                error_messages: [...this.state.error_messages, ...data],
                showMsg: false
              });
            }
            break;
        }
        this.setState({ redirect: true, allowNavigation: false }, () => {
        });
      } else {
        let errorMessagesArray = [];
        errorMessagesArray.push(TextManagementErrors.TEXT_TYPE_REQUIRED);
        this.setState({ error_messages: errorMessagesArray, textTypeError: true });
      }
    });
  };
  getProviderSpecialty(value) {
    if (value === this.DEFAULT_DD_VALUE) {
    } else {
      this.props.dispatch(ProviderSpecialtyDataAction(value));
      this.props.dispatch(setFocus(false));
    }
  }

  cancelMinorSection =() => {
    this.setState({ allowNavigation: false });
    setTimeout(()=>{
      this.setState({dialogOpen:false});
      this.props.dispatch(setFocus(false));
      this.props.history.push({
        pathname: '/TextManagementSearch',
        tableRender: false
      });
    },100)
    };
    
    majorCancel =()=> {
    if (this.props.isFocusCheck) {
      this.setState({dialogOpen:true});
    } else {
      this.setState({dialogOpen:false});
      this.props.dispatch(ResetData());
      this.props.history.push({
        pathname: '/TextManagementSearch',
        tableRender: false
      });
    }
  };

  render() {
    return (
      <div className="pos-relative w-100 h-100" ref={this.toPrintRef}>
        <Prompt
          when={this.state.allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />        
        {this.state.error_messages.length > 0 ? (
          <div class="alert alert-danger custom-alert" role="alert">
            {this.state.error_messages.map(message => (
              <li>{message}</li>
            ))}
          </div>
        ) : null}
        {this.state.showMsg ? (
          this.props.Result !== null ? (
            this.props.Result.respcode === "01" ? (
              <div class="alert alert-success custom-alert" role="alert">
                <li>{TextManagementErrors.SUCCESS_MSG}</li>
              </div>
            ) : this.props.Result !== null && this.props.Result.respcode ? (
              <div class="alert alert-danger custom-alert" role="alert">
                {this.props.Result.errorMessages !== null ? (
                  this.props.Result.errorMessages.map(message => (
                    <li>{message}</li>
                  ))
                ) : (
                    <li>{this.props.Result.respdesc}</li>
                  )}
              </div>
            ) : (
                  <div class="alert alert-danger custom-alert" role="alert">
                    <li>{TextManagementErrors.ERR_PROCESSING_REQ}</li>
                  </div>
                )
          ) : null
        ) : null}
        <div className="tabs-container" >
          <div className="tab-header">
            <h1 className="tab-heading float-left">Add Text</h1>
            <div className="float-right mt-2">
              <Button className="btn btn-success" onClick={this.handleSave}>
                <i class="fa fa-check" aria-hidden="true"></i>
                Save
              </Button>
              {window.location.pathname === '/TextAdd' && (
                    <Button title="Cancel" className='btn btn-primary' onClick={this.majorCancel}>Cancel</Button>
                  )}
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="tab-body">
            <form autoComplete="off">
              <div class="form-wrapper">
                  <NativeDropDown
                    id="text-type"
                    isRequired
                    label="Text Type"
                  inputProps={{ 'data-testid': 'text_type_id' }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    errTxt={
                      this.state.textTypeError
                        ? TextManagementErrors.TEXT_TYPE_REQUIRED
                        : null
                    }
                    value={this.state.TextType}
                    onChange={this.handleChanges}
                    dropdownList={this.props.functionalDropDown &&
                      this.props.functionalDropDown.listObj &&
                      this.props.functionalDropDown.listObj["Reference#1130"]
                      ? this.props.functionalDropDown.listObj[
                        "Reference#1130"
                      ].map(option => {
                        if (option.code !== "1" && option.code !== "7") {
                          return (
                            <option
                              key={option.code}
                              value={option.description}
                            >
                              {option.description.split("-")[1]}
                            </option>
                          );
                        }
                      })
                      : null}
                  />
              </div>
            </form>
          </div>
          {(() => {
            if (this.state.TextType != this.DEFAULT_DD_VALUE) {
              return (
                <div className="tab-body">
                  <div className="collapsable-panel">
                    <div className="MuiExpansionPanel-root">
                      <div className="panel-header">
                        <div className="MuiExpansionPanelSummary-content ">
                          <p className="MuiTypography-root">Text Detail</p>
                        </div>
                      </div>
                      <div className="MuiCollapse-container MuiCollapse-entered">
                        <div className="MuiCollapse-wrapper">
                          {(() => {
                            let textType = this.MappingTextType(
                              this.state.TextType
                            );
                            switch (textType) {
                              case "EOMB":
                                return (
                                  <EOMB
                                    ref={this.eomb_ref}
                                    setErrorNull={this.setErrorNull}
                                    dropDown={
                                      this.props.functionalDropDown
                                        ? this.props.functionalDropDown
                                        : null
                                    }
                                    setAllowNavigation={this.setAllowNavigation}
                                  />
                                );
                              case "RA EOB":
                                return (
                                  <RAEOB
                                    ref={this.raeob_ref}
                                    setErrorNull={this.setErrorNull}
                                    dropDown={
                                      this.props.functionalDropDown
                                        ? this.props.functionalDropDown
                                        : null
                                    }
                                    setAllowNavigation={this.setAllowNavigation}
                                  />
                                );
                              case "Provider Notice":
                                return (
                                  <ProviderNotice
                                    ref={this.provider_notice_ref}
                                    getProviderSpecialty={
                                      this.getProviderSpecialty
                                    }
                                    ProviderSpecialtyData={
                                      this.props.ProviderSpecialtyData
                                    }
                                    setErrorNull={this.setErrorNull}
                                    dropDown={
                                      this.props.functionalDropDown
                                        ? this.props.functionalDropDown
                                        : null
                                    }
                                    setAllowNavigation={this.setAllowNavigation}
                                  />
                                );
                              case "Remark Code":
                                return (
                                  <RemarkCode
                                    ref={this.remark_code_ref}
                                    setErrorNull={this.setErrorNull}
                                    setAllowNavigation={this.setAllowNavigation}
                                  />
                                );
                              case "Adjustment Reason":
                                return (
                                  <AdjustmentReason
                                    ref={this.adjustment_reason_ref}
                                    setErrorNull={this.setErrorNull}
                                    setAllowNavigation={this.setAllowNavigation}
                                  />
                                );
                              case "NCPDP Rejection Codes":
                                return (
                                  <NCPDP
                                    ref={this.ncpdp_ref}
                                    setErrorNull={this.setErrorNull}
                                    setAllowNavigation={this.setAllowNavigation}
                                  />
                                );
                              default:
                                return null;
                            }
                          })()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })()}

          <div className="tab-panelbody" style={{ marginTop: "-5px" }}>
            <div className="tab-holder mb-3 mt-2">
              <AppBar position="static">
                <Tabs
                  value={this.state.value}
                  aria-label="simple tabs example"
                  className="tabChange"
                >
                  <Tab label="Notes" />
                </Tabs>
              </AppBar>
              <TabPanel value={this.state.tabValue} index={0}>
                <Notes
                  onChange={this.handleChangeTabs}
                  addNotes={this.addNotes}
                  ref={this.notes_ref}
                  notesTableData={this.state.notesTableData}
                  noteSetListInput={this.state.noteSetListInput}
                  setNoteSetListInput={this.setNoteSetListInput}
                  setFocusCheck={this.setFocusCheck}
                  usageTypeCodeData={
                    this.props.functionalDropDown &&
                      this.props.functionalDropDown.listObj &&
                      this.props.functionalDropDown.listObj["General#1012"]
                      ? this.props.functionalDropDown.listObj[
                      "General#1012"
                      ]
                      : []
                  }
                  editNoteData={this.state.editNoteData}
                  disableNotes={this.state.disableNotes}
                  setSuccessMessages={this.setErrorNull}
                  setErrorMessages={this.setErrorMessages}
                />
              </TabPanel>
            </div>
          </div>

          <Footer print />
          <Dialog
                open={this.state.dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="custom-alert-box"
      >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <>
                      Unsaved changes will be lost. <br />
                      Are you sure you want to continue?
                    </>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
              this.setState({dialogOpen:false});
            }}
                    color="primary"
                    className="btn btn-transparent"
          >
                    STAY ON THIS PAGE!
                  </Button>
                  <Button
                    onClick={this.cancelMinorSection}
                    color="primary"
                    className="btn btn-success"
                    autoFocus
          >
                    CONTINUE <i className="fa fa-arrow-right ml-1" />
                  </Button>
                </DialogActions>
              </Dialog>
        </div>
      </div>
    );
  }
}

class EOMB_ extends React.Component {
  constructor(props) {
    super(props);
    this.total_characters = 320;
    this.dropDown = props.dropDown ? props.dropDown : [];
    this.state = {
      disableFrom: "",
      disableThru: "",
      eombFromCode: "",
      eombThruCode: "",
      eombText: "",
      showDropdown: false,
      wordCount: this.total_characters,
      eombType: "hcpcs",
      mod1: this.DEFAULT_DD_VALUE,
      mod2: this.DEFAULT_DD_VALUE,
      mod3: this.DEFAULT_DD_VALUE,
      mod4: this.DEFAULT_DD_VALUE,
      modt1: this.DEFAULT_DD_VALUE,
      modt2: this.DEFAULT_DD_VALUE,
      modt3: this.DEFAULT_DD_VALUE,
      modt4: this.DEFAULT_DD_VALUE,
      disableFrom: false,
      disableThru: false,
      errorObj: {
        eombFromCode: false,
        eombThruCode: false,
        text: false
      },
      error_msg: {
        eombFromCode: "",
        eombThruCode: "",
        text: ""
      }
    };
    this.props.dispatch(ModifierDropdownActions());
  }
  
  resetFieldError = () => {
    var errorObj = this.state.errorObj;
    var error_msg = this.state.error_msg;
    errorObj.eombFromCode = false;
    errorObj.eombThruCode = false;
    errorObj.text = false;
    error_msg.eombFromCode = "";
    error_msg.eombThruCode = "";
    error_msg.text = ""
    this.setState({ errorObj: errorObj, error_msg: error_msg });
  }
  searchCheck = () => {
    let errorMessagesArray = [];
    var errorObj = this.state.errorObj;
    var error_msg = this.state.error_msg;
    if (
      !this.state.eombFromCode ||
      (this.state.eombFromCode && !this.state.eombFromCode.trim())
    ) {
      errorObj.eombFromCode = true;
      error_msg.eombFromCode =
        "From Code" + TextManagementErrors.Required_Field_Error;
      errorMessagesArray.push(error_msg.eombFromCode);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    } else {
      errorObj.eombFromCode = false;
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    }
    if (
      !this.state.eombThruCode ||
      (this.state.eombFromCode && !this.state.eombFromCode.trim())
    ) {
      if (this.state.eombFromCode) {
        this.setState(
          { eombThruCode: this.state.eombFromCode.toUpperCase() },
          () => {
            this.handleModifiers();
          }
        );
        errorObj.eombThruCode = false;
        error_msg.eombThruCode = "";
        this.setState(
          {
            errorObj: errorObj,
            error_msg: error_msg,
            eombThruCode: this.state.eombFromCode.toUpperCase()
          },
          () => { }
        );
      } else {
        errorObj.eombThruCode = true;
        error_msg.eombThruCode =
          "Thru Code" + TextManagementErrors.Required_Field_Error;
        errorMessagesArray.push(error_msg.eombThruCode);
        this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
      }
    } else {
      if (
        !isNaN(this.state.eombThruCode) &&
        !isNaN(this.state.eombFromCode) &&
        parseInt(this.state.eombThruCode) < parseInt(this.state.eombFromCode)
      ) {
        errorObj.eombFromCode = true;
        if (this.state.eombType === "revenue") {
          error_msg.eombFromCode =
            TextManagementErrors.From_Thru_Equality_Error;
        } else if (this.state.eombType === "surgical-procedure") {
          error_msg.eombFromCode =
            TextManagementErrors.FROM_THRU_EQUAL_ERROR_SP;
        } else {
          error_msg.eombFromCode =
            TextManagementErrors.FROM_THRU_EQUAL_ERROR_HCPCS;
        }
        errorMessagesArray.push(error_msg.eombFromCode);
        this.setState({ errorObj: errorObj, error_msg: error_msg });
      } else if (
        isNaN(this.state.eombThruCode) &&
        isNaN(this.state.eombFromCode) &&
        this.state.eombFromCode.localeCompare(this.state.eombThruCode) === 1
      ) {
        errorObj.eombFromCode = true;
        if (this.state.eombType === "revenue") {
          error_msg.eombFromCode =
            TextManagementErrors.From_Thru_Equality_Error;
        } else if (this.state.eombType === "surgical-procedure") {
          error_msg.eombFromCode =
            TextManagementErrors.FROM_THRU_EQUAL_ERROR_SP;
        } else {
          error_msg.eombFromCode =
            TextManagementErrors.FROM_THRU_EQUAL_ERROR_SP;
        }
        errorMessagesArray.push(error_msg.eombFromCode);
        this.setState({ errorObj: errorObj, error_msg: error_msg });
      }
    }
    if (
      (this.state.eombText && !this.state.eombText.trim()) ||
      !this.state.eombText
    ) {
      errorObj.text = true;
      error_msg.text = "Text" + TextManagementErrors.Required_Field_Error;
      errorMessagesArray.push(error_msg.text);
      this.setState({ errorObj: errorObj, error_msg: error_msg });
    }
    this.eombSort();
    return errorMessagesArray;
  };
  onSave = e => {
    let eombType = "";
    switch (this.state.eombType) {
      case "hcpcs":
        eombType = "H";
        break;
      case "surgical-procedure":
        eombType = "I";
        break;
      case "revenue":
        eombType = "R";
        break;
      default:
        eombType = "H";
    }
    let Array1 = [
      this.state.mod1 !== this.DEFAULT_DD_VALUE ? this.state.mod1 : "",
      this.state.mod2 !== this.DEFAULT_DD_VALUE ? this.state.mod2 : "",
      this.state.mod3 !== this.DEFAULT_DD_VALUE ? this.state.mod3 : "",
      this.state.mod4 !== this.DEFAULT_DD_VALUE ? this.state.mod4 : ""
    ];
    let Array2 = [
      this.state.modt1 !== this.DEFAULT_DD_VALUE ? this.state.modt1 : "",
      this.state.modt2 !== this.DEFAULT_DD_VALUE ? this.state.modt2 : "",
      this.state.modt3 !== this.DEFAULT_DD_VALUE ? this.state.modt3 : "",
      this.state.modt4 !== this.DEFAULT_DD_VALUE ? this.state.modt4 : ""
    ];
    Array1.sort();
    Array2.sort();
    let A1 = [];
    let A2 = [];
    for (let i = 0; i < 4; i++) {
      if (Array1[i] !== "") {
        A1.push(Array1[i]);
      }
      if (Array2[i] !== "") {
        A2.push(Array2[i]);
      }
    }    
    this.props.dispatch(ResetTextSearchReducer());
    return {
      auditUserID: this.props.logInUserID ? this.props.logInUserID : null,
      auditTimeStamp: DateUtils.getUTCTimeStamp(),
      addedAuditUserID: this.props.logInUserID ? this.props.logInUserID : null,
      addedAuditTimeStamp: DateUtils.getUTCTimeStamp(),
      versionNo: 0,
      dbRecord: false,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      eombFromCode: this.state.eombFromCode,
      eombThruCode:
        !this.state.eombFromCode ||
          (this.state.eombFromCode && !this.state.eombFromCode.trim())
          ? this.state.eombFromCode
          : this.state.eombThruCode,
      eombType: eombType,
      lob: AppConstants.DEFAULT_LOB_VALUE,
      eombDesc: this.state.eombText,
      noteSet: null,
      fromModifier1: eombType === "H" ? (A1.length < 0 ? null : A1[0]) : null,
      fromModifier2: eombType === "H" ? (A1.length < 0 ? null : A1[1]) : null,
      fromModifier3: eombType === "H" ? (A1.length < 0 ? null : A1[2]) : null,
      fromModifier4: eombType === "H" ? (A1.length < 0 ? null : A1[3]) : null,
      thruModifier1: eombType === "H" ? (A2.length < 0 ? null : A2[0]) : null,
      thruModifier2: eombType === "H" ? (A2.length < 0 ? null : A2[1]) : null,
      thruModifier3: eombType === "H" ? (A2.length < 0 ? null : A2[2]) : null,
      thruModifier4: eombType === "H" ? (A2.length < 0 ? null : A2[3]) : null
    };
  };
  handleEombType = event => {
    this.props.dispatch(setFocus(true));
    this.props.setAllowNavigation();
    var errorObj = this.state.errorObj;
    if (event.target.name == "eombFromCode") {
      errorObj.eombFromCode = false;
      this.setState({ errorObj: errorObj });
      this.setState({ eombFromCode: event.target.value.toUpperCase() }, () => {
        this.handleModifiers();
      });
    }
    if (event.target.name == "eombThruCode") {
      errorObj.eombThruCode = false;
      this.setState({ errorObj: errorObj });
      this.setState({ eombThruCode: event.target.value.toUpperCase() }, () => {
        this.handleModifiers();
      });
    }
  };
  eombSort = () => {
    let Array1 = [
      this.state.mod1 !== this.DEFAULT_DD_VALUE ? this.state.mod1 : "",
      this.state.mod2 !== this.DEFAULT_DD_VALUE ? this.state.mod2 : "",
      this.state.mod3 !== this.DEFAULT_DD_VALUE ? this.state.mod3 : "",
      this.state.mod4 !== this.DEFAULT_DD_VALUE ? this.state.mod4 : ""
    ];
    let Array2 = [
      this.state.modt1 !== this.DEFAULT_DD_VALUE ? this.state.modt1 : "",
      this.state.modt2 !== this.DEFAULT_DD_VALUE ? this.state.modt2 : "",
      this.state.modt3 !== this.DEFAULT_DD_VALUE ? this.state.modt3 : "",
      this.state.modt4 !== this.DEFAULT_DD_VALUE ? this.state.modt4 : ""
    ];
    Array1.sort();
    Array2.sort();
    let A1 = [];
    let A2 = [];
    for (let i = 0; i < 4; i++) {
      let c1 = 0;
      let c2 = 0;
      if (Array1[i] !== "") {
        A1.push(Array1[i]);
      }
      if (Array2[i] !== "") {
        A2.push(Array2[i]);
      }
    }
    this.setState(
      {
        mod1: A1.length < 0 ? "" : A1[0],
        mod2: A1.length < 1 ? "" : A1[1],
        mod3: A1.length < 2 ? "" : A1[2],
        mod4: A1.length < 3 ? "" : A1[3],
        modt1: A2.length < 0 ? "" : A2[0],
        modt2: A2.length < 1 ? "" : A2[1],
        modt3: A2.length < 2 ? "" : A2[2],
        modt4: A2.length < 3 ? "" : A2[3]
      }
    );
  };
  handleChanges = name => event => {
    this.props.dispatch(setFocus(true));
    this.props.setAllowNavigation();
    var errorObj = this.state.errorObj;
    errorObj.text = false;
    errorObj.eombFromCode = false;
    errorObj.eombThruCode = false;
    this.setState(
      {
        errorObj: errorObj,
        eombFromCode: "",
        eombThruCode: "",
        mod1: this.DEFAULT_DD_VALUE,
        mod2: this.DEFAULT_DD_VALUE,
        mod3: this.DEFAULT_DD_VALUE,
        mod4: this.DEFAULT_DD_VALUE,
        modt1: this.DEFAULT_DD_VALUE,
        modt2: this.DEFAULT_DD_VALUE,
        modt3: this.DEFAULT_DD_VALUE,
        modt4: this.DEFAULT_DD_VALUE,
        eombText: ""
      },
      () => {
        this.handleModifiers();
      }
    );
    this.setState({ eombType: event.target.value });
  };
  handleModifiers = e => {
    this.props.dispatch(setFocus(true));
    if (!this.state.eombFromCode) {
      this.setState({ disableFrom: false });
    } else {
      this.setState({ disableFrom: true });
    }
    if (!this.state.eombThruCode) {
      this.setState({ disableThru: false });
    } else {
      this.setState({ disableThru: true });
    }
  };
  handleField = e => {
    this.props.dispatch(setFocus(true));
    this.props.setAllowNavigation();
    var errorObj = this.state.errorObj;
    let name = e.target.name;
    let value = e.target.value;
    errorObj[name] = false;
    if (name == "eombFromCode" || name == "eombThruCode") {
      this.setState({ [name]: value.toUpperCase(), errorObj: errorObj });
    } else {
      this.setState({ [name]: value, errorObj: errorObj });
    }
  };
  checkWordCount = e => {
    let value = e.target.value;
    let left_Chars = this.total_characters - value.length;
    this.setState({ wordCount: left_Chars, text: value });
  };
  renderSwitch = eombType => {
    switch (eombType) {
      case "hcpcs":
        return (
          <div className="w-100">
            <div className="form-wrapper">
              <div className="mui-custom-form override-width-18 override-m-1">
                <TextField
                  id="eombFromCode"
                  fullWidth
                  name="eombFromCode"
                  value={this.state.eombFromCode}
                  onChange={this.handleEombType}
                  label="From Code"
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 10
                  }}
                  helperText={
                    this.state.errorObj.eombFromCode
                      ? this.state.error_msg.eombFromCode
                      : null
                  }
                  error={
                    this.state.errorObj.eombFromCode
                      ? this.state.error_msg.eombFromCode
                      : null
                  }
                />
              </div>
                <NativeDropDown
                  id="mod1"
                  className="override-width-18 override-m-1"
                  label="Modifier 1"
                  disabled={this.state.disableFrom ? false : true}
                  name="mod1"
                  value={this.state.mod1}
                  onChange={this.handleField}
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={this.props.ModifierData &&
                    this.props.ModifierData.modifierList
                    ? this.props.ModifierData.modifierList.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))
                    : null}
                />
                <NativeDropDown
                  id="mod2"
                  className="override-width-18 override-m-1"
                  label="Modifier 2"
                  disabled={this.state.disableFrom ? false : true}
                  name="mod2"
                  value={this.state.mod2}
                  onChange={this.handleField}
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={this.props.ModifierData &&
                    this.props.ModifierData.modifierList
                    ? this.props.ModifierData.modifierList.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))
                    : null}
                />
                <NativeDropDown
                  id="mod3"
                  className="override-width-18 override-m-1"
                  label="Modifier 3"
                  disabled={this.state.disableFrom ? false : true}
                  name="mod3"
                  value={this.state.mod3}
                  onChange={this.handleField}
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={this.props.ModifierData &&
                    this.props.ModifierData.modifierList
                    ? this.props.ModifierData.modifierList.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))
                    : null}
                />
                <NativeDropDown
                  id="mod4"
                  className="override-width-18 override-m-1"
                  label="Modifier 4"
                  disabled={this.state.disableFrom ? false : true}
                  name="mod4"
                  value={this.state.mod4}
                  onChange={this.handleField}
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={this.props.ModifierData &&
                    this.props.ModifierData.modifierList
                    ? this.props.ModifierData.modifierList.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))
                    : null}
                />
            </div>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="mui-custom-form override-width-18 override-m-1">
                <TextField
                  id="eombThruCode"
                  fullWidth
                  name="eombThruCode"
                  value={this.state.eombThruCode}
                  onChange={this.handleEombType}
                  label="Thru Code"
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 10
                  }}
                  helperText={
                    this.state.errorObj.eombThruCode
                      ? this.state.error_msg.eombThruCode
                      : null
                  }
                  error={
                    this.state.errorObj.eombThruCode
                      ? this.state.error_msg.eombThruCode
                      : null
                  }
                />
              </div>
                <NativeDropDown
                  id="modt1"
                  className="override-width-18 override-m-1"
                  label="Modifier 1"
                  disabled={this.state.disableThru ? false : true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  name="modt1"
                  value={this.state.modt1}
                  onChange={this.handleField}
                  dropdownList={this.props.ModifierData &&
                    this.props.ModifierData.modifierList
                    ? this.props.ModifierData.modifierList.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))
                    : null}
                />
                <NativeDropDown
                  id="modt2"
                  className="override-width-18 override-m-1"
                  label="Modifier 2"
                  disabled={this.state.disableThru ? false : true}
                  name="modt2"
                  value={this.state.modt2}
                  onChange={this.handleField}
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={this.props.ModifierData &&
                    this.props.ModifierData.modifierList
                    ? this.props.ModifierData.modifierList.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))
                    : null}
                />
                <NativeDropDown
                  id="modt3"
                  className="override-width-18 override-m-1"
                  label="Modifier 3"
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={this.state.disableThru ? false : true}
                  name="modt3"
                  value={this.state.modt3}
                  onChange={this.handleField}
                  dropdownList={this.props.ModifierData &&
                    this.props.ModifierData.modifierList
                    ? this.props.ModifierData.modifierList.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))
                    : null}
                />
                <NativeDropDown
                  id="modt4"
                  className="override-width-18 override-m-1"
                  label="Modifier 4"
                  disabled={this.state.disableThru ? false : true}
                  name="modt4"
                  value={this.state.modt4}
                  onChange={this.handleField}
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={this.props.ModifierData &&
                    this.props.ModifierData.modifierList
                    ? this.props.ModifierData.modifierList.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))
                    : null}
                />
            </div>
            <div className="mui-custom-form with-textarea override-width-98 override-m-1">
              <Form.Group>
                <Form.Label
                  for="eomb-text3"
                  className="MuiFormLabel-root small-label"
                >
                  Text <span>*</span>
                </Form.Label>
                <Form.Control
                  id="eomb-text3"
                  as="textarea"
                  rows="4"
                  name="eombText"
                  value={this.state.eombText}
                  onChange={this.handleField}
                  onKeyUp={this.checkWordCount}
                  maxLength={this.total_characters}
                  style={{ width: "100%" }}
                />
                {this.state.errorObj.text > 0 ? (
                  <p
                    class="MuiFormHelperText-root Mui-error Mui-required"
                    role="alert"
                  >
                    {this.state.error_msg.text}
                  </p>
                ) : null}
              </Form.Group>
              <div
                style={{
                  border: "1px solid rgb(169, 169, 169)",
                  borderRadius: ".25rem",
                  paddingLeft: "5px",
                  width: "200px",
                  alignText: "center"
                }}
              >
                Characters Remaining:{this.state.wordCount}
              </div>
            </div>
          </div>
        );
      case "surgical-procedure":
        return (
          <div className="w-100">
            <div className="form-wrapper">
              <div className="mui-custom-form override-m-1">
                <TextField
                  id="eombSPFromCode"
                  fullWidth
                  name="eombFromCode"
                  value={this.state.eombFromCode}
                  label="From Code"
                  onChange={this.handleField}
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 10
                  }}
                  helperText={
                    this.state.errorObj.eombFromCode
                      ? this.state.error_msg.eombFromCode
                      : null
                  }
                  error={
                    this.state.errorObj.eombFromCode
                      ? this.state.error_msg.eombFromCode
                      : null
                  }
                />
              </div>
              <div className="mui-custom-form override-m-1">
                <TextField
                  max={10}
                  id="eombSPThruCode"
                  fullWidth
                  name="eombThruCode"
                  value={this.state.eombThruCode}
                  onChange={this.handleField}
                  required
                  label="Thru Code"
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 10
                  }}
                  helperText={
                    this.state.errorObj.eombThruCode
                      ? this.state.error_msg.eombThruCode
                      : null
                  }
                  error={
                    this.state.errorObj.eombThruCode
                      ? this.state.error_msg.eombThruCode
                      : null
                  }
                />
              </div>
            </div>

            <div className="mui-custom-form with-textarea override-width-95">
              <Form.Group>
                <Form.Label
                  for="eomb-text2"
                  className="MuiFormLabel-root small-label"
                >
                  Text <span>*</span>
                </Form.Label>
                <Form.Control
                  id="eomb-text2"
                  as="textarea"
                  rows="4"
                  onKeyUp={this.checkWordCount}
                  name="eombText"
                  value={this.state.eombText}
                  onChange={this.handleField}
                  style={{ width: "100%" }}
                  maxLength={this.total_characters}
                />
                {this.state.errorObj.text > 0 ? (
                  <p
                    class="MuiFormHelperText-root Mui-error Mui-required"
                    role="alert"
                  >
                    {this.state.error_msg.text}
                  </p>
                ) : null}
              </Form.Group>
              <div
                style={{
                  border: "1px solid rgb(169, 169, 169)",
                  borderRadius: ".25rem",
                  paddingLeft: "5px",
                  width: "200px",
                  alignText: "center"
                }}
              >
                Characters Remaining:{this.state.wordCount}
              </div>
            </div>
          </div>
        );
      case "revenue":
        return (
          <div className="w-100">
            <div className="form-wrapper">
              <div className="mui-custom-form override-m-1">
                <TextField
                  id="eombRFromCode"
                  fullWidth
                  name="eombFromCode"
                  value={this.state.eombFromCode}
                  onChange={this.handleField}
                  label="From Code"
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 10
                  }}
                  helperText={
                    this.state.errorObj.eombFromCode
                      ? this.state.error_msg.eombFromCode
                      : null
                  }
                  error={
                    this.state.errorObj.eombFromCode
                      ? this.state.error_msg.eombFromCode
                      : null
                  }
                />
              </div>
              <div className="mui-custom-form override-m-1">
                <TextField
                  id="eombRThruCode"
                  fullWidth
                  name="eombThruCode"
                  value={this.state.eombThruCode}
                  onChange={this.handleField}
                  required
                  label="Thru Code"
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 10
                  }}
                  helperText={
                    this.state.errorObj.eombThruCode
                      ? this.state.error_msg.eombThruCode
                      : null
                  }
                  error={
                    this.state.errorObj.eombThruCode
                      ? this.state.error_msg.eombThruCode
                      : null
                  }
                />
              </div>
            </div>
            <div className="mui-custom-form with-textarea override-width-95">
              <Form.Group>
                <Form.Label
                  for="eomb-text1"
                  className="MuiFormLabel-root small-label"
                >
                  Text <span>*</span>
                </Form.Label>
                <Form.Control
                  id="eomb-text1"
                  as="textarea"
                  rows="4"
                  name="eombText"
                  value={this.state.eombText}
                  onChange={this.handleField}
                  maxLength={this.total_characters}
                  onKeyUp={this.checkWordCount}
                  style={{ width: "100%" }}
                />
                {this.state.errorObj.text > 0 ? (
                  <p
                    class="MuiFormHelperText-root Mui-error Mui-required"
                    role="alert"
                  >
                    {this.state.error_msg.text}
                  </p>
                ) : null}
              </Form.Group>
              <div
                style={{
                  border: "1px solid rgb(169, 169, 169)",
                  borderRadius: ".25rem",
                  paddingLeft: "5px",
                  width: "200px",
                  alignText: "center"
                }}
              >
                Characters Remaining:{this.state.wordCount}
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <form className="w-100" noValidate style={{ padding: "0px 5px" }}>
        <div className="form-wrapper">
          {this.state.eombType == "hcpcs" ? (
            <div
              className="mui-custom-form with-select override-width-40 override-m-1" //style={{ marginLeft: '146px' }}
            >
              <label className="MuiFormLabel-root small-label">
                EOMB Type<span>*</span>
              </label>
              <div className='sub-radio' style={{ paddingTop: '5px', marginLeft: '0px' }}>
                <Radio
                  id="standard-radio-hcpcs"
                  defaultChecked="true"
                  value="hcpcs"
                  name="eombType"
                  checked={this.state.eombType === 'hcpcs'}
                  onChange={this.handleChanges("selectedOption")}
                  style={{ marginLeft: "0px" }}
                />
                <label for="standard-radio-hcpcs" className="text-black">
                  HCPCS
                </label>
                <Radio
                  id="standard-radio-surgical"
                  value="surgical-procedure"
                  name="eombType"
                  className="ml-2"
                  checked={this.state.eombType === 'surgical-procedure'}
                  onChange={this.handleChanges("selectedOption")}
                  style={{ marginLeft: "0px" }}
                />
                <label for="standard-radio-surgical" className="text-black">
                  Surgical Procedure
                </label>
                <Radio
                  id="standard-radio-revenue"
                  value="revenue"
                  className="ml-2"
                  name="eombType"
                  checked={this.state.eombType === 'revenue'}
                  onChange={this.handleChanges("selectedOption")}
                  style={{ marginLeft: "0px" }}
                />
                <label for="standard-radio-revenue" className="text-black">
                  Revenue
                </label>
              </div>
            </div>
          ) : (
              <div
                className="mui-custom-form with-select override-width-40 override-m-1" //style={{ marginLeft: '20px' }}
              >
                <label className="MuiFormLabel-root small-label">
                  EOMB Type<span>*</span>
                </label>
                <div className='sub-radio' style={{ paddingTop: '5px', marginLeft: '0px' }}>
                  <Radio
                    id="standard-radio-hcpcs1"
                    defaultChecked="true"
                    value="hcpcs"
                    name="eombType"
                    checked={this.state.eombType === 'hcpcs'}
                    onChange={this.handleChanges("selectedOption")}
                    style={{ marginLeft: "0px" }}
                  />
                  <label for="standard-radio-hcpcs1" className="text-black">
                    {" "}
                    HCPCS
                </label>
                  <Radio
                    id="standard-radio-surgical1"
                    value="surgical-procedure"
                    className="ml-2"
                    name="eombType"
                    checked={this.state.eombType === 'surgical-procedure'}
                    onChange={this.handleChanges("selectedOption")}
                  />
                  <label for="standard-radio-surgical1" className="text-black">
                    {" "}
                    Surgical Procedure
                </label>
                  <Radio
                    id="standard-radio-revenue1"
                    value="revenue"
                    className="ml-2"
                    name="eombType"
                    checked={this.state.eombType === 'revenue'}
                    onChange={this.handleChanges("selectedOption")}
                  />
                  <label for="standard-radio-revenue1" className="text-black">
                    {" "}
                    Revenue
                </label>
                </div>
              </div>
            )}
        </div>
        <div className="form-wrapper">
          {this.renderSwitch(this.state.eombType)}
        </div>
      </form>
    );
  }
}

//RAEOB Class component
class RAEOB_ extends React.Component {
  constructor(props) {
    super(props);
    this.total_characters = 320;
    this.dropDown = props.dropDown ? props.dropDown : [];
    this.state = {
      eobCode: "",
      text: "",
      showDropdown: false,
      wordCount: this.total_characters,
      errorObj: {
        eobCode: false,
        text: false
      },
      error_msg: {
        eobCode: "",
        text: ""
      }
    };
    this.value = 0;
  }
  componentDidMount() {
    if (this.props.dropDown) {
      this.setState({ showDropdown: true });
    }
  }
  resetFieldError = () => {
    var errorObj = this.state.errorObj;
    var error_msg = this.state.error_msg;
    errorObj.eobCode = false;
    errorObj.text = false;
    error_msg.eobCode = "";
    error_msg.text = ""
    this.setState({ errorObj: errorObj, error_msg: error_msg });
  }
  searchCheck = () => {
    let errorMessagesArray = [];
    var errorObj = this.state.errorObj;
    var error_msg = this.state.error_msg;
    if (
      !this.state.eobCode ||
      (this.state.eobCode && !this.state.eobCode.trim())
    ) {
      errorObj.eobCode = true;
      error_msg.eobCode =
        "EOB Code" + TextManagementErrors.Required_Field_Error;
      errorMessagesArray.push(error_msg.eobCode);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    }
    if ((this.state.text && !this.state.text.trim()) || !this.state.text) {
      errorObj.text = true;
      error_msg.text = "Text" + TextManagementErrors.Required_Field_Error;
      errorMessagesArray.push(error_msg.text);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    }
    return errorMessagesArray;
  };
  onSave = e => {
    
    this.props.dispatch(ResetTextSearchReducer());
    return {
      auditUserID: this.props.logInUserID ? this.props.logInUserID : null,
      auditTimeStamp: DateUtils.getUTCTimeStamp(),
      addedAuditUserID: this.props.logInUserID ? this.props.logInUserID : null,
      addedAuditTimeStamp: DateUtils.getUTCTimeStamp(),
      versionNo: 0,
      dbRecord: false,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      claimEOBCode: this.state.eobCode,
      checkClmExc: null,
      eobDesc: this.state.text,
      eobType: null,
      eobBeginDate: null,
      eobEndDate: null,
      lobCode: AppConstants.DEFAULT_LOB_VALUE,
      noteSet: null
    };
  };

  checkWordCount = e => {
    let value = e.target.value;
    let left_Chars = this.total_characters - value.length;
    this.setState({ wordCount: left_Chars, text: value });
  };
  handleField = e => {
    this.props.setAllowNavigation();
    let name = e.target.name;
    let errorObj = this.state.errorObj;
    errorObj[name] = false;
    let value = e.target.value;
    if (name == "eobCode") {
      this.setState({ [name]: value.toUpperCase(), errorObj: errorObj });
    } else {
      this.setState({ [name]: value, errorObj: errorObj });
    }
  };
  handleTabs = e => {
    this.value = e.target.value;
  };
  render() {
    return (
      <div style={{ width: "100%" }}>
        {/* <AppBar position="static">
                    <Tabs value={this.value} onChange={this.handleTabs} aria-label="simple tabs example" className="tabChange">
                        <Tab label="Text Detail" />
                        <Tab label="Notes" />
                        <Tab label="Attachments" />
                        <Tab label="Audit Log" />
                    </Tabs>
                </AppBar> */}
        {/* <TabPanel value={this.value} index={0}> */}
        <div className="row">
          <form
            autoComplete="off"
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "space-evenly"
            }}
            noValidate
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start"
              }}
            >
              <div className="mui-custom-form input-sm">
                <TextField
                  required
                  id="eobCode"
                  fullWidth
                  label="EOB Code"
                  name="eobCode"
                  value={this.state.eobCode}
                  onChange={this.handleField}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 6
                  }}
                  helperText={
                    this.state.errorObj.eobCode
                      ? this.state.error_msg.eobCode
                      : null
                  }
                  error={
                    this.state.errorObj.eobCode
                      ? this.state.error_msg.eobCode
                      : null
                  }
                ></TextField>
              </div>
            </div>
            <div className="mui-custom-form with-textarea override-width-95">
              {/* <label>Text*</label> */}
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label
                  for="raeob-text"
                  className="MuiFormLabel-root small-label"
                >
                  Text <span>*</span>
                </Form.Label>
                <Form.Control
                  id="raeob-text"
                  as="textarea"
                  rows="4"
                  name="text"
                  maxLength={this.total_characters}
                  onKeyUp={this.checkWordCount}
                  onChange={this.handleField}
                  value={this.state.text}
                  className="text-area-text"
                />
                {this.state.errorObj.text > 0 ? (
                  <p
                    class="MuiFormHelperText-root Mui-error Mui-required"
                    role="alert"
                  >
                    {this.state.error_msg.text}
                  </p>
                ) : null}
              </Form.Group>
              <div
                style={{
                  border: "1px solid rgb(169, 169, 169)",
                  borderRadius: ".25rem",
                  paddingLeft: "5px",
                  width: "220px",
                  alignText: "center"
                }}
              >
                Characters Remaining:{this.state.wordCount}
              </div>
            </div>
          </form>
          {/* </TabPanel> */}
          {/* <TabPanel value={this.value} index={1}> */}
          {/* <div>
                        Notes
                    </div> */}
          {/* </TabPanel> */}
        </div>
      </div>
    );
  }
}
//RAEOB class component ends

class ProviderNotice_ extends React.Component {
  constructor(props) {
    super(props);
    this.total_characters = 4000;
    this.DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
    this.state = {
      provider_type: this.DEFAULT_DD_VALUE,
      provider_specialty: this.DEFAULT_DD_VALUE,
      begin_date: null,
      end_date: null,
      text: "",
      showDropdown: false,
      word_count: this.total_characters,
      errorObj: {
        begin_date: false,
        end_date: false,
        text: false
      },
      error_msg: {
        begin_date: "",
        end_date: "",
        text: ""
      }
    };
    this.editorData = this.editorData.bind(this);
    this.dropDown =
      props.dropDown != undefined && props.dropDown != null
        ? props.dropDown
        : null;
  }
  resetFieldError = () => {
    var errorObj = this.state.errorObj;
    var error_msg = this.state.error_msg;
    errorObj.begin_date = false;
    errorObj.end_date = false;
    errorObj.text = false;
    error_msg.begin_date = '';
    error_msg.end_date = '';
    error_msg.text = '';
    this.setState({ errorObj: errorObj, error_msg: error_msg });
  }
  componentDidMount() {
    if (this.props.dropDown) {
      this.setState({ showDropdown: true });
    }
  }
  getProviderSpecialty = value => {
    this.setState({ setShow: false });
    let providertype = this.props.functionalDropDown.listObj[
      "Provider#11"
    ].filter(data => data.code === value);
    this.setState({ provider_specialty: this.DEFAULT_DD_VALUE });
    this.props.getProviderSpecialty(providertype[0]);
    setTimeout(
      function () {
        this.setState({ setShow: true });
      }.bind(this),
      2500
    );
  };
  setErrObject() {
    var errorObj = this.state.errorObj;
    errorObj.begin_date = false;
    errorObj.end_date = false;
    errorObj.text = false;
    this.setState({ errorObj: errorObj });
  }
  searchCheck = () => {
    let errorMessagesArray = [];
    var errorObj = this.state.errorObj;
    var error_msg = this.state.error_msg;
    var current_date = new Date();
    let date_format_regex = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/;
    if (!this.state.begin_date) {
      errorObj.begin_date = true;
      error_msg.begin_date =
        "Begin Date" + TextManagementErrors.Required_Field_Error;
      errorMessagesArray.push(
        "Begin Date" + TextManagementErrors.Required_Field_Error
      );
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    } else if (this.state.begin_date.toString() == "Invalid Date") {
      errorObj.begin_date = true;
      error_msg.begin_date =
        TextManagementErrors.Invalid_Format_Error_BeginDate;
      errorMessagesArray.push(error_msg.begin_date);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    } else if (DateUtils.validateDateMinimumValue(this.state.begin_date)) {
      errorObj.begin_date = true;
      error_msg.begin_date = AppConstants.DATE_ERROR_1964;
      errorMessagesArray.push(error_msg.begin_date);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    } else if (dateCheck(new Date(this.state.begin_date), new Date()) == 0) {
      errorObj.begin_date = true;
      error_msg.begin_date = TextManagementErrors.Current_Date_Error;
      errorMessagesArray.push(TextManagementErrors.Current_Date_Error);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    } else if (
      this.state.end_date != null &&
      dateCheck(this.state.begin_date, this.state.end_date) == 1
    ) {
      errorObj.begin_date = true;
      error_msg.begin_date = TextManagementErrors.Begin_End_Date_Error;
      errorMessagesArray.push(TextManagementErrors.Begin_End_Date_Error);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    }
    if (!this.state.end_date) {
      errorObj.end_date = true;
      error_msg.end_date =
        "End Date" + TextManagementErrors.Required_Field_Error;
      errorMessagesArray.push(
        "End Date" + TextManagementErrors.Required_Field_Error
      );
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    } else if (this.state.end_date.toString() == "Invalid Date") {
      errorObj.end_date = true;
      error_msg.end_date = TextManagementErrors.Invalid_Format_Error_EndDate;
      errorMessagesArray.push(error_msg.end_date);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    } else if (DateUtils.validateDateMinimumValue(this.state.end_date)) {
      errorObj.end_date = true;
      error_msg.end_date = AppConstants.DATE_ERROR_1964;
      errorMessagesArray.push(error_msg.end_date);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    }
    if (
      (this.state.text &&
        !this.state.text.replace(/<\/?[^>]+(>|$)/g, "").trim()) ||
      !this.state.text
    ) {
      errorObj.text = true;
      error_msg.text = "Text" + TextManagementErrors.Required_Field_Error;
      errorMessagesArray.push(error_msg.text);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    }
    return errorMessagesArray;
  };
  onSave = e => {
    
    this.props.dispatch(ResetTextSearchReducer());
    let providertype = null;
    let providerSpeciality = null;
    if (
      this.state.provider_type !== null &&
      this.state.provider_type !== this.DEFAULT_DD_VALUE
    ) {
      providertype = this.props.functionalDropDown.listObj[
        "Provider#11"
      ].filter(data => data.code === this.state.provider_type);
    }
    if (
      this.state.provider_specialty !== null &&
      this.state.provider_specialty !== this.DEFAULT_DD_VALUE &&
      this.props.ProviderSpecialtyData
    ) {
      providerSpeciality = this.props.ProviderSpecialtyData.filter(
        data => this.state.provider_specialty === data.code
      );
    }
    return {
      auditUserID: this.props.logInUserID ? this.props.logInUserID : null,
      auditTimeStamp: DateUtils.getUTCTimeStamp(),
      addedAuditUserID: this.props.logInUserID ? this.props.logInUserID : null,
      addedAuditTimeStamp: DateUtils.getUTCTimeStamp(),
      versionNo: 0,
      dbRecord: false,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      beginDate: moment(this.state.begin_date).format("MM/DD/YYYY"),
      endDate: moment(this.state.end_date).format("MM/DD/YYYY"),
      voidDate: null,
      textRALetterSK: "",
      providerNoticeText: this.state.text,
      providerNoticeTypeDesc:
        this.state.provider_type !== this.DEFAULT_DD_VALUE
          ? providertype && providertype[0]
            ? providertype[0].description
            : null
          : null,
      providerNoticeSpecialityDesc:
        this.state.provider_specialty !== this.DEFAULT_DD_VALUE
          ? providerSpeciality && providerSpeciality[0]
            ? providerSpeciality[0].description
            : null
          : null,
      providerSpeciality:
        this.state.provider_specialty !== this.DEFAULT_DD_VALUE
          ? providerSpeciality && providerSpeciality[0]
            ? providerSpeciality[0].code
            : null
          : null,
      providerType:
        this.state.provider_type != this.DEFAULT_DD_VALUE
          ? providertype && providertype[0]
            ? providertype[0].code
            : null
          : null,
      lobCode: AppConstants.DEFAULT_LOB_VALUE,
      noteSet: null,
      disableVoidFlag: false,
      providerVoid: false,
      inputtaxtFlag: false
    };
  };
  handleField = e => {
    this.props.dispatch(setFocus(true));
    this.props.setAllowNavigation();
    let value = e.target.value;
    let name = e.target.name;
    let errorObj = this.state.errorObj;
    errorObj[name] = false;
    if (name === "provider_type") {
      if (value !== this.DEFAULT_DD_VALUE) {
        this.setState({ [name]: value, errorObj: errorObj }, () => {
          this.getProviderSpecialty(value);
        });
      } else {
        this.setState({ provider_specialty: this.DEFAULT_DD_VALUE });
      }
    }
    this.setState({ [name]: value, errorObj: errorObj });
  };
  
  handleDate = e => {
    this.props.dispatch(setFocus(true));
    this.props.setAllowNavigation();
    let errorObj = this.state.errorObj;
    errorObj.begin_date = false;
    this.setState({ begin_date: e, errorObj: errorObj });
  };
  handleEndDate = e => {
    this.props.dispatch(setFocus(true));
    this.props.setAllowNavigation();
    let errorObj = this.state.errorObj;
    errorObj.end_date = false;
    this.setState({ end_date: e, errorObj: errorObj });
  };
  editorData = (data, editorData) => {
    this.props.setAllowNavigation();
    let errorObj = this.state.errorObj;
    errorObj.text = false;
    this.setState({ text: editorData, errorObj: errorObj }, () => {
      let value = data;
      let left_Chars = this.total_characters - value.length;
      this.setState({ word_count: left_Chars, text: editorData });
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <div className="w-100" style={{ padding: "0px 2px" }}>
        <div className="row">
          <form
            autoComplete="off"
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
            noValidate
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap"
              }}
              className="form-170"
            >
              <NativeDropDown
                  id="provider_type"
                  label="Provider Type"
                  name="provider_type"
                  value={this.state.provider_type}
                  onChange={this.handleField}
                  onClick={this.handleField}
                  InputLabelProps={{
                    shrink: true
                  }}
                  SelectProps={{
                    MenuProps: {
                    }
                  }}
                  dropdownList={this.props.functionalDropDown &&
                    this.props.functionalDropDown.listObj &&
                    this.props.functionalDropDown.listObj["Provider#11"]
                    ? this.props.functionalDropDown.listObj["Provider#11"].map(
                      (item, index) => (
                        <option key={index} value={item.code}>
                          {item.description}
                        </option>
                      )
                    )
                    : null}
                />
                <NativeDropDown
                  id="provider_specialty"
                  label="Provider Specialty"
                  InputProps={{
                    readOnly: this.state.provider_type == this.DEFAULT_DD_VALUE,
                    className:
                      this.state.provider_type == this.DEFAULT_DD_VALUE
                        ? "Mui-disabled"
                        : ""
                  }}
                  name="provider_specialty"
                  disabled={this.state.provider_type ? false : true}
                  value={this.state.provider_specialty}
                  onChange={this.handleField}
                  InputLabelProps={{
                    shrink: true
                  }}
                  SelectProps={{
                    MenuProps: {
                    }
                  }}
                  dropdownList={this.props.ProviderSpecialtyData &&
                    !this.props.ProviderSpecialtyData.systemError
                    ? this.props.ProviderSpecialtyData.map((item, index) => (
                      <option key={index} value={item.code}>
                        {item.description}
                      </option>
                    ))
                    : null}
                />
              <div className="mui-custom-form with-date">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                    id="begin_date"
                    label="Begin Date"
                    format="MM/dd/yyyy"
                    required
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder="mm/dd/yyyy"
                    name="begin_date"
                    value={this.state.begin_date}
                    onChange={this.handleDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    helperText={
                      this.state.errorObj.begin_date
                        ? this.state.error_msg.begin_date
                        : null
                    }
                    error={
                      this.state.errorObj.begin_date
                        ? TextManagementErrors.Required_Field_Error
                        : null
                    }
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="mui-custom-form with-date">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                    id="end_date"
                    label="End Date"
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    required
                    InputLabelProps={{
                      shrink: true
                    }}
                    name="end_date"
                    value={this.state.end_date}
                    onChange={this.handleEndDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    helperText={
                      this.state.errorObj.end_date
                        ? this.state.error_msg.end_date
                        : null
                    }
                    error={
                      this.state.errorObj.end_date
                        ? TextManagementErrors.Required_Field_Error
                        : null
                    }
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className="mui-custom-form with-textarea override-width-95">
              <label className="MuiFormLabel-root small-label" id="text-descr-01">
                Text<span>*</span>
              </label>
              <div
                style={{
                  border: "1px solid rgb(169, 169, 169)",
                  borderRadius: ".25rem"
                }}
              >
                <CustomizedEditor
                  ariaLabel="text-descr-01"
                  ariaDescribedBy="text-descr-01"
                  className="ram"
                  total_characters={this.total_characters}
                  editorData={this.editorData}
                  editorState={null}
                />
              </div>

              {this.state.errorObj.text > 0 ? (
                <p
                  class="MuiFormHelperText-root Mui-error Mui-required"
                  role="alert"
                >
                  {this.state.error_msg.text}
                </p>
              ) : null}
              <div
                style={{
                  border: "1px solid rgb(169, 169, 169)",
                  borderRadius: ".25rem",
                  paddingLeft: "5px",
                  marginTop: "5px",
                  width: "220px",
                  alignText: "center"
                }}
              >
                Characters Remaining:{this.state.word_count}
              </div>
            </div>
            {/* <div style={{ border: '1px solid rgb(169, 169, 169)', borderRadius: '.25rem', paddingLeft: '5px', margin: '10px', width: '200px', alignText: 'center' }}>Characters Remaining:{this.state.word_count}</div> */}
          </form>
        </div>
      </div>
    );
  }
}

class RemarkCode_ extends React.Component {
  constructor(props) {
    super(props);
    this.total_characters = 4000;
    this.state = {
      remark_code: "",
      begin_date: null,
      text: "",
      word_count: this.total_characters,
      errorObj: {
        remark_code: false,
        begin_date: false,
        text: false
      },
      error_msg: {
        remark_code: "",
        begin_date: "",
        text: ""
      }
    };
  }
  resetFieldError = () => {
    var errorObj = this.state.errorObj;
    var error_msg = this.state.error_msg;
    errorObj.remark_code = false;
    errorObj.begin_date = false;
    errorObj.text = false;
    error_msg.remark_code = '';
    error_msg.begin_date = '';
    error_msg.text = '';
    this.setState({ errorObj: errorObj, error_msg: error_msg });
  }
  setErrObject() {
    var errorObj = this.state.errorObj;
    errorObj.remark_code = false;
    errorObj.begin_date = false;
    errorObj.termination_date = false;
    errorObj.text = false;
    this.setState({ errorObj: errorObj });
  }
  searchCheck = () => {
    let errorMessagesArray = [];
    var errorObj = this.state.errorObj;
    var error_msg = this.state.error_msg;
    if (
      (this.state.remark_code && !this.state.remark_code.trim()) ||
      !this.state.remark_code
    ) {
      errorObj.remark_code = true;
      error_msg.remark_code =
        "Remark Code" + TextManagementErrors.Required_Field_Error;
      errorMessagesArray.push(error_msg.remark_code);
      this.setState({ errorObj: errorObj }, () => { });
    }
    if (!this.state.begin_date) {
      errorObj.begin_date = true;
      error_msg.begin_date =
        "Begin Date" + TextManagementErrors.Required_Field_Error;
      errorMessagesArray.push(error_msg.begin_date);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    } else if (this.state.begin_date.toString() == "Invalid Date") {
      errorObj.begin_date = true;
      error_msg.begin_date =
        TextManagementErrors.Invalid_Format_Error_BeginDate;
      errorMessagesArray.push(error_msg.begin_date);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    } else if (DateUtils.validateDateMinimumValue(this.state.begin_date)) {
      errorObj.begin_date = true;
      error_msg.begin_date = AppConstants.DATE_ERROR_1964;
      errorMessagesArray.push(error_msg.begin_date);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    }
    if ((this.state.text && !this.state.text.trim()) || !this.state.text) {
      errorObj.text = true;
      error_msg.text = "Text" + TextManagementErrors.Required_Field_Error;
      errorMessagesArray.push(
        "Text" + TextManagementErrors.Required_Field_Error
      );
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    }
    return errorMessagesArray;
  };
  onSave = e => {
    
    this.props.dispatch(ResetTextSearchReducer());
    return {
      auditUserID: this.props.logInUserID ? this.props.logInUserID : null,
      auditTimeStamp: DateUtils.getUTCTimeStamp(),
      addedAuditUserID: this.props.logInUserID ? this.props.logInUserID : null,
      addedAuditTimeStamp: DateUtils.getUTCTimeStamp(),
      versionNo: 0,
      dbRecord: false,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      beginDate: moment(this.state.begin_date).format("MM/DD/YYYY"),
      remarkCode: this.state.remark_code,
      remarkText: this.state.text,
      checkClmExc: null,
      terminationDate: "12/31/9999",
      tempBeginDate: null,
      tempTermDate: null,
      remarkCodeSeqNo: null
    };
  };

  checkWordCount = e => {
    let value = e.target.value;
    value = value.split(" ").join(" ");
    this.setState({ word_count: this.total_characters - value.length });
  };
  handleField = e => {
    this.props.dispatch(setFocus(true));
    this.props.setAllowNavigation();
    let value = e.target.value;
    let name = e.target.name;
    let errorObj = this.state.errorObj;
    errorObj[name] = false;
    if (name == "remark_code") {
      this.setState({ [name]: value.toUpperCase(), errorObj: errorObj });
    } else {
      this.setState({ [name]: value, errorObj: errorObj });
    }
  };
  handleDate = e => {
    this.props.dispatch(setFocus(true));
    this.props.setAllowNavigation();
    let errorObj = this.state.errorObj;
    errorObj.begin_date = false;
    this.setState({ begin_date: e, errorObj: errorObj });
  };

  render() {
    return (
      <div className="w-100">
        <div className="row">
          <div style={{ display: "flex", width: "100%" }}>
            <div className="mui-custom-form input-md">
              <TextField
                id="remark_code"
                fullWidth
                label="Remark Code"
                required
                name="remark_code"
                value={this.state.remark_code}
                onChange={this.handleField}
                InputLabelProps={{
                  shrink: true
                }}
                helperText={
                  this.state.errorObj.remark_code
                    ? this.state.error_msg.remark_code
                    : null
                }
                error={
                  this.state.errorObj.remark_code
                    ? this.state.error_msg.remark_code
                    : null
                }
                inputProps={{
                  maxLength: 5
                }}
              />
            </div>
            <div className="mui-custom-form with-date">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                  id="begin_date"
                  label="Begin Date"
                  format="MM/dd/yyyy"
                  required
                  placeholder="mm/dd/yyyy"
                  InputLabelProps={{
                    shrink: true
                  }}
                  name="begin_date"
                  value={this.state.begin_date}
                  onChange={this.handleDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  helperText={
                    this.state.errorObj.begin_date
                      ? this.state.error_msg.begin_date
                      : null
                  }
                  error={
                    this.state.errorObj.begin_date
                      ? this.state.error_msg.begin_date
                      : null
                  }
                />
              </MuiPickersUtilsProvider>
            </div>
            {/* </div> */}
          </div>
        </div>
        <div className="row">
          <div className="mui-custom-form with-textarea override-width-95">
            {/* <label>Text*</label> */}
            <Form.Group>
              <Form.Label
                for="remarkcode-text"
                className="MuiFormLabel-root small-label"
              >
                Text <span>*</span>
              </Form.Label>
              <Form.Control
                id="remarkcode-text"
                as="textarea"
                onChange={this.handleField}
                name="text"
                value={this.state.text}
                rows="4"
                maxLength={this.total_characters}
                onKeyUp={this.checkWordCount}
              />
              {this.state.errorObj.text > 0 ? (
                <p
                  class="MuiFormHelperText-root Mui-error Mui-required"
                  role="alert"
                >
                  {this.state.error_msg.text}
                </p>
              ) : null}
            </Form.Group>
            <div
              style={{
                border: "1px solid rgb(169, 169, 169)",
                borderRadius: ".25rem",
                paddingLeft: "5px",
                width: "200px",
                alignText: "center"
              }}
            >
              Characters Remaining:{this.state.word_count}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class AdjustmentReason_ extends React.Component {
  constructor(props) {
    super(props);
    this.total_characters = 4000;
    this.state = {
      adjustment_reason_code: "",
      begin_date: null,
      text: "",
      word_count: this.total_characters,
      errorObj: {
        adjustment_reason_code: false,
        begin_date: false,
        text: false
      },
      error_msg: {
        adjustment_reason_code: "",
        begin_date: "",
        text: ""
      }
    };
  }
  resetFieldError = () => {
    var errorObj = this.state.errorObj;
    var error_msg = this.state.error_msg;
    errorObj.adjustment_reason_code = false;
    errorObj.begin_date = false;
    errorObj.text = false;
    error_msg.adjustment_reason_code = '';
    error_msg.begin_date = '';
    error_msg.text = '';
    this.setState({ errorObj: errorObj, error_msg: error_msg });
  }
  setErrObject() {
    var errorObj = this.state.errorObj;
    errorObj.adjustment_reason_code = false;
    errorObj.begin_date = false;
    errorObj.termination_date = false;
    errorObj.text = false;
    this.setState({ errorObj: errorObj });
  }
  searchCheck = () => {
    let errorMessagesArray = [];
    var errorObj = this.state.errorObj;
    var error_msg = this.state.error_msg;
    if (
      (this.state.adjustment_reason_code &&
        !this.state.adjustment_reason_code.trim()) ||
      !this.state.adjustment_reason_code
    ) {
      errorObj.adjustment_reason_code = true;
      error_msg.adjustment_reason_code =
        "Adjustment Reason code" + TextManagementErrors.Required_Field_Error;
      errorMessagesArray.push(error_msg.adjustment_reason_code);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    }
    if (!this.state.begin_date) {
      errorObj.begin_date = true;
      error_msg.begin_date =
        "Begin Date" + TextManagementErrors.Required_Field_Error;
      errorMessagesArray.push(error_msg.begin_date);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    } else if (this.state.begin_date.toString() == "Invalid Date") {
      errorObj.begin_date = true;
      error_msg.begin_date =
        TextManagementErrors.Invalid_Format_Error_BeginDate;
      errorMessagesArray.push(error_msg.begin_date);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    } else if (DateUtils.validateDateMinimumValue(this.state.begin_date)) {
      errorObj.begin_date = true;
      error_msg.begin_date = AppConstants.DATE_ERROR_1964;
      errorMessagesArray.push(error_msg.begin_date);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    }
    if ((this.state.text && !this.state.text.trim()) || !this.state.text) {
      errorObj.text = true;
      error_msg.text = "Text" + TextManagementErrors.Required_Field_Error;
      errorMessagesArray.push(error_msg.text);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    }
    return errorMessagesArray;
  };
  onSave = e => {
    
    this.props.dispatch(ResetTextSearchReducer());
    return {
      auditUserID: this.props.logInUserID ? this.props.logInUserID : null,
      auditTimeStamp: DateUtils.getUTCTimeStamp(),
      addedAuditUserID: this.props.logInUserID ? this.props.logInUserID : null,
      addedAuditTimeStamp: DateUtils.getUTCTimeStamp(),
      versionNo: 0,
      dbRecord: false,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      adjustmentReasonCode: this.state.adjustment_reason_code,
      adjustmentReasonText: this.state.text,
      noteSet: null,
      checkClmExc: null,
      terminationDate: "12/31/9999",
      beginDate: moment(this.state.begin_date).format("MM/DD/YYYY"),
      adjstResnSeqNo: 1
    };
  };

  checkWordCount = e => {
    let value = e.target.value;
    value = value.split(" ").join(" ");
    this.setState({ word_count: this.total_characters - value.length });
  };
  handleField = e => {
    this.props.dispatch(setFocus(true));
    this.props.setAllowNavigation();
    let value = e.target.value;
    let name = e.target.name;
    let errorObj = this.state.errorObj;
    errorObj[name] = false;
    if (name == "adjustment_reason_code") {
      this.setState({ [name]: value.toUpperCase(), errorObj: errorObj });
    } else {
      this.setState({ [name]: value, errorObj: errorObj });
    }
  };
  handleDate = e => {
    this.props.dispatch(setFocus(true));
    this.props.setAllowNavigation();
    let errorObj = this.state.errorObj;
    errorObj.begin_date = false;
    this.setState({ begin_date: e, errorObj: errorObj });
  };
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          width: "100%"
        }}
      >
        <div className="row">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              width: "100%"
            }}
          >
            <div className="mui-custom-form input-md">
              <TextField
                id="adjustment_reason_code"
                fullWidth
                label="Adjustment Reason Code"
                name="adjustment_reason_code"
                value={this.state.adjustment_reason_code}
                onChange={this.handleField}
                required
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 5
                }}
                helperText={
                  this.state.errorObj.adjustment_reason_code
                    ? this.state.error_msg.adjustment_reason_code
                    : null
                }
                error={
                  this.state.errorObj.adjustment_reason_code
                    ? this.state.error_msg.adjustment_reason_code
                    : null
                }
              />
            </div>
            <div className="mui-custom-form with-date">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                  id="begin_date"
                  label="Begin Date"
                  format="MM/dd/yyyy"
                  placeholder="mm/dd/yyyy"
                  name="begin_date"
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={this.state.begin_date}
                  onChange={this.handleDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  helperText={
                    this.state.errorObj.begin_date
                      ? this.state.error_msg.begin_date
                      : null
                  }
                  error={
                    this.state.errorObj.begin_date
                      ? this.state.error_msg.begin_date
                      : null
                  }
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="mui-custom-form with-textarea override-width-95">
            {/* <label>Text*</label> */}
            <Form.Group>
              <Form.Label
                for="adjreason-text"
                className="MuiFormLabel-root small-label"
              >
                Text <span>*</span>
              </Form.Label>
              <Form.Control
                id="adjreason-text"
                as="textarea"
                onChange={this.handleField}
                name="text"
                value={this.state.text}
                rows="4"
                maxLength={this.total_characters}
                onKeyUp={this.checkWordCount}
              />
              {this.state.errorObj.text > 0 ? (
                <p
                  class="MuiFormHelperText-root Mui-error Mui-required"
                  role="alert"
                >
                  {this.state.error_msg.text}
                </p>
              ) : null}
            </Form.Group>
            <div
              style={{
                border: "1px solid rgb(169, 169, 169)",
                borderRadius: ".25rem",
                paddingLeft: "5px",
                width: "200px",
                alignText: "center"
              }}
            >
              Characters Remaining:{this.state.word_count}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class NCPDP_ extends React.Component {
  constructor(props) {
    super(props);
    this.total_characters = 4000;
    this.state = {
      ncpdp_code: "",
      begin_date: null,
      text: "",
      word_count: this.total_characters,
      errorObj: {
        ncpdp_code: false,
        begin_date: false,
        text: false
      },
      error_msg: {
        ncpdp_code: "",
        begin_date: "",
        text: ""
      }
    };
  }
  resetFieldError = () => {
    var errorObj = this.state.errorObj;
    var error_msg = this.state.error_msg;
    errorObj.ncpdp_code = false;
    errorObj.begin_date = false;
    errorObj.text = false;
    error_msg.ncpdp_code = '';
    error_msg.begin_date = '';
    error_msg.text = '';
    this.setState({ errorObj: errorObj, error_msg: error_msg });
  }
  setErrObject() {
    var errorObj = this.state.errorObj;
    errorObj.ncpdp_code = false;
    errorObj.begin_date = false;
    errorObj.termination_date = false;
    errorObj.text = false;
    this.setState({ errorObj: errorObj });
  }
  searchCheck = () => {
    let errorMessagesArray = [];
    var errorObj = this.state.errorObj;
    var error_msg = this.state.error_msg;
    if (
      (this.state.ncpdp_code && !this.state.ncpdp_code.trim()) ||
      !this.state.ncpdp_code
    ) {
      errorObj.ncpdp_code = true;
      error_msg.ncpdp_code =
        "NCPDP Reject Code" + TextManagementErrors.Required_Field_Error;
      errorMessagesArray.push(error_msg.ncpdp_code);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    }
    if (!this.state.begin_date) {
      errorObj.begin_date = true;
      error_msg.begin_date =
        "Begin Date" + TextManagementErrors.Required_Field_Error;
      errorMessagesArray.push(error_msg.begin_date);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    } else if (this.state.begin_date.toString() == "Invalid Date") {
      errorObj.begin_date = true;
      error_msg.begin_date =
        TextManagementErrors.Invalid_Format_Error_BeginDate;
      errorMessagesArray.push(error_msg.begin_date);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    } else if (DateUtils.validateDateMinimumValue(this.state.begin_date)) {
      errorObj.begin_date = true;
      error_msg.begin_date = AppConstants.DATE_ERROR_1964;
      errorMessagesArray.push(error_msg.begin_date);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    }
    if ((this.state.text && !this.state.text.trim()) || !this.state.text) {
      errorObj.text = true;
      error_msg.text = "Text" + TextManagementErrors.Required_Field_Error;
      errorMessagesArray.push(error_msg.text);
      this.setState({ errorObj: errorObj, error_msg: error_msg }, () => { });
    }
    return errorMessagesArray;
  };
  onSave = e => {
    
    this.props.dispatch(ResetTextSearchReducer());
    return {
      auditUserID: this.props.logInUserID ? this.props.logInUserID : null,
      auditTimeStamp: DateUtils.getUTCTimeStamp(),
      addedAuditUserID: this.props.logInUserID ? this.props.logInUserID : null,
      addedAuditTimeStamp: DateUtils.getUTCTimeStamp(),
      versionNo: 0,
      dbRecord: false,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      ncpdpRejectCode: this.state.ncpdp_code,
      beginDate: moment(this.state.begin_date).format("MM/DD/YYYY"),
      terminationDate: "12/31/9999",
      claimException: null,
      ncpdpRejectCodeText: this.state.text
    };
  };
  checkWordCount = e => {
    let value = e.target.value;
    value = value.split(" ").join(" ");
    this.setState({ word_count: this.total_characters - value.length });
  };
  handleField = e => {
    this.props.dispatch(setFocus(true));
    this.props.setAllowNavigation();
    let value = e.target.value;
    let name = e.target.name;
    let errorObj = this.state.errorObj;
    errorObj[name] = false;
    if (name == "ncpdp_code")
      this.setState({ [name]: value.toUpperCase(), errorObj: errorObj });
    else this.setState({ [name]: value, errorObj: errorObj });
  };
  handleDate = e => {
    this.props.dispatch(setFocus(true));
    this.props.setAllowNavigation();
    let errorObj = this.state.errorObj;
    errorObj.begin_date = false;
    this.setState({ begin_date: e, errorObj: errorObj });
  };
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          width: "100%"
        }}
      >
        <div className="row">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              width: "100%"
            }}
          >
            <div className="mui-custom-form input-md">
              <TextField
                id="ncpdp_code"
                fullWidth
                label="NCPDP Reject Code"
                name="ncpdp_code"
                value={this.state.ncpdp_code}
                onChange={this.handleField}
                required
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 3
                }}
                helperText={
                  this.state.errorObj.ncpdp_code
                    ? this.state.error_msg.ncpdp_code
                    : null
                }
                error={
                  this.state.errorObj.ncpdp_code
                    ? this.state.error_msg.ncpdp_code
                    : null
                }
              />
            </div>
            <div className="mui-custom-form with-date">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                  formnovalidate
                  id="begin_date"
                  label="Begin Date"
                  format="MM/dd/yyyy"
                  placeholder="mm/dd/yyyy"
                  InputLabelProps={{
                    shrink: true
                  }}
                  name="begin_date"
                  value={this.state.begin_date}
                  onChange={this.handleDate}
                  required
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  helperText={
                    this.state.errorObj.begin_date
                      ? this.state.error_msg.begin_date
                      : null
                  }
                  error={
                    this.state.errorObj.begin_date
                      ? this.state.error_msg.begin_date
                      : null
                  }
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>

          <div className="mui-custom-form with-textarea override-width-95">
            <Form.Group>
              <Form.Label
                for="ncpdp-text"
                className="MuiFormLabel-root small-label"
              >
                Text <span>*</span>
              </Form.Label>
              <Form.Control
                id="ncpdp-text"
                as="textarea"
                onChange={this.handleField}
                name="text"
                value={this.state.text}
                rows="4"
                maxLength={this.total_characters}
                onKeyUp={this.checkWordCount}
              />
              {this.state.errorObj.text > 0 ? (
                <p
                  class="MuiFormHelperText-root Mui-error Mui-required"
                  role="alert"
                >
                  {this.state.error_msg.text}
                </p>
              ) : null}
            </Form.Group>
            <div
              style={{
                border: "1px solid rgb(169, 169, 169)",
                borderRadius: ".25rem",
                paddingLeft: "5px",
                width: "200px",
                alignText: "center"
              }}
            >
              Characters Remaining:{this.state.word_count}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const NCPDP = connect(mapStateToProps, null, null, { forwardRef: true })(
  NCPDP_
);
export const AdjustmentReason = connect(mapStateToProps, null, null, {
  forwardRef: true
})(AdjustmentReason_);
export const RemarkCode = connect(mapStateToProps, null, null, {
  forwardRef: true
})(RemarkCode_);
export const RAEOB = connect(mapStateToProps, null, null, { forwardRef: true })(
  RAEOB_
);
export const ProviderNotice = connect(mapStateToProps, null, null, {
  forwardRef: true
})(ProviderNotice_);
export const EOMB = connect(mapStateToProps, null, null, { forwardRef: true })(
  EOMB_
);
export default withRouter(connect(mapStateToProps, null)(TextAddUpdate));
