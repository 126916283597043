/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from "react";
import TableComponent from "../../../SharedModules/Table/Table";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router";

import * as fundCodeConstants from "./FundCodeConstants";

/* FundCodeHistory()
 * This component is used to display Fund Code history (inside 2nd tab in edit fund code screen)
 * Inside this component we are getting values using props sent by FundCodeAdd.js
 */
function FundCodeHistory(props) {
  const [showForm, setShowForm] = React.useState(false);
  /* Setting initial values */
  const [values, setValues] = React.useState({
    cos: "",
    benefitPlanId: "",
    bProviderIdType: "",
    bProviderId: "",
    bProviderType: "",
    familyPlan: "",
    taxonomy: "",
    coe: "",
    levelCare: "",
    memberAge: "",
    claimType: "",
    mapsetId: "",
    procedureCode: "",
    disability: "",
    serviceBeginDate: "",
    serviceEndDate: "",
    default: ""
  });

  const tableDetails = [];
  var rowData = {};
  if (props.tabData !== undefined) {
    props.tabData.map((tabValue, index) => {
      const value = {};
      value.cos = tabValue.categoryOfServiceCode;
      value.benefitPlanId = tabValue.benefitPlanID;
      value.bProviderType = tabValue.renderingProviderTypeCode;
      value.bProviderIdType = tabValue.billingProviderAltTypeCode;
      value.bProviderId = tabValue.billingProviderAltID;
      value.level = tabValue.levelOfCare;
      value.memberBAge = tabValue.ageFromNumber;
      value.memberEAge = tabValue.ageThruNumber;
      value.coe = tabValue.coeCode;
      value.taxo = tabValue.taxonomyCode;
      value.claimType = tabValue.claimTypeCode;
      value.serviceBeginDate = tabValue.firstDate;
      value.serviceEndDate = tabValue.lastDate;
      value.familyPlan = tabValue.familyPlanningIND;
      value.defaultCheckVal = tabValue.defaultIND;
      value.mapsetId = tabValue.mapsetID;
      value.procCodeValue = tabValue.procedureCode;
      value.procBeginCode = tabValue.procedureFromCode;
      value.procEndCode = tabValue.procedureThruCode;
      value.modi1 = tabValue.procedureModifierCode1;
      value.modi2 = tabValue.procedureModifierCode2;
      value.modi3 = tabValue.procedureModifierCode3;
      value.modi4 = tabValue.procedureModifierCode4;
      value.voidDate = tabValue.voidDate;
      value.taxonomy = tabValue.taxonomy;
      value.disabilityType = tabValue.subType;
      value.cosForTable = tabValue.categoryOfServiceCode;
      value.bProviderIdTypeForTable = tabValue.billingProviderAltTypeCode;
      value.bProviderTypeForTable = tabValue.renderingProviderTypeCode;
      if (
        value.memberBAge !== "" &&
        value.memberBAge != null &&
        value.memberEAge !== "" &&
        value.memberEAge != null
      ) {
        value.memberAge = value.memberBAge + "-" + value.memberEAge;
      }
      if (value.cos != "" && value.cos !== null && value.cos !== undefined) {
        // for (var cosTypes in props.cosList) {
        //   if (props.cosList[cosTypes].code === value.cos) {
        //     value.cosForTable = props.cosList[cosTypes].description;
        //     value.cosCodeLongDesc = props.cosList[cosTypes].longDescription;
        //   }
        // }
        const cosCode =
        props.dropdowns &&
        props.dropdowns['Claims#C_COS_CD'] &&
        props.dropdowns['Claims#C_COS_CD'].filter(
          value => value.code === tabValue.categoryOfServiceCode
        );
    value.cosForTable =
      cosCode && cosCode.length > 0
            ? cosCode[0].description
            : tabValue.categoryOfServiceCode ? tabValue.categoryOfServiceCode : '';
      }
      
      if (
        value.bProviderIdType != null &&
      value.bProviderIdType !== undefined
      ) {
        // for (var pIdTypes in props.prvIdTypeList) {
        //   if (props.prvIdTypeList[pIdTypes].code === value.bProviderIdType) {
        //     value.bProviderIdTypeForTable = props.prvIdTypeList[pIdTypes].description;
        //   }
        // }
        const provCode =
            props.dropdowns &&
            props.dropdowns['P1#P_ALT_ID_TY_CD'] &&
            props.dropdowns['P1#P_ALT_ID_TY_CD'].filter(
              value => value.code === value.bProviderIdType
            );
            value.bProviderIdTypeForTable =
               provCode && provCode.length > 0
                ? provCode[0].description
                : value.bProviderIdType ? value.bProviderIdType : '';
      }

      if (value.bProviderType != null && value.bProviderType !== undefined) {
        // for (var pTypes in props.prvTypeList) {
        //   if (props.prvTypeList[pTypes].code === value.bProviderType) {
        //     value.bProviderTypeForTable = props.prvTypeList[pTypes].description;
        //   }
        // }
        const bProviderType =
              props.dropdowns &&
                props.dropdowns['P1#P_TY_CD'] &&
                props.dropdowns['P1#P_TY_CD'].filter(
                  value => value.code === value.bProviderType
                );
                value.bProviderTypeForTable =
                bProviderType && bProviderType.length > 0
                ? bProviderType[0].description
                : value.bProviderType ? value.bProviderType : '';
      }
      if (value.claimType != null && value.claimType !== undefined) {
        const claimType =
        props.dropdowns &&
          props.dropdowns['C1#C_TY_CD'] &&
          props.dropdowns['C1#C_TY_CD'].filter(
            value => value.code === value.claimType
          );
          value.claimType =
            claimType && claimType.length > 0
              ? claimType[0].description
              : value.claimType ? value.claimType : '';
      }
      let fundData = {}
      fundData = value
      tableDetails.push(fundData);
    });
  }

  /* This function is called on click of a row inside table of history
   *  Displaying all fields values
   */
  const editRow = row => event => {
    rowData = row;
    setShowForm(true);
    setFieldValues(rowData);
  };

  /* This function is setting values */
  const setFieldValues = rowData => {
    setValues({
      cos: rowData.cos || "",
      cosForTable: rowData.cosForTable || "",
      benefitPlanId: rowData.benefitPlanId || "",
      bProviderIdType: rowData.bProviderIdType || "",
      bProviderIdTypeForTable: rowData.bProviderIdTypeForTable || "",
      bProviderId: rowData.bProviderId || "",
      bProviderType: rowData.bProviderType || "",
      bProviderTypeForTable: rowData.bProviderTypeForTable || "",
      familyPlan: rowData.familyPlan || "",
      taxonomy: rowData.taxonomy || "",
      coe: rowData.coe || "",
      levelCare: rowData.level || "",
      memberAge: rowData.memberAge || "",
      claimType: rowData.claimType || "",
      mapsetId: rowData.mapsetId || "",
      procedureCode: rowData.procCodeValue || "",
      disability: rowData.disabilityType || "",
      serviceBeginDate: rowData.serviceBeginDate || "",
      serviceEndDate: rowData.serviceEndDate || "",
      default: rowData.defaultCheckVal || ""
    });
  };

  return (
    <div className="tab-holder">
      <div className="mt-3 table-no-wrap">
        <TableComponent
          print={props.print}
          isSearch={true}
          headCells={fundCodeConstants.headCellsHistory}
          tableData={tableDetails || []}
          onTableRowClick={editRow}
          defaultSortColumn={fundCodeConstants.headCellsEdit[0].id}
        />
      </div>

      {showForm ? (
        <form autoComplete="off">
          <div className="form-wrapper">
            <div className="mui-custom-form input-md with-select">
              <TextField
                id="catService"
                disabled
                label="Category of Service"
                value={values.cosForTable}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
            <div className="mui-custom-form input-md with-select">
              <TextField
                id="benefitPlanId"
                disabled
                label="Benefit Plan ID"
                value={values.benefitPlanId}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
            <div className="mui-custom-form input-md with-select">
              <TextField
                id="bProviderIdType"
                disabled
                label="Billing Provider ID Type"
                value={values.bProviderIdTypeForTable}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
            <div className="mui-custom-form input-md">
              <TextField
                disabled
                id="bProviderId"
                label="Billing Provider ID"
                value={values.bProviderId}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
          </div>
          <div className="form-wrapper">
            <div
              className="mui-custom-form input-md
       with-select"
            >
              <TextField
                id="bProviderType"
                disabled
                label="Billing Provider Type"
                value={values.bProviderTypeForTable}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
            <div className="mui-custom-form input-md with-select">
              <TextField
                id="levelCare"
                disabled
                label="Level of Care"
                value={values.levelCare}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
            <div className="mui-custom-form input-md with-select">
              <TextField
                id="memberAge"
                disabled
                label="Member Age"
                value={values.memberAge}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
            <div className="mui-custom-form input-md with-select">
              <TextField
                id="coe"
                disabled
                label="COE"
                value={values.coe}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
          </div>
          <div className="form-wrapper">
            {/* <div className="mui-custom-form input-md with-select">
              <TextField
                id="disability"
                disabled
                label="Disability type"
                value={values.disability}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div> */}
            <div className="mui-custom-form input-md">
              <TextField
                id="taxonomy"
                disabled
                label="Taxonomy"
                value={values.taxonomy}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
            <div className="mui-custom-form input-md with-select">
              <TextField
                id="claimType"
                disabled
                label="Claim Type"
                value={values.claimType}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
            <div className="mui-custom-form input-md with-select">
              <TextField
                id="familyPlan"
                disabled
                label="Family Planning"
                value={values.familyPlan}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
          </div>
          <div className="form-wrapper">
            <div className="mui-custom-form input-md with-select">
              <TextField
                id="serviceBeginDate"
                disabled
                label="Service Begin Date"
                value={values.serviceBeginDate}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
            <div className="mui-custom-form input-md with-select">
              <TextField
                id="serviceEndDate"
                disabled
                label="Service End Date"
                value={values.serviceEndDate}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
            <div className="mui-custom-form input-md with-select">
              <TextField
                id="default"
                disabled
                label="Default"
                value={values.default}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
            <div className="mui-custom-form input-md with-select">
              <TextField
                id="procedureCode"
                disabled
                label="Procedure Code"
                value={values.procedureCode}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
          </div>
          <div className="form-wrapper">
            <div className="mui-custom-form orField">OR</div>
            <div className="mui-custom-form input-413 with-select margin-left-130">
              <TextField
                id="mapsetId"
                disabled
                label="MapSet ID"
                value={values.mapsetId}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
          </div>
        </form>
      ) : null}
    </div>
  );
}

export default withRouter(FundCodeHistory);
