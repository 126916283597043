import React, { useEffect } from "react";
import TableComponent from "../../../../SharedModules/Table/Table";
import Bootstrap, { Button, Form } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Swal from "sweetalert2";
import {
  generateUUID,
  getDateInMMDDYYYYFormat,
  getUTCTimeStamp,
} from "../../../../SharedModules/DateUtilities/DateUtilities";
import ErrorMessages from "../../../../SharedModules/Errors/ErrorMessages";
import * as PaymentCriteriaConstant from "../PaymentCriteriaConstant";
import { findPayeeAction } from "../../Store/Actions/PaymentCriteriaAction";
import { useDispatch, useSelector } from "react-redux";
import NativeDropDown from "../../../../SharedModules/Dropdowns/NativeDropDown";

function PaymentCriteriaDetails(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const dispatch = useDispatch();
  const [retainRowData, setRetainRowData] = React.useState(null);
  const [retainPayeeRowData, setRetainPayeeRowData] = React.useState(null);
  const [rowEdit, setRowEdit] = React.useState(false);
  const [rowPayeeEdit, setRowPayeeEdit] = React.useState(false);

  const [multiDelete, setMultiDelete] = React.useState([]);
  const findPayeeAPI = (value) => dispatch(findPayeeAction(value));
  const payeeesults = useSelector(
    (state) => state.paymentCriteriaState?.payeeData
  );
  const {
    values,
    typeList,
    payeeTypeList,
    pValueList,
    valueList,
    tableData,
    setPaymentReqCriteriaSet,
    paymentReqCriteriaSet,
    logInUserID,
    setPayeeDataValues,
    payeeDataValues,
    setReasonDataValues,
    reasonDataValues,
    reasonTable,
    payeeTable,
    setReasonTable,
    setPayeeTable,
    setDeleteReqCriteriaSet,
    deleteReqCriteriaSet,
    setDeletePayeeCriteriaSet,
    deletePayeeCriteriaSet,
    setDeleteReason,
    setDeletePayee,
    seterrorMessages,
    setSystemSuccesMessages,
    setReasonErrorMessages,
    setPayeeErrorMessages,
    addFormValidation,
    errorMessages,
    setAddFormShowErr,
    errors,
    setAllowNavigation,
    setFocusCheck,
    dropdowns,
  } = props;
  const headCells = [
    {
      id: "reqTyCdDesc",
      numeric: false,
      disablePadding: false,
      label: "Type",
      width: "30%",
    },
    {
      id: "reqValueDesc",
      numeric: false,
      disablePadding: false,
      label: "Value",
      width: "70%",
    },
  ];
  const payeeHeadCells = [
    {
      id: "payeeReqTyCdDesc",
      numeric: false,
      disablePadding: false,
      label: "Type",
      width: "30%",
    },
    {
      id: "reqValue",
      numeric: false,
      disablePadding: false,
      label: "Value",
      width: "70%",
    },
  ];

  let valueDropdown = valueList?.filter(
    (o) => !pValueList.some(({ code }) => o.code === code)
  );
  const [reasonValues, setReasonValues] = React.useState({
    auditUserID: "C5069899",
    auditTimeStamp: "2023-09-13T13:14:33.000-04:00",
    addedAuditUserID: "C5069899",
    addedAuditTimeStamp: "2023-09-13T13:15:33.000-04:00",
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    paymentReqSk: null,
    paymentReqCritSeqNum: null,
    value: DEFAULT_DD_VALUE,
    type: DEFAULT_DD_VALUE,
  });

  const [payeeValues, setPayeeValues] = React.useState({
    auditUserID: "C5069899",
    auditTimeStamp: "2023-09-13T13:14:33.000-04:00",
    addedAuditUserID: "C5069899",
    addedAuditTimeStamp: "2023-09-13T13:15:33.000-04:00",
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    paymentReqSk: null,
    paymentReqCritSeqNum: null,
    value: "",
    type: DEFAULT_DD_VALUE,
  });
  const [
    { showReasonTypeeErr, showReasonValueErr, showReasonDupErr },
    setShowError,
  ] = React.useState(false);
  const [
    {
      showPayeeTypeeErr,
      showPayeeValueErr,
      showPayeeValidErr,
      showPayeeDupErr,
    },
    setPayeeShowError,
  ] = React.useState(false);

  const [payeeTableSelectedRows, setPayeeTableSelectedRows] = React.useState(
    []
  );
  const [reasonTableSelectedRows, setReasonTableSelectedRows] = React.useState(
    []
  );
  const [findClick, setFindClick] = React.useState(false);

  const [showForm, setShowForm] = React.useState(false);
  const [showPayeeForm, setShowPayeeForm] = React.useState(false);
  const handleChangeReason = (name) => (event) => {
    setReasonValues({ ...reasonValues, [name]: event.target.value });
    setAllowNavigation(true);
    setFocusCheck(true);
  };
  const handleChangePayee = (name) => (event) => {
    if(name === 'value'){
      const reg = /^[0-9\b]+$/
      if (event.target.value === '' || reg.test(event.target.value)) {
        setPayeeValues({ ...payeeValues, [name]: event.target.value });
      }
      setAllowNavigation(true);
      setFocusCheck(true);
    }
    else{
      setPayeeValues({ ...payeeValues, [name]: event.target.value });
    setAllowNavigation(true);
    setFocusCheck(true);
    } 
    
  };

  const addClickForm = () => {
    setShowForm(true);
    setRowEdit(false);
    setReasonValues({
      value: DEFAULT_DD_VALUE,
      type: DEFAULT_DD_VALUE,
      paymentReqCritSeqNum: 0,
    });
  };

  const addClickPayeeForm = () => {
    setShowPayeeForm(true);
    setRowPayeeEdit(false);
    setPayeeValues({
      value: "",
      type: DEFAULT_DD_VALUE,
      paymentReqCritSeqNum: 0,
    });
  };

  const handleClose = () => {
    setShowForm(false);
    setShowError({});
    seterrorMessages([]);
    setReasonErrorMessages([]);
  };
  const payeePayerValue = {
    payeePayer: payeeValues.value,
  };
  const clickFindPayee = () => {
    setFindClick(true);
    if (payeeValues.value === "") {
      window.open("/FinancialEntitysearch", "_blank");
    } else {
      findPayeeAPI(payeePayerValue);
    }
  };
  const dataToUrlParam = (rowData) => {
    const objStr = JSON.stringify(rowData);
    const toBase64 = btoa(unescape(encodeURIComponent(objStr)));
    return toBase64;
  };
  useEffect(() => {
    if(payeeValues.value !== '' && payeeesults && payeeesults.errorCode === PaymentCriteriaConstant.PAYEE_NUM_INVALID){
      setPayeeShowError({
        showPayeeValidErr: true,
      });
      setPayeeErrorMessages([PaymentCriteriaConstant.PAYEE_NUM_INVALID]);
      
    }else if(payeeValues.value !== '' && payeeesults && payeeesults.status === "success" && findClick) {
      const datatoURL = dataToUrlParam(payeeValues.value);
      window.open('/FinancialEntityUpdate?data=' + datatoURL, '_blank' );
    }else if(payeeValues.value !== '' && payeeesults && payeeesults.status === "success" && !findClick){
      finalSave();
    }
  }, [payeeesults]);

  const reasonReset = () => {
    setShowError({});
    seterrorMessages([]);
    setReasonErrorMessages([]);
    if (rowEdit) {
      setReasonValues({
        auditUserID: retainRowData.auditUserID
          ? retainRowData.auditUserID
          : logInUserID,
        auditTimeStamp: retainRowData.auditTimeStamp
          ? retainRowData.auditTimeStamp
          : getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: getUTCTimeStamp(),
        paymentReqSk: retainRowData.paymentReqSk,
        value: retainRowData.reqValue,
        type: retainRowData.reqTyCd,
        id: retainRowData.id,
        paymentReqCritSeqNum: retainRowData.paymentReqCritSeqNum,
      });
    } else {
      setReasonValues({
        value: DEFAULT_DD_VALUE,
        type: DEFAULT_DD_VALUE,
      });
    }
  };
  const payeeReset = () => {
    setPayeeShowError({});
    seterrorMessages([]);
    setPayeeErrorMessages([]);
    if (rowPayeeEdit) {
      setPayeeValues({
        auditUserID: rowPayeeEdit.auditUserID
          ? rowPayeeEdit.auditUserID
          : logInUserID,
        auditTimeStamp: rowPayeeEdit.auditTimeStamp
          ? rowPayeeEdit.auditTimeStamp
          : getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: getUTCTimeStamp(),
        paymentReqSk: retainPayeeRowData.paymentReqSk,
        value: retainPayeeRowData.reqValue,
        type: retainPayeeRowData.reqTyCd,
        id: retainPayeeRowData.id,
        paymentReqCritSeqNum: retainPayeeRowData.paymentReqCritSeqNum
      })
    } else {
      setPayeeValues({
        value: "",
        type: DEFAULT_DD_VALUE,
      });
    }
  }


  const handlePayeeClose = () => {
    setShowPayeeForm(false);
    setPayeeShowError({});
    seterrorMessages([]);
    setPayeeErrorMessages([]);
  };

  const validateReason = () => {
    const errorMsgArray = [];
    setSystemSuccesMessages([]);
    let showReasonTypeeErr = false;
    let showReasonValueErr = false;
    let showReasonDupErr = false;
    let error = true;

    if (reasonValues.type === DEFAULT_DD_VALUE) {
      showReasonTypeeErr = true;
      errorMsgArray.push(PaymentCriteriaConstant.TYPE);
      error = false;
    }
    if (reasonValues.value === DEFAULT_DD_VALUE) {
      showReasonValueErr = true;
      errorMsgArray.push(PaymentCriteriaConstant.VALUE);
      error = false;
    }
    const reasonDupVal =
      reasonTable &&
      reasonTable.length &&
      reasonTable.filter((value) => value.reqValue === reasonValues.value && value.reqTyCd === reasonValues.type);

    if (reasonDupVal.length > 0) {
      showReasonDupErr = true;
      errorMsgArray.push(PaymentCriteriaConstant.REASON_CODES_DUPLICATE);
      error = false;
    }
    setShowError({
      showReasonTypeeErr: showReasonTypeeErr,
      showReasonValueErr: showReasonValueErr,
      showReasonDupErr: showReasonDupErr,
    });
    setReasonErrorMessages(errorMsgArray);
    return error;
  };

  // Add Row and Edit Row
  const saveOrEditReasonDetails = () => {
    if (validateReason()) {
      const tableDataTemp = [...reasonTable];
      let genSeqNum =
        ([
          ...(reasonTable?.map((e) => e.paymentReqCritSeqNum || 0) || []),
          ...(payeeTable?.map((e) => e.paymentReqCritSeqNum || 0) || []),
        ]?.sort((a, b) => b - a)[0] || 0) + 1;
      setReasonValues({
        ...reasonValues,
        paymentReqCritSeqNum: genSeqNum,
      });
      const value =
        valueDropdown &&
        valueDropdown.filter((value) => value.code === reasonValues.value);
      const type =
        typeList &&
        typeList.filter((value) => value.code === reasonValues.type);
      const reasonData = {
        auditUserID: reasonValues.auditUserID
          ? reasonValues.auditUserID
          : logInUserID,
        auditTimeStamp: reasonValues.auditTimeStamp
          ? reasonValues.auditTimeStamp
          : getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: getUTCTimeStamp(),
        versionNo: reasonValues.versionNo ? reasonValues.versionNo : 0,
        dbRecord: reasonValues.dbRecord ? reasonValues.dbRecord : false,
        sortColumn: reasonValues.sortColumn ? reasonValues.sortColumn : null,
        auditKeyList: reasonValues.auditKeyList
          ? reasonValues.auditKeyList
          : [],
        auditKeyListFiltered: reasonValues.auditKeyListFiltered
          ? reasonValues.auditKeyListFiltered
          : false,
        paymentReqSk: reasonValues.paymentReqSk
          ? reasonValues.paymentReqSk
          : null,
        paymentReqCritSeqNum: rowEdit
          ? reasonValues.paymentReqCritSeqNum
          : genSeqNum,
        reqTyCd: reasonValues.type,
        reqValue: reasonValues.value,
        reqValueDesc: value && value.length > 0 ? value[0].description : "",
        reqTyCdDesc: type && type.length > 0 ? type[0].description : "",
        id: reasonValues.id ? reasonValues.id : generateUUID(),
      };
      if (rowEdit && retainRowData && retainRowData !== null) {
        tableDataTemp.map((row) => {
          if (
            row.paymentReqCritSeqNum &&
            row.paymentReqCritSeqNum === reasonValues.paymentReqCritSeqNum
          ) {
            row.auditUserID = reasonValues.auditUserID
              ? reasonValues.auditUserID
              : logInUserID;
            row.auditTimeStamp = reasonValues.auditTimeStamp
              ? reasonValues.auditTimeStamp
              : getUTCTimeStamp();
            row.paymentReqCritSeqNum = reasonValues.paymentReqCritSeqNum;
            row.paymentReqSk = reasonValues.paymentReqSk
              ? reasonValues.paymentReqSk
              : null;
            row.reqTyCd = reasonValues.type;
            row.reqValue = reasonValues.value;
            row.id = reasonValues.id;
            row.reqValueDesc =
              value && value.length > 0 ? value[0].description : "";
            row.reqTyCdDesc =
              type && type.length > 0 ? type[0].description : "";
          }
        });
      } else {
        tableDataTemp.push(reasonData);
      }
      setReasonTable(tableDataTemp);
      setReasonDataValues(tableDataTemp);
      handleClose();
    }
  };

  const validatePayee = () => {
    setSystemSuccesMessages([]);
    const errorMsgArray = [];
    let showPayeeTypeeErr = false;
    let showPayeeValueErr = false;
    let showPayeeDupErr = false;
    let error = true;

    if (payeeValues.type === DEFAULT_DD_VALUE) {
      showPayeeTypeeErr = true;
      errorMsgArray.push(PaymentCriteriaConstant.TYPE);
      error = false;
    }
    if (payeeValues.value === "") {
      showPayeeValueErr = true;
      errorMsgArray.push(PaymentCriteriaConstant.VALUE);
      error = false;
    }
    const payeeDupVal =
      payeeTable &&
      payeeTable.length &&
      payeeTable.filter((value) => value.reqValue === payeeValues.value && value.reqTyCd === payeeValues.type);
    if (payeeDupVal.length > 0) {
      showPayeeDupErr = true;
      errorMsgArray.push(PaymentCriteriaConstant.PAYEE_NUM_DUPLICATE);
      error = false;
    }
    setPayeeShowError({
      showPayeeTypeeErr: showPayeeTypeeErr,
      showPayeeValueErr: showPayeeValueErr,
      showPayeeDupErr: showPayeeDupErr,
    });
    setPayeeErrorMessages(errorMsgArray);
    return error;
  };

  const saveOrEditPayeeDetails = () => {
    setFindClick(false);
    if (validatePayee()) {
      findPayeeAPI(payeePayerValue);
    }
  };

  const finalSave = () => {
    const tableDataTemp = [...payeeTable];
    let genSeqNum =
      ([
        ...(reasonTable?.map((e) => e.paymentReqCritSeqNum || 0) || []),
        ...(payeeTable?.map((e) => e.paymentReqCritSeqNum || 0) || []),
      ]?.sort((a, b) => b - a)[0] || 0) + 1;
    setPayeeValues({
      ...payeeValues,
      paymentReqCritSeqNum: genSeqNum,
    });
    const type =
      payeeTypeList &&
      payeeTypeList.filter((value) => value.code === payeeValues.type);
    const payeeData = {
      auditUserID: payeeValues.auditUserID
        ? payeeValues.auditUserID
        : logInUserID,
      auditTimeStamp: payeeValues.auditTimeStamp
        ? payeeValues.auditTimeStamp
        : getUTCTimeStamp(),
      addedAuditUserID: logInUserID,
      addedAuditTimeStamp: getUTCTimeStamp(),
      versionNo: payeeValues.versionNo ? payeeValues.versionNo : 0,
      dbRecord: payeeValues.dbRecord ? payeeValues.dbRecord : false,
      sortColumn: payeeValues.sortColumn ? payeeValues.sortColumn : null,
      auditKeyList: payeeValues.auditKeyList ? payeeValues.auditKeyList : [],
      auditKeyListFiltered: payeeValues.auditKeyListFiltered
        ? payeeValues.auditKeyListFiltered
        : false,
      paymentReqSk: payeeValues.paymentReqSk ? payeeValues.paymentReqSk : null,
      paymentReqCritSeqNum: rowPayeeEdit
        ? payeeValues.paymentReqCritSeqNum
        : genSeqNum,
      reqTyCd: payeeValues.type,
      reqValue: payeeValues.value,
      payeeReqTyCdDesc: type && type.length > 0 ? type[0].description : "",
      id: payeeValues.id ? payeeValues.id : generateUUID(),
    };
    if (rowPayeeEdit && retainPayeeRowData && retainPayeeRowData !== null) {
      tableDataTemp.map((row) => {
        if (
          row.paymentReqCritSeqNum &&
          row.paymentReqCritSeqNum === payeeValues.paymentReqCritSeqNum
        ) {
          row.auditUserID = payeeValues.auditUserID
            ? payeeValues.auditUserID
            : logInUserID;
          row.auditTimeStamp = payeeValues.auditTimeStamp
            ? payeeValues.auditTimeStamp
            : getUTCTimeStamp();
          row.paymentReqCritSeqNum = payeeValues.paymentReqCritSeqNum;
          row.paymentReqSk = payeeValues.paymentReqSk
            ? payeeValues.paymentReqSk
            : null;
          row.reqTyCd = payeeValues.type;
          row.payeeReqTyCdDesc =
            type && type.length > 0 ? type[0].description : "";
          row.reqValue = payeeValues.value;
          row.id = payeeValues.id;
        }
      });
    } else {
      tableDataTemp.push(payeeData);
    }
    setPayeeTable(tableDataTemp);
    setPayeeDataValues(tableDataTemp);
    handlePayeeClose();
  };

  const onEditPopup = (row) => (event) => {
    setShowForm(true);
    if (row !== "") {
      setRowEdit(true);
      setRetainRowData(row);
      setReasonValues({
        ...reasonValues,
        auditUserID: row.auditUserID ? row.auditUserID : logInUserID,
        auditTimeStamp: row.auditTimeStamp
          ? row.auditTimeStamp
          : getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: getUTCTimeStamp(),
        paymentReqSk: row.paymentReqSk,
        value: row.reqValue,
        type: row.reqTyCd,
        id: row.id,
        paymentReqCritSeqNum: row.paymentReqCritSeqNum,
      });
    }
  };

  const onEditPayee = (row) => (event) => {
    setShowPayeeForm(true);
    if (row !== "") {
      setRowPayeeEdit(true);
      setRetainPayeeRowData(row);
      setPayeeValues({
        ...payeeValues,
        auditUserID: row.auditUserID ? row.auditUserID : logInUserID,
        auditTimeStamp: row.auditTimeStamp
          ? row.auditTimeStamp
          : getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: getUTCTimeStamp(),
        paymentReqSk: row.paymentReqSk,
        value: row.reqValue,
        type: row.reqTyCd,
        id: row.id,
        paymentReqCritSeqNum: row.paymentReqCritSeqNum,
      });
    }
  };

  const selectedReasonTableRow = (data) => {
    setReasonTableSelectedRows(data);
  };
  const selectedPayeeTableRow = (data) => {
    setPayeeTableSelectedRows(data);
  };
  const RowDeleteClick = () => {
    setDeleteReason(true);
    if (rowEdit) {
      Confirm();
    } else {
      handleMultiDelete();
    }
  };

  function Confirm() {
    Swal.fire({
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-primary",
        container: "unsaved-changes-popup2",
      },
      allowOutsideClick: false,
      buttonsStyling: false,
      heightAuto: false,
      icon: "warning",
      html: "<h4>Are you sure you want to delete?</h4>",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        handleMultiDelete();
      } else {
      }
    });
  }

  function handleMultiDelete() {
    if (
      reasonTableSelectedRows != null ||
      reasonTableSelectedRows !== undefined
    ) {
      let updatedTableDetails = JSON.parse(JSON.stringify(reasonTable));

      const frontEndOnlyRecordsToDelete = reasonTable.filter(
        (record) =>
          !record.paymentReqSk && reasonTableSelectedRows.includes(record.id)
      );

      updatedTableDetails = updatedTableDetails.filter(
        (record) => !frontEndOnlyRecordsToDelete.some((r) => r.id === record.id)
      );

      const recordsToDelete = updatedTableDetails.filter(
        (record) =>
          record.paymentReqSk && reasonTableSelectedRows.includes(record.id)
      );

      if (recordsToDelete && recordsToDelete.length) {
        const databaseRecordsToDelete = recordsToDelete.filter((record) =>
          reasonDataValues.some((deleteRecord) => deleteRecord.id === record.id)
        );
        updatedTableDetails = updatedTableDetails.filter(
          (record) => !databaseRecordsToDelete.some((r) => r.id === record.id)
        );
        setDeleteReqCriteriaSet(databaseRecordsToDelete);
      }
      setReasonDataValues(updatedTableDetails);
      setReasonTable(updatedTableDetails);
      setReasonTableSelectedRows([]);
    }
  }

  const RowPayeeDeleteClick = () => {
    setDeletePayee(true);
    if (showPayeeForm) {
      payeeConfirm();
    } else {
      handlePayeeMultiDelete();
    }
  };

  function payeeConfirm() {
    Swal.fire({
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-primary",
        container: "unsaved-changes-popup2",
      },
      allowOutsideClick: false,
      buttonsStyling: false,
      heightAuto: false,
      icon: "warning",
      html: "<h4>Are you sure you want to delete?</h4>",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
    }).then((result) => {
      console.log(result);
      if (result.value) {
        handlePayeeMultiDelete();
      } else {
      }
    });
  }

  function handlePayeeMultiDelete() {
    if (
      payeeTableSelectedRows != null ||
      payeeTableSelectedRows !== undefined
    ) {
      let updatedTableDetails = JSON.parse(JSON.stringify(payeeTable));

      const frontEndOnlyRecordsToDelete = payeeTable.filter(
        (record) =>
          !record.paymentReqSk && payeeTableSelectedRows.includes(record.id)
      );

      updatedTableDetails = updatedTableDetails.filter(
        (record) => !frontEndOnlyRecordsToDelete.some((r) => r.id === record.id)
      );

      const recordsToDelete = updatedTableDetails.filter(
        (record) =>
          record.paymentReqSk && payeeTableSelectedRows.includes(record.id)
      );

      if (recordsToDelete && recordsToDelete.length) {
        const databaseRecordsToDelete = recordsToDelete.filter((record) =>
          payeeDataValues.some((deleteRecord) => deleteRecord.id === record.id)
        );
        updatedTableDetails = updatedTableDetails.filter(
          (record) => !databaseRecordsToDelete.some((r) => r.id === record.id)
        );
        setDeletePayeeCriteriaSet(databaseRecordsToDelete);
      }
      setPayeeDataValues(updatedTableDetails);
      setPayeeTable(updatedTableDetails);
      setPayeeTableSelectedRows([]);
    }
  }

  return (
    <div className="tab-holder my-2">
      <fieldset className="r-custom-fieldset no-padding">
        <legend>Reason Code:</legend>
        <div className="tab-holder">
          <div className="mb-3 " style={{ float: "right" }}>
            <Button
              className="btn-text-main-delete btn-transparent  ml-1"
              onClick={RowDeleteClick}
              disabled={values?.hasBeenProcessed === "Y"}
              title={"Delete"}
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
              Delete
            </Button>
            <Button
              className="btn-text-main-add btn-success ml-1"
              onClick={addClickForm}
              title={"Add Reason Code"}
              disabled={values?.hasBeenProcessed === "Y"}
			  data-testid='test-add-reason'
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
              Add
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-holder table-no-wrap table-p-5">
          <TableComponent
            isSearch={false}
            fixedTable
            headCells={headCells}
            tableData={reasonTable || []}
            onTableRowClick={onEditPopup}
            onTableRowDelete={selectedReasonTableRow}
            defaultSortColumnDesc={true}
            defaultSortColumn={"addedAuditTimeStamp"}
          />
          {showForm ? (
            <div className="tabs-container" id="form_pop_up">
              <div className="tab-header  mini-tab-header">
                <div className="tab-heading float-left">
                  {rowEdit ? "Edit Reason Code" : "Add Reason Code"}
                </div>
                <div className="float-right mt-1">
                  <Button
                    className="btn btn-success ml-1"
                    onClick={saveOrEditReasonDetails}
                    disabled={values?.hasBeenProcessed === "Y"}
                    title={rowEdit ? "Update" : "Add"}
                  >
                    <i class="fa fa-check" aria-hidden="true"></i>
                    {rowEdit ? "Update" : "Add"}
                  </Button>
                  <Button
                    className="bt-reset btn-transparent  ml-1"
                    onClick={reasonReset}
                    disabled={values?.hasBeenProcessed === "Y"}
                    title={"Reset"}
                  >
                    <i class="fa fa-undo" aria-hidden="true"></i>
                    Reset
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="btn btn-primary btn btn-outlined ml-1"
                    onClick={handleClose}
                    title={"Cancel"}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
              <div className="form-border my-3">
                <form autoComplete="off">
                  <div className="form-wrapper">
                    {values?.hasBeenProcessed === "Y" ? (
                      <NativeDropDown
                        id="reasonType"
                        label="Type"
                        value={reasonValues.type}
                        isRequired
                        disabled={true}
                        onChange={handleChangeReason("type")}
                        inputProps={{ maxlength: 3 }}
                        dropdownList={
                          dropdowns &&
                          dropdowns["F1#F_PAYMENT_REQ_TY_CD"] &&
                          dropdowns["F1#F_PAYMENT_REQ_TY_CD"].length > 0
                            ? dropdowns["F1#F_PAYMENT_REQ_TY_CD"].map(
                                (option) => (
                                  <option key={option.code} value={option.code}>
                                    {option.description}
                                  </option>
                                )
                              )
                            : null
                        }
                      />
                    ) : (
                      <NativeDropDown
                        id="reasonType"
                        label="Type"
                        value={reasonValues.type}
                        onChange={handleChangeReason("type")}
                        isRequired
                        inputProps={{ maxlength: 3 }}
                        dropdownList={
                          typeList && typeList && typeList.length > 0
                            ? typeList.map((option) => (
                                <option key={option.code} value={option.code}>
                                  {option.description}
                                </option>
                              ))
                            : null
                        }
                        errTxt={
                          showReasonTypeeErr
                            ? PaymentCriteriaConstant.TYPE
                            : null
                        }
                      />
                    )}

                    {values?.hasBeenProcessed === "Y" ? (
                      <NativeDropDown
                        id="Value"
                        label="Value"
                        value={reasonValues.value}
                        disabled={true}
                        isRequired
                        onChange={handleChangeReason("value")}
                        inputProps={{ maxlength: 3 }}
                        dropdownList={
                          dropdowns &&
                          dropdowns["F1#F_RSN_CD"] &&
                          dropdowns["F1#F_RSN_CD"].length > 0
                            ? dropdowns["F1#F_RSN_CD"].map((option) => (
                                <option key={option.code} value={option.code}>
                                  {option.description}
                                </option>
                              ))
                            : null
                        }
                        errTxt={
                          showReasonValueErr
                            ? PaymentCriteriaConstant.VALUE
                            : showReasonDupErr
                            ? PaymentCriteriaConstant.REASON_CODES_DUPLICATE
                            : null
                        }
                      />
                    ) : (
                      <NativeDropDown
                        id="Value"
                        label="Value"
                        value={reasonValues.value}
                        isRequired
                        onChange={handleChangeReason("value")}
                        inputProps={{ maxlength: 3 }}
                        dropdownList={
                          valueDropdown &&
                          valueDropdown &&
                          valueDropdown.length > 0
                            ? valueDropdown.map((option) => (
                                <option key={option.code} value={option.code}>
                                  {option.description}
                                </option>
                              ))
                            : null
                        }
                        errTxt={
                          showReasonValueErr
                            ? PaymentCriteriaConstant.VALUE
                            : showReasonDupErr
                            ? PaymentCriteriaConstant.REASON_CODES_DUPLICATE
                            : null
                        }
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </fieldset>
      <br></br>
      <fieldset className="r-custom-fieldset no-padding">
        <legend>Payee Details:</legend>
        <div className="tab-holder">
          <div className="mb-3 " style={{ float: "right" }}>
            <Button
              className="btn-text-main-delete btn-transparent  ml-1"
              onClick={RowPayeeDeleteClick}
              disabled={values?.hasBeenProcessed === "Y"}
              title={"Delete"}
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
              Delete
            </Button>
            <Button
              className="btn-text-main-add btn-success ml-1"
              onClick={addClickPayeeForm}
              title={"Add Payee Details"}
              disabled={values?.hasBeenProcessed === "Y"}
			  data-testid='test-add-payee'
            >
				
              <i class="fa fa-plus" aria-hidden="true"></i>
              Add
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-holder table-no-wrap">
          <TableComponent
            isSearch={false}
            headCells={payeeHeadCells}
            tableData={payeeTable || []}
            onTableRowClick={onEditPayee}
            onTableRowDelete={selectedPayeeTableRow}
            defaultSortColumnDesc={true}
            defaultSortColumn={"addedAuditTimeStamp"}
          />
          {showPayeeForm ? (
            <div className="tabs-container" id="form_pop_up">
              <div className="tab-header  mini-tab-header">
                <div className="tab-heading float-left">
                  {rowPayeeEdit ? "Edit Payee Details" : "Add Payee Details"}
                </div>
                <div className="float-right mt-1 ">
                  <Button
                    className="btn btn-success ml-1"
                    onClick={saveOrEditPayeeDetails}
                    disabled={values?.hasBeenProcessed === "Y"}
                    title={rowPayeeEdit ? "Update" : "Add"}
                  >
                    <i class="fa fa-check" aria-hidden="true"></i>
                    {rowPayeeEdit ? "Update" : "Add"}
                  </Button>
                  <Button
                    className="bt-reset btn-transparent  ml-1"
                    onClick={payeeReset}
                    disabled={values?.hasBeenProcessed === "Y"}
                    title={"Reset"}
                  >
                    <i class="fa fa-undo" aria-hidden="true"></i>
                    Reset
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="btn btn-primary btn btn-outlined ml-1"
                    onClick={handlePayeeClose}
                    title={"Cancel"}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
              <div className="form-border my-3">
                <form autoComplete="off">
                  <div className="form-wrapper">
                    {values?.hasBeenProcessed === "Y" ? (
                      <NativeDropDown
                        id="payeeType"
                        label="Type"
                        disabled={true}
                        value={payeeValues.type}
                        isRequired
                        onChange={handleChangePayee("type")}
                        inputProps={{ maxlength: 3 }}
                        dropdownList={
                          dropdowns &&
                          dropdowns["F1#F_PAYMENT_REQ_TY_CD"] &&
                          dropdowns["F1#F_PAYMENT_REQ_TY_CD"].length > 0
                            ? dropdowns["F1#F_PAYMENT_REQ_TY_CD"].map(
                                (option) => (
                                  <option key={option.code} value={option.code}>
                                    {option.description}
                                  </option>
                                )
                              )
                            : null
                        }
                      />
                    ) : (
                      <NativeDropDown
                        id="payeeType"
                        label="Type"
                        value={payeeValues.type}
                        isRequired
                        onChange={handleChangePayee("type")}
                        inputProps={{ maxlength: 3 }}
                        dropdownList={
                          payeeTypeList && payeeTypeList.length > 0
                            ? payeeTypeList.map((option) => (
                                <option key={option.code} value={option.code}>
                                  {option.description}
                                </option>
                              ))
                            : null
                        }
                        errTxt={
                          showPayeeTypeeErr
                            ? PaymentCriteriaConstant.TYPE
                            : null
                        }
                      />
                    )}

                    <div className="mui-custom-form input-md ">
                      <TextField
                        id="standard-Value"
                        fullWidth
                        label="Value"
                        disabled={values?.hasBeenProcessed === "Y"}
                        value={payeeValues.value}
                        onChange={handleChangePayee("value")}
                        inputProps={{ maxlength: 100 }}
                        placeholder=""
                        InputLabelProps={{
                          shrink: true,
                          required: true
                        }}
                        data-testid ='test-value'
                        onKeyDown={evt =>
                          (evt.key === '.' || evt.key === 'e') &&
                          evt.preventDefault()
                        }
                        helperText={showPayeeValueErr ? PaymentCriteriaConstant.VALUE : showPayeeValidErr ? PaymentCriteriaConstant.PAYEE_NUM_INVALID : showPayeeDupErr ? PaymentCriteriaConstant.PAYEE_NUM_DUPLICATE : null}
                        error={showPayeeValueErr ? PaymentCriteriaConstant.VALUE : showPayeeValidErr ? PaymentCriteriaConstant.PAYEE_NUM_INVALID : showPayeeDupErr ? PaymentCriteriaConstant.PAYEE_NUM_DUPLICATE : null}
                      ></TextField>
                    </div>
                    <div className="mui-custom-form">
                      <br></br>
                      <Button
                        className="btn btn-primary btn btn-outlined ml-1"
                        onClick={clickFindPayee}
                      >
                        <i class="fa fa-search" aria-hidden="true"></i>
                        FIND/NEW PAYEE
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </fieldset>
    </div>
  );
}

export default PaymentCriteriaDetails;
