/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/
  
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Bootstrap, { Button } from 'react-bootstrap';
import SearchResults from './SearchResults.json';
import { withRouter } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import './FCNSearchResults.scss';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TableComponent from '../../../../SharedModules/Table/Table';
import TabPanel from '../../../../SharedModules/TabPanel/TabPanel';

function FinancialFCNSearchResults (props) {
  const tableRecord = props.location.state.payload;

  const [value, setValue] = React.useState(0);
  const [values, setValues] = React.useState({
    lineOfBusiness: '',
    entityType: '',
    entityIDTypeCode: '',
    entityID: '',
    payeePayerID: '',
    payeePayerName: '',
    activeTypeCode: '',
    activityType: '',
    sysID: '',
    FCN: '',
    CheckNumber: '',
    CheckDate: '',
    ReasonCode: '',
    Amount: '',
    ESDate: '',
    RelatedFT: ''
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  useEffect(() => {
    setValues({
      lineOfBusiness: tableRecord.lineOfBusiness
        ? tableRecord.lineOfBusiness
        : '',
      entityType: tableRecord.entityType ? tableRecord.entityType : '',
      entityIDTypeCode: tableRecord.entityIDTypeCode
        ? tableRecord.entityIDTypeCode
        : '',
      entityID: tableRecord.entityID ? tableRecord.entityID : '',
      activeTypeCode: tableRecord.activeTypeCode
        ? tableRecord.activeTypeCode
        : '',
      payeePayerID: tableRecord.payeePayerID ? tableRecord.payeePayerID : '',
      payeePayerName: tableRecord.payeePayerName
        ? tableRecord.payeePayerName
        : '',
      sysID: tableRecord.sysID ? tableRecord.sysID : ''
    });
  }, []);
  const headCells = [
    {
      id: 'receivableFCN',
      disablePadding: false,
      label: 'FCN',
      enableHyperLink: true
    },
    {
      id: 'accountReceivableReasonCode',
      disablePadding: false,
      label: 'Reason Code',
      enableHyperLink: false
    },
    {
      id: 'entityIDTypeCode',
      disablePadding: false,
      label: 'Payee ID Type Code',
      enableHyperLink: false
    },
    {
      id: 'entityID',
      disablePadding: false,
      label: 'Payee ID',
      enableHyperLink: false
    },
    {
      id: 'activeTypeCode',
      disablePadding: false,
      label: 'Activity',
      enableHyperLink: false
    },
    {
      id: 'establishedDate',
      disablePadding: false,
      label: 'Established Date',
      enableHyperLink: false
    },
    {
      id: 'lineOfBusiness',
      disablePadding: false,
      label: 'LOB',
      enableHyperLink: false
    },
    {
      id: 'entityType',
      disablePadding: false,
      label: 'Payee Type',
      enableHyperLink: false
    },
    {
      id: 'payeePayerID',
      disablePadding: false,
      label: 'Payee ID',
      enableHyperLink: false
    },
    {
      id: 'payeePayerName',
      disablePadding: false,
      label: 'Payee Name',
      enableHyperLink: false
    },
    {
      id: 'accountReceivableReasonCode',
      disablePadding: false,
      label: 'Reason Code',
      enableHyperLink: false
    },
    {
      id: 'balanceAmount',
      disablePadding: false,
      label: 'Balance Amount',
      enableHyperLink: false
    }
  ];
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="tabs-container">
      <div className="tab-header">
        <h1 className="tab-heading float-left">Edit Payout</h1>
        <div className="float-right mt-2 mr-2">
          <Button color="primary" className="btn btn-primary ml-1">
            Print
          </Button>
          <Button color="primary" className="btn btn-primary ml-1">
            Help
          </Button>
        </div>
        <div className="clearfix"></div>
      </div>
      <div>
        <form noValidate autoComplete="off">
          <div className="form-wrapper">
            <div className="mui-custom-form input-md">
              <TextField
                id="lineOfBusiness"
                fullWidth
                disabled={true}
                label="Line Of Business"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.lineOfBusiness}
              />
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="entityType"
                fullWidth
                disabled={true}
                label="Payee type"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.entityType}
              />
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="entityIDTypeCode"
                fullWidth
                disabled={true}
                label="Payee ID Type Code"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.entityIDTypeCode}
              />
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="entityID"
                fullWidth
                disabled={true}
                label="Payee ID"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.payeePayerID}
              />
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="payeePayerName"
                fullWidth
                disabled={true}
                label="Payee Name"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.payeePayerName}
              />
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="activeTypeCode"
                fullWidth
                disabled={true}
                label="Active Type Code"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.activeTypeCode}
              />
            </div>
          </div>
        </form>
        <div>
          {/* <AppBar position="static"> */}
          {/* <Tabs value={value} onChange={handleChangeTabs} aria-label="simple tabs example">
              <Tab label="Payout Details" />
              <Tab label="History" />
            </Tabs> */}
          {/* </AppBar> */}
          <TabPanel value={value} index={0}>
            <div className="tab-holder">
              {/* <form noValidate autoComplete="off">
            <div className='form-wrapper'>
                <div className="mui-custom-form input-210" style={{marginLeft:'-20px'}} >
                <TextField
                    id="lob"
                    fullWidth
                    disabled={true}

                    label="FCN"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={values.FCN}
                />
                </div>

                <div className="mui-custom-form input-210">
                <TextField
                    id="payeeType"
                    fullWidth
                    disabled={true}
                    label="Check Number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={'122333333' }
                />
                </div>

                <div className="mui-custom-form input-210" style={{marginLeft:'5px'}}>
                <TextField
                    id="payeeIDTypeCode"
                    fullWidth
                    disabled={true}
                    label="Check Date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={values.CheckDate}
                />
                </div>

                <div className="mui-custom-form input-210">
                <TextField
                    id="payeeID"
                    fullWidth
                    disabled={true}
                    label="Reason Code"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={values.ReasonCode}
                />
                </div>

                <div className="mui-custom-form input-210" style={{marginLeft:'-20px', marginTop:'-10px'}}>
                <TextField
                    id="payeeName"
                    fullWidth
                    disabled={true}
                    label="Amount"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={values.Amount}
                />
                </div>

                <div className="mui-custom-form input-220" style={{ marginTop:'-10px'}}>
            <TextField
                id="sysPayeeId"
                fullWidth
                disabled={true}
                label="Established Date"
                InputLabelProps={{
                    shrink: true,
                }}
                value={values.ESDate}
            />
            </div>
            <div className="mui-custom-form input-210" style={{ marginTop:'-10px'}}>
            <TextField
                id="sysPayeeId"
                fullWidth
                disabled={true}
                label="Related FT TCN"
                InputLabelProps={{
                    shrink: true,
                }}
                value={values.RelatedFT}
            />
            </div>

            </div>
            <div  style={{ maxWidth: '398px' }}>
              <fieldset className="custom-fieldset">
                <legend>Account:</legend>

                  <div className="mui-custom-form input-md">
                    <TextField
                      required
                      fullWidth
                      id="standard-originala/ramount"
                      label="Fiscal Year"
                      placeholder=""
                      inputProps={{ maxlength: 11 }}
                      InputLabelProps={{
                        shrink: true
                      }}

                    >
                    </TextField>

                  </div>
                  <div className="mui-custom-form with-select input-sm">
                    <TextField
                      select
                      fullWidth
                      id="standard-FCN"
                      label="Category of Service"
                    //   value={props.values.categoryOfService}
                    //   onChange={props.handleChange('categoryOfService')}
                   placeholder=""
                      inputProps={{ maxlength: 10 }}
                   InputLabelProps={{
                     shrink: true
                  }}
                    >

                    </TextField>
                  </div>
                  <div className="mui-custom-form  with-select input-md">
                    <TextField
                      required
                      fullWidth
                      select
                      id="standard-FCN"

                      label="Fund Code"

                    //   value={props.values.collocationCode}
                    //   onChange={props.handleChange('collocationCode')}

                      placeholder=""
                      inputProps={{ maxlength: 10 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    //   helperText={props.errors[2] ? props.errorText[2] : null}
                    //   error={props.errors[2] ? props.errorText[2] : null}
                    >

                    </TextField>
                  </div>

              </fieldset>
            </div>

        </form> */}

              {/* <SystemListAddTable tableData={tableData} onEditSystemlistDetail={onClickeditSystemList} /> */}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TableComponent
              headCells={headCells}
              tableData={[]}
              defaultSortColumn={'receivableFCN'}
            />
          </TabPanel>
        </div>
      </div>
    </div>
  );
}

export default withRouter((FinancialFCNSearchResults));
