/* eslint-disable camelcase */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const Required_Field_Error = ' is required.';
export const Invalid_Format_Error = 'Data entered is in the incorrect format.';
export const From_Thru_Equality_Error = 'Beginning Revenue Code Value must be less than or equal to Ending Revenue Code Value.';
export const FROM_THRU_EQUAL_ERROR_HCPCS = 'Beginning HCPCS Code Value must be less than or equal to Ending HCPCS Code Value.';
export const FROM_THRU_EQUAL_ERROR_SP = 'Beginning Surgical Procedure Code Value must be less than or equal to Ending Surgical Procedure Code Value.';
export const Current_Date_Error = 'Date must be greater than or equal to the current date.';
export const Begin_End_Date_Error = 'The begin date must be less than or equal to the end date.';
export const ERR_PROCESSING_REQ = 'There was an error processing the request. Please retry the transaction.';
export const SUCCESS_MSG = 'System successfully saved the information.';
export const TEXT_TYPE_REQUIRED = 'Text Type is required.';
export const Invalid_Format_Error_BeginDate = 'Begin Date that was entered is invalid.';
export const Invalid_Format_Error_EndDate = 'End Date that was entered is invalid.';
export const Invalid_Format_Error_TerminationDate = 'Termination Date that was entered is invalid.';
export const VOIDED_ROW_ERROR = 'Voided rows can not be updated or changed.';
export const NO_RECORDS_FOUND = 'No records found for the search criteria entered.';
export const PROVIDER_NOTICE_TEXT_END_DATE_ERROR = 'When updating the Provider Notice End date it must be greater than or equal to the current date.';
