/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const FUNCTIONAL_AREA_REQUIRED = 'Thru Code is a required field if From Code is entered.';
export const BUSINESS_NAME_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.'
    ;
export const DATA_ELEMENT_NAME_ERROR = 'Either reject code or text is required.'
    ;
export const PN_PROVIDERTYPE_ERROR = 'Please Select Provider Type.';
export const LOCKBOX_NAME_ERROR = 'is required.';
export const END_DATE_ERROR = 'End Date must be greater than or equal to the Begin Date.';
export const BEGIN_DATE_ERROR = 'Begin date can not be in past.';
export const TRANSACTION_NUMBER_FORMAT = 'should not contain spaces or special characters.';

export const PAYEE_TYPE_REQUIRED = 'Payee Type is required.';
export const PAYEE_TYPE_ID_CODE_REQUIRED = 'Payee ID Type Code is required.';
export const PAYEE_ID_REQUIRED = 'Payee ID is required.';
export const ACTIVITY_TYPE_REQUIRED = 'Activity Type is required.';
export const REASON_CODE_REQUIRED = 'Reason Code is required.';
export const ORIGINAL_AR_AMOUNT_REQUIRED = 'Original A/R Amount is required.';
export const APPROVAL_ERROR = 'Approval must be No for Gross level, withhold Lien/Levy.';
export const FISCAL_YEAR_REQUIRED = 'Fiscal Year is required.';
export const COLLOCATION_CODE = 'Fund Code is required.';
export const RECOUPMENT_TYPE_CODE_REQUIRED = 'Recoupment Type Code is required.';
export const LIEN_HOLDER_TYPE_REQUIRED = 'Lien Holder Type is required.';
export const LIEN_HOLDER_TYPE_CODE_REQUIRED = 'Lien Holder ID Type Code is required.';
export const LIEN_HOLDER_ID_REQUIRED = 'Lien Holder ID is required.';
export const LIEN_HOLDER_ID_SAME_ERR = 'Lien Holder ID cannot be same as Payee ID.'
export const DUE_DATE_REQUIRED = 'Due Date is required.';
export const AMOUNT_DUE = 'Installment Amount is required.';
export const PAYMENT_SCHEDULE_TYPE = 'Payment Schedule Type is required.';
export const RECOPUMENT_START_DATE = 'Recoupment Start Date is required.';
export const FREQUENCY = 'Frequency is required.';
export const NO_OF_INSTALLMENTS = 'No of Installments is required.';
export const INTEREST_PERCENT = 'Interest Percentage is required.';
export const BEGIN_DATE_INVALID = 'Begin Date Entered is Invalid.';
export const END_DATE_INVALID = 'End Date Entered is Invalid.';
export const BEGIN_DATE_REQUIRED = 'Begin Date is required.';
export const END_DATE_REQUIRED = 'End Date is required.';
export const PERCENT_REQUIRED = 'Percent Value is required.';
export const PERCENT_ZERO = 'Percentage Amount must be greater than 0. Please Re-Enter.';
export const INSTALLMENT_REQUIRED = 'Installment Value is required.';
export const START_DATE_ERROR = 'Start date must be greater than or equal to today’s date.';
export const DUE_DATE_ERROR = 'Due Date must be greater than or equal to today’s date.';
export const DUE_DATE_RECOUP = 'Due Date should not be less than the recoupment start date.';
export const START_DATE_REQUIRED = ' Start Date is required.';
export const START_DATE_BLANK = 'For Recoupment Type No Recoupment, Recoupment Start Date must be blank.';
export const DUE_DATE_INVALID = 'Due Date entered is invalid.';
export const DEFAULT_DATE_GT_TODAY = 'Default Date cannot be prior to today’s date.';
export const NUMBER_OF_INSTALLMENTS = 'Number of Installments is required.';
export const START_DATE_INVALID = 'Start Date entered is invalid.';
export const START_DATE_LESS_CURRENT_DATE_ERROR = 'Recoupment Start Date cannot be less than {current date}. Please re-enter.';
export const START_DATE_LESS_AR_CREATED_ERROR = 'The Recoupment Start Date must be greater than or equal to the date the A/R was created. Please re-enter.';
export const DEFAULT_DATE_INVALID = 'Default Date entered is invalid.';
export const DEFAULT_DATE_LESS = 'Default Date can not be prior to todays date.';
export const START_DATE_SHOULD_BE_GREATER = 'The Recoupment Start Date must be greater than or equal to the date the A/R was created. Please re-enter.';
export const DUPLICATE_DUE_DATE = 'Duplicate Due Date cannot be added please re-enter.';
export const START_DATE_PERCENT_REQUIRED = 'Recoupment start date and Percentage value are required.';
export const NEGATIVE_AMOUNT = 'A negative Installment Amount cannot be entered please re-enter.';
export const GREATER_THAN_ZERO_AMOUNT = 'Installment Amount must be greater than zero.';
export const VALID_PERCENT_VALUE = 'Recoupment Percentage Value must be between 0 and 100. Please re-enter.';
export const NEGATIVE_INSTALLMENT_VALUE = 'A negative Installment value is not allowed. Please re-enter.';
export const NEGATIVE_PERCENT = 'A negative Percentage value cannot be entered. Please re-enter.';
export const PERCENT_INVALID = 'Percentage value cannot be greater than 100.  Please re-enter.';
export const INSTALLMENT_AMOUNT_INVALID = 'Installment Amount cannot exceed Original/Adjusted A/R Amount. Please re-enter.';
export const PAYMENT_SCHEDULE_ERROR = 'For recoupment type scheduled payment, a payment schedule is required.';
export const BOTH_VALUES_CANNOT_ENTER = 'Both Percent and Installment values are not allowed at the same time. Please re-enter.';
export const MANUAL_CHECK_NUMBER_REQIRED = 'Manual Check number is required.';
export const MANUAL_CHECK_DATE_REQUIRED = 'Manual check date is required.';
export const INSTALLMENT_MUST_BE_BLANK = 'For Recoupment Type Percent, Installment Value must be blank.';
export const START_DATE_INSTALLMENT_REQUIRED = 'Recoupment start date and installment value are required. Please enter required field.';
export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const REASON_CODE_SETTLEMENT_ERROR = 'For the selected Reason Code, Begin and End dates are required.';
export const RECOUPMENT_PERCENT_AND_DATE_REQUIRED = 'Recoupment start date and Percentage value are required.';
export const RECOUPMENT_START_DATE_FOR_INSTALLMENT = 'The Recoupment Start Date must be greater than or equal to the date the A/R was created. Please re-enter.';
export const INSTALLMENT_MUST_BE_GREATER_0 = 'Installment Value must be greater than 0. Please re-enter.';
export const PERCENT_MUST_BE_BLANK = 'For Recoupment Type Installment, Percent Value must be blank.';
export const FREQUENCY_BLANK_NO_RECOUP = 'For Recoupment Type No Recoupment, Frequency must be blank.';
export const PERCENT_MUST_BE_WITHIN_0_100 = 'Recoupment Percentage Value must be between 0 and 100. Please re-enter.';
export const PERCENT_VALUE_CAN_NOT_GREATER_100 = 'Percentage value cannot be greater than 100. Please re-enter.';
export const PERCENT_NEGATIVE_ERROR = 'A negative Percentage value cannot be entered. Please re-enter.';
export const INCORRECT_DATA_FORMAT = 'Data entered is in the incorrect format.';
export const INSTALLMENT_NEGATIVE_ERROR = 'A negative Installment value is not allowed. Please re-enter.';
export const INSTALLMENT_GREATER_ORIGINAL_AMOUNT_ERROR = 'Installment Amount cannot exceed Original/Adjusted A/R Amount. Please re-enter.';
export const INVALID_RECOUPMENT_TYPE = 'Invalid Recoupment Type Code for Withholding Lien.';
export const INSTALLMENT_MORE = 'Number of installments cannot be greater than ';
export const SUM_AMOUNT = 'Sum of installment amount is not equal to the Original/Adjusted AR amount.';
export const RECOUPEMENT_INVALID = 'Invalid Recoupment Type Code for Payment Schedule Type.';

export const AP = 'advancedPaymentReasonCodeList';
export const CR = 'wlaimRecvReasonCodeList';
export const GL = 'grossLevelReasonCodeList';
export const RA = 'adjustmentReasonCodeList';
export const RR = 'reversalReasonCodeList';
export const RT = 'transferReasonCodeList';
export const WL = 'withHoldingReasonCodeList';
export const WO = 'writeOffReasonCodeListForSearch';
export const WR = 'wrWOReestablishmentReasonCodeList';

export const AP_TEXT = 'AP';
export const CR_TEXT = 'CR';
export const GL_TEXT = 'GL';
export const RA_TEXT = 'RA';
export const RR_TEXT = 'RR';
export const RT_TEXT = 'RT';
export const WL_TEXT = 'WL';
export const WO_TEXT = 'WO';
export const WR_TEXT = 'WR';

export const PAYEE_TYPE = 'payeeType';
export const ACTIVITY_CODE = 'activityCode';
export const REASON_CODE = 'reasonCode';
export const TRANSACTION_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const BALNACE_AMOUNT_ZERO_ERROR = 'Balance amount must be greater than zero to write-off re-establish.';
export const WRITE_OFF_BALNACE_AMOUNT_ZERO_ERROR = 'Balance amount on original FCN transaction must be greater than zero to write-off.';
export const TRANSFER_BALNACE_AMOUNT_ZERO_ERROR = 'An A/R with a zero balance cannot be Transferred.';
export const ADJUSTED_BALNACE_AMOUNT_ZERO_ERROR = 'A Receivable with a Balance Amount of zero cannot be adjusted.';
export const ADV_PAYMENT = 'An advance payment receivable cannot be adjusted.';
export const INSTALLMENT_INVALID = 'Installment Value entered is invalid.';
export const PERCENT_INVALID_ERROR = 'Percent Value entered is invalid.';
export const INSTALLMENT_START_DATE_REQUIRED = 'Recoupment start  date and value are required. Please enter required field.';
export const PERCENTAGE_START_DATE_REQUIRED = 'Recoupment start  date and Percentage value are required.';
export const RECOUPMENT_START_DATE_ERROR = 'Recoupment Start Date cannot be less than {current date}. Please re-enter.';
export const DEFAULT_ERROR = 'Default Date cannot be prior to todays date.';
export const INSTALLMENT_PERCENT_MUST_BE_0 = 'For Recoupment Type No Recoupment, Installment and Percent must be zero.';
export const FREQUENCY_MUST_BE_BLANK = 'For Recoupment Type No Recoupment, the frequency must be blank.';
export const Invalid_Format_Error = 'Data entered is in the incorrect format.';
export const RECOUPMENT_CODE_REQUIRED = 'Recoupment Type Code is required.';
export const AMOUNTDUE_ORIGINALAMOUNT_VALIDATION = 'Installment Amount cannot exceed Original/Adjusted A/R Amount. Please re-enter.';
export const DATA_INVALID = 'Please enter the data in correct format.';
export const TECHNICAL_ERROR = 'There was an error processing the request. Please contact Technical Support.';
export const RECOUPMENT_BALANCE_INVALID = 'Invalid Recoupment Type Code. Payment Schedule can not be set if Original/Adjusted AR Amount and balance amount are not equal.';
export const BEGIN_DT_END_DT_ERROR = 'For the selected Reason Code, Begin and End dates are required.';
