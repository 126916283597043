/* eslint-disable */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import { useSelector } from "react-redux";
import { generateUUID } from "../DateUtilities/DateUtilities";
import { parseJwt } from "../store/Actions/SharedAction";
import * as SERVICE from "../services/service";

const findTarget = (arr, target, path = [], nestedLvl = 0) => {
  for (let i = 0; i < arr.length; i++) {
    const newPath = [...path];
    newPath.push(arr[i].id);

    if (
      arr[i].navTo === target ||
      (arr[i].hasAliases && arr[i].hasAliases.includes(target))
    ) {
      return newPath;
    }

    if (arr[i].hasChildren) {
      const result = findTarget(
        arr[i].children,
        target,
        newPath,
        nestedLvl + 1
      );
      if (result) {
        return result;
      }
    }
  }
};

export const getActivePath = (urlPath, expandLvl) => {
  return findTarget(navStructure, urlPath, expandLvl);
};

const icons = {
  facogs: (
    <span className="sidebar-icon">
      <i
        className="fa fa-cog"
        aria-hidden="true"
        style={ { fontSize: "26px" } }
      ></i>
    </span>
  ),
  fauniversity: (
    <span className="sidebar-icon">
      <i className="fa fa-university" aria-hidden="true"></i>
    </span>
  ),
  fabars: (
    <span className="sidebar-icon double-icons">
      <i className="fa fa-usd" aria-hidden="true"></i>
      <i className="fa fa-bars" aria-hidden="true"></i>
    </span>
  ),
  falinechart: (
    <span className="sidebar-icon">
      <i className="fa fa-line-chart" aria-hidden="true"></i>
    </span>
  ),
  falock: (
    <span className="sidebar-icon">
      <i
        className="fa fa-lock"
        aria-hidden="true"
        style={ { fontSize: "28px" } }
      ></i>
    </span>
  ),
  fausers: (
    <span className="sidebar-icon">
      <i className="fa fa-users" aria-hidden="true"></i>
    </span>
  ),
  faclipboard: (
    <span className="sidebar-icon">
      <i className="fa fa-clipboard" aria-hidden="true"></i>
    </span>
  ),
  famoney: (
    <span className="sidebar-icon">
      <i className="fa fa-money" aria-hidden="true"></i>
    </span>
  ),
  fafiletext: (
    <span className="sidebar-icon">
      <i className="fa fa-file-text" aria-hidden="true"></i>
    </span>
  ),
  fausd: (
    <span className="sidebar-icon">
      <i className="fa fa-usd" aria-hidden="true"></i>
    </span>
  ),
  facheck: (
    <span className="sidebar-icon">
      <i
        className="fa fa-check"
        aria-hidden="true"
        style={ { fontSize: "28px" } }
      ></i>
    </span>
  ),
  fauser: (
    <span className="sidebar-icon">
      <i className="fa fa-user" aria-hidden="true"></i>
    </span>
  ),
  fadesktop: (
    <span className="sidebar-icon">
      <i className="fa fa-desktop" aria-hidden="true"></i>
    </span>
  ),
  faclaim: (
    <span className="sidebar-icon">
      <i className="fa fa-external-link" aria-hidden="true"></i>
    </span>
  ),
  fahandshake: (
    <span className="sidebar-icon">
      <i className="fa fa-handshake-o" aria-hidden="true"></i>
    </span>
  ),
  faemar: (
    <span className="sidebar-icon">
      <i
        className="fa fa-etsy"
        aria-hidden="true"
        style={ { fontSize: "16px" } }
      ></i>
    </span>
  ),
  fareporting: (
    <span className="sidebar-icon">
      <i class="fas fa-chart-bar" aria-hidden="true"></i>
    </span>
  ),
  fadollar: (
    <span className="sidebar-icon">
      <i class="fa fa-usd" aria-hidden="true"></i>
      <i
        class="fa fa-exchange"
        style={ {
          fontSize: "13px",
          paddingTop: "3px"
        } }
        aria-hidden="true"
      ></i>
    </span>
  ),
  faexchange: (
    <span className="sidebar-icon">
      <i className="fa fa-exchange" aria-hidden="true"></i>
    </span>
  ),
  famedkit: (
    <span className="sidebar-icon">
      <i className="fa fa-medkit" aria-hidden="true"></i>
    </span>
  ),
  fabarchart: (
    <span className="sidebar-icon">
      <i className="fa fa-bar-chart" aria-hidden="true"></i>
    </span>
  ),
  faeye: (
    <span className="sidebar-icon">
      <i className="fa fa-eye" aria-hidden="true"></i>
    </span>
  ),
};

const dataToUrlParam = rowData => {
  const objStr = JSON.stringify(rowData);
  const toBase64 = btoa(objStr);
  return toBase64;
};

export const navStructure = [
  {
    value: "CMdS-Financial",
    navTo: "/dashboard",
    id: generateUUID(),
    hasChildren: false
  },
  {
    value: "Configuration",
    id: generateUUID(),
    icon: icons.faCog,
    privilegesNeeded: "applicationConfig",
    hasChildren: true,
    expanded: false,
    children: [
      {
        value: "Valid Value",
        id: generateUUID(),
        hasAliases: ["/ValidvalueView"],
        hasChildren: true,
        expanded: false,
        children: [
          {
            value: "Search",
            id: generateUUID(),
            navTo: "/ValidvalueSearch",
            hasChildren: false
          }
        ]
      },
      {
        value: "System Parameter",
        id: generateUUID(),
        hasAliases: ["/SystemParametersEdit"],
        hasChildren: true,
        expanded: false,
        children: [
          {
            value: "Search",
            id: generateUUID(),
            navTo: "/SystemParameters",
            hasChildren: false
          },
          {
            value: "Add",
            userInquiry: "applicationUser",
            id: generateUUID(),
            navTo: "/SystemParametersAdd",
            hasChildren: false
          }
        ]
      },
      {
        value: "System List",
        id: generateUUID(),
        hasAliases: ["/SystemListAddUpdate"],
        hasChildren: true,
        expanded: false,
        children: [
          {
            value: "Search",
            id: generateUUID(),
            navTo: "/SystemListSearch",
            hasChildren: false
          },
          {
            value: "Add",
            userInquiry: "applicationUser",
            id: generateUUID(),
            navTo: "/SystemListAdd",
            hasChildren: false
          }
        ]
      },
      {
        value: "Map Definition",
        id: generateUUID(),
        hasAliases: ["/MapSetEdit"],
        hasChildren: true,
        expanded: false,
        children: [
          {
            value: "Search",
            id: generateUUID(),
            navTo: "/MapSetSearch",
            hasChildren: false
          },
          {
            value: "Add",
            userInquiry: "applicationUser",
            id: generateUUID(),
            navTo: "/MapSetAdd",
            hasChildren: false
          }
        ]
      },
      {
        value: "Text Management",
        id: generateUUID(),
        hasAliases: ["/TextUpdate"],
        hasChildren: true,
        expanded: false,
        children: [
          {
            value: "Search",
            id: generateUUID(),
            navTo: "/TextManagementSearch",
            hasChildren: false
          },
          {
            value: "Add",
            userInquiry: "applicationUser",
            id: generateUUID(),
            navTo: "/TextAdd",
            hasChildren: false
          }
        ]
      },
      {
        value: "Federal Stamping",
        id: generateUUID(),

        hasChildren: true,
        expanded: false,
        children: [
          {
            value: "CMS TOS",
            id: generateUUID(),
            hasAliases: ["/EMARUpdate"],
            hasChildren: true,
            expanded: false,
            children: [
              {
                value: "Search",
                id: generateUUID(),
                navTo: "/EMARSearch",
                hasChildren: false
              },
              {
                value: "Add",
                id: generateUUID(),
                navTo: "/EMARAdd",
                hasChildren: false
              }
            ]
          },
          {
            value: "CMS Form/COL",
            id: generateUUID(),
            hasAliases: ["/CMS64Update"],
            hasChildren: true,
            expanded: false,
            children: [
              {
                value: "Search",
                id: generateUUID(),
                navTo: "/CMS64Search",
                hasChildren: false
              },
              {
                value: "Add",
                id: generateUUID(),
                navTo: "/CMS64Add",
                hasChildren: false
              }
            ]
          },
          {
            value: "Financial Transactions",
            id: generateUUID(),
            hasAliases: ["/FRCUpdate"],
            hasChildren: true,
            expanded: false,
            children: [
              {
                value: "Search",
                id: generateUUID(),
                navTo: "/FRCSearch",
                hasChildren: false
              },
              {
                value: "Add",
                id: generateUUID(),
                navTo: "/FRCAdd",
                hasChildren: false
              }
            ]
          },
          {
            value: "Waiver Services",
            id: generateUUID(),
            hasAliases: ["/WaiverUpdate"],
            hasChildren: true,
            expanded: false,
            children: [
              {
                value: "Search",
                id: generateUUID(),
                navTo: "/WaiverSearch",
                hasChildren: false
              },
              {
                value: "Add",
                id: generateUUID(),
                navTo: "/WaiverAdd",
                hasChildren: false
              }
            ]
          }
        ]
      }
    ]
  },
  {
    value: "Bank Account",
    id: generateUUID(),
    hasAliases: ["/BankAccountLockBoxEdit"],
    icon: icons.faUniversity,
    privilegesNeeded: "bankAccount",
    hasChildren: true,
    expanded: false,
    children: [
      {
        value: "Search",
        id: generateUUID(),
        navTo: "/BankAccountAndLockbox",
        hasChildren: false
      },
      {
        value: "Add",
        userInquiry: "bankAccUser",
        id: generateUUID(),
        navTo: "/BankAccountLockBoxAdd",
        hasChildren: false
      }
    ]
  },
  {
    value: "Fund Code",
    id: generateUUID(),
    hasAliases: ["/FundCodeEdit"],
    icon: icons.faUsdBars,
    privilegesNeeded: "fundCode",
    hasChildren: true,
    expanded: false,
    children: [
      {
        value: "Search",
        id: generateUUID(),
        navTo: "/FundCodeSearch",
        hasChildren: false
      },
      {
        value: "Add",
        userInquiry: "fundCodeUSer",
        id: generateUUID(),
        navTo: "/FundCodeAdd",
        hasChildren: false
      }
    ]
  },
  {
    value: "Budget Maintenance",
    id: generateUUID(),
    hasAliases: ["/BudgetDataUpdate"],
    icon: icons.faLineChart,
    privilegesNeeded: "budgetMaintenance",
    hasChildren: true,
    expanded: false,
    children: [
      {
        value: "Search",
        id: generateUUID(),
        navTo: "/FinancialBudgetSearch",
        hasChildren: false
      },
      {
        value: "Add",
        userInquiry: "budgetMainUser",
        id: generateUUID(),
        navTo: "/BudgetDataAdd",
        hasChildren: false
      }
    ]
  },
  {
    value: "Fiscal Pend Criteria",
    id: generateUUID(),
    hasAliases: ["/FiscalPendCriteriaEdit"],
    icon: icons.faLock,
    privilegesNeeded: "fiscalPend",
    hasChildren: true,
    expanded: false,
    children: [
      {
        value: "Search",
        id: generateUUID(),
        navTo: "/FiscalPendCriteriaSearch",
        hasChildren: false
      },
      {
        value: "Add",
        userInquiry: "fiscalPendUser",
        id: generateUUID(),
        navTo: "/FiscalPendCriteriaAdd",
        hasChildren: false
      }
    ]
  },
  {
    value: "Financial Entity",
    id: generateUUID(),
    hasAliases: ["/FinancialEntityUpdate"],
    icon: icons.faUsers,
    privilegesNeeded: "financialEntity",
    hasChildren: true,
    expanded: false,
    children: [
      {
        value: "Search",
        id: generateUUID(),
        navTo: "/FinancialEntitySearch",
        hasChildren: false
      },
      {
        value: "Add",
        userInquiry: "financialEntityUSer",
        id: generateUUID(),
        navTo: `/FinancialEntityAdd`,
        hasChildren: false
      }
    ]
  },
  {
    value: "Accounts Receivable",
    id: generateUUID(),
    hasAliases: [
      "/FinancialAccountGrossLevelUpdate",
      "/FinancialAccountGrossLevel",
      "/FinancialAccountLienLevyUpdate",
      "/AdvancePayment",
      "/ClaimsReceivable",
      "/ARTransfer",
      "/ARReverse",
      "/ARAdjust",
      "/WriteOff",
      "/WriteOffReEstablishment",
      "/ARBulkTransfer",
      "/BulkReverse",
      "/BulkWriteOff",
      "/BulkWriteOffReest"
    ],
    icon: icons.faClipboard,
    privilegesNeeded: "accountReceivable",
    hasChildren: true,
    expanded: false,
    children: [
      {
        value: "Search",
        id: generateUUID(),
        navTo: "/FinancialAccountInquiry",
        hasChildren: false
      },
      {
        value: "Add",
        userInquiry: "aRMainUser",
        id: generateUUID(),
        navTo: "/GrossLevelAddPayeeComponent",
        hasChildren: false
      },
      {
        value: "Bulk Maintenance",
        id: generateUUID(),
        userInquiry: "aRMainUser",
        navTo: "/BulkMaintenance",
        hasChildren: false
      }
    ]
  },
  {
    value: "Financial Payout",
    id: generateUUID(),
    hasAliases: ["/FCNPayOutSearchResults", "/FinancialPayoutUpdate"],
    icon: icons.faMoney,
    privilegesNeeded: "fiscalPayout",
    hasChildren: true,
    expanded: false,
    children: [
      {
        value: "Search",
        id: generateUUID(),
        navTo: "/FinancialPayOutSearch",
        hasChildren: false
      },
      {
        value: "Search Approval",
        userInquiry: "payOutManager",
        id: generateUUID(),
        navTo: "/PayoutApprovalSearch",
        hasChildren: false
      },
      {
        value: "Add",
        userInquiry: "payoutManagerorMainUser",
        id: generateUUID(),
        navTo: "/FinancialPayOutAdd",
        hasChildren: false
      }
    ]
  },
  {
    value: "Financial Receipt",
    id: generateUUID(),
    hasAliases: [
      "/FinancialReceiptReturn",
      "/FinancialReceiptUpdate",
      "/FinancialReceiptAdjustment",
      "/FinancialReceiptReversal",
      "/ReissueUpdate"
    ],
    icon: icons.faFileText,
    privilegesNeeded: "financialReceipt",
    hasChildren: true,
    expanded: false,
    children: [
      {
        value: "Search",
        id: generateUUID(),
        navTo: "/FinancialReceiptSearch",
        hasChildren: false
      },
      {
        value: "Add",
        userInquiry: "receiptMainUser",
        id: generateUUID(),
        navTo: "/FinancialReceiptAdd",
        hasChildren: false
      }
    ]
  },
  {
    value: "Financial TXN",
    id: generateUUID(),
    hasAliases: ["/FinancialUploadView"],
    icon: icons.faDollar,
    privilegesNeeded: "FinancialTXN",
    hasChildren: true,
    expanded: false,
    children: [
      {
        value: "Data Upload",
        userInquiry: "financeUploadMainUser",
        id: generateUUID(),
        navTo: "/FinancialUpload",
        hasChildren: false
      }
    ]
  },
  {
    value: "Financial Inquiry",
    id: generateUUID(),
    hasAliases: ["/ClaimSummary"],
    icon: icons.faUsd,
    privilegesNeeded: "FinancialInquiry",
    hasChildren: true,
    expanded: false,
    children: [
      {
        value: "Search",
        id: generateUUID(),
        navTo: "/FinancialInquirySearch",
        hasChildren: false
      }
    ]
  },
  {
    value: "Payment Status Inquiry",
    id: generateUUID(),
    hasAliases: ["/ClaimSummary"],
    icon: icons.faUsd,
    privilegesNeeded: "PaymentStatusInquirySearch",
    hasChildren: true,
    expanded: false,
    children: [
      {
        value: "Search",
        id: generateUUID(),
        navTo: "/PaymentStatusInquirySearch",
        hasChildren: false
      }
    ]
  },
  {
    value: "Maintain 1099",
    id: generateUUID(),
    hasAliases: ["/CorrectionDetailsView"],
    icon: icons.faCheck,
    privilegesNeeded: "Correction1099",
    hasChildren: true,
    expanded: false,
    children: [
      {
        value: "Search",
        id: generateUUID(),
        navTo: "/CorrectionSearch",
        hasChildren: false
      },
      {
        value: "Search Approval",
        userInquiry: "mainManager1099",
        id: generateUUID(),
        navTo: "/CorrectionSearchApproval",
        hasChildren: false
      }
    ]
  },
  {
    value: "Project Control",
    id: generateUUID(),
    icon: icons.faUser,
    privilegesNeeded: "ProjectControl",
    hasChildren: true,
    expanded: false,
    children: [      
      {
        value: "Event Logging",
        id: generateUUID(),
        navTo: "/EventLogging",
        hasChildren: false,
      },
      {
        value: "Global Audit Search",
        id: generateUUID(),
        navTo: "/GlobalAuditSearch",
        hasChildren: false,
      }
    ],
  },
  {
    value: "Manage Account",
    id: generateUUID(),
    icon: icons.faUser,
    privilegesNeeded: "FssAdminUser",
    hasChildren: true,
    expanded: false,
    children: [
      {
        value: "Add/Edit User",
        id: generateUUID(),
        navTo: "/AddUser",
        hasChildren: false
      }
    ]
  },
  {
    value: "Insights",
    id: generateUUID(),
    icon: icons.faDesktop,
    hasChildren: true,
    expanded: false,
    children: [
      {
        value: "Operational Insights",
        id: generateUUID(),
        navTo: SERVICE.OPERATIONAL_INSIGHT_LINK,
        target: "_blank",
        hasChildren: false
      },
      {
        value: "Performance Insights",
        id: generateUUID(),
        navTo: SERVICE.PERFORMANCE_INSIGHT_LINK,
        target: "_blank",
        hasChildren: false
      }
    ]
  },
  {
    value: "Federal Reporting",
    id: generateUUID(),
    icon: icons.faLineChart,
    hasChildren: true,
    expanded: false,
    children: [
      {
        value: "MARS",
        id: generateUUID(),
        navTo: SERVICE.MARS_INSIGHT_LINK,
        target: "_blank",
        hasChildren: false
      },
      {
        value: "FADS",
        id: generateUUID(),
        navTo: SERVICE.FADS_INSIGHT_LINK,
        target: "_blank",
        hasChildren: false
      }
    ]
  },
  {
    value: "Claims",
    id: generateUUID(),
    icon: icons.faClaim,
    navTo: process.env.REACT_APP_CLAIMS_URL_DASHBOARD,
    hasChildren: false
  }
];

// export const navStructure = () => {  
//   const  nav =[
//     {
//       value: "CMdS-Financial",
//       navTo: "/dashboard",
//       id: "94ae8f6a-2a26-4585-8d09-2c2acbe6df18",
//       hasChildren: false,
//     },
//   ];
//   let depth = 0;
//   let l1 = (e) => {
//     let  o = {};
//     if (e.icon) {
//     o.icon = icons[e.icon.replace('fa ','').split('-').join('')];
//     }
//     o.value = e.name;
//     o.readOnly = e.readOnly ? true : false;
//     o.id = generateUUID()
//     o.privilegesNeeded = e.previledges;
//         o.hasChildren = e.children?.length ? true : false;
//     if (o.hasChildren) {
//       o.expanded = false;
//       o.children = e.children.map(i => l2(i));   
//     } else {
//       o.navTo = e.url;
//     }      
//     if(depth === 1){
//       o.hasAliases = [`/${o.value.split(' ').join('')}`];
//     }
//     return o;
//   };
//   let l2 = (e) => {
//     let o = {};
//     depth = 1;
//     if (e.icon) {
//       o.icon = icons[e.icon.replace('fa ','').split('-').join('')];
//     }
//     o.value = e.name;
//     o.readOnly = e.readOnly ? true : false;
//     o.id = generateUUID();
//     o.privilegesNeeded = e.previledges;
//     o.hasChildren = e.children?.length ? true : false;
//     if (o.hasChildren) {
//       o.expanded = false;
//       o.children = e.children.map(i => l3(i));         
//     } else {
//       o.navTo = e.url;
//     }    
//     if(depth === 2){
//       o.hasAliases = [`/${o.value.split(' ').join('')}`];
//     }
//     return o;
//   };
//   let l3 = (e) => {
//     let o = {};
//     depth = 2;
//     if (e.icon) {
//       o.icon = icons[e.icon.replace('fa ','').split('-').join('')];
//     }
//     o.value = e.name;
//     o.readOnly = e.readOnly ? true : false;
//     o.id = generateUUID()
//     o.privilegesNeeded = e.previledges; 
//     o.hasChildren = e.children?.length ? true : false;
//     if (o.hasChildren) {
//       o.expanded = false;
//       o.children = null;    
//     } else {
//       o.navTo = e.url;
//     }
//     return o;
//   };
//   const loginState = JSON.parse(localStorage.getItem("loginState"));
//   const res = loginState .menuItems;
//   res.forEach((e) => { 
//     nav.push(l1(e));
//   });
//   return nav;  
// }
