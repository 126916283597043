/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const FINANCIAL_RECEIPT_SEARCH_ACTION_TYPE = 'FINANCIAL_RECEIPT_SEARCH_ACTION_TYPE';
export const FINANCIAL_RECEIPT_UPDATE_ACTION_TYPE = 'FINANCIAL_RECEIPT_SEARCH_UPDATE_TYPE';
export const SEARCH_SYSTEM_LIST = 'SEARCH_SYSTEM_LIST';
export const GET_PAYEEIDTYPECODE_ON_PAYEETYPE = 'GET_PAYEEIDTYPECODE_ON_PAYEETYPE';
export const GET_FINANCIALREASONCODE_ON_ACTIVITYTYPE = 'GET_FINANCIALREASONCODE_ON_ACTIVITYTYPE';
export const RESETDATA = 'RESETDATA';
export const GET_ADJUSTMENT_DATA = 'GET_ADJUSTMENT_DATA';
export const GET_ADJUSTMENT_CALCULATION = 'GET_ADJUSTMENT_CALCULATION';
export const SAVE_ADJUSTMENT_CALCULATION = 'SAVE_ADJUSTMENT_CALCULATION';
export const VALIDATE_PAYEE_RESULT = 'VALIDATE_PAYEE_RESULT';
export const FINANCIAL_ADD_GENERATE_FCN = 'FINANCIAL_ADD_GENERATE_FCN';
export const ADD_REFUND_RECOVERY_VALIDATE = 'ADD_REFUND_RECOVERY_VALIDATE';
export const ADD_FINANCIAL_RECEIPT = 'ADD_FINANCIAL_RECEIPT';
export const UPDATE_FINANCIAL_RECEIPT = 'UPDATE_FINANCIAL_RECEIPT';
export const DROPDOWNDATA = 'DROPDOWNDATA';
export const ADD_RECEIPT_REVERSAL = 'ADD_RECEIPT_REVERSAL';
export const SAVE_RECEIPT_REVERSAL = 'SAVE_RECEIPT_REVERSAL';
export const GET_CHECK_REPLACEMENT_DATA = 'GET_CHECK_REPLACEMENT_DATA';
export const FINANCIAL_RECEIPT_FCN_SEARCH_ACTION_TYPE = 'FINANCIAL_RECEIPT_FCN_SEARCH_ACTION_TYPE';
export const MEMBER_ID_DETAILS = 'MEMBER_ID_DETAILS';
export const AUDIT_LOG_TABLE = "AUDIT_LOG_TABLE";
export const AUDIT_LOG_TABLE_ROW = "AUDIT_LOG_TABLE_ROW";

