/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import TextField from '@material-ui/core/TextField';
import numeral from 'numeral';
import Radio from '@material-ui/core/Radio';

export default function ExemptionDetails (props) {
  return (
    <form autoComplete="off">
      <div className="row">
        <div className="col-3">
          <div className="form-wrapper vertical-wrapper">
            <div
              className="mui-custom-form min-md"
              style={{ lineHeight: '100%', marginBottom: '22px' }}
            >
              <label className="MuiFormLabel-root small-label">
                Exempt From Penalty<span>*</span>
              </label>
              <div className="sub-radio no-margin-y ">
                <Radio
                  value="Y"
                  id="GrossLevelExemptionexemptFromPenaltyIdYes"
                  checked={props.values.exemptFromPenalty === 'Y'}
                  onChange={props.handleChange('exemptFromPenalty')}
                />
                <label
                  className="text-black"
                  for="GrossLevelExemptionexemptFromPenaltyIdYes"
                >
                  Yes
                </label>
                <Radio
                  value="N"
                  id="GrossLevelExemptionexemptFromPenaltyIdNo"
                  checked={props.values.exemptFromPenalty === 'N'}
                  onChange={props.handleChange('exemptFromPenalty')}
                  className="ml-4"
                />
                <label
                  className="text-black"
                  for="GrossLevelExemptionexemptFromPenaltyIdNo"
                >
                  No
                </label>
              </div>
            </div>
            <div
              className="mui-custom-form min-md"
              style={{ lineHeight: '100%', marginBottom: '22px' }}
            >
              <label className="MuiFormLabel-root small-label">
                Exempt from Interest<span>*</span>
              </label>
              <div className="sub-radio no-margin-y">
                <Radio
                  value="Y"
                  id="GrossLevelExemptionexemptFromExemptIdYes"
                  checked={props.values.exemptFromInterest === 'Y'}
                  onChange={props.handleChange('exemptFromInterest')}
                />
                <label
                  className="text-black"
                  for="GrossLevelExemptionexemptFromExemptIdYes"
                >
                  Yes
                </label>
                <Radio
                  value="N"
                  id="GrossLevelExemptionexemptFromExemptIdNo"
                  checked={props.values.exemptFromInterest === 'N'}
                  onChange={props.handleChange('exemptFromInterest')}
                  className="ml-4"
                />
                <label
                  className="text-black"
                  for="GrossLevelExemptionexemptFromExemptIdNo"
                >
                  No
                </label>
              </div>
            </div>
            <div
              className="mui-custom-form min-md"
              style={{ lineHeight: '100%', marginBottom: '22px' }}
            >
              <label className="MuiFormLabel-root small-label">
                Exempt From Collection Agency<span>*</span>
              </label>
              <div className="sub-radio no-margin-y">
                <Radio
                  value="Y"
                  id="GrossLevelExemptionAgencyIdYes"
                  checked={props.values.exemptFromCollectionAgency === 'Y'}
                  onChange={props.handleChange('exemptFromCollectionAgency')}
                />
                <label
                  className="text-black"
                  for="GrossLevelExemptionAgencyIdYes"
                >
                  Yes
                </label>
                <Radio
                  value="N"
                  id="GrossLevelExemptionAgencyIdNo"
                  checked={props.values.exemptFromCollectionAgency === 'N'}
                  onChange={props.handleChange('exemptFromCollectionAgency')}
                  className="ml-4"
                />
                <label
                  className="text-black"
                  for="GrossLevelExemptionAgencyIdNo"
                >
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3" style={{ fontSize: '15px' }}>
          <div className="form-wrapper vertical-wrapper">
            <div className="mui-custom-form input-md override-width-45">
              <TextField
                id="standard-ExemptionCalculatedPenalty"
                fullWidth
                label="Calculated Penalty"
                disabled
                placeholder=""
                inputProps={{ maxlength: 13 }}
                InputLabelProps={{
                  shrink: true
                }}
                value={numeral(props.values.calculatedPenalty).format(
                  '$0,0.00'
                )}
              ></TextField>
            </div>
            <div className="mui-custom-form input-md override-width-45">
              <TextField
                id="standard-ExemptionCalculatedInterest"
                fullWidth
                label="Calculated Interest"
                disabled
                placeholder=""
                inputProps={{ maxlength: 13 }}
                InputLabelProps={{
                  shrink: true
                }}
                value={numeral(props.values.calculateInterest).format(
                  '$0,0.00'
                )}
              ></TextField>
            </div>
            <div className="mui-custom-form input-md override-width-45">
              <TextField
                id="standard-ExemptionFeesAssessed"
                fullWidth
                label="Fees Assessed"
                disabled
                placeholder=""
                inputProps={{ maxlength: 13 }}
                InputLabelProps={{
                  shrink: true
                }}
                value={numeral(props.values.feesAssesed).format('$0,0.00')}
              ></TextField>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
