/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TableComponent from "../../../../SharedModules/Table/Table";
import { Button } from "react-bootstrap";
import AddEditIdentifier from "./AddEditPayeeIdentifier";
import { validatePayeeIdentifierDetails } from "../AddValidationUtility";
import {
  generateUUID,
  getDateInMMDDYYYYFormat,
  getUTCTimeStamp
} from "../../../../SharedModules/DateUtilities/DateUtilities";

export default function PayeeIdentifiers(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const {
    tableData,
    disableFields,
    callValidatePayeeDetails,
    setPayeeIdentifierTableData,
    payeeIDTypeCodeDropdown,
    IsG16003,
    showEditPage,
    setAllowNavigation,
    seterrorMessages,
    userInquiryPrivileges,
    setFocusCheck,
    initialFocusCheck,
    dropdowns
  } = props;
  const headCells = [
    {
      id: "entityIDTypeCodeDesc",
      numeric: false,
      disablePadding: false,
      width: "25%",
      isVarChar: true,
      label: "Payee ID Type Code"
    },
    {
      id: "idNumber",
      numeric: false,
      disablePadding: true,
      width: "25%",
      isVarChar: true,
      label: "Payee ID"
    },
    {
      id: "beginDate",
      numeric: false,
      disablePadding: false,
      width: "25%",
      isDate: true,
      label: "Begin Date"
    },
    {
      id: "endDate",
      numeric: false,
      disablePadding: false,
      width: "25%",
      isDate: true,
      label: "End Date"
    }
  ];
  // Handling Add Identifier Details PopUp
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [rowEdit, setRowEdit] = React.useState(false);
  const [newRecord, setNewRecord] = React.useState(false);
  const handleClose = () => {
    setFocusCheck(initialFocusCheck);
    setAllowNavigation(initialFocusCheck);
    seterrorMessages([]);
    setOpenPopUp(false);
  };
  useEffect(() => {
    if (openPopUp && document.getElementById("form_pop_up"))
      document.getElementById("form_pop_up").scrollIntoView();
  }, [openPopUp]);
  const handleOpen = () => {
    if (callValidatePayeeDetails(true)) {
      setOpenPopUp(true);
      setNewRecord(false);
      setRowEdit(false);
      setRetainRowData(null);
      setCurrentRow(null);
      setIdentifierDetails({
        id: generateUUID(),
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        idNumber: "",
        idType: DEFAULT_DD_VALUE,
        altIdSk: null
      });
      setBeginDate(null);
      setEndDate(null);
      setIdentifierErr({
        idNumber: false,
        idType: false,
        beginDate: false,
        endDtae: false
      });
      setIdentifierErrMsg({
        idNumber: "",
        idType: "",
        beginDate: "",
        endDtae: ""
      });
    }
  };

  const [IdentifierDetails, setIdentifierDetails] = useState({
    id: generateUUID(),
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    idNumber: "",
    idType: DEFAULT_DD_VALUE,
    altIdSk: null
  });

  const [IdentifierErr, setIdentifierErr] = useState({
    idNumber: false,
    idType: false,
    beginDate: false,
    endDtae: false
  });
  const [IdentifierErrMsg, setIdentifierErrMsg] = useState({
    idNumber: "",
    idType: "",
    beginDate: "",
    endDtae: ""
  });
  const handlePayeeChange = name => event => {
    setFocusCheck(true)
    setAllowNavigation(true);
    setIdentifierDetails({ ...IdentifierDetails, [name]: event.target.value });
  };
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleBeginDate = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setBeginDate(date);
  };
  const handleEndDate = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setEndDate(date);
  };

  const [retainRowData, setRetainRowData] = useState(null);
  const [currentPayeeIdentifierRow, setCurrentRow] = useState(null);
  // On Click

  const onEditPopup = row => event => {
    if (callValidatePayeeDetails(true)) {
      if (row !== "") {
        setRowEdit(true);
        setRetainRowData(row);
        setCurrentRow(row);
        if (row.id) {
          setNewRecord(true);
        } else {
          setNewRecord(false);
        }
        setIdentifierDetails({
          ...IdentifierDetails,
          id: row.id ? row.id : null,
          auditUserID: row.auditUserID ? row.auditUserID : logInUserID,
          auditTimeStamp: row.auditTimeStamp
            ? row.auditTimeStamp
            : getUTCTimeStamp(),
          addedAuditUserID: logInUserID,
          addedAuditTimeStamp: getUTCTimeStamp(),
          idNumber: row.idNumber,
          idType: row.idType,
          altIdSk: row.altIdSk,
          mainRecord: row.mainRecord,
          RowIndex:row.index,
        });
        setBeginDate(row.beginDate);
        setEndDate(row.endDate);
        setIdentifierErr({
          idNumber: false,
          idType: false,
          beginDate: false,
          endDate: false
        });
        setIdentifierErrMsg({
          idNumber: "",
          idType: "",
          beginDate: "",
          endDate: ""
        });
        setOpenPopUp(true);
      }
    }
  };
  // Add Row and Edit Row
  const saveOrEditPopUpDetails = () => {
    if (
      validatePayeeIdentifierDetails(
        IdentifierDetails,
        beginDate,
        endDate,
        setIdentifierErr,
        setIdentifierErrMsg,
        currentPayeeIdentifierRow,
        tableData,
        seterrorMessages
      )
    ) {
      const tableDataTemp = [...tableData];
      const payeeData = {
        id: IdentifierDetails.id ? IdentifierDetails.id : null,
        auditUserID: IdentifierDetails.auditUserID
          ? IdentifierDetails.auditUserID
          : logInUserID,
        auditTimeStamp: IdentifierDetails.auditTimeStamp
          ? IdentifierDetails.auditTimeStamp
          : getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: getUTCTimeStamp(),
        versionNo: IdentifierDetails.versionNo,
        dbRecord: IdentifierDetails.dbRecord,
        sortColumn: IdentifierDetails.sortColumn,
        auditKeyList: IdentifierDetails.auditKeyList,
        auditKeyListFiltered: IdentifierDetails.auditKeyListFiltered,
        idNumber: IdentifierDetails.idNumber,
        idType: IdentifierDetails.idType,
        altIdSk: IdentifierDetails.altIdSk,
        beginDate: getDateInMMDDYYYYFormat(beginDate),
        endDate: getDateInMMDDYYYYFormat(endDate)
      };
      if (rowEdit && retainRowData && retainRowData !== null) {
        tableDataTemp.forEach((row,index) => {
          if(index===IdentifierDetails.RowIndex){
            row.auditUserID = IdentifierDetails.auditUserID
              ? IdentifierDetails.auditUserID
              : logInUserID;
            row.auditTimeStamp = IdentifierDetails.auditTimeStamp
              ? IdentifierDetails.auditTimeStamp
              : getUTCTimeStamp();
            row.idNumber = IdentifierDetails.idNumber;
            row.idType = IdentifierDetails.idType;
            row.beginDate = getDateInMMDDYYYYFormat(beginDate);
            row.endDate = getDateInMMDDYYYYFormat(endDate);
          }
        });
      } else {
        tableDataTemp.push({ ...payeeData, id: generateUUID() });
      }
      setPayeeIdentifierTableData(tableDataTemp);
      handleClose();
      setAllowNavigation(true);
    }
  };
  // Add/Edit Popup reset
  const resetPopUp = event => {
    setFocusCheck(initialFocusCheck);
    setAllowNavigation(initialFocusCheck);
    seterrorMessages([]);
    if (rowEdit) {
      setIdentifierDetails({
        ...IdentifierDetails,
        auditUserID: retainRowData.auditUserID
          ? retainRowData.auditUserID
          : logInUserID,
        auditTimeStamp: retainRowData.auditTimeStamp
          ? retainRowData.auditTimeStamp
          : getUTCTimeStamp(),
        addedAuditUserID: retainRowData.logInUserID,
        addedAuditTimeStamp: getUTCTimeStamp(),
        idNumber: retainRowData.idNumber,
        idType: retainRowData.idType,
        altIdSk: retainRowData.altIdSk
      });
      setBeginDate(retainRowData.beginDate);
      setEndDate(retainRowData.endDate);
      setIdentifierErr({
        idNumber: false,
        idType: false,
        beginDate: false,
        endDate: false
      });
      setIdentifierErrMsg({
        idNumber: "",
        idType: "",
        beginDate: "",
        endDate: ""
      });
    } else {
      setIdentifierDetails({
        id: generateUUID(),
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        idNumber: "",
        idType: DEFAULT_DD_VALUE,
        altIdSk: null
      });
      setBeginDate(null);
      setEndDate(null);
      setIdentifierErr({
        idNumber: false,
        idType: false,
        beginDate: false,
        endDate: false
      });
      setIdentifierErrMsg({
        idNumber: "",
        idType: "",
        beginDate: "",
        endDate: ""
      });
    }
  };
  // Delete Functionality
  const [payeeTableSelectedRows, setPayeeTableSelectedRows] = React.useState(
    []
  );
  const selectedPayeeTableRow = selectedRows => {
    setPayeeTableSelectedRows([...selectedRows]);
  };
  const deleteSelectedPayeeIdentifierTableRow = () => {
    let tempTableData = [...tableData];
    if (payeeTableSelectedRows && payeeTableSelectedRows.length > 0) {
      payeeTableSelectedRows.map((selectedRowId, index) => {
        tempTableData = tempTableData.filter(
          tempRow => tempRow.id !== selectedRowId
        );
      });
    }
    setPayeeIdentifierTableData(tempTableData);
  };

  const getTableData = (d) => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;          
          if (props.payeeValues?.payeePayerType === "P") {
            const provEntityTypeCD =
              props.dropdowns &&
              props.dropdowns["F1#P_ALT_ID_TY_CD"] &&
              props.dropdowns["F1#P_ALT_ID_TY_CD"].filter(
                (value) => value.code === each.idType
              );
            each.entityIDTypeCodeDesc =
              provEntityTypeCD && provEntityTypeCD.length > 0
                ? provEntityTypeCD[0].description
                : each.idType ? each.idType : "";            
          }else if(props.payeeValues?.payeePayerType === "O"){
            const othrEntityTypeCD =
              props.dropdowns &&
              props.dropdowns["F1#G_ENTY_EXTL_ID_TY_CD"] &&
              props.dropdowns["F1#G_ENTY_EXTL_ID_TY_CD"].filter(
                (value) => value.code === each.idType
              );
            each.entityIDTypeCodeDesc =
              othrEntityTypeCD && othrEntityTypeCD.length > 0
                ? othrEntityTypeCD[0].description
                : each.idType ? each.idType : "";         
          }else if(props.payeeValues?.payeePayerType === "M"){
            const memberEntityTypeCD =
              props.dropdowns &&
              props.dropdowns["F1#B_ALT_ID_TY_CD"] &&
              props.dropdowns["F1#B_ALT_ID_TY_CD"].filter(
                (value) => value.code === each.idType
              );
            each.entityIDTypeCodeDesc =
              memberEntityTypeCD && memberEntityTypeCD.length > 0
                ? memberEntityTypeCD[0].description
                : each.idType ? each.idType : "";
          }else if(props.payeeValues?.payeePayerType === "TC" ){
            const tplEntityTypeCD =
              props.dropdowns &&
              props.dropdowns["F1#DM_G_CARR_ENTY_ID_TY_CD"] &&
              props.dropdowns["F1#DM_G_CARR_ENTY_ID_TY_CD"].filter(
                (value) => value.code === each.idType
              );
            each.entityIDTypeCodeDesc =
              tplEntityTypeCD && tplEntityTypeCD.length > 0
                ? tplEntityTypeCD[0].description
                : each.idType ? each.idType : "";            
          }
        }
        return each;
      });
      return tData;
    }
    return [];
  };


  return (
    <div className="tab-holder my-2">
      <fieldset className="r-custom-fieldset no-padding">
        <legend>Payee Identifiers:</legend>
        <div className="tab-holder">
          <div className="mb-3" style={{ float: "right" }}>
            <Button
              className="btn-text-main-delete btn-transparent  ml-1"
              onClick={deleteSelectedPayeeIdentifierTableRow}
              data-testid='Delete_btn'
              disabled={(!IsG16003 && showEditPage) || disableFields}
              title={"Delete"}
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
              Delete
            </Button>
            <button
              class="btn-text-main-add btn-success ml-1"
              onClick={handleOpen}
              data-testid='test_add_btn'
              disabled={
                (!IsG16003 && showEditPage) ||
                disableFields ||
                !userInquiryPrivileges
              }
              title={"Add Payee Identifier"}
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
              Add
            </button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-holder table-no-wrap table-p-5">
          <TableComponent
            print={props.print}
            headCells={headCells}
            isSearch={false}
            tableData={getTableData(props.tableData || [])}
            defaultSortColumn={headCells[0].id}
            onTableRowClick={onEditPopup}
            onTableRowDelete={selectedPayeeTableRow}
          />
          {openPopUp ? (
            <div className="tabs-container" id="form_pop_up">
              <div className="tab-header  mini-tab-header">
                <div className="tab-heading float-left">
                  {rowEdit ? "Edit payee Identifier" : "Add Payee Identifier"}
                </div>
                <div className="float-right mt-1">
                  <Button
                    className="btn btn-success ml-1"
                    onClick={saveOrEditPopUpDetails}
                    data-testid='test_add_pi_btn'
                    disabled={
                      disableFields ||
                      (!IsG16003 && showEditPage) ||
                      !userInquiryPrivileges
                    }
                    title={rowEdit ? "Update" : "Add"}
                  >
                    <i class="fa fa-check" aria-hidden="true"></i>
                    {rowEdit ? "Update" : "Add"}
                  </Button>
                  <Button
                    className="bt-reset btn-transparent  ml-1"
                    data-testid='test_Reset_btn'
                    onClick={resetPopUp}
                    disabled={
                      disableFields ||
                      (!IsG16003 && showEditPage) ||
                      !userInquiryPrivileges
                    }
                    title="Reset"
                  >
                    <i class="fa fa-undo" aria-hidden="true"></i>
                    Reset
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="btn btn-primary btn btn-outlined ml-1"
                    onClick={handleClose}
                    title="Cancel"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
              <AddEditIdentifier
                rowEdit={rowEdit}
                IsG16003={IsG16003}
                disableFields={disableFields}
                showEditPage={showEditPage}
                IdentifierDetails={IdentifierDetails}
                handlePayeeChange={handlePayeeChange}
                beginDate={beginDate}
                endDate={endDate}
                payeeIDTypeCodeDropdown={payeeIDTypeCodeDropdown || []}
                handleBeginDate={handleBeginDate}
                handleEndDate={handleEndDate}
                IdentifierErr={IdentifierErr}
                IdentifierErrMsg={IdentifierErrMsg}
                saveOrEditPopUpDetails={saveOrEditPopUpDetails}
                resetPopUp={resetPopUp}
                userInquiryPrivileges={userInquiryPrivileges}
                dropdowns={dropdowns}
              />
            </div>
          ) : null}
        </div>
      </fieldset>
    </div>
  );
}
