// eslint-disable-next-line no-unused-vars
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import { compareTwoDates, checkForValidDateString, getDateInMMDDYYYYFormat, validateDateMinimumValue } from '../../../SharedModules/DateUtilities/DateUtilities';
import * as fundCodeConstants from './FundCodeConstants';
import * as AppConstants from '../../../SharedModules/AppConstants';

/* This function is used to validate header field's values */
export const validateHeaderFields = (values, selectedBeginDate, selectedEndDate) => {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const errorMessagesArray = [];
  var beginDateErrorMsg = '';
  var endDateErrorMsg = '';
  var showAgencyError; var showRankError; var showBeginDateError; var showEndDateError; var showFundCodeError = false;
  const setErrors = { showAgencyError, showFundCodeError, showRankError, showBeginDateError, showEndDateError };
  if (!(values.agency) || values.agency === DEFAULT_DD_VALUE) {
    setErrors.showAgencyError = true;
    errorMessagesArray.push(fundCodeConstants.AGENCY_REQUIRED);
  }

  if ((!(values.fundCode) || values.fundCode === DEFAULT_DD_VALUE)) {
    setErrors.showFundCodeError = true;
    errorMessagesArray.push(fundCodeConstants.FUND_CODE_REQUIRED);
  }

  if (!values.rank) {
    setErrors.showRankError = true;
    errorMessagesArray.push(fundCodeConstants.RANK_REQUIRED);
  }

  if (!selectedBeginDate) {
    setErrors.showBeginDateError = true;
    beginDateErrorMsg = fundCodeConstants.BEGIN_DATE_REQUIRED;
    errorMessagesArray.push(fundCodeConstants.BEGIN_DATE_REQUIRED);
  }

  if (!selectedEndDate) {
    setErrors.showEndDateError = true;
    endDateErrorMsg = fundCodeConstants.END_DATE_REQUIRED;
    errorMessagesArray.push(fundCodeConstants.END_DATE_REQUIRED);
  }

  let isBeginDateInValid = false;
  if (selectedBeginDate) {
    if (checkForValidDateString(getDateInMMDDYYYYFormat(selectedBeginDate))) {
      isBeginDateInValid = validateDateMinimumValue(getDateInMMDDYYYYFormat(selectedBeginDate));
      if (isBeginDateInValid) {
        setErrors.showBeginDateError = true;
        beginDateErrorMsg = AppConstants.DATE_ERROR_1964;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
    } else {
      setErrors.showBeginDateError = true;
      beginDateErrorMsg = fundCodeConstants.BEGIN_DATE_INVALID;
      errorMessagesArray.push(fundCodeConstants.BEGIN_DATE_INVALID);
    }
  }

  if (selectedEndDate) {
    if (checkForValidDateString(getDateInMMDDYYYYFormat(selectedEndDate))) {
      const isEndDateInValid = validateDateMinimumValue(getDateInMMDDYYYYFormat(selectedEndDate));
      if (isEndDateInValid) {
        setErrors.showEndDateError = true;
        endDateErrorMsg = AppConstants.DATE_ERROR_1964;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      } else if (selectedBeginDate && selectedEndDate && !showBeginDateError && !showEndDateError) {
        var begDate = new Date(selectedBeginDate);
        var enDate = new Date(selectedEndDate);
        if (!compareTwoDates(begDate, enDate)) {
          setErrors.showBeginDateError = true;
          beginDateErrorMsg = fundCodeConstants.BEGIN_DATE_GREATER_ERROR;
          errorMessagesArray.push(fundCodeConstants.BEGIN_DATE_GREATER_ERROR);
        }
      }
    } else {
      setErrors.showEndDateError = true;
      endDateErrorMsg = fundCodeConstants.END_DATE_INVALID;
      errorMessagesArray.push(fundCodeConstants.END_DATE_INVALID);
    }
  }
  const errors = {
    setErrors: setErrors,
    beginDateErrorMsg: beginDateErrorMsg,
    endDateErrorMsg: endDateErrorMsg,
    errorMessagesArray: errorMessagesArray
  };
  return errors;
};

/* This function is used to validate criteria field's values on pop-up */
export const validateCriteria = (values, selectedServiceBeginDate, selectedServiceEndDate, procedureCodeVal, defaultCheck) => {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  var showPopUpError; var showProviderIdTypeError; var showProviderTypeError; var showProviderIdError; var showServiceBeginDateError; var showServiceEndDateError; var showMapSetIdError; var showProcedureCodeValueError; var showMemberAgeError; var showProcedureRangeCodeError = false;
  var showPopUpErrorText; var showProviderIdTypeErrorText; var showProviderTypeErrorText; var showProviderIdErrorText; var showServiceBeginDateErrorText; var showServiceEndDateErrorText; var showMapSetIdErrorText; var showProcedureCodeValueErrorText; var showMemberAgeErrorText; var showProcedureRangeCodeErrorText = '';
  const popUpErrorMessagesArray = [];
  const errorMessagesArray = [];
  const setErrors = { showPopUpError, showProviderIdTypeError, showProviderTypeError, showProviderIdError, showProcedureRangeCodeError, showMemberAgeError, showProcedureCodeValueError, showMapSetIdError, showServiceBeginDateError, showServiceEndDateError };
  const setErrorText = { showPopUpErrorText, showProviderIdTypeErrorText, showProviderTypeErrorText, showProviderIdErrorText, showProcedureRangeCodeErrorText, showMemberAgeErrorText, showProcedureCodeValueErrorText, showMapSetIdErrorText, showServiceBeginDateErrorText, showServiceEndDateErrorText };

  // Check if Add assignment criteria is blank
  if (values.catService === DEFAULT_DD_VALUE && values.benefitPlanId === DEFAULT_DD_VALUE &&
    !selectedServiceBeginDate && !selectedServiceEndDate && values.bProviderIdType === DEFAULT_DD_VALUE &&
    values.bProviderId === '' && values.bProviderType === DEFAULT_DD_VALUE && values.taxonomy === '' &&
    values.coe === DEFAULT_DD_VALUE && values.levelCare === DEFAULT_DD_VALUE && values.claimType === DEFAULT_DD_VALUE &&
    values.memberBeginAge === '' && values.memberEndAge === '' && values.mapsetId === DEFAULT_DD_VALUE &&
    ((values.procedureCode === 'procedureCodeValue' && values.procedureValueCode === '' && values.modifier1 === '' && values.modifier2 === '' && values.modifier3 === '' && values.modifier4 === '') ||
      values.procedureCode === 'procedureCodeNA')) {
    setErrors.showPopUpError = true;
    setErrorText.showPopUpErrorText = fundCodeConstants.DETAIL_ROW_REQUIRED;
    popUpErrorMessagesArray.push(showPopUpErrorText);
    errorMessagesArray.push(fundCodeConstants.DETAIL_ROW_REQUIRED)
  }

  let isServiceBeginDateInValid = false;
  if (selectedServiceBeginDate) {
    if (checkForValidDateString(getDateInMMDDYYYYFormat(selectedServiceBeginDate))) {
      isServiceBeginDateInValid = validateDateMinimumValue(getDateInMMDDYYYYFormat(selectedServiceBeginDate));
      if (isServiceBeginDateInValid) {
        setErrors.showServiceBeginDateError = true;
        setErrorText.showServiceBeginDateErrorText = AppConstants.DATE_ERROR_1964;
        popUpErrorMessagesArray.push(showServiceBeginDateErrorText);
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964)
      }
    } else {
      setErrors.showServiceBeginDateError = true;
      setErrorText.showServiceBeginDateErrorText = fundCodeConstants.SERVICE_BEGIN_DATE_INVALID;
      popUpErrorMessagesArray.push(showServiceBeginDateErrorText);
      errorMessagesArray.push(fundCodeConstants.SERVICE_BEGIN_DATE_INVALID)
    }
  }
  let isServiceEndDateInValid = false;
  if (selectedServiceEndDate) {
    if (checkForValidDateString(getDateInMMDDYYYYFormat(selectedServiceEndDate))) {
      if (selectedServiceBeginDate && selectedServiceEndDate) {
        var serviceBegDate = new Date(selectedServiceBeginDate);
        var serviceEnDate = new Date(selectedServiceEndDate);
        isServiceEndDateInValid = validateDateMinimumValue(getDateInMMDDYYYYFormat(selectedServiceEndDate));
        if (isServiceEndDateInValid) {
          setErrors.showServiceEndDateError = true;
          setErrorText.showServiceEndDateErrorText = AppConstants.DATE_ERROR_1964;
          popUpErrorMessagesArray.push(showServiceEndDateErrorText);
          errorMessagesArray.push(AppConstants.DATE_ERROR_1964)

        } else if (!compareTwoDates(serviceBegDate, serviceEnDate)) {
          setErrors.showServiceBeginDateError = true;
          setErrorText.showServiceBeginDateErrorText = fundCodeConstants.SERVICE_BEGIN_DATE_GREATER_ERROR;
          popUpErrorMessagesArray.push(showServiceBeginDateErrorText);
          errorMessagesArray.push(fundCodeConstants.SERVICE_BEGIN_DATE_GREATER_ERROR)
        }
      }
    } else {
      setErrors.showServiceEndDateError = true;
      setErrorText.showServiceEndDateErrorText = fundCodeConstants.SERVICE_END_DATE_INVALID;
      popUpErrorMessagesArray.push(showServiceEndDateErrorText);
      errorMessagesArray.push(fundCodeConstants.SERVICE_END_DATE_INVALID)
    }
  }

  if (procedureCodeVal === 'procedureCodeRange') {
    if ((!values.procedureBeginCode) || (!values.procedureEndCode)) {
      setErrors.showProcedureRangeCodeError = true;
      setErrorText.showProcedureRangeCodeErrorText = fundCodeConstants.FROM_TO_CODE_REQUIRED;
      popUpErrorMessagesArray.push(showProcedureRangeCodeErrorText);
      errorMessagesArray.push(fundCodeConstants.FROM_TO_CODE_REQUIRED);
    }

    if (values.procedureBeginCode && values.procedureEndCode) {
      if (values.procedureBeginCode > values.procedureEndCode) {
        setErrors.showProcedureRangeCodeError = true;
        setErrorText.showProcedureRangeCodeErrorText = fundCodeConstants.PROCEDURE_FROM_CODE_GREATER;
        popUpErrorMessagesArray.push(showProcedureRangeCodeErrorText);
        errorMessagesArray.push(fundCodeConstants.PROCEDURE_FROM_CODE_GREATER)
      }
    }
  }

  if (values.memberBeginAge && values.memberEndAge) {
    if (Number(values.memberBeginAge) > Number(values.memberEndAge)) {
      setErrors.showMemberAgeError = true;
      setErrorText.showMemberAgeErrorText = fundCodeConstants.BEGIN_AGE_GREATER;
      popUpErrorMessagesArray.push(showMemberAgeErrorText);
      errorMessagesArray.push(fundCodeConstants.BEGIN_AGE_GREATER)
    }
  }

  if ((values.modifier1 || values.modifier2 || values.modifier3 || values.modifier4) && (!values.procedureValueCode)) {
    setErrors.showProcedureCodeValueError = true;
    setErrorText.showProcedureCodeValueErrorText = fundCodeConstants.PROCEDURE_CODE_VALUE_MUST_BE_SELECTED;
    popUpErrorMessagesArray.push(showProcedureCodeValueErrorText);
    errorMessagesArray.push(fundCodeConstants.PROCEDURE_CODE_VALUE_MUST_BE_SELECTED)
  }

  if (((values.catService !== DEFAULT_DD_VALUE && values.catService !== null) || (values.benefitPlanId !== DEFAULT_DD_VALUE && values.benefitPlanId !== null) || (values.bProviderIdType !== DEFAULT_DD_VALUE && values.bProviderIdType !== null) || values.bProviderId !== '' || (values.bProviderType !== DEFAULT_DD_VALUE && values.bProviderType !== null) || values.memberBeginAge !== '' || values.memberEndAge !== '' || values.taxonomy !== '' || (values.claimType !== DEFAULT_DD_VALUE && values.claimType !== null) || (values.coe !== DEFAULT_DD_VALUE && values.coe !== null) || (values.levelCare !== DEFAULT_DD_VALUE && values.levelCare !== null) || (selectedServiceBeginDate) || (selectedServiceEndDate) || values.familyPlanning !== '' || (defaultCheck) || values.procedureCode !== 'procedureCodeNA' || (values.procedureBeginCode !== '' && values.procedureBeginCode !== null) || (values.procedureEndCode !== '' && values.procedureEndCode !== null) || (values.procedureValueCode !== '' && values.procedureValueCode !== null) || (values.modifier1 !== '' && values.modifier1 !== null) || (values.modifier2 !== '' && values.modifier2 !== null) || (values.modifier3 !== '' && values.modifier3 !== null) || (values.modifier4 !== '' && values.modifier4 !== null)) && (values.mapsetId !== DEFAULT_DD_VALUE && values.mapsetId !== null)) {
    setErrors.showMapSetIdError = true;
    setErrorText.showMapSetIdErrorText = fundCodeConstants.MAP_SET_WITH_CRITERIA_ERROR;
    popUpErrorMessagesArray.push(fundCodeConstants.MAP_SET_WITH_CRITERIA_ERROR);
    errorMessagesArray.push(fundCodeConstants.MAP_SET_WITH_CRITERIA_ERROR)

  }

  if (values.bProviderIdType !== DEFAULT_DD_VALUE && values.bProviderIdType !== null) {
    if (values.bProviderId === '' || values.bProviderId === null) {
      setErrors.showProviderIdError = true;
      setErrorText.showProviderIdErrorText = fundCodeConstants.PROV_TYPE_AND_ID_REQUIRED;
      popUpErrorMessagesArray.push(showProviderIdErrorText);
      errorMessagesArray.push(fundCodeConstants.PROV_TYPE_AND_ID_REQUIRED)
    }
  } else if (values.bProviderId !== '' && values.bProviderId != null) {
    if (values.bProviderIdType === DEFAULT_DD_VALUE || values.bProviderIdType === null) {
      setErrors.showProviderIdTypeError = true;
      setErrorText.showProviderIdTypeErrorText = fundCodeConstants.PROV_TYPE_AND_ID_REQUIRED;
      popUpErrorMessagesArray.push(showProviderIdTypeErrorText);
      errorMessagesArray.push(fundCodeConstants.PROV_TYPE_AND_ID_REQUIRED)
    }
  }

  const errors = {
    setErrors: setErrors,
    setErrorText: setErrorText,
    popUpErrorMessagesArray: popUpErrorMessagesArray,
    errorMessagesArray: errorMessagesArray
  };
  return errors;
};
