/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const PAYEE_TYPE_REQUIRED = 'Payee type is required.';
export const PAYEE_TYPE_ID_CODE_REQUIRED = 'Payee type id code is required.';
export const PAYEE_ID_REQUIRED = 'Payee id is required.';
export const ACTIVITY_TYPE_REQUIRED = 'Activity type is required.';
export const REASON_CODE_REQUIRED = 'Reason code is required.';
export const ORIGINAL_AR_AMOUNT_REQUIRED = 'Original A/R Amount is required.';
export const APPROVAL_ERROR = 'Approval must be No for Gross level add.';
export const FISCAL_YEAR_REQUIRED = 'Fiscal Year is required.';
export const COLLOCATION_CODE = 'Fund Code is required';
export const RECOUPMENT_TYPE_CODE_REQUIRED = 'Recoupment type code is required.';
export const DUE_DATE_REQUIRED = 'Due Date is required.';
export const AMOUNT_DUE = 'Installment Amount is required.';
export const BEGIN_DATE_INVALID = 'Begin Date Entered is Invalid.';
export const END_DATE_INVALID = 'End Date Entered is Invalid.';
export const BEGIN_DATE_REQUIRED = 'Begin Date is Required.';
export const END_DATE_REQUIRED = 'End Date is Required.';
export const PERCENT_REQUIRED = 'Percent is Required';

export const AP = 'advancedPaymentReasonCodeList';
export const CR = 'wlaimRecvReasonCodeList';
export const GL = 'grossLevelReasonCodeList';
export const RA = 'adjustmentReasonCodeList';
export const RR = 'reversalReasonCodeList';
export const RT = 'transferReasonCodeList';
export const WL = 'withHoldingReasonCodeList';
export const WO = 'writeOffReasonCodeListForSearch';
export const WR = 'wrWOReestablishmentReasonCodeList';

export const AP_TEXT = 'AP';
export const CR_TEXT = 'CR';
export const GL_TEXT = 'GL';
export const RA_TEXT = 'RA';
export const RR_TEXT = 'RR';
export const RT_TEXT = 'RT';
export const WL_TEXT = 'WL';
export const WO_TEXT = 'WO';
export const WR_TEXT = 'WR';

export const PAYEE_TYPE = 'payeeType';
export const ACTIVITY_CODE = 'activityCode';
export const REASON_CODE = 'reasonCode';
export const TRANSACTION_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const PAYMENT_SCHEDULE_TYPE = 'Payment Schedule Type is required.';
export const RECOPUMENT_START_DATE = 'Recoupment Start Date is required.';
export const FREQUENCY = 'Frequency is required.';
export const DUE_DATE_ERROR = 'Due Date must be greater than or equal to today’s date.';
export const DUE_DATE_RECOUP = 'Due Date should not be less than the recoupment start date.';
export const DUE_DATE_INVALID = 'Due Date entered is invalid.';
export const NO_OF_INSTALLMENTS = 'No of Installments is required.';
export const INTEREST_PERCENT = 'Interest Percentage is required.';
export const PAYMENT_SCHEDULE_ERROR = 'For recoupment type scheduled payment, a payment schedule is required.';
export const REVERSE_ERROR = 'Reversal can only be performed when the Balance Amount is EQUAL TO the Adjusted/Original Amount.';
export const BALNACE_AMOUNT_ZERO_ERROR = 'Balance amount must be greater than zero to write-off re-establish.';
export const WRITE_OFF_BALNACE_AMOUNT_ZERO_ERROR = 'Balance amount on original FCN transaction must be greater than zero to write-off.';
export const TRANSFER_BALNACE_AMOUNT_ZERO_ERROR = 'An A/R with a zero balance cannot be Transferred.';
export const ADJUSTED_BALNACE_AMOUNT_ZERO_ERROR = 'A Receivable with a Balance Amount of zero cannot be adjusted.';
export const START_DATE_INVALID = 'Start Date entered is invalid.';
export const SUM_AMOUNT = 'Sum of installment amount is not equal to the Original/Adjusted AR amount.';
export const START_DATE_LESS_CURRENT_DATE_ERROR = 'Recoupment Start Date cannot be less than {current date}. Please re-enter.';
export const START_DATE_LESS_AR_CREATED_ERROR = 'The Recoupment Start Date must be greater than or equal to the date the A/R was created. Please re-enter.';
export const Invalid_Format_Error = 'Data entered is in the incorrect format.';
export const NEGATIVE_AMOUNT = 'A negative Installment Amount cannot be entered please re-enter.';
export const GREATER_THAN_ZERO_AMOUNT = 'Installment Amount must be greater than zero.';
export const AMOUNTDUE_ORIGINALAMOUNT_VALIDATION = 'Installment Amount cannot exceed Original/Adjusted A/R Amount. Please re-enter.';
export const DUPLICATE_DUE_DATE = 'Duplicate Due Date cannot be added please re-enter.';
export const INSTALLMENT_MORE = 'Number of installments cannot be greater than ';
export const NUMBER_OF_INSTALLMENTS = 'Number of Installments is required.';
export const DATA_INVALID = 'Please enter the data in correct format.';
export const RECOUPEMENT_INVALID = 'Invalid Recoupment Type Code for Payment Schedule Type.';
export const RECOUPMENT_BALANCE_INVALID = 'Invalid Recoupment Type Code. Payment Schedule can not be set if Original/Adjusted AR and balance amount are not equal.';
