/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./App.scss";
import "./custFssApp.scss";
import NavigationMenu from "./SharedModules/Navigation/navigationMenu";
import Login from "./Login";
import Swal from "sweetalert2";
import { generateUUID } from './SharedModules/DateUtilities/DateUtilities';
import axios from 'axios';
import {
  setUserprivileges,
  setLogInUserID,
  userLogout,
  ValidateUser,
  parseJwt,
  resetSearchClaimsCorrection,
  setNavigationPrivileges,
  setTokenInStore,
  ssoLogin,
  dispatchUserRoles,
  logOut
} from "./SharedModules/store/Actions/SharedAction";
import Cookies from 'js-cookie';
import * as SERVICES from './SharedModules/services/service';

function Confirm (props) {
  Swal.fire({
    customClass: {
      confirmButton: "btn-text1 btn-transparent mr-3",
      cancelButton: "btn1 btn-success",
      container: "unsaved-changes-popup"
    },
    allowOutsideClick: false,
    buttonsStyling: false,
    heightAuto: false,
    icon: "warning",
    html:
      "<h4>Unsaved changes will be lost.<br/>Are you sure you want to Exit?</h4 >",
    showCancelButton: true,
    confirmButtonText: "Stay on this page!",
    cancelButtonText: 'Continue! <i class="fa fa-arrow-right ml-1"></i>'
  }).then(result => {
    if (result.value) {
      blockTransition();
    } else {
      allowTransition();
    }
  });
  function allowTransition () {
    props.setConfirm(false);
    props.confirmCallback(true);
    props.setUUID(generateUUID());
  }

  function blockTransition () {
    props.setConfirm(false);
    props.confirmCallback(false);
  }

  return <React.Fragment></React.Fragment>;
}

function App () {
  const params = (new URL(window.location.href)).searchParams;
  const ssoUser = params.get('user');
  const lssoUSer = localStorage.getItem('ssoUser');
  const dispatch = useDispatch();
  const [{
    userNameInvalidErr
  }, setErrorMessages] = useState(false);
  const userData = useSelector((state) => state.sharedState.userPrivileges);
  const [redirect, setRedirect] = useState(false);
  const getUser = (value) => dispatch(ValidateUser(value));
const MicroFrontendInitiate = () => {
};
  useEffect(() => {
    if (ssoUser) {
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem('ssoUser', ssoUser);
      localStorage.setItem('accessToken', params.get('accessToken'));
      localStorage.setItem('refreshToken', params.get('refreshToken'));
      localStorage.setItem('lsso', ssoUser);
      window.location = '/dashboard';
    } else if (lssoUSer) {
      MicroFrontendInitiate();
      axios
        .post(SERVICES.TOKEN_LOGIN, {
          username: localStorage.getItem('ssoUser'),
          accessToken: localStorage.getItem('accessToken'),
          refreshToken: localStorage.getItem('refreshToken')
        })
        .then(r => {
          if (r.data.success) {
            dispatch(dispatchUserRoles("", r.data));
            dispatch(setUserprivileges(r.data));
          } else {
            dispatch(logOut());
          }
        })
        .catch(() => {
          dispatch(logOut());
        });
    } else if (!localStorage.getItem('accessToken')) {
      if (window.location.origin === process.env.REACT_APP_PRODUCT_FINANCE_URL) {
        window.location = process.env.REACT_APP_SSO_LOGIN_URL;
      }
    }
  }, []);
  useEffect(() => {
    dispatch(resetSearchClaimsCorrection());
    window.addEventListener('storage', () => {
      if (!safeLocalStorage('get', 'loginState')) {
        setLogin(false);
        dispatch(setLogInUserID(null));
      }
    });
  });
  useEffect(() => {
    setRedirect(true);
    if (redirect && userData && userData.success) {
      const data = parseJwt(userData.data);
      const userFullName = `${data.firstname} ${data.lastname}`;
      const userID = data.username;
      safeLocalStorage('set', 'loginState', JSON.stringify({
        logInUser: data.username?.trim(),
        loginUserName: userFullName?.toUpperCase(),
        firstname: data.firstname,
        lastname: data.lastname,
        menuItems: data.data,
        userName: userFullName,
        email: data.email,
        time: Date.now(),
        readOnly: data && data.data && data.data[0]?.readOnly
      }));
      localStorage.setItem('ssoToken', userData.ssoToken);
      localStorage.setItem('expiresInSeconds', userData.expiresInSeconds);
      localStorage.setItem('refreshToken', userData.refreshToken);
      localStorage.setItem('accessToken', userData.accessToken);
      if (!lssoUSer) {
        document.cookie =
          'iPlanetDirectoryPro' +
          '=' +
          userData.ssoToken +
          ';' +
          `;domain=.${window.location.host};path=/;secure;`;
      }
      dispatch(setLogInUserID(userID?.trim()));
      dispatch(setLogInUserID(userID));
      dispatch(setTokenInStore(userData));
      const rolesNavData = [];
      if (rolesNavData.length) {
        dispatch(setNavigationPrivileges(rolesNavData));
      }
      dispatch(ssoLogin(false || ssoUser || lssoUSer));
      setLogin(false || ssoUser || lssoUSer);
    } else if (safeLocalStorage("get", "loginState")) {
      setLogin(true);
    } else if (redirect && userData && userData.errorType == "invalid_grant") {
      setErrorMessages({ userNameInvalidErr: true });

      setLogin(false || ssoUser || lssoUSer);
    } else {
      setLogin(false || ssoUser || lssoUSer);
    }
  }, [userData]);

  const [confirm, setConfirm] = useState(false);
  const [logindone, setLogin] = useState(false || ssoUser || lssoUSer);
  const [logInUser, setLogInUser] = useState(null);
  const userPrivileges = useSelector(state => state.sharedState.userPrivileges);
  const [uuid, setUUID] = useState(generateUUID);
  const [userDoesntExist, setUserDoesntExist] = useState(false);
  const [loginErrorMessages, setLoginErrorMessages] = useState([]);
  const loggedInUser = useSelector((state) => state.sharedState.logInUserID);
  const [showSideMenu, setShowSideMenu] = React.useState({
    applicationConfig: false,
    bankAccount: false,
    fiscalPend: false,
    budgetMaintenance: false,
    accountReceivable: false,
    financialEntity: false,
    fiscalPayout: false,
    financialReceipt: false,
    fundCode: false,
    Correction1099: false,
    FinancialInquiry: false,
    FssAdminUser: false,
    FinancialTXN: false
  });
  const [userInquiry, setUserInquiry] = React.useState({
    applicationUser: false,
    applicationInquiry: false,
    bankAccUser: false,
    bankAccInquiry: false
  });

  const safeLocalStorage = (actType, storeName, data) => {
    let res;
    try {
      if (actType === "set") {
        res = localStorage.setItem(storeName, data);
      } else if (actType === "get") {
        res = localStorage.getItem(storeName);
      } else if (actType === "remove") {
        res = localStorage.removeItem(storeName);
      }
    } catch {
    }
    return res;
  };

  useEffect(() => {
    const loginSeasonLength = 12 * 60 * 60 * 1000;
    let storedSession = safeLocalStorage("get", "loginState");
    storedSession = storedSession && JSON.parse(storedSession);
    if (userPrivileges && userPrivileges.respcode === '01') {
      setLoginErrorMessages([]);
      safeLocalStorage(
        "set",
        "loginState",
        JSON.stringify({ logInUser, time: Date.now() })
      );
      setLogin(true);
      dispatch(setLogInUserID(logInUser));
      setUserDoesntExist(false);
    }
    if (userPrivileges && userPrivileges.respcode === '02') {
      setUserDoesntExist(true);
      setLoginErrorMessages(userPrivileges.errorMessages);
    }
    if (userPrivileges && userPrivileges.length > 0) {
      safeLocalStorage(
        "set",
        "loginState",
        JSON.stringify({ logInUser, userPrivileges, time: Date.now() })
      );
      setLogin(true);
      dispatch(setLogInUserID(logInUser));
      setUserDoesntExist(false);
    } else if (storedSession) {
      if (Date.now() - storedSession.time < loginSeasonLength) {
        setLogin(true);
        dispatch(setLogInUserID(storedSession.logInUser ? storedSession.logInUser : storedSession.loginId));
        setUserDoesntExist(false);
      } else {
        setLogin(false || ssoUser || lssoUSer);
        safeLocalStorage("remove", "loginState");
        setUserDoesntExist(false);
      }
    }
    if (userPrivileges && userPrivileges.length === 0) {
      setUserDoesntExist(true);
    }
    // DB Values Ends
  }, [userPrivileges]);
  const callGetUserDetailsAPI = (userId, password) => {
    getUser({
      password: password,
      username: userId
    });
  };
  const setLoginDone = (userId, password) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken == null || accessToken == undefined) {
      callGetUserDetailsAPI(userId, password);
    }
    setLogInUser(userId);
  };

  // Logout for multiple tabs open
  useEffect(() => {
    window.addEventListener('storage', () => {
      if (!safeLocalStorage('get', 'loginState')) {
        setLogin(false);
        setUserDoesntExist(false);
        setLogInUserID(null);
      }
    });
  });

  const [afterLogout, setAfterLogout] = useState(false);
  const setLogOut = () => {
    setLogin(false);
    setLogInUserID(null);
    // Retriving UID from local storage for passport logout
    const uid = JSON.parse(localStorage.getItem('loginState')).loginId;
    // Performming Passport logout via express, Cookie and Local session removal and IDP logout
    if (uid) {
      dispatch(userLogout(uid));
    } else {
      dispatch(userLogout(loggedInUser));
    }
    Cookies.remove('userProfile');
    localStorage.removeItem("loginState");
    setAfterLogout(true);
    const isNormalUser = localStorage.getItem("isNormalUser");
    localStorage.clear();
    if (isNormalUser == "true") {
      window.location.href = process.env.REACT_APP_UI_URL;
    } else {
      window.location.href = SERVICES.MAVEN_LOGOUT;
    }
  };

  const [confirmCallback, setConfirmCallback] = useState(null);
  function getConfirmation (message, callback) {
    setConfirmCallback(() => callback);
    setConfirm(true);
  }

  return (
    <Router getUserConfirmation={getConfirmation}>

      {!logindone ? (
        <Login LoginStart={logindone} setLoginDone={setLoginDone} userDoesntExist={userDoesntExist} setUserDoesntExist={setUserDoesntExist} loginErrorMessages={loginErrorMessages} userNameInvalidErr={userNameInvalidErr}></Login>
      ) : (
        <NavigationMenu
          setLoginDone={setLoginDone}
          setLogOut={setLogOut}
          showSideMenu={showSideMenu}
          userInquiry={userInquiry}
          uuid={uuid}
        />
      )}
      {confirm && (
        <Confirm confirmCallback={confirmCallback} setConfirm={setConfirm} setUUID={setUUID} />
      )}
    </Router>
  );
}

export default App;
