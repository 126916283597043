/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import * as ValidValueConstants from './ValidValueConstants';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import Radio from '@material-ui/core/Radio';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown'

export default function ValidValueSearchForm (props) {
  return (
    <form autoComplete="off">
      <div className="form-wrapper">
        <div className="mui-custom-form">
          <TextField
            id="standard-code"
            label="Code"
            fullWidth
            value={props.values.code || ""}
            onChange={props.handleChanges('code')}
            placeholder=""
            inputProps={{ maxLength: 15 }}
            InputLabelProps={{
              shrink: true
            }} />
        </div>
        <div className="mui-custom-form" 
        >
          <TextField
            id="standard-businessName"
            fullWidth
            label="Business Name"
            value={props.values.businessName || ""}
            inputProps={{ maxLength: 40 }}
            onChange={props.handleChanges('businessName')}
            placeholder=""
            helperText={props.errors.showbusinessNameError ? ValidValueConstants.BUSINESS_NAME_ERROR : null}
            InputLabelProps={{
              shrink: true
            }}
            error={props.errors.showbusinessNameError ? ValidValueConstants.BUSINESS_NAME_ERROR : null}
          />
          <div className="sub-radio">
            <Radio
              value="StartsWith"
              id="businessNameStartId"
              checked={props.values.busNameStartsOrContains === 'StartsWith'}
              onChange={props.handleChanges('busNameStartsOrContains')}
            /><label className="text-black" htmlFor="businessNameStartId">Starts With</label>
            <Radio
              value="Contains"
              id="businessNameContainsId"
              checked={props.values.busNameStartsOrContains === 'Contains'}
              onChange={props.handleChanges('busNameStartsOrContains')}
              className="ml-2"
            /><label htmlFor="businessNameContainsId" className="text-black">Contains</label>
          </div>
        </div>
        <div className="mui-custom-form"
        >
          <TextField
            id="standard-dataElementName"
            fullWidth
            label="Data Element Name"
            value={props.values.dataElementName || ""}
            inputProps={{ maxLength: 30 }}
            onChange={props.handleChanges('dataElementName')}
            placeholder=""
            helperText={props.errors.showdataElementNameError ? ValidValueConstants.DATA_ELEMENT_NAME_ERROR : null}
            InputLabelProps={{
              shrink: true
            }}
            error={props.errors.showdataElementNameError ? ValidValueConstants.DATA_ELEMENT_NAME_ERROR : null}
          />
          <div className="sub-radio">
            <Radio
              value="StartsWith"
              id="dataElementNameStartsId"
              checked={props.values.dataEleNameStartsOrContains === 'StartsWith'}
              onChange={props.handleChanges('dataEleNameStartsOrContains')}
            /><label className="text-black" htmlFor="dataElementNameStartsId">Starts With</label>
            <Radio
              value="Contains"
              id="dataElementNameContainsId"
              checked={props.values.dataEleNameStartsOrContains === 'Contains'}
              onChange={props.handleChanges('dataEleNameStartsOrContains')}
              className="ml-2"
            /><label className="text-black" htmlFor="dataElementNameContainsId">Contains</label>
          </div>
        </div>
      </div>
      <div className="form-wrapper">
        <div className="mui-custom-form">
          <TextField
            id="standard-valueShortDescription"
            fullWidth
            label="Value Short Description"
            value={props.values.valueShortDescription || ""}
            inputProps={{ maxLength: 10 }}
            onChange={props.handleChanges('valueShortDescription')}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}/>
        </div>
        <div className="mui-custom-form"
        >
          <TextField
            id="standard-tableName"
            fullWidth
            label="Table Name"
            value={props.values.crossReferenceTableName || ""}
            inputProps={{ maxLength: 30 }}
            onChange={props.handleChanges('crossReferenceTableName')}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}/>
        </div>
        <div className="mui-custom-form"
        >
          <TextField
            id="standard-columnName"
            fullWidth
            label="Column Name"
            value={props.values.crossReferenceColumnName || ""}
            inputProps={{ maxLength: 30 }}
            onChange={props.handleChanges('crossReferenceColumnName')}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}/>
        </div>

      </div>
      <div className="float-right mr-3 mb-2">
        <Button className='btn btn-primary'
          onClick={props.searchCheck}>
          <i className="fa fa-search" aria-hidden="true"></i>
             Search </Button>
        <Button className='bt-reset btn-transparent  ml-1'
          onClick={props.resetTable}>
          <i className="fa fa-undo" aria-hidden="true"></i>
           Reset </Button>
      </div>
      <div className="clearfix"></div>
    </form>
  );
};
