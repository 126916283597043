/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const SEARCH_BUDGET_MAINTENANCE = 'SEARCH_BUDGET_MAINTENANCE';
export const ROW_SEARCH_BUDGET_MAINTENANCE = 'ROW_SEARCH_BUDGET_MAINTENANCE';
export const RESETDATA = 'RESETDATA';
export const ADD_BUDGET_MAINTENANCE_SAVE = 'ADD_BUDGET_MAINTENANCE_SAVE';
export const ADD_BUDGET_MAINTENANCE_VIEW = 'ADD_BUDGET_MAINTENANCE_VIEW';
export const UPDATE_BUDGET_MAINTENANCE_SAVE = 'UPDATE_BUDGET_MAINTENANCE_SAVE';
export const SEARCH_BUDGET_MAINTENANCE_ID = 'SEARCH_BUDGET_MAINTENANCE_ID';
export const DROPDOWNDATA = 'DROPDOWNDATA';
export const SUB_DROPDOWNDATA = 'SUB_DROPDOWNDATA';
export const END_DATE_API = 'END_DATE_API';
export const BUDEGT_NOTES_USAGE_DROPDOWN = 'BUDEGT_NOTES_USAGE_DROPDOWN';
export const AUDIT_LOG_TABLE = "AUDIT_LOG_TABLE";
export const AUDIT_LOG_TABLE_AUDIT = "AUDIT_LOG_TABLE_AUDIT";

