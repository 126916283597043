/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import { DrawerFooter } from '../Navigation/navigationMenu';
import clsx from 'clsx';
import BottomLogo from '../../images/bottom_logo.png';
import { useHistory } from 'react-router-dom';
import { FOOTER_LINKS, SITE_MAP, WIP_FOOTER_LINKS } from '../Navigation/RouteConstants';
import {PRIVACY_POLICY, TERMS_OF_USE, BROWSER_REQUIREMENTS, ACCESSIBILITY_COMPLIANCE, SITEMAP} from '../../Modules/ContentManagement/FooterLinks';
function Footer ({ print, main }) {
  const handleLink = (linkName) => {
    if (linkName == PRIVACY_POLICY) {
      history1.push(FOOTER_LINKS, linkName);
    } else if (linkName === TERMS_OF_USE) {
      history1.push(FOOTER_LINKS, linkName);
    } else if (linkName === BROWSER_REQUIREMENTS) {
      history1.push(FOOTER_LINKS, linkName);
    } else if (linkName === ACCESSIBILITY_COMPLIANCE) {
      history1.push(FOOTER_LINKS, linkName);
    } else if (linkName === SITEMAP) {
      history1.push(SITE_MAP);
    } else {
      history1.push(WIP_FOOTER_LINKS);
    }
  };
  const history1 = useHistory();
  return (
    <>
      {!print ? (<div className="container-fluid" style={{ flex: '0 ' }} id="mainContentFooter" >
        <div className="print-footer">        
        <div className="main-footer pt-3">
            <div className="print-col-4 hide-on-screen">
              <div className="bottom left-bottom-logo">
                  <div className="poweredBy">Powered By</div>
                  <div className="left-bottom-brand"> <img src={BottomLogo} alt="CONDUENT" title="CONDUENT" /> </div>
              </div>
              <div align="left" class="build-version">Build Version No: <span><a title={process.env.REACT_APP_PRODUCT_BUILD_NO}> {process.env.REACT_APP_PRODUCT_BUILD_NO}</a></span></div>
            </div>
            <div class="print-col-8" align="center">
              <p class="footer-text mb-1">2019 Conduent, Inc. All rights reserved. Conduent and Conduent Agile Star are trademarks of Conduent, Inc. and/or its subsidiaries in the United States and/or other countries</p>
              <div align="center" className="nav-menu col-lg-12 check" >
                <span>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleLink(PRIVACY_POLICY);
                    }}
                    title="Privacy Policy"
                  >
                    Privacy Policy
                  </a>
                </span>
                <span>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleLink(SITEMAP);
                    }}
                    title="Site Map"
                  >
                    &nbsp;&nbsp;Site Map
                  </a>
                </span>
                <span>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleLink(TERMS_OF_USE);
                    }}
                    title="Terms of Use"
                  >
                    &nbsp;&nbsp;Terms of Use
                  </a>
                </span>
                <span>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleLink(BROWSER_REQUIREMENTS);
                    }}
                    title="Browser Requirements"
                  >
                    &nbsp;&nbsp; Browser Requirements
                  </a>
                </span>
                <span>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleLink(ACCESSIBILITY_COMPLIANCE);
                    }}
                    title="Accessibility Compliance"
                  >
                    &nbsp;&nbsp; Accessibility Compliance
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>)
        : (<div></div>)}
    </>

  );
}

export default Footer;
