/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import TableComponent from '../../../../../SharedModules/Table/Table';

const headCells = [
  { id: 'beginDate', numeric: false, disablePadding: true, label: 'Begin Date', width:'20%', isDate:true,},
  { id: 'endDate', numeric: false, disablePadding: false, label: 'End Date', width: '20%', isDate: true },
  { id: 'startingValue', numeric: false, disablePadding: false, label: 'Begin Value', width:'20%', isVarChar: true },
  { id: 'endingValue', numeric: false, disablePadding: false, label: 'End Value', width:'20%', isVarChar: true},
  { id: 'sortNum', numeric: false, disablePadding: false, label: 'Sort Order', width:'20%', isVarChar: true}
];



export default function SystemListAddTable (props) {
  const tableComp = <TableComponent print={props.print} fixedTable headCells={headCells}
    tableData={props.tableData ? props.tableData : []}
    onTableRowClick={props.onEditSystemlistDetail}
    defaultSortColumn={headCells[0].id} isSearch={false} onTableRowDelete = {props.rowDeleteSystemListDetails}/>;
  return (
    tableComp
  );
}
