// eslint-disable-next-line no-unused-vars
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';

export default function Searchuser () {
  return (
    <div><h1>Search User</h1></div>
  );
};
