/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import TableComponent from "../../../../SharedModules/Table/Table";
import TextField from "@material-ui/core/TextField";
import { getLongDescription } from '../../../../SharedModules/CommonUtilities/commonUtilities';
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";

const headCells = [
  {
    id: "tcn",
    numeric: false,
    disablePadding: true,
    label: "FCN/TCN",
    width: 80,
    fontSize: 12,
    isRedirectable: true,
    openInNewTab: true
  },
  {
    id: "lineItemNumber",
    numeric: false,
    disablePadding: false,
    label: "Line Item Number",
    width: 80,
    fontSize: 12
  },
  {
    id: "reasonCode",
    numeric: false,
    disablePadding: false,
    label: "Disposition Reason Code",
    width: 80,
    fontSize: 12,
    isTooltip: true,
    isTitle: 'rcLongDesc'
  },
  {
    id: "dispositionDate",
    numeric: false,
    disablePadding: false,
    label: "Disposition Date",
    width: 80,
    fontSize: 12
  },
  {
    id: "appliedAmount",
    numeric: false,
    disablePadding: false,
    label: "Applied Amount",
    isBalance: true,
    width: 80,
    fontSize: 12
  },
  {
    id: "overPaymentAmount",
    numeric: false,
    disablePadding: false,
    label: "Overpayment Amount",
    isBalance: true,
    width: 80,
    fontSize: 12
  }
];

export default function History (props) {
  const { historyData, globalHistory, originalTab, financialReasCodeDD, editPageFlag } = props;
  const [isDeleteDisable, setDeleteDisable] = React.useState(true);
  const historyTableRowClick = row => event => { };
  const onLinkClick = row => event => {
    window.open("fcn", "_blank");
  };

  function RowDeleteClick () {
    if (editPageFlag) {
      Confirm();
    } else {
      props.deleteConfirm();
    }
  }

  function Confirm () {
    Swal.fire({
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-primary",
        container: "unsaved-changes-popup2",
      },
      allowOutsideClick: false,
      buttonsStyling: false,
      heightAuto: false,
      icon: "warning",
      html: "<h4>Are you sure you want to reverse?</h4>",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        props.deleteConfirm();
      } else {
      }
    });
  }

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;         
          const reasonVal =
            props.dropdowns &&
            props.dropdowns['F1#F_RSN_CD'] &&
            props.dropdowns['F1#F_RSN_CD'].filter(
              value => value.code === (each.reasonCode || each.financialReason)
            );
          each.reasonCode = reasonVal && reasonVal.length > 0
          ? reasonVal[0].description
          : each.reasonCode ? each.reasonCode : each.financialReason ? each.financialReason : '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  return (
    <div>
      <div className="form-wrapper">
        <div className="mui-custom-form input-md">
          <TextField
            id="standard-ReasonCode"
            fullWidth
            label="Reason Code"
            value={globalHistory.reasonCode}
            inputProps={{ maxlength: 15 }}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            title={getLongDescription(financialReasCodeDD, globalHistory.reasonCode)}
            InputProps={{
              className: "Mui-disabled"
            }}
          ></TextField>
        </div>
        <div className="mui-custom-form input-md">
          <TextField
            id="standard-EstablishedDate"
            fullWidth
            label="Established Date"
            value={originalTab.establishedDate}
            inputProps={{ maxlength: 15 }}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              className: "Mui-disabled"
            }}
          ></TextField>
        </div>
        <div className="mui-custom-form input-md">
          <TextField
            id="standard-OriginalAmount"
            fullWidth
            label="Original Amount"
            value={globalHistory.originalAmount}
            inputProps={{ maxlength: 15 }}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              className: "Mui-disabled"
            }}
          ></TextField>
        </div>
        <div className="mui-custom-form input-md">
          <TextField
            id="standard-AppliedAmount"
            fullWidth
            label="Applied Amount"
            value={globalHistory.appliedAmount === null ? '' : globalHistory.appliedAmount}
            inputProps={{ maxlength: 15 }}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              className: "Mui-disabled"
            }}
          ></TextField>
        </div>
      </div>
      <div className="form-wrapper">
        <div className="mui-custom-form input-md">
          <TextField
            id="standard-Balance-Amount"
            fullWidth
            label="Balance Amount"
            value={originalTab.balanceAmount}
            inputProps={{ maxlength: 15 }}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              className: "Mui-disabled"
            }}
          ></TextField>
        </div>
      </div>
      <div className="float-right mt-3">
        <Button
          className="btn-text-main-delete btn-transparent ml-1"
          variant="outlined"
          onClick={RowDeleteClick}
          disabled={isDeleteDisable}
          title={'Reverse'}
        >
          <i class="fa fa-trash" aria-hidden="true"></i>
                      Reverse
        </Button>
      </div>
      <div className="clearfix"></div>
      <div className="tab-holder table-no-wrap my-3">
        <TableComponent
          print={props.print}
          isSearch={false}
          headCells={headCells}
          tableData={getTableData(historyData)}
          onTableRowClick={historyTableRowClick}
          defaultSortColumn={"tcn"}
          onLinkClick={onLinkClick}
          onTableRowDelete={props.onTableRowDelete}
          setDeleteDisable={setDeleteDisable}
        />
      </div>
    </div>
  );
}
