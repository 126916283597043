/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../../Actions/FinancialReceipt/financialReceiptActionTypes';

const axiosPayLoad = {
  financialReceiptSearch: null,
  dropDowns: []
};
const reducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.DROPDOWNDATA:
      return { dropdownData: action.dropdownData };
    case actionTypes.FINANCIAL_RECEIPT_SEARCH_ACTION_TYPE:
      return { ...state, financialReceiptSearch: action.financialReceiptSearch };
    case actionTypes.FINANCIAL_ADD_GENERATE_FCN:
      return { generatedFCNNUM: action.generatedFCNNUM };
    case actionTypes.SEARCH_SYSTEM_LIST:
      return { systemLists: action.systemLists };
    case actionTypes.GET_PAYEEIDTYPECODE_ON_PAYEETYPE:
      return { payeeIDTypeCodeDetails: action.payeeIDTypeCodeDetails };
    case actionTypes.GET_FINANCIALREASONCODE_ON_ACTIVITYTYPE:
      return { financialReasonCodeDetails: action.financialReasonCodeDetails };
    case actionTypes.GET_ADJUSTMENT_DATA:
      return { ...state, adjustmentData: action.adjustmentData };
    case actionTypes.GET_ADJUSTMENT_CALCULATION:
      return { ...state, calculatedData: action.calculatedData };
    case actionTypes.SAVE_ADJUSTMENT_CALCULATION:
      return { ...state, saveResult: action.saveResult };
    case actionTypes.VALIDATE_PAYEE_RESULT:
      return { validatePayeeResult: action.validatePayeeResult };
    case actionTypes.MEMBER_ID_DETAILS:
      return { ...state, memberDetails: action.memberDetails };
    case actionTypes.FINANCIAL_RECEIPT_UPDATE_ACTION_TYPE:
      return { financialReceiptUpdate: action.financialReceiptUpdate };
    case actionTypes.ADD_REFUND_RECOVERY_VALIDATE:
      return { validateAddRefundResult: action.validateAddRefundResult };
    case actionTypes.ADD_FINANCIAL_RECEIPT:
      return { addFinanceReceiptResult: action.addResponse };
    case actionTypes.UPDATE_FINANCIAL_RECEIPT:
      return { updateFinancialReceiptResult: action.updateResponse };
    case actionTypes.ADD_RECEIPT_REVERSAL:
      return { addReceiptReverseResponse: action.addReceiptReverseResponse };
    case actionTypes.SAVE_RECEIPT_REVERSAL:
      return { saveReceiptReverseResponse: action.saveReceiptReverseResponse };
    case actionTypes.GET_CHECK_REPLACEMENT_DATA:
      return { checkReplacementData: action.checkReplacementData };
    case actionTypes.FINANCIAL_RECEIPT_FCN_SEARCH_ACTION_TYPE:
      return { fcnSearch: action.fcnSearch };
    case actionTypes.RESETDATA:
      return action.resetData;
    case actionTypes.AUDIT_LOG_TABLE:
      return { auditDetails: action.payload }
    case actionTypes.AUDIT_LOG_TABLE_ROW:
      return { auditRowDetails: action.payload }
    default: return state;
  }
};

export default reducer;
