/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from 'react';
import MapSetSearchTableComponent from './MapSetSearchTableComponent';
import { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import * as MapSetConstants from './MapSetConstants';
import {
  mapSetAction,
  resetpayloadMapSet,
  resetSearchMapSet
} from '../../Store/Actions/MapSet/mapSetActions';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import dropdownCriteria from './MapSetSearch.json';
import {
  AppConfigDropdownActions,
  DataElementMapDropdownActions
} from '../../Store/Actions/AppConfigCommon/AppConfigActions';
import Footer from '../../../../SharedModules/Layout/footer';
import Radio from '@material-ui/core/Radio';
import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';
import { DEFAULT_LOB_VALUE } from '../../../../SharedModules/AppConstants';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 400
  },
  paper: {
    maxWidth: 'max-content'
  }
}));

function MapSetSearch (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const classes = useStyles();
  const [showTable, setShowTable] = React.useState(false);
  let errorMessagesArray = [];
  const dropDownDispatch = dropdownvalues =>
    dispatch(AppConfigDropdownActions(dropdownvalues));
  const dataElemDropDownDispatch = () => dispatch(DataElementMapDropdownActions());

  useEffect(() => {
    dropDownDispatch(dropdownCriteria);
    dataElemDropDownDispatch();
  }, []);

  const [isSearch, setIsSearch] = React.useState(false);

  const [values, setValues] = React.useState({
    lineOfBusiness: DEFAULT_DD_VALUE,
    mapType: DEFAULT_DD_VALUE,
    mapSetID: '',
    mapDescription: '',
    fieldValue: null,
    mapSetIDStartsOrContains: null,
    mapDescStartsOrContains: null,
    dataElementName: DEFAULT_DD_VALUE
  });

  const [errorMessages, seterrorMessages] = React.useState([]);
  const [
    { showMapIDError, showMapDescError, showDataElementError },
    setShowError,
    showMapDesError
  ] = React.useState(false);
  const handleChanges = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  const [errorMsg, setErrorMsg] = React.useState(false);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const resetTable = () => {
    onReset();
    seterrorMessages([]);
    setShowError({
      showMapIDError: false,
      showMapDescError: false,
      showDataElementError: false
    });
    setValues({
      lineOfBusiness: DEFAULT_DD_VALUE,
      mapType: DEFAULT_DD_VALUE,
      mapSetID: '',
      mapDescription: '',
      dataElementName: DEFAULT_DD_VALUE,
      fieldValue: '',
      columnName: '',
      tableName: ''
    });
    setShowTable(false);
    setErrorMsg(false);

    const valuetoredirect = redirect - 1;
    setRedirect(valuetoredirect);
  };

  /// INTEGRATION PART BEGIN
  const dispatch = useDispatch();

  const onReset = () => dispatch(resetSearchMapSet());

  useEffect(() => {
    onReset();
    onDropdowns([
      Dropdowns.MAP_TYP_CODE
    ]);
  }, []);

  let paylod = [];
  const [redirect, setRedirect] = React.useState(0);
  const onSearch = values => {
 dispatch(mapSetAction(values));
    setIsSearch(true);
};
  const [functionalAreaReference, setfunctionalAreaReference] = useState([]);
  paylod = useSelector(state => state.appConfigState.mapSetState.payload);
  const dropdown = useSelector(
    state => state.appConfigState.AppConfigCommonState.appConfigDropdown
  );
  const datElemDropdown = useSelector(
    state => state.appConfigState.AppConfigCommonState.dataElementMapDropdown
  );
  const [MapTypeReference, setMapTypeReference] = useState([]);

  const [dataElementReference, setdataElementReference] = useState([]);
  const [dataElementRequired, setDataElementRequired] = useState(false);

  useEffect(() => {
    if (values) {
      if (values.fieldValue === '' || values.fieldValue === null) {
        setDataElementRequired(false);
      } else {
        setDataElementRequired(true);
      }
    }
  }, [values]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['Reference#1022']) {
        setMapTypeReference(dropdown.listObj['Reference#1022']);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (datElemDropdown && !datElemDropdown.systemFailure) {
      setdataElementReference(datElemDropdown.sort());
    }
  }, [datElemDropdown]);

  useEffect(() => {
    if (paylod && paylod.systemFailure) {
      const tempArray = [];
      tempArray.push(MapSetConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(tempArray);
    }
    if (paylod === '') {
      setErrorMsg(true);
      const tempArray = [];
      tempArray.push(MapSetConstants.ERROR_OCCURED_DURING_TRANSACTION);
      seterrorMessages(tempArray);
    }
    if (paylod && typeof paylod === "object" && paylod.length > 0) {
      setShowTable(true);
    }
    if (paylod && typeof paylod === "object" && paylod.length === 0) {
      setErrorMsg(true);
      const tempArray = [];
      tempArray.push(MapSetConstants.ERROR_OCCURED_DURING_TRANSACTION);
      seterrorMessages(tempArray);
    }
  }, [paylod]);

  const payloadData = paylod ? paylod[0] : {};

  const mapTypeDescriptionMap = mapTypeCode => {
    const filteredValue = MapTypeReference.filter(
      (mapType) => mapType.code === mapTypeCode
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return mapTypeCode;
  };

  if (paylod && paylod.length > 0) {
    paylod.map((data) => {
      data.mapTypeDesc = mapTypeDescriptionMap(data.mapTypeCode);
    });
  }

  const setValuesFromMapSet = props?.history?.state?.values;

  if (redirect === 1) {
    if (paylod != null) {
      if (paylod.length === 1 && payloadData !== {}) {
        payloadData.mapDefDetail.map(() => {
        });
        functionalAreaReference.map(fDesc => {
          if (payloadData.functionalArea === fDesc.code) {
            payloadData.functionalAreaDesc = fDesc.description;
          }
        });
        props.history.push({
          pathname: '/MapSetEdit',
          state: { payloadData, values: values }
        });
      }
    }
  }

  // INTEGRATION PART END

  const searchCheck = values => {
    setShowTable(false);
    errorMessagesArray = [];
    dispatch(resetpayloadMapSet());
    seterrorMessages([]);
    var showMapIDError;
    var showMapDescError = false;
    var showDataElementError = false;
    if (
      values.mapSetIDStartsOrContains &&
      (!values.mapSetID || values.mapSetID.trim().length < 2)
    ) {
      showMapIDError = true;
      errorMessagesArray.push(MapSetConstants.MAP_SET_ID_ERROR);
      seterrorMessages(errorMessagesArray);
      setShowTable(false);
    }
    if (
      dataElementRequired &&
      (values.dataElementName === DEFAULT_DD_VALUE)
    ) {
      showDataElementError = true;
      errorMessagesArray.push(MapSetConstants.DATA_ELEMENT_NAME_REQUIRED);
      seterrorMessages(errorMessagesArray);
      setShowTable(false);
    }
    if (
      values.mapDescStartsOrContains &&
      (!values.mapDescription || values.mapDescription.trim().length < 2)
    ) {
      showMapDescError = true;
      errorMessagesArray.push(MapSetConstants.MAP_SET_ID_ERROR);
      seterrorMessages(errorMessagesArray);
      setShowTable(false);
    } else if (errorMessagesArray.length === 0) {
      setErrorMsg(false);
      setShowTable(true);
      const searchCriteria = {
        lineOfBusiness: [DEFAULT_LOB_VALUE],
    mapType: values.mapType !== DEFAULT_DD_VALUE ? values.mapType : null,
    mapSetID: values.mapSetID !== '' ? values.mapSetID : null,
    mapDescription:
      values.mapDescription !== '' ? values.mapDescription : null,
    fieldValue: values.fieldValue !== '' ? values.fieldValue : null,
    mapSetIDStartsOrContains:
      values.mapSetIDStartsOrContains === 'StartsWith'
        ? 0
        : values.mapSetIDStartsOrContains === 'Contains'
          ? 1
          : null,
    mapDescStartsOrContains:
      values.mapDescStartsOrContains === 'StartsWith'
        ? 0
        : values.mapDescStartsOrContains === 'Contains'
          ? 1
          : null,
    dataElementName:
      values.dataElementName !== DEFAULT_DD_VALUE
        ? values.dataElementName
        : null
  };
      onSearch(searchCriteria);
      if (!props.history.state) {
        props.history.state = {};
      }
      props.history.state.values = searchCriteria;
      let valuetoredirect = 0;
      valuetoredirect = valuetoredirect + 1;
      setRedirect(valuetoredirect);
    }
    setShowError({
      showMapIDError: showMapIDError,
      showMapDesError: showMapDesError,
      showMapDescError: showMapDescError,
      showDataElementError: showDataElementError
    });
    setShowTable(false);
  };

  useEffect(() => {
    if (props.location.tableRender && !isSearch) {
      if (setValuesFromMapSet !== undefined) {
    const searchCriteria = {
      lineOfBusiness: [DEFAULT_LOB_VALUE],
    mapType: setValuesFromMapSet.mapType !== DEFAULT_DD_VALUE ? setValuesFromMapSet.mapType : null,
    mapSetID: setValuesFromMapSet.mapSetID !== '' ? setValuesFromMapSet.mapSetID : null,
    mapDescription:
      setValuesFromMapSet.mapDescription !== '' ? setValuesFromMapSet.mapDescription : null,
    fieldValue: setValuesFromMapSet.fieldValue !== '' ? setValuesFromMapSet.fieldValue : null,
    mapSetIDStartsOrContains:
      setValuesFromMapSet?.mapSetIDStartsOrContains,
    mapDescStartsOrContains:
      setValuesFromMapSet?.mapDescStartsOrContains,
    dataElementName:
      setValuesFromMapSet.dataElementName !== DEFAULT_DD_VALUE
        ? setValuesFromMapSet.dataElementName
        : null
  };
        onSearch(searchCriteria);
        setValues({
...setValuesFromMapSet,
          mapSetIDStartsOrContains: setValuesFromMapSet.mapSetIDStartsOrContains === 0 ? "StartsWith" : setValuesFromMapSet.mapSetIDStartsOrContains === 1 ? "Contains" : null,
          mapDescStartsOrContains: setValuesFromMapSet.mapDescStartsOrContains === 0 ? "StartsWith" : setValuesFromMapSet.mapDescStartsOrContains === 1 ? "Contains" : null
        });
        setShowTable(true);
        seterrorMessages([]);
      }
    }
    }, [props.location.tableRender]);

  const addMapSet = () => {
    props.history.push({
      pathname: '/MapSetAdd'
    });
  };

  return (
    <div className="pos-relative w-100 h-100">

      <div className="tabs-container">
        {(errorMessages.length === 0 &&
          paylod &&
          paylod.length === 0 &&
          errorMsg) ||
          (paylod === '' && errorMsg) ? (
            <div className="alert alert-danger custom-alert" role="alert">
              <li>{MapSetConstants.NO_RECORDS_FOUND}</li>
            </div>
          ) : null}
        {errorMessages.length > 0 ? (
          <div className="alert alert-danger custom-alert" role="alert">
            {errorMessages.map(message => (
              <li key={message}>{message}</li>
            ))}
          </div>
        ) : null}
        <div className="tab-header">
          <div className="tab-heading float-left">
            {MapSetConstants.MAPSET_HEADER}
          </div>
          <div className="float-right mt-2">
            <Button
              title="Add Map Definition"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-add btn-add-new"
              onClick={() => addMapSet()}
              disabled={global.globalIsReadOnly()}
            >
              Add
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div>
          <div className="tab-body">
            <form autoComplete="off">
              <div className="form-wrapper">
                <div className="mui-custom-form with-select input-md">
                  <TextField
                    id="standard-mapSetID"
                    label={MapSetConstants.MAP_ID}
                    value={values.mapSetID}
                    onChange={handleChanges('mapSetID')}
                    placeholder=""
                    helperText={
                      showMapIDError ? MapSetConstants.MAP_SET_ID_ERROR : null
                    }
                    inputProps={{ maxLength: 30 }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={
                      showMapIDError ? MapSetConstants.MAP_SET_ID_ERROR : null
                    }
                  />
                  <div className="sub-radio">
                    <Radio
                      value="StartsWith"
                      id="startmap"
                      checked={values.mapSetIDStartsOrContains === 'StartsWith'}
                      onChange={handleChanges('mapSetIDStartsOrContains')}
                    />
                    <label className="text-black" htmlFor="startmap">Starts With</label>
                    <Radio
                      value="Contains"
                      id="containmap"
                      checked={values.mapSetIDStartsOrContains === 'Contains'}
                      onChange={handleChanges('mapSetIDStartsOrContains')}
                      className="ml-2"
                    />
                    <label className="text-black" htmlFor="containmap">Contains</label>
                  </div>
                </div>
                <div className="mui-custom-form with-select input-md">
                  <TextField
                    id="standard-mapDesc"
                    label={MapSetConstants.MAP_DESCRIPTION}
                    value={values.mapDescription}
                    onChange={handleChanges('mapDescription')}
                    placeholder=""
                    inputProps={{ maxLength: 30 }}
                    helperText={
                      showMapDescError ? MapSetConstants.MAP_SET_ID_ERROR : null
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={
                      showMapDescError ? MapSetConstants.MAP_SET_ID_ERROR : null
                    }
                  />
                  <div className="sub-radio">
                    <Radio
                      value="StartsWith"
                      id="startdescription"
                      checked={values.mapDescStartsOrContains === 'StartsWith'}
                      onChange={handleChanges('mapDescStartsOrContains')}
                    />
                    <label className="text-black" htmlFor="startdescription">Starts With </label>
                    <Radio
                      value="Contains"
                      id="containsdescription"
                      checked={values.mapDescStartsOrContains === 'Contains'}
                      onChange={handleChanges('mapDescStartsOrContains')}
                      className="ml-2"
                    />
                    <label className="text-black" htmlFor="containsdescription">Contains </label>
                  </div>
                </div>
                  <NativeDropDown
                    id="standard-select-mapType"
                    label={MapSetConstants.MAP_TYPE}
                    value={values.mapType || DEFAULT_DD_VALUE}
                    onChange={handleChanges('mapType')}
                    SelectProps={{
                      MenuProps: {
                      }
                    }}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    dropdownList={MapTypeReference
                      ? MapTypeReference.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.description}
                        </option>
                      ))
                      : null}
                  />

                  <NativeDropDown
                    id="standard-select-dataElementName"
                    label={MapSetConstants.DATA_ELEMENT_NAME}
                    value={values.dataElementName || DEFAULT_DD_VALUE}
                    onChange={handleChanges('dataElementName')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    isRequired={dataElementRequired}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    errTxt={
                      showDataElementError ? MapSetConstants.DATA_ELEMENT_NAME_REQUIRED : null
                    }
                    dropdownList={dataElementReference
                      ? dataElementReference.map((item, index) => (
                        <option
                          key={index}
                          value={item}
                          style={{ width: '350px' }}
                        >
                          {item}
                        </option>
                      ))
                      : null}
                  />

                <div className="mui-custom-form with-select input-md">
                  <TextField
                    id="standard-fieldValue"
                    label={MapSetConstants.FIELD_VALUE}
                    value={values.fieldValue}
                    onChange={handleChanges('fieldValue')}
                    placeholder=""
                    inputProps={{ maxLength: 30 }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>
              </div>
            </form>

            <div className="float-right m-3">
              <Button
                variant="outlined"
                color="primary"
                className="btn btn-primary"
                onClick={() => searchCheck(values)}
              >
                {' '}
                <i className="fa fa-search" aria-hidden="true"></i>
                Search
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className="bt-reset btn-transparent  ml-1"
                onClick={() => resetTable()}
              >
                <i className="fa fa-undo" aria-hidden="true"></i>
                Reset
              </Button>
            </div>
            <div className="clearfix"></div>
            <div></div>
            {showTable &&
              paylod &&
              !paylod.systemFailue &&
              paylod.length > 0 ? (
                <div className="tab-holder pb-1">
                  <MapSetSearchTableComponent
                    tableData={paylod}
                    values={values}
                    functionalAreaReference={functionalAreaReference}

                    dropdowns={addDropdowns}
                  />
                </div>
              ) : null}
            <Footer print />
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(MapSetSearch);
