/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Bootstrap, { Button } from "react-bootstrap";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import numeral from "numeral";
import AddMiscellaneous from "./AddMiscellaneous";
import MiscellaneousTable from "./MiscellaneousTable";
import DialogActions from "@material-ui/core/DialogActions";
import {
  validateMiscellaneousPopup,
  MiscellaneousReset
} from "../AddValidationUtility";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {
  generateUUID,
  getDateInMMDDYYYYFormat,
  getUTCTimeStamp
} from "../../../../SharedModules/DateUtilities/DateUtilities";
import axios from "axios";
import clsx from "clsx";
import TableComponent from "../../../../SharedModules/Table/Table";
import AuditTableComponent from "../../../../SharedModules/AuditLog/AuditTableComponent";
import { setFocus } from "../../../ApplicationConfiguration/Store/Actions/AppConfigCommon/AppConfigActions";
import { checkingDecimal } from "../../../../SharedModules/CommonUtilities/commonUtilities";
import { YES } from "../FinancialEntityConstants";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  }
}));

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);
export default function MiscellaneousDetails(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const classes = useStyles();
  const {
    disableFields,
    miscellaneousTableData,
    setMiscellaneousTableData,
    showEditPage,
    setAllowNavigation,
    callValidatePayeeDetails,
    BankruptcyTypeDropdown,
    seterrorMessages,
    showLogMiscellaneousTable,
    auditRowData,
    setAuditRowData,
    setShowLogTable,
    setActiveMiscellaneousData,
    setShowLogMiscellaneousTable,
    userInquiryPrivileges,
    setFocusCheck,
    initialFocusCheck
  } = props;
  // Dropdown description implementation ---- starts
  const dropDownData = useSelector(
    state => state.financialEntityState.dropdownData
  );
  const [filteredTableData, setFilteredTableData] = React.useState([]);
  const dropDownFilter = bankruptcyType => {
    if (BankruptcyTypeDropdown) {
      const filteredValue = BankruptcyTypeDropdown.filter(
        (bankruptcyTypeValue, index) =>
          bankruptcyTypeValue.code === bankruptcyType
      );
      if (filteredValue && filteredValue.length > 0) {
        return filteredValue[0].description;
      }
      return bankruptcyType;
    }
  };

  const miscellaneousTableFilter = filteredData => {
    if (filteredData && filteredData.length > 0) {
      filteredData.map(
        (data, index) =>
          (data.bankruptcyTypeDesc = dropDownFilter(data.bankruptcyType))
      );
    }
    setFilteredTableData(filteredData);
  };
  // Dropdown description implementation -------- ends

  // Set Description in fields on Initial table load
  React.useEffect(() => {
    const filteredData = miscellaneousTableData;
    miscellaneousTableFilter(filteredData);
  }, [miscellaneousTableData]);

  const [editRow, setEditRow] = React.useState(false);
  // Edit row Reset Functionality
  const [retainRecordEdit, setRetainRecordEdit] = React.useState("");
  const onEditRowPopup = value => {
    const beginDate = setSelectedMiscellaneousBeginDate(
      value.bankruptcyBeginDate
    );
    const endDate = setSelectedMiscellaneousEndDate(value.bankruptcyEndDate);
    setMiscellaneousDetails({
      id: value.id || null,
      auditUserID: logInUserID, // For Add and Edit/Update API call new values
      auditTimeStamp: getUTCTimeStamp(),
      addedAuditUserID: value.addedAuditUserID
        ? value.addedAuditUserID
        : logInUserID,
      addedAuditTimeStamp: value.addedAuditTimeStamp
        ? value.addedAuditTimeStamp
        : getUTCTimeStamp(),
      bankruptcyTypeCode: value.bankruptcyType,
      bankruptcyBeginDate: beginDate,
      bankruptcyEndDate: endDate,
      claimAmount: value.claimAmount,
      postedPetitionDebtAmount: value.postedPetitionDebtAmount,
      postedPetitionIndicator: value.postedPetitionIndicator === YES ? true : false,
      beginDate: null,
      endDate: null,
      date: null,
      bankRuptVrsnNo: value.bankRuptVrsnNo,
      payeePayerBankruptcySequenceNumber:
        value.payeePayerBankruptcySequenceNumber
    });
  };
  const handleClickOpenMiscellaneous = () => {
    if (callValidatePayeeDetails(true)) {
      MiscellaneousReset(
        miscellaneousTableData,
        setMiscellaneousDetails,
        setSelectedMiscellaneousBeginDate,
        setSelectedMiscellaneousEndDate,
        setShowMiscellaneousError,
        setShowMiscellaneousErrorMsg
      );
      setOpenMiscellaneous(true);
      setEditRow(false);
      setMiscellaneousDetails({
        id: generateUUID(),
        auditUserID: logInUserID, // For Add and Edit/Update API call new values
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: getUTCTimeStamp(),
        bankruptcyTypeCode: DEFAULT_DD_VALUE,
        bankruptcyBeginDate: null,
        bankruptcyEndDate: null,
        claimAmount: "",
        postedPetitionDebtAmount: "",
        postedPetitionIndicator: false,
        bankRuptVrsnNo: 0,
        payeePayerBankruptcySequenceNumber: null
      });
    }
  };
  // Reset Popup
  const handleReset = () => {
    setFocusCheck(initialFocusCheck);
    setAllowNavigation(initialFocusCheck);
    setActiveMiscellaneousData(null);
    seterrorMessages([]);
    if (!editRow) {
      MiscellaneousReset(
        miscellaneousTableData,
        setMiscellaneousDetails,
        setSelectedMiscellaneousBeginDate,
        setSelectedMiscellaneousEndDate,
        setShowMiscellaneousError,
        setShowMiscellaneousErrorMsg
      );
    } else {
      onEditRowPopup(retainRecordEdit);
    }
  };

  const [MiscellaneousDetails, setMiscellaneousDetails] = React.useState({
    bankruptcyTypeCode: DEFAULT_DD_VALUE,
    bankruptcyBeginDate: null,
    bankruptcyEndDate: null,
    claimAmount: "",
    postedPetitionDebtAmount: "",
    postedPetitionIndicator: false,
    beginDate: null,
    endDate: null,
    payeePayerBankruptcySequenceNumber: null
  });
  const [showMiscellaneousError, setShowMiscellaneousError] = React.useState({
    showBankruptcyTypeCodeError: false,
    showBankruptcyBeginDateError: false,
    showBankruptcyEndDateError: false,
    showClaimAmountError: false,
    showPostedPetitionDebtAmountError: false,
    showPostedPetitionIndicatorError: false
  });
  const [
    showMiscellaneousErrorMsg,
    setShowMiscellaneousErrorMsg
  ] = React.useState({
    showBankruptcyTypeCodeErrorMsg: "",
    showBankruptcyBeginDateErrorMsg: "",
    showBankruptcyEndDateErrorMsg: "",
    showClaimAmountErrorMsg: "",
    showPostedPetitionDebtAmountErrorMsg: "",
    showPostedPetitionIndicatorErrorMsg: ""
  });
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedBeginDate, setselectedBeginDate] = React.useState(null);
  const [
    SelectedMiscellaneousBeginDate,
    setSelectedMiscellaneousBeginDate
  ] = React.useState(null);
  const [
    SelectedMiscellaneousEndDate,
    setSelectedMiscellaneousEndDate
  ] = React.useState(null);
  const handleMiscellaneousTableAdd = value => event => {
    setShowMiscellaneousError({
      showBankruptcyTypeCodeError: false,
      showBankruptcyBeginDateError: false,
      showBankruptcyEndDateError: false,
      showClaimAmountError: false,
      showPostedPetitionDebtAmountError: false,
      showPostedPetitionIndicatorError: false
    })
    setActiveMiscellaneousData(value);
    if (callValidatePayeeDetails(true)) {
      setOpenMiscellaneous(true);
      setEditRow(true);
      setRetainRecordEdit(value);
      const beginDate = setSelectedMiscellaneousBeginDate(
        value.bankruptcyBeginDate
      );
      const endDate = setSelectedMiscellaneousEndDate(value.bankruptcyEndDate);
      setMiscellaneousDetails({
        id: value.id || null,
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: value.addedAuditUserID
          ? value.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: value.addedAuditTimeStamp
          ? value.addedAuditTimeStamp
          : getUTCTimeStamp(),
        bankruptcyTypeCode: value.bankruptcyType,
        bankruptcyBeginDate: beginDate,
        bankruptcyEndDate: endDate,
        claimAmount: value.claimAmount,
        postedPetitionDebtAmount: value.postedPetitionDebtAmount,
        postedPetitionIndicator: value.postedPetitionIndicator === YES ? true : false,
        beginDate: null,
        endDate: null,
        date: null,
        bankRuptVrsnNo: value.bankRuptVrsnNo,
        payeePayerBankruptcySequenceNumber:
          value.payeePayerBankruptcySequenceNumber
      });
    }
  };

  // Handle change for Add update Miscellaneous values
  const handleChangeMiscellaneous = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);if(name === 'claimAmount') {
      if (event.target.value && event.target.value.includes('.')) {
        if (checkingDecimal(event.target.value)) {
          setMiscellaneousDetails({ ...MiscellaneousDetails, [name]: event.target.value });
        }
      } else {
          setMiscellaneousDetails({ ...MiscellaneousDetails, [name]: event.target.value });
        }        
    }     
    else if (name === "postedPetitionIndicator") {
      setMiscellaneousDetails({
        ...MiscellaneousDetails,
        [name]: !MiscellaneousDetails.postedPetitionIndicator
      });
    } else if(name === "postedPetitionDebtAmount"){
      if (event.target.value && event.target.value.includes('.')) {
        if (checkingDecimal(event.target.value)) {
          setMiscellaneousDetails({ ...MiscellaneousDetails, [name]: event.target.value });
        }
      } else {
          setMiscellaneousDetails({ ...MiscellaneousDetails, [name]: event.target.value });
        }
    }
    else{
      setMiscellaneousDetails({
        ...MiscellaneousDetails,
        [name]: event.target.value
      }
      );
    } 
  };

  // Delete functionality starts --->
  const [
    miscellaneousTableDataRows,
    setMiscellaneousTableDataRows
  ] = React.useState([]);
  const selectedAddressTableRow = selectedRows => {
    setMiscellaneousTableDataRows([...selectedRows]);
  };
  const deleteSelectedAddressTableRow = () => {
    let tempMiscellaneousTableData = [...miscellaneousTableData];
    if (miscellaneousTableDataRows && miscellaneousTableDataRows.length > 0) {
      miscellaneousTableDataRows.map((selectedRowId, index) => {
        tempMiscellaneousTableData = tempMiscellaneousTableData.filter(
          tempRow => tempRow.id !== selectedRowId
        );
      });
    }
    setMiscellaneousTableData(tempMiscellaneousTableData);
  };
  // Delete functionality ends --->

  // On addition of new Miscellaneous record
  const handleSave = event => {
    if (
      validateMiscellaneousPopup(
        MiscellaneousDetails,
        SelectedMiscellaneousBeginDate,
        SelectedMiscellaneousEndDate,
        setShowMiscellaneousError,
        setShowMiscellaneousErrorMsg,
        seterrorMessages
      )
    ) {
      let miscellaneousTableDataNew = [];
      const miscellaneousInfo = {
        id: MiscellaneousDetails.id,
        auditUserID: logInUserID, // For Add and Edit/Update API call new values
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: MiscellaneousDetails.addedAuditUserID
          ? MiscellaneousDetails.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: MiscellaneousDetails.addedAuditTimeStamp
          ? MiscellaneousDetails.addedAuditTimeStamp
          : getUTCTimeStamp(),
        bankruptcyType: MiscellaneousDetails.bankruptcyTypeCode,
        bankruptcyBeginDate: SelectedMiscellaneousBeginDate
          ? getDateInMMDDYYYYFormat(SelectedMiscellaneousBeginDate)
          : null,
        bankruptcyEndDate: SelectedMiscellaneousEndDate
          ? getDateInMMDDYYYYFormat(SelectedMiscellaneousEndDate)
          : null,
        claimAmount:
          MiscellaneousDetails.claimAmount &&
          MiscellaneousDetails.claimAmount.trim()
            ? numeral(MiscellaneousDetails.claimAmount).format("0,0.00")
            : "",
        postedPetitionDebtAmount:
          MiscellaneousDetails.postedPetitionDebtAmount &&
          MiscellaneousDetails.postedPetitionDebtAmount.trim()
            ? numeral(MiscellaneousDetails.postedPetitionDebtAmount).format(
                "0,0.00"
              )
            : "",
        postedPetitionIndicator:
          MiscellaneousDetails.postedPetitionIndicator === false ? "No" : "Yes",
        postedPetitionIndBoolean:
          MiscellaneousDetails.postedPetitionIndicator !== false,
        bankRuptVrsnNo: MiscellaneousDetails.bankRuptVrsnNo,
        payeePayerBankruptcySequenceNumber:
          MiscellaneousDetails.payeePayerBankruptcySequenceNumber
      };
      if (editRow) {
        miscellaneousTableDataNew = miscellaneousTableData.map(Record => {
          if (Record.id) {
            if (Record.id === miscellaneousInfo.id) {
              return miscellaneousInfo;
            } else {
              return Record;
            }
          } else {
            if (
              Record.payeePayerBankruptcySequenceNumber ===
              miscellaneousInfo.payeePayerBankruptcySequenceNumber
            ) {
              return miscellaneousInfo;
            } else {
              return Record;
            }
          }
        });
      } else {
        miscellaneousTableDataNew = miscellaneousTableData;
        miscellaneousTableDataNew.push(miscellaneousInfo);
      }
      setMiscellaneousTableData(miscellaneousTableDataNew);
      miscellaneousTableFilter(miscellaneousTableDataNew);
      handleCloseMiscellaneous();
    }
  };

  const [openMiscellaneous, setOpenMiscellaneous] = React.useState(false);
  const handleBeginDateChange = date => {
    setFocusCheck(true)
    setAllowNavigation(true);
    setSelectedMiscellaneousBeginDate(date);
    setMiscellaneousDetails({
      ...MiscellaneousDetails,
      bankruptcyBeginDate: date
    });
  };

  useEffect(() => {
    if (openMiscellaneous && document.getElementById("form_pop_up")) {
      document.getElementById("form_pop_up").scrollIntoView();
    }
  }, [openMiscellaneous]);

  // Handle change for Miscellaneous end date
  const handleEndDateChange = date => {
    setFocusCheck(true)
    setAllowNavigation(true);
    setSelectedMiscellaneousEndDate(date);
    setMiscellaneousDetails({
      ...MiscellaneousDetails,
      bankruptcyEndDate: date
    });
  };

  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  // Open Dialog box
  const handleClickOpen = () => {
    setOpen(true);
  };
  // Close Dialog box
  const handleClose = () => {
    setFocusCheck(false);
    setAllowNavigation(false);
    setActiveMiscellaneousData(null);
    seterrorMessages([]);
    setOpenMiscellaneous(false);
  };

  // Close Miscellaneous on cancel
  const handleCloseMiscellaneous = () => {
    setOpenMiscellaneous(false);
  };

  const selectedBankTableRow = () => event => {
    // setOpenMiscellaneous(false);
  };
  const headCells = [
    {
      id: "bankruptcyTypeDesc",
      numeric: false,
      disablePadding: true,
      label: "Bankruptcy Type Code"
    },
    {
      id: "bankruptcyBeginDate",
      numeric: false,
      disablePadding: false,
      label: "Bankruptcy Begin Date",
      isDate: true
    },
    {
      id: "bankruptcyEndDate",
      numeric: false,
      disablePadding: false,
      label: "Bankruptcy End Date",
      isDate: true
    },
    {
      id: "claimAmount",
      numeric: false,
      disablePadding: false,
      label: "Claim Amount"
    },
    {
      id: "postedPetitionDebtAmount",
      numeric: false,
      disablePadding: false,
      label: "Posted Petition Debt Amount"
    },
    {
      id: "postedPetitionIndicator",
      numeric: false,
      disablePadding: false,
      label: "Posted Petition Indicator"
    }
  ];

  const editHeadCells = [
    {
      id: "bankruptcyTypeDesc",
      numeric: false,
      disablePadding: true,
      label: "Bankruptcy Type Code"
    },
    {
      id: "bankruptcyBeginDate",
      numeric: false,
      disablePadding: false,
      label: "Bankruptcy Begin Date",
      isDate: true
    },
    {
      id: "bankruptcyEndDate",
      numeric: false,
      disablePadding: false,
      label: "Bankruptcy End Date",
      isDate: true
    },
    {
      id: "claimAmount",
      numeric: false,
      disablePadding: false,
      label: "Claim Amount"
    },
    {
      id: "postedPetitionDebtAmount",
      numeric: false,
      disablePadding: false,
      label: "Posted Petition Debt Amount"
    },
    {
      id: "postedPetitionIndicator",
      numeric: false,
      disablePadding: false,
      label: "Posted Petition Indicator"
    }
  ];

  const formatDecimalForAmountClaimAmount = () => {
    if (MiscellaneousDetails.claimAmount !== "") {
      setMiscellaneousDetails({
        ...MiscellaneousDetails,
        claimAmount: numeral(MiscellaneousDetails.claimAmount).format("0,0.00")
      });
    }
  };

  const formatDecimalForAmountDebtAmount = () => {
    if (MiscellaneousDetails.postedPetitionDebtAmount !== "") {
      setMiscellaneousDetails({
        ...MiscellaneousDetails,
        postedPetitionDebtAmount: numeral(
          MiscellaneousDetails.postedPetitionDebtAmount
        ).format("0,0.00")
      });
    }
  };
  const removeFormatDecimalForAmountClaimAmount = () => {
    if (MiscellaneousDetails.claimAmount !== "") {
      setMiscellaneousDetails({
        ...MiscellaneousDetails,
        claimAmount: numeral(MiscellaneousDetails.claimAmount).format("0.00")
      });
    }
  };

  const removeFormatDecimalForAmountDebtAmount = () => {
    if (MiscellaneousDetails.postedPetitionDebtAmount !== "") {
      setMiscellaneousDetails({
        ...MiscellaneousDetails,
        postedPetitionDebtAmount: numeral(
          MiscellaneousDetails.postedPetitionDebtAmount
        ).format("0.00")
      });
    }
  };

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const bnkrptcyCode =
            props.dropdowns &&
            props.dropdowns['G1#F_BNKRPTCY_TY_CD'] &&
            props.dropdowns['G1#F_BNKRPTCY_TY_CD'].filter(
              value => value.code === each.bankruptcyType
            );
          each.bankruptcyTypeDesc =
          bnkrptcyCode && bnkrptcyCode.length > 0
              ? bnkrptcyCode[0].description
              : each.bankruptcyType ? each.bankruptcyType :  '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  return (
    <div>
      <fieldset className="r-custom-fieldset no-padding">
        <legend>Bankruptcy Details:</legend>
        <div className="tab-holder">
          <div className="mb-3" style={{ float: "right" }}>
            <Button
              className="btn-text-main-delete btn-transparent  ml-1"
              onClick={deleteSelectedAddressTableRow}
              disabled={disableFields}
              title={"Delete"}
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
              Delete
            </Button>
            <button
              class="btn-text-main-add btn-success  ml-1"
              onClick={handleClickOpenMiscellaneous}
              disabled={disableFields || !userInquiryPrivileges}
              title={"Add Miscellaneous Info"}
              data-testid="test_add_plus_btn"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
              Add
            </button>
          </div>
          <div className="clearfix"></div>
          <div className="table-no-wrap table-p-5">
            <TableComponent
              print={props.print}
              isSearch={false}
              headCells={showEditPage ? editHeadCells : headCells}
              tableData={getTableData(filteredTableData || [])}
              onTableRowClick={handleMiscellaneousTableAdd}
              onTableRowDelete={selectedAddressTableRow}
              defaultSortColumn={headCells[0].id}
              onTableCellButtonClick={props.onClickAuditDialogLog}
              uniqueKey={"payeePayerBankruptcySequenceNumber"}
              setClose={props.setClose}
              close={props.close}
            />
            {openMiscellaneous ? (
              <div className="tabs-container" id="form_pop_up">
                <div className="tab-header  mini-tab-header">
                  <div className="tab-heading float-left">
                    {editRow
                      ? "Edit Miscellaneous Info"
                      : "Add Miscellaneous Info"}
                  </div>
                  <div className="float-right mt-1">
                    <Button
                      className="btn btn-success ml-1"
                      onClick={handleSave}
                      disabled={disableFields || !userInquiryPrivileges}
                      title={editRow ? "Update" : "Add"}
                    >
                      <i class="fa fa-check" aria-hidden="true"></i>
                      {editRow ? "Update" : "Add"}
                    </Button>
                    <Button
                      className="bt-reset btn-transparent  ml-1"
                      onClick={handleReset}
                      disabled={disableFields || !userInquiryPrivileges}
                      title={'Reset'}
                      data-testid="test_reset_btn"
                    >
                      <i class="fa fa-undo" aria-hidden="true"></i>
                      Reset
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      className="btn btn-primary btn btn-outlined ml-1"
                      onClick={handleClose}
                      title={'Cancel'}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
                <AddMiscellaneous
                  disableFields={disableFields}
                  editRow={editRow}
                  showEditPage={showEditPage}
                  handleChangeMiscellaneous={handleChangeMiscellaneous}
                  handleBeginDateChange={handleBeginDateChange}
                  handleEndDateChange={handleEndDateChange}
                  values={MiscellaneousDetails}
                  SelectedMiscellaneousBeginDate={
                    SelectedMiscellaneousBeginDate
                  }
                  SelectedMiscellaneousEndDate={SelectedMiscellaneousEndDate}
                  showMiscellaneousErrorMsg={showMiscellaneousErrorMsg}
                  showMiscellaneousError={showMiscellaneousError}
                  formatDecimalForAmountClaimAmount={
                    formatDecimalForAmountClaimAmount
                  }
                  formatDecimalForAmountDebtAmount={
                    formatDecimalForAmountDebtAmount
                  }
                  removeFormatDecimalForAmountClaimAmount={
                    removeFormatDecimalForAmountClaimAmount
                  }
                  removeFormatDecimalForAmountDebtAmount={
                    removeFormatDecimalForAmountDebtAmount
                  }
                  BankruptcyTypeDropdown={BankruptcyTypeDropdown || []}
                  userInquiryPrivileges = {userInquiryPrivileges}
                />
              </div>
            ) : null}
            {showLogMiscellaneousTable ? (
              <div id="audit-Miscellaneous-table">
                <AuditTableComponent
                  print
                  auditRowData={auditRowData}
                  setAuditRowData={setAuditRowData}
                  setShowLogTable={setShowLogTable}
                />{" "}
              </div>
            ) : null}
          </div>
        </div>
      </fieldset>
    </div>
  );
}
