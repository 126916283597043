// eslint-disable-next-line no-unused-vars
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import moment from 'moment-timezone';

export const getDateInMMDDYYYYFormat = (enteredDate) => {
  if (enteredDate && enteredDate !== '' && enteredDate !== 'Invalid Date') {
    return moment(enteredDate).format('MM/DD/YYYY');
  }
  return null;
};

export const getDateInYYYYMMDDtoMMDDYYYY = (enteredDate) => {
  if (enteredDate && enteredDate !== '' && enteredDate !== 'Invalid Date' && typeof enteredDate === 'string') {
    return (enteredDate.substring(5, 7) + '/' + enteredDate.substring(8, 10) + '/' + enteredDate.substring(0, 4));
  } else if(typeof enteredDate !== 'string') {
    let dateEntered = new Date(enteredDate);
    dateEntered = dateEntered.toISOString();
    return (dateEntered.substring(5, 7) + '/' + dateEntered.substring(8, 10) + '/' + dateEntered.substring(0, 4));
  }
  return null;
};

export const getDateInMMDDYYYYFormatWithApendZero = (enteredDate) => {
  if (enteredDate && enteredDate !== '' && enteredDate !== 'Invalid Date') {
      return moment(enteredDate).format('MM/DD/YYYY');
  }
  return null;
};

export const getDateInYYYYMMDDFormatWithApendZero = (enteredDate) => {
  if (enteredDate && enteredDate !== '' && enteredDate !== 'Invalid Date') {
    const tempDate = new Date(enteredDate);
    let date = null;
    let month = null;
    if ((tempDate.getMonth() + 1) <= 9) {
      month = '0' + (tempDate.getMonth() + 1).toString();
    } else {
      month = (tempDate.getMonth() + 1).toString();
    }
    if (tempDate.getDate() <= 9) {
      date = '0' + (tempDate.getDate()).toString();
    } else {
      date = tempDate.getDate().toString();
    }
    return (tempDate.getFullYear().toString() + '-' + month + '-' + date);
  }
  return null;
};
export const dateSubString = (enteredDate) => {
  if (enteredDate) {
    return enteredDate.substring(0, 10);
  }
}
export const validateDateMinimumValueForSP = date => {
  let _date;
  if (typeof (date) === 'string') {
    _date = date.toString().substring(0, 4);
  } else if (date) {
    _date = getDateInYYYYMMDDFormatWithApendZero(date).toString().substring(0, 4);
  }
  if (_date && parseInt(_date) < 1964) {
    return true;
  } else {
    return false;
  }
};

export const validateDateMinimumValue = date => {
  let _date;
  if (typeof (date) === 'string') {
    _date = date.toString().substring(6, 10);
  } else if (date) {
    _date = getDateInMMDDYYYYFormat(date).toString().substring(6, 10);
  }
  if (_date && parseInt(_date) < 1964) {
    return true;
  } else {
    return false;
  }
};

/* This function is used to convert datetime to timestamp format */
export const setSelectedDate = date => {
  var selectedDate = new Date(null);

  if (((date.getMonth() + 1) < 10 && (date.getMonth() + 1) > 0) && ((date.getDate()) < 10 && (date.getDate()) > 0)) {
    selectedDate = '0' + (date.getMonth() + 1) + '/0' + date.getDate() + '/' + date.getFullYear();
  } else if ((date.getMonth() + 1) < 10 && (date.getMonth() + 1) > 0) {
    selectedDate = '0' + (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
  } else if ((date.getDate()) < 10 && (date.getDate()) > 0) {
    selectedDate = (date.getMonth() + 1) + '/0' + date.getDate() + '/' + date.getFullYear();
  } else {
    selectedDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
  }

  return selectedDate;
};

export const generateUUID = () => {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
};

export const getDifferenceInDays = (startDate, endDate) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds Y-M-D
  const firstDate = new Date(startDate);
  const secondDate = new Date(endDate);

  return (Math.abs((secondDate - firstDate) / oneDay));
};

// this function takes an array of date ranges in this format:
// [{ start: Date, end: Date}]
// the array is first sorted, and then checked for any overlap

export const overlapCheckWithDateRange = (dateRanges) => {
  var sortedRanges = dateRanges.sort((previous, current) => {
    // get the start date from previous and current
    var previousTime = previous.start.getTime();
    var currentTime = current.start.getTime();

    // if the previous is earlier than the current
    if (previousTime < currentTime) {
      return -1;
    }

    // if the previous time is the same as the current time
    if (previousTime === currentTime) {
      return 0;
    }

    // if the previous time is later than the current time
    return 1;
  });

  var result = sortedRanges.reduce((result, current, idx, arr) => {
    // get the previous range
    if (idx === 0) { return result; }
    var previous = arr[idx - 1];

    // check for any overlap
    var previousEnd = previous.end.getTime();
    var currentStart = current.start.getTime();
    var overlap = (previousEnd >= currentStart);

    // store the result
    if (overlap) {
      // yes, there is overlap
      result.overlap = true;
      // store the specific ranges that overlap
      result.ranges.push({
        previous: previous,
        current: current
      });
    }

    return result;

    // seed the reduce
  }, { overlap: false, ranges: [] });
  // return the final results
  return result;
};
export const convertDateTimeToTimestamp = (dateTimeValue) => {
  var dateTimestamp = Date.parse(dateTimeValue);
  return dateTimestamp / 1000;
};

/* This function is used to compare two dates
 * Returns false if date1 is greater than date2
 * Return true if date1 is less than or equals to date2
*/
export const compareTwoDates = (date1, date2) => {
  date1 = new Date(date1);
  date2 = new Date(date2);
  var dateone = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(), 0, 0, 0);
  var datetwo = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate(), 0, 0, 0);
  if (dateone > datetwo) {
    return false;
  } else {
    return true;
  }
};

export const compareTwoDatesGreaterThanOrEqual = (date1, date2) => {
  date1 = new Date(date1);
  date2 = new Date(date2);
  var dateone = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(), 0, 0, 0);
  var datetwo = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate(), 0, 0, 0);
  if (dateone >= datetwo) {
    return false;
  } else {
    return true;
  }
};

/* This function is used to validate date string
 * Returns false if month, day or year is not a valid one
*/
export const checkForValidDateString = (dateString) => {
  // First checking for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) { return false; }

  // Parsing the date parts to integers
  var parts = dateString.split('/');
  var day = parseInt(parts[1], 10);
  var month = parseInt(parts[0], 10);
  var year = parseInt(parts[2], 10);

  // Checking the ranges of month and year
  if (year > 9999 || month === 0 || month > 12) {
    return false;
  }

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Checking the range of the day
  return day > 0 && day <= monthLength[month - 1];
};
/* This function returns UTC timestamp for auditTimeStamp and addedAuditTimeStamp
*/
export const getUTCTimeStamp = () => {
  return moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
};

export const setDateTimeZoneFormat = (date) => {
  if (!date || new Date() === "Invalid Date") {
    date = new Date();
  }
  return moment
    .tz(date, process.env.REACT_APP_TIMEZONE)
    .format(process.env.REACT_APP_DATE_TIME_FORMAT);
};

// current year
export const currentYear = () => {
  return moment().format('YYYY');
}

const historyTableDayGap = (sourceHistoryData) => {
  sourceHistoryData =[...sourceHistoryData];
  sourceHistoryData = sourceHistoryData.sort((a,b)=>new Date(a.beginDate) - new Date(b.beginDate));
  const lastRecIndex = sourceHistoryData.length - 1;
  const fhistoryBeginDate = moment(new Date(sourceHistoryData[0]?.beginDate));
  const historyEndDate = moment(
    new Date(sourceHistoryData[lastRecIndex]?.endDate)
  );
  var historyDuration = moment.duration(
    historyEndDate.diff(fhistoryBeginDate)
  );
  let historyDurationdaysLast = Math.floor(historyDuration.asDays())+1;
  return historyDurationdaysLast;
}

export const handelDateRangeGapHeaderCI = (
  headerStartDate,
  headerEndDate,
  sortedInputArrayCI,
  sourceHistoryData = []
) => {
 let dayGapWithHistoryTable = historyTableDayGap(sourceHistoryData);
  sortedInputArrayCI = [...sortedInputArrayCI];
  sortedInputArrayCI = sortedInputArrayCI.sort(
    (a, b) => new Date(a.beginDate) - new Date(b.beginDate)
  );
  const lastRecIndex = sortedInputArrayCI.length - 1;
  const firstCIDate = sortedInputArrayCI[0].beginDate;

  const lastCIDate = sortedInputArrayCI[lastRecIndex].endDate;
  const firstCIBeginDate = moment(new Date(sortedInputArrayCI[0].beginDate));
  const lastCIEndDate = moment(
    new Date(sortedInputArrayCI[lastRecIndex].endDate)
  );
  
  var headerStartDateM = moment(new Date(headerStartDate));
  var headerEndDateM = moment(new Date(headerEndDate));
  var durationFirst = moment.duration(
    firstCIBeginDate.diff(headerStartDateM)
  );
  var durationLast = moment.duration(headerEndDateM.diff(lastCIEndDate));
  let checkValid =
    new Date(headerStartDate) <= new Date(firstCIDate) &&
    new Date(firstCIDate) <= new Date(lastCIDate) &&
    new Date(lastCIDate) >= new Date(headerStartDate) &&
    new Date(lastCIDate) <= new Date(headerEndDate);
  var daysFirst = Math.floor(durationFirst.asDays());
  var daysLast = Math.floor(durationLast.asDays());
  if(sourceHistoryData?.length > 0 ){
    var totalDaysWithOutHistory = daysFirst + daysLast;
    var finalDays = totalDaysWithOutHistory - dayGapWithHistoryTable;
    var historyFirstDay = dayGapWithHistoryTable - daysFirst;
    var historyLastDay = dayGapWithHistoryTable - daysLast;
    if(finalDays > 0 ){
      return true
    }
    else {
      return false;
    }
  }
  if (daysFirst > 0 || daysLast > 0 || !checkValid) {
    return true;
  } else {
    return false;
  }
};


const handelDateGaps = (sortedInputArray, index) => {
  let sortedInputArrayCI = sortedInputArray.sort(
    (a, b) => new Date(a.beginDate) - new Date(b.beginDate)
  );
  if (sortedInputArrayCI.length > index + 1) {
    var secondStartDate = moment(
      new Date(sortedInputArrayCI[index + 1].beginDate)
    );
    var firstEndDate = moment(new Date(sortedInputArrayCI[index].endDate));
    var duration = moment.duration(secondStartDate.diff(firstEndDate));
    var days = Math.floor(duration.asDays());
    if (days > 1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

 export const handelDateRangeGap = sortedInputArray => {
  if (sortedInputArray.length > 0) {
    const result = sortedInputArray.map((each, index) =>
      handelDateGaps(sortedInputArray, index)
    );
    if (result.filter(e => e === true).length > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};