/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { memo } from 'react';
import './Spinner.scss';

const Spinner = memo(function spinner ({ on }) {
  return (
    <div>
      {on && (
        <div className="loading-spinner hide-on-print ">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
});

export default Spinner;
