/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './ActionTypes';
import * as serviceEndPoint from '../../../SharedModules/services/service';
import axios from 'axios';
import { commonAPIHandler } from '../../../SharedModules/ServerErrorHandler/index';
const headers = {
    'Access-Control-Allow-Origin': '*'
};

export const ResetFRC = (keyName) => {
    return {
        type: actionTypes.RESET_FRC,
        keyName: keyName
    }
}
export const getSubDropdownDataAction = (value) => {
    return dispatch => {
        return axios.post(`${serviceEndPoint.VALID_VALUE_DROPDOWNS}`, value)
            .then(response => {
                dispatch(setSubDropdownData(response.data));
            })
            .catch(error => {
                dispatch(setSubDropdownData(error));
            });
    };
};

export const setSubDropdownData = (subdropdownData) => {
    return {
        type: actionTypes.SUB_DROPDOWNDATA,
        subdropdownData: subdropdownData
    };
};

export const searchFRCAction = (value) => {
    return dispatch => {
        return axios.post(`${serviceEndPoint.SEARCH_FRC}`, value)
            .then(response => {
                let sendingResponse = commonAPIHandler(response, true);
                dispatch(setSearchData(sendingResponse));
            })
            .catch(error => {
                let sendingResponse = commonAPIHandler(error);
                dispatch(setSearchData(sendingResponse));
            });
    };
};

export const setSearchData = (searchData) => {
    return {
        type: actionTypes.SEARCH_FRC,
        searchPayload: searchData
    }
}

export const createFRCAction = (value) => {
    return dispatch => {
        return axios.post(`${serviceEndPoint.ADD_FRC}`, value)
            .then(response => {
                let sendingResponse = commonAPIHandler(response);
                dispatch(setAddresponse(sendingResponse));
            })
            .catch(error => {
                let sendingResponse = commonAPIHandler(error);
                dispatch(setAddresponse({ systemFailure: true }));
            });
    };
};

export const setAddresponse = (addResponse) => {
    return {
        type: actionTypes.CREATE_FRC,
        createPayload: addResponse
    }
}

export const updateFRCAction = (value) => {
    return dispatch => {
        return axios.post(`${serviceEndPoint.UPDATE_FRC}`, value)
            .then(response => {
                let sendingResponse = commonAPIHandler(response);
                dispatch(setUpdateresponse(sendingResponse));
            })
            .catch(error => {
                let sendingResponse = commonAPIHandler(error);
                dispatch(setUpdateresponse({ systemFailure: true }));
            });
    };
};

export const setUpdateresponse = (updateResponse) => {
    return {
        type: actionTypes.UPDATE_FRC,
        updatePayload: updateResponse
    }
}