/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as AppConstants from '../../../SharedModules/AppConstants';
import { redirectGrossLevelFCN } from '../../../SharedModules/TabRedirections/TabRedirections';
import { LinkOfFcn, LinkOfTcn } from '../../../SharedModules/TabRedirections/customInputLink';
import { getLongDescription } from '../../../SharedModules/CommonUtilities/commonUtilities';
import { Link } from 'react-router-dom';
import * as Constants from './WriteOffContants';
import '../WriteOff.scss';
import NativeDropDown from '../../../SharedModules/Dropdowns/NativeDropDown';

export default function WriteOffTab(props) {
  return (
    <form autoComplete="off">
      <div className="form-wrapper">
        <div className={props.saveFlag ? "mui-custom-form with-link has-fcn-link" : "mui-custom-form with-link"}>
          <TextField
            id="fcn-code"
            fullWidth
            label="FCN"
            InputProps={{
              readOnly: true, className: 'Mui-disabled', inputComponent: LinkOfFcn,
              inputProps: { value: props.values.fcn, saveFlag: props.saveFlag, redirection: redirectGrossLevelFCN }
            }}
            InputLabelProps={{
              shrink: true
            }}
          ></TextField>
        </div>
        <div className="mui-custom-form with-link has-link">
          <TextField
            id="related-ft-tcn"
            fullWidth
            label="Related FT TCN"
            InputProps={{
              readOnly: true, className: 'Mui-disabled',
              inputComponent: LinkOfTcn,
              inputProps: { value: props.values.relatedFtTcn, saveFlag: props.saveFlag }
            }}

            InputLabelProps={{
              shrink: true
            }}
          ></TextField>
        </div>
        <div className="mui-custom-form">
          <TextField
            id="activity-type"
            fullWidth
            label="Activity Type"
            value={props.values.activityTypeCodeDesc}
            InputProps={{ readOnly: true, className: 'Mui-disabled' }}
            InputLabelProps={{
              shrink: true
            }}
          ></TextField>
        </div>
        <NativeDropDown
            id="reason-code"
          isRequired
            label="Reason Code"
            inputProps={{ maxlength: 3 }}
            value={props.values.reasonCode}
            onChange={props.handleChange('reasonCode')}
          errTxt={props.errors[0] ? Constants.REASON_CODE_REQUIRED : null}
            title={getLongDescription(props.reasonCodeList, props.values.reasonCode)}
          dropdownList={props.reasonCodeList.map((item, index) => (
            <option key={index} value={item.code}>
                {item.description}
              </option>
            ))}
        />
      </div>
      <div className="form-wrapper">
        <div className="mui-custom-form">
          <TextField
            id="established-date"
            fullWidth
            label="Established Date"
            value={props.values.establishedDate}
            disabled={true}
            InputProps={{ readOnly: true, className: 'Mui-disabled' }}
            InputLabelProps={{
              shrink: true
            }}
          ></TextField>
        </div>
        <div className="mui-custom-form">
          <TextField
            id="original-ar"
            fullWidth
            label="Original A/R Amount"
            value={props.values.originalArAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              readOnly: true,
              className: 'Mui-disabled'
            }}
            InputLabelProps={{
              shrink: true
            }}
          ></TextField>
        </div>

        <div className="mui-custom-form">
          <TextField
            id="balance-amt"
            fullWidth
            label="Balance Amount"
            value={props.values.balanceAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              readOnly: true,
              className: 'Mui-disabled'
            }}
            InputLabelProps={{
              shrink: true
            }}
          ></TextField>
        </div>
        <div className="mui-custom-form">
          <TextField
            id="penality"
            fullWidth
            label="Penalty"
            value={props.values.penalty}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              readOnly: true,
              className: 'Mui-disabled'
            }}
            InputLabelProps={{
              shrink: true
            }}
          ></TextField>
        </div>
      </div>
      <div className="form-wrapper">
        <div className="mui-custom-form">
          <TextField
            id="interest"
            fullWidth
            label="Interest"
            value={props.values.interest}
            InputProps={{ readOnly: true, className: 'Mui-disabled' }}
            InputLabelProps={{
              shrink: true
            }}
          ></TextField>
        </div>
        <div className="mui-custom-form with-select">
          <TextField
            id="fees"
            fullWidth
            label="Fees"
            value={props.values.fees}
            InputProps={{ readOnly: true, className: 'Mui-disabled' }}
            InputLabelProps={{
              shrink: true
            }}
          ></TextField>
        </div>
        <div className="mui-custom-form">
          <TextField
            id="adjusted-original-amt"
            fullWidth
            label="Adjusted A/R Amount"
            value={props.values.adjustedOriginalAmount}
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              readOnly: true,
              className: 'Mui-disabled'
            }}
            InputLabelProps={{
              shrink: true
            }}
          ></TextField>
        </div>

        <div className="mui-custom-form">
          <TextField
            id="ARLetterSent"
            fullWidth
            label="A/R Letter Sent"
            value={props.values.arLetterSent}
            InputProps={{ readOnly: true, className: 'Mui-disabled' }}
            InputLabelProps={{
              shrink: true
            }}
          ></TextField>
        </div>
      </div>
      <div className="form-wrapper">
        <div className="mui-custom-form">
          <TextField
            id='ATRnumber'
            fullWidth
            label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
            placeholder=""
            value={props.values.atrNumber}
            onChange={props.handleChange('atrNumber')}
            inputProps={{ maxlength: 18 }}
            InputLabelProps={{
              shrink: true
            }}
            helperText={props.errors[1] ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${Constants.TRANSACTION_NUMBER_FORMAT}` : null}
            error={props.errors[1] ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${Constants.TRANSACTION_NUMBER_FORMAT}` : null}
          ></TextField>
        </div>
      </div>
    </form>
  );
}
