import React,{useState,useEffect} from 'react';
import { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import dateFnsFormat from 'date-fns/format';
import * as ErrorConst from "../../../../SharedModules/Errors/ErrorMsgConstant";
import { NativeSelect, InputLabel, FormHelperText } from '@material-ui/core';
import { PLEASE_SELECT_ONE } from '../../../../SharedModules/AppConstants.js';

const beginDateHelperText = (props) =>{
    return props.errors.beginDateErrEvent ?
    ErrorConst.Begin_Date_Error:props.errors.beginDateInvalidErrEvent?ErrorConst.Invalid_Begin_Date_Error : null
}
const beginDateErrorText = (props)=> {
    return props.errors.beginDateErrEvent ? ErrorConst.Begin_Date_Error:props.errors.beginDateInvalidErrEvent?ErrorConst.Invalid_Begin_Date_Error : null
}
const endDateHelperText = (props) =>{
    return props.errors.endDateErrEvent ?
    ErrorConst.End_Date_Error:props.errors.endDateInvalidErrEvent?ErrorConst.Invalid_End_Date_Error :
     props.errors.beginDtGtEndDtErrEvent?ErrorConst.EVENT_GT_DT_ERROR:null
}
const endDateErrorText = (props)=>{
    return props.errors.endDateErrEvent ?
    ErrorConst.End_Date_Error:props.errors.endDateInvalidErrEvent?ErrorConst.Invalid_End_Date_Error : props.errors.beginDtGtEndDtErrEvent?ErrorConst.EVENT_GT_DT_ERROR:null
}
const eventTypeHelperText = (props) => {
    return props.errors.showMemberSearch ? ErrorConst.SEARCH_BY : null
}
const eventTypeErrorText = (props) =>{
    return props.errors.showMemberSearch ? ErrorConst.SEARCH_BY : null
}
const formatDate = dt => {
    if (!dt) {
      return "";
    }
    dt = new Date(dt)
    if (dt.toString() == 'Invalid Date') {
      return dt
    } else {
      return dateFnsFormat(dt, 'MM/dd/yyyy')
    }
  }
function EventSearchForm(props) {
    const [, setSelectedToDate] = useState('');
    const [, setSelectedFromDate] = useState('');
    const handleBeginDateChange = date => {props.handleDCDtChangeEvent('beginDate', formatDate(date));
    };
const handleEndDateChange = date => {props.handleDCDtChangeEvent('endDate', formatDate(date));};
useEffect(() => {
    props.eventValues.beginDate !== '' ? setSelectedFromDate(props.eventValues.beginDate) : setSelectedFromDate('');
}, []);
useEffect(() => {
     props.eventValues.endDate !== '' ? setSelectedToDate(props.eventValues.endDate) : setSelectedToDate('');
}, []);
    return (
        <div>
            <form autoComplete="off">
                <div className="tab-body mt-4">
                    <div className="form-wrapper p-0">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div className="mui-custom-form input-md with-select">
                                <KeyboardDatePicker
                                    id="begin_date"
                                    label="Begin Date"
                                    format="MM/dd/yyyy"
                                    InputLabelProps={{
                                        shrink: true,
                                        required: true
                                    }}
                                    placeholder="mm/dd/yyyy"
                                    value={props.eventValues.beginDate?props.eventValues.beginDate:null}
                                    onChange={handleBeginDateChange}
                                    helperText={beginDateHelperText(props)}
                                    error={beginDateErrorText(props)}
                                    KeyboardButtonProps={{"aria-label": "change date"}}
                                />
                                </div>
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div className="mui-custom-form input-md with-select">
                                <KeyboardDatePicker
                                    id="end_date"
                                    label="End Date"
                                    format="MM/dd/yyyy"
                                    InputLabelProps={{shrink: true,required: true}}
                                    placeholder="mm/dd/yyyy"
                                    value={props.eventValues.endDate?props.eventValues.endDate:null}
                                    onChange={handleEndDateChange}
                                    helperText={endDateHelperText(props)}
                                    error={endDateErrorText(props)}
                                    KeyboardButtonProps={{"aria-label": "change date"}}
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                        <div className="mui-custom-form input-md with-select">
                            <TextField
                                id="beginTime"
                                label="Begin Time"
                                format="HH:MM:SS"
                                InputLabelProps={{shrink: true}}
                                inputProps={{ maxLength: 12 }}
                                value={props.eventValues.beginTime}
                                onChange={props.handleChanges('beginTime')}
                                helperText={props.errors.beginTimeErrEvent ?
                                    ErrorConst.BEGIN_TIME_INVALID : null}
                                error={props.errors.beginTimeErrEvent ?
                                    ErrorConst.BEGIN_TIME_INVALID : null}
                                KeyboardButtonProps={{"aria-label": "change date"}}
                            />
                            <div className="mt-2 ml-2 txt-time-format">HH:MM:SS PM</div>
                        </div>
                        <div className="mui-custom-form input-md with-select">
                            <TextField
                                id="endTime"
                                label="End Time"
                                format="HH:MM:SS"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                inputProps={{ maxLength: 12 }}
                                value={props.eventValues.endTime}
                                onChange={props.handleChanges('endTime')}
                                KeyboardButtonProps={{"aria-label": "change date"}}
                                helperText={props.errors.endTimeErrEvent ?
                                    ErrorConst.END_TIME_INVALID : null}
                                error={props.errors.endTimeErrEvent ?
                                ErrorConst.END_TIME_INVALID : null}/>
                            <div className="mt-2 ml-2 txt-time-format">HH:MM:SS PM</div>
                        </div>
                        <div class="line-break-div"></div>
                        <div className="mui-custom-form with-select input-md">
                            <TextField
                                id="userID"
                                label="User ID"
                                value={props.eventValues.userID}
                                inputProps={{ maxLength: 30 }}
                                onChange={props.handleChanges('userID')}
                                placeholder=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        <div className="mui-custom-form with-select input-md">
                            <TextField
                                id="userLastName"
                                label="User Last Name"
                                value={props.eventValues.userLastName}
                                inputProps={{ maxLength: 35 }}
                                onChange={props.handleChanges('userLastName')}
                                placeholder=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={props.errors.userIdNameErrEvent ?ErrorConst.USER_ID_NAME_ERR  : null}
                                error={props.errors.userIdNameErrEvent ?ErrorConst.USER_ID_NAME_ERR  : null}/>
                        </div>    
                        <div class="line-break-div"></div>
                        <div className="mui-custom-form with-select input-md custom-input-select pos-rel">
                            <InputLabel className="Event Type" htmlFor="eventType">
                                Event Type
                            </InputLabel>
                            <NativeSelect
                                id="eventType"
                                inputProps={{ maxLength: 11 }}
                                value={props.eventValues.eventType}
                                onChange={props.handleChanges('eventType')}
                                InputLabelProps={{shrink: true}}
                            >
                                {<option selected key={process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE} value={process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE}>{PLEASE_SELECT_ONE}</option>}
                                {props.dropdowns
                                && props.dropdowns['R_EVENT_LOG_MSG_TB#G_EVENT_LOG_TY_CD'] &&
                                 props.dropdowns['R_EVENT_LOG_MSG_TB#G_EVENT_LOG_TY_CD'].map((option, index) => (
                                     <option key={index} value={option.code}>
                                {option.description}
                                     </option>
                                      ))}
                            </NativeSelect>
                            <FormHelperText className="required-label">{eventTypeHelperText(props)}</FormHelperText>
                        </div>
                        <div className="mui-custom-form with-select input-md">
                            <TextField
                                id="eventCode"
                                label="Event Code"
                                value={props.eventValues.eventCode} inputProps={{ maxLength: 9 }}
                                onChange={props.handleChanges('eventCode')}
                                placeholder=""
                                InputLabelProps={{shrink: true}}
                                />
                        </div>
                        <div className="mui-custom-form with-select input-md">
                            <TextField
                                id="message"
                                label="Message"
                                value={props.eventValues.message}
                                inputProps={{ maxLength: 40 }}
                                onChange={props.handleChanges('message')}
                                placeholder=""
                                InputLabelProps={{shrink: true}}
                                />
                        </div>
                    </div>
                </div>
                <div className="tab-footer">
                    <div className="float-right th-btnGroup mr-2 mb-2">
                        <Button
                            title="Search"
                            variant="outlined"
                            color="primary"
                            className="btn btn-ic btn-search"
                            onClick={props.eventSearchCheck}
                            disabled={
                                props.privileges && !props.privileges.search ? "disabled" : ""
                            }
                        >
                            Search{" "}
                        </Button>
                        <Button
                            title="Reset"
                            variant="outlined"
                            color="primary"
                            className="btn btn-ic btn-reset"
                            onClick={props.resetTable}
                        >
                            Reset{" "}
                        </Button>
                    </div>
                </div>
                <div className="clearfix" />
            </form>
        </div>
        );
    }
export default EventSearchForm;
