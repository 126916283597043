import * as actionTypes from '../../../SharedModules/store/Actions/ActionTypes';

const axiosPayLoad = {
  changePassword: null,
  searchByNameFields: null,
  inquirySearchTime: new Date,
};
const reducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_PASSWORD:
      return { ...state, changePassword: action.searchData,searchByNameFields:action.fieldsValue, inquirySearchTime: new Date()};
      case actionTypes.RESET_DATA:
          return axiosPayLoad;
    default: return state;
  }
};

export default reducer;
