/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const SEARCH_CORRECTION_1099 = 'SEARCH_CORRECTION_1099';
export const CORRECTION_1099_SEARCH_RESULTS_RESET = 'CORRECTION_1099_SEARCH_RESULTS_RESET';
export const SEARCH_CORRECTION_APPROVAL = 'SEARCH_CORRECTION_APPROVAL';
export const CORRECTION_DETAIL_UPDATE_ACTION = 'CORRECTION_DETAIL_UPDATE_ACTION';
export const CORRECTION_DROPDOWNS_ACTION = 'CORRECTION_DROPDOWNS_ACTION';
export const CORRECTION_DETAIL_LIST_ACTION = 'CORRECTION_DETAIL_LIST_ACTION';
export const CORRECTION_DETAIL_VIEW_ACTION = 'CORRECTION_DETAIL_VIEW_ACTION';
export const CORRECTION_APPROVAL_UPDATE_ACTION = 'CORRECTION_APPROVAL_UPDATE_ACTION';
export const AUDIT_LOG_TABLE = "AUDIT_LOG_TABLE";
export const CORRECTION_APPROVAL_VALIDATE_ACTION = 'CORRECTION_APPROVAL_VALIDATE_ACTION';
