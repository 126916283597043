import { GET_VOID_RSCD_DROPDOWNS } from "./actions";

export const RESETDATA = "RESETDATA";
export const MEDICARE_DETAILS_RESETDATA = "MEDICARE_DETAILS_RESETDATA";
export const EVENT_LOG_INQUIRY ="EVENT_LOG_INQUIRY";
export const EVENT_LOG_EVENT ="EVENT_LOG_EVENT";
export const EVENT_TYPE = {
    functionalArea: 'R_EVENT_LOG_MSG_TB',
    dataElementName: 'G_EVENT_LOG_TY_CD',
    businessName: null,
    valueShortDescription: null,
    crossReferenceColumnName: null,
    crossReferenceTableName: null,
    dataEleNameStartsOrContains: null,
    busNameStartsOrContains: null,
    code: null,
  };
export const GET_APP_DROPDOWNS = 'GET_APP_DROPDOWNS_ACTION_TYPE';
export const GET_VALIDVALUE_DROPDOWNS = 'GET_VALIDVALUE_DROPDOWNS';
export const GET_VOID_RSCD = 'GET_VOID_RSCD';
