/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import numeral from 'numeral';
import AuditTableComponent from '../../../../SharedModules/AuditLog/AuditTableComponent'


export default function CorrectionView(props) {
  const reasonCode =
            props.dropdowns &&
            props.dropdowns['F1#F_RSN_CD'] &&
            props.dropdowns['F1#F_RSN_CD'].filter(
              value => value.code === props.correctionSearchViewData.reasonCode?.split('-')[0]
            );
  props.correctionSearchViewData.reasonCode = reasonCode && reasonCode.length > 0
              ? reasonCode[0].description : '';
  return (
    <div>
      <form autoComplete="off" style={ { paddingLeft: '1%' } }>
        <div className="form-wrapper">
          <div className="mui-custom-form  MuiTextField-root override-width-22 override-m-08">
            <TextField
              id="standard-original1099amount"
              label="Original 1099 Amount"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              value={ numeral(
                props.correctionSearchViewData.original1099Amount
              ).format('$0,0.00') }
              handleChange={ props.handleChangeSearchDetails(
                'original1099Amount'
              ) }
              placeholder=""
              inputProps={ { maxlength: 14 } }
              InputLabelProps={ {
                shrink: true
              } }
            ></TextField>
          </div>
          <div className="mui-custom-form MuiTextField-root override-width-22 override-m-08">
            <TextField
              id="standard-adjusted-amount"
              label="Adjusted 1099 Amount"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              value={ numeral(
                props.correctionSearchViewData.adjusted1099Amount
              ).format('$0,0.00') }
              handleChange={ props.handleChangeSearchDetails(
                'adjusted1099Amount'
              ) }
              placeholder=""
              inputProps={ { maxlength: 13 } }
              InputLabelProps={ {
                shrink: true
              } }
            ></TextField>
          </div>
          <div className="mui-custom-form MuiTextField-root override-width-25 override-m-08">
            <TextField
              id="standard-reason-code"
              label="Reason Code"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              value={ props.correctionSearchViewData.reasonCode }
              handleChange={ props.handleChangeSearchDetails('reasonCode') }
              placeholder=""
              inputProps={ { maxlength: 11 } }
              InputLabelProps={ {
                shrink: true
              } }
              title={ props.correctionSearchViewData.reasonCodeTitle }
            ></TextField>
          </div>
          <MuiPickersUtilsProvider utils={ DateFnsUtils }>
            <div className="mui-custom-form with-date MuiTextField-root override-width-22 override-m-08">
              <KeyboardDatePicker
                maxDate={ Date.parse('31 Dec 9999 00:00:00 GMT') }
                id="date-picker-dialog"
                disabled
                InputProps={ { readOnly: true, className: 'Mui-disabled' } }
                label="Update Date"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                value={ props.correctionSearchViewData.updatedDate }
                handleChange={ props.handleChangeSearchDetails('updatedDate') }
                inputProps={ { maxlength: 10 } }
                InputLabelProps={ {
                  shrink: true
                } }
                KeyboardButtonProps={ {
                  'aria-label': 'change date'
                } }
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form  override-width-22 override-m-08">
            <TextField
              id="standard-approval-indicator"
              label="1099 Approval Indicator"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              value={ props.correctionSearchViewData.a1099Approval }
              handleChange={ props.handleChangeSearchDetails('a1099Approval') }
              placeholder=""
              inputProps={ { maxlength: 17 } }
              InputLabelProps={ {
                shrink: true
              } }
            ></TextField>
          </div>
          <div className="mui-custom-form  override-width-22 override-m-08">
            <TextField
              id="standard-correction-indicator"
              label="1099 Correction Indicator"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              value={ props.correctionSearchViewData.c1099Correction }
              handleChange={ props.handleChangeSearchDetails('c1099Correction') }
              placeholder=""
              inputProps={ { maxlength: 17 } }
              InputLabelProps={ {
                shrink: true
              } }
            ></TextField>
          </div>
          <div className="mui-custom-form  override-width-22 override-m-08">
            <TextField
              id="standard-void-indicator"
              label="Void Indicator"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              value={ props.correctionSearchViewData.void1 }
              handleChange={ props.handleChangeSearchDetails('void1') }
              placeholder=""
              inputProps={ { maxlength: 17 } }
              InputLabelProps={ {
                shrink: true
              } }
            ></TextField>
          </div>
        </div>
      </form>
      {props.showLogTable ? (
                  <div id="audit-table">
                    <AuditTableComponent
                      auditRowData={props.auditData ? props.auditData : []}
                    />
                  </div>
                ) : null}
    </div>
  );
}
