/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/PaymentStatusInquiryActionTypes';
import * as serviceEndPoint from '../../../../SharedModules/services/service';
import axios from 'axios';
import { commonAPIHandler } from '../../../../SharedModules/ServerErrorHandler/index';

export const PaymentStatusInquirySearchAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.PAYMENT_STATUS_INQUIRY}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse) {

          dispatch(displayPaymentSearchResults(sendingResponse));
        } else {
          dispatch(displayPaymentSearchResults([]));
        };
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(displayPaymentSearchResults(sendingResponse));
      });
  };
};

export const displayPaymentSearchResults = (searchData) => {
  return {
    type: actionTypes.PAYMENT_STATUS_INQUIRY_SEARCH,
    searchData: searchData
  };
};


export const resetState = () => {
  return {
    type: actionTypes.SEARCH_RESULTS_RESET,
    resetData: []
  };
};

export const modifyStartWithOrContains = (selectedValue) => {
  if (selectedValue === 'Starts With') {
    return "0"; }
  if (selectedValue === 'Contains') {
    return "1"; }
  return null;
};


export const getAuditLogDetailsAction = (tableName, keyValue, auditData) => {
  let body = {
    "tableName": tableName,
    "keyValue": {...keyValue}
  }
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}

export const setAuditLogData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE,
    payload: data,
    keyName
  };
};