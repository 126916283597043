/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './systemListActionTypes';
import * as serviceEndPoint from '../../../services/service';
import axios from 'axios';
import { commonAPIHandler } from '../../../../../SharedModules/ServerErrorHandler';

export const getAuditLogDetailsAction = (tableName, keyValue, auditData) => {
  let body = {
    "tableName": tableName,
    "keyValue": keyValue
  }
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}

export const setAuditLogData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE,
    payload: data,
    keyName
  };
};

export const getAuditLogRowDetailsAction = (tableName, keyValue, auditData) => {
  let body = {
    "tableName": tableName,
    "keyValue": keyValue
  }
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogRowData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}

export const setAuditLogRowData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE_ROW,
    payload: data,
    keyName
  };
};
export const resetSystemList = () => {
  return {
    type: actionTypes.RESET_DATA,
    resetData: []
  };
};

export const systemListActions = (value, dynamicType) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SYSTEM_LIST_SEARCH_ENDPOINT}`, value)
      .then(response => {
        let sendingRes = commonAPIHandler(response, true);
        dispatch(setSystemList(sendingRes, dynamicType));
      })
      .catch(error => {

        let sendingRes = commonAPIHandler(error);
        dispatch(setSystemList(sendingRes, dynamicType));
      });
  };
};
export const systemListActions1021 = (value, dynamicType) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SYSTEM_LIST_SEARCH_ENDPOINT}`, value)
      .then(response => {
        let sendingRes = commonAPIHandler(response, true);
        dispatch(setSystemList1021(sendingRes, dynamicType));
      })
      .catch(error => {

        let sendingRes = commonAPIHandler(error);
        dispatch(setSystemList1021(sendingRes, dynamicType));
      });
  };
};

export const systemListActions9003 = (value, dynamicType) => {
	return dispatch => {
	  return axios.post(`${serviceEndPoint.SYSTEM_LIST_SEARCH_ENDPOINT}`, value)
		.then(response => {
		  let sendingRes = commonAPIHandler(response, true);
		  dispatch(setSystemList9003(sendingRes, dynamicType));
		})
		.catch(error => {
  
		  let sendingRes = commonAPIHandler(error);
		  dispatch(setSystemList9003(sendingRes, dynamicType));
		});
	};
  };

export const systemListActions9026 = (value, dynamicType) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SYSTEM_LIST_SEARCH_ENDPOINT}`, value)
      .then(response => {
        let sendingRes = commonAPIHandler(response, true);
        dispatch(setSystemList9026(sendingRes, dynamicType));
      })
      .catch(error => {

        let sendingRes = commonAPIHandler(error);
        dispatch(setSystemList9026(sendingRes, dynamicType));
      });
  };
};

export const systemListActions9027 = (value, dynamicType) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SYSTEM_LIST_SEARCH_ENDPOINT}`, value)
      .then(response => {
        let sendingRes = commonAPIHandler(response, true);
        dispatch(setSystemList9027(sendingRes, dynamicType));
      })
      .catch(error => {
        let sendingRes = commonAPIHandler(error);
        dispatch(setSystemList9027(sendingRes, dynamicType));
      });
  };
};

export const systemListViewAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SYSTEM_LIST_SEARCH_ENDPOINT}`, value)
      .then(response => {
        let sendingRes = commonAPIHandler(response, true);
        dispatch(setSystemListRow(sendingRes));
      })
      .catch(error => {
        let sendingRes = commonAPIHandler(error, true);
        dispatch(setSystemListRow(sendingRes));
      });
  };
};

export const addSystemListAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SYSTEM_LIST_ADD}?userIDStr=`, value)
      .then(response => {
        let sendingRes = commonAPIHandler(response);
        dispatch(setSystemListAdd(sendingRes));
      })
      .catch(error => {
        let sendingRes = commonAPIHandler(error);
        dispatch(setSystemListAdd(sendingRes));
      });
  };
};

export const updateSystemList = (value) => {
  return dispatch => {
	return axios.post(`${serviceEndPoint.SYSTEM_LIST_UPDATE}?userIDStr=${value.auditUserID}`, value)
      .then(response => {
        let sendingRes = commonAPIHandler(response);
        dispatch(setSystemListUpdate(sendingRes));
      })
      .catch(error => {
        let sendingRes = commonAPIHandler(error);
        dispatch(setSystemListUpdate(sendingRes));
      });
  };
};

export const setSystemList = (systemList, dynamicType) => {
  return {
    type: actionTypes.SEARCH_SYSTEM_LIST,
    systemLists: systemList,
    dType : dynamicType
  };
};
export const setSystemList1021 = (systemList, dynamicType) => {
  return {
    type: actionTypes.SEARCH_SYSTEM_LIST1021,
    systemLists1021: systemList,
    dType : dynamicType
  };
};

export const setSystemList9003 = (systemList, dynamicType) => {
	return {
	  type: actionTypes.SEARCH_SYSTEM_LIST9003,
	  systemLists9003: systemList,
	  dType : dynamicType
	};
  };

export const setSystemList9026 = (systemList9026, dynamicType) => {
  return {
    type: actionTypes.SEARCH_SYSTEM_LIST_9026,
    systemLists9026: systemList9026,
    dType : dynamicType
  };
};
export const setSystemList9027 = (payloadV9027, dynamicType) => {
  return {
    type: actionTypes.SEARCH_SYSTEM_LIST_9027,
    payloadV9027: payloadV9027,
    dType : dynamicType
  };
};

export const setSystemListAdd = (systemlistAddResponse) => {
  return {
    type: actionTypes.SYSTEM_LIST_ADD,
    systemListAddResponse: systemlistAddResponse
  };
};

export const setSystemListUpdate = (systemListUpdateResponse) => {
  return {
    type: actionTypes.SYSTEM_LIST_UPDATE,
    systemListUpdateResponse: systemListUpdateResponse
  };
};

export const setSystemListRow = (data) => {
  return {
    type: actionTypes.SYSTEM_LIST_VIEW,
    rowsearchsyslist: data
  };
};
