/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MiscellaneousDetails from "./MiscellaneousDetails";

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    textAlign: "left"
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  container2: {
    textAlign: "left",
    marginTop: "10px"
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function MiscellaneousTab(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [showTable, setShowTable] = React.useState(false);
  const [historyValue, setHistoryValue] = React.useState({
    experience: ""
  });
  const {
    miscellaneousTableData,
    setMiscellaneousTableData,
    showEditPage,
    setAllowNavigation,
    callValidatePayeeDetails,
    BankruptcyTypeDropdown,
    disableFields,
    seterrorMessages,
    auditRowData,
    setAuditRowData,
    setShowLogTable,
    showLogMiscellaneousTable,
    setActiveMiscellaneousData,
    setShowLogMiscellaneousTable,
    userInquiryPrivileges,
    setFocusCheck,
    initialFocusCheck
  } = props;
  const [
    receivingTradingPartnerTableData,
    setreceivingTradingPartnerTableData
  ] = React.useState([
    {
      id: "1",
      receivingTPId: "AK10815",
      name: "Script, Pro",
      classification: "BA-Bill Agent",
      beginDate: "01/01/2015",
      endDate: "12/31/9999"
    },
    {
      id: "1",
      receivingTPId: "AK10816",
      name: "Script, Pro",
      classification: "BA-Bill Agent",
      beginDate: "01/01/2016",
      endDate: "12/11/9997"
    }
  ]);

  const handleReceivingTradingPartnerTableAdd = value => {
    OpenReceivingTradingPartner(true);
    setOpenReceivingTradingPartner({
      receivingTPId: value.receivingTPId,
      name: value.name,
      classification: value.classification,
      beginDate: value.beginDate,
      endDate: value.endDate
    });
  };

  const [
    OpenReceivingTradingPartner,
    setOpenReceivingTradingPartner
  ] = React.useState(false);
  const [values, setValues] = React.useState({
    receivingTPId: "",
    name: "",
    classification: "",
    beginDate: "",
    endDate: ""
  });

  const handleChanges = name => event => {
    setValues({ [name]: event.target.value });
  };
  const handleChangeHistoryValue = name => event => {
    setHistoryValue({ ...historyValue, [name]: event.target.value });
  };

  const resetTable = () => {
    setValues({
      name: "",
      age: "",
      multiline: "",
      currency: "",
      date: ""
    });
    setShowTable(false);
  };
  return (
    <div>
      <div className="tab-holder p-0">
        <MiscellaneousDetails
          initialFocusCheck={initialFocusCheck}
          BankruptcyTypeDropdown={BankruptcyTypeDropdown || []}
          disableFields={disableFields}
          callValidatePayeeDetails={callValidatePayeeDetails}
          setAllowNavigation={setAllowNavigation}
          showEditPage={showEditPage}
          setMiscellaneousTableData={setMiscellaneousTableData}
          miscellaneousTableData={miscellaneousTableData}
          onClickAuditDialogLog={props.onClickAuditDialogLog}
          uniqueKey={"payeePayerBankruptcySequenceNumber"}
          setClose={props.setClose}
          close={props.close}
          seterrorMessages={seterrorMessages}
          auditRowData={auditRowData}
          setAuditRowData={setAuditRowData}
          setShowLogTable={setShowLogTable}
          setActiveMiscellaneousData={setActiveMiscellaneousData}
          showLogMiscellaneousTable={showLogMiscellaneousTable}
          setShowLogMiscellaneousTable = {setShowLogMiscellaneousTable}
          userInquiryPrivileges= {userInquiryPrivileges}
          setFocusCheck={setFocusCheck}
          dropdowns = {props.dropdowns}
        />
      </div>

    </div>
  );
}
