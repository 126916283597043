/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../../Actions/TextManagement/TextManagementActionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SEARCH_TEXT_MANAGEMENT:
      return { ...state, payload: action.eombData };
    case actionTypes.SEARCH_TEXT_RAEOB:
      return { ...state, payload: action.RAEOBData };
    case actionTypes.SEARCH_TEXT_PN:
      return { ...state, payload: action.PNData };
    case actionTypes.SEARCH_TEXT_SP:
      return { ...state, payload: action.SPData };
    case actionTypes.SEARCH_TEXT_RC:
      return { ...state, payload: action.RCData };
    case actionTypes.SEARCH_TEXT_ARC:
      return { ...state, payload: action.ARCData };
    case actionTypes.SEARCH_TEXT_REVENUE:
      return { ...state, payload: action.RevenueData };
    case actionTypes.UPDATE_TEXT_MANAGEMENT:
      return { ...state, updateSystemParameter: action.updateSystemParameter };
    case actionTypes.ADD_TEXT_MANAGEMENT:
      return { ...state, addSystemParameter: action.addSystemParameter };
    case actionTypes.SEARCH_TEXT_PNROW:
      return { ...state, payload: action.PNRowData };
    case actionTypes.SEARCH_TEXT_RAEOBROW:
      return { ...state, payload: action.RAEOBRowData };
    case actionTypes.RESETDATA:
      return action.resetData;
    case actionTypes.SEARCH_TEXT_NCPDP:
      return { ...state, payload: action.NCPDPData };
    case actionTypes.SEARCH_TEXT_NCPDPROW:
      return { ...state, payload: action.NCPDPRowData };
    case actionTypes.SEARCH_TEXT_ARROW:
      return { ...state, payload: action.ARRowData };
    case actionTypes.SEARCH_TEXT_RCROW:
      return { ...state, payload: action.RCRowData };
    case actionTypes.ADD_TEXT_EOMB_TEXT:
      return { ...state, payload: action.EOMBData };
    case actionTypes.ADD_TEXT_PN:
      return { ...state, payload: action.PNData };
    case actionTypes.ADD_TEXT_RAEOB:
      return { ...state, payload: action.RAEOBData };
    case actionTypes.ADD_TEXT_REMARKTEXT:
      return { ...state, payload: action.RemarkTextData };
    case actionTypes.ADD_TEXT_ADJUSTMENT_REASON:
      return { ...state, payload: action.AdjustmentReasonData };
    case actionTypes.ADD_TEXT_NCPDP_TEXT:
      return { ...state, payload: action.NCPDPData };
    case actionTypes.PROVIDER_SPECIALTY_DATA:
      return { ...state, ProviderSpecialtyData: action.ProviderSpecialtyData };
    case actionTypes.DELETE_TEXT_EOMB_TEXT:
      return { ...state, DelResult: action.EOMBDelResult };
    case actionTypes.DELETE_TEXT_RAEOB:
      return { ...state, DelResult: action.RAEOBDelResult };
    case actionTypes.RESET_FIELD:
      return { ...state, DelResult: null, payload: null };
    default: return state;
  }
};

export default reducer;
