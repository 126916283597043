/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { isArray } from "util";
import ErrorMessages from "../../../../SharedModules/Errors/ErrorMessages";

import CorrectionSearchTable from "./CorrectionApprovalSearchTable";
import CorrectionView from "./CorrectionApprovalSearchView";
import CorrectionDropdownInput from "./CorrectionDropdownInput.json";
import Checkbox from '@material-ui/core/Checkbox';
import {
  searchCorrectionApprovalAction,
  resetState,
  getCorrectionDetailView,
  updateCorrectionApprovalAction,
  get1099CorrectionDropdowns,
  modifyStartWithOrContains,
  getAuditLogDetailsAction
} from "../../Store/Actions/CorrectionSearchActions";
import * as ErrorConstants from "../ErrorConstants";
import {
  generateUUID,
  getUTCTimeStamp
} from "../../../../SharedModules/DateUtilities/DateUtilities";
import SuccessMessages from "../../../../SharedModules/Errors/SuccessMessage";
import Swal from "sweetalert2";
import Footer from "../../../../SharedModules/Layout/footer";
import {
  getLongDescription,
  removeLeadingTrailingSpaces
} from "../../../../SharedModules/CommonUtilities/commonUtilities";
import Radio from '@material-ui/core/Radio';
import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";

export default function CorrectionApprovalSearch (props) {
  const [
    searchApprovalResponseData,
    setsearchApprovalResponseData
  ] = React.useState([]);

  const [showTable, setShowTable] = React.useState(false);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [
    openApprovalCorrectionSearch,
    setopenApprovalCorrectionSearch
  ] = React.useState(false);
  const [{ showProviderNameError, showProviderNameErrorText }, setShowError] = React.useState(false);
  const [selectedDataForDelete, setSelectedDataForDelete] = React.useState([]);
  const [selectedDataForApproval, setSelectedDataForApproval] = React.useState(
    []
  );
  const [auditButtonToggle, setAuditButtonToggle] = React.useState(false);
  const [showLogTable, setShowLogTable] = React.useState(false);
  const [approvalArray, setApprovalArray] = React.useState([]);
  const [deleteArray, setDeleteArray] = React.useState([]);
  const [notesArray, setNotesArray] = React.useState([]);
  const [tableDataDesc, setTableDataDesc] = React.useState([]);
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [reasonCodeData, setReasonCodeData] = React.useState([]);
  const userInquiryPrivileges = global.globalIsReadOnly();
  const logInUserIDconst = useSelector(state => state.sharedState.logInUserID);
  const [values, setValues] = React.useState({
    providerMedicaidId: "",
    providerTaxId: "",
    providerName: "",
    ssn: "",
    P: "P",
    Y: "",
    N: ""
  });
  const [correctionSearchDetails, setcorrectionSearchDetails] = React.useState({
    original1099Amount: "",
    adjusted1099Amount: "",
    reasonCode: "",
    reasonCodeDesc: "",
    reasonCodeTitle: "",
    updatedDate: "",
    a1099Approval: "",
    c1099Correction: "",
    void1: "",
    claimProvSumSk: "",
    claimCorrSeqNum: ""
  });
  const [notesData, setNotesData] = React.useState({});
  const [rowSeqNum, setrowSeqNum] = React.useState(0);
  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState([]);
  const [logInUserID, setLoginUserId] = React.useState();
  const errorMessagesArray = [];
  let searchApprovalResponse = [];
  let dropdown = [];
  const sucessMessages = [];
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  const OnReset = () => dispatch(resetState());
  const onSearchApproval = searchCriteria =>
    dispatch(searchCorrectionApprovalAction(searchCriteria));
  searchApprovalResponse = useSelector(
    state => state.correctionSearchState.searchCorrectionApprovalData
  );
  const onRowclickAPICall = row => dispatch(getCorrectionDetailView(row));
  const onCorrectionApprovalDetailData = useSelector(
    state => state.correctionSearchState.correctionDeatilViewData
  );
  const onUpdateApproval = value =>
    dispatch(updateCorrectionApprovalAction(value));
  const approvalUpdate = useSelector(
    state => state.correctionSearchState.correctionApprovalUpdateResponse
  );
  const getResultRow = (num, activeRow, historyRow) => {
    if (activeRow && activeRow.claimCorrSeqNum === num) {
      return activeRow;
    } else {
      const selectedRow = historyRow?.filter((e) => { return e.claimCorrSeqNum === num; });
      return selectedRow[0];
    }
  };

  // dropdown for description
  const onDropdowns = CorrectionDropdownInput =>
    dispatch(get1099CorrectionDropdowns(CorrectionDropdownInput));
  dropdown = useSelector(state => state.correctionSearchState.dropdowns);
  const validValueDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  useEffect(() => {
    if (logInUserIDconst) {
      setLoginUserId(logInUserIDconst);
    }
  }, [logInUserIDconst]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj["Financial#3102"]) {
        setReasonCodeData(dropdown.listObj["Financial#3102"]);
      };
      if (dropdown.listObj['General#1012']) {
        setUsageTypeCodeData(dropdown.listObj['General#1012']);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    OnReset();
    validValueDropdowns([
      Dropdowns.F_RSN_CD
    ]);
  }, []);

  useEffect(() => {
    onDropdowns(CorrectionDropdownInput);
  }, [CorrectionDropdownInput]);

  useEffect(() => {
    if (
      searchApprovalResponse &&
      !searchApprovalResponse.systemFailure &&
      searchApprovalResponse.length > 0
    ) {
      setShowTable(true);
      setsearchApprovalResponseData(searchApprovalResponse);
    }
    if (
      searchApprovalResponse &&
      !searchApprovalResponse.systemFailure &&
      searchApprovalResponse.length === 0
    ) {
      errorMessagesArray.push(ErrorConstants.NO_RECORDS_FOUND);
      seterrorMessages(errorMessagesArray);
    }
    if (searchApprovalResponse && searchApprovalResponse.systemFailure) {
      errorMessagesArray.push(ErrorConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray);
    }
  }, [searchApprovalResponse]);

  useEffect(() => {
    if (approvalUpdate) {
      if (approvalUpdate) {
        if (approvalUpdate.respcode && approvalUpdate.respcode === "02") {
          errorMessagesArray.push(approvalUpdate.respdesc);
          seterrorMessages(errorMessagesArray);
        } else if (approvalUpdate.systemFailure || approvalUpdate.message) {
          errorMessagesArray.push(ErrorConstants.GENERIC_SYSTEM_ERROR);
          seterrorMessages(errorMessagesArray);
        } else if (
          approvalUpdate.respcode &&
          approvalUpdate.respcode === "01"
        ) {
          const searchCriteria = {
            providerID: values.providerMedicaidId || null,
            providerTaxID: values.providerTaxId || null,
            providerName: values.providerName || null,
            providerNameStartsWithOrContains: modifyStartWithOrContains(values.providerNameSelected),
            ssn: values.ssn || null,
            approvalIndicator: null
          };
          onSearchApproval(removeLeadingTrailingSpaces(searchCriteria));
          handleCloseCorrectionApprovalSearch();
          sucessMessages.push(ErrorConstants.SUCCESS_MESSAGE);
          setSystemSuccesMessages(sucessMessages);
        }
      }
    }
  }, [approvalUpdate]);

  // Map function to get the Description for corresponding Code
  const mapCodeDescription = (_code, _mapArray) => {
    if (_mapArray && _mapArray.length > 0) {
      let _description = [];
      _description = _mapArray.filter((value, index) => value.code === _code);
      if (_description.length > 0) {
        return _description[0].description;
      } else {
        return _code;
      }
    }
    return _code;
  };

  useEffect(() => {
    if (searchApprovalResponseData.length > 0) {
      const approvalArraySeperation = searchApprovalResponseData.map(
        x => x.approvedIndicator
      );
      setApprovalArray(approvalArraySeperation);
      const deleteArraySeperation = searchApprovalResponseData.map(
        x => x.deleteIndicator
      );
      setDeleteArray(deleteArraySeperation);
      const notesArraySeperation = searchApprovalResponseData.map(
        x => (!x.approvedIndicator && !x.deleteIndicator)
      );
      setNotesArray(notesArraySeperation);
    }
    const tempFcnPayload = [];
    searchApprovalResponseData.map((row, index) => {
      row.uid1 = generateUUID();
      row.uid2 = generateUUID();
      const outputData = searchApprovalResponseData;
      tempFcnPayload.push({
        ...outputData[index],
        deleteIndicator: row.deleteIndicator,
        reasonCodeDesc: mapCodeDescription(
          row.reasonCode,
          dropdown ? dropdown.reasonCode : reasonCodeData
        ),
        reasonCodeLongDesc: getLongDescription(
          dropdown ? dropdown.reasonCode : reasonCodeData,
          row.reasonCode
        )
      });
    });
    setTableDataDesc(tempFcnPayload);
  }, [searchApprovalResponseData]);

  useEffect(() => {
    setopenApprovalCorrectionSearch(false);
    if (onCorrectionApprovalDetailData) {
      if (
        onCorrectionApprovalDetailData.respcode &&
        onCorrectionApprovalDetailData.respcode === "02"
      ) {
        errorMessagesArray.push(onCorrectionApprovalDetailData.respdesc);
        seterrorMessages(errorMessagesArray);
        setShowTable(false);
      } else if (onCorrectionApprovalDetailData.systemFailure) {
        errorMessagesArray.push(ErrorConstants.GENERIC_SYSTEM_ERROR);
        seterrorMessages(errorMessagesArray);
        setShowTable(false);
      } else if (onCorrectionApprovalDetailData.enterpriseBaseVO === null) {
        errorMessagesArray.push(ErrorConstants.NO_RECORDS_FOUND);
        seterrorMessages(errorMessagesArray);
        setShowTable(false);
      } else if (
        onCorrectionApprovalDetailData.enterpriseBaseVO &&
        isArray(onCorrectionApprovalDetailData.enterpriseBaseVO) &&
        onCorrectionApprovalDetailData.enterpriseBaseVO.length === 0
      ) {
        errorMessagesArray.push(ErrorConstants.NO_RECORDS_FOUND);
        seterrorMessages(errorMessagesArray);
        setShowTable(false);
      } else if (
        onCorrectionApprovalDetailData.enterpriseBaseVO &&
        !onCorrectionApprovalDetailData.systemFailure
      ) {
        const activeCorrectionArray = onCorrectionApprovalDetailData?.enterpriseBaseVO?.active1099Corrections;
        const historyCorrectionArray = onCorrectionApprovalDetailData?.enterpriseBaseVO?.history1099Corrections;
        const resultData = getResultRow(rowSeqNum, activeCorrectionArray, historyCorrectionArray);
        setopenApprovalCorrectionSearch(true);
        setcorrectionSearchDetails({
          original1099Amount: resultData?.original1099Amount,
          adjusted1099Amount: resultData?.adjusted1099Amount,
          reasonCode: mapCodeDescription(
            resultData?.reasonCode,
            dropdown ? dropdown.reasonCode : reasonCodeData
          ),
          reasonCodeTitle: getLongDescription(
            dropdown ? dropdown.reasonCode : reasonCodeData,
            resultData?.reasonCode
          ),
          updatedDate: resultData?.updateDate,
          a1099Approval: resultData?.fin1099ApprovalIndicator,
          c1099Correction: resultData?.fin1099CorrectionIndicator,
          void1: resultData?.fin1099VoidIndicator,
          claimProvSumSk: resultData?.claimProvSumSk,
          claimCorrSeqNum: resultData?.claimCorrSeqNum,
          commonEntitySK: resultData?.commonEntitySK,
          categoryCode: resultData?.categoryCode,
          lobCode: resultData?.lobCode,
          timePeriodCode: resultData?.timePeriodCode
        });
      }
    }
  }, [onCorrectionApprovalDetailData]);

  const handleChange = name => event => {
    if (name === 'P') {
      if (event.target.checked) {
        setValues({ ...values, [name]: 'P' });
      } else {
        setValues({ ...values, [name]: '' });
      }
    } else if (name === 'Y') {
      if (event.target.checked) {
        setValues({ ...values, [name]: 'Y' });
      } else {
        setValues({ ...values, [name]: '' });
      }
    } else if (name === 'N') {
      if (event.target.checked) {
        setValues({ ...values, [name]: 'N' });
      } else {
        setValues({ ...values, [name]: '' });
      }
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  };

  const handleChangeSearchDetails = name => event => {
    setcorrectionSearchDetails({
      ...correctionSearchDetails,
      [name]: event.target.value
    });
  };

  const selectedArrayApproval = data => {
    setSelectedDataForApproval(data);
  };
  const selectedArrayDelete = data => {
    setSelectedDataForDelete(data);
  };

  const searchValidations = () => {
    seterrorMessages([]);
    setSystemSuccesMessages([]);
    var showProviderNameError = false;
    var showProviderNameErrorText = false;
    if (values.providerTaxId == '' && values.providerNameSelected == undefined && values.providerName == '' && values.providerMedicaidId == '' && values.ssn == '') {
      showProviderNameError = true;
      errorMessagesArray.push(ErrorConstants.DATA_REQUIRED);
      seterrorMessages(errorMessagesArray);
      setShowTable(false);
    }
    if (values.providerNameSelected && (!values.providerName || values.providerName.trim()?.length < 2)) {
      showProviderNameErrorText = true;
      errorMessagesArray.push(ErrorConstants.MIN_CHAR_ERROR);
      seterrorMessages(errorMessagesArray);
      setShowTable(false);
    }
    setShowError({
      showProviderNameError: showProviderNameError,
      showProviderNameErrorText: showProviderNameErrorText
    });
    seterrorMessages(errorMessagesArray);
  };
  const correctionApprovalSearch = () => {
    setShowTable(false);
    seterrorMessages([]);
    setSystemSuccesMessages([]);
    searchValidations();
    if (errorMessagesArray.length === 0) {
      const searchCriteria = {
        providerID: values.providerMedicaidId || null,
        providerTaxID: values.providerTaxId || null,
        providerName: values.providerName || null,
        providerNameStartsWithOrContains: modifyStartWithOrContains(values.providerNameSelected),
        ssn: values.ssn || null,
        approvalIndicator: ((values.P && values.N && values.Y) ? 'P,Y,N' : (values.P && values.N) ? 'P,N' : (values.P && values.Y) ? 'P,Y'
          : (values.N && values.Y) ? 'Y,N' : values.Y ? 'Y' : values.P ? 'P' : values.N ? 'N' : null)
      };
      onSearchApproval(removeLeadingTrailingSpaces(searchCriteria));
    }
  };

  const correctionApprovalSearchReset = () => {
    OnReset();
    setShowTable(false);
    seterrorMessages([]);
    setShowError(false);
    setSystemSuccesMessages([]);
    setValues({
      providerMedicaidId: "",
      providerTaxId: "",
      providerName: "",
      ssn: "",
      P: "P",
      Y: "",
      N: ""
    });
  };

  const handlecorrectionApprovalSearchView = data => {
    const rowDetails = {
      lobCode: data.lobCode,
      categoryCode: data.categoryCode,
      timePeriodCode: data.timePeriodCode,
      commonEntitySK: data.commonEntitySK
    };

    onRowclickAPICall(rowDetails);
  };
  const auditData = useSelector(state => state.correctionSearchState.auditData);
  const handleCloseCorrectionApprovalSearch = () => {
    setopenApprovalCorrectionSearch(false);
  };

  const onClickAuditLog = () => {
    const tableName = "c_prov_1099_corr_tb";
    const keyValue = {
      c_prov_clm_sum_sk: correctionSearchDetails.claimProvSumSk,
      c_1099_corr_seq_num: correctionSearchDetails.claimCorrSeqNum
    };
    dispatch(getAuditLogDetailsAction(tableName, keyValue, "auditLogData"));
  };

  useEffect(() => {
    if (auditButtonToggle) {
      onClickAuditLog();
    } else {
      setShowLogTable(false);
    }
  }, [auditButtonToggle]);

  const editLink = row => {
    handlecorrectionApprovalSearchView(row);
  };

  const onSubmitConfirm = () => {
    const submitApprovalData = [];
    const approvalData = {
      commonEntitySK: 0,
      categoryCode: "",
      lobCode: "",
      timePeriodCode: "",
      approvedIndicator: false,
      approvalDisableCheck: false,
      deleteIndicator: false,
      claimProvSumSk: "",
      claimCorrSeqNum: "",
      noteSetVO: notesData
    };
    for (let i = 0; i < approvalArray.length; i++) {
      if (selectedDataForApproval.includes(tableDataDesc[i].uid1)) {
        approvalArray[i] = true;
        tableDataDesc[i].approvedIndicator = true;
        approvalData.commonEntitySK = tableDataDesc[i].commonEntitySK;
        approvalData.categoryCode = tableDataDesc[i].categoryCode;
        approvalData.claimProvSumSk = tableDataDesc[i].claimProvSumSk;
        approvalData.claimCorrSeqNum = tableDataDesc[i].claimCorrSeqNum;
        approvalData.lobCode = tableDataDesc[i].lobCode;
        approvalData.timePeriodCode = tableDataDesc[i].timePeriodCode;
        approvalData.approvedIndicator = tableDataDesc[i].approvedIndicator;
        approvalData.approvalDisableCheck =
          tableDataDesc[i].approvalDisableCheck;
        approvalData.deleteIndicator = tableDataDesc[i].deleteIndicator;
        approvalData.auditUserID = logInUserID;
        approvalData.auditTimeStamp = getUTCTimeStamp();
        approvalData.addedAuditUserID = tableDataDesc[i].addedAuditUserID
          ? tableDataDesc[i].addedAuditUserID
          : logInUserID;
        approvalData.addedAuditTimeStamp = tableDataDesc[i].addedAuditTimeStamp
          ? tableDataDesc[i].addedAuditTimeStamp
          : getUTCTimeStamp();
        submitApprovalData.push(approvalData);
      }
      if (approvalArray[i]) {
        tableDataDesc[i].approvedIndicator = true;
        tableDataDesc[i].deleteIndicator = false;
      }
    }
    for (let i = 0; i < deleteArray.length; i++) {
      if (selectedDataForDelete.includes(tableDataDesc[i].uid2)) {
        deleteArray[i] = true;
        tableDataDesc[i].deleteIndicator = true;
        approvalData.commonEntitySK = tableDataDesc[i].commonEntitySK;
        approvalData.categoryCode = tableDataDesc[i].categoryCode;
        approvalData.claimProvSumSk = tableDataDesc[i].claimProvSumSk;
        approvalData.claimCorrSeqNum = tableDataDesc[i].claimCorrSeqNum;
        approvalData.lobCode = tableDataDesc[i].lobCode;
        approvalData.timePeriodCode = tableDataDesc[i].timePeriodCode;
        approvalData.approvedIndicator = tableDataDesc[i].approvedIndicator;
        approvalData.approvalDisableCheck =
          tableDataDesc[i].approvalDisableCheck;
        approvalData.deleteIndicator = tableDataDesc[i].deleteIndicator;
        approvalData.auditUserID = logInUserID;
        approvalData.auditTimeStamp = getUTCTimeStamp();
        approvalData.addedAuditUserID = tableDataDesc[i].addedAuditUserID
          ? tableDataDesc[i].addedAuditUserID
          : logInUserID;
        approvalData.addedAuditTimeStamp = tableDataDesc[i].addedAuditTimeStamp
          ? tableDataDesc[i].addedAuditTimeStamp
          : getUTCTimeStamp();
        submitApprovalData.push(approvalData);
      }
      if (deleteArray[i]) {
        tableDataDesc[i].approvedIndicator = false;
        tableDataDesc[i].deleteIndicator = true;
      }
    }
    onUpdateApproval(submitApprovalData);
    setApprovalArray(approvalArray);
    setDeleteArray(deleteArray);
    setNotesArray(notesArray);
    setTableDataDesc([...tableDataDesc]);
  };

  const submitForApproval = () => {
    seterrorMessages([]);
    setSystemSuccesMessages([]);
    if (selectedDataForDelete.length > 0) {
      Swal.fire({
        customClass: {
          confirmButton: "btn-text1 btn-transparent mr-3",
          cancelButton: "btn1 btn-success",
          container: "unsaved-changes-popup2"
        },
        allowOutsideClick: false,
        buttonsStyling: false,
        heightAuto: false,
        icon: "warning",
        html: "<h4>Are you sure you want to Void?</h4 >",
        showCancelButton: true,
        confirmButtonText: "Cancel!",
        cancelButtonText: 'Continue! <i class="fa fa-arrow-right ml-1"></i>'
      }).then(result => {
        if (result.value) {
        } else {
          onSubmitConfirm();
        }
      });
    } else {
      onSubmitConfirm();
    }
  };

  return (
    <div className="pos-relative w-100 h-100">

      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        {errorMessages.length === 0 ? (
          <SuccessMessages successMessages={systemSuccessMessages} />
        ) : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left">
            Search 1099 Correction Approval
          </h1>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form noValidate autoComplete="off">
            <div className="form-wrapper">
              <div className="mui-custom-form input-md">
                <TextField
                  id="ProviderId"
                  fullWidth
                  label="Provider Medicaid Id"
                  disabled={userInquiryPrivileges}
                  placeholder=""
                  value={values.providerMedicaidId}
                  onChange={handleChange("providerMedicaidId")}
                  inputProps={{ maxlength: 15 ,"data-testid":"test_ProviderId"}}
                  InputLabelProps={{
                    shrink: true
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="providerTaxId"
                  fullWidth
                  label="Provider Tax ID"
                  value={values.providerTaxId}
                  placeholder=""
                  disabled={userInquiryPrivileges}
                  onChange={handleChange("providerTaxId")}
                  inputProps={{ maxlength: 15 ,"data-testid":"test_providerTaxId"}}
                  InputLabelProps={{
                    shrink: true
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form  input-md">
                <TextField
                  id="providerName"
                  fullWidth
                  label="Provider Name"
                  value={values.providerName}
                  placeholder=""
                  disabled={userInquiryPrivileges}
                  onChange={handleChange("providerName")}
                  inputProps={{ maxlength: 60 ,"data-testid":"test_providerName"}}
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={
                    showProviderNameErrorText ? ErrorConstants.MIN_CHAR_ERROR : null
                  }
                  error={showProviderNameErrorText ? ErrorConstants.MIN_CHAR_ERROR : null}
                ></TextField>
                <div className="sub-radio">
                  <Radio
                    value="Starts With"
                    id="providernamestart"
                    checked={values.providerNameSelected === 'Starts With'}
                    onChange={handleChange('providerNameSelected')}
                    inputProps={{"data-testid":"test_providernamestart"}}
                  /> <label className="text-black" htmlFor="providernamestart">Starts With </label>
                  <Radio
                    value="Contains"
                    checked={values.providerNameSelected === 'Contains'}
                    id="providernamecontains"
                    onChange={handleChange('providerNameSelected')}
                    className="ml-2"
                    inputProps={{"data-testid":"test_providernameContains"}}
                  /><label className="text-black" htmlFor="providernamecontains">Contains</label>
                </div>
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="ssn"
                  fullWidth
                  label="SSN"
                  value={values.ssn}
                  onChange={handleChange("ssn")}
                  placeholder=""
                  disabled={userInquiryPrivileges}
                  inputProps={{ maxlength: 9 ,"data-testid":"test_ssn"}}
                  InputLabelProps={{
                    shrink: true
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form input-md ca-indicator">
                <label className="MuiFormLabel-root small-label no-margin">
            1099 Approval Indicator
                </label>
                <div className="form-wrapper cust-sub-radio-lbl">
                  <div className="mui-custom-form dib ">

                    <div className="sub-radio flex-radio">
                      <Checkbox
                        checked={!!values.Y}
                        type="checkbox"
                        color="primary"
                        id="VI"
                        inputProps={{"data-testid":"test_ApprovalYes"}}
                        onChange={handleChange('Y')}
                      />
                      <label className="text-black" htmlFor="VI">
                 Yes
                      </label>
                    </div>
                  </div>
                  <div className="mui-custom-form dib">
                    <div className="sub-radio">
                      <Checkbox
                        checked={!!values.N}
                        inputProps={{ 'aria-label': 'secondary checkbox',"data-testid":"test_ApprovalNo" }}
                        color="primary"
                        id="VJ"
                        onChange={handleChange('N')}
                      />
                      <label className="text-black" htmlFor="VJ">
                 No
                      </label>
                    </div>
                  </div>
                  <div className="mui-custom-form dib">
                    <div className="sub-radio">
                      <Checkbox
                        checked={!!values.P}
                        inputProps={{ 'aria-label': 'secondary checkbox',"data-testid":"test_ApprovalPending" }}
                        color="primary"
                        id="VK"
                        onChange={handleChange('P')}
                      />
                      <label className="text-black" htmlFor="VK">
                 Pending
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="float-right mr-3 mb-2">
            <Button
              variant="outlined"
              data-testid="test_searchbtn"
              color="primary"
              className="btn btn-primary ml-1"
              disabled={userInquiryPrivileges}
              onClick={() => {
                correctionApprovalSearch();
              }}
            >
              <i className="fa fa-search" aria-hidden="true"></i>
              Search
            </Button>
            <Button
              className="bt-reset btn-transparent ml-1"
              disabled={userInquiryPrivileges}
              data-testid="test_ResetBtn"
              onClick={() => {
                correctionApprovalSearchReset();
              }}
            >
              <i className="fa fa-undo" aria-hidden="true"></i> Reset
            </Button>
          </div>
          <div className="clearfix"></div>
          {showTable ? (
            <div className="tab-holder table-no-wrap table-p-10">
              <CorrectionSearchTable
                setNotesData={setNotesData}
                setrowSeqNum={setrowSeqNum}
                seterrorMessages={seterrorMessages}
                usageTypeCodeData={ usageTypeCodeData }
                correctionSearchViewData={correctionSearchDetails}
                logInUserID={logInUserID}
                approvalArray={approvalArray}
                deleteArray={deleteArray}
                selectedArrayRowsApproval={selectedArrayApproval}
                selectedArrayRowsDelete={selectedArrayDelete}
                dropdowns={addDropdowns}
                tableData={tableDataDesc.keySort({
                  providerTaxID: "asc",
                  providerID: "asc"
                })}
                editLink={editLink}
              />
              {openApprovalCorrectionSearch ? (
                <div className="tabs-container" id="form_pop_up">
                  <div className="tab-header  mini-tab-header">
                    <div className="tab-heading float-left">
                      View 1099 Correction
                    </div>
                    <div className="float-right mt-1">
                      <Button
                        data-testid="test_AuditBtn"
                        className={auditButtonToggle ? "btn btn-primary ml-1" : "btn btn-text1 btn-transparent ml-1"}
                        onClick={() => {
                          setAuditButtonToggle(!auditButtonToggle);
                          setShowLogTable(!showLogTable);
                        }}
                      >
                        <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
                        Audit Log
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        className="btn btn-primary btn btn-outlined ml-1"
                        onClick={handleCloseCorrectionApprovalSearch}
                        data-testid="test_CancelBtn"
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                  <div className="form-border my-3">
                    <CorrectionView
                      correctionSearchViewData={correctionSearchDetails}
                      handleChangeSearchDetails={handleChangeSearchDetails}
                      dropdowns={addDropdowns}
                      showLogTable={showLogTable}
                      setShowLogTable={setShowLogTable}
                      auditData={auditData}
                    ></CorrectionView>
                  </div>
                </div>
              ) : null}
              <Button
                className="float-right btn mb-2 btn-primary ml-1"
                onClick={() => submitForApproval()}
                data-testid="test_submitBtn"
                disabled={userInquiryPrivileges}
              >
                Submit
              </Button>
              <div className="clearfix"></div>
            </div>
          ) : null}
        </div>
        <Footer print />
      </div>
    </div>
  );
}
