/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import { withRouter } from 'react-router';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';
import { TRANSACTION_NUMBER_FORMAT } from '../../../../SharedModules/Errors/ErrorMsgConstant';
import { NO_CHECK_TO_DISPOSITION } from '../FinancialReceiptConstants';

function OtherBillingReceivableDisposition(props) {
  const {
    validValRsnCodes, seterrorMessages, crswalkFS9303, tabPanelValue, otherBillingTab, otherBillingTabHandleChanges,atrNumber,
    dispositionReasonCodeDD, collationCodeDD, cosDD, showOtherTabError, otherTabErrMsg, otherBillingFormat, userInquiryPrivileges, removeOtherBillingFormat, sysParameterPayeeExist
  } = props;

  React.useEffect(() => {
    if (tabPanelValue === 3) {
      if (!crswalkFS9303?.length) {
        seterrorMessages([NO_CHECK_TO_DISPOSITION]);
      }
    } else {
      seterrorMessages([]);
    }
  }, [tabPanelValue]);
  
  return (
    <div >
      <form autoComplete="off">
        <div className="form-wrapper">
            <NativeDropDown
              id="dispositionResonCode"
              disabled={!userInquiryPrivileges || sysParameterPayeeExist}
              label="Disposition Reason Code"
              inputProps={{ maxLength: 3 }}
              value={otherBillingTab.billingRsnCode}
              onChange={otherBillingTabHandleChanges('billingRsnCode')}
              InputLabelProps={{
                shrink: true
              }}
              errTxt={(showOtherTabError.showDispositionRCodeErr && otherTabErrMsg.dispositionRCodeErrMsg) || (!crswalkFS9303?.length && NO_CHECK_TO_DISPOSITION)}
              dropdownList={validValRsnCodes?.filter(e => crswalkFS9303?.indexOf(e.code) > -1)?.map(option => (
                <option key={option.code} value={option.code}>
                  {option.description}
                </option>
              ))}
            />
            <NativeDropDown
              id="fundCode"
              disabled={!userInquiryPrivileges || sysParameterPayeeExist}
              label="Fund Code"
              inputProps={{ maxLength: 3 }}
              value={otherBillingTab.otherBillingRecvDispfundCode}
              onChange={otherBillingTabHandleChanges('otherBillingRecvDispfundCode')}
              InputLabelProps={{
                shrink: true
              }}
              errTxt={showOtherTabError.shoeFundCodeErr ? otherTabErrMsg.fundCodeErrMsg : null}
              dropdownList={collationCodeDD ? collationCodeDD.map(option => (
                <option key={option.code} value={option.code}>
                  {option.description}
                </option>
              )) : null}
            />
            <NativeDropDown
              id="cos"
              disabled={!userInquiryPrivileges || sysParameterPayeeExist}
              label="COS"
              inputProps={{ maxLength: 3 }}
              value={otherBillingTab.otherBillingCosCode}
              onChange={otherBillingTabHandleChanges('otherBillingCosCode')}
              InputLabelProps={{
                shrink: true
              }}
              dropdownList={cosDD ? cosDD.map(option => (
                <option key={option.code} value={option.code}>
                  {option.description}
                </option>
              )) : null}
            />
          <div className="mui-custom-form input-md">
            <TextField
              id="paymentAmount"
              fullWidth
              data-testid = 'test-paymentamount-txt'
              name="paymentAmount"
              label="Payment Amount"
              inputProps={{ maxLength: 12 }}
              onBlur={otherBillingFormat}
              onFocus={removeOtherBillingFormat}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: !userInquiryPrivileges || sysParameterPayeeExist,
                className: !userInquiryPrivileges || sysParameterPayeeExist ? 'Mui-disabled' : '',
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              onInput={e => {
                e.target.value = e.target.value.replace(
                  /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                  ''
                );
              }}
              value={otherBillingTab.billingAmount}
              onChange={otherBillingTabHandleChanges('billingAmount')}
              helperText={showOtherTabError.showPaymentAmtErr ? otherTabErrMsg.paymentAmtErrMsg : null}
              error={showOtherTabError.showPaymentAmtErr ? otherTabErrMsg.paymentAmtErrMsg : null}
            />
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form input-md">
            <TextField
              id='ATRnumber'
              data-testid = 'test_ATR_txt'
              fullWidth
              label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
              placeholder=""
              value={otherBillingTab.atrNumber}
              disabled = {!userInquiryPrivileges}
              onChange ={otherBillingTabHandleChanges('atrNumber')} 
              helperText={showOtherTabError.showATRErr?otherTabErrMsg.atrErrMsg:null}
              error= {showOtherTabError.showATRErr?otherTabErrMsg.atrErrMsg:null}
              inputProps={{ maxlength: 18 }}
              InputLabelProps={{
                shrink: true,
              }}
            ></TextField>
          </div>
        </div>
      </form>
    </div>
  );
};
export default withRouter((OtherBillingReceivableDisposition));
