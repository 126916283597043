/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Bootstrap, { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import numeral from 'numeral';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import { redirectGrossLevelFCN } from '../../../../../SharedModules/TabRedirections/TabRedirections';
import { getLongDescription } from '../../../../../SharedModules/CommonUtilities/commonUtilities'
import NativeDropDown from '../../../../../SharedModules/Dropdowns/NativeDropDown';
import * as AppConstants from '../../../../../SharedModules/AppConstants';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function GrossLevelDetails(props) {
  const userInquiryPrivileges = !global.globalIsReadOnly();

  

  //getting fund code record  to set desc
  let fundCodeRecord =props.addDropdowns &&  Object.keys(props.addDropdowns).length >0  &&  props.addDropdowns['R1#R_FUND_CD']?.find(value => value.code === props.values.collocationCode);
  let fundCodeVal =fundCodeRecord !== undefined ? fundCodeRecord.description :  null;

    // getting cos code record  to set desc
    //props.values.categoryOfService is getting null for few records hence unable to get record
    let cosRecord = props.addDropdowns &&  Object.keys(props.addDropdowns).length >0  &&  
                    props.addDropdowns['C1#C_COS_CD']?.find(value => value.code === props.values.categoryOfService);

    let cosVal = cosRecord !== undefined ? cosRecord.description :  null;

  return (
    <div>
      <form autoComplete="off">
        <div className="form-wrapper">
          <div className="mui-custom-form  input-md">
            <TextField
              id="standard-fcn"
              fullWidth
              label="FCN"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              placeholder=""
              inputProps={ { maxlength: 14 } }
              InputLabelProps={ {
                shrink: true
              } }
              value={ props.values.fcn }
            />
          </div>
          <div className="mui-custom-form input-md">
            <TextField
              required
              fullWidth
              id="AdvancePaymentstandardOriginalA/R"
              label="Original A/R Amount"
              name="originalARAmount"
              placeholder=""
              inputProps={ { maxlength: 11 } }
              InputLabelProps={ {
                shrink: true
              } }
              InputProps={ {
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                readOnly: true,
                className: 'Mui-disabled'
              } }
              value={ numeral(props.values.originalARAmount).format('0,0.00') }
            >
            </TextField>
          </div>
          <div className="mui-custom-form input-md">
            <TextField
              id="standard-adjusted-aramount"
              fullWidth
              label="Adjusted A/R Amount"
              InputProps={ { startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' } }
              placeholder=""
              inputProps={ { maxlength: 11 } }
              InputLabelProps={ {
                shrink: true
              } }
              value={ numeral(props.values.adjustedARAmount).format('0,0.00') }
            >
            </TextField>
          </div>
          <div className="mui-custom-form input-md">
            <TextField
              id="standard-balanceamount"
              fullWidth
              label="Balance Amount"
              InputProps={ { startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' } }
              placeholder=""
              inputProps={ { maxlength: 11 } }
              InputLabelProps={ {
                shrink: true
              } }
              value={ numeral(props.values.balanceAmount).format('0,0.00') }
            >
            </TextField>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form">
            <label className='MuiFormLabel-root small-label' >Approval<span>*</span></label>
          
              <div className="sub-radio no-margin">
                <input type="radio"
                  disabled={ true }
                  value="Y"
                  id="approvalIdY"
                  checked={ props.values.approval === 'Y' }
                  onChange={ props.handleChange('approval') }
                /><label className="text-black" for="approvalIdY">Yes</label>
                <input type="radio"
                  disabled={ true }
                  value="N"
                  id="approvalIdN"
                  checked={ props.values.approval === 'N' }
                  onChange={ props.handleChange('approval') }
                  className="ml-2"
                /><label className="text-black" for="approvalIdN">No</label>
              </div>
          </div>
          <div className="mui-custom-form  input-md">
            <TextField
              id="standard-established-date"
              fullWidth
              label="Established Date"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              placeholder=""
              inputProps={ { maxlength: 10 } }
              InputLabelProps={ {
                shrink: true
              } }
              value={ props.values.establishedDate }
            >
            </TextField>
          </div>
          <div className="mui-custom-form  input-md" >
            <TextField
              id="standard-lastdate-applied"
              fullWidth
              label="Last Date Applied"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              placeholder=""
              inputProps={ { maxlength: 10 } }
              InputLabelProps={ {
                shrink: true
              } }
              value={ props.values.lastDateApplied }
            >
            </TextField>
          </div>
          <div className="mui-custom-form  input-md">
            <TextField
              id="standard-arlettersent"
              fullWidth
              label="A/R Letter Sent "
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              placeholder=""
              inputProps={ { maxlength: 3 } }
              InputLabelProps={ {
                shrink: true
              } }
              value={ props.values.arLettersent }
            >
            </TextField>
          </div>

        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form  input-md">
            <TextField
              id="standard-arage"
              fullWidth
              label="A/R Age In Days "
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              placeholder=""
              InputLabelProps={ {
                shrink: true
              } }
              value={ props.values.arAgeInDays }
            >
            </TextField>
          </div>
          <div className="mui-custom-form  input-md">
            <TextField
              id="standard-manual-chknumber"
              fullWidth
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              label="Manual Check Number"
              placeholder=""
              inputProps={ { maxlength: 11 } }
              InputLabelProps={ {
                shrink: true
              } }
              value={ props.values.manualCheckNumber }
              onChange={ props.handleChange('manualCheckNumber') }
              required
              helperText={ props.errors[16] ? props.errorText[16] : null }
              error={ props.errors[16] ? props.errorText[16] : null }
            >
            </TextField>
          </div>
          { <MuiPickersUtilsProvider utils={ DateFnsUtils }>
            <div className="mui-custom-form with-date">
              <KeyboardDatePicker
                maxDate={ Date.parse('31 Dec 9999 00:00:00 GMT') }
                required
                disabled
                InputProps={ { readOnly: true, className: 'Mui-disabled' } }
                id="date-picker-dialog-checkdate"
                label="Manual Check Date"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                minDate={ '01/01/1964' }
                value={ props.manualCheckDate }
                onChange={ props.handleManualCheckDate }
                InputLabelProps={ {
                  shrink: true
                } }
                KeyboardButtonProps={ {
                  'aria-label': 'change date'
                } }
                helperText={ props.errors[4] ? props.errorText[4] : null || props.errors[7] ? props.errorText[7] : null || props.errors[13] ? props.errors[13] : null }
                error={ props.errors[4] ? props.errorText[4] : null || props.errors[7] ? props.errorText[7] : null || props.errors[13] ? props.errors[13] : null }
              />
            </div>
          </MuiPickersUtilsProvider> }
          <NativeDropDown
            id="payeeStatus"
              label="Payee Status"
            value={props.values.payeeStatus || ' - 1'}
            onChange={props.handleChange('payeeStatus')}
              title={getLongDescription(props.payeePaymentStatusFrmState, props.values.payeeStatus)}
            dropdownList={props.payeePaymentStatusFrmState
                ? props.payeePaymentStatusFrmState.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.description}
                  </option>
                ))
                : null}
          />
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form  input-md">
            <TextField
              id='ATRnumber'
              fullWidth
              label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
              placeholder=""
              value={props.values.atrNumber}
              onChange={props.handleChange('atrNumber')}
              inputProps={{ maxlength: 18 }}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={props.errors[18] ? props.errorText[18] : null}
              error={props.errors[18] ? props.errorText[18]  : null}
            ></TextField>
          </div>
        </div>
        <div className="tab-holder">
          <div className="row mb-2">
            <div className="col-5" style={ { maxWidth: '398px' } }>
              <fieldset className="custom-fieldset">
                <legend>Account:</legend>
                <div className="form-wrapper vertical-wrapper">
                  <div className="mui-custom-form min-md">
                    <TextField
                      required
                      fullWidth
                      id="standard-fiscal-year"
                      label="Fiscal Year"
                      placeholder=""
                      inputProps={ { maxlength: 4 } }
                      InputLabelProps={ {
                        shrink: true
                      } }
                      type="number"
                      value={ props.values.fiscalYear }
                      onChange={ props.handleChange('fiscalYear') }
                      helperText={ props.errors[1] ? props.errorText[1] : null }
                      error={ props.errors[1] ? props.errorText[1] : null }
                      onInput={ (e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4);
                      } }
                      onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                      InputProps={ { readOnly: !userInquiryPrivileges ? !userInquiryPrivileges : props.values.balanceAmount === '0.00' || props.amountObj.tempBalanceAmount !== props.amountObj.tempOriginalARAmount, className: !userInquiryPrivileges ? !userInquiryPrivileges : props.values.balanceAmount === '0.00' || props.amountObj.tempBalanceAmount !== props.amountObj.tempOriginalARAmount ? 'Mui-disabled' : '' } }
                    >
                    </TextField>
                  </div>
                  <NativeDropDown
                    className='min-md'
                    id="Category_of_Service"
                      label="Category of Service"
                    value={props.values.categoryOfService}
                    onChange={props.handleChange('categoryOfService')}
                    inputProps={{ maxlength: 3 }}
                      disabled
                      title={ getLongDescription(props.COSData, props.values.categoryOfService) }
                    dropdownList={props.addDropdowns && props.addDropdowns['C1#C_COS_CD'] && 
                          props.addDropdowns['C1#C_COS_CD'].length > 0
                           ? props.addDropdowns['C1#C_COS_CD'].map(option => (
                             <option key={option.code} value={option.code}>
                            {option.description}
                             </option>
                        ))
                    : null}

                  />
                  <NativeDropDown
                    className='min-md'
                    isRequired
                    id="Fund_code"
                    label="Fund Code"
                    value={props.values.collocationCode}
                    onChange={props.handleChange('collocationCode')}
                      inputProps={ { maxlength: 10 } }
                    errTxt={props.errors[2] ? props.errorText[2] : null}
                      disabled
                      title={ getLongDescription(props.fundCodeData, props.values.collocationCode) }
                    dropdownList={props.addDropdowns && props.addDropdowns['R1#R_FUND_CD'] && 
                          props.addDropdowns['R1#R_FUND_CD'].length > 0
                           ? props.addDropdowns['R1#R_FUND_CD'].map(option => (
                             <option key={option.code} value={option.code}>
                            {option.description}
                             </option>
                        ))
                    : null}

                  />
                </div>
              </fieldset>
            </div>
            <div className="col-7" style={ { paddingLeft: '78px' } }>
              <fieldset className="custom-fieldset">
                <legend>Recoupment:</legend>
                <div className="form-wrapper">
                  <div className="mui-custom-form override-width-95">
                    { userInquiryPrivileges
                      ? <a onClick={ props.defaultRecoupment } style={ { color: 'blue', cursor: 'pointer' } }>
                        Use Payee Default Recoupment
                      </a>
                      : <a style={ { cursor: 'pointer' } }>
                        Use Payee Default Recoupment
                      </a>
                    }
                  </div>
                </div>
                <div className="form-wrapper">
                  <NativeDropDown
                    className='min-md'
                    id="Recoupment_Type_Code"
                      label="Recoupment Type Code"
                      value={ props.values.recoupmentTypeCode }
                    onChange={props.handleChange('recoupmentTypeCode')}
                    inputProps={{ maxlength: 1, 'data-testid': "recoupment_type_code_test" }}
                    errTxt={props.errors[3] ? props.errorText[3] : null}
                      disabled={ !userInquiryPrivileges ? !userInquiryPrivileges : props.values.balanceAmount === '0.00' }
                    dropdownList={props.addDropdowns && props.addDropdowns['F1#F_RECOUP_TY_CD'] && 
                          props.addDropdowns['F1#F_RECOUP_TY_CD'].length > 0
                           ? props.addDropdowns['F1#F_RECOUP_TY_CD'].map(option => (
                             <option key={option.code} value={option.code}>
                            {option.description}
                             </option>
                        ))
                    : null}

                  />
                  <div className="mui-custom-form min-md">
                    <TextField
                      required={ props.values.recoupmentTypeCode === 'A' }
                      fullWidth
                      id="standard-installment-value"
                      label="Installment Value"
                      placeholder=""
                      inputProps={ { maxlength: 14 } }
                      InputLabelProps={ {
                        shrink: true
                      } }
                      InputProps={ {
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      } }
                      value={ props.values.installmentValue }
                      onChange={ props.handleChange('installmentValue') }
                      disabled={ !userInquiryPrivileges ? !userInquiryPrivileges : props.values.recoupmentTypeCode === 'S' || props.values.balanceAmount === '0.00' }
                      helperText={ props.errors[10] ? props.errorText[10] : null }
                      error={ props.errors[10] ? props.errorText[10] : null }
                      onInput={ e => {
                        e.target.value = e.target.value.replace(
                          /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                          ''
                        );
                      } }
                    >
                    </TextField>
                  </div>
                </div>
                <div className="form-wrapper">
                  <div className="mui-custom-form min-md">
                    <TextField
                      id="standard-percent-value"
                      fullWidth
                      required={ props.values.recoupmentTypeCode === 'B' || props.values.recoupmentTypeCode === 'D' }
                      label="Percent Value"
                      placeholder=""
                      inputProps={ { maxlength: 14 } }
                      InputLabelProps={ {
                        shrink: true
                      } }
                      InputProps={ {
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      } }
                      value={ props.values.percentValue }
                      onChange={ props.handleChange('percentValue') }
                      onBlur={ props.formatPercent('percentValue') }
                      disabled={ !userInquiryPrivileges ? !userInquiryPrivileges : props.values.recoupmentTypeCode === 'S' || props.values.balanceAmount === '0.00' }
                      helperText={ props.errors[9] ? props.errorText[9] : null }
                      error={ props.errors[9] ? props.errorText[9] : null }
                      onInput={ e => {
                        e.target.value = e.target.value.replace(
                          /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                          ''
                        );
                      } }
                    >

                    </TextField>
                  </div>
                  { <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                    <div className="mui-custom-form with-date min-md">
                      <KeyboardDatePicker
                        maxDate={ Date.parse('31 Dec 9999 00:00:00 GMT') }
                        disabled={ !userInquiryPrivileges ? !userInquiryPrivileges : props.values.balanceAmount === '0.00' }
                        id="date-picker-dialog-startdate"
                        label="Start Date"
                        format="MM/dd/yyyy"
                        placeholder="mm/dd/yyyy"
                        minDate={ new Date() }
                        value={ props.selectedRecoupmentDate }
                        onChange={ props.handleRecoupmentDateChange }
                        InputLabelProps={ {
                          shrink: true
                        } }
                        KeyboardButtonProps={ {
                          'aria-label': 'change date'
                        } }
                        disabled={ !userInquiryPrivileges ? !userInquiryPrivileges : props.values.recoupmentTypeCode === 'S' || props.values.balanceAmount === '0.00' }
                        helperText={ props.errors[11] ? props.errorText[11] : null || props.errors[12] ? props.errorText[12] : null || props.errors[15] ? props.errors[15] : null }
                        error={ props.errors[11] ? props.errorText[11] : null || props.errors[12] ? props.errorText[12] : null || props.errors[15] ? props.errors[15] : null }
                        required={ props.values.recoupmentTypeCode === 'B' || props.values.recoupmentTypeCode === 'A' || props.values.recoupmentTypeCode === 'D' }
                      />
                    </div>
                  </MuiPickersUtilsProvider> }
                </div>
                <div className="form-wrapper">
                  <NativeDropDown
                    isRequired={props.values.recoupmentTypeCode === AppConstants.RECOUPMENTTYPECODE_PERCENTAMT || props.values.recoupmentTypeCode === AppConstants.RECOUPMENTTYPECODE_DOLLARAMT}
                    className='min-md'
                      id="standard-frequency"
                    label="Frequency"
                      value={ props.values.frequency }
                    onChange={props.handleChange('frequency')}
                    inputProps={{ maxlength: 2 }}
                      disabled={ !userInquiryPrivileges ? !userInquiryPrivileges : props.values.recoupmentTypeCode === 'S' || props.values.balanceAmount === '0.00' }
                    errTxt={props.errors[17] ? props.errorText[17] : null}
                    dropdownList={props.frequencyDataDropdown ? props.frequencyDataDropdown.map((option, index) => (
                      <option key={index} value={option.code}>
                          { option.description }
                      </option>
                      )) : null }
                  />
                </div>
              </fieldset>
            </div>
          </div>
        </div>
        <div className="col-12" style={ { paddingRight: '22px' } }>
          <fieldset className="custom-fieldset">
            <legend>Funding Source Allocation:</legend>
            <div className="form-wrapper">

              <div className="mui-custom-form">
                <TextField
                  id="standard-federal"
                  fullWidth
                  label="Federal"
                  placeholder=""
                  value={ props.values.federal }
                  InputProps={ {
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: true,
                    className: 'Mui-disabled'
                  } }
                  inputProps={ { maxlength: 14 } }
                  InputLabelProps={ {
                    shrink: true
                  } }
                >
                </TextField>
              </div>
              <div className="mui-custom-form">
                <TextField
                  id="standard-state"
                  fullWidth
                  label="State"
                  placeholder=""
                  value={ props.values.state }
                  InputProps={ {
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: true,
                    className: 'Mui-disabled'
                  } }
                  inputProps={ { maxlength: 14 } }
                  InputLabelProps={ {
                    shrink: true
                  } }
                >
                </TextField>
              </div>
              <div className="mui-custom-form">
                <TextField
                  id="standard-county"
                  fullWidth
                  label="County"
                  placeholder=""
                  value={ props.values.county }
                  InputProps={ {
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: true,
                    className: 'Mui-disabled'
                  } }
                  inputProps={ { maxlength: 14 } }
                  InputLabelProps={ {
                    shrink: true
                  } }
                >
                </TextField>
              </div>
              <div className="mui-custom-form">
                <TextField
                  id="standard-other"
                  fullWidth
                  label="Other"
                  placeholder=""
                  value={ props.values.other }
                  InputProps={ {
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: true,
                    className: 'Mui-disabled'
                  } }
                  inputProps={ { maxlength: 14 } }
                  InputLabelProps={ {
                    shrink: true
                  } }
                >
                </TextField>
              </div>
            </div>
          </fieldset>
        </div>
      </form>
    </div>
  );
}
