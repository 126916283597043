/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/WaiverActiontypes';

const initialState = {
};
const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SEARCH_WAIVER: 
        return { ...state, searchPayload: action.searchPayload};
    case actionTypes.CREATE_WAIVER: 
        return {...state, createPayload: action.createPayload};
    case actionTypes.UPDATE_WAIVER: 
        return {...state, updatePayload: action.updatePayload};
    case actionTypes.RESET_WAIVER:
        return { ...state,[action.keyName]: null};
      default: return state;
    }
};

export default reducer;
