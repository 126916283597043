/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

export const DialogTitle = withStyles()(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography>  
            <Typography variant='h6'>{children}</Typography>
            {onClose ? 
                <IconButton aria-label='close' onClick={onClose}>
                    <CloseIcon />
                </IconButton>
             : null}
        </MuiDialogTitle>
    );
});

export const DialogContent = withStyles(theme => ({
    // root: {
    //     padding: theme.spacing(2),
    // },
}))(MuiDialogContent);

export const DialogActions = withStyles(theme => ({
    // root: {
    //     margin: 0,
    //     padding: theme.spacing(1),
    // },
}))(MuiDialogActions);



