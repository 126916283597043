export const Begin_Date_Error = 'Begin Date is required.';
export const End_Date_Error = 'End Date is required.';
export const Invalid_Begin_Date_Error =
  'Begin Date that was entered is invalid.';
export const Invalid_End_Date_Error =
  'End Date that was entered is invalid.';
export const EVENT_GT_DT_ERROR =
  'End Date must be greater than or equal to begin date.';
export const BEGIN_TIME_INVALID = 'Begin Time format is invalid.';
export const END_TIME_INVALID = 'End Time format is invalid.';
export const USER_ID_NAME_ERR =
  'Please enter either User ID or Last Name for the search.';
  export const ERROR_OCCURED_DURING_TRANSACTION =
  'There was an error processing the request. Please retry the transaction.';
  export const NO_RECORDS_WITHSEARCH =
  'No records found for the search criteria entered.';
  export const KEY_VALUE_ERROR = 'Key is required when Key Type is entered.';
  export const KEY_TYPE_ERROR = 'Key Type is required when Key is entered.';
  export const SEARCH_BY = 'Search By is required.';
  export const INVALID_DATE = 'Invalid Date';
  export const CHANGE_DATE = 'Change date';
  export const PASSWORD_NEW_REQ = 'New Password is required.';
export const PASSWORD_CONFIRM_REQ = 'Confirm New Password is required.';
export const PASSWORD_REQ = 'Current Password is required.';
export const PASSWORD_LEN = 'The password must contain at least 10 characters.';
export const PASSWORD_3_OF_4 =
  'The password must not contain characters other than alphanumeric characters and the following non-alphanumeric characters: !, $, #, or %.';
export const PASSWORD_5_TIMES =
  'The new password must not be one of the 5 previously used passwords. Please try again.';
export const PASSWORD_AND_CONFIRM_SAME =
  'New and Confirm New passwords do not match. Please try again.';
  export const SUCCESS_MESSAGE = 'Password changed sucessfully.';
  export const INVALID_PWD = 'Unauthorized. Invalid credential has been provided';
  export const INVALID_PWD_MSG = 'Current password provided is incorrect. Please try again..';
  export const EMPTY_PWD_NULL = 'UserID / Old Password / New Password Cannot be Null or Empty.';
  export const  EMPTY_PWD_NULL_MSG ="Current password provided is incorrect. Please try again.";
  export const  PREVIOUS_PWD = 'The new password must not be any of the user’s previous 5 passwords.';
  export const OLD_NEW_PWD_MSG = "Old Password and New Password should not be same";
  export const TRANSACTION_NUMBER_FORMAT = 'should not contain spaces or special characters.';
