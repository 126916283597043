/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import { NavLink } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as SecurityConstants from './Modules/Security/Components/SecurityConstants';
import ErrorMessages from './SharedModules/Errors/ErrorMessages';
import * as service_constants from './SharedModules/services/service';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: "",
            password: "",
            showPassword: false,
            accessBox: false,
            updatesBox: true,
            linksBox: true,
            values: null,
            checkBox :false,
            void:false,
            imagesArray: ['images/banner1.jpg', 'images/banner2.jpg', 'images/banner3.jpg'],
            count: 0,
            userNameError: "",
            passwordError:"",
            userNameErrorState: false,
            passwordErrorState: false,
            formErrors: {
                userName: "",
                password: ""
            },
            formErrorsState: {
                userName: false,
                password: false
            },
            accessArray: [
                'Apply for Health benefits',
                'Food stamp program',
                'Temporary assist program',
                'MO Healthnet programs for Aged, Blind and Disabled',
                'Child Care/Early Childhood Services',
                'Apply for Child Support Services',
                'Apply for Other programs'
            ],
            updatesArray: [
                'Health benefits',
                'Food stamp program',
                'Temporary assist program'
            ],
            linksArray: [
                'FAQ',
                'System Integration',
                'Data Services',
                'Eligibility and Enrollment',
                'Provider Enrollment',
                'Claims',
                'Benefit Management Services'
            ]
        };
        this.handleChange = this.handleChange.bind(this);
        this.sTimer = setInterval(this.rightImages, 5000)
        this.validateField = this.validateField.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
        this.validateUserId = this.validateUserId.bind(this);
        this.handleCheckBox = this.handleCheckBox.bind(this);
    }
    componentWillUnmount() {
        clearInterval(this.sTimer)
    }
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.userDoesntExist) {
            this.setState({ formErrors: { userName: SecurityConstants.USER_NAME_DOESNT_EXIST } });
            this.setState({ formErrorsState: { userName: true } });
        }
    }
    handleChange = (name) => event => {
        
        if (name == 'userName'){
            let value = event.target.value?.toUpperCase();
            this.setState({ [name]: value });
            this.validateField(name, value);
        }
        else{
            let value = event.target.value;
            this.setState({ [name]: value });
            this.validateField(name, value);
        }
       
    }

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };
    handleCheckBox = () => {
        this.setState((state)=>({void: !state.void}));
    }
    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    validateUserId = () => {
        this.props.setUserDoesntExist(false);
        var regex = /^[a-zA-Z0-9-_.]+$/;
        var _userIdErr = false;
        var _pwdErr=false;
        var _userIdErrMsg = '';
        var _userPwdErrMsg = '';
        var _ipValid = true;
        var _pwdValid = true;
        if (this.state.userName === '' || this.state.userName === null || this.state.userName === undefined) {
            _ipValid = false;
            _userIdErr = true;
            _userIdErrMsg = SecurityConstants.UNAME_REQUIRED_LOGIN;
        } else {
            if (this.state.userName.toString().length < 6) {
                _ipValid = false;
                _userIdErr = true;
                _userIdErrMsg = SecurityConstants.USER_ID_MIN_SIX_CHARACTERS_LOGIN;
            } else {
                if (!regex.test(this.state.userName)) {
                    _ipValid = false;
                    _userIdErr = true;
                    _userIdErrMsg = SecurityConstants.USER_ID_VALIDATION_ERROR_LOGIN;
                } else if (this.state.userName.toString().length > 6){
                    _ipValid = true;
                    _userIdErr = false;
                    this.setState({ userNameErrorState: _userIdErr });
                    this.setState({ userNameError: _userIdErrMsg });
                }
            }
        }

        if (this.state.password === '' || this.state.password === null || this.state.password === undefined) {
            _pwdValid = false;
            _pwdErr = true;
            _userPwdErrMsg = SecurityConstants.PWD_REQUIRED;
        }
        if (!_ipValid) {
            this.setState({ userNameError: _userIdErrMsg });
            this.setState({ userNameErrorState: _userIdErr });
        }
        if (!_pwdValid){
            this.setState({ passwordError: _userPwdErrMsg });
            this.setState({ passwordErrorState: _pwdErr });
        }
        else{
            this.setState({ passwordError: _userPwdErrMsg });
        }
        

        if(_pwdValid && _ipValid && !this.state.void){
            this.setState({checkBox : true})
        }
        else{
            this.setState({checkBox : false})
        }
        if (_ipValid && this.state.void) {
            this.props.setLoginDone(this.state.userName,this.state.password)
        }
        if (
            (this.state.userName === 'DWILLIAMS150891' ||
            this.state.userName === 'MASTERUSER') &&
            this.state.password === ''
          ){
            _pwdValid = true;
            _pwdErr = false;
            _ipValid = true;
            _userIdErr = false;
            this.setState({ passwordErrorState: _pwdErr });
          }
        localStorage.setItem("isNormalUser","true");
    };
    redirectToSSO = (e) => {
        window.location = service_constants.GET_USER_PRIVILEGES_SSO;
    }
    handleRedirect = (e) => {
        e.preventDefault()
    }
    rightImages = () => {
        if (this.state.count == this.state.imagesArray.length - 1) {
            this.setState({
                count: 0
            })
        } else {
            this.setState({
                count: this.state.count + 1
            })
        }
    }

    leftImages = () => {
        if (this.state.count == 0) {
            this.setState({
                count: 2
            })
        } else {
            this.setState({
                count: this.state.count - 1
            })
        }
    }

    showLessOrMore = param => {
        if (param === 'access') {
            this.setState({
                accessBox: !this.state.accessBox,
                linksBox: true,
                updatesBox: true
            });
        } else if (param === 'updates') {
            this.setState({
                accessBox: true,
                linksBox: true,
                updatesBox: !this.state.updatesBox

            });

        } else if (param === 'links') {
            this.setState({
                accessBox: true,
                linksBox: !this.state.linksBox,
                updatesBox: true

            });

        }
    }

    validateField(FieldName, value) {
        let errorMessage = "";
        switch (FieldName) {
            case "userName":
                if (value === '')
                    errorMessage = "Please provide your username*";
                break;
            case "password":
                if (value === '')
                    errorMessage = "Please provide your password*";
                break;
            default:
                break;

        }
        this.setState({ errorMessage: { ...this.state.formErrors, [FieldName]: errorMessage } });
    }

    render() {
        return (
            <div className="h-login-container">
                <div className="container-fluid">
                    <div className="row login-container">
                        <div className='col-md-8 col-sm-12 bg-white login-image-section'>
                            <div className="login-image">
                                <img src={this.state.imagesArray[this.state.count]} alt="login image" />
                            </div>
                            <div className="row h-100 position-relative">
                                <div className="col-md-8 col-sm-12 py-5 py-md-0">
                                    <div className="carosuel-tools">
                                        <div className="carousel-arrows" onClick={() => this.leftImages()}>
                                            <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                                        </div>
                                        <div className="carousel-text">
                                            <div>
                                                Welcome to
                                    </div>
                                            <div>
                                                CMdS-Financial
                                    </div>
                                            <div style={{ display: 'flex' }}>
                                                <div className="carousel-underline"></div>
                                                <div className="carosuel-count">{this.state.count + 1} of {this.state.imagesArray.length}</div>
                                            </div>
                                        </div>
                                        <div className="carousel-arrows" onClick={() => this.rightImages()}>
                                            <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 no-padding position-relative h-100 py-5 py-md-0">
                                    <div className="no-scrollbar overflow-auto" style={{ height: 'calc(100vh - 40px)' }} >
                                        <div className={clsx('content-box green-border')}>
                                            <div className="content-box-heading">
                                                Quick Access
                                    {
                                                    !this.state.accessBox ? <span className="heading-toggle" onClick={() => this.showLessOrMore('access')}>
                                                        show less<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
                                                    </span> : <span className="heading-toggle" onClick={() => this.showLessOrMore('access')}>
                                                            show more<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
                                                        </span>
                                                }
                                                <div className="clearfix"></div>
                                            </div>
                                            <div className="content-body">
                                                <ul className="list-group">
                                                    {!this.state.accessBox ?
                                                        this.state.accessArray.map((p, i) => (
                                                            <li key={i} className="list-group-item">
                                                                {p}
                                                            </li>
                                                        )) : this.state.accessArray.slice(0, 2).map((p, i) => (
                                                            <li key={i} className="list-group-item">
                                                                {p}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={clsx('content-box purple-border')}>
                                            <div className="content-box-heading">
                                                Updates
                                        <label className="label label-danger">NEW</label>
                                                {
                                                    !this.state.updatesBox ? <span className="heading-toggle" onClick={() => this.showLessOrMore('access')}>
                                                        show less<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
                                                    </span> : <span className="heading-toggle" onClick={() => this.showLessOrMore('updates')}>
                                                            show more<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
                                                        </span>
                                                }
                                                <div className="clearfix"></div>
                                            </div>
                                            <div className="content-body">
                                                <ul className="list-group">
                                                    {!this.state.updatesBox ?
                                                        this.state.updatesArray.map((p, i) => (
                                                            <li key={i} className="list-group-item">
                                                                {p}
                                                            </li>
                                                        )) : this.state.updatesArray.slice(0, 2).map((p, i) => (
                                                            <li key={i} className="list-group-item">
                                                                {p}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={clsx('content-box green-border')}>
                                            <div className="content-box-heading">
                                                Quick Links
                                        {
                                                    !this.state.linksBox ? <span className="heading-toggle" onClick={() => this.showLessOrMore('links')}>
                                                        show less<i className="fa fa-long-arrow-up" aria-hidden="true"></i>
                                                    </span> : <span className="heading-toggle" onClick={() => this.showLessOrMore('links')}>
                                                            show more<i className="fa fa-long-arrow-down" aria-hidden="true"></i>
                                                        </span>
                                                }
                                                <div className="clearfix"></div>
                                            </div>
                                            <div className="content-body">
                                                <ul className="list-group">
                                                    {!this.state.linksBox ?
                                                        this.state.linksArray.map((p, i) => (
                                                            <li key={i} className="list-group-item">
                                                                {p}
                                                            </li>
                                                        )) : this.state.linksArray.slice(0, 2).map((p, i) => (
                                                            <li key={i} className="list-group-item">
                                                                {p}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 bg-white loginInputWrapper py-5 py-md-0">
                            <div
                                onKeyPress={(ev) => ev && ev.key === "Enter" && this.validateUserId()}
                                className="login-credential-box"
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        id="standard-adornment-username"
                                        label="UserName"
                                        required
                                        value={this.state.userName}
                                        onChange={this.handleChange('userName')}
                                        type="text"
                                        defaultValue='Username'
                                        inputProps={{ minLength: 6, maxLength: 16, style: { textTransform: 'uppercase' } }}
                                        error={this.state.userNameErrorState ? this.state.userNameError : this.props.userNameReqErr
                                            ? SecurityConstants.USERNAME_REQ_ERR
                                            : this.props.userNameMinLenErr
                                            ? SecurityConstants.USERNAME_MIN_LEN_ERR
                                            : this.props.userNameInvalidErr
                                            ? SecurityConstants.USERNAME_EXIST_ERR
                                            : null}
                                        helperText={this.state.userNameError ? this.state.userNameError :  this.props.userNameReqErr
                                            ? SecurityConstants.USERNAME_REQ_ERR
                                            : this.props.userNameMinLenErr
                                            ? SecurityConstants.USERNAME_MIN_LEN_ERR
                                            : null}
                                    />
                                </FormControl>
                                <div className='clearfix'></div>
                                <div style={{ paddingTop: '15px' }}></div>
                                <FormControl fullWidth>

                                    <TextField
                                        id="standard-adornment-Password"
                                        label="Password"
                                        required
                                        value={this.state.password}
                                        onChange={this.handleChange('password')}
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        inputProps={{ maxLength: 15 }}
                                        error={this.state.passwordErrorState ? this.state.passwordError : false}
                                        helperText={
                                            this.state.passwordError ? this.state.passwordError : false                                       
                                        }
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                    onMouseDown={this.handleMouseDownPassword}
                                                >
                                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>,
                                        }}
                                    />
                                    <div className='clearfix'></div>
                                </FormControl>
                                {
                            this.props.userNameMinLenErr ? SecurityConstants.USERNAME_MIN_LEN_ERR :
                            this.props.userNameInvalidErr ?<p
                            className="MuiFormHelperText-root Mui-error Mui-required"
                            role="alert"
                            > {SecurityConstants.USERNAME_EXIST_ERR}</p>:
                                this.props.userLockedErr ? <p
                                className="MuiFormHelperText-root Mui-error Mui-required"
                                role="alert"
                                >{SecurityConstants.USER_LOCKED_ERR_MESSAGE}</p> :         
                                this.props.invalidPasswordErr ?  <p
                                className="MuiFormHelperText-root Mui-error Mui-required"
                                role="alert"
                                > {SecurityConstants.USERNAME_EXIST_ERR}</p> 
                                : null
                }
                                <p><a title="Forgot password" href="#" className="forgot-password">
                                Forgot Password? Please contact your system administrator.
                                </a></p>
                                <input type="checkbox" id="terms-condition" name="terms-condition" value="void" onChange={this.handleCheckBox} />
                                <label htmlFor="terms-condition" className='termscondition'>I accept all the terms & conditions</label>
                                {this.state.checkBox ? <p style={{ fontStyle: '0.75rem' }} className="MuiFormHelperText-root Mui-error Mui-required">Please accept Terms and Conditions</p> : null}
                                <NavLink onClick={this.validateUserId} className="btn btn-login" to='/dashboard'>Log On</NavLink>
                                <NavLink onClick={this.redirectToSSO} className="btn btn-success-sso" to='/dashboard'>Log On With SSO</NavLink>
                                <ErrorMessages errorMessages={this.props.loginErrorMessages} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(Login)