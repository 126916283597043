import * as actionTypes from '../../Actions/systemParameter/systemParameterActionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SEARCH_SYSTEM_PARAMETER:
      return { payload: action.systemParameters };
    case actionTypes.SEARCH_SYSTEM_PARAMETER9047:
      return { data: action.systemParameters9047 };
    case actionTypes.ROW_SEARCH_SYSTEM_PARAMETER:
      return { rowSearchSysParam: action.rowsearchsysparam };
    case actionTypes.UPDATE_SYSTEM_PARAMETER:
      return { updateSystemParameter: action.updateSystemParameter };
    case actionTypes.ADD_SYSTEM_PARAMETER:
      return { addSystemParameter: action.addSystemParameter };
    case actionTypes.FUNCTIONAL_AREA_DROPDOWN:
      return { functionalDropDown: action.functionalAreaDropDown };
    case actionTypes.SYSTEM_ERROR:
      return { systemError: action.systemError };
    case actionTypes.RESETDATA:
      return action.resetData;
    case actionTypes.AUDIT_LOG_TABLE:
      return { [action.keyName]: action.payload };
    default: return state;
  }
};

export default reducer;
