/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import * as ARSearchConstants from './ARSearchConstants';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withRouter } from 'react-router';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import {
  searchClaimsReceivableAction,
  searchMCNAccountReceivableAction,
  resetFCNSearchAccountReceivable,
  searchFCNAccountReceivableAction
} from '../../../Store/Actions/accountReceivable/accountReceivableActions';
import AccountRecevSearchResultsComponent from './AccountRecevSearchResultsComponent';
import { removeLeadingTrailingSpaces } from '../../../../../SharedModules/CommonUtilities/commonUtilities';

/* ManualCheckNumberInquiry()
 * Used to search for MCN number
 */
function ManualCheckNumberInquiry (props) {
  const dispatch = useDispatch();
  const onReset = () => dispatch(resetFCNSearchAccountReceivable());

  const onOutstandingRowSearch = values =>
    dispatch(searchClaimsReceivableAction(values));
  const claimsReceivableData = useSelector(
    state => state.accountReceivableState.payloadClaims
  );
  const searchValues = useSelector(state => state.accountReceivableState.searchValues);
  const [showTable, setShowTable] = React.useState(false);
  const [isSearch, setIsSearch] = React.useState(false);
  let errorMessagesArray = [];
  const [errorMeg, setErrorMeg] = React.useState('');
  let payloadMCN = [];
  let payloadFCN = [];
  const [payeeTableData, setPayeeTableData] = React.useState(null);
  const [values, setValues] = React.useState({
    mcn: ''
  });
  const [singleSearch, setSingleSearch] = React.useState(false);

  const onSearch = values =>
    dispatch(
      searchMCNAccountReceivableAction(removeLeadingTrailingSpaces(values))
    );
  payloadMCN = useSelector(state => state.accountReceivableState.payloadMcns);
  const onSearchFCN = values =>
    dispatch(searchFCNAccountReceivableAction(values));
  payloadFCN = useSelector(state => state.accountReceivableState.payload);
  const [
    manualCheckNumberStartsWith,
    setManualCheckNumberStartsWith
  ] = React.useState(false);

  /* Show error messages if record not found for MCN number entered */
  useEffect(() => {
    if (payloadMCN != null && payloadMCN.length === 0) {
      errorMessagesArray.push(ARSearchConstants.NO_RECORDS_FOUND);
      props.showError(errorMessagesArray);
      setShowTable(false);
    }
    if (payloadMCN != null && payloadMCN.length > 0) {
      payloadMCN.map(data => {
        if (
          data.payeePayerTypeCode != null &&
          data.payeePayerTypeCode !== undefined
        ) {
          for (var pTypeCode in props.payeeTypeData) {
            if (
              props.payeeTypeData[pTypeCode].code === data.payeePayerTypeCode
            ) {
              data.payeePayerTypeCode =
                props.payeeTypeData[pTypeCode].description;
            }
          }
        }
        for (var key in props.initialReasonCodeArray) {
          if (props.initialReasonCodeArray[key].code === data.reasonCode) {
            data.reasonCode = props.initialReasonCodeArray[key].description;
          }
        }
        data.establishedDate = data.establishedDate
          ? moment(data.establishedDate).format('L')
          : data.establishedDate;
      });
      setPayeeTableData(payloadMCN);
      errorMessagesArray = [];
      props.showFieldError(
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      );
      props.showError(errorMessagesArray);
      setShowTable(true);
    }

    if (payloadMCN != null && payloadMCN.length === 1) {
      if (!singleSearch) {
        setShowTable(false);
        if (
          payloadMCN[0].accountReceivableReasonCode === '119' ||
        payloadMCN[0].accountReceivableReasonCode === '119-ARClaimFA'
        ) {
          onOutstandingRowSearch(payloadMCN[0].receivableFCN);
        } else if (
          payloadMCN[0].activityTypeCode === 'AP' ||
        payloadMCN[0].activityTypeCode === 'AP-Adv Pymt' ||
        payloadMCN[0].accountReceivableReasonCode === '107' ||
        payloadMCN[0].accountReceivableReasonCode === '108' ||
        payloadMCN[0].accountReceivableReasonCode === '107-ARMAdvPmt' ||
        payloadMCN[0].accountReceivableReasonCode === '108-ARMAdvPmt'
        ) {
          onSearchFCN(payloadMCN[0].receivableFCN);
          let advancePaymentData = {};
          if (payloadFCN) {
            advancePaymentData = payloadFCN;
            props.history.push({
              pathname: '/AdvancePayment',
              state: { advancePaymentData, values: values }
            });
          }
        } else if (
          payloadMCN[0].activityTypeCode === 'CR' ||
        payloadMCN[0].activityTypeCode === 'CR-Claims Rec'
        ) {
          props.history.push({
            pathname: '/ClaimsReceivable',
            state: { claimsReceivableData: payloadMCN[0], values: values }
          });
        } else if (payloadMCN[0].activityTypeCode === 'WL') {
          props.history.push({
            pathname: '/FinancialAccountLienLevyUpdate',
            state: { withHoldSearchResult: payloadMCN[0], values: values }
          });
        } else if (payloadMCN[0].activityTypeCode === 'GL') {
          props.history.push({
            pathname: '/FinancialAccountGrossLevelUpdate',
            state: { claimsReceivableData: payloadMCN[0], values: values }
          });
        } else {
          props.history.push({
            pathname: '/FinancialAccountGrossLevelUpdate',
            state: { claimsReceivableData: payloadMCN[0] }
          });
        }
      } else {
        setShowTable(true);
        const newPayload = [];
        newPayload.push(payloadMCN);
        setPayeeTableData(newPayload);
      }
    }

    if (payloadMCN && payloadMCN.systemFailure !== undefined) {
      errorMessagesArray.push(ARSearchConstants.TRANSACTION_ERROR);
      props.showError(errorMessagesArray);
      setShowTable(false);
    }
  }, [payloadMCN]);

  useEffect(() => {
    if (payloadFCN != null && payloadFCN !== '') {
      if (!singleSearch) {
        if (
          payloadFCN.activityTypeCode === 'CR' ||
        payloadFCN.activityTypeCode === 'CR-Claims Rec'
        ) {
          props.history.push({
            pathname: '/ClaimsReceivable',
            state: { claimsReceivableData: payloadFCN, values: values }
          });
        } else if (payloadFCN.activityTypeCode === 'WL') {
          const withHoldSearchResult = payloadFCN;
          props.history.push({
            pathname: '/FinancialAccountLienLevyUpdate',
            state: { withHoldSearchResult, values: values }
          });
        } else if (payloadFCN.activityTypeCode === 'GL') {
          props.history.push({
            pathname: '/FinancialAccountGrossLevelUpdate',
            state: { claimsReceivableData: payloadFCN, values: values }
          });
        } else if (payloadFCN.activityTypeCode === 'AP') {
          const advancePaymentData = payloadFCN;
          props.history.push({
            pathname: '/AdvancePayment',
            state: { advancePaymentData, values: values }
          });
        } else {
          props.history.push({
            pathname: '/FinancialAccountGrossLevelUpdate',
            state: { claimsReceivableData: payloadFCN, values: values }
          });
        }
      } else {
        setShowTable(true);
        const newPayload = [];
        newPayload.push(payloadMCN);
        setPayeeTableData(newPayload);
      }
    }
  }, [payloadFCN]);
  /* Redirecting to Claims Receivable screen */
  if (claimsReceivableData != null && claimsReceivableData !== '') {
    if (
      claimsReceivableData.activityTypeCode === 'CR' ||
      claimsReceivableData.activityTypeCode === 'CR-Claims Rec'
    ) {
      props.history.push({
        pathname: '/ClaimsReceivable',
        state: { claimsReceivableData, values: values }
      });
    } else if (claimsReceivableData.activityTypeCode === 'WL') {
      const withHoldSearchResult = claimsReceivableData;
      props.history.push({
        pathname: '/FinancialAccountLienLevyUpdate',
        state: { withHoldSearchResult, values: values }
      });
    } else if (claimsReceivableData.activityTypeCode === 'GL') {
      props.history.push({
        pathname: '/FinancialAccountGrossLevelUpdate',
        state: { claimsReceivableData, values: values }
      });
    }
  }

  const valuesFromAREdit = props?.history?.state?.values;
  useEffect(() => {
    if (props.location.tableRender && !isSearch) {
      if (valuesFromAREdit !== undefined) {
        setSingleSearch(true);
        props.showFieldError(
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false
        );
        errorMessagesArray = [];
        props.showError(errorMessagesArray);

        const searchCriteria = {
          manualCheckNumber: valuesFromAREdit.manualCheckNumber !== '' ? valuesFromAREdit.manualCheckNumber : null,
          manualCheckNumberStartsWith: valuesFromAREdit.manualCheckNumberStartsWith,
          manualCheckNumSearch: valuesFromAREdit.manualCheckNumSearch
        };
        onSearch(searchCriteria);
        setValues({ ...valuesFromAREdit, mcn: valuesFromAREdit.manualCheckNumber });
        setManualCheckNumberStartsWith(valuesFromAREdit.manualCheckNumberStartsWith);
      };
    }
  }, [props.location.tableRender]);

  useEffect(() => {
    if (searchValues) {
      setValues({ ...values, mcn: searchValues.manualCheckNumber });
      setManualCheckNumberStartsWith(searchValues.manualCheckNumberStartsWith);
    }
  }, [searchValues]);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChangeStartsWith = () => {
    setManualCheckNumberStartsWith(!manualCheckNumberStartsWith);
  };

  /* Restting error messgaes and MCN field value */
  const resetMCN = () => {
    setManualCheckNumberStartsWith(false);
    setValues({ mcn: '' });
    errorMessagesArray = [];
    props.showFieldError(
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false
    );
    props.showError(errorMessagesArray);
    setShowTable(false);
    onReset();
  };

  /* Search MCN
   * Validate MCN field for empty and valid data entered
   * If validations passed search for MCN number by hitting API
   */

  const searchMCN = () => {
    sessionStorage.setItem('tabValue', 2);
    setIsSearch(true);
    setSingleSearch(false);
    if (isNullOrEmpty(values.mcn)) {
      setValues({ mcn: '' });
      props.showFieldError(
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      );
      errorMessagesArray = [];
      if (manualCheckNumberStartsWith) {
        errorMessagesArray.push(ARSearchConstants.STARTS_WITH_REQUIRED);
        setErrorMeg(ARSearchConstants.STARTS_WITH_REQUIRED);
      } else {
        errorMessagesArray.push(ARSearchConstants.MCN_REQUIRED);
        setErrorMeg(ARSearchConstants.MCN_REQUIRED);
      }
      props.showError(errorMessagesArray);
      setShowTable(false);
    } else if (manualCheckNumberStartsWith && values.mcn.length <= 1) {
      props.showFieldError(
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      );
      errorMessagesArray = [];
      errorMessagesArray.push(ARSearchConstants.STARTS_WITH_REQUIRED);
      setErrorMeg(ARSearchConstants.STARTS_WITH_REQUIRED);
      props.showError(errorMessagesArray);
      setShowTable(false);
    } else {
      props.showFieldError(
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      );
      setShowTable(false);
      errorMessagesArray = [];
      props.showError(errorMessagesArray);

      const searchCriteria = {
        manualCheckNumber: values.mcn !== '' ? values.mcn : null,
        manualCheckNumberStartsWith:
          manualCheckNumberStartsWith === true
            ? manualCheckNumberStartsWith
            : false,
        manualCheckNumSearch: true
      };
      onSearch(searchCriteria);
      if (!props.history.state) {
        props.history.state = {};
      }
      props.history.state.values = searchCriteria;
    }
  };

  /* Check if text enetered is empty or contains only white spaces */
  const isNullOrEmpty = str => {
    return !str || !str.trim();
  };

  return (
    <div className="pos-relative w-100 h-100">
      <div>
        <div className="form-wrapper">
          <div className="mui-custom-form input-md">
            <TextField
              id='ARSearch_2'
              required
              label={ARSearchConstants.MCN_TEXT}
              value={values.mcn}
              onChange={handleChange('mcn')}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{ maxLength: 15 }}
              helperText={props.showMCNError ? errorMeg : null}
              error={props.showMCNError ? errorMeg : null}
            />
            <div className="sub-radio">
              <FormControlLabel
                value="startWith"
                control={
                  <Checkbox
                    color="primary"
                    checked={manualCheckNumberStartsWith}
                    value={manualCheckNumberStartsWith}
                    onChange={handleChangeStartsWith}
                  />
                }
                label="Starts With"
                labelPlacement="end"
              />
            </div>
          </div>
        </div>
        <div className="float-right mb-2 mr-3">
          <Button className="btn-tab-pannel" onClick={() => searchMCN()}>
            <i className="fa fa-search" aria-hidden="true"></i>Search
          </Button>

          <Button
            variant="outlined"
            color="primary"
            className="bt-tabreset btn-transparent ml-1"
            onClick={() => resetMCN()}
          >
            <i className="fa fa-undo" aria-hidden="true"></i>Reset
          </Button>
        </div>
        <div className="clearfix"></div>
        {showTable ? (
          <div className="tab-holder my-3 p-0 tableNoWrap">
            <AccountRecevSearchResultsComponent
              tableData={payloadMCN || payeeTableData}
              payeeTypeCodeDataInitial={props.payeeTypeCodeDataInitial}
              values = {values}
              dropdowns={props.dropdowns}
            />
          </div>
        ) : null}

      </div>
    </div>
  );
}

export default withRouter(ManualCheckNumberInquiry);
