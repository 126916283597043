/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../../Actions/systemList/systemListActionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SEARCH_SYSTEM_LIST:
      return { ...state, [action.dType || 'payload']: action.systemLists  };
    case actionTypes.SEARCH_SYSTEM_LIST1021:
      return { ...state, [action.dType]: action.systemLists1021 };
	  case actionTypes.SEARCH_SYSTEM_LIST9003:
      return { ...state, payload9003Data: action.systemLists9003};
    case actionTypes.SEARCH_SYSTEM_LIST_9026:
      return { ...state, [action.dType || 'payload9026']: action.systemLists9026  };
    case actionTypes.SEARCH_SYSTEM_LIST_9027:
      return { ...state, [action.dType || 'payloadV9027']: action.payloadV9027  };
    case actionTypes.SYSTEM_LIST_VIEW:
      return { systemListViewData: action.rowsearchsyslist };
    case actionTypes.RESET_DATA:
      return action.resetData;
    case actionTypes.SYSTEM_LIST_ADD:
      return { systemListAddResponse: action.systemListAddResponse };
    case actionTypes.SYSTEM_LIST_UPDATE:
      return { systemListUpdateResponse: action.systemListUpdateResponse };
    case actionTypes.AUDIT_LOG_TABLE:
      return { ...state, auditData: action.payload };
    case actionTypes.AUDIT_LOG_TABLE_ROW:
      return { ...state, auditRowData: action.payload };
    default: return state;
  }
};

export default reducer;
