/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const BEG_FUND_TY_CD_REQ = 'Begin Fund Type Code is required.';
export const PRIORITY_NUMBER_REQ = 'Priority number is required.';
export const TOS_DESCRIPTION_REQ = 'CMS64 TOS Description is required.';
export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const NO_RECORDS_FOUND = 'No records found for the search criteria entered.';
export const CMS_FORM_CD_REQ = 'CMS Form Code is required.';
export const CMS_COL_CD_REQ = 'CMS Column Code is required.';
export const INVALID_DATE = 'Please Enter the date in correct format.';
export const END_DATE_GREATER_THAN_BG_DT = 'The End Date must be greater than or equal to the Begin Date.'

