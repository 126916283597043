/* eslint-disable camelcase */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const FARINQUIRYSET_HEADER = 'Receipt Adjustment';
export const DATA_ELEMENT_NAME = 'Date Element Name';
export const FIELD_VALUE = 'Field Value';
export const FUNCTIONAL_AREA_REQUIRED = 'Functional Area Code is a required field if Valid Value Code is entered.';
export const BUSINESS_NAME_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const DATA_ELEMENT_NAME_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const NEW_ORIGINAL_AMOUNT_REQUIRED = 'New Original Amount is required.';
export const REASON_CODE_REQUIRED = 'Reason Code is required.';
//Modified as part of this DF-60033
export const GREATER_THAN_ZERO = 'New Original Amount Must Be Greater Than Zero.';
export const INVALID_FORMAT_ERROR = 'Please enter the data in correct format.';

