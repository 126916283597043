/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import PropTypes from 'prop-types';

export default function DisplaySuccessMessages (props) {
  return <div>{props.successMessages.length > 0 ? <div className="alert alert-success custom-alert" role="alert" >
    {props.successMessages.map((message, index) => <li key ={index}>{message}</li>)
    }
  </div> : null
  }</div>;
}

DisplaySuccessMessages.propTypes = {
  successMessages: PropTypes.array.isRequired
};
