/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import TableComponent from '../../../../SharedModules/Table/Table';

const headCells = [
  { id: 'financialCtrlNumber', disablePadding: false, label: 'FCN', width: '15%', isRedirectable: true, openInNewTab: true },
  {
    id: 'reasonCodeDesc', disablePadding: false, label: 'Reason Code', width: '10%',
    isTooltip: true,
    isTitle: 'reasonCodeLongDesc'
  },
  { id: 'effectiveDate', disablePadding: false, label: 'Effective Date', width: '10%', isDate: true },
  { id: 'paidDate', disablePadding: false, label: 'Last Applied Date', width: '10%', isDate: true },
  { id: 'beginCreditBalance', disablePadding: false, label: 'Begin Credit Balance', width: '20%', isBalance: true },
  { id: 'endCreditBalance', disablePadding: false, label: 'End Credit Balance', width: '20%', isBalance: true },
  { id: 'netChange', disablePadding: false, label: 'Net Change', width: '15%', isBalance: true }
];

function ARCreditBalanceTable(props) {
  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          // getting respective record from dropdown list which also has desc
          const reasonType =
            props.addDropdowns &&
            props.addDropdowns['F1#F_RSN_CD'] &&
            props.addDropdowns['F1#F_RSN_CD'].filter(
              value => value.code === each.reasonCode
            );
          //updating the record with desc
          each.reasonCodeDesc =
            reasonType && reasonType.length > 0
              ? reasonType[0].description
              : each.reasonCodeDesc ? each.reasonCodeDesc : '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };
  return (
    <TableComponent
      headCells={headCells}
      tableData={getTableData(props.tableData)}
      align={'left'}
      isSearch={true}
      onTableRowClick={props.onRowClick}
    />
  );
}

export default ARCreditBalanceTable;
