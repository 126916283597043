/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './mapSetActionTypes';
import * as serviceEndPoint from '../../../services/service';
import { commonAPIHandler } from '../../../../../SharedModules/ServerErrorHandler';

import axios from 'axios';
const headers = {
  'Access-Control-Allow-Origin': '*'
};

export const getAuditLogDetailsAction = (tableName, keyValue, auditData) => {
  let body = {
    "tableName": tableName,
    "keyValue": keyValue
  }
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}

export const setAuditLogData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE,
    payload: data,
    keyName
  };
};

export const getAuditLogRowDetailsAction = (body, auditData) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_MULTIPLE_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogRowData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}

export const setAuditLogRowData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE_ROW,
    payload: data,
    keyName
  };
};

export const resetSearchMapSet = () => {
  return {
    type: actionTypes.RESETDATA,
    resetData: []
  };
};

export const resetAddMapSet = () => {
  return {
    type: actionTypes.RESET_ADD_DATA
  };
};
//RESET_PAYLOAD_DATA
export const resetpayloadMapSet = () => {
  return {
    type: actionTypes.RESET_PAYLOAD_DATA
  };
};
export const mapSetAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.MAP_SET_SEARCH_ENDPOINT}`, value, { headers: headers })

      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(setMapSet(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setMapSet(sendingResponse));
      });
  };
};
export const mapSetActionView = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.MAP_SET_SEARCH_ENDPOINT}`, value, { headers: headers })
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(setmapSetView(sendingResponse));
      }).catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setmapSetView(sendingResponse));
      });
  };
};
export const systemListActions = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SYSTEM_LIST_SEARCH_ENDPOINT_NEW}`, value)
      .then(response => {

        let sendingResponse = commonAPIHandler(response, true);
        dispatch(setSystemList(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSystemList(sendingResponse));
      });
  };
};
export const mapDefinitionAdd = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.MAP_DEFINITION_ADD}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setAddMapDefinition(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setAddMapDefinition(sendingResponse));
      });
  };
};
export const mapDefinitionUpdate = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.MAP_DEFINITION_UPDATE}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setUpdateMapDefinition(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setUpdateMapDefinition(sendingResponse));
      });
  };
};
export const setMapSet = (mapSet) => {
  return {
    type: actionTypes.MAP_SET_ACTION_TYPE,
    mapSet: mapSet
  };
};
export const setmapSetView = (mapSetView) => {
  return {
    type: actionTypes.MAP_SET_VIEW_ACTION_TYPE,
    mapSetView: mapSetView
  };
};
export const setAddMapDefinition = (addResponse) => {
  return {
    type: actionTypes.MAP_DEFINITION_ADD_TYPE,
    addResult: addResponse
  };
};
export const setUpdateMapDefinition = (updateResponse) => {
  return {
    type: actionTypes.MAP_DEFINITION_UPDATE_TYPE,
    updateResult: updateResponse
  };
};
export const setSystemList = (systemList) => {
  return {
    type: actionTypes.SEARCH_SYSTEM_LIST,
    systemLists: systemList
  };
};
