/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Bootstrap, { Button } from 'react-bootstrap';
import { withRouter } from 'react-router';


function MCNInquiryResults (props) {
  const tableRecord = props.location.state.payloadMCN;

  const [values, setValues] = React.useState({
    lob: '',
    payeeType: '',
    payeeIDTypeCode: '',
    fcn: '',
    payeeName: '',
    reasonCode: ''
  });

  useEffect(() => {
    setValues({
      lob: tableRecord[0].lob ? tableRecord[0].lob : '',
      payeeType: tableRecord[0].payeePayerTypeCode ? tableRecord[0].payeePayerTypeCode : '',
      payeeIDTypeCode: tableRecord[0].payeePayerType ? tableRecord[0].payeePayerType : '',
      fcn: tableRecord[0].receivableFCN ? tableRecord[0].receivableFCN : '',
      payeeName: tableRecord[0].entityName ? tableRecord[0].entityName : '',
      reasonCode: tableRecord[0].reasonCode ? tableRecord[0].reasonCode : ''
    });
  }, [tableRecord]);

  return (
    <div className="tabs-container">
      <div className="tab-header">
        <div className="tab-heading float-left">
                    Gross Level Detail
        </div>
        <div className="clearfix"></div>
      </div>
      <div>
        <form noValidate autoComplete="off">
          <div className='form-wrapper'>
            <div className="mui-custom-form input-md">
              <TextField
                id="lob"
                disabled={true}

                label="LOB"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.lob}
              />
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="payeeType"
                disabled={true}
                label="Payee type"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.payeeType}
              />
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="payeeIDTypeCode"
                disabled={true}
                label="Payee ID Type Code"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.payeeIDTypeCode}
              />
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="fcn"
                disabled={true}
                label="FCN"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.fcn}
              />
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="payeeName"
                disabled={true}
                label="Payee Name"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.payeeName}
              />
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="reasonCode"
                disabled={true}
                label="Reason code"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.reasonCode}
              />
            </div>
          </div>
        </form>

      </div>
    </div>
  );
}

export default withRouter((MCNInquiryResults));
