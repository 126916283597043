/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as serviceEndPoint from '../../../../SharedModules/services/service';
import axios from 'axios';
import * as actionTypes from '../Actions/FundCodeSearchActionType';
import { commonAPIHandler } from '../../../../SharedModules/ServerErrorHandler';

export const getAuditLogDetailsAction = (tableName, keyValue, auditData) => {
  let body = {
    "tableName": tableName,
    "keyValue": keyValue
  }
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}

export const setAuditLogData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE,
    payload: data,
    keyName
  };
};

export const getAuditLogRowDetailsAction = (tableName, keyValue, auditData) => {
  let body = {
    "tableName": tableName,
    "keyValue": keyValue
  }
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogRowData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}

export const resetResponse =()=>{
  return {
    type: actionTypes.RESET_RESPONSE,
  }
}

export const setAuditLogRowData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE_ROW,
    payload: data,
    keyName
  };
};


export const searchCriteriaAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FUND_CODE_SEARCHCRITERIA}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse === null) {
          dispatch(setSearchDetails([]));
        } else {
          dispatch(setSearchDetails(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSearchDetails(sendingResponse));
      });
  };
};
export const setInternalError = (data) => {
  return {
    type: actionTypes.FUND_CODE_SEARCH_ERROR,
    searchError: data
  };
};
export const setSearchDetails = (data) => {
  return {
    type: actionTypes.FUND_CODE_SEARCH_CRITERIA,
    searchResults: data
  };
};
export const resetFundCodeSearch = () => {
  return {
    type: actionTypes.RESETDATA,
    resetData: []
  };
};

export const addFundCode = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FUND_CODE_ADD}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setFundCodeAdd([]));
        } else {
          dispatch(setFundCodeAdd(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error)
        dispatch(setFundCodeAdd(sendingResponse));
      });
  };
};

export const setFundCodeAdd = (addFundCodeData) => {
  return {
    type: actionTypes.FUND_CODE_ADD,
    addFundCodeData: addFundCodeData
  };
};

export const updateFundCode = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FUND_CODE_UPDATE}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setFundCodeUpdate([]));
        } else {
          dispatch(setFundCodeUpdate(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setFundCodeUpdate(sendingResponse));
      });
  };
};

export const setFundCodeUpdate = (updateFundCodeData) => {
  return {
    type: actionTypes.FUND_CODE_UPDATE,
    updateFundCodeData: updateFundCodeData
  };
};

export const searchCriteriaRowAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FUND_CODE_SEARCHCRITERIA}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse === null) {
          dispatch(setSearchRowDetails([]));
        } else {
          dispatch(setSearchRowDetails(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setSearchRowDetails(sendingResponse));
      });
  };
};

export const setSearchRowDetails = (rowData) => {
  return {
    type: actionTypes.FUND_CODE_SEARCH_ROW_CRITERIA,
    rowSearchResults: rowData
  };
};

export const getDropdownDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.DROPDOWNS}`, value)
      .then(response => {
        dispatch(setDropdownData(response.data));
      })
      .catch(error => {
        dispatch(setDropdownData(error));
      });
  };
};

export const setDropdownData = (dropdownData) => {
  return {
    type: actionTypes.DROPDOWNDATA,
    dropdownData: dropdownData
  };
};

export const getMapIDList = (values) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.MAP_ID_DROPDOWN}` + '?mapType=' + values)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setMapId([]));
        } else {
          dispatch(setMapId(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setMapId(sendingResponse));
      });
  };
};

export const setMapId = (mapIdData) => {
  return {
    type: actionTypes.MAP_ID,
    mapIdData: mapIdData
  };
};

export const getBenefitPlanIDList = () => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.BENEFIT_PLAN_ID_DROPDOWN}`)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setBenefitPlanId([]));
        } else {
          dispatch(setBenefitPlanId(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setBenefitPlanId(sendingResponse));
      });
  };
};

export const setBenefitPlanId = (benefitPlanIdData) => {
  return {
    type: actionTypes.BENEFIT_PLAN_ID,
    benefitPlanIdData: benefitPlanIdData
  };
};
