import * as actionTypes from '../Actions/ActionTypes';

const axiosPayLoad = {
  payload: null,
  userData:'',
  refreshTokenData: null,
  refreshTokenTime: null
};
const reducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.USERROLES:
      return{ ...state, payload: action.roles,userData:action.userData };
    case actionTypes.REFRESH_TOKEN:
      return{ ...state, refreshTokenData: action.refreshTokenData, refreshTokenTime: new Date() };
    default: return state;
  }
};

export default reducer;
