/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { redirectReceiptFCN } from '../../../../SharedModules/TabRedirections/TabRedirections';
import { Link } from 'react-router-dom';
import { CLAIMS_INQUIRY_TCN_LINK } from '../../../../SharedModules/services/service';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { TRANSACTION_NUMBER_FORMAT } from '../../../../SharedModules/Errors/ErrorMsgConstant';

export default function OriginalRequest(props) {
  const [value, setValue] = React.useState(0);
  const handleChanges = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const [collationCodeDD,setCollationCodeDD]= React.useState([])
  const [fundCodeValue,setFundCodeValue]=React.useState('')
  const dropdown = useSelector(
    state => state.financialReceiptState.dropdownData
  );
useEffect(()=>{
  if (dropdown&&dropdown.listObj["Financial#3200"]) {
    setCollationCodeDD(dropdown.listObj["Financial#3200"]);
  }
},[dropdown])
  const disableField = true;
useEffect(()=>{  
  if(collationCodeDD){
    let fundCodedesc=collationCodeDD && collationCodeDD.find(value => value.code === props.values.fundCode)
    setFundCodeValue(fundCodedesc && fundCodedesc.description)
  }
},[collationCodeDD])

  return (
    <form autoComplete="off">
      <div className="tab-holder my-2">
        <fieldset className="r-custom-fieldset no-padding">
          <legend>Original Request</legend>
          <div className="form-wrapper">
            <div className="mui-custom-form override-width-22 override-m-1 has-link">
              <TextField
                id="original-fcn-code"
                fullWidth
                label="FCN"
                InputProps={{
                  readOnly: true, className: 'Mui-disabled', startAdornment: (
                    <InputAdornment position="start">
                      <Link to={redirectReceiptFCN(props.values.fcn)}>
                        {props.values.fcn}
                      </Link>
                    </InputAdornment>
                  )
                }}
                placeholder=""
                InputLabelProps={{
                  shrink: true
                }}
              >
              </TextField>
            </div>
            <div className="mui-custom-form with-date override-width-22 override-m-1 with-date">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                  formnovalidate
                  id="original-begin_date"
                  label="Established Date"
                  format="MM/dd/yyyy"
                  placeholder="mm/dd/yyyy"
                  InputLabelProps={{
                    shrink: true
                  }}
                  name="begin_date"
                  value={props.values.establishedDate}
                  required
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  disabled={disableField}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="mui-custom-form override-width-22 override-m-1 with-icon with-icon-margin">
              <TextField
                id="original-original-ar"
                fullWidth
                label="Original Amount"
                inputProps={{ maxlength: 11 }}
                value={props.values.originalAmount}
                disabled={disableField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
            <div className="mui-custom-form override-width-22 override-m-1">
              <TextField
                id="original-select-reasoncode"
                fullWidth
                label="Reason Code"
                value={props.values.reasonCode}
                inputProps={{ maxLength: 3 }}
                onChange={handleChanges('reasoncode')}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={disableField}
                title={props.originalReasonCodeLD}
              ></TextField>
            </div>
          </div>
          <div className="form-wrapper">
            <div className="mui-custom-form override-width-22 override-m-1 has-link">
              <TextField
                id="original-related-ft"
                fullWidth
                label="Related FT TCN"
                InputProps={{
                  readOnly: true, className: 'Mui-disabled',
                  startAdornment: (
                    <InputAdornment position="start">
                      <a href={CLAIMS_INQUIRY_TCN_LINK + props.values.relatedfttcn} target='_blank'>
                        {props.values.relatedfttcn}
                      </a>
                    </InputAdornment>
                  )
                }}
                placeholder=""
                InputLabelProps={{
                  shrink: true
                }}
              >
              </TextField>
            </div>
            <div className="mui-custom-form override-width-22 override-m-1">
              <TextField
                id="original-bal-amount"
                fullWidth
                label="Balanced Amount"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
                inputProps={{ maxlength: 11 }}
                value={props.values.balanceamount}
                disabled={disableField}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
            <div className="mui-custom-form override-width-22 override-m-1">
              <TextField
                id="original-fund-code"
                fullWidth
                label="Fund Code"
                value={fundCodeValue}
                disabled={disableField}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
            <div className="mui-custom-form override-width-22 override-m-1">
              <TextField
                id='ATRnumber-orginal'
                fullWidth
                label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
                placeholder=""
                disabled={disableField}
                value={props.values.atrNumber||""}
                inputProps={{ maxlength: 18 }}
                InputLabelProps={{
                  shrink: true,
                }}
              ></TextField>
            </div>
          </div>
        </fieldset>
      </div>
    </form>
  );
}
