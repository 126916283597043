/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../../Actions/budgetMaintenance/budgetMaintenanceActionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SEARCH_BUDGET_MAINTENANCE:
      return { payload: action.budgets };
    case actionTypes.ROW_SEARCH_BUDGET_MAINTENANCE:
      return { rowSearchBudget: action.rowSearchBudget };
    case actionTypes.RESETDATA:
      return action.resetData;
    case actionTypes.ADD_BUDGET_MAINTENANCE_SAVE:
      return { BudgetMaintenanceAddSaveResponse: action.BudgetMaintenanceAddSaveResponse };
    case actionTypes.ADD_BUDGET_MAINTENANCE_VIEW:
      return { budgetsview: action.budgetsview };
    case actionTypes.UPDATE_BUDGET_MAINTENANCE_SAVE:
      return { BudgetMaintenanceUpdateSaveResponse: action.BudgetMaintenanceUpdateSaveResponse };
    case actionTypes.SEARCH_BUDGET_MAINTENANCE_ID:
      return { searchBudgetID: action.searchBudgetID };
    case actionTypes.DROPDOWNDATA:
      return { dropdownData: action.dropdownData };
    case actionTypes.SUB_DROPDOWNDATA:
      return { subdropdownData: action.subdropdownData };
    case actionTypes.END_DATE_API:
      return { budgetEndDateInfo: action.budgetEndDateInfo };
    case actionTypes.BUDEGT_NOTES_USAGE_DROPDOWN:
      return { usageDropDown: action.usageDropDown };
    case actionTypes.AUDIT_LOG_TABLE:
      return { auditDetails: action.payload }
    case actionTypes.AUDIT_LOG_TABLE_AUDIT:
      return { auditRowDetails: action.payload }
    default: return state;
  }
};

export default reducer;
