/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const SEARCH_BUDGET_MAINTENANCE = 'SEARCH_BUDGET_MAINTENANCE';
export const ROW_SEARCH_BUDGET_MAINTENANCE = 'ROW_SEARCH_BUDGET_MAINTENANCE';
export const RESETDATA = 'RESETDATA';
export const FUND_CODE_SEARCH_CRITERIA = 'FUND_CODE_SEARCH_CRITERIA';
export const FUND_CODE_SEARCH_ERROR = 'FUND_CODE_SEARCH_ERROR';
export const FUND_CODE_ADD = 'FUND_CODE_ADD';
export const SEARCH_FUND_CODE = 'SEARCH_FUND_CODE';
export const SAVE_REISSUE_CHECK_REPLACEMENT = 'SAVE_REISSUE_CHECK_REPLACEMENT';
export const RECEIPT_DROPDOWNS = 'RECEIPT_DROPDOWNS';
export const SYSTEM_LIST_RECEIPT = "SYSTEM_LIST_RECEIPT";
