/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './FinancialInquiryActionTypes';
import * as serviceEndPoint from '../../../../../SharedModules/services/service';
import axios from 'axios';
import { commonAPIHandler } from '../../../../../SharedModules/ServerErrorHandler/index';
import { DEFAULT_LOB_VALUE } from '../../../../../SharedModules/AppConstants';


export const getDropdownDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.DROPDOWNS}`, value)
      .then(response => {
        dispatch(setDropdownData(response.data));
      })
      .catch(error => {
        dispatch(setDropdownData(error));
      });
  };
};

export const setDropdownData = (dropdownData) => {
  return {
    type: actionTypes.DROPDOWNDATA,
    dropdownData: dropdownData
  };
};

export const FinancialInquirySearchAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.FINANCIAL_INQUIRY_SEARCH}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse.length === 0) {
          dispatch(setFinancialInquirySearch([]));
        } else {
          dispatch(setFinancialInquirySearch(sendingResponse.enterpriseSearchresultVO.searchResults));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setFinancialInquirySearch(sendingResponse));
      });
  };
};

export const setFinancialInquirySearch = (data) => {
  return {
    type: actionTypes.SEARCH_FINANCIAL_INQUIRY,
    searchRecord: data
  };
};

export const GetPayeeIdTypeCodeAction = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.PAYOUT_PAYEE_ID_TYPE_CODE_ENDPOINT}?payeeTypeCode=${value}`)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setPayeeIdTypeCodeDetails(sendingResponse.payeeIdTypeCode));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setPayeeIdTypeCodeDetails(sendingResponse));
      });
  };
};

export const setPayeeIdTypeCodeDetails = (payeeIDTypeCodeData) => {
  return {
    type: actionTypes.GET_PAYEEIDTYPECODE_ON_PAYEETYPE,
    payeeIDTypeCodeDetails: payeeIDTypeCodeData
  };
};

export const FinancialClaimDetails = (value) => {
  const userLoginState = JSON.parse(
    window.localStorage.getItem('loginState')
  );
  const requestBody = {
    "userId": userLoginState?.logInUser? userLoginState.logInUser: null,
    "userLastName": userLoginState.userName,
    "functionCode": "FINANCIAL-INQUIRY",
    "keyType": "G_ENTY_EXTL_ID",
    "key": value.payeeID,
    "lobCode": DEFAULT_LOB_VALUE,
    "cmnEntitySK": value.commonEntitySK || value.cmnEntitySK || value,
  }
  return dispatch => {
    return axios.post(serviceEndPoint.FINANCIAL_CLAIM_SUMMARY_DETAILS, requestBody)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse.respcode === '02') {
          dispatch(setFinancialClaimDetails([]));
        } else {
          dispatch(setFinancialClaimDetails(sendingResponse.enterpriseSearchresultVO.searchResults));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setFinancialClaimDetails(error, sendingResponse));
      });
  };
};

export const setFinancialClaimDetails = (data) => {
  return {
    type: actionTypes.FINANCIAL_CLAIM_SUMMARY_DETAILS,
    summaryDetails: data
  };
};
