/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import { withRouter } from "react-router";

import TableComponent from "../../../../../SharedModules/Table/Table";

const headCells = [
  {
    id: "receivableFCN",
    numeric: false,
    disablePadding: true,
    label: "FCN",
    width: "20%",
    enableHyperLink: true
  },
  {
    id: "reasonCodeDesc",
    numeric: false,
    disablePadding: false,
    label: "Original A/R Reason Code",
    width: "20%",
    isTooltip: true,
    isTitle: 'resonCodeLongDesc'
  },
  {
    id: "establishedDate",
    numeric: false,
    disablePadding: false,
    label: "Established Date",
    width: "18%",
    isDate: true
  },
  {
    id: "originalAmount",
    numeric: false,
    disablePadding: false,
    label: "Original A/R Amount",
    width: "18%",
    isBalance: true
  },
  {
    id: "balanceAmount",
    numeric: false,
    disablePadding: false,
    label: "Balance Amount",
    width: "18%",
    isBalance: true
  },
  {
    id: "transferreasonCode",
    numeric: false,
    errorKey: false,
    disablePadding: false,
    isSelectBox: true,
    label: "Reverse Reason code",
    width: "28%",
    dropDownData: []
  }
];

function BulkReverseTable (props) {
  const formatSearchCriteria = row => {
    return (row.receivableFCN);
  };

  const editRow = (row) => (event) => {
    const payloadData = formatSearchCriteria(row);
    props.history.push({
      pathname: "/ARBulkTransfer",
      state: { payloadData }
    });
  };
  React.useEffect(() => {
    if (props.reasonCodeDropDown) {
      headCells[6].dropDownData = props.reasonCodeDropDown;
    }
  }, [props.reasonCodeDropDown]);
  const tableComp = (
    <TableComponent
      print={ props.print }
      pathTo='/FinancialAccountGrossLevelUpdate?data='
      formatSearchCriteria={ formatSearchCriteria }
      fixedTable
      fixedView
      headCells={ headCells }
      isSearch={ true }
      dropDownData={ props.dropDownData }
      handleDropDown={ props.handleTableReasonCode }
      tableData={ props.tableData ? props.tableData : [] }
      onTableRowClick={ editRow }
      defaultSortColumn={ headCells[0].id }
      setRsnCdData={ props.setRsnCdData }
      order={ 'desc' }

    />
  );
  return tableComp;
}
export default withRouter(BulkReverseTable);
