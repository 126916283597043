/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import { Link } from 'react-router-dom';
import React from 'react';
import { CLAIMS_INQUIRY_TCN_LINK } from '../../SharedModules/services/service';
export function LinkOfFcn(props) {
    return (
        <div>
            {
                props.saveFlag ?
                    <Link to={props.redirection(props.value)}>
                        {props.value}
                    </Link>
                    : <span style={{ color: '#000000' }}>{props.value}</span>
            }
        </div>
    );
}
export function LinkOfTcn(props) {
    return (
        <div>
            {
                props.saveFlag ?
                    <a href={CLAIMS_INQUIRY_TCN_LINK + props.value} target='_blank'>
                        {props.value}
                    </a>
                    : <span style={{ color: '#000000' }}>{props.value}</span>
            }
        </div>
    )
}