/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const VALIDATE_USER_ID_ACTION_TYPE = 'VALIDATE_USER_ID';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const GET_WORKUNIT_LIST = 'GET_WORKUNIT_LIST';
export const GET_LOB_ADD_USER = 'GET_LOB_ADD_USER';
export const UPDATE_EXISTING_USER = 'UPDATE_EXISTING_USER';
export const USER_VALID_ROLES_ACTION = 'USER_VALID_ROLES_ACTION';
export const  CHANGE_PASSWORD = 'CHANGE_PASSWORD';