/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/


import React from 'react';
import Swal from 'sweetalert2';

function CheckSave (props) {
  Swal.fire({
    customClass: {
      confirmButton: 'btn btn-primary mr-3',
      container: 'unsaved-changes-popup'
    },
    buttonsStyling: false,
    heightAuto: false,
    icon: 'warning',
    html: '<h4>No changes to save.</h4 >',
    confirmButtonText: 'OK'
  });

  return (
    <React.Fragment></React.Fragment>
  );
}
export default CheckSave;
