/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/FundCodeSearchActionType';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.DROPDOWNDATA:
      return { ...state, dropdownData: action.dropdownData };
    case actionTypes.MAP_ID:
      return { ...state, mapIdData: action.mapIdData };
    case actionTypes.BENEFIT_PLAN_ID:
      return { ...state, benefitPlanIdData: action.benefitPlanIdData };
    case actionTypes.FUND_CODE_SEARCH_CRITERIA:
      return { ...state, searchResults: action.searchResults };
    case actionTypes.FUND_CODE_SEARCH_ERROR:
      return { ...state, searchError: action.searchError };
    case actionTypes.FUND_CODE_ADD:
      return { ...state, addFundCodeData: action.addFundCodeData };
    case actionTypes.FUND_CODE_UPDATE:
      return { ...state, updateFundCodeData: action.updateFundCodeData };
    case actionTypes.FUND_CODE_SEARCH_ROW_CRITERIA:
      return { ...state, rowSearchResults: action.rowSearchResults };
    case actionTypes.RESETDATA:
      return action.resetData;
    case actionTypes.AUDIT_LOG_TABLE:
      return { auditDetails: action.payload }
    case actionTypes.AUDIT_LOG_TABLE_ROW:
      return { auditRowDetails: action.payload }
      case actionTypes.RESET_RESPONSE:
        return {...state, searchResults: null}  
    default: return state;
  }
};
export default reducer;
