/* eslint-disable no-unused-vars */
/**

* © [2024] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from "react";
import Iframe from "./Iframe";
import * as endPointURLs from "../../SharedModules/services/service";
import { SITE_MAP } from "../../SharedModules/Navigation/RouteConstants";

function FOOTERLINKS(props) {
  const [source, setSource] = useState();
  useEffect(() => {
    if (props.location && props.location.state) {
      if (props.location.state === PRIVACY_POLICY) {
        setSource(endPointURLs.PRIVACY_POLICY);
      } else if (props.location.state === TERMS_OF_USE) {
        setSource(endPointURLs.TERMS_OF_USE);
      } else if (props.location.state === BROWSER_REQUIREMENTS) {
        setSource(endPointURLs.BROWSER_REQUIREMENTS);
      } else if (props.location.state === ACCESSIBILITY_COMPLIANCE) {
        setSource(endPointURLs.ACCESSIBILITY_COMPLIANCE);
      } else if (props.location.state === SITEMAP) {
        props.history.push(SITE_MAP)
      } else {
        setSource(endPointURLs.PRIVACY_POLICY);
      }
    }
  }, [props.location]);

  return (
    <>
      <div className="pos-relative">
        <Iframe source={source} />
      </div>
    </>
  );
}
export const PRIVACY_POLICY = 'PRIVACY_POLICY';
export const TERMS_OF_USE = 'TERMS_OF_USE';
export const BROWSER_REQUIREMENTS = 'BROWSER_REQUIREMENTS';
export const ACCESSIBILITY_COMPLIANCE = 'ACCESSIBILITY_COMPLIANCE';
export const SITEMAP = 'SITEMAP';
export default FOOTERLINKS;
