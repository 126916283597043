/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const SEARCH_SYSTEM_LIST = 'SEARCH_SYSTEM_LIST';
export const SEARCH_SYSTEM_LIST1021 = 'SEARCH_SYSTEM_LIST1021';
export const SEARCH_SYSTEM_LIST_9026 = 'SEARCH_SYSTEM_LIST_9026';
export const SEARCH_SYSTEM_LIST_9027 = 'SEARCH_SYSTEM_LIST_9027';
export const RESET_DATA = 'RESET_DATA';
export const SYSTEM_LIST_VIEW = 'SYSTEM_LIST_VIEW';
export const SYSTEM_LIST_ADD = 'SYSTEM_LIST_ADD';
export const SYSTEM_LIST_UPDATE = 'SYSTEM_LIST_UPDATE';
export const AUDIT_LOG_TABLE = "AUDIT_LOG_TABLE";
export const AUDIT_LOG_TABLE_ROW = "AUDIT_LOG_TABLE_ROW";
export const SEARCH_SYSTEM_LIST9003 = "SEARCH_SYSTEM_LIST9003"