/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import { withRouter } from 'react-router';

import TableComponent from '../../../../../SharedModules/Table/Table';

const headCells = [
  {
    id: 'listNumber',
    numeric: false,
    disablePadding: true,
    label: 'List Number',
    width: '20%',
    enableHyperLink: true
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    isDescription: true,
    width: '20%',
    isVarChar: true
  },
  {
    id: 'listTypedesc',
    numeric: false,
    disablePadding: false,
    label: 'List Type',
    width: '20%'
  },
  {
    id: 'dataElementName',
    numeric: false,
    disablePadding: false,
    label: 'Data Element Name',
    width: '20%'
  },
  {
    id: 'functionalAreaDesc',
    numeric: false,
    disablePadding: false,
    label: 'Functional Area',
    width: '20%'
  }
];

function SystemListTable (props) {
  const [redirect, setRedirect] = React.useState(0);

  const { values } = props;

  const formatSearchCriteria = _row => ({
    listNumber: _row.listNumber,
    functionalArea: _row.functionalArea,
    dataElementName: _row.dataElementName,
    description: _row.description,
    listType: _row.listType,
    listBusinessName: _row.listBusinessName,
    funcAreaDesc: _row.funcAreaDesc
  });

  const editRow = row => event => {
    const payloadData = formatSearchCriteria(row);
    const valuetoredirect = redirect + 1;
    setRedirect(valuetoredirect);
    props.history.push({
      pathname: '/SystemListAddUpdate',
      state: { payloadData, values }
    });
  };

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const functionalArea =
            props.dropdowns &&
            props.dropdowns['Reference#R_FUNC_AREA_CD'] &&
            props.dropdowns['Reference#R_FUNC_AREA_CD'].filter(
              value => value.code === each.functionalArea
            );
          each.functionalAreaDesc =
          functionalArea && functionalArea.length > 0
              ? functionalArea[0].description
              : each.functionalArea ? each.functionalArea : '';
              const listType = props.listTypeDrop && props.listTypeDrop.listObj && props.listTypeDrop.listObj['Reference#2'] && props.listTypeDrop.listObj['Reference#2'].filter(value => value.code === each.listType);
              each.listTypedesc = listType && listType.length > 0
                ? listType[0].description
                : each.listType ? each.listType : '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  const tableComp = (
    <TableComponent
      print={props.print}
      pathTo="/SystemListAddUpdate?data="
      formatSearchCriteria={formatSearchCriteria}
      fixedTable
      fixedView
      headCells={headCells}
      isSearch={true}
      tableData={getTableData(props.tableData ? props.tableData : [])}
      onTableRowClick={editRow}
      defaultSortColumn={headCells[0].id}
    />
  );
  return tableComp;
}
export default withRouter(SystemListTable);
