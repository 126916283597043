import React, { memo, forwardRef } from 'react';
import MicroFrontend from './MicroFrontend';

const { REACT_APP_ROUTE_ENV: CommonHost } = process.env;

function MicrofrontendComponent (props, ref) {
  const { history, privileges } = props;

  const containerId = props.id ? props.id : `${props.type}-container`;
  const injectableHost = `${CommonHost}injectableModule`;
  return (
    <>
      <div className='mfComponent'>
        <MicroFrontend
          ref={ref}
          history={history}
          privileges={privileges}
          componentProps={props}
          host={injectableHost}
          name={props.type}
          containerId={containerId}

          setScriptLoad={props.setScriptLoad}
        />
      </div>
    </>
  );
}

export function arePropsEqual (prevProps, nextProps) {
  return prevProps.id === nextProps.id && nextProps.type === 'Notes';
}

export default memo(forwardRef(MicrofrontendComponent, arePropsEqual));
