/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { Component, useState } from 'react';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TextEditor.scss';
import * as Icons from '@material-ui/icons/Palette';

function CustomizedEditor(props) {
  const [editorState, setEditorState] = useState(null);
  const [textList, setTextList] = useState([]);
  // eslint-disable-next-line camelcase
  const [word_count, setWordCount] = useState(props.total_characters);
  React.useEffect(() => {
    if (props.editorState && props.editorState !== null) {
      const value = convertFromHTML(props.editorState);
      const defaultData = ContentState.createFromBlockArray(value);
    }
  }, [props.editorState]);
  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    setWordCount(props.total_characters - newEditorState.getCurrentContent().getPlainText().length);
    props.editorData(newEditorState.getCurrentContent().getPlainText(), draftToHtml(convertToRaw(newEditorState.getCurrentContent())));
  };

  const onAddText = () => {
    if (editorState) {
      const tempTextList = [...textList];
      tempTextList.push(draftToHtml(convertToRaw(editorState.getCurrentContent())));
      setTextList(tempTextList);
      setEditorState(null);
    }
  };
  const listsText = textList.map(function (list, index) {
    return (
      <div key={index}>{ReactHtmlParser(list)}</div>
    );
  });
  return (
    <div>
      <div className='editor'>
        <Editor
          ariaLabel={props.ariaLabel}
          ariaDescribedBy={props.ariaDescribedBy}
          EditorState={editorState === null ? EditorState.createEmpty()
            : editorState}
          handleBeforeInput={val => {
            const textLength = editorState.getCurrentContent().getPlainText().length;
            if (val && textLength >= props.total_characters) {
              return 'handled';
            }
            return 'not-handled';
          }}
          handlePastedText={val => {
            const textLength = editorState.getCurrentContent().getPlainText().length;
            return ((val.length + textLength) >= props.total_characters);
          }}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'history'],
            inline: {
              bold: { icon: Icons.bold, className: 'demo-option-custom' },
              italic: { icon: Icons.italic, className: 'demo-option-custom' },
              underline: { icon: Icons.underline, className: 'demo-option-custom' },
              strikethrough: { icon: Icons.strikethrough, className: 'demo-option-custom d-none' },
              monospace: { className: 'demo-option-custom d-none' },
              superscript: { icon: Icons.superscript, className: 'demo-option-custom d-none' },
              subscript: { icon: Icons.subscript, className: 'demo-option-custom d-none' }
            },
            blockType: { className: 'demo-option-custom-wide', dropdownClassName: 'demo-dropdown-custom' },
            fontSize: { className: 'demo-option-custom-medium' },
            list: {
              unordered: { icon: Icons.unordered, className: 'demo-option-custom' },
              ordered: { icon: Icons.ordered, className: 'demo-option-custom' },
              indent: { icon: Icons.indent, className: 'demo-option-custom d-none' },
              outdent: { icon: Icons.outdent, className: 'demo-option-custom d-none' }
            },
            textAlign: {
              left: { icon: Icons.left, className: 'demo-option-custom' },
              center: { icon: Icons.center, className: 'demo-option-custom' },
              right: { icon: Icons.right, className: 'demo-option-custom' },
              justify: { icon: Icons.justify, className: 'demo-option-custom' }
            },
            fontFamily: {
              options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
              className: 'demo-option-custom-wide',
              dropdownClassName: 'demo-dropdown-custom'
            },
            colorPicker: { className: 'demo-option-custom', popupClassName: 'demo-popup-custom' },
            emoji: { className: 'demo-option-custom', popupClassName: 'demo-popup-custom' },
            history: {
              undo: { icon: Icons.undo, className: 'demo-option-custom' },
              redo: { icon: Icons.redo, className: 'demo-option-custom' }
            }
          }}
        />
      </div>
      <div>
      </div>
    </div>
  );
};
export default CustomizedEditor;
