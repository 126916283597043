/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { Component } from "react";
import { withRouter } from "react-router";
import ErrorMessages from "../Errors/ErrorMessages";
import * as AppConstants from "../AppConstants.js";
import { FOOTER_LINKS, My_ACCOUNT, SITE_MAP } from "../Navigation/RouteConstants";
class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.checkAccessibility = this.checkAccessibility.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
  }
  checkAccessibility() {
    const { history } = this.props;
    let menuItems = localStorage.getItem("loginState") && JSON.parse(localStorage.getItem("loginState")).menuItems || [];
    let returnFlag = true;
    
    const publicAccessURLs = ['/', '/dashboard', FOOTER_LINKS, SITE_MAP , My_ACCOUNT];
  
    const urlInMenus = (e, path) => {
      if(path === e.rootUrl || path === e.url || publicAccessURLs.includes(path)
        || e.assocURLs?.some(n => path.includes(n))) {
        return true;
      }
      if(e.children?.length){
        return e.children?.some(el => urlInMenus(el, path));
      }
      return false;
    };


    if(menuItems.length){
      returnFlag =  menuItems?.some(e=> urlInMenus(e, history.location.pathname));
    }
    
    if(returnFlag){
      this.setState({
      hasError: false
      });
      return true;
      }else{
        this.setState({
        hasError: true,
        errorMsg: "Either you don't have permission to access this page or this page does not exist."
        });
        return false;     
        }
    }

  
  componentDidMount() {
  
    this.checkAccessibility();
  }
    
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.checkAccessibility();
    }
  
  }

  render() {
    if (this.state.hasError && this.state.errorMsg) {
      return (
        <div className="pos-relative w-100 h-100">
          <ErrorMessages errorMessages={[this.state.errorMsg]} />
        </div>
      );
    }
    if (this.state.hasError && !this.state.errorMsg) {
      return (
        <div className="pos-relative w-100 h-100">
          <ErrorMessages errorMessages={[AppConstants.ERR_PROCESSING_REQ]} />
        </div>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorHandler);
