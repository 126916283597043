/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const PAYMENT_STATUS_INQUIRY_SEARCH = 'PAYMENT_STATUS_INQUIRY_SEARCH';
export const SEARCH_RESULTS_RESET = 'SEARCH_RESULTS_RESET';
export const AUDIT_LOG_TABLE = "AUDIT_LOG_TABLE";
