/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import { withRouter } from 'react-router';

import TableComponent from '../../../SharedModules/Table/Table';

const headCellsMultiRecord = [
  {
    id: 'fcn',
    disablePadding: false,
    label: 'FCN',
    enableHyperLink: true,
    isDate: false,
    width: '15.5%',
    fontSize: 14
  },
  {
    id: 'transDate',
    disablePadding: false,
    label: 'Established Date',
    enableHyperLink: false,
    isDate: true,
    width: '14%',
    fontSize: 14
  },
  {
    id: 'reasonCode',
    disablePadding: false,
    label: 'Reason Code',
    enableHyperLink: false,
    isDate: false,
    width: '11%',
    fontSize: 14,
    isTooltip: true,
    isTitle: 'resonCodeLongDesc'
  },
  {
    id: 'entityType',
    disablePadding: false,
    label: 'Payee Type',
    enableHyperLink: false,
    isDate: false,
    width: '10%',
    fontSize: 14
  },
  {
    id: 'payerID',
    disablePadding: false,
    label: 'Payee ID',
    enableHyperLink: false,
    isDate: false,
    width: '16%',
    fontSize: 14
  },
  {
    id: 'entityName',
    disablePadding: false,
    label: 'Payee Name',
    enableHyperLink: false,
    isDate: false,
    width: '16%',
    fontSize: 14
  },

  {
    id: 'originalAmount',
    disablePadding: false,
    label: 'Original Amount',
    isBalance: true,
    enableHyperLink: false,
    isDate: false,
    width: '12%',
    fontSize: 14
  },
  {
    id: 'balanceAmount',
    disablePadding: false,
    label: 'Balance Amount',
    isBalance: true,
    enableHyperLink: false,
    isDate: false,
    width: '12%',
    fontSize: 14
  }
];
const headCellsSingle = [
  {
    id: 'fcn',
    disablePadding: false,
    label: 'FCN',
    enableHyperLink: true,
    isDate: false,
    width: '15.5%',
    fontSize: 14
  },
  {
    id: 'establishedDate',
    disablePadding: false,
    label: 'Established Date',
    enableHyperLink: false,
    isDate: true,
    width: '14%',
    fontSize: 14
  },
  {
    id: 'reasonCode',
    disablePadding: false,
    label: 'Reason Code',
    enableHyperLink: false,
    isDate: false,
    width: '11%',
    fontSize: 14,
    isTooltip: true,
    isTitle: 'resonCodeLongDesc'
  },
  {
    id: 'entityType',
    disablePadding: false,
    label: 'Payee Type',
    enableHyperLink: false,
    isDate: false,
    width: '10%',
    fontSize: 14
  },
  {
    id: 'payeePayerID',
    disablePadding: false,
    label: 'Payee ID',
    enableHyperLink: false,
    isDate: false,
    width: '16%',
    fontSize: 14
  },
  {
    id: 'entityName',
    disablePadding: false,
    label: 'Payee Name',
    enableHyperLink: false,
    isDate: false,
    width: '16%',
    fontSize: 14
  },

  {
    id: 'originalAmount',
    disablePadding: false,
    label: 'Original Amount',
    isBalance: true,
    enableHyperLink: false,
    isDate: false,
    width: '12%',
    fontSize: 14
  },
  {
    id: 'balanceAmount',
    disablePadding: false,
    label: 'Balance Amount',
    isBalance: true,
    enableHyperLink: false,
    isDate: false,
    width: '12%',
    fontSize: 14
  }
];
const formatSearchCriteria = (_row) => {
  return ({
    fcn: _row.fcn,
    fcnSearch: true
  });
};

function FinancialReceiptSearchTable(props) {
  const headCells = props.tableData.length === 1 ? headCellsSingle : headCellsMultiRecord;

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          // getting respective record from dropdown list which also has desc
          const payeeType =
            props.dropdowns &&
            props.dropdowns['G1#G_CMN_ENTY_TY_CD'] &&
            props.dropdowns['G1#G_CMN_ENTY_TY_CD'].filter(
              value => value.code === each.entityType
            );
          //updating the record with desc
          each.entityType =
            payeeType && payeeType.length > 0
              ? payeeType[0].description
              : each.entityType ? each.entityType : '';
          const reasonVal =
            props.dropdowns &&
            props.dropdowns['F1#F_RSN_CD'] &&
            props.dropdowns['F1#F_RSN_CD'].filter(
              value => value.code === (each.reasonCode || each.financialReason)
            );
          each.reasonCode = reasonVal && reasonVal.length > 0
          ? reasonVal[0].description
          : each.reasonCode ? each.reasonCode : each.financialReason ? each.financialReason : '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };
  return (
    <div className="table-no-wrap">
      <TableComponent
        print={props.print}
        pathTo='/FinancialReceiptUpdate?data='
        formatSearchCriteria={formatSearchCriteria}
        fixedTable
        headCells={headCells}
        tableData={getTableData(props.tableData)}
        dropdownData={props.dropdownData}
        onTableRowClick={props.onRowClick}
        defaultSortColumn={headCells[1].id}
        order={'desc'}
        isSearch={true}
      />
    </div>
  );
}
export default withRouter(FinancialReceiptSearchTable);
