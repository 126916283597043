/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  hamburger: {
    display: 'flex !important',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: 24,
    alignItems: 'center',
    marginRight: 5,
    cursor: "pointer",
    zIndex: 1201,
  },
  line: {
    width: 24,
    height: 3,
    backgroundColor: "#aaafb9",
    borderRadius: 2,
    margin: "3px 0",
    transition: "0.4s"
  }
}));

export default props => {
  const classes = useStyles();

  const onHamburgerClick = ev => {
    ev.stopPropagation();
    props.toggleDrawer()
  };
  return (
    <div
      id="hamburger"
      className={clsx("sidebar-icon", classes.hamburger)}
      onClick={onHamburgerClick}
    >
      <div className={classes.line} />
      <div className={classes.line} />
      <div className={classes.line} />
    </div>
  );
};
