/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import { DEFAULT_LOB_VALUE } from '../../../../SharedModules/AppConstants';
import TableComponent from '../../../../SharedModules/Table/Table';

const headCellsMultiRecord = [
  { id: 'payeeTypeDesc', disablePadding: false, label: 'Payee Type', enableHyperLink: false, width: '25%' },
  { id: 'payeeTypeIDDesc', disablePadding: false, label: 'Payee ID Type Code', enableHyperLink: false, width: '25%' },
  { id: 'payeeID', disablePadding: false, label: 'Payee ID', enableHyperLink: true, width: '25%' },
  { id: 'payeeName', disablePadding: false, label: 'Payee Name', enableHyperLink: false, width: '25%' },
];
const headCellsSingleRecord = [
  { id: 'payeeTypeDesc', disablePadding: false, label: 'Payee Type', enableHyperLink: false, width: '25%' },
  { id: 'payeeIDTypeCode', disablePadding: false, label: 'Payee ID Type Code', enableHyperLink: false, width: '25%' },
  { id: 'payeeID', disablePadding: false, label: 'Payee ID', enableHyperLink: true, width: '25%' },
  { id: 'payeeName', disablePadding: false, label: 'Payee Name', enableHyperLink: false, width: '25%' },
];

const formatSearchCriteria = (row) => {
  return ({
    lobCode: DEFAULT_LOB_VALUE,
    cmnEntitySK: row.commonEntitySK,
    lob: DEFAULT_LOB_VALUE,
    payeeType: row.payeeType && row.payeeType.split('-')[0],
    payeeIdTypeCode: row.payeeIdTypeCode && row.payeeIdTypeCode.split('-')[0],
    payeeID: row.payeeID,
    payeeName: row.payeeName,
    ssn: row.ssn,
    fein: row.fein
  });
};

function FinancialInquirySearchTable(props) {
  const headCells = props.tableData.length === 1 ? headCellsSingleRecord : headCellsMultiRecord;

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          // getting respective record from dropdown list which also has desc
          const payeeType =
            props.dropdowns &&
            props.dropdowns['G1#G_CMN_ENTY_TY_CD'] &&
            props.dropdowns['G1#G_CMN_ENTY_TY_CD'].filter(
              value => value.code === each.payeeType
            );
          //updating the record with desc
          each.payeeTypeDesc =
            payeeType && payeeType.length > 0
              ? payeeType[0].description
              : each.payeeType ? each.payeeType : '';
          let payeeTypeIDDropdown;
          if (each.payeeType.split('-')[0] === 'P'){
            payeeTypeIDDropdown = 'F1#P_ALT_ID_TY_CD'
          }else if(each.payeeType.split('-')[0] === 'M'){
            payeeTypeIDDropdown = 'F1#B_ALT_ID_TY_CD'
          }else if(each.payeeType.split('-')[0] === 'O'){
            payeeTypeIDDropdown = 'F1#G_ENTY_EXTL_ID_TY_CD'
          }else if(each.payeeType.split('-')[0] === 'TC'){
            payeeTypeIDDropdown = 'F1#DM_G_CARR_ENTY_ID_TY_CD'
          }
          const payeeTypeID =
            props.dropdowns &&
            props.dropdowns[payeeTypeIDDropdown] &&
            props.dropdowns[payeeTypeIDDropdown].filter(
              value => value.code === each.payeeIdTypeCode
            );
          //updating the record with desc
          each.payeeTypeIDDesc =
            payeeTypeID && payeeTypeID.length > 0
              ? payeeTypeID[0].description
              : each.payeeIdTypeCode ? each.payeeIdTypeCode : '';
          
        }
        return each;
      });
      return tData;
    }
    return [];
  };
  return (
    <TableComponent
      pathTo='/ClaimSummary?data='
      fixedTable
      formatSearchCriteria={formatSearchCriteria}
      headCells={headCells}
      tableData={getTableData(props.tableData)}
      onTableRowClick={props.onRowClick}
      defaultSortColumn={'payeeType'}
      order={'asc'}
      isSearch={true}
    />
  );
}

export default FinancialInquirySearchTable;
