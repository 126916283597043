/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/accRecActionTypes';

const initialState = {
  tempe: ''
};

const addValue = (state, action) => { return state; };
const removeValue = (state, action) => { return state; };
const setValue = (state, action) => { return state; };
const fetchValue = (state, action) => { return state; };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_VALUE: return addValue(state, action);
    case actionTypes.REMOVE_VALUE: return removeValue(state, action);
    case actionTypes.SET_VALUE: return setValue(state, action);
    case actionTypes.FETCH_VALUE: return fetchValue(state, action);
    default: return state;
  }
};

export default reducer;
