/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const NO_RECORDS_FOUND = 'No records found for the search criteria entered.';
export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const PROVIDER_ID_REQUIRED = 'Provider ID is required.';
export const DATA_REQUIRED = 'Please enter atleast one of the four criteria.';
export const UPDATE_DATE_VALID = "A valid 'Update' date must be entered.";
export const UPDATE_DATE_EQUAL_CURRENT_DATE = 'The Update Date must be equal to the Current Date.';
export const REASON_CODE_REQUIRED = 'Reason Code is required.';
export const UPDATE_DATE_INVALID_ERROR = "A valid 'Update' date must be entered.";
export const UPDATE_DATE_REQUIRED = 'Update Date is required.';
export const SUCCESS_MESSAGE = 'System successfully saved the Information.';
export const APPROVED_DELETE_ERROR = 'Both Approved and Delete boxes cannot be checked for the Provider';
export const ADJUSTED_NEGATIVE_ERROR = 'Adjusted 1099 amount is less than zero and it cannot be less than zero';
export const DATA_INVALID = 'Please enter the data in correct format.';
export const MIN_CHAR_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const LETTER_SENT_DATE_REQUIRED = 'Letter Sent Date is required.';
export const INVALID_FILE_TYPE = 'The files you are attempting to attach are an invalid file type. Please contact Technical Support for information on valid file types.';
