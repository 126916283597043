/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const SAVED_SUCCESS = 'System Successfully saved information.'
export const REASON_CODE_REQ = 'Reverse reason Code is required.'