/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import TableComponent from '../../../../SharedModules/Table/Table';
import { withRouter } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import { ARRowClickAction } from '../../Store/Actions/TextManagement/TextManagementActions';

const headCells = [
  {
    id: 'adjustmentReasonCode',
    disablePadding: false,
    label: 'Adjustment Reason',
    width: '20%',
    enableHyperLink: true,
    isVarChar: true,
    numeric: false
  },
  {
    id: 'adjustmentReasonBeginDate',
    disablePadding: false,
    label: 'Begin Date',
    width: '13%',
    isDate: true,
    numeric: false
  },
  {
    id: 'adjustmentReasonEndDate',
    disablePadding: false,
    label: 'End Date',
    width: '13%',
    isDate: true,
    numeric: false
  },
  {
    id: 'adjustmentReasonText',
    disablePadding: false,
    label: 'Text',
    width: '51%',
    isText: true,
    numeric: false
  }
];

function ProviderNoticeTable (props) {
  const [redirect, setRedirect] = React.useState(false);

  const dispatch = useDispatch();
  const onRowClick = values => dispatch(ARRowClickAction(values));
  const payloadData = useSelector(
    state => state.appConfigState.textManagementState.payload
  );
  if (redirect === 1) {
    if (payloadData != null) {
      if (payloadData.length === 1) {
        props.history.push({
          pathname: '/TextUpdate',
          state: { row: payloadData[0], TextType: 'Adjustment Reason' }
        });
      }
    }
  }

  const editRow = row => event => {
    const searchCriteria = {
      adjustmentReasonCode: row.adjustmentReasonCode
        ? row.adjustmentReasonCode
        : null,
      adjustmentReasonText: row.adjustmentReasonText
        ? row.adjustmentReasonText
        : null,
      adjustmentReasonTextStartsOrContains: null
    };
    onRowClick(searchCriteria);

    let valuetoredirect = 0;
    valuetoredirect = valuetoredirect + 1;
    setRedirect(valuetoredirect);
  };
  const formatSearchCriteria = (row) => {
    return ({
      adjustmentReasonCode: row.adjustmentReasonCode
        ? row.adjustmentReasonCode
        : null,
      adjustmentReasonText: row.adjustmentReasonText
        ? row.adjustmentReasonText
        : null,
      adjustmentReasonTextStartsOrContains: null
    });
  };
  return (
    <TableComponent
      isSearch={true}
      fixedTable
      print={props.print}
      headCells={headCells}
      pathTo='/TextUpdate?data='
      formatSearchCriteria={formatSearchCriteria}
      tableData={props.tableData ? props.tableData : []}
      onTableRowClick={editRow}
      defaultSortColumn={headCells[0].id}
    />
  );
}
export default withRouter(ProviderNoticeTable);
