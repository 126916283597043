/**

* © [2024] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";

const Iframe = ({ source }) => {
  const src = source;

  if (!source) {
    return <div>Loading...</div>;
  }
  return (
    <div>

      <div>
      <iframe
        src={src}
        style={{ width: "100%", height: "500px", scrollBehavior: "auto", border: "0" }}
      ></iframe>
   </div>
    </div>
  );
};
export default Iframe;
