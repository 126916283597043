/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from "react";
import Bootstrap, { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import { useSelector } from "react-redux";
import * as AppConstants from "../../../SharedModules/AppConstants";
import "../FundCode.scss";
import Radio from "@material-ui/core/Radio";
import { getLongDescription } from "../../../SharedModules/CommonUtilities/commonUtilities";
import NativeDropDown from "../../../SharedModules/Dropdowns/NativeDropDown";
/* AddAssignmentCriteria()
 * This component is used to display add assignment pop-up details
 */
export default function AddAssignmentCriteria(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const userInquiryPrivileges = !global.globalIsReadOnly();
  const renderSwitch = procedureCode => {
    switch (procedureCode) {
      case "procedureCodeRange":
        return (
          <div className="mui-custom-form input-with-range justify-space-bw override-width-21 override-m-2">
            <label
              for="procCodeRange"
              className="MuiFormLabel-root small-label db"
            >
              Procedure code Range
            </label>
            <input
              id="procCodeRange"
              value={props.values.procedureBeginCode}
              onChange={props.handleChange("procedureBeginCode")}
              maxlength="7"
              className="override-width-45"
              disabled={!userInquiryPrivileges}
            />
            -
            <input
              id="procCodeRange"
              value={props.values.procedureEndCode}
              onChange={props.handleChange("procedureEndCode")}
              maxlength="7"
              className="override-width-45"
              disabled={!userInquiryPrivileges}
            />
            <p class="MuiFormHelperText-root MuiFormHelperText-filled danger-text">
              {props.errors[0] ? props.errorText[0] : null}
            </p>
          </div>
        );
      case "procedureCodeValue":
        return (
          <div className="form-wrapper">
            <div className="mui-custom-form input-with-range override-width-12-2 override-m-2">
              <label
                for="procedureValueCode"
                className="MuiFormLabel-root small-label db"
              >
                Procedure code
              </label>
              <input
                id="procedureValueCode"
                value={props.values.procedureValueCode}
                onChange={props.handleChange("procedureValueCode")}
                maxlength="7"
                className="input-102"
                disabled={!userInquiryPrivileges}
              />
            </div>
            <div className="mui-custom-form input-with-range override-width-12-2 override-m-1">
              <label
                for="modifier1"
                className="MuiFormLabel-root small-label db"
              >
                Modifier 1
              </label>
              <input
                id="modifier1"
                value={props.values.modifier1}
                onChange={props.handleChange("modifier1")}
                maxlength="2"
                className="input-92"
                disabled={!userInquiryPrivileges}
              />
            </div>
            <div className="mui-custom-form input-with-range override-width-12-2 override-m-1">
              <label
                for="modifier2"
                className="MuiFormLabel-root small-label db"
              >
                Modifier 2
              </label>
              <input
                id="modifier2"
                value={props.values.modifier2}
                onChange={props.handleChange("modifier2")}
                maxlength="2"
                className="input-92"
                disabled={!userInquiryPrivileges}
              />
            </div>
            <div className="mui-custom-form input-with-range override-width-12-2 override-m-1">
              <label
                for="modifier3"
                className="MuiFormLabel-root small-label db"
              >
                Modifier 3
              </label>
              <input
                id="modifier3"
                value={props.values.modifier3}
                onChange={props.handleChange("modifier3")}
                maxlength="2"
                className="input-92"
                disabled={!userInquiryPrivileges}
              />
            </div>
            <div className="mui-custom-form input-with-range override-width-12-2 override-m-1">
              <label
                for="modifier4"
                className="MuiFormLabel-root small-label db"
              >
                Modifier 4
              </label>
              <input
                id="modifier4"
                value={props.values.modifier4}
                onChange={props.handleChange("modifier4")}
                maxlength="2"
                className="input-92"
                disabled={!userInquiryPrivileges}
              />
            </div>
            <p class="MuiFormHelperText-root MuiFormHelperText-filled danger-text margin-left-20">
              {props.errors[2] ? props.errorText[2] : null}
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  const disabledValue = !!props.disabled;

  let cosCodeVal = props.cosList.find(value => value.code === props.values.catService);
  let prvIdTypeCodeVal = props.prvIdTypeList.find(value => value.code === props.values.bProviderIdType);
  let prvTypeCodeVal = props.prvTypeList.find(value => value.code === props.values.bProviderType);
  let coeCodeVal = props.coeList.find(value => value.code === props.values.coe);
  let levelOfCareVal = props.levelOfCareList.find(value => value.code === props.values.levelCare);
  let claimListVal = props.claimList.find(value => value.code === props.values.claimType);

  return (
    <div className="form-border my-3">
      <form autoComplete="off">
        <div className="form-wrapper">
          <NativeDropDown
            className="override-width-21 override-m-2"
            id="catService"
              disabled={
                !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
              }
              label="Category of Service"
              inputProps={{ maxlength: 3 }}
            value={cosCodeVal !== undefined ? props.values.catService : DEFAULT_DD_VALUE}
              onChange={props.handleChange("catService")}
              InputLabelProps={{
                shrink: true
              }}
              title={getLongDescription(props.cosList, props.values.catService)}
            dropdownList={props.cosList.map((item, index) => (
              <option key={index} value={item.code}>
                  {item.description}
              </option>
              ))}
          />
          <NativeDropDown
            className="override-width-21 override-m-2"
            id="benefitPlanId"
              disabled={
                !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
              }
              label="Benefit Plan ID"
              inputProps={{ maxLength: 30 }}
              value={props.values.benefitPlanId}
              onChange={props.handleChange("benefitPlanId")}
              InputLabelProps={{
                shrink: true
              }}
            dropdownList={props.planIdList.map((item, index) => (
              <option key={index} value={item.code}>
                  {item.code}-{item.description}
              </option>
              ))}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="mui-custom-form with-date override-width-21 override-m-2">
              <KeyboardDatePicker
                maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                id="serviceBeginDate"
                fullWidth
                disabled={
                  !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
                }
                label="Service Begin Date"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                value={props.selectedServiceBeginDate}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={props.handleServiceBeginDate}
                helperText={props.errors[4] ? props.errorText[4] : null}
                error={props.errors[4] ? props.errorText[4] : null}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </div>
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="mui-custom-form with-date override-width-21 override-m-2">
              <KeyboardDatePicker
                maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                id="serviceEndDate"
                fullWidth
                disabled={
                  !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
                }
                label="Service End Date"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                value={props.selectedServiceEndDate}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={props.handleServiceEndDate}
                helperText={props.errors[5] ? props.errorText[5] : null}
                error={props.errors[5] ? props.errorText[5] : null}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
        <div className="form-wrapper">
          <NativeDropDown
            className="override-width-21 override-m-2"
            id="bProviderIdType"
              disabled={
                !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
              }
              label="Billing Provider ID Type"
              inputProps={{ maxlength: 3 }}
            value={prvIdTypeCodeVal !== undefined ? props.values.bProviderIdType : DEFAULT_DD_VALUE}
              onChange={props.handleChange("bProviderIdType")}
            errTxt={props.errors[7] ? props.errorText[7] : null}
            dropdownList={props.prvIdTypeList.map((item, index) => (
              <option key={index} value={item.code}>
                  {item.description}
              </option>
              ))}
          />
          <div className="mui-custom-form input-md with-select override-width-21 override-m-2">
            <TextField
              id="bProviderId"
              fullWidth
              label="Billing Provider ID"
              disabled={
                !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
              }
              inputProps={{ maxlength: 15 }}
              value={props.values.bProviderId}
              onChange={props.handleChange("bProviderId")}
              helperText={props.errors[6] ? props.errorText[6] : null}
              error={props.errors[6] ? props.errorText[6] : null}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>
          <NativeDropDown
            className="override-width-21 override-m-2"
            id="bProviderType"
              disabled={
                !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
              }
              label="Billing Provider Type"
              inputProps={{ maxlength: 2 }}
            value={prvTypeCodeVal !== undefined ? props.values.bProviderType : DEFAULT_DD_VALUE}
              onChange={props.handleChange("bProviderType")}
            errTxt={props.errors[8] ? props.errorText[8] : null}
            dropdownList={props.prvTypeList.map((item, index) => (
              <option key={index} value={item.code}>
                  {item.description}
              </option>
              ))}
          />
          <div className="mui-custom-form input-xl override-width-21 override-m-2">
            <label className="MuiFormLabel-root very-small-label">
              Family Planning
          </label>
            <div className="sub-radio">
              <Radio
                id="familyPlanningIdY"
                fullWidth
                disabled={
                  !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
                }
                name="familyPlanning"
                value="Y"
                checked={props.values.familyPlanning === "Y"}
                onChange={props.handleChange("familyPlanning")}
              />
              <label className="text-black" for="familyPlanningIdY">
                Yes
            </label>
              <Radio
                id="familyPlanningIdN"
                disabled={
                  !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
                }
                name="familyPlanning"
                value="N"
                checked={props.values.familyPlanning === "N"}
                className="ml-2"
                onChange={props.handleChange("familyPlanning")}
              />
              <label className="text-black" for="familyPlanningIdN">
                No
            </label>
            </div>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form input-md with-select override-width-21 override-m-2">
            <TextField
              id="taxonomy"
              fullWidth
              disabled={
                !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
              }
              label="Taxonomy"
              inputProps={{ maxlength: 10 }}
              value={props.values.taxonomy}
              onChange={props.handleChange("taxonomy")}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>
          <NativeDropDown
            className="override-width-21 override-m-2"
            id="coe"
              disabled={
                !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
              }
              label="COE"
              inputProps={{ maxlength: 4 }}
            value={coeCodeVal !== undefined ? props.values.coe : DEFAULT_DD_VALUE}
              onChange={props.handleChange("coe")}
            dropdownList={props.coeList.map((item, index) => (
              <option key={index} value={item.code}>
                  {item.description}
              </option>
              ))}
          />
          <NativeDropDown
            className="override-width-21 override-m-2"
            id="levelCare"
              select
              disabled={
                !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
              }
              label="Level of Care"
              inputProps={{ maxlength: 3 }}
            value={levelOfCareVal !== undefined ? props.values.levelCare : DEFAULT_DD_VALUE}
              onChange={props.handleChange("levelCare")}
            dropdownList={props.levelOfCareList.map((item, index) => (
              <option key={index} value={item.code}>
                  {item.description}
              </option>
              ))}
          />
          <div className="mui-custom-form input-xl override-width-21 override-m-2">
            <div className="sub-radio">
              <FormControlLabel
                disabled={
                  !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
                }
                control={
                  <Checkbox
                    color="primary"
                    checked={props.defaultCheck}
                    value={props.defaultCheck}
                    onChange={props.handleChangeDefault}
                  />
                }
                label="Default"
              />
            </div>
          </div>
          <NativeDropDown
            className="override-width-21 override-m-2"
            id="claimType"
              disabled={
                !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
              }
              label="Claim Type"
              inputProps={{ maxlength: 1 }}
            value={claimListVal !== undefined ? props.values.claimType : DEFAULT_DD_VALUE}
              onChange={props.handleChange("claimType")}
            dropdownList={props.claimList.map((item, index) => (
              <option key={index} value={item.code}>
                  {item.description}
              </option>
              ))}
          />
          <div className="mui-custom-form input-with-range justify-space-bw override-width-21 override-m-2 ">
            <label for="member-age" className="MuiFormLabel-root small-label db">
              Member Age
          </label>
            <input
              disabled={
                !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
              }
              onInput={e => {
                e.target.value = e.target.value.replace(
                  AppConstants.NUMBER_ONLY_REGEX,
                  ""
                );
              }}
              pattern="\d*"
              onChange={props.handleChange("memberBeginAge")}
              value={props.values.memberBeginAge}
              maxlength="3"
              className="override-width-45"
              id="member-age"
            />
            -
          <input
              disabled={
                !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
              }
              onInput={e => {
                e.target.value = e.target.value.replace(
                  AppConstants.NUMBER_ONLY_REGEX,
                  ""
                );
              }}
              pattern="\d*"
              onChange={props.handleChange("memberEndAge")}
              value={props.values.memberEndAge}
              maxlength="3"
              className="override-width-45"
              id="member-age"
            />
            <p class="MuiFormHelperText-root MuiFormHelperText-filled danger-text">
              {props.errors[1] ? props.errorText[1] : null}
            </p>
          </div>
          {props.showVoidYesNo ? (
            <div className="mui-custom-form margin-right-134 override-width-21 override-m-2">
              <label className="MuiFormLabel-root small-label no-margin">
                Void
            </label>
              <div className="sub-radio">
                <Radio
                  id="voidYesId"
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : disabledValue
                  }
                  name="voidYesNo"
                  value="Y"
                  checked={props.values.voidYesNo === "Y"}
                  onChange={props.handleChange("voidYesNo")}
                />
                <label className="text-black" for="voidYesId">
                  Yes
              </label>
                <Radio
                  id="voidNoId"
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : disabledValue
                  }
                  name="voidYesNo"
                  value="N"
                  checked={props.values.voidYesNo === "N"}
                  onChange={props.handleChange("voidYesNo")}
                  className="ml-2"
                />
                <label className="text-black" for="voidNoId">
                  No
              </label>
              </div>
            </div>
          ) : null}
          <div className="mui-custom-form input-xl override-width-21 override-m-2">
            <label className="MuiFormLabel-root small-label no-margin">
              Procedure Code
          </label>
            <div className="sub-radio">
              <Radio
                id="procedureCodeRange"
                disabled={
                  !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
                }
                name="procedureCode"
                value={props.procedureCodeRange}
                checked={props.values.procedureCode === "procedureCodeRange"}
                onChange={props.handleChange("procedureCode")}
              />
              <label className="text-black" for="procedureCodeRange">
                Range
            </label>
              <Radio
                id="procedureCodeValue"
                disabled={
                  !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
                }
                name="procedureCode"
                value={props.procedureCodeValue}
                checked={props.values.procedureCode === "procedureCodeValue"}
                onChange={props.handleChange("procedureCode")}
                className="ml-2"
              />
              <label className="text-black" for="procedureCodeValue">
                Value
            </label>
              <Radio
                id="procedureCodeNA"
                disabled={
                  !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
                }
                name="procedureCode"
                value={props.procedureCodeNA}
                checked={props.values.procedureCode === "procedureCodeNA"}
                onChange={props.handleChange("procedureCode")}
                className="ml-2"
              />
              <label className="text-black" for="procedureCodeNA">
                N/A
            </label>
            </div>
          </div>
        </div>
        <div className="form-wrapper">{renderSwitch(props.procedureCodeVal)}</div>
        <div className="form-wrapper">
          <div
            className="mui-custom-form orField override-width-21 override-m-2"
            style={{ textAlign: "right", paddingTop: "23px" }}
          >
            OR
        </div>
          <NativeDropDown
            className="override-width-21 override-m-2"
            id="mapsetId"
              disabled={
                !userInquiryPrivileges ? !userInquiryPrivileges : disabledValue
              }
              label="Mapset ID"
              inputProps={{ maxLength: 30 }}
              value={props.values.mapsetId}
              onChange={props.handleChange("mapsetId")}
            errTxt={props.errors[3] ? props.errorText[3] : null}
            dropdownList={props.mapIdList.map((item, index) => (
              <option key={index} value={item.code}>
                  {item.code}-{item.description}
              </option>
              ))}
          />
        </div>

      </form>
    </div>
  );
}
