/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const DROPDOWNDATA = 'DROPDOWNDATA';
export const SEARCH_WAIVER = 'SEARCH_WAIVER';
export const CREATE_WAIVER = 'CREATE_WAIVER';
export const UPDATE_WAIVER = 'UPDATE_WAIVER';
export const RESET_WAIVER = 'RESET_WAIVER';