/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/ActionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.RESETPDFDATA:
      return {...state,downloadeFilePDFError:null};
    case actionTypes.GET_CURRENT_PATH:
      return { currentPath: action.currentPath };
    case actionTypes.MULTI_DROPDOWN_VALUES_ACTION:
      return { ...state, multiDropDownData: action.multiDropDownData };
    case actionTypes.ADDRESS_VERIFY:
      return { ...state, addressValidatedData: action.addressValidatedData };
    case actionTypes.RESET_ADDRESS_RESPONSE:
      return { ...state, addressValidatedData: {}}
    case actionTypes.USER_LOGGIN_ACTION:
      return { ...state, userPrivileges: action.userPrivileges };
    case actionTypes.USER_INQUIRY_PRIVILEGE:
      return { ...state, userInquiryPrivileges: action.userInquiryPrivileges };
    case actionTypes.LOGIN_USER_ID:
      return { ...state, logInUserID: action.logInUserID };
    case actionTypes.ATTACHMENT_API:
      return { ...state, attachmentResult: action.attachmentResult };
    case actionTypes.SET_PRINT_LAYOUT:
      return { ...state, printLayout: action.printLayout };
    case actionTypes.SET_ATTACHMENTS_DDD_VALUES:
      return { ...state, attachmentDDValues: action.attachmentDDValues };
    case actionTypes.DOWNLOAD_FILE_ACTION:
      return { ...state, downloadeFile: action.downloadeFile };
    case actionTypes.DOWNLOAD_PDF_FILE_ACTION:
        return { ...state, downloadeFilePDF: action.downloadeFilePDF };
    case actionTypes.DOWNLOAD_PDF_FILE_ACTION_ERROR:
          return { ...state, downloadeFilePDFError: action.downloadeFilePDFError };
    case actionTypes.REFRESH_ATTACHMENTS:
      return { ...state, newAttachmentResult: action.newAttachmentResult };
    case actionTypes.SYSTEM_PARAMETER_SPECIFIC_VALUE:
      return { ...state, SystemParameterData: action.SystemParameterData };
    case actionTypes.FREQUENCY_PARAM:
        return { ...state, frequencyParameter: action.frequencyParameter };
    case actionTypes.AUDIT_LOG_TABLE_COMMON:
      return { ...state, [action.keyName]: action.payload };
    case actionTypes.SSO_LOGIN:
        return { ...state, ssoLogin: action.ssoLogin };
    case actionTypes.SHOW_SESSION_EXPIRY_DIALOG:
      return { ...state, showSessionExpiryDialog: action.status };
    case actionTypes.SESSION_EXPANDED:
      return { ...state, sessionExtendStatus: action.status };
    case actionTypes.USER_IN_OUT_ACTION:
      return { ...state, userInOutStatus: action.status };
      case actionTypes.SET_NAVIGATION_PRIVILEGES:
      {
      return {...state,privileges:action.privileges}
    }
    default: return state;
  }
};

export default reducer;
