/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const GET_CURRENT_PATH = 'GET_CURRENT_PATH';
export const MULTI_DROPDOWN_VALUES_ACTION = 'MULTI_DROPDOWN_VALUES_ACTION';
export const ADDRESS_VERIFY = 'ADDRESS_VERIFY';
export const USER_LOGGIN_ACTION = 'USER_LOGGIN_ACTION';
export const USER_INQUIRY_PRIVILEGE = 'USER_INQUIRY_PRIVILEGE';
export const LOGIN_USER_ID = 'LOGIN_USER_ID';
export const ATTACHMENT_API = 'ATTACHMENT_API';
export const SET_PRINT_LAYOUT = 'SET_PRINT_LAYOUT';
export const SET_ATTACHMENTS_DDD_VALUES = 'SET_ATTACHMENTS_DDD_VALUES';
export const DOWNLOAD_FILE_ACTION = 'DOWNLOAD_FILE_ACTION';
export const DOWNLOAD_PDF_FILE_ACTION = 'DOWNLOAD_PDF_FILE_ACTION';
export const DOWNLOAD_PDF_FILE_ACTION_ERROR = 'DOWNLOAD_PDF_FILE_ACTION_ERROR';
export const REFRESH_ATTACHMENTS = 'REFRESH_ATTACHMENTS';
export const AUDIT_LOG_TABLE_COMMON = 'AUDIT_LOG_TABLE_COMMON';
export const SYSTEM_PARAMETER_SPECIFIC_VALUE = 'SYSTEM_PARAMETER_SPECIFIC_VALUE';
export const FREQUENCY_PARAM = 'FREQUENCY_PARAM';
export const SSO_LOGIN = 'SSO_LOGIN';
export const USERROLES = 'USERROLES';
export const RESETDATA = 'RESETDATA';
export const RESETPDFDATA = 'RESETPDFDATA';
export const MENU_STATE_CHANGED = "MENU_STATE_CHANGED";
export const SHOW_SESSION_EXPIRY_DIALOG = 'SHOW_SESSION_EXPIRY_DIALOG';
export const SESSION_EXPANDED = 'SESSION_EXPANDED';
export const USER_IN_OUT_ACTION = 'USER_IN_OUT_ACTION';
export const SET_NAVIGATION_PRIVILEGES = 'SET_NAVIGATION_PRIVILEGES';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const RESET_DATA = "RESET_DATA";
export const RESET_ADDRESS_RESPONSE = "RESET_ADDRESS_RESPONSE";
