/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Bootstrap, { Button, Row, Form } from "react-bootstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import { withRouter } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AddAssignmentCriteria from "./AddAssignmentCriteria";
import {
  DialogTitle,
  DialogActions,
  DialogContent
} from "../../../SharedModules/Dialog/DialogUtilities";
import DialogContentText from '@material-ui/core/DialogContentText';
import TabPanel from "../../../SharedModules/TabPanel/TabPanel";
import * as AppConstants from "../../../SharedModules/AppConstants";

import "../FundCode.scss";
import {
  generateUUID,
  getUTCTimeStamp,
  compareTwoDates,
  checkForValidDateString,
  getDateInMMDDYYYYFormat,
  validateDateMinimumValue,
  getDateInYYYYMMDDFormatWithApendZero
} from "../../../SharedModules/DateUtilities/DateUtilities";
import moment from "moment";
import TableComponent from "../../../SharedModules/Table/Table";
import * as fundCodeConstants from "./FundCodeConstants";
import ErrorMessages from "../../../SharedModules/Errors/ErrorMessages";
import SuccessMessages from "../../../SharedModules/Errors/SuccessMessage";
import {
  getLongDescription,
  removeLeadingTrailingSpaces
} from "../../../SharedModules/CommonUtilities/commonUtilities";

import {
  getDropdownDataAction,
  getMapIDList,
  getBenefitPlanIDList,
  searchCriteriaRowAction,
  addFundCode,
  updateFundCode,
  getAuditLogDetailsAction,
  getAuditLogRowDetailsAction
} from "../Store/Actions/FundCodeSearchActions";
import { validateHeaderFields, validateCriteria } from "./FundCodeValidations";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FundCodeHistory from "./FundCodeHistory";
import NoSaveMessage from "../../../SharedModules/Errors/NoSaveMessage";

import dropdownCriteria from "./FundCodeAddUpdate.json";
import Notes from "../../../SharedModules/Notes/Notes";
import Footer from "../../../SharedModules/Layout/footer";
import NavHOC from "../../../SharedModules/Navigation/NavHOC";
import Radio from "@material-ui/core/Radio";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AuditTableComponent from "../../../SharedModules/AuditLog/AuditTableComponent";
import { GET_APP_DROPDOWNS } from "../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from "../../../SharedModules/Dropdowns/NativeDropDown";

const useStyles = makeStyles(theme => ({
  customTooltip: {
    backgroundColor: "#D3D3D3",
    color: "#2F2F2F",
    fontWeight: "normal",
    fontSize: "1em",
    margin: "0px",
    marginRight: "0px"
  },
  customArrow: {
    color: "#D3D3D3"
  }
}));

/* FundCodeAdd()
 * This component is used to add/update fund code criteria
 */

export default NavHOC({
  Wrapped: FundCodeAdd,
  action: searchCriteriaRowAction,
  url: "/FundCodeEdit",
  selector: "fundCodeState.rowSearchResults",
  actionKey: "FundCode"
});
function FundCodeAdd (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const classes = useStyles();

  const dispatch = useDispatch();
  const toPrintRef = useRef();

  const getDropdownData = dropdownValues => {
    dispatch(getDropdownDataAction(dropdownValues));
  };
  const getMapIDData = () => {
    dispatch(getMapIDList("FUN"));
  };
  const getBenefitPlanIDData = () => {
    dispatch(getBenefitPlanIDList());
  };
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const onFundCodeAdd = value => dispatch(addFundCode(value));
  const onFundCodeUpdate = value => dispatch(updateFundCode(value));
  // Audit Log Implementation starts
  const [showLogTable, setShowLogTable] = React.useState(false);

  const onClickAuditLog = () => {
    const tableName = "r_fund_cd_tb";

    const keyValue = {
      r_fund_cd_sk: fundCodeSK
    };
    dispatch(getAuditLogDetailsAction(tableName, keyValue, "auditLogData"));
  };
  useEffect(() => {
    if (showLogTable && document.getElementById("audit-table")) { document.getElementById("audit-table").scrollIntoView(); }
  }, [showLogTable]);
  const auditLogData = useSelector(state => state.fundCodeState.auditDetails);
  const [auditRowData, setAuditRowData] = React.useState([]);
  const filterAuditTable = rowData => {
    return rowData.afterValue || rowData.beforeValue;
  };
  useEffect(() => {
    if (auditLogData) {
      setAuditRowData(auditLogData.filter(filterAuditTable));
    }
  }, [auditLogData]);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const [initalFocusCheck, setInitalFocusCheck] = React.useState(false);
  const [showLogDialogTable, setShowLogDialogTable] = React.useState(false);
  const auditLogTableData = useSelector(
    state => state.fundCodeState.auditRowDetails
  );
  const [auditTableData, setAuditTableData] = React.useState([]);
  const [close, setClose] = React.useState(true);

  const onClickAuditDialogLog = row => {
    const tableName = "r_fund_cd_crit_tb";
    const keyValue = {
      r_fund_cd_sk: fundCodeSK,
      r_fund_cd_crit_seq_num: row.sequenceNumber
    };
    dispatch(
      getAuditLogRowDetailsAction(tableName, keyValue, "auditLogTableData")
    );
    setShowLogDialogTable(true);
  };
  useEffect(() => {
    if (auditLogTableData) {
      setAuditTableData(auditLogTableData.filter(filterAuditTable));
    }
  }, [auditLogTableData]);

  useEffect(() => {
    if (showLogDialogTable && document.getElementById("audit-dialog-table")) { document.getElementById("audit-dialog-table").scrollIntoView(); }
  }, [showLogDialogTable]);

  const [auditButtonToggle, setAuditButtonToggle] = React.useState(false);
  const [auditLogRow, setAuditLogRow] = React.useState(null);

  useEffect(() => {
    if (auditButtonToggle) {
      onClickAuditLog();
      if (auditLogRow) {
        onClickAuditDialogLog(auditLogRow);
      }
    } else {
      setClose(false);
      setShowLogDialogTable(false);
      setShowLogTable(false);
    }
  }, [auditButtonToggle]);
  useEffect(() => {
    if (auditLogRow && auditButtonToggle) {
      setAuditTableData([]);
      onClickAuditDialogLog(auditLogRow);
    } else {
      setAuditTableData([]);
    }
  }, [auditLogRow]);
  // Audit Log Implementation ends
  const [fundCodeList, setFundCodeList] = React.useState([]);
  const [agencyList, setAgencyList] = React.useState([]);
  const [cosList, setCosList] = React.useState([]);
  const [planIdList, setPlanIdList] = React.useState([]);
  const [prvIdTypeList, setPrvIdTypeList] = React.useState([]);
  const [prvTypeList, setPrvTypeList] = React.useState([]);
  const [coeList, setCoeList] = React.useState([]);
  const [levelOfCareList, setLevelOfCareList] = React.useState([]);
  const [claimList, setClaimList] = React.useState([]);
  const [mapIdList, setMapIdList] = React.useState([]);
  const [selectedBeginDate, setSelectedBeginDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [beginDatePress, setBeginDatePress] = React.useState(false);
  const [endDatePress, setEndDatePress] = React.useState(false);
  const [add, setAdd] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [fundCodeTableData, setFundCodeTableData] = React.useState([]);
  const [fundCodeNonVoidedData, setFundCodeNonVoidedData] = React.useState([]);
  const [rowFundCodeData, setRowFundCodeData] = React.useState([]);
  const [retainEdit, setRetainEdit] = React.useState({});
  const [retainValues, setRetainValues] = React.useState({});
  const [editData, setEditData] = React.useState({});
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const popUpErrorMessagesArray = [];
  const [
    selectedServiceBeginDate,
    setSelectedServiceBeginDate
  ] = React.useState(null);
  const [selectedServiceEndDate, setSelectedServiceEndDate] = React.useState(
    null
  );
  const [serviceBeginDatePress, setServiceBeginDatePress] = React.useState(
    false
  );
  const [serviceEndDatePress, setServiceEndDatePress] = React.useState(false);
  const [retainServiceBeginDate, setRetainServiceBeginDate] = React.useState();
  const [retainServiceEndDate, setRetainServiceEndDate] = React.useState();
  const [procedureCodeVal, setProcedureCode] = React.useState(
    "procedureCodeNA"
  );
  const procedureCodeRange = "procedureCodeRange";
  const procedureCodeValue = "procedureCodeValue";
  const procedureCodeNA = "procedureCodeNA";
  let errorMessagesArray = [];
  let validationErrors = [];
  let criteriaValidationErrors = [];
  const [showVoidYesNo, setShowVoidYesNo] = React.useState(false);
  const [
    {
      showPopUpError,
      showProviderIdTypeError,
      showProviderTypeError,
      showProviderIdError,
      showServiceBeginDateError,
      showServiceEndDateError,
      showMapSetIdError,
      showProcedureRangeCodeError,
      showMemberAgeError,
      showProcedureCodeValueError,
      showBlankRowError,
      showAgencyError,
      showFundCodeError,
      showRankError,
      showBeginDateError,
      showEndDateError
    },
    setShowError
  ] = React.useState(false);
  const [
    {
      showPopUpErrorText,
      showProviderIdTypeErrorText,
      showProviderTypeErrorText,
      showProviderIdErrorText,
      showServiceBeginDateErrorText,
      showServiceEndDateErrorText,
      showMapSetIdErrorText,
      showProcedureRangeCodeErrorText,
      showMemberAgeErrorText,
      showProcedureCodeValueErrorText
    },
    setShowErrorText
  ] = React.useState("");
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [defaultCheck, setDefaultCheck] = React.useState(false);
  const [popupErrorMessages, setPopUpErrorMessage] = React.useState([]);
  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState([]);
  const [errorMegBeginDate, setErrorMegBeginDate] = React.useState("");
  const [errorMegEndDate, setErrorMegEndDate] = React.useState("");
  const sucessMessages = [];
  let requestTableData = [];
  let tabFundData = [];
  const [fundCodeDetails, setFundCodeDetails] = React.useState([]);
  const [fundCodeDetailsArray, setFundCodeDetailsArray] = React.useState([]);
  const [historyDetail, setHistoryDetail] = React.useState([]);
  const [showEditScreen, setShowEditScreen] = React.useState(false);
  const [showVoid, setShowVoid] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [headerDisabled, setHeaderDisabled] = React.useState(false);
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  useEffect(() => {
    getDropdownData(dropdownCriteria);
    getMapIDData();
    getBenefitPlanIDData();
    if (
      props.location.state &&
      props.location.state.editFlag !== undefined &&
      props.location.state.editFlag != null
    ) {

    }
    if (props.location.state && props.location.state.fundCodeData) {
      setFundCodeDetails(props.location.state.fundCodeData);
    } else {
      updateFundCodeResponse = null;
    }
    addFundCodeResponse = null;
    fundCodeData = null;
    onDropdowns([
      Dropdowns.FUND_CODE,
      Dropdowns.CATEGORY_OF_SERVICE_VALIDVALUE,
      Dropdowns.AGNCY_CODE,
      Dropdowns.PROVIDER_ID_TYPE,
      Dropdowns.PROVIDER_TYPE_CODE,
      Dropdowns.CLAIM_TYPE
    ]);
  }, []);
  const [disableNotes, setDisableNotes] = React.useState(true);
  const notesValidation = () => {
    let flag = false;
    if (!values.agency || values.agency === DEFAULT_DD_VALUE) {
      flag = flag || true;
    }

    if (!values.fundCode || values.fundCode === DEFAULT_DD_VALUE) {
      flag = flag || true;
    }

    if (!values.rank) {
      flag = flag || true;
    }

    if (!selectedBeginDate) {
      flag = flag || true;
    }

    if (!selectedEndDate) {
      flag = flag || true;
    }

    let isBeginDateInValid = false;
    if (selectedBeginDate) {
      if (checkForValidDateString(getDateInMMDDYYYYFormat(selectedBeginDate))) {
        isBeginDateInValid = validateDateMinimumValue(
          getDateInMMDDYYYYFormat(selectedBeginDate)
        );
        if (isBeginDateInValid) {
          flag = flag || true;
        }
      } else {
        flag = flag || true;
      }
    }

    if (selectedEndDate) {
      if (checkForValidDateString(getDateInMMDDYYYYFormat(selectedEndDate))) {
        const isEndDateInValid = validateDateMinimumValue(
          getDateInMMDDYYYYFormat(selectedEndDate)
        );
        if (isEndDateInValid) {
          flag = flag || true;
        } else if (
          selectedBeginDate &&
          selectedEndDate &&
          !showBeginDateError &&
          !showEndDateError
        ) {
          var begDate = new Date(selectedBeginDate);
          var enDate = new Date(selectedEndDate);
          if (!compareTwoDates(begDate, enDate)) {
            flag = flag || true;
          }
        }
      } else {
        flag = flag || true;
      }
    }
    return flag;
  };
  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setErrorMessages([]);
    setSystemSuccesMessages([]);
    setShowError(false);
    setShowErrorText("");
  };
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: "",
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(new Date()).format("MM/DD/YYYY hh:mm:ss"),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: "",
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(new Date()).format("YYYY-MM-DD"),
    filterEndDate: null,
    userId: logInUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: generateUUID()
  });
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  // notes drop down api call

  const dropdown = useSelector(state => state.fundCodeState.dropdownData);
  const mapIdDropdown = useSelector(state => state.fundCodeState.mapIdData);
  const benefitPlanIdDropdown = useSelector(
    state => state.fundCodeState.benefitPlanIdData
  );

  let addFundCodeResponse = useSelector(
    state => state.fundCodeState.addFundCodeData
  );
  let updateFundCodeResponse = useSelector(
    state => state.fundCodeState.updateFundCodeData
  );
  const providerIdResponse = useSelector(
    state => state.fundCodeState.providerIdData
  );
  const onRowClick = value => dispatch(searchCriteriaRowAction(value));
  let fundCodeData = useSelector(state => state.fundCodeState.rowSearchResults);
  const userInquiryPrivileges = !global.globalIsReadOnly();

  let disabledHeaderValue = headerDisabled;
  useEffect(() => {
    if (fundCodeData && !fundCodeData.searchError) {
      let editFlag = "1";
      if (systemSuccessMessages[0]) {
        editFlag = systemSuccessMessages[0];
      }
      props.history.push({
        pathname: "/FundCodeEdit",
        state: { fundCodeData: fundCodeData, editFlag: editFlag, ValuesFromFuncdCode: ValuesFromFuncdCode, isFromAdd: true }
      });
    }
    if (fundCodeData && fundCodeData.searchError) {
      setErrorMessages([fundCodeConstants.TRANSACTION_ERROR]);
    }
  }, [fundCodeData]);

  useEffect(() => {
    if (open && document.getElementById("form_pop_up")) {
 document.getElementById("form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
}
  }, [open]);

  useEffect(() => {
    if (props.location.state && props.location.state.fundCodeData) {
      setFundCodeDetails(props.location.state.fundCodeData);
      fundCodeData = null;
    }
  }, [props.location.state]);

  /* Display error message if Provider Id not found in */
  useEffect(() => {
    var showProviderIdError = false;
    var showProviderIdErrorText = "";
    if (
      providerIdResponse &&
      providerIdResponse.respcode === "03" &&
      providerIdResponse.respdesc === ""
    ) {
      showProviderIdError = true;
      showProviderIdErrorText = providerIdResponse.exc;
      popUpErrorMessagesArray.push(showProviderIdErrorText);
    }
    if (
      providerIdResponse &&
      providerIdResponse.respcode === "03" &&
      providerIdResponse.respdesc !== ""
    ) {
      showProviderIdError = true;
      showProviderIdErrorText = providerIdResponse.respdesc;
      popUpErrorMessagesArray.push(showProviderIdErrorText);
    }
    if (
      providerIdResponse &&
      providerIdResponse.respcode === "02" &&
      providerIdResponse.respdesc !== ""
    ) {
      showProviderIdError = true;
      showProviderIdErrorText = providerIdResponse.respdesc;
      popUpErrorMessagesArray.push(showProviderIdErrorText);
    }
    setShowError({ showProviderIdError: showProviderIdError });
    setShowErrorText({ showProviderIdErrorText: showProviderIdErrorText });
  }, [providerIdResponse]);

  /* Calling api to get fund code dropdowns */

  useEffect(() => {
    if (benefitPlanIdDropdown && benefitPlanIdDropdown.bpIDList) {
      setPlanIdList(benefitPlanIdDropdown.bpIDList);
    }
  }, [benefitPlanIdDropdown]);

  useEffect(() => {
    if (mapIdDropdown && mapIdDropdown.mapIDList) {
      const mapList = JSON.parse(
        JSON.stringify(
          mapIdDropdown.mapIDList.sort((a, b) =>
            a.code.toLocaleUpperCase() < b.code.toLocaleUpperCase() ? -1 : 1
          )
        )
      );
      setMapIdList(mapList);
    }
  }, [mapIdDropdown]);

  /* Setting dropdown values */
  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj["Reference#1042"]) {
        setFundCodeList(dropdown.listObj["Reference#1042"]);
      }
      if (dropdown.listObj["Claims#1020"]) {
        setCosList(dropdown.listObj["Claims#1020"]);
      }
      if (dropdown.listObj["Financial#1125"]) {
        setAgencyList(dropdown.listObj["Financial#1125"]);
      }
      if (dropdown.listObj["Provider#1017"]) {
        setPrvIdTypeList(dropdown.listObj["Provider#1017"]);
      }
      if (dropdown.listObj["Provider#1062"]) {
        setPrvTypeList(dropdown.listObj["Provider#1062"]);
      }
      if (dropdown.listObj["Member#1041"]) {
        setLevelOfCareList(dropdown.listObj["Member#1041"]);
      }
      if (dropdown.listObj["Member#1019"]) {
        setCoeList(dropdown.listObj["Member#1019"]);
      }
      if (dropdown.listObj["Claims#1038"]) {
        setClaimList(dropdown.listObj["Claims#1038"]);
      }
      if (dropdown.listObj["General#1012"]) {
        setUsageTypeCodeData(dropdown.listObj["General#1012"]);
      }
    }
  }, [dropdown]);
  /* Setting initial values */
  const [values, setValues] = React.useState({
    agency: DEFAULT_DD_VALUE,
    fundCode: DEFAULT_DD_VALUE,
    rank: "",
    catService: DEFAULT_DD_VALUE,
    benefitPlanId: DEFAULT_DD_VALUE,
    bProviderIdType: DEFAULT_DD_VALUE,
    bProviderId: "",
    bProviderType: DEFAULT_DD_VALUE,
    familyPlanning: "",
    taxonomy: "",
    coe: DEFAULT_DD_VALUE,
    levelCare: DEFAULT_DD_VALUE,
    memberBeginAge: "",
    memberEndAge: "",
    claimType: DEFAULT_DD_VALUE,
    mapsetId: DEFAULT_DD_VALUE,
    procedureCode: procedureCodeVal,
    procedureBeginCode: "",
    procedureEndCode: "",
    procedureValueCode: "",
    modifier1: "",
    modifier2: "",
    modifier3: "",
    modifier4: "",
    voidYesNo: ""
  });

  /* Setting value for void type in header */
  const [valuesYesNoHeader, setValuesYesNoHeader] = React.useState({
    voidYesNoHeader: "N"
  });
  /* UseEffect for disabling Notes Button */
  useEffect(() => {
    setDisableNotes(notesValidation());
  }, [values, selectedBeginDate, selectedEndDate]);

  /* Getting Fund Code details using props from different screens
   * setting screen fields based on the details coming from back-end
   */
  const [fundCodeSK, setFundCodesk] = React.useState(null);
  useEffect(() => {
    if (
      props.location.state &&
      props.location.state.editFlag !== undefined &&
      props.location.state.editFlag != null
    ) {
      setShowEditScreen(true);
      if (props.location.state.editFlag) {
        if (props.location.state.editFlag !== "1") {
          setSystemSuccesMessages([props.location.state.editFlag]);
        }
      }
    } else {
      setShowEditScreen(false);
    }
    if (fundCodeDetails && fundCodeDetails.length > 0) {
      setFundCodeDetailsArray(fundCodeDetails);
      if (fundCodeDetails[0].fundCodeDetailsVO.fundCodeSK) {
        setFundCodesk(fundCodeDetails[0].fundCodeDetailsVO.fundCodeSK);
      }
      if (fundCodeDetails[0].fundCodeDetailsVO) {
        if (
          fundCodeDetails[0].fundCodeDetailsVO.activityCode !== null &&
          fundCodeDetails[0].fundCodeDetailsVO.activityCode !== undefined
        ) {
          setFieldValues(
            fundCodeDetails[0].fundCodeDetailsVO.activityCode,
            fundCodeDetails[0].fundCodeDetailsVO.fundCode,
            fundCodeDetails[0].fundCodeDetailsVO.rankNumber,
            DEFAULT_DD_VALUE,
            DEFAULT_DD_VALUE,
            DEFAULT_DD_VALUE,
            "",
            DEFAULT_DD_VALUE,
            "",
            "",
            DEFAULT_DD_VALUE,
            DEFAULT_DD_VALUE,
            DEFAULT_DD_VALUE,
            "",
            "",
            DEFAULT_DD_VALUE,
            "procedureCodeNA",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            ""
          );
        } else {
          setFieldValues(
            DEFAULT_DD_VALUE,
            fundCodeDetails[0].fundCodeDetailsVO.fundCode,
            fundCodeDetails[0].fundCodeDetailsVO.rankNumber,
            DEFAULT_DD_VALUE,
            DEFAULT_DD_VALUE,
            DEFAULT_DD_VALUE,
            "",
            DEFAULT_DD_VALUE,
            "",
            "",
            DEFAULT_DD_VALUE,
            DEFAULT_DD_VALUE,
            DEFAULT_DD_VALUE,
            "",
            "",
            DEFAULT_DD_VALUE,
            "procedureCodeNA",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            ""
          );
        }
        setValuesYesNoHeader({
          voidYesNoHeader:
            fundCodeDetails[0].fundCodeDetailsVO.voids === "Y" ? "Y" : "N"
        });
        setSelectedBeginDate(fundCodeDetails[0].fundCodeDetailsVO.beginDate);
        setSelectedEndDate(fundCodeDetails[0].fundCodeDetailsVO.endDate);
        setHistoryDetail(fundCodeDetails[0].deleteFundCodeCriterias);
        createFundCodeTableData(fundCodeDetails[0].fundCodeCriterias);
        if (fundCodeDetails[0].fundCodeDetailsVO.voids === "Y") {
          setDisabled(true);
          setHeaderDisabled(true);
          disabledHeaderValue = true;
        }
      }
    }
  }, [fundCodeDetails]);
  useEffect(() => {
    if (fundCodeDetails && fundCodeDetails.length > 0 && dropdown && cosList) {
      createFundCodeTableData(fundCodeDetails[0].fundCodeCriterias);
    }
  }, [dropdown, cosList]);

  /* Display success/error message after adding fund code details based on the response and response description coming from the API */
  useEffect(() => {
    setAllowNavigation(false);
    if (addFundCodeResponse && !addFundCodeResponse.systemFailure) {
      if (
        addFundCodeResponse &&
        addFundCodeResponse.respcode === "03" &&
        addFundCodeResponse.respdesc !== ""
      ) {
        const error = addFundCodeResponse.respdesc;
        errorMessagesArray = [];
        setErrorMessages([]);
        setSystemSuccesMessages([]);
        errorMessagesArray.push(error);
        setErrorMessages(errorMessagesArray);
      }
      if (
        addFundCodeResponse &&
        addFundCodeResponse.respcode === "01" &&
        addFundCodeResponse.respdesc !== ""
      ) {
        const success = AppConstants.SAVE_SUCCESS;
        setErrorMessages([]);
        setSystemSuccesMessages([]);
        sucessMessages.push(success);
        setSystemSuccesMessages(sucessMessages);
        let cosCode = null;
        if (fundCodeTableData) {
          for (var i = 0; i < fundCodeTableData.length; i++) {
            if (fundCodeTableData[i].cos) {
              cosCode = fundCodeTableData[i].cos;
              break;
            } else {
              cosCode = null;
            }
          }
        }
        const tempvalue = {
          lobCode: AppConstants.DEFAULT_LOB_VALUE,
          fundCode: values.fundCode,
          activityCode: values.agency,
          cosCode: cosCode,
          beginDate: selectedBeginDate
            ? getDateInYYYYMMDDFormatWithApendZero(selectedBeginDate)
            : "",
          endDate: selectedEndDate
            ? getDateInYYYYMMDDFormatWithApendZero(selectedEndDate)
            : ""
        };
          onRowClick(tempvalue);
      }
      if (
        addFundCodeResponse &&
        addFundCodeResponse.respcode === "02" &&
        addFundCodeResponse.respdesc !== ""
      ) {
        const error = addFundCodeResponse.respdesc;
        errorMessagesArray = [];
        setErrorMessages([]);
        setSystemSuccesMessages([]);
        errorMessagesArray.push(error);
        setErrorMessages(errorMessagesArray);
      }
    }
    if (addFundCodeResponse && addFundCodeResponse.systemFailure) {
      setErrorMessages([]);
      setSystemSuccesMessages([]);
      errorMessagesArray.push(fundCodeConstants.TRANSACTION_ERROR);

      setErrorMessages(errorMessagesArray);
    }
  }, [addFundCodeResponse]);

  /* Display success/error message after updating fund code details based on the response and response description coming from the API */
  useEffect(() => {
    setAllowNavigation(false);
    if (updateFundCodeResponse && !updateFundCodeResponse.systemFailure) {
      if (
        updateFundCodeResponse &&
        updateFundCodeResponse.respcode === "03" &&
        updateFundCodeResponse.respdesc !== ""
      ) {
        errorMessagesArray = [];
        setErrorMessages([]);
        setSystemSuccesMessages([]);

        setErrorMessages(updateFundCodeResponse.errorMessages);
      }
      if (
        updateFundCodeResponse &&
        updateFundCodeResponse.respcode === "01" &&
        updateFundCodeResponse.respdesc !== ""
      ) {
        setAllowNavigation(false);
        setErrorMessages([]);

        setSystemSuccesMessages([updateFundCodeResponse.respdesc]);
        setFocusCheck(false);
        let cosCode = "";
        if (fundCodeTableData) {
          for (var i = 0; i < fundCodeTableData.length; i++) {
            if (fundCodeTableData[i].cos) {
              cosCode = fundCodeTableData[i].cos;
              break;
            } else {
              cosCode = "";
            }
          }
        }
        const tempvalue = {
          lobCode: AppConstants.DEFAULT_LOB_VALUE,
          fundCode: values.fundCode,
          activityCode: values.agency,
          cosCode: cosCode,
          beginDate: selectedBeginDate
            ? getDateInYYYYMMDDFormatWithApendZero(selectedBeginDate)
            : "",
          endDate: selectedEndDate
            ? getDateInYYYYMMDDFormatWithApendZero(selectedEndDate)
            : ""
        };
          onRowClick(tempvalue);
      }
      if (
        updateFundCodeResponse &&
        updateFundCodeResponse.respcode === "02" &&
        updateFundCodeResponse.respdesc !== ""
      ) {
        errorMessagesArray = [];
        setErrorMessages([]);
        setSystemSuccesMessages([]);

        setErrorMessages(updateFundCodeResponse.errorMessages);
      }
    }
    if (updateFundCodeResponse && updateFundCodeResponse.systemFailure) {
      setErrorMessages([]);
      setSystemSuccesMessages([]);
      errorMessagesArray.push(fundCodeConstants.TRANSACTION_ERROR);

      setErrorMessages(errorMessagesArray);
    }
  }, [updateFundCodeResponse]);

  /* This function is used to create criteria table data from fund code data coming from back-end
   *  and displaying table data as a non-voided data intially
   */
  const createFundCodeTableData = fundCodeTabData => {
    tabFundData = [];
    if (fundCodeTabData && fundCodeTabData.length > 0) {
      fundCodeTabData.map((tabValue, index) => {
        const value = {};
        value.fundCodeAssignCretiriaSK = tabValue.fundCodeAssignCretiriaSK;
        value.sequenceNumber = tabValue.sequenceNumber;
        value.auditUserID = tabValue.auditUserID;
        value.auditTimeStamp = tabValue.auditTimeStamp;
        value.addedAuditTimeStamp = tabValue.addedAuditTimeStamp;
        value.addedAuditUserID = tabValue.addedAuditUserID;
        value.cos = tabValue.categoryOfServiceCode;
        value.benefitPlanId = tabValue.benefitPlanID;
        value.bProviderType = tabValue.renderingProviderTypeCode;
        value.bProviderIdType = tabValue.billingProviderAltTypeCode;
        value.bProviderId = tabValue.billingProviderAltID;
        value.level = tabValue.levelOfCare;
        value.memberBAge = tabValue.ageFromNumber;
        value.memberEAge = tabValue.ageThruNumber;
        value.coe = tabValue.coeCode;
        value.taxo = tabValue.taxonomyCode;
        value.claimType = tabValue.claimTypeCode;
        value.serviceBeginDate = tabValue.serviceBeginDate;
        value.serviceEndDate = tabValue.serviceEndDate;
        value.familyPlan = tabValue.familyPlanIndicator;
        value.defaultCheckVal = tabValue.defaultIND;
        value.mapsetId = tabValue.mapsetId;
        value.procCodeValue = tabValue.procedureCode;
        value.procBeginCode = tabValue.procedureFromCode;
        value.procRadioVal =
          tabValue.procedureCodeIndicator === "R"
            ? "procedureCodeRange"
            : tabValue.procedureCodeIndicator === "V"
            ? "procedureCodeValue"
            : "procedureCodeNA";
        value.procEndCode = tabValue.procedureThruCode;
        value.modi1 = tabValue.procedureModifierCode1;
        value.modi2 = tabValue.procedureModifierCode2;
        value.modi3 = tabValue.procedureModifierCode3;
        value.modi4 = tabValue.procedureModifierCode4;
        value.voidYesNo = tabValue.voidDate != null ? "Y" : "N";
        value.voidDate = tabValue.voidDate;
        value.cosForTable = tabValue.categoryOfServiceCode;
        value.benefitPlanIdForTable = tabValue.benefitPlanID;
        value.bProviderIdTypeForTable = tabValue.billingProviderAltTypeCode;
        value.bProviderTypeForTable = tabValue.renderingProviderTypeCode;
        value.mapsetIdForTable = tabValue.mapsetId;
        value.claimTypeForTable = tabValue.claimTypeCode;
        if (value.cos != null && value.cos !== undefined) {
          const cosCode =
              addDropdowns &&
              addDropdowns['Claims#C_COS_CD'] &&
              addDropdowns['Claims#C_COS_CD'].filter(
                value => value.code === tabValue.categoryOfServiceCode
              );
          value.cosForTable =
            cosCode && cosCode.length > 0
                  ? cosCode[0].description
                  : tabValue.categoryOfServiceCode ? tabValue.categoryOfServiceCode : '';
        }
        if (value.benefitPlanId != null && value.benefitPlanId !== undefined) {
          for (var planIds in planIdList) {
            if (planIdList[planIds].code === value.benefitPlanId) {
              value.benefitPlanIdForTable = planIdList[planIds].code + "-" + planIdList[planIds].description;
            }
          }
        }

        if (
          value.bProviderIdType != null &&
          value.bProviderIdType !== undefined
        ) {
          const provCode =
          addDropdowns &&
          addDropdowns['P1#P_ALT_ID_TY_CD'] &&
          addDropdowns['P1#P_ALT_ID_TY_CD'].filter(
            value => value.code === tabValue.billingProviderAltTypeCode
          );
          value.bProviderIdTypeForTable =
             provCode && provCode.length > 0
              ? provCode[0].description
              : tabValue.billingProviderAltTypeCode ? tabValue.billingProviderAltTypeCode : '';
        }

        if (value.bProviderType != null && value.bProviderType !== undefined) {
          const bProviderType =
              addDropdowns &&
                addDropdowns['P1#P_TY_CD'] &&
                addDropdowns['P1#P_TY_CD'].filter(
                  value => value.code === tabValue.renderingProviderTypeCode
                );
              value.bProviderTypeForTable =
                bProviderType && bProviderType.length > 0
                ? bProviderType[0].description
                : tabValue.renderingProviderTypeCode ? tabValue.renderingProviderTypeCode : '';
        }

        if (value.claimType != null && value.claimType !== undefined) {
          const claimType =
              addDropdowns &&
                addDropdowns['C1#C_TY_CD'] &&
                addDropdowns['C1#C_TY_CD'].filter(
                  value => value.code === tabValue.claimTypeCode
                );
            value.claimTypeForTable =
              claimType && claimType.length > 0
                ? claimType[0].description
                : tabValue.claimTypeCode ? tabValue.claimTypeCode : '';
        }

        if (value.mapsetId != null && value.mapsetId !== undefined) {
          for (var mTypes in mapIdList) {
            if (mapIdList[mTypes].code === value.mapsetId) {
              value.mapsetIdForTable =
                mapIdList[mTypes].code + "-" + mapIdList[mTypes].description;
            }
          }
        }

        if (
          value.memberBAge !== "" &&
          value.memberBAge != null &&
          value.memberEAge !== "" &&
          value.memberEAge != null
        ) {
          value.memberAgeForTable = value.memberBAge + "-" + value.memberEAge;
        }

        let finalDataElement = {};
        finalDataElement = value;
        tabFundData.push(finalDataElement);
      });
    }
    setFundCodeTableData(tabFundData);
    const filterData = tabFundData.filter(data => {
      return data.voidDate === null;
    });
    setFundCodeNonVoidedData(filterData);
  };

  /* This function is used to handle default checkbox for add criteria pop-up */
  const handleChangeDefault = () => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setDefaultCheck(!defaultCheck);
  };

  /* This function is used to handle change in any input field */
  const handleChange = name => event => {
    if (name === 'rank' || name === 'agency' || name === 'fundCode') {
      setInitalFocusCheck(true);
    }
    setFocusCheck(true);
    setAllowNavigation(true);
    setSystemSuccesMessages([]);
    setAllowNavigation(true);
    if (
      name === "modifier1" ||
      name === "modifier2" ||
      name === "modifier3" ||
      name === "modifier4" ||
      name === "procedureValueCode" ||
      name === "procedureBeginCode" ||
      name === "procedureEndCode"
    ) {
      setValues({ ...values, [name]: event.target.value.toUpperCase() });
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
    if (name === "procedureCode") {
      setProcedureCode(event.target.value);
      if (event.target.value === "procedureCodeNA") {
        setFieldValues(
          values.agency,
          values.fundCode,
          values.rank,
          values.catService,
          values.benefitPlanId,
          values.bProviderIdType,
          values.bProviderId,
          values.bProviderType,
          values.familyPlanning,
          values.taxonomy,
          values.coe,
          values.levelCare,
          values.claimType,
          values.memberBeginAge,
          values.memberEndAge,
          values.mapsetId,
          "procedureCodeNA",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          values.voidYesNo
        );
      } else if (event.target.value === "procedureCodeRange") {
        setFieldValues(
          values.agency,
          values.fundCode,
          values.rank,
          values.catService,
          values.benefitPlanId,
          values.bProviderIdType,
          values.bProviderId,
          values.bProviderType,
          values.familyPlanning,
          values.taxonomy,
          values.coe,
          values.levelCare,
          values.claimType,
          values.memberBeginAge,
          values.memberEndAge,
          values.mapsetId,
          "procedureCodeRange",
          values.procedureBeginCode,
          values.procedureEndCode,
          "",
          "",
          "",
          "",
          "",
          values.voidYesNo
        );
      } else {
        setFieldValues(
          values.agency,
          values.fundCode,
          values.rank,
          values.catService,
          values.benefitPlanId,
          values.bProviderIdType,
          values.bProviderId,
          values.bProviderType,
          values.familyPlanning,
          values.taxonomy,
          values.coe,
          values.levelCare,
          values.claimType,
          values.memberBeginAge,
          values.memberEndAge,
          values.mapsetId,
          "procedureCodeValue",
          "",
          "",
          values.procedureValueCode,
          values.modifier1,
          values.modifier2,
          values.modifier3,
          values.modifier4,
          values.voidYesNo
        );
      }
    }
  };

  /* This function is used to handle change in void type field in header */
  const handleChangeYesNo = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setInitalFocusCheck(true);
    setValuesYesNoHeader({ ...valuesYesNoHeader, [name]: event.target.value });
  };

  /* This function is used to set service begin date using date picker */
  const handleServiceBeginDate = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedServiceBeginDate(date);
  };

  /* This function is used to set service end date using date picker */
  const handleServiceEndDate = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedServiceEndDate(date);
  };

  /* This function is used to handle change in any tab */
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  /* This function is used to set begin date using date picker */
  const handleBeginDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setInitalFocusCheck(true);
    setSelectedBeginDate(date);
    setBeginDatePress(false);
  };

  /* This function is used to set end date using date picker */
  const handleEndDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setInitalFocusCheck(true);
    setSelectedEndDate(date);
    setEndDatePress(false);
  };

  /* This function is called on click of Add Assignment button
   *  If all required header fields entered
   *  Open dialog box to display add criteria pop-up fields
   *  Set fields to initial state
   */
  const handleClickOpenAddAssignment = () => {
    setDisabled(false);
    setShowVoidYesNo(false);
    showValidationErrors();
    if (open && document.getElementById("form_pop_up")) {
 document.getElementById("form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
}
    setShowLogDialogTable(false);
    setAuditLogRow(null);
  };

  /* This function is used to validate header fields
   *  and displaying errors if validation fails
   *  open criteria pop-up if validation passes
   */
  const showValidationErrors = () => {
    validationErrors = validateHeaderFields(
      values,
      selectedBeginDate,
      selectedEndDate
    );
    if (
      validationErrors.errorMessagesArray &&
      validationErrors.errorMessagesArray.length > 0
    ) {
      setSystemSuccesMessages([]);
      errorMessagesArray = [];
      errorMessagesArray.push(validationErrors.errorMessagesArray);
      setErrorMessages(validationErrors.errorMessagesArray);
      setErrorMegBeginDate(validationErrors.beginDateErrorMsg);
      setErrorMegEndDate(validationErrors.endDateErrorMsg);
      setShowError({
        showAgencyError: validationErrors.setErrors.showAgencyError,
        showFundCodeError: validationErrors.setErrors.showFundCodeError,
        showRankError: validationErrors.setErrors.showRankError,
        showBeginDateError: validationErrors.setErrors.showBeginDateError,
        showEndDateError: validationErrors.setErrors.showEndDateError
      });
    }
    if (
      validationErrors.errorMessagesArray &&
      validationErrors.errorMessagesArray.length === 0
    ) {
      setShowError(false);
      setErrorMessages([]);
      setOpen(true);
      setAdd(true);
      setSelectedServiceBeginDate(null);
      setSelectedServiceEndDate(null);
      setProcedureCode("procedureCodeNA");
      setRetainValues(values);
      setFieldValues(
        values.agency,
        values.fundCode,
        values.rank,
        DEFAULT_DD_VALUE,
        DEFAULT_DD_VALUE,
        DEFAULT_DD_VALUE,
        "",
        DEFAULT_DD_VALUE,
        "",
        "",
        DEFAULT_DD_VALUE,
        DEFAULT_DD_VALUE,
        DEFAULT_DD_VALUE,
        "",
        "",
        DEFAULT_DD_VALUE,
        "procedureCodeNA",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      );
      setValuesYesNoHeader({
        voidYesNoHeader: valuesYesNoHeader.voidYesNoHeader
      });
      setDefaultCheck(false);
    }
  };

  /* This function is used to validate criteria fields
   *  and displaying errors if validation fails
   */
  const showCriteriaValidationErrors = () => {
    criteriaValidationErrors = validateCriteria(
      values,
      selectedServiceBeginDate,
      selectedServiceEndDate,
      procedureCodeVal,
      defaultCheck
    );
    if (
      criteriaValidationErrors.popUpErrorMessagesArray &&
      criteriaValidationErrors.popUpErrorMessagesArray.length > 0
    ) {
      setSystemSuccesMessages([]);
      setPopUpErrorMessage(criteriaValidationErrors.popUpErrorMessagesArray);
      setShowError({
        showPopUpError: criteriaValidationErrors.setErrors.showPopUpError,
        showProviderIdTypeError:
          criteriaValidationErrors.setErrors.showProviderIdTypeError,
        showProviderTypeError:
          criteriaValidationErrors.setErrors.showProviderTypeError,
        showProviderIdError:
          criteriaValidationErrors.setErrors.showProviderIdError,
        showProcedureRangeCodeError:
          criteriaValidationErrors.setErrors.showProcedureRangeCodeError,
        showMemberAgeError:
          criteriaValidationErrors.setErrors.showMemberAgeError,
        showProcedureCodeValueError:
          criteriaValidationErrors.setErrors.showProcedureCodeValueError,
        showMapSetIdError: criteriaValidationErrors.setErrors.showMapSetIdError,
        showServiceBeginDateError:
          criteriaValidationErrors.setErrors.showServiceBeginDateError,
        showServiceEndDateError:
          criteriaValidationErrors.setErrors.showServiceEndDateError
      });

      setShowErrorText({
        showPopUpErrorText:
          criteriaValidationErrors.setErrorText.showPopUpErrorText,
        showProviderIdTypeErrorText:
          criteriaValidationErrors.setErrorText.showProviderIdTypeErrorText,
        showProviderTypeErrorText:
          criteriaValidationErrors.setErrorText.showProviderTypeErrorText,
        showProviderIdErrorText:
          criteriaValidationErrors.setErrorText.showProviderIdErrorText,
        showProcedureRangeCodeErrorText:
          criteriaValidationErrors.setErrorText.showProcedureRangeCodeErrorText,
        showMemberAgeErrorText:
          criteriaValidationErrors.setErrorText.showMemberAgeErrorText,
        showProcedureCodeValueErrorText:
          criteriaValidationErrors.setErrorText.showProcedureCodeValueErrorText,
        showMapSetIdErrorText:
          criteriaValidationErrors.setErrorText.showMapSetIdErrorText,
        showServiceBeginDateErrorText:
          criteriaValidationErrors.setErrorText.showServiceBeginDateErrorText,
        showServiceEndDateErrorText:
          criteriaValidationErrors.setErrorText.showServiceEndDateErrorText
      });
      setErrorMessages(criteriaValidationErrors.errorMessagesArray);
    }

    if (
      criteriaValidationErrors.popUpErrorMessagesArray &&
      criteriaValidationErrors.errorMessagesArray.length > 0
    ) {
      setSystemSuccesMessages([]);
      setErrorMessages([]);
      setErrorMessages(criteriaValidationErrors.errorMessagesArray);
    }
  };

  /* This function is called to close the fund code criteria pop-up */
  const handleClose = () => {
    setFocusCheck(initalFocusCheck);
    setAllowNavigation(allowNavigation);
    setOpen(false);
    setSelectedServiceBeginDate(null);
    setSelectedServiceEndDate(null);
    setProcedureCode("procedureCodeNA");
    setFieldValues(
      values.agency,
      values.fundCode,
      values.rank,
      DEFAULT_DD_VALUE,
      DEFAULT_DD_VALUE,
      DEFAULT_DD_VALUE,
      "",
      DEFAULT_DD_VALUE,
      "",
      "",
      DEFAULT_DD_VALUE,
      DEFAULT_DD_VALUE,
      DEFAULT_DD_VALUE,
      "",
      "",
      DEFAULT_DD_VALUE,
      "procedureCodeNA",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
    setValuesYesNoHeader({
      voidYesNoHeader: valuesYesNoHeader.voidYesNoHeader
    });
    setDefaultCheck(false);
  };

  /* This function is called on click of delete checkbox to delete row(s) */
  function AssignmentRowDeleteClick () {
    let temNewDialogData = [...fundCodeTableData];
    if (
      rowFundCodeData.rowFundCodeData != null ||
      rowFundCodeData.rowFundCodeData !== undefined
    ) {
      for (let i = 0; i < rowFundCodeData.rowFundCodeData.length; i++) {
        temNewDialogData = temNewDialogData.filter(
          payment => payment.id !== rowFundCodeData.rowFundCodeData[i]
        );
      }
      setFundCodeTableData(temNewDialogData);
      const filterData = temNewDialogData.filter(data => {
        return data.voidDate === null;
      });
      setFundCodeNonVoidedData(filterData);
      setRowFundCodeData([]);
    }
    setAllowNavigation(true);
  }

  /* This function is deleting row */
  const rowDeleteFundCode = data => {
    setRowFundCodeData({ ...rowFundCodeData, rowFundCodeData: data });
  };

  /* This function is called on click of a row inside table to edit it's values
   *  Setting "add" variable false to recognise that it is for edit
   *  Setting all fields with initial filled values inside add payment schedule pop-up
   */
  const editRow = data => event => {
    setAuditLogRow(data);
    if (showEditScreen) {
      setShowVoidYesNo(true);
    } else {
      setShowVoidYesNo(false);
    }
    setAdd(false);
    setEditData(data);
    setRetainEdit(data);
    setOpen(true);
    setRetainServiceBeginDate(data.serviceBeginDate);
    setRetainServiceEndDate(data.serviceEndDate);
    setSelectedServiceBeginDate(data.serviceBeginDate);
    setSelectedServiceEndDate(data.serviceEndDate);

    setFieldValues(
      values.agency,
      values.fundCode,
      values.rank,
      data.cos,
      data.benefitPlanId,
      data.bProviderIdType,
      data.bProviderId,
      data.bProviderType,
      data.familyPlan,
      data.taxo,
      data.coe,
      data.level,
      data.claimType,
      data.memberBAge,
      data.memberEAge,
      data.mapsetId,
      data.procRadioVal,
      data.procBeginCode,
      data.procEndCode,
      data.procCodeValue,
      data.modi1,
      data.modi2,
      data.modi3,
      data.modi4,
      data.voidYesNo
    );
    setValuesYesNoHeader({
      voidYesNoHeader: valuesYesNoHeader.voidYesNoHeader
    });
    setDefaultCheck(data.defaultCheckVal);
    setProcedureCode(data.procRadioVal);
    if (data.voidYesNo === "Y") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setShowError({
      showPopUpError: false,
      showProviderIdTypeError: false,
      showProviderTypeError: false,
      showProviderIdError: false,
      showProcedureRangeCodeError: false,
      showMemberAgeError: false,
      showProcedureCodeValueError: false,
      showMapSetIdError: false,
      showServiceBeginDateError: false,
      showServiceEndDateError: false
    });

    setShowErrorText({
      showPopUpErrorText: "",
      showProviderIdTypeErrorText: "",
      showProviderTypeErrorText: "",
      showProviderIdErrorText: "",
      showProcedureRangeCodeErrorText: "",
      showMemberAgeErrorText: "",
      showProcedureCodeValueErrorText: "",
      showMapSetIdErrorText: "",
      showServiceBeginDateErrorText: "",
      showServiceEndDateErrorText: ""
    });
    if (open && document.getElementById("form_pop_up")) {
 document.getElementById("form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
}
  };

  /* This function is used to add fund code criteria on click of add in pop-up
   *  Before adding/updating check for validations passes or not
   *  If "Edit" update the criteria with new values entered
   */
  const saveAssignment = () => {
    if (add) {
      const assignmentData = fundCodeTableData;
      showCriteriaValidationErrors();

      if (
        criteriaValidationErrors.popUpErrorMessagesArray &&
        criteriaValidationErrors.popUpErrorMessagesArray.length === 0
      ) {
        setShowError(false);
        let count = 0;
        const overlapCount = 0;
        var showFundCodeDuplicateError = false;

        var value = {
          id: generateUUID(),
          versionNo: 0,
          cos:
            values.catService !== DEFAULT_DD_VALUE
              ? values.catService
              : null,
          benefitPlanId:
            values.benefitPlanId !== DEFAULT_DD_VALUE
              ? values.benefitPlanId
              : null,
          bProviderIdType:
            values.bProviderIdType !== DEFAULT_DD_VALUE
              ? values.bProviderIdType
              : null,
          bProviderId: values.bProviderId,
          bProviderType:
            values.bProviderType !== DEFAULT_DD_VALUE
              ? values.bProviderType
              : null,
          memberBAge: values.memberBeginAge,
          memberEAge: values.memberEndAge,
          claimType:
            values.claimType !== DEFAULT_DD_VALUE
              ? values.claimType
              : null,
          mapsetId:
            values.mapsetId !== DEFAULT_DD_VALUE
              ? values.mapsetId
              : null,
          taxo: values.taxonomy,
          coe:
            values.coe !== DEFAULT_DD_VALUE ? values.coe : null,
          level:
            values.levelCare !== DEFAULT_DD_VALUE
              ? values.levelCare
              : null,
          serviceBeginDate: selectedServiceBeginDate
            ? moment(new Date(selectedServiceBeginDate).getTime()).format(
                "MM/DD/YYYY"
              )
            : null,
          serviceEndDate: selectedServiceEndDate
            ? moment(new Date(selectedServiceEndDate).getTime()).format(
                "MM/DD/YYYY"
              )
            : null,
          familyPlan:
            values.familyPlanning === "Y"
              ? "Y"
              : values.familyPlanning === "N"
              ? "N"
              : "",
          defaultCheckVal: defaultCheck,
          procRadioVal:
            values.procedureCode === "procedureCodeRange"
              ? "procedureCodeRange"
              : values.procedureCode === "procedureCodeValue"
              ? "procedureCodeValue"
              : values.procedureCode === "procedureCodeNA"
              ? "procedureCodeNA"
              : "",
          procBeginCode: values.procedureBeginCode,
          procEndCode: values.procedureEndCode,
          procCodeValue: values.procedureValueCode,
          modi1: values.modifier1,
          modi2: values.modifier2,
          modi3: values.modifier3,
          modi4: values.modifier4,
          agencyCode: values.agency,
          fundCodeValue: values.fundCode,
          rankCode: values.rank,
          beginDateValue: selectedBeginDate,
          endDateValue: selectedEndDate,
          voidYesNo: values.voidYesNo,
          voidDate:
            values.voidYesNo === "Y"
              ? moment(new Date()).format("MM/DD/YYYY")
              : null,
          voidYesNoHeader: valuesYesNoHeader.voidYesNoHeader,
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: logInUserID,
          addedAuditTimeStamp: getUTCTimeStamp()
        };

        if (fundCodeTableData && fundCodeTableData.length > 0) {
          fundCodeTableData.map((value, index) => {
            if (value.voidDate === null) {
              if (values.catService === DEFAULT_DD_VALUE) {
                values.catService = null;
              }
              if (values.benefitPlanId === DEFAULT_DD_VALUE) {
                values.benefitPlanId = null;
              }
              if (values.bProviderIdType === DEFAULT_DD_VALUE) {
                values.bProviderIdType = null;
              }
              if (value.bProviderIdType === undefined) {
                value.bProviderIdType = null;
              }
              if (values.bProviderType === DEFAULT_DD_VALUE) {
                values.bProviderType = null;
              }
              if (values.claimType === DEFAULT_DD_VALUE) {
                values.claimType = null;
              }
              if (values.coe === DEFAULT_DD_VALUE) {
                values.coe = null;
              }
              if (values.levelCare === DEFAULT_DD_VALUE) {
                values.levelCare = null;
              }
              if (values.mapsetId === DEFAULT_DD_VALUE) {
                values.mapsetId = null;
              }

              if (
                (value.cos === values.catService &&
                  value.benefitPlanId === values.benefitPlanId &&
                  value.bProviderIdType === values.bProviderIdType &&
                  (value.bProviderId === values.bProviderId ||
                    ((value.bProviderId === null || value.bProviderId === "") &&
                      (values.bProviderId === null ||
                        values.bProviderId === ""))) &&
                  value.bProviderType === values.bProviderType &&
                  (value.memberBAge === values.memberBeginAge ||
                    ((value.memberBAge === null || value.memberBAge === "") &&
                      (values.memberBeginAge === null ||
                        values.memberBeginAge === ""))) &&
                  (value.memberEAge === values.memberEndAge ||
                    ((value.memberEAge === null || value.memberEAge === "") &&
                      (values.memberEndAge === null ||
                        values.memberEndAge === ""))) &&
                  value.claimType === values.claimType &&
                  (value.taxo === values.taxonomy ||
                    ((value.taxo === null || value.taxo === "") &&
                      (values.taxonomy === null || values.taxonomy === ""))) &&
                  value.coe === values.coe &&
                  value.level === values.levelCare &&
                  (selectedServiceBeginDate
                    ? value.serviceBeginDate ===
                      moment(new Date(selectedServiceBeginDate)).format("L")
                    : value.serviceBeginDate === selectedServiceBeginDate) &&
                  (selectedServiceEndDate
                    ? value.serviceEndDate ===
                      moment(new Date(selectedServiceEndDate)).format("L")
                    : value.serviceEndDate === selectedServiceEndDate) &&
                  value.familyPlan === values.familyPlanning &&
                  value.defaultCheckVal === defaultCheck &&
                  value.procRadioVal === values.procedureCode &&
                  (value.procBeginCode === values.procedureBeginCode ||
                    ((value.procBeginCode === null ||
                      value.procBeginCode === "") &&
                      (values.procedureBeginCode === null ||
                        values.procedureBeginCode === ""))) &&
                  (value.procEndCode === values.procedureEndCode ||
                    ((value.procEndCode === null || value.procEndCode === "") &&
                      (values.procedureEndCode === null ||
                        values.procedureEndCode === ""))) &&
                  (value.procCodeValue === values.procedureValueCode ||
                    ((value.procCodeValue === null ||
                      value.procCodeValue === "") &&
                      (values.procedureValueCode === null ||
                        values.procedureValueCode === ""))) &&
                  (value.modi1 === values.modifier1 ||
                    ((value.modi1 === null || value.modi1 === "") &&
                      (values.modifier1 === null ||
                        values.modifier1 === ""))) &&
                  (value.modi2 === values.modifier2 ||
                    ((value.modi2 === null || value.modi2 === "") &&
                      (values.modifier2 === null ||
                        values.modifier2 === ""))) &&
                  (value.modi3 === values.modifier3 ||
                    ((value.modi3 === null || value.modi3 === "") &&
                      (values.modifier3 === null ||
                        values.modifier3 === ""))) &&
                  (value.modi4 === values.modifier4 ||
                    ((value.modi4 === null || value.modi4 === "") &&
                      (values.modifier4 === null ||
                        values.modifier4 === ""))) &&
                  value.mapsetId === values.mapsetId) ||
                (value.cos === values.catService &&
                  !value.benefitPlanId &&
                  !value.bProviderIdType &&
                  !value.bProviderId &&
                  !value.bProviderType &&
                  !value.memberBAge &&
                  !value.memberEAge &&
                  !value.claimType &&
                  !value.taxo &&
                  !value.coe &&
                  !value.level &&
                  !value.serviceBeginDate &&
                  !value.serviceEndDate &&
                  !value.familyPlan &&
                  !value.defaultCheckVal &&
                  !value.procRadioVal &&
                  !value.procBeginCode &&
                  !value.procEndCode &&
                  !value.procCodeValue &&
                  !value.modi1 &&
                  !value.modi2 &&
                  !value.modi3 &&
                  !value.modi4 &&
                  !value.mapsetId)
              ) {
                count = count + 1;
              }
            }
          });
        }
        var addVal = "add";
        showFundCodeDuplicateError = showCountError(
          count,
          overlapCount,
          addVal
        );

        if (!showFundCodeDuplicateError) {
          if (value.cos != null && value.cos !== undefined) {
            const cosCode =
              addDropdowns &&
              addDropdowns['Claims#C_COS_CD'] &&
              addDropdowns['Claims#C_COS_CD'].filter(
                  value => value.code === value.cos
              );
            value.cosForTable =
              cosCode && cosCode.length > 0
                ? cosCode[0].description
                : value.cos ? value.cos : '';
          }

          if (
            value.benefitPlanId != null &&
            value.benefitPlanId !== undefined
          ) {
            for (var planIds in planIdList) {
              if (planIdList[planIds].code === value.benefitPlanId) {
                value.benefitPlanIdForTable = planIdList[planIds].code + "-" + planIdList[planIds].description;
              }
            }
          }

          if (
            value.bProviderIdType != null &&
            value.bProviderIdType !== undefined
          ) {
            const provCode =
             addDropdowns &&
             addDropdowns['P1#P_ALT_ID_TY_CD'] &&
             addDropdowns['P1#P_ALT_ID_TY_CD'].filter(
            value => value.code === value.bProviderIdType
          );
          value.bProviderIdTypeForTable =
             provCode && provCode.length > 0
              ? provCode[0].description
              : value.bProviderIdType ? value.bProviderIdType : '';
          }

          if (
            value.bProviderType != null &&
            value.bProviderType !== undefined
          ) {
            const bProviderType =
            addDropdowns &&
              addDropdowns['P1#P_TY_CD'] &&
              addDropdowns['P1#P_TY_CD'].filter(
                value => value.code === value.bProviderType
              );
            value.bProviderTypeForTable =
              bProviderType && bProviderType.length > 0
              ? bProviderType[0].description
              : value.bProviderType ? value.bProviderType : '';
          }

          if (value.claimType != null && value.claimType !== undefined) {
            const claimType =
              addDropdowns &&
                addDropdowns['C1#C_TY_CD'] &&
                addDropdowns['C1#C_TY_CD'].filter(
                  value => value.code === value.claimType
                );
              value.claimTypeForTable =
              claimType && claimType.length > 0
                ? claimType[0].description
                : value.claimType ? value.claimType : '';
          }
          if (value.mapsetId != null && value.mapsetId !== undefined) {
            for (var mTypes in mapIdList) {
              if (mapIdList[mTypes].code === value.mapsetId) {
                value.mapsetIdForTable =
                  mapIdList[mTypes].code + "-" + mapIdList[mTypes].description;
              }
            }
          }
          if (value.memberBAge !== "" && value.memberEAge !== "") {
            value.memberAgeForTable = value.memberBAge + "-" + value.memberEAge;
          }
          assignmentData.push(value);
          setFundCodeTableData(assignmentData);
          const filterData = assignmentData.filter(data => {
            return data.voidDate === null;
          });
          setFundCodeNonVoidedData(filterData);
        }
        handleClose();
      }
    } else {
      showCriteriaValidationErrors();
      if (
        criteriaValidationErrors.popUpErrorMessagesArray &&
        criteriaValidationErrors.popUpErrorMessagesArray.length === 0
      ) {
        // eslint-disable-next-line no-redeclare
        var addVal = "add";
        let count = 0;
        const overlapCount = 0;
        const selectedIndex = fundCodeTableData && fundCodeTableData.findIndex(item => { return item.sequenceNumber ? item.sequenceNumber === editData.sequenceNumber : item.id === editData.id; });
        if (fundCodeTableData && fundCodeTableData.length > 0) {
          fundCodeTableData.map((value, index) => {
            if (
              index !==
                fundCodeTableData[selectedIndex] &&
              value.voidDate === null
            ) {
              if (value.voidDate === null) {
                if (values.catService === DEFAULT_DD_VALUE) {
                  values.catService = null;
                }
                if (values.benefitPlanId === DEFAULT_DD_VALUE) {
                  values.benefitPlanId = null;
                }
                if (values.bProviderIdType === DEFAULT_DD_VALUE) {
                  values.bProviderIdType = null;
                }
                if (value.bProviderIdType === undefined) {
                  value.bProviderIdType = null;
                }
                if (values.bProviderType === DEFAULT_DD_VALUE) {
                  values.bProviderType = null;
                }
                if (values.claimType === DEFAULT_DD_VALUE) {
                  values.claimType = null;
                }
                if (values.coe === DEFAULT_DD_VALUE) {
                  values.coe = null;
                }
                if (values.levelCare === DEFAULT_DD_VALUE) {
                  values.levelCare = null;
                }
                if (values.mapsetId === DEFAULT_DD_VALUE) {
                  values.mapsetId = null;
                }

                if (
                  value.cos === values.catService &&
                  value.benefitPlanId === values.benefitPlanId &&
                  value.bProviderIdType === values.bProviderIdType &&
                  (value.bProviderId === values.bProviderId ||
                    ((value.bProviderId === null || value.bProviderId === "") &&
                      (values.bProviderId === null ||
                        values.bProviderId === ""))) &&
                  value.bProviderType === values.bProviderType &&
                  (value.memberBAge === values.memberBeginAge ||
                    ((value.memberBAge === null || value.memberBAge === "") &&
                      (values.memberBeginAge === null ||
                        values.memberBeginAge === ""))) &&
                  (value.memberEAge === values.memberEndAge ||
                    ((value.memberEAge === null || value.memberEAge === "") &&
                      (values.memberEndAge === null ||
                        values.memberEndAge === ""))) &&
                  value.claimType === values.claimType &&
                  (value.taxo === values.taxonomy ||
                    ((value.taxo === null || value.taxo === "") &&
                      (values.taxonomy === null || values.taxonomy === ""))) &&
                  value.coe === values.coe &&
                  value.level === values.levelCare &&
                  (selectedServiceBeginDate
                    ? value.serviceBeginDate ===
                      moment(new Date(selectedServiceBeginDate)).format("L")
                    : value.serviceBeginDate === selectedServiceBeginDate) &&
                  (selectedServiceEndDate
                    ? value.serviceEndDate ===
                      moment(new Date(selectedServiceEndDate)).format("L")
                    : value.serviceEndDate === selectedServiceEndDate) &&
                  value.familyPlan === values.familyPlanning &&
                  value.defaultCheckVal === defaultCheck &&
                  value.procRadioVal === values.procedureCode &&
                  (value.procBeginCode === values.procedureBeginCode ||
                    ((value.procBeginCode === null ||
                      value.procBeginCode === "") &&
                      (values.procedureBeginCode === null ||
                        values.procedureBeginCode === ""))) &&
                  (value.procEndCode === values.procedureEndCode ||
                    ((value.procEndCode === null || value.procEndCode === "") &&
                      (values.procedureEndCode === null ||
                        values.procedureEndCode === ""))) &&
                  (value.procCodeValue === values.procedureValueCode ||
                    ((value.procCodeValue === null ||
                      value.procCodeValue === "") &&
                      (values.procedureValueCode === null ||
                        values.procedureValueCode === ""))) &&
                  (value.modi1 === values.modifier1 ||
                    ((value.modi1 === null || value.modi1 === "") &&
                      (values.modifier1 === null ||
                        values.modifier1 === ""))) &&
                  (value.modi2 === values.modifier2 ||
                    ((value.modi2 === null || value.modi2 === "") &&
                      (values.modifier2 === null ||
                        values.modifier2 === ""))) &&
                  (value.modi3 === values.modifier3 ||
                    ((value.modi3 === null || value.modi3 === "") &&
                      (values.modifier3 === null ||
                        values.modifier3 === ""))) &&
                  (value.modi4 === values.modifier4 ||
                    ((value.modi4 === null || value.modi4 === "") &&
                      (values.modifier4 === null ||
                        values.modifier4 === ""))) &&
                  value.mapsetId === values.mapsetId &&
                  (value.voidDate === values.voidDate ||
                    (value.voidDate === null && values.voidDate === undefined))
                ) {
                  count = count + 1;
                }
              }
            }
          });
        }
        showFundCodeDuplicateError = showCountError(
          count,
          overlapCount,
          addVal
        );
        if (!showFundCodeDuplicateError && fundCodeTableData) {
          setShowError(false);
          if (values.catService !== DEFAULT_DD_VALUE) {
            editData.cos = values.catService || null;
          }

          fundCodeTableData[selectedIndex].benefitPlanId =
            values.benefitPlanId !== DEFAULT_DD_VALUE
              ? values.benefitPlanId
              : null;
              fundCodeTableData[selectedIndex].bProviderIdType =
                values.bProviderIdType !== DEFAULT_DD_VALUE
              ? values.bProviderIdType
              : null;
          fundCodeTableData[selectedIndex].bProviderId =
            values.bProviderId;
          fundCodeTableData[selectedIndex].bProviderType =
            values.bProviderType !== DEFAULT_DD_VALUE
              ? values.bProviderType
              : null;
          fundCodeTableData[selectedIndex].memberBAge =
            values.memberBeginAge;
          fundCodeTableData[selectedIndex].memberEAge =
            values.memberEndAge;
          fundCodeTableData[selectedIndex].claimType =
            values.claimType !== DEFAULT_DD_VALUE
              ? values.claimType
              : null;
          fundCodeTableData[selectedIndex].mapsetId =
            values.mapsetId !== DEFAULT_DD_VALUE
              ? values.mapsetId
              : null;
          fundCodeTableData[selectedIndex].taxo =
            values.taxonomy;
          fundCodeTableData[selectedIndex].coe =
            values.coe !== DEFAULT_DD_VALUE ? values.coe : null;
          fundCodeTableData[selectedIndex].level =
            values.levelCare !== DEFAULT_DD_VALUE
              ? values.levelCare
              : null;
          fundCodeTableData[selectedIndex].familyPlan =
            values.familyPlanning === "Y"
              ? "Y"
              : values.familyPlanning === "N"
              ? "N"
              : "";
          fundCodeTableData[selectedIndex].serviceBeginDate = selectedServiceBeginDate
            ? moment(new Date(selectedServiceBeginDate).getTime()).format(
                "MM/DD/YYYY"
              )
            : null;
          fundCodeTableData[selectedIndex].serviceEndDate = selectedServiceEndDate
            ? moment(new Date(selectedServiceEndDate).getTime()).format(
                "MM/DD/YYYY"
              )
            : null;
          fundCodeTableData[selectedIndex].defaultCheckVal = defaultCheck;
          fundCodeTableData[selectedIndex].procRadioVal =
            values.procedureCode === "procedureCodeRange"
              ? "procedureCodeRange"
              : values.procedureCode === "procedureCodeValue"
              ? "procedureCodeValue"
              : values.procedureCode === "procedureCodeNA"
              ? "procedureCodeNA"
              : "";
          fundCodeTableData[selectedIndex].procBeginCode =
            values.procedureBeginCode;
          fundCodeTableData[selectedIndex].procEndCode =
            values.procedureEndCode;
          fundCodeTableData[selectedIndex].procCodeValue =
            values.procedureValueCode;
          fundCodeTableData[selectedIndex].modi1 =
            values.modifier1;
          fundCodeTableData[selectedIndex].modi2 =
            values.modifier2;
          fundCodeTableData[selectedIndex].modi3 =
            values.modifier3;
          fundCodeTableData[selectedIndex].modi4 =
            values.modifier4;
          fundCodeTableData[selectedIndex].agencyCode =
            values.agency;
          fundCodeTableData[selectedIndex].fundCodeValue =
            values.fundCode;
          fundCodeTableData[selectedIndex].rankCode =
            values.rank;
            fundCodeTableData[selectedIndex].beginDateValue = selectedBeginDate;
            fundCodeTableData[selectedIndex].endDateValue = selectedEndDate;
          fundCodeTableData[selectedIndex].voidYesNoHeader = valuesYesNoHeader.voidYesNoHeader;
          fundCodeTableData[selectedIndex].voidYesNo =
            values.voidYesNo === "Y"
              ? "Y"
              : values.voidYesNo === "N"
              ? "N"
              : "";
          fundCodeTableData[selectedIndex].auditUserID = logInUserID;
          fundCodeTableData[selectedIndex].auditTimeStamp = getUTCTimeStamp();
          fundCodeTableData[selectedIndex].addedAuditUserID = fundCodeTableData[selectedIndex].addedAuditUserID
            ? fundCodeTableData[selectedIndex]
                .addedAuditUserID
            : logInUserID;
          fundCodeTableData[selectedIndex].addedAuditTimeStamp = fundCodeTableData[selectedIndex].addedAuditTimeStamp
            ? fundCodeTableData[selectedIndex]
                .addedAuditTimeStamp
            : getUTCTimeStamp();
          if (values.voidYesNo === "Y") {
           fundCodeTableData[selectedIndex].voidDate = moment(new Date()).format("MM/DD/YYYY");
          } else {
           fundCodeTableData[selectedIndex].voidDate = null;
          }
          if (
            values.catService !== DEFAULT_DD_VALUE &&
            values.catService != null &&
            values.catService !== undefined
          ) {
            for (var cosType in cosList) {
              if (cosList[cosType].code === values.catService) {
                fundCodeTableData[selectedIndex].cosForTable = cosList[cosType].description;
                fundCodeTableData[selectedIndex].cosCodeLongDesc = cosList[cosType].longDescription;
              }
            }
          } else {
           fundCodeTableData[selectedIndex].cosForTable = null;
           fundCodeTableData[selectedIndex].cosCodeLongDesc = null;
          }
          if (
            values.benefitPlanId !== DEFAULT_DD_VALUE &&
            values.benefitPlanId != null &&
            values.benefitPlanId !== undefined
          ) {
            for (var planId in planIdList) {
              if (planIdList[planId].code === values.benefitPlanId) {
                fundCodeTableData[selectedIndex].benefitPlanIdForTable = planIdList[planId].code + "-" + planIdList[planId].description;
              }
            }
          } else {
           fundCodeTableData[selectedIndex].benefitPlanIdForTable = null;
          }

          if (
            values.bProviderIdType !== DEFAULT_DD_VALUE &&
            values.bProviderIdType != null &&
            values.bProviderIdType !== undefined
          ) {
            for (var pIdType in prvIdTypeList) {
              if (prvIdTypeList[pIdType].code === values.bProviderIdType) {
                fundCodeTableData[selectedIndex].bProviderIdTypeForTable = prvIdTypeList[pIdType].description;
              }
            }
          } else {
           fundCodeTableData[selectedIndex].bProviderIdTypeForTable = null;
          }

          if (
            values.bProviderType !== DEFAULT_DD_VALUE &&
            values.bProviderType != null &&
            values.bProviderType !== undefined
          ) {
            for (var pType in prvTypeList) {
              if (prvTypeList[pType].code === values.bProviderType) {
                fundCodeTableData[selectedIndex].bProviderTypeForTable = prvTypeList[pType].description;
              }
            }
          } else {
           fundCodeTableData[selectedIndex].bProviderTypeForTable = null;
          }

          if (
            values.claimType !== DEFAULT_DD_VALUE &&
            values.claimType != null &&
            values.claimType !== undefined
          ) {
            for (var cType in claimList) {
              if (claimList[cType].code === values.claimType) {
                fundCodeTableData[selectedIndex].claimTypeForTable = claimList[cType].description;
              }
            }
          } else {
           fundCodeTableData[selectedIndex].claimTypeForTable = null;
          }

          if (
            values.mapsetId !== DEFAULT_DD_VALUE &&
            values.mapsetId != null &&
            values.mapsetId !== undefined
          ) {
            for (var mType in mapIdList) {
              if (mapIdList[mType].code === values.mapsetId) {
                fundCodeTableData[selectedIndex].mapsetIdForTable =
                  mapIdList[mType].code + "-" + mapIdList[mType].description;
              }
            }
          } else {
           fundCodeTableData[selectedIndex].mapsetIdForTable = null;
          }

          if (
            values.memberBeginAge !== "" &&
            values.memberBeginAge != null &&
            values.memberEndAge !== "" &&
            values.memberEndAge != null
          ) {
           fundCodeTableData[selectedIndex].memberAgeForTable =
              values.memberBeginAge + "-" + values.memberEndAge;
          } else {
           fundCodeTableData[selectedIndex].memberAgeForTable = "";
          }

          const filterData = fundCodeTableData.filter(data => {
            return data.voidDate === null;
          });
          setFundCodeNonVoidedData(filterData);
          setOpen(false);
          setAdd(true);
          setErrorMessages([]);
          setSystemSuccesMessages([]);
        }
        handleClose();
      }
    }
  };

  /* This function is used to validate header/criteria overlapping
   *  and displaying errors if validation fails
   */
  const showCountError = (count, overlapCount, addValue) => {
    var showFundCodeDuplicateError = false;
    if (count > 0) {
      errorMessagesArray = [];
      setErrorMessages([]);
      if (addValue === "add") {
        showFundCodeDuplicateError = true;
      }
      errorMessagesArray.push(fundCodeConstants.HEADER_VALUE_DUPLICATE);
      setErrorMessages(errorMessagesArray);
    }

    if (overlapCount > 0) {
      errorMessagesArray = [];
      setErrorMessages([]);
      if (addValue === "add") {
        showFundCodeDuplicateError = true;
      }
      errorMessagesArray.push(fundCodeConstants.DATES_OVERLAP);
      setErrorMessages(errorMessagesArray);
    }
    return showFundCodeDuplicateError;
  };

  /* This function is called on click of reset button inside add fund code criteria pop-up
   *  Setting all fields null if "Add" reset
   *  Setting all fields with initial filled values if "Edit" reset
   */
  const resetAssignment = () => {
    setFocusCheck(initalFocusCheck);
    setAllowNavigation(initalFocusCheck);
    if (add) {
      setProcedureCode("procedureCodeNA");
      setFieldValues(
        values.agency,
        values.fundCode,
        values.rank,
        DEFAULT_DD_VALUE,
        DEFAULT_DD_VALUE,
        DEFAULT_DD_VALUE,
        "",
        DEFAULT_DD_VALUE,
        "",
        "",
        DEFAULT_DD_VALUE,
        DEFAULT_DD_VALUE,
        DEFAULT_DD_VALUE,
        "",
        "",
        DEFAULT_DD_VALUE,
        "procedureCodeNA",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      );
      setValuesYesNoHeader({
        voidYesNoHeader: valuesYesNoHeader.voidYesNoHeader
      });
      setSelectedServiceBeginDate(null);
      setSelectedServiceEndDate(null);
      setDefaultCheck(false);
      setShowError(false);
      setErrorMessages([]);
    } else {
      setProcedureCode(retainEdit.procRadioVal);
      setSelectedServiceBeginDate(retainServiceBeginDate);
      setSelectedServiceEndDate(retainServiceEndDate);
      setFieldValues(
        values.agency,
        values.fundCode,
        values.rank,
        retainEdit.cos,
        retainEdit.benefitPlanId,
        retainEdit.bProviderIdType,
        retainEdit.bProviderId,
        retainEdit.bProviderType,
        retainEdit.familyPlan,
        retainEdit.taxo,
        retainEdit.coe,
        retainEdit.level,
        retainEdit.claimType,
        retainEdit.memberBAge,
        retainEdit.memberEAge,
        retainEdit.mapsetId,
        retainEdit.procRadioVal,
        retainEdit.procBeginCode,
        retainEdit.procEndCode,
        retainEdit.procCodeValue,
        retainEdit.modi1,
        retainEdit.modi2,
        retainEdit.modi3,
        retainEdit.modi4,
        retainEdit.voidYesNo
      );
      setValuesYesNoHeader({
        voidYesNoHeader: valuesYesNoHeader.voidYesNoHeader
      });
      setDefaultCheck(retainEdit.defaultCheckVal);
      setShowError(false);
      setErrorMessages([]);
    }
  };

  /* This function is setting values */
  const setFieldValues = (
    agencyVal,
    fundcodeVal,
    rankVal,
    catVal,
    planIdVal,
    planIdTypeVal,
    idVal,
    typeVal,
    planningVal,
    texoVal,
    coeVal,
    levelVal,
    claimVal,
    ageBeginVal,
    ageEndVal,
    mapSetVal,
    proceRadioVal,
    procBeginVal,
    procEndVal,
    procValCode,
    mod1,
    mod2,
    mod3,
    mod4,
    voidYesNoVal
  ) => {
    setValues({
      agency: agencyVal || DEFAULT_DD_VALUE,
      fundCode: fundcodeVal || DEFAULT_DD_VALUE,
      rank: rankVal,
      catService: catVal || DEFAULT_DD_VALUE,
      benefitPlanId: planIdVal || DEFAULT_DD_VALUE,
      bProviderIdType: planIdTypeVal || DEFAULT_DD_VALUE,
      bProviderId: idVal || "",
      bProviderType: typeVal || DEFAULT_DD_VALUE,
      familyPlanning: planningVal,
      taxonomy: texoVal || "",
      coe: coeVal || DEFAULT_DD_VALUE,
      levelCare: levelVal || DEFAULT_DD_VALUE,
      claimType: claimVal || DEFAULT_DD_VALUE,
      memberBeginAge:
        ageBeginVal !== null && JSON.stringify(ageBeginVal) ? ageBeginVal : "",
      memberEndAge:
        ageEndVal !== null && JSON.stringify(ageEndVal) ? ageEndVal : "",
      mapsetId: mapSetVal || DEFAULT_DD_VALUE,
      procedureCode: proceRadioVal,
      procedureBeginCode: procBeginVal,
      procedureEndCode: procEndVal,
      procedureValueCode: procValCode,
      modifier1: mod1,
      modifier2: mod2,
      modifier3: mod3,
      modifier4: mod4,
      voidYesNo: voidYesNoVal
    });
  };

  useEffect(() => {
    if (
      fundCodeDetailsArray &&
      fundCodeDetailsArray[0] &&
      fundCodeDetailsArray[0].noteSetVO
    ) {
      const noteSetVO = fundCodeDetailsArray[0].noteSetVO;
      const notesTable = fundCodeDetailsArray[0].noteSetVO.notesList;
      setNotesInput({
        auditUserID: logInUserID,
        auditTimeStamp: noteSetVO.auditTimeStamp
          ? noteSetVO.auditTimeStamp
          : null,
        addedAuditUserID: noteSetVO.addedAuditUserID
          ? noteSetVO.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
          ? noteSetVO.addedAuditTimeStamp
          : getUTCTimeStamp(),
        versionNo: noteSetVO.versionNo,
        dbRecord: noteSetVO.dbRecord,
        sortColumn: noteSetVO.sortColumn,
        tableName: noteSetVO.tableName,
        noteSetSK: noteSetVO.noteSetSK,
        noteSourceName: noteSetVO.noteSourceName,
        notesList: notesTable,
        globalNotesList: [],
        checkAll: noteSetVO.checkAll,
        addNewLinkRender: noteSetVO.addNewLinkRender,
        filterLinkRender: noteSetVO.filterLinkRender,
        printLinkRender: noteSetVO.printLinkRender,
        completeNotesList: []
      });

      setNotesTableData(notesTable);
    }
  }, [fundCodeDetailsArray]);

  /* This function is used to add fund code by hitting create API
   *  If all required header fields entered and validations passes, hit the API
   */
  const saveFundCodeCriteria = values => {
    if (showEditScreen && !allowNavigation) {
      NoSaveMessage();
    } else {
      var showBlankRowError = false;

      if (
        values.agency !== DEFAULT_DD_VALUE &&
        values.fundCode !== DEFAULT_DD_VALUE &&
        values.rank &&
        selectedBeginDate &&
        selectedEndDate
      ) {
        const count = 0;
        const overlapCount = 0;
        showValidationErrors();
        setOpen(false);
        if (
          fundCodeTableData &&
          errorMessagesArray &&
          fundCodeTableData.length === 0 &&
          errorMessagesArray.length <= 0
        ) {
          errorMessagesArray = [];
          setErrorMessages([]);
          setSystemSuccesMessages([]);
          showBlankRowError = true;
          errorMessagesArray.push(fundCodeConstants.DETAIL_ROW_REQUIRED);
          setErrorMessages(errorMessagesArray);
          setShowError({ showBlankRowError: showBlankRowError });
        } else if (fundCodeTableData && fundCodeTableData.length > 1) {
          setOpen(false);
          var addVal = "save";
          var showFundCodeDuplicateError = showCountError(
            count,
            overlapCount,
            addVal
          );
        }
      } else {
        setOpen(false);
        showValidationErrors();
      }

      if (errorMessagesArray && errorMessagesArray.length === 0) {
        setOpen(false);
        errorMessagesArray = [];
        setErrorMessages([]);
        validationErrors.errorMessagesArray = [];

        requestTableData = [];

        fundCodeTableData.map((value, index) => {
          let dataElementToSend = {};
          dataElementToSend.auditUserID = value.auditUserID;
          dataElementToSend.auditTimeStamp = value.auditTimeStamp;
          dataElementToSend.addedAuditUserID = value.addedAuditUserID;
          dataElementToSend.addedAuditTimeStamp = value.addedAuditTimeStamp;
          if (showEditScreen) {
            dataElementToSend.versionNo =
              fundCodeDetailsArray[0].fundCodeDetailsVO.versionNo;
            dataElementToSend.dbRecord =
              fundCodeDetailsArray[0].fundCodeDetailsVO.dbRecord;
            dataElementToSend.sortColumn =
              fundCodeDetailsArray[0].fundCodeDetailsVO.sortColumn;
            dataElementToSend.auditKeyList =
              fundCodeDetailsArray[0].fundCodeDetailsVO.auditKeyList;
            dataElementToSend.auditKeyListFiltered =
              fundCodeDetailsArray[0].fundCodeDetailsVO.auditKeyListFiltered;
            dataElementToSend.eligibilityCode = null;
            dataElementToSend.subType = null;
            dataElementToSend.phsIndicator = null;
            dataElementToSend.ihsIndicator = null;
            dataElementToSend.raceCode = null;
            dataElementToSend.voids = value.voidYesNo;
            dataElementToSend.hasVoidFlag = null;
            dataElementToSend.fundCodeAssignCretiriaSK =
              fundCodeDetailsArray[0].fundCodeDetailsVO.fundCodeSK;
            dataElementToSend.billingProviderInKindIndicator = null;
            dataElementToSend.tempAgeFromNumber = "";
            dataElementToSend.tempAgeThruNumber = "";
            dataElementToSend.tempAgeFlag = false;
            dataElementToSend.voidDate = value.voidDate;
            dataElementToSend.meadOnlyIND = null;
            dataElementToSend.sequenceNumber = null;
            dataElementToSend.auditUserName = null;
            dataElementToSend.addedAuditUserName = null;
          }
          dataElementToSend.categoryOfServiceCode = value.cos;
          dataElementToSend.benefitPlanID = value.benefitPlanId;
          dataElementToSend.billingProviderAltTypeCode = value.bProviderIdType;
          dataElementToSend.billingProviderAltID = value.bProviderId;
          dataElementToSend.renderingProviderTypeCode = value.bProviderType
            ? value.bProviderType
            : null;
          dataElementToSend.billingProviderSystemID = value.bProviderType
            ? parseInt(value.bProviderType)
            : value.bProviderType;
          dataElementToSend.levelOfCare = value.level;
          dataElementToSend.ageFromNumber = parseInt(value.memberBAge);
          dataElementToSend.ageThruNumber = parseInt(value.memberEAge);
          dataElementToSend.coeCode = value.coe;
          dataElementToSend.taxonomyCode = value.taxo;
          dataElementToSend.claimTypeCode = value.claimType;
          dataElementToSend.serviceBeginDate = value.serviceBeginDate;
          dataElementToSend.serviceEndDate = value.serviceEndDate;
          dataElementToSend.familyPlanIndicator =
            value.familyPlan === "Y"
              ? "true"
              : value.familyPlan === "N"
              ? "false"
              : "";
          dataElementToSend.procedureCodeIndicator =
            value.procRadioVal === "procedureCodeRange"
              ? "R"
              : value.procRadioVal === "procedureCodeValue"
              ? "V"
              : value.procRadioVal === "procedureCodeNA"
              ? "N"
              : "";
          dataElementToSend.defaultIND = value.defaultCheckVal === true ? 1 : 0;
          dataElementToSend.mapsetId = value.mapsetId;
          dataElementToSend.procedureCode = value.procCodeValue;
          dataElementToSend.procedureFromCode = value.procBeginCode;
          dataElementToSend.procedureThruCode = value.procEndCode;
          dataElementToSend.procedureModifierCode1 = value.modi1;
          dataElementToSend.procedureModifierCode2 = value.modi2;
          dataElementToSend.procedureModifierCode3 = value.modi3;
          dataElementToSend.procedureModifierCode4 = value.modi4;
          let finalDataElementToSend = {};
          finalDataElementToSend = removeLeadingTrailingSpaces(
            dataElementToSend
          );

          requestTableData.push(finalDataElementToSend);
          finalDataElementToSend = {};
          dataElementToSend = {};
        });
        setAllowNavigation(false);
        if (showEditScreen) {
          validationErrors = validateHeaderFields(
            values,
            selectedBeginDate,
            selectedEndDate
          );
          if (
            validationErrors.errorMessagesArray &&
            validationErrors.errorMessagesArray.length > 0
          ) {
            errorMessagesArray = [];
            errorMessagesArray.push(validationErrors.errorMessagesArray);
            setErrorMessages(validationErrors.errorMessagesArray);
            setErrorMegBeginDate(validationErrors.beginDateErrorMsg);
            setErrorMegEndDate(validationErrors.endDateErrorMsg);
            setShowError({
              showAgencyError: validationErrors.setErrors.showAgencyError,
              showFundCodeError: validationErrors.setErrors.showFundCodeError,
              showRankError: validationErrors.setErrors.showRankError,
              showBeginDateError: validationErrors.setErrors.showBeginDateError,
              showEndDateError: validationErrors.setErrors.showEndDateError
            });
          }
          if (
            validationErrors.errorMessagesArray &&
            validationErrors.errorMessagesArray.length === 0
          ) {
            const updateValue = {
              voidNoteInd: disabledHeaderValue || false,
              renderFundCodeCriteriaSection: true,
              fundCodeDetailsVO: {
                auditUserID: logInUserID,
                auditTimeStamp: getUTCTimeStamp(),
                addedAuditUserID: fundCodeDetailsArray[0].fundCodeDetailsVO
                  .addedAuditUserID
                  ? fundCodeDetailsArray[0].fundCodeDetailsVO.addedAuditUserID
                  : logInUserID,
                addedAuditTimeStamp: fundCodeDetailsArray[0].fundCodeDetailsVO
                  .addedAuditTimeStamp
                  ? fundCodeDetailsArray[0].fundCodeDetailsVO
                      .addedAuditTimeStamp
                  : getUTCTimeStamp(),
                lobCode: AppConstants.DEFAULT_LOB_VALUE,
                fundCode: values.fundCode,
                activityCode: values.agency,
                voids: valuesYesNoHeader.voidYesNoHeader,
                beginDate: moment(selectedBeginDate).format("MM/DD/YYYY"),
                endDate: moment(selectedEndDate).format("MM/DD/YYYY"),
                rankNumber: parseInt(values.rank),
                fundCodeSK:
                  fundCodeDetailsArray[0].fundCodeDetailsVO.fundCodeSK,
                maxSeqNum: fundCodeDetailsArray[0].fundCodeDetailsVO.maxSeqNum,
                tempRankNumber: parseInt(values.rank)
              },
              fundCodeCriterias: requestTableData,
              noteSetVO: notesInput
            };

            updateValue.fundCodeDetailsVO.voidDate = fundCodeDetails[0]
              .fundCodeDetailsVO.voidDate
              ? fundCodeDetails[0].fundCodeDetailsVO.voidDate
              : updateValue.fundCodeDetailsVO.voidDate;
            onFundCodeUpdate(updateValue);
          }
        } else {
          const value = {
            renderFundCodeCriteriaSection: true,
            fundCodeDetailsVO: {
              auditUserID: logInUserID,
              auditTimeStamp: getUTCTimeStamp(),
              addedAuditUserID: logInUserID,
              addedAuditTimeStamp: getUTCTimeStamp(),
              lobCode: AppConstants.DEFAULT_LOB_VALUE,
              fundCode: values.fundCode,
              activityCode: values.agency,
              beginDate: moment(selectedBeginDate).format("MM/DD/YYYY"),
              endDate: moment(selectedEndDate).format("MM/DD/YYYY"),
              rankNumber: parseInt(values.rank),
              tempRankNumber: parseInt(values.rank)
            },
            fundCodeCriterias: requestTableData,
            noteSetVO: notesInput
          };
          onFundCodeAdd(value);
        }
      }
    }
  };

  /* This function is used to handle show void checkbox */
  const handleShowVoid = () => {
    setFocusCheck(true);
    setShowVoid(!showVoid);
  };

  // add notes
  let notesDataArray = [];

  const addNotes = data => {
    const noteText = data;
    notesDataArray = notesTableData;
	setAllowNavigation(true);
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };
  const ValuesFromFuncdCode = props.history && props.history.location && props.history.location.state && props.history.location.state.values;

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(() => {
      setDialogOpen(false);
      setFocusCheck(false);
      if (window.location.pathname === '/FundCodeAdd') {
          setDialogOpen(false);
          setFocusCheck(false);
          props.history.push({
            pathname: '/FundCodeSearch',
            tableRender: false
          });
      } else if (window.location.pathname === '/FundCodeEdit') {
        props.history.push({
          pathname: '/FundCodeSearch',
          tableRender: !props.history.location?.state?.isFromAdd,
          state: { values: ValuesFromFuncdCode }
        });
      }
    }, 100);
    };

  const majorCancel = () => {
    setErrorMessages([]);
    setAllowNavigation(false);
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      if (window.location.pathname === '/FundCodeEdit') {
         props.history.push({
        pathname: '/FundCodeSearch',
        tableRender: !props.history.location?.state?.isFromAdd,
        state: { values: ValuesFromFuncdCode }
      });
} else if (window.location.pathname === '/FundCodeAdd') {
        props.history.push({
          pathname: '/FundCodeSearch',
          tableRender: false
        });
      }
    }
  };

  const agncyCode = agencyList.find(value => value.code === values.agency);
  const fundCodeVal = fundCodeList.find(value => value.code === values.fundCode);

  return (
    <div className="pos-relative w-100 h-100">
      <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />

      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        {errorMessages && errorMessages.length === 0 ? (
          <SuccessMessages successMessages={systemSuccessMessages} />
        ) : null}
        <div className="tab-header">
          {showEditScreen ? (
            <h1 className="tab-heading float-left">
              {fundCodeConstants.FUND_CODE_EDIT_TEXT}
            </h1>
          ) : null}
          {!showEditScreen ? (
            <h1 className="tab-heading float-left">
              {fundCodeConstants.FUND_CODE_ADD_TEXT}
            </h1>
          ) : null}
          <div className="float-right mt-2">
            <Button
              className="btn btn-success ml-1"
              disabled={!userInquiryPrivileges ? !userInquiryPrivileges : false}
              onClick={() => saveFundCodeCriteria(values)}
            >
              <i className="fa fa-check" aria-hidden="true"></i>
              {AppConstants.SAVE}
            </Button>
            {showEditScreen ? (
              <Button
                className={
                  auditButtonToggle
                    ? "btn btn-primary ml-1"
                    : "btn btn-text1 btn-transparent ml-1"
                }
                onClick={() => {
                  setAuditButtonToggle(!auditButtonToggle);
                  setShowLogTable(true);
                }}
              >
                <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
                Audit Log
              </Button>
            ) : null}
              <Button title="Cancel" onClick={majorCancel}>{AppConstants.CANCEL}</Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form>
            <div className="form-wrapper">
              <NativeDropDown
                id="agency"
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : disabledHeaderValue
                  }
                  label="Agency"
                  inputProps={{ maxlength: 3 }}
                value={agncyCode !== undefined ? values.agency : DEFAULT_DD_VALUE}
                  onChange={handleChange("agency")}
                isRequired
                errTxt={
                    showAgencyError ? fundCodeConstants.AGENCY_REQUIRED : null
                  }
                dropdownList={agencyList.map((item, index) => (
                  <option key={index} value={item.code}>
                      {item.description}
                  </option>
                  ))}
              />
              <NativeDropDown
                id="fundCode"
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : disabledHeaderValue
                  }
                  label="Fund Code"
                  inputProps={{ maxlength: 5 }}
                value={fundCodeVal !== undefined ? values.fundCode : DEFAULT_DD_VALUE}
                  onChange={handleChange("fundCode")}
                isRequired
                errTxt={
                    showFundCodeError
                      ? fundCodeConstants.FUND_CODE_REQUIRED
                      : null
                  }
                  title={getLongDescription(fundCodeList, values.fundCode)}
                dropdownList={fundCodeList.map((item, index) => (
                  <option key={index} value={item.code}>
                      {item.description}
                  </option>
                  ))}
              />

              <div className="mui-custom-form input-md">
                <TextField
                  id="rank"
                  fullWidth
                  InputProps={{
                    readOnly: !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : disabledHeaderValue,
                    className: !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : disabledHeaderValue
                      ? "Mui-disabled"
                      : ""
                  }}
                  label="Rank"
                  required
                  value={values.rank}
                  onChange={handleChange("rank")}
                  placeholder=""
                  inputProps={{ maxLength: 6 }}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      AppConstants.NUMBER_ONLY_REGEX,
                      ""
                    );
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={
                    showRankError ? fundCodeConstants.RANK_REQUIRED : null
                  }
                  error={showRankError ? fundCodeConstants.RANK_REQUIRED : null}
                />
              </div>
            </div>
            <div className="form-wrapper">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="mui-custom-form with-date">
                  <KeyboardDatePicker
                    maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                    id="beginDate"
                    fullWidth
                    disabled={
                      !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : disabledHeaderValue
                    }
                    label="Begin Date"
                    format="MM/dd/yyyy"
                    value={selectedBeginDate}
                    placeholder="mm/dd/yyyy"
                    onChange={handleBeginDateChange}
                    required
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    helperText={showBeginDateError ? errorMegBeginDate : null}
                    error={showBeginDateError ? errorMegBeginDate : null}
                  />
                </div>
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="mui-custom-form with-date">
                  <KeyboardDatePicker
                    maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                    id="endDate"
                    fullWidth
                    disabled={
                      !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : disabledHeaderValue
                    }
                    label="End Date"
                    format="MM/dd/yyyy"
                    value={selectedEndDate}
                    placeholder="mm/dd/yyyy"
                    onChange={handleEndDateChange}
                    required
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    helperText={showEndDateError ? errorMegEndDate : null}
                    error={showEndDateError ? errorMegEndDate : null}
                  />
                </div>
              </MuiPickersUtilsProvider>
              {showEditScreen ? (
                <div className="mui-custom-form">
                  <label className="MuiFormLabel-root small-label no-margin">
                    Void
                  </label>
                  <div className="sub-radio">
                    <Radio
                      id="voidYesHeaderId"
                      fullWidth
                      disabled={
                        !userInquiryPrivileges
                          ? !userInquiryPrivileges
                          : disabledHeaderValue
                      }
                      name="voidYesNoHeader"
                      value="Y"
                      checked={valuesYesNoHeader.voidYesNoHeader === "Y"}
                      onChange={handleChangeYesNo("voidYesNoHeader")}
                    />
                    <label htmlFor="voidYesHeaderId" className="text-black">
                      Yes
                    </label>
                    <Radio
                      id="voidNoHeaderId"
                      disabled={
                        !userInquiryPrivileges
                          ? !userInquiryPrivileges
                          : disabledHeaderValue
                      }
                      name="voidYesNoHeader"
                      value="N"
                      checked={valuesYesNoHeader.voidYesNoHeader === "N"}
                      onChange={handleChangeYesNo("voidYesNoHeader")}
                      className="ml-2"
                    />
                    <label htmlFor="voidNoHeaderId" className="text-black">
                      No
                    </label>
                  </div>
                </div>
              ) : null}
            </div>

            {showEditScreen ? (
              <div className="mui-custom-form dib show-voids mt-1">
                <div className="sub-radio no-margin">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={showVoid}
                        value={showVoid}
                        onChange={handleShowVoid}
                      />
                    }
                    label="Show Voids"
                  />
                </div>
              </div>
            ) : null}
          </form>
          <div className="tab-panelbody m-0">
            <div className="tab-holder my-3">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"

                >
                  <Tab label="Fund Code Assignment Criteria"
					data-testid='fund-code-Ass-criteria'
				  />
                  {showEditScreen ? <Tab label={AppConstants.HISTORY}
				  data-testid='fund-code-history' /> : null}
                  <Tab label={AppConstants.NOTES}
				  data-testid='fund-code-Notes' />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <>
                  {
                    <div className="tab-holder">
                      {value === 0 && (
                        <div className="float-right mt-3">
                          <Button
                            variant="outlined"
                            color="primary"
                            className="btn-text-main-delete btn-transparent  ml-1"
                            onClick={AssignmentRowDeleteClick}
                            disabled={
                              !userInquiryPrivileges
                                ? !userInquiryPrivileges
                                : disabledHeaderValue
                            }
                            title={"Delete"}
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                            Delete
                          </Button>

                          <Button
                            variant="outlined"
                            color="primary"
                            className="btn-text-main-add btn-success ml-1"
                            disabled={
                              !userInquiryPrivileges
                                ? !userInquiryPrivileges
                                : disabledHeaderValue
                            }
                            title={"Add Assignment Criteria"}
                            onClick={handleClickOpenAddAssignment}
							data-testid='Add-Assignment-Criteria'
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                            Add
                          </Button>
                        </div>
                      )}
                      <div className="clearfix"></div>

                      {showEditScreen ? (
                        <div>
                          <div className="tab-holder my-3 p-0 tableNoWrap">
                            {value === 0 && (
                              <TableComponent
                                headCells={fundCodeConstants.headCellsEdit}
                                fixedTable
                                tableData={
                                  showVoid
                                    ? fundCodeTableData || []
                                    : fundCodeNonVoidedData || []
                                  }
                                onTableRowClick={editRow}
                                onTableRowDelete={rowDeleteFundCode}
                                isSearch={false}
                                defaultSortColumn={"cos"}
                                // Audit Log Changes
                                onTableCellButtonClick={onClickAuditDialogLog}
                                uniqueKey="sequenceNumber"
                                setClose={setClose}
                                close={close}
                              />
                            )}
                          </div>

                        </div>
                      ) : (
                        <div>
                          <div className="tab-holder my-3 p-0 tableNoWrap">
                            {value === 0 && (
                              <TableComponent
                                headCells={fundCodeConstants.headCellsAdd}
                                fixedTable
                                tableData={fundCodeTableData || []}
                                onTableRowClick={editRow}
                                onTableRowDelete={rowDeleteFundCode}
                                isSearch={false}
                                defaultSortColumn={"cos"}
                              />
                            )}
                          </div>
                        </div>
                      )}
                      {open ? (
                        <div className="tabs-container" id="form_pop_up">
                          <div className="tab-header  mini-tab-header">
                            <div className="tab-heading float-left">
                              {add
                                ? "New Assignment Criteria"
                                : "Edit Assignment Criteria"}
                            </div>
                            <div className="float-right mt-1">
                              <Button
                                variant="outlined"
                                color="primary"
                                className="btn btn-success ml-1"
                                onClick={saveAssignment}
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : disabled
                                }
                                title={add ? 'Add' : 'Update'}
                              >
                             <i className="fa fa-check" aria-hidden="true"></i>
                                {add ? "Add" : "Update"}
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                className="bt-reset btn-transparent  ml-1"
                                onClick={resetAssignment}
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : disabled
                                }
                                title={'Reset'}
                              >
                                <i
                                  className="fa fa-undo"
                                  aria-hidden="true"
                                ></i>
                                Reset
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                className="btn btn-primary btn btn-outlined ml-1"
                                onClick={handleClose}
                                title = {'Cancel'}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                          <AddAssignmentCriteria
                            values={values}
                            add={add}
                            disabled={disabled}
                            showVoidYesNo={showVoidYesNo}
                            selectedServiceBeginDate={selectedServiceBeginDate}
                            handleServiceBeginDate={handleServiceBeginDate}
                            selectedServiceEndDate={selectedServiceEndDate}
                            handleServiceEndDate={handleServiceEndDate}
                            handleChange={handleChange}
                            saveAssignment={saveAssignment}
                            resetAssignment={resetAssignment}
                            procedureCodeVal={procedureCodeVal}
                            procedureCodeRange={procedureCodeRange}
                            procedureCodeValue={procedureCodeValue}
                            procedureCodeNA={procedureCodeNA}
                            handleChangeDefault={handleChangeDefault}
                            defaultCheck={defaultCheck}
                            cosList={cosList}
                            planIdList={planIdList}
                            prvIdTypeList={prvIdTypeList}
                            prvTypeList={prvTypeList}
                            coeList={coeList}
                            levelOfCareList={levelOfCareList}
                            claimList={claimList}
                            mapIdList={mapIdList}
                            errors={[
                              showProcedureRangeCodeError,
                              showMemberAgeError,
                              showProcedureCodeValueError,
                              showMapSetIdError,
                              showServiceBeginDateError,
                              showServiceEndDateError,
                              showProviderIdError,
                              showProviderIdTypeError,
                              showProviderTypeError,
                              showPopUpError
                            ]}
                            errorText={[
                              showProcedureRangeCodeErrorText,
                              showMemberAgeErrorText,
                              showProcedureCodeValueErrorText,
                              showMapSetIdErrorText,
                              showServiceBeginDateErrorText,
                              showServiceEndDateErrorText,
                              showProviderIdErrorText,
                              showProviderIdTypeErrorText,
                              showProviderTypeErrorText,
                              showPopUpErrorText
                            ]}
                          ></AddAssignmentCriteria>
                          {showLogDialogTable ? (
                            <div id="audit-dialog-table">
                              <AuditTableComponent
                                print
                                auditRowData={auditTableData}
                                setAuditRowData={setAuditTableData}
                                setShowLogTable={setShowLogDialogTable}
                                setClose={setClose}
                              />{" "}
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  }
                </>
              </TabPanel>
              {showEditScreen ? (
                <TabPanel value={value} index={1}>
                  <div className="tab-holder p-0">
                    {value === 1 && (
                      <FundCodeHistory
                        tabData={historyDetail}
                        values={values}
                        cosList={cosList}
                        prvIdTypeList={prvIdTypeList}
                        prvTypeList={prvTypeList}
                        dropdowns={addDropdowns}
                      ></FundCodeHistory>
                    )}
                  </div>
                </TabPanel>
              ) : null}
              {!showEditScreen ? (
                <TabPanel value={value} index={1}>
                  {value === 1 && (
                    <div className="tab-holder p-0">
                      <Notes
                        disableNotes={disableNotes}
                        addNotes={addNotes}
                        notesTableData={notesTableData}
                        noteSetListInput={noteSetListInput}
                        setNoteSetListInput={setNoteSetListInput}
                        usageTypeCodeData={usageTypeCodeData}
                        editNoteData={editNoteData}
                        setEditNoteData={setEditNoteData}
                        setSuccessMessages={clearSuccessErrorMsgs}
                        isNotesTable={true}
                        setErrorMessages={setErrorMessages}
                        userInquiryPrivileges={userInquiryPrivileges}
                        setFocusCheck={setFocusCheck}
                      />
                    </div>
                  )}
                </TabPanel>
              ) : (
                <TabPanel value={value} index={2}>
                  {value === 2 && (
                    <div className="tab-holder p-0">
                      <Notes
                        disableNotes={disableNotes}
                        addNotes={addNotes}
                        setFocusCheck={setFocusCheck}
                        notesTableData={notesTableData}
                        noteSetListInput={noteSetListInput}
                        setNoteSetListInput={setNoteSetListInput}
                        usageTypeCodeData={usageTypeCodeData}
                        editNoteData={editNoteData}
                        setEditNoteData={setEditNoteData}
                        setSuccessMessages={clearSuccessErrorMsgs}
                        isNotesTable={true}
                        setErrorMessages = {setErrorMessages}
                        userInquiryPrivileges = {userInquiryPrivileges}

                      />
                    </div>
                  )}
                </TabPanel>
              )}
            </div>
          </div>
        </div>
        {showLogTable ? (
          <div id="audit-table">
            <AuditTableComponent
              print
              auditRowData={auditRowData}
              setAuditRowData={setAuditRowData}
              setShowLogTable={setShowLogTable}
            />{" "}
          </div>
        ) : null}
        <Footer print />
        <Dialog
              open={dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="custom-alert-box"
      >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
                  </>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
              setDialogOpen(false);
            }}
			                 color="primary"
                  className="btn btn-transparent"
          >
                  STAY ON THIS PAGE!
                </Button>
                <Button
                  onClick={cancelMinorSection}
                  color="primary"
                  className="btn btn-success"
                  autoFocus
          >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
                </Button>
              </DialogActions>
            </Dialog>
      </div>
    </div>
  );
}
