/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import ErrorMessages from '../../../SharedModules/Errors/ErrorMessages';
import TableComponent from '../../../SharedModules/Table/Table';
import Footer from '../../../SharedModules/Layout/footer';
import { searchFRCAction, getSubDropdownDataAction, ResetFRC } from '../Store/Actions';
import * as FRCConstants from './FRCConstants';
import dropDownCriteria from '../ReasonCode.json';

function FRCSearch (props) {
    const dispatch = useDispatch();
    const toPrintRef = useRef();

    const [showTable, setShowTable] = React.useState(false);
    const [errorMessages, seterrorMessages] = React.useState([]);
    const [FRCState, setFRCState] = useState({
        financeReasonCd: 'Please Select One'
    });
    const [frcTable, setFRCTable] = useState([]);
    const searchFRC = (values) => dispatch(searchFRCAction(values));
    const getDropdownData = (dropdownValues) => dispatch(getSubDropdownDataAction(dropdownValues));

    let searchSuccess = useSelector(state => state.frcState.searchPayload);
    const dropDownData = useSelector(state => state.frcState.subdropdownData);
    const [ReasonCodeData, setReasonCodeData] = useState([]);

    useEffect(() => {
        if (dropDownData) {
            if (dropDownData.listObj && dropDownData.listObj['Financial#F_RSN_CD']) {
                const reasonCodeList = JSON.parse(JSON.stringify(dropDownData.listObj['Financial#F_RSN_CD'].sort((a, b) => a.code.toLocaleUpperCase() < b.code.toLocaleUpperCase() ? -1 : 1)));
                setReasonCodeData(reasonCodeList);
            }
        }
    }, [dropDownData]);

    useEffect(() => {
        ResetFRC('searchPayload');
        searchSuccess = null;
        getDropdownData(dropDownCriteria);
    }, []);
    const mapCodeDescription = (_code, _mapArray) => {
        if (_mapArray && _mapArray.length > 0) {
            let _description = [];
            _description = _mapArray.filter((value, index) => value.code === _code);
            if (_description.length > 0) {
                return _description[0].description;
            } else {
                return _code;
            };
        };
        return _code;
    };

    useEffect(() => {
        if (searchSuccess && !searchSuccess.systemFailure) {
            seterrorMessages([]);
        }
        if (searchSuccess && !searchSuccess.systemFailure && searchSuccess.length > 1) {
            setShowTable(true);
            const tempSearchData = [];
            searchSuccess.map((row, index) => {
                const outputData = searchSuccess;
                tempSearchData.push({
                    ...outputData[index],
                    frcDesc: mapCodeDescription(
                        row.financeReasonCd,
                        ReasonCodeData
                    )
                });
            });

            setFRCTable(tempSearchData);
        } else if (searchSuccess && !searchSuccess.systemFailure && searchSuccess.length === 1) {
            const searchData = searchSuccess;
            props.history.push({
                pathname: '/FRCUpdate',
                state: { searchData: searchData, successMessages: [], editPage: true, fromSearch: true }
            });
        } else if (searchSuccess && searchSuccess.systemFailure) {
            const ErrorMessages = [];
            ErrorMessages.push(FRCConstants.GENERIC_SYSTEM_ERROR);
            seterrorMessages(ErrorMessages);
        } else if (searchSuccess && !searchSuccess.systemFailure && searchSuccess.length === 0) {
            const ErrorMessages = [];
            ErrorMessages.push(FRCConstants.NO_RECORDS_FOUND);
            seterrorMessages(ErrorMessages);
            setFRCTable([]);
            setShowTable(false);
        }
    }, [searchSuccess]);
    const handleChange = name => event => {
        setFRCState({
            ...FRCState,
            [name]: event.target.value
        });
    };
    const FRCSearchAction = () => {
        seterrorMessages([]);
        const searchFRCOb = {
            financeReasonCd: FRCState.financeReasonCd !== 'Please Select One' ? FRCState.financeReasonCd : null
        };
        searchFRC(searchFRCOb);
    };
    const FRCReset = () => {
        setFRCState({
            financeReasonCd: 'Please Select One'
        });
        setFRCTable([]);
        setShowTable(false);
        seterrorMessages([]);
    };

    const headCells = [
        {
            id: 'cms64SumLine',
            numeric: false,
            disablePadding: false,
            enableHyperLink: true,
            label: 'CMS 64',
            isDate: false,
            width: '7%'
        },
        {
            id: 'financeReasonCd',
            numeric: false,
            disablePadding: false,
            enableHyperLink: false,
            label: 'Finance Reason Code',
            width: '10%'
        },
        {
            id: 'financeReasonCdDescr',
            numeric: false,
            disablePadding: false,
            enableHyperLink: false,
            label: 'FRC Description',
            isDate: false,
            width: '20%'
        },
        {
            id: 'tplCollectionRsnCd',
            numeric: false,
            disablePadding: false,
            enableHyperLink: false,
            label: 'TPL Collection Reason Code',
            isDate: false,
            width: '13%'
        },
        {
            id: 'financeReasonCdTy',
            numeric: false,
            disablePadding: false,
            enableHyperLink: false,
            label: 'Finance Reason Code Type',
            isDate: false,
            width: '20%'
        },
        {
            id: 'marExclInd',
            numeric: false,
            disablePadding: false,
            enableHyperLink: false,
            label: 'MAR Excl Ind',
            isDate: false,
            width: '10%'
        },
        {
            id: 'claimInclInd',
            numeric: false,
            disablePadding: false,
            enableHyperLink: false,
            label: 'Claim Inc Ind',
            isDate: false,
            width: '10%'
        },
        {
            id: 'historyInd',
            numeric: false,
            disablePadding: false,
            enableHyperLink: false,
            label: 'History Ind',
            isDate: false,
            width: '10%'
        }
    ];
    const [showError, setShowError] = useState({
        financeReasonCd: false
    });
    const [showErrorMessage, setShowErrorMsg] = useState({
        financeReasonCd: false
    });
    const formatSearchCriteria = (row) => {
        return ({});
    };
    const editRow = row => event => {
        const searchFRCOb = {
            financeRsnCodeSeq: row.financeRsnCodeSeq
        };
        searchFRC(searchFRCOb);
    };

    return (
        <div className="pos-relative w-100 h-100">

            <div className="tabs-container" ref={toPrintRef}>
                <ErrorMessages errorMessages={errorMessages} />
                <div className="tab-header">
                    <h1 className="tab-heading float-left">Search FRC</h1>
                    <div className="clearfix"></div>
                </div>
                <div className="tab-body" style={{ overflow: 'hidden' }}>
                    <form autoComplete="off">
                        <div className="form-wrapper">
                            <div className="mui-custom-form with-select input-md">
                                <TextField
                                    select
                                    fullWidth
                                    id="standard-AccountActivityType"
                                    label="Finance Reason Code"
                                    value={FRCState.financeReasonCd}
                                    onChange={handleChange('financeReasonCd')}
                                    placeholder=""
                                    inputProps={{ maxlength: 3 }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    helperText={
                                        showError.financeReasonCd ? showErrorMessage.financeReasonCd : null
                                    }
                                    error={
                                        showError.financeReasonCd ? showErrorMessage.financeReasonCd : null
                                    }
                                >
                                    <MenuItem
                                        selected
                                        key="Please Select One"
                                        value="Please Select One"
                                    >
                                        Please Select One
                                    </MenuItem>

                                    {ReasonCodeData ? ReasonCodeData.map(option => (
                                        <MenuItem key={option.code} value={option.code}>
                                            {option.description}
                                        </MenuItem>
                                    )) : null}
                                </TextField>
                            </div>

                        </div>
                    </form>

                    <div className="float-right mr-4 mb-3">
                        <Button
                            variant="outlined"
                            color="primary"
                            className="btn btn-primary ml-1"
                            onClick={() => {
                                FRCSearchAction();
                            }}
                        >
                            <i className="fa fa-search" aria-hidden="true"></i>
                            Search
            </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            className="bt-reset btn-transparent ml-1"
                            onClick={() => {
                                FRCReset();
                            }}
                        >
                            <i className="fa fa-undo" aria-hidden="true"></i> Reset
            </Button>
                    </div>

                    <div className="clearfix"></div>

                    {showTable ? (
                        <div className="tab-holder pb-1 tableNoWrap">

                            <TableComponent
                                print
                                fixedTable
                                headCells={headCells}
                                formatSearchCriteria={formatSearchCriteria}
                                tableData={frcTable}
                                isSearch={true}
                                onTableRowClick={editRow}
                                defaultSortColumn={headCells[1].id}
                                order={'asc'}
                            />
                        </div>
                    ) : null}
                    <Footer print />
                </div>
            </div>
        </div>
    );
}

export default withRouter(FRCSearch);
