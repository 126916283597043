/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const PAYEE_TYPE_REQUIRED = 'Payee Type is required.';
export const PAYEE_TYPE_ID_CODE_REQUIRED = 'Payee ID Type Code is required.';
export const PAYEE_ID_REQUIRED = 'Payee ID is required.';
export const ACTIVITY_TYPE_REQUIRED = 'Activity Type is required.';
export const REASON_CODE_REQUIRED = 'Reason Code is required.';
export const FUND_CODE_REQUIRED = 'Fund Code is required.';
export const AMOUNT_REQUIRED = 'Amount is required.';
export const APPROVAL_ERROR = 'Approval must be No for Gross level, withhold Lien/Levy.';
export const FISCAL_YEAR_REQUIRED = 'Fiscal Year is required.';
export const DATA_FORMAT_ERROR = 'Please enter the data in correct format.';
export const WRONG_FISCAL_YEAR = 'Fiscal year cannot be less than the current fiscal year. Please re-enter.'
export const COLLOCATION_CODE = 'A Fund Code is required when the Fiscal Year is entered.';
export const CHECK_NUMBER_REQUIRED = 'Check Number is required.';
export const CHECK_DETAILS_REQUIRED = 'Manual Payout Reason Codes require Check Number and Check Date.';
export const CHECK_DATE_INVALID_ERROR = 'A valid Check Date must be entered for Manual checks.';
export const MEMBERID_REASONCODE_REQUIRED = 'For a HIPP Payment Reason Code, a Member ID is required. Please Re-Enter.';
export const BEGIN_END_DATE_COMPARE = 'End Date must be greater than or equal to the Begin Date.';
export const SETTLEMENT_DATES_REQUIRED = 'For the selected Reason Code, Begin and End dates are required.';
export const MEMBERID_REQUIRED = 'Member ID is required when Member ID Type is selected from the select box.';
export const MEMBERIDTYPECODE_REQUIRED = 'Member ID Type is required when Member ID is entered.';
export const MEMBER_ID_TYPE_REQUIRED = 'Member ID Type is required.';
export const SYSTEM_SUCCESS_MESSAGE = 'System successfully saved the Information.';
export const AMOUNT_GREATER_THAN_ZERO = 'Payout amount must be greater than zero.';
export const SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const SETTLEMENT_BEGINDATE_INVALID = 'Begin Date that was entered is invalid.';
export const SETTLEMENT_ENDDATE_INVALID = 'End Date that was entered is invalid.';
export const MANUAL_DATE_INVALID = 'A valid Check Date must be entered for Manual checks.';
export const TRANSACTION_NUMBER_FORMAT = 'cannot contain spaces or special characters.';
export const CHECK_DATE_GREATER_EST_DATE = 'Check Date cannot be greater than the Established Date.';
