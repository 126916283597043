/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import ErrorMessages from "../../../SharedModules/Errors/ErrorMessages";
import SuccessMessages from '../../../SharedModules/Errors/SuccessMessage';
import { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getDropdownDataAction, createEMARAction, updateEMARAction, searchEMARAction, ResetEMAR } from '../Store/Actions/EmarActions';
import dropDownCriteria from '../EMARDropdown.json';
import * as EMARAddConstants from './EMARConstants';
import { getDateInMMDDYYYYFormat, getUTCTimeStamp } from '../../../SharedModules/DateUtilities/DateUtilities';

function EMARAdd (props) {
  const dispatch = useDispatch();
  const toPrintRef = useRef();

  const [errorMessages, seterrorMessages] = useState([]);
  const [EMARState, setEMARState] = useState({
    tosLine: "",
    tosDescription: "",
    priorityNumber: "",
    beginFundCode: "Please Select One",
    endFundCode: "Please Select One",
    beginBenifitPlanId: "Please Select One",
    endBenifitPlanId: "Please Select One",
    beginAdmitTypeCode: "Please Select One",
    endAdmitTypeCode: "Please Select One",
    beginStateCos: "Please Select One",
    endStateCos: "Please Select One",
    beginProviderType: "Please Select One",
    endProviderType: 'Please Select One',
    beginProcedureCode: '',
    endProcedureCode: '',
    beginProviderSpeciality: 'Please Select One',
    endProviderSpeciality: 'Please Select One',
    beginFiancialReasonCode: 'Please Select One',
    endFinancialReasonCode: 'Please Select One',
    beginBillingProvider: '',
    endBillingProvider: '',
    comments: '',
    sterilIndicator: false,
    abortionIndicator: false
  });
  const [fundCodeData, setFundCodeData] = useState([]);
  const [cosCodeData, setCosCodeData] = useState([]);
  const [providetypeCodeData, setProviderTypeCodeData] = useState([]);
  const [ReasonCodeData, setReasonCodeData] = useState([]);
  const [benfitPlanData, setBenfitPlanData] = useState([]);
  const [admitTypeCodeData, setAdmitTypeCodeData] = useState([]);
  const [providerSpecData, setProviderSpecData] = useState([]);
  const [procedureData, setProcedureData] = useState([]); // procedure code dqata

  const [searchObj, setSearchObj] = useState({});
  const [showError, setShowError] = useState({
      tosLineError: false,
      priorityNumberError: false,
      tosDescriptionError: false
  });
  const [showErrorMessage, setShowErrorMessage] = useState({
    tosLineErrorMsg: '',
    priorityNumberErrorMsg: '',
    tosDescriptionErrorMsg: ''
  });
  const [successMessages, setSuccessMessages] = useState([]);
  const [updateSuc, setUpdateSuc] = useState({});
  const getDropdownData = (dropdownValues) => dispatch(getDropdownDataAction(dropdownValues));
  const dropDownData = useSelector(state => state.emarState.dropdownData);
  const [editPage, setEditPage] = useState(false);
  const createEmar = (values) => dispatch(createEMARAction(values));
  const updateEmar = (values) => dispatch(updateEMARAction(values));
  let createSuccess = useSelector(state => state.emarState.createPayload);
  let updateSuccess = useSelector(state => state.emarState.updatePayload);
  const searchEmar = (values) => dispatch(searchEMARAction(values));
  let searchSuccess = {};
  searchSuccess = useSelector(state => state.emarState.searchPayload);
  const [selectedBeginDate, setSelectedBeginDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const handleBeginDate = date => {
      setSelectedBeginDate(date);
  };
  const handleEndDate = date => {
    setSelectedEndDate(date);
};
  useEffect(() => {
   if (searchSuccess && !searchSuccess.systemFailure && searchSuccess.length === 1) {
        const searchData = searchSuccess;
        props.history.push({
            pathname: '/EMARUpdate',
            state: { searchData: searchData, successMessages: successMessages, editPage: true }
          });
    } else if (searchSuccess && searchSuccess.systemFailure) {
        const ErrorMessages = [];
        ErrorMessages.push(EMARAddConstants.GENERIC_SYSTEM_ERROR);
        seterrorMessages(ErrorMessages);
    }
}, [searchSuccess]);
  const successMessagesArray = [];
  useEffect(() => {
      if (createSuccess && createSuccess.respcode === '01') {
        successMessagesArray.push(createSuccess.respdesc);
        setSuccessMessages(successMessagesArray);
        const searchEmarOb = {

            priority_Number: EMARState.priorityNumber,
            cms64TosLine: EMARState.tosLine,
            cms64TosDescription: EMARState.tosDescription
        };
        searchEmar(searchEmarOb);
      } else if (createSuccess && createSuccess.systemFailure) {
        const ErrorMessages = [];
        ErrorMessages.push(EMARAddConstants.GENERIC_SYSTEM_ERROR);
        seterrorMessages(ErrorMessages);
    }
  }, [createSuccess]);
  useEffect(() => {
    if (updateSuccess && updateSuccess.respcode === '01') {
      setUpdateSuc(updateSuccess);
      successMessagesArray.push(updateSuccess.respdesc);
      setSuccessMessages(successMessagesArray);
      const searchEmarOb = {
        cms64TosCdSortKey: "",
        priorityNumber: EMARState.priorityNumber,
        cms64TosLine: EMARState.tosLine,
        cms64TosDescription: EMARState.tosDescription
    };
    searchEmar(searchEmarOb);
    } else if (updateSuccess && updateSuccess.systemFailure) {
        const ErrorMessages = [];
        ErrorMessages.push(EMARAddConstants.GENERIC_SYSTEM_ERROR);
        seterrorMessages(ErrorMessages);
    }
}, [updateSuccess]);
  let searchObject = {};
  useEffect(() => {
      if (props.location.state && props.location.state.searchData) {
        setEditPage(true);
          searchObject = props.location.state.searchData[0];
          setSearchObj(searchObject);
          setEMARState({
            tosLine: searchObject.cms64TosLine,
            tosDescription: searchObject.cms64TosDescription,
            priorityNumber: searchObject.priority_Number,
            beginFundCode: !(searchObject.begFundSrcCd && searchObject.begFundSrcCd.trim()) ? "Please Select One" : searchObject.begFundSrcCd,
            endFundCode: !(searchObject.endFundSrcCd && searchObject.endFundSrcCd.trim()) ? "Please Select One" : searchObject.endFundSrcCd,
            beginBenifitPlanId: !(searchObject.begMbrBpId && searchObject.begMbrBpId.trim()) ? "Please Select One" : searchObject.begMbrBpId,
            endBenifitPlanId: !(searchObject.endMbrBpId && searchObject.endMbrBpId.trim()) ? "Please Select One" : searchObject.endMbrBpId,
            beginAdmitTypeCode: !(searchObject.begAdmitTyCd && searchObject.begAdmitTyCd.trim()) ? "Please Select One" : searchObject.begAdmitTyCd,
            endAdmitTypeCode: !(searchObject.endAdmitTyCd && searchObject.endAdmitTyCd.trim()) ? "Please Select One" : searchObject.endAdmitTyCd,
            beginStateCos: !(searchObject.beginStateCOS && searchObject.beginStateCOS.trim()) ? "Please Select One" : searchObject.beginStateCOS,
            endStateCos: !(searchObj.endStateCOS && searchObj.endStateCOS.trim()) ? "Please Select One" : searchObj.endStateCOS,
            beginProviderType: !(searchObject.beginProviderType && searchObject.beginProviderType.trim()) ? "Please Select One" : searchObject.beginProviderType,
            endProviderType: !(searchObject.endProviderType && searchObject.endProviderType.trim()) ? 'Please Select One' : searchObject.endProviderType,
            beginProcedureCode: searchObject.beginProcedureType,
            endProcedureCode: searchObject.endProcedureType,
            beginProviderSpeciality: !(searchObject.begProvSplCd && searchObject.begProvSplCd.trim()) ? 'Please Select One' : searchObject.begProvSplCd,
            endProviderSpeciality: !(searchObject.endProvSplCd && searchObject.endProvSplCd.trim()) ? 'Please Select One' : searchObject.endProvSplCd,
            beginFiancialReasonCode: !(searchObject.begFinRsncd && searchObject.begFinRsncd.trim()) ? 'Please Select One' : searchObject.begFinRsncd,
            endFinancialReasonCode: !(searchObject.endFinRsncd && searchObject.endFinRsncd.trim()) ? 'Please Select One' : searchObject.endFinRsncd,
            beginBillingProvider: searchObject.begBlngProvId,
            endBillingProvider: searchObject.endBlngProvId,
            comments: searchObject.comments,
            sterilIndicator: searchObject.sterilInd !== 'N',
            abortionIndicator: searchObject.abortInd !== 'N'
          });
          setSelectedBeginDate(searchObject.begDt);
          setSelectedEndDate(searchObject.endDt);
      }
      if (props.location.state && props.location.state.successMessages && updateSuccess && !updateSuccess.systemFailure) {
          setSuccessMessages(props.location.state.successMessages);
      }
      if (props.location.state && props.location.state.successMessages && createSuccess) {
        const successArray = [];
        successArray.push('System successfully saved the information.');
        setSuccessMessages(successArray);
    }
  }, [props.location.state]);

  useEffect(() => {
    if (!props.location.state) {
     setEditPage(false);
    }
    if (props.location.state) {
      if (props.location.state.successMessages && !props.location.state.fromSearch) {
        const tempVar = JSON.parse(JSON.stringify(props.location.state.successMessages));
        setSuccessMessages(tempVar);
      } else {
        setSuccessMessages([]);
      }
    }
    ResetEMAR('createPayload');
    ResetEMAR('searchPayload');
    ResetEMAR('updatePayload');

    searchSuccess = null;
    createSuccess = null;
    updateSuccess = null;
  }, []);

const handleChange = name => event => {
    if (name === 'sterilIndicator') {
        setEMARState({ ...EMARState, sterilIndicator: !EMARState.sterilIndicator });
    } else if (name === 'abortionIndicator') {
        setEMARState({ ...EMARState, abortionIndicator: !EMARState.abortionIndicator });
    } else {
    setEMARState({
        ...EMARState,
        [name]: event.target.value
    });
}
};

useEffect(() => {
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Claims#1020']) {
        setCosCodeData(dropDownData.listObj['Claims#1020']);
    }
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Financial#1005']) {
        setReasonCodeData(dropDownData.listObj['Financial#1005']);
    }
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Reference#1042']) {
        setFundCodeData(dropDownData.listObj['Reference#1042']);
    }
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Reference#3045']) {
        setProviderTypeCodeData(dropDownData.listObj['Reference#3045']);
    }
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Claims6#3111']) {
        setAdmitTypeCodeData(dropDownData.listObj['Claims6#3111']);
    }
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Provider#1054']) {
        setProviderSpecData(dropDownData.listObj['Provider#1054']);
    }
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Financial#3458']) {
        setBenfitPlanData(dropDownData.listObj['Financial#3458']);
    }
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Reference#3042']) {
        setProcedureData(dropDownData.listObj['Reference#3042']);
    }
}, [dropDownData]);

  useEffect(() => {
    getDropdownData(dropDownCriteria);
  }, []);

  const logInUserID = useSelector(state => state.sharedState.logInUserID);

  const saveEmar = () => {
    setSuccessMessages([]);
    seterrorMessages([]);
      let tosLineError = false; let priorityNumberError = false; let tosDescriptionError = false;
      let tosLineErrorMsg = ''; let priorityNumberErrorMsg = ''; let tosDescriptionErrorMsg = '';
      const errorMessages = [];
      if (EMARState.tosLine === '') {
        tosLineError = true;
        tosLineErrorMsg = EMARAddConstants.TOSLINE_REQ;
        errorMessages.push(tosLineErrorMsg);
      }
      if (EMARState.priorityNumber === '') {
        priorityNumberError = true;
        priorityNumberErrorMsg = EMARAddConstants.PRIORITY_NUMBER_REQ;
        errorMessages.push(priorityNumberErrorMsg);
      }
      if (EMARState.tosDescription === '') {
        tosDescriptionError = true;
        tosDescriptionErrorMsg = EMARAddConstants.TOS_DESCRIPTION_REQ;
        errorMessages.push(tosDescriptionErrorMsg);
      }
      seterrorMessages(errorMessages);
      setShowError({
        tosLineError: tosLineError,
        priorityNumberError: priorityNumberError,
        tosDescriptionError: tosDescriptionError
      });
      setShowErrorMessage({
        tosLineErrorMsg: tosLineErrorMsg,
        priorityNumberErrorMsg: priorityNumberErrorMsg,
        tosDescriptionErrorMsg: tosDescriptionErrorMsg
      });

      if (errorMessages.length === 0) {
          if (!editPage) {
          const EmarAdd = {
                auditUserID: logInUserID,
                auditTimeStamp: getUTCTimeStamp(),
                addedAuditUserID: logInUserID,
                addedAuditTimeStamp: getUTCTimeStamp(),
                versionNo: 0,
                dbRecord: false,
                sortColumn: null,
                auditKeyList: [],
                auditKeyListFiltered: false,
                emarSeqNo: null,
                cms64TosCdSortKey: "01",
                cms64TosLine: EMARState.tosLine,
                cms64TosDescription: EMARState.tosDescription,
                cms64TosDescriptionStartsOrContains: null,
                priority_Number: +EMARState.priorityNumber,
                begFundSrcCd: EMARState.beginFundCode === 'Please Select One' ? null : EMARState.beginFundCode,
                endFundSrcCd: EMARState.endFundCode === 'Please Select One' ? null : EMARState.endFundCode,
                begMbrBpId: EMARState.beginBenifitPlanId === 'Please Select One' ? null : EMARState.beginBenifitPlanId,
                endMbrBpId: EMARState.endBenifitPlanId === 'Please Select One' ? null : EMARState.endBenifitPlanId,
                beginStateCOS: EMARState.beginStateCos === 'Please Select One' ? null : EMARState.beginStateCos,
                endStateCOS: EMARState.endStateCos === 'Please Select One' ? null : EMARState.endStateCos,
                beginProviderType: EMARState.beginProviderType === 'Please Select One' ? null : EMARState.beginProviderType,
                endProviderType: EMARState.endProviderType === 'Please Select One' ? null : EMARState.endProviderType,
                beginProcedureType: EMARState.beginProcedureCode === '' ? null : EMARState.beginProcedureCode,
                endProcedureType: EMARState.endProcedureCode === '' ? null : EMARState.endProcedureCode,
                begFinRsncd: EMARState.beginFiancialReasonCode === 'Please Select One' ? null : EMARState.beginFiancialReasonCode,
                endFinRsncd: EMARState.endFinancialReasonCode === 'Please Select One' ? null : EMARState.endFinancialReasonCode,
                abortInd: EMARState.abortionIndicator === true ? 'Y' : 'N',
                sterilInd: EMARState.sterilIndicator === true ? 'Y' : 'N',
                begAdmitTyCd: EMARState.beginAdmitTypeCode === 'Please Select One' ? null : EMARState.beginAdmitTypeCode,
                endAdmitTyCd: EMARState.endAdmitTypeCode === 'Please Select One' ? null : EMARState.endAdmitTypeCode,
                begDt: getDateInMMDDYYYYFormat(selectedBeginDate),
                endDt: getDateInMMDDYYYYFormat(selectedEndDate),
                comments: EMARState.comments === '' ? null : EMARState.comments,
                begProvSplCd: EMARState.beginProviderSpeciality === 'Please Select One' ? null : EMARState.beginProviderSpeciality,
                endProvSplCd: EMARState.endProviderSpeciality === 'Please Select One' ? null : EMARState.endProviderSpeciality,
                begBlngProvId: EMARState.beginBillingProvider === '' ? null : EMARState.beginBillingProvider,
                endBlngProvId: EMARState.endBillingProvider === '' ? null : EMARState.endBillingProvider,
                updId: null,
                updDt: null,
                loadDt: null

          };
          createEmar(EmarAdd);
        } else if (editPage) {
            const EmarUpdate =
            {
                auditUserID: logInUserID,
                auditTimeStamp: getUTCTimeStamp(),
                addedAuditUserID: logInUserID,
                addedAuditTimeStamp: getUTCTimeStamp(),
                versionNo: searchObj.versionNo,
                dbRecord: false,
                sortColumn: null,
                auditKeyList: [],
                auditKeyListFiltered: false,
                emarSeqNo: searchObj.emarSeqNo,
                cms64TosCdSortKey: searchObj.cms64TosCdSortKey,
                cms64TosLine: EMARState.tosLine,
                cms64TosDescription: EMARState.tosDescription,
                cms64TosDescriptionStartsOrContains: null,
                priority_Number: EMARState.priorityNumber,
                begFundSrcCd: EMARState.beginFundCode === 'Please Select One' ? null : EMARState.beginFundCode,
                endFundSrcCd: EMARState.endFundCode === 'Please Select One' ? null : EMARState.endFundCode,
                begMbrBpId: EMARState.beginBenifitPlanId === 'Please Select One' ? null : EMARState.beginBenifitPlanId,
                endMbrBpId: EMARState.endBenifitPlanId === 'Please Select One' ? null : EMARState.endBenifitPlanId,
                beginStateCOS: EMARState.beginStateCos === 'Please Select One' ? null : EMARState.beginStateCos,
                endStateCOS: EMARState.endStateCos === 'Please Select One' ? null : EMARState.endStateCos,
                beginProviderType: EMARState.beginProviderType === 'Please Select One' ? null : EMARState.beginProviderType,
                endProviderType: EMARState.endProviderType === 'Please Select One' ? null : EMARState.endProviderType,
                beginProcedureType: EMARState.beginProcedureCode === '' ? null : EMARState.beginProcedureCode,
                endProcedureType: EMARState.endProcedureCode === '' ? null : EMARState.endProcedureCode,
                begFinRsncd: EMARState.beginFiancialReasonCode === 'Please Select One' ? null : EMARState.beginFiancialReasonCode,
                endFinRsncd: EMARState.endFinancialReasonCode === 'Please Select One' ? null : EMARState.endFinancialReasonCode,
                abortInd: EMARState.abortionIndicator === true ? 'Y' : 'N',
                sterilInd: EMARState.sterilIndicator === true ? 'Y' : 'N',
                begAdmitTyCd: EMARState.beginAdmitTypeCode === 'Please Select One' ? null : EMARState.beginAdmitTypeCode,
                endAdmitTyCd: EMARState.endAdmitTypeCode === 'Please Select One' ? null : EMARState.endAdmitTypeCode,
                begDt: getDateInMMDDYYYYFormat(selectedBeginDate),
                endDt: getDateInMMDDYYYYFormat(selectedEndDate),
                comments: EMARState.comments === '' ? null : EMARState.comments,
                begProvSplCd: EMARState.beginProviderSpeciality === 'Please Select One' ? null : EMARState.beginProviderSpeciality,
                endProvSplCd: EMARState.endProviderSpeciality === 'Please Select One' ? null : EMARState.endProviderSpeciality,
                begBlngProvId: EMARState.beginBillingProvider === '' ? null : EMARState.beginBillingProvider,
                endBlngProvId: EMARState.endBillingProvider === '' ? null : EMARState.endBillingProvider,
                updId: null,
                updDt: null,
                loadDt: null
            };
            updateEmar(EmarUpdate);
        }
      }
  };

  return (
    <div className="pos-relative w-100 h-100">

      <div className="tabs-container" ref={toPrintRef}>

        <ErrorMessages errorMessages={errorMessages} />
        {errorMessages.length === 0 ? <SuccessMessages successMessages={successMessages} /> : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left">{ editPage ? 'Update EMAR' : 'Add EMAR' }</h1>

          <div className="float-right mt-1 pt-1">
            <Button
              className="btn btn-success ml-1"
              onClick={() => saveEmar()}
            >
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form noValidate autoComplete="off">
            <div className="form-wrapper">
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-AccountPayeeID"
                  fullWidth
                  required
                  label="CMS64 TOS Line"
                  value={EMARState.tosLine}
                  onChange={handleChange('tosLine')}
                  inputProps={{ maxlength: 15 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={
                   showError.tosLineError ? showErrorMessage.tosLineErrorMsg : null
                  }
                  error={
                    showError.tosLineError ? showErrorMessage.tosLineErrorMsg : null
                }
                ></TextField>
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-AccountPayeeID"
                  fullWidth
                  required
                  label="Priority Number"
                  value={EMARState.priorityNumber}
                  onChange={handleChange('priorityNumber')}
                  inputProps={{ maxlength: 3 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={
                    showError.priorityNumberError ? showErrorMessage.priorityNumberErrorMsg : null
                   }
                   error={
                     showError.priorityNumberError ? showErrorMessage.priorityNumberErrorMsg : null
                 }
                ></TextField>
              </div>
              <div
                className="mui-custom-form-description" // style={{ marginLeft: '30px' }}
              >
                <TextField
                  id="standard-businessName"
                  fullWidth
                  required
                  label="CMS64 TOS Description"
                    value={EMARState.tosDescription}
                    onChange={handleChange('tosDescription')}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ maxLength: 50 }}
                  helperText={
                    showError.tosDescriptionError ? showErrorMessage.tosDescriptionErrorMsg : null
                   }
                   error={
                     showError.tosDescriptionError ? showErrorMessage.tosDescriptionErrorMsg : null
                 }
                />
              </div>
            </div>
            <div className="form-wrapper">
              <div className="mui-custom-form with-select input-md">
                <TextField
                  select
                  fullWidth
                  id="standard-AccountActivityType"
                  label="Begin Fund Code"
                  value={EMARState.beginFundCode}
                  onChange={handleChange('beginFundCode')}
                  placeholder=""
                  inputProps={{ maxlength: 3 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  <MenuItem
                    selected
                    key="Please Select One"
                    value="Please Select One"
                  >
                    Please Select One
                  </MenuItem>

                  {fundCodeData ? fundCodeData.map(option => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.description}
                              </MenuItem>
                            )) : null}
                </TextField>
              </div>
              <div className="mui-custom-form with-select input-md">
                <TextField
                  select
                  fullWidth
                  id="standard-AccountActivityType"
                  label="End Fund Code"
                    value={EMARState.endFundCode}
                    onChange={handleChange('endFundCode')}
                  placeholder=""
                  inputProps={{ maxlength: 3 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  <MenuItem
                    selected
                    key="Please Select One"
                    value="Please Select One"
                  >
                    Please Select One
                  </MenuItem>
                  {fundCodeData ? fundCodeData.map(option => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.description}
                              </MenuItem>
                            )) : null}
                </TextField>
              </div>
              <div className="mui-custom-form with-select input-md">
                <TextField
                  select
                  fullWidth
                  id="standard-AccountActivityType"
                  label="Begin Benfit Plan ID"
                    value={EMARState.beginBenifitPlanId}
                    onChange={handleChange('beginBenifitPlanId')}
                  placeholder=""
                  inputProps={{ maxlength: 3 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  <MenuItem
                    selected
                    key="Please Select One"
                    value="Please Select One"
                  >
                    Please Select One
                  </MenuItem>
                  {benfitPlanData ? benfitPlanData.map(option => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.description}
                              </MenuItem>
                            )) : null}

                </TextField>
              </div>
              <div className="mui-custom-form with-select input-md">
                <TextField
                  select
                  fullWidth
                  id="standard-AccountActivityType"
                  label="End Benfit Plan ID"
                    value={EMARState.endBenifitPlanId}
                    onChange={handleChange('endBenifitPlanId')}
                  placeholder=""
                  inputProps={{ maxlength: 3 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  <MenuItem
                    selected
                    key="Please Select One"
                    value="Please Select One"
                  >
                    Please Select One
                  </MenuItem>
                  {benfitPlanData ? benfitPlanData.map(option => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.description}
                              </MenuItem>
                            )) : null}
                </TextField>
              </div>
            </div>
            <div className="form-wrapper">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="mui-custom-form with-date">
                  <KeyboardDatePicker
                    fullWidth
                    id="GrossLevelDetailBegindate-picker-dialog"
                    label="Begin Date"
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    value={selectedBeginDate}
                    onChange={handleBeginDate}
                    InputLabelProps={{
                      shrink: true
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    maxDateMessage = ''

                  />
                </div>
                <div className="mui-custom-form with-date">
                  <KeyboardDatePicker
                    fullWidth
                    id="GrossLevelDetailBegindate-picker-dialog"
                    label="End Date"
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    value={selectedEndDate}
                    onChange={handleEndDate}
                    InputLabelProps={{
                      shrink: true
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    maxDateMessage = ''
                  />
                </div>
              </MuiPickersUtilsProvider>
              <div className="mui-custom-form sub-radio float-left no-margin-label margin-top-24">
                <FormControlLabel
                  value="balance"
                  control={
                    <Checkbox
                      color="primary"
                        checked={EMARState.abortionIndicator}
                        value={EMARState.abortionIndicator}
                        onChange={handleChange('abortionIndicator')}
                    />
                  }
                  label="Abortion Indicator"
                  labelPlacement="end"
                />
              </div>
              <div className="mui-custom-form sub-radio float-left no-margin-label margin-top-24">
                <FormControlLabel
                  value="balance"
                  control={
                    <Checkbox
                      color="primary"
                        checked={EMARState.sterilIndicator}
                        value={EMARState.sterilIndicator}
                        onChange={handleChange('sterilIndicator')}
                    />
                  }
                  label="Steril Indicator"
                  labelPlacement="end"
                />
              </div>
            </div>
            <div className="form-wrapper">
              <div className="mui-custom-form with-select input-md">
                <TextField
                  select
                  fullWidth
                  id="standard-AccountActivityType"
                  label="Begin Admit Type Code"
                    value={EMARState.beginAdmitTypeCode}
                    onChange={handleChange('beginAdmitTypeCode')}
                  placeholder=""
                  inputProps={{ maxlength: 3 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  <MenuItem
                    selected
                    key="Please Select One"
                    value="Please Select One"
                  >
                    Please Select One
                  </MenuItem>
                  {admitTypeCodeData ? admitTypeCodeData.map(option => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.description}
                              </MenuItem>
                            )) : null}

                </TextField>
              </div>
              <div className="mui-custom-form with-select input-md">
                <TextField
                  select
                  fullWidth
                  id="standard-AccountActivityType"
                  label="End Admit Type Code"
                    value={EMARState.endAdmitTypeCode}
                    onChange={handleChange('endAdmitTypeCode')}
                  placeholder=""
                  inputProps={{ maxlength: 3 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  <MenuItem
                    selected
                    key="Please Select One"
                    value="Please Select One"
                  >
                    Please Select One
                  </MenuItem>
                  {admitTypeCodeData ? admitTypeCodeData.map(option => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.description}
                              </MenuItem>
                            )) : null}

                </TextField>
              </div>
            </div>

            <div>
              <div className="row">
                <div className="col-6">
                  <fieldset className="r-custom-fieldset">
                    <div className="form-wrapper">
                      <div className="mui-custom-form with-select input-md override-width-45">
                        <TextField
                          select
                          fullWidth
                          id="standard-AccountActivityType"
                          label="Begin State COS"
                            value={EMARState.beginStateCos}
                            onChange={handleChange('beginStateCos')}
                          placeholder=""
                          inputProps={{ maxlength: 3 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        >
                          <MenuItem
                            selected
                            key="Please Select One"
                            value="Please Select One"
                          >
                            Please Select One
                          </MenuItem>
                          {cosCodeData ? cosCodeData.map(option => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.description}
                              </MenuItem>
                            )) : null}
                        </TextField>
                      </div>
                      <div className="mui-custom-form with-select input-md override-width-45">
                        <TextField
                          select
                          fullWidth
                          id="standard-AccountActivityType"
                          label="End State COS"
                            value={EMARState.endStateCos}
                            onChange={handleChange('endStateCos')}
                          placeholder=""
                          inputProps={{ maxlength: 3 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        >
                          <MenuItem
                            selected
                            key="Please Select One"
                            value="Please Select One"
                          >
                            Please Select One
                          </MenuItem>
                          {cosCodeData ? cosCodeData.map(option => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.description}
                              </MenuItem>
                            )) : null}
                        </TextField>
                      </div>
                    </div>
                    <div className="form-wrapper">
                      <div className="mui-custom-form with-select input-md override-width-45">
                        <TextField
                          select
                          fullWidth
                          id="standard-AccountActivityType"
                          label="Begin Provider Type"
                            value={EMARState.beginProviderType}
                            onChange={handleChange('beginProviderType')}
                          placeholder=""
                          inputProps={{ maxlength: 3 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        >
                          <MenuItem
                            selected
                            key="Please Select One"
                            value="Please Select One"
                          >
                            Please Select One
                          </MenuItem>
                          {providetypeCodeData ? providetypeCodeData.map(option => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.description}
                              </MenuItem>
                            )) : null}
                        </TextField>
                      </div>
                      <div className="mui-custom-form with-select input-md override-width-45">
                        <TextField
                          select
                          fullWidth
                          id="standard-AccountActivityType"
                          label="End Proivder Type"
                            value={EMARState.endProviderType}
                            onChange={handleChange('endProviderType')}
                          placeholder=""
                          inputProps={{ maxlength: 3 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        >
                          <MenuItem
                            selected
                            key="Please Select One"
                            value="Please Select One"
                          >
                            Please Select One
                          </MenuItem>
                          {providetypeCodeData ? providetypeCodeData.map(option => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.description}
                              </MenuItem>
                            )) : null}

                        </TextField>
                      </div>
                    </div>
                    <div className="form-wrapper">
                      <div className="mui-custom-form with-select input-md override-width-45">
                        <TextField
                          fullWidth
                          id="standard-AccountActivityType"
                          label="Begin Procedure Code"
                            value={EMARState.beginProcedureCode}
                            onChange={handleChange('beginProcedureCode')}
                          placeholder=""
                          inputProps={{ maxlength: 5 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        >
                        </TextField>
                      </div>
                      <div className="mui-custom-form min-md override-width-45">
                        <TextField
                          fullWidth
                          id="standard-AccountActivityType"
                          label="End Procedure Code"
                            value={EMARState.endProcedureCode}
                            onChange={handleChange('endProcedureCode')}
                          placeholder=""
                          inputProps={{ maxlength: 5 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        >
                        </TextField>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="col-6">
                  <fieldset className="custom-fieldset">
                    <div className="form-wrapper">
                      <div className="mui-custom-form with-select input-md override-width-45">
                        <TextField
                          select
                          fullWidth
                          id="standard-AccountActivityType"
                          label="Begin Provider Specialty"
                            value={EMARState.beginProviderSpeciality}
                            onChange={handleChange('beginProviderSpeciality')}
                          placeholder=""
                          inputProps={{ maxlength: 3 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        >
                          <MenuItem
                            selected
                            key="Please Select One"
                            value="Please Select One"
                          >
                            Please Select One
                          </MenuItem>
                          {providerSpecData ? providerSpecData.map(option => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.description}
                              </MenuItem>
                            )) : null}

                        </TextField>
                      </div>
                      <div className="mui-custom-form min-md override-width-45">
                        <TextField
                          select
                          fullWidth
                          id="standard-AccountActivityType"
                          label="End Provider Specialty"
                            value={EMARState.endProviderSpeciality}
                            onChange={handleChange('endProviderSpeciality')}
                          placeholder=""
                          inputProps={{ maxlength: 3 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        >
                          <MenuItem
                            selected
                            key="Please Select One"
                            value="Please Select One"
                          >
                            Please Select One
                          </MenuItem>
                          {providerSpecData ? providerSpecData.map(option => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.description}
                              </MenuItem>
                            )) : null}

                        </TextField>
                      </div>
                    </div>
                    <div className="form-wrapper">
                      <div className="mui-custom-form with-select input-md override-width-45">
                        <TextField
                          select
                          fullWidth
                          id="standard-AccountActivityType"
                          label="Begin Financial Reason Code"
                            value={EMARState.beginFiancialReasonCode}
                            onChange={handleChange('beginFiancialReasonCode')}
                          placeholder=""
                          inputProps={{ maxlength: 3 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        >
                          <MenuItem
                            selected
                            key="Please Select One"
                            value="Please Select One"
                          >
                            Please Select One
                          </MenuItem>
                          {ReasonCodeData ? ReasonCodeData.map(option => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.description}
                              </MenuItem>
                            )) : null}
                        </TextField>
                      </div>
                      <div className="mui-custom-form min-md override-width-45">
                        <TextField
                          select
                          fullWidth
                          id="standard-AccountActivityType"
                          label="End Financial Reason Code
                  "
                            value={EMARState.endFinancialReasonCode}
                            onChange={handleChange('endFinancialReasonCode')}
                          placeholder=""
                          inputProps={{ maxlength: 3 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        >
                          <MenuItem
                            selected
                            key="Please Select One"
                            value="Please Select One"
                          >
                            Please Select One
                          </MenuItem>
                          {ReasonCodeData ? ReasonCodeData.map(option => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.description}
                              </MenuItem>
                            )) : null}
                        </TextField>
                      </div>
                    </div>
                    <div className="form-wrapper">
                      <div className="mui-custom-form with-select input-md override-width-45">
                        <TextField
                          fullWidth
                          id="standard-AccountActivityType"
                          label="Begin Billing Provider ID
                  "
                            value={EMARState.beginBillingProvider}
                            onChange={handleChange('beginBillingProvider')}
                          placeholder=""
                          inputProps={{ maxlength: 3 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        ></TextField>
                      </div>
                      <div className="mui-custom-form min-md override-width-45">
                        <TextField
                          fullWidth
                          id="standard-AccountActivityType"
                          label="End Billing Provider ID

                  "
                            value={EMARState.endBillingProvider}
                            onChange={handleChange('endBillingProvider')}
                          placeholder=""
                          inputProps={{ maxlength: 3 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        ></TextField>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
            <div className ='form-wrapper'>
            <div className="mui-custom-form">
            <label className='MuiFormLabel-root small-label no-margin' htmlFor="w3review">Comments</label>
                    <textarea id="w3review" name="w3review" rows="4" cols="100" value= {EMARState.comments} onChange = {handleChange('comments')}/>
 </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default withRouter(EMARAdd);
