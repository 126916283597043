/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../../Actions/AppConfigCommon/AppConfigActionTypes';

const axiosPayLoad = {
  payload: null,
  lobDropDown: [],
  isFocusCheck: false
};
const reducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.APP_CONFIG_DROPDOWNS:
      return { ...state, appConfigDropdown: action.appConfigDropdown };
    case actionTypes.FOCUS_CHECK:
      return { ...state, isFocusCheck: action.setFocus };
    case actionTypes.DATA_ELEMENT_NAME_DROPDOWNS:
      return { ...state, dataElementNameDropdown: action.dataElementNameDropdown };
    case actionTypes.DATA_ELEMENT_MAP_NAME_DROPDOWNS:
      return { ...state, dataElementMapDropdown: action.dataElementMapDropdown };
    case actionTypes.MODIFIER_DROPDOWNS:
      return { ...state, modifierDropdown: action.modifierDropdown };
    case actionTypes.AUDIT_LOG_TABLE:
      return { ...state, auditData: action.payload };
    default: return state;
  }
};

export default reducer;
