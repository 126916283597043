/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/SecurityActionTypes';

const initialState = {
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VALIDATE_USER_ID_ACTION_TYPE:
      return { ...state, validUserDetails: action.validUserDetails };
    case actionTypes.ADD_NEW_USER:
      return { ...state, addUSerResponse: action.addUSerResponse };
    case actionTypes.GET_WORKUNIT_LIST:
      return { ...state, workUnitList: action.workUnitList };
    case actionTypes.GET_LOB_ADD_USER:
      return { ...state, lobDDList: action.lobDDList };
    case actionTypes.UPDATE_EXISTING_USER:
      return { ...state, updateUser: action.updateUser };
    case actionTypes.USER_VALID_ROLES_ACTION:
      return { ...state, validUserPrivileges: action.validUserPrivileges };
    default: return state;
  }
};

export default reducer;
