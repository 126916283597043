/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import * as Constants from './BulkWriteOffReestConstants';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import ErrorMessages from '../../../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../../../SharedModules/Errors/SuccessMessage';
import { GET_APP_DROPDOWNS } from "../../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../../SharedModules/Dropdowns/dropdowns";
import dropDownCriteria from './DropDownCriteria.json';
import { getDropdownDataAction, getPayeeIdType, saveBulkViewWriteOffReest } from '../../../Store/Actions/accountReceivable/accountReceivableActions';
import TextField from '@material-ui/core/TextField';
import { validatePayeeID } from '../../../Store/Actions/accountReceivable/ARTransferActions';
import { getUTCTimeStamp } from '../../../../../SharedModules/DateUtilities/DateUtilities';
import Footer from '../../../../../SharedModules/Layout/footer';
import TableComponent from '../../../../../SharedModules/Table/Table';
import SystemPayeeTable from '../../../../../SharedModules/SystemIDTable/SystemPayeeIDTable';
import { removeLeadingTrailingSpaces } from '../../../../../SharedModules/CommonUtilities/commonUtilities';
import {
  DialogContent,
  DialogActions
} from '../../../../../SharedModules/Dialog/DialogUtilities';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import NativeDropDown from '../../../../../SharedModules/Dropdowns/NativeDropDown';

function BulkWriteOffRest (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);

  const DropDownAPI = (dropdownValues) => { dispatch(getDropdownDataAction(dropdownValues)); };
  const dropDownDetails = useSelector(state => state.accountReceivableState.dropdownData);

  const payeeDetails = useSelector(state => state.accountReceivableState.payeeDetails);

  const [payeeTypeDropdown, setPayeeTypeDropdown] = React.useState([]);
  const [payeeIDTypeFromDropdown, setPayeeIDTypeFromDropdown] = React.useState([]);
  const [payeeIDTypeTODropdown, setPayeeIDTypeTODropdown] = React.useState([]);

  const [reasonCodeDropdown, setReasonCodeDropdown] = React.useState([]);
  let payeeIdTypeDropdown = [];
  payeeIdTypeDropdown = useSelector(
    state => state.accountReceivableState.payeeIdTypeDropdown
  );
  const saveBulkViewWriteOffResult = useSelector(state => state.accountReceivableState.saveBulkViewWriteOffResult);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const dropdownData = useSelector(state => state.appDropDowns.appdropdowns);
  useEffect(() => {
    if (saveBulkViewWriteOffResult && !saveBulkViewWriteOffResult.systemFailure && saveBulkViewWriteOffResult.errorMessages && saveBulkViewWriteOffResult.errorMessages.length > 0) {
      const errorMessages = [];
      saveBulkViewWriteOffResult.errorMessages.map((value) => {
        const message = `${saveBulkViewWriteOffResult.originalFCN}: ${value}`;
        errorMessages.push(message);
      });
      setErrorMessages(errorMessages);
    } else if (saveBulkViewWriteOffResult && !saveBulkViewWriteOffResult.systemFailure && saveBulkViewWriteOffResult.respcode === '01') {
      const successMessage = [];
      const message = Constants.SAVED_SUCCESS;
      successMessage.push(message);
      setSystemSuccesMessages(successMessage);
      setFocusCheck(false);
      const tempData = JSON.parse(JSON.stringify(tableData));
      tempData.map((value) => {
        value.balanceAmount = '0.00';
        saveBulkViewWriteOffResult.fcnamountlist.map((values) => {
          if (value.receivableFCN === values.oldFCN) {
            value.newTransferFcn = values.newFCN;
            value.newBalanceAmount = values.newAmount;
          }
        });
      });
      setTableData(tempData);
    } else if (saveBulkViewWriteOffResult && saveBulkViewWriteOffResult.systemFailure) {
      const errorMessages = [];
      errorMessages.push(Constants.GENERIC_SYSTEM_ERROR);
      setErrorMessages(errorMessages);
    }
  }, [saveBulkViewWriteOffResult]);

  useEffect(() => {
    if (payeeIdTypeDropdown && payeeIdTypeDropdown.payeeIdTypeCode && payeeIdTypeDropdown.payeeIdTypeCode.length > 0) {
      setPayeeIDTypeFromDropdown(payeeIdTypeDropdown.payeeIdTypeCode);
      setPayeeIDTypeTODropdown(payeeIdTypeDropdown.payeeIdTypeCode);
    }
  }, [payeeIdTypeDropdown]);
  /* Setting initial values */
  const [values, setValues] = React.useState({
    fromPayeeType: DEFAULT_DD_VALUE,
    fromPayeeIDTypeCode: DEFAULT_DD_VALUE,
    fromPayeeID: '',
    fromPayeeName: '',
    toPayeeType: DEFAULT_DD_VALUE,
    toPayeeIDTypeCode: DEFAULT_DD_VALUE,
    toPayeeID: '',
    toPayeeName: ''
  });
  const [sysID, setSysID] = React.useState('');
  const [provBillSysId, setProvBillSysId] = React.useState('');
  const [tableData, setTableData] = React.useState([]);
  const handleTableReasonCode = () => () => {
    setFocusCheck(true);
  };

  const setRsnCdData = data => {
    setTableData(JSON.parse(JSON.stringify(data)));
  };

  const handlePayeeValue = (name) => e => {
    setFocusCheck(true);
    const value = e.target.value;
    setValues({ ...values, [name]: value });
    if (name === 'toPayeeType') {
      setValues({...values, [name]: value, toPayeeIDTypeCode: DEFAULT_DD_VALUE });
      if(e.target.value !== DEFAULT_DD_VALUE){
        onSearchPayeeIdType(e.target.value);
        const payeeTypeDesc = fieldDescription(payeeTypeDropdown, e.target.value);
        setPayeeTypeDesc(payeeTypeDesc);
       }
    }
    if (name === 'toPayeeIDTypeCode') {
      setValues({ ...values, [name]: value, toPayeeID: '', toPayeeName: '' });
      const payeeTypeIdDesc = fieldDescription(payeeIDTypeTODropdown, e.target.value);
      setPayeeTypeIdCodeDesc(payeeTypeIdDesc);
    }
  };
  let validateIDResult = [];

  React.useEffect(() => {
    DropDownAPI(dropDownCriteria);
    onDropdowns([
      Dropdowns.F_RSN_CD,
      Dropdowns.PAYEE_TYPE,
      Dropdowns.PAYEE_STATUS_TYPE,
      Dropdowns.PAYEE_ID_TYPE_CD_OTHER,
      Dropdowns.PAYEE_ID_TYPE_CD_PROVIDER,
      Dropdowns.PAYEE_ID_TYPE_CD_MEMBER,
      Dropdowns.PAYEE_ID_TYPE_CD_TPL
    ]);
    setOpenSysPayeeTable(false);
    validateIDResult = [];
  }, []);
  React.useEffect(() => {
    if (payeeDetails && !payeeDetails.systemFailure) {
    }
  }, [payeeDetails]);
  const onSearchPayeeIdType = values => dispatch(getPayeeIdType(values));

  React.useEffect(() => {
    if (dropDownDetails) {
      const tableValues = JSON.parse(JSON.stringify(props.location.state.selectedFcnObj));

      if (dropDownDetails.listObj && dropDownDetails.listObj['General#6002']) {
        setPayeeTypeDropdown(dropDownDetails.listObj['General#6002']);
      }
      if (dropDownDetails.listObj && dropDownDetails.listObj['Financial#3003']) {
        setReasonCodeDropdown(dropDownDetails.listObj['Financial#3003']);
        tableValues.map((value) => {
          value.transferreasonCode = dropDownDetails.listObj['Financial#3003'][0]?.code;
        });
        setTableData(tableValues);
      }
    }
  }, [dropDownDetails]);

  // Validation
  const [showError, setShowError] = React.useState({
    toPayeeType: false,
    toPayeeIDTypeCode: false,
    toPayeeID: false,
    fromPayeeType: false,
    fromPayeeIDTypeCode: false,
    fromPayeeID: false,
    showATRError: false

  });
  const [showErrorMessage, setShowErrorMessage] = React.useState({
    toPayeeType: '',
    toPayeeIDTypeCode: '',
    toPayeeID: '',
    fromPayeeType: '',
    fromPayeeIDTypeCode: '',
    fromPayeeID: '',
    atrErrorMsg: ''
  });
  const ValidateFields = () => {
    var toPayeeTypeErr = false;
    var toPayeeIDTypeErr = false;
    var toPayeeIDErr = false;
    var fromPayeeTypeErr = false;
    var fromPayeeIDTypeErr = false;
    var fromPayeeIDErr = false;
    var atrErrorTemp = false;
    var toPayeeTypeErrMsg = '';
    var toPayeeIDTypeErrMsg = '';
    var toPayeeIDErrMsg = '';
    var fromPayeeTypeErrMsg = '';
    var fromPayeeIDTypeErrMsg = '';
    var fromPayeeIDErrMsg = '';
    var atrErrorMsgTemp = '';
    var _error = true;
    const errorMsgs = [];
    const regex = /[^A-Za-z0-9]/;

    if (values.toPayeeType === DEFAULT_DD_VALUE) {
      _error = false;
      toPayeeTypeErr = true;
      toPayeeTypeErrMsg = Constants.PAYEE_TYPE_REQ;
      errorMsgs.push(toPayeeTypeErrMsg);
    }
    if (values.toPayeeIDTypeCode === DEFAULT_DD_VALUE) {
      _error = false;
      toPayeeIDTypeErr = true;
      toPayeeIDTypeErrMsg = Constants.PAYEE_ID_TYPE_CODE;
      errorMsgs.push(toPayeeIDTypeErrMsg);
    }
    if (!values.toPayeeID || (values.toPayeeID && !values.toPayeeID.trim())) {
      _error = false;
      toPayeeIDErr = true;
      toPayeeIDErrMsg = Constants.PAYEE_ID_REQUIRED;
      errorMsgs.push(toPayeeIDErrMsg);
    }
    if (values.atrNumber && regex.test(values.atrNumber)) {
      var _error = false;
      atrErrorTemp = true;
      atrErrorMsgTemp = `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${Constants.TRANSACTION_NUMBER_FORMAT}`;
      errorMsgs.push(atrErrorMsgTemp);
    } else { atrErrorTemp = false; }
    if (tableData && tableData.length > 0) {
      let count = 0;
      for (let i = 0; i <= tableData.length - 1; i++) {
        if (!tableData[i].transferreasonCode ||
          tableData[i].transferreasonCode === DEFAULT_DD_VALUE) {
          tableData[i].errorKey = true;
          tableData[i].transferreasonCode = DEFAULT_DD_VALUE;
          count = count + 1;
        }
      }
      if (count > 0) {
        errorMsgs.push('Write-off Re-establishment Reasoncode is Required.');
        setErrorMessages(errorMsgs);
        setTableData(tableData);
      }
    }
    setErrorMessages(errorMsgs);
    setShowError({
      toPayeeType: toPayeeTypeErr,
      toPayeeIDTypeCode: toPayeeIDTypeErr,
      toPayeeID: toPayeeIDErr,
      fromPayeeType: fromPayeeTypeErr,
      fromPayeeIDType: fromPayeeIDTypeErr,
      fromPayeeID: fromPayeeIDErr,
      showATRError: atrErrorTemp
    });
    setShowErrorMessage({
      toPayeeType: toPayeeTypeErrMsg,
      toPayeeIDTypeCode: toPayeeIDTypeErrMsg,
      toPayeeID: toPayeeIDErrMsg,
      fromPayeeType: fromPayeeTypeErrMsg,
      fromPayeeIDType: fromPayeeIDTypeErrMsg,
      fromPayeeID: fromPayeeIDErrMsg,
      atrErrorMsg: atrErrorMsgTemp
    });
    return _error;
  };
  // Validate Payee
  const validatePayee = () => {
    setErrorMessages([]);
    setSystemSuccesMessages([]);
    const validatePayeeObj = {
      transferVO: removeLeadingTrailingSpaces({
        addedAuditTimeStamp: null,
        addedAuditUserID: null,
        agingCode: null,
        auditTimeStamp: null,
        auditUserID: null,
        balanceAmount: null,
        dbRecord: false,
        establishedDate: null,
        establishedDateStr: null,
        fcn: null,
        fcnMediaCode: null,
        fcnSeqNum: null,
        originalAmount: null,
        payeePayerID: values.toPayeeID,
        payeePayerIDTypeCode: values.toPayeeIDTypeCode,
        payeePayerIdFromDB: null,
        payeePayerNameFromDB: null,
        payeePayerTypeCode: values.toPayeeType,
        relatedFTTCN: null,
        sortColumn: null,
        sysID: null,
        transferActivityTypeCodeDesc: null,
        transferReasonCode: null,
        transferReasonCodeDesc: null,
        versionNo: 0
      })
    };
    ValidateFields();
    validatePayeeAPICall(validatePayeeObj);
  };
    // Validate Payee Action Call
  const validatePayeeAPICall = (validatePayeeobj) => {
    ValidateFields();

    dispatch(validatePayeeID(validatePayeeobj));
  };

  const saveBulkWriteOffReestApi = (value) => {
    dispatch(saveBulkViewWriteOffReest(value));
  };
  const saveBulkWriteOffReest = () => {
    const tempErrorMessages = [];
    if (ValidateFields()) {
      const bulkViewWriteOffReestResult = props.location.state.viewBulkWriteOffReestResult;
      const selectedTransferResult = tableData;
      const saveTransferArr = [];
      for (let i = 0; i < bulkViewWriteOffReestResult.length; i++) {
        if (bulkViewWriteOffReestResult[i].receivableFCN === selectedTransferResult[i]?.receivableFCN) {
          bulkViewWriteOffReestResult[i].auditUserID = logInUserID;
          bulkViewWriteOffReestResult[i].auditTimeStamp = getUTCTimeStamp();
          bulkViewWriteOffReestResult[i].addedAuditUserID = logInUserID;
          bulkViewWriteOffReestResult[i].addedAuditTimeStamp = getUTCTimeStamp();
          bulkViewWriteOffReestResult[i].writeOffReasonCode = selectedTransferResult[i]?.transferreasonCode;
          bulkViewWriteOffReestResult[i].reestablishReceivableVO.payeePayerID = values.toPayeeID;
          bulkViewWriteOffReestResult[i].reestablishReceivableVO.payeePayerTypeCode = values.toPayeeType;
          bulkViewWriteOffReestResult[i].reestablishReceivableVO.payeePayerIDTypeCode = values.toPayeeIDTypeCode;
          bulkViewWriteOffReestResult[i].reestablishReceivableVO.payeePayerIdFromDB = sysID;
          bulkViewWriteOffReestResult[i].reestablishReceivableVO.payeePayerName = values.toPayeeName;
          bulkViewWriteOffReestResult[i].reestablishReceivableVO.accountReceivableReasonCode = selectedTransferResult[i]?.transferreasonCode;
          bulkViewWriteOffReestResult[i].reestablishReceivableVO.balanceAmount = bulkViewWriteOffReestResult[i].balanceAmount;
          bulkViewWriteOffReestResult[i].reestablishReceivableVO.agingCode = '6';
          bulkViewWriteOffReestResult[i].reestablishReceivableVO.atrNumber = values?.atrNumber?.toUpperCase();
          bulkViewWriteOffReestResult[i].reestablishReceivableVO = removeLeadingTrailingSpaces(bulkViewWriteOffReestResult[i].reestablishReceivableVO);
          saveTransferArr.push(bulkViewWriteOffReestResult[i]);
        }
      }
      if (tableData && tableData.length > 0) {
        let count = 0;
        for (let i = 0; i <= tableData.length - 1; i++) {
          if (tableData[i].transferreasonCode === DEFAULT_DD_VALUE) {
            tableData[i].errorKey = true;
            tableData[i].transferreasonCode = DEFAULT_DD_VALUE;
            count = count + 1;
          }
        }
        if (count > 0) {
          tempErrorMessages.push('Write-off Re-establishment Reasoncode is Required.');
          setErrorMessages(tempErrorMessages);
          setTableData(tableData);
        }
      }

      if (tempErrorMessages.length === 0 && values.toPayeeName !== '') {
        setErrorMessages([]);
        setFocusCheck(false);
        setSystemSuccesMessages([]);

        saveBulkWriteOffReestApi(saveTransferArr);
      } else if (values.toPayeeName === '') {
        const message = 'Payee/Payer does not exist in the database.';
        const messages = [];
        messages.push(message);
        setErrorMessages(messages);
      }
    }
  };

  validateIDResult = useSelector(state => state.accountReceivableTransferState.validatePayeeIDvalues);
  useEffect(() => {
    if (validateIDResult && !validateIDResult.systemFailure) {
      if (validateIDResult && validateIDResult.respcode && validateIDResult.respcode === '02') {
        setErrorMessages(validateIDResult.errorMessages);
        setValues({ ...values, toPayeeName: '' });
      }
      if (validateIDResult && validateIDResult.respcode && validateIDResult.respcode === '01') {
        if (validateIDResult && validateIDResult.enterpriseSearchresultVO && validateIDResult.enterpriseSearchresultVO.searchResults &&
                    validateIDResult.enterpriseSearchresultVO.searchResults[0] &&
                    validateIDResult.enterpriseSearchresultVO.searchResults[0].multiplePayeeDetails && validateIDResult.enterpriseSearchresultVO.searchResults[0].multiplePayeeDetails.length > 1) {
          setOpenSysPayeeTable(true);
          setSystemPayeeTableData(validateIDResult.enterpriseSearchresultVO.searchResults[0].multiplePayeeDetails);
          setProvBillSysId(validateIDResult.enterpriseSearchresultVO.searchResults[0].provBillSysId);
        } else {
          setValues({ ...values, toPayeeName: validateIDResult.enterpriseSearchresultVO.searchResults[0].transferVO.payeePayerNameFromDB });
          setToPayeeName(validateIDResult.enterpriseSearchresultVO.searchResults[0].transferVO.payeePayerNameFromDB);
          setSysID(validateIDResult.enterpriseSearchresultVO.searchResults[0].transferVO.sysID);
          setProvBillSysId(validateIDResult.enterpriseSearchresultVO.searchResults[0].provBillSysId);
        }
      }
    } else if (validateIDResult && validateIDResult.systemFailure) {
      setErrorMessages([Constants.GENERIC_SYSTEM_ERROR]);
      setValues({ ...values, toPayeeName: '' });
    }
  }, [validateIDResult]);

  /* Check if text enetered is empty or contains only white spaces */

  /* This function is used to navigate to Accounts Receivable Add Screen */
  useEffect(() => {
    if (props.location.state && props.location.state.selectedFcnObj && props.location.state.selectedFcnObj.length > 0) {
      props.location.state.selectedFcnObj.map(() => {
      });
    }
    const selectedPayeeFrmSearch = props.location.state.selectedPayee;
      const payeeType = selectedPayeeFrmSearch.payeeType?.split("-")[0];
      const fromPayeeTypeDesc = dropdownData && dropdownData['G1#G_CMN_ENTY_TY_CD'] &&
      dropdownData['G1#G_CMN_ENTY_TY_CD']?.find(value => {
        return value.code === payeeType;
      })?.description || selectedPayeeFrmSearch.payeeType;
      let payeeIdTypeCdDD;
      if (payeeType === 'P') {
        payeeIdTypeCdDD = "F1#P_ALT_ID_TY_CD";
      } else if (payeeType === 'M') {
        payeeIdTypeCdDD = "F1#B_ALT_ID_TY_CD";
      } else if (payeeType === 'O') {
        payeeIdTypeCdDD = "F1#G_ENTY_EXTL_ID_TY_CD";
      } else if (payeeType === 'TC') {
        payeeIdTypeCdDD = "F1#DM_G_CARR_ENTY_ID_TY_CD";
      }
      const fromPayeeIdTypeDesc = dropdownData && dropdownData[payeeIdTypeCdDD] &&
      dropdownData[payeeIdTypeCdDD]?.find(value => {
        return value.code === selectedPayeeFrmSearch.payeeTypeCode?.split("-")[0];
      })?.description || selectedPayeeFrmSearch.payeeTypeCode;
    if (props.location.state && props.location.state.selectedPayee) {
      setValues({
        ...values,
        fromPayeeType: fromPayeeTypeDesc,
        fromPayeeID: props.location.state.selectedPayee.payee,
        fromPayeeIDTypeCode: fromPayeeIdTypeDesc,
        fromPayeeName: props.location.state.selectedPayee.payeeName
      });
    }
  }, []);
  const headCells = [
    {
      id: 'receivableFCN',
      numeric: false,
      disablePadding: true,
      label: 'FCN',
      width: '13%',
      enableHyperLink: true
    },
    {
      id: 'reasonCodeDesc',
      numeric: false,
      disablePadding: false,
      label: 'Original A/R Reason Code',
      width: '15%',
      isTooltip: true,
      isTitle: 'resonCodeLongDesc'
    },
    {
      id: 'establishedDateStr',
      numeric: false,
      disablePadding: false,
      label: 'Established Date',
      width: '12%',
      isDate: true
    },
    {
      id: 'balanceAmount',
      numeric: false,
      disablePadding: false,
      label: 'Balance Amount',
      width: '15%',
      isBalance: 'true'
    },
    {
      id: 'transferreasonCode',
      numeric: false,
      disablePadding: false,
      isSelectBox: true,
      label: 'Write-Off Re-establishment Reason Code',
      width: '17%',
      errorKey: 'errorKey',
      dropDownData: []
    },
    {
      id: 'newTransferFcn',
      numeric: false,
      disablePadding: false,
      label: 'Re-Establishment FCN',
      isNewHyperlink: true,
      width: '13%'
    },
    {
      id: 'newBalanceAmount',
      numeric: false,
      disablePadding: false,
      label: 'Re-Establishment Balance Amount',
      width: '15%',
      isBalance: true
    }
  ];
  const formatSearchCriteria = row => {
    return (row.receivableFCN);
  };

  const editRow = row => () => {
    const payloadData = formatSearchCriteria(row);
    props.history.push({
      pathname: '/ARBulkTransfer',
      state: { payloadData, values: valuesFromBulkMaintenance }
    });
  };

  // Payee Popup Implementation Starts
  const [systemPayeeTableData, setSystemPayeeTableData] = React.useState([]);
  const [currentSysID, setCurrentSysID] = React.useState(null);
  const [openSysPayeeTable, setOpenSysPayeeTable] = React.useState(false);
  const [toPayeeName, setToPayeeName] = React.useState('');
  const [toSysID, setToSysID] = React.useState('');
  const [payeeTypeDesc, setPayeeTypeDesc] = React.useState('');
  const [payeeTypeIdCodeDesc, setPayeeTypeIdCodeDesc] = React.useState('');

  const fieldDescription = (dropdownFilter, Code) => {
    const filteredValue = dropdownFilter.filter(
      (rCode) => rCode.code === Code
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return Code;
  };

  const handleCloseSysPayeeTable = () => {
    setOpenSysPayeeTable(false);
  };
  const onRadioButtonClick = (row) => {
    setCurrentSysID(row);
  };
  const onContinue = () => {
    if (currentSysID) {
      setValues({ ...values, toPayeeName: currentSysID.payeeName });
      setToPayeeName(currentSysID.payeeName);
      setToSysID(currentSysID.sysID);
      setSysID(currentSysID.sysID);
      setCurrentSysID(null);
      setOpenSysPayeeTable(false);
    }
  };
  const valuesFromBulkMaintenance = props.history && props.history.location && props.history.location.state &&
  props.history.location.state.values;

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(() => {
      setDialogOpen(false);
      setFocusCheck(false);
      props.history.push({
        pathname: '/BulkMaintenance',
        tableRender: true,
        state: { valuesFromBulkMaintenance: valuesFromBulkMaintenance }
      });
    }, 100);
  };
  const majorCancel = () => {
    setErrorMessages([]);
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      props.history.push({
        pathname: '/BulkMaintenance',
        tableRender: true,
        state: { valuesFromBulkMaintenance: valuesFromBulkMaintenance }
      });
    }
  };
  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          // getting respective record from dropdown list which also has desc
          const reasonCode =
          dropdownData &&
          dropdownData['F1#F_RSN_CD'] &&
          dropdownData['F1#F_RSN_CD'].filter(
            value => value.code === each.reasonCode?.split("-")[0]
          );
        // updating the record with desc
        each.reasonCodeDesc =
          reasonCode && reasonCode.length > 0
            ? reasonCode[0].description
            : each.reasonCode ? each.reasonCode : '';
        }
        each.transReasCodeCheck = reasonCodeDropdown.find(value => value.code === each.transferreasonCode);
        if (!each.transReasCodeCheck || each.transReasCodeCheck.length === 0) {
          each.transferreasonCode = DEFAULT_DD_VALUE;
        }
        return each;
      });
      return tData;
    }
    return [];
  };
  return (
    <div>
      <div className="pos-relative w-100 h-100">
      <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
        <ErrorMessages errorMessages={errorMessages} />
        {systemSuccessMessages.length > 0 && errorMessages.length === 0 ? (
          <SuccessMessages successMessages={systemSuccessMessages} />
        ) : null}

        <div className="tabs-container" ref={toPrintRef}>
          <div className="tab-header">
            <h1 className="tab-heading float-left">
              {Constants.BULK_TRANFER_HEADER}
            </h1>
            <div className="float-right mt-2">
              <Button className='btn btn-success ml-1' onClick={() => saveBulkWriteOffReest()} data-testid="test_save_button">
                <i className="fa fa-check" aria-hidden="true"></i>
                                Save
              </Button>
              <Button title="Cancel" onClick={majorCancel} data-testid="test_cancel_button">Cancel</Button>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="tab-body">
            <form noValidate autoComplete="off">
              <div className="tab-holder">
                <div className="row mb-2">
                  <div className="col-6" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <fieldset className="custom-fieldset">
                      <legend>Write-Off:</legend>
                      <div className="form-wrapper">

                        <div className="mui-custom-form with-select override-width-45 override-m-3">
                          <TextField
                            id="payeeType-bulkTransfer"
                            label="Payee Type"
                            required
                            disabled
                            value={values.fromPayeeType}
                            onChange={handlePayeeValue('fromPayeeType')}
                            InputLabelProps={{
                              shrink: true
                            }}
                          >
                          </TextField>
                        </div>
                        <div className="mui-custom-form with-select override-width-45 override-m-3">
                          <TextField
                            id="payeeIDTypeCode-bulkTransfer"
                            label="Payee ID Type Code"
                            required
                            disabled
                            value={values.fromPayeeIDTypeCode}
                            onChange={handlePayeeValue('fromPayeeIDTypeCode')}
                            InputLabelProps={{
                              shrink: true
                            }}
                          >

                          </TextField>
                        </div>
                      </div>
                      <div className="form-wrapper">

                        <div className="mui-custom-form with-select override-width-45 override-m-3">
                          <TextField
                            id="payeeID-bulkTransfer"
                            label="Payee ID"
                            inputProps={{ maxLength: 15 }}
                            required
                            disabled
                            value={values.fromPayeeID}
                            onChange={handlePayeeValue('fromPayeeID')}
                            InputLabelProps={{
                              shrink: true
                            }}
                          >
                          </TextField>
                        </div>
                        <div className="mui-custom-form with-select override-width-45 override-m-3">
                          <TextField
                            id="payeeName-bulkTransfer"
                            label="Payee Name"
                            disabled
                            value={values.fromPayeeName}
                            onChange={handlePayeeValue('fromPayeeName')}
                            InputLabelProps={{
                              shrink: true
                            }}
                          >
                          </TextField>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-6" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <fieldset className="custom-fieldset">
                      <legend>Re-Establishment:</legend>
                      <div className="form-wrapper">

                        <NativeDropDown
                          className='override-width-45 override-m-3'
                          id="payeeType-bulkTransferto"
                            label="Payee Type"
                          isRequired
                            value={values.toPayeeType}
                            inputProps={{"data-testid": "test_to_payee_type"}}
                            onChange={handlePayeeValue('toPayeeType')}
                          errTxt={showError.toPayeeType ? showErrorMessage.toPayeeType : null}
                          dropdownList={payeeTypeDropdown.map((item, index) => (
                            <option key={index} value={item.code}>
                                {item.description}
                            </option>
                            ))}
                        />
                        <NativeDropDown
                          className='override-width-45 override-m-3'
                          id="payeeIDTypeCode-bulkTransferto"
                            label="Payee ID Type Code"
                          isRequired
                            value={values.toPayeeIDTypeCode || DEFAULT_DD_VALUE}
                            disabled={values.toPayeeType === DEFAULT_DD_VALUE}
                            inputProps={{"data-testid":"test_to_payee_id_type_code"}}
                            onChange={handlePayeeValue('toPayeeIDTypeCode')}
                          errTxt={showError.toPayeeIDTypeCode ? showErrorMessage.toPayeeIDTypeCode : null}
                          dropdownList={payeeIDTypeTODropdown.map((item, index) => (
                            <option key={index} value={item.code}>
                                {item.description}
                            </option>
                            ))}
                        />
                      </div>
                      <div className="form-wrapper">
                        <div className="mui-custom-form with-select override-width-45 override-m-3">
                          <TextField
                            id="payeeID-bulkTransferto"
                            label="Payee ID"
                            required
                            inputProps={{ maxLength: 15,"data-testid": "test_to_payee_id" }}
                            value={values.toPayeeID}
                            onChange={handlePayeeValue('toPayeeID')}
                            onBlur={validatePayee}
                            helperText={showError.toPayeeID ? showErrorMessage.toPayeeID : null}
                            error={showError.toPayeeID ? showErrorMessage.toPayeeID : null}
                            InputLabelProps={{
                              shrink: true
                            }}
                          >
                          </TextField>
                        </div>
                        <div className="mui-custom-form with-select override-width-45 override-m-3">
                          <TextField
                            id="payeeName-bulkTransferto"
                            disabled
                            inputProps={{"data-testid": "test_to_payee_name"}}
                            label="Payee Name"
                            value={values.toPayeeName}
                            onChange={handlePayeeValue('toPayeeName')}
                            InputLabelProps={{
                              shrink: true
                            }}
                          >
                          </TextField>
                        </div>
                      </div>
                      <div className="form-wrapper">
                        <div className="mui-custom-form with-select override-width-45 override-m-3">
                          <TextField
                            id="atrNumber"
                            label= {process.env.REACT_APP_TRANSACTION_NUMBER_LABEL}
                            required
                            inputProps={{ maxLength: 18, "data-testid":"test_atr_number" }}
                            value={values.atrNumber}
                            onChange={handlePayeeValue('atrNumber')}
                            helperText={showError.showATRError ? showErrorMessage.atrErrorMsg : null}
                            error={showError.showATRError ? showErrorMessage.atrErrorMsg : null}
                            InputLabelProps={{
                              shrink: true
                            }}
                          >
                          </TextField>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </form>
            <div className="tab-holder table-no-wrap">
              <TableComponent
                print={props.print}
                pathTo='/FinancialAccountGrossLevelUpdate?data='
                formatSearchCriteria={formatSearchCriteria}
                fixedTable
                fixedView
                headCells={headCells}
                isSearch={true}
                dropDownData={reasonCodeDropdown}
                handleDropDown={handleTableReasonCode}
                tableData={getTableData(tableData || [])}
                onTableRowClick={editRow}
                defaultSortColumn={headCells[0].id}
                setRsnCdData={setRsnCdData}
              />
            </div>
          </div>
          <Footer print />
          <Dialog
            open={dialogOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="custom-alert-box"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
                </>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setDialogOpen(false);
                }}
                color="primary"
                className="btn btn-transparent"
              >
                  STAY ON THIS PAGE!
              </Button>
              <Button
                onClick={cancelMinorSection}
                color="primary"
                className="btn btn-success"
                autoFocus
              >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <SystemPayeeTable
        openSysPayeeTable={openSysPayeeTable}
        handleCloseSysPayeeTable={handleCloseSysPayeeTable}
        uniqueKey={'sysID'}
        onTableCellButtonClick={onRadioButtonClick}
        tableData={systemPayeeTableData}
        currentSysID={currentSysID}
        onContinue={onContinue}
      />
    </div>
  );
}

export default withRouter(BulkWriteOffRest);
