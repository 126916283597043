/* eslint-disable no-unused-vars */
import { combineReducers } from 'redux';
import appConfigReducer from '../../ApplicationConfiguration/Store/Reducers/appConfigReducer';
import systemParameterReducer from '../../ApplicationConfiguration/Store/Reducers/systemParameter/systemParameterReducer';
import validValueReducer from '../../ApplicationConfiguration/Store/Reducers/validValue/validValueReducer';
import textManagementReducer from '../../ApplicationConfiguration/Store/Reducers/TextManagement/TextManagementReducers';
import mapSetReducer from '../../ApplicationConfiguration/Store/Reducers/mapSet/mapSetReducer';
import systemListReducer from '../../ApplicationConfiguration/Store/Reducers/systemList/systemListReducer';
import ProviderSpecialtyReducer from '../../ApplicationConfiguration/Store/Reducers/TextManagement/ProviderSpecialtyReducer';
import TextSearchReducer from '../../ApplicationConfiguration/Store/Reducers/TextManagement/TextSearchReducer';
import AppConfigCommonReducer from '../Store/Reducers/AppConfigCommon/appConfigCommonReducer';

export const appReducer = combineReducers({
  appConfigState: appConfigReducer,
  systemParameterState: systemParameterReducer,
  validValueState: validValueReducer,
  textManagementState: textManagementReducer,
  mapSetState: mapSetReducer,
  systemListState: systemListReducer,
  providerSpecialtyState: ProviderSpecialtyReducer,
  TextSearchState: TextSearchReducer,  
  AppConfigCommonState: AppConfigCommonReducer
});
