/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import TableComponent from "../../../../../../SharedModules/Table/Table";
import TextField from "@material-ui/core/TextField";
import numeral from "numeral";

export default function GrossLevelhistoryTable(props) {
  const headCells = [
    {
      id: "fcn/tcn",
      numeric: false,
      disablePadding: true,
      label: "FCN/TCN",
      enableHyperLink: true,
      isDate: false,
      width: 200,
      fontSize: 12,
      isLink: true
    },
    {
      id: "reasonCode",
      numeric: false,
      disablePadding: false,
      label: "Reason Code",
      enableHyperLink: true,
      isDate: false,
      width: 200,
      fontSize: 12
    },
    {
      id: "appliedDate",
      numeric: false,
      disablePadding: false,
      label: "Applied Date",
      enableHyperLink: false,
      isDate: true,
      width: 200,
      fontSize: 12
    },
    {
      id: "appliedAmount",
      numeric: false,
      disablePadding: false,
      label: "Applied Amount",
      enableHyperLink: false,
      isDate: true,
      width: 200,
      fontSize: 12
    }
  ];
  const onRowClick = row => event => {};

  const onLinkClick = row => event => {
    window.open("fcn", "_blank");
  };

  return (
    <div>
      <div className="form-wrapper">
        <div className="mui-custom-form input-md">
          <TextField
            id="GrossLevelHistorystandard-OriginalA/Ramount"
            label="Original A/R Amount"
            value={numeral(props.values.originalARAmountData).format("$0,0.00")}
            inputProps={{ maxlength: 15 }}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            disabled={true}
          ></TextField>
        </div>
        <div className="mui-custom-form input-md">
          <TextField
            id="GrossLevelHistorystandard-Appliedamount"
            label="Applied Amount"
            value={numeral(props.values.appliedAmountData).format("$0,0.00")}
            inputProps={{ maxlength: 15 }}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            disabled={true}
          ></TextField>
        </div>
        <div className="mui-custom-form input-md">
          <TextField
            id="GrossLevelHistorystandard-Balanceamount"
            label="Balance Amount"
            value={numeral(props.values.balanceAmountData).format("$0,0.00")}
            inputProps={{ maxlength: 15 }}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            disabled={true}
          ></TextField>
        </div>
      </div>
      <div className="tab-holder">
        <TableComponent
          print={props.print}
          headCells={headCells}
          tableData={[]}
          onTableRowClick={onRowClick}
          onLinkClick={onLinkClick}
        />
      </div>
    </div>
  );
}
