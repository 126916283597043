/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../../Actions/validValue/validValueActionTypes';

const axiosPayLoad = {
  payload: null
};
const reducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.VALID_VALUE_ACTION_TYPE:
      return { ...state, validValue: action.validValue };
    case actionTypes.RESETDATA:
      return action.resetData;
    case actionTypes.VALID_VALUE_VIEW_ACTION_TYPE:
      return { ...state, validValueView: action.validValueView };
    case actionTypes.FUNCTIONAL_AREA_DROPDOWN:
      return { functionalDropDown: action.functionalAreaDropDown };
    case actionTypes.VALID_VALUE_UPDATE_ACTION_TYPE:
      return { validValueUpdate: action.validValueUpdate };
    case actionTypes.AUDIT_LOG_TABLE:
      return { ...state, auditData: action.payload };
    case actionTypes.AUDIT_LOG_TABLE_ROW:
      return { ...state, auditRowData: action.payload };
    default: return state;
  }
};

export default reducer;
