/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../../Actions/FinancialInquiry/FinancialInquiryActionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SEARCH_FINANCIAL_INQUIRY:
      return { searchRecord: action.searchRecord };
    case actionTypes.DROPDOWNDATA:
      return { dropdownData: action.dropdownData };
    case actionTypes.GET_PAYEEIDTYPECODE_ON_PAYEETYPE:
      return { payeeIDTypeCodeDetails: action.payeeIDTypeCodeDetails };
    case actionTypes.FINANCIAL_CLAIM_SUMMARY_DETAILS:
      return { summaryDetails: action.summaryDetails }
    case actionTypes.RESETDATA:
      return action.resetData;
    default: return state;
  }
};

export default reducer;
