/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from "react";
import "date-fns";
import TableComponent from "../../../../SharedModules/Table/Table";

const headCells = [
  {
    id: "beginDate",
    numeric: false,
    disablePadding: true,
    label: "Begin Date",
    isDate: true,
    width: "15%",
    fontsize: 12
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: false,
    label: "End Date",
    isDate: true,
    width: "15%",
    fontsize: 12
  },
  {
    id: "federalPercentage",
    numeric: false,
    disablePadding: false,
    label: "Federal",
    isPercent: true,
    width: "15%",
    fontsize: 12
  },
  {
    id: "statePercentage",
    numeric: false,
    disablePadding: false,
    label: "State",
    isPercent: true,
    width: "15%",
    fontsize: 12
  },
  {
    id: "countyPercentage",
    numeric: false,
    disablePadding: false,
    label: "County",
    isPercent: true,
    width: "15%",
    fontsize: 12
  },
  {
    id: "otherPercentage",
    numeric: false,
    disablePadding: false,
    label: "Other",
    isPercent: true,
    width: "10%",
    fontsize: 12
  },
  {
    id: "fundSourceVoidDate",
    numeric: false,
    disablePadding: false,
    label: "Void Date",
    isDate: true,
    width: "15%",
    fontsize: 12
  }
];

function BudgetFundingSourceTable(props) {  
  const fundingSourceTableData = props.tableData ? props.tableData : [];

  return (
    <TableComponent
      print={props.print}
      fixedTable
      headCells={headCells}
      tableData={fundingSourceTableData}
      onTableRowDelete={props.rowDeleteFSource}
      onTableRowClick={props.onRowClick}
      isSearch={false}
      defaultSortColumn="endDate"
      sortOrder="desc"      
    />
  );
}
export default BudgetFundingSourceTable;
