/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import TableComponent from "../../../../../../SharedModules/Table/Table";
import TextField from "@material-ui/core/TextField";
import numeral from "numeral";
import { determineAmountToUse, parseAmount } from "../../../../../../SharedModules/CommonUtilities/commonUtilities";

export default function GrossLevelhistoryTable (props) {
  const headCells = [
    {
      id: "receivableFCN",
      numeric: false,
      disablePadding: false,
      label: "FCN/TCN",
      isDate: false,
      width: 120,
      fontSize: 13,
      isRedirectable: true,
      openInNewTab: true
    },
    {
      id: "reasonCode",
      numeric: false,
      disablePadding: false,
      label: "Reason Code",
      enableHyperLink: false,
      isDate: false,
      width: 120,
      fontSize: 13,
      isTooltip: true,
      isTitle: 'reasonCodeLongDesc'
    },
    {
      id: "appliedDateStr",
      numeric: false,
      disablePadding: false,
      label: "Applied Date",
      enableHyperLink: false,
      isDate: true,
      width: 120,
      fontSize: 13
    },
    {
      id: "appliedAmount",
      numeric: false,
      disablePadding: false,
      label: "Applied Amount",
      enableHyperLink: false,
      isDate: false,
      isBalance: true,
      width: 120,
      fontSize: 13
    }
  ];
  const rowClick = row => event => { };
  const adjustedAmount = parseAmount(props.values.adjustedARAmount);
  const originalAmount = parseAmount(props.values.originalARAmount);
  const amountToUse = determineAmountToUse(adjustedAmount, originalAmount);

  return (
    <div>
      <div className="form-wrapper">
        <div className="mui-custom-form input-md">
          <TextField
            id="standard-GrossHistoryOriginalA/R"
            label="Original A/R Amount"
            value={numeral(amountToUse).format("$0,0.00")}
            inputProps={{ maxlength: 15 }}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ readOnly: true, className: "Mui-disabled" }}
          ></TextField>
        </div>
        <div className="mui-custom-form input-md">
          <TextField
            id="standard-GrossHistoryAppliedAmount"
            label="Applied Amount"
            value={numeral(props.values.appliedAmountData).format("$0,0.00")}
            inputProps={{ maxlength: 15 }}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ readOnly: true, className: "Mui-disabled" }}
          ></TextField>
        </div>
        <div className="mui-custom-form input-md">
          <TextField
            id="standard-GrossHistoryBalanceAmount"
            label="Balance Amount"
            value={numeral(props.values.balanceAmountData).format("$0,0.00")}
            inputProps={{ maxlength: 15 }}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ readOnly: true, className: "Mui-disabled" }}
          ></TextField>
        </div>
      </div>
      <div className="tab-holder">
        <TableComponent
          print={props.print}
          align={"left"}
          headCells={headCells}
          tableData={props.historyPayload}
          isSearch={true}
          onTableRowClick={rowClick}
        />
      </div>
    </div>
  );
}
