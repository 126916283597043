import * as actionTypes from "./actionTypes";

const axiosPayLoad = {
  payload: null,
  addResponse: null,
  updateResponse: null,
  deleteResponse: null,
  batchControlData: null,
  urContraData: null,
  payloadDetails: null,
  sysListDetail: null,
  auditLog: {},
  pageLevelAuditLog: null,
  codeIndiAuditLog: null,
  type: "Add",
};
const GlobalAuditSearchReducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.RESETDATA:
      return axiosPayLoad;

    case actionTypes.GLOBAL_AUDIT_SEARCH_TYPE:
      return { ...state, auditSearchData: action.auditSearchData, fieldValues: action.fieldValues };
    case actionTypes.AUDIT_LOG: {
      return {
        ...state,
        auditLog: { ...state.auditLog, [action.auditType]: action.response },
      };
    }
    case actionTypes.RESET_AUDIT_DATA: {
      return { ...state, auditLog: {} };
    }

    default:
      return state;
  }
};

export default GlobalAuditSearchReducer;
