/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useSelector, useEffect } from 'react';
import ChildTableComponent from './ChildTableComponent';
import { withRouter } from 'react-router';

function ClaimSummary(props) {
const [claimSummaryFlag,setClaimSummaryFlag] = React.useState(false);
  const ClaimsHeadCells = [
    { id: 'categoryCode', disablePadding: false, label: 'Status', numeric: false, width: 80 },
    { id: 'numberOfClaims', disablePadding: false, label: 'No of Claims', numeric: false, width: 80 },
    { id: 'totalClaimAmount', disablePadding: false, label: 'Amount', numeric: true, isBalance: true, width: 80 }
  ];

  const TransactionHeadCells = [
    { id: 'categoryCode', disablePadding: false, label: 'Financial activity', numeric: false, width: 80 },
    { id: 'numberOfClaims', disablePadding: false, label: 'No of Transactions', numeric: false, width: 100 },
    { id: 'totalClaimAmount', disablePadding: false, label: 'Amount', numeric: true, isBalance: true, width: 80 }
  ];
  
    useEffect(()=>{
    setClaimSummaryFlag(true);
    },[]);

  return (
    <div className="pt-2">
      <div className="row">
        <div className="col-6" style={{ marginTop: '10px' }}>
          <fieldset className="custom-fieldset ">
            <legend>Cycle-to-Date</legend>
            <div>
              <h4 className="sub-header">Claims</h4>
              <ChildTableComponent claimSummaryFlag ={claimSummaryFlag} tableData={props.summaryDetails ? props.summaryDetails.cycleToDateList : []} headCells={ClaimsHeadCells} />
            </div>
            <h4 className="sub-header" style={{ marginTop: '10px' }}>Financial Transactions</h4>
            <div>
              <ChildTableComponent claimSummaryFlag ={claimSummaryFlag} tableData={props.summaryDetails ? props.summaryDetails.cycleToDateSubList : []} headCells={TransactionHeadCells} />
            </div>
          </fieldset>
        </div>
        <div className="col-6" style={{ marginTop: '10px' }}>
          <fieldset className="custom-fieldset ">
            <legend>Month-to-Date</legend>
            <div>
              <h4 className="sub-header">Claims</h4>
              <ChildTableComponent claimSummaryFlag ={claimSummaryFlag} tableData={props.summaryDetails ? props.summaryDetails.monthToDateList : []} headCells={ClaimsHeadCells} />
            </div>
            <h4 style={{ marginTop: '45px' }} className="sub-header"> Financial Transactions</h4>
            <div>
              <ChildTableComponent claimSummaryFlag ={claimSummaryFlag} tableData={props.summaryDetails ? props.summaryDetails.monthToDateSubList : []} headCells={TransactionHeadCells} />
            </div>
          </fieldset>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <fieldset className="custom-fieldset ">
            <legend>Year-to-Date</legend>
            <div>
              <h4 className="sub-header">Claims</h4>
              <ChildTableComponent claimSummaryFlag ={claimSummaryFlag}  tableData={props.summaryDetails ? props.summaryDetails.yearToDateList : []} headCells={ClaimsHeadCells} />
            </div>

            <h4 className="sub-header" style={{ marginTop: '17px' }}>Financial Transactions</h4>
            <div>
              <ChildTableComponent claimSummaryFlag ={claimSummaryFlag} tableData={props.summaryDetails ? props.summaryDetails.yearToDateSubList : []} headCells={TransactionHeadCells} />
            </div>
          </fieldset>
        </div>
        <div className="col-6">
          <fieldset className="custom-fieldset ">
            <legend>State-Fiscal-YTD</legend>
            <div>
              <h4 className="sub-header">Claims</h4>
            </div>
            <ChildTableComponent claimSummaryFlag ={claimSummaryFlag} tableData={props.summaryDetails ? props.summaryDetails.stateFiscalYtdList : []} headCells={ClaimsHeadCells} />
            <h4 className="sub-header" style={{ marginTop: '23%' }}>Financial Transactions</h4>
            <div>
              <ChildTableComponent claimSummaryFlag ={claimSummaryFlag} tableData={props.summaryDetails ? props.summaryDetails.stateFiscalYtdSubList : []} headCells={TransactionHeadCells} />
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
}
export default withRouter((ClaimSummary));
