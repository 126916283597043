/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../../Actions/MapSet/mapSetActionTypes';

const axiosPayLoad = {
  payload: null,
  lobDropDown: []
};
const reducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.MAP_SET_ACTION_TYPE:
      return { ...state, payload: action.mapSet };
    case actionTypes.RESET_PAYLOAD_DATA:
      return { ...state, payload: null };
    case actionTypes.MAP_SET_VIEW_ACTION_TYPE:
      return { ...state, mapSetView: action.mapSetView };
    case actionTypes.MAP_DEFINITION_ADD_TYPE:
      return { ...state, addResponse: action.addResult };
    case actionTypes.MAP_DEFINITION_UPDATE_TYPE:
      return { ...state, updateResponse: action.updateResult };
    case actionTypes.SEARCH_SYSTEM_LIST:
      return { systemLists: action.systemLists };
    case actionTypes.RESETDATA:
      return action.resetData;
    case actionTypes.RESET_ADD_DATA:
      return { ...state, addResponse: null };
    case actionTypes.AUDIT_LOG_TABLE:
      return { ...state, auditData: action.payload };
    case actionTypes.AUDIT_LOG_TABLE_ROW:
      return { ...state, auditRowData: action.payload };
    default: return state;
  }
};

export default reducer;
