/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './validValueActionTypes';
import * as serviceEndPoint from '../../../services/service';
import { commonAPIHandler } from '../../../../../SharedModules/ServerErrorHandler';
import axios from 'axios';

export const getAuditLogDetailsAction = (tableName, keyValue, auditData) => {
  let body = {
    "tableName": tableName,
    "keyValue": keyValue
  }
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}

export const setAuditLogData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE,
    payload: data,
    keyName
  };
};

export const getAuditLogRowDetailsAction = (tableName, keyValue, auditData) => {
  let body = {
    "tableName": tableName,
    "keyValue": keyValue
  }
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogRowData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}

export const setAuditLogRowData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE_ROW,
    payload: data,
    keyName
  };
};
export const resetSearchValidValue = () => {
  return {
    type: actionTypes.RESETDATA,
    resetData: []
  };
};

export const ValidValueUpdateAction = (values) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.VALID_VALUE_UPDATE_ENDPOINT}`, values)

      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setValidValueUpdate(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setValidValueUpdate(sendingResponse));
      });
  };
};

export const validValueAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.VALID_VALUE_SEARCH_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(setValidValue(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setValidValue(sendingResponse));
      });
  };
};

export const validValueViewAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.VALID_VALUE_SEARCH_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(setValidValue(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setValidValue(sendingResponse));
      });
  };
};

export const setValidValueUpdate = (validValueUpdate) => {
  return {
    type: actionTypes.VALID_VALUE_UPDATE_ACTION_TYPE,
    validValueUpdate: validValueUpdate
  };
};
export const setValidValue = (validValue) => {
  return {
    type: actionTypes.VALID_VALUE_ACTION_TYPE,
    validValue: validValue
  };
};

export const setValidValueView = (validValueView) => {
  return {
    type: actionTypes.VALID_VALUE_VIEW_ACTION_TYPE,
    validValueView: validValueView
  };
};
