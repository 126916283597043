import * as actionTypes from "../../../SharedModules/Dropdowns/actionTypes";

const axiosPayLoad = {
  payload: null,
  searchFields: null,
  medicareDetails: null,
  type: "Add",
  auditLog: {},
  pageLevelAuditLog: null,
  codeIndiAuditLog: null,
  updateData: null,
  createData: null,
  policyDetailData: null,
  policyType: "Add",
  validateCarrierId: null,
  saveData: null,
  saveTime: new Date,
};
const reducer = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case actionTypes.RESETDATA:
      return axiosPayLoad;

      case actionTypes.EVENT_LOG_INQUIRY:
      return { ...state, logInquiryData: action.logInquiryData };
      case actionTypes.EVENT_LOG_EVENT:
      return { ...state, logEventData: action.logEventData };    
      default:
      return state;
  }
};
export default reducer;
