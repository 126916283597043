/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessages from "../../../SharedModules/Errors/ErrorMessages";
import SuccessMessages from '../../../SharedModules/Errors/SuccessMessage';
import { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import moment from 'moment';
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as FRCConstants from './FRCConstants';
import { getUTCTimeStamp, compareTwoDatesGreaterThanOrEqual } from '../../../SharedModules/DateUtilities/DateUtilities';
import { getSubDropdownDataAction, createFRCAction, updateFRCAction, searchFRCAction, ResetFRC } from '../Store/Actions';
import reasonCodeCriteria from '../ReasonCode.json';
import NoSaveMessage from '../../../SharedModules/Errors/NoSaveMessage';
import { Prompt } from 'react-router-dom';

function FRCAdd (props) {
    const dispatch = useDispatch();
    const toPrintRef = useRef();
    const [errorMessages, seterrorMessages] = useState([]);
    const [allowNavigation, setAllowNavigation] = React.useState(false);

    const [FRCState, setFRCState] = useState({
        MARExclInd: false,
        ClaimInclInd: false,
        HistoryInd: false,
        CMS64SumLine: "",
        TPLCollectionRsnCd: "",
        PriorityNum: '',
        FinancialTransInd: false,
        FinanceReasonCd: 'Please Select One',
        FinanceReasonCdDescr: '',
        FinanceReasonCdGroup: '',
        FinanceReasonCdTy: '',
        BegDt: null,
        EndDt: null,
        Comment: '',
        financeRsnCodeSeq: null
    });

    const [ReasonCodeData, setReasonCodeData] = useState([]);
    const [searchObj, setSearchObj] = useState({});
    const [showError, setShowError] = useState({
        CMS64SumLine: false,
        TPLCollectionRsnCd: false,
        PriorityNum: false,
        FinanceReasonCd: false,
        FinanceReasonCdDescr: false,
        FinanceReasonCdGroup: false,
        FinanceReasonCdTy: false,
        BegDt: false,
        EndDt: false
    });
    const [showErrorMessage, setShowErrorMessage] = useState({
        CMS64SumLine: '',
        TPLCollectionRsnCd: '',
        PriorityNum: '',
        FinanceReasonCd: '',
        FinanceReasonCdDescr: '',
        FinanceReasonCdGroup: '',
        FinanceReasonCdTy: '',
        BegDt: '',
        EndDt: ''
    });
    const [successMessages, setSuccessMessages] = useState([]);
    const [editPage, setEditPage] = useState(false);
    const createFRC = (values) => dispatch(createFRCAction(values));
    const updateFRC = (values) => dispatch(updateFRCAction(values));
    let createSuccess = useSelector(state => state.frcState.createPayload);
    let updateSuccess = useSelector(state => state.frcState.updatePayload);
    const searchFRC = (values) => dispatch(searchFRCAction(values));
    let searchSuccess = useSelector(state => state.frcState.searchPayload);
    const [selectedBeginDate, setSelectedBeginDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const handleBeginDate = date => {
        setAllowNavigation(true);
        setSelectedBeginDate(date);
    };
    const handleEndDate = date => {
        setAllowNavigation(true);
        setSelectedEndDate(date);
    };
    // Reason Code Dropdown
    const getReasonCodeMapData = (dropdownValues) => dispatch(getSubDropdownDataAction(dropdownValues));
    const reasonCodeMapdropdown = useSelector(state => state.frcState.subdropdownData);
    React.useEffect(() => {
        ResetFRC('createPayload');
        ResetFRC('searchPayload');
        ResetFRC('updatePayload');
        searchSuccess = null;
        createSuccess = null;
        updateSuccess = null;
        getReasonCodeMapData(reasonCodeCriteria);
    }, []);
    React.useEffect(() => {
        if (reasonCodeMapdropdown) {
            if (reasonCodeMapdropdown.listObj && reasonCodeMapdropdown.listObj['Financial#F_RSN_CD']) {
                const reasonCodeList = JSON.parse(JSON.stringify(reasonCodeMapdropdown.listObj['Financial#F_RSN_CD'].sort((a, b) => a.code.toLocaleUpperCase() < b.code.toLocaleUpperCase() ? -1 : 1)));
                setReasonCodeData(reasonCodeList);
            }
        }
    }, [reasonCodeMapdropdown]);
    useEffect(() => {
        if (searchSuccess && !searchSuccess.systemFailure && searchSuccess.length === 1) {
            const searchData = searchSuccess;
            const successMessagesArray = [];
            successMessagesArray.push('System successfully saved the information.');
            props.history.push({
                pathname: '/FRCUpdate',
                state: { searchData: searchData, successMessages: successMessagesArray, search: false }
            });
        } else if (searchSuccess && searchSuccess.systemFailure) {
            const ErrorMessages = [];
            ErrorMessages.push(FRCConstants.GENERIC_SYSTEM_ERROR);
            seterrorMessages(ErrorMessages);
        }
    }, [searchSuccess]);
    const successMessagesArray = [];
    useEffect(() => {
        if (createSuccess && createSuccess.respcode === '01') {
            successMessagesArray.push(createSuccess.respdesc);
            setSuccessMessages(successMessagesArray);
            const searchFRCOb = {
                priorityNum: FRCState.PriorityNum,
                financeReasonCd: FRCState.FinanceReasonCd,
                financeReasonCdDescr: FRCState.FinanceReasonCdDescr,
                financeReasonCdGroup: FRCState.FinanceReasonCdGroup,
                FinanceReasonCdTy: FRCState.FinanceReasonCdTy
            };
            searchFRC(searchFRCOb);
        } else if (createSuccess && createSuccess.systemFailure) {
            const ErrorMessages = [];
            ErrorMessages.push(FRCConstants.GENERIC_SYSTEM_ERROR);
            seterrorMessages(ErrorMessages);
        }
    }, [createSuccess]);
    useEffect(() => {
        if (updateSuccess && updateSuccess.respcode === '01') {
            successMessagesArray.push(updateSuccess.respdesc);
            setSuccessMessages(successMessagesArray);
            const searchFRCOb = {
                financeRsnCodeSeq: FRCState.financeRsnCodeSeq
            };
            searchFRC(searchFRCOb);
        } else if (updateSuccess && updateSuccess.systemFailure) {
            const ErrorMessages = [];
            ErrorMessages.push(FRCConstants.GENERIC_SYSTEM_ERROR);
            seterrorMessages(ErrorMessages);
        }
    }, [updateSuccess]);
    let searchObject = {};
    useEffect(() => {
        if (props.history.location.state && props.history.location.state.searchData) {
            setEditPage(true);
            searchObject = props.history.location.state.searchData[0];
            setSearchObj(searchObject);
            setFRCState({
                MARExclInd: searchObject.marExclInd !== 'N',
                ClaimInclInd: searchObject.claimInclInd !== 'N',
                HistoryInd: searchObject.historyInd !== 'N',
                CMS64SumLine: searchObject.cms64SumLine ? searchObject.cms64SumLine : "",
                TPLCollectionRsnCd: searchObject.tplCollectionRsnCd ? searchObject.tplCollectionRsnCd : "",
                PriorityNum: searchObject.priorityNum ? searchObject.priorityNum : "",
                FinancialTransInd: searchObject.financialTransInd !== 'N',
                FinanceReasonCd: searchObject.financeReasonCd ? searchObject.financeReasonCd : 'Please Select One',
                FinanceReasonCdDescr: searchObject.financeReasonCdDescr ? searchObject.financeReasonCdDescr : '',
                FinanceReasonCdGroup: searchObject.financeReasonCdGroup ? searchObject.financeReasonCdGroup : '',
                FinanceReasonCdTy: searchObject.financeReasonCdTy ? searchObject.financeReasonCdTy : '',
                Comment: searchObject.comment ? searchObject.comment : '',
                financeRsnCodeSeq: searchObject.financeRsnCodeSeq
            });
            setSuccessMessages(props.history.location.state.successMessages);
            setSelectedBeginDate(searchObject.begDt);
            setSelectedEndDate(searchObject.endDt);
        }
        if (props.history.location.state && props.history.location.state.successMessages && updateSuccess && !updateSuccess.systemFailure) {
            setSuccessMessages(props.history.location.state.successMessages);
        }
        if (props.history.location.state && props.history.location.state.successMessages && createSuccess && !createSuccess.systemFailure) {
            setSuccessMessages(props.history.location.state.successMessages);
        }
    }, [props.history.location.state]);

    const handleChange = name => event => {
        setAllowNavigation(true);
        if (name === 'MARExclInd') {
            setFRCState({ ...FRCState, MARExclInd: !FRCState.MARExclInd });
        } else if (name === 'ClaimInclInd') {
            setFRCState({ ...FRCState, ClaimInclInd: !FRCState.ClaimInclInd });
        } else if (name === 'HistoryInd') {
            setFRCState({ ...FRCState, HistoryInd: !FRCState.HistoryInd });
        } else if (name === 'FinancialTransInd') {
            setFRCState({ ...FRCState, FinancialTransInd: !FRCState.FinancialTransInd });
        } else {
            setFRCState({
                ...FRCState,
                [name]: event.target.value
            });
        }
    };

    const logInUserID = useSelector(state => state.sharedState.logInUserID);

    const saveFrc = () => {
        setSuccessMessages([]);
        seterrorMessages([]);
        let CMS64SumLineErr = false;
        const TPLCollectionRsnCdErr = false;
        let PriorityNumErr = false;
        let FinanceReasonCdErr = false;
        let FinanceReasonCdDescrErr = false;
        let FinanceReasonCdGroupErr = false;
        let FinanceReasonCdTyErr = false;
        let BegDtErr = false;
        let EndDtErr = false;
        let CMS64SumLineErrMsg = '';
        const TPLCollectionRsnCdErrMsg = '';
        let PriorityNumErrMsg = '';
        let FinanceReasonCdErrMsg = '';
        let FinanceReasonCdDescrErrMsg = '';
        let FinanceReasonCdGroupErrMsg = '';
        let FinanceReasonCdTyErrMsg = '';
        let BegDtErrMsg = '';
        let EndDtErrMsg = '';
        const errorMessages = [];
        if (FRCState.FinanceReasonCd === 'Please Select One') {
            FinanceReasonCdErr = true;
            FinanceReasonCdErrMsg = FRCConstants.FINANCE_RC_REQ;
            errorMessages.push(FinanceReasonCdErrMsg);
        }
        if (FRCState.PriorityNum === '') {
            PriorityNumErr = true;
            PriorityNumErrMsg = FRCConstants.PN_REQ;
            errorMessages.push(PriorityNumErrMsg);
        }
        if (FRCState.FinanceReasonCdDescr === '' || !FRCState.FinanceReasonCdDescr) {
            FinanceReasonCdDescrErr = true;
            FinanceReasonCdDescrErrMsg = FRCConstants.FRC_DESC_REQ;
            errorMessages.push(FinanceReasonCdDescrErrMsg);
        }
        if (FRCState.FinanceReasonCdTyGroup === '' || !FRCState.FinanceReasonCdGroup) {
            FinanceReasonCdGroupErr = true;
            FinanceReasonCdGroupErrMsg = FRCConstants.FRC_GROUP_REQ;
            errorMessages.push(FinanceReasonCdGroupErrMsg);
        }
        if (FRCState.CMS64SumLine && isNaN(FRCState.CMS64SumLine)) {
            CMS64SumLineErr = true;
            CMS64SumLineErrMsg = FRCConstants.INVALID_FORMAT;
            errorMessages.push(CMS64SumLineErrMsg);
        }
        if (FRCState.FinanceReasonCdTy === '' || !FRCState.FinanceReasonCdTy) {
            FinanceReasonCdTyErr = true;
            FinanceReasonCdTyErrMsg = FRCConstants.FRC_CD_TY_REQ;
            errorMessages.push(FinanceReasonCdTyErrMsg);
        }
        if (!selectedBeginDate) {
            BegDtErr = true;
            BegDtErrMsg = FRCConstants.BEGIN_DT_REQ;
            errorMessages.push(BegDtErrMsg);
        }
        if (!selectedEndDate) {
            EndDtErr = true;
            EndDtErrMsg = FRCConstants.END_DT_REQ;
            errorMessages.push(EndDtErrMsg);
        }
        if (selectedBeginDate && selectedBeginDate.toString() === 'Invalid Date') {
            BegDtErr = true;
            BegDtErrMsg = FRCConstants.INVALID_DATE;
            errorMessages.push(BegDtErrMsg);
        }
        if (selectedEndDate && selectedEndDate.toString() === 'Invalid Date') {
            EndDtErr = true;
            EndDtErrMsg = FRCConstants.INVALID_DATE;
            errorMessages.push(EndDtErrMsg);
        }
        if (selectedBeginDate && selectedEndDate && !BegDtErr && !EndDtErr &&
            compareTwoDatesGreaterThanOrEqual(selectedEndDate, selectedBeginDate)) {
            EndDtErr = true;
            EndDtErrMsg = FRCConstants.END_DATE_GREATER_THAN_BG_DT;
            errorMessages.push(EndDtErrMsg);
        }
        seterrorMessages(errorMessages);
        setShowError({
            CMS64SumLine: CMS64SumLineErr,
            TPLCollectionRsnCd: TPLCollectionRsnCdErr,
            PriorityNum: PriorityNumErr,
            FinanceReasonCd: FinanceReasonCdErr,
            FinanceReasonCdDescr: FinanceReasonCdDescrErr,
            FinanceReasonCdGroup: FinanceReasonCdGroupErr,
            FinanceReasonCdTy: FinanceReasonCdTyErr,
            BegDt: BegDtErr,
            EndDt: EndDtErr
        });
        setShowErrorMessage({
            CMS64SumLine: CMS64SumLineErrMsg,
            TPLCollectionRsnCd: TPLCollectionRsnCdErrMsg,
            PriorityNum: PriorityNumErrMsg,
            FinanceReasonCd: FinanceReasonCdErrMsg,
            FinanceReasonCdDescr: FinanceReasonCdDescrErrMsg,
            FinanceReasonCdGroup: FinanceReasonCdGroupErrMsg,
            FinanceReasonCdTy: FinanceReasonCdTyErrMsg,
            BegDt: BegDtErrMsg,
            EndDt: EndDtErrMsg
        });
        if (!allowNavigation && editPage) {
            NoSaveMessage();
        } else {
            if (errorMessages.length === 0) {
                setAllowNavigation(false);
                if (!editPage) {
                    const FRCAddObj = {
                        auditUserID: logInUserID,
                        auditTimeStamp: getUTCTimeStamp(),
                        addedAuditUserID: logInUserID,
                        addedAuditTimeStamp: getUTCTimeStamp(),
                        versionNo: 0,
                        dbRecord: false,
                        sortColumn: null,
                        auditKeyList: [],
                        auditKeyListFiltered: false,
                        marExclInd: FRCState.MARExclInd === true ? 'Y' : 'N',
                        claimInclInd: FRCState.ClaimInclInd === true ? 'Y' : 'N',
                        cms64SumLine: FRCState.CMS64SumLine !== '' ? (FRCState.CMS64SumLine) : null,
                        tplCollectionRsnCd: FRCState.TPLCollectionRsnCd !== '' ? FRCState.TPLCollectionRsnCd : null,
                        historyInd: FRCState.HistoryInd === true ? 'Y' : 'N',
                        priorityNum: FRCState.PriorityNum,
                        financialTransInd: FRCState.FinancialTransInd === true ? 'Y' : 'N',
                        financeReasonCd: FRCState.FinanceReasonCd,
                        financeReasonCdDescr: FRCState.FinanceReasonCdDescr,
                        financeReasonCdGroup: FRCState.FinanceReasonCdGroup,
                        financeReasonCdTy: FRCState.FinanceReasonCdTy,
                        begDt: moment(selectedBeginDate).format('MM/DD/YYYY'),
                        endDt: moment(selectedEndDate).format('MM/DD/YYYY'),
                        comment: FRCState.Comment !== '' ? FRCState.Comment : null,
                        financeRsnCodeSeq: null
                    };
                    createFRC(FRCAddObj);
                } else if (editPage) {
                    const FRCUpdate =
                    {
                        auditUserID: logInUserID,
                        auditTimeStamp: getUTCTimeStamp(),
                        addedAuditUserID: logInUserID,
                        addedAuditTimeStamp: getUTCTimeStamp(),
                        versionNo: searchObj.versionNo,
                        dbRecord: false,
                        sortColumn: null,
                        auditKeyList: [],
                        auditKeyListFiltered: false,
                        marExclInd: FRCState.MARExclInd === true ? 'Y' : 'N',
                        claimInclInd: FRCState.ClaimInclInd === true ? 'Y' : 'N',
                        cms64SumLine: FRCState.CMS64SumLine !== '' ? FRCState.CMS64SumLine : null,
                        tplCollectionRsnCd: FRCState.TPLCollectionRsnCd !== '' ? FRCState.TPLCollectionRsnCd : null,
                        historyInd: FRCState.HistoryInd === true ? 'Y' : 'N',
                        priorityNum: FRCState.PriorityNum,
                        financialTransInd: FRCState.FinancialTransInd === true ? 'Y' : 'N',
                        financeReasonCd: FRCState.FinanceReasonCd,
                        financeReasonCdDescr: FRCState.FinanceReasonCdDescr,
                        financeReasonCdGroup: FRCState.FinanceReasonCdGroup,
                        financeReasonCdTy: FRCState.FinanceReasonCdTy,
                        begDt: moment(selectedBeginDate).format('MM/DD/YYYY'),
                        endDt: moment(selectedEndDate).format('MM/DD/YYYY'),
                        comment: FRCState.Comment !== '' ? FRCState.Comment : null,
                        financeRsnCodeSeq: searchObj.financeRsnCodeSeq
                    };
                    updateFRC(FRCUpdate);
                }
            }
        }
    };

    return (
        <div className="pos-relative w-100 h-100">

            <div className="tabs-container" ref={toPrintRef}>

                <Prompt
                    when={allowNavigation}
                    message={location => `Are you sure you want to go to ${location.pathname}`}
                />
                <ErrorMessages errorMessages={errorMessages} />
                {errorMessages.length === 0 ? <SuccessMessages successMessages={successMessages} /> : null}
                <div className="tab-header">
                    <h1 className="tab-heading float-left">{editPage ? 'Update FRC' : 'Add FRC'}</h1>

                    <div className="float-right mt-1 pt-1">
                        <Button
                            className="btn btn-success ml-1"
                            onClick={() => saveFrc()}
                        >
                            <i className="fa fa-check" aria-hidden="true"></i>
                            Save
            </Button>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <div className="tab-body">
                    <form noValidate autoComplete="off">
                        <div className="form-wrapper">
                            <div className="mui-custom-form input-md">
                                <TextField
                                    id="standard-AccountPayeeID"
                                    fullWidth
                                    label="CMS64 Sum Line"
                                    value={FRCState.CMS64SumLine}
                                    onChange={handleChange('CMS64SumLine')}
                                    inputProps={{ maxlength: 2 }}
                                    placeholder=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    helperText={
                                        showError.CMS64SumLine ? showErrorMessage.CMS64SumLine : null
                                    }
                                    error={
                                        showError.CMS64SumLine ? showErrorMessage.CMS64SumLine : null
                                    }
                                ></TextField>
                            </div>
                            <div className="mui-custom-form with-select input-md">
                                <TextField
                                    select
                                    fullWidth
                                    required
                                    id="standard-AccountActivityType"
                                    label="Finance Reason Code"
                                    value={FRCState.FinanceReasonCd}
                                    onChange={handleChange('FinanceReasonCd')}
                                    placeholder=""
                                    inputProps={{ maxlength: 3 }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    helperText={
                                        showError.FinanceReasonCd ? showErrorMessage.FinanceReasonCd : null
                                    }
                                    error={
                                        showError.FinanceReasonCd ? showErrorMessage.FinanceReasonCd : null
                                    }
                                >
                                    <MenuItem
                                        selected
                                        key="Please Select One"
                                        value="Please Select One"
                                    >
                                        Please Select One
                                    </MenuItem>

                                    {ReasonCodeData ? ReasonCodeData.map(option => (
                                        <MenuItem key={option.code} value={option.code}>
                                            {option.description}
                                        </MenuItem>
                                    )) : null}
                                </TextField>
                            </div>

                            <div
                                className="mui-custom-form-description" // style={{ marginLeft: '30px' }}
                            >
                                <TextField
                                    id="standard-businessName"
                                    fullWidth
                                    required
                                    label="Finance Reason Code Description"
                                    value={FRCState.FinanceReasonCdDescr}
                                    onChange={handleChange('FinanceReasonCdDescr')}
                                    placeholder=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{ maxLength: 100 }}
                                    helperText={
                                        showError.FinanceReasonCdDescr ? showErrorMessage.FinanceReasonCdDescr : null
                                    }
                                    error={
                                        showError.FinanceReasonCdDescr ? showErrorMessage.FinanceReasonCdDescr : null
                                    }
                                />
                            </div>

                        </div>

                        <div className="form-wrapper">
                            <div className="mui-custom-form-description">
                                <TextField
                                    id="standard-FinanceReasonCdGroup"
                                    fullWidth
                                    required
                                    label="Finance Reason Code Group"
                                    value={FRCState.FinanceReasonCdGroup}
                                    onChange={handleChange('FinanceReasonCdGroup')}
                                    inputProps={{ maxlength: 100 }}
                                    placeholder=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    helperText={
                                        showError.FinanceReasonCdGroup ? showErrorMessage.FinanceReasonCdGroup : null
                                    }
                                    error={
                                        showError.FinanceReasonCdGroup ? showErrorMessage.FinanceReasonCdGroup : null
                                    }
                                ></TextField>
                            </div>
                            <div className="mui-custom-form-description">
                                <TextField
                                    id="standard-FinanceReasonCdTy"
                                    fullWidth
                                    required
                                    label="Finance Reason Code Type"
                                    value={FRCState.FinanceReasonCdTy}
                                    onChange={handleChange('FinanceReasonCdTy')}
                                    inputProps={{ maxlength: 100 }}
                                    placeholder=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    helperText={
                                        showError.FinanceReasonCdTy ? showErrorMessage.FinanceReasonCdTy : null
                                    }
                                    error={
                                        showError.FinanceReasonCdTy ? showErrorMessage.FinanceReasonCdTy : null
                                    }
                                ></TextField>
                            </div>
                        </div>
                        <div className="form-wrapper">
                            <div className="mui-custom-form input-md">
                                <TextField
                                    id="standard-AccountPayeeID"
                                    fullWidth
                                    required
                                    label="Priority Number"
                                    value={FRCState.PriorityNum}
                                    onChange={handleChange('PriorityNum')}
                                    inputProps={{ maxlength: 3 }}
                                    placeholder=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    helperText={
                                        showError.PriorityNum ? showErrorMessage.PriorityNum : null
                                    }
                                    error={
                                        showError.PriorityNum ? showErrorMessage.PriorityNum : null
                                    }
                                ></TextField>
                            </div>
                            <div className="mui-custom-form input-md">
                                <TextField
                                    id="standard-AccountPayeeID"
                                    fullWidth
                                    label="TPL Collection Reason Code"
                                    value={FRCState.TPLCollectionRsnCd}
                                    onChange={handleChange('TPLCollectionRsnCd')}
                                    inputProps={{ maxlength: 1 }}
                                    placeholder=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    helperText={
                                        showError.TPLCollectionRsnCd ? showErrorMessage.TPLCollectionRsnCd : null
                                    }
                                    error={
                                        showError.TPLCollectionRsnCd ? showErrorMessage.TPLCollectionRsnCd : null
                                    }
                                ></TextField>
                            </div>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="mui-custom-form with-date">
                                    <KeyboardDatePicker
                                        fullWidth
                                        required
                                        maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                                        id="GrossLevelDetailBegindate-picker-dialog"
                                        label="Begin Date"
                                        format="MM/dd/yyyy"
                                        placeholder="mm/dd/yyyy"
                                        minDate={"01/01/1964"}
                                        value={selectedBeginDate}
                                        onChange={handleBeginDate}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date"
                                        }}
                                        helperText={
                                            showError.BegDt ? showErrorMessage.BegDt : null
                                        }
                                        error={
                                            showError.BegDt ? showErrorMessage.BegDt : null
                                        }
                                    />
                                </div>
                                <div className="mui-custom-form with-date">
                                    <KeyboardDatePicker
                                        fullWidth
                                        required
                                        maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                                        id="GrossLevelDetailBegindate-picker-dialog"
                                        label="End Date"
                                        format="MM/dd/yyyy"
                                        placeholder="mm/dd/yyyy"
                                        minDate={"01/01/1964"}
                                        value={selectedEndDate}
                                        onChange={handleEndDate}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date"
                                        }}
                                        helperText={
                                            showError.EndDt ? showErrorMessage.EndDt : null
                                        }
                                        error={
                                            showError.EndDt ? showErrorMessage.EndDt : null
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="form-wrapper">
                            <div className="mui-custom-form sub-radio float-left no-margin-label margin-top-24">
                                <FormControlLabel
                                    value="balance"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={FRCState.MARExclInd}
                                            value={FRCState.MARExclInd}
                                            onChange={handleChange('MARExclInd')}
                                        />
                                    }
                                    label="MAR Exclude Indicator"
                                    labelPlacement="end"
                                />
                            </div>
                            <div className="mui-custom-form sub-radio float-left no-margin-label margin-top-24">
                                <FormControlLabel
                                    value="balance"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={FRCState.ClaimInclInd}
                                            value={FRCState.ClaimInclInd}
                                            onChange={handleChange('ClaimInclInd')}
                                        />
                                    }
                                    label="Claim Include Indicator"
                                    labelPlacement="end"
                                />
                            </div>
                            <div className="mui-custom-form sub-radio float-left no-margin-label margin-top-24">
                                <FormControlLabel
                                    value="balance"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={FRCState.HistoryInd}
                                            value={FRCState.HistoryInd}
                                            onChange={handleChange('HistoryInd')}
                                        />
                                    }
                                    label="History Indicator"
                                    labelPlacement="end"
                                />
                            </div>
                            <div className="mui-custom-form sub-radio float-left no-margin-label margin-top-24">
                                <FormControlLabel
                                    value="balance"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={FRCState.FinancialTransInd}
                                            value={FRCState.FinancialTransInd}
                                            onChange={handleChange('FinancialTransInd')}
                                        />
                                    }
                                    label="Financial Transaction Indicator"
                                    labelPlacement="end"
                                />
                            </div>
                        </div>
                        <div className='form-wrapper'>
                            <div className="mui-custom-form">
                                <label className='MuiFormLabel-root small-label no-margin' htmlFor="w3review">Comments</label>
                                <textarea id="w3review" name="w3review" rows="4" cols="100" value={FRCState.Comment} onChange={handleChange('Comment')} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default FRCAdd;
