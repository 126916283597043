/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from "@material-ui/core/TextField";
import { Button } from "react-bootstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import LockBoxTable from "./LockBoxTable";
import AddressTable from "./AddressTable";
import ContactTable from "./ContactsTable";
import * as ErrorConstants from "./ErrorConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  getDropdownDataAction,
  getDropdownTypeDataAction,
  resetBankAccountAndLockBox,
  addBankAccountAndLockBox,
  searchLockBoxDetailsAction
} from "../Store/Actions/BankAccountAndLockBoxSearchActions";
import * as DateUtilities from "../../../SharedModules/DateUtilities/DateUtilities";
import ErrorMessages from "../../../SharedModules/Errors/ErrorMessages";
import {
  verifyAddressAPI
} from "../../../SharedModules/store/Actions/SharedAction";
import * as AppConstants from "../../../SharedModules/AppConstants";

import { Prompt } from "react-router-dom";
import TabPanel from "../../../SharedModules/TabPanel/TabPanel";
import dropdownCriteria from "./BankAccountAndLockBoxAddEdit.json";
import dropdownTypeCriteria from "./AccountType.json";
import Notes from "../../../SharedModules/Notes/Notes";
import Footer from "../../../SharedModules/Layout/footer";
import { isArray } from "util";
import Radio from "@material-ui/core/Radio";
import { removeLeadingTrailingSpaces } from "../../../SharedModules/CommonUtilities/commonUtilities";
import NativeDropDown from "../../../SharedModules/Dropdowns/NativeDropDown";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    textAlign: "left"
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  }
}));

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default function BankAccountAndLockBoxAdd (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const toPrintRef = useRef();
  const dispatch = useDispatch();
  const [useEffectValues] = React.useState([]);
  const onReset = () => dispatch(resetBankAccountAndLockBox());
  const dropDownDispatch = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));
  const dropDownTypeDispatch = dropdownValues =>
    dispatch(getDropdownTypeDataAction(dropdownValues));
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [focusCheck, setFocusCheck] = React.useState(false);

  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [addressTypeList, setaddressTypeList] = React.useState([]);
  const [significanceTypeList, setsignificanceTypeList] = React.useState([]);
  const [addStatusList, setaddStatusList] = React.useState([]);
  const [addStateList, setaddStateList] = React.useState([]);
  const [addCountryList, setaddCountryList] = React.useState([]);
  const [accountTypeList, setaccountTypeList] = React.useState([]);
  const [changeReasonList, setchangeReasonList] = React.useState([]);
  const [redirect, setRedirect] = React.useState(0);
  const [contactPrefixList, setcontactPrefixList] = React.useState([]);
  const [countyList, setcountyList] = React.useState([]);
  const [contactSuffixList, setcontactSuffixList] = React.useState([]);
  const [contactStatusList, setcontactStatusList] = React.useState([]);
  const [contactTypeList, setcontactTypeList] = React.useState([]);
  const [contactSignificanceList, setcontactSignificanceList] = React.useState(
    []
  );
  const [genderList, setgenderList] = React.useState([]);
  const [considerUSPS, setConsiderUSPS] = React.useState("Y");
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [addressError, setAddressError] = React.useState([]);
  const [uspsAddres, setUspsAddress] = React.useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode5: ""
  });
  const [verified, setVerified] = React.useState(false);
  const [filteredAddressData, setFilteredAddressData] = React.useState([]);
  const [filteredContactData, setFilteredContactData] = React.useState([]);
  const [lockBoxTableData, setLockBoxTableData] = React.useState([]);
  const [addressTableData, setAddressTableData] = React.useState([]);
  const [addContactsData, setaddContactsData] = React.useState([]);
  const classes = useStyles();
  const [searchData] = React.useState({});
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [
    selectedLockBoxBeginDate,
    setSelectedLockBoxBeginDate
  ] = React.useState(null);
  const [selectedLockBoxEndDate, setSelectedLockBoxEndDate] = React.useState(
    null
  );
  const [
    selectedAddAddressBeginDate,
    setSelectedAddAddressBeginDate
  ] = React.useState(new Date());
  const [
    selectedAddAddressEndDate,
    setSelectedAddAddressEndDate
  ] = React.useState(null);
  const [
    selectedContactBeginDate,
    setSelectedContactBeginDate
  ] = React.useState(null);
  const [selectedContactEndDate, setSelectedContactEndDate] = React.useState(
    null
  );
  const [selectedContactDOB, setSelectedContactDOB] = React.useState(null);
  const [selectedContactDOD, setSelectedContactDOD] = React.useState(null);
  const [rowLockBoxData, setrowLockBoxData] = React.useState([{}]);
  const [rowContactData, setrowContactData] = React.useState([{}]);
  const [rowAddressData, setrowAddressData] = React.useState([{}]);
  const [selectedRow, setSelectedRow] = React.useState(null);

  const [values, setValues] = React.useState({
    id: "",
    code: "",
    shortDescription: searchData.functionalArea,
    longDescription: "",
    constantText: "",
    sourceTable: "",
    sourceCodeDescription: "",
    sourceCode: "",
    associatedselectedOption: "",
    selectedOption: false,
    routingBank: "",
    accountType: DEFAULT_DD_VALUE,
    accountNumber: "",
    accountName: "",
    bankName: ""
  });

  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openLockBox, setOpenLockBox] = React.useState(false);
  const [openContact, setOpenContact] = React.useState(false);

  const [, setDataElement] = React.useState({
    id: "",
    code: "",
    shortDescription: "",
    longDescription: "",
    constantText: "",
    associatedselectedOption: ""
  });
  const [lockBoxDetails, setlockBoxDetails] = React.useState({
    id: "",
    beginDate: "",
    endDate: "",
    lockBoxNumber: "",
    lockBoxName: ""
  });
  const [templockBoxDetails, settemplockBoxDetails] = React.useState({
    id: "",
    beginDate: "",
    endDate: "",
    lockBoxNumber: "",
    lockBoxName: ""
  });
  const [tempAddAddress, settempAddAddress] = React.useState({});
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const [addAddress, setAddAddress] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: DateUtilities.getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    addressUsageTypeCode: DEFAULT_DD_VALUE,
    addressSK: null,
    beginDate: "",
    endDate: "",
    addressUsageSigCode: DEFAULT_DD_VALUE,
    changeReasonCode: DEFAULT_DD_VALUE,
    statusCode: DEFAULT_DD_VALUE,
    addressRepCrossReferences: [],
    addressUsageSequenceNumber: 1,
    addressUsageSK: null,
    commonEntitySK: null,
    addressUsageVersionNumber: "0",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    addressLine4: "",
    statusDesc: null,
    addressTypeDesc: null,
    countyDesc: null,
    latitudeNumber: "",
    longitudeNumber: "",
    city: "",
    state: DEFAULT_DD_VALUE,
    zipCode5: "",
    zipCode4: "",
    town: "",
    country: DEFAULT_DD_VALUE,
    county: DEFAULT_DD_VALUE,
    uspsAddressVerifyCode: null,
    uspsAddressLine1: null,
    uspsAddressLine2: null,
    addressVersionNumber: 0
  });
  const [tempContactDetails, settempContactDetails] = React.useState({});
  const [contactDetails, setContactDetails] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: DateUtilities.getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: true,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    contactType: DEFAULT_DD_VALUE,
    addressType: null,
    contactSSN: "",
    contactDob: null,
    contactDod: null,
    contactStatusDesc: null,
    contactTypeDesc: null,
    contactTitle: "",
    significanceTypeCode: DEFAULT_DD_VALUE,
    status: null,
    contactAddrBeginDate: null,
    contactAddrEndDate: null,
    gender: DEFAULT_DD_VALUE,
    streetaddress: null,
    zipcode: null,
    dummyInd: null,
    addressUsageSequenceNumber: null,
    statusCode: DEFAULT_DD_VALUE,
    changeReason: null,
    beginDate: null,
    endDate: null,
    addressTypeRowIndex: null,
    latitudeNumber: null,
    longitudeNumber: null,
    uspsAddressLine1: null,
    uspsAddressLine2: null,
    uspsAddressVerifyCode: null,
    addressUsageSK: null,
    prefix: DEFAULT_DD_VALUE,
    lastName: "",
    firstName: "",
    middleName: "",
    suffix: DEFAULT_DD_VALUE,
    phone: null,
    phoneExtn: null,
    email: null,
    addressOne: null,
    addressTwo: null,
    addressThree: null,
    addressFour: null,
    county: null,
    country: null,
    town: null,
    addressVerification: null,
    uspsVerifiedCode: null,
    latitude: null,
    longitude: null,
    city: null,
    state: null,
    zipOne: null,
    zipTwo: null,
    representativeSK: null,
    eaddressSK: null,
    phoneSK: null,
    addressSK: null,
    countryCode: null,
    countyCode: null,
    addressBeginDateStr: null,
    addressEndDateStr: null,
    mailReturnedIndicator: false,
    addressVoidIndicator: false,
    addressPendingIndicator: false,
    phoneBeginDateStr: null,
    phoneEndDateStr: null,
    phoneVoidIndicator: false,
    phoneOutOfServiceIndicator: false,
    emailAddressBeginDateStr: null,
    emailAddressEndDateStr: null,
    emailAddressVoidIndicator: false,
    emailAddressBouncedIndicator: false,
    contactTypeRowIndex: null,
    commonEntityVersionNumber: 0,
    repCommonEntitySK: null,
    renderContactSection: false,
    renderAddContactSection: false,
    renderEditContactSection: false,
    renderDeleteContactSection: false,
    contactKey: null,
    contactRowIndex: null,
    renderAdditionalContactSection: false,
    renderMailingAddressSection: false,
    commonEntityCrossRefVersionNumber: 0,
    representativeVersionNumber: 0,
    addressUsageVersionNumber: 0,
    addressVersionNumber: 0,
    returnedMailVersionNumber: 0,
    phoneUsageVersionNumber: 0,
    phoneVersionNumber: 0,
    emailUsageVersionNumber: 0,
    emailVersionNumber: 0,
    renderPlusMailingAddressSection: false,
    renderPlusAdditionalContactSection: false,
    nameVrsnNo: 0,
    cmnEntityTypVrsnNo: 0,
    commonEntitySK: null,
    repCommonEntity: null,
    disableContactType: false
  });

  // Notes
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: DateUtilities.getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: DateUtilities.getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: "",
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(new Date()).format("MM/DD/YYYY hh:mm:ss"),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: "",
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(new Date()).format("YYYY-MM-DD"),
    filterEndDate: null,
    userId: logInUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: DateUtilities.generateUUID()
  });
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  let errorMessagesArray = [];
  const [
    {
      showLockBoxNameError,
      showLockBoxNumberError,
      showLockBoxBeginDateError,
      showStatusrror,
      showContactTypeError,
      showContactBeginDateError,
      showSignificanceError,
      showContactFName,
      showContactMName,
      showContactLName,
      showLockBoxEndDateError,
      showLockBoxBeginDateGreaterError,
      showLockBoxBeginDateSmallerError,
      mainAccountTypeEror,
      mainAccountNumberError,
      mainRoutingError,
      mainBeginDateError,
      showContactDateMatchError,
      showAddressBeginDateGreaterError,
      showContactEndDateMatchError,
      showLockBoxBeginDateMatchError,
      showLockBoxEndDateMatchError,
      mainAccountBeginDateInvalid,
      isContactEndDateValid,
      showContactBeginDateGreaterError,
      showAddressBeginDateMatchError,
      isLockBoxEndDateValid,
      showAddressEndDateMatchError,
      invalidLockBoxBeginDateError,
      invalidLockBoxEndDateError,
      invalidMainBeginDateError,
      invalidMainEndDateError,
      invalidAddressBeginDateError,
      isAddressEndDateValid,
      invalidAddressEndDateError,
      invalidContactBeginDateError,
      invalidContactEndDateError,
      isLockBoxBeginDateValid,
      isMainBeginDateValid,
      isMainBeginDateSmaller,
      isMainEndDateValid,
      isAddressBeginDateValid,
      isContactBeginDateValid,
      invalidDateOfBirthError,
      invalidDateOfDeathError,
      showDeathDateError,
      showLockBoxDuplicateNumberError,
      showAddressTypeError,
      showAdressBeginDateError,
      showAddressEndDateError,
      showAddressLine1Error,
      showSignificantTypeCodeError,
      showZipcodeError,
      overLapDateError,
      bankNameError,
      showZipCodeReqError,
      showStateError,
      showCityError,
      showStatusError,
      routingNumberError,
      routingValidationError
    },
    setShowError
  ] = React.useState(false);
  const [
    {
      showAddressTypeErrorMsg,
      showAdressBeginDateErrorMsg,
      showAddressEndDateErrorMsg,
      showAddressLine1ErrorMsg,
      showSignificantTypeCodeErrorMsg,
      showZipcodeErrorMsg,
      showZipcodereqErrorMsg,
      showStateErrorMsg,
      showStatusErrorMsg,
      showCityErrorMsg
    },
    setAddressErrorMessage
  ] = React.useState("");

  const [showAdditionalDetails, setShowAdditionalDetails] = React.useState(
    false
  );
  const [showAdditionalDetails1, setShowAdditionalDetails1] = React.useState(
    false
  );
  const [disableNotes, setDisableNotes] = React.useState(true);

  const dropdown = useSelector(state => state.bankAccountState.dropdownData);
  const typeDropdown = useSelector(
    state => state.bankAccountState.typeDropdownData
  );
  const saveAPICall = saveObj => dispatch(addBankAccountAndLockBox(saveObj));
  const saveAPIResult = useSelector(
    state => state.bankAccountState.addBankAccountAndLockBox
  );
  const onAddSuccess = values => dispatch(searchLockBoxDetailsAction(values));
  const payloadData = useSelector(state => state.bankAccountState.lockBoxData);

  const validateAddress = values => {
    dispatch(verifyAddressAPI(values));
  };
  let validateAddressResult = useSelector(
    state => state.sharedState.addressValidatedData
  );

  const handleChangeUSPS = () => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setConsiderUSPS(event.target.value);
    setShowError(false);
  };



  useEffect(() => {
    if (validateAddressResult) {
      if (validateAddressResult.systemFailue) {
        setAddressError([ErrorConstants.SYSTEM_ERROR]);
        const errorArray = JSON.parse(JSON.stringify(errorMessages));
        errorArray.push(ErrorConstants.SYSTEM_ERROR);
        seterrorMessages(errorArray);
        setUspsAddress({
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipCode5: ""
        });
        setVerified(false);
      }
      if (
        validateAddressResult.AddressValidateResponse &&
        !validateAddressResult.systemFailue
      ) {
        if (validateAddressResult.AddressValidateResponse.Address) {
          if (validateAddressResult.AddressValidateResponse.Address.Error) {
            setAddressError([
              validateAddressResult.AddressValidateResponse.Address.Error
                .Description
            ]);
            const errorArray = JSON.parse(JSON.stringify(errorMessages));
            errorArray.push(
              validateAddressResult.AddressValidateResponse.Address.Error
                .Description
            );
            seterrorMessages(errorArray);
            setVerified(false);
            setUspsAddress({
              addressLine1: "",
              addressLine2: "",
              city: "",
              state: "",
              zipCode5: ""
            });
          } else {
            setUspsAddress({
              addressLine1:
                validateAddressResult.AddressValidateResponse.Address.Address2,
              addressLine2:
                validateAddressResult.AddressValidateResponse.Address.Address1,
              city: validateAddressResult.AddressValidateResponse.Address.City,
              state:
                validateAddressResult.AddressValidateResponse.Address.State,
              zipCode5:
                validateAddressResult.AddressValidateResponse.Address.Zip5
            });
            setAddressError([]);
            seterrorMessages([]);
            setVerified(true);
            setShowAdditionalDetails1(true);
          }
        } else {
          setAddressError([ErrorConstants.INVALID_ADDRESS]);
          const errorArray = JSON.parse(JSON.stringify(errorMessages));
          errorArray.push(ErrorConstants.INVALID_ADDRESS);
          seterrorMessages(errorArray);
          setVerified(false);
          setUspsAddress({
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zipCode5: ""
          });
        }
      } else {
        setAddressError([ErrorConstants.SYSTEM_ERROR]);
        const errorArray = JSON.parse(JSON.stringify(errorMessages));
        errorArray.push(ErrorConstants.SYSTEM_ERROR);
        seterrorMessages(errorArray);
        setVerified(false);
        setUspsAddress({
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipCode5: ""
        });
      }
    }
  }, [validateAddressResult]);

  useEffect(() => {
    onReset();
    dropDownDispatch(dropdownCriteria);
    dropDownTypeDispatch(dropdownTypeCriteria);
    validateAddressResult = null;
  }, []);

  useEffect(() => {
    if (redirect === 1) {
      if (payloadData) {
        if (payloadData !== "" && payloadData && isArray(payloadData)) {
          props.history.push({
            pathname: "/BankAccountLockBoxEdit",
            state: {
              payloadData: payloadData[0],
              isFromAdd: true,
              message: "System successfully saved the Information."
            }
          });
        } else {
          seterrorMessages([ErrorConstants.SYSTEM_ERROR]);
        }
      }
    }
  }, [redirect, payloadData]);

  const handleSaveAddressFinal = () => {
    errorMessagesArray = [];
    seterrorMessages([]);
    var invalidAddressBeginDateError;
    var showAddressEndDateMatchError;
    var isAddressEndDateValid;
    var showAddressBeginDateMatchError;
    var addressEndDateLesserError;
    var showAddressBeginDateGreaterError;
    var invalidAddressEndDateError;
    var isAddressBeginDateValid = false;
    var showAddressTypeError;
    var showAdressBeginDateError;
    var showAddressEndDateError;
    var showStatusError = false;
    var showCityError = false;
    var showStateError = false;
    var showZipCodeReqError = false;
    var showAddressLine1Error = false;
    var showSignificantTypeCodeError = false;
    var showZipcodeError = false;
    var showZipcodeErrorMsg;
    var showAddressTypeErrorMsg = false;
    var showAdressBeginDateErrorMsg = false;
    var showAddressEndDateErrorMsg = false;
    var showAddressLine1ErrorMsg;
    var showSignificantTypeCodeErrorMsg;
    var showStatusErrorMsg = "";
    var showCityErrorMsg = "";
    var showStateErrorMsg = "";
    var showZipcodereqErrorMsg = "";
    if (addAddress.statusCode === DEFAULT_DD_VALUE) {
      showStatusError = true;
      showStatusErrorMsg = ErrorConstants.STATUS_REQUIRED;
      errorMessagesArray.push(showStatusErrorMsg);
    }
    if (addAddress.state === DEFAULT_DD_VALUE) {
      showStateError = true;
      showStateErrorMsg = ErrorConstants.STATE_REQUIRED;
      errorMessagesArray.push(showStateErrorMsg);
    }
    if (addAddress.city === "") {
      showCityError = true;
      showCityErrorMsg = ErrorConstants.CITY_REQUIRED;
      errorMessagesArray.push(showCityErrorMsg);
    }
    if (addAddress.zipCode5 === "") {
      showZipCodeReqError = true;
      showZipcodereqErrorMsg = ErrorConstants.ZIP_CODE_REQUIRED;
      errorMessagesArray.push(showZipcodereqErrorMsg);
    }
    if (addAddress.addressUsageTypeCode === DEFAULT_DD_VALUE) {
      showAddressTypeError = true;
      showAddressTypeErrorMsg = ErrorConstants.ADDRESS_TYPE_REQUIRED;
      errorMessagesArray.push(showAddressTypeErrorMsg);
    }
    if (
      !addAddress.addressLine1 ||
      (addAddress.addressLine1 && !addAddress.addressLine1.trim())
    ) {
      showAddressLine1Error = true;
      showAddressLine1ErrorMsg = ErrorConstants.ADDRESS_LINE_1_REQUIRED;
      errorMessagesArray.push(showAddressLine1ErrorMsg);
    }
    if (addAddress.addressUsageSigCode === DEFAULT_DD_VALUE) {
      showSignificantTypeCodeError = true;
      showSignificantTypeCodeErrorMsg =
        ErrorConstants.SIGNIFICANT_TYPE_CODE_REQUIRED;
      errorMessagesArray.push(showSignificantTypeCodeErrorMsg);
    }
    if (!selectedAddAddressBeginDate) {
      showAdressBeginDateError = true;
      errorMessagesArray.push(showAddressTypeErrorMsg);
      showAdressBeginDateErrorMsg = ErrorConstants.BEGINDATE_REQUIRED;
    }
    if (selectedAddAddressBeginDate) {
      addAddress.beginDate =
        selectedAddAddressBeginDate.getMonth() +
        1 +
        "/" +
        selectedAddAddressBeginDate.getDate() +
        "/" +
        selectedAddAddressBeginDate.getFullYear();
    }
    if (
      addAddress.beginDate &&
      addAddress.beginDate.toString() === "NaN/NaN/NaN"
    ) {
      addAddress.beginDate = "";
    }
    if (selectedAddAddressEndDate) {
      addAddress.endDate =
        selectedAddAddressEndDate.getMonth() +
        1 +
        "/" +
        selectedAddAddressEndDate.getDate() +
        "/" +
        selectedAddAddressEndDate.getFullYear();
    }
    // Begin Date Validation
    if (
      selectedAddAddressBeginDate &&
      selectedAddAddressBeginDate.toString() === "Invalid Date"
    ) {
      invalidAddressBeginDateError = true;
      errorMessagesArray.push("Begin Date " + ErrorConstants.DATE_INVALID);
    }
    if (
      selectedAddAddressBeginDate &&
      selectedAddAddressBeginDate.toString() !== "Invalid Date"
    ) {
      const isDateValid = DateUtilities.validateDateMinimumValue(
        selectedAddAddressBeginDate
      );
      if (isDateValid) {
        isAddressBeginDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        !isDateValid &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormat(selectedAddAddressBeginDate)
        ) <
          new Date(
            DateUtilities.getDateInMMDDYYYYFormat(new Date(selectedDate))
          )
      ) {
        showAddressBeginDateMatchError = true;
        errorMessagesArray.push(ErrorConstants.ADDRESS_DATES_MATCH_ERROR);
      }
    }
    // End Date Validation
    var tempSelectedAddressEndDate = new Date(selectedAddAddressEndDate);
    if (!selectedAddAddressEndDate) {
      addAddress.endDate = "12/31/9999";
      setSelectedAddAddressEndDate(new Date("12/31/9999"));
      tempSelectedAddressEndDate = new Date("12/31/9999");
    }
    if (
      tempSelectedAddressEndDate &&
      tempSelectedAddressEndDate.toString() === "Invalid Date"
    ) {
      invalidAddressEndDateError = true;
      errorMessagesArray.push("End Date " + ErrorConstants.DATE_INVALID);
    }
    if (
      tempSelectedAddressEndDate &&
      tempSelectedAddressEndDate.toString() !== "Invalid Date"
    ) {
      let isDateValid = false;
      isDateValid = DateUtilities.validateDateMinimumValue(
        tempSelectedAddressEndDate
      );
      if (isDateValid) {
        isAddressEndDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        !isDateValid &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
            tempSelectedAddressEndDate
          )
        ) >
          new Date(
            DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEndDate)
          )
      ) {
        showAddressEndDateMatchError = true;
        errorMessagesArray.push(ErrorConstants.ADDRESS_DATES_MATCH_ERROR);
      }
    }
    // Begin Date + End Date Comparison
    if (
      selectedAddAddressBeginDate &&
      tempSelectedAddressEndDate &&
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
          selectedAddAddressBeginDate
        )
      ) >
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
            tempSelectedAddressEndDate
          )
        )
    ) {
      showAddressBeginDateGreaterError = true;
      errorMessagesArray.push(ErrorConstants.ADDRESS_BEGIN_DATE_GREATER_ERROR);
    }
    if (verified === false && considerUSPS === "Y") {
      setAddressError([ErrorConstants.VERIFIED_ADDRESS]);
      errorMessagesArray.push(ErrorConstants.VERIFIED_ADDRESS);
    } else {
      setAddressError([]);
    }
    const regex = /^^[a-zA-Z0-9 ]*$/;
    if (
      addAddress &&
      addAddress.zipCode5 &&
      addAddress.zipCode5.toString().trim() &&
      !regex.test(addAddress.zipCode5)
    ) {
      showZipcodeError = true;
      showZipcodeErrorMsg = ErrorConstants.INVALID_ZIPCODE;
      errorMessagesArray.push(ErrorConstants.INVALID_ZIPCODE);
    }
    if (errorMessagesArray.length === 0 && (verified || considerUSPS === "N")) {
      if (selectedRow === null) {
        addAddress.beginDate = DateUtilities.getDateInMMDDYYYYFormat(
          addAddress.beginDate
        );
        addAddress.endDate = DateUtilities.getDateInMMDDYYYYFormat(
          addAddress.endDate
        );
        addAddress.id = DateUtilities.generateUUID();
        const tempAddAddress = JSON.parse(JSON.stringify(addAddress));
        if (considerUSPS === "Y") {
          tempAddAddress.addressLine1 = uspsAddres.addressLine1;
          tempAddAddress.addressLine2 = uspsAddres.addressLine2;
          tempAddAddress.city = uspsAddres.city;
          tempAddAddress.state = uspsAddres.state;
          tempAddAddress.zipCode5 = uspsAddres.zipCode5;
        }
        if (considerUSPS === "Y") {
          setAddressTableData(addressTableData => [
            ...addressTableData,
            tempAddAddress
          ]);
        } else {
          setAddressTableData(addressTableData => [
            ...addressTableData,
            addAddress
          ]);
        }
        filterAddressData(addressTableData);
      } else {
        addressTableData.map(element => {
          if (element.id === selectedRow) {
            if (selectedAddAddressBeginDate) {
              addAddress.beginDate =
                selectedAddAddressBeginDate.getMonth() +
                1 +
                "/" +
                selectedAddAddressBeginDate.getDate() +
                "/" +
                selectedAddAddressBeginDate.getFullYear();
              if (
                addAddress.beginDate &&
                addAddress.beginDate.toString() === "NaN/NaN/NaN"
              ) {
                addAddress.beginDate = "";
              }
            }
            if (selectedAddAddressEndDate) {
              addAddress.endDate =
                selectedAddAddressEndDate.getMonth() +
                1 +
                "/" +
                selectedAddAddressEndDate.getDate() +
                "/" +
                selectedAddAddressEndDate.getFullYear();
            } else {
              addAddress.endDate = new Date("12/31/9999");
            }
            element.addressUsageTypeCode = addAddress.addressUsageTypeCode;
            element.beginDate = DateUtilities.getDateInMMDDYYYYFormat(
              addAddress.beginDate
            );
            element.endDate = DateUtilities.getDateInMMDDYYYYFormat(
              addAddress.endDate
            );
            element.addressUsageSigCode = addAddress.addressUsageSigCode;
            element.changeReasonCode = addAddress.changeReasonCode;
            element.statusCode = addAddress.statusCode;
            if (considerUSPS === "Y") {
              element.addressLine1 = uspsAddres.addressLine1;
              element.addressLine2 = uspsAddres.addressLine2;
              element.city = uspsAddres.city;
              element.state = uspsAddres.state;
              element.zipCode5 = uspsAddres.zipCode5;
            } else {
              element.addressLine1 = addAddress.addressLine1;
              element.addressLine2 = addAddress.addressLine2;
              element.city = addAddress.city;
              element.state = addAddress.state;
              element.zipCode5 = addAddress.zipCode5;
            }
            element.addressLine3 = addAddress.addressLine3;
            element.addressLine4 = addAddress.addressLine4;
            element.uspsAddress1 = addAddress.uspsAddress1;
            element.uspsAddressVerifyCode = addAddress.uspsAddressVerifyCode;
            element.latitudeNumber = addAddress.latitudeNumber;
            element.longitudeNumber = addAddress.longitudeNumber;
            element.county = addAddress.county;
            element.country = addAddress.country;
            element.town = addAddress.town;
          }
        });
        filterAddressData(addressTableData);
      }
      setOpen(false);
      setAddAddress({
        auditUserID: logInUserID,
        auditTimeStamp: DateUtilities.getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        addressUsageTypeCode: DEFAULT_DD_VALUE,
        addressSK: null,
        beginDate: "",
        endDate: "",
        addressUsageSigCode: DEFAULT_DD_VALUE,
        changeReasonCode: DEFAULT_DD_VALUE,
        statusCode: DEFAULT_DD_VALUE,
        addressRepCrossReferences: [],
        addressUsageSequenceNumber: 1,
        addressUsageSK: null,
        commonEntitySK: null,
        addressUsageVersionNumber: "0",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        addressLine4: "",
        statusDesc: null,
        addressTypeDesc: null,
        countyDesc: null,
        latitudeNumber: "",
        longitudeNumber: "",
        city: "",
        state: DEFAULT_DD_VALUE,
        zipCode5: "",
        zipCode4: "",
        town: "",
        country: DEFAULT_DD_VALUE,
        county: DEFAULT_DD_VALUE,
        uspsAddressVerifyCode: null,
        uspsAddressLine1: null,
        uspsAddressLine2: null,
        addressVersionNumber: 0
      });
      setConsiderUSPS("Y");
      setSelectedAddAddressBeginDate(new Date());
      setSelectedAddAddressEndDate(null);
      setSelectedRow(null);
      errorMessagesArray = [];
      setVerified(false);
      setShowAdditionalDetails1(false);
      setUspsAddress({
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode5: ""
      });
    }
    setShowError({
      invalidAddressBeginDateError: invalidAddressBeginDateError,
      isAddressEndDateValid: isAddressEndDateValid,
      invalidAddressEndDateError: invalidAddressEndDateError,
      isAddressBeginDateValid: isAddressBeginDateValid,
      showAddressEndDateMatchError: showAddressEndDateMatchError,
      showAddressBeginDateMatchError: showAddressBeginDateMatchError,
      addressEndDateLesserError: addressEndDateLesserError,
      showAddressBeginDateGreaterError: showAddressBeginDateGreaterError,
      showAddressTypeError: showAddressTypeError,
      showAddressLine1Error: showAddressLine1Error,
      showSignificantTypeCodeError: showSignificantTypeCodeError,
      showAdressBeginDateError: showAdressBeginDateError,
      showAddressEndDateError: showAddressEndDateError,
      showZipcodeError: showZipcodeError,
      showZipCodeReqError: showZipCodeReqError,
      showStatusError: showStatusError,
      showCityError: showCityError,
      showStateError: showStateError
    });
    setAddressErrorMessage({
      showAddressTypeErrorMsg: showAddressTypeErrorMsg,
      showAddressLine1ErrorMsg: showAddressLine1ErrorMsg,
      showSignificantTypeCodeErrorMsg: showSignificantTypeCodeErrorMsg,
      showAdressBeginDateErrorMsg: showAdressBeginDateErrorMsg,
      showAddressEndDateErrorMsg: showAddressEndDateErrorMsg,
      showZipcodeErrorMsg: showZipcodeErrorMsg,
      showZipcodereqErrorMsg: showZipcodereqErrorMsg,
      showCityErrorMsg: showCityErrorMsg,
      showStatusErrorMsg: showStatusErrorMsg,
      showStateErrorMsg: showStateErrorMsg
    });
    seterrorMessages(errorMessagesArray);
  };
  useEffect(() => {
    if (saveAPIResult) {
      if (saveAPIResult.respcode === "01") {
        let valuetoredirect = 0;
        valuetoredirect = valuetoredirect + 1;
        setRedirect(valuetoredirect);
        const rowClickValues = {
          lob: [AppConstants.DEFAULT_LOB_VALUE],
          bankRoutingNumber: values.routingBank,
          bankRoutingNumberStartsWith: false,
          bankName: null,
          bankNameStartsWithOrContains: null,
          bankAccountNumber: values.accountNumber,
          accountNumberStartsWith: false,
          accountName: null,
          accountNameStartsWithOrContains: null,
          beginDate: moment(selectedDate).format("MM/DD/YYYY"),
          endDate: moment(selectedEndDate).format("MM/DD/YYYY"),
          lockBoxNumber: lockBoxTableData[0].lockBoxNumber,
          lockBoxNumberStartsWith: false,
          lockBoxName: lockBoxTableData[0].lockBoxName,
          lockBoxNameStartsWithOrContains: null
        };
        onAddSuccess(rowClickValues);
      } else if (
        saveAPIResult.respcode === "02" &&
        saveAPIResult.errorMessages !== null
      ) {
        const duplicateErrorMessage = saveAPIResult.errorMessages;
        duplicateErrorMessage.map(errMsg => {
          errorMessagesArray.push(errMsg);
        });
        seterrorMessages(errorMessagesArray);
      } else if (
        saveAPIResult.respcode === "02" &&
        saveAPIResult.errorMessages === null
      ) {
        errorMessagesArray.push(ErrorConstants.SYSTEM_ERROR);
        seterrorMessages(errorMessagesArray);
      } else {
        errorMessagesArray.push(ErrorConstants.SYSTEM_ERROR);
        seterrorMessages(errorMessagesArray);
      }
    }
  }, [saveAPIResult]);

  useEffect(() => {
    onReset();
  }, [useEffectValues]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj["General#1041"]) {
        setsignificanceTypeList(dropdown.listObj["General#1041"]);
      }
      if (dropdown.listObj["General#1020"]) {
        setchangeReasonList(dropdown.listObj["General#1020"]);
      }
      if (dropdown.listObj["General#1000"]) {
        setaddStatusList(dropdown.listObj["General#1000"]);
      }
      if (dropdown.listObj["General#1019"]) {
        setaddStateList(dropdown.listObj["General#1019"]);
      }
      if (dropdown.listObj["General#1007"]) {
        setcountyList(dropdown.listObj["General#1007"]);
      }
      if (dropdown.listObj["General#1006"]) {
        const cntyList = JSON.parse(
          JSON.stringify(
            dropdown.listObj["General#1006"].sort((a, b) =>
              a.code.toLocaleUpperCase() < b.code.toLocaleUpperCase() ? -1 : 1
            )
          )
        );
        setaddCountryList(cntyList);
      }
      if (dropdown.listObj["General#1076"]) {
        setcontactStatusList(dropdown.listObj["General#1076"]);
      }
      if (dropdown.listObj["General#1018"]) {
        setcontactTypeList(dropdown.listObj["General#1018"]);
      }
      if (dropdown.listObj["General#1004"]) {
        setcontactSignificanceList(dropdown.listObj["General#1004"]);
      }
      if (dropdown.listObj["General#1011"]) {
        setcontactPrefixList(dropdown.listObj["General#1011"]);
      }
      if (dropdown.listObj["General#1053"]) {
        setcontactSuffixList(dropdown.listObj["General#1053"]);
      }
      if (dropdown.listObj["General#1010"]) {
        setgenderList(dropdown.listObj["General#1010"]);
      }
      if (dropdown.listObj["General#6001"]) {
        setaddressTypeList(dropdown.listObj["General#6001"]);
      }
      if (dropdown.listObj["General#1012"]) {
        setUsageTypeCodeData(dropdown.listObj["General#1012"]);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (typeDropdown && typeDropdown.listObj) {
      if (typeDropdown.listObj["Financial#F_BANK_ACCT_TY_CD"]) {
        setaccountTypeList(typeDropdown.listObj["Financial#F_BANK_ACCT_TY_CD"]);
      }
    }
  }, [typeDropdown]);

  // DropdownFilter starts
  const dropDownFilter = (dropDownType, value) => {
    let TypeDropdownData = [];
    switch (dropDownType) {
      case "addressStatus":
        TypeDropdownData = addStatusList;
        break;
      case "addressType":
        TypeDropdownData = addressTypeList;
        break;
      case "contactStatus":
        TypeDropdownData = contactStatusList;
        break;
      case "contactType":
        TypeDropdownData = contactTypeList;
        break;
      case "stateType":
        TypeDropdownData = addStateList;
        break;

    }
    if (TypeDropdownData) {
      const filteredValue = TypeDropdownData.filter(
        (dropdownTypeValue) => dropdownTypeValue.code === value
      );
      if (filteredValue && filteredValue.length > 0) {
        return filteredValue[0].description;
      }
      return value;
    }
  };

  const filterAddressData = addressTableData => {
    if (addressTableData && addressTableData.length > 0) {
      addressTableData.map((data) => {
        data.statusCodeDesc = dropDownFilter("addressStatus", data.statusCode);
        data.addressUsageTypeCodeDesc = dropDownFilter(
          "addressType",
          data.addressUsageTypeCode
        );
        data.stateCodeDesc = dropDownFilter("stateType", data.state);
      });
    }
    setFilteredAddressData(addressTableData);
  };
  const filterContactData = addContactsData => {
    if (addContactsData && addContactsData.length > 0) {
      addContactsData.map((data) => {
        data.statusCodeDesc = dropDownFilter("contactStatus", data.statusCode);
        data.contactTypeDesc = dropDownFilter("contactType", data.contactType);
      });
    }
    setFilteredContactData(addContactsData);
  };
  // DropdownFilter ends

  const handleDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedDate(date);
  };

  let sampleDatalockbox = lockBoxTableData;
  let sampleDataAddress = addressTableData;
  let sampleDataContacts = addContactsData;

  function rowDeleteClickAddress () {
    for (let i = 0; i < rowAddressData.rowAddressData.length; i++) {
      sampleDataAddress = sampleDataAddress.filter(
        addressTable => addressTable.id !== rowAddressData.rowAddressData[i]
      );
    }
    setAddressTableData(sampleDataAddress);
    setSelectedRow([]);
  }
  function rowDeleteClickContact () {
    for (let i = 0; i < rowContactData.rowContactData.length; i++) {
      sampleDataContacts = sampleDataContacts.filter(
        ContactTable => ContactTable.id !== rowContactData.rowContactData[i]
      );
    }
    setaddContactsData(sampleDataContacts);
    setSelectedRow([]);
  }
  function rowDeleteClick () {
    for (let i = 0; i < rowLockBoxData.rowLockBoxData.length; i++) {
      sampleDatalockbox = sampleDatalockbox.filter(
        lockBoxTable => lockBoxTable.id !== rowLockBoxData.rowLockBoxData[i]
      );
    }
    setLockBoxTableData(sampleDatalockbox);
    setSelectedRow([]);
  }
  const rowDeleteLockBox = data => {
    setrowLockBoxData({ ...rowLockBoxData, rowLockBoxData: data });
  };
  const rowDeleteContact = data => {
    setrowContactData({ ...rowContactData, rowContactData: data });
  };
  const rowDeleteAddress = data => {
    setrowAddressData({ ...rowAddressData, rowAddressData: data });
  };

  useEffect(() => {
    setLockBoxTableData(lockBoxTableData);
    setSelectedRow(null);
  }, [lockBoxTableData]);
  useEffect(() => {
    setAddressTableData(addressTableData);
    setSelectedRow(null);
  }, [addressTableData]);
  useEffect(() => {
    setaddContactsData(addContactsData);
    setSelectedRow(null);
  }, [addContactsData]);

  useEffect(() => {
    if (addressTableData) {
      filterAddressData(addressTableData);
    }
  }, [addressTableData, selectedRow, addStatusList, addressTypeList]);

  useEffect(() => {
    if (addContactsData) {
      filterContactData(addContactsData);
    }
  }, [addContactsData, selectedRow, contactStatusList, contactTypeList, addStateList]);

  const handleEndDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedEndDate(date);
  };

  const handleLockBoxBeginDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedLockBoxBeginDate(date);
  };

  const handleLockBoxEndDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedLockBoxEndDate(date);
  };

  const handleAddAddressBeginDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedAddAddressBeginDate(date);
  };

  const handleAddAddressEndDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedAddAddressEndDate(date);
  };

  const handleContactBeginDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedContactBeginDate(date);
  };

  const handleContactEndDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedContactEndDate(date);
  };

  const handleContactDOB = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedContactDOB(date);
  };

  const handleContactDOD = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedContactDOD(date);
  };

  const handleLockBoxTableAdd = value => () => {
    setShowError(false);
    setShowError({
      showLockBoxNameError: false,
      showLockBoxNumberError: false,
      showLockBoxBeginDateError: false,
      showLockBoxEndDateError: false,
      showLockBoxBeginDateGreaterError: false,
      showLockBoxBeginDateSmallerError: false,
      showLockBoxEndDateMatchError: false,
      showLockBoxBeginDateMatchError: false,
      validLockBoxBeginDateShow: false,
      validLockBoxEndDateShow: false,
      invalidLockBoxBeginDateError: false,
      invalidLockBoxEndDateError: false,
      isLockBoxBeginDateValid: false,
      isLockBoxEndDateValid: false,
      showLockBoxDuplicateNumberError: false,
      overLapDateError: false
    });
    seterrorMessages([]);
    setOpenLockBox(true);
    if (value.id) {
      setSelectedRow(value.id);
    }
    setSelectedLockBoxBeginDate(new Date(value.beginDate));
    setSelectedLockBoxEndDate(new Date(value.endDate));
    setlockBoxDetails({
      beginDate: value.beginDate,
      endDate: value.endDate,
      lockBoxNumber: value.lockBoxNumber,
      lockBoxName: value.lockBoxName,
      id: value.id
    });
    settemplockBoxDetails({
      beginDate: value.beginDate,
      endDate: value.endDate,
      lockBoxNumber: value.lockBoxNumber,
      lockBoxName: value.lockBoxName
    });
    if (openLockBox && document.getElementById("lockbox_form_pop_up")) {
      document.getElementById("lockbox_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  const handleContactTableAdd = value => () => {
    setShowError(false);
    setOpenContact(true);
    if (value.id) {
      setSelectedRow(value.id);
    }
    setSelectedContactBeginDate(new Date(value.contactAddrBeginDate));
    setSelectedContactEndDate(new Date(value.contactAddrEndDate));
    setSelectedContactDOB(value.contactDob ? new Date(value.contactDob) : null);
    setSelectedContactDOD(value.contactDod ? new Date(value.contactDod) : null);
    setContactDetails({
      auditUserID: logInUserID,
      auditTimeStamp: DateUtilities.getUTCTimeStamp(),
      addedAuditUserID: logInUserID,
      addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
      versionNo: 0,
      dbRecord: true,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      contactType: value.contactType ? value.contactType : DEFAULT_DD_VALUE,
      addressType: null,
      contactSSN: value.contactSSN,
      contactDob: value.contactDob ? value.contactDob : null,
      contactDod: value.contactDod ? value.contactDod : null,
      contactStatusDesc: null,
      contactTypeDesc: null,
      contactTitle: value.contactTitle,
      significanceTypeCode: value.significanceTypeCode
        ? value.significanceTypeCode
        : DEFAULT_DD_VALUE,
      status: null,
      contactAddrBeginDate: value.contactAddrBeginDate
        ? value.contactAddrBeginDate
        : null,
      contactAddrEndDate: value.contactAddrEndDate
        ? value.contactAddrEndDate
        : null,
      gender: value.gender ? value.gender : DEFAULT_DD_VALUE,
      streetaddress: null,
      zipcode: null,
      dummyInd: null,
      addressUsageSequenceNumber: null,
      statusCode: value.statusCode,
      changeReason: null,
      beginDate: null,
      endDate: null,
      addressTypeRowIndex: null,
      latitudeNumber: null,
      longitudeNumber: null,
      uspsAddressLine1: null,
      uspsAddressLine2: null,
      uspsAddressVerifyCode: null,
      addressUsageSK: null,
      prefix: value.prefix ? value.prefix : DEFAULT_DD_VALUE,
      lastName: value.lastName ? value.lastName : "",
      firstName: value.firstName ? value.firstName : "",
      middleName: value.middleName ? value.middleName : "",
      suffix: value.suffix ? value.suffix : DEFAULT_DD_VALUE,
      phone: null,
      phoneExtn: null,
      email: null,
      addressOne: null,
      addressTwo: null,
      addressThree: null,
      addressFour: null,
      county: null,
      country: null,
      town: null,
      addressVerification: null,
      uspsVerifiedCode: null,
      latitude: null,
      longitude: null,
      city: null,
      state: null,
      zipOne: null,
      zipTwo: null,
      representativeSK: null,
      eaddressSK: null,
      phoneSK: null,
      addressSK: null,
      countryCode: null,
      countyCode: null,
      addressBeginDateStr: null,
      addressEndDateStr: null,
      mailReturnedIndicator: false,
      addressVoidIndicator: false,
      addressPendingIndicator: false,
      phoneBeginDateStr: null,
      phoneEndDateStr: null,
      phoneVoidIndicator: false,
      phoneOutOfServiceIndicator: false,
      emailAddressBeginDateStr: null,
      emailAddressEndDateStr: null,
      emailAddressVoidIndicator: false,
      emailAddressBouncedIndicator: false,
      contactTypeRowIndex: null,
      commonEntityVersionNumber: 0,
      repCommonEntitySK: null,
      renderContactSection: false,
      renderAddContactSection: false,
      renderEditContactSection: false,
      renderDeleteContactSection: false,
      contactKey: null,
      contactRowIndex: null,
      renderAdditionalContactSection: false,
      renderMailingAddressSection: false,
      commonEntityCrossRefVersionNumber: 0,
      representativeVersionNumber: 0,
      addressUsageVersionNumber: 0,
      addressVersionNumber: 0,
      returnedMailVersionNumber: 0,
      phoneUsageVersionNumber: 0,
      phoneVersionNumber: 0,
      emailUsageVersionNumber: 0,
      emailVersionNumber: 0,
      renderPlusMailingAddressSection: false,
      renderPlusAdditionalContactSection: false,
      nameVrsnNo: 0,
      cmnEntityTypVrsnNo: 0,
      commonEntitySK: null,
      repCommonEntity: null,
      disableContactType: false,
      id: value.id
    });
    settempContactDetails({
      auditUserID: logInUserID,
      auditTimeStamp: DateUtilities.getUTCTimeStamp(),
      addedAuditUserID: logInUserID,
      addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
      versionNo: 0,
      dbRecord: true,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      contactType: value.contactType ? value.contactType : DEFAULT_DD_VALUE,
      addressType: null,
      contactSSN: value.contactSSN ? value.contactSSN : "",
      contactDob: value.contactDob ? value.contactDob : null,
      contactDod: value.contactDod ? value.contactDod : null,
      contactStatusDesc: null,
      contactTypeDesc: null,
      contactTitle: value.contactTitle ? value.contactTitle : "",
      significanceTypeCode: value.significanceTypeCode
        ? value.significanceTypeCode
        : DEFAULT_DD_VALUE,
      status: null,
      contactAddrBeginDate: value.contactAddrBeginDate
        ? value.contactAddrBeginDate
        : null,
      contactAddrEndDate: value.contactAddrEndDate
        ? value.contactAddrEndDate
        : null,
      gender: value.gender ? value.gender : DEFAULT_DD_VALUE,
      streetaddress: null,
      zipcode: null,
      dummyInd: null,
      addressUsageSequenceNumber: null,
      statusCode: value.statusCode,
      changeReason: null,
      beginDate: null,
      endDate: null,
      addressTypeRowIndex: null,
      latitudeNumber: null,
      longitudeNumber: null,
      uspsAddressLine1: null,
      uspsAddressLine2: null,
      uspsAddressVerifyCode: null,
      addressUsageSK: null,
      prefix: value.prefix ? value.prefix : DEFAULT_DD_VALUE,
      lastName: value.lastName ? value.lastName : "",
      firstName: value.firstName ? value.firstName : "",
      middleName: value.middleName ? value.middleName : "",
      suffix: value.suffix ? value.suffix : DEFAULT_DD_VALUE,
      phone: null,
      phoneExtn: null,
      email: null,
      addressOne: null,
      addressTwo: null,
      addressThree: null,
      addressFour: null,
      county: null,
      country: null,
      town: null,
      addressVerification: null,
      uspsVerifiedCode: null,
      latitude: null,
      longitude: null,
      city: null,
      state: null,
      zipOne: null,
      zipTwo: null,
      representativeSK: null,
      eaddressSK: null,
      phoneSK: null,
      addressSK: null,
      countryCode: null,
      countyCode: null,
      addressBeginDateStr: null,
      addressEndDateStr: null,
      mailReturnedIndicator: false,
      addressVoidIndicator: false,
      addressPendingIndicator: false,
      phoneBeginDateStr: null,
      phoneEndDateStr: null,
      phoneVoidIndicator: false,
      phoneOutOfServiceIndicator: false,
      emailAddressBeginDateStr: null,
      emailAddressEndDateStr: null,
      emailAddressVoidIndicator: false,
      emailAddressBouncedIndicator: false,
      contactTypeRowIndex: null,
      commonEntityVersionNumber: 0,
      repCommonEntitySK: null,
      renderContactSection: false,
      renderAddContactSection: false,
      renderEditContactSection: false,
      renderDeleteContactSection: false,
      contactKey: null,
      contactRowIndex: null,
      renderAdditionalContactSection: false,
      renderMailingAddressSection: false,
      commonEntityCrossRefVersionNumber: 0,
      representativeVersionNumber: 0,
      addressUsageVersionNumber: 0,
      addressVersionNumber: 0,
      returnedMailVersionNumber: 0,
      phoneUsageVersionNumber: 0,
      phoneVersionNumber: 0,
      emailUsageVersionNumber: 0,
      emailVersionNumber: 0,
      renderPlusMailingAddressSection: false,
      renderPlusAdditionalContactSection: false,
      nameVrsnNo: 0,
      cmnEntityTypVrsnNo: 0,
      commonEntitySK: null,
      repCommonEntity: null,
      disableContactType: false,
      id: value.id
    });
    if (openContact && document.getElementById("contact_form_pop_up")) {
 document.getElementById("contact_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
}
  };

  const handleAddressTableAdd = value => () => {
    setShowError(false);
    setOpen(true);
    if (value.id) {
      setSelectedRow(value.id);
    }
    setAddressError([]);
    setSelectedAddAddressBeginDate(new Date(value.beginDate));
    setSelectedAddAddressEndDate(new Date(value.endDate));
    setAddAddress({
      auditUserID: logInUserID,
      auditTimeStamp: DateUtilities.getUTCTimeStamp(),
      addedAuditUserID: logInUserID,
      addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
      versionNo: 0,
      dbRecord: false,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      addressUsageTypeCode: value.addressUsageTypeCode
        ? value.addressUsageTypeCode
        : DEFAULT_DD_VALUE,
      addressSK: null,
      beginDate: value.beginDate,
      endDate: value.endDate,
      addressUsageSigCode: value.addressUsageSigCode
        ? value.addressUsageSigCode
        : DEFAULT_DD_VALUE,
      changeReasonCode: value.changeReasonCode
        ? value.changeReasonCode
        : DEFAULT_DD_VALUE,
      statusCode: value.statusCode ? value.statusCode : DEFAULT_DD_VALUE,
      addressRepCrossReferences: [],
      addressUsageSequenceNumber: 1,
      addressUsageSK: null,
      commonEntitySK: null,
      addressUsageVersionNumber: "0",
      addressLine1: value.addressLine1 ? value.addressLine1 : null,
      addressLine2: value.addressLine2 ? value.addressLine2 : null,
      addressLine3: value.addressLine3 ? value.addressLine3 : null,
      addressLine4: value.addressLine4 ? value.addressLine4 : null,
      statusDesc: null,
      addressTypeDesc: null,
      countyDesc: null,
      latitudeNumber: value.latitudeNumber ? value.latitudeNumber : null,
      longitudeNumber: value.longitudeNumber ? value.longitudeNumber : null,
      city: value.city ? value.city : null,
      state: value.state ? value.state : DEFAULT_DD_VALUE,
      zipCode5: value.zipCode5 ? value.zipCode5 : null,
      zipCode4: null,
      town: value.town,
      country: value.country ? value.country : DEFAULT_DD_VALUE,
      county: value.county ? value.county : DEFAULT_DD_VALUE,
      uspsAddressVerifyCode: null,
      uspsAddressLine1: null,
      uspsAddressLine2: null,
      addressVersionNumber: 0,
      id: value.id
    });
    settempAddAddress({
      auditUserID: logInUserID,
      auditTimeStamp: DateUtilities.getUTCTimeStamp(),
      addedAuditUserID: logInUserID,
      addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
      versionNo: 0,
      dbRecord: false,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      addressUsageTypeCode: value.addressUsageTypeCode
        ? value.addressUsageTypeCode
        : DEFAULT_DD_VALUE,
      addressSK: null,
      beginDate: value.beginDate,
      endDate: value.endDate,
      addressUsageSigCode: value.addressUsageSigCode
        ? value.addressUsageSigCode
        : DEFAULT_DD_VALUE,
      changeReasonCode: value.changeReasonCode
        ? value.changeReasonCode
        : DEFAULT_DD_VALUE,
      statusCode: value.statusCode ? value.statusCode : DEFAULT_DD_VALUE,
      addressRepCrossReferences: [],
      addressUsageSequenceNumber: 1,
      addressUsageSK: null,
      commonEntitySK: null,
      addressUsageVersionNumber: "0",
      addressLine1: value.addressLine1 ? value.addressLine1 : null,
      addressLine2: value.addressLine2 ? value.addressLine2 : null,
      addressLine3: value.addressLine3 ? value.addressLine3 : null,
      addressLine4: value.addressLine4 ? value.addressLine4 : null,
      statusDesc: null,
      addressTypeDesc: null,
      countyDesc: null,
      latitudeNumber: value.latitudeNumber ? value.latitudeNumber : null,
      longitudeNumber: value.longitudeNumber ? value.longitudeNumber : null,
      city: value.city ? value.city : null,
      state: value.state ? value.state : DEFAULT_DD_VALUE,
      zipCode5: value.zipCode5 ? value.zipCode5 : null,
      zipCode4: null,
      town: value.town,
      country: value.country ? value.country : DEFAULT_DD_VALUE,
      county: value.county ? value.county : DEFAULT_DD_VALUE,
      uspsAddressVerifyCode: null,
      uspsAddressLine1: null,
      uspsAddressLine2: null,
      addressVersionNumber: 0,
      id: value.id
    });
    if (open && document.getElementById("address_form_pop_up")) {
 document.getElementById("address_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
}
  };

  const handleClickOpen = () => {
    const errorMessagesArray = validateMasterInputs(false);
    if (errorMessagesArray.length === 0) {
      setOpen(true);
      setDataElement({
        code: "",
        longDescription: "",
        description: ""
      });
      setAddAddress({
        auditUserID: logInUserID,
        auditTimeStamp: DateUtilities.getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        addressUsageTypeCode: DEFAULT_DD_VALUE,
        addressSK: null,
        beginDate: "",
        endDate: "",
        addressUsageSigCode: DEFAULT_DD_VALUE,
        changeReasonCode: DEFAULT_DD_VALUE,
        statusCode: DEFAULT_DD_VALUE,
        addressRepCrossReferences: [],
        addressUsageSequenceNumber: 1,
        addressUsageSK: null,
        commonEntitySK: null,
        addressUsageVersionNumber: "0",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        addressLine4: "",
        statusDesc: null,
        addressTypeDesc: null,
        countyDesc: null,
        latitudeNumber: "",
        longitudeNumber: "",
        city: "",
        state: DEFAULT_DD_VALUE,
        zipCode5: "",
        zipCode4: "",
        town: "",
        country: DEFAULT_DD_VALUE,
        county: DEFAULT_DD_VALUE,
        uspsAddressVerifyCode: null,
        uspsAddressLine1: null,
        uspsAddressLine2: null,
        addressVersionNumber: 0
      });
      setUspsAddress({
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode5: ""
      });
      setSelectedAddAddressEndDate(null);
      setShowError(false);
    } else {
      seterrorMessages(errorMessagesArray);
    }
    if (open && document.getElementById("address_form_pop_up")) {
 document.getElementById("address_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
}
  };

  const handleClickOpenLockBox = () => {
    const errorMessagesArray = validateMasterInputs(false);
    if (errorMessagesArray.length === 0) {
      setSelectedLockBoxBeginDate(null);
      setSelectedLockBoxEndDate(null);
      setOpenLockBox(true);
      setlockBoxDetails({
        beginDate: "",
        endDate: "",
        lockBoxNumber: "",
        lockBoxName: ""
      });
      setSelectedRow(null);
    } else {
      seterrorMessages(errorMessagesArray);
    }
    if (openLockBox && document.getElementById("lockbox_form_pop_up")) {
      document.getElementById("lockbox_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  const handleClickOpenContact = () => {
    const errorMessagesArray = validateMasterInputs(false);
    if (errorMessagesArray.length === 0) {
      setOpenContact(true);
      setSelectedContactEndDate(null);
      setSelectedContactDOB(null);
      setSelectedContactDOD(null);
      setContactDetails({
        auditUserID: logInUserID,
        auditTimeStamp: DateUtilities.getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: true,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        contactType: DEFAULT_DD_VALUE,
        addressType: null,
        contactSSN: "",
        contactDob: null,
        contactDod: null,
        contactStatusDesc: null,
        contactTypeDesc: null,
        contactTitle: "",
        significanceTypeCode: DEFAULT_DD_VALUE,
        status: null,
        contactAddrBeginDate: null,
        contactAddrEndDate: null,
        gender: DEFAULT_DD_VALUE,
        streetaddress: null,
        zipcode: null,
        dummyInd: null,
        addressUsageSequenceNumber: null,
        statusCode: DEFAULT_DD_VALUE,
        changeReason: null,
        beginDate: null,
        endDate: null,
        addressTypeRowIndex: null,
        latitudeNumber: null,
        longitudeNumber: null,
        uspsAddressLine1: null,
        uspsAddressLine2: null,
        uspsAddressVerifyCode: null,
        addressUsageSK: null,
        prefix: DEFAULT_DD_VALUE,
        lastName: "",
        firstName: "",
        middleName: "",
        suffix: DEFAULT_DD_VALUE,
        phone: null,
        phoneExtn: null,
        email: null,
        addressOne: null,
        addressTwo: null,
        addressThree: null,
        addressFour: null,
        county: null,
        country: null,
        town: null,
        addressVerification: null,
        uspsVerifiedCode: null,
        latitude: null,
        longitude: null,
        city: null,
        state: null,
        zipOne: null,
        zipTwo: null,
        representativeSK: null,
        eaddressSK: null,
        phoneSK: null,
        addressSK: null,
        countryCode: null,
        countyCode: null,
        addressBeginDateStr: null,
        addressEndDateStr: null,
        mailReturnedIndicator: false,
        addressVoidIndicator: false,
        addressPendingIndicator: false,
        phoneBeginDateStr: null,
        phoneEndDateStr: null,
        phoneVoidIndicator: false,
        phoneOutOfServiceIndicator: false,
        emailAddressBeginDateStr: null,
        emailAddressEndDateStr: null,
        emailAddressVoidIndicator: false,
        emailAddressBouncedIndicator: false,
        contactTypeRowIndex: null,
        commonEntityVersionNumber: 0,
        repCommonEntitySK: null,
        renderContactSection: false,
        renderAddContactSection: false,
        renderEditContactSection: false,
        renderDeleteContactSection: false,
        contactKey: null,
        contactRowIndex: null,
        renderAdditionalContactSection: false,
        renderMailingAddressSection: false,
        commonEntityCrossRefVersionNumber: 0,
        representativeVersionNumber: 0,
        addressUsageVersionNumber: 0,
        addressVersionNumber: 0,
        returnedMailVersionNumber: 0,
        phoneUsageVersionNumber: 0,
        phoneVersionNumber: 0,
        emailUsageVersionNumber: 0,
        emailVersionNumber: 0,
        renderPlusMailingAddressSection: false,
        renderPlusAdditionalContactSection: false,
        nameVrsnNo: 0,
        cmnEntityTypVrsnNo: 0,
        commonEntitySK: null,
        repCommonEntity: null,
        disableContactType: false
      });
      setSelectedRow(null);
    } else {
      seterrorMessages(errorMessagesArray);
    }
    if (openContact && document.getElementById("contact_form_pop_up")) {
 document.getElementById("contact_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
}
  };

  const handleClose = () => {
    handleResetAddress();
    errorMessagesArray = [];
    seterrorMessages(errorMessagesArray);
    setOpen(false);
    setSelectedRow(null);
    setVerified(false);
    setShowAdditionalDetails1(false);
    setUspsAddress({
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode5: ""
    });
  };

  const handleCloseLockBox = () => {
    handleResetLockBox(lockBoxDetails);
    errorMessagesArray = [];
    seterrorMessages(errorMessagesArray);
    setOpenLockBox(false);
    setSelectedRow(null);
  };

  const handleContactClose = () => {
    handleResetContact();
    errorMessagesArray = [];
    seterrorMessages(errorMessagesArray);
    setOpenContact(false);
    setSelectedRow(null);
  };

  const handleChangeTabs = (event, newValue) => {
    handleClose();
    handleCloseLockBox();
    handleContactClose();
    setValue(newValue);
  };
  const handleChange = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChangeLockBox = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setlockBoxDetails({ ...lockBoxDetails, [name]: event.target.value });
  };

  const handleChangeAddAddress = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setAddAddress({ ...addAddress, [name]: event.target.value });
    if (
      name === "addressLine1" ||
      name === "addressLine2" ||
      name === "city" ||
      name === "state" ||
      name === "zipCode5"
    ) {
      setVerified(false);
    }
  };

  const handleAddContact = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setContactDetails({ ...contactDetails, [name]: event.target.value });
  };
  const handleResetLockBox = () => {
    setOpenLockBox(true);
    if (selectedRow === null) {
      setlockBoxDetails({
        lockBoxNumber: "",
        lockBoxName: ""
      });
      setSelectedLockBoxBeginDate(null);
      setSelectedLockBoxEndDate(null);
    } else {
      setlockBoxDetails(templockBoxDetails);
      setSelectedLockBoxBeginDate(new Date(templockBoxDetails.beginDate));
      setSelectedLockBoxEndDate(new Date(templockBoxDetails.endDate));
    }
    setShowError(false);
    errorMessagesArray = [];
    seterrorMessages(errorMessagesArray);
  };
  const handleResetAddress = () => {
    setConsiderUSPS("Y");
    setUspsAddress({
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode5: ""
    });
    setShowAdditionalDetails1(false);
    setAddressError([]);
    seterrorMessages([]);
    if (selectedRow === null) {
      setAddAddress({
        auditUserID: logInUserID,
        auditTimeStamp: DateUtilities.getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        addressUsageTypeCode: DEFAULT_DD_VALUE,
        addressSK: null,
        beginDate: "",
        endDate: "",
        addressUsageSigCode: DEFAULT_DD_VALUE,
        changeReasonCode: DEFAULT_DD_VALUE,
        statusCode: DEFAULT_DD_VALUE,
        addressRepCrossReferences: [],
        addressUsageSequenceNumber: 1,
        addressUsageSK: null,
        commonEntitySK: null,
        addressUsageVersionNumber: "0",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        addressLine4: "",
        statusDesc: null,
        addressTypeDesc: null,
        countyDesc: null,
        latitudeNumber: "",
        longitudeNumber: "",
        city: "",
        state: DEFAULT_DD_VALUE,
        zipCode5: "",
        zipCode4: "",
        town: "",
        country: DEFAULT_DD_VALUE,
        county: DEFAULT_DD_VALUE,
        uspsAddressVerifyCode: null,
        uspsAddressLine1: null,
        uspsAddressLine2: null,
        addressVersionNumber: 0
      });
      if (selectedDate) {
        setSelectedAddAddressBeginDate(selectedDate);
      } else {
        setSelectedAddAddressBeginDate(null);
      }
      setSelectedAddAddressEndDate(null);
    } else {
      setAddAddress(tempAddAddress);
      setSelectedAddAddressBeginDate(
        tempAddAddress.beginDate !== ""
          ? new Date(tempAddAddress.beginDate)
          : null
      );
      setSelectedAddAddressEndDate(new Date(tempAddAddress.endDate));
    }
    setShowError(false);
    errorMessagesArray = [];
    seterrorMessages(errorMessagesArray);
  };

  const handleResetContact = () => {
    if (selectedRow === null) {
      setContactDetails({
        auditUserID: logInUserID,
        auditTimeStamp: DateUtilities.getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: true,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        contactType: DEFAULT_DD_VALUE,
        addressType: null,
        contactSSN: "",
        contactDob: null,
        contactDod: null,
        contactStatusDesc: null,
        contactTypeDesc: null,
        contactTitle: "",
        significanceTypeCode: DEFAULT_DD_VALUE,
        status: null,
        contactAddrBeginDate: null,
        contactAddrEndDate: null,
        gender: DEFAULT_DD_VALUE,
        streetaddress: null,
        zipcode: null,
        dummyInd: null,
        addressUsageSequenceNumber: null,
        statusCode: DEFAULT_DD_VALUE,
        changeReason: null,
        beginDate: null,
        endDate: null,
        addressTypeRowIndex: null,
        latitudeNumber: null,
        longitudeNumber: null,
        uspsAddressLine1: null,
        uspsAddressLine2: null,
        uspsAddressVerifyCode: null,
        addressUsageSK: null,
        prefix: DEFAULT_DD_VALUE,
        lastName: "",
        firstName: "",
        middleName: "",
        suffix: DEFAULT_DD_VALUE,
        phone: null,
        phoneExtn: null,
        email: null,
        addressOne: null,
        addressTwo: null,
        addressThree: null,
        addressFour: null,
        county: null,
        country: null,
        town: null,
        addressVerification: null,
        uspsVerifiedCode: null,
        latitude: null,
        longitude: null,
        city: null,
        state: null,
        zipOne: null,
        zipTwo: null,
        representativeSK: null,
        eaddressSK: null,
        phoneSK: null,
        addressSK: null,
        countryCode: null,
        countyCode: null,
        addressBeginDateStr: null,
        addressEndDateStr: null,
        mailReturnedIndicator: false,
        addressVoidIndicator: false,
        addressPendingIndicator: false,
        phoneBeginDateStr: null,
        phoneEndDateStr: null,
        phoneVoidIndicator: false,
        phoneOutOfServiceIndicator: false,
        emailAddressBeginDateStr: null,
        emailAddressEndDateStr: null,
        emailAddressVoidIndicator: false,
        emailAddressBouncedIndicator: false,
        contactTypeRowIndex: null,
        commonEntityVersionNumber: 0,
        repCommonEntitySK: null,
        renderContactSection: false,
        renderAddContactSection: false,
        renderEditContactSection: false,
        renderDeleteContactSection: false,
        contactKey: null,
        contactRowIndex: null,
        renderAdditionalContactSection: false,
        renderMailingAddressSection: false,
        commonEntityCrossRefVersionNumber: 0,
        representativeVersionNumber: 0,
        addressUsageVersionNumber: 0,
        addressVersionNumber: 0,
        returnedMailVersionNumber: 0,
        phoneUsageVersionNumber: 0,
        phoneVersionNumber: 0,
        emailUsageVersionNumber: 0,
        emailVersionNumber: 0,
        renderPlusMailingAddressSection: false,
        renderPlusAdditionalContactSection: false,
        nameVrsnNo: 0,
        cmnEntityTypVrsnNo: 0,
        commonEntitySK: null,
        repCommonEntity: null,
        disableContactType: false
      });
      if (selectedDate) {
        setSelectedContactBeginDate(selectedDate);
      } else {
        setSelectedContactBeginDate(null);
      }
      setSelectedContactEndDate(null);
      setSelectedContactDOB(null);
      setSelectedContactDOD(null);
    } else {
      setContactDetails(tempContactDetails);
      setSelectedContactBeginDate(
        new Date(contactDetails.contactAddrBeginDate)
      );
      setSelectedContactEndDate(new Date(contactDetails.contactAddrEndDate));
      setSelectedContactDOB(
        contactDetails.contactDob ? new Date(contactDetails.contactDob) : null
      );
      setSelectedContactDOD(
        contactDetails.contactDod ? new Date(contactDetails.contactDod) : null
      );
      errorMessagesArray = [];
      seterrorMessages(errorMessagesArray);
    }
    setShowError(false);
    errorMessagesArray = [];
    seterrorMessages(errorMessagesArray);
  };

  const handleSaveLockBox = () => {
    errorMessagesArray = [];
    seterrorMessages([]);
    var showLockBoxNameError;
    var showLockBoxBeginDateGreaterError;
    var showLockBoxNumberError;
    var showLockBoxBeginDateMatchError;
    var showLockBoxBeginDateError;
    var showLockBoxEndDateError;
    var showLockBoxEndDateMatchError;
    var showLockBoxDuplicateNumberError;
    var validLockBoxBeginDateShow;
    var validLockBoxEndDateShow;
    var isLockBoxBeginDateValid;
    var isLockBoxEndDateValid;
    var invalidLockBoxBeginDateError;
    var invalidLockBoxEndDateError;
    var showLockBoxBeginDateSmallerError = false;
    var overLapDateError;
    if (!lockBoxDetails.lockBoxName) {
      showLockBoxNameError = true;
      errorMessagesArray.push("LockBox Name " + ErrorConstants.NAME_ERROR);
    }
    if (!lockBoxDetails.lockBoxNumber) {
      showLockBoxNumberError = true;
      errorMessagesArray.push("LockBox # " + ErrorConstants.NAME_ERROR);
    }
    // Begin Date Validations
    if (!selectedLockBoxBeginDate) {
      showLockBoxBeginDateError = true;
      errorMessagesArray.push("Begin Date " + ErrorConstants.NAME_ERROR);
    }
    if (
      selectedLockBoxBeginDate &&
      selectedLockBoxBeginDate.toString() === "Invalid Date"
    ) {
      invalidLockBoxBeginDateError = true;
      errorMessagesArray.push("Begin Date " + ErrorConstants.DATE_INVALID);
    }
    if (
      selectedLockBoxBeginDate &&
      selectedLockBoxBeginDate.toString() !== "Invalid Date"
    ) {
      const isDateValid = DateUtilities.validateDateMinimumValue(
        selectedLockBoxBeginDate
      );
      if (isDateValid) {
        isLockBoxBeginDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        !isDateValid &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormat(selectedLockBoxBeginDate)
        ) < new Date(DateUtilities.getDateInMMDDYYYYFormat(new Date()))
      ) {
        showLockBoxBeginDateSmallerError = true;
        errorMessagesArray.push(
          ErrorConstants.LOCKBOX_BEGIN_DATE_SMALLER_ERROR
        );
      }
      if (
        !isDateValid &&
        !showLockBoxBeginDateSmallerError &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormat(selectedLockBoxBeginDate)
        ) < new Date(DateUtilities.getDateInMMDDYYYYFormat(selectedDate))
      ) {
        showLockBoxBeginDateMatchError = true;
        errorMessagesArray.push(ErrorConstants.LOCKBOX_DATES_MATCH_ERROR);
      }
    }
    // End Date Validations
    var tempSelectedLockBoxEndDate = new Date(selectedLockBoxEndDate);
    if (!selectedLockBoxEndDate) {
      const date = new Date("12/31/9999");
      setSelectedLockBoxEndDate(date);
      tempSelectedLockBoxEndDate = date;
    }
    if (
      tempSelectedLockBoxEndDate &&
      tempSelectedLockBoxEndDate.toString() === "Invalid Date"
    ) {
      invalidLockBoxEndDateError = true;
      errorMessagesArray.push("End Date " + ErrorConstants.DATE_INVALID);
    }
    if (
      tempSelectedLockBoxEndDate &&
      tempSelectedLockBoxEndDate.toString() !== "Invalid Date"
    ) {
      const isDateValid = DateUtilities.validateDateMinimumValue(
        tempSelectedLockBoxEndDate
      );
      if (isDateValid) {
        isLockBoxEndDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        !isDateValid &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
            tempSelectedLockBoxEndDate
          )
        ) >
          new Date(
            DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEndDate)
          )
      ) {
        showLockBoxEndDateMatchError = true;
        errorMessagesArray.push(ErrorConstants.LOCKBOX_DATES_MATCH_ERROR);
      }
    }
    // Begin Date & End Date comparison
    if (
      selectedLockBoxBeginDate &&
      tempSelectedLockBoxEndDate &&
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
          tempSelectedLockBoxEndDate
        )
      ) <
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
            selectedLockBoxBeginDate
          )
        )
    ) {
      showLockBoxBeginDateGreaterError = true;
      errorMessagesArray.push(ErrorConstants.LOCKBOX_BEGIN_DATE_GREATER_ERROR);
    }

    // overlapping dates
    if (selectedLockBoxBeginDate && selectedLockBoxEndDate) {
      for (var i = 0; i < lockBoxTableData.length; i++) {
        if (selectedRow) {
          if (
            selectedRow !== i + 1 &&
            lockBoxTableData[i].beginDate <=
            DateUtilities.getDateInMMDDYYYYFormat(selectedLockBoxBeginDate) &&
            DateUtilities.getDateInMMDDYYYYFormat(selectedLockBoxBeginDate) <=
            lockBoxTableData[i].endDate
          ) {
            overLapDateError = true;
            errorMessagesArray.push(
              ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
            );
            break;
          }
        } else if (
          lockBoxTableData[i].beginDate <=
          DateUtilities.getDateInMMDDYYYYFormat(selectedLockBoxBeginDate) &&
          DateUtilities.getDateInMMDDYYYYFormat(selectedLockBoxBeginDate) <=
          lockBoxTableData[i].endDate
        ) {
          overLapDateError = true;
          errorMessagesArray.push(
            ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
          );
          break;
        }
      }
    }

    // Duplicate Lock Box Check
    if (lockBoxTableData.length > 0) {
      for (let i = 0; i < lockBoxTableData.length; i++) {
        if (selectedRow) {
          if (
            selectedRow !== i + 1 &&
            lockBoxTableData[i].lockBoxNumber === lockBoxDetails.lockBoxNumber
          ) {
            errorMessagesArray.push(
              ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
            );
            showLockBoxDuplicateNumberError = true;
            break;
          }
        } else if (
          lockBoxTableData[i].lockBoxNumber === lockBoxDetails.lockBoxNumber
        ) {
          errorMessagesArray.push(
            ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
          );
          showLockBoxDuplicateNumberError = true;
          break;
        }
      }
    }
    if (errorMessagesArray.length <= 0) {
      if (selectedRow === null) {
        lockBoxDetails.auditUserID = logInUserID;
        lockBoxDetails.auditTimeStamp = DateUtilities.getUTCTimeStamp();
        lockBoxDetails.addedAuditUserID = logInUserID;
        lockBoxDetails.addedAuditTimeStamp = DateUtilities.getUTCTimeStamp();
        lockBoxDetails.versionNo = 0;
        lockBoxDetails.dbRecord = true;
        lockBoxDetails.sortColumn = null;
        lockBoxDetails.auditKeyList = [];
        lockBoxDetails.auditKeyListFiltered = false;
        lockBoxDetails.lockBoxId = null;
        lockBoxDetails.renderLockBoxSection = false;
        lockBoxDetails.renderDeleteLockBoxHeader = false;
        lockBoxDetails.lockBoxKey = null;
        lockBoxDetails.lockBoxRowIndex = null;
        lockBoxDetails.renderAddLockBoxHeader = false;
        lockBoxDetails.renderEditLockBoxHeader = false;
        lockBoxDetails.disableLockBoxNumber = false;
        lockBoxDetails.beginDate = DateUtilities.getDateInMMDDYYYYFormat(
          selectedLockBoxBeginDate
        );
        lockBoxDetails.endDate = DateUtilities.getDateInMMDDYYYYFormat(
          tempSelectedLockBoxEndDate
        );
        lockBoxDetails.lockBoxBeginDate = lockBoxDetails.beginDate;
        lockBoxDetails.lockBoxEndDate = lockBoxDetails.endDate;
        if (lockBoxTableData.length === 0) {
          let id = lockBoxTableData.length;
          id++;
          lockBoxDetails.id = id;
          setLockBoxTableData(lockBoxTableData => [
            ...lockBoxTableData,
            lockBoxDetails
          ]);
        } else {
          let id = lockBoxTableData.length;
          id = lockBoxTableData[id - 1].id;
          id++;
          lockBoxDetails.id = id;
          setLockBoxTableData(lockBoxTableData => [
            ...lockBoxTableData,
            lockBoxDetails
          ]);
        }
      } else {
        if (lockBoxTableData.length === 0) {
          let id = lockBoxTableData.length;
          id++;
          lockBoxDetails.id = id;
          setLockBoxTableData(lockBoxTableData => [
            ...lockBoxTableData,
            lockBoxDetails
          ]);
        } else {
          lockBoxTableData.map(element => {
            if (element.id === selectedRow) {
              lockBoxDetails.beginDate = DateUtilities.getDateInMMDDYYYYFormat(
                selectedLockBoxBeginDate
              );
              lockBoxDetails.endDate = DateUtilities.getDateInMMDDYYYYFormat(
                tempSelectedLockBoxEndDate
              );
              element.beginDate = lockBoxDetails.beginDate;
              element.endDate = lockBoxDetails.endDate;
              element.lockBoxName = lockBoxDetails.lockBoxName;
              element.lockBoxNumber = lockBoxDetails.lockBoxNumber;
              element.lockBoxBeginDate = lockBoxDetails.beginDate;
              element.lockBoxEndDate = lockBoxDetails.endDate;
            }
          });
        }
      }
      setOpenLockBox(false);
      setlockBoxDetails({
        lockBoxNumber: "",
        lockBoxName: ""
      });
      setSelectedLockBoxBeginDate(null);
      setSelectedLockBoxEndDate(null);
      setSelectedRow(null);
    }
    setShowError({
      showLockBoxNameError: showLockBoxNameError,
      showLockBoxNumberError: showLockBoxNumberError,
      showLockBoxBeginDateError: showLockBoxBeginDateError,
      showLockBoxEndDateError: showLockBoxEndDateError,
      showLockBoxBeginDateGreaterError: showLockBoxBeginDateGreaterError,
      showLockBoxBeginDateSmallerError: showLockBoxBeginDateSmallerError,
      showLockBoxEndDateMatchError: showLockBoxEndDateMatchError,
      showLockBoxBeginDateMatchError: showLockBoxBeginDateMatchError,
      validLockBoxBeginDateShow: validLockBoxBeginDateShow,
      validLockBoxEndDateShow: validLockBoxEndDateShow,
      invalidLockBoxBeginDateError: invalidLockBoxBeginDateError,
      invalidLockBoxEndDateError: invalidLockBoxEndDateError,
      isLockBoxBeginDateValid: isLockBoxBeginDateValid,
      isLockBoxEndDateValid: isLockBoxEndDateValid,
      showLockBoxDuplicateNumberError: showLockBoxDuplicateNumberError,
      overLapDateError: overLapDateError
    });
    seterrorMessages(errorMessagesArray);
  };

  const handleSaveContact = () => {
    errorMessagesArray = [];
    seterrorMessages([]);
    var showStatusrror;
    var showContactTypeError;
    var showContactBeginDateError;
    var showContactEndDateError;
    var showSignificanceError;
    var showContactFName;
    var showContactMName;
    var showContactLName;
    var showContactDateMatchError;
    var showContactEndDateMatchError;
    var showContactBeginDateGreaterError;
    var validContactEndDateShow;
    var isContactEndDateValid;
    var invalidContactBeginDateError;
    var invalidContactEndDateError;
    var isContactBeginDateValid;
    var invalidDateOfBirthError;
    var invalidDateOfDeathError;
    var showDeathDateError = false;

    if (
      !contactDetails.statusCode ||
      contactDetails.statusCode === DEFAULT_DD_VALUE
    ) {
      showStatusrror = true;
      errorMessagesArray.push("Status " + ErrorConstants.NAME_ERROR);
    }
    if (
      !contactDetails.contactType ||
      contactDetails.contactType === DEFAULT_DD_VALUE
    ) {
      showContactTypeError = true;
      errorMessagesArray.push("Contact Type " + ErrorConstants.NAME_ERROR);
    }
    if (!selectedContactBeginDate) {
      showContactBeginDateError = true;
      errorMessagesArray.push("Begin Date " + ErrorConstants.NAME_ERROR);
    }
    if (
      !contactDetails.significanceTypeCode ||
      contactDetails.significanceTypeCode === DEFAULT_DD_VALUE
    ) {
      showSignificanceError = true;
      errorMessagesArray.push("Significance " + ErrorConstants.NAME_ERROR);
    }
    if (!contactDetails.firstName) {
      showContactFName = true;
      errorMessagesArray.push("First Name " + ErrorConstants.NAME_ERROR);
    }
    if (!contactDetails.lastName) {
      showContactLName = true;
      errorMessagesArray.push("Last Name " + ErrorConstants.NAME_ERROR);
    }
    // Begin Date Validation
    if (
      selectedContactBeginDate &&
      selectedContactBeginDate.toString() === "Invalid Date"
    ) {
      invalidContactBeginDateError = true;
      errorMessagesArray.push("Begin Date " + ErrorConstants.DATE_INVALID);
    }
    if (
      selectedContactBeginDate &&
      selectedContactBeginDate.toString() !== "Invalid Date"
    ) {
      const isDateValid = DateUtilities.validateDateMinimumValue(
        selectedContactBeginDate
      );
      if (isDateValid) {
        isContactBeginDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        !isDateValid &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
            selectedContactBeginDate
          )
        ) <
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedDate)
        )
      ) {
        showContactDateMatchError = true;
        errorMessagesArray.push(ErrorConstants.BEGIN_DATE_MATCH_ERROR);
      }
    }
    // End Date Validation
    var tempContactEndDate = selectedContactEndDate;
    if (!selectedContactEndDate) {
      setSelectedContactEndDate(new Date("12/31/9999"));
      tempContactEndDate = new Date("12/31/9999");
    }
    if (
      tempContactEndDate &&
      tempContactEndDate.toString() === "Invalid Date"
    ) {
      invalidContactEndDateError = true;
      errorMessagesArray.push("End Date " + ErrorConstants.DATE_INVALID);
    }
    if (
      tempContactEndDate &&
      tempContactEndDate.toString() !== "Invalid Date"
    ) {
      const isDateValid = DateUtilities.validateDateMinimumValue(
        tempContactEndDate
      );
      if (isDateValid) {
        isContactEndDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        !isDateValid &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(tempContactEndDate)
        ) >
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEndDate)
        )
      ) {
        showContactEndDateMatchError = true;
        errorMessagesArray.push(ErrorConstants.BEGIN_DATE_MATCH_ERROR);
      }
    }
    // Begin Date + End Date Comparison
    if (
      selectedContactBeginDate &&
      tempContactEndDate &&
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
          selectedContactBeginDate
        )
      ) >
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(tempContactEndDate)
      )
    ) {
      showContactBeginDateGreaterError = true;
      errorMessagesArray.push(ErrorConstants.CONTACT_BEGIN_DATE_GREATER_ERROR);
    }

    // DOD & DOB Validation
    if (
      selectedContactDOB &&
      selectedContactDOB.toString() === "Invalid Date"
    ) {
      invalidDateOfBirthError = true;
      errorMessagesArray.push("Date of Birth " + ErrorConstants.DATE_INVALID);
    }
    if (
      selectedContactDOD &&
      selectedContactDOD.toString() === "Invalid Date"
    ) {
      invalidDateOfDeathError = true;
      errorMessagesArray.push("Date of Death " + ErrorConstants.DATE_INVALID);
    }
    if (
      selectedContactDOB &&
      selectedContactDOD &&
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedContactDOB)
      ) >
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedContactDOD)
      )
    ) {
      showDeathDateError = true;
      errorMessagesArray.push(ErrorConstants.DEATH_DATE_ERROR);
    }
    if (errorMessagesArray.length === 0) {
      for (const key in contactDetails) {
        if (contactDetails[key] === DEFAULT_DD_VALUE) {
          contactDetails[key] = "";
        }
      }
      if (selectedRow === null) {
        setSelectedContactBeginDate(new Date(selectedContactBeginDate));
        setSelectedContactEndDate(new Date(tempContactEndDate));
        if (addContactsData.length === 0) {
          let id = addContactsData.length;
          id++;
          contactDetails.id = id;
          contactDetails.contactAddrBeginDate =
            selectedContactBeginDate.getMonth() +
            1 +
            "/" +
            selectedContactBeginDate.getDate() +
            "/" +
            selectedContactBeginDate.getFullYear();
          contactDetails.contactAddrEndDate =
            tempContactEndDate.getMonth() +
            1 +
            "/" +
            tempContactEndDate.getDate() +
            "/" +
            tempContactEndDate.getFullYear();
          if (selectedContactDOB) {
            contactDetails.contactDob =
              selectedContactDOB.getMonth() +
              1 +
              "/" +
              selectedContactDOB.getDate() +
              "/" +
              selectedContactDOB.getFullYear();
          }
          if (selectedContactDOD) {
            contactDetails.contactDod =
              selectedContactDOD.getMonth() +
              1 +
              "/" +
              selectedContactDOD.getDate() +
              "/" +
              selectedContactDOD.getFullYear();
          }
          setaddContactsData(addContactsData => [
            ...addContactsData,
            contactDetails
          ]);
        } else {
          let id = addContactsData.length;
          id = addContactsData[id - 1].id;
          id++;
          contactDetails.id = id;
          contactDetails.contactAddrBeginDate =
            selectedContactBeginDate.getMonth() +
            1 +
            "/" +
            selectedContactBeginDate.getDate() +
            "/" +
            selectedContactBeginDate.getFullYear();
          contactDetails.contactAddrEndDate =
            tempContactEndDate.getMonth() +
            1 +
            "/" +
            tempContactEndDate.getDate() +
            "/" +
            tempContactEndDate.getFullYear();
          if (selectedContactDOB) {
            contactDetails.contactDob =
              selectedContactDOB.getMonth() +
              1 +
              "/" +
              selectedContactDOB.getDate() +
              "/" +
              selectedContactDOB.getFullYear();
          }
          if (selectedContactDOD) {
            contactDetails.contactDod =
              selectedContactDOD.getMonth() +
              1 +
              "/" +
              selectedContactDOD.getDate() +
              "/" +
              selectedContactDOD.getFullYear();
          }
          setaddContactsData(addContactsData => [
            ...addContactsData,
            contactDetails
          ]);
        }
      } else {
        if (addContactsData.length === 0) {
          let id = addContactsData.length;
          id++;
          contactDetails.id = id;
          contactDetails.contactAddrBeginDate =
            selectedContactBeginDate.getMonth() +
            1 +
            "/" +
            selectedContactBeginDate.getDate() +
            "/" +
            selectedContactBeginDate.getFullYear();
          contactDetails.contactAddrEndDate =
            tempContactEndDate.getMonth() +
            1 +
            "/" +
            tempContactEndDate.getDate() +
            "/" +
            tempContactEndDate.getFullYear();
          if (selectedContactDOB) {
            contactDetails.contactDob =
              selectedContactDOB.getMonth() +
              1 +
              "/" +
              selectedContactDOB.getDate() +
              "/" +
              selectedContactDOB.getFullYear();
          }
          if (selectedContactDOD) {
            contactDetails.contactDod =
              selectedContactDOD.getMonth() +
              1 +
              "/" +
              selectedContactDOD.getDate() +
              "/" +
              selectedContactDOD.getFullYear();
          }
          setaddContactsData(addContactsData => [
            ...addContactsData,
            contactDetails
          ]);
        } else {
          addContactsData.map(element => {
            if (element.id === selectedRow) {
              contactDetails.contactAddrBeginDate =
                selectedContactBeginDate.getMonth() +
                1 +
                "/" +
                selectedContactBeginDate.getDate() +
                "/" +
                selectedContactBeginDate.getFullYear();
              contactDetails.contactAddrEndDate =
                tempContactEndDate.getMonth() +
                1 +
                "/" +
                tempContactEndDate.getDate() +
                "/" +
                tempContactEndDate.getFullYear();
              if (selectedContactDOB) {
                contactDetails.contactDob =
                  selectedContactDOB.getMonth() +
                  1 +
                  "/" +
                  selectedContactDOB.getDate() +
                  "/" +
                  selectedContactDOB.getFullYear();
              }
              if (selectedContactDOD) {
                contactDetails.contactDod =
                  selectedContactDOD.getMonth() +
                  1 +
                  "/" +
                  selectedContactDOD.getDate() +
                  "/" +
                  selectedContactDOD.getFullYear();
              }
              element.beginDate = contactDetails.beginDate;
              element.endDate = contactDetails.endDate;
              element.contactAddrBeginDate =
                contactDetails.contactAddrBeginDate;
              element.contactAddrEndDate = contactDetails.contactAddrEndDate;
              element.statusCode = contactDetails.statusCode;
              element.contactType = contactDetails.contactType;
              element.significanceTypeCode =
                contactDetails.significanceTypeCode;
              element.contactTitle = contactDetails.contactTitle;
              element.prefix = contactDetails.prefix;
              element.firstName = contactDetails.firstName;
              element.middleName = contactDetails.middleName;
              element.lastName = contactDetails.lastName;
              element.suffix = contactDetails.suffix;
              element.contactSSN = contactDetails.contactSSN;
              element.contactDob = contactDetails.contactDob
                ? contactDetails.contactDob
                : null;
              element.contactDod = contactDetails.contactDod
                ? contactDetails.contactDod
                : null;
              element.gender = contactDetails.gender;
            }
          });
          filterContactData(addContactsData);
        }
      }
      setOpenContact(false);
      setContactDetails({
        contactType: DEFAULT_DD_VALUE,
        contactSSN: "",
        contactDob: "",
        contactDod: "",
        contactTitle: "",
        significanceTypeCode: DEFAULT_DD_VALUE,
        gender: DEFAULT_DD_VALUE,
        statusCode: DEFAULT_DD_VALUE,
        beginDate: "",
        endDate: "",
        prefix: DEFAULT_DD_VALUE,
        lastName: "",
        firstName: "",
        middleName: "",
        suffix: DEFAULT_DD_VALUE,
        phone: null,
        auditUserID: logInUserID,
        auditTimeStamp: DateUtilities.getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: true,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        addressType: null,
        contactStatusDesc: null,
        contactTypeDesc: null,
        status: null,
        contactAddrBeginDate: null,
        contactAddrEndDate: null,
        streetaddress: null,
        zipcode: null,
        dummyInd: null,
        addressUsageSequenceNumber: null,
        changeReason: null,
        addressTypeRowIndex: null,
        latitudeNumber: null,
        longitudeNumber: null,
        uspsAddressLine1: null,
        uspsAddressLine2: null,
        uspsAddressVerifyCode: null,
        addressUsageSK: null,
        phoneExtn: null,
        email: null,
        addressOne: null,
        addressTwo: null,
        addressThree: null,
        addressFour: null,
        county: null,
        country: null,
        town: null,
        addressVerification: null,
        uspsVerifiedCode: null,
        latitude: null,
        longitude: null,
        city: null,
        state: null,
        zipOne: null,
        zipTwo: null,
        representativeSK: null,
        eaddressSK: null,
        phoneSK: null,
        addressSK: null,
        countryCode: null,
        countyCode: null,
        addressBeginDateStr: null,
        addressEndDateStr: null,
        mailReturnedIndicator: false,
        addressVoidIndicator: false,
        addressPendingIndicator: false,
        phoneBeginDateStr: null,
        phoneEndDateStr: null,
        phoneVoidIndicator: false,
        phoneOutOfServiceIndicator: false,
        emailAddressBeginDateStr: null,
        emailAddressEndDateStr: null,
        emailAddressVoidIndicator: false,
        emailAddressBouncedIndicator: false,
        contactTypeRowIndex: null,
        commonEntityVersionNumber: 0,
        repCommonEntitySK: null,
        renderContactSection: false,
        renderAddContactSection: false,
        renderEditContactSection: false,
        renderDeleteContactSection: false,
        contactKey: null,
        contactRowIndex: null,
        renderAdditionalContactSection: false,
        renderMailingAddressSection: false,
        commonEntityCrossRefVersionNumber: 0,
        representativeVersionNumber: 0,
        addressUsageVersionNumber: 0,
        addressVersionNumber: 0,
        returnedMailVersionNumber: 0,
        phoneUsageVersionNumber: 0,
        phoneVersionNumber: 0,
        emailUsageVersionNumber: 0,
        emailVersionNumber: 0,
        renderPlusMailingAddressSection: false,
        renderPlusAdditionalContactSection: false,
        nameVrsnNo: 0,
        cmnEntityTypVrsnNo: 0,
        commonEntitySK: null,
        repCommonEntity: null,
        disableContactType: false
      });
      setSelectedContactBeginDate(null);
      setSelectedContactEndDate(null);
      setSelectedContactDOB(null);
      setSelectedContactDOD(null);
      setSelectedRow(null);
    }
    setShowError({
      showStatusrror: showStatusrror,
      showContactTypeError: showContactTypeError,
      showContactBeginDateError: showContactBeginDateError,
      showContactEndDateError: showContactEndDateError,
      showSignificanceError: showSignificanceError,
      showContactFName: showContactFName,
      showContactMName: showContactMName,
      showContactLName: showContactLName,
      showContactDateMatchError: showContactDateMatchError,
      showContactEndDateMatchError: showContactEndDateMatchError,
      validContactEndDateShow: validContactEndDateShow,
      invalidContactBeginDateError: invalidContactBeginDateError,
      invalidContactEndDateError: invalidContactEndDateError,
      isContactBeginDateValid: isContactBeginDateValid,
      invalidDateOfBirthError: invalidDateOfBirthError,
      invalidDateOfDeathError: invalidDateOfDeathError,
      showDeathDateError: showDeathDateError,
      isContactEndDateValid: isContactEndDateValid,
      showContactBeginDateGreaterError: showContactBeginDateGreaterError
    });
    seterrorMessages(errorMessagesArray);
  };

  const handleSaveAddress = () => {
    errorMessagesArray = [];
    var invalidAddressBeginDateError;
    var showAddressEndDateMatchError;
    var isAddressEndDateValid;
    var showAddressBeginDateMatchError;
    var addressEndDateLesserError;
    var showAddressBeginDateGreaterError;
    var invalidAddressEndDateError;
    var isAddressBeginDateValid = false;
    var showAddressTypeError;
    var showAdressBeginDateError;
    var showAddressEndDateError;
    var showAddressLine1Error = false;
    var showSignificantTypeCodeError = false;
    var showZipcodeError = false;
    var showStatusError = false;
    var showCityError = false;
    var showStateError = false;
    var showZipCodeReqError = false;
    var showAddressTypeErrorMsg = false;
    var showAdressBeginDateErrorMsg = false;
    var showAddressEndDateErrorMsg = false;
    var showAddressLine1ErrorMsg;
    var showSignificantTypeCodeErrorMsg;
    var showZipcodeErrorMsg;
    var showStatusErrorMsg = "";
    var showCityErrorMsg = "";
    var showStateErrorMsg = "";
    var showZipcodereqErrorMsg = "";
    if (addAddress.statusCode === DEFAULT_DD_VALUE) {
      showStatusError = true;
      showStatusErrorMsg = ErrorConstants.STATUS_REQUIRED;
      errorMessagesArray.push(showStatusErrorMsg);
    }
    if (addAddress.state === DEFAULT_DD_VALUE) {
      showStateError = true;
      showStateErrorMsg = ErrorConstants.STATE_REQUIRED;
      errorMessagesArray.push(showStateErrorMsg);
    }
    if (addAddress.city === "") {
      showCityError = true;
      showCityErrorMsg = ErrorConstants.CITY_REQUIRED;
      errorMessagesArray.push(showCityErrorMsg);
    }
    if (addAddress.zipCode5 === "") {
      showZipCodeReqError = true;
      showZipcodereqErrorMsg = ErrorConstants.ZIP_CODE_REQUIRED;
      errorMessagesArray.push(showZipcodereqErrorMsg);
    }
    if (addAddress.addressUsageTypeCode === DEFAULT_DD_VALUE) {
      showAddressTypeError = true;
      showAddressTypeErrorMsg = ErrorConstants.ADDRESS_TYPE_REQUIRED;
    }
    if (
      !addAddress.addressLine1 ||
      (addAddress.addressLine1 && !addAddress.addressLine1.trim())
    ) {
      showAddressLine1Error = true;
      showAddressLine1ErrorMsg = ErrorConstants.ADDRESS_LINE_1_REQUIRED;
    }
    if (addAddress.addressUsageSigCode === DEFAULT_DD_VALUE) {
      showSignificantTypeCodeError = true;
      showSignificantTypeCodeErrorMsg =
        ErrorConstants.SIGNIFICANT_TYPE_CODE_REQUIRED;
    }
    if (!selectedAddAddressBeginDate) {
      showAdressBeginDateError = true;
      showAdressBeginDateErrorMsg = ErrorConstants.BEGINDATE_REQUIRED;
    }
    if (selectedAddAddressBeginDate) {
      addAddress.beginDate =
        selectedAddAddressBeginDate.getMonth() +
        1 +
        "/" +
        selectedAddAddressBeginDate.getDate() +
        "/" +
        selectedAddAddressBeginDate.getFullYear();
    }
    if (
      addAddress.beginDate &&
      addAddress.beginDate.toString() === "NaN/NaN/NaN"
    ) {
      addAddress.beginDate = "";
    }
    if (selectedAddAddressEndDate) {
      addAddress.endDate =
        selectedAddAddressEndDate.getMonth() +
        1 +
        "/" +
        selectedAddAddressEndDate.getDate() +
        "/" +
        selectedAddAddressEndDate.getFullYear();
    }
    const regex = /^^[a-zA-Z0-9 ]*$/;
    if (
      addAddress &&
      addAddress.zipCode5 &&
      addAddress.zipCode5.toString().trim() &&
      !regex.test(addAddress.zipCode5)
    ) {
      showZipcodeError = true;
      showZipcodeErrorMsg = ErrorConstants.INVALID_ZIPCODE;
    } else if (addAddress.zipCode5 && addAddress.zipCode5.length < 5) {
      showZipcodeError = true;
      showZipcodeErrorMsg = ErrorConstants.INVALID_ZIPCODE;
    }
    // Begin Date Validation
    if (
      selectedAddAddressBeginDate &&
      selectedAddAddressBeginDate.toString() === "Invalid Date"
    ) {
      invalidAddressBeginDateError = true;
      errorMessagesArray.push("Begin Date " + ErrorConstants.DATE_INVALID);
    }
    if (
      selectedAddAddressBeginDate &&
      selectedAddAddressBeginDate.toString() !== "Invalid Date"
    ) {
      const isDateValid = DateUtilities.validateDateMinimumValue(
        selectedAddAddressBeginDate
      );
      if (isDateValid) {
        isAddressBeginDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        !isDateValid &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormat(selectedAddAddressBeginDate)
        ) <
        new Date(
          DateUtilities.getDateInMMDDYYYYFormat(new Date(selectedDate))
        )
      ) {
        showAddressBeginDateMatchError = true;
        errorMessagesArray.push(ErrorConstants.ADDRESS_DATES_MATCH_ERROR);
      }
    }
    // End Date Validation
    var tempSelectedAddressEndDate = new Date(selectedAddAddressEndDate);
    if (!selectedAddAddressEndDate) {
      addAddress.endDate = "12/31/9999";
      setSelectedAddAddressEndDate(new Date("12/31/9999"));
      tempSelectedAddressEndDate = new Date("12/31/9999");
    }
    if (
      tempSelectedAddressEndDate &&
      tempSelectedAddressEndDate.toString() === "Invalid Date"
    ) {
      invalidAddressEndDateError = true;
      errorMessagesArray.push("End Date " + ErrorConstants.DATE_INVALID);
    }
    if (
      tempSelectedAddressEndDate &&
      tempSelectedAddressEndDate.toString() !== "Invalid Date"
    ) {
      let isDateValid = false;
      isDateValid = DateUtilities.validateDateMinimumValue(
        tempSelectedAddressEndDate
      );
      if (isDateValid) {
        isAddressEndDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        !isDateValid &&
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
            tempSelectedAddressEndDate
          )
        ) >
        new Date(
          DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEndDate)
        )
      ) {
        showAddressEndDateMatchError = true;
        errorMessagesArray.push(ErrorConstants.ADDRESS_DATES_MATCH_ERROR);
      }
    }
    // Begin Date + End Date Comparison
    if (
      selectedAddAddressBeginDate &&
      tempSelectedAddressEndDate &&
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
          selectedAddAddressBeginDate
        )
      ) >
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
          tempSelectedAddressEndDate
        )
      )
    ) {
      showAddressBeginDateGreaterError = true;
      errorMessagesArray.push(ErrorConstants.ADDRESS_BEGIN_DATE_GREATER_ERROR);
    }
    if (errorMessagesArray.length === 0) {
      const addressReq = {
        address1: addAddress.addressLine1,
        address2: addAddress.addressLine2,
        city: addAddress.city,
        state: addAddress.state,
        zip4: addAddress.zipCode5,
        zip5: ""
      };
      validateAddress(removeLeadingTrailingSpaces(addressReq));
    }
    setShowError({
      invalidAddressBeginDateError: invalidAddressBeginDateError,
      isAddressEndDateValid: isAddressEndDateValid,
      invalidAddressEndDateError: invalidAddressEndDateError,
      isAddressBeginDateValid: isAddressBeginDateValid,
      showAddressEndDateMatchError: showAddressEndDateMatchError,
      showAddressBeginDateMatchError: showAddressBeginDateMatchError,
      addressEndDateLesserError: addressEndDateLesserError,
      showAddressBeginDateGreaterError: showAddressBeginDateGreaterError,
      showAddressTypeError: showAddressTypeError,
      showAddressLine1Error: showAddressLine1Error,
      showSignificantTypeCodeError: showSignificantTypeCodeError,
      showAdressBeginDateError: showAdressBeginDateError,
      showAddressEndDateError: showAddressEndDateError,
      showZipcodeError: showZipcodeError,
      showZipCodeReqError: showZipCodeReqError,
      showStatusError: showStatusError,
      showCityError: showCityError,
      showStateError: showStateError
    });
    setAddressErrorMessage({
      showAddressTypeErrorMsg: showAddressTypeErrorMsg,
      showAddressLine1ErrorMsg: showAddressLine1ErrorMsg,
      showSignificantTypeCodeErrorMsg: showSignificantTypeCodeErrorMsg,
      showAdressBeginDateErrorMsg: showAdressBeginDateErrorMsg,
      showAddressEndDateErrorMsg: showAddressEndDateErrorMsg,
      showZipcodeErrorMsg: showZipcodeErrorMsg,
      showZipcodereqErrorMsg: showZipcodereqErrorMsg,
      showCityErrorMsg: showCityErrorMsg,
      showStatusErrorMsg: showStatusErrorMsg,
      showStateErrorMsg: showStateErrorMsg
    });
    seterrorMessages(errorMessagesArray);
  };

  const validateMasterDates = (
    selectedDate,
    selectedEndDate,
    beginDate,
    endDate
  ) => {
    var showDateError = false;
    if (
      new Date(DateUtilities.getDateInMMDDYYYYFormatWithApendZero(endDate)) >
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEndDate)
      ) ||
      new Date(DateUtilities.getDateInMMDDYYYYFormatWithApendZero(beginDate)) <
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedDate)
      )
    ) {
      showDateError = true;
    }
    return showDateError;
  };

  const validateMasterInputs = flag => {
    const regx = /^[0-9]*$/;
    errorMessagesArray = [];
    seterrorMessages([]);
    var mainAccountTypeEror;
    var mainAccountNumberError;
    var mainRoutingError;
    var routingNumberError;
    var routingValidationError;
    var mainBeginDateError;
    var mainEndDateError;
    var invalidMainBeginDateError;
    var invalidMainEndDateError;
    var mainAccountBeginDateInvalid;
    var isMainBeginDateValid;
    var isMainBeginDateSmaller;
    var isMainEndDateValid;
    var bankNameError = false;
    if (values.accountType === DEFAULT_DD_VALUE) {
      mainAccountTypeEror = true;
      errorMessagesArray.push("Account Type " + ErrorConstants.NAME_ERROR);
    }
    if (values.bankName === "") {
      bankNameError = true;
      errorMessagesArray.push("Bank Name " + ErrorConstants.NAME_ERROR);
    }
    if (!values.accountNumber) {
      mainAccountNumberError = true;
      errorMessagesArray.push("Account # " + ErrorConstants.NAME_ERROR);
    }
    if (!values.routingBank) {
      mainRoutingError = true;
      errorMessagesArray.push("Routing/ABA# " + ErrorConstants.NAME_ERROR);
    } else if (values.routingBank.length < 9) {
      routingNumberError = true;
      errorMessagesArray.push(ErrorConstants.ROUTING_NUMBER_ERROR);
    } else if (!regx.test(values.routingBank)) {
      routingValidationError = true;
      errorMessagesArray.push(ErrorConstants.ROUTING_VALIDATION_ERROR);
    } else if (regx.test(values.routingBank)) {
      let n = 0;
      const routingNumber = values.routingBank;
      for (let i = 0; i < routingNumber.length; i += 3) {
        n +=
          parseInt(routingNumber.charAt(i), 10) * 3 +
          parseInt(routingNumber.charAt(i + 1), 10) * 7 +
          parseInt(routingNumber.charAt(i + 2), 10);
      }
      if (n != 0 && n % 10 == 0) routingValidationError = false;
      else {
        routingValidationError = true;
        errorMessagesArray.push(ErrorConstants.ROUTING_VALIDATION_ERROR);
      }
    }

    if (!selectedDate) {
      mainBeginDateError = true;
      errorMessagesArray.push("Begin Date  " + ErrorConstants.NAME_ERROR);
    } else {
      setSelectedAddAddressBeginDate(selectedDate);
      setSelectedContactBeginDate(selectedDate);
    }
    if (selectedDate && selectedDate.toString() === "Invalid Date") {
      invalidMainBeginDateError = true;
      errorMessagesArray.push("Begin Date " + ErrorConstants.DATE_INVALID);
    }
    if (selectedDate && selectedDate.toString() !== "Invalid Date") {
      let isDateValid = false;
      isDateValid = DateUtilities.validateDateMinimumValue(selectedDate);
      if (isDateValid) {
        isMainBeginDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
      if (
        !isMainBeginDateValid &&
        new Date(DateUtilities.getDateInMMDDYYYYFormat(selectedDate)) <
        new Date(DateUtilities.getDateInMMDDYYYYFormat(new Date()))
      ) {
        isMainBeginDateSmaller = true;
        errorMessagesArray.push(
          ErrorConstants.ACCOUNT_BEGIN_DATE_SMALLER_ERROR
        );
      }
    }
    if (!selectedEndDate) {
      setSelectedEndDate(new Date("12/31/9999"));
    }
    if (selectedEndDate && selectedEndDate.toString() === "Invalid Date") {
      invalidMainEndDateError = true;
      errorMessagesArray.push("End Date " + ErrorConstants.DATE_INVALID);
    }
    if (selectedEndDate && selectedEndDate.toString() !== "Invalid Date") {
      let isDateValid = false;
      isDateValid = DateUtilities.validateDateMinimumValue(selectedEndDate);
      if (isDateValid) {
        isMainEndDateValid = true;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
    }
    if (
      selectedDate &&
      selectedEndDate &&
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedDate)
      ) >
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEndDate)
      )
    ) {
      mainAccountBeginDateInvalid = true;
      errorMessagesArray.push(ErrorConstants.ACCOUNT_BEGIN_DATE_ERROR);
    }
    if (flag) {
      if (lockBoxTableData.length === 0) {
        errorMessagesArray.push(ErrorConstants.ONE_LOCKBOX_REQ);
      } else {
        for (let i = 0; i < lockBoxTableData.length; i++) {
          if (
            validateMasterDates(
              selectedDate,
              selectedEndDate,
              lockBoxTableData[i].beginDate,
              lockBoxTableData[i].endDate
            )
          ) {
            errorMessagesArray.push(
              "Lockbox Begin and End dates must be within Bank Accounts Begin and End Date for lockBox #" +
              lockBoxTableData[i].lockBoxNumber
            );
          }
        }
      }
      if (addressTableData.length === 0) {
        errorMessagesArray.push(ErrorConstants.ONE_ADDRESS_REQ);
      } else {
        for (let i = 0; i < addressTableData.length; i++) {
          if (
            validateMasterDates(
              selectedDate,
              selectedEndDate,
              addressTableData[i].beginDate,
              addressTableData[i].endDate
            )
          ) {
            errorMessagesArray.push(
              "Address Begin and End dates must be within Bank Accounts Begin and End Date for Record #" +
              i +
              1
            );
          }
        }
      }
      if (addContactsData.length === 0) {
        errorMessagesArray.push(ErrorConstants.ONE_CONTACT_REQ);
      } else {
        for (let i = 0; i < addContactsData.length; i++) {
          if (
            validateMasterDates(
              selectedDate,
              selectedEndDate,
              addContactsData[i].contactAddrBeginDate,
              addContactsData[i].contactAddrEndDate
            )
          ) {
            errorMessagesArray.push(
              "Contact Begin and End dates must be within Bank Accounts Begin and End Date for Record #" +
              i +
              1
            );
          }
        }
      }
    }
    setShowError({
      mainAccountTypeEror: mainAccountTypeEror,
      mainAccountNumberError: mainAccountNumberError,
      mainRoutingError: mainRoutingError,
      mainBeginDateError: mainBeginDateError,
      mainEndDateError: mainEndDateError,
      invalidMainBeginDateError: invalidMainBeginDateError,
      mainAccountBeginDateInvalid: mainAccountBeginDateInvalid,
      invalidMainEndDateError: invalidMainEndDateError,
      isMainBeginDateValid: isMainBeginDateValid,
      isMainBeginDateSmaller: isMainBeginDateSmaller,
      isMainEndDateValid: isMainEndDateValid,
      bankNameError: bankNameError,
      routingNumberError: routingNumberError,
      routingValidationError: routingValidationError
    });
    return errorMessagesArray;
  };

  const masterSave = () => {
    setAllowNavigation(false);
    let activeContacts = [];
    const errorMessagesArray = validateMasterInputs(true);
    if (addContactsData.length > 0) {
      activeContacts = addContactsData.filter(
        contact => contact.statusCode === "A"
      );
      if (activeContacts.length < 1) {
        errorMessagesArray.push(ErrorConstants.ACTIVE_CONTACT_ERROR);
        seterrorMessages(errorMessagesArray);
      }
    }
    if (errorMessagesArray.length === 0) {
      // Formatting address table for save request json
      setFocusCheck(false);
      addressTableData.map(value => {
        if (value.addressUsageTypeCode === DEFAULT_DD_VALUE) {
          value.addressUsageTypeCode = "";
        }
        if (value.addressUsageSigCode === DEFAULT_DD_VALUE) {
          value.addressUsageSigCode = "";
        }
        if (value.changeReasonCode === DEFAULT_DD_VALUE) {
          value.changeReasonCode = "";
        }
        if (value.country === DEFAULT_DD_VALUE) {
          value.country = "";
        }
        if (value.county === DEFAULT_DD_VALUE) {
          value.county = "";
        }
        if (value.state === DEFAULT_DD_VALUE) {
          value.state = "";
        }
        if (value.statusCode === DEFAULT_DD_VALUE) {
          value.statusCode = "";
        }
      });
      addressTableData.map(element => {
        element = removeLeadingTrailingSpaces(element);
      });
      lockBoxTableData.map(element => {
        element = removeLeadingTrailingSpaces(element);
      });
      addContactsData.map(element => {
        element = removeLeadingTrailingSpaces(element);
      });

      const addIput = {
        auditUserID: logInUserID,
        auditTimeStamp: DateUtilities.getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        fileSavedFlag: "false",
        smallsaveflag: false,
        lobCode: AppConstants.DEFAULT_LOB_VALUE,
        routingNumber: values.routingBank,
        bankName: values.bankName,
        accountType: values.accountType,
        accountNumber: values.accountNumber,
        accountName: values.accountName,
        beginDate: DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
          selectedDate
        ),
        endDate: DateUtilities.getDateInMMDDYYYYFormatWithApendZero(
          selectedEndDate
        ),
        addressVO: {
          auditUserID: logInUserID,
          auditTimeStamp: DateUtilities.getUTCTimeStamp(),
          addedAuditUserID: logInUserID,
          addedAuditTimeStamp: DateUtilities.getUTCTimeStamp(),
          versionNo: 0,
          dbRecord: false,
          sortColumn: null,
          auditKeyList: [],
          auditKeyListFiltered: false,
          contactType: null,
          addressType: null,
          contactSSN: null,
          contactDob: null,
          contactDod: null,
          contactStatusDesc: null,
          contactTypeDesc: null,
          contactTitle: null,
          significanceTypeCode: null,
          status: null,
          contactAddrBeginDate: null,
          contactAddrEndDate: null,
          gender: null,
          streetaddress: null,
          zipcode: null,
          dummyInd: null,
          addressUsageSequenceNumber: null,
          statusCode: "",
          changeReason: null,
          beginDate: null,
          endDate: null,
          addressTypeRowIndex: null,
          latitudeNumber: null,
          longitudeNumber: null,
          uspsAddressLine1: null,
          uspsAddressLine2: null,
          uspsAddressVerifyCode: null,
          addressUsageSK: null,
          prefix: null,
          lastName: null,
          firstName: null,
          middleName: null,
          suffix: null,
          phone: null,
          phoneExtn: null,
          email: null,
          addressOne: null,
          addressTwo: null,
          addressThree: null,
          addressFour: null,
          county: null,
          country: null,
          town: null,
          addressVerification: null,
          uspsVerifiedCode: null,
          latitude: null,
          longitude: null,
          city: null,
          state: null,
          zipOne: null,
          zipTwo: null,
          representativeSK: null,
          eaddressSK: null,
          phoneSK: null,
          addressSK: null,
          countryCode: null,
          countyCode: null,
          addressBeginDateStr: null,
          addressEndDateStr: null,
          mailReturnedIndicator: false,
          addressVoidIndicator: false,
          addressPendingIndicator: false,
          phoneBeginDateStr: null,
          phoneEndDateStr: null,
          phoneVoidIndicator: false,
          phoneOutOfServiceIndicator: false,
          emailAddressBeginDateStr: null,
          emailAddressEndDateStr: null,
          emailAddressVoidIndicator: false,
          emailAddressBouncedIndicator: false,
          contactTypeRowIndex: null,
          commonEntityVersionNumber: 0,
          repCommonEntitySK: null,
          renderContactSection: false,
          renderAddContactSection: false,
          renderEditContactSection: false,
          renderDeleteContactSection: false,
          contactKey: null,
          contactRowIndex: null,
          renderAdditionalContactSection: false,
          renderMailingAddressSection: false,
          commonEntityCrossRefVersionNumber: 0,
          representativeVersionNumber: 0,
          addressUsageVersionNumber: 0,
          addressVersionNumber: 0,
          returnedMailVersionNumber: 0,
          phoneUsageVersionNumber: 0,
          phoneVersionNumber: 0,
          emailUsageVersionNumber: 0,
          emailVersionNumber: 0,
          renderPlusMailingAddressSection: false,
          renderPlusAdditionalContactSection: false,
          nameVrsnNo: 0,
          cmnEntityTypVrsnNo: 0,
          commonEntitySK: null,
          repCommonEntity: null,
          disableContactType: false
        },
        bankAddressVOList: addressTableData,
        lockBoxList: lockBoxTableData,
        lockBoxMap: null,
        contactList: addContactsData,
        contactMap: null,
        lockBoxVO: null,
        disableBankDetails: false,
        renderUpdatePage: false,
        deletedLockBoxList: [],
        deletedContactList: [],
        disableLockBoxDetails: false,
        lobDesc: null,
        bankAuditTimeStamp: null,
        bankAddedAuditTimeStamp: null,
        noteSetVO: notesInput
      };
      saveAPICall(removeLeadingTrailingSpaces(addIput));
    } else {
      seterrorMessages(errorMessagesArray);
    }
  };

  // add notes
  let notesDataArray = [];

  const addNotes = data => {
    setAllowNavigation(true);

    const noteText = data;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };
  const validateParentForm = () => {
    let flag = false;
    if (values.accountType === DEFAULT_DD_VALUE) {
      flag = flag || true;
    }
    if (!values.accountNumber) {
      flag = flag || true;
    }
    if (!values.bankName) {
      flag = flag || true;
    }
    if (!values.routingBank) {
      flag = flag || true;
    }
    if (!selectedDate) {
      flag = flag || true;
    }
    if (selectedDate && selectedDate.toString() === "Invalid Date") {
      flag = flag || true;
    }
    if (selectedDate && selectedDate.toString() !== "Invalid Date") {
      let isDateValid = false;
      isDateValid = DateUtilities.validateDateMinimumValue(selectedDate);
      if (isDateValid) {
        flag = flag || true;
      }
      if (
        !isDateValid &&
        new Date(DateUtilities.getDateInMMDDYYYYFormat(selectedDate)) <
        new Date(DateUtilities.getDateInMMDDYYYYFormat(new Date()))
      ) {
        flag = flag || true;
      }
    }
    if (selectedEndDate && selectedEndDate.toString() === "Invalid Date") {
      flag = flag || true;
    }
    if (selectedEndDate && selectedEndDate.toString() !== "Invalid Date") {
      let isDateValid = false;
      isDateValid = DateUtilities.validateDateMinimumValue(selectedEndDate);
      if (isDateValid) {
        flag = flag || true;
      }
    }
    if (
      selectedDate &&
      selectedEndDate &&
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedDate)
      ) >
      new Date(
        DateUtilities.getDateInMMDDYYYYFormatWithApendZero(selectedEndDate)
      )
    ) {
      flag = flag || true;
    }
    return flag;
  };
  useEffect(() => {
    setDisableNotes(validateParentForm());
  }, [values, selectedEndDate, selectedDate]);

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    seterrorMessages([]);
    setShowError(false);
  };

  useEffect(() => {
    if (openLockBox && document.getElementById("lockbox_form_pop_up")) {
      document.getElementById("lockbox_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
    }
  }, [openLockBox]);
  useEffect(() => {
    if (open && document.getElementById("address_form_pop_up")) {
      document.getElementById("address_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
    }
  }, [open]);
  useEffect(() => {
    if (openContact && document.getElementById("contact_form_pop_up")) {
      document.getElementById("contact_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
    }
  }, [openContact]);

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(() => {
      setDialogOpen(false);
      setFocusCheck(false);
      props.history.push({
        pathname: '/BankAccountAndLockbox',
        tableRender: !props.history.location?.state?.isFromAdd,
        state: { isFromAdd: true }
      });
    }, 100);
  };
  const majorCancel = () => {
    seterrorMessages([]);
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      props.history.push({
        pathname: '/BankAccountAndLockbox',
        tableRender: !props.history.location?.state?.isFromAdd,
        state: { isFromAdd: true }
      });
    }
  };

  return (
    <div className="pos-relative w-100 h-100">
      <Prompt
        when={allowNavigation}
        message={location =>
          `Are you sure you want to go to ${location.pathname}`
        }
      />

      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        {saveAPIResult ? (
          saveAPIResult.accountNumber === null ? (
            <div className="alert alert-danger custom-alert" role="alert">
              Account Already Exists!
            </div>
          ) : null
        ) : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left">
            Add Bank Account And LockBox
          </h1>
          <div className="float-right mt-2">
            <Button className="btn btn-success" onClick={masterSave} data-testid='master_Save_Btn'>
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>

            {window.location.pathname === '/BankAccountLockBoxAdd' && (
              <Button title="Cancel" onClick={majorCancel}
                data-testid="Cancel_btn1">Cancel</Button>
            )}
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form autoComplete="off">
            <div className="form-wrapper">
              <NativeDropDown
                isRequired
                id="accountType"
                inputProps={{ maxlength: 3 }}
                label="Account Type"
                testId="test_accType"
                value={values.accountType}
                onChange={handleChange("accountType")}
                errTxt={
                  mainAccountTypeEror
                    ? "Account Type " + ErrorConstants.NAME_ERROR
                    : null
                }
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                placeholder=""
                InputLabelProps={{
                  shrink: true
                }}
                dropdownList={accountTypeList.map(option => (
                  <option key={option.code} value={option.code}>
                    {option.description}
                  </option>
                ))}
              />
              <div className="mui-custom-form input-md">
                <TextField
                  id="accountNumber"
                  fullWidth
                  required
                  label="Account #"
                  data-testid="test_acctNum"
                  inputProps={{ maxlength: AppConstants.ACCOUNT_NUMBER_MAX_LENGTH }}
                  value={values.accountNumber}
                  onChange={handleChange("accountNumber")}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      AppConstants.NUMBER_ONLY_REGEX,
                      ""
                    );
                  }}
                  error={
                    mainAccountNumberError
                      ? "Account # " + ErrorConstants.NAME_ERROR
                      : null
                  }
                  helperText={
                    mainAccountNumberError
                      ? "Account # " + ErrorConstants.NAME_ERROR
                      : null
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="accountName"
                  fullWidth
                  label="Account Name"
                  data-testid="test_acctName"
                  value={values.accountName}
                  onChange={handleChange("accountName")}
                  inputProps={{ maxlength: AppConstants.ACCOUNT_NAME_MAX_LENGTH }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="routingBank"
                  fullWidth
                  required
                  label="Routing/ABA#"
                  data-testid="test_routingNum"
                  inputProps={{ maxlength: AppConstants.ROUTING_NUMBER_MAX_LENGTH }}
                  value={values.routingBank}
                  onChange={handleChange("routingBank")}
                  error={
                    mainRoutingError
                      ? "Routing/ABA# " + ErrorConstants.NAME_ERROR
                      : routingNumberError
                        ? ErrorConstants.ROUTING_NUMBER_ERROR
                        : routingValidationError
                          ? ErrorConstants.ROUTING_VALIDATION_ERROR
                          : null
                  }
                  helperText={
                    mainRoutingError
                      ? "Routing/ABA# " + ErrorConstants.NAME_ERROR
                      : routingNumberError
                        ? ErrorConstants.ROUTING_NUMBER_ERROR
                        : routingValidationError
                          ? ErrorConstants.ROUTING_VALIDATION_ERROR
                          : null
                  }
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="mui-custom-form with-date">
                  <KeyboardDatePicker
                    maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                    required
                    id="date-picker-dialog"
                    fullWidth
                    label="Begin Date"
                    data-testid="test_begin_date"
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    value={selectedDate}
                    error={
                      mainBeginDateError
                        ? "Begin Date " + ErrorConstants.NAME_ERROR
                        : invalidMainBeginDateError
                          ? "Begin Date " + ErrorConstants.DATE_INVALID
                          : isMainBeginDateValid
                            ? AppConstants.DATE_ERROR_1964
                            : isMainBeginDateSmaller
                              ? ErrorConstants.ACCOUNT_BEGIN_DATE_SMALLER_ERROR
                              : mainAccountBeginDateInvalid
                                ? ErrorConstants.ACCOUNT_BEGIN_DATE_ERROR
                                : null
                    }
                    helperText={
                      mainBeginDateError
                        ? "Begin Date " + ErrorConstants.NAME_ERROR
                        : invalidMainBeginDateError
                          ? "Begin Date " + ErrorConstants.DATE_INVALID
                          : isMainBeginDateValid
                            ? AppConstants.DATE_ERROR_1964
                            : isMainBeginDateSmaller
                              ? ErrorConstants.ACCOUNT_BEGIN_DATE_SMALLER_ERROR
                              : mainAccountBeginDateInvalid
                                ? ErrorConstants.ACCOUNT_BEGIN_DATE_ERROR
                                : null
                    }
                    onChange={handleDateChange}
                    InputLabelProps={{
                      shrink: true
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </div>
                <div className="mui-custom-form with-date">
                  <KeyboardDatePicker
                    maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                    required
                    id="date-picker-dialog2"
                    fullWidth
                    label="End Date"
                    data-testid="test_end_date"
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    error={
                      invalidMainEndDateError
                        ? "End Date " + ErrorConstants.DATE_INVALID
                        : isMainEndDateValid
                          ? AppConstants.DATE_ERROR_1964
                          : null
                    }
                    helperText={
                      invalidMainEndDateError
                        ? "End Date " + ErrorConstants.DATE_INVALID
                        : isMainEndDateValid
                          ? AppConstants.DATE_ERROR_1964
                          : null
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </div>
              </MuiPickersUtilsProvider>
              <div className="mui-custom-form input-md">
                <TextField
                  required
                  id="bankName"
                  fullWidth
                  label="Bank Name"
                  data-testid="test_bank_name"
                  value={values.bankName}
                  onChange={handleChange("bankName")}
                  inputProps={{ maxlength: AppConstants.BANK_NAME_MAX_LENGTH }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={
                    bankNameError
                      ? "Bank Name " + ErrorConstants.NAME_ERROR
                      : null
                  }
                  helperText={
                    bankNameError
                      ? "Bank Name " + ErrorConstants.NAME_ERROR
                      : null
                  }
                />
              </div>
            </div>
          </form>
          <div className="tab-panelbody m-0">
            <div className="tab-holder mb-3 mt-2">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                >
                  <Tab label="LockBox"
                    data-testid='Lockbox_tab_test' />
                  <Tab label="Address"
                    data-testid='Address_tab_test' />
                  <Tab label="Contacts"
                    data-testid='Contacts_tab_test' />
                  <Tab label="Notes"
                    data-testid='Notes_tab_test' />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <div className="tab-holder p-0">
                  <>
                    {value === 0 && (
                      <div className="float-right my-3 mr-3">
                        <Button
                          className="btn-text-main-delete btn-transparent"
                          onClick={rowDeleteClick}
                          data-testid="test_lb_delete_btn"
                          title={"Delete"}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                          Delete
                        </Button>
                        <Button
                          className="btn-text-main-add btn-success ml-1"
                          title="Add LockBox"
                          data-testid="test_add_btn"
                          onClick={handleClickOpenLockBox}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                          Add
                        </Button>
                      </div>
                    )}
                  </>

                  <div className="clearfix"></div>

                  <div className="tab-holder table-no-wrap my-3">
                    {value === 0 && (
                      <div className="tab-holder p-0">
                        <LockBoxTable
                          fixedTable
                          tableData={lockBoxTableData}
                          handleLockBoxTableAdd={handleLockBoxTableAdd}
                          rowDeleteLockBox={rowDeleteLockBox}
                          addPage={true}
                        />
                      </div>
                    )}
                    {openLockBox ? (
                      <div className="tabs-container" id="lockbox_form_pop_up">
                        <div className="tab-header  mini-tab-header">
                          <div className="tab-heading float-left">
                            {selectedRow ? "Update" : "Add"} LockBox
                          </div>
                          <div className="float-right mt-1">
                            <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-success ml-1"
                              onClick={handleSaveLockBox}
                              data-testid="test_lb_add_btn"
                              title={selectedRow ? "Update" : "Add"}
                            >
                              <i className="fa fa-check" aria-hidden="true"></i>
                              {selectedRow ? "Update" : "Add"}
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="bt-reset btn-transparent  ml-1"
                              data-testid="test_lb_reset_btn"
                              onClick={() => handleResetLockBox(lockBoxDetails)}
                              title={'Reset'}
                            >
                              <i className="fa fa-undo" aria-hidden="true"></i>
                              Reset
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-primary btn btn-outlined ml-1"
                              data-testid="test_lb_cancel_btn"
                              onClick={handleCloseLockBox}
                              title={'Cancel'}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        <div className="form-border my-3">
                          <form autoComplete="off">
                            <div className="form-wrapper">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="mui-custom-form input-md with-date">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      "31 Dec 9999 00:00:00 GMT"
                                    )}
                                    required
                                    id="beginDate"
                                    fullWidth
                                    label="Begin Date"
                                    data-testid="test_lb_begin_date"
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    error={
                                      showLockBoxBeginDateError
                                        ? "Begin Date " +
                                          ErrorConstants.NAME_ERROR
                                        : invalidLockBoxBeginDateError
                                        ? "Begin Date " +
                                          ErrorConstants.DATE_INVALID
                                        : isLockBoxBeginDateValid
                                        ? AppConstants.DATE_ERROR_1964
                                        : showLockBoxBeginDateSmallerError
                                        ? ErrorConstants.LOCKBOX_BEGIN_DATE_SMALLER_ERROR
                                        : showLockBoxBeginDateGreaterError
                                        ? ErrorConstants.LOCKBOX_BEGIN_DATE_GREATER_ERROR
                                        : showLockBoxBeginDateMatchError
                                        ? ErrorConstants.LOCKBOX_DATES_MATCH_ERROR
                                        : overLapDateError
                                        ? ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
                                        : null
                                    }
                                    helperText={
                                      showLockBoxBeginDateError
                                        ? "Begin Date " +
                                          ErrorConstants.NAME_ERROR
                                        : invalidLockBoxBeginDateError
                                        ? "Begin Date " +
                                          ErrorConstants.DATE_INVALID
                                        : isLockBoxBeginDateValid
                                        ? AppConstants.DATE_ERROR_1964
                                        : showLockBoxBeginDateSmallerError
                                        ? ErrorConstants.LOCKBOX_BEGIN_DATE_SMALLER_ERROR
                                        : showLockBoxBeginDateGreaterError
                                        ? ErrorConstants.LOCKBOX_BEGIN_DATE_GREATER_ERROR
                                        : showLockBoxBeginDateMatchError
                                        ? ErrorConstants.LOCKBOX_DATES_MATCH_ERROR
                                        : overLapDateError
                                        ? ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
                                        : null
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedLockBoxBeginDate}
                                    onChange={handleLockBoxBeginDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                  />
                                </div>
                                <div className="mui-custom-form input-md with-date">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      "31 Dec 9999 00:00:00 GMT"
                                    )}
                                    required
                                    id="endDate"
                                    fullWidth
                                    label="End Date"
                                    data-testid="test_lb_end_date"
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    error={
                                      showLockBoxEndDateError
                                        ? "End Date " +
                                          ErrorConstants.NAME_ERROR
                                        : invalidLockBoxEndDateError
                                        ? "End Date " +
                                          ErrorConstants.DATE_INVALID
                                        : isLockBoxEndDateValid
                                        ? AppConstants.DATE_ERROR_1964
                                        : showLockBoxEndDateMatchError
                                        ? ErrorConstants.LOCKBOX_DATES_MATCH_ERROR
                                        : null
                                    }
                                    helperText={
                                      showLockBoxEndDateError
                                        ? "End Date " +
                                          ErrorConstants.NAME_ERROR
                                        : invalidLockBoxEndDateError
                                        ? "End Date " +
                                          ErrorConstants.DATE_INVALID
                                        : isLockBoxEndDateValid
                                        ? AppConstants.DATE_ERROR_1964
                                        : showLockBoxEndDateMatchError
                                        ? ErrorConstants.LOCKBOX_DATES_MATCH_ERROR
                                        : null
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedLockBoxEndDate}
                                    onChange={handleLockBoxEndDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                  />
                                </div>
                              </MuiPickersUtilsProvider>
                              <div className="mui-custom-form with-icon with-icon-margin">
                                <TextField
                                  id="lockBoxNumber"
                                  fullWidth
                                  label="LockBox #"
                                  data-testid="test_lockbox_num"
                                  inputProps={{ maxlength: 7 }}
                                  value={lockBoxDetails.lockBoxNumber}
                                  error={
                                    showLockBoxNumberError
                                      ? "LockBox # " +
                                      ErrorConstants.NAME_ERROR
                                      : showLockBoxDuplicateNumberError
                                        ? ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
                                        : null
                                  }
                                  helperText={
                                    showLockBoxNumberError
                                      ? "LockBox # " +
                                      ErrorConstants.NAME_ERROR
                                      : showLockBoxDuplicateNumberError
                                        ? ErrorConstants.DUPLICATE_VALUE_LOCKBOX_NUMBER
                                        : null
                                  }
                                  required
                                  onChange={handleChangeLockBox(
                                    "lockBoxNumber"
                                  )}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                              <div className="mui-custom-form with-icon with-icon-margin">
                                <TextField
                                  id="lockBoxName"
                                  fullWidth
                                  label="LockBox Name"
                                  data-testid="test_lb_name"
                                  value={lockBoxDetails.lockBoxName}
                                  error={
                                    showLockBoxNameError
                                      ? "LockBox Name " +
                                      ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  helperText={
                                    showLockBoxNameError
                                      ? "LockBox Name " +
                                      ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  inputProps={{ maxlength: 40 }}
                                  required
                                  onChange={handleChangeLockBox("lockBoxName")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                            </div>
                          </form>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="tab-holder p-0">
                  <>
                    {value === 1 && (
                      <div className="float-right my-3 mr-3">
                        <Button
                          className="btn-text-main-delete btn-transparent ml-1"
                          onClick={rowDeleteClickAddress}
                          data-testid="test_Ad_Add_delete"
                          title={"Delete"}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                          Delete
                        </Button>
                        <Button
                          className="btn-text-main-add btn-success ml-1"
                          onClick={handleClickOpen}
                          data-testid="test_Ad_add_btn"
                          title="Add Address"
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                          Add
                        </Button>
                      </div>
                    )}
                  </>
                  <div className="clearfix"></div>
                  <div className="tab-holder table-no-wrap my-3">
                    {value === 1 && (
                      <div className="tab-holder p-0">
                        <AddressTable
                          tableData={filteredAddressData}
                          handleAddressTableAdd={handleAddressTableAdd}
                          rowDeleteAddress={rowDeleteAddress}
                          setFocusCheck ={setFocusCheck}
                        />
                      </div>
                    )}
                    {open ? (
                      <div className="tabs-container" id="address_form_pop_up">
                        <div className="tab-header  mini-tab-header">
                          <div className="tab-heading float-left">
                            {selectedRow ? "Update" : "New"} Address
                          </div>
                          <div className="float-right mt-1">
                            <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-success ml-1"
                              data-testid='Add_Address_verify'
                              onClick={() => handleSaveAddress(addAddress)}
                              title={"Verify"}
                            >
                              Verify
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-success ml-1"
                              data-testid='Add_Address_minorADD'
                              onClick={() => handleSaveAddressFinal()}
                              title={selectedRow ? "Update" : "Add"}
                            >
                              <i className="fa fa-check" aria-hidden="true"></i>
                              {selectedRow ? "Update" : "Add"}
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="bt-reset btn-transparent  ml-1"
                              onClick={() => handleResetAddress(addAddress)}
                              data-testid='Add_Address_reset'
                              title = {'Reset'}
                            >
                              <i className="fa fa-undo" aria-hidden="true"></i>
                              Reset
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              data-testid='Add_Address_cancel'
                              className="btn btn-primary btn btn-outlined ml-1"
                              onClick={handleClose}
                              title = {'Cancel'}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        <div className="form-border my-3">
                          <ErrorMessages errorMessages={addressError} />
                          <form autoComplete="off">
                            <div className="form-wrapper">
                              <NativeDropDown
                                id="addressUsageTypeCode"
                                inputProps={{ maxlength: 2, "data-testid": 'address_type' }}
                                label="Address Type"
                                value={addAddress.addressUsageTypeCode}
                                onChange={handleChangeAddAddress(
                                  "addressUsageTypeCode"
                                )}
                                placeholder=""
                                isRequired
                                errTxt={
                                  showAddressTypeError
                                    ? showAddressTypeErrorMsg || null
                                    : null
                                }
                                InputLabelProps={{
                                  shrink: true
                                }}
                                dropdownList={addressTypeList.map(option => (
                                  <option
                                    key={option.code}
                                    value={option.code}
                                  >
                                    {option.description}
                                  </option>
                                ))}
                              />
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="mui-custom-form input-md with-date">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      "31 Dec 9999 00:00:00 GMT"
                                    )}
                                    id="beginDate"
                                    fullWidth
                                    label="Begin Date"
                                    format="MM/dd/yyyy"
                                    data-testid='test_adr_begin_Date'
                                    placeholder="mm/dd/yyyy"
                                    required
                                    error={
                                      showAdressBeginDateError
                                        ? showAdressBeginDateErrorMsg
                                        : invalidAddressBeginDateError
                                          ? "Begin Date " +
                                          ErrorConstants.DATE_INVALID
                                          : isAddressBeginDateValid
                                            ? AppConstants.DATE_ERROR_1964
                                            : showAddressBeginDateMatchError
                                              ? ErrorConstants.ADDRESS_DATES_MATCH_ERROR
                                              : null
                                    }
                                    helperText={
                                      showAdressBeginDateError
                                        ? showAdressBeginDateErrorMsg
                                        : invalidAddressBeginDateError
                                          ? "Begin Date " +
                                          ErrorConstants.DATE_INVALID
                                          : isAddressBeginDateValid
                                            ? AppConstants.DATE_ERROR_1964
                                            : showAddressBeginDateMatchError
                                              ? ErrorConstants.ADDRESS_DATES_MATCH_ERROR
                                              : null
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedAddAddressBeginDate}
                                    onChange={handleAddAddressBeginDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                  />
                                </div>
                                <div className="mui-custom-form input-md with-date">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      "31 Dec 9999 00:00:00 GMT"
                                    )}
                                    id="endDate"
                                    fullWidth
                                    label="End Date"
                                    data-testid='test_adr_end_Date'
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedAddAddressEndDate}
                                    onChange={handleAddAddressEndDateChange}
                                    required
                                    error={
                                      showAddressEndDateError
                                        ? showAddressEndDateErrorMsg
                                        : invalidAddressEndDateError
                                          ? "End Date " +
                                          ErrorConstants.DATE_INVALID
                                          : isAddressEndDateValid
                                            ? AppConstants.DATE_ERROR_1964
                                            : showAddressBeginDateGreaterError
                                              ? ErrorConstants.ADDRESS_BEGIN_DATE_GREATER_ERROR
                                              : showAddressEndDateMatchError
                                                ? ErrorConstants.ADDRESS_DATES_MATCH_ERROR
                                                : null
                                    }
                                    helperText={
                                      showAddressEndDateError
                                        ? showAddressEndDateErrorMsg
                                        : invalidAddressEndDateError
                                          ? "End Date " +
                                          ErrorConstants.DATE_INVALID
                                          : isAddressEndDateValid
                                            ? AppConstants.DATE_ERROR_1964
                                            : showAddressBeginDateGreaterError
                                              ? ErrorConstants.ADDRESS_BEGIN_DATE_GREATER_ERROR
                                              : showAddressEndDateMatchError
                                                ? ErrorConstants.ADDRESS_DATES_MATCH_ERROR
                                                : null
                                    }
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                  />
                                </div>
                              </MuiPickersUtilsProvider>
                              <NativeDropDown
                                id="addressUsageSigCode"
                                inputProps={{ maxlength: 2, "data-testid": "signinificance_type" }}
                                label="Significance Type Code"
                                value={addAddress.addressUsageSigCode}
                                onChange={handleChangeAddAddress(
                                  "addressUsageSigCode"
                                )}
                                placeholder=""
                                InputLabelProps={{
                                  shrink: true
                                }}
                                isRequired
                                errTxt={
                                  showSignificantTypeCodeError
                                    ? showSignificantTypeCodeErrorMsg || null
                                    : null
                                }
                                dropdownList={significanceTypeList.map(option => (
                                  <option
                                    key={option.code}
                                    value={option.code}
                                  >
                                    {option.description}
                                  </option>
                                ))}
                              />
                            </div>
                            <div className="form-wrapper">
                              <NativeDropDown
                                id="changeReasonCode"
                                inputProps={{ maxlength: 2 }}
                                label="Change Reason"
                                data-testid="test_reason"
                                value={addAddress.changeReasonCode}
                                onChange={handleChangeAddAddress(
                                  "changeReasonCode"
                                )}
                                placeholder=""
                                InputLabelProps={{
                                  shrink: true
                                }}
                                dropdownList={changeReasonList.map(option => (
                                  <option
                                    key={option.code}
                                    value={option.code}
                                  >
                                    {option.description}
                                  </option>
                                ))}
                              />
                              <NativeDropDown
                                id="statusCode"
                                isRequired
                                inputProps={{ maxlength: 2, "data-testid": "status_code" }}
                                label="Status"
                                value={addAddress.statusCode}
                                onChange={handleChangeAddAddress(
                                  "statusCode"
                                )}
                                placeholder=""
                                InputLabelProps={{
                                  shrink: true
                                }}
                                errTxt={
                                  showStatusError
                                    ? showStatusErrorMsg || null
                                    : null
                                }
                                dropdownList={addStatusList.map(option => (
                                  <option
                                    key={option.code}
                                    value={option.code}
                                  >
                                    {option.description}
                                  </option>
                                ))}
                              />
                              <div className="mui-custom-form override-width-md">
                                <TextField
                                  className="AddressLine1"
                                  id="addressLine1"
                                  fullWidth
                                  label="Address Line 1"
                                  data-testid='test_adr_adr1_line'
                                  inputProps={{ maxlength: 64 }}
                                  value={addAddress.addressLine1}
                                  onChange={handleChangeAddAddress(
                                    "addressLine1"
                                  )}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  required
                                  error={
                                    showAddressLine1Error
                                      ? showAddressLine1ErrorMsg || null
                                      : null
                                  }
                                  helperText={
                                    showAddressLine1Error
                                      ? showAddressLine1ErrorMsg || null
                                      : null
                                  }
                                />
                              </div>
                              <div className="mui-custom-form override-width-md">
                                <TextField
                                  id="addressLine2"
                                  fullWidth
                                  label="Address Line 2"
                                  inputProps={{ maxlength: 64 }}
                                  value={addAddress.addressLine2}
                                  onChange={handleChangeAddAddress(
                                    "addressLine2"
                                  )}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                              <div className="mui-custom-form input-md">
                                <TextField
                                  required
                                  id="city"
                                  fullWidth
                                  label="City"
                                  value={addAddress.city}
                                  inputProps={{ maxlength: 30 }}
                                  data-testid="city_test"
                                  onChange={handleChangeAddAddress("city")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  error={
                                    showCityError
                                      ? showCityErrorMsg || null
                                      : null
                                  }
                                  helperText={
                                    showCityError
                                      ? showCityErrorMsg || null
                                      : null
                                  }
                                />
                              </div>
                              <NativeDropDown
                                isRequired
                                id="state"
                                inputProps={{ maxlength: 2, "data-testid": "state_type" }}
                                  label="State"
                                value={addAddress.state}
                                onChange={handleChangeAddAddress("state")}
                                placeholder=""
                                InputLabelProps={{
                                  shrink: true
                                }}
                                errTxt={
                                  showStateError
                                    ? showStateErrorMsg || null
                                    : null
                                }
                                dropdownList={addStateList.map(option => (
                                  <option
                                    key={option.code}
                                    value={option.code}
                                  >
                                    {option.description}
                                  </option>
                                ))}
                              />
                              <div className="mui-custom-form input-md">
                                <TextField
                                  required
                                  id="zipCode5"
                                  fullWidth
                                  label="Zip Code"
                                  data-testid="zipcode_test"
                                  inputProps={{ maxlength: 9 }}
                                  value={addAddress.zipCode5}
                                  onChange={handleChangeAddAddress("zipCode5")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  error={
                                    showZipCodeReqError
                                      ? showZipcodereqErrorMsg
                                      : showZipcodeError
                                        ? showZipcodeErrorMsg
                                        : null
                                  }
                                  helperText={
                                    showZipCodeReqError
                                      ? showZipcodereqErrorMsg
                                      : showZipcodeError
                                        ? showZipcodeErrorMsg
                                        : null
                                  }
                                />
                              </div>
                            </div>
                            <div
                              onClick={() =>
                                setShowAdditionalDetails(!showAdditionalDetails)
                              }
                              style={{
                                backgroundColor: "#274463",
                                color: "#fff",
                                padding: "10px",
                                margin: "10px 10px",
                                minWidth: "680px"
                              }}
                            >
                              Additional Address Details{" "}
                              <div className="pull-right">
                                {showAdditionalDetails ? (
                                  <span>
                                    <i
                                      className="fa fa-angle-up"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                ) : (
                                  <span>
                                    <i
                                      className="fa fa-angle-down"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                )}
                              </div>
                            </div>
                            {(() => {
                              if (showAdditionalDetails) {
                                return (
                                  <div>
                                    <div className="form-wrapper">
                                      <NativeDropDown
                                        id="county"
                                        inputProps={{ maxlength: 5 }}
                                        label="County"
                                        value={addAddress.county}
                                        onChange={handleChangeAddAddress(
                                          "county"
                                        )}
                                        SelectProps={{
                                          MenuProps: {
                                            className: classes.menu
                                          }
                                        }}
                                        InputLabelProps={{
                                          shrink: true
                                        }}
                                        dropdownList={countyList.map(option => (
                                          <option
                                            key={option.code}
                                            value={option.code}
                                          >
                                            {option.description}
                                          </option>
                                        ))}
                                      />
                                      <div className="mui-custom-form input-md">
                                        <TextField
                                          id="town"
                                          fullWidth
                                          label="Town"
                                          inputProps={{ maxlength: 3 }}
                                          value={addAddress.town}
                                          onChange={handleChangeAddAddress(
                                            "town"
                                          )}
                                          placeholder=""
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </div>
                                      <NativeDropDown
                                        id="country"
                                        inputProps={{ maxlength: 3 }}
                                        label="Country"
                                        value={addAddress.country}
                                        onChange={handleChangeAddAddress(
                                          "country"
                                        )}
                                        placeholder=""
                                        InputLabelProps={{
                                          shrink: true
                                        }}
                                        dropdownList={addCountryList.map(option => (
                                          <option
                                            key={option.code}
                                            value={option.code}
                                          >
                                            {option.description}
                                          </option>
                                        ))}
                                      />
                                      <div className="mui-custom-form input-md">
                                        <TextField
                                          id="addressLine3"
                                          fullWidth
                                          label="Address Line 3"
                                          inputProps={{ maxlength: 64 }}
                                          value={addAddress.addressLine3}
                                          onChange={handleChangeAddAddress(
                                            "addressLine3"
                                          )}
                                          placeholder=""
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-wrapper">
                                      <div className="mui-custom-form input-md">
                                        <TextField
                                          id="addressLine4"
                                          fullWidth
                                          label="Address Line 4"
                                          inputProps={{ maxlength: 64 }}
                                          value={addAddress.addressLine4}
                                          onChange={handleChangeAddAddress(
                                            "addressLine4"
                                          )}
                                          placeholder=""
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })()}
                            <div
                              onClick={() =>
                                setShowAdditionalDetails1(
                                  !showAdditionalDetails1
                                )
                              }
                              style={{
                                backgroundColor: "#274463",
                                color: "#fff",
                                padding: "10px",
                                margin: "10px 10px",
                                minWidth: "680px"
                              }}
                              data-testid='address_verification'
                            >
                              Address Verification
                              <div className="pull-right">
                                {showAdditionalDetails1 ? (
                                  <span>
                                    <i
                                      className="fa fa-angle-up"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                ) : (
                                  <span>
                                    <i
                                      className="fa fa-angle-down"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                )}
                              </div>
                            </div>
                            {(() => {
                              if (showAdditionalDetails1) {
                                return (
                                  <div>
                                    <div className="form-wrapper">
                                      <div className="mui-custom-form input-md">
                                        <TextField
                                          className="AddressLine1"
                                          id="addressLine1"
                                          fullWidth
                                          label="Address Line 1"
                                          inputProps={{ maxlength: 64 }}
                                          value={uspsAddres.addressLine1}
                                          onChange={handleChangeAddAddress(
                                            "addressLine1"
                                          )}
                                          placeholder=""
                                          InputProps={{
                                            readOnly: true,
                                            className: "Mui-disabled"
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          required
                                        />
                                      </div>
                                      <div className="mui-custom-form input-md">
                                        <TextField
                                          disabled
                                          id="addressLine2"
                                          fullWidth
                                          label="Address Line 2"
                                          inputProps={{ maxlength: 64 }}
                                          value={uspsAddres.addressLine2}
                                          onChange={handleChangeAddAddress(
                                            "addressLine2"
                                          )}
                                          placeholder=""
                                          InputProps={{
                                            readOnly: true,
                                            className: "Mui-disabled"
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </div>
                                      <div className="mui-custom-form input-md">
                                        <TextField
                                          id="city"
                                          fullWidth
                                          label="City"
                                          value={uspsAddres.city}
                                          InputProps={{
                                            readOnly: true,
                                            className: "Mui-disabled"
                                          }}
                                          inputProps={{ maxlength: 30 }}
                                          onChange={handleChangeAddAddress(
                                            "city"
                                          )}
                                          placeholder=""
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </div>
                                      <NativeDropDown
                                        id="state"
                                        inputProps={{ maxlength: 2 }}
                                        testid="add_address_state"
                                        InputProps={{
                                          readOnly: true,
                                          className: "Mui-disabled"
                                        }}
                                        label="State"
                                        value={uspsAddres.state}
                                        onChange={(e) => { setUspsAddress({ ...uspsAddres, state: e.target.value }); }}
                                        placeholder=""
                                        InputLabelProps={{
                                          shrink: true
                                        }}
                                        dropdownList={addStateList.map(option => (
                                          <option
                                            key={option.code}
                                            value={option.code}
                                          >
                                            {option.description}
                                          </option>
                                        ))}
                                      />
                                    </div>
                                    <div className="form-wrapper">
                                      <div className="mui-custom-form input-md">
                                        <TextField
                                          disabled
                                          id="zipCode5"
                                          fullWidth
                                          label="Zip Code"
                                          inputProps={{ maxlength: 5 }}
                                          value={uspsAddres.zipCode5}
                                          onChange={handleChangeAddAddress(
                                            "zipCode5"
                                          )}
                                          placeholder=""
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })()}
                            <div className="mui-custom-form input-md">
                              <label
                                className="MuiFormLabel-root small-label"
                                htmlFor="fiscalyes"
                              >
                                Consider USPS
                              </label>
                              <div className="sub-radio">
                                <Radio
                                  value="Y"
                                  inputProps={{ "data-testid": "radio_Y" }}
                                  name="void"
                                  id="fiscalyes"
                                  checked={considerUSPS === "Y"}
                                  onChange={handleChangeUSPS("Flag")}
                                />
                                <span className="text-black"> Yes </span>
                                <Radio
                                  inputProps={{ "data-testid": "radio_N" }}
                                  defaultChecked="true"
                                  value="N"
                                  className="ml-2"
                                  name="void"
                                  id="fiscalyes"
                                  checked={considerUSPS === "N"}
                                  onChange={handleChangeUSPS("Flag")}
                                />
                                <span className="text-black"> No </span>
                              </div>
                            </div>
                          </form>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div className="tab-holder p-0">
                  <>
                    {value === 2 && (
                      <div className="float-right my-3 mr-3">
                        <Button
                          className="btn-text-main-delete btn-transparent"
                          onClick={rowDeleteClickContact}
                          data-testid="Add_con_delete"
                          title={"Delete"}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                          Delete
                        </Button>
                        <Button
                          className="btn-text-main-add btn-success ml-1 "
                          onClick={handleClickOpenContact}
                          data-testid='test_Con_add_btn'
                          title="Add Contact"
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                          Add
                        </Button>
                      </div>
                    )}
                  </>
                  <div className="clearfix"></div>
                  <div className="tab-holder table-no-wrap my-3">
                    {value === 2 && (
                      <div className="tab-holder p-0">
                        <ContactTable
                          tableData={filteredContactData}
                          handleContactTableAdd={handleContactTableAdd}
                          rowDeleteContact={rowDeleteContact}
                        />
                      </div>
                    )}
                    {openContact ? (
                      <div className="tabs-container" id="contact_form_pop_up">
                        <div className="tab-header  mini-tab-header">
                          <div className="tab-heading float-left">
                            {selectedRow ? "Update" : "New"} Contact
                          </div>
                          <div className="float-right mt-1">
                            <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-success ml-1"
                              onClick={() => handleSaveContact(contactDetails)}
                              data-testid='con_minor_ad'
                              title={selectedRow ? "Update" : "Add"}
                            >
                              <i className="fa fa-check" aria-hidden="true"></i>
                              {selectedRow ? "Update" : "Add"}
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="bt-reset btn-transparent  ml-1"
                              data-testid='Con_reset'
                              onClick={() => handleResetContact(contactDetails)}
                              title={'Reset'}
                            >
                              <i className="fa fa-undo" aria-hidden="true"></i>
                              Reset
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-primary btn btn-outlined ml-1"
                              onClick={handleContactClose}
                              data-testid='Con_cancel'
                              title={'Cancel'}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        <div className="form-border my-3">
                          <form autoComplete="off">
                            <div className="form-wrapper">
                                <NativeDropDown
                                  isRequired
                                  id="statusCode"
                                inputProps={{ maxlength: 2, "data-testid": "status_code_contact" }}
                                label="Status"
                                  value={contactDetails.statusCode}
                                  errTxt={
                                    showStatusrror
                                      ? "Status " + ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  onChange={handleAddContact("statusCode")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  dropdownList={contactStatusList.map(option => (
                                    <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                    </option>
                                  ))}
                                />
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="mui-custom-form input-md with-date">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      "31 Dec 9999 00:00:00 GMT"
                                    )}
                                    required
                                    id="beginDate"
                                    label="Begin Date"
                                    format="MM/dd/yyyy"
                                    data-testid="cont_begin_dt"
                                    placeholder="mm/dd/yyyy"
                                    error={
                                      showContactBeginDateError
                                        ? "Begin Date " +
                                        ErrorConstants.NAME_ERROR
                                        : invalidContactBeginDateError
                                          ? "Begin Date " +
                                          ErrorConstants.DATE_INVALID
                                          : isContactBeginDateValid
                                            ? AppConstants.DATE_ERROR_1964
                                            : showContactBeginDateGreaterError
                                              ? ErrorConstants.CONTACT_BEGIN_DATE_GREATER_ERROR
                                              : showContactDateMatchError
                                                ? ErrorConstants.BEGIN_DATE_MATCH_ERROR
                                                : null
                                    }
                                    helperText={
                                      showContactBeginDateError
                                        ? "Begin Date " +
                                        ErrorConstants.NAME_ERROR
                                        : invalidContactBeginDateError
                                          ? "Begin Date " +
                                          ErrorConstants.DATE_INVALID
                                          : isContactBeginDateValid
                                            ? AppConstants.DATE_ERROR_1964
                                            : showContactBeginDateGreaterError
                                              ? ErrorConstants.CONTACT_BEGIN_DATE_GREATER_ERROR
                                              : showContactDateMatchError
                                                ? ErrorConstants.BEGIN_DATE_MATCH_ERROR
                                                : null
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedContactBeginDate}
                                    onChange={handleContactBeginDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                  />
                                </div>
                                <div className="mui-custom-form input-md with-date">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      "31 Dec 9999 00:00:00 GMT"
                                    )}
                                    required
                                    id="endDate"
                                    fullWidth
                                    label="End Date"
                                    data-testid='cont_end_dt'
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    error={
                                      invalidContactEndDateError
                                        ? "End Date " +
                                        ErrorConstants.DATE_INVALID
                                        : isContactEndDateValid
                                          ? AppConstants.DATE_ERROR_1964
                                          : showContactEndDateMatchError
                                            ? ErrorConstants.BEGIN_DATE_MATCH_ERROR
                                            : null
                                    }
                                    helperText={
                                      invalidContactEndDateError
                                        ? "End Date " +
                                        ErrorConstants.DATE_INVALID
                                        : isContactEndDateValid
                                          ? AppConstants.DATE_ERROR_1964
                                          : showContactEndDateMatchError
                                            ? ErrorConstants.BEGIN_DATE_MATCH_ERROR
                                            : null
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedContactEndDate}
                                    onChange={handleContactEndDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                  />
                                </div>
                              </MuiPickersUtilsProvider>

                                <NativeDropDown
                                  isRequired
                                  id="contactType"
                                inputProps={{ maxlength: 2, "data-testid": "contact_type" }}
                                  label="Contact Type"
                                  value={contactDetails.contactType}
                                  errTxt={
                                    showContactTypeError
                                      ? "Contact Type " +
                                        ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  onChange={handleAddContact("contactType")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  dropdownList={contactTypeList.map(option => (
                                    <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                    </option>
                                  ))}
                                />
                            </div>
                            <div className="form-wrapper">
                                <NativeDropDown
                                  isRequired
                                  id="significanceTypeCode"
                                inputProps={{ maxlength: 2, "data-testid": "significance_code" }}
                                  label="Significance"
                                  value={contactDetails.significanceTypeCode}
                                  errTxt={
                                    showSignificanceError
                                      ? "Significance " +
                                        ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  onChange={handleAddContact(
                                    "significanceTypeCode"
                                  )}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  dropdownList={contactSignificanceList.map(option => (
                                    <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                    </option>
                                  ))}
                                />
                              <div className="mui-custom-form input-md">
                                <TextField
                                  id="contactSSN"
                                  fullWidth
                                  label="SSN"
                                  inputProps={{ maxlength: 9 }}
                                  value={contactDetails.contactSSN}
                                  onChange={handleAddContact("contactSSN")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                              <div className="mui-custom-form input-md">
                                <TextField
                                  id="contactTitle"
                                  fullWidth
                                  label="Title"
                                  inputProps={{ maxlength: 60 }}
                                  value={contactDetails.contactTitle}
                                  data-testid="add_con_title"
                                  onChange={handleAddContact("contactTitle")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                                <NativeDropDown
                                  id="prefix"
                                inputProps={{ maxlength: 10 }}
                                label="Prefix"
                                  value={contactDetails.prefix}
                                  onChange={handleAddContact("prefix")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  dropdownList={contactPrefixList.map(option => (
                                    <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                    </option>
                                  ))}
                                />
                            </div>
                            <div className="form-wrapper">
                              <div className="mui-custom-form input-md">
                                <TextField
                                  id="firstName"
                                  fullWidth
                                  data-testid='First_name_con'
                                  label="First Name"
                                  inputProps={{ maxlength: 35 }}
                                  required
                                  value={contactDetails.firstName}
                                  error={
                                    showContactFName
                                      ? "First Name " +
                                        ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  helperText={
                                    showContactFName
                                      ? "First Name " +
                                        ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  onChange={handleAddContact("firstName")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                              <div className="mui-custom-form input-md">
                                <TextField
                                  id="middleName"
                                  fullWidth
                                  label="Middle Name"
                                  data-testid="test_middle_name"
                                  inputProps={{ maxlength: 25 }}
                                  value={contactDetails.middleName}
                                  error={
                                    showContactMName
                                      ? "Middle Name " +
                                        ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  helperText={
                                    showContactMName
                                      ? "Middle Name " +
                                        ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  onChange={handleAddContact("middleName")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                              <div className="mui-custom-form input-md">
                                <TextField
                                  id="lastName"
                                  fullWidth
                                  label="Last Name"
                                  data-testid='last_name_test'
                                  inputProps={{ maxlength: 60 }}
                                  required
                                  value={contactDetails.lastName}
                                  error={
                                    showContactLName
                                      ? "Last Name " + ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  helperText={
                                    showContactLName
                                      ? "Last Name " + ErrorConstants.NAME_ERROR
                                      : null
                                  }
                                  onChange={handleAddContact("lastName")}
                                  placeholder=""
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                              <NativeDropDown
                                id="suffix"
                                inputProps={{ maxlength: 10 }}
                                label="Suffix"
                                value={contactDetails.suffix}
                                onChange={handleAddContact("suffix")}
                                placeholder=""
                                InputLabelProps={{
                                  shrink: true
                                }}
                                dropdownList={contactSuffixList.map(option => (
                                  <option
                                    key={option.code}
                                    value={option.code}
                                  >
                                    {option.description}
                                  </option>
                                ))}
                              />
                            </div>
                            <div className="form-wrapper">
                              <NativeDropDown
                                id="gender"
                                inputProps={{ maxlength: 3 }}
                                label="Gender"
                                data-testid='gender_dropdown'
                                value={contactDetails.gender}
                                onChange={handleAddContact("gender")}
                                placeholder=""
                                InputLabelProps={{
                                  shrink: true
                                }}
                                dropdownList={genderList.map(option => (
                                  <option
                                    key={option.code}
                                    value={option.code}
                                  >
                                    {option.description}
                                  </option>
                                ))}
                              />
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="mui-custom-form with-date input-md">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      "31 Dec 9999 00:00:00 GMT"
                                    )}
                                    id="contactDob"
                                    label="Date of Birth"
                                    format="MM/dd/yyyy"
                                    data-testid="test_DOB"
                                    placeholder="mm/dd/yyyy"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedContactDOB}
                                    onChange={handleContactDOB}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                    helperText={
                                      invalidDateOfBirthError
                                        ? "Date of Birth " +
                                        ErrorConstants.DATE_INVALID
                                        : null
                                    }
                                    error={
                                      invalidDateOfBirthError
                                        ? "Date of Birth " +
                                        ErrorConstants.DATE_INVALID
                                        : null
                                    }
                                  />
                                </div>
                                <div className="mui-custom-form with-date input-md">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      "31 Dec 9999 00:00:00 GMT"
                                    )}
                                    id="contactDod"
                                    label="Date of Death"
                                    data-testid='test_DOD'
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedContactDOD}
                                    onChange={handleContactDOD}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date"
                                    }}
                                    helperText={
                                      invalidDateOfDeathError
                                        ? "Date of Death " +
                                        ErrorConstants.DATE_INVALID
                                        : showDeathDateError
                                          ? ErrorConstants.DEATH_DATE_ERROR
                                          : null
                                    }
                                    error={
                                      invalidDateOfDeathError
                                        ? "Date of Death " +
                                        ErrorConstants.DATE_INVALID
                                        : showDeathDateError
                                          ? ErrorConstants.DEATH_DATE_ERROR
                                          : null
                                    }
                                  />
                                </div>
                              </MuiPickersUtilsProvider>
                            </div>
                          </form>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <div className="tab-holder p-0">
                  {value === 3 && (
                    <Notes
                      addNotes={addNotes}
                      setSuccessMessages={clearSuccessErrorMsgs}
                      disableNotes={disableNotes}
                      notesTableData={notesTableData}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setEditNoteData={setEditNoteData}
                      setFocusCheck ={setFocusCheck}
                      setErrorMessages={seterrorMessages}
                    />
                  )}
                </div>

              </TabPanel>
            </div>
          </div>
        </div>
        <Footer print />
        <Dialog
              open={dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="custom-alert-box"
      >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
                  </>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
              data-testid="stay_OnThisPage_Btn"
                  onClick={() => {
              setDialogOpen(false);
            }}
			                 color="primary"
                  className="btn btn-transparent"
          >
                  STAY ON THIS PAGE!
                </Button>
                <Button
                  onClick={cancelMinorSection}
              data-testid="cancel_minor_popup_Btn"
                  color="primary"
                  className="btn btn-success"
                  autoFocus
          >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
                </Button>
              </DialogActions>
            </Dialog>
      </div>
    </div>
  );
}
