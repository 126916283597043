/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import TableComponent from "../../../../../../../SharedModules/Table/Table";

const headCells = [
  {
    id: "dueDate",
    numeric: false,
    disablePadding: true,
    label: "Due Date",
    isDate: true,
    width: 140
  },
  {
    id: "installmentAmount",
    numeric: false,
    disablePadding: false,
    label: "Installment Amount",
    isBalance: true,
    width: 140
  },
  {
    id: "recoupedAmount",
    numeric: false,
    disablePadding: false,
    label: "Recouped Amount",
    isBalance: true,
    width: 240
  }
];

function PaymentScheduleTable(props) {
  const paymentScheduleTableData = props.tableData;
  return (
    <div className="tab-holder">
      <TableComponent
        print={props.print}
        headCells={headCells}
        tableData={paymentScheduleTableData || []}
        onTableRowClick={props.onRowClick}
        align={"left"}
        onTableRowDelete={props.rowDeletePaymentSchedule}
        defaultSortColumn={"dueDate"}
        isSearch={props.isSearch}
        onTableCellButtonClick={props.onTableCellButtonClick}
        uniqueKey={props.uniqueKey}
        setClose={props.setClose}
        close={props.close}
      />
    </div>
  );
}
export default PaymentScheduleTable;
