/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const UPLOADED_DATE_INVALID = 'Uploaded Date that was entered is invalid.';
export const NO_RECORDS = 'No records found for the search criteria entered.';
export const SUCCESS_MSG = 'File is being processed, please check after sometime.';
export const INVALID_AMOUNT = 'Please enter the data in correct format.';