/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import TableComponent from "../../../../../SharedModules/Table/Table";
import TextField from "@material-ui/core/TextField";
import numeral from "numeral";
import { determineAmountToUse, parseAmount } from "../../../../../SharedModules/CommonUtilities/commonUtilities";

export default function GrossLevelhistoryTable(props) {
  const headCells = [
    {
      id: "receivableFCN",
      numeric: false,
      disablePadding: true,
      label: "FCN/TCN",
      width: 200,
      fontSize: 12,
      isRedirectable: true,
      openInNewTab: true
    },
    {
      id: "reasonCodeDesc",
      numeric: false,
      disablePadding: false,
      label: "Reason Code",
      enableHyperLink: false,
      width: 200,
      fontSize: 12,
      isTooltip: true,
      isTitle: 'reasonCodeLongDesc'
    },
    {
      id: "appliedDateStr",
      numeric: false,
      disablePadding: false,
      label: "Applied Date",
      enableHyperLink: false,
      isDate: true,
      width: 200,
      fontSize: 12
    },
    {
      id: "appliedAmount",
      numeric: false,
      disablePadding: false,
      label: "Applied Amount",
      enableHyperLink: false,
      isBalance: true,
      width: 200,
      fontSize: 12
    }
  ];
  const adjustedAmount = parseAmount(props.values.adjustedARAmount);
  const originalAmount = parseAmount(props.values.originalARAmount);
  const amountToUse = determineAmountToUse(adjustedAmount, originalAmount);
  const onRowClick = () => { };
  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const reasonCode =
            props.dropdowns &&
            props.dropdowns['F1#F_RSN_CD'] &&
            props.dropdowns['F1#F_RSN_CD'].filter(
              value => value.code === each.reasonCode
            );
          each.reasonCodeDesc =
          reasonCode && reasonCode.length > 0
              ? reasonCode[0].description
              : '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };
  return (
    <div>
      <div className="form-wrapper">
        <div className="mui-custom-form input-md">
          <TextField
            id="standard-aramount"
            label="Original A/R Amount"
            value={numeral(amountToUse).format(
              "$0,0.00"
            )}
            inputProps={{ maxlength: 15 }}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            disabled={true}
          ></TextField>
        </div>
        <div className="mui-custom-form input-md">
          <TextField
            id="standard-appliedamount"
            label="Applied Amount"
            value={numeral(props.values.appliedAmountData).format("$0,0.00")}
            inputProps={{ maxlength: 15 }}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            disabled={true}
          ></TextField>
        </div>
        <div className="mui-custom-form input-md">
          <TextField
            id="standard-balance-amount"
            label="Balance Amount"
            value={numeral(props.advancePayment.balanceAmount).format(
              "$0,0.00"
            )}
            inputProps={{ maxlength: 15 }}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            disabled={true}
          ></TextField>
        </div>
      </div>
      <div className="tab-holder">
        <TableComponent
          print={props.print}
          headCells={headCells}
          tableData={getTableData(props.historyData || [])}
          isSearch={true}
          onTableRowClick={onRowClick}
        />
      </div>
    </div>
  );
}
