/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    width: "100%"
  },
  orange: {
    position: "absolute",
    background: "#ff8700",
    left: 55,
    width: 206,
    height: 6,
    transform: "skew(45deg)"
  }
}));

export default () => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} hide-on-print`}>
      <div className={classes.orange} />
    </div>
  );
};
