/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { withStyles, lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { systemListActions, resetSearchMapSet } from '../../Store/Actions/MapSet/mapSetActions';
import TableComponent from '../../../../SharedModules/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }

}));

const headCells = [
  { id: 'excludeIndicator', numeric: false, disablePadding: true, label: 'Exclude', isBoolean: true, width: '16%' },
  { id: 'dataElementCriteria', numeric: false, disablePadding: false, label: 'Data Element Criteria', enableHyperLink: true, width: '24%' },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type', width: '20%' },
  { id: 'functionalAreaDesc', numeric: false, disablePadding: false, label: 'Functional Area', width: '20%' },
  { id: 'value', numeric: false, disablePadding: false, label: 'Value', width: '20%' }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
    // marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

export default function MapSetUpdateDefinitionData (props) {
  const classes = useStyles();
  let systemLists = [];
  const dispatch = useDispatch();
  const [valuesListDropdown, setvaluesListDropdown] = React.useState([]);
  const [LOBReference, setLOBReference] = useState([]);
  const [MapTypeReference, setMapTypeReference] = useState([]);
  const [dataElementReference, setdataElementReference] = useState([]);
  const [functionalAreaReference, setfunctionalAreaReference] = useState([]);

  const [dataElement, setDataElement] = React.useState({
    id: 0,
    dataElementCriteria: '',
    functionalArea: '',
    value: '',
    selectedType: '',
    exclude: false,
    value1: '',
    value2: '',
    selectedOption: 'List'
  });
  const [rowData, setRowData] = React.useState(0);

  const [values, setValues] = React.useState({
    lobDetail: 'LOB1',
    dataFormat: 'AB',
    description: '',
    paramNumber: '12',
    LOB: 'React'
  });
  const onAddSuccess = rowClickValues => dispatch(systemListActions(rowClickValues));
  systemLists = useSelector(state => state.appConfigState.mapSetState.systemLists);
  const userInquiryPrivileges = !global.globalIsReadOnly();
  useEffect(() => {
    if (dataElement.functionalArea && dataElement.functionalArea !== '' &&
    dataElement.dataElementCriteria && dataElement.dataElementCriteria !== '') {
      const rowClickValues = {
        functionalArea: dataElement.functionalArea,
        dataElementName: dataElement.dataElementCriteria
      };
      onAddSuccess(rowClickValues);
    }
  }, [dataElement.functionalArea]);
  useEffect(() => {
    const valuesList = [];
    if (systemLists && systemLists.length > 0) {
      systemLists.map(list => {
        valuesList.push(list.listNumber);
      });
      setvaluesListDropdown(valuesList);
    }
  }, [systemLists]);
  // const classes = useStyles2();

  const handleChangeDataElement = name => event => {
    setDataElement({ ...dataElement, [name]: event.target.value });
    if (name === 'selectedOption') { setEombtype(event.target.value); }
  };

  const [newDialogData, setnewDialogData] = React.useState([
    {
      exclude: 'No',
      dataElementCriteria: 'PQS-PlaceofSvc',
      type: 'List',
      functionalArea: 'C1-Claims Ent',
      value: 'Omni Clinic'
    }
  ]);

  const [eombType, setEombtype] = useState('List');
  const renderSwitch = (eombType) => {
    switch (eombType) {
      case 'Range':
        return (
          <div className="form-wrapper">
            <div className="mui-custom-form input-xs">
              <label className="MuiFormLabel-root small-label">Value<span>*</span></label>
            </div>
            <div className="mui-custom-form input-sm from-thru">
              <TextField
                id="value1"
                label="From"
                disabled={!userInquiryPrivileges}
                InputLabelProps={{
                  shrink: true
                }}
                value={dataElement.value1}
                onChange={handleChangeDataElement('value1')}
              />
            </div>
            <div className="mui-custom-form input-sm from-thru">
              <TextField
                id="value2"
                label="Thru"
                disabled={!userInquiryPrivileges}
                InputLabelProps={{
                  shrink: true
                }}
                value={dataElement.value2}
                onChange={handleChangeDataElement('value2')}
              />
            </div>
          </div>
        );
      case 'List':
        return (
          <div className="form-wrapper">
              <NativeDropDown
                id="functional-area"
                disabled={!userInquiryPrivileges}
                label="Functional Area" required
                value={dataElement.functionalArea}
                onChange={handleChangeDataElement('functionalArea')}
                InputLabelProps={{
                  shrink: true
                }}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                dropdownList={functionalAreaReference.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))}
              />
              <NativeDropDown
                id="value"
                disabled={!userInquiryPrivileges}
                label="Value" required
                value={dataElement.value}
                onChange={handleChangeDataElement('value')}
                InputLabelProps={{
                  shrink: true
                }}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
               dropdownList={valuesListDropdown.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              />
          </div>

        );
      default:
        return null;
    }
  };
  const tableComp = <TableComponent fixedTable isSearch = {false}  onTableRowDelete = {props.rowDeleteSystemListDetails} headCells={headCells} tableData={props.tableData ? props.tableData : []} onTableRowClick = {props.handleClickOpenNewDialog} defaultSortColumn={'dataElementName'} />;
  return (
    tableComp
  );
}

