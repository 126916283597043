/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const NORECORDS_FOUND_FOR_SEARCH_CRITERIA = 'No records found for the search criteria entered.';
export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const FINANCIALENTITYPAYEE_HEADER = 'Add Financial Entity';
export const FINANCIAL_ENTITY_HEADER_EDIT = 'Edit Financial Entity';
export const PAYEETYPE_REQUIRED = 'Payee Type is required.';
export const PAYEEIDTYPECODE_REQUIRED = 'Payee ID Type Code is required.';
export const PAYEEID_REQUIRED = 'Payee ID is required.';
export const PERSENTVALUE_SHOULDNOT_GREATERTHAN_100 = 'Percentage value cannot be greater than 100.  Please re-enter.';
export const PERSENTVALUE_SHOULDNOT_LESSTHAN_0 = 'A negative Percentage value cannot be entered. Please re-enter.';
export const PERCENT_VAL_BETWEEN_0_100 = 'Recoupment Percentage Value must be between 0 and 100. Please Re-Enter.';
export const INSTALLMENTVALUE_SHOULDNOT_LESSTHAN_0 = 'A negative Installment value is not allowed. Please re-enter.';
export const INSTALLMENTVALUE_SHOULDNOT_BE_EQUAL_0 = 'Installment value must be greater than 0. Please re-enter.';
export const INSTALLMENTVALUE_SHOULDNOT_GREATERTHAN_100 = 'Installment value cannot be greater than 100.  Please re-enter.';
export const CANNT_ENTER_BOTH_INSTALLMENT_AND_PERCENT = 'Both Percent and Installment values are not allowed at the same time. Please re-enter.';
export const FREQUENCY_REQUIRED = 'Frequency is required.';
export const SAVED_SUCCESFULLY_MSG = 'System successfully saved the Information.';
export const RECOUPMENT_LOG_DAY_MUSTBE_GREATERTHAN_ZERO = ' Recoupment Lag Days must be greater than zero.';
export const INVALID_DATE_FORMAT = 'Please enter the date in correct format.';
export const INVALID_DATA_FORMAT = 'Please enter the data in correct format.';
export const PAYEE_NAME_REQ = 'Payee Name is required.'
export const INVALID_ZIP_CODE = 'Zip Code is Invalid.';
export const ZIP_CODE_REQ = 'Zip Code is required.';
export const CITY_REQ = 'City is required.'
export const STATE_REQ = 'State is required.'
export const SIGNIFICANCE_REQ = 'Significance Type Code is required.';
export const ADDRESS_1_REQ = 'Address Line 1 is required.';
export const MIN_DATE_ERRORMSG = 'Date should be greater than 01/01/1964.';
export const EFT_DATE_FALL_BW_BEGIN_END_DATE = 'EFT Status Date should be between Begin Date and End Date.';
export const PRE_NOTE_DATE_FALL_BW_BEGIN_END_DATE = 'Pre Note date should be between Begin Date and End Date.';
export const BEGINDATE_REQUIRED = 'Begin Date is required.';
export const BANKRUPTCY_BEGIN_DATE_REQ = 'Bankruptcy Begin Date is required.';
export const BANKRUPTCY_END_DATE_REQ = 'Bankruptcy End Date is required.';
export const ENDNDATE_REQUIRED = 'End Date is required.';
export const EFTSTATUS_REQUIRED = 'EFT Status is required.';
export const EFTSTATUS_DATE_REQUIRED = 'EFT Status Date is required.';
export const FINANCIAL_INSTITUTION_REQUIRED = 'Financial Institution is required.';
export const PETITION_DEBT_AMT_REQ = 'Posted Petition Debt Amount is required.';
export const CLAIM_AMT_REQ = 'Claim Amount is required.';
export const PETITION_IND_REQ = 'Posted Petition Indicator is required.';
export const ACC_TYPE_REQUIRED = 'Account Type is required.';
export const ROUTING_NUM_REQUIRED = 'Routing Number is required.';
export const BRANCH_REQUIRED = 'Branch is required.';
export const ACC_NUM_REQUIRED = 'Account Number is required.';
export const ACC_HOLD_NAME_REQUIRED = 'Account Holder Name is required.';
export const ENDATE_SHOULDGREATER_OR_EQUAL_BEGINDATE = 'End Date must be greater than or equal to Begin Date.';
export const ROUTING_NUM_LENGTH_MUSTBE_9 = 'Routing Number must be 9 digits.';
export const BANKRUPTCY_BEGIN_DATE_LESS_THAN_END = 'Bankruptcy Begin Date must be less than or equal to Bankruptcy End Date.';
export const BANKRUPTCY_TYPE_REQUIRED = 'Bankruptcy Type Code is required.';
export const ADDRESS_TYPE_REQUIRED = 'Address Type is required.';
export const BANK_ACCOUNT_OVERLAP = 'Bank Account details are overlapping with the existing Bank Account.';
export const PAYEENAME_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const IDNUMBER_REQ = 'ID Number is required.';
export const ID_TYPE_RQ = 'ID Type is required.';
export const END_DATE_REQ = 'End Date is required.';
export const BEGIN_DATE_REQ = 'Begin Date is required.';
export const PAYEE_STATUS_OVERLAP = 'Overlapping Payee Status details.';
export const ADDRESS_OVERLAP = 'Overlapping dates for Payee address details.';
export const PAYEE_IDENTIFIER_OVERLAP = 'Overlapping Payee identifier details for same ID Type.';
export const PHONE_10_DIGITS = 'Phone must be 10 digits.';
export const PAYEE_STATUS_REQ = 'Payee status is required.';
export const PAYEE_REASON_CODE_REQ = 'Payee reason code is required.';
export const PAYEEID_ID_NAME = 'Payee ID or Payee name is required.';
export const ATLEAST_ONE_PAYEE = 'Minimum one record should be added for payee status.';
export const DATE_FORMAT_INVALID = 'Please enter the data in correct format.';
export const YES = 'Yes';