/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const DROPDOWNDATA = "DROPDOWNDATA";
export const SEARCH_ACTION = "SEARCH_ACTION";
export const RESET_SEARCH_ACTION="RESET_SEARCH_ACTION";
export const ACTIVITY_DROPDOWN_ACTION = "ACTIVITY_DROPDOWN_ACTION";
export const UNIQUE_DETAILS = "UNIQUE_DETAILS";
export const UNIQUE_DETAILS_USER_ID = "UNIQUE_DETAILS_USER_ID";
export const UPLOAD_TXN = "UPLOAD_TXN";
