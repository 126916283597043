/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Bootstrap, { Button } from 'react-bootstrap';
import { withRouter } from 'react-router';


/* PayeeSearch()
 * Used to display payee/payer search results
 */
function PayeeSearch(props) {
  let tableRecord = [];
  var lobValue;
  var reasonCodeValue;
  useEffect(() => {
    if (props.location.state.payloadPayeesOutstanding !== undefined) {
      tableRecord = props.location.state.payloadPayeesOutstanding;
    } else if (props.location.state.payloadPayees) {
      tableRecord = props.location.state.payloadPayees;
    }
  }, [props.location.state.payloadPayeesOutstanding, props.location.state.payloadPayees]);

  const [values, setValues] = React.useState({
    lob: '',
    payeeType: '',
    payeeIDTypeCode: '',
    payeeID: '',
    payeeName: '',
    activityType: '',
    reasonCode: '',
    sysPayeeId: ''
  });

  /* Displaying payee/payer search results */
  useEffect(() => {
    if (tableRecord[0].lobCode !== undefined) {
      lobValue = tableRecord[0].lobCode;
      reasonCodeValue = tableRecord[0].payout.reasonCode;
    } else {
      lobValue = tableRecord[0].lob;
      reasonCodeValue = tableRecord[0].reasonCode;
    }
    setValues({
      lob: lobValue || '',
      payeeType: tableRecord[0].payeePayerTypeCode ? tableRecord[0].payeePayerTypeCode : '',
      payeeIDTypeCode: tableRecord[0].payeePayerIDTypeCode ? tableRecord[0].payeePayerIDTypeCode : '',
      payeeID: tableRecord[0].payeePayerID ? tableRecord[0].payeePayerID : '',
      payeeName: tableRecord[0].payeeName ? tableRecord[0].payeeName : '',
      activityType: tableRecord[0].activeTypeCode ? tableRecord[0].activeTypeCode : '',
      reasonCode: reasonCodeValue || '',
      sysPayeeId: tableRecord[0].memberSystemID ? tableRecord[0].memberSystemID : ''
    });
  }, [tableRecord]);

  return (
    <div className="tabs-container">
      <div className="tab-header">
        <div className="tab-heading float-left">
          Gross Level Detail
        </div>
        <div className="clearfix"></div>
      </div>
      <div>
        <form noValidate autoComplete="off">
          <div className='form-wrapper'>
            <div className="mui-custom-form input-md">
              <TextField
                id="lob"
                disabled={true}

                label="LOB"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.lob}
              />
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="payeeType"
                disabled={true}
                label="Payee type"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.payeeType}
              />
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="payeeIDTypeCode"
                disabled={true}
                label="Payee ID Type Code"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.payeeIDTypeCode}
              />
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="payeeID"
                disabled={true}
                label="Payee ID"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.payeeID}
              />
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="payeeName"
                disabled={true}
                label="Payee Name"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.payeeName}
              />
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="activityType"
                disabled={true}
                label="Activity Type"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.activityType}
              />
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="reasonCode"
                disabled={true}
                label="Reason code"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.reasonCode}
              />
            </div>

            <div className="mui-custom-form input-md">
              <TextField
                id="sysPayeeId"
                disabled={true}
                label="System Payee ID"
                InputLabelProps={{
                  shrink: true
                }}
                value={values.sysPayeeId}
              />
            </div>
          </div>
        </form>

      </div>
    </div>
  );
}

export default withRouter((PayeeSearch));
