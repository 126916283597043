/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './FiscalPendCriteriaSearchActionTypes';
import * as serviceEndPoint from '../../../../SharedModules/services/service';
import axios from 'axios';
import { commonAPIHandler } from '../../../../SharedModules/ServerErrorHandler/index';

export const getAuditLogDetailsAction = (tableName, keyValue, auditData) => {
  let body = {
    "tableName": tableName,
    "keyValue": keyValue
  }
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}

export const setAuditLogData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE,
    payload: data,
    keyName
  };
};
export const getAuditLogRowDetailsAction = (tableName, keyValue, auditData) => {
  let body = {
    "tableName": tableName,
    "keyValue": keyValue
  }
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogRowData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}
export const setAuditLogRowData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE_ROW,
    payload: data,
    keyName
  };
};


export const getDropdownDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.DROPDOWNS}`, value)
      .then(response => {
        dispatch(setDropdownData(response.data));
      })
      .catch(error => {
        dispatch(setDropdownData(error));
      });
  };
};

export const setDropdownData = (dropdownData) => {
  return {
    type: actionTypes.DROPDOWNDATA,
    dropdownData: dropdownData
  };
};

export const getMapIDList = (values) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.MAP_ID_DROPDOWN}` + '?mapType=' + values)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setMapId([]));
        } else {
          dispatch(setMapId(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setMapId(sendingResponse));
      });
  };
};

export const setMapId = (mapIdData) => {
  return {
    type: actionTypes.MAP_ID,
    mapIdData: mapIdData
  };
};

export const getBenefitPlanIDList = () => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.BENEFIT_PLAN_ID_DROPDOWN}`)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setBenefitPlanId([]));
        } else {
          dispatch(setBenefitPlanId(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setBenefitPlanId(sendingResponse));
      });
  };
};

export const setBenefitPlanId = (benefitPlanIdData) => {
  return {
    type: actionTypes.BENEFIT_PLAN_ID,
    benefitPlanIdData: benefitPlanIdData
  };
};

export const searchFiscalPendCriteriaAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.SEARCH_FISCAL_PEND_CRITERIA}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (!sendingResponse.enterpriseSearchresultVO) {
          dispatch(searchFiscalPendCriteria(sendingResponse));
        } else {
          dispatch(searchFiscalPendCriteria(sendingResponse.enterpriseSearchresultVO.searchResults));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(searchFiscalPendCriteria(sendingResponse));
      });
  };
};

export const searchFiscalPendCriteria = (searchData) => {
  return {
    type: actionTypes.SEARCH_FISCAL_PEND_CRITERIA,
    searchData: searchData
  };
};

export const getFiscalPendRecordAction = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.GET_FISCAL_PEND_RECORD}` + value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setFiscalPendRecordDetails(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setFiscalPendRecordDetails(sendingResponse));
      });
  };
};

export const setFiscalPendRecordDetails = (searchRecord) => {
  return {
    type: actionTypes.GET_FISCAL_PEND_RECORD,
    searchRecord: searchRecord
  };
};

export const resetState = () => {
  return {
    type: actionTypes.FISCAL_PEND_SEARCH_RESET,
    resetData: []
  };
};
