/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const USER_ID_MIN_SIX_CHARACTERS = 'UserID must contain minimum of 6 Characters.';
export const USER_ID_VALIDATION_ERROR = 'User ID must contain AlphaNumeric and Special characters: Hyphen(‘-‘), Underscore(‘_’), Dot(‘.’).';
export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const UNAME_REQUIRED = 'User ID is required.';
export const FNAME_REQUIRED = 'First Name is required.';
export const LNAME_REQUIRED = 'Last Name is required.';
export const PHONENUMBER_IS_REQUIRED = 'Phone is required.';
export const EMAIL_REQUIRED = 'Email is required.';
export const EMAIL_NOT_PROPER_FORMAT = 'Please enter the Email in correct format.';
export const PNUMBER_NOT_PROPER_FORMAT = 'Phone must be 10 digits.';
export const LOB_IS_REQUIRED = 'LOB is required.';
export const WORKUNIT_IS_REQUIRED = 'WorkUnit is required.';
export const NO_ROLES_SELECTED_TO_MOVE_MAP = 'Select atleast one role to map.';
export const NO_ROLES_SELECTED_TO_MOVE_DEMAP = 'Select atleast one role to demap.';
export const UNAME_REQUIRED_LOGIN = 'UserName is required.';
export const USER_ID_MIN_SIX_CHARACTERS_LOGIN = 'UserName must contain minimum of 6 Characters.';
export const USER_ID_VALIDATION_ERROR_LOGIN = 'UserName must contain AlphaNumeric and Special characters: Hyphen(‘-‘), Underscore(‘_’), Dot(‘.’).';
export const USER_NAME_DOESNT_EXIST = 'UserName does not exist.';
export const PASSWORD_REQUIRED = 'Password is required.';
export const PWD_REQUIRED = 'Password is required.';
export const PASSWORD_VALIDATION_ERROR = 'The password created should be a minimum of 10 characters.'
export const PASSWORD_STANDARD_ERROR = 'Password should not be a standard dictionary word.'
export const USERNAME_REQ_ERR = "UserName is required";
export const USERNAME_MIN_LEN_ERR = "UserName must contain minimum of 6 Characters.";
export const USERNAME_EXIST_ERR = "UserName or Password supplied is invalid.  Please provide the valid credentials";
export const PASSWORD_REQ_ERR = 'Password is required.';
export const USER_LOCKED_ERR = 'Your account has been LOCKED. Please Contact System Administrator or try after sometime';
export const USER_LOCKED_ERR_MESSAGE = 'Account Locked, contact Administrator';