/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const SEARCH_FISCAL_PEND_CRITERIA = 'SEARCH_FISCAL_PEND_CRITERIA';
export const GET_FISCAL_PEND_RECORD = 'GET_FISCAL_PEND_RECORD';
export const FISCAL_PEND_SEARCH_RESET = 'FISCAL_PEND_SEARCH_RESET';
export const DROPDOWNDATA = 'DROPDOWNDATA';
export const MAP_ID = 'MAP_ID';
export const BENEFIT_PLAN_ID = 'BENEFIT_PLAN_ID';
export const AUDIT_LOG_TABLE = "AUDIT_LOG_TABLE";
export const AUDIT_LOG_TABLE_ROW = "AUDIT_LOG_TABLE_ROW";

