/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const SEARCH_BUDGET_MAINTENANCE = 'SEARCH_BUDGET_MAINTENANCE';
export const ROW_SEARCH_BUDGET_MAINTENANCE = 'ROW_SEARCH_BUDGET_MAINTENANCE';
export const RESETDATA = 'RESETDATA';
export const FUND_CODE_SEARCH_CRITERIA = 'FUND_CODE_SEARCH_CRITERIA';
export const FUND_CODE_SEARCH_ROW_CRITERIA = 'FUND_CODE_SEARCH_ROW_CRITERIA';
export const FUND_CODE_SEARCH_ERROR = 'FUND_CODE_SEARCH_ERROR';
export const FUND_CODE_ADD = 'FUND_CODE_ADD';
export const FUND_CODE_UPDATE = 'FUND_CODE_UPDATE';
export const DROPDOWNDATA = 'DROPDOWNDATA';
export const MAP_ID = 'MAP_ID';
export const BENEFIT_PLAN_ID = 'BENEFIT_PLAN_ID';
export const AUDIT_LOG_TABLE = "AUDIT_LOG_TABLE";
export const AUDIT_LOG_TABLE_ROW = "AUDIT_LOG_TABLE_ROW";
export const RESET_RESPONSE = "RESET_RESPONSE"

