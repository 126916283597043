import { Route } from "react-router";
import * as RouteUrls from "./RouteConstants";
const routeHelpTabConfig = [
  // Application Configuration starts
  {
    route: RouteUrls.DASHBOARD,
    helperId: "WELCOME_INTERNAL_USERS",
  },  
  {
    route: RouteUrls.VALID_VALUE_SEARCH,
    helperId: "DATA_ELEMENT_VALID_VALUES_SEARCH",
  },  
  {
    route: RouteUrls.VALID_VALUE_VIEW,
    helperId: "DATA_ELEMENT_VALID_VALUES_EDIT",
  },  
  {
    route: RouteUrls.SYSTEM_LIST,
    helperId: "SYSTEM_LIST_SEARCH",
  },
  {
    route: RouteUrls.SYSTEM_LIST_ADD,
    helperId: "SYSTEM_LIST_ADD_EDIT",
  },  
  {
    route: RouteUrls.SYSTEM_PARAMETER,
    helperId: "SYSTEM_PARAMETER_SEARCH",
  },  
  {
    route: RouteUrls.SYSTEM_PARAMETER_ADD,
    helperId: "SYSTEM_PARAMETER_ADD_EDIT",
  },  
  {
    route: RouteUrls.SYSTEM_PARAMETER_EDIT,
    helperId: "SYSTEM_PARAMETER_ADD_EDIT",
  },  
  {
    route: RouteUrls.SYSTEM_LIST_ADD_UPDATE,
    helperId: "SYSTEM_LIST_ADD_EDIT",
  },  
  {
    route: RouteUrls.MAPSET_SEARCH,
    helperId: "MAP_DEFINITION_SEARCH",
  },  
  {
    route: RouteUrls.MAPSET_ADD,
    helperId: "MAP_DEFINITION_ADD_EDIT",
  },  
  {
    route: RouteUrls.MAPSET_EDIT,
    helperId: "MAP_DEFINITION_ADD_EDIT",
  },  
  {
    route: RouteUrls.TEXT_MANAGEMENT_SEARCH,
    helperId: "TEXT_MGMT_SEARCH",
  },  
  {
    route: RouteUrls.TEXT_UPDATE,
    helperId: "TEXT_MGMT_ADD_EDIT",
  },
  {
    route: RouteUrls.TEXT_ADD,
    helperId: "TEXT_MGMT_ADD_EDIT",
  },
  //  Application Configuration end
  //  Bank Account & LockBox starts
  {
    route: RouteUrls.BANK_ACCOUNT_AND_LOCKBOX,
    helperId: "BANK_ACCOUNT_SEARCH",
  },
  {
    route: RouteUrls.BANK_ACCOUNT_LOCKBOX_ADD,
    helperId: "BANK_ACCOUNT_ADD_EDIT",
  },
  {
    route: RouteUrls.BANK_ACCOUNT_LOCKBOX_EDIT,
    helperId: "BANK_ACCOUNT_ADD_EDIT",
  },
  // Bank Account & LockBox ends
  // Budget Maintenance starts
  {
    route: RouteUrls.FINANCIAL_BUDGET_SEARCH,
    helperId: "BUDGET_INQUIRY_SEARCH",
  },
  {
    route: RouteUrls.BUDGET_DATA_UPDATE,
    helperId: "BUDGET_INQUIRY_EDIT",
  },
  {
    route: RouteUrls.BUDGET_DATA_ADD,
    helperId: "BUDGET_INQUIRY_ADD",
  },
  // Budget Maintenance ends
  // Account Receivables starts
  {
    route: RouteUrls.FINANCIAL_ACCOUNT_INQUIRY,
    helperId: "SEARCH_ACCOUNTS_RECEIVABLE",
  },
  {
    route: RouteUrls.FCN_SEARCH_RESULTS,
    helperId: "SEARCH_ACCOUNTS_RECEIVABLE",
  },
  {
    route: RouteUrls.GROSS_LEVEL_ADD_PAYEE_COMPONENT,
    helperId: "ACCOUNTS_RECEIVABLE_ABOUT",
  },
  {
    route: RouteUrls.FINANCIAL_ACCOUNT_GROSS_LEVEL,
    helperId: "ACCOUNTS_RECEIVABLE_ABOUT",
  },
  {
    route: RouteUrls.PAYEE_SEARCH_RESULTS,
    helperId: "ACCOUNTS_RECEIVABLE_SEARCH",
  },
  {
    route: RouteUrls.MCN_SEARCH_RESULTS,
    helperId: "ACCOUNTS_RECEIVABLE_SEARCH",
  },
  {
    route: RouteUrls.FINANCIAL_ACCOUNT_LIEN_LEVY,
    helperId: "ACCOUNTS_RECEIVABLE_ABOUT",
  },
  {
    route: RouteUrls.CLAIMS_RECEIVABLE,
    helperId: "ACCOUNTS_RECEIVABLE_ABOUT",
  },
  {
    route: RouteUrls.FINANCIAL_ACCOUNT_LIEN_LEVY_UPDATE,
    helperId: "ACCOUNTS_RECEIVABLE_ABOUT",
  },
  {
    route: RouteUrls.FINANCIAL_ACCOUNT_GROSS_LEVEL_UPDATE,
    helperId: "ACCOUNTS_RECEIVABLE_ABOUT",
  },
  {
    route: RouteUrls.AR_TRANSFER,
    helperId: "ACCOUNTS_RECEIVABLE_ABOUT",
  },
  {
    route: RouteUrls.AR_REVERSE,
    helperId: "ACCOUNTS_RECEIVABLE_ABOUT",
  },
  {
    route: RouteUrls.AR_ADJUST,
    helperId: "ACCOUNTS_RECEIVABLE_ABOUT",
  },
  {
    route: RouteUrls.FP_ADJUST,
    helperId: "ACCOUNTS_RECEIVABLE_ABOUT",
  },
  {
    route: RouteUrls.ADVANCE_PAYMENT,
    helperId: "ACCOUNTS_RECEIVABLE_ABOUT",
  },
  {
    route: RouteUrls.WRITE_OFF,
    helperId: "ACCOUNTS_RECEIVABLE_ABOUT",
  },
  {
    route: RouteUrls.WRITE_OFF_REESTABLISHMENT,
    helperId: "ACCOUNTS_RECEIVABLE_ABOUT",
  },
  {
    route: RouteUrls.BULK_MAINTENANCE,
    helperId: "BULK_MAINTENANCE",
  },
  {
    route: RouteUrls.AR_BULK_TRANSFER,
    helperId: "BULK_MAINTENANCE",
  },
  {
    route: RouteUrls.BULK_REVERSE,
    helperId: "BULK_MAINTENANCE",
  },
  {
    route: RouteUrls.BULK_WRITE_OFF,
    helperId: "BULK_MAINTENANCE",
  },
  {
    route: RouteUrls.BULK_WRITE_OFF_REEST,
    helperId: "BULK_MAINTENANCE",
  },
  {
    route: RouteUrls.CHANGE_PAY_STATUS,
    helperId: "BULK_MAINTENANCE",
  },
  // Account Receivables ends
  // Security starts
  {
    route: RouteUrls.ADD_USER,
    helperId: "ADD_EDIT_USER_PAGE",
  },
  {
    route: RouteUrls.EDIT_USER,
    helperId: "ADD_EDIT_USER_PAGE",
  },
  {
    route: RouteUrls.USER_PROFILE,
    helperId: "FIELDDESC_MANAGE_USER_EDIT_VIEW",
  },
  {
    route: RouteUrls.SEARCH_USER,
    helperId: "MANAGE_USERS_SEARCH",
  },
  {
    route: RouteUrls.RESET_PASSWORD,
    helperId: "MANAGE_USERS_RESET_PASSWORD",
  },
  {
    route: RouteUrls.CHANGE_PASSWORD,
    helperId: "CHANGE_PASSWORD_EDIT",
  },
  // Security ends
  // Financial Entity starts
  {
    route: RouteUrls.FINANCIAL_ENTITY_SEARCH,
    helperId: "FINANCIAL_ACCOUNTING_ENTITY_SEARCH",
  },
  {
    route: RouteUrls.FINANCIAL_ENTITY_ADD,
    helperId: "FINANCIAL_ACCOUNTING_ENTITY_ADD_EDIT",
  },
  {
    route: RouteUrls.FINANCIAL_ENTITY_UPDATE,
    helperId: "FINANCIAL_ACCOUNTING_ENTITY_ADD_EDIT",
  },
  // Financial Entity ends
  // Financial Receipt starts
  {
    route: RouteUrls.FINANCIAL_RECEIPT_SEARCH,
    helperId: "FINANCIAL_RECEIPTS_SEARCH",
  },
  {
    route: RouteUrls.FINANCIAL_RECEIPT_RETURN,
    helperId: "FINANCIAL_RECEIPTS_RETURN_VOID",
  },
  {
    route: RouteUrls.FINANCIAL_RECEIPT_ADD,
    helperId: "FINANCIAL_RECEIPTS_ADD_EDIT",
  },
  {
    route: RouteUrls.FINANCIAL_RECEIPT_UPDATE,
    helperId: "FINANCIAL_RECEIPTS_ADD_EDIT",
  },
  {
    route: RouteUrls.FINANCIAL_RECEIPT_ADJUSTMENT,
    helperId: "FINANCIAL_RECEIPTS_ADJUSTMENT",
  },
  {
    route: RouteUrls.FINANCIAL_RECEIPT_REVERSAL,
    helperId: "FINANCIAL_RECEIPTS_REVERSAL",
  },
  {
    route: RouteUrls.REISSUE_UPDATE,
    helperId: "FINANCIAL_RECEIPTS_REPLACEMENT",
  },
  // Financial Receipt ends
  // Fund Code starts
  {
    route: RouteUrls.FUND_CODE_SEARCH,
    helperId: "ADJUD_FUND_CODE_SEARCH",
  },
  {
    route: RouteUrls.FUND_CODE_ADD,
    helperId: "ADJUD_FUND_CODE_ADD_EDIT",
  },
  {
    route: RouteUrls.FUND_CODE_EDIT,
    helperId: "ADJUD_FUND_CODE_ADD_EDIT",
  },
  // Fund Code ends
  // Financial Payout starts
  {
    route: RouteUrls.FINANCIAL_PAYOUT_SEARCH,
    helperId: "ACCOUNTS_PAYABLE_PAYOUT_SEARCH",
  },
  {
    route: RouteUrls.FCN_PAYOUT_SEARCH_RESULTS,
    helperId: "ACCOUNTS_PAYABLE_PAYOUT_SEARCH",
  },
  {
    route: RouteUrls.FINANCIAL_PAYOUT_ADD,
    helperId: "ACCOUNTS_PAYABLE_PAYOUT_ADD_EDIT",
  },
  {
    route: RouteUrls.FINANCIAL_PAYOUT_UPDATE,
    helperId: "ACCOUNTS_PAYABLE_PAYOUT_ADD_EDIT",
  },
  // Financial Payout ends
  // Accounts Payable starts
  {
    route: RouteUrls.PAYOUT_APPROVAL_SEARCH,
    helperId: "ACCOUNTS_PAYABLE_PAYOUT_APPROVAL_ABOUT",
  },
  // Accounts Payable ends
  // Fiscal Pend Criteria start
  {
    route: RouteUrls.FISCAL_PEND_CRITERIA_ADD,
    helperId: "ADJUD_FISCAL_PEND_ADD_EDIT",
  },
  {
    route: RouteUrls.FISCAL_PEND_CRITERIA_EDIT,
    helperId: "ADJUD_FISCAL_PEND_ADD_EDIT",
  },
  {
    route: RouteUrls.FISCAL_PEND_CRITERIA_SEARCH,
    helperId: "ADJUD_FISCAL_PEND_SEARCH",
  },
  // Fiscal Pend Criteria ends
  // 1099 Correction start
  {
    route: RouteUrls.CORRECTION_SEARCH,
    helperId: "HOW_TO_SEARCH_FOR_PROVIDER_1099_INFORMATION",
  },
  {
    route: RouteUrls.CORRECTION_DETAILS_VIEW,
    helperId: "_1099_PAGE_FIELDS",
  },
  {
    route: RouteUrls.CORRECTION_SEARCH_APPROVAL,
    helperId: "HOW_TO_SEARCH_FOR_AND_APPROVE_1099_CORRECTIONS",
  },
  // 1099 Correction ends
  // Financial Inquiry start
  {
    route: RouteUrls.FINANCIAL_INQUIRY_SEARCH,
    helperId: "FINANCIAL_ACCOUNTING_ENTITY_SEARCH",
  },
  {
    route: RouteUrls.CLAIM_SUMMARY,
    helperId: "ACCOUNTS_RECEIVABLE_CLAIM_RECEIVABLE_ABOUT",
  },
  // Financial Inquiry ends
  // Project Control starts
  {
    route: RouteUrls.EVENT_LOGGING,
    helperId: "EVENT_LOGGING_SEARCH",
  },
  {
    route: RouteUrls.GLOBAL_AUDIT_SEARCH,
    helperId: "GLOBAL_AUDIT_SEARCH",
  },
  {
    route: RouteUrls.GLOBAL_AUDIT_DETAILS,
    helperId: "FIELDDESC_GLOBAL_AUDIT_VIEW",
  },
  // Project Control ends
  // EMAR starts
  {
    route: RouteUrls.EMAR_SEARCH,
    helperId: "SEARCH_EMAR_PAGE",
  },
  {
    route: RouteUrls.EMAR_ADD,
    helperId: "ADD_EMAR_PAGE",
  },
  {
    route: RouteUrls.EMAR_UPDATE,
    helperId: "EDIT_EMAR_PAGE",
  },
  // EMAR ends
  // Waiver starts
  {
    route: RouteUrls.WAIVER_SEARCH,
    helperId: "WAIVER_SERVICES",
  },
  {
    route: RouteUrls.WAIVER_ADD,
    helperId: "WAIVER_SERVICES",
  },
  {
    route: RouteUrls.WAIVER_UPDATE,
    helperId: "WAIVER_SERVICES",
  },
  // Waiver ends
  // FRC starts
  {
    route: RouteUrls.FRC_ADD,
    helperId: "ADD_FRC_PAGE",
  },
  {
    route: RouteUrls.FRC_SEARCH,
    helperId: "SEARCH_FRC_PAGE",
  },
  {
    route: RouteUrls.FRC_UPDATE,
    helperId: "EDIT_FRC_PAGE",
  },
  // FRC ends
  // CMS starts
  {
    route: RouteUrls.CMS64_SEARCH,
    helperId: "SEARCH_CMS_64_PAGE",
  },
  {
    route: RouteUrls.CMS64_ADD,
    helperId: "ADD_CMS_64_PAGE",
  },
  {
    route: RouteUrls.CMS64_UPDATE,
    helperId: "EDIT_CMS_64_PAGE",
  },
  // CMS ends
  // Mass Upload starts
  {
    route: RouteUrls.FINANCIAL_UPLOAD,
    helperId: "HOW_TO_UPLOAD_FINANCIAL_TRANSACTIONS",
  },
  {
    route: RouteUrls.FINANCIAL_UPLOAD_VIEW,
    helperId: "FINANCIAL_UPLOAD_FIELDS_AND_DESCRIPTIONS",
  },
  // Mass Upload ends
  //Payment Inquiry Start
  {
    route: RouteUrls.PAYMENT_INQUIRY_STATUS,
    helperId: "PAYMENT_STATUS_INQUIRY",
  },
//Payment Inquiry End

//Payment Criteria Start
{
  route: RouteUrls.PAYMENT_CRITERIA_SEARCH,
  helperId: "SEARCH4",
},

{
  route: RouteUrls.PAYMENT_CRITERIA_ADD,
  helperId: "SEARCH4",
},

{
  route: RouteUrls.PAYMENT_CRITERIA_EDIT,
  helperId: "SEARCH4",
},
//Payment Criteria End

];
export default routeHelpTabConfig;