/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import TableComponent from '../../../../SharedModules/Table/Table';
import TextField from '@material-ui/core/TextField';
import { textAlign } from '@material-ui/system';
import numeral from 'numeral';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getShortDescription } from '../../../../SharedModules/CommonUtilities/commonUtilities';

/* ClaimsOriginating()
* This component is used to display Claims Receivable originating (inside 2nd tab in claims receivables screen)
* Inside this component we are getting values using props sent by ClaimsReceivable.js
*/
export default function ClaimsOriginating(props) {
  let claimsOriginatingData = [];
  
  if (props.tabData !== undefined) {
    claimsOriginatingData = props.tabData.originatingClaimsList.map(data=> {
      return {...data, adjustmentReasonCode:getShortDescription(props.aRRSNCDData, data.adjustmentReasonCode||'')};
     }) ;
  }
  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const adjustmentReasonCode =
            props.voidRscdDropdown['C1#C_VOID_REPLCMT_RSN_CD'] &&
            props.voidRscdDropdown['C1#C_VOID_REPLCMT_RSN_CD'].filter(
              value => value.code === each.adjustmentReasonCode
            );
          each.reasonCodeDesc =
          adjustmentReasonCode && adjustmentReasonCode.length > 0
              ? adjustmentReasonCode[0].description
              : each.adjustmentReasonCode 
              ? each.adjustmentReasonCode 
              : '';
          each.activityType = each.receipt ? "Receipt" : "FTTCN"
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  const editRow = row => event => { };

  const onLinkClick = row => event => {
    window.open('fcn', '_blank');
  };

  const headCells = [
    { id: 'transactionCtrlNumber', disablePadding: false, width: '33.3%', label: 'TCN/FCN', isRedirectable: true },
    { id: 'reasonCodeDesc', disablePadding: false, width: '33.3%', label: 'Adjustment Reason Code' },
    { id: 'receivableAmount', disablePadding: false, label: 'Applied Amount', enableHyperLink: false, isBalance: true, width: '33.3%', numeric: true }
    
  ];
  let num=numeral(props.values.originalAmount).format('0,0.00')* (-1);
  return (
       <div className="tab-holder tableNoWrap">
      <div className="mui-custom-form input-md position-relative ml-0">
        <i class="fa fa-info-circle info" aria-hidden="true" title="Sum of Applied Amount"> </i>
        <TextField
              id="amount"
              label= "Total Amount"
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' }}
        
              InputLabelProps={{
                shrink: true
              }}
              
              value={num} 
              />
            </div>
              <TableComponent fixedTable headCells={headCells} tableData={getTableData(claimsOriginatingData) || []} onTableRowClick={editRow} defaultSortColumn={headCells[0].id} isSearch={true}
          onLinkClick={onLinkClick} />
      </div>

  );
}
