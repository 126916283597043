const ROUTE_HOST_ENV = process.env.REACT_APP_ROUTE_ENV;

/* All Dropdown Values */
export const DROPDOWS = ROUTE_HOST_ENV + 'SystemList/getDropDowns';
export const APP_CONFIG_DROPDOWN = ROUTE_HOST_ENV + 'SystemList/getReferenceData';
export const DATA_ELEMENT_DROPDOWN = ROUTE_HOST_ENV + 'SystemList/getAllDomainNames';
export const DATA_ELEMENT_MAP_DROPDOWN = ROUTE_HOST_ENV + 'MapDefinition/getAllMapSets';
export const MODIFIER_DROPDOWN = ROUTE_HOST_ENV + 'SystemList/getModifierList';
export const VALID_VALUE_DROPDOWNS = ROUTE_HOST_ENV + 'ValidValue/getdomainAllvalues';

/* System Parameter End-Points Start */
export const SYSTEM_PARAMETER_SEARCH_ENDPOINT = ROUTE_HOST_ENV + 'SystemParameter/getSystemParameter';
export const SYSTEM_PARAMETER_MULTI_SEARCH_ENDPOINT = ROUTE_HOST_ENV + 'SystemParameter/getSystemParameterUsingSearchCriteria';
export const UPDATE_SYSTEMPARAMETER = ROUTE_HOST_ENV + 'SystemParameter/updateSystemParameter';
export const ADD_SYSTEM_PARAMETER = ROUTE_HOST_ENV + 'SystemParameter/createSystemParameter';
export const SYSTEM_PARAMETER_SPECIFIC_VALUE = ROUTE_HOST_ENV + 'SystemParameter/getSpecificSystemParameter';
/* System Parameter End-Points END */

/* Valid Value End-Points Start */
export const VALID_VALUE_SEARCH_ENDPOINT = ROUTE_HOST_ENV + 'ValidValue/getValidValue';
export const VALID_VALUE_UPDATE_ENDPOINT = ROUTE_HOST_ENV + 'ValidValue/updateValidValue';
/* Valid Value End-Points End */

/* Text Management Start */
export const TEXT_MANAGEMENT_SEARCH_ENDPOINT = ROUTE_HOST_ENV + 'TextData/getEOMBText';
export const TEXT_MANAGEMENT_SEARCH_RAEOB_ENDPOINT = ROUTE_HOST_ENV + 'TextData/getRAEOBText';
export const TEXT_MANAGEMENT_SEARCH_PN_ENDPOINT = ROUTE_HOST_ENV + 'TextData/getProviderNoticeText';
export const TEXT_MANAGEMENT_SEARCH_SP_ENDPOINT = ROUTE_HOST_ENV + 'TextData/getEOMBText';
export const TEXT_MANAGEMENT_SEARCH_REVENUE_ENDPOINT = ROUTE_HOST_ENV + 'TextData/getEOMBText';
export const TEXT_MANAGEMENT_SEARCH_RC_ENDPOINT = ROUTE_HOST_ENV + 'TextData/getRemarkCodeText';
export const TEXT_MANAGEMENT_SEARCH_ARC_ENDPOINT = ROUTE_HOST_ENV + 'TextData/getAdjustmentReasonText';
export const TEXT_MANAGEMENT_SEARCH_NCPDP_ENDPOINT = ROUTE_HOST_ENV + 'TextData/getNCPDPRejectCodeText';

/* Text Management Add End-Points Start */
export const TEXT_MANAGEMENT_ADD_ADJUSTMENT_REASON_ENDPOINT = ROUTE_HOST_ENV + 'TextData/createAdjustmentReasonText';
export const TEXT_MANAGEMENT_NCPDP_ENDPOINT = ROUTE_HOST_ENV + 'TextData/createNCPDPRejectCdText';
export const PROVIDER_SPECIALTY_ENDPOINT = ROUTE_HOST_ENV + 'TextData/getProviderSpecialities?providerTypeCode=';
export const TEXT_MANAGEMENT_ADD_EOMB_ENDPOINT = ROUTE_HOST_ENV + 'TextData/createEOMBText';
export const TEXT_MANAGEMENT_ADD_RAEOB_ENDPOINT = ROUTE_HOST_ENV + 'TextData/createRAEOBText ';
export const TEXT_MANAGEMENT_ADD_PN_ENDPOINT = ROUTE_HOST_ENV + 'TextData/createProviderNoticeText';
export const TEXT_MANAGEMENT_ADD_REMARK_TEXT_ENDPOINT = ROUTE_HOST_ENV + 'TextData/createRemarkCodeText';
/* Text Management Add End-Points End */

/* Text Management update End-Points Start */
export const TEXT_MANAGEMENT_UPDATE_ADJUSTMENT_REASON_ENDPOINT = ROUTE_HOST_ENV + 'TextData/updateAdjustmentReasonText';
export const TEXT_MANAGEMENT_UPDATE_NCPDP_ENDPOINT = ROUTE_HOST_ENV + 'TextData/updateNCPDPRejectCdText';
export const TEXT_MANAGEMENT_UPDATE_EOMB_ENDPOINT = ROUTE_HOST_ENV + 'TextData/updateEOMBText';
export const TEXT_MANAGEMENT_UPDATE_RAEOB_ENDPOINT = ROUTE_HOST_ENV + 'TextData/updateRAEOBText';
export const TEXT_MANAGEMENT_UPDATE_PN_ENDPOINT = ROUTE_HOST_ENV + 'TextData/updateProviderNoticeText';
export const TEXT_MANAGEMENT_UPDATE_REMARK_TEXT_ENDPOINT = ROUTE_HOST_ENV + 'TextData/updateRemarkCodeText';
export const TEXT_MANAGEMENT_DELETE_EOMBTEXT = ROUTE_HOST_ENV + 'TextData/deleteEOMBText';
export const TEXT_MANGEMENT_DELETE_RAEOB = ROUTE_HOST_ENV + 'TextData/deleteRAEOBText';
/* Text Management update End-Points End */

/* Map Set End-Points Start */
export const MAP_SET_SEARCH_ENDPOINT = ROUTE_HOST_ENV + 'MapDefinition/searchMapDefinition';
export const MAP_DEFINITION_ADD = ROUTE_HOST_ENV + 'MapDefinition/createMapDefinition';
export const MAP_DEFINITION_UPDATE = ROUTE_HOST_ENV + 'MapDefinition/updateMapDefinition';
/* Map Set End-Points End */

/* System List End-Points Start */
export const SYSTEM_LIST_SEARCH_ENDPOINT = ROUTE_HOST_ENV + 'SystemList/getSystemList';
export const SYSTEM_LIST_SEARCH_ENDPOINT_NEW = ROUTE_HOST_ENV + 'SystemList/getMapDefinitionValuesList';
export const SYSTEM_LIST_ADD = ROUTE_HOST_ENV + 'SystemList/createSystemList';
export const SYSTEM_LIST_UPDATE = ROUTE_HOST_ENV + 'SystemList/updateSystemList';
/* System List End-Points End */

/* Audit log End-Points Start */
export const AUDIT_LOG_ENDPOINT = ROUTE_HOST_ENV + 'auditlogs/getAuditRequest';
export const AUDIT_LOG_MULTIPLE_ENDPOINT =  ROUTE_HOST_ENV + 'auditlogs/getAuditRequests';

// export const AUDIT_LOG_ENDPOINT = 'http://10.142.246.138:8080/auditlogs/getAuditRequest';
/* Audit log End-Points End */
