/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const SUB_DROPDOWNDATA = 'SUB_DROPDOWNDATA';
export const CREATE_FRC = 'CREATE_FRC';
export const UPDATE_FRC = 'UPDATE_FRC';
export const SEARCH_FRC = 'SEARCH_FRC';
export const RESET_FRC = 'SEARCH_FRC';




