/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PayeeJson from "./PayeType.json";
import AddressDetails from "./AddressDetails";
import axios from "axios";
import clsx from "clsx";
export default function AddressDetailTab(props) {
  const {
    AddressTypeDropdown,
    SignificanceTypeDropdown,
    StatusCodeDropdown,
    CountyDropdown,
    CountryDropdown,
    StateDropdown,
    ChangeReasonDropdown,
    showEditPage,
    payeeValues,
    addressTableData,
    setAddressTableData,
    setAllowNavigation,
    callValidatePayeeDetails,
    disableFields,
    seterrorMessages,
    showLogAddressTable,
    auditRowData,
    setAuditRowData,
    setShowLogTable,
    setActiveAddressData,
    userInquiryPrivileges,
    setFocusCheck,
    initialFocusCheck
  } = props;
  const [values, setValues] = React.useState({
    installmentvalue: "",
    percentagevalue: "",
    frequency: ""
  });
  const [value, setValue] = React.useState(0);
  const handleChanges = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className="tab-holder p-0">
        <fieldset className="r-custom-fieldset no-padding">
          <legend>Address:</legend>
          <AddressDetails
            print
            disableFields={disableFields}
            callValidatePayeeDetails={callValidatePayeeDetails}
            setAllowNavigation={setAllowNavigation}
            setAddressTableData={setAddressTableData}
            addressTableData={addressTableData}
            showEditPage={showEditPage}
            payeeValues={payeeValues}
            AddressTypeDropdown={AddressTypeDropdown || []}
            SignificanceTypeDropdown={SignificanceTypeDropdown || []}
            StatusCodeDropdown={StatusCodeDropdown || []}
            CountyDropdown={CountyDropdown || []}
            CountryDropdown={CountryDropdown || []}
            StateDropdown={StateDropdown || []}
            ChangeReasonDropdown={ChangeReasonDropdown || []}
            onClickAuditDialogLog={props.onClickAuditDialogLog}
            uniqueKey="addressSK"
            setClose={props.setClose}
            close={props.close}
            seterrorMessages={seterrorMessages}
            showLogAddressTable={showLogAddressTable}
            auditRowData={auditRowData}
            setAuditRowData={setAuditRowData}
            setShowLogTable={setShowLogTable}
            setActiveAddressData={setActiveAddressData}
            userInquiryPrivileges = {userInquiryPrivileges}
            setFocusCheck={setFocusCheck}
            initialFocusCheck={initialFocusCheck}
            dropdowns = {props.dropdowns}
          />
        </fieldset>
      </div>

    </div>
  );
}
