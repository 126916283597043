/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import Footer from '../../../SharedModules/Layout/footer';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import * as moment from 'moment';
import { Button } from 'react-bootstrap';
import ErrorMessages from '../../../SharedModules/Errors/ErrorMessages';
import { useSelector, useDispatch } from 'react-redux';
import dropdownCriteria from './PaymentStatusInquiry.json';
import * as AppConstants from '../../../SharedModules/AppConstants';
import { removeLeadingTrailingSpaces } from '../../../SharedModules/CommonUtilities/commonUtilities';
import { PaymentStatusInquirySearchAction, resetState } from '../../PaymentStatusInquiry/Store/Actions/PaymentStatusInquiryAction';
import { getDropdownDataAction } from '../../FinancialEntities/Store/Actions/FinancialEntityActions';
import * as FinancialEntityConstants from '../../FinancialEntities/Components/FinancialEntityConstants';
import { withRouter, useHistory } from 'react-router';
import {
  validateDateMinimumValue
} from "../../../SharedModules/DateUtilities/DateUtilities";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import { GET_APP_DROPDOWNS } from "../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../SharedModules/Dropdowns/dropdowns";
import PaymentStatusInquiryTable from '../Components/PaymentStatusInquiryTable';
import PaymentStatusInquiryView from '../Components/PaymentStatusInquiryView';
import NativeDropDown from '../../../SharedModules/Dropdowns/NativeDropDown';

function PaymentStatusInquirySearch (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const [showTable, setShowTable] = React.useState(false);
  const [showDetailView, setShowDetailView] = React.useState(false);
  const [showData, setShowData] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [erroMessage, setErrorMessage] = React.useState({
    payeeIDErrorMsg: '',
    payeeNameErrorMsg: ''
  });
  const history = useHistory();
  const [detailsData, setDetailData] = React.useState({});

  const getDropdownData = (dropdownValues) => dispatch(getDropdownDataAction(dropdownValues));
  const onSearchView = searchValues => dispatch(PaymentStatusInquirySearchAction(removeLeadingTrailingSpaces(searchValues)));
  const searchPaymentResponse = useSelector(
    state => state.paymentStatusInquiryState.searchPaymentData
  );
  const dropdown = useSelector(state => state.financialEntityState.dropdownData);
  const OnReset = () => dispatch(resetState());
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const [values, setValues] = React.useState({
    payeeType: DEFAULT_DD_VALUE,
    payeeIDTypeCode: DEFAULT_DD_VALUE,
    payeeID: '',
    payeeName: '',
    payeeNameStartsWithOrContains: '',
    remittanceAdviceNumber: '',
    checkEFTNumber: '',
    checkEFTStatus: DEFAULT_DD_VALUE,
    paymentDate: null
  });
  const dispatch = useDispatch();
  const toPrintRef = useRef();

  const [paymentDate, setPaymentDate] = React.useState(null);
  const [searchtableData, setSearchTableData] = React.useState([]);
  const [payeeTypeDataFrmState, setPayeeTypeDataFrmState] = React.useState([]);
  const [descriptionDropdownValues, setDropdownDescriptionValues] = React.useState([]);
  const [payeePaymentStatusFrmState, setPayeePaymentStatusFrmState] = React.useState([]);
  const [payeeIDTypeFrmState, setPayeeIDTYpeFrmState] = React.useState([]);
  const [EFTStatusDropdown, setEFTStatusDropdown] = React.useState([]);
  const [payeeIDTypeDropdown, setPayeeIDTypeDropdown] = React.useState({
    memberDropdown: [],
    otherDropdown: [],
    providerDropdown: [],
    tplDropdown: []
  });

  const handlePaymentDate = (date) => {
    setPaymentDate(date);
  };

  useEffect(() => {
    OnReset();
    getDropdownData(dropdownCriteria);
    onDropdowns([
      Dropdowns.PAYEE_TYPE,
      Dropdowns.PAYEE_ID_TYPE_CD_MEMBER,
      Dropdowns.PAYEE_ID_TYPE_CD_PROVIDER,
      Dropdowns.PAYEE_ID_TYPE_CD_OTHER,
      Dropdowns.PAYEE_ID_TYPE_CD_TPL,
      Dropdowns.PAYMENT_METHOD,
      Dropdowns.WARR_STAT_CD,
      Dropdowns.F_RSN_CD
    ]);
  }, []);

  // DropdownFilter starts
  const dropDownFilter = (dropDownType, value) => {
    let TypeDropdownData = [];
    switch (dropDownType) {
      case 'payeeType':
        TypeDropdownData = payeeTypeDataFrmState;
        break;
      case 'payeeIDTypeCode':
        TypeDropdownData = descriptionDropdownValues;
        break;
    }
    if (TypeDropdownData) {
      const filteredValue = TypeDropdownData.filter((dropdownTypeValue) => dropdownTypeValue.code === value);
      if (filteredValue && filteredValue.length > 0) {
        return filteredValue[0].description;
      }
      return value;
    }
  };

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['General#6002']) {
        setPayeeTypeDataFrmState(dropdown.listObj['General#6002']);
      }
      if (dropdown.listObj['Financial#6022']) {
        setEFTStatusDropdown(dropdown.listObj['Financial#6022']);
      }
      const payeeIDTypeDropdownTemp = { memberDropdown: [], otherDropdown: [], providerDropdown: [], tplDropdown: [] };
      if (dropdown.listObj['Financial#1023']) {
        setPayeeIDTypeDropdown({ ...payeeIDTypeDropdown, tplDropdown: dropdown.listObj['Financial#1023'] });
        payeeIDTypeDropdownTemp.tplDropdown = dropdown.listObj['Financial#1023'];
      }
      if (dropdown.listObj['Financial#1024']) {
        setPayeeIDTypeDropdown({ ...payeeIDTypeDropdown, otherDropdown: dropdown.listObj['Financial#1024'] });
        payeeIDTypeDropdownTemp.otherDropdown = dropdown.listObj['Financial#1024'];
      }
      if (dropdown.listObj['Financial#2']) {
        setPayeeIDTypeDropdown({ ...payeeIDTypeDropdown, memberDropdown: dropdown.listObj['Financial#2'] });
        payeeIDTypeDropdownTemp.memberDropdown = dropdown.listObj['Financial#2'];
      }
      if (dropdown.listObj['Financial#3']) {
        setPayeeIDTypeDropdown({ ...payeeIDTypeDropdown, providerDropdown: dropdown.listObj['Financial#3'] });
        payeeIDTypeDropdownTemp.providerDropdown = dropdown.listObj['Financial#3'];
      }
      setPayeeIDTypeDropdown({ ...payeeIDTypeDropdown, ...payeeIDTypeDropdownTemp });
      if (dropdown && dropdown.listObj && dropdown.listObj['Financial#3'] && dropdown.listObj['Financial#2'] && dropdown.listObj['Financial#1023'] && dropdown.listObj['Financial#1024']) {
        const mergedropdowns = [];
        dropdown.listObj['Financial#3'].map((row) => {
          mergedropdowns.push(row);
        });
        dropdown.listObj['Financial#2'].map((row) => {
          mergedropdowns.push(row);
        });
        dropdown.listObj['Financial#1023'].map((row) => {
          mergedropdowns.push(row);
        });
        dropdown.listObj['Financial#1024'].map((row) => {
          mergedropdowns.push(row);
        });
        if (mergedropdowns && mergedropdowns.length > 0) {
          setDropdownDescriptionValues(mergedropdowns);
        }
      }
      if (dropdown.listObj['Financial#6023']) {
        setPayeePaymentStatusFrmState(dropdown.listObj['Financial#6023']);
      }
    }
  }, [dropdown]);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
    if (name === 'payeeType' && event.target.value !== DEFAULT_DD_VALUE) {
      const payeeType = event.target.value;
      setValues({
        payeeType: event.target.value,
        payeeIDTypeCode: DEFAULT_DD_VALUE,
        payeeID: values.payeeID,
        payeeName: values.payeeName,
        payeeNameStartsWithOrContains: values.payeeNameStartsWithOrContains,
        remittanceAdviceNumber: values.remittanceAdviceNumber,
        checkEFTNumber: values.checkEFTNumber,
        checkEFTStatus: values.checkEFTStatus
      });
      payeeTypecodeSwitch(payeeType);
    }
    if (name === 'payeeType' && event.target.value === DEFAULT_DD_VALUE) {
      setPayeeIDTYpeFrmState([]);
      setValues({
        payeeType: DEFAULT_DD_VALUE,
        payeeIDTypeCode: DEFAULT_DD_VALUE,
        payeeID: values.payeeID,
        payeeName: values.payeeName,
        payeeNameStartsWithOrContains: values.payeeNameStartsWithOrContains,
        remittanceAdviceNumber: values.remittanceAdviceNumber,
        checkEFTNumber: values.checkEFTNumber,
        checkEFTStatus: values.checkEFTStatus
      });
    }
  };

  const payeeTypecodeSwitch = payeeType => {
    if (payeeIDTypeDropdown) {
      switch (payeeType) {
        case 'M':
          if (payeeIDTypeDropdown.memberDropdown) { setPayeeIDTYpeFrmState(payeeIDTypeDropdown.memberDropdown); }
          break;
        case 'O':
          if (payeeIDTypeDropdown.otherDropdown) { setPayeeIDTYpeFrmState(payeeIDTypeDropdown.otherDropdown); }
          break;
        case 'P':
          if (payeeIDTypeDropdown.providerDropdown) { setPayeeIDTYpeFrmState(payeeIDTypeDropdown.providerDropdown); }
          break;
        case 'TC':
          if (payeeIDTypeDropdown.tplDropdown) { setPayeeIDTYpeFrmState(payeeIDTypeDropdown.tplDropdown); }
          break;
        default:
          setPayeeIDTYpeFrmState([]);
      }
    }
  };

  const [{ showPayeeError, showPayeeIdTypeCodeError, showPayeeIdError, showPayeeNameError, showPaymentDateError }, setShowError] = React.useState(false);
  const validateSearch = () => {
    setErrorMessage({
      payeeIDErrorMsg: '',
      payeeNameErrorMsg: ''
    });
    const errorMsgArray = [];
    let showPayeeIdTypeCodeError = false;
    let showPayeeIdError = false;
    const showPayeeError = false;
    let showPayeeNameError = false;
    let showOneError = false;
    let payeeIDErrorMsg = '';
    let payeeNameErrorMsg = '';
    let showPaymentDateError = false;
    let error = true;

    if (values.payeeType === DEFAULT_DD_VALUE && values.payeeIDTypeCode === DEFAULT_DD_VALUE && values.payeeID === '' &&
      values.checkEFTNumber === '' && values.checkEFTStatus === DEFAULT_DD_VALUE && values.payeeName === '' && values.payeeNameStartsWithOrContains === '' &&
    paymentDate === null && values.remittanceAdviceNumber === '') {
      showOneError = true;
      errorMsgArray.push(['At least one of the search criteria is required.']);
      error = false;
    }

    if (values.payeeType !== DEFAULT_DD_VALUE && values.payeeIDTypeCode === DEFAULT_DD_VALUE) {
      showPayeeIdTypeCodeError = true;
      errorMsgArray.push(FinancialEntityConstants.PAYEEIDTYPECODE_REQUIRED);
      error = false;
    }
    if (values.payeeType !== DEFAULT_DD_VALUE && values.payeeID === '') {
      showPayeeIdError = true;
      errorMsgArray.push(FinancialEntityConstants.PAYEEID_REQUIRED);
      error = false;
      payeeIDErrorMsg = FinancialEntityConstants.PAYEEID_REQUIRED;
    }
    if (values.payeeNameStartsWithOrContains) {
      if (values.payeeName.length < 2) {
        showPayeeNameError = true;
        errorMsgArray.push(FinancialEntityConstants.PAYEENAME_ERROR);
        payeeNameErrorMsg = FinancialEntityConstants.PAYEENAME_ERROR;
        error = false;
      }
    }
    if (paymentDate) {
      if (paymentDate.toString() !== 'Invalid Date') {
        if (validateDateMinimumValue(paymentDate)) {
          showPaymentDateError = true;
          errorMsgArray.push(AppConstants.DATE_ERROR_1964);
          error = false;
        }
      } else {
        showPaymentDateError = true;
        errorMsgArray.push(FinancialEntityConstants.DATE_FORMAT_INVALID);
        error = false;
      }
    }
    setShowError({
      showPayeeError: showPayeeError,
      showPayeeIdTypeCodeError: showPayeeIdTypeCodeError,
      showPayeeIdError: showPayeeIdError,
      showPayeeNameError: showPayeeNameError,
      showPaymentDateError: showPaymentDateError
    });

    setErrorMessage({
      payeeIDErrorMsg: payeeIDErrorMsg,
      payeeNameErrorMsg: payeeNameErrorMsg
    });

    setErrorMessages(errorMsgArray);
    return error;
  };

  const searchPaymentInquiry = () => {
    setShowDetailView(false);
    setShowTable(false);
    setShowData(true);
    if (validateSearch()) {
      const searchCriteria = {
        commonEntityTypeCode: values.payeeType !== '' && values.payeeType !== DEFAULT_DD_VALUE ? values.payeeType : null,
        entityIDTypeCode: values.payeeIDTypeCode !== '' && values.payeeIDTypeCode !== DEFAULT_DD_VALUE ? values.payeeIDTypeCode : null,
        entityID: values.payeeID,
        sortName: values.payeeName,
        payeeNameStartsWithOrContains: values.payeeNameStartsWithOrContains?.trim() === 'StartsWith' ? '0' : values.payeeNameStartsWithOrContains?.trim() === 'Contains' ? '1' : null,
        raNumber: values.remittanceAdviceNumber,
        warrantNumber: values.checkEFTNumber,
        warrStatusCd: values.checkEFTStatus !== '' && values.checkEFTStatus !== DEFAULT_DD_VALUE ? values.checkEFTStatus : '',
        warrantDate: paymentDate ? moment(paymentDate).format('YYYY-MM-DD') : null
      };
      onSearchView(searchCriteria);
    }
  };

  const filterSearchData = (filteredData) => {
    if (filteredData && filteredData.length > 0) {
      filteredData.map((data) => {
        data.commonEntityTypeCodeDesc = dropDownFilter('payeeType', data.commonEntityTypeCode);
        data.entityIDTypeCodeDesc = dropDownFilter('payeeIDTypeCode', data.entityIDTypeCode);
        if (data.sortName === 'null, null') {
          data.sortName = '';
        }
      });
    }
    setSearchTableData(filteredData);
  };

  useEffect(() => {
    const tempErrorArray = [];

    if (searchPaymentResponse && searchPaymentResponse.systemFailue) {
      tempErrorArray.push(FinancialEntityConstants.GENERIC_SYSTEM_ERROR);
      setErrorMessages(tempErrorArray);
      setShowTable(false);
      setSearchTableData([]);
    }
    if (searchPaymentResponse && searchPaymentResponse.length === 0 && showData) {
      tempErrorArray.push(FinancialEntityConstants.NORECORDS_FOUND_FOR_SEARCH_CRITERIA);
      setErrorMessages(tempErrorArray);
      setShowTable(false);
      setSearchTableData([]);
    }
    if (searchPaymentResponse && searchPaymentResponse.length > 0) {
      setShowTable(true);
      searchPaymentResponse.map((value) => {
        if (value.entityName === 'null') {
          value.entityName = '';
        }
        if (value.sortName === 'null, null') {
          value.sortName = '';
        }
      });
      filterSearchData(searchPaymentResponse);
      setSearchTableData(searchPaymentResponse);
    }
  }, [searchPaymentResponse]);

  const resetPaymentStatus = () => {
    OnReset();
    setValues(
      {
        payeeType: DEFAULT_DD_VALUE,
        payeeIDTypeCode: DEFAULT_DD_VALUE,
        payeeID: '',
        payeeName: '',
        payeeNameStartsWithOrContains: '',
        remittanceAdviceNumber: '',
        checkEFTNumber: '',
        checkEFTStatus: DEFAULT_DD_VALUE,
        paymentDate: null
      }
    );
    setPaymentDate(null);
    setShowTable(false);
    setShowDetailView(false);
    setShowError(false);
    setErrorMessages([]);
  };

  const closePaymentDetails = () => {
    setShowDetailView(false);
    setShowError(false);
    setErrorMessages([]);
  };

  return (
    <div className="pos-relative w-100 h-100">

      <div className="tabs-container" ref={toPrintRef} >
      <ErrorMessages errorMessages={errorMessages} />
        <div className="tab-header">
          <h1 className="tab-heading float-left">
            Payment Status Inquiry
          </h1>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form noValidate autoComplete="off">
          <div className='form-wrapper'>
              <NativeDropDown
                id="standard-select-payeeType"
                      value={values.payeeType}
                      label="Payee Type"
                      name='payeeType'
                      onChange={handleChange('payeeType')}
                      SelectProps={{
                        MenuProps: {}
                      }}
                      placeholder=""
                errTxt={showPayeeError ? FinancialEntityConstants.PAYEETYPE_REQUIRED : null}
                dropdownList={payeeTypeDataFrmState.map(option => (
                  <option key={option.code} value={option.code}>
                          {option.description}
                  </option>
                      ))}
              />
              <NativeDropDown
                id="standard-select-payeeIDTypeCode"
                      value={values.payeeIDTypeCode}
                disabled={values.payeeType === DEFAULT_DD_VALUE}
                      label="Payee ID Type Code"
                      name='payeeIDTypeCode'
                      onChange={handleChange('payeeIDTypeCode')}
                      SelectProps={{
                        MenuProps: {}
                      }}
                      placeholder=""
                errTxt={showPayeeIdTypeCodeError ? FinancialEntityConstants.PAYEEIDTYPECODE_REQUIRED : null}
                dropdownList={payeeIDTypeFrmState.map(option => (
                  <option key={option.code} value={option.code}>
                          {option.description}
                  </option>
                      ))}
              />
                  <div className="mui-custom-form input-md">
                    <TextField
                      id="PayeeID"
                      fullWidth
                      label="Payee ID"
                      type="string"
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{ maxLength: 15 }}
                      helperText={showPayeeIdError ? erroMessage.payeeIDErrorMsg : null}
                      error={showPayeeIdError ? erroMessage.payeeIDErrorMsg : null}
                      value={values.payeeID}
                      onChange={handleChange('payeeID')}
                    />
                  </div>
                  <div className="mui-custom-form override-m-1" >
                    <TextField
                      id="standard-select-PayeeName"
                      fullWidth
                      value={values.payeeName}
                      label="Payee Name"
                      name='payeeName'
                      helperText={showPayeeNameError ? erroMessage.payeeNameErrorMsg : null}
                      error={showPayeeNameError ? erroMessage.payeeNameErrorMsg : null}
                      onChange={handleChange('payeeName')}
                      inputProps={{ maxLength: 60 }}
                      placeholder=""
                      InputLabelProps={{
                        shrink: true
                      }}
                    >
                    </TextField>
                    <div className="sub-radio">
                      <Radio
                        value="StartsWith"
                        id="payeeNameStarts"
                        checked={values.payeeNameStartsWithOrContains === 'StartsWith'}
                        inputProps={{ maxLength: 1 }}
                        onChange={handleChange('payeeNameStartsWithOrContains')}
                      /><label className="text-black" htmlFor="payeeNameStarts">Starts With</label>
                      <Radio
                        value="Contains"
                        id="payeeNameContains"
                        inputProps={{ maxLength: 1 }}
                        checked={values.payeeNameStartsWithOrContains === 'Contains'}
                        onChange={handleChange('payeeNameStartsWithOrContains')}
                        className="ml-2"
                      /><label htmlFor="payeeNameContains" className="text-black">Contains</label>
                    </div>
                  </div>
                  </div>
                  <div className="form-wrapper">
                    <div className="mui-custom-form input-md">
                      <TextField
                        id="remittanceAdviceNumber"
                        fullWidth
                        label="Remittance Advice Number"
                        value={values.remittanceAdviceNumber}
                        onChange={handleChange('remittanceAdviceNumber')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{ maxLength: 10 }}
                        // helperText={showPayeeNameError ? FISearchConstants.PAYEE_NAME_ERROR : null}
                        // error={showPayeeNameError ? FISearchConstants.PAYEE_NAME_ERROR : null}
                      />
                    </div>
                    <div className="mui-custom-form input-md">
                      <TextField
                        id="checkEFTNumber"
                        fullWidth
                        label="Check/EFT Number"
                        value={values.checkEFTNumber}
                        onChange={handleChange('checkEFTNumber')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{ maxLength: 30 }}
                      />
                    </div>
              <NativeDropDown
                id="checkEFTStatus"
                            value={values.checkEFTStatus}
                            label="Check/EFT Status"
                            name='checkEFTStatus'
                            onChange={handleChange('checkEFTStatus')}
                            SelectProps={{
                              MenuProps: {}
                            }}
                dropdownList={EFTStatusDropdown ? EFTStatusDropdown.map(option => (
                  <option key={option.code} value={option.code}>
                                {option.description}
                  </option>
                            )) : null}
              />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div
                          className="mui-custom-form with-date input-md"
                          style={ { marginLeft: '15px' } }
                        >
                          <KeyboardDatePicker
                            maxDate={ Date.parse('31 Dec 9999 00:00:00 GMT') }
                            id="date-picker-begindate"
                            fullWidth
                            label="Payment Date"
                            InputLabelProps={ {
                              shrink: true
                            } }
                            helperText={showPaymentDateError ? FinancialEntityConstants.DATE_FORMAT_INVALID : null}
                            error={showPaymentDateError ? FinancialEntityConstants.DATE_FORMAT_INVALID : null}
                            placeholder="mm/dd/yyyy"
                            format="MM/dd/yyyy"
                            value={ paymentDate }
                            onChange={ handlePaymentDate }
                            KeyboardButtonProps={ {
                              'aria-label': 'change date'
                            } }
                          />
                        </div>
                    </MuiPickersUtilsProvider>
                    </div>
                    <br></br>
                        <div className="float-right mr-3 mb-2">
                          <Button className='btn btn-primary' onClick={searchPaymentInquiry}>
                            <i className="fa fa-search" aria-hidden="true"></i>
                            Search </Button>
                          <Button className='bt-reset btn-transparent  ml-1' onClick={resetPaymentStatus}>
                            <i className="fa fa-undo" aria-hidden="true"></i>
                            Reset </Button>
                        </div>
                    <div className="clearfix"></div>
                    </form>
                    <div className="clearfix"></div>
                      <div>
                    {
                    showTable
                      ? <div className="tab-holder pb-1">
                        <PaymentStatusInquiryTable

                        tableData={searchtableData.keySort({ commonEntityTypeCodeDesc: 'asc', entityIDTypeCodeDesc: 'asc', entityID: 'asc' })}
                        values={ values} dropdowns = {addDropdowns}
                        setShowDetailView={setShowDetailView} setDetailData={setDetailData} history={history} OnReset={OnReset} />
                      </div>
                      : null
                  }
          </div>
          <div>
                   {showDetailView ? (
                <div className="tabs-container" id="form_pop_up">
                  <div className="tab-header  mini-tab-header">
                    <div className="tab-heading float-left">
                      View Payment Status
                    </div>
                    <div className="float-right mt-1">
                      <Button
                        variant="outlined"
                        color="primary"
                        className="btn btn-primary btn btn-outlined ml-1"
                        onClick={closePaymentDetails}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                  <div className="form-border my-3">
                    <PaymentStatusInquiryView detailsData = {detailsData} dropdowns = {addDropdowns} showDetailView={showDetailView}/>
                  </div>
                </div>
              ) : null}
          </div>
        </div>
        <Footer print />
      </div>
    </div>

  );
}

export default withRouter(PaymentStatusInquirySearch);
