/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import * as Constants from './WriteOffContants';
import WriteOfftab from './WriteOffTab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import TabPanel from '../../../SharedModules/TabPanel/TabPanel';
import { withRouter } from 'react-router';
import { redirectSystemID } from '../../../SharedModules/TabRedirections/TabRedirections';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, Link } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as AppConstants from '../../../SharedModules/AppConstants';
import {
  getDropdownDataAction,
  saveWriteOff
} from '../Store/Actions/WriteOffActions';
import ErrorMessages from '../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../SharedModules/Errors/SuccessMessage';

import TextField from '@material-ui/core/TextField';
import NoSaveMessage from '../../../SharedModules/Errors/NoSaveMessage';
import moment from 'moment';
import numeral from 'numeral';
import '../WriteOff.scss';
import dropdownCriteria from './WriteOff.json';
import { GET_APP_DROPDOWNS } from "../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../SharedModules/Dropdowns/dropdowns";
import { refreshAttachmentsAction } from '../../../SharedModules/store/Actions/SharedAction';
import Footer from '../../../SharedModules/Layout/footer';
import Notes from '../../../SharedModules/Notes/Notes';
import {
  getUTCTimeStamp,
  generateUUID,
  getDateInMMDDYYYYFormatWithApendZero
} from '../../../SharedModules/DateUtilities/DateUtilities';
import Attachments from '../../../SharedModules/Attachments/Attachments';

/* WriteOff()
 * This component is used to write-off account receivable
 */
function WriteOff (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  // Attachments Integration start
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  let refreshAttachmentResult = useSelector(state => state.sharedState.newAttachmentResult);
  const [attachmentFile, setAttachmentFile] = React.useState([]);
  const [saveFlag, setSaveFlag] = React.useState(false);
  const userInquiryPrivileges = !global.globalIsReadOnly();
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  React.useEffect(() => {
    const attachmentVOList = [];
    if (attachmentsTableData && attachmentsTableData.length > 0) {
      attachmentsTableData.map((value) => {
        if (value.id) {
          const attachmentVO = {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            versionNo: 0,
            dbRecord: true,
            sortColumn: null,
            attachmentSK: null,
            attachmentPageID: null,
            finalPath: null,
            detachInd: false,
            historyIndicator: false,
            cascadeKey: null,
            attachmentIndicator: null,
            dateAdded: getDateInMMDDYYYYFormatWithApendZero(new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE }))),
            fileUrl: null,
            showDeleteForSearched: false,
            rowIndex: 0,
            addedBy: logInUserID,
            description:
              value && value
                ? value.description
                : null,
            edmsStoredDate: null,
            anEDMSPageId: null,
            fileName:
              attachmentsTableData && value
                ? value.fileName
                : null,
            file1: null,
            edmsWrkUnitLevel: null,
            edmsDocType: null
          };
          attachmentVOList.push(attachmentVO);
        }
      });
    }
    writeOffDetailsData.attachmentVO = null;
    writeOffDetailsData.attachmentsList = attachmentVOList;
  }, [attachmentsTableData]);
  // Attachment Integration ends
  // Notes Integration Begin
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [writeVONotesInput, setWriteVONotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: [],
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({});
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  const [isFocusCheck, setFocusCheck] = React.useState(false);
  // add notes
  let notesDataArray = [];

  const addNotes = data => {
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;

    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
    const noteswritelist = writeVONotesInput.notesList;
    noteswritelist.push(noteText);
    setWriteVONotesInput({ ...writeVONotesInput, notesList: noteswritelist });
  };

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setErrorMessages([]);
    setShowError(false);
    setSystemSuccesMessages([]);
  };

  let paramsData;

  useEffect(() => {
    if (props.location.state.writeOffDetails) {
      paramsData = props.location.state.writeOffDetails;
      // notes
      if (paramsData.noteSetVO) {
        const noteSetVO = paramsData.noteSetVO;
        const notesTable = paramsData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID
            ? noteSetVO.addedAuditUserID
            : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
            ? noteSetVO.addedAuditTimeStamp
            : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTableData,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
    }
    refreshAttachmentResult = null;
  }, []);

  useEffect(() => {
    if (notesInput) {
      setNoteSetListInput({
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: notesInput.addedAuditUserID
          ? notesInput.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: notesInput.addedAuditTimeStamp
          ? notesInput.addedAuditTimeStamp
          : getUTCTimeStamp(),
        versionNo: notesInput.versionNo,
        dbRecord: false,
        sortColumn: null,
        noteTextValue: null,
        userIdName: null,
        notesCexAuditUserID: null,
        notesCexAuditTimeStamp: null,
        notesCexAddedAuditUserID: null,
        notesCexAddedAuditTimeStamp: null,
        noteSetSK: notesInput.noteSetSK,
        usageTypeDesc: '',
        shortNotes: null,
        checked: false,
        renderNoHistoryMsg: false,
        noteSequenceNumber: null,
        currentNote: null,
        rowValue: null,
        usageTypeList: null,
        strBeginDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
        usageTypeCode: DEFAULT_DD_VALUE,
        tableName: null,
        noteText: '',
        commonEntityName: null,
        commonEntityTypeCode: null,
        commonEntityId: null,
        entityId: null,
        filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
        filterEndDate: null,
        userId: notesInput.userId ? notesInput.userId : logInUserID,
        noteCexVersionNum: notesInput.noteCexVersionNum
          ? notesInput.noteCexVersionNum
          : 0,
        saNoteSequenceNumber: notesInput.saNoteSequenceNumber
          ? notesInput.saNoteSequenceNumber
          : null,
        notesCexnoteTextValue: notesInput.notesCexnoteTextValue
          ? notesInput.notesCexnoteTextValue
          : 0,
        id: generateUUID()
      });
    }
  }, [notesInput]);

  // Notes Integration End

  const [{ showReasonCodeError, atrError }, setShowError] = React.useState(false);
  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [allowNavigation, setAllowNavigation] = React.useState(false);

  const [reasonCodeList, setReasonCodeList] = React.useState([]);
  const [payeeTypeList, setPayeeTypeList] = React.useState([]);
  const [payeeIdTypeCodeList, setPayeeIdTypeCodeList] = React.useState([]);
  const [payeeIdTypeCodeDataList, setPayeeIdTypeCodeDataList] = React.useState(
    []
  );
  const [activityCodeList, setActivityCodeList] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const sucessMessages = [];
  const systemErrorMessges = [];
  var errorMessagesArray = [];
  const dispatch = useDispatch();
  const toPrintRef = useRef();

  const getDropdownData = dropdownValues => {
    dispatch(getDropdownDataAction(dropdownValues));
  };
  useEffect(() => {
    if (refreshAttachmentResult && !refreshAttachmentResult.systemFailure) {
      setAttachmentsTableData(refreshAttachmentResult);
    }
  }, [refreshAttachmentResult]);

  const onSaveWriteOff = (value, index) =>
    dispatch(
      saveWriteOff(
        value,
        attachmentsTableData &&
          index >= 0 &&
          attachmentsTableData[index] &&
          attachmentsTableData[index].id
          ? attachmentFile
          : null
      )
    );
  // System Payee redirection starts
  const searchSingleFinancialEntityRes = useSelector(
    state => state.financialEntityState.singleSearchResults
  );
  const [redirect, setRedirect] = React.useState(0);
  React.useEffect(() => {
    if (
      redirect === 1 &&
      searchSingleFinancialEntityRes &&
      !searchSingleFinancialEntityRes.systemFailure
    ) {
      props.history.push({
        pathname: '/FinancialEntityUpdate',
        state: { searchSingleFinancialEntityRes }
      });
    }
  }, [searchSingleFinancialEntityRes]);
  // System Payee redirection ends
  let dropdown = '';
  dropdown = useSelector(state => state.writeOffState.dropdownData);

  const saveWriteOffResponse = useSelector(
    state => state.writeOffState.saveWriteOffData
  );

  const writeOffDetailsData = props.location.state.writeOffDetails
    ? props.location.state.writeOffDetails
    : null;

  /* Setting initial values */
  const [values, setValues] = React.useState({
    payeeName: '',
    systemPayeeId: '',
    payeeId: '',
    payeeIdTypeCode: '',
    payeeType: '',
    fcn: '',
    activityType: '',
    reasonCode: DEFAULT_DD_VALUE,
    establishedDate: '',
    originalArAmount: '',
    adjustedOriginalAmount: '',
    relatedFtTcn: '',
    balanceAmount: '',
    penalty: '',
    interest: '',
    fees: '',
    arLetterSent: '',
    atrNumber: ''
  });

  /* Display success/error message based on the response and response description coming from the API after saving write-off details */
  useEffect(() => {
    setAllowNavigation(false);
    if (
      saveWriteOffResponse &&
      saveWriteOffResponse.respcode === '03' &&
      saveWriteOffResponse.respdesc !== ''
    ) {
      const error = saveWriteOffResponse.respdesc;
      setErrorMessages([]);
      setSystemSuccesMessages([]);
      systemErrorMessges.push(error);
      setErrorMessages(systemErrorMessges);
    }
    if (
      saveWriteOffResponse &&
      saveWriteOffResponse.respcode === '01' &&
      saveWriteOffResponse.respdesc !== ''
    ) {
      const success = AppConstants.SAVE_SUCCESS;
      setErrorMessages([]);
      setSystemSuccesMessages([]);
      sucessMessages.push(success);
      setSystemSuccesMessages(sucessMessages);
      setSaveFlag(true);
      dispatch(refreshAttachmentsAction(writeOffDetailsData.writeOffFCN));
    }
    if (
      saveWriteOffResponse &&
      saveWriteOffResponse.respcode === '02' &&
      saveWriteOffResponse.respdesc !== ''
    ) {
      const error = saveWriteOffResponse.respdesc;
      setErrorMessages([]);
      setSystemSuccesMessages([]);
      systemErrorMessges.push(error);
      setErrorMessages(systemErrorMessges);
    }
    if (
      saveWriteOffResponse != null &&
      (saveWriteOffResponse.errorMessages !== undefined ||
        saveWriteOffResponse.message !== undefined)
    ) {
      if (
        saveWriteOffResponse.errorMessages &&
        saveWriteOffResponse.errorMessages === Constants.NETWORK_ERROR
      ) {
        setErrorMessages([]);
        setSystemSuccesMessages([]);
        errorMessagesArray.push(Constants.TRANSACTION_ERROR);
        setErrorMessages(errorMessagesArray);
      } else if (
        saveWriteOffResponse &&
        saveWriteOffResponse.message &&
        saveWriteOffResponse.message === Constants.NETWORK_ERROR
      ) {
        setErrorMessages([]);
        setSystemSuccesMessages([]);
        errorMessagesArray.push(Constants.TRANSACTION_ERROR);
        setErrorMessages(errorMessagesArray);
      } else if (
        saveWriteOffResponse && saveWriteOffResponse.systemFailure
      ) {
        setErrorMessages([]);
        setSystemSuccesMessages([]);
        errorMessagesArray.push(Constants.TRANSACTION_ERROR);
        setErrorMessages(errorMessagesArray);
      }
    }
  }, [saveWriteOffResponse]);

  /* Calling api to get write-off reason code dropdown */
  useEffect(() => {
    getDropdownData(dropdownCriteria);
    onDropdowns([
      Dropdowns.PAYEE_TYPE,
      Dropdowns.PAYEE_ID_TYPE_CD_OTHER,
      Dropdowns.PAYEE_ID_TYPE_CD_PROVIDER,
      Dropdowns.PAYEE_ID_TYPE_CD_MEMBER,
      Dropdowns.PAYEE_ID_TYPE_CD_TPL,
      Dropdowns.ACTIVITY_TYPE
    ]);
  }, []);
  function updateDropdownDescription (addDropdowns, dropdownKey, valueToMatch, targetProperty) {
    if (addDropdowns && addDropdowns[dropdownKey]) {
    const dropdownData = addDropdowns[dropdownKey].filter((value) => value.code === valueToMatch?.split("-")[0]);
    setValues(prevState => ({
      ...prevState,
      [targetProperty]: dropdownData && dropdownData.length > 0
        ? dropdownData[0].description
        : valueToMatch || ""
    }));
    }
  }
  useEffect(() => {
    if (addDropdowns) {
      // Usage for 'payeePayerTypeCodeDesc':
      updateDropdownDescription(addDropdowns, 'G1#G_CMN_ENTY_TY_CD', values.payeeType, 'payeePayerTypeCodeDesc');
      // Usage for 'payeePayerIDTypeCodeDesc':
      const payeeIdTypeCdDD = {
        P: 'F1#P_ALT_ID_TY_CD',
        M: 'F1#B_ALT_ID_TY_CD',
        O: 'F1#G_ENTY_EXTL_ID_TY_CD',
        TC: 'F1#DM_G_CARR_ENTY_ID_TY_CD'
      };
      const selectedPayeeIdTypeCdDD = payeeIdTypeCdDD[values.payeeType.split('-')[0]] || '';
      updateDropdownDescription(addDropdowns, selectedPayeeIdTypeCdDD, values.payeeIdTypeCode, 'payeePayerIDTypeCodeDesc');
      // Usage for 'activityTypeCodeDesc':
      updateDropdownDescription(addDropdowns, 'F1#F_ACTV_TY_CD', values.activityType, 'activityTypeCodeDesc');
    }
  }, [addDropdowns]);
  /* Setting dropdown values */
  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['General#6002']) {
        setPayeeTypeList(dropdown.listObj['General#6002']);
      }
      if (dropdown.listObj['Financial#2']) {
        setPayeeIdTypeCodeList(dropdown.listObj['Financial#2']);
      }
      if (dropdown.listObj['Financial#3']) {
        setPayeeIdTypeCodeDataList(dropdown.listObj['Financial#3']);
      }
      if (dropdown.listObj['Financial#3013']) {
        setActivityCodeList(dropdown.listObj['Financial#3013']);
      }
      if (dropdown.listObj['General#1012']) {
        setUsageTypeCodeData(dropdown.listObj['General#1012']);
      }
    }
  }, [dropdown]);

  /* Getting Write-Off details using props from different screens
   * setting screen fields based on the Write-Off details
   */
  useEffect(() => {
    if (
      writeOffDetailsData &&
      activityCodeList &&
      payeeTypeList &&
      payeeIdTypeCodeList &&
      payeeIdTypeCodeDataList
    ) {
      for (var activityCode in activityCodeList) {
        if (
          activityCodeList[activityCode].code ===
          writeOffDetailsData.activityTypeCode
        ) {
          writeOffDetailsData.activityTypeCode =
            activityCodeList[activityCode].description;
        }
      }
      for (var payeeCode in payeeTypeList) {
        if (
          payeeTypeList[payeeCode].code ===
          writeOffDetailsData.payeePayerTypeCode
        ) {
          writeOffDetailsData.payeePayerTypeCode =
            payeeTypeList[payeeCode].description;
        }
      }
      for (var payeeIdTypeCode in payeeIdTypeCodeList) {
        if (
          payeeIdTypeCodeList[payeeIdTypeCode].code ===
          writeOffDetailsData.payeePayerIDTypeCode
        ) {
          writeOffDetailsData.payeePayerIDTypeCode =
            payeeIdTypeCodeList[payeeIdTypeCode].description;
        }
      }

      for (var payeeIdTypeCodeData in payeeIdTypeCodeDataList) {
        if (
          payeeIdTypeCodeDataList[payeeIdTypeCodeData].code ===
          writeOffDetailsData.payeePayerIDTypeCode
        ) {
          writeOffDetailsData.payeePayerIDTypeCode =
            payeeIdTypeCodeDataList[payeeIdTypeCodeData].description;
        }
      }

      setValues({
        payeeName: writeOffDetailsData.payeePayerNameFromDB
          ? writeOffDetailsData.payeePayerNameFromDB
          : '',
        systemPayeeId: writeOffDetailsData.sysID
          ? writeOffDetailsData.sysID
          : '',
        payeeId: writeOffDetailsData.payeePayerID
          ? writeOffDetailsData.payeePayerID
          : '',
        payeeIdTypeCode: writeOffDetailsData.payeePayerIDTypeCode
          ? writeOffDetailsData.payeePayerIDTypeCode
          : '',
        payeeType: writeOffDetailsData.payeePayerTypeCode
          ? writeOffDetailsData.payeePayerTypeCode
          : '',
        fcn: writeOffDetailsData.writeOffFCN
          ? writeOffDetailsData.writeOffFCN
          : '',
        activityType: writeOffDetailsData.activityTypeCode
          ? writeOffDetailsData.activityTypeCode
          : '',
        reasonCode: DEFAULT_DD_VALUE,
        establishedDate: writeOffDetailsData.establishedDateStr
          ? moment(writeOffDetailsData.establishedDateStr).format('MM/DD/YYYY')
          : '',
        originalArAmount: writeOffDetailsData.originalAmount
          ? numeral(writeOffDetailsData.originalAmount).format('0,0.00')
          : '',
        adjustedOriginalAmount: writeOffDetailsData.adjustedArAmt
          ? numeral(writeOffDetailsData.adjustedArAmt).format('0,0.00')
          : '',
        relatedFtTcn: writeOffDetailsData.relatedFTTCN
          ? writeOffDetailsData.relatedFTTCN
          : '',
        balanceAmount: writeOffDetailsData.balanceAmount
          ? numeral(writeOffDetailsData.balanceAmount).format('0,0.00')
          : '',
        penalty: writeOffDetailsData.calculatedPenalty
          ? numeral(writeOffDetailsData.calculatedPenalty).format('0,0.00')
          : '',
        interest: writeOffDetailsData.calculatedInterest
          ? numeral(writeOffDetailsData.calculatedInterest).format('0,0.00')
          : '',
        fees: writeOffDetailsData.calculatedFees
          ? numeral(writeOffDetailsData.calculatedFees).format('0,0.00')
          : '',
        arLetterSent: writeOffDetailsData.agingCode
          ? writeOffDetailsData.agingCode
          : ''
      });
    }
  }, [
    writeOffDetailsData,
    activityCodeList,
    payeeTypeList,
    payeeIdTypeCodeList,
    payeeIdTypeCodeDataList
  ]);
  useEffect(() => {
    if (writeOffDetailsData && writeOffDetailsData.activityTypeCode) {
      const tempData = JSON.parse(JSON.stringify(values));
      if (dropdown && dropdown.listObj) {
        if ((writeOffDetailsData.activityTypeCode === 'CR' || writeOffDetailsData.activityTypeCode === 'CR-Claims Rec') && dropdown.listObj['Financial#18']) {
          if (dropdown.listObj['Financial#18'].length === 1) {
            tempData.reasonCode = dropdown.listObj['Financial#18'][0].code;
          }
          setReasonCodeList(dropdown.listObj['Financial#18']);
        } else if ((writeOffDetailsData.activityTypeCode === 'GL' || writeOffDetailsData.activityTypeCode === 'GL-GrossLevel') && dropdown.listObj['Financial#19']) {
          if (dropdown.listObj['Financial#19'].length === 1) {
            tempData.reasonCode = dropdown.listObj['Financial#19'][0].code;
          }
          setReasonCodeList(dropdown.listObj['Financial#19']);
        } else if ((writeOffDetailsData.activityTypeCode === 'AP' || writeOffDetailsData.activityTypeCode === 'AP-Adv Pymt') && dropdown.listObj['Financial#20']) {
          if (dropdown.listObj['Financial#20'].length === 1) {
            tempData.reasonCode = dropdown.listObj['Financial#20'][0].code;
          }
          setReasonCodeList(dropdown.listObj['Financial#20']);
        } else if ((writeOffDetailsData.activityTypeCode === 'WL' || writeOffDetailsData.activityTypeCode === 'WL-Withhold') && dropdown.listObj['Financial#21']) {
          if (dropdown.listObj['Financial#21'].length === 1) {
            tempData.reasonCode = dropdown.listObj['Financial#21'][0].code;
          }
          setReasonCodeList(dropdown.listObj['Financial#21']);
        }
        setValues({ ...values, reasonCode: tempData.reasonCode });
      }
    }
  }, [dropdown && dropdown.listObj, writeOffDetailsData]);
  /* This function is used to handle change in any input field */
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
    setAllowNavigation(true);
  };

  /* This function is used to handle tab change */
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  /* This function is used to save write-off details by hitting API
   *  If all required fields entered and validations passes, hit the API
   */
  const saveWriteOffDetails = values => {
    errorMessagesArray = [];
    var showReasonCodeError = false;
    var atrError = false;
    const regex = /[^A-Za-z0-9]/;
    if (
      !values.reasonCode ||
      values.reasonCode === DEFAULT_DD_VALUE
    ) {
      showReasonCodeError = true;
      errorMessagesArray.push(Constants.REASON_CODE_REQUIRED);
      setErrorMessages(errorMessagesArray);
    }

    if (values.atrNumber && regex.test(values.atrNumber)) {
      atrError = true;
      errorMessagesArray.push(`${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${Constants.TRANSACTION_NUMBER_FORMAT}`);
      setErrorMessages(errorMessagesArray);
    } else { atrError = false; }

    setShowError({
      showReasonCodeError: showReasonCodeError,
      atrError: atrError
    });

    if (errorMessagesArray.length === 0) {
      if (!allowNavigation) {
        NoSaveMessage();
      } else {
        errorMessagesArray = [];
        setErrorMessages([]);

        writeOffDetailsData.auditUserID = logInUserID;
        writeOffDetailsData.auditTimeStamp = getUTCTimeStamp();
        writeOffDetailsData.addedAuditUserID = writeOffDetailsData.addedAuditUserID
          ? writeOffDetailsData.addedAuditUserID
          : logInUserID;
        writeOffDetailsData.addedAuditTimeStamp = writeOffDetailsData.addedAuditTimeStamp
          ? writeOffDetailsData.addedAuditTimeStamp
          : getUTCTimeStamp();
        if (
          writeOffDetailsData.payeePayerTypeCode != null &&
          writeOffDetailsData.payeePayerTypeCode !== undefined
        ) {
          writeOffDetailsData.payeePayerTypeCode = writeOffDetailsData.payeePayerTypeCode.split(
            '-'
          )[0];
        }

        writeOffDetailsData.writeOffReasonCode = values.reasonCode;
        writeOffDetailsData.atrNumber = values?.atrNumber?.toUpperCase();
        writeOffDetailsData.activityTypeCode = writeOffDetailsData.activityTypeCode.split(
          '-'
        )[0];
        if (
          writeOffDetailsData.accountReceivableReasonCode != null &&
          writeOffDetailsData.accountReceivableReasonCode !== undefined
        ) {
          writeOffDetailsData.accountReceivableReasonCode = writeOffDetailsData.accountReceivableReasonCode.split(
            '-'
          )[0];
        }
        if (
          writeOffDetailsData.payeePayerIDTypeCode != null &&
          writeOffDetailsData.payeePayerIDTypeCode !== undefined
        ) {
          writeOffDetailsData.payeePayerIDTypeCode = writeOffDetailsData.payeePayerIDTypeCode.split(
            '-'
          )[0];
        }
        writeOffDetailsData.lob = AppConstants.DEFAULT_LOB_VALUE;
        writeOffDetailsData.fcnSequenceNo = writeOffDetailsData.fcnSequenceNo.substr(
          writeOffDetailsData.fcnSequenceNo.length - 5
        );
        if (writeOffDetailsData.noteSetVO) {
          const noteSK = writeOffDetailsData.noteSetVO.noteSetSK;
          writeOffDetailsData.noteSetVO = writeVONotesInput;
          writeOffDetailsData.noteSetVO.noteSetSK = noteSK;
        } else {
          writeOffDetailsData.noteSetVO = writeVONotesInput;
        }
        const index = attachmentsTableData.length - 1;
        const attachmentVOList = [];
        if (attachmentsTableData && attachmentsTableData.length > 0) {
          attachmentsTableData.map((value) => {
            if (value.id) {
              const attachmentVO = {
                auditUserID: logInUserID,
                auditTimeStamp: getUTCTimeStamp(),
                addedAuditUserID: logInUserID,
                addedAuditTimeStamp: getUTCTimeStamp(),
                versionNo: 0,
                dbRecord: true,
                sortColumn: null,
                attachmentSK: null,
                attachmentPageID: null,
                finalPath: null,
                detachInd: false,
                historyIndicator: false,
                cascadeKey: null,
                attachmentIndicator: null,
                dateAdded: getDateInMMDDYYYYFormatWithApendZero(new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE }))),
                fileUrl: null,
                showDeleteForSearched: false,
                rowIndex: 0,
                addedBy: logInUserID,
                description:
                  value && value
                    ? value.description
                    : null,
                edmsStoredDate: null,
                anEDMSPageId: null,
                fileName:
                  attachmentsTableData && value
                    ? value.fileName
                    : null,
                file1: null,
                edmsWrkUnitLevel: null,
                edmsDocType: null
              };
              attachmentVOList.push(attachmentVO);
            }
          });
        }
        writeOffDetailsData.attachmentVO = null;
        writeOffDetailsData.attachmentsList = attachmentVOList;
        onSaveWriteOff(writeOffDetailsData, index);
      }
    }
  };

  /* This function is used to navigate to Accounts Receivable Add Screen */

  return (
    <div className="pos-relative w-100 h-100">
      <div className="tabs-container" ref={toPrintRef}>
        <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
        <ErrorMessages errorMessages={errorMessages} />
        {systemSuccessMessages.length > 0 && errorMessages.length === 0 ? (
          <SuccessMessages successMessages={systemSuccessMessages} />
        ) : null}

        <div className="tab-header">
          <h1 className="tab-heading float-left">
            {Constants.WRITEOFFSET_HEADER}
          </h1>
          <div className="float-right mt-2">
            <Button
              className="btn btn-success ml-1"
              onClick={() => saveWriteOffDetails(values)}
            >
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form noValidate autoComplete="off">
            <div className="form-wrapper">
              <div className="mui-custom-form with-select ">
                <TextField
                  id="payee-type"
                  fullWidth
                  label="Payee Type"
                  value={values.payeePayerTypeCodeDesc || ''}
                  InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                  InputLabelProps={{
                    shrink: true
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form ">
                <TextField
                  id="payee-type-code"
                  fullWidth
                  label="Payee ID Type Code"
                  value={values.payeePayerIDTypeCodeDesc || ''}
                  InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                  InputLabelProps={{
                    shrink: true
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form ">
                <TextField
                  id="payee-id"
                  fullWidth
                  label="Payee ID"
                  value={values.payeeId}
                  InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                  InputLabelProps={{
                    shrink: true
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form  with-link has-link">
                <TextField
                  id="system-payee-id"
                  fullWidth
                  label="System Payee ID"
                  InputProps={{
                    readOnly: true,
className: 'Mui-disabled',
startAdornment: (
                      <InputAdornment position="start">
                        <Link to={redirectSystemID(values)}>
                          {values.systemPayeeId}
                        </Link>
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form ">
                <TextField
                  id="payee-name"
                  fullWidth
                  label="Payee Name"
                  value={values.payeeName}
                  InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                  InputLabelProps={{
                    shrink: true
                  }}
                ></TextField>
              </div>
            </div>
          </form>
          <div className="tab-panelbody">
            <div className="tab-holder mb-3 mt-1">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                >
                  <Tab label="Write-Off" />
                  <Tab label="Notes" />
                  <Tab label="Attachments" />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                {value === 0 && (
                  <div className="tab-holder my-3 p-0">
                    <WriteOfftab
                      saveFlag={saveFlag}
                      handleChange={handleChange}
                      values={values}
                      reasonCodeList={reasonCodeList || []}
                      errors={[showReasonCodeError, atrError]}
                    />
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {value === 1 && (
                  <div className="tab-holder p-0">
                    <Notes
                      addNotes={addNotes}
                      notesTableData={notesTableData}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setFocusCheck={setFocusCheck}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs}
                    />
                  </div>
                )}
              </TabPanel>
              <TabPanel
                value={value}
                index={2}
              >
                <div className="tab-holder p-0">
                  <Attachments
                    userInquiryPrivileges={userInquiryPrivileges}
                    attachmentFile={attachmentFile}
                    setAttachmentFile={setAttachmentFile}
                    attachmentsTableData={attachmentsTableData}
                    setAttachmentsTableData={setAttachmentsTableData}
                    parentErrorMesage={setErrorMessages}
                    setAllowNavigation={setAllowNavigation}
                    setSystemSuccesMessages={clearSuccessErrorMsgs}
                    setFocusCheck={setFocusCheck}
                  />
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
        <Footer print />
      </div>
    </div>
  );
}

export default withRouter(WriteOff);
