/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition
} from "react-toasts";
import TableComponent from "../../../../SharedModules/Table/Table";

const headCells = [
  {
    id: "fundCode",
    numeric: false,
    disablePadding: true,
    label: "Fund Code",
    width: "15%",
    fontSize: 12
  },
  {
    id: "cos",
    numeric: false,
    disablePadding: false,
    label: "COS",
    width: "15%",
    fontSize: 12
  },
  {
    id: "startDate",
    isDate: true,
    numeric: false,
    disablePadding: false,
    label: "Begin Date",
    width: "10%",
    fontSize: 12
  },
  {
    id: "endDate",
    isDate: true,
    numeric: false,
    disablePadding: false,
    label: "End Date",
    width: "10%",
    fontSize: 12
  },
  {
    id: "adjAuth",
    numeric: false,
    disablePadding: false,
    label: "Adj Auth",
    width: "10%",
    fontSize: 12
  },
  {
    id: "acActivity",
    numeric: false,
    disablePadding: false,
    label: "AC Activity",
    width: "10%",
    fontSize: 12
  },
  {
    id: "userId",
    numeric: false,
    disablePadding: false,
    label: "User ID",
    width: "10%",
    fontSize: 12
  },
  {
    id: "dateChanged",
    numeric: false,
    disablePadding: false,
    label: "Date Changed",
    width: "10%",
    fontSize: 12
  },
  {
    id: "voidDateHistory",
    isDate: true,
    numeric: false,
    disablePadding: false,
    label: "Void Date",
    width: "10%",
    fontSize: 12
  }
];

export default function FundCodeHistoryTable(props) {
  const { classes } = props;

  return (
    <div className={classes}>
      <ToastsContainer
        store={ToastsStore}
        position={ToastsContainerPosition.TOP_RIGHT}
      />
      <TableComponent
        print={props.print}
        fixedTable
        isSearch={true}
        headCells={headCells}
        tableData={props.tableData ? props.tableData : []}
        defaultSortColumn={headCells[0].id}
        sortOrder="desc"
      />

      <div className="clearfix"></div>
    </div>
  );
}
