/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";

import { searchSingleRecordFinancialEntity } from "../Store/Actions/FinancialEntityActions";
import TableComponent from "../../../SharedModules/Table/Table";

const headCellsMultiRecord = [
  {
    id: "commonEntityTypeCodeDesc",
    label: "Payee Type",
    enableHyperLink: true,
    isDate: false,
    width: "9%",
    fontSize: 12,
    disablePadding: false
  },
  {
    id: "entityIDTypeCodeDesc",
    label: "Payee ID Type Code",
    enableHyperLink: false,
    isDate: false,
    width: "12%",
    fontSize: 12,
    disablePadding: false
  },
  {
    id: "entityID",
    label: "Payee ID",
    enableHyperLink: false,
    isDate: false,
    fontSize: 12,
    isVarChar: true,
    width: "9%",
    disablePadding: false
  },
  {
    id: "sortName",
    label: "Payee Name",
    enableHyperLink: false,
    isDate: false,
    width: "21%",
    fontSize: 12,
    disablePadding: false
  }
];
const headCellsSingleRecord = [
  {
    id: "entTypeDesc",
    label: "Payee Type",
    enableHyperLink: true,
    isDate: false,
    width: "9%",
    fontSize: 12,
    disablePadding: false
  },
  {
    id: "entTypCdDesc",
    label: "Payee ID Type Code",
    enableHyperLink: false,
    isDate: false,
    width: "12%",
    fontSize: 12,
    disablePadding: false
  },
  {
    id: "entityID",
    label: "Payee ID",
    enableHyperLink: false,
    isDate: false,
    fontSize: 12,
    isVarChar: true,
    width: "9%",
    disablePadding: false
  },
  {
    id: "entityName",
    label: "Payee Name",
    enableHyperLink: false,
    isDate: false,
    width: "21%",
    fontSize: 12,
    disablePadding: false
  }
];

function FinancialEntitySearchTable (props) {
  const dispatch = useDispatch();
  const searchSingleFinancialEntityResData = useSelector(
    (state) => state.financialEntityState.singleSearchResults
  );
  const [redirect, setRedirect] = React.useState(0);

  const searchSingleFinancialEntityRes =
    searchSingleFinancialEntityResData || props.tableData;

  React.useEffect(() => {
    if (
      redirect === 1 &&
      searchSingleFinancialEntityRes &&
      !searchSingleFinancialEntityRes.systemFailue
    ) {
      props.history.push({
        pathname: "/FinancialEntityUpdate",
        state: { searchSingleFinancialEntityRes, values: props.values }
      });
    }
  }, [searchSingleFinancialEntityRes]);

  const getTableData = (d) => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;

          const commanEntyPayTypeCode =
            props.dropdowns &&
            props.dropdowns["G1#G_CMN_ENTY_TY_CD"] &&
            props.dropdowns["G1#G_CMN_ENTY_TY_CD"].filter(
              (value) => value.code === each.commonEntityTypeCode
            );
          each.commonEntityTypeCodeDesc =
            commanEntyPayTypeCode && commanEntyPayTypeCode.length > 0
                ? commanEntyPayTypeCode[0].description
                : each.commonEntityTypeCode
                ? each.commonEntityTypeCode
                : "";

          const payTypeCode =
            props.dropdowns &&
            props.dropdowns["G1#G_CMN_ENTY_TY_CD"] &&
            props.dropdowns["G1#G_CMN_ENTY_TY_CD"].filter(
              (value) => value.code === each.entityType
            );
          each.entTypeDesc =
            payTypeCode && payTypeCode.length > 0
              ? payTypeCode[0].description
              : each.entityType
              ? each.entityType
              : "";

          if (each.commonEntityTypeCode === "P" || each.entityType === 'P') {
            const provEntityTypeCD =
              props.dropdowns &&
              props.dropdowns["F1#P_ALT_ID_TY_CD"] &&
              props.dropdowns["F1#P_ALT_ID_TY_CD"].filter(
                (value) => value.code === (each.entityIDTypeCode || each.entityTypeCode)
              );
            each.entityIDTypeCodeDesc =
              provEntityTypeCD && provEntityTypeCD.length > 0
                ? provEntityTypeCD[0].description
                : each.entityIDTypeCode
                ? each.entityIDTypeCode : each.entityTypeCode ? each.entityTypeCode
                : "";

            each.entTypCdDesc =
              provEntityTypeCD && provEntityTypeCD.length > 0
                ? provEntityTypeCD[0].description
                : each.entityIDTypeCode
                ? each.entityIDTypeCode : each.entityTypeCode ? each.entityTypeCode
                : "";
          } else if (each.commonEntityTypeCode === "O" || each.entityType === 'O') {
            const othrEntityTypeCD =
              props.dropdowns &&
              props.dropdowns["F1#G_ENTY_EXTL_ID_TY_CD"] &&
              props.dropdowns["F1#G_ENTY_EXTL_ID_TY_CD"].filter(
                (value) => value.code === (each.entityIDTypeCode || each.entityTypeCode)
              );
            each.entityIDTypeCodeDesc =
              othrEntityTypeCD && othrEntityTypeCD.length > 0
                ? othrEntityTypeCD[0].description
                : each.entityIDTypeCode
                ? each.entityIDTypeCode : each.entityTypeCode ? each.entityTypeCode
                : "";

            each.entTypCdDesc =
              othrEntityTypeCD && othrEntityTypeCD.length > 0
                ? othrEntityTypeCD[0].description
                : each.entityIDTypeCode
                ? each.entityIDTypeCode : each.entityTypeCode ? each.entityTypeCode
                : "";
          } else if (each.commonEntityTypeCode === "M" || each.entityType === 'M') {
            const memberEntityTypeCD =
              props.dropdowns &&
              props.dropdowns["F1#B_ALT_ID_TY_CD"] &&
              props.dropdowns["F1#B_ALT_ID_TY_CD"].filter(
                (value) => value.code === (each.entityIDTypeCode || each.entityTypeCode)
              );
            each.entityIDTypeCodeDesc =
              memberEntityTypeCD && memberEntityTypeCD.length > 0
                ? memberEntityTypeCD[0].description
                : each.entityIDTypeCode
                ? each.entityIDTypeCode : each.entityTypeCode ? each.entityTypeCode
                : "";

            each.entTypCdDesc =
              memberEntityTypeCD && memberEntityTypeCD.length > 0
                ? memberEntityTypeCD[0].description
                : each.entityIDTypeCode
                ? each.entityIDTypeCode : each.entityTypeCode ? each.entityTypeCode
                : "";
          } else if (each.commonEntityTypeCode === "TC" || each.entityType === 'TC') {
            const tplEntityTypeCD =
              props.dropdowns &&
              props.dropdowns["F1#DM_G_CARR_ENTY_ID_TY_CD"] &&
              props.dropdowns["F1#DM_G_CARR_ENTY_ID_TY_CD"].filter(
                (value) => value.code === (each.entityIDTypeCode || each.entityTypeCode)
              );
            each.entityIDTypeCodeDesc =
              tplEntityTypeCD && tplEntityTypeCD.length > 0
                ? tplEntityTypeCD[0].description
                : each.entityIDTypeCode
                ? each.entityIDTypeCode : each.entityTypeCode ? each.entityTypeCode
                : "";

            each.entTypCdDesc =
              tplEntityTypeCD && tplEntityTypeCD.length > 0
                ? tplEntityTypeCD[0].description
                : each.entityIDTypeCode
                ? each.entityIDTypeCode : each.entityTypeCode ? each.entityTypeCode
                : "";
          }
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  const formatSearchCriteria = (_row) => _row;
  const onTableRowclick = (row) => (event) => {
    dispatch(searchSingleRecordFinancialEntity(row));
    setRedirect(1);
  };
  const headCells =
    props.tableData && props.tableData.length === 1
      ? headCellsSingleRecord
      : headCellsMultiRecord;
  return (
    <TableComponent
      print={props.print}
      pathTo="/FinancialEntityUpdate?data="
      formatSearchCriteria={formatSearchCriteria}
      headCells={headCells}
      isSearch={true}
      tableData={getTableData(props.tableData || [])}
      onTableRowClick={onTableRowclick}
      defaultSortColumn={headCells[0].id}
      order={"asc"}
      sortColumns = 'commonEntityTypeCodeDesc,entityIDTypeCodeDesc,entityID,sortName'
      multiColumnSortingEnabled={true}
    />
  );
}
export default withRouter(FinancialEntitySearchTable);
