/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useRef } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FCNComponent from './FCNComponent';
import PayeeReceivablesComponent from './PayeeReceivablesComponent';
import TabPanel from '../../../../SharedModules/TabPanel/TabPanel';
import { Button } from 'react-bootstrap';
import ErrorMessages from '../../../../SharedModules/Errors/ErrorMessages';
import Footer from '../../../../SharedModules/Layout/footer';

export default function FinancialPayOutInquiry (props) {
  const tabValue = Number(sessionStorage.getItem('tabValuePayout'));
  const [value, setValue] = React.useState(tabValue || 0);
  const toPrintRef = useRef();

  const [errorMessages, seterrorMessages] = React.useState([]);
  const handleChange = (event, newValue) => {
    sessionStorage.clear();
    props.location.tableRender = false;
    setValue(newValue);
    seterrorMessages([]);
  };

  const showValidationError = errorMsgArray => {
    seterrorMessages(errorMsgArray);
  };

  const addPayoutInquiry = () => {
    sessionStorage.clear();
    setValue(0);
    props.history.push({
      pathname: '/FinancialPayOutAdd'
    });
  };

  return (
    <div className="pos-relative w-100 h-100">
      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />

        <div className="tab-header">
          <div className="float-left tab-heading">
            Search Payout
          </div>

          <div className="float-right mt-2">
            <Button
              title="Add Financial Payout"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-add btn-add-new"
              onClick={() => addPayoutInquiry()}
              disabled={global.globalIsReadOnly()}
            >
              Add
            </Button>
          </div>

          <div className="clearfix"></div>
        </div>
        <div className="tab-body padTop-10">
          <div className="tab-panelbody">
            <div className="tab-holder-p-7 my-3">
              <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                  <Tab label="FCN" />
                  <Tab label="Payee" />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                {value === 0 && <FCNComponent value={value} showError={showValidationError}></FCNComponent>}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {value === 1 && <PayeeReceivablesComponent value={value} showError={showValidationError}></PayeeReceivablesComponent> }
              </TabPanel>
            </div>
          </div>
        </div>
        <Footer print />
      </div>
    </div>

  );
}
