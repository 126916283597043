/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const VALID_VALUE_ACTION_TYPE = 'VALID_VALUE_ACTION_TYPE';
export const VALID_VALUE_VIEW_ACTION_TYPE = 'VALID_VALUE_VIEW_ACTION_TYPE';
export const FUNCTIONAL_AREA_DROPDOWN = 'FUNCTIONAL_AREA_DROPDOWN';
export const VALID_VALUE_UPDATE_ACTION_TYPE = 'VALID_VALUE_UPDATE_ACTION_TYPE';
export const RESETDATA = 'RESETDATA';
export const AUDIT_LOG_TABLE = "AUDIT_LOG_TABLE";
export const AUDIT_LOG_TABLE_ROW = "AUDIT_LOG_TABLE_ROW";
