/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import { withRouter } from 'react-router';
import TableComponent from '../../../../SharedModules/Table/Table';

const headCells = [
  {
    id: 'receivableFCN',
    disablePadding: false,
    label: 'FCN',
    enableHyperLink: true,
    width: '14%'
  },
  {
    id: 'establishedDate',
    disablePadding: false,
    label: 'Established Date',
    enableHyperLink: false,
    isDate: true,
    width: '13%'
  },
  {
    id: 'reasonCodeDesc',
    disablePadding: false,
    label: 'Reason Code',
    enableHyperLink: false,
    width: '12%',
    isTooltip: true,
    isTitle: 'resonCodeLongDesc',
  },
  {
    id: 'payeePayerTypeCodeDesc',
    disablePadding: false,
    label: 'Payee Type',
    enableHyperLink: false,
    width: '13%'
  },
  {
    id: 'payeePayerType',
    disablePadding: false,
    label: 'Payee ID',
    enableHyperLink: false,
    width: '13%'
  },
  {
    id: 'entityName',
    disablePadding: false,
    label: 'Payee Name',
    enableHyperLink: false,
    width: '12%'
  },
  {
    id: 'payeeStatusDesc',
    disablePadding: false,
    label: 'Payee Status',
    enableHyperLink: false,
    width: '9%',
  },
  {
    id: 'balanceAmount',
    disablePadding: false,
    label: 'Balance Amount',
    enableHyperLink: false,
    isBalance: true,
    width: '14%',
    numeric: true
  }
];

function BulkSearchResults(props) {
  const formatSearchCriteria = (row) => {
    return (row.receivableFCN);
  };
  const rowSelectedForDelete = data => {
    props.rowIdsSelected(data);
  };
  const columns = headCells.filter(cell => { if (cell.id === 'payeeStatusDesc' && props.showPayeePaymentStatus !== "N") { return false; } return true; });
  return (
    <TableComponent
      print={props.print}
      headCells={columns}
      fixedTable
      fixedView
      pathTo='/FinancialAccountGrossLevelUpdate?data='
      formatSearchCriteria={formatSearchCriteria}
      tableData={props.tableData || []}
      onTableRowDelete={rowSelectedForDelete}
      defaultSortColumn={headCells[1].id}
      isSearch={false}
      order={'desc'}
    />
  )
}

export default withRouter(BulkSearchResults);
