/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/BankAccountSearchActionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.BANKACCOUNT_LOCKBOX_GETREFERENCEDATA:
      return { ...state, dropdownData: action.dropdownData };
    case actionTypes.BANKACCOUNT_TYPE_GETREFERENCEDATA:
      return { ...state, typeDropdownData: action.typeDropdownData };
    case actionTypes.SEARCH_BANKACCOUNT_AND_LOCKBOX:
      return { ...state, lockBoxData: action.lockBoxData };
    case actionTypes.ADD_BANK_ACCOUNT_AND_LOCKBOX:
      return { ...state, addBankAccountAndLockBox: action.addBankAccountAndLockBox };
    case actionTypes.UPDATE_BANK_ACCOUNT_AND_LOCKBOX:
      return { ...state, updateBankAccountAndLockBox: action.updateBankAccountAndLockBox };
    case actionTypes.NOTES_USAGE_TYPE_DROPDOWNS_BANK_ACCOUNT:
      return { ...state, usageDropDown: action.usageDropDown };
    case actionTypes.RESETDATA:
      return action.resetData;
    case actionTypes.AUDIT_LOG_TABLE:
      return { ...state, auditDetails: action.payload }
    case actionTypes.AUDIT_LOG_TABLE_LOCK_BOX:
      return { ...state, auditLockBoxDetails: action.payload }
    case actionTypes.AUDIT_LOG_TABLE_CONTACT:
      return { ...state, auditContactDetails: action.payload }
    case actionTypes.AUDIT_LOG_TABLE_ADDRESS:
      return { ...state, auditAddressDetails: action.payload }
    default: return state;
  }
};
export default reducer;
