/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/FiscalPendCriteriaSearchActionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.DROPDOWNDATA:
      return { dropdownData: action.dropdownData };
    case actionTypes.MAP_ID:
      return { mapIdData: action.mapIdData };
    case actionTypes.BENEFIT_PLAN_ID:
      return { benefitPlanIdData: action.benefitPlanIdData };
    case actionTypes.SEARCH_FISCAL_PEND_CRITERIA:
      return { searchFiscalPendData: action.searchData };
    case actionTypes.GET_FISCAL_PEND_RECORD:
      return { searchRecord: action.searchRecord };
    case actionTypes.FISCAL_PEND_SEARCH_RESET:
      return action.resetData;

    case actionTypes.AUDIT_LOG_TABLE:
      return { auditDetails: action.payload }
    case actionTypes.AUDIT_LOG_TABLE_ROW:
      return { auditRowDetails: action.payload }
    default: return state;
  }
};

export default reducer;
