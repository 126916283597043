/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import serverExceptionsHandler from '../ServerErrorHandler';
import { systemParameterRowClickAction, providerNoticeRowClickAction, NCPDPRowClickAction, ARRowClickAction, RAEOBRowClickAction } from '../../Modules/ApplicationConfiguration/Store/Actions/TextManagement/TextManagementActions';
import { RemarkTextGetRecordAction } from '../../Modules/ApplicationConfiguration/Store/Actions/TextManagement/TextManagementAddUpdateActions';

const resolvePath = (object, path) =>
  path ? path.split('.').reduce((o, p) => (o ? o[p] : {}), object) : object;

export const dataToUrlParam = (data) => {
  const objStr = JSON.stringify(data);
  const toBase64 = btoa(unescape(encodeURIComponent(objStr)));

  return toBase64;
};

export const getDataFromUrl = (_url) => {
  if (!window) return null;
  const urlStr = _url || window.location.href;
  const url = new URL(urlStr);
  const data = url.searchParams.get('data');
  const decoded = data && atob(data);
  const parsed = decoded && JSON.parse(decoded);
  return parsed;
};

export default ({
  Wrapped,
  url,
  action = null,
  actionKey = 'payloadData',
  selector = null
}) => {
  const HOC = args => {
    const dispatch = useDispatch();
    const history = useHistory();
    const dataFromHistroy = args.location.state && args.location.state.dataUrl;
    const [data, setData] = useState(getDataFromUrl() || dataFromHistroy);
    const [check, setCheck] = useState(false);
    const [payload, setPayload] = useState(null);
    const [searchCriteria, setSearchCriteria] = useState(null);
    const onSearchView = searchviewvalues => {
      setSearchCriteria(searchviewvalues);
      dispatch(action(searchviewvalues));
    };
    let payloadViewData = useSelector(state => resolvePath(state, selector));
    let payloadData = useSelector(state => state.appConfigState.TextSearchState.Text);

    let passThrough = (data && data.passThrough) || !data;
    useEffect(() => {
      if (getDataFromUrl() && action && url !== '/TextUpdate') {
        setCheck(false);
        passThrough = false;
        setData(getDataFromUrl());
        payloadViewData = null;
      }
    }, [args.location.search]);

    const tempArrOfAdopteds = ['/ValidvalueView'];
    const passAsProps = tempArrOfAdopteds.includes(url);

    useEffect(() => {
      if (action && data && !passThrough && actionKey && url !== '/TextUpdate') {
        onSearchView(data);
        payloadViewData = null;
      } else if (data && !passThrough && actionKey && url === '/TextUpdate') {
        if (data.hasOwnProperty('eombProcedureTypeCode')) {
          dispatch(systemParameterRowClickAction(data));
          setSearchCriteria(data);
        }
        if (data.hasOwnProperty('providerTextSk')) {
          dispatch(providerNoticeRowClickAction(data));
          setSearchCriteria(data);
        }
        if (data.hasOwnProperty('ncpdpRejectCodeText')) {
          dispatch(NCPDPRowClickAction(data));
          setSearchCriteria(data);
        }
        if (data.hasOwnProperty('adjustmentReasonCode')) {
          dispatch(ARRowClickAction(data));
          setSearchCriteria(data);
        }
        if (data.hasOwnProperty('claimEOBCode')) {
          dispatch(RAEOBRowClickAction(data));
          setSearchCriteria(data);
        }
        if (data.hasOwnProperty('remarkCode')) {
          dispatch(RemarkTextGetRecordAction(data));
          setSearchCriteria(data);
        }
        payloadViewData = null;
        payloadData = null;
      }
    }, []);

    useEffect(() => {
      if (selector && payloadViewData && !passThrough) {
        const _parsedData = serverExceptionsHandler.parseData(payloadViewData, actionKey);
        _parsedData && setPayload(_parsedData);
      }
    }, [payloadViewData]);

    useEffect(() => {
      if (selector && payloadData && !passThrough) {
        const _parsedData = serverExceptionsHandler.parseData(payloadData, actionKey);
        _parsedData && setPayload(_parsedData);
      }
    }, [payloadData]);

    useEffect(() => {
      if (!check && !passAsProps) {
        if (passThrough) {
          setCheck(true);
        } else if (action ? payload : data) {
          const _history = serverExceptionsHandler.parseAndPush({ data: action ? payload : data, url, actionKey, searchCriteria });
          history.push(_history);
          setCheck(true);
        }
      }
      if (passAsProps) {
        history.push({
          pathname: url,
          state: { dataUrl: data }
        });
      }
      if (url === '/FinancialEntitySearch' && !payload) {
        const searchValue = getDataFromUrl();
        const _history = serverExceptionsHandler.parseAndPush({ data: action ? payload : data, url, actionKey, searchCriteria: searchValue });
        history.push(_history);
        setCheck(true);
      }
    }, [payload]);

    return check ? <Wrapped {...args} /> : passAsProps ? <Wrapped {...args} payloadData={payload} /> : null;
  };
  return HOC;
};
