/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableComponent from '../Table/Table';
import Bootstrap, { Button } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';

import {
    DialogTitle,
    DialogContent,
    DialogActions
} from '../Dialog/DialogUtilities';
/**
 * this is for Financial entity Screen
 */
export const headCells = [
    {
        id: '',
        numeric: false,
        disablePadding: true,
        label: 'Select',
        width: '20%',
        isRadio: true,
        sortDisable: true
    },
    {
        id: 'sysID',
        numeric: false,
        enableHyperLink: true,
        disablePadding: false,
        label: 'System Payee ID',
        width: '30%'
    },
    {
        id: 'payeeName',
        numeric: false,
        disablePadding: false,
        label: 'Payee Name',
        isNote: true,
        width: '30%'
    },
    {
        id: 'medicadeID',
        numeric: false,
        disablePadding: false,
        label: 'Medicaid ID',
        width: '30%'
    }
];

function SystemPayeeTable(props) {
    const searchSingleFinancialEntityRes = useSelector(
        state => state.financialEntityState.singleSearchResults
    );
    const [redirect, setRedirect] = React.useState(0);
   React.useEffect(() => {
        
        if (
            searchSingleFinancialEntityRes &&
            !searchSingleFinancialEntityRes.systemFailue
        ) {
            props.setUpdatedRecord(searchSingleFinancialEntityRes)
        }
    }, [searchSingleFinancialEntityRes]); 
    const editRow = row => event => {
        const searchCriteria = formatSearchCriteria(row);
        props.onContinueHyper(searchCriteria)
      };
    const formatSearchCriteria = _row => _row;
    return (
        <>
            <Dialog
                className="custom-dialog dialog-700"
                open={props.openSysPayeeTable}
            >
                <DialogTitle
                    id="system-Payee-id-table"
                
                >
                    System Payee IDs
                {/* <span onClick={props.handleCloseSysPayeeTable}>X</span> */}
                
                </DialogTitle>
                {/* <DialogActions onClick={props.handleCloseSysPayeeTable}>X</DialogActions> */}
                <DialogContent dividers>
                    <div className='tab-panelbody-audit' style={{ paddingTop: '30px' }}>
                        <div className="form-wrapper">
                        <div className="mui-custom-form input-md">
                            <TextField
                            id="standard-payee-id"
                            label="Payee Type"
                            disabled
                            value={props.payeeType}
                            inputProps={{ maxlength: 15 }}
                            placeholder=""
                            InputLabelProps={{
                                shrink: true
                            }}
                            >
                            </TextField>
                        </div>
                        <div className="mui-custom-form input-md">
                            <TextField
                            id="standard-payee-id"
                            label="Payee ID Type Code"
                            disabled
                            value={props.payeeIdTypeCode}
                            inputProps={{ maxlength: 15 }}
                            placeholder=""
                            InputLabelProps={{
                                shrink: true
                            }}
                            >
                            </TextField>
                        </div>
                        <div className="mui-custom-form input-md">
                            <TextField
                            id="standard-payee-id"
                            label="Payee ID"
                            disabled
                            value={props.payeeId}
                            inputProps={{ maxlength: 15 }}
                            placeholder=""
                            InputLabelProps={{
                                shrink: true
                            }}
                            >
                            </TextField>
                        </div>
                        </div>
                        <div className="tab-holder table-no-wrap table-p-5">
                            <TableComponent
                                fixedTable
                                print={props.print}
                                headCells={headCells}
                                isSearch={true}
                                formatSearchCriteria={formatSearchCriteria}
                               // pathTo="/FinancialEntityUpdate?data="
                                tableData={props.tableData}
                                onTableRowClick={editRow}
                                defaultSortColumnDesc={true}
                                defaultSortColumn={'sysPayeeID'}
                                uniqueKey={props.uniqueKey}
                                onTableCellButtonClick={props.onTableCellButtonClick}
                                pagination={true}
                            />
                        </div>
                        <div style={{ paddingBottom: '50px', marginBotton: '20px' }}>
                            <div className="float-right" style={{ marginBotton: '20px', marginRight: '15px' }}>
                                <Button color="primary" className='btn btn-success ml-1'
                                    onClick={props.onContinue}
                                    disabled={!props.currentSysID}
                                >
                                    <i class="fa fa-arrow-right ml-1" aria-hidden="true"></i>
                                    Continue
                                </Button>
                            </div>
                            <div className="float-right" style={{ marginBotton: '20px', marginRight: '15px' }}>
                                <Button color="primary" className='btn btn-success ml-1'
                                    onClick={props.handleCloseSysPayeeTable}
                                   
                                >
                                    <i class="fa fa-arrow-right ml-1" aria-hidden="true"></i>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>);
}
export default SystemPayeeTable;
