/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import { withRouter } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { RAEOBRowClickAction } from "../../Store/Actions/TextManagement/TextManagementActions";
import TableComponent from "../../../../SharedModules/Table/Table";
import { DEFAULT_LOB_VALUE } from "../../../../SharedModules/AppConstants";

const headCells = [
  {
    id: "claimEOBCode",
    disablePadding: false,
    label: "EOB Code",
    width: "40%",
    isVarchar: true
  },
  {
    id: "eobDesc",
    disablePadding: false,
    label: "EOB Text",
    width: "60%",
    isText: true
  }
];

function RAEOBTable (props) {
  const [redirect, setRedirect] = React.useState(false);

  // API CAll
  const dispatch = useDispatch();
  const onRowClick = values => dispatch(RAEOBRowClickAction(values));
  const payloadData = useSelector(
    state => state.appConfigState.textManagementState.payload
  );
  if (redirect === 1) {
    if (payloadData != null) {
      if (payloadData.length === 1) {
        props.history.push({
          pathname: "/TextUpdate",
          state: { row: payloadData[0], TextType: "RA EOB" }
        });
      }
    }
  }
  const editRow = row => event => {
    const searchCriteria = {
      lobCode: [DEFAULT_LOB_VALUE],
      claimEOBCode: row.claimEOBCode,
      eobDesc: row.eobDesc
    };
    onRowClick(searchCriteria);

    let valuetoredirect = 0;
    valuetoredirect = valuetoredirect + 1;
    setRedirect(valuetoredirect);
  };
  const formatSearchCriteria = (row) => {
    return ({
      lobCode: [DEFAULT_LOB_VALUE],
      claimEOBCode: row.claimEOBCode,
      eobDesc: row.eobDesc
    });
  };

  const tableComp = (
    <TableComponent
      print={props.print}
      isSearch={true}
      fixedTable
      headCells={headCells}
      tableData={props.tableData ? props.tableData : []}
      onTableRowClick={editRow}
      defaultSortColumn={headCells[0].id}
      pathTo='/TextUpdate?data='
      formatSearchCriteria={formatSearchCriteria}
    />
  );
  return tableComp;
}
export default withRouter(RAEOBTable);
