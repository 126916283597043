import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import AppConfigText from './Modules/ApplicationConfiguration/Translate/appConfigTranslate.json';
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          TEST: 'sample text',
          WELCOME: 'Welcome to React application in english language'
        },
        APPCONFIG: {
          APPCONTX: { ...AppConfigText },
          TEST: 'samle text from appcon',
          EMPLOYEE: {
            NAME: 'siddu',
            PHONE: '9500290XXX',
            ADDRESS: {
              STREET: 'colony',
              CITY: 'city'
            }
          }
        }

      },
      de: {
        translations: {
          WELCOME: 'Welcome to React application in another language'
        }
      }
    },
    fallbackLng: 'en',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations', 'APPCONFIG'],
    defaultNS: 'translations',
    // objectNotation: true,
    keySeparator: '.', // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });
export default i18n;
