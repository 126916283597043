/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/WriteOffActionType';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.DROPDOWNDATA:
      return { dropdownData: action.dropdownData };
    case actionTypes.WRITE_OFF_SAVE:
      return { saveWriteOffData: action.saveWriteOffData };
    case actionTypes.WRITE_OFF_RESTABLISHMENT_SAVE:
      return { saveWriteOffRestablishmentData: action.saveWriteOffRestablishmentData };
    case actionTypes.PAYEE_ID_TYPE_DROPDOWN:
      return { payeeIdTypeDropdown: action.payeeIdTypeDropdown };
    case actionTypes.AR_AGING_DAYS:
      return { agingDaysListData: action.agingDaysListData };
    case actionTypes.PAYEE_ID_VALIDATE:
      return { validatePayeeIDvalues: action.validatePayeeIDvalues };
    case actionTypes.RESETDATA:
      return action.resetData;
    default: return state;
  }
};
export default reducer;
