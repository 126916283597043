/* eslint-disable no-unused-vars */
/**

* © [2023] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

// Application Configuration starts
export const DASHBOARD = "/dashboard";
export const VALID_VALUE_SEARCH = "/ValidvalueSearch";
export const VALID_VALUE_VIEW = "/ValidvalueView";
export const SYSTEM_LIST = "/SystemList";
export const SYSTEM_LIST_ADD = "/SystemListAdd";
export const SYSTEM_PARAMETER = "/SystemParameter";
export const SYSTEM_PARAMETER_ADD = "/SystemParametersAdd";
export const SYSTEM_PARAMETER_EDIT = "/SystemParametersEdit";
export const SYSTEM_LIST_ADD_UPDATE = "/SystemListAddUpdate";
export const MAPSET_SEARCH = "/MapSetSearch";
export const MAPSET_ADD = "/MapSetAdd";
export const MAPSET_EDIT = "/MapSetEdit";
export const TEXT_MANAGEMENT_SEARCH = "/TextManagementSearch";
export const TEXT_UPDATE = "/TextUpdate";
export const TEXT_ADD = "/TextAdd";
export const My_ACCOUNT = '/MyAccount'
// Application Configuration ends
// Bank Account & LockBox starts
export const BANK_ACCOUNT_AND_LOCKBOX = "/BankAccountAndLockbox";
export const BANK_ACCOUNT_LOCKBOX_ADD = "/BankAccountLockBoxAdd";
export const BANK_ACCOUNT_LOCKBOX_EDIT = "/BankAccountLockBoxEdit";
// Bank Account & LockBox ends
// Budget Maintenance starts
export const FINANCIAL_BUDGET_SEARCH = "/FinancialBudgetSearch";
export const BUDGET_DATA_UPDATE = "/BudgetDataUpdate";
export const BUDGET_DATA_ADD = "/BudgetDataAdd";
// Budget Maintenance ends
// Account Receivables starts
export const FINANCIAL_ACCOUNT_INQUIRY = "/FinancialAccountInquiry";
export const FCN_SEARCH_RESULTS = "/FCNSearchResults";
export const GROSS_LEVEL_ADD_PAYEE_COMPONENT = "/GrossLevelAddPayeeComponent";
export const FINANCIAL_ACCOUNT_GROSS_LEVEL = "/FinancialAccountGrossLevel";
export const PAYEE_SEARCH_RESULTS = "/PayeeSearchResults";
export const MCN_SEARCH_RESULTS = "/MCNSearchResults";
export const FINANCIAL_ACCOUNT_LIEN_LEVY = "/FinancialAccountLienLevy";
export const CLAIMS_RECEIVABLE = "/ClaimsReceivable";
export const FINANCIAL_ACCOUNT_LIEN_LEVY_UPDATE = "/FinancialAccountLienLevyUpdate";
export const FINANCIAL_ACCOUNT_GROSS_LEVEL_UPDATE = "/FinancialAccountGrossLevelUpdate";
export const AR_TRANSFER = "/ARTransfer";
export const AR_REVERSE = "/ARReverse";
export const AR_ADJUST = "/ARAdjust";
export const FP_ADJUST = "/FPAdjust";
export const ADVANCE_PAYMENT = "/AdvancePayment";
export const WRITE_OFF = "/WriteOff";
export const WRITE_OFF_REESTABLISHMENT = "/WriteOffReEstablishment";
export const BULK_MAINTENANCE = "/BulkMaintenance";
export const AR_BULK_TRANSFER = "/ARBulkTransfer";
export const BULK_REVERSE = "/BulkReverse";
export const BULK_WRITE_OFF = "/BulkWriteOff";
export const BULK_WRITE_OFF_REEST = "/BulkWriteOffReest";
export const CHANGE_PAY_STATUS = "/ChangePayStatus";
// Account Receivables ends
// Security starts
export const CHANGE_PASSWORD = "/ChangePassword";
export const ADD_USER = "/AddUser";
export const EDIT_USER = "/EditUser";
export const USER_PROFILE = "/UserProfile";
export const SEARCH_USER = "/SearchUser";
export const RESET_PASSWORD = "/ResetPassword";
// Security ends
// Financial Entity starts
export const FINANCIAL_ENTITY_SEARCH = "/FinancialEntitySearch";
export const FINANCIAL_ENTITY_ADD = "/FinancialEntityAdd";
export const FINANCIAL_ENTITY_UPDATE = "/FinancialEntityUpdate";
// Financial Entity ends
// Financial Receipt starts
export const FINANCIAL_RECEIPT_SEARCH = "/FinancialReceiptSearch";
export const FINANCIAL_RECEIPT_RETURN = "/FinancialReceiptReturn";
export const FINANCIAL_RECEIPT_ADD = "/FinancialReceiptAdd";
export const FINANCIAL_RECEIPT_UPDATE = "/FinancialReceiptUpdate";
export const FINANCIAL_RECEIPT_ADJUSTMENT = "/FinancialReceiptAdjustment";
export const FINANCIAL_RECEIPT_REVERSAL = "/FinancialReceiptReversal";
export const REISSUE_UPDATE = "/ReissueUpdate";
// Financial Receipt ends
// Fund Code starts
export const FUND_CODE_SEARCH = "/FundCodeSearch";
export const FUND_CODE_ADD = "/FundCodeAdd";
export const FUND_CODE_EDIT = "/FundCodeEdit";
// Fund Code ends
// Financial Payout starts
export const FINANCIAL_PAYOUT_SEARCH = "/FinancialPayOutSearch";
export const FCN_PAYOUT_SEARCH_RESULTS = "/FCNPayOutSearchResults";
export const FINANCIAL_PAYOUT_ADD = "/FinancialPayOutAdd";
export const FINANCIAL_PAYOUT_UPDATE = "/FinancialPayoutUpdate";
// Financial Payout ends
// Accounts Payable starts
export const PAYOUT_APPROVAL_SEARCH = "/PayoutApprovalSearch";
// Accounts Payable ends
// Fiscal Pend Criteria starts
export const FISCAL_PEND_CRITERIA_ADD = "/FiscalPendCriteriaAdd";
export const FISCAL_PEND_CRITERIA_EDIT = "/FiscalPendCriteriaEdit";
export const FISCAL_PEND_CRITERIA_SEARCH = "/FiscalPendCriteriaSearch";
// Fiscal Pend Criteria ends
// 1099 Correction starts
export const CORRECTION_SEARCH = "/CorrectionSearch";
export const CORRECTION_DETAILS_VIEW = "/CorrectionDetailsView";
export const CORRECTION_SEARCH_APPROVAL = "/CorrectionSearchApproval";
// 1099 Correction ends
// Financial Inquiry starts
export const FINANCIAL_INQUIRY_SEARCH = "/FinancialInquirySearch";
export const CLAIM_SUMMARY = "/ClaimSummary";
// Financial Inquiry ends
// Project Control starts
export const EVENT_LOGGING = "/EventLogging";
export const GLOBAL_AUDIT_SEARCH = "/GlobalAuditSearch";
export const GLOBAL_AUDIT_DETAILS = "/GlobalAuditDetails";
// Project Control ends
// EMAR starts
export const EMAR_SEARCH = "/EMARSearch";
export const EMAR_ADD = "/EMARAdd";
export const EMAR_UPDATE = "/EMARUpdate";
// EMAR ends
// Waiver starts
export const WAIVER_SEARCH = "/WaiverSearch";
export const WAIVER_ADD = "/WaiverAdd";
export const WAIVER_UPDATE = "/WaiverUpdate";
// Waiver ends
// FRC starts
export const FRC_ADD = "/FRCAdd";
export const FRC_SEARCH = "/FRCSearch";
export const FRC_UPDATE = "/FRCUpdate";
// FRC ends
// CMS starts
export const CMS64_SEARCH = "/CMS64Search";
export const CMS64_ADD = "/CMS64Add";
export const CMS64_UPDATE = "/CMS64Update";
// CMS ends
// Mass Upload starts
export const FINANCIAL_UPLOAD = "/FinancialUpload";
export const FINANCIAL_UPLOAD_VIEW = "/FinancialUploadView";
// Mass Upload ends
//Payment Inquiry Start
export const PAYMENT_INQUIRY_STATUS = '/PaymentStatusInquirySearch'
//Payment Inquiry End
// Payment Cirteria Starts
export const PAYMENT_CRITERIA_SEARCH = '/PaymentCriteriaSearch';
export const PAYMENT_CRITERIA_ADD = '/PaymentCriteriaAdd';
export const PAYMENT_CRITERIA_EDIT = '/PaymentCriteriaEdit';
// Payment Cirteria Ends
// Footer Links Starts
export const SITE_MAP = '/SiteMap';
export const FOOTER_LINKS = '/FooterLinks';
export const WIP_FOOTER_LINKS = '/WIPFooterLinks';
// Footer Links Ends
// Insights Starts
export const OPERATIONAL_INSIGHTS = '/OperationalInsights';
export const PERFORMANCE_INSIGHTS = '/PerformanceInsights';
// Insights Ends
