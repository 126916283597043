/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const BANKACCOUNT_LOCKBOX_GETREFERENCEDATA = 'BANKACCOUNT_LOCKBOX_GETREFERENCEDATA';
export const BANKACCOUNT_TYPE_GETREFERENCEDATA = 'BANKACCOUNT_TYPE_GETREFERENCEDATA';
export const SEARCH_BANKACCOUNT_AND_LOCKBOX = 'SEARCH_BANKACCOUNT_AND_LOCKBOX';
export const ADD_BANK_ACCOUNT_AND_LOCKBOX = 'ADD_BANK_ACCOUNT_AND_LOCKBOX ';
export const UPDATE_BANK_ACCOUNT_AND_LOCKBOX = 'UPDATE_BANK_ACCOUNT_AND_LOCKBOX ';
export const RESETDATA = 'RESETDATA';
export const NOTES_USAGE_TYPE_DROPDOWNS_BANK_ACCOUNT = 'NOTES_USAGE_TYPE_DROPDOWNS_BANK_ACCOUNT';
export const AUDIT_LOG_TABLE = "AUDIT_LOG_TABLE";
export const AUDIT_LOG_TABLE_ADDRESS = "AUDIT_LOG_TABLE_ADDRESS";
export const AUDIT_LOG_TABLE_CONTACT = "AUDIT_LOG_TABLE_CONTACT";
export const AUDIT_LOG_TABLE_LOCK_BOX = "AUDIT_LOG_TABLE_LOCK_BOX";