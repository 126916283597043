/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const SEARCH_TEXT_MANAGEMENT = 'SEARCH_TEXT_MANAGEMENT';
export const UPDATE_TEXT_MANAGEMENT = 'UPDATE_TEXT_MANAGEMENT';
export const ADD_TEXT_MANAGEMENT = 'ADD_TEXT_MANAGEMENT';
export const RESETDATA = 'RESETDATA';
export const SEARCH_TEXT_RAEOB = 'SEARCH_TEXT_RAEOB';
export const SEARCH_TEXT_PN = 'SEARCH_TEXT_PN';
export const SEARCH_TEXT_SP = 'SEARCH_TEXT_SP';
export const SEARCH_TEXT_REVENUE = 'SEARCH_TEXT_REVENUE';
export const SEARCH_TEXT_PNROW = 'SEARCH_TEXT_PNROW';
export const SEARCH_TEXT_RAEOBROW = 'SEARCH_TEXT_RAEOBROW';
export const SEARCH_TEXT_RC = 'SEARCH_TEXT_RC';
export const SEARCH_TEXT_ARC = 'SEARCH_TEXT_ARC';
export const TEXT_MANAGEMENT_SEARCH_NCPDP_ENDPOINT = 'TEXT_MANAGEMENT_SEARCH_NCPDP_ENDPOINT';
export const SEARCH_TEXT_NCPDP = 'SEARCH_TEXT_NCPDP';
export const SEARCH_TEXT_NCPDPROW = 'SEARCH_TEXT_NCPDPROW';
export const SEARCH_TEXT_ARROW = 'SEARCH_TEXT_ARROW';
export const SEARCH_TEXT_RCROW = 'SEARCH_TEXT_RCROW';
export const TEXT_MANAGEMENT_SEARCH_DROPDOWN_ENDPOINT = 'TEXT_MANAGEMENT_SEARCH_DROPDOWN_ENDPOINT';
export const ADD_TEXT_PN = 'ADD_TEXT_PN';
export const ADD_TEXT_RAEOB = 'ADD_TEXT_RAEOB';
export const ADD_TEXT_REMARKTEXT = 'ADD_TEXT_REMARKTEXT';
export const ADD_TEXT_ADJUSTMENT_REASON = 'ADD_TEXT_ADJUSTMENT_REASON';
export const ADD_TEXT_NCPDP_TEXT = 'ADD_TEXT_NCPDP_TEXT';
export const ADD_TEXT_EOMB_TEXT = 'ADD_TEXT_EOMB_TEXT';
export const UPDATE_TEXT_PN = 'ADD_TEXT_PN';
export const UPDATE_TEXT_RAEOB = 'ADD_TEXT_RAEOB';
export const UPDATE_TEXT_REMARKTEXT = 'ADD_TEXT_REMARKTEXT';
export const UPDATE_TEXT_ADJUSTMENT_REASON = 'ADD_TEXT_ADJUSTMENT_REASON';
export const UPDATE_TEXT_NCPDP_TEXT = 'ADD_TEXT_NCPDP_TEXT';
export const UPDATE_TEXT_EOMB_TEXT = 'ADD_TEXT_EOMB_TEXT';
export const PROVIDER_SPECIALTY_DATA = 'PROVIDER_SPECIALTY_DATA';
export const DELETE_TEXT_EOMB_TEXT = 'DELETE_TEXT_EOMB_TEXT';
export const DELETE_TEXT_RAEOB = 'DELETE_TEXT_RAEOB';
export const GET_PROVIDER_NOTICE_TEXT = 'GET_PROVIDER_NOTICE_TEXT';
export const GET_RAEOB_TEXT = 'GET_RAEOB_TEXT';
export const GET_EOMB_TEXT = 'GET_EOMB_TEXT';
export const GET_REMARK_TEXT = 'GET_REMARK_TEXT';
export const GET_ADJUSTMENT_TEXT = 'GET_ADJUSTMENT_TEXT';
export const GET_NCPDP_TEXT = 'GET_NCPDP_TEXT';
export const RESET_FIELD = 'RESET_FIELD';
export const RESET_TEXT_SEARCH = 'RESET_TEXT_SEARCH';
