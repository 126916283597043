/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const PAYEE_ID_VALIDATE = 'PAYEE_ID_VALIDATE';
export const AR_TRANSFER = 'AR_TRANSFER';
export const RESET_TRANSFER = 'RESET_TRANSFER';
export const ARTRANSFER_ERROR = 'ARTRANSFER_ERROR';
