/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import { withRouter } from "react-router";

import TableComponent from "../../../../SharedModules/Table/Table";

/* IMPLEMENT TABLE COMPONENT */
const headCells = [
  {
    id: "providerTaxID",
    disablePadding: false,
    label: "Provider Tax ID",
    enableHyperLink: false,
    width: 90
  },
  {
    id: "providerID",
    disablePadding: false,
    label: "Provider Medicaid Id",
    enableHyperLink: true,
    width: 90
  },
  {
    id: "providerName",
    disablePadding: false,
    label: "Provider Name",
    enableHyperLink: false,
    width: 90
  },
  {
    id: "ssn",
    disablePadding: false,
    label: "SSN",
    enableHyperLink: false,
    width: 90
  }
];

function CorrectionSearchResults (props) {
  /* DISPLAY CORRECTION DETAILS ON ROW CLICK */
  const dataToUrlParam = (rowData) => {
    const objStr = JSON.stringify(rowData);
    const toBase64 = btoa(unescape(encodeURIComponent(objStr)));
    return toBase64;
  };
  const editRow = row => event => {
    if (row.providerID === event.target.innerText) {
      const datatoURL = dataToUrlParam(row);
      window.open('/FinancialEntityUpdate?data=' + datatoURL, '_blank');
      return false;
    }
    props.setErrorMessages([]);

    const rowDetails = {
      lobCode: row.lobCode,
      categoryCode: row.categoryCode,
      timePeriodCode: row.timePeriodCode,
      commonEntitySK: row.commonEntitySK
    };
    props.setParentTableRow(rowDetails);
    props.onRowclickAPICall(rowDetails);
  };
  return (
    <div>
      <TableComponent
        print={props.print}
        isSearch={true}
        headCells={headCells}
        tableData={props.tableData || []}
        onTableRowClick={editRow}
        defaultSortColumn={headCells[0].id}
        order={'asc'}
      />
    </div>
  );
}
export default withRouter(CorrectionSearchResults);
