/* eslint-disable eol-last */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const REASON_CODE = 'An Adjustment Reason Code must be entered on an A/R Adjustment.';
export const BALANCE_ZERO = 'A Receivable with a Balance Amount of zero cannot be adjusted.';
export const SYS_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const SAVE_SUCCESS = 'System successfully saved the Information.';
export const REVISED_AR_AMOUNT_INVALID = 'Please enter the data in correct format.';
export const REVISED_PENALITY_INVALID = 'Please enter the data in correct format.';
export const REVISED_INTEREST_INVALID = 'Please enter the data in correct format.';
export const REVISED_FESS_INVALID = 'Please enter the data in correct format.';
export const REVISED_AMOUNT_ZERO = 'Revised Amount Values must be greater than zero. Please re-enter.';
export const AR_AMOUNT_REGEX = /^[-]?[0-9]{0,9}\.?[0-9]{0,2}$/;
export const INTEREST_AMOUNT_REGEX = /^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/;
export const CALCULATE_ADJUST = 'Calculate before adjust.';
export const ARADJUST_DONE = 'Adjustment has already been created for this A/R.';
export const TRANSACTION_NUMBER_FORMAT = 'should not contain spaces or special characters.';