/* eslint-disable arrow-parens */
import axios from "axios";
import * as actionTypes from "./actionTypes";
import * as serviceEndPoint from "../services/service";

export const dispatchEventLogInquiry = (logInquiryData) =>({
  type: actionTypes.EVENT_LOG_INQUIRY,
  logInquiryData: logInquiryData,
})

export const dispatchEventLogEvent = (logEventData) =>({
type: actionTypes.EVENT_LOG_EVENT,
logEventData: logEventData,
})

export const EventLogInquiryAction = (values) => (dispatch) => {
  return axios
    .post(`${serviceEndPoint.EVENT_LOG_INQUIRY_SEARCH}`, values)
    .then((response) => {
      if (response.status === 500 || response.status === 400) {
        dispatch(dispatchEventLogInquiry(response.data));
      } else if (response.status === 200 && response.data) {
        dispatch(dispatchEventLogInquiry(response.data));
      } else {
        dispatch(dispatchEventLogInquiry([]));
      }
    })
    .catch((error) => {
      dispatch(dispatchEventLogInquiry(error.data));
    });
};

export const EventLogEventAction = (values) => (dispatch) => {
  return axios
    .post(`${serviceEndPoint.EVENT_LOG_EVENT_SEARCH}`, values)
    .then((response) => {
      if (response.status === 500 || response.status === 400) {
        dispatch(dispatchEventLogEvent(response.data));
      } else if (response.status === 200 && response.data) {
        dispatch(dispatchEventLogEvent(response.data));
      } else {
        dispatch(dispatchEventLogEvent([]));
      }
    })
    .catch((error) => {
      dispatch(dispatchEventLogEvent(error.data));
    });
};

export const resetSearchCriteria = () => ({
  type: actionTypes.RESETDATA,
  resetData: [],
});

export const resetDetailsMedicare = () => ({
  type: actionTypes.MEDICARE_DETAILS_RESETDATA,
});

export const dispatchDropdowns = (response,dynamicType) => ({
  type: dynamicType?dynamicType:actionTypes.GET_APP_DROPDOWNS,
  [dynamicType?'dynamicType':'dropdowns']: response
});
export const dispatchRsCdDropdowns = (response,dynamicType) => ({
  type: dynamicType?dynamicType:actionTypes.GET_VOID_RSCD,
  [dynamicType?'dynamicType':'dropdowns']: response
});

export const GET_SYSTEM_LIST_DROPDOWNS =
  (values, dynamicType) => async (dispatch) => {
    try {
      const response = await Promise.all(
        values.map((e) =>
          axios.post(`${serviceEndPoint.SYSTEM_LIST_SEARCH_ENDPOINT}`, e)
        )
      );
      dispatch(
        dispatchDropdowns(
          response.reduce((a, c) => {
            a[
              `${c.data.searchResults[0].functionalArea}_${c.data.searchResults[0].listNumber}`
            ] = c.data.searchResults[0].systemListDetails.map((e) => ({
              startingValue: e.startingValue,
              endingValue: e.endingValue
            }));
            return a;
          }, {}),
          dynamicType
        )
      );
    } catch (error) {
      dispatch(dispatchDropdowns({}, dynamicType));
    }
  };

export const GET_APP_DROPDOWNS = (values,dynamicType) => dispatch => {  
  return axios.post(`${serviceEndPoint.VALID_VALUE_DROPDOWNS}`, values)
      .then(response => {
          if (Object.keys(response.data.listObj).length > 0) {
              dispatch(dispatchDropdowns(response.data.listObj,dynamicType));
          }
      })
}

export const GET_VOID_RSCD_DROPDOWNS = (values,dynamicType) => dispatch => {  
  return axios.post(`${serviceEndPoint.VALID_VALUE_DROPDOWNS}`, values)
      .then(response => {
          if (Object.keys(response.data.listObj).length > 0) {
              dispatch(dispatchRsCdDropdowns(response.data.listObj,dynamicType));
          }
      })
}

export const dispatchVVDropdowns = (response,dynamicType) => ({
  type: dynamicType?dynamicType:actionTypes.GET_VALIDVALUE_DROPDOWNS,
  [dynamicType?'dynamicType':'dropdownsVV']: response
});

export const GET_VALIDVALUE_DROPDOWNS = (values,dynamicType) => dispatch => {  
  return axios.post(`${serviceEndPoint.VALID_VALUE_SEARCH_ENDPOINT}`, values)
      .then(response => {
          if (Object.keys(response.data.listObj).length > 0) {
              dispatch(dispatchDropdowns(response.data.listObj,dynamicType));
          }
      })
}