/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import AddBank from "./AddBank";
import Dialog from "@material-ui/core/Dialog";
import TableComponent from "../../../../SharedModules/Table/Table";
import AuditTableComponent from "../../../../SharedModules/AuditLog/AuditTableComponent";
import {
  DialogTitle,
  DialogContent
} from "../../../../SharedModules/Dialog/DialogUtilities";
import {
  validateAddBankDetails,
  resetAddBankPopUp
} from "../AddValidationUtility";
import {
  generateUUID,
  getDateInMMDDYYYYFormat,
  getUTCTimeStamp
} from "../../../../SharedModules/DateUtilities/DateUtilities";
import { setFocus } from "../../../ApplicationConfiguration/Store/Actions/AppConfigCommon/AppConfigActions";

export default function Banking(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const {
    callValidatePayeeDetails,
    showEditPage,
    setAllowNavigation,
    bankingTabTableData,
    setBankingTabTableData,
    bankingTabEFTStatusDD,
    bankingTabAccTypeDD,
    EFTStatusDropdown,
    AccountTypeDropdown,
    disableFields,
    seterrorMessages,
    auditRowData,
    setAuditRowData,
    setShowLogTable,
    setActiveBankingData,
    showLogBankingTable,
    userInquiryPrivileges,
    setFocusCheck,
    initialFocusCheck
  } = props;
  const headCells = [
    {
      id: "beginDate",
      numeric: false,
      disablePadding: true,
      width: "9%",
      label: "Begin Date"
    },
    {
      id: "endDate",
      numeric: false,
      disablePadding: false,
      width: "9%",
      label: "End Date"
    },
    {
      id: "eftStatusCodeDesc",
      numeric: false,
      disablePadding: false,
      width: "13%",
      label: "Status"
    },
    {
      id: "financialInstitutionName",
      numeric: false,
      disablePadding: false,
      width: "15%",
      label: "Financial Institution"
    },
    {
      id: "bankBranchName",
      numeric: false,
      disablePadding: false,
      width: "15%",
      label: "Branch"
    },
    {
      id: "accountTypeCodeDesc",
      numeric: false,
      disablePadding: false,
      width: "15%",
      label: "Account Type"
    },
    {
      id: "routingNumber",
      numeric: false,
      disablePadding: false,
      width: "12%",
      label: "Routing Number"
    },
    {
      id: "accountNumber",
      numeric: false,
      disablePadding: false,
      width: "12%",
      label: "Account Number"
    }
  ];
  const editHeadCells = [
    {
      id: "beginDate",
      numeric: false,
      disablePadding: true,
      width: "10%",
      label: "Begin Date"
    },
    {
      id: "endDate",
      numeric: false,
      disablePadding: false,
      width: "10%",
      label: "End Date"
    },
    {
      id: "eftStatusCodeDesc",
      numeric: false,
      disablePadding: false,
      width: "15%",
      label: "Status"
    },
    {
      id: "financialInstitutionName",
      numeric: false,
      disablePadding: false,
      width: "15%",
      label: "Financial Institution"
    },
    {
      id: "bankBranchName",
      numeric: false,
      disablePadding: false,
      width: "15%",
      label: "Branch"
    },
    {
      id: "accountTypeCodeDesc",
      numeric: false,
      disablePadding: false,
      width: "17%",
      label: "Account Type"
    },
    {
      id: "routingNumber",
      numeric: false,
      disablePadding: false,
      width: "14%",
      label: "Routing Number"
    },
    {
      id: "accountNumber",
      numeric: false,
      disablePadding: false,
      width: "14%",
      label: "Account Number"
    }
  ];
  // Dropdown description implementation ---- starts
  const [filteredTableData, setFilteredTableData] = React.useState([]);

  const dropDownFilter = (dropDownType, value) => {
    let TypeDropdownData = [];
    switch (dropDownType) {
      case "eftStatusCode":
        TypeDropdownData = EFTStatusDropdown;
        break;
      case "accountTypeCode":
        TypeDropdownData = AccountTypeDropdown;
        break;
    }
    if (TypeDropdownData) {
      const filteredValue = TypeDropdownData.filter(
        (dropdownTypeValue, index) => dropdownTypeValue.code === value
      );
      if (filteredValue && filteredValue.length > 0) {
        return filteredValue[0].description;
      }
      return value;
    }
  };

  const bankTableFilter = filteredData => {
    if (filteredData && filteredData.length > 0) {
      filteredData.map((data, index) => {
        data.eftStatusCodeDesc = dropDownFilter(
          "eftStatusCode",
          data.eftStatusCode
        );
        data.accountTypeCodeDesc = dropDownFilter(
          "accountTypeCode",
          data.accountTypeCode
        );
      });
    }
    setFilteredTableData(filteredData);
  };
  // Dropdown description implementation -------- ends

  // Set Description in fields on Initial table load
  React.useEffect(() => {
    const filteredData = bankingTabTableData;
    bankTableFilter(filteredData);
  }, [bankingTabTableData]);

  const [
    bankingTableSelectedRows,
    setBankingTableSelectedRows
  ] = React.useState([]);
  const [bankingTableClkRow, setBankingTableClkRow] = React.useState(null);
  // Add banking Details Popup variables
  const [bankingDetails, setbankingDetails] = React.useState({
    beginDate: "",
    endDate: "12/31/9999",
    eftStatus: DEFAULT_DD_VALUE,
    eftStatusDate: "",
    preNoteDate: "",
    financialInstitution: "",
    branch: "",
    accountType: DEFAULT_DD_VALUE,
    routingNumber: "",
    accountNumber: "",
    accountHolderName: "",
    PayeePayerEffectiveSetupSequenceNumber: null
  });
  const [showBankDetailsErr, setShowBankDetailsErr] = React.useState({
    showBeginDateErr: false,
    showEndDateErr: false,
    showEFTStatusErr: false,
    showEFTStatusDateErr: false,
    showPreNoteDateErr: false,
    showFinancInstiErr: false,
    showBranchErr: false,
    showAccTypeErr: false,
    showRoutingNumErr: false,
    showACCNumErr: false,
    showAccHoldNameErr: false
  });
  const [bankDetailsErrMsg, setBankDetailsErrMsg] = React.useState({
    beginDateErrMsg: "",
    endDateErrMsg: "",
    eftStatusErrMsg: "",
    eftStatusDateErrMsg: "",
    preNoteDateErrMsg: "",
    financInstiErrMsg: "",
    branchErrMsg: "",
    accTypeErrMsg: "",
    routingNumErrMsg: "",
    accNumerrMsg: "",
    accHoldNameErrMsg: ""
  });
  const handleChangeAddBankPopUp = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setbankingDetails({ ...bankingDetails, [name]: event.target.value });
  };

  // Edit popup reset functionality
  const [retainRowValue, setRetainRowValue] = React.useState("");
  const onEditPopupValue = row => {
    if (row !== "") {
      setSelectedBeginDate(new Date(row.beginDate));
      setSelectedEndDate(new Date(row.endDate));
      setSelectedEftDate(new Date(row.eftStatusDate));
      let preDate = row.preNoteDate ? new Date(row.preNoteDate) : "";
      setSelectedPreNoteDate(preDate);
      setbankingDetails({
        auditUserID: logInUserID, // For Add and Edit/Update API call new values
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: row.addedAuditUserID
          ? row.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: row.addedAuditTimeStamp
          ? row.addedAuditTimeStamp
          : getUTCTimeStamp(),
        beginDate: row.beginDate,
        endDate: row.endDate,
        eftStatus: row.eftStatusCode,
        eftStatusDate: row.eftStatusDate,
        preNoteDate: row.preNoteDate,
        financialInstitution: row.financialInstitutionName,
        branch: row.bankBranchName,
        accountType: row.accountTypeCode,
        routingNumber: row.routingNumber,
        accountNumber: row.accountNumber,
        accountHolderName: row.accountHolderName,
        PayeePayerEffectiveSetupSequenceNumber:
          row.PayeePayerEffectiveSetupSequenceNumber || null
      });
    }
  };
  const [selectedBeginDate, setSelectedBeginDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    new Date("12/31/9999")
  );
  const [selectedEftDate, setSelectedEftDate] = React.useState(null);
  const [selectedPreNoteDate, setSelectedPreNoteDate] = React.useState(null);

  const handleBeginDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedBeginDate(date);
    if (date !== null && date.toString() !== "Invalid Date") {
      setbankingDetails({
        ...bankingDetails,
        beginDate: getDateInMMDDYYYYFormat(date)
      });
    }
  };
  const handleEndDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedEndDate(date);
    if (date !== null && date.toString() !== "Invalid Date") {
      setbankingDetails({
        ...bankingDetails,
        endDate: getDateInMMDDYYYYFormat(date)
      });
    }
  };
  const handleEftDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedEftDate(date);
    if (date !== null && date.toString() !== "Invalid Date") {
      setbankingDetails({
        ...bankingDetails,
        eftStatusDate: getDateInMMDDYYYYFormat(date)
      });
    }
  };
  const handlePreNoteDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedPreNoteDate(date);
    if (date !== null && date.toString() !== "Invalid Date") {
      setbankingDetails({
        ...bankingDetails,
        preNoteDate: getDateInMMDDYYYYFormat(date)
      });
    }
    if (date === null || date === "") {
      setbankingDetails({ ...bankingDetails, preNoteDate: "" });
    }
  };
  // Handling Add Bank Details PopUp
  const [openBankPopUp, setOpenBankPopUp] = React.useState(false);
  const [rowEdit, setRowEdit] = React.useState(false);
  const handleClose = () => {
    setFocusCheck(false);
    setAllowNavigation(false)
    setActiveBankingData(null);
    seterrorMessages([]);
    setOpenBankPopUp(false);
  };
  const handleOpen = () => {
    setOpenBankPopUp(true);
  };
  useEffect(() => {
    if (openBankPopUp && document.getElementById("form_pop_up")) {
      document.getElementById("form_pop_up").scrollIntoView();
    }
  }, [openBankPopUp]);
  // Add/Edit Popup reset
  const resetPopUp = event => {
    setFocusCheck(initialFocusCheck);
    setAllowNavigation(initialFocusCheck)
    setActiveBankingData(null);
    seterrorMessages([]);
    setShowBankDetailsErr([]);
    if (rowEdit) {
      onEditPopupValue(retainRowValue);
    } else {
      resetAddBankPopUp(
        setbankingDetails,
        setSelectedBeginDate,
        setSelectedEndDate,
        setSelectedEftDate,
        setSelectedPreNoteDate,
        setShowBankDetailsErr,
        setBankDetailsErrMsg,
        bankingTableClkRow
      );
    }
  };
  const handleClickOpenBank = () => {
    if (callValidatePayeeDetails(true)) {
      resetAddBankPopUp(
        setbankingDetails,
        setSelectedBeginDate,
        setSelectedEndDate,
        setSelectedEftDate,
        setSelectedPreNoteDate,
        setShowBankDetailsErr,
        setBankDetailsErrMsg,
        bankingTableClkRow
      );
      setRowEdit(false);
      handleOpen();
    }
  };
  const callValidateAddBankDetails = () => {
    return validateAddBankDetails(
      bankingDetails,
      selectedBeginDate,
      selectedEndDate,
      setSelectedEndDate,
      selectedEftDate,
      selectedPreNoteDate,
      setShowBankDetailsErr,
      setBankDetailsErrMsg,
      bankingTabTableData,
      bankingTableClkRow,
      seterrorMessages
    );
  };
  const saveOrEditPopUpDetails = () => {
    if (callValidateAddBankDetails()) {
      const tempBankingTabTableData = [...bankingTabTableData];
      const bankingData = {
        auditUserID: logInUserID, // For Add and Edit/Update API call new values
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: bankingDetails.addedAuditUserID
          ? bankingDetails.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: bankingDetails.addedAuditTimeStamp
          ? bankingDetails.addedAuditTimeStamp
          : getUTCTimeStamp(),
        accountNumber: bankingDetails.accountNumber,
        accountHolderName: bankingDetails.accountHolderName,
        beginDate: bankingDetails.beginDate,
        endDate: bankingDetails.endDate,
        bankBranchName: bankingDetails.branch,
        eftStatusCode: bankingDetails.eftStatus,
        eftStatusDate: bankingDetails.eftStatusDate,
        preNoteDate: bankingDetails.preNoteDate || null,
        financialInstitutionName: bankingDetails.financialInstitution,
        accountTypeCode: bankingDetails.accountType,
        routingNumber: bankingDetails.routingNumber,
        bankInfoVrsnNo: 0,
        payeePayerEffectiveSetupSequenceNumber:
          bankingDetails.payeePayerEffectiveSetupSequenceNumber || null
      };
      if (bankingTableClkRow && bankingTableClkRow !== null && rowEdit) {
        tempBankingTabTableData.map((value, index) => {
          if (value.id) {
            if (value.id === bankingTableClkRow.id) {
              value.auditUserID = logInUserID; // For Add and Edit/Update API call new values
              value.auditTimeStamp = getUTCTimeStamp();
              value.addedAuditUserID = bankingDetails.addedAuditUserID
                ? bankingDetails.addedAuditUserID
                : logInUserID;
              value.addedAuditTimeStamp = bankingDetails.addedAuditTimeStamp
                ? bankingDetails.addedAuditTimeStamp
                : getUTCTimeStamp();
              value.beginDate = bankingDetails.beginDate;
              value.endDate = bankingDetails.endDate;
              value.eftStatusCode = bankingDetails.eftStatus;
              value.eftStatusDate = bankingDetails.eftStatusDate;
              value.preNoteDate = bankingDetails.preNoteDate;
              value.financialInstitutionName =
                bankingDetails.financialInstitution;
              value.bankBranchName = bankingDetails.branch;
              value.accountTypeCode = bankingDetails.accountType;
              value.routingNumber = bankingDetails.routingNumber;
              value.accountNumber = bankingDetails.accountNumber;
              value.accountHolderName = bankingDetails.accountHolderName;
            }
          } else {
            if (
              value.payeePayerEffectiveSetupSequenceNumber ===
              bankingTableClkRow.payeePayerEffectiveSetupSequenceNumber
            ) {
              value.auditUserID = logInUserID; // For Add and Edit/Update API call new values
              value.auditTimeStamp = getUTCTimeStamp();
              value.addedAuditUserID = bankingDetails.addedAuditUserID
                ? bankingDetails.addedAuditUserID
                : logInUserID;
              value.addedAuditTimeStamp = bankingDetails.addedAuditTimeStamp
                ? bankingDetails.addedAuditTimeStamp
                : getUTCTimeStamp();
              value.beginDate = bankingDetails.beginDate;
              value.endDate = bankingDetails.endDate;
              value.eftStatusCode = bankingDetails.eftStatus;
              value.eftStatusDate = bankingDetails.eftStatusDate;
              value.preNoteDate = bankingDetails.preNoteDate;
              value.financialInstitutionName =
                bankingDetails.financialInstitution;
              value.bankBranchName = bankingDetails.branch;
              value.accountTypeCode = bankingDetails.accountType;
              value.routingNumber = bankingDetails.routingNumber;
              value.accountNumber = bankingDetails.accountNumber;
              value.accountHolderName = bankingDetails.accountHolderName;
            }
          }
        });
        setBankingTableClkRow(null);
      } else {
        tempBankingTabTableData.push({ ...bankingData, id: generateUUID() });
      }
      setBankingTabTableData(tempBankingTabTableData);
      bankTableFilter(tempBankingTabTableData);
      seterrorMessages([]);
      setOpenBankPopUp(false);
    }
  };
  const selectedBankTableRow = selectedRows => {
    setBankingTableSelectedRows([...selectedRows]);
  };
  const deleteSelectedBankTableRow = () => {
    let tempBankTableData = [...bankingTabTableData];
    if (bankingTableSelectedRows && bankingTableSelectedRows.length > 0) {
      bankingTableSelectedRows.map((selectedRowId, index) => {
        tempBankTableData = tempBankTableData.filter(
          tempRow => tempRow.id !== selectedRowId
        );
      });
    }
    setBankingTabTableData(tempBankTableData);
  };
  const [newRecordFlag, setNewRecordFlag] = React.useState(false);
  const bankingTableRowClk = row => event => {
    setActiveBankingData(row);
    if (callValidatePayeeDetails(true)) {
      setRowEdit(true);
      setBankingTableClkRow(row);
      setRetainRowValue(row);
      setSelectedBeginDate(new Date(row.beginDate));
      setSelectedEndDate(new Date(row.endDate));
      setSelectedEftDate(new Date(row.eftStatusDate));
      let preDate = row.preNoteDate ? new Date(row.preNoteDate) : "";
      setSelectedPreNoteDate(preDate);
      setbankingDetails({
        auditUserID: logInUserID, // For Add and Edit/Update API call new values
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: row.addedAuditUserID
          ? row.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: row.addedAuditTimeStamp
          ? row.addedAuditTimeStamp
          : getUTCTimeStamp(),
        beginDate: row.beginDate,
        endDate: row.endDate,
        eftStatus: row.eftStatusCode,
        eftStatusDate: row.eftStatusDate,
        preNoteDate: row.preNoteDate,
        financialInstitution: row.financialInstitutionName,
        branch: row.bankBranchName,
        accountType: row.accountTypeCode,
        routingNumber: row.routingNumber,
        accountNumber: row.accountNumber,
        accountHolderName: row.accountHolderName,
        PayeePayerEffectiveSetupSequenceNumber:
          row.PayeePayerEffectiveSetupSequenceNumber || null
      });
      if (row.id) {
        setNewRecordFlag(true);
      } else {
        setNewRecordFlag(false);
      }
    }
    handleOpen();
    setShowBankDetailsErr({
      showBeginDateErr: false,
      showEndDateErr: false,
      showEFTStatusErr: false,
      showEFTStatusDateErr: false,
      showPreNoteDateErr: false,
      showFinancInstiErr: false,
      showBranchErr: false,
      showAccTypeErr: false,
      showRoutingNumErr: false,
      showACCNumErr: false,
      showAccHoldNameErr: false
    });
  };

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const eftStatusCode =
            props.dropdowns &&
            props.dropdowns['F1#F_EFT_STAT_CD'] &&
            props.dropdowns['F1#F_EFT_STAT_CD'].filter(
              value => value.code === each.eftStatusCode
            );
          each.eftStatusCodeDesc =
          eftStatusCode && eftStatusCode.length > 0
              ? eftStatusCode[0].description
              : each.eftStatusCode ? each.eftStatusCode :  '';
        //   const accountTypeCode =
        //       props.dropdowns &&
        //       props.dropdowns['F1#F_BANK_ACCT_TY_CD'] &&
        //       props.dropdowns['F1#F_BANK_ACCT_TY_CD'].filter(
        //         value => value.code === each.accountTypeCode
        //       );
        //  each.accountTypeCodeDesc =
        //  accountTypeCode && accountTypeCode.length > 0
        //         ? accountTypeCode[0].description
        //         : each.accountTypeCode ? each.accountTypeCode :  '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  return (
    <div className="tab-holder my-2">
      <fieldset className="r-custom-fieldset no-padding">
        <legend>Bank Details:</legend>
        <div className="tab-holder">
          <div className="mb-3" style={{ float: "right" }}>
            <Button
              className="btn-text-main-delete btn-transparent  ml-1"
              onClick={deleteSelectedBankTableRow}
              disabled={disableFields}
              data-testid="delete_btn"
              title={"Delete"}
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
              Delete
            </Button>
            <button
              class="btn-text-main-add btn-success  ml-1"
              onClick={handleClickOpenBank}
              disabled={disableFields || !userInquiryPrivileges}
              title={"Add Bank Info"}
              data-testid="test_add_plus_btn"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
              Add
            </button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-holder table-no-wrap table-p-5">
          <TableComponent
            wraptableData={true}
            print={props.print}
            isSearch={false}
            headCells={showEditPage ? editHeadCells : headCells}
            tableData={getTableData(filteredTableData || [])}
            onTableRowClick={bankingTableRowClk}
            onTableRowDelete={selectedBankTableRow}
            defaultSortColumn={headCells[0].id}
            // Audit Log Changes
            onTableCellButtonClick={props.onClickAuditDialogLog}
            uniqueKey="payeePayerEffectiveSetupSequenceNumber"
            setClose={props.setClose}
            close={props.close}
          />
          {openBankPopUp ? (
            <div className="tabs-container" id="form_pop_up">
              <div className="tab-header  mini-tab-header">
                <div className="tab-heading float-left">
                  {rowEdit ? "Edit Bank Info" : "Add Bank Info"}
                </div>
                <div className="float-right mt-1">
                  <Button
                    className="btn btn-success ml-1"
                    onClick={saveOrEditPopUpDetails}
                    disabled={disableFields || !userInquiryPrivileges}
                  >
                   <i class="fa fa-check" aria-hidden="true"></i>
                    {rowEdit ? "Update" : "Add"}
                  </Button>
                  <Button
                    className="bt-reset btn-transparent  ml-1"
                    onClick={resetPopUp}
                    disabled={disableFields || !userInquiryPrivileges}
                    data-testid="test_reset_button"
                  >
                    <i class="fa fa-undo" aria-hidden="true"></i>
                    Reset
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="btn btn-primary btn btn-outlined ml-1"
                    onClick={handleClose}
                    data-testid="cancel_btn"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
              <AddBank
                newRecordFlag={newRecordFlag}
                disableFields={disableFields}
                rowEdit={rowEdit}
                showEditPage={showEditPage}
                bankingTabEFTStatusDD={bankingTabEFTStatusDD}
                bankingTabAccTypeDD={bankingTabAccTypeDD}
                bankingDetails={bankingDetails}
                handleChangeAddBankPopUp={handleChangeAddBankPopUp}
                selectedBeginDate={selectedBeginDate}
                handleBeginDateChange={handleBeginDateChange}
                selectedEndDate={selectedEndDate}
                handleEndDateChange={handleEndDateChange}
                selectedEftDate={selectedEftDate}
                handleEftDateChange={handleEftDateChange}
                selectedPreNoteDate={selectedPreNoteDate}
                handlePreNoteDateChange={handlePreNoteDateChange}
                showBankDetailsErr={showBankDetailsErr}
                bankDetailsErrMsg={bankDetailsErrMsg}
                saveOrEditPopUpDetails={saveOrEditPopUpDetails}
                resetPopUp={resetPopUp}
                EFTStatusDropdown={EFTStatusDropdown}
                AccountTypeDropdown={AccountTypeDropdown}
                userInquiryPrivileges = {userInquiryPrivileges}
                dropdowns={props.dropdowns}
              />
              {showLogBankingTable ? (
                <div id="audit-Banking-table">
                  <AuditTableComponent
                    print
                    auditRowData={auditRowData}
                    setAuditRowData={setAuditRowData}
                    setShowLogTable={setShowLogTable}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </fieldset>
    </div>
  );
}
