/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './EmarActiontypes';
import * as serviceEndPoint from '../../../../SharedModules/services/service';
import axios from 'axios';
import { commonAPIHandler } from '../../../../SharedModules/ServerErrorHandler/index';

const headers = {
  'Access-Control-Allow-Origin': '*'
};

export const getDropdownDataAction = (value) => {
    return dispatch => {
      return axios.post(`${serviceEndPoint.DROPDOWNS}`, value)
        .then(response => {
            let sendingResponse = commonAPIHandler(response);
          dispatch(setDropdownData(sendingResponse));
        })
        .catch(error => {
            let sendingResponse = commonAPIHandler(error);
          dispatch(setDropdownData(sendingResponse));
        });
    };
  };

  export const searchEMARAction = (value) => {
    return dispatch => {
      return axios.post(`${serviceEndPoint.SEARCH_EMAR}`, value)
        .then(response => {
            let sendingResponse = commonAPIHandler(response, true);
          dispatch(setSearchData(sendingResponse));
        })
        .catch(error => {
            let sendingResponse = commonAPIHandler(error);
          dispatch(setSearchData(sendingResponse));
        });
    };
  };

  export const createEMARAction = (value) => {
    return dispatch => {
      return axios.post(`${serviceEndPoint.ADD_EMAR}`, value)
        .then(response => {
            let sendingResponse = commonAPIHandler(response);
          dispatch(setAddresponse(sendingResponse));
        })
        .catch(error => {
            let sendingResponse = commonAPIHandler(error);
          dispatch(setAddresponse({systemFailure: true}));
        });
    };
  };

  
  export const updateEMARAction = (value) => {
    return dispatch => {
      return axios.post(`${serviceEndPoint.UPDATE_EMAR}`, value)
        .then(response => {
            let sendingResponse = commonAPIHandler(response);
          dispatch(setUpdateresponse(sendingResponse));
        })
        .catch(error => {
            let sendingResponse = commonAPIHandler(error);
          dispatch(setUpdateresponse({systemFailure: true}));
        });
    };
  };

  export const setUpdateresponse = (updateResponse) => {
    return {
        type: actionTypes.UPDATE_EMAR,
        updatePayload: updateResponse
    }
}

  export const setAddresponse = (addResponse) => {
      return {
          type: actionTypes.CREATE_EMAR,
          createPayload: addResponse
      }
  }

  export const setSearchData = (searchData) => {
      return {
          type: actionTypes.SEARCH_EMAR,
          searchPayload: searchData
      }
  }
  
  export const setDropdownData = (dropdownData) => {
    return {
      type: actionTypes.DROPDOWNDATA,
      dropdownData: dropdownData
    };
  };

export const ResetEMAR = (keyName) => {
  return {
      type: actionTypes.RESET_EMAR,
      keyName: keyName
  }
}