/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../../Actions/TextManagement/TextManagementActionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.GET_PROVIDER_NOTICE_TEXT:
      return { ...state, Text: action.PNText };
    case actionTypes.GET_EOMB_TEXT:
      return { ...state, Text: action.EOMBText };
    case actionTypes.GET_REMARK_TEXT:
      return { ...state, Text: action.RemarkText };
    case actionTypes.GET_ADJUSTMENT_TEXT:
      return { ...state, Text: action.ARText };
    case actionTypes.GET_NCPDP_TEXT:
      return { ...state, Text: action.NcpdpText };
    case actionTypes.GET_RAEOB_TEXT:
      return { ...state, Text: action.RaeobText };
    case actionTypes.RESET_TEXT_SEARCH:
      return { ...state, Text: null };
    default: return state;
  }
};

export default reducer;
