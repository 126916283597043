/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const FUNCTIONAL_AREA_REQUIRED = 'From Code is a required field if Thru Code is entered.';
export const BUSINESS_NAME_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.'
;
export const DATA_ELEMENT_NAME_ERROR = 'Either code or text is required.'
;
export const PN_PROVIDERTYPE_ERROR = 'is required.';
export const NO_RECORD_FOUND = 'No records found for the search criteria entered.';
