/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as serviceEndPoint from '../../../../../SharedModules/services/service';
import axios from 'axios';
import * as actionTypes from './SecurityActionTypes';

const headers = {
  'Access-Control-Allow-Origin': '*'
};
export const getLobDDDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.DROPDOWNS}`, value)
      .then(response => {
        let data = [];
        data = response.data;
        dispatch(setLobDDList(data));
      })
      .catch(error => {
        dispatch(setLobDDList({ systemFailue: true }));
      });
  };
};
export const getWorkUnitListAction = () => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.GET_WORKUNIT_LIST}`, { headers: headers })
      .then(response => {
        let data = [];
        data = response.data;
        dispatch(setWorkUnitList(data));
      })
      .catch(error => {
        dispatch(setWorkUnitList({ systemFailue: true }));
      });
  };
};
export const validateUserIdAction = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.VALIDATE_USER_ID}/${value}`, { headers: headers })
      .then(response => {
        let data = [];
        data = response.data;
        dispatch(setValidUserDetails(data));
      })
      .catch(error => {
        dispatch(setValidUserDetails({ systemFailue: true }));
      });
  };
};
export const addNewUserActioin = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.ADD_NEW_USER}`, value, { headers: headers })
      .then(response => {
        dispatch(setAddUserResponse(response.data));
      })
      .catch(error => {
        dispatch(setAddUserResponse({ systemFailue: true }));
      });
  };
};
export const updateExistingUserAction = (value) => {
  return dispatch => {
    return axios.put(`${serviceEndPoint.UPDATE_EXISTING_USER}`, value)
      .then(response => {
        let data = [];
        data = response.data;

        dispatch(setUpdateUserDetails(data));
      })
      .catch(error => {
        dispatch(setUpdateUserDetails({ systemFailue: true }));
      });
  };
};
export const getValidUserDetails = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.GET_USER_PRIVILEGES}?userId=${value}`)
      .then(response => {
        if (response) {

        }
        dispatch(setValidUserprivileges(response.data));
      })
      .catch(error => {
        dispatch(setValidUserprivileges([]));
        
      });
  };
};
export const setValidUserprivileges = (data) => {
  return {
    type: actionTypes.USER_VALID_ROLES_ACTION,
    validUserPrivileges: data
  };
};
export const setLobDDList = (lobDDList) => {
  return {
    type: actionTypes.GET_LOB_ADD_USER,
    lobDDList: lobDDList
  };
};
export const setWorkUnitList = (workUnitList) => {
  return {
    type: actionTypes.GET_WORKUNIT_LIST,
    workUnitList: workUnitList
  };
};
export const setValidUserDetails = (validUserDetails) => {
  return {
    type: actionTypes.VALIDATE_USER_ID_ACTION_TYPE,
    validUserDetails: validUserDetails
  };
};
export const setAddUserResponse = (addUSerResponse) => {
  return {
    type: actionTypes.ADD_NEW_USER,
    addUSerResponse: addUSerResponse
  };
};
export const setUpdateUserDetails = (updateUser) => {
  return {
    type: actionTypes.UPDATE_EXISTING_USER,
    updateUser: updateUser
  };
};
