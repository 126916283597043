/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import TableComponent from "../../../../../../../SharedModules/Table/Table";
import TextField from "@material-ui/core/TextField";
import numeral from "numeral";
import LienHolderPayOutTable from "./LienHolderPayoutComponent";
export default function LienLevyhistoryTable(props) {
  const headCells = [
    {
      id: "fcn/tcn",
      numeric: false,
      disablePadding: true,
      label: "FCN/TCN",
      enableHyperLink: false,
      isDate: false,
      width: 120,
      fontSize: 12
    },
    {
      id: "reasonCode",
      numeric: false,
      disablePadding: false,
      label: "Reason Code",
      enableHyperLink: true,
      isDate: false,
      width: 120,
      fontSize: 12
    },
    {
      id: "appliedDate",
      numeric: false,
      disablePadding: false,
      label: "Applied Date",
      enableHyperLink: false,
      isDate: true,
      width: 120,
      fontSize: 12
    },
    {
      id: "appliedAmount",
      numeric: false,
      disablePadding: false,
      label: "Applied Amount",
      enableHyperLink: false,
      isDate: true,
      width: 120,
      fontSize: 12
    }
  ];
  return (
    <div>
      <div className="form-wrapper">
        <div className="mui-custom-form input-md">
          <TextField
            id="standard-aramount"
            label="Original A/R Amount"
            value={numeral(props.values.originalARAmountData).format("$0,0.00")}
            inputProps={{ maxlength: 15 }}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            disabled={true}
          ></TextField>
        </div>
        <div className="mui-custom-form input-md">
          <TextField
            id="standard-appliedamount"
            label="Applied Amount"
            value={numeral(props.values.appliedAmountData).format("$0,0.00")}
            inputProps={{ maxlength: 15 }}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            disabled={true}
          ></TextField>
        </div>
        <div className="mui-custom-form input-md">
          <TextField
            id="LienLevyDetailsstandardBalanceAmount"
            label="Balance Amount"
            value={numeral(props.values.balanceAmountData).format("$0,0.00")}
            inputProps={{ maxlength: 15 }}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            disabled={true}
          ></TextField>
        </div>
      </div>
      <div className="tab-holder">
        <h6 className="sub-header" style={{ fontWeight: "bold" }}>
          Receivable Activity
        </h6>
        <TableComponent
          print={props.print}
          headCells={headCells}
          tableData={[]}
        />
        <h6 className="sub-header" style={{ fontWeight: "bold" }}>
          Lien Holder Payouts
        </h6>
        <LienHolderPayOutTable print />
      </div>
    </div>
  );
}
