/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import numeral from 'numeral';
import Radio from '@material-ui/core/Radio';

export default function ExemptionDetails (props) {
  const userInquiryPrivileges = !global.globalIsReadOnly();
  return (
    <form autoComplete="off">
      <div className="row">
        <div className="col-3">
          <div className="form-wrapper vertical-wrapper">
            <div
              className="mui-custom-form min-md"
              style={{ lineHeight: '100%', marginBottom: '22px' }}
            >
              <label className="MuiFormLabel-root small-label">
                Exempt From Penalty<span>*</span>
              </label>
              <div className="sub-radio no-margin-y">
                <Radio
                  value="Y"
                  id="exemptFromPenaltyIdYes"
                  checked={
                    props.values.exemptFromPenalty === true ||
                    props.values.exemptFromPenalty === 'Y'
                  }
                  onChange={props.handleChange('exemptFromPenalty')}
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : parseFloat(props.values.balanceAmount) <= 0
                  }
                />
                <label className="text-black" for="exemptFromPenaltyIdYes">
                  Yes
                </label>
                <Radio
                  value="N"
                  id="exemptFromPenaltyIdNo"
                  checked={
                    props.values.exemptFromPenalty === false ||
                    props.values.exemptFromPenalty === 'N'
                  }
                  onChange={props.handleChange('exemptFromPenalty')}
                  className="ml-4"
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : parseFloat(props.values.balanceAmount) <= 0
                  }
                />
                <label className="text-black" for="exemptFromPenaltyIdNo">
                  No
                </label>
              </div>
            </div>
            <div
              className="mui-custom-form min-md"
              style={{ lineHeight: '100%', marginBottom: '22px' }}
            >
              <label className="MuiFormLabel-root small-label">
                Exempt from Interest<span>*</span>
              </label>
              <div className="sub-radio no-margin-y">
                <Radio
                  value="Y"
                  id="exemptFromInterestIdYes1"
                  checked={
                    props.values.exemptFromInterest === true ||
                    props.values.exemptFromInterest === 'Y'
                  }
                  onChange={props.handleChange('exemptFromInterest')}
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : parseFloat(props.values.balanceAmount) <= 0
                  }
                />
                <label className="text-black" for="exemptFromInterestIdYes1">
                  Yes
                </label>
                <Radio
                  value="N"
                  id="exemptFromInterestIdNo1"
                  checked={
                    props.values.exemptFromInterest === false ||
                    props.values.exemptFromInterest === 'N'
                  }
                  onChange={props.handleChange('exemptFromInterest')}
                  className="ml-4"
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : parseFloat(props.values.balanceAmount) <= 0
                  }
                />
                <label className="text-black" for="exemptFromInterestIdNo1">
                  No
                </label>
              </div>
            </div>
            <div
              className="mui-custom-form min-md"
              style={{ lineHeight: '100%', marginBottom: '22px' }}
            >
              <label className="MuiFormLabel-root small-label">
                Exempt From Collection Agency<span>*</span>
              </label>
              <div className="sub-radio no-margin-y">
                <Radio
                  value="Y"
                  id="exemptFromCollectionAgencyIdYes1"
                  checked={
                    props.values.exemptFromCollectionAgency === true ||
                    props.values.exemptFromCollectionAgency === 'Y'
                  }
                  onChange={props.handleChange('exemptFromCollectionAgency')}
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : parseFloat(props.values.balanceAmount) <= 0
                  }
                />
                <label
                  className="text-black"
                  for="exemptFromCollectionAgencyIdYes1"
                >
                  Yes
                </label>
                <Radio
                  value="N"
                  id="exemptFromCollectionAgencyIdNo1"
                  checked={
                    props.values.exemptFromCollectionAgency === false ||
                    props.values.exemptFromCollectionAgency === 'N'
                  }
                  onChange={props.handleChange('exemptFromCollectionAgency')}
                  className="ml-4"
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : parseFloat(props.values.balanceAmount) <= 0
                  }
                />
                <label
                  className="text-black"
                  for="exemptFromCollectionAgencyIdNo1"
                >
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="form-wrapper vertical-wrapper">
            <div className="mui-custom-form min-md">
              <TextField
                id="standard-GrossExemptionCalculatedPenalty"
                fullWidth
                label="Calculated Penalty"
                InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                placeholder=""
                inputProps={{ maxlength: 13 }}
                InputLabelProps={{
                  shrink: true
                }}
                value={numeral(props.calculatedPenalty).format('$0,0.00')}
              ></TextField>
            </div>
            <div className="mui-custom-form min-md">
              <TextField
                id="standard-GrossExemptionCalculatedInterest"
                fullWidth
                label="Calculated Interest"
                InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                placeholder=""
                inputProps={{ maxlength: 13 }}
                InputLabelProps={{
                  shrink: true
                }}
                value={numeral(props.calculatedInterest).format('$0,0.00')}
              ></TextField>
            </div>
            <div className="mui-custom-form min-md">
              <TextField
                id="standard-GrossExemptionCalculatedFeesAssessed"
                fullWidth
                label="Fees Assessed"
                InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                placeholder=""
                inputProps={{ maxlength: 13 }}
                InputLabelProps={{
                  shrink: true
                }}
                value={numeral(props.calculatedFee).format('$0,0.00')}
              ></TextField>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
