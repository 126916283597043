/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';

import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import numeral from 'numeral';
import {
  downloadPDFPreview
} from '../../../../SharedModules/store/Actions/SharedAction';
import * as PriorCalenderYearConst from '../FISearch/FISearchConstants';
import { currentYear } from '../../../../SharedModules/DateUtilities/DateUtilities';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding='default'
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ minWidth: headCell.width, maxWidth: headCell.width, width: headCell.width, fontSize: '14px' }}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: '50%'
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  anchorLine: {
    margin: '-15px !important'
  }
}));

export default function SystemParameterTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };
  const dispatch = useDispatch();
  let year = currentYear();
  const pdfDownload = (folderName, fileName) => dispatch(downloadPDFPreview(folderName, fileName));
  const onRowClick = (row) => {
	const orginal1099FolderName = process.env.REACT_APP_FOLDER_NAME;
  	const orginal1099FileName = process.env.REACT_APP_LTR_ACT_08G_0001_ORIG + "_" + props?.commonEntitySK + "_" + Number(year - row.timePeriodCode) + ".pdf"
    const correctionl1099FileName = process.env.REACT_APP_LTR_ACT_08G_0001_CRCN + "_" + props?.commonEntitySK + "_" + Number(year- row.timePeriodCode) + "_" + row.latest1099CorrectionSeqNum + ".pdf"
	let orginal1099AmountInd = false;
	if(row.categoryCode === PriorCalenderYearConst.ORGINAL_1099_AMOUNT){
		orginal1099AmountInd = true;
	}
    pdfDownload(orginal1099FolderName, orginal1099AmountInd ? orginal1099FileName : correctionl1099FileName);
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper, 'override-width-100'}>
        <div style={{ width: '100%' }}>
          <Table
            className={clsx(classes.table, 'customDataTable', 'with-link')}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.tableData.length}
              headCells={props.headCells}
            />
            <TableBody>
              {stableSort(props.tableData, getSorting(order, orderBy))
                .map((row, index) => {
					const totalClaimAmountInd = row.totalClaimAmount === 0 || row.totalClaimAmount === null
					const hyperLinkIndFor1099 = row.categoryCode === PriorCalenderYearConst.ORGINAL_1099_AMOUNT && !totalClaimAmountInd;
					const hyperLinkIndFor1099Correction = row.categoryCode === PriorCalenderYearConst.CORRECTION_1099_AMOUNT && row.isLatest1099CorrectedAndApproved === true;
					return (
                    <TableRow hover data-testid ='test-tableData'>
                      <TableCell style={{ width: '40px' }} id ='test-categoryCd'>{row.categoryCode}</TableCell>
                      <TableCell style={{ width: '40px' }} id ='test-numberOfClaims'>{numeral(row.numberOfClaims).format('0,0')}</TableCell>
                      <TableCell style={{ width: '40px' }} className="has-link" scope="row" id ='test-amount'>{!props.claimSummaryFlag && (hyperLinkIndFor1099 || hyperLinkIndFor1099Correction) ? <a title={row.categoryCode} data-testid = 'test-hyprlink' id ="test-hyperLink" onClick={() => onRowClick(row) } className={classes.anchorLine}>{numeral(row.totalClaimAmount).format('$0, 0.00')} </a> : numeral(row.totalClaimAmount).format('$0, 0.00')}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  );
}
