import * as actionTypes from '../Actions/appConfigActionTypes';
import { toast } from 'react-toastify';
const initialState = {
  temp: '',
  name: 'siddu'
};

const addValue = (state, action) => { return state; };
const removeValue = (state, action) => { return state; };
const setValue = (state, action) => { return state; };
const fetchValue = (state, action) => {
  toast.success(' value added to Store', { position: toast.POSITION.TOP_CENTER });
  return { ...state, temp: action.value };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_VALUE: return addValue(state, action);
    case actionTypes.REMOVE_VALUE: return removeValue(state, action);
    case actionTypes.SET_VALUE: return setValue(state, action);
    case actionTypes.FETCH_VALUE: return fetchValue(state, action);
    default: return state;
  }
};

export default reducer;
