/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const RESETDATA = 'RESETDATA';
export const WRITE_OFF_SAVE = 'WRITE_OFF_SAVE';
export const WRITE_OFF_RESTABLISHMENT_SAVE = 'WRITE_OFF_RESTABLISHMENT_SAVE';
export const PAYEE_ID_TYPE_DROPDOWN = 'PAYEE_ID_TYPE_DROPDOWN';
export const AR_AGING_DAYS = 'AR_AGING_DAYS';
export const PAYEE_ID_VALIDATE = 'PAYEE_ID_VALIDATE';
export const DROPDOWNDATA = 'DROPDOWNDATA';
