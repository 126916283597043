/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import PayeeJson from './PayeType.json';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import DialogActions from '@material-ui/core/DialogActions';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';

export default function AddBank(props) {
  const {
    newRecordFlag, bankingTabEFTStatusDD, bankingTabAccTypeDD, bankingDetails, handleChangeAddBankPopUp, selectedBeginDate, handleBeginDateChange, selectedEndDate, handleEndDateChange,
    selectedEftDate, handleEftDateChange, selectedPreNoteDate, handlePreNoteDateChange, showBankDetailsErr, bankDetailsErrMsg,
    saveOrEditPopUpDetails, resetPopUp, AccountTypeDropdown, EFTStatusDropdown, showEditPage, rowEdit, disableFields, userInquiryPrivileges
  } = props;



  return (
    <div className="form-border my-3">

    <form autoComplete="off">
      <div className="form-wrapper">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="mui-custom-form with-date override-width-23 override-m-08">
            <KeyboardDatePicker
              maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
              id="begin-date"
              label="Begin Date"
              required
              disabled={(showEditPage && rowEdit && !(new Date() < new Date(selectedPreNoteDate))) && (!newRecordFlag) || disableFields || !userInquiryPrivileges}
              format="MM/dd/yyyy"
              placeholder="mm/dd/yyyy"
              value={selectedBeginDate}
              onChange={handleBeginDateChange}
              helperText={showBankDetailsErr.showBeginDateErr ? bankDetailsErrMsg.beginDateErrMsg : null}
              error={showBankDetailsErr.showBeginDateErr ? bankDetailsErrMsg.beginDateErrMsg : null}
              InputLabelProps={{
                shrink: true
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </div>
          <div className="mui-custom-form  with-date override-width-23 override-m-08">
            <KeyboardDatePicker
              maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
              id="end-date"
              label="End Date"
              required
              format="MM/dd/yyyy"
              placeholder="mm/dd/yyyy"
              value={selectedEndDate}
              onChange={handleEndDateChange}
              InputLabelProps={{
                shrink: true
              }}
              disabled={disableFields || !userInquiryPrivileges}
              helperText={showBankDetailsErr.showEndDateErr ? bankDetailsErrMsg.endDateErrMsg : null}
              error={showBankDetailsErr.showEndDateErr ? bankDetailsErrMsg.endDateErrMsg : null}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
        {(showEditPage && rowEdit && (bankingDetails.eftStatus === 'A')) && (!newRecordFlag) || disableFields || !userInquiryPrivileges ?  
            <NativeDropDown
              className="override-width-23 override-m-08"
              id="eft-status"
            label="EFT Status"
              isRequired
            disabled
            inputProps={{ maxlength: 2 }}
            value={bankingDetails.eftStatus}
            onChange={handleChangeAddBankPopUp('eftStatus')}
            InputLabelProps={{
              shrink: true
            }}
              dropdownList={props.dropdowns && props.dropdowns['F1#F_EFT_STAT_CD'] && 
             props.dropdowns['F1#F_EFT_STAT_CD'].length > 0
              ? props.dropdowns['F1#F_EFT_STAT_CD'].map(option => (
                <option key={option.code} value={option.code}>
                    {option.description}
                </option>
                ))
              : null}
            />
        :
            <NativeDropDown
              className='override-width-23 override-m-08'
              id="eft-status"
            label="EFT Status"
              isRequired
            inputProps={{ maxlength: 2 }}
            value={bankingDetails.eftStatus}
            onChange={handleChangeAddBankPopUp('eftStatus')}
            InputLabelProps={{
              shrink: true
            }}
              errTxt={showBankDetailsErr.showEFTStatusErr ? bankDetailsErrMsg.eftStatusErrMsg : null}
              dropdownList={EFTStatusDropdown ? EFTStatusDropdown.map(option => (
                <option key={option.code} value={option.code}>
                {option.description}
                </option>
            )) : null}
            />}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="mui-custom-form with-date override-width-23 override-m-08">
            <KeyboardDatePicker
              maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
              id="eft-status-date"
              label="EFT Status Date"
              required
              format="MM/dd/yyyy"
              disabled={disableFields || !userInquiryPrivileges}
              placeholder="mm/dd/yyyy"
              value={selectedEftDate}
              onChange={handleEftDateChange}
              InputLabelProps={{
                shrink: true
              }}
              helperText={showBankDetailsErr.showEFTStatusDateErr ? bankDetailsErrMsg.eftStatusDateErrMsg : null}
              error={showBankDetailsErr.showEFTStatusDateErr ? bankDetailsErrMsg.eftStatusDateErrMsg : null}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </div>
          <div className="mui-custom-form  with-date override-width-23 override-m-08">
            <KeyboardDatePicker
              maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
              id="pre-note"
              label="Pre Note"
              format="MM/dd/yyyy"
              placeholder="mm/dd/yyyy"
              disabled={(showEditPage && rowEdit && (bankingDetails.eftStatus === 'A')) && (!newRecordFlag) || disableFields || !userInquiryPrivileges}
              value={selectedPreNoteDate}
              onChange={handlePreNoteDateChange}
              InputLabelProps={{
                shrink: true
              }}
              helperText={showBankDetailsErr.showPreNoteDateErr ? bankDetailsErrMsg.preNoteDateErrMsg : null}
              error={showBankDetailsErr.showPreNoteDateErr ? bankDetailsErrMsg.preNoteDateErrMsg : null}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
        <div className="mui-custom-form input-md override-width-23 override-m-08">
          <TextField
              id="financial-institution"
              label="Financial Institution"
            required
              inputProps={{ maxlength: 60 }}
              data-testid='Financial_inst_test'
            value={bankingDetails.financialInstitution}
            onChange={handleChangeAddBankPopUp('financialInstitution')}
            InputLabelProps={{
              shrink: true
            }}
            disabled={disableFields || !userInquiryPrivileges}
            helperText={showBankDetailsErr.showFinancInstiErr ? bankDetailsErrMsg.financInstiErrMsg : null}
            error={showBankDetailsErr.showFinancInstiErr ? bankDetailsErrMsg.financInstiErrMsg : null}
          >
          </TextField>
        </div>
          <NativeDropDown
            className='override-width-23 override-m-08'
            id="account-type"
            label="Account Type"
            isRequired
            disabled={(showEditPage && rowEdit && (bankingDetails.eftStatus === 'A')) && (!newRecordFlag) || disableFields || !userInquiryPrivileges}
            inputProps={{ maxlength: 2 }}
            value={bankingDetails.accountType}
            onChange={handleChangeAddBankPopUp('accountType')}
            InputLabelProps={{
              shrink: true
            }}
            errTxt={showBankDetailsErr.showAccTypeErr ? bankDetailsErrMsg.accTypeErrMsg : null}
            dropdownList={AccountTypeDropdown ? AccountTypeDropdown.map(option => (
              <option key={option.code} value={option.code}>
                {option.description}
              </option>
            )) : null}
          />
        <div className="mui-custom-form input-md override-width-23 override-m-08">
          <TextField
            id="routing-number"
            label="Routing Number"
            type="number"
            required
            disabled={(showEditPage && rowEdit && (bankingDetails.eftStatus === 'A')) && (!newRecordFlag) || disableFields || !userInquiryPrivileges}
            inputProps={{ maxlength: 9 }}
            value={bankingDetails.routingNumber}
            onChange={handleChangeAddBankPopUp('routingNumber')}
            InputLabelProps={{
              shrink: true
            }}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9);
            }}
            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
            helperText={showBankDetailsErr.showRoutingNumErr ? bankDetailsErrMsg.routingNumErrMsg : null}
            error={showBankDetailsErr.showRoutingNumErr ? bankDetailsErrMsg.routingNumErrMsg : null}
          >
          </TextField>
        </div>
        <div className="mui-custom-form input-md override-width-23 override-m-08">
          <TextField
            id="branch"
            label="Branch"
            required
              inputProps={{ maxlength: 60 }}
              data-testid='test_branch'
            disabled={disableFields || !userInquiryPrivileges}
            value={bankingDetails.branch}
            onChange={handleChangeAddBankPopUp('branch')}
            InputLabelProps={{
              shrink: true
            }}
            helperText={showBankDetailsErr.showBranchErr ? bankDetailsErrMsg.branchErrMsg : null}
            error={showBankDetailsErr.showBranchErr ? bankDetailsErrMsg.branchErrMsg : null}
          >
          </TextField>
        </div>
        <div className="mui-custom-form input-md override-width-23 override-m-08">
          <TextField
            id="account-number"
            label="Account Number"
            type="number"
            required
            disabled={(showEditPage && rowEdit && (bankingDetails.eftStatus !== 'P')) && (!newRecordFlag) || disableFields || !userInquiryPrivileges}
            inputProps={{ maxlength: 17 }}
            value={bankingDetails.accountNumber}
            onChange={handleChangeAddBankPopUp('accountNumber')}
            InputLabelProps={{
              shrink: true
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 17);
            }}
            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
            helperText={showBankDetailsErr.showACCNumErr ? bankDetailsErrMsg.accNumerrMsg : null}
            error={showBankDetailsErr.showACCNumErr ? bankDetailsErrMsg.accNumerrMsg : null}
          >
          </TextField>
        </div>
        <div className="mui-custom-form input-md override-width-23 override-m-08">
          <TextField
            id="account-holder-name"
            label="Account Holder Name"
            required
              inputProps={{ maxlength: 60 }}
            disabled={disableFields || !userInquiryPrivileges}
            value={bankingDetails.accountHolderName}
              data-testid='account_holder_name_test'
            onChange={handleChangeAddBankPopUp('accountHolderName')}
            InputLabelProps={{
              shrink: true
            }}
            helperText={showBankDetailsErr.showAccHoldNameErr ? bankDetailsErrMsg.accHoldNameErrMsg : null}
            error={showBankDetailsErr.showAccHoldNameErr ? bankDetailsErrMsg.accHoldNameErrMsg : null}
          >
          </TextField>
        </div>
      </div>
    </form>
    </div>
  );
}
