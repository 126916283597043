/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const BULK_TRANFER_HEADER = 'Account Receivable Write-Off Re-Establishment';
export const PAYEE_ID_REQUIRED = 'Payee ID is required.';
export const PAYEE_TYPE_REQ = 'Payee Type is required.';
export const PAYEE_ID_TYPE_CODE = 'Payee ID Type Code is required.';
export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const SAME_ID = 'A Receivable cannot be transferred to the same Payee ID.';
export const SAVED_SUCCESS = 'System Successfully saved information.'
export const TRANSACTION_NUMBER_FORMAT = 'should not contain spaces or special characters.';

