/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as serviceEndpoint from '../../../../SharedModules/services/service';
import axios from 'axios';
import { commonAPIHandler } from '../../../../SharedModules/ServerErrorHandler';
import * as actionTypes from './PaymentCriteriaActionTypes';

export const PaymentCriteriaSearchAction =(value)=>{
    return dispatch =>{
        return axios.post(`${serviceEndpoint.PAYMENT_CRITERIA_SEARCH}`,value)
        .then(response =>{
            let sendingResponse = commonAPIHandler(response);
            if(sendingResponse === null){
                dispatch(searchDetails([]))
            }
            else{
                dispatch(searchDetails(sendingResponse));
            }
        })
        .catch(err =>{
            let sendingResponse = commonAPIHandler(err,true);
            dispatch(searchDetails(sendingResponse));
        });
    };
};

export const paymentCriteriaRowAction = (value) =>{
    return dispatch =>{
        return axios.get(`${serviceEndpoint.PAYMENT_GET_DETAILS}?criteriaId=${value}`)
        .then(response => {
          let sendingResponse = commonAPIHandler(response);
          if (sendingResponse === undefined) {
            dispatch(getRowDetails([]));
          } else {
            dispatch(getRowDetails(sendingResponse));
          }
        })
        .catch(error => {
          let sendingResponse = commonAPIHandler(error);
          dispatch(getRowDetails(sendingResponse));
        });
    };
};


export const findPayeeAction = (value) =>{
  return dispatch =>{
      return axios.post(`${serviceEndpoint.FIND_PAYEE}`,value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(getPayeeData([]));
        } else {
          dispatch(getPayeeData(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(getPayeeData(sendingResponse));
      });
  };
};


export const deletePayeeAction = (value) =>{
  return dispatch =>{
      return axios.delete(`${serviceEndpoint.DELETE_PAYEE}${value}` )
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(deletePayeeData([]));
        } else {
          dispatch(deletePayeeData(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(deletePayeeData(sendingResponse));
      });
  };
};

export const deletePayeeData =(data)=>{
  return{
      type: actionTypes.DELETE_PAYEE,
      deletePayeeData:data
  }
};

//add-update API
export const paymentCriteriaSaveUpdate = (value, file) => {
  const formInfo = new FormData();
  if (file && file.length > 0) {
    file.map((values) => {
      formInfo.append('file', values);
    })
  }
  formInfo.append('paymentRequestVO', JSON.stringify(value));
  return dispatch => {
    return axios.post(`${serviceEndpoint.PAYMENT_CRITERIA_ADD_UPDATE}`, formInfo
      , {
        headers: {
          'content-type': `multipart/form-data; boundary=${formInfo._boundary}`,
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(saveAndUpdateData(sendingResponse));
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(saveAndUpdateData(sendingResponse));
        console.log(error);
      });
  };
};

export const searchDetails =(data)=>{
    return{
        type: actionTypes.PAYMENT_CRITERIA_SEARCH,
        searchResults:data
    }
};

export const getRowDetails =(data)=>{
    return{
        type: actionTypes.PAYMENT_CRITERIA_ROW_DETAILS,
        rowSearchResults:data
    }
};

export const getPayeeData =(data)=>{
    return{
        type: actionTypes.FIND_PAYEE,
        payeeData:data
    }
};

export const resetPaymentCriteriaSearch = () => {
    return {
      type: actionTypes.RESETDATA,
      resetData: []
    };
  };
  export const resetResponse =()=>{
    return {
      type: actionTypes.RESET_RESPONSE,
    }
  }

  export const saveAndUpdateData = (data) =>{
    return {
        type: actionTypes.SAVE_UPDATE_DATA,
        saveUpadteData : data
    }
  }


  //auditLOg
  export const getAuditLogDetailsAction = (tableName, keyValue, auditData) => {
    let body = {
      "tableName": tableName,
      "keyValue": keyValue
    }
    return dispatch => {
      return axios.post(`${serviceEndpoint.AUDIT_LOG_ENDPOINT}`, body)
        .then(response => {
          if (response.data)
            dispatch(setAuditLogData(response.data.responseAuditLogDetails, auditData));
        })
        .catch(error => {
        });
    }
  }
  
  export const setAuditLogData = (data, keyName) => {
    return {
      type: actionTypes.AUDIT_LOG_TABLE,
      payload: data,
      keyName
    };
  };
  
  export const getAuditLogRowDetailsAction = (tableName, keyValue, auditData) => {
    let body = {
      "tableName": tableName,
      "keyValue": keyValue
    }
    return dispatch => {
      return axios.post(`${serviceEndpoint.AUDIT_LOG_ENDPOINT}`, body)
        .then(response => {
          if (response.data)
            dispatch(setAuditLogRowData(response.data.responseAuditLogDetails, auditData));
        })
        .catch(error => {
        });
    }
  }

  export const setAuditLogRowData = (data, keyName) => {
    return {
      type: actionTypes.AUDIT_LOG_TABLE_ROW,
      payload: data,
      keyName
    };
  };