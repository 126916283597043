/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/PaymentCriteriaActionTypes';

const reducer =(state=[],action)=>{
    switch(action.type){
        case actionTypes.PAYMENT_CRITERIA_SEARCH:
            return {...state,paymentCriteriaSearchResult: action.searchResults }
        case actionTypes.PAYMENT_CRITERIA_ROW_DETAILS:
            return{...state,rowSearchResults: action.rowSearchResults}
        case actionTypes.FIND_PAYEE:
            return{...state,payeeData: action.payeeData}
        case actionTypes.DELETE_PAYEE:
            return{...state,deletePayeeData: action.deletePayeeData}
        case actionTypes.SAVE_UPDATE_DATA:
            return{...state,saveUpadteData: action.saveUpadteData}
        case actionTypes.RESETDATA:
            return action.resetData;
            case actionTypes.AUDIT_LOG_TABLE:
                return { auditDetails: action.payload }
              case actionTypes.AUDIT_LOG_TABLE_ROW:
                return { auditRowDetails: action.payload }
        case actionTypes.RESET_RESPONSE:
            return {...state, searchResults: null}  
            default: return state;
        }
}

export default reducer;