/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const ACCOUNT_PAYABLE_APPROVAL_FCN_SEARCH = 'ACCOUNT_PAYABLE_APPROVAL_FCN_SEARCH';
export const ACCOUNT_PAYABLE_APPROVAL_PAYEE_SEARCH = 'ACCOUNT_PAYABLE_APPROVAL_PAYEE_SEARCH';
export const RESETDATA = 'RESETDATA';
export const GETPAYEEIDTYPE = 'GETPAYEEIDTYPE';
export const DROPDOWNDATA = 'DROPDOWNDATA';
export const UPDATE_PAYOUT_APPROVAL_FCN = 'UPDATE_PAYOUT_APPROVAL_FCN';
export const UPDATE_PAYOUT_APPROVAL_PAYEE = 'UPDATE_PAYOUT_APPROVAL_PAYEE';
