/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const WVRLICD = 'Waiver Line Code is required.';
export const CMS64CD = 'CMS64 TOS Code is required.';
export const WVRLICDDESC = 'Wavier Line Code Description is required.';
export const PRIORITY_NUMB = 'Priority number is required.';
export const WAIVERNAME = 'Waiver Name is required.';
export const CMS64CDDESC = 'CMS64 TOS Code Description is required.';
export const BGNWVRCD = 'Begin Waiver Code is required.';
export const ENDWVRCD = 'End Waiver Code is required.';
export const ENDWVRCDINVALID = 'Begin Waiver Code must be greater than or equal to End Waiver Code.';
export const ENDPRCCDINVALID = 'Begin Procedure Code must be greater than or equal to End Procedure Code.';
export const BGNPROCCD = 'Begin Procedure Code is required.';
export const ENDPROCCD = 'End Procedure Code is required.';
export const BGNDTREQ = 'Begin Date is required.';
export const ENDDTREQ = 'End Date is required.';
export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const NO_RECORDS_FOUND = 'No records found for the search criteria entered.';
export const INVALID_DATE = 'Please Enter the date in correct format.';
export const END_DATE_GREATER_THAN_BG_DT = 'The End Date must be greater than or equal to the Begin Date.'

