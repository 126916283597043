/* eslint-disable no-unused-vars */
/**

* © [2024] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";

function SVGComponent({
  imageName: image,
  height,
  width,
  fillColor,
  classNames
}) {
  const svgFunction = () => {
    switch (image) {
      case "Home":
        return (
          <svg
            width="20"
            height="17"
            viewBox="0 0 20 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 17V11H12V17H17V9H20L10 0L0 9H3V17H8Z"
              fill={fillColor || "#aaafb9"}
            />
          </svg>
        );
        break;
      case "CreateClaims":
        return (
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 15.2501V19.0001H3.75L14.81 7.94006L11.06 4.19006L0 15.2501ZM17.71 5.04006C18.1 4.65006 18.1 4.02006 17.71 3.63006L15.37 1.29006C14.98 0.900059 14.35 0.900059 13.96 1.29006L12.13 3.12006L15.88 6.87006L17.71 5.04006Z"
              fill={fillColor || "#aaafb9"}
            />
          </svg>
        );
        break;
      case "ManageClaims":
        return (
          <svg
            width="21"
            height="14"
            viewBox="0 0 21 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 4H0V6H12V4ZM12 0H0V2H12V0ZM0 10H8V8H0V10ZM19.5 5.5L21 7L14.01 14L9.5 9.5L11 8L14.01 11L19.5 5.5Z"
              fill={fillColor || "#aaafb9"}
            />
          </svg>
        );
        break;
      case "CreateTemplate":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM16 16H13V19H11V16H8V14H11V11H13V14H16V16ZM13 9V3.5L18.5 9H13Z"
              fill={fillColor || "#aaafb9"}
            />
          </svg>
        );
        break;
      case "ManageTemplate":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM15 5L21 11V21C21 22.1 20.1 23 19 23H7.99C6.89 23 6 22.1 6 21L6.01 7C6.01 5.9 6.9 5 8 5H15ZM14 12H19.5L14 6.5V12Z"
              fill={fillColor || "#aaafb9"}
            />
          </svg>
        );
        break;
      case "StatusInquiry":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23 8C23 9.1 22.1 10 21 10C20.82 10 20.65 9.98 20.49 9.93L16.93 13.48C16.98 13.64 17 13.82 17 14C17 15.1 16.1 16 15 16C13.9 16 13 15.1 13 14C13 13.82 13.02 13.64 13.07 13.48L10.52 10.93C10.36 10.98 10.18 11 10 11C9.82 11 9.64 10.98 9.48 10.93L4.93 15.49C4.98 15.65 5 15.82 5 16C5 17.1 4.1 18 3 18C1.9 18 1 17.1 1 16C1 14.9 1.9 14 3 14C3.18 14 3.35 14.02 3.51 14.07L8.07 9.52C8.02 9.36 8 9.18 8 9C8 7.9 8.9 7 10 7C11.1 7 12 7.9 12 9C12 9.18 11.98 9.36 11.93 9.52L14.48 12.07C14.64 12.02 14.82 12 15 12C15.18 12 15.36 12.02 15.52 12.07L19.07 8.51C19.02 8.35 19 8.18 19 8C19 6.9 19.9 6 21 6C22.1 6 23 6.9 23 8Z"
              fill={fillColor || "#aaafb9"}
            />
          </svg>
        );
        break;
      case "PaymentInquiry":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 18H4V12H20V18ZM20 8H4V6H20V8Z"
              fill={fillColor || "#aaafb9"}
            />
          </svg>
        );
        break;
      case "fa-users":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM12 7C13.66 7 15 8.34 15 10C15 11.66 13.66 13 12 13C10.34 13 9 11.66 9 10C9 8.34 10.34 7 12 7ZM18 19H6V17.6C6 15.6 10 14.5 12 14.5C14 14.5 18 15.6 18 17.6V19Z"
              fill={fillColor || "#aaafb9"}
            />
          </svg>
        );
        break;
      case "AuthorizationsRequest":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1ZM10 17L6 13L7.41 11.59L10 14.17L16.59 7.58L18 9L10 17Z"
              fill={fillColor || "#aaafb9"}
            />
          </svg>
        );
        break;
      case "SubmitAuthorizations":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.0002 6.99984L16.5902 5.58984L10.2502 11.9298L11.6602 13.3398L18.0002 6.99984ZM22.2402 5.58984L11.6602 16.1698L7.48016 11.9998L6.07016 13.4098L11.6602 18.9998L23.6602 6.99984L22.2402 5.58984ZM0.410156 13.4098L6.00016 18.9998L7.41016 17.5898L1.83016 11.9998L0.410156 13.4098Z"
              fill={fillColor || "#aaafb9"}
            />
          </svg>
        );
        break;
      case "EDI":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.01 14H2V16H9.01V19L13 15L9.01 11V14ZM14.99 13V10H22V8H14.99V5L11 9L14.99 13Z"
              fill={fillColor || "#aaafb9"}
            />
          </svg>
        );
        break;
      case "fa-cog":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5ZM12 19.2C9.5 19.2 7.29 17.92 6 15.98C6.03 13.99 10 12.9 12 12.9C13.99 12.9 17.97 13.99 18 15.98C16.71 17.92 14.5 19.2 12 19.2Z"
              fill={fillColor || "#aaafb9"}
            />
          </svg>
        );
        break;
      case "ManageUsers":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 11C17.66 11 18.99 9.66 18.99 8C18.99 6.34 17.66 5 16 5C14.34 5 13 6.34 13 8C13 9.66 14.34 11 16 11ZM8 11C9.66 11 10.99 9.66 10.99 8C10.99 6.34 9.66 5 8 5C6.34 5 5 6.34 5 8C5 9.66 6.34 11 8 11ZM8 13C5.67 13 1 14.17 1 16.5V19H15V16.5C15 14.17 10.33 13 8 13ZM16 13C15.71 13 15.38 13.02 15.03 13.05C16.19 13.89 17 15.02 17 16.5V19H23V16.5C23 14.17 18.33 13 16 13Z"
              fill={fillColor || "#aaafb9"}
            />
          </svg>
        );
        break;
      case "ContactUs":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.01 15.38C18.78 15.38 17.59 15.18 16.48 14.82C16.13 14.7 15.74 14.79 15.47 15.06L13.9 17.03C11.07 15.68 8.42 13.13 7.01 10.2L8.96 8.54C9.23 8.26 9.31 7.87 9.2 7.52C8.83 6.41 8.64 5.22 8.64 3.99C8.64 3.45 8.19 3 7.65 3H4.19C3.65 3 3 3.24 3 3.99C3 13.28 10.73 21 20.01 21C20.72 21 21 20.37 21 19.82V16.37C21 15.83 20.55 15.38 20.01 15.38Z"
              fill={fillColor || "#aaafb9"}
            />
          </svg>
        );
        break;
      case "Help":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 18H13V16H11V18ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 6C9.79 6 8 7.79 8 10H10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 12 11 11.75 11 15H13C13 12.75 16 12.5 16 10C16 7.79 14.21 6 12 6Z"
              fill={fillColor || "#aaafb9"}
            />
          </svg>
        );
      case "close":
        return (
          <svg
            width={width || "24px"}
            height={height || "24px"}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill={fillColor || "black"}
            />
          </svg>
        );
        break;
      case "Menu":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z"
              fill={fillColor || "#aaafb9"}
            />
          </svg>
        );
        break;
      case "fa-lock":
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 440 512"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
              fill={fillColor || "#aaafb9"}
            />
          </svg>
        );
        break;
      case "delete":
        return (
          <svg
            width={width || "24px"}
            height={height || "24px"}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
              fill={fillColor || "black"}
            />
          </svg>
        );
      case "total":
        return (
          <svg
            width={width || "24px"}
            height={height || "24px"}
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.99 16.54L1.62 10.81L0 12.07L9 19.07L18 12.07L16.37 10.8L8.99 16.54ZM9 14L16.36 8.27L18 7L9 0L0 7L1.63 8.27L9 14Z"
              fill={fillColor || "black"}
            />
          </svg>
        );
      case "read":
        return (
          <svg
            width={width || "24px"}
            height={height || "24px"}
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.99 7C19.99 6.28 19.62 5.65 19.05 5.3L10 0L0.95 5.3C0.38 5.65 0 6.28 0 7V17C0 18.1 0.9 19 2 19H18C19.1 19 20 18.1 20 17L19.99 7ZM10 12L1.74 6.84L10 2L18.26 6.84L10 12Z"
              fill={fillColor || "black"}
            />
          </svg>
        );
      case "unread":
        return (
          <svg
            width={width || "24px"}
            height={height || "24px"}
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 4L10 9L2 4V2L10 7L18 2V4Z"
              fill={fillColor || "black"}
            />
          </svg>
        );
      case "pdfSymbol":
        return (
          <svg
            width="30"
            height="30"
            viewBox="0 0 36 30"
            fill="#860505"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="30" height="30" rx="4" fill="#860505"/>
            <path d="M19.5817 12.1746C18.0902 10.5889 13.9648 11.234 12.9972 11.3952C11.5728 10.0111 10.5785 8.37171 10.2425 7.76702C10.78 6.23511 11.1294 4.60915 11.1832 3.01006C11.1832 1.55878 10.6053 0 8.9928 0C8.42841 0.0134377 7.91778 0.322506 7.63559 0.792827C6.95026 1.97535 7.21902 4.42102 8.32091 6.89356C7.6759 8.70766 6.74869 11.328 5.52586 13.4512C3.87302 14.1096 0.365766 15.7222 0.0298223 17.5094C-0.0776796 18.0335 0.110449 18.571 0.513581 18.9338C0.903276 19.2832 1.41391 19.4579 1.93798 19.4444C4.04771 19.4444 6.15743 16.515 7.58183 14.0021C8.77779 13.5856 10.686 13.0346 12.6076 12.6852C14.8382 14.6337 16.827 14.9293 17.8348 14.9293C19.2055 14.9293 19.7161 14.3381 19.9042 13.8409C20.1058 13.2765 19.9849 12.6315 19.5817 12.1746ZM18.1305 13.1959C18.0633 13.5856 17.5661 13.9753 16.6389 13.7737C15.5639 13.5049 14.556 12.9943 13.7094 12.2955C14.4351 12.1746 16.1282 12.0133 17.3242 12.2418C17.7542 12.3224 18.2514 12.5643 18.1305 13.1959ZM8.50904 1.30346C8.6031 1.12877 8.77779 1.00783 8.97936 1.00783C9.49 1.00783 9.5975 1.62597 9.5975 2.1366C9.53031 3.35944 9.26155 4.60915 8.92561 5.72448C8.06559 3.44006 8.18653 1.85441 8.50904 1.30346ZM8.3881 12.4702C8.84498 11.5699 9.49 9.93049 9.71844 9.27204C10.2291 10.1186 11.0891 11.1802 11.546 11.664C11.5594 11.6505 9.79906 12.0133 8.3881 12.4702ZM5.01523 14.7815C3.65801 16.9584 2.32768 18.3291 1.54829 18.3291C1.42735 18.3291 1.29297 18.2888 1.19891 18.2081C1.05109 18.0872 0.983902 17.8991 1.02422 17.7109C1.17203 16.9316 2.71737 15.8028 5.01523 14.7815Z" fill="white"/>
          </svg>
        );
        case "pdfBox":
        return (
          <svg width="30" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="30" height="30" rx="4" fill="url(#paint0_linear)"/>
          <defs>
          <linearGradient id="paint0_linear" x1="18" y1="0" x2="18" y2="30" gradientUnits="userSpaceOnUse">
          <stop stop-color="#005A64"/>
          <stop offset="0.9999" stop-color="#00A89D"/>
          <stop offset="1" stop-color="#C4C4C4" stop-opacity="0"/>
          </linearGradient>
          </defs>
          <path d="M18 0H6C4.9 0 4 0.9 4 2V14C4 15.1 4.9 16 6 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM9.5 7.5C9.5 8.33 8.83 9 8 9H7V11H5.5V5H8C8.83 5 9.5 5.67 9.5 6.5V7.5ZM14.5 9.5C14.5 10.33 13.83 11 13 11H10.5V5H13C13.83 5 14.5 5.67 14.5 6.5V9.5ZM18.5 6.5H17V7.5H18.5V9H17V11H15.5V5H18.5V6.5ZM7 7.5H8V6.5H7V7.5ZM2 4H0V18C0 19.1 0.9 20 2 20H16V18H2V4ZM12 9.5H13V6.5H12V9.5Z" fill="white"/>
          </svg>
        );
      case "polygon5":
        return(
          <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 4L0 7.4641L0 0.535898L6 4Z" fill="#FF8700"/>
          </svg>
        )
      default:
//        null;
    }
  };

  return (
    <div className={`svg-container ${classNames || ""}`}>{svgFunction()}</div>
  );
}

export default SVGComponent;
