/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import TableComponent from "../Table/Table";
import { DialogTitle, DialogContent } from "../Dialog/DialogUtilities";
import AddAttachment from "./AddAttachment";
import { downloadFileAction } from "../store/Actions/SharedAction";
import { useDispatch, useSelector } from "react-redux";
import AttachmentsLobCriteria from "./AttachementsDDInput.json";
import * as Errorconstants from "./AttachmentsConstants";
import { generateUUID } from "../DateUtilities/DateUtilities";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { areIntervalsOverlapping } from "date-fns";
const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 600,
    maxWidth: 600,
    minHeight: 900,
    maxHeight: 900
  }
}));
export default function Attachments(props) {
  const classes = useStyles();
  const inputRef = React.useRef();
  const anchorRef = React.useRef();
  const dispatch = useDispatch();
  const downloadeFile = useSelector(state => state.sharedState.downloadeFile);
  const {
    attachmentFile,
    setAttachmentFile,
    attachmentsTableData,
    setAttachmentsTableData,
    parentErrorMesage,
    userInquiryPrivileges,
    setAllowNavigation,
    setSystemSuccesMessages,
    disableAttachments,
    setFocusCheck,
    values
  } = props;
  const headCells = [
    {
      id: "addedAuditTimeStamp",
      numeric: false,
      disablePadding: true,
      label: "Date Added",
      isDateTimeWithLink: true,
      width: "20%"
    },
    {
      id: "addedBy",
      numeric: false,
      disablePadding: false,
      label: "Added By",
      width: "20%"
    },
    {
      id: "fileName",
      numeric: false,
      disablePadding: false,
      label: "File Name",
      width: "35%"
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
      width: "25%"
    }
  ];
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [preview, setPreview] = React.useState();
  const [
    attachmentTableSelectedRows,
    setAttachmentTableSelectedRows
  ] = React.useState([]);
  const [attachmentRowEditFlag, setAttachmentRowEditFlag] = React.useState(
    false
  );
  const [selectedAttachmentRow, setSelectedAttachmentRow] = React.useState(
    null
  );
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const [addAttachmentDetails, setAddAttachmentDetails] = React.useState({
    dateAdded: moment(new Date()).format("MM/DD/YYYY HH:mm:ss"),
    addedAuditTimeStamp: moment(new Date()).format("MM/DD/YYYY HH:mm:ss"),
    addedBy: logInUserID,
    fileName: "",
    description: "",
    isAddedRow: true
  });
  const [showAttachmentErr, setShowAttachmentErr] = React.useState({
    showFileNameErr: false,
    showDescriptionErr: false
  });
  const [attachmentErrMsg, setAttachmentErrMsg] = React.useState({
    fileNameErrMsg: "",
    descriptionErrMsg: ""
  });
  useEffect(() => {
    if (
      downloadeFile &&
      !downloadeFile.systemFailure &&
      selectedAttachmentRow
    ) {
      anchorRef.current.href = downloadeFile;
      anchorRef.current.download = selectedAttachmentRow.fileName;
      anchorRef.current.click();
    }
    if (downloadeFile && downloadeFile.systemFailure) {
      const temerr = [];
      temerr.push(Errorconstants.ERROR_IN_DOWNLOADING_FILE);
      seterrorMessages(temerr);
      parentErrorMesage(temerr);
    }
  }, [downloadeFile]);
  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    // window.webkitURL works in Chrome and window.URL works in Firefox
    var myURL = window.URL || window.webkitURL;
    const objectUrl = myURL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => myURL.revokeObjectURL(objectUrl);
  }, [selectedFile]);
  const handleChangeAttachment = name => event => {
    setFocusCheck(true);
    setAddAttachmentDetails({
      ...addAttachmentDetails,
      [name]: event.target.value
    });
  };
  // Handling Attachments Details PopUp
  const [openAttachmentPopUp, setOpenAttachmentPopUp] = React.useState(false);
  useEffect(() => {
    if (openAttachmentPopUp) {
      document.getElementById("attachment-div").scrollIntoView({
        behavior: "smooth"
      });
    }
  }, [openAttachmentPopUp]);
  const handleClose = () => {
    if (parentErrorMesage) {
      parentErrorMesage([]);
    }
    setAttachmentRowEditFlag(false);
    setSelectedAttachmentRow(null);
    setOpenAttachmentPopUp(false);
  };
  const handleOpen = () => {
    setSystemSuccesMessages();
    setSelectedFile(null);
    setAddAttachmentDetails({
      dateAdded: moment(new Date()).format("MM/DD/YYYY HH:mm:ss"),
      addedAuditTimeStamp: moment(new Date()).format("MM/DD/YYYY HH:mm:ss"),
      addedBy: logInUserID,
      fileName: "",
      description: "",
      isAddedRow: true
    });
    setOpenAttachmentPopUp(true);
    parentErrorMesage([]);
    seterrorMessages([]);
    setShowAttachmentErr({
      showFileNameErr: false,
      showDescriptionErr: false
    });
    setAttachmentErrMsg({
      fileNameErrMsg: "",
      descriptionErrMsg: ""
    });
    // }
  };
  //comment for DF 311103 fix const [showHeaderAttachment,setshowHeaderAttachment]=useState(false)
  const attachmentsSelectedRows = selectedRows => {
    setAttachmentTableSelectedRows([...selectedRows]);
  };
  const editAttachment = row => event => {
    setSelectedAttachmentRow({ ...row });
    setAddAttachmentDetails({
      dateAdded: row.dateAdded,
      addedAuditTimeStamp: moment(row.addedAuditTimeStamp).format(
        "MM/DD/YYYY HH:mm:ss"
      ),
      addedBy: row.addedBy,
      fileName: row.fileName,
      description: row.description,
      fileObject: row.fileObject,
      isAddedRow: !!row.id
    });
    setAttachmentRowEditFlag(true);
    setOpenAttachmentPopUp(true);
    seterrorMessages([]);
    parentErrorMesage([]);
    setShowAttachmentErr({
      showFileNameErr: false,
      showDescriptionErr: false
    });
    setAttachmentErrMsg({
      fileNameErrMsg: "",
      descriptionErrMsg: ""
    });
  };
  const attachmentsTableRowDeletEvent = () => {
    let tempAttachments = [...attachmentsTableData];
    let tempFileNames = [];
    if (attachmentTableSelectedRows && attachmentTableSelectedRows.length > 0) {
      attachmentTableSelectedRows.map((selectedRowId, index) => {
        if (selectedRowId !== undefined) {
          tempAttachments = tempAttachments.filter(
            tempRow => tempRow.id !== selectedRowId
          );
        }
      });
      tempAttachments.map(value => {
        if (value.fileObject) {
          tempFileNames.push(value.fileObject);
        }
      });
      setAttachmentFile(tempFileNames);
      setSelectedFile(null);
      parentErrorMesage([]);
    }
    setAttachmentsTableData(tempAttachments);
  //comment for DF 311103 fix  if (setAllowNavigation) {
  //comment for DF 311103 fix    setAllowNavigation(true);
  //comment for DF 311103 fix  }
  };
  useEffect(() => {
    if (attachmentsTableData/* //comment for DF 311103 fix &&attachmentsTableData.length>0 */) {
      attachmentsTableData.map(data => {
        if (data.addedAuditTimeStamp) {
          data.addedAuditTimeStamp = moment(data.addedAuditTimeStamp).format(
            "MM/DD/YYYY HH:mm:ss"
          );
        }
  //comment for DF 311103 fix      if(!data.id){
  //comment for DF 311103 fix        data.id=generateUUID();
  //comment for DF 311103 fix      }
      });
//comment for DF 311103 fix setshowHeaderAttachment(true)

    }
  }, [attachmentsTableData]);
  const validateAttachmentDetails = () => {
    let _ipValid = true;
    let _fileErr, _desErr, _wulErr, _docTypErr;
    _fileErr = _desErr = _wulErr = _docTypErr = false;
    let _fileErrMSg, _desErrMsg, _wulErrMsg, _docTypeErrMsg;
    _fileErrMSg = _desErrMsg = _wulErrMsg = _docTypeErrMsg = "";
    const tempErrArr = [];

    if (selectedFile === null) {
      _ipValid = false;
      _fileErr = true;
      _fileErrMSg = Errorconstants.FILE_IS_REQUIRED;
      tempErrArr.push(_fileErrMSg);
    }
    if (
      addAttachmentDetails.description === "" ||
      addAttachmentDetails.description === null ||
      addAttachmentDetails.description === undefined
    ) {
      _ipValid = false;
      _desErr = true;
      _desErrMsg = Errorconstants.DESCRIPTION_IS_REQUIRED;
      tempErrArr.push(_desErrMsg);
    }
    setShowAttachmentErr({
      showFileNameErr: _fileErr,
      showDescriptionErr: _desErr
    });
    setAttachmentErrMsg({
      fileNameErrMsg: _fileErrMSg,
      descriptionErrMsg: _desErrMsg
    });

    if (parentErrorMesage) {
      parentErrorMesage(tempErrArr);
    }
    return _ipValid;
  };
  const saveAttachment = () => {
    if (attachmentRowEditFlag) {
      if (validateAttachmentDetails()) {
        const tempAttachments = [...attachmentsTableData];
        const index = tempAttachments.length - 1;
        tempAttachments.map(value => {
          if (value.id === selectedAttachmentRow.id) {
            value.description = addAttachmentDetails.description
              ? addAttachmentDetails.description.trim()
              : addAttachmentDetails.description;
          }
        });

        setAttachmentsTableData(tempAttachments);
        if (setAllowNavigation) {
          setAllowNavigation(true);
        }
        handleClose();
      }
    } else {
      if (validateAttachmentDetails()) {
        const tempAttachments = [...attachmentsTableData];

        tempAttachments.push({
          ...addAttachmentDetails,
          id: generateUUID()
        });
        tempAttachments.map(value => {
          if (value.description) {
            value.description = value.description.trim();
          }
        });

        setAttachmentsTableData(tempAttachments);
        if (setSystemSuccesMessages) {
          setSystemSuccesMessages();
        }
        if (setAllowNavigation) {
          setAllowNavigation(true);
        }
        handleClose();
      }
    }
  };
  const resetAttachment = () => {
    if (parentErrorMesage) {
      parentErrorMesage([]);
    }
    seterrorMessages([]);
    parentErrorMesage([]);
    if (selectedAttachmentRow) {
      setAddAttachmentDetails({
        ...addAttachmentDetails,
        description: selectedAttachmentRow.description
      });
    } else {
      setSelectedFile(null);
      inputRef.current.value = "";
      let tempAttachList = attachmentFile;
      tempAttachList.filter(
        value => value.name !== addAttachmentDetails.fileName
      );
      setAttachmentFile(tempAttachList);
      setAddAttachmentDetails({
        ...addAttachmentDetails,
        dateAdded: moment(new Date()).format("MM/DD/YYYY HH:mm:ss"),
        addedAuditTimeStamp: moment(new Date()).format("MM/DD/YYYY HH:mm:ss"),
        addedBy: logInUserID,
        fileName: "",
        description: ""
      });
    }
    setShowAttachmentErr({
      showFileNameErr: false,
      showDescriptionErr: false
    });
    setAttachmentErrMsg({
      fileNameErrMsg: "",
      descriptionErrMsg: ""
    });
  };
  const maxSelectFile = event => {
    const files = event.target.files;
    if (files.length > 1) {
      const msg = Errorconstants.ONLY_ONE_FILE_SELECT;
      event.target.value = null;
      const temErr = [];
      temErr.push(msg);
      seterrorMessages(temErr);
      parentErrorMesage(temErr);
      return false;
    }
    return true;
  };
  const checkMimeType = event => {
    // getting file object
    const files = event.target.files;
    // define message container
    const err = [];
    // list allow mime type
    const types = process.env.REACT_APP_FILE_TYPE.split(' ');
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every(type => files[x].type !== type)) {
        // create error message and assign to container
        err[x] = Errorconstants.INVALID_FILE_TYPE;
      }
    }
    const temErr = [];
    for (var z = 0; z < err.length; z++) {
      event.target.value = null;
      temErr.push(err[z]);
    }
    if (temErr.length > 0) {
      seterrorMessages(temErr);
      parentErrorMesage(temErr);
      return false;
    }
    return true;
  };
  const checkFileSize = event => {
    const files = event.target.files;
    const size = process.env.REACT_APP_FILE_SIZE;
    const err = [];
    for (var x = 0; x < files.length; x++) {
      if (files[x].size === 0) {
        err[x] = Errorconstants.ZERO_MB_FILE;
      }
      if (files[x].size > size) {
        err[x] = Errorconstants.MAX_FILE_SIZE_EXCEEDED;
      }
    }
    const tempErr = [];
    for (var z = 0; z < err.length; z++) {
      tempErr.push(err[z]);
      event.target.value = null;
    }
    if (tempErr.length > 0) {
      seterrorMessages(tempErr);
      parentErrorMesage(tempErr);
      return false;
    }
    return true;
  };
  const onChangeChooseFile = event => {
    setFocusCheck(true);
    if (maxSelectFile(event) && checkMimeType(event) && checkFileSize(event)) {
      const files = event.target.files[0];
      if (files) {
        setSelectedFile(files);
        attachmentFile.push(files);
        setAddAttachmentDetails({
          ...addAttachmentDetails,
          fileName: files.name,
          fileObject: files
        });
        seterrorMessages([]);
        parentErrorMesage([]);
      }
    } else {
      inputRef.current.value = "";
    }
  };
  const downloaFileClick = () => {
    seterrorMessages([]);
    parentErrorMesage([]);
    dispatch(
      downloadFileAction(
        selectedAttachmentRow.attachmentPageID,
        selectedAttachmentRow.fileName
      )
    );
  };
  const addedFileDownload = (fileName, url) => {
    anchorRef.current.href = url;
    anchorRef.current.download = fileName;
    anchorRef.current.click();
  };
  return (
    <div className="tab-holder p-0">
      <a ref={anchorRef}></a>
      <div>
        <div className="float-right mr-3 mt-3">
          <Button
            className="btn-text-main-delete btn-transparent ml-1"
            onClick={attachmentsTableRowDeletEvent}
            disabled={!userInquiryPrivileges || disableAttachments || values?.hasBeenProcessed === 'Y'}
          >
            <i class="fa fa-trash" aria-hidden="true"></i>Delete
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className="btn-text-main-add btn-success ml-1"
            disabled={!userInquiryPrivileges || disableAttachments || values?.hasBeenProcessed === 'Y'}
            onClick={handleOpen}
          >
            <i
              class="fa fa-plus"
              aria-hidden="true"
              title={"Add Attachment"}
            ></i>
            add
          </Button>
        </div>
        <div className="clearfix"></div>
      </div>
      <div className="tab-holder table-no-wrap my-3">
        <TableComponent
          fixedTable
          isSearch={false}
  //comment for DF 311103 fix        showHeaderAttachment={showHeaderAttachment}
          headCells={headCells}
          tableData={attachmentsTableData}
          onTableRowClick={editAttachment}
          onTableRowDelete={attachmentsSelectedRows}
          defaultSortColumnDesc={true}
          defaultSortColumn={"addedAuditTimeStamp"}
        />
      </div>
      {openAttachmentPopUp ? (
        <div className="tabs-container" id="attachment-div">
          <div className="tab-header  mini-tab-header">
            <div className="tab-heading float-left">
              {attachmentRowEditFlag ? "Edit Attachment" : "Add Attachment"}
            </div>
            <div className="float-right mt-1">
              <Button
                variant="outlined"
                color="primary"
                className="btn btn-success ml-1"
                onClick={saveAttachment}
                disabled={
                  !userInquiryPrivileges
                    ? !userInquiryPrivileges
                    : !addAttachmentDetails.isAddedRow
                }
                title={attachmentRowEditFlag ? "Update" : "Add"}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
                {attachmentRowEditFlag ? "Update" : "Add"}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className="bt-reset btn-transparent  ml-1"
                onClick={resetAttachment}
                disabled={
                  !userInquiryPrivileges
                    ? !userInquiryPrivileges
                    : !addAttachmentDetails.isAddedRow
                }
              >
                <i className="fa fa-undo" aria-hidden="true"></i>
                Reset
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className="btn btn-primary btn btn-outlined ml-1"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>

          <AddAttachment
            selectedFile={selectedFile}
            preview={preview}
            attachmentRowEditFlag={attachmentRowEditFlag}
            addAttachmentDetails={addAttachmentDetails}
            handleChangeAttachment={handleChangeAttachment}
            showAttachmentErr={showAttachmentErr}
            attachmentErrMsg={attachmentErrMsg}
            saveAttachment={saveAttachment}
            resetAttachment={resetAttachment}
            onChangeChooseFile={onChangeChooseFile}
            errorMessages={errorMessages}
            userInquiryPrivileges={userInquiryPrivileges}
            inputRef={inputRef}
            downloaFileClick={downloaFileClick}
            addedFileDownload={addedFileDownload}
          />
        </div>
      ) : null}
    </div>
  );
}
