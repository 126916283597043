/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './ActionTypes';

const reducer = (state = [], action) => {
    switch (action.type) {
        case actionTypes.SUB_DROPDOWNDATA:
            return { ...state, subdropdownData: action.subdropdownData };
        case actionTypes.CREATE_FRC:
            return { ...state, createPayload: action.createPayload };
        case actionTypes.UPDATE_FRC:
            return { ...state, updatePayload: action.updatePayload };
        case actionTypes.SEARCH_FRC:
            return { ...state, searchPayload: action.searchPayload };
        case actionTypes.RESET_FRC:
            return { ...state, [action.keyName]: null };
        default: return state;
    }
};

export default reducer;
