/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from "@material-ui/core/TextField";
import { Button, Form } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import SystemParameterAddTable from "./SystemParameterAddTable";
import DateFnsUtils from "@date-io/date-fns";
import numeral from "numeral";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import * as SystemParameterAddConstants from "./systemParameterEditConstants";
import {
  updateSystemParameter,
  systemParameterRowClickAction
} from "../../Store/Actions/systemParameter/systemParameterActions";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../../SharedModules/TabPanel/TabPanel";
import AppBar from "@material-ui/core/AppBar";
import { Prompt } from "react-router-dom";
import NoSaveMessage from "../../../../SharedModules/Errors/NoSaveMessage";
import { AppConfigDropdownActions } from "../../Store/Actions/AppConfigCommon/AppConfigActions";

import * as DateUtils from "../../../../SharedModules/DateUtilities/DateUtilities";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import {
  DialogContent,
  DialogActions
} from "../../../../SharedModules/Dialog/DialogUtilities";
import ErrorMessage from "../../../../SharedModules/Errors/ErrorMessages";
import SuccessMessage from "../../../../SharedModules/Errors/SuccessMessage";
import moment from "moment";
import dropdownCriteria from "./SystemParameterAddUpdate.json";
import * as AppConstants from "../../../../SharedModules/AppConstants";
import Notes from "../../../../SharedModules/Notes/Notes";
import {
  getAuditLogDetailsAction
} from "../../../../SharedModules/store/Actions/SharedAction";
import Footer from "../../../../SharedModules/Layout/footer";
import NavHOC from "../../../../SharedModules/Navigation/NavHOC";
import Radio from "@material-ui/core/Radio";

import Table from "../../../../SharedModules/Table/Table";
import { headCells } from "./AuditLogConstants";
import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from "../../../../SharedModules/Dropdowns/NativeDropDown";
import { checkingDecimal } from "../../../../SharedModules/CommonUtilities/commonUtilities";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  }
}));

export default NavHOC({
  Wrapped: SysyemParameterAdd,
  url: "/SystemParametersEdit",
  action: systemParameterRowClickAction,
  selector: "appConfigState.systemParameterState.rowSearchSysParam"
});

function SysyemParameterAdd (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const toPrintRef = useRef();
  let paramsData;
  let editHeadCellsData = [];
  let errorMessagesArray = [];
  let successMessagesArray = [];
  const [tableRecord, settableRecord] = React.useState(
    props.location.state.payloadData.systemParameterDetail
      ? props.location.state.payloadData.systemParameterDetail
      : []
  );
  const dispatch = useDispatch();
  const [showForm, setShowForm] = React.useState(true);
  const classes = useStyles();
  const [tableData, setTableData] = React.useState(
    props.location.state.payloadData.systemParameterDetail
      ? props.location.state.payloadData.systemParameterDetail
      : []
  );

  const [close, setClose] = React.useState(true);
  const [showLogTable, setShowLogTable] = React.useState(false);

  useEffect(() => {
    if (tableData.length > 0) {
      tableData.map(var1 => {
        if (var1.valueAmt) {
          var1.valueAmt = numeral(
            Number(var1.valueAmt.replace(/,/g, "")).toFixed(4)
          ).format("0,0.0000");
        }
        if (var1.beginDate) {
          var1.beginDate = moment(var1.beginDate).format("YYYY-MM-DD");
        }
        if (var1.endDate) {
          var1.endDate = moment(var1.endDate).format("YYYY-MM-DD");
        }
        if (var1.voidDate && typeof var1.voidDate === "string") {
          const voidDate = var1.voidDate.split(" ");
          var1.voidDate = voidDate[0];
        }
      });
    }
  }, [tableData]);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedAmt, setSelectedAmt] = React.useState("");
  const [selectedNum, setSelectedNum] = React.useState("");
  const [selectedPCT, setSelectedPCT] = React.useState("");
  const [selectedData, setSelectedData] = React.useState("");
  const [selectedDateFormat, setSelectedDateFormat] = React.useState(null);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [addEdit, setAddEdit] = React.useState(false);
  const [dataElement, setDataElement] = React.useState({
    voidSelected: "No",
    valueAmt: "",
    valuePCT: "",
    valueNum: "",
    valueData: "",
    valueTimeStamp: ""
  });
  const [add, setAdd] = React.useState(true);
  const [voidFlag, setVoidFlag] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [retainEdit, setRetainEdit] = React.useState();
  const [retainBeginDate, setRetainBeginDate] = React.useState();
  const [retainFormatDate, setRetainFormatDate] = React.useState("");
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const [initalFocusCheck, setinitalFocusCheck] = React.useState(false);
  const [values, setValues] = React.useState({
    dataFormat: "",
    description: "",
    paramNumber: "",
    functionalArea: ""
  });

  const [
    systemParameterEditHeadCells,
    setSystemParameterEditHeaddCells
  ] = React.useState([
    {
      id: "beginDate",
      numeric: false,
      disablePadding: true,
      label: "Begin Date",
      width: "120",
      isDate: true
    },
    {
      id: "endDate",
      numeric: false,
      disablePadding: true,
      label: "End Date",
      width: "120",
      isDate: true
    },
    {
      id: "voidDate",
      numeric: false,
      disablePadding: false,
      label: "Void Date",
      width: "120",
      isDate: true
    }
  ]);

  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const [
    {
      showBeginDateErrorText,
      showDateErrorText,
      showCurrencyErrorText,
      showNumericErrorText,
      showAlphaNumericErrorText,
      showPercentErrorText,
      showTimeStampErrorText,
      showDescriptionErrorText
    },
    setErrorMeg
  ] = React.useState([]);
  const [selectedBeginDate, setSelectedBeginDate] = React.useState(null);
  const [selectedFormatDate, setSelectedFormatDate] = React.useState(null);

  const systemParamAddCons = SystemParameterAddConstants;
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(tableData.length);
  const [showVoid, setShowVoid] = React.useState(false);
  const [voidYesNo, setVoidYesNo] = React.useState(false);
  const [
    {
      showDescriptionError,
      showNumericError,
      showPercentError,
      showAlphaNumericError,
      showCurrencyError,
      showBeginDateError,
      showDateError,
      showTimeStampError
    },
    setShowError
  ] = React.useState(false);

  const [errorFocus, setErrorFocus] = React.useState(false);
  const [functionalAreaData, setFunctionalAreaData] = React.useState([]);
  const [dataFormatData, setDataFormatData] = React.useState([]);
  const [successMessage, setSuccessMessage] = React.useState([]);
  const [voidedData, setVoidedData] = React.useState([]);
  const [nonVoidedData, setNonVoidedData] = React.useState([]);
  const [
    nonVoidedDatatoSetVoidPop,
    setNonVoidedDatatoSetVoidPop
  ] = React.useState([]);
  const [
    concatbothvoidandnonvoid,
    setConcatbothVoidAndNonVoid
  ] = React.useState([]);
  const [index, setIndex] = React.useState();
  const [currentParameterSK, setcurrentParameterSK] = React.useState();
  const [rowSystemParameterData, setRowSystemParameterData] = React.useState(
    []
  );
  const [versionNo, setVersionNo] = React.useState(0);
  const [notesTableData, setNotesTableData] = React.useState([]);
  const logInUserID = useSelector(state => state.sharedState.logInUserID);

  const systemParameterState = useSelector(
    state =>
      state &&
      state.appConfigState &&
      state.appConfigState.systemParameterState &&
      state.appConfigState.systemParameterState &&
      state.appConfigState.systemParameterState
  );
  const [auditDialogRowData, setAuditDialogRowData] = useState([]);
  const [auditRowData, setAuditRowData] = useState([]);

  const { auditDialogData, auditLogData } = systemParameterState;

  useEffect(() => {
    if (auditDialogData) {
      setAuditDialogRowData(auditDialogData);
    }
    if (auditLogData) {
      setAuditRowData(auditLogData);
    }
  }, [auditDialogData, auditLogData]);

  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: DateUtils.getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: DateUtils.getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({});
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  const [auditButtonToggle, setAuditButtonToggle] = React.useState(false);
  const [auditLogRow, setAuditLogRow] = React.useState(null);

  useEffect(() => {
    if (auditButtonToggle) {
      auditLogClick(values.functionalArea, values.paramNumber);
      if (auditLogRow) {
        onClickAuditDialogLog(auditLogRow);
      }
    } else {
      setClose(false);
      setShowLogDialogTable(false);
      setShowLogTable(false);
    }
  }, [auditButtonToggle]);

  useEffect(() => {
    if (auditLogRow && auditButtonToggle) {
      setAuditDialogRowData([]);
      onClickAuditDialogLog(auditLogRow);
    } else {
      setAuditDialogRowData([]);
    }
  }, [auditLogRow]);

  // add notes
  let notesDataArray = [];

  const addNotes = data => {
    setFocusCheck(true);
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;

    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };
  const dropDownDispatch = dropdownvalues =>
    dispatch(AppConfigDropdownActions(dropdownvalues));
  const userInquiryPrivileges = !global.globalIsReadOnly();
  // Update Record
  const onUpdateSuccess = values =>
    dispatch(systemParameterRowClickAction(values));
  const systemParameterUpdatedRecord = useSelector(
    state => state.appConfigState.systemParameterState.rowSearchSysParam
  );

  useEffect(() => {
    dropDownDispatch(dropdownCriteria);
    onDropdowns([
      Dropdowns.R_FUNC_AREA_CD,
      Dropdowns.DATE_FORMAT,
      Dropdowns.NOTES_USG_CODE
    ]);
  }, []);
  const dropdown = useSelector(
    state => state.appConfigState.AppConfigCommonState.appConfigDropdown
  );
  let voidDateArray = [];
  let nonVoidDateArray = [];
  const nonVoidArrayPopup = [];

  const updateResponse = useSelector(
    state => state.appConfigState.systemParameterState.updateSystemParameter
  );

  const auditLogClick = (functionalArea, paramNumber) => {
    dispatch(
      getAuditLogDetailsAction(
        functionalArea,
        paramNumber,
        "",
        false,
        "auditLogData"
      )
    );
  };
  const [showLogDialogTable, setShowLogDialogTable] = React.useState(false);
  const onClickAuditDialogLog = data => {
    setShowLogDialogTable(true);
    dispatch(
      getAuditLogDetailsAction(
        values.functionalArea,
        values.paramNumber,
        data.parameterDetailSK,
        true,
        "auditDialogData"
      )
    );
  };
  useEffect(() => {
    if (showLogTable) document.getElementById("audit-table").scrollIntoView();
  }, [showLogTable]);
  useEffect(() => {
    if (showLogDialogTable && document.getElementById("audit-row-table")) { document.getElementById("audit-row-table").scrollIntoView(); }
  }, [showLogDialogTable]);

  useEffect(() => {
    tableRecord.map((option) => {
      if (option.voidDate && typeof option.voidDate === "string") {
        const voidDate = option.voidDate.split(" ");
        option.voidDate = voidDate[0];
      }
      if (option.voidDate) {
        voidDateArray.push(option);
        setVoidedData(voidDateArray);
      } else {
        nonVoidDateArray.push(option);
        nonVoidArrayPopup.push(option);
        setNonVoidedData(nonVoidDateArray);
        setNonVoidedDatatoSetVoidPop(nonVoidArrayPopup);
      }
    });
    adjustEdnDates(nonVoidDateArray);
  }, [tableRecord]);

  useEffect(() => {
    if (props.location.state.successResponse) {
      const response = props.location.state.successResponse;
      if (response.respcode === "01") {
        successMessagesArray.push(
          SystemParameterAddConstants.SAVED_SUCCESSFULLY
        );
        setSuccessMessage(successMessagesArray);
        setFocusCheck(false);
      } else if (response.respcode === "03") {
        errorMessagesArray.push(response.exc);
        seterrorMessages(errorMessagesArray);
      } else if (response.respcode === "02") {
        errorMessagesArray.push(response.respdesc);
        seterrorMessages(errorMessagesArray);
      }
    }
  }, [props.location.state.successResponse]);

  useEffect(() => {
    if (props.location.state.payloadData) {
      paramsData = props.location.state.payloadData;
      // notes
      if (paramsData.noteSetVO) {
        const noteSetVO = paramsData.noteSetVO;
        const notesTable = paramsData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: DateUtils.getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID
            ? noteSetVO.addedAuditUserID
            : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
            ? noteSetVO.addedAuditTimeStamp
            : DateUtils.getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTable,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
      setAddEdit(true);
      setVersionNo(paramsData.versionNo);
      setValues({
        dataFormat: paramsData.parameterTypeCode
          ? paramsData.parameterTypeCode
          : "",
        description: paramsData.parameterName ? paramsData.parameterName : "",
        paramNumber:
          paramsData && paramsData.parameterNumber
            ? paramsData.parameterNumber
            : 0,
        functionalArea:
          paramsData && paramsData.functionalArea
            ? paramsData.functionalArea
            : ""
      });

      if (paramsData.parameterTypeCode === "C") {
        editHeadCellsData = systemParameterEditHeadCells;
        editHeadCellsData.splice(1, 0, {
          id: "valueAmt",
          numeric: false,
          disablePadding: false,
          label: "Currency",
          isSPBalance: true
        });
        setSystemParameterEditHeaddCells(editHeadCellsData);
      } else if (paramsData.parameterTypeCode === "P") {
        editHeadCellsData = systemParameterEditHeadCells;
        editHeadCellsData.splice(1, 0, {
          id: "valuePCT",
          numeric: false,
          disablePadding: false,
          label: "Percent"
        });
        setSystemParameterEditHeaddCells(editHeadCellsData);
      } else if (paramsData.parameterTypeCode === "D") {
        editHeadCellsData = systemParameterEditHeadCells;
        editHeadCellsData.splice(1, 0, {
          id: "valueDate",
          numeric: false,
          disablePadding: false,
          label: "Date",
          isSystemParamDate: true
        });
        setSystemParameterEditHeaddCells(editHeadCellsData);
      } else if (paramsData.parameterTypeCode === "N") {
        editHeadCellsData = systemParameterEditHeadCells;
        editHeadCellsData.splice(1, 0, {
          id: "valueNum",
          numeric: false,
          disablePadding: false,
          label: "Number"
        });
        setSystemParameterEditHeaddCells(editHeadCellsData);
      } else if (paramsData.parameterTypeCode === "T") {
        editHeadCellsData = systemParameterEditHeadCells;
        editHeadCellsData.splice(1, 0, {
          id: "valueData",
          numeric: false,
          disablePadding: false,
          label: "Text"
        });
        setSystemParameterEditHeaddCells(editHeadCellsData);
      } else if (paramsData.parameterTypeCode === "Z") {
        editHeadCellsData = systemParameterEditHeadCells;
        editHeadCellsData.splice(1, 0, {
          id: "valueTimeStamp",
          numeric: false,
          disablePadding: false,
          label: "Timestamp"
        });
        setSystemParameterEditHeaddCells(editHeadCellsData);
      }
    }
  }, []);
  useEffect(() => {
    if (systemParameterUpdatedRecord) {
      settableRecord(systemParameterUpdatedRecord.systemParameterDetail);
      setVersionNo(systemParameterUpdatedRecord.versionNo);
      setTableData(systemParameterUpdatedRecord.systemParameterDetail);
      const paramsData = systemParameterUpdatedRecord;
      if (paramsData.noteSetVO) {
        const noteSetVO = paramsData.noteSetVO;
        const notesTable = paramsData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: DateUtils.getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID
            ? noteSetVO.addedAuditUserID
            : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
            ? noteSetVO.addedAuditTimeStamp
            : DateUtils.getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTable,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
    }
  }, [systemParameterUpdatedRecord]);
  useEffect(() => {
    if (notesInput) {
      setNoteSetListInput({
        auditUserID: logInUserID,
        auditTimeStamp: DateUtils.getUTCTimeStamp(),
        addedAuditUserID: notesInput.addedAuditUserID
          ? notesInput.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: notesInput.addedAuditTimeStamp
          ? notesInput.addedAuditTimeStamp
          : DateUtils.getUTCTimeStamp(),
        versionNo: notesInput.versionNo,
        dbRecord: false,
        sortColumn: null,
        noteTextValue: null,
        userIdName: null,
        notesCexAuditUserID: null,
        notesCexAuditTimeStamp: null,
        notesCexAddedAuditUserID: null,
        notesCexAddedAuditTimeStamp: null,
        noteSetSK: notesInput.noteSetSK,
        usageTypeDesc: "",
        shortNotes: null,
        checked: false,
        renderNoHistoryMsg: false,
        noteSequenceNumber: null,
        currentNote: null,
        rowValue: null,
        usageTypeList: null,
        strBeginDate: moment(new Date()).format("MM/DD/YYYY hh:mm:ss"),
        usageTypeCode: DEFAULT_DD_VALUE,
        tableName: null,
        noteText: "",
        commonEntityName: null,
        commonEntityTypeCode: null,
        commonEntityId: null,
        entityId: null,
        filterbeginDate: moment(new Date()).format("YYYY-MM-DD"),
        filterEndDate: null,
        userId: notesInput.userId ? notesInput.userId : logInUserID,
        noteCexVersionNum: notesInput.noteCexVersionNum
          ? notesInput.noteCexVersionNum
          : 0,
        saNoteSequenceNumber: notesInput.saNoteSequenceNumber
          ? notesInput.saNoteSequenceNumber
          : null,
        notesCexnoteTextValue: notesInput.notesCexnoteTextValue
          ? notesInput.notesCexnoteTextValue
          : 0,
        id: DateUtils.generateUUID()
      });
    }
  }, [notesInput]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      const tempData = JSON.parse(JSON.stringify(values));
      if (dropdown.listObj["Reference#1017"]) {
        setFunctionalAreaData(dropdown.listObj["Reference#1017"]);
      }
      if (dropdown.listObj["Reference#1025"]) {
        setDataFormatData(dropdown.listObj["Reference#1025"]);
      }
      if (dropdown.listObj["General#1012"]) {
        setUsageTypeCodeData(dropdown.listObj["General#1012"]);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (updateResponse) {
      if (
        updateResponse &&
        updateResponse.respcode &&
        updateResponse.respcode === "01"
      ) {
        successMessagesArray.push(AppConstants.SAVE_SUCCESS);
        setSuccessMessage(successMessagesArray);
        setFocusCheck(false);

        const rowClickValues = {
          functionalArea: values.functionalArea,
          parameterNumber: +values.paramNumber
        };
        onUpdateSuccess(rowClickValues);
        setAllowNavigation(false);
      }
      if (
        updateResponse &&
        updateResponse.respcode &&
        updateResponse.respcode === "03"
      ) {
        errorMessagesArray.push(updateResponse.exc);
        seterrorMessages(errorMessagesArray);
      }
      if (
        updateResponse &&
        updateResponse.respcode &&
        updateResponse.respcode === "02"
      ) {
        errorMessagesArray.push(updateResponse.respdesc);
        seterrorMessages(errorMessagesArray);
      }
      if (updateResponse && updateResponse.systemFailure) {
        errorMessagesArray.push([AppConstants.ERR_PROCESSING_REQ]);
        seterrorMessages(errorMessagesArray);
      }
    }
  }, [updateResponse]);

  const handleClickOpen = () => {
    var showDescriptionError = false;
    var showDescriptionErrorText = "";
    if (values.description === "") {
      showDescriptionError = true;
      showDescriptionErrorText = systemParamAddCons.DESCRIPTION_REQUIRED;
      setShowError({ showDescriptionError: showDescriptionError });
      errorMessagesArray = [];
      errorMessagesArray.push(showDescriptionErrorText);
      seterrorMessages(errorMessagesArray);
    } else {
      seterrorMessages([]);
      errorMessagesArray = [];
      successMessagesArray = [];
      setSuccessMessage(successMessagesArray);
      setOpen(true);
      setAdd(true);
      setSelectedFormatDate(null);
      setSelectedBeginDate(null);
      setDataElement({ voidSelected: "No", valueData: '' });
      setValues({ ...values });
      setErrorMeg({
        showDescriptionErrorText: null
      });
    }
    setErrorMeg({ showDescriptionErrorText: showDescriptionErrorText });
  };

  const handleClose = () => {
    setFocusCheck(initalFocusCheck);
    setAllowNavigation(initalFocusCheck);
    seterrorMessages([]);
    setAuditRowData([]);
    setAuditLogRow(null);
    setValues({ ...values });
    setOpen(false);
    setDataElement({});
    setSelectedFormatDate(null);
    setSelectedBeginDate(null);
    setErrorMeg({
      showBeginDateErrorText: null,
      showDateErrorText: null,
      showCurrencyErrorText: null,
      showPercentErrorText: null,
      showNumericErrorText: null,
      showAlphaNumericErrorText: null,
      showTimeStampErrorText: null
    });
  };

  const handleShowVoid = () => {
    setFocusCheck(true);
    if (showVoid) {
      setShowVoid(false);
      setTableData(nonVoidedData);
    } else {
      setShowVoid(true);
      const voiddata = voidedData;
      const nonVoidData = nonVoidedData;
      const concatBoth = voiddata.concat(nonVoidData);
      setConcatbothVoidAndNonVoid(concatBoth);
      setTableData(concatBoth);
    }
  };

  const addTableData = () => {
    if (!showVoid) {
      setTableData(nonVoidDateArray);
    } else {
      const voiddata = voidedData;
      const nonVoidData = nonVoidDateArray;
      const concatBoth = voiddata.concat(nonVoidData);
      setConcatbothVoidAndNonVoid(concatBoth);
      setTableData(concatBoth);
    }
  };
  const [voidUpdate, setVoidUpdate] = React.useState(false);
  const onClickeditSystemParaMeter = (data, index) => () => {
    setAuditLogRow(data);
    var showDescriptionError, showDescriptionErrorText;
    if (values.description === "") {
      showDescriptionError = true;
      showDescriptionErrorText = systemParamAddCons.DESCRIPTION_REQUIRED;
      setShowError({ showDescriptionError: showDescriptionError });
      errorMessagesArray = [];
      errorMessagesArray.push(showDescriptionErrorText);
      seterrorMessages(errorMessagesArray);
      setErrorMeg({ showDescriptionErrorText: showDescriptionErrorText });
    } else {
      setErrorMeg({
        showDescriptionErrorText: null
      });
      setIndex(index);
      setcurrentParameterSK(data.parameterDetailSK);
      successMessagesArray = [];
      seterrorMessages([]);
      setSuccessMessage(successMessagesArray);
      setSelectedDate(data.beginDate);
      setSelectedData(data.valueData);
      setSelectedNum(data.valueNum);
      setSelectedPCT(data.valuePCT);
      setSelectedAmt(data.valueAmt);
      setSelectedDateFormat(data.valueDate);
      setSelectedFormatDate(moment(data.valueDate).format("MM/DD/YYYY"));

      let SetData;
      if (data.voidDate) {
        SetData = {
          valueNum: data.valueNum,
          valueAmt: numeral(
            Number(
              data.valueAmt ? data.valueAmt.replace(/,/g, "") : data.valueAmt
            ).toFixed(4)
          ).format("0,0.00"),
          valueData: data.valueData,
          valuePCT: data.valuePCT,
          voidSelected: "Yes",
          voidDate: data.voidDate
        };
        setVoidUpdate(true);
      } else {
        SetData = {
          valueNum: data.valueNum,
          valueAmt: numeral(
            Number(
              data.valueAmt ? data.valueAmt.replace(/,/g, "") : data.valueAmt
            ).toFixed(4)
          ).format("0,0.00"),
          valueData: data.valueData,
          valuePCT: data.valuePCT,
          valueTimeStamp: data.valueTimeStamp,
          voidSelected: "No",
          voidDate: null
        };
        setVoidUpdate(false);
      }
      if (data.id) {
        setVoidFlag(true);
      } else {
        setVoidFlag(false);
      }
      setDataElement(SetData);
      setValues({ ...values });

      if (data.valueDate) {
        var formatDate = data.valueDate;
        var dateVal = formatDate.split("/");
        setSelectedFormatDate(
          moment(dateVal[2] + "-" + dateVal[0] + "-" + dateVal[1]).format(
            "MM/DD/YYYY"
          )
        );
        setRetainFormatDate(
          moment(dateVal[2] + "-" + dateVal[0] + "-" + dateVal[1]).format(
            "MM/DD/YYYY"
          )
        );
      }

      const beginDate = data.beginDate;
      setSelectedBeginDate(
        moment(moment.utc(beginDate).format("l LT")).format("MM/DD/YYYY")
      );
      setRetainBeginDate(moment(beginDate).format("MM/DD/YYYY"));
      setAdd(false);
      setOpen(true);
      setEditData(data);
      setRetainEdit(data);
    }
  };

  const resetEdit = () => {
    setFocusCheck(initalFocusCheck);
    setAllowNavigation(initalFocusCheck);
    seterrorMessages([]);
    if (add) {
      setSelectedBeginDate(null);
      setSelectedFormatDate(null);
      setDataElement({
        valueNum: "",
        valueAmt: "",
        valueData: "",
        valuePCT: "",
        valueTimeStamp: "",
        voidSelected: "No"
      });
      setValues({ ...values });
      setErrorMeg({
        showBeginDateErrorText: null,
        showDateErrorText: null,
        showCurrencyErrorText: null,
        showPercentErrorText: null,
        showNumericErrorText: null,
        showAlphaNumericErrorText: null,
        showTimeStampErrorText: null
      });
      seterrorMessages([]);
    } else {
      setSelectedFormatDate(retainFormatDate);
      setSelectedBeginDate(moment.utc(retainBeginDate).format("l LT"));
      setDataElement({
        valueData: retainEdit.valueData,
        valueNum: retainEdit.valueNum,
        valuePCT: retainEdit.valuePCT,
        valueAmt: numeral(retainEdit.valueAmt).format("0,0.00"),
        valueTimeStamp: retainEdit.valueTimeStamp,
        voidSelected: "No"
      });
      setValues({ ...values });
      setErrorMeg({
        showBeginDateErrorText: null,
        showDateErrorText: null,
        showCurrencyErrorText: null,
        showPercentErrorText: null,
        showNumericErrorText: null,
        showAlphaNumericErrorText: null,
        showTimeStampErrorText: null
      });
      seterrorMessages([]);
    }
  };

  const checkFormatValidations = () => {
    const errorArray = [];
    var showBeginDateError;
    var showDateError;
    var showPercentError;
    var showNumericError;
    var showCurrencyError;
    var showAlphaNumericError;
    var showTimeStampError = false;
    var showBeginDateErrorText;
    var showDateErrorText;
    var showPercentErrorText;
    var showNumericErrorText;
    var showCurrencyErrorText;
    var showAlphaNumericErrorText;
    var showTimeStampErrorText = "";
    setErrorMeg({
      showBeginDateErrorText: null,
      showDateErrorText: null,
      showCurrencyErrorText: null,
      showPercentErrorText: null,
      showNumericErrorText: null,
      showAlphaNumericErrorText: null,
      showTimeStampErrorText: null
    });
    if (!selectedBeginDate) {
      showBeginDateError = true;
      showBeginDateErrorText = SystemParameterAddConstants.BEGIN_DATE_REQUIRED;
      errorArray.push(SystemParameterAddConstants.BEGIN_DATE_REQUIRED);
    } else if (selectedBeginDate.toString() === "Invalid Date") {
      showBeginDateError = true;
      showBeginDateErrorText = SystemParameterAddConstants.BEGIN_DATE_INVALID;
      errorArray.push(SystemParameterAddConstants.BEGIN_DATE_INVALID);
    } else if (DateUtils.validateDateMinimumValue(selectedBeginDate)) {
      showBeginDateError = true;
      showBeginDateErrorText = AppConstants.DATE_ERROR_1964;
      errorArray.push(AppConstants.DATE_ERROR_1964);
    }
    if (values.dataFormat === "D" && !selectedFormatDate) {
      showDateError = true;
      showDateErrorText = SystemParameterAddConstants.DATE_REQUIRED;
      errorArray.push(SystemParameterAddConstants.DATE_REQUIRED);
    } else if (
      values.dataFormat === "D" &&
      selectedFormatDate.toString() === "Invalid Date"
    ) {
      showDateError = true;
      showDateErrorText = SystemParameterAddConstants.DATE_INVALID;
      errorArray.push(SystemParameterAddConstants.DATE_INVALID);
    } else if (
      values.dataFormat === "D" &&
      DateUtils.validateDateMinimumValue(selectedFormatDate)
    ) {
      showDateError = true;
      showDateErrorText = AppConstants.DATE_ERROR_1964;
      errorArray.push(AppConstants.DATE_ERROR_1964);
    }
    if (
      selectedBeginDate &&
      selectedFormatDate &&
      values.dataFormat === "D" &&
      !showBeginDateError &&
      !showDateError
    ) {
      seterrorMessages(errorArray);
      return true;
    }

    if (values.dataFormat === "P") {
      if (!dataElement.valuePCT) {
        showPercentError = true;
        showPercentErrorText = SystemParameterAddConstants.PERCENT_REQUIRED;
        errorArray.push(SystemParameterAddConstants.PERCENT_REQUIRED);
      } else {
        const regex = /^[0-9]{1}(\.[0-9]{0,4})?$/;
        if (!regex.test(dataElement.valuePCT)) {
          showPercentError = true;
          showPercentErrorText = SystemParameterAddConstants.PERCENT_INVALID;
          errorArray.push(SystemParameterAddConstants.PERCENT_INVALID);
        }
      }
    }

    if (values.dataFormat === "N") {
      if (!dataElement.valueNum) {
        showNumericError = true;
        showNumericErrorText = SystemParameterAddConstants.NUMERIC_REQUIRED;
        errorArray.push(SystemParameterAddConstants.NUMERIC_REQUIRED);
      } else {
        const regex = /^[0-9]+$/;
        if (!regex.test(dataElement.valueNum)) {
          showNumericError = true;
          showNumericErrorText = SystemParameterAddConstants.NUMERIC_INVALID;
          errorArray.push(SystemParameterAddConstants.NUMERIC_INVALID);
        }
      }
    }

    if (values.dataFormat === "C") {
      if (!dataElement.valueAmt) {
        showCurrencyError = true;
        showCurrencyErrorText = SystemParameterAddConstants.CURRENCY_REQUIRED;
        errorArray.push(SystemParameterAddConstants.CURRENCY_REQUIRED);
      } else {
        if (
          dataElement.valueAmt
            .toString()
            .replace(/,/g, "")
            .match(/^[-]?[0-9]{0,9}\.?[0-9]{0,4}$/) === null
        ) {
          showCurrencyError = true;
          showCurrencyErrorText = SystemParameterAddConstants.CURRENCY_INVALID;
          errorArray.push(SystemParameterAddConstants.CURRENCY_INVALID);
        } else {
          if (
            !(
              dataElement.valueAmt
                .toString()
                .replace(/,/g, "")
                .match(/^[-]?[0-9]{10,15}\.?[0-9]{0,2}?$/) === null
            )
          ) {
            showCurrencyError = true;
            showCurrencyErrorText =
              SystemParameterAddConstants.CURRENCY_INVALID;
              errorArray.push(SystemParameterAddConstants.CURRENCY_INVALID);
          }
        }
      }
    }

    if (values.dataFormat === "T") {
      if (!dataElement.valueData) {
        showAlphaNumericError = true;
        showAlphaNumericErrorText =
          SystemParameterAddConstants.ALPHA_NUMERIC_REQUIRED;
          errorArray.push(SystemParameterAddConstants.ALPHA_NUMERIC_REQUIRED);
      } else {
        const regex = /^[a-zA-Z0-9]+(\s+[a-zA-Z0-9]+)*\s?$/;
        if (!regex.test(dataElement.valueData)) {
          showAlphaNumericError = true;
          showAlphaNumericErrorText =
            SystemParameterAddConstants.ALPHA_NUMERIC_INVALID;
            errorArray.push(SystemParameterAddConstants.ALPHA_NUMERIC_INVALID);
        }
      }
    }
    if (values.dataFormat === "Z") {
      if (!dataElement.valueTimeStamp) {
        showTimeStampError = true;
        showTimeStampErrorText = SystemParameterAddConstants.TIMESTAMP_REQUIRED;
        errorArray.push(SystemParameterAddConstants.TIMESTAMP_REQUIRED);
      } else {
        const regex = SystemParameterAddConstants.TIMESTAMP_REGEX;
        if (!regex.test(dataElement.valueTimeStamp)) {
          showTimeStampError = true;
          showTimeStampErrorText =
            SystemParameterAddConstants.TIMESTAMP_INVALID;
            errorArray.push(SystemParameterAddConstants.TIMESTAMP_INVALID);
        }
      }
    }
    seterrorMessages(errorArray);
    setShowError({
      showAlphaNumericError: showAlphaNumericError,
      showDateError: showDateError,
      showCurrencyError: showCurrencyError,
      showBeginDateError: showBeginDateError,
      showNumericError: showNumericError,
      showPercentError: showPercentError,
      showTimeStampError: showTimeStampError,
    });

    setErrorMeg({
      showBeginDateErrorText: showBeginDateErrorText,
      showDateErrorText: showDateErrorText,
      showCurrencyErrorText: showCurrencyErrorText,
      showPercentErrorText: showPercentErrorText,
      showNumericErrorText: showNumericErrorText,
      showAlphaNumericErrorText: showAlphaNumericErrorText,
      showTimeStampErrorText: showTimeStampErrorText
    });
    if (
      !showTimeStampError &&
      !showDateError &&
      !showAlphaNumericError &&
      !showCurrencyError &&
      !showBeginDateError &&
      !showNumericError &&
      !showPercentError &&
      !showTimeStampError
    ) {
      return true;
    } else {
      return false;
    }
  };

  const changeSelectedBeginDateTimeStamp = inputdate => {
    return DateUtils.getDateInMMDDYYYYFormatWithApendZero(inputdate);
  };

  const changeDataBeginDateTimeStamp = date => {
    return DateUtils.getDateInMMDDYYYYFormatWithApendZero(date);
  };
  const adjustEdnDates = nonVoidedDatatemp => {
    const tempArray = JSON.parse(
      JSON.stringify(
        nonVoidedDatatemp.sort((a, b) =>
          Date.parse(a.beginDate) < Date.parse(b.beginDate) ? -1 : 1
        )
      )
    );
    tempArray.map((option, index) => {
      if (index === tempArray.length - 1) {
        option.endDate = moment("9999-12-31").format("L");
      } else {
        option.endDate = moment(
         (tempArray[index + 1].beginDate)
        ).subtract(1, "days").format("L");
      }
    });
    nonVoidDateArray = tempArray;
    setNonVoidedData(tempArray);
    addTableData();
  };

  const addParameter = () => {
    setErrorFocus(!errorFocus);
    if (add) {
      if (checkFormatValidations()) {
        setShowForm(true);
        setOpen(false);
        setShowError({
          showAlphaNumericError: false,
          showCurrencyError: false,
          showBeginDateError: false,
          showNumericError: false,
          showPercentError: false,
          showTimeStampError: false,
        });
        dataElement.id = DateUtils.generateUUID();
        dataElement.beginDate = changeSelectedBeginDateTimeStamp(
          selectedBeginDate
        );

        dataElement.endDate = new Date("9999-12-30T19:00:00.000+0000");

        let count = 0;
        if (nonVoidedData.length > 0) {
          nonVoidedData.map(value => {
            const beginDateToCompareOverlap = changeDataBeginDateTimeStamp(
              value.beginDate
            );
            if (
              beginDateToCompareOverlap ===
              changeDataBeginDateTimeStamp(selectedBeginDate)
            ) {
              count = count + 1;
            }
          });
        }

        var showDateOverlappingError = false;

        if (count > 0) {
          errorMessagesArray = [];
          seterrorMessages([]);
          showDateOverlappingError = true;
          errorMessagesArray.push(systemParamAddCons.BEGIN_DATE_OVERLAPPING);
          seterrorMessages(errorMessagesArray);
          setShowError({ showDateOverlappingError: showDateOverlappingError });
          window.scrollTo(0, 0);

          return showDateOverlappingError;
        }

        const value = {
          auditUserID: logInUserID,
          auditTimeStamp: DateUtils.getUTCTimeStamp(),
          addedAuditUserID: logInUserID,
          addedAuditTimeStamp: DateUtils.getUTCTimeStamp(),
          versionNo: tableRecord.versionNo,
          voidDate: null,
          tempValue: tableRecord.tempValue,
          beginDate: moment(new Date(selectedBeginDate)).format("MM/DD/YYYY"),
          endDate: moment(new Date(dataElement.endDate)).format("MM/DD/YYYY"),
          valueDate: null,
          valueTimeStamp: null,
          valueAmt: null,
          valuePCT: null,
          valueNum: null,
          valueData: null,
          parameterDetails: tableRecord.parameterDetails,
          id: DateUtils.generateUUID()
        };

        dataElement.beginDate = selectedBeginDate;

        if (values.dataFormat === "D") {
          dataElement.valueDate = moment(selectedFormatDate).format(
            "MM/DD/YYYY"
          );
          if (dataElement.voidSelected === "Yes") {
            value.voidDate = new Date().getTime();
            value.valueDate = dataElement.valueDate;
          } else {
            value.voidDate = null;
            value.valueDate = dataElement.valueDate;
          }
        }
        if (values.dataFormat === "C") {
          dataElement.valueAmt = dataElement.valueAmt;
          if (dataElement.voidSelected === "Yes") {
            value.voideDate = new Date().getTime();
            value.valueAmt = dataElement.valueAmt;
          } else {
            value.voideDate = null;
            value.valueAmt = dataElement.valueAmt;
          }
        }
        if (values.dataFormat === "N") {
          dataElement.valueNum = dataElement.valueNum;
          if (dataElement.voidSelected === "Yes") {
            value.valueNum = dataElement.valueNum;
            value.voidDate = new Date().getTime();
          } else {
            value.valueNum = dataElement.valueNum;
            value.voidDate = null;
          }
        }
        if (values.dataFormat === "P") {
          dataElement.valuePct = dataElement.valuePCT;
          if (dataElement.voidSelected === "Yes") {
            value.valuePCT = dataElement.valuePCT;
            value.voidDate = new Date().getTime();
          } else {
            value.valuePCT = dataElement.valuePCT;
            value.voidDate = null;
          }
        }
        if (values.dataFormat === "T") {
          dataElement.valueData = dataElement.valueData;
          value.valueDate = null;
          if (dataElement.voidSelected === "Yes") {
            value.valueData = dataElement.valueData;
            value.voidDate = new Date().getTime();
          } else {
            value.valueData = dataElement.valueData;
            value.voidDate = null;
          }
        }

        if (values.dataFormat === "Z") {
          dataElement.valueTimeStamp = dataElement.valueTimeStamp;
          if (dataElement.voidSelected === "Yes") {
            let tempFormat = dataElement.valueTimeStamp;
            if (
              dataElement.valueTimeStamp &&
              dataElement.valueTimeStamp.length === 10
            ) {
              tempFormat = tempFormat + " 00:00:00.000000";
            } else if (
              dataElement.valueTimeStamp &&
              dataElement.valueTimeStamp.length === 19
            ) {
              tempFormat = tempFormat + ".000000";
            } else if (
              dataElement.valueTimeStamp &&
              dataElement.valueTimeStamp.length === 29
            ) {
              tempFormat = tempFormat ? tempFormat.slice(0, -3) : tempFormat;
            }
            value.valueTimeStamp = tempFormat;
            value.voidDate = new Date().getTime();
          } else {
            let tempFormat = dataElement.valueTimeStamp;
            if (
              dataElement.valueTimeStamp &&
              dataElement.valueTimeStamp.length === 10
            ) {
              tempFormat = tempFormat + " 00:00:00.000000";
            } else if (
              dataElement.valueTimeStamp &&
              dataElement.valueTimeStamp.length === 19
            ) {
              tempFormat = tempFormat + ".000000";
            } else if (
              dataElement.valueTimeStamp &&
              dataElement.valueTimeStamp.length === 29
            ) {
              tempFormat = tempFormat ? tempFormat.slice(0, -3) : tempFormat;
            }
            value.valueTimeStamp = tempFormat;
            value.voidDate = null;
          }
        }
        if (value.voidDate) {
          if (!showDateOverlappingError) {
            setNonVoidedData(nonVoidDateArray);
          }
        } else {
          if (!showDateOverlappingError) {
            nonVoidDateArray = JSON.parse(JSON.stringify(nonVoidedData));
            nonVoidDateArray.push(value);
            adjustEdnDates(nonVoidDateArray);
          }
        }
      }
    } else {
      let count = 0;
      if (selectedBeginDate) {
        dataElement.beginDate = moment(
          changeSelectedBeginDateTimeStamp(selectedBeginDate)
        ).format("MM/DD/YYYY");
      }
      if (checkFormatValidations()) {
        setAdd(true);
        setOpen(false);
        setShowError({
          showAlphaNumericError: false,
          showCurrencyError: false,
          showBeginDateError: false,
          showNumericError: false,
          showPercentError: false,
          showTimeStampError: false
        });
        nonVoidedData.map((value, indexNumber) => {
          if (value.parameterDetailSK !== currentParameterSK) {
            const beginDateToCompareOverlap = changeDataBeginDateTimeStamp(
              value.beginDate
            );
            if (beginDateToCompareOverlap === dataElement.beginDate) {
              count = count + 1;
            }
            var showDateOverlappingError = false;
            if (count > 0) {
              errorMessagesArray = [];
              seterrorMessages([]);
              showDateOverlappingError = true;
              errorMessagesArray.push(
                systemParamAddCons.BEGIN_DATE_OVERLAPPING
              );
              seterrorMessages(errorMessagesArray);
              setShowError({
                showDateOverlappingError: showDateOverlappingError
              });
              window.scrollTo(0, 0);
              return showDateOverlappingError;
            }
          } else {
            const selectedDate = new Date(selectedBeginDate);
            const formatDateSelected = new Date(selectedFormatDate);
            if (
              selectedDate instanceof Date &&
              !isNaN(selectedDate.valueOf())
            ) {
              value.auditUserID = logInUserID;
              value.auditTimeStamp = DateUtils.getUTCTimeStamp();
              if (value.endDate !== "9999-12-30T19:00:00.000+0000") {
                value.beginDate = moment(new Date(selectedBeginDate)).format(
                  "MM/DD/YYYY"
                );
              }

              if (values.dataFormat === "C") {
                value.valueAmt = dataElement.valueAmt;
              }

              if (values.dataFormat === "N") {
                value.valueNum = dataElement.valueNum;
              }

              if (values.dataFormat === "P") {
                value.valuePCT = dataElement.valuePCT;
              }

              if (values.dataFormat === "T") {
                value.valueData = dataElement.valueData;
                value.valueDate = null;
              }

              if (values.dataFormat === "Z") {
                let tempFormat = dataElement.valueTimeStamp;
                if (
                  dataElement.valueTimeStamp &&
                  dataElement.valueTimeStamp.length === 10
                ) {
                  tempFormat = tempFormat + " 00:00:00.000000";
                } else if (
                  dataElement.valueTimeStamp &&
                  dataElement.valueTimeStamp.length === 19
                ) {
                  tempFormat = tempFormat + ".000000";
                } else if (
                  dataElement.valueTimeStamp &&
                  dataElement.valueTimeStamp.length === 29
                ) {
                  tempFormat = tempFormat
                    ? tempFormat.slice(0, -3)
                    : tempFormat;
                }
                value.valueTimeStamp = tempFormat;
              }

              if (values.dataFormat === "D") {
                if (
                  formatDateSelected instanceof Date &&
                  !isNaN(formatDateSelected.valueOf())
                ) {
                  value.valueDate = moment(new Date(selectedFormatDate)).format(
                    "MM/DD/YYYY"
                  );
                } else {
                  errorMessagesArray.push(systemParamAddCons.DATE_INVALID);
                  seterrorMessages(errorMessagesArray);
                }
              }
            } else {
              errorMessagesArray.push(systemParamAddCons.BEGIN_DATE_INVALID);
              seterrorMessages(errorMessagesArray);
            }
            value.auditUserID = logInUserID;
            value.auditTimeStamp = DateUtils.getUTCTimeStamp();

            nonVoidDateArray = JSON.parse(JSON.stringify(nonVoidedData));
            if (dataElement.voidSelected === "Yes") {
              value.voidDate = new Date().getTime();
              voidDateArray = voidedData;
              voidDateArray.push(value);
              setVoidedData(voidDateArray);
              nonVoidDateArray.splice(indexNumber, 1);
            }
          }
        });
        adjustEdnDates(nonVoidDateArray);
        setIndex(-1);
        setcurrentParameterSK("");
      }
    }
  };

  const handleDecimalValues = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    if (name === "valueAmt") {
      if (event.target.value && event.target.value.includes('.')) {
      if (checkingDecimal(event.target.value)) {
        setDataElement({ ...dataElement, [name]: event.target.value });
      }
    } else {
      setDataElement({ ...dataElement, [name]: event.target.value });
    }
    }
  };

  const handleChangeDataElement = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    const inputValue = event.target.value;
    const regex = /^[^\s].*/;
    if (name === 'valueData' && (inputValue === "" || regex.test(inputValue))) {
    setDataElement({ ...dataElement, [name]: event.target.value });
    } else if (name !== 'valueData') {
      setDataElement({ ...dataElement, [name]: event.target.value });
    }
  };

  const handleChange = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setinitalFocusCheck(true);
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChangeDataFormat = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setValues({ ...values, [name]: event.target.value });

    if (event.target.value === "Currency") {
      editHeadCellsData = systemParameterEditHeadCells;
      editHeadCellsData.pop();
      editHeadCellsData.push({
        id: "valueAmt",
        numeric: false,
        disablePadding: false,
        label: "Currency",
        isSPBalance: true,
        width: "34%"
      });
      setSystemParameterEditHeaddCells(editHeadCellsData);
    } else if (event.target.value === "Percent") {
      editHeadCellsData = systemParameterEditHeadCells;
      editHeadCellsData.pop();
      editHeadCellsData.push({
        id: "percent",
        numeric: false,
        disablePadding: false,
        label: "Percent"
      });
      setSystemParameterEditHeaddCells(editHeadCellsData);
    } else if (paramsData.parameterTypeCode === "D") {
      editHeadCellsData = systemParameterEditHeadCells;
      editHeadCellsData.splice(1, 0, {
        id: "date",
        numeric: false,
        disablePadding: false,
        label: "Date"
      });
      setSystemParameterEditHeaddCells(editHeadCellsData);
    } else if (event.target.value === "Numeric") {
      editHeadCellsData = systemParameterEditHeadCells;
      editHeadCellsData.pop();
      editHeadCellsData.push({
        id: "numeric",
        numeric: false,
        disablePadding: false,
        label: "Number"
      });
      setSystemParameterEditHeaddCells(editHeadCellsData);
    } else if (event.target.value === "Text") {
      editHeadCellsData = systemParameterEditHeadCells;
      editHeadCellsData.pop();
      editHeadCellsData.push({
        id: "alpha numeric",
        numeric: false,
        disablePadding: false,
        label: "Text"
      });
      setSystemParameterEditHeaddCells(editHeadCellsData);
    }
  };
  const handleDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedBeginDate(date);
  };

  const handleFormatDateChange = formatDate => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedFormatDate(formatDate);
  };

  const saveSystemParameter = () => {
    setSuccessMessage([]);
    seterrorMessages([]);
    if (allowNavigation === false) {
      NoSaveMessage();
    } else {
      var showDescriptionError;
      var showDescriptionErrorText;
      if (values.description === "") {
        showDescriptionError = true;
        showDescriptionErrorText = systemParamAddCons.DESCRIPTION_REQUIRED;
        setShowError({ showDescriptionError: showDescriptionError });
        errorMessagesArray = [];
        errorMessagesArray.push(showDescriptionErrorText);
        seterrorMessages(errorMessagesArray);
        setErrorMeg({ showDescriptionErrorText: showDescriptionErrorText });
      } else {
        const voidSet = voidedData;
        const nonVoidSet = nonVoidedData;
        voidSet.map(var1 => {
          var1.voidDate = moment(new Date(var1.voidDate)).format("MM/DD/YYYY");
        });
        const concatBoth = voidSet.concat(nonVoidSet);
        if (showVoid) {
          setTableData(concatBoth);
        } else {
          setTableData(nonVoidSet);
        }
        concatBoth.map(var1 => {
          var1.endDate = moment(var1.endDate).format("MM/DD/YYYY");
        });
        const data = props.location.state.payloadData;
        errorMessagesArray = [];
        setErrorMeg([]);
        setShowError(false);
        seterrorMessages(errorMessagesArray);
        const detailIdObject = {};
        detailIdObject.functionalArea = values.functionalArea;
        detailIdObject.parameterNumber = values.paramNumber;
        detailIdObject.parameterDetailSK = null;
        concatBoth.systemParameterDetailID = detailIdObject;
        for (let i = 0; i < concatBoth.length; i++) {
          if (concatBoth[i].systemParameterDetailID == null) {
            concatBoth[i].systemParameterDetailID = detailIdObject;
            concatBoth[i].lob = AppConstants.DEFAULT_LOB_VALUE;
            if (concatBoth[i].valueAmt) {
              concatBoth[i].valueAmt = concatBoth[i].valueAmt.replace(/,/g, "");
            }
            if (concatBoth[i].beginDate) {
              concatBoth[i].beginDate = moment(concatBoth[i].beginDate).format(
                "MM/DD/YYYY"
              );
            }
            if (concatBoth[i].endDate) {
              concatBoth[i].endDate = moment(concatBoth[i].endDate).format(
                "MM/DD/YYYY"
              );
            }
            if (concatBoth[i].valueDate) {
              concatBoth[i].valueDate = concatBoth[i].valueDate;
            }
          }
        }
        const requestData = {
          auditUserID: logInUserID,
          auditTimeStamp: DateUtils.getUTCTimeStamp(),
          addedAuditUserID: data.addedAuditUserID
            ? data.addedAuditUserID
            : logInUserID,
          addedAuditTimeStamp: data.addedAuditTimeStamp
            ? data.addedAuditTimeStamp
            : DateUtils.getUTCTimeStamp(),
          versionNo: versionNo,
          parameterTypeCode: values.dataFormat,
          functionalArea: values.functionalArea,
          parameterNumber: values.paramNumber,
          parameterName: values.description,
          systemParamID: {
            functionalArea: values.functionalArea,
            parameterNumber: values.paramNumber
          },
          systemParameterDetail: concatBoth,
          deletedSysParameterDetails: null,
          noteSetVO: notesInput
        };
        setAllowNavigation(false);

        requestData.systemParameterDetail.map(value => {
          if (
            value.systemParameterDetailID &&
            value.systemParameterDetailID.valueDate
          ) {
            moment(value.systemParameterDetailID.valueDate).format("L");
          }
        });
        dispatch(updateSystemParameter(requestData));
      }
    }
  };

  const rowDeleteSystemParameterDetails = data => {
    setRowSystemParameterData({
      ...rowSystemParameterData,
      rowSystemParameterData: data
    });
  };

  function systemParameterRowDeleteClick () {
    let temNewDialogData = [...tableData];
    if (rowSystemParameterData.rowSystemParameterData) {
      for (
        let i = 0;
        i < rowSystemParameterData.rowSystemParameterData.length;
        i++
      ) {
        if (rowSystemParameterData.rowSystemParameterData[i] !== undefined) {
          temNewDialogData = temNewDialogData.filter(
            payment =>
              payment.id !== rowSystemParameterData.rowSystemParameterData[i]
          );
        }
      }
    }
    settableRecord(temNewDialogData);
    setRowSystemParameterData([]);
  }
  const formatCurrency = () => {
    if (dataElement.valueAmt && values.dataFormat === "C") {
      setDataElement({
        ...dataElement,
        valueAmt: numeral(dataElement.valueAmt).format("0,0.00")
      });
    }
  };

  const removeFormatCurrency = () => {
    if (dataElement.valueAmt && values.dataFormat === "C") {
      setDataElement({
        ...dataElement,
        valueAmt: numeral(dataElement.valueAmt).format("0.00")
      });
    }
  };
  const [disableNotes, setDisableNotes] = React.useState(true);
  const validateParentForm = () => {
    if (!values.description || values.description === "") {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setDisableNotes(validateParentForm());
  }, [values]);

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSuccessMessage([]);
    seterrorMessages([]);
    setShowError(false);
  };

  const ValuesFromSysParam = props.history && props.history.location && props.history.location.state &&
    props.history.location.state.values;

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(() => {
      setDialogOpen(false);
      setFocusCheck(false);
      props.history.push({
        pathname: '/SystemParameter',
        tableRender: !props.history.location?.state?.isFromAdd,
        state: { ValuesFromSysParam: ValuesFromSysParam }
      });
    }, 100);
    };
    const majorCancel = () => {
      seterrorMessages([]);
      if (focusCheck) {
        setDialogOpen(true);
      } else {
        setDialogOpen(false);
        props.history.push({
          pathname: '/SystemParameter',
          tableRender: !props.history.location?.state?.isFromAdd,
          state: { ValuesFromSysParam: ValuesFromSysParam }
        });
      }
    };

  return (
    <div className="pos-relative w-100 h-100">
      <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />

      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessage errorMessages={errorMessages} />
        {errorMessages.length === 0 ? (
          <SuccessMessage successMessages={successMessage} />
        ) : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left">Edit System Parameter</h1>
          <div className="float-right mt-1 pt-1">
            <Button
              color="primary"
              className="btn btn-success"
              onClick={() => saveSystemParameter()}
              disabled={!userInquiryPrivileges}
            >
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
            <Button
              color="primary"
              className={
                auditButtonToggle
                  ? "btn btn-primary ml-1"
                  : "btn btn-text1 btn-transparent ml-1"
              }
              onClick={() => {
                setAuditButtonToggle(!auditButtonToggle);
                setShowLogTable(true);
              }}
            >
              <i className="fa fa-calendar-check-o" aria-hidden="true"></i>

              Audit Log
            </Button>
            {window.location.pathname === '/SystemParametersEdit' && (
              <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
            )}
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <div className="pb-2">
            <form className={classes.container} noValidate autoComplete="off">
                <NativeDropDown
                  disabled={true}
                  id="functional-area"
                  label="Functional Area"
                  value={values.functionalArea}
                  onChange={handleChange("functionalArea")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={addDropdowns && addDropdowns['Reference#R_FUNC_AREA_CD'] &&
                  addDropdowns['Reference#R_FUNC_AREA_CD'].length > 0
                    ? addDropdowns['Reference#R_FUNC_AREA_CD'].map((option, index) => (
                        <option key={index} value={option.code}>
                          {option.description}
                        </option>
                      ))
                    : null}
                />
              <div className="mui-custom-form input-md">
                <TextField
                  InputProps={{ readOnly: true, className: "Mui-disabled" }}
                  id="routing-bank"
                  fullWidth
                  label="Parameter Number"
                  value={values.paramNumber}
                  onChange={handleChange("paramNumber")}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      AppConstants.NUMBER_ONLY_REGEX,
                      ""
                    );
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ maxLength: 10 }}
                />
              </div>
                <NativeDropDown
                  id="data-format"
                  disabled={true}
                  label="Data Format"
                  value={values.dataFormat}
                  onChange={handleChangeDataFormat("dataFormat")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={addDropdowns && addDropdowns['Reference#G_PARAM_TYPE_CD'] &&
                  addDropdowns['Reference#G_PARAM_TYPE_CD'].length > 0
                    ? addDropdowns['Reference#G_PARAM_TYPE_CD'].map((option, index) => (
                        <option key={index} value={option.code}>
                          {option.description}
                        </option>
                      ))
                    : null}
                />
              <div className="mui-custom-form-description input-xxl">
                <TextField
                  id="description"
                  fullWidth
                  label="Description"
                  InputProps={{
                    readOnly: !userInquiryPrivileges,
                    className: !userInquiryPrivileges ? "Mui-disabled" : ""
                  }}
                  value={values.description}
                  onChange={handleChange("description")}
                  inputProps={{ maxLength: 50 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  required
                  helperText={
                    showDescriptionError ? showDescriptionErrorText : null
                  }
                  error={showDescriptionError ? showDescriptionErrorText : null}
                />
              </div>
            </form>

            <div className="my-3 tab-holder" style={{ marginLeft: "13px" }}>
              <div className="mui-custom-form no-margin dib show-voids mt-1">
                <div className="sub-radio no-margin">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={showVoid}
                        value={showVoid}
                        onChange={handleShowVoid}
                      />
                    }
                    label="Show Voids"
                  />
                </div>
              </div>
              <div
                className="float-right my-3"
                style={{ marginRight: "14px" }}
              >
                <Button
                  className="btn-text-main-delete btn-transparent"
                  title={'Delete'}
                  onClick={systemParameterRowDeleteClick}
                  disabled={!userInquiryPrivileges}

                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                  Delete
                </Button>

                <Button
                  className="btn btn-success btn-text-main-add ml-1"
                  onClick={handleClickOpen}
                  disabled={!userInquiryPrivileges}
                  title={'Add System Parameter Detail'}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>
                  Add
                </Button>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="tab-holder">
              <div className="tab-holder pb-1">
                <SystemParameterAddTable
                  tableData={tableData}
                  addEdit={addEdit}
                  editSystemVariableTable={onClickeditSystemParaMeter}
                  systemParameterEditHeadCells={systemParameterEditHeadCells}
                  dataFormat={tableRecord.parameterTypeCode}
                  pageCount={pageCount}
                  voidYesNo={voidYesNo}
                  showVoid={showVoid}
                  voidTableData={concatbothvoidandnonvoid}
                  nonVoidTableData={nonVoidedData}
                  rowDeleteSystemParameterDetails={
                    rowDeleteSystemParameterDetails
                  }
                  onClickAuditDialogLog={onClickAuditDialogLog}
                  setClose={setClose}
                  close={close}
                  dropdowns={addDropdowns}
                />
            {open ? (

                   <div className="tabs-container" id="form_pop_up">
                <div className="tab-header  mini-tab-header">
                  <div className="tab-heading float-left">
                    {add
                      ? "Add System Parameter Detail"
                      : "Edit System Parameter Detail"}
                  </div>
                  <div className="float-right mt-1">
                    {add ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={addParameter}
                        disabled={!userInquiryPrivileges}
                        className="btn btn-success"
                        title={'Add'}
                      ><i className="fa fa-check" aria-hidden="true"></i>
                        Add
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={addParameter}
                        disabled={!userInquiryPrivileges || voidUpdate}
                        className="btn btn-success"
                        title={'Update'}
                      >
                        <i className="fa fa-check" aria-hidden="true"></i>
                        Update
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      color="primary"
                      className="bt-reset btn-transparent ml-1"
                      onClick={resetEdit}
                      disabled={!userInquiryPrivileges}
                    >
                      <i className="fa fa-undo" aria-hidden="true"></i>
                      Reset
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      className="btn btn-primary btn btn-outlined ml-1"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
                <div className="form-border my-3">
                  <form noValidate autoComplete="off">
                        <div className="form-wrapper">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="mui-custom-form override-width-20">
                          {dataElement.voidDate ? (
                            <KeyboardDatePicker
                              maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                              disabled={true}
                              InputProps={{
                                readOnly: true,
                                className: "Mui-disabled"
                              }}
                              required
                              id="beginDate"
                              fullWidth
                              label="Begin Date"
                              format="MM/dd/yyyy"
                              InputLabelProps={{
                                shrink: true
                              }}
                              placeholder="mm/dd/yyyy"
                              value={moment
                                .utc(selectedBeginDate)
                                .format("l LT")}
                              onChange={handleDateChange}
                              helperText={
                                showBeginDateError
                                  ? showBeginDateErrorText
                                  : null
                              }
                              error={
                                showBeginDateError
                                  ? showBeginDateErrorText
                                  : null
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          ) : (
                            <KeyboardDatePicker
                              maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                              required
                              disabled={!userInquiryPrivileges}
                              id="beginDate"
                              fullWidth
                              label="Begin Date"
                              format="MM/dd/yyyy"
                              InputLabelProps={{
                                shrink: true
                              }}
                              placeholder="mm/dd/yyyy"
                              value={selectedBeginDate}
                              onChange={handleDateChange}
                              helperText={
                                showBeginDateError
                                  ? showBeginDateErrorText
                                  : null
                              }
                              error={
                                showBeginDateError
                                  ? showBeginDateErrorText
                                  : null
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          )}
                        </div>
                      </MuiPickersUtilsProvider>
                      {values.dataFormat === "N" ? (
                        <div className="mui-custom-form override-width-20">
                          {dataElement.voidDate ? (
                            <TextField
                              required
                              InputProps={{
                                readOnly: true,
                                className: "Mui-disabled"
                              }}
                              id="format"
                              fullWidth
                              label="Number"
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={dataElement.valueNum}
                              onChange={handleChangeDataElement("valueNum")}
                              inputProps={{ maxLength: 10 }}
                              helperText={
                                showNumericError ? showNumericErrorText : null
                              }
                              error={
                                showNumericError ? showNumericErrorText : null
                              }
                            />
                          ) : (
                            <TextField
                              required
                              id="format"
                              label="Number"
                              fullWidth
                              InputProps={{
                                readOnly: !userInquiryPrivileges,
                                className: !userInquiryPrivileges
                                  ? "Mui-disabled"
                                  : ""
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={dataElement.valueNum}
                              onChange={handleChangeDataElement("valueNum")}
                              inputProps={{ maxLength: 10 }}
                              helperText={
                                showNumericError ? showNumericErrorText : null
                              }
                              error={
                                showNumericError ? showNumericErrorText : null
                              }
                            />
                          )}
                        </div>
                      ) : null}

                      {values.dataFormat === "C" ? (
                        <div className="mui-custom-form override-width-20">
                          {dataElement.voidDate ? (
                            <TextField
                              label="Currency"
                              id="format"
                              fullWidth
                              required
                              value={dataElement.valueAmt}
                              inputProps={{ maxLength: 14 }}
                              onChange={handleDecimalValues("valueAmt")}
                              startAdornment={
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              }
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                                  ""
                                );
                              }}
                              error={
                                showCurrencyError ? showCurrencyErrorText : null
                              }
                              helperText={
                                showCurrencyError ? showCurrencyErrorText : null
                              }
                              InputProps={{
                                readOnly: true,
                                className: "Mui-disabled"
                              }}
                            />
                          ) : (
                            <TextField
                              id="format"
                              label="Currency"
                              fullWidth
                              required
                              onBlur={formatCurrency}
                              onFocus={removeFormatCurrency}
                              InputProps={{
                                readOnly: !userInquiryPrivileges,
                                className: !userInquiryPrivileges
                                  ? "Mui-disabled"
                                  : ""
                              }}
                              value={dataElement.valueAmt}
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{ maxLength: 14 }}
                              onChange={handleDecimalValues("valueAmt")}
                              startAdornment={
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              }
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                                  ""
                                );
                              }}
                              error={
                                showCurrencyError ? showCurrencyErrorText : null
                              }
                              helperText={
                                showCurrencyError ? showCurrencyErrorText : null
                              }
                            />
                          )}
                        </div>
                      ) : null}

                      {values.dataFormat === "D" ? (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          {dataElement.voidDate ? (
                            <div className="mui-custom-form override-width-20">
                              <KeyboardDatePicker
                                maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                                disabled={true}
                                InputProps={{
                                  readOnly: true,
                                  className: "Mui-disabled"
                                }}
                                required
                                fullWidth
                                id="format"
                                label="Date"
                                format="MM/dd/yyyy"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                placeholder="mm/dd/yyyy"
                                value={selectedFormatDate}
                                onChange={handleFormatDateChange}
                                helperText={
                                  showDateError ? showDateErrorText : null
                                }
                                error={showDateError ? showDateErrorText : null}
                                KeyboardButtonProps={{
                                  "aria-label": "change date"
                                }}
                              />
                            </div>
                          ) : (
                            <div className="mui-custom-form override-width-20">
                              <KeyboardDatePicker
                                maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                                required
                                fullWidth
                                disabled={!userInquiryPrivileges}
                                InputProps={{
                                  readOnly: !userInquiryPrivileges,
                                  className: !userInquiryPrivileges
                                    ? "Mui-disabled"
                                    : ""
                                }}
                                id="format"
                                label="Date"
                                format="MM/dd/yyyy"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                placeholder="mm/dd/yyyy"
                                value={selectedFormatDate}
                                onChange={handleFormatDateChange}
                                helperText={
                                  showDateError ? showDateErrorText : null
                                }
                                error={showDateError ? showDateErrorText : null}
                                KeyboardButtonProps={{
                                  "aria-label": "change date"
                                }}
                              />
                            </div>
                          )}
                        </MuiPickersUtilsProvider>
                      ) : null}

                      {values.dataFormat === "P" ? (
                        <div className="mui-custom-form override-width-20">
                          {dataElement.voidDate ? (
                            <TextField
                              required
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                className: "Mui-disabled"
                              }}
                              id="format"
                              label="Percent"
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={dataElement.valuePCT}
                              inputProps={{ maxLength: 6 }}
                              onChange={handleChangeDataElement("valuePCT")}
                              helperText={
                                showPercentError ? showPercentErrorText : null
                              }
                              error={
                                showPercentError ? showPercentErrorText : null
                              }
                            />
                          ) : (
                            <TextField
                              required
                              fullWidth
                              id="format"
                              label="Percent"
                              InputProps={{
                                readOnly: !userInquiryPrivileges,
                                className: !userInquiryPrivileges
                                  ? "Mui-disabled"
                                  : ""
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={dataElement.valuePCT}
                              inputProps={{ maxLength: 6 }}
                              onChange={handleChangeDataElement("valuePCT")}
                              helperText={
                                showPercentError ? showPercentErrorText : null
                              }
                              error={
                                showPercentError ? showPercentErrorText : null
                              }
                            />
                          )}
                        </div>
                      ) : null}

                          {values.dataFormat === "T" ? (
                            <div className="mui-custom-form with-textarea override-width-95">
                              {dataElement.voidDate ? (
                                <>
                                  <Form.Group>
                                    <Form.Label className="MuiFormLabel-root small-label">
                                      Text{" "}
                                      <span className="Mui-required" id="text-label-01">
                                        *
                                      </span>
                                    </Form.Label>
                                    <Form.Control
                                      aria-describedby="text-label-01"
                                      aria-label="form-controll-01"
                                      as="textarea"
                                      rows="4"
                                      name="text"
                                      disabled={true}
                                      value={dataElement.valueData}
                                      onChange={handleChangeDataElement("valueData")}
                                      maxLength={300}
                                      style={{ width: "100%" }} />
                                  </Form.Group><div
                                    style={{
                                      border: "1px solid rgb(169, 169, 169)",
                                      borderRadius: ".25rem",
                                      paddingLeft: "5px",
                                      width: "200px",
                                      alignText: "center"
                                    }}
                                  >
                                    Characters Remaining:{dataElement.valueData ? (300 - dataElement.valueData?.length) : 300}
                                  </div>
                                </>) : (
                                <>
                                  <Form.Group>
                                    <Form.Label className="MuiFormLabel-root small-label">
                                      Text{" "}
                                      <span className="Mui-required" id="text-label-01">
                                        *
                                      </span>
                                    </Form.Label>
                                    <Form.Control
                                      aria-describedby="text-label-01"
                                      aria-label="form-controll-01"
                                      as="textarea"
                                      rows="4"
                                      name="text"
                                      value={dataElement.valueData}
                                      onChange={handleChangeDataElement("valueData")}
                                      maxLength={300}
                                      style={{ width: "100%" }} />
                                    {showAlphaNumericError ? (
                                      <p
                                        className="MuiFormHelperText-root Mui-error Mui-required"
                                        role="alert"
                                      >
                                        {showAlphaNumericErrorText}
                                      </p>
                                    ) : null}
                                  </Form.Group><div
                                    style={{
                                      border: "1px solid rgb(169, 169, 169)",
                                      borderRadius: ".25rem",
                                      paddingLeft: "5px",
                                      width: "200px",
                                      alignText: "center"
                                    }}
                                  >
                                    Characters Remaining:{dataElement.valueData ? (300 - dataElement.valueData?.length) : 300}
                                  </div>
                                </>)}
                            </div>
                          ) : null}
                          {values.dataFormat === "Z" ? (
                            <div className="mui-custom-form override-width-20">
                              {dataElement.voidDate ? (
                                <TextField
                                  InputProps={{
                                    readOnly: true,
                                    className: "Mui-disabled"
                                  }}
                                  fullWidth
                                  id="format"
                                  required
                                  label="Timestamp"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  inputProps={{ maxLength: 29 }}
                                  value={dataElement.valueTimeStamp}
                                  onChange={handleChangeDataElement(
                                    "valueTimeStamp"
                                  )}
                                  helperText={
                                    showTimeStampError
                                      ? showTimeStampErrorText
                                      : null
                                  }
                                  error={
                                    showTimeStampError
                                      ? showTimeStampErrorText
                                      : null
                                  }
                                />
                              ) : (
                                <TextField
                                  id="format"
                                  required
                                  disabled={!userInquiryPrivileges}
                                  InputProps={{
                                    readOnly: !userInquiryPrivileges,
                                    className: !userInquiryPrivileges
                                      ? "Mui-disabled"
                                      : ""
                                  }}
                                  fullWidth
                                  label="Timestamp"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  inputProps={{ maxLength: 29 }}
                                  value={dataElement.valueTimeStamp}
                                  onChange={handleChangeDataElement(
                                    "valueTimeStamp"
                                  )}
                                  helperText={
                                    showTimeStampError
                                      ? showTimeStampErrorText
                                      : null
                                  }
                                  error={
                                    showTimeStampError
                                      ? showTimeStampErrorText
                                      : null
                                  }
                                />
                              )}
                            </div>
                          ) : null}

                      {add || voidFlag ? null : (
                        <div className="mui-custom-form override-width-20">
                          {dataElement.voidDate ? (
                            <div>
                              <span className="MuiFormLabel-root small-label no-margin">
                                Void
                              </span>
                              <div
                                className="sub-radio"
                                style={{
                                  paddingTop: "10px",
                                  marginLeft: "0px"
                                }}
                              >
                                <Radio
                                  name="voidSelection"
                                  value="Yes"
                                  id="voidYesId"
                                  checked={dataElement.voidSelected === "Yes"}
                                  onChange={handleChangeDataElement(
                                    "voidSelected"
                                  )}
                                  disabled
                                />
                                <label className="text-black" htmlFor="voidYesId">
                                  Yes
                                </label>
                                <Radio
                                  name="voidSelection"
                                  value="No"
                                  id="voidNoId"
                                  checked={dataElement.voidSelected === "No"}
                                  onChange={handleChangeDataElement(
                                    "voidSelected"
                                  )}
                                  disabled
                                  className="ml-2"
                                />
                                <label className="text-black" htmlFor="voidNoId">
                                  No
                                </label>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <span className="MuiFormLabel-root small-label no-margin">
                                Void
                              </span>
                              <div
                                className="sub-radio"
                                style={{
                                  paddingTop: "10px",
                                  marginLeft: "0px"
                                }}
                              >
                                <Radio
                                  name="voidSelection"
                                  value="Yes"
                                  id="voidSelectionYesId"
                                  disabled={!userInquiryPrivileges}
                                  checked={dataElement.voidSelected === "Yes"}
                                  onChange={handleChangeDataElement(
                                    "voidSelected"
                                  )}
                                />
                                <label
                                  htmlFor="voidSelectionYesId"
                                  className="text-black"
                                >
                                  Yes
                                </label>
                                <Radio
                                  name="voidSelection"
                                  value="No"
                                  id="voidSelectionNoId"
                                  disabled={!userInquiryPrivileges}
                                  checked={dataElement.voidSelected === "No"}
                                  onChange={handleChangeDataElement(
                                    "voidSelected"
                                  )}
                                  className="ml-2"
                                />
                                <label
                                  htmlFor="voidSelectionNoId"
                                  className="text-black"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {showLogDialogTable ? (
                      <div
                        id="audit-row-table"
                        className="tab-panelbody-audit"
                        style={{ padding: "20px" }}
                      >
                        <div
                          style={{
                            paddingBottom: "50px",
                            marginBotton: "20px"
                          }}
                        >
                          <div
                            className="tab-holder form-control-text float-left"
                            style={{ marginBotton: "20px" }}
                          >
                            Audit Details
                          </div>
                        </div>
                        <div className="tab-holder table-no-wrap table-p-5">
                          <Table
                            fixedTable
                            print={props.print}
                            headCells={headCells}
                            isSearch={true}
                            tableData={auditDialogRowData}
                            onTableRowClick={null}
                            defaultSortColumnDesc={true}
                            defaultSortColumn={"timestamp"}
                            setClose={setClose}
                          />
                        </div>
                      </div>
                    ) : null}
                  </form>
                </div>
              </div>
            ) : null}
              </div>

            </div>

          </div>

          <div className="tab-panelbody">
            <div className="tab-holder my-3">
              <AppBar position="static">
                <Tabs
                  value={value}
                  aria-label="simple tabs example"
                  className="tabChange"
                >
                  <Tab label="Notes" />
                </Tabs>
              </AppBar>
              <TabPanel value={tabValue} index={0}>
                <div className="tab-holder  p-0">
                  <div className={classes.root}>
                    <Notes
                      addNotes={addNotes}
                      notesTableData={notesTableData}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs}
                      disableNotes={disableNotes}
                      setErrorMessages = {seterrorMessages}
                      userInquiryPrivileges = {userInquiryPrivileges}
                      setFocusCheck={setFocusCheck}
                      focusCheck={focusCheck}
                    />
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
          {showLogTable ? (
            <div
              className="tab-panelbody-audit"
              id="audit-table"
              style={{ padding: "20px" }}
            >
              <div style={{ paddingBottom: "50px", marginBotton: "20px" }}>
                <div
                  className="tab-holder form-control-text float-left"
                  style={{ marginBotton: "20px" }}
                >
                  Audit Details
                </div>
              </div>
              <div className="tab-holder table-no-wrap table-p-5">
                <Table
                  fixedTable
                  print={props.print}
                  headCells={headCells}
                  isSearch={true}
                  tableData={auditRowData}
                  onTableRowClick={null}
                  defaultSortColumnDesc={true}
                  defaultSortColumn={"timestamp"}
                />
              </div>
            </div>
          ) : null}
          <Footer print />
        </div>
        <Dialog
              open={dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="custom-alert-box"
      >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
                  </>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
              setDialogOpen(false);
            }}
			                 color="primary"
                  className="btn btn-transparent"
          >
                  STAY ON THIS PAGE!
                </Button>
                <Button
                  onClick={cancelMinorSection}
                  color="primary"
                  className="btn btn-success"
                  autoFocus
          >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
                </Button>
              </DialogActions>
            </Dialog>
      </div>
    </div>
  );
}
