/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { Button } from "react-bootstrap";
import TableComponent from "../../../../SharedModules/Table/Table";
import {
  DialogTitle,
  DialogContent,
  DialogActions
} from "../../../../SharedModules/Dialog/DialogUtilities";
import Dialog from "@material-ui/core/Dialog";
import NativeDropDown from "../../../../SharedModules/Dropdowns/NativeDropDown";

export default function PayeeStatusTab(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE
  const {
    handlePayeeStatusChange,
    payeeStatus,
    statusDropDown,
    statusReasonCodeDropDown,
    handleBeginDate,
    handleEndDate,
    beginDate,
    endDate,
    payeeStatusTable,
    showEditPage,
    editPayeeStatus,
    addPayeeStatus,
    payeeStatusError,
    payeeStatusErrorMsg,
    openPopUp,
    handleClose,
    handleOpen,
    addClickPayeeStatus,
    handleEditRow,
    editRowBool,
    resetAdd,
    selectedPayeeStatusTableRow,
    deleteSelectedRows,
    disableFields,
    IsG16003,
    userInquiryPrivileges,
    setFocusCheck,
    payeePaymentStatusFrmState,
    payeePaymentStatusValue,
    handlePayeePaymentStatusChange,
    showPayeePaymentStatus,
    isPpayeePayerType,
    dropdowns
  } = props;
  const headCells = [
    {
      id: "beginDate",
      numeric: false,
      disablePadding: true,
      width: "25%",
      isDate: true,
      label: "Begin Date"
    },
    {
      id: "endDate",
      numeric: false,
      disablePadding: false,
      width: "25%",
      isDate: true,
      label: "End Date"
    },
    {
      id: "statusDesc",
      numeric: false,
      disablePadding: false,
      isVarChar: true,
      width: "25%",
      label: "Status"
    },
    {
      id: "reasonDesc",
      numeric: false,
      disablePadding: false,
      width: "25%",
      isVarChar: true,
      label: "Reason code"
    }
  ];
  const editRow = row => event => {
    handleEditRow(row);
  };

  useEffect(() => {
    if (openPopUp && document.getElementById("form_pop_up")) {
      document.getElementById("form_pop_up").scrollIntoView();
    }
  }, [openPopUp]);

  const [payeeTableSelectedRows, setPayeeTableSelectedRows] = React.useState(
    []
  );
  const selectedPayeeTableRow = selectedRows => {
    setPayeeTableSelectedRows([...selectedRows]);
  };
  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const ststusRsnCd =
            dropdowns &&
            dropdowns['P1#P_ENRL_STAT_RSN_CD'] &&
            dropdowns['P1#P_ENRL_STAT_RSN_CD'].filter(
              value => value.code === each.ststusRsnCd
            );
          each.reasonDesc =
          ststusRsnCd && ststusRsnCd.length > 0
              ? ststusRsnCd[0].description
              : each.ststusRsnCd ? each.ststusRsnCd :  ''     
                  
        }
        return each;
      });
      return tData;
    }
    return [];
  };
  let payStatusCode = payeePaymentStatusFrmState.find(value => value.code === payeePaymentStatusValue);
  return (
    <div>
      {showPayeePaymentStatus === 'Y' ? (
        <div className="tab-holder my-2">
        <fieldset className="r-custom-fieldset no-padding">
          <legend>Payee Payment Status:</legend>
            <NativeDropDown
                id="standard-select-payeePaymentStatus"
                fullWidth
              value={payStatusCode !== undefined ? payeePaymentStatusValue : DEFAULT_DD_VALUE}
                label="Payee Payment Status"
                name='payeePaymentStatus'
                onChange={handlePayeePaymentStatusChange}
                SelectProps={{
                  MenuProps: {}
                }}
                placeholder=""
                InputLabelProps={{
                  shrink: true
                }}
              dropdownList={payeePaymentStatusFrmState?.map(option => (
                <option key={option.code} value={option.code}>
                    {option.description}
                </option>
                ))}
            />
        </fieldset>
      </div>
      ) : null}
      <div className="tab-holder my-2">
        <fieldset className="r-custom-fieldset no-padding">
          <legend>Payee Enrolment Status:</legend>
          <div className="tab-holder">
            <div className="mb-3" style={{ float: "right" }}>
              <Button
                className="btn-text-main-delete btn-transparent  ml-1"
                onClick={deleteSelectedRows}
                disabled={disableFields || isPpayeePayerType }
                title={"Delete"}
              >
                <i class="fa fa-trash" aria-hidden="true"></i>
                Delete
              </Button>
              <Button
                className="btn-text-main-add btn-success ml-1"
                onClick={addClickPayeeStatus}
                title={"Add Enrolment Payee Status"}
                disabled={
                  disableFields ||
                  isPpayeePayerType ||
                  !userInquiryPrivileges 
                }
              >
                <i class="fa fa-plus" aria-hidden="true"></i>
                Add
              </Button>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="tab-holder table-no-wrap table-p-5">
            <TableComponent
              headCells={headCells}
              isSearch={false}
              tableData={getTableData(payeeStatusTable || [])}
              onTableRowClick={editRow}
              onTableRowDelete={selectedPayeeTableRow}
              defaultSortColumn={headCells[0].id}
              onTableRowDelete={selectedPayeeStatusTableRow}
            />
            {openPopUp ? (
              <div className="tabs-container" id="form_pop_up">
                <div className="tab-header  mini-tab-header">
                  <div className="tab-heading float-left">
                    {editRowBool ? "Edit Enrolment Payee Status" : "Add Enrolment Payee Status"}
                  </div>
                  <div className="float-right mt-1">
                    <Button
                      className="btn btn-success ml-1"
                      onClick={editRowBool ? editPayeeStatus : addPayeeStatus}
                      disabled={
                        disableFields ||
                        isPpayeePayerType ||
                        !userInquiryPrivileges 
                      }
                      title={editRowBool ? "Update" : "Add"}
                    >
                      <i class="fa fa-check" aria-hidden="true"></i>
                      {editRowBool ? "Update" : "Add"}
                    </Button>
                    <Button
                      className="bt-reset btn-transparent  ml-1"
                      onClick={resetAdd}
                      disabled={
                        disableFields ||
                        isPpayeePayerType ||
                        !userInquiryPrivileges 
                      }
                      title={"Reset"}
                    >
                      <i class="fa fa-undo" aria-hidden="true"></i>
                      Reset
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      className="btn btn-primary btn btn-outlined ml-1"
                      onClick={handleClose}
                      title={"Cancel"}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
                <div className="form-border my-3">
                  <form autoComplete="off">
                    <div className="form-wrapper">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="mui-custom-form with-date">
                          <KeyboardDatePicker
                            maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                            id="payeeDob"
                            label="Begin Date"
                            required
                            format="MM/dd/yyyy"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={beginDate}
                            disabled={
                              (editRowBool && !payeeStatus.id) ||
                              disableFields ||
                              isPpayeePayerType 
                            }
                            placeholder="mm/dd/yyyy"
                            onChange={handleBeginDate}
                            helperText={
                              payeeStatusError.beginDateError
                                ? payeeStatusErrorMsg.beginDateErrorMsg
                                : null
                            }
                            error={
                              payeeStatusError.beginDateError
                                ? payeeStatusErrorMsg.beginDateErrorMsg
                                : null
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="mui-custom-form with-date">
                          <KeyboardDatePicker
                            maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                            id="payeeDob"
                            label="End Date"
                            format="MM/dd/yyyy"
                            required
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={endDate}
                            disabled={
                              !userInquiryPrivileges ||
                              disableFields ||
                              isPpayeePayerType 
                            }
                            placeholder="mm/dd/yyyy"
                            onChange={handleEndDate}
                            helperText={
                              payeeStatusError.endDateError
                                ? payeeStatusErrorMsg.endDateErrorMsg
                                : null
                            }
                            error={
                              payeeStatusError.endDateError
                                ? payeeStatusErrorMsg.endDateErrorMsg
                                : null
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                      <NativeDropDown
                          id="Status"
                        isRequired
                          disabled={
                            (editRowBool && !payeeStatus.id) ||
                            disableFields ||
                            isPpayeePayerType 
                          }
                          label="Status"
                          value={payeeStatus.statusTypeCd}
                          onChange={handlePayeeStatusChange("statusTypeCd")}
                          InputLabelProps={{
                            shrink: true
                          }}
                        errTxt={
                            payeeStatusError.statusError
                              ? payeeStatusErrorMsg.statusErrorMsg
                              : null
                          }
                        dropdownList={statusDropDown
                            ? statusDropDown.map(option => (
                              <option key={option.code} value={option.code}>
                                  {option.description}
                              </option>
                              ))
                            : null}
                      />
                      {showEditPage ?
                        <NativeDropDown
                          id="payee-type"
                          isRequired
                          disabled={
                            !userInquiryPrivileges ||
                            disableFields ||
                            isPpayeePayerType 
                          }
                          label="Reason"
                          value={payeeStatus.ststusRsnCd}
                          onChange={handlePayeeStatusChange("ststusRsnCd")}
                          InputLabelProps={{
                            shrink: true
                          }}
                          errTxt={
                            payeeStatusError.reasonCodeError
                              ? payeeStatusErrorMsg.reasonCodeErrorMsg
                              : null
                          }
                          dropdownList={dropdowns && dropdowns['P1#P_ENRL_STAT_RSN_CD'] && 
                          dropdowns['P1#P_ENRL_STAT_RSN_CD'].length > 0
                          ? dropdowns['P1#P_ENRL_STAT_RSN_CD'].map(option => (
                            <option key={option.code} value={option.code}>
                                {option.description}
                            </option>
                            ))
                            : null}
                        /> :
                        <NativeDropDown
                        id="payee-type"
                          isRequired
                        label="Reason"
                        value={payeeStatus.ststusRsnCd}
                        onChange={handlePayeeStatusChange("ststusRsnCd")}
                        InputLabelProps={{
                          shrink: true
                        }}
                          errTxt={
                          payeeStatusError.reasonCodeError
                            ? payeeStatusErrorMsg.reasonCodeErrorMsg
                            : null
                        }
                          dropdownList={statusReasonCodeDropDown
                          ? statusReasonCodeDropDown.map(option => (
                            <option key={option.code} value={option.code}>
                                {option.description}
                            </option>
                            ))
                          : null}
                        />
                      }
                       
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
          </div>
        </fieldset>
      </div>
    </div>
  );
}
