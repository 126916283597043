/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../../Actions/accountReceivable/ARTransferActionTypes';
import * as actionTypesAR from '../../Actions/accountReceivable/accountReceivableActionTypes';
const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.RESET_TRANSFER:
      return action.resetData;
    case actionTypes.PAYEE_ID_VALIDATE:
      return { validatePayeeIDvalues: action.validatePayeeIDvalues };
    case actionTypes.AR_TRANSFER:
      return { saveResults: action.saveResults };
    case actionTypes.ARTRANSFER_ERROR:
      return { transferError: action.transferError };
    case actionTypesAR.AR_AGING_DAYS:
      return { agingDaysList: action.agingDaysList };
    default: return state;
  }
};

export default reducer;
