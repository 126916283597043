/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from "react";
import TableComponent from "../../../../SharedModules/Table/Table";
import "date-fns";

const headCells = [
  {
    id: "fundCodeDesc",
    numeric: false,
    disablePadding: false,
    label: "Fund Code",
    width: "13%",
    enableHyperLink: true,
    isTooltip: true,
    isTitle: 'fundCodeLongDesc'
  },
  {
    id: "cosDesc",
    numeric: false,
    disablePadding: false,
    label: "COS",
    width: "8%",
    isTooltip: true,
    isTitle: 'cosCodeLongDesc'
  },
  {
    id: "beginDate",
    isDate: true,
    numeric: false,
    disablePadding: true,
    label: "Start Date",
    width: "13%"
  },
  {
    id: "endDate",
    isDate: true,
    numeric: false,
    disablePadding: false,
    label: "End Date",
    width: "12%"
  },
  {
    id: "budgetAmount",
    isBalance: true,
    numeric: false,
    disablePadding: false,
    label: "Budget Amt",
    width: "14%"
  },
  {
    id: "adjustedAuthorizedAmount",
    isBalance: true,
    numeric: false,
    disablePadding: false,
    label: "Adj Auth",
    width: "12%"
  },
  {
    id: "expenditureAmount",
    isBalance: true,
    numeric: false,
    disablePadding: false,
    label: "Expenditure",
    width: "14%"
  },
  {
    id: "fundVoidDate",
    isDate: true,
    numeric: false,
    disablePadding: false,
    label: "Void Date",
    width: "12%"
  }
];

function BudgetFundingSourceTable(props) {
  const fundingSourceTableData = props.tableData ? props.tableData : [];

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const fundCode =
            props.dropdowns &&
            props.dropdowns['R1#R_FUND_CD'] &&
            props.dropdowns['R1#R_FUND_CD'].filter(
              value1 => value1.code == each.fundCode
            );
          each.fundCodeDesc =
          fundCode && fundCode.length > 0
              ? fundCode[0].description
              : each.fundCode ? each.fundCode :  '';                  

          const cos =
            props.dropdowns &&
            props.dropdowns['C1#C_COS_CD'] &&
            props.dropdowns['C1#C_COS_CD'].filter(
              value1 => value1.code == each.cos
            );
          each.cosDesc =
          cos && cos.length > 0
              ? cos[0].description
              : each.cos ? each.cos :  '';                    
        }
        return each;
      });
      return tData;
    }
    return [];
  };
  return (
    <TableComponent
      print={props.print}
      fixedTable
      headCells={headCells}
      align={"left"}
      tableData={getTableData(fundingSourceTableData)}
      onTableRowDelete={props.rowDeleteFCode}
      onTableRowClick={props.onRowClick}
      isSearch={false}
      defaultSortColumn={headCells[0].id}
      // Audit Log Changes
      onTableCellButtonClick={props.onClickAuditDialogLog}
      uniqueKey="sequenceNumber"
      setClose={props.setClose}
      close={props.close}
    />
  );
}
export default BudgetFundingSourceTable;
