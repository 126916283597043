/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../Actions/FiscalPendAddActionTypes';
const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FISCAL_PEND_ADD_ACTION:
      return { resultOfFiscalPendAdd: action.resultOfFiscalPendAdd };
    case actionTypes.FISCAL_PEND_ID_ACTION:
      return { fiscalPendID: action.fiscalPendID };
    case actionTypes.GET_FISCAL_PEND_RECORD:
      return { searchRecord: action.searchRecord };
    case actionTypes.FISCAL_PEND_UPDATE_ACTION:
      return { resultOfFiscalPendUpdate: action.resultOfFiscalPendUpdate };
    case actionTypes.FISCAL_PEND_DELETE:
      return { deleteResult: action.deleteResult };
    case actionTypes.FISCAL_PEND_RESET:
      return action.resetData;



    default: return state;
  }
};

export default reducer;
