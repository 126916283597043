/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import ChildTableComponent from './ChildTableComponent';
import { withRouter } from 'react-router';
import { currentYear } from '../../../../SharedModules/DateUtilities/DateUtilities';
function PriorCalenderYear(props) {
  const [commonEntitySK, setCommonEntitySK] = React.useState('');
  useEffect(()=>{
    setCommonEntitySK(props.summaryDetails.commonEntitySK);
  },[]);
  let year = currentYear();
  const ClaimsHeadCells = [
    { id: 'categoryCode', disablePadding: false, label: 'Status', numeric: false, width: 80 },
    { id: 'numberOfClaims', disablePadding: false, label: 'No of Claims', numeric: false, width: 80 },
    { id: 'totalClaimAmount', disablePadding: false, label: 'Amount', numeric: true, width: 80 }
  ];

  const TransactionHeadCells = [
    { id: 'categoryCode', disablePadding: false, label: 'Financial activity', numeric: false, width: 80 },
    { id: 'numberOfClaims', disablePadding: false, label: 'No of Transactions', numeric: false, width: 80 },
    { id: 'totalClaimAmount', disablePadding: false, label: 'Amount', numeric: true, width: 80 }
  ];

  return (
    <div className="pt-2">
      <div className="row">
        <div className="col-6" style={{ marginTop: '10px' }}>
          <fieldset className="custom-fieldset ">
            <legend>{year-1} YTD</legend>
            <div>
              <h4 className="sub-header">Claims</h4>
              <ChildTableComponent commonEntitySK={commonEntitySK} tableData={props.summaryDetails ? props.summaryDetails.priorFirstYTDList : []} headCells={ClaimsHeadCells} searchDetails={props.searchDetails} FiscalYear={year - 1} />
            </div>
            <h4 className="sub-header mt-3">Financial Transactions</h4>
            <div>
              <ChildTableComponent commonEntitySK={commonEntitySK} tableData={props.summaryDetails ? props.summaryDetails.priorFirstYTDSubList : []} headCells={TransactionHeadCells} searchDetails={props.searchDetails} FiscalYear={year - 1} />
            </div>
          </fieldset>
        </div>
        <div className="col-6" style={{ marginTop: '10px' }}>
          <fieldset className="custom-fieldset ">
            <legend>{year-2} YTD</legend>
            <div>
              <h4 className="sub-header">Claims</h4>
              <ChildTableComponent commonEntitySK={commonEntitySK} tableData={props.summaryDetails ? props.summaryDetails.priorSecondYTDList : []} headCells={ClaimsHeadCells} searchDetails={props.searchDetails} FiscalYear={year - 2} />
            </div>
            <h4 className="sub-header mt-3">Financial Transactions</h4>
            <div>
              <ChildTableComponent commonEntitySK={commonEntitySK} tableData={props.summaryDetails ? props.summaryDetails.priorSecondYTDSubList : []} headCells={TransactionHeadCells} searchDetails={props.searchDetails} FiscalYear={year - 2} />
            </div>
          </fieldset>
        </div>
      </div>
      <div className="row  mt-3">
        <div className="col-6">
          <fieldset className="custom-fieldset ">
            <legend>{year-3} YTD</legend>
            <div>
              <h4 className="sub-header">Claims</h4>
              <ChildTableComponent commonEntitySK={commonEntitySK} tableData={props.summaryDetails ? props.summaryDetails.priorThirdYTDList : []} headCells={ClaimsHeadCells} searchDetails={props.searchDetails} FiscalYear={year - 3} />
            </div>

            <h4 className="sub-header mt-3">Financial Transactions</h4>
            <div>
              <ChildTableComponent commonEntitySK={commonEntitySK} tableData={props.summaryDetails ? props.summaryDetails.priorThirdYTDSubList : []} headCells={TransactionHeadCells} searchDetails={props.searchDetails} FiscalYear={year - 3} />
            </div>
          </fieldset>
        </div>
        <div className="col-6">
          <fieldset className="custom-fieldset ">
            <legend>{year-4} YTD</legend>
            <div>
              <h4 className="sub-header">Claims</h4>
            </div>
            <ChildTableComponent commonEntitySK={commonEntitySK} tableData={props.summaryDetails ? props.summaryDetails.priorFourthYTDList : []} headCells={ClaimsHeadCells} searchDetails={props.searchDetails} FiscalYear={year - 4} />
            <h4 className="sub-header mt-3">Financial Transactions</h4>
            <div>
              <ChildTableComponent commonEntitySK={commonEntitySK} tableData={props.summaryDetails ? props.summaryDetails.priorFourthYTDSubList : []} headCells={TransactionHeadCells} searchDetails={props.searchDetails} FiscalYear={year - 4} />
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
}
export default withRouter((PriorCalenderYear));
