/* eslint-disable no-unused-vars */
/**

* © [2024] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import * as RouteUrls from "../../SharedModules/Navigation/RouteConstants";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SVGComponent from "../../SharedModules/SVGModule/SVGComponent";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  row: {
    direction: "row",
    justify: "center",
    alignItems: "flex-start",
    spacing: 2
  },
  line: {
    border: "1px solid #000000",
    marginTop: 0
  },
  heading: {
    paddingTop: "10px",
    color: "#274463",
    fontWeight: 650,
    fontSize: "16px"
  },
  svg: {
    float: "left",
    paddingRight: "8px"
  },
  grid: {
    padding: "6px"
  }
}));

function SITEMAP(){
  const classes = useStyles();
  const changeRoute = routeName => {
    window.open(routeName, '_blank');
  };
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`
    };
  } 
return (
    <>
    <div className="tabs-holder">
        <div className="tab-body-bordered collapsable-panel mt-3">
            <div className="custom-panel">
            <div className="panel-header">
              <span>CMdS Financial Site Map</span>
            </div>
            </div>
            <div className="p-3 sitemap-container">
            <Grid container className={classes.row}>
                <Grid item xs className={classes.grid}>
                  <label className={classes.heading}>
                    Configuration
                  </label>
                  <hr className={classes.line} />
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.VALID_VALUE_SEARCH} className="a site-map" title="Valid Value">
                    Valid Value
                    </a>
                  </div>
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.SYSTEM_PARAMETER} className="a site-map" title="System Parameter">
                      System Parameter
                    </a>
                  </div>
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.SYSTEM_LIST} className="a site-map" title="System List">
                    System List
                    </a>
                  </div>
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.MAPSET_SEARCH} className="a site-map" title="Map Definition">
                    Map Definition
                    </a>
                  </div>
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.TEXT_MANAGEMENT_SEARCH} className="a site-map" title="Text Management">
                    Text Management
                    </a>
                  </div>                 
                  <label className={classes.heading}>
                  Bank Account
                  </label>
                  <hr className={classes.line} />
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.BANK_ACCOUNT_AND_LOCKBOX} className="a site-map" title="Bank Account">
                    Bank Account
                    </a>
                  </div>
                  <label className={classes.heading}>
                  Fund Code
                  </label>
                  <hr className={classes.line} />
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.FUND_CODE_SEARCH} className="a site-map" title="Fund Code">
                    Fund Code
                    </a>
                  </div>
                  <label className={classes.heading}>
                  Budget Maintenance
                  </label>
                  <hr className={classes.line} />
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.FINANCIAL_BUDGET_SEARCH} className="a site-map" title="Budget Maintenance">
                    Budget Maintenance
                    </a>
                  </div>
                  <label className={classes.heading}>
                  Fiscal Pend Criteria
                  </label>
                  <hr className={classes.line} />
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.FISCAL_PEND_CRITERIA_SEARCH} className="a site-map" title="Fiscal Pend Criteria">
                    Fiscal Pend Criteria
                    </a>
                  </div>
                  <label className={classes.heading}>
                  Financial Entity
                  </label>
                  <hr className={classes.line} />
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.FINANCIAL_ENTITY_SEARCH} className="a site-map" title="Financial Entity">
                    Financial Entity
                    </a>
                  </div>
                </Grid>
                <Grid item xs className={classes.grid}>
                  <label className={classes.heading}>
                  Accounts Receivable
                  </label>
                  <hr className={classes.line} />
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.FINANCIAL_ACCOUNT_INQUIRY} className="a site-map" title="Accounts Receivable">
                    Accounts Receivable
                    </a>
                  </div>
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.BULK_MAINTENANCE} className="a site-map" title="Bulk Maintenance">
                    Bulk Maintenance
                    </a>
                  </div>
                  <label className={classes.heading}>
                  Financial Payout
                  </label>
                  <hr className={classes.line} />
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.FINANCIAL_PAYOUT_SEARCH} className="a site-map" title="Financial Payout">
                    Financial Payout
                    </a>
                  </div>
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.PAYOUT_APPROVAL_SEARCH} className="a site-map" title="Payout Approval">
                    Payout Approval
                    </a>
                  </div>
                  <label className={classes.heading}>
                  Payment Criteria
                  </label>
                  <hr className={classes.line} />
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.PAYMENT_CRITERIA_SEARCH} className="a site-map" title="Payment Criteria">
                    Payment Criteria
                    </a>
                  </div>                  
                  <label className={classes.heading}>
                  Financial Receipt
                  </label>
                  <hr className={classes.line} />
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.FINANCIAL_RECEIPT_SEARCH} className="a site-map" title="Financial Receipt">
                    Financial Receipt
                    </a>
                  </div>
                  <label className={classes.heading}>
                  Financial TXN
                  </label>
                  <hr className={classes.line} />
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.FINANCIAL_UPLOAD} className="a site-map" title="Financial Data Upload">
                    Financial Data Upload
                    </a>
                  </div>
                  <label className={classes.heading}>
                  Financial Inquiry
                  </label>
                  <hr className={classes.line} />
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.FINANCIAL_INQUIRY_SEARCH} className="a site-map" title="Financial Inquiry">
                    Financial Inquiry
                    </a>
                  </div>
                </Grid>
                <Grid item xs className={classes.grid}>
                  <label className={classes.heading}>
                  Payment Status Inquiry
                  </label>
                  <hr className={classes.line} />
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.PAYMENT_INQUIRY_STATUS} className="a site-map" title="Payment Status Inquiry">
                    Payment Status Inquiry
                    </a>
                  </div> 
                  <label className={classes.heading}>
                  Maintain 1099
                  </label>
                  <hr className={classes.line} />
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.CORRECTION_SEARCH} className="a site-map" title="1099 Correction Search">
                    1099 Correction Search
                    </a>
                  </div>
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.CORRECTION_SEARCH_APPROVAL} className="a site-map" title="1099 Correction Approval">
                    1099 Correction Approval
                    </a>
                  </div>
                  <label className={classes.heading}>
                  Project Control
                  </label>
                  <hr className={classes.line} />
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.EVENT_LOGGING} className="a site-map" title="Event Logging">
                    Event Logging
                    </a>
                  </div>
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href={RouteUrls.GLOBAL_AUDIT_SEARCH} className="a site-map" title="Global Audit Search">
                    Global Audit Search
                    </a>
                  </div>
                  <label className={classes.heading}>
                  Insights
                  </label>
                  <hr className={classes.line} />
                 <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <Link to={RouteUrls.SITE_MAP} onClick={() => changeRoute(process.env.REACT_APP_OPERATIONAL_INSIGHTS_URL)} className="a site-map" title="Operational Insights">
                    Operational Insights
                    </Link>
                  </div>
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <Link to={RouteUrls.SITE_MAP} onClick={() => changeRoute(process.env.REACT_APP_PERFORMANCE_INSIGHTS_URL)} className="a site-map" title="Performance Insights">
                    Performance Insights
                    </Link>
                  </div>
                  <label className={classes.heading}>
                  Manage Account
                  </label>
                  <hr className={classes.line} />
                 <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href="/UserChangePassword" className="a site-map" title="Change Password">
                    Change Password
                    </a>
                  </div>
                  <div>
                    <SVGComponent
                      imageName="polygon5"
                      classNames={classes.svg}
                    />
                    <a href="/UserSearch" className="a site-map" title="Manage User">
                    Manage User
                    </a>
                  </div>
                </Grid>
              </Grid>
            </div>
        </div>
    </div>
          
    </>
);
}

export default withRouter(SITEMAP)