/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const PAYEE_ID_TYPE_CODE_ERROR = 'Both the Payee ID Type Code and the Payee ID are required if the Payee is a Provider.';
export const PAYEE_NAME_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const NO_RECODRS_FOUND = 'No records found for the search criteria entered.';
export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const PAYEE_ID_TYPE_CODE_REQUIRED = 'Payee ID Type Code is required.';
export const PAYEE_REQUIRED = 'Payee ID is required.';
export const PAYEE_TYPE_REQUIRED = 'Payee Type is required.';
export const FOLDER_NAME ="1099LETTERS";
export const CORRECTION_FOLDER_NAME ="CORRECTION1099LETTERS";
export const CORRECTION_LTR_ACT_08G_0001 = "LTR_ACT-08G-0001_CRCN";
export const LTR_ACT_08G_0001 = "LTR_ACT-08G-0001_ORIG";
export const ORGINAL_1099_AMOUNT = "1099 Amount";
export const CORRECTION_1099_AMOUNT = "1099 Correction";
