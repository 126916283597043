/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/
import React, { useEffect } from "react";
import TableComponent from "../../../../../SharedModules/Table/Table";

export default function SystemParameterTable(props) {
  return (
    <TableComponent
      fixedTable
      print={props.print}
      isSearch={false}
      tableData={props.tableData}
      headCells={props.systemParameterAddHeadCells}
      onTableRowClick={props.editSystemVariableTable}
      defaultSortColumn={props.systemParameterAddHeadCells[0].id}
      onTableRowDelete={props.rowDeleteSystemParameterDetails}
    ></TableComponent>
  );
}
