/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { redirectSystemID, redirectReceiptFCN } from '../../../SharedModules/TabRedirections/TabRedirections';
import { LinkOfFcn, LinkOfTcn } from '../../../SharedModules/TabRedirections/customInputLink';
import ErrorMessages from '../../../SharedModules/Errors/ErrorMessages';
import InputAdornment from '@material-ui/core/InputAdornment';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { CLAIMS_INQUIRY_TCN_LINK } from '../../../SharedModules/services/service';
import TabPanel from '../../../SharedModules/TabPanel/TabPanel';
import SuccessMessages from '../../../SharedModules/Errors/SuccessMessage';
import 'date-fns';
import * as ErrorConstants from './FinancialReceiptConstants';
import {
  getDropdownDataAction,
  saveFinancialReceiptReverseAction
} from '../Store/Actions/FinancialReceipt/financialReceiptActions';
import dropdownCriteria from './FinancialReceiptReversal.json';
import { refreshAttachmentsAction } from '../../../SharedModules/store/Actions/SharedAction';
import Footer from '../../../SharedModules/Layout/footer';
import { generateUUID, getUTCTimeStamp, getDateInMMDDYYYYFormatWithApendZero } from '../../../SharedModules/DateUtilities/DateUtilities';
import Notes from '../../../SharedModules/Notes/Notes';
import Attachments from '../../../SharedModules/Attachments/Attachments';
import { getLongDescription } from '../../../SharedModules/CommonUtilities/commonUtilities';
import NativeDropDown from '../../../SharedModules/Dropdowns/NativeDropDown';
import { TRANSACTION_NUMBER_FORMAT } from '../../../SharedModules/Errors/ErrorMsgConstant';

function FinancialReceiptReversal (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const userInquiry = !global.globalIsReadOnly();
  // Attachments variable
  let refreshAttachmentResult = useSelector(state => state.sharedState.newAttachmentResult);
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  const [attachmentFile, setAttachmentFile] = React.useState([]);
  const [value, setValue] = React.useState(0);

  const [searchData, setsearchData] = React.useState(
    props.location.state.searchData
  );
  const [atrError, setAtrError] = useState(false);
  const [payerType, setpayerType] = React.useState(
    props.location.state.searchData.entityType
  );
  const entityIDTypeCode = props.location.state?.searchData?.entityType;
  const atrNumber = props.location.state.atrNumber;
  const payeeTypeIDDropdown = entityIDTypeCode === 'P' ? 'P1#P_ALT_ID_TY_CD'
      : entityIDTypeCode === 'M' ? 'F1#B_ALT_ID_TY_CD'
      : entityIDTypeCode === 'O' ? 'F1#G_ENTY_EXTL_ID_TY_CD'
      : entityIDTypeCode === 'TC' ? 'F1#DM_G_CARR_ENTY_ID_TY_CD' : null;
  const [isFocusCheck, setFocusCheck] = React.useState(false);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [successMessages, setSuccessMessages] = React.useState([]);
  const [reasonCodeError, setReasonCodeError] = React.useState(false);
  const [reasonCodeErrorDes, setReasonCodeErrorDes] = React.useState(
    ErrorConstants.REASON_CODE
  );
  const [financialReasonCode, setFinancialReasonCode] = React.useState([
    { code: '252', description: '252-RctRevrsal' }
  ]);
  const dispatch = useDispatch();
  const toPrintRef = useRef();

  const [values, setValues] = React.useState({
    reasonCode: DEFAULT_DD_VALUE,
    atrNumber: ''
  });

  const [fundCodeDesc, setFundCodeDesc] = React.useState('');
  const [saveFlag, setSaveFlag] = React.useState(false);
  const dropdown = useSelector(
    state => state.financialReceiptState.dropdownData
  );
  const [balanceAmount, setBalanceAmount] = React.useState(
    searchData.balanceAmount
  );

  const onSaveReversal = searchviewvalues => {
    searchviewvalues.noteSetVO = notesInput;
    const index = attachmentsTableData.length - 1;
    const attachmentVOList = [];
    if (attachmentsTableData && attachmentsTableData.length > 0) {
      attachmentsTableData.map((value) => {
        if (value.id) {
          const attachmentVO = {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            versionNo: 0,
            dbRecord: true,
            sortColumn: null,
            attachmentSK: null,
            attachmentPageID: null,
            finalPath: null,
            detachInd: false,
            historyIndicator: false,
            cascadeKey: null,
            attachmentIndicator: null,
            dateAdded: getDateInMMDDYYYYFormatWithApendZero(new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE }))),
            fileUrl: null,
            showDeleteForSearched: false,
            rowIndex: 0,
            addedBy: logInUserID,
            description:
              value
                ? value.description
                : null,
            edmsStoredDate: null,
            anEDMSPageId: null,
            fileName:
              attachmentsTableData && value
                ? value.fileName
                : null,
            file1: null,
            edmsWrkUnitLevel: null,
            edmsDocType: null
          };
          attachmentVOList.push(attachmentVO);
        }
      });
    }
    searchviewvalues.attachmentVO = null;
    searchviewvalues.attachmentsList = attachmentVOList;
    dispatch(saveFinancialReceiptReverseAction(searchviewvalues, attachmentsTableData && index >= 0 && attachmentsTableData[index] && attachmentsTableData[index].id ? attachmentFile : null));
  };
  const payloadViewData = useSelector(
    state => state.financialReceiptState.saveReceiptReverseResponse
  );

  const [reasonCodeLD, setReasonCodeLD] = useState('');
  const [fundCodeLD, setFundCodeLD] = useState('');

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    dispatch(getDropdownDataAction(dropdownCriteria));
    refreshAttachmentResult = null;
    if (props.location.state.fundCodeDropdown) {
      if (props.location.state.searchData.fundCode) {
        setFundCodeLD(getLongDescription(props.location.state.fundCodeDropdown, props.location.state.searchData.fundCode));
      }
    }
    if (props.location.state.reasonCodeDD) {
      if (searchData.financialReasonValue && searchData.financialReasonValue.indexOf('-') > 0) {
        setReasonCodeLD(getLongDescription(props.location.state.reasonCodeDD, searchData.financialReasonValue.split('-')[0]));
      }
    }
  }, []);

  useEffect(() => {
    if (refreshAttachmentResult && !refreshAttachmentResult.systemFailure) {
      setAttachmentsTableData(refreshAttachmentResult);
    }
  }, [refreshAttachmentResult]);
  // System Payee redirection starts
  const searchSingleFinancialEntityRes = useSelector(
    state => state.financialEntityState.singleSearchResults
  );
  const [redirect, setRedirect] = React.useState(0);
  React.useEffect(() => {
    if (
      redirect === 1 &&
      searchSingleFinancialEntityRes &&
      !searchSingleFinancialEntityRes.systemFailure
    ) {
      props.history.push({
        pathname: '/FinancialEntityUpdate',
        state: { searchSingleFinancialEntityRes }
      });
    }
  }, [searchSingleFinancialEntityRes]);
  // System Payee redirection ends
  useEffect(() => {
    if (searchData.noteSetVO) {
      const noteSetVO = searchData.noteSetVO;
      const notesTable = searchData.noteSetVO.notesList;
      setNotesInput({
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: noteSetVO.addedAuditUserID
          ? noteSetVO.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
          ? noteSetVO.addedAuditTimeStamp
          : getUTCTimeStamp(),
        versionNo: noteSetVO.versionNo,
        dbRecord: noteSetVO.dbRecord,
        sortColumn: noteSetVO.sortColumn,
        tableName: noteSetVO.tableName,
        noteSetSK: noteSetVO.noteSetSK,
        noteSourceName: noteSetVO.noteSourceName,
        notesList: notesTableData,
        globalNotesList: [],
        checkAll: noteSetVO.checkAll,
        addNewLinkRender: noteSetVO.addNewLinkRender,
        filterLinkRender: noteSetVO.filterLinkRender,
        printLinkRender: noteSetVO.printLinkRender,
        completeNotesList: []
      });

      setNotesTableData(notesTable);
    }
    setAttachmentFile([]);
  }, [searchData]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['Financial#3010']) {
        setFinancialReasonCode(dropdown.listObj['Financial#3010']);
      }
      if (dropdown.listObj['Financial#3200']) {
        dropdown.listObj['Financial#3200'].map(var1 => {
          if (var1.code === props.location.state.searchData.fundCode) {
            setFundCodeDesc(var1.description);
          }
        });
      }
      if (dropdown.listObj['General#1012']) {
        setUsageTypeCodeData(dropdown.listObj['General#1012']);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (payloadViewData && payloadViewData.respcode === '02') {
      const tempErrorArray = [];
      tempErrorArray.push(payloadViewData.respdesc);
      seterrorMessages(tempErrorArray);

      setSuccessMessages([]);
    }
    if (payloadViewData && payloadViewData.respcode === '01') {
      const tempErrorArray = [];
      tempErrorArray.push(ErrorConstants.SUCCESSFULLY_SAVED);
      setBalanceAmount('0.00');
      setSuccessMessages(tempErrorArray);

      seterrorMessages([]);
      dispatch(refreshAttachmentsAction(searchData.fcn));
      setSaveFlag(true);
    }
    if (payloadViewData) {
      if (payloadViewData.systemFailure) {
        seterrorMessages([ErrorConstants.PROCESSING_ERROR]);

        setSuccessMessages([]);
      }
    }
  }, [payloadViewData]);
  const clearSuccessErrorMsgs = () => {
    setReasonCodeError(false);
    setSuccessMessages([]);
    seterrorMessages([]);
  };

  const handleChange = name => event => {
    seterrorMessages([]);
    setReasonCodeError(false);
    setAtrError(false);
    setValues({
      ...values,
      [name]: event.target.value
    });
  };
  const payloadViewDataSearch = useSelector(
    state => state.financialReceiptState.financialReceiptSearch
  );

  // Check search results and navigate to edit screen
  useEffect(() => {
    const errorMessagesArray = [];
    if (payloadViewDataSearch && payloadViewDataSearch.systemFailure) {
      errorMessagesArray.push(ErrorConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (
      payloadViewDataSearch &&
      payloadViewDataSearch.length === 0 &&
      !payloadViewDataSearch.systemFailure
    ) {
      errorMessagesArray.push(ErrorConstants.NO_RECORDS);

      seterrorMessages(errorMessagesArray);
    }
    var duplicateSearchPayload;
    // Updating search result with description for Reason Code and Payee Type Codes instead of displaying codes
    if (
      payloadViewDataSearch &&
      payloadViewDataSearch.length > 0 &&
      !payloadViewDataSearch.systemFailure
    ) {
      duplicateSearchPayload = JSON.parse(
        JSON.stringify(payloadViewDataSearch)
      );
    }
    if (
      payloadViewDataSearch &&
      payloadViewDataSearch.length === 1 &&
      !payloadViewDataSearch.systemFailure
    ) {
      props.history.push({
        pathname: '/FinancialReceiptUpdate',
        state: {
          payloadViewData: payloadViewDataSearch,
          editFlag: true,
          duplicateSearchPayload: duplicateSearchPayload
        }
      });
    }
  }, [payloadViewDataSearch]);

  const masterSave = () => {
    const msgArray = [];
    setSuccessMessages([]);
    if (values.reasonCode === DEFAULT_DD_VALUE) {
      msgArray.push(ErrorConstants.REASON_CODE);
      setReasonCodeError(true);
    }
    if (Number(balanceAmount.replace(',', '')) === 0) {
      msgArray.push(ErrorConstants.BALANCE_AMOUNT_ZERO);
    }
    if (values.atrNumber) {
      const regex = /[^A-Za-z0-9]/;
       if (regex.test(values.atrNumber)) {
         setAtrError(true);
         msgArray.push(process.env.REACT_APP_TRANSACTION_NUMBER_LABEL + ' ' + TRANSACTION_NUMBER_FORMAT);
       } else { setAtrError(false); }
    }
    if (msgArray.length === 0) {
      searchData.billingRsnCode = values.reasonCode;
      searchData.atrNumber = values?.atrNumber?.toUpperCase();
      searchData.reverseVO.reversalReasonCode = values.reasonCode;
      searchData.reverseVO.auditUserID = logInUserID;
      searchData.reverseVO.auditTimeStamp = getUTCTimeStamp();
      searchData.reverseVO.addedAuditUserID = logInUserID;
      searchData.reverseVO.addedAuditTimeStamp = getUTCTimeStamp();
      onSaveReversal(searchData);
      seterrorMessages([]);
    } else {
      seterrorMessages(msgArray);
    }
  };
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  const [notesTableData, setNotesTableData] = React.useState([]);
  let notesDataArray = [];

  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: '',
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: '',
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
    filterEndDate: null,
    userId: logInUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: generateUUID()
  });

  const addNotes = data => {
    const noteText = data;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };
  // Notes ENd
  const dropdownData = props.location.state.addDropdowns;
  const reasonCode = dropdownData['F1#F_RSN_CD'].find(value => value.code === searchData.financialReason);

  return (
    <div className="pos-relative w-100 h-100">
      <div className="tabs-container" ref={ toPrintRef }>

        <ErrorMessages errorMessages={ errorMessages } />
        { errorMessages.length === 0 ? <SuccessMessages successMessages={ successMessages } /> : null }

        <div className="tab-header">
          <div className="tab-heading float-left">Receipt Reversal</div>
          <div className="float-right mt-2">
            <Button className="btn btn-success ml-1" onClick={ masterSave }>
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        {/* form goes here */ }
        <div className="tab-body">
          <div className="form-wrapper">
            <NativeDropDown
                id="reverse-payeeType"
                value={ payerType }
                label="Payee Type"
                disabled={ true }
                name="payeeType"
                inputProps={ { maxLength: 2 } }
                placeholder=""
                InputLabelProps={ {
                  shrink: true
                } }
                dropdownList={dropdownData ? dropdownData['G1#G_CMN_ENTY_TY_CD'] &&
              dropdownData['G1#G_CMN_ENTY_TY_CD'].map((item, index) => (
                <option key={index} value={item.code}>
                  {item.description}
                </option>
              )) : null}
            />
              <NativeDropDown
                id="reverse-payeeTypeCode"
                value={ props.location.state?.searchData?.entityIDTypeCode }
                label="Payee ID Type Code"
                name="payeeIDTypeCode"
                disabled={ true }
                inputProps={ { maxLength: 3 } }
                placeholder=""
                InputLabelProps={ {
                  shrink: true
                } }
              dropdownList={dropdownData ? dropdownData[payeeTypeIDDropdown] &&
              dropdownData[payeeTypeIDDropdown].map((item, index) => (
                <option key={index} value={item.code}>
                  {item.description}
                </option>
              )) : null}
              />
            <div className="mui-custom-form input-md">
              <TextField
                id="reverse-payerID"
                fullWidth
                value={ searchData.entityID }
                label="Payee ID"
                name="payeeID"
                disabled={ true }
                inputProps={ { maxLength: 15 } }
                SelectProps={ {
                  MenuProps: {}
                } }
                placeholder=""
                InputLabelProps={ {
                  shrink: true
                } }
              ></TextField>
            </div>

            <div className="mui-custom-form input-md has-link">
              <TextField
                id="reverse-systemPayeeID"
                fullWidth
                label="System Payee ID"
                name="systemPayeeID"
                disabled={ true }
                inputProps={ { maxLength: 60 } }
                SelectProps={ {
                  MenuProps: {}
                } }
                placeholder=""
                InputLabelProps={ {
                  shrink: true
                } }
                InputProps={ {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Link to={ redirectSystemID(searchData) }>
                        { searchData.payeePayerID }
                      </Link>
                    </InputAdornment>
                  )
                } }
              ></TextField>
            </div>
            <div className="mui-custom-form input-md">
              <TextField
                id="reverse-payeeName"
                fullWidth
                value={ searchData.entityName }
                label="Payee Name"
                name="payeeName"
                disabled={ true }
                inputProps={ { maxLength: 60 } }
                SelectProps={ {
                  MenuProps: {}
                } }
                placeholder=""
                InputLabelProps={ {
                  shrink: true
                } }
              ></TextField>
            </div>

              <NativeDropDown
                id="reverse-memberIDType"
                value={ searchData.memberIDType ? searchData.memberIDType : DEFAULT_DD_VALUE }
                label="Member ID Type"
                disabled={ true }
                name="memberIDType"
                inputProps={ { maxLength: 60 } }
                SelectProps={ {
                  MenuProps: {}
                } }
                placeholder=""
                InputLabelProps={ {
                  shrink: true
                } }
              dropdownList={dropdownData
                ? dropdownData['Member#B_ALT_ID_TY_CD'] && dropdownData['Member#B_ALT_ID_TY_CD'].map(option => (
                  <option key={option.code} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
                />
            <div className="mui-custom-form input-md">
              <TextField
                id="reverse-memberID"
                fullWidth
                value={ searchData.memberID }
                label="Member ID"
                name="memberID"
                disabled={ true }
                inputProps={ { maxLength: 15 } }
                SelectProps={ {
                  MenuProps: {}
                } }
                placeholder=""
                InputLabelProps={ {
                  shrink: true
                } }
              ></TextField>
            </div>
            <div className="mui-custom-form input-xxl">
              <TextField
                id="reverse-memberName"
                fullWidth
                value={ searchData.memberName }
                disabled={ true }
                label="Member Name"
                name="memberName"
                inputProps={ { maxLength: 60 } }
                SelectProps={ {
                  MenuProps: {}
                } }
                placeholder=""
                InputLabelProps={ {
                  shrink: true
                } }
              ></TextField>
            </div>
          </div>
          <div className="tab-panelbody">
            <div className="tab-holder">
              <AppBar position="static">
                <Tabs
                  value={ value }
                  onChange={ handleChangeTabs }
                  aria-label="simple tabs example"
                >
                  <Tab label="Check Reversal Details" />
                  <Tab label="Notes" />
                  { <Tab label="Attachments" /> }
                </Tabs>
              </AppBar>
              <TabPanel
                value={ value }
                index={ 0 }
              >
                <div className="tab-holder p-0">
                  <form autoComplete="off">
                    { value === 0 && (
                      <div>
                        <div className="row mb-2">
                          <div className="col-6">
                            <fieldset className="custom-fieldset no-padding mb-3 mt-2 w-100">
                              <legend>Original</legend>
                              <div className="form-wrapper">
                                <div className="mui-custom-form override-width-45 has-link">
                                  <TextField
                                    id="original-reverse-FCN"
                                    fullWidth
                                    label="FCN"
                                    InputProps={ {
                                      readOnly: true,
className: 'Mui-disabled',
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <Link to={ redirectReceiptFCN(searchData.fcn) }>
                                            { searchData.fcn }
                                          </Link>
                                        </InputAdornment>
                                      )
                                    } }
                                    placeholder=""
                                    InputLabelProps={ {
                                      shrink: true
                                    } }
                                  >
                                  </TextField>
                                </div>
                                <div className="mui-custom-form with-select input-md override-width-45">
                                  <TextField
                                    id="original-reverse-reasonCode"
                                    fullWidth
                                    value={reasonCode !== 'undefined' ? reasonCode.description : null}
                                    label="Reason Code"
                                    disabled={ true }
                                    inputProps={ { maxLength: 60 } }
                                    SelectProps={ {
                                      MenuProps: {}
                                    } }
                                    placeholder=""
                                    InputLabelProps={ {
                                      shrink: true
                                    } }
                                    title={ reasonCodeLD }
                                  ></TextField>
                                </div>
                              </div>
                              <div className="form-wrapper">
                                <div className="mui-custom-form override-width-45 has-link">
                                  <TextField
                                    id="original-reverse-systemPayeeID"
                                    fullWidth
                                    label="Related FT TCN"
                                    InputProps={ {
                                      readOnly: true,
className: 'Mui-disabled',
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <a href={ CLAIMS_INQUIRY_TCN_LINK + searchData.relatedFTTCN } target={ '_blank' } rel="noreferrer">
                                            { searchData.relatedFTTCN }
                                          </a>
                                        </InputAdornment>
                                      )
                                    } }
                                    placeholder=""
                                    InputLabelProps={ {
                                      shrink: true
                                    } }
                                  >
                                  </TextField>
                                </div>
                                <div className="mui-custom-form input-md override-width-45">
                                  <TextField
                                    id="original-reverse-estdDate"
                                    value={ moment(
                                      searchData.receiptAuditTimeStamp
                                    ).format('L') }
                                    label="Established Date"
                                    name="systemPayeeID"
                                    disabled={ true }
                                    inputProps={ { maxLength: 60 } }
                                    SelectProps={ {
                                      MenuProps: {}
                                    } }
                                    placeholder=""
                                    InputLabelProps={ {
                                      shrink: true
                                    } }
                                  ></TextField>
                                </div>
                              </div>
                              <div className="form-wrapper">
                                <div className="mui-custom-form with-select input-md override-width-45">
                                  <TextField
                                    id="original-reverse-originalAmount"
                                    fullWidth
                                    value={ searchData.originalAmount }
                                    label="Original Amount"
                                    name="systemPayeeID"
                                    disabled={ true }
                                    inputProps={ { maxLength: 60 } }
                                    SelectProps={ {
                                      MenuProps: {}
                                    } }
                                    placeholder=""
                                    InputLabelProps={ {
                                      shrink: true
                                    } }
                                  ></TextField>
                                </div>
                                <div className="mui-custom-form with-select input-md override-width-45">
                                  <TextField
                                    id="original-reverse-balanceAmount"
                                    value={ balanceAmount }
                                    label="Balance Amount"
                                    name="systemPayeeID"
                                    disabled={ true }
                                    inputProps={ { maxLength: 60 } }
                                    SelectProps={ {
                                      MenuProps: {}
                                    } }
                                    placeholder=""
                                    InputLabelProps={ {
                                      shrink: true
                                    } }
                                  ></TextField>
                                </div>
                              </div>
                              <div className="form-wrapper">
                                  <NativeDropDown
                                    id="original-reverse-fundCode"
                                    className='override-width-45'
                                    value={ props.location.state.searchData.fundCode }
                                    label="Fund Code"
                                    name="systemPayeeID"
                                    disabled={ true }
                                    inputProps={ { maxLength: 60 } }
                                    SelectProps={ {
                                      MenuProps: {}
                                    } }
                                    placeholder=""
                                    InputLabelProps={ {
                                      shrink: true
                                    } }
                                    dropdownList={dropdownData
                                    ? dropdownData['R1#R_FUND_CD'] && dropdownData['R1#R_FUND_CD'].map(option => (
                                      <option key={option.code} value={option.code}>
                                        {option.description}
                                      </option>
                                    ))
                                    : null}

                                />
                                            <div className="mui-custom-form with-select input-md override-width-45">
              <TextField
                id='ATRnumber-reversal'
                fullWidth
                label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
                placeholder=""
                disabled={true}
                value={atrNumber || ""}
                inputProps={{ maxlength: 18 }}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
                              </div>
                            </fieldset>
                          </div>
                          <div className="col-6">
                            <fieldset className="custom-fieldset  no-padding mb-3 mt-2 w-100">
                              <legend>Reversal</legend>
                              <div className="form-wrapper">
                                <div className={ saveFlag ? "mui-custom-form with-select input-15 override-width-45 has-fcn-link" : "mui-custom-form with-select input-15 override-width-45" }>
                                  <TextField
                                    id="reversalNew-systemPayeeID"
                                    fullWidth
                                    value={ saveFlag ? null : searchData.reverseVO.reversedFCN }
                                    label="FCN"
                                    name="systemPayeeID"
                                    disabled={ true }
                                    inputProps={ { maxLength: 60 } }
                                    SelectProps={ {
                                      MenuProps: {}
                                    } }
                                    InputProps={ {
                                      inputComponent: LinkOfFcn,
                                      inputProps: { value: searchData.reverseVO.reversedFCN, redirection: redirectReceiptFCN, saveFlag: saveFlag }
                                    } }
                                    placeholder=""
                                    InputLabelProps={ {
                                      shrink: true
                                    } }
                                  ></TextField>
                                </div>
                                <div className="mui-custom-form with-select override-width-45">
                                  <TextField
                                    id="reversal-reverse-estdDate"
                                    fullWidth
                                    value={ searchData.reverseVO.establishedDate }
                                    label="Established Date"
                                    name="systemPayeeID"
                                    disabled={ true }
                                    inputProps={ { maxLength: 60 } }
                                    SelectProps={ {
                                      MenuProps: {}
                                    } }
                                    placeholder=""
                                    InputLabelProps={ {
                                      shrink: true
                                    } }
                                  ></TextField>
                                </div>
                              </div>
                              <div className="form-wrapper">
                                <div className="mui-custom-form override-width-45 has-link">
                                  <TextField
                                    id="reversal-reverse-TCN"
                                    fullWidth
                                    label="Related FT TCN"
                                    InputProps={ {
                                      readOnly: true,
className: 'Mui-disabled',
                                      inputComponent: LinkOfTcn,
                                      inputProps: { value: searchData.reverseVO.reversedTCN, saveFlag: saveFlag }
                                    } }
                                    placeholder=""
                                    InputLabelProps={ {
                                      shrink: true
                                    } }
                                  >
                                  </TextField>
                                </div>
                                <div className="mui-custom-form with-select override-width-45">
                                  <TextField
                                    id="reversal-reverse-amount"
                                    fullWidth
                                    value={ searchData.reverseVO.reversedAmount }
                                    label="Amount"
                                    name="systemPayeeID"
                                    disabled={ true }
                                    inputProps={ { maxLength: 60 } }
                                    SelectProps={ {
                                      MenuProps: {}
                                    } }
                                    placeholder=""
                                    InputLabelProps={ {
                                      shrink: true
                                    } }
                                  ></TextField>
                                </div>
                                  <NativeDropDown
                                    id="reversal-reverse-reasonCode"
                                    className="override-width-45"
                                    isRequired
                                    value={ values.reasonCode }
                                    label="Reason Code"
                                    name="lob"
                                    onChange={ handleChange('reasonCode') }
                                    inputProps={ { maxLength: 3 } }
                                    SelectProps={ {
                                      MenuProps: {}
                                    } }
                                    placeholder=""
                                    errTxt={
                                      reasonCodeError
                                        ? reasonCodeErrorDes
                                        : null
                                    }
                                    InputLabelProps={ {
                                      shrink: true
                                    } }
                                    dropdownList={ financialReasonCode
                                      ? financialReasonCode.map(option => (
                                        <option
                                          key={ option.code }
                                          value={ option.code }
                                          title={ option.longDescription }
                                        >
                                          { option.description }
                                        </option>
                                      ))
                                      : null }
                                  />
                                  <div className="mui-custom-form with-select override-width-45">
                                  <TextField
                                    id='ATRnumber'
                                    fullWidth
                                    label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
                                    placeholder=""
                                    value={values?.atrNumber}
                                    onChange={handleChange('atrNumber')}
                                    inputProps={{ maxlength: 18 }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    helperText={atrError ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${TRANSACTION_NUMBER_FORMAT}` : null}
                                    error= {atrError ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${TRANSACTION_NUMBER_FORMAT}` : null}
                                  ></TextField>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    ) }
                    <div className="clearfix"></div>
                  </form>
                </div>
              </TabPanel>
              <TabPanel value={ value } index={ 1 }>
                { value === 1 && (
                  <div className="tab-holder my-3">
                    <Notes
                      addNotes={ addNotes }
                      setFocusCheck={setFocusCheck}
                      notesTableData={ notesTableData }
                      noteSetListInput={ noteSetListInput }
                      setNoteSetListInput={ setNoteSetListInput }
                      usageTypeCodeData={ usageTypeCodeData }
                      editNoteData={ editNoteData }
                      setEditNoteData={ setEditNoteData }
                      setSuccessMessages={ clearSuccessErrorMsgs }
                    />
                  </div>
                ) }

              </TabPanel>
              { <TabPanel value={ value } index={ 2 }>
                <div className="tab-holder p-0">
                  <Attachments
                    userInquiryPrivileges={ userInquiry }
                    attachmentFile={ attachmentFile }
                    setAttachmentFile={ setAttachmentFile }
                    attachmentsTableData={ attachmentsTableData }
                    setAttachmentsTableData={ setAttachmentsTableData }
                    parentErrorMesage={ seterrorMessages }
                    setAllowNavigation={ null }
                    setSystemSuccesMessages={ clearSuccessErrorMsgs }
                    setFocusCheck={setFocusCheck}
                  />
                </div>
              </TabPanel> }

            </div>
          </div>
          <Footer print />
        </div>
      </div>
    </div>
  );
}

export default withRouter(FinancialReceiptReversal);
