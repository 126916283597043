/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import TableComponent from "../../../../../../SharedModules/Table/Table";

const headCells = [
  {
    id: "dueDate",
    numeric: false,
    disablePadding: true,
    label: "Due Date",
    isDate: true,
    width: 100
  },
  {
    id: "installmentAmount",
    numeric: false,
    disablePadding: false,
    label: "Installment Amount",
    isBalance: true,
    width: 100
  },
  {
    id: "recoupedAmount",
    numeric: false,
    disablePadding: false,
    label: "Recouped Amount",
    isBalance: true,
    width: 200
  }
];

function PaymentScheduleTable(props) {
  return (
    <TableComponent
      print={props.print}
      headCells={headCells}
      tableData={props.tableData ? props.tableData : []}
      onTableRowClick={props.onRowClick}
      align={"left"}
      onTableRowDelete={props.rowDeletePaymentSchedule}
      defaultSortColumn={headCells[0].id}
      isSearch={props.isSearch}
    />
  );
}
export default PaymentScheduleTable;
