/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const DESCRIPTION_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const LIST_NUMBER_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const BUSINESS_NAME_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const ADDVALUE_REGEX = /(^[0-9]{3}([.][0-9 ]{1,2})?$)|(^[0-9]{3}([ ][ ]{1,2})?$)|(^[E][0-9]{3}([.][0-9 ]{1,2})?$)|(^[E][0-9]{3}([ ][ ]{1,2})?$)|(^[V][0-9]{2}([.][0-9 ]{1,2})?$)|(^[V][0-9]{2}([ ][ ]{1,2})?$)/;
export const SURGICAL_REGEX = /(^[0-9]{2}([.][0-9 ]{1,2})?$)/;
export const SURGICAL_REGEX_ICD = /(^([0-9]|[^IOa-z]){1,7}$)/;
