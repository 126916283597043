/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const TOSLINE_REQ = 'CMS64 TOS Line is required.';
export const PRIORITY_NUMBER_REQ = 'Priority number is required.';
export const TOS_DESCRIPTION_REQ = 'CMS64 TOS Description is required.';
export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const NO_RECORDS_FOUND = 'No records found for the search criteria entered.';
