/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import ErrorMessages from '../../../SharedModules/Errors/ErrorMessages';
import TableComponent from '../../../SharedModules/Table/Table';
import Footer from '../../../SharedModules/Layout/footer';
import { searchEMARAction, getDropdownDataAction, ResetEMAR } from '../Store/Actions/EmarActions';
import * as EMARAddConstants from './EMARConstants';
import dropDownCriteria from '../EMARDropdown.json';

function EMARSearch (props) {
    const dispatch = useDispatch();
    const toPrintRef = useRef();

    const [showTable, setShowTable] = React.useState(false);
    const [errorMessages, seterrorMessages] = React.useState([]);
    const [EMARState, setEMARState] = useState({
        tosLine: '',
        tosDescription: '',
        priorityNumber: ''
    });
    const [emarTable, setEmarTable] = useState([]);
  const searchEmar = (values) => dispatch(searchEMARAction(values));
  const getDropdownData = (dropdownValues) => dispatch(getDropdownDataAction(dropdownValues));

  let searchSuccess = useSelector(state => state.emarState.searchPayload);
  const dropDownData = useSelector(state => state.emarState.dropdownData);

  const [fundCodeData, setFundCodeData] = useState([]);
  const [cosCodeData, setCosCodeData] = useState([]);
  const [providetypeCodeData, setProviderTypeCodeData] = useState([]);
  const [ReasonCodeData, setReasonCodeData] = useState([]);
  const [benfitPlanData, setBenfitPlanData] = useState([]);
  const [admitTypeCodeData, setAdmitTypeCodeData] = useState([]);
  const [providerSpecData, setProviderSpecData] = useState([]);
  const [procedureData, setProcedureData] = useState([]);

  useEffect(() => {
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Claims#1020']) {
        setCosCodeData(dropDownData.listObj['Claims#1020']);
    }
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Financial#1005']) {
        setReasonCodeData(dropDownData.listObj['Financial#1005']);
    }
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Reference#1042']) {
        setFundCodeData(dropDownData.listObj['Reference#1042']);
    }
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Reference#3045']) {
        setProviderTypeCodeData(dropDownData.listObj['Reference#3045']);
    }
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Claims6#3111']) {
        setAdmitTypeCodeData(dropDownData.listObj['Claims6#3111']);
    }
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Provider#1054']) {
        setProviderSpecData(dropDownData.listObj['Provider#1054']);
    }
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Financial#3458']) {
        setBenfitPlanData(dropDownData.listObj['Financial#3458']);
    }
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Reference#3042']) {
        setProcedureData(dropDownData.listObj['Reference#3042']);
    }
}, [dropDownData]);

  useEffect(() => {
    getDropdownData(dropDownCriteria);
    searchSuccess = null;
    ResetEMAR('searchPayload');
  }, []);
const mapCodeDescription = (_code, _mapArray) => {
    if (_mapArray && _mapArray.length > 0) {
      let _description = [];
      _description = _mapArray.filter((value, index) => value.code === _code);
      if (_description.length > 0) {
        return _description[0].description;
      } else {
        return _code;
      };
    };
    return _code;
  };

    useEffect(() => {
        if (searchSuccess && !searchSuccess.systemFailure) {
            seterrorMessages([]);
        }
        if (searchSuccess && !searchSuccess.systemFailure && searchSuccess.length > 1) {
            setShowTable(true);
            const tempSearchData = [];
            searchSuccess.map((row, index) => {
                const outputData = searchSuccess;
                tempSearchData.push({
                    ...outputData[index],
                    beginCosDesc: mapCodeDescription(
                        row.beginStateCOS,
                        cosCodeData
                      ),
                    endCosDes: mapCodeDescription(
                        row.endStateCos,
                        cosCodeData
                    ),
                    beginProvDesc: mapCodeDescription(
                        row.beginProviderType,
                        providetypeCodeData
                      ),
                    endProvDesc: mapCodeDescription(
                        row.endProviderType,
                        providetypeCodeData
                    ),
                    beginProcDesc: mapCodeDescription(
                        row.beginProcedureType,
                        procedureData
                      ),
                    endProcDes: mapCodeDescription(
                        row.endProcedureType,
                        procedureData
                    )
                });
            });

            setEmarTable(tempSearchData);
        } else if (searchSuccess && !searchSuccess.systemFailure && searchSuccess.length === 1) {
            const searchData = searchSuccess;
            props.history.push({
                pathname: '/EMARUpdate',
                state: { searchData: searchData, editPage: true, fromSearch: true }
              });
        } else if (searchSuccess && searchSuccess.systemFailure) {
            const ErrorMessages = [];
            ErrorMessages.push(EMARAddConstants.GENERIC_SYSTEM_ERROR);
            seterrorMessages(ErrorMessages);
        } else if (searchSuccess && !searchSuccess.systemFailure && searchSuccess.length === 0) {
            const ErrorMessages = [];
            ErrorMessages.push(EMARAddConstants.NO_RECORDS_FOUND);
            seterrorMessages(ErrorMessages);
            setEmarTable([]);
            setShowTable(false);
        }
    }, [searchSuccess]);
    const handleChange = name => event => {
        setEMARState({
            ...EMARState,
            [name]: event.target.value
        });
    };
    const EMARSearchAction = () => {
        const searchEmarOb = {
            cms64TosCdSortKey: "",
            priority_Number: EMARState.priorityNumber,
            cms64TosLine: EMARState.tosLine,
            cms64TosDescription: EMARState.tosDescription
        };
        searchEmar(searchEmarOb);
    };
    const EMARReset = () => {
        setEMARState({
            tosLine: '',
            tosDescription: '',
            priorityNumber: ''
        });
        setEmarTable([]);
        setShowTable(false);
        seterrorMessages([]);
    };

    const headCells = [
        {
            id: 'cms64TosLine',
            numeric: false,
            disablePadding: false,
            enableHyperLink: true,
            label: 'TOS Line',
            isDate: false,
            width: '12%'
        },
        {
            id: 'cms64TosDescription',
            numeric: false,
            disablePadding: false,
            enableHyperLink: false,
            label: 'TOS Description',
            width: '15%'
        },
        {
            id: 'priority_Number',
            numeric: false,
            disablePadding: false,
            enableHyperLink: false,
            label: 'Priority Number',
            isDate: false,
            width: '12%'
        },
        {
            id: 'beginCosDesc',
            numeric: false,
            disablePadding: false,
            enableHyperLink: false,
            label: 'Begin State COS',
            isDate: false,
            width: '11%'
        },
        {
            id: 'endCosDesc',
            numeric: false,
            disablePadding: false,
            enableHyperLink: false,
            label: 'End State COS',
            isDate: false,
            width: '11%'
        },
        {
            id: 'beginProvDesc',
            numeric: false,
            disablePadding: false,
            enableHyperLink: false,
            label: 'Begin Provider Type',
            isDate: false,
            width: '10%'
        },
        {
            id: 'endProvDesc',
            numeric: false,
            disablePadding: false,
            enableHyperLink: false,
            label: 'End Provider Type',
            isDate: false,
            width: '10%'
        },
        {
            id: 'beginProcDesc',
            numeric: false,
            disablePadding: false,
            enableHyperLink: false,
            label: 'Begin Procedure Code',
            isDate: false,
            width: '12%'
        },
        {
            id: 'beginProcDesc',
            numeric: false,
            disablePadding: false,
            enableHyperLink: false,
            label: 'End Procedure Code',
            isDate: false,
            width: '12%'
        }
    ];
    const formatSearchCriteria = (row) => {
        return ({});
    };
    const editRow = row => event => {
        const searchEmarOb = {
            emarSk: row.emarSeqNo
        };
        searchEmar(searchEmarOb);
    };

    return (
        <div className="pos-relative w-100 h-100">

            <div className="tabs-container" ref={toPrintRef}>
                <ErrorMessages errorMessages={errorMessages} />
                <div className="tab-header">
                    <h1 className="tab-heading float-left">Search EMAR</h1>
                    <div className="clearfix"></div>
                </div>
                <div className="tab-body" style={{ overflow: 'hidden' }}>
                    <form autoComplete="off">
                        <div className="form-wrapper">
                            <div className="mui-custom-form with-select input-md">
                                <TextField
                                    id="tosLine"
                                    fullWidth
                                    label="CMS64 TOS Line"
                                    onChange={handleChange('tosLine')}
                                    value={EMARState.tosLine}
                                    placeholder=""
                                    inputProps={{ maxlength: 8 }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                </TextField>
                            </div>
                            <div className="mui-custom-form with-select input-md">
                                <TextField
                                    id="tosDescription"
                                    fullWidth
                                    label="CMS64 TOS Description"
                                    onChange={handleChange('tosDescription')}
                                    value={EMARState.tosDescription}
                                    placeholder=""
                                    inputProps={{ maxlength: 150 }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                </TextField>
                            </div>
                            <div className="mui-custom-form with-select input-md">
                                <TextField
                                    id="priorityNumber"
                                    fullWidth
                                    label="Begin State COS"
                                    onChange={handleChange('priorityNumber')}
                                    value={EMARState.priorityNumber}
                                    placeholder=""
                                    inputProps={{ maxlength: 8 }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                </TextField>
                            </div>
                        </div>
                    </form>

                    <div className="float-right mr-4 mb-3">
                        <Button
                            variant="outlined"
                            color="primary"
                            className="btn btn-primary ml-1"
                            onClick={() => {
                                EMARSearchAction();
                            }}
                        >
                            <i className="fa fa-search" aria-hidden="true"></i>
                            Search
            </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            className="bt-reset btn-transparent ml-1"
                            onClick={() => {
                                EMARReset();
                            }}
                        >
                            <i className="fa fa-undo" aria-hidden="true"></i> Reset
            </Button>
                    </div>

                    <div className="clearfix"></div>

                    {showTable ? (
                        <div className="tab-holder pb-1 tableNoWrap">

                            <TableComponent
                                print
                                fixedTable
                                headCells={headCells}
                                formatSearchCriteria={formatSearchCriteria}
                                tableData={emarTable}
                                isSearch={true}
                                onTableRowClick={editRow}
                                defaultSortColumn={headCells[1].id}
                                order={'asc'}
                            />
                        </div>
                    ) : null}
                    <Footer print />
                </div>
            </div>
        </div>
    );
}

export default withRouter(EMARSearch);
